import { getDatasheetPanel } from 'common/api/v1/equipmentCompatibility';

import {
    FETCH_DATASHEET_PANEL,
    FETCH_DATASHEET_PANEL_FAILURE,
    FETCH_DATASHEET_PANEL_SUCCESS,
} from '../actionTypes';
import { equipmentCompatibilityActions } from '../reducer';

export default (id) => (dispatch) => {
    dispatch(equipmentCompatibilityActions[FETCH_DATASHEET_PANEL]());

    getDatasheetPanel(id)
        .then((response) =>
            dispatch(
                equipmentCompatibilityActions[FETCH_DATASHEET_PANEL_SUCCESS](
                    response.data.data
                )
            )
        )
        .catch((error) =>
            dispatch(
                equipmentCompatibilityActions[FETCH_DATASHEET_PANEL_FAILURE](
                    error?.response?.data?.errors
                )
            )
        );
};
