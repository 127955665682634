import React, { useEffect } from 'react';

import { debounce, isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { createStructuredSelector } from 'reselect';

import AccordionProduct from 'common/components/accordions/AccordionProduct';
import { IconAdditional } from 'common/components/icons';
import { DEBOUNCE_TIME } from 'common/constants';
import { PERMISSION_LIST } from 'common/constants/permissionsV2';
import withPermissions from 'common/hocs/withPermissions';
import { numberFormat } from 'common/utils/helpers';

import {
    handleClickDelete,
    handleNormalizeDiscountField,
    handleNormalizeField,
    handleNormalizeFieldsBuild,
    handleNormalizeMarginField,
    handleNormalizeModel,
    handleNormalizeModelFieldBuild,
    handleNormalizePercentageDiscountField,
    handleRemoveBuild,
} from '../proposalGeneratorQuote/helpers';
import * as proposalGeneratorQuoteSelectors from '../proposalGeneratorQuote/selectors';

import * as actions from './actions';
import CardForm from './components/CardForm';
import * as selectors from './selectors';

const Container = ({
    additionals,
    createProduct,
    currencyIso,
    currencyLocale,
    currencySymbol,
    customerMode,
    deleteProduct,
    getPermissionsByCode,
    initialize,
    isFetching,
    isLoading,
    isLocked = false,
    isUpdating,
    offerAdditionals,
    proposalId,
    save,
    totalCost,
}) => {
    const { t } = useTranslation();

    useEffect(() => {
        if (!isEmpty(offerAdditionals)) {
            initialize(offerAdditionals);
        }
    }, [offerAdditionals]);

    const { canModify } = getPermissionsByCode(
        PERMISSION_LIST.PROPOSAL_ADITIONALS_PERMISSION
    );
    const { canModify: canModifyCost } = getPermissionsByCode(
        PERMISSION_LIST.PROPOSAL_PRODUCTS_COST_PERMISSION
    );
    const { canModify: canModifyDiscount } = getPermissionsByCode(
        PERMISSION_LIST.PROPOSAL_PRODUCTS_DISCOUNT_PERMISSION
    );
    const { canModify: canModifyMargin } = getPermissionsByCode(
        PERMISSION_LIST.PROPOSAL_PRODUCTS_MARGIN_PERMISSION
    );

    const handleOnSave = React.useRef(debounce(save, DEBOUNCE_TIME)).current;

    const handleNormalizeFields = handleNormalizeFieldsBuild(
        createProduct,
        handleOnSave,
        proposalId
    );

    const handleNormalizeModelField = handleNormalizeModelFieldBuild(
        createProduct,
        handleOnSave,
        proposalId
    );

    const handleRemove = handleRemoveBuild(deleteProduct, proposalId);

    const isLoadingForm = isFetching || isLoading || isUpdating;

    const isLockedForm = isLoadingForm || isLocked;

    return (
        <AccordionProduct
            caption={t('Total price')}
            cardId="proposal_generator_additional_card"
            isLoading={isLoadingForm}
            svgIcon={<IconAdditional />}
            title={t('Additional', { count: 2 })}
            total={numberFormat(totalCost, {
                currency: currencyIso,
                locale: currencyLocale,
                style: 'currency',
            })}
        >
            <CardForm
                canModify={canModify}
                canModifyCost={canModifyCost}
                canModifyDiscount={canModifyDiscount}
                canModifyMargin={canModifyMargin}
                currencyIso={currencyIso}
                currencyLocale={currencyLocale}
                currencySymbol={currencySymbol}
                customerMode={customerMode}
                handleClickDelete={handleClickDelete}
                handleNormalizeDiscountField={handleNormalizeDiscountField}
                handleNormalizeField={handleNormalizeField}
                handleNormalizeFields={handleNormalizeFields}
                handleNormalizeMarginField={handleNormalizeMarginField}
                handleNormalizeModel={handleNormalizeModel}
                handleNormalizeModelField={handleNormalizeModelField}
                handleNormalizePercentageDiscountField={
                    handleNormalizePercentageDiscountField
                }
                handleRemove={handleRemove}
                isLoading={isLoadingForm}
                isLocked={isLockedForm}
                itemsData={additionals}
                offerItems={
                    offerAdditionals &&
                    offerAdditionals.map((item) => item.product_branch_office)
                }
            />
        </AccordionProduct>
    );
};

const mapStateToProps = createStructuredSelector({
    additionals: proposalGeneratorQuoteSelectors.getProposalAdditionalCatalogs,
    isFetching: selectors.getIsFetchingOfferAdditionals,
    isUpdating: selectors.getIsUpdating,
    offerAdditionals: proposalGeneratorQuoteSelectors.getOfferAdditionalsData,
    totalCost: proposalGeneratorQuoteSelectors.getFinalCostAdditionals,
});

const mapDispatchToProps = (dispatch) => ({
    createProduct: (proposalId, values) =>
        dispatch(actions.create(proposalId, values)),
    deleteProduct: (id, proposalId, successCallback) =>
        dispatch(actions.delete(id, proposalId, successCallback)),
    initialize: (values) => dispatch(actions.initialValues(values)),
    save: (proposalId, valuesForm) =>
        dispatch(actions.save(proposalId, valuesForm)),
});

Container.propTypes = {
    additionals: PropTypes.array,
    createProduct: PropTypes.func,
    currencyIso: PropTypes.string,
    currencyLocale: PropTypes.string,
    currencySymbol: PropTypes.string,
    customerMode: PropTypes.bool,
    deleteProduct: PropTypes.func,
    getPermissionsByCode: PropTypes.func,
    initialize: PropTypes.func,
    isFetching: PropTypes.bool,
    isLoading: PropTypes.bool,
    isLocked: PropTypes.bool,
    isUpdating: PropTypes.bool,
    offerAdditionals: PropTypes.array,
    proposalId: PropTypes.string,
    save: PropTypes.func,
    totalCost: PropTypes.number,
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withPermissions([
        PERMISSION_LIST.PROPOSAL_ADITIONALS_PERMISSION,
        PERMISSION_LIST.PROPOSAL_PRODUCTS_COST_PERMISSION,
        PERMISSION_LIST.PROPOSAL_PRODUCTS_DISCOUNT_PERMISSION,
        PERMISSION_LIST.PROPOSAL_PRODUCTS_MARGIN_PERMISSION,
    ])
)(Container);
