import React, { useEffect, useRef, useState } from 'react';

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { styled, useTheme } from '@mui/material/styles';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import Chart from 'react-apexcharts';
import { useTranslation } from 'react-i18next';
import { Box } from 'sunwise-ui';

import { DropdownCardMenu } from 'common/components/cards';
import { ChartControls, MonthSelector } from 'common/components/charts';
import { useBreakpoint } from 'common/hooks';
import { scroll } from 'common/utils/mixins';

import { getChartConfig } from '../helpers';

const ChartWrapper = styled(Box)`
    overflow-x: scroll;
    overflow-y: hidden;
    width: 100%;

    ${scroll.custom()}
`;

const ChartComponent = ({
    canDelete,
    canModify,
    countryCurrencyLocale,
    deleteGenerationProfile,
    generationProfile,
    getValues,
    handleOpenCsvImporter,
    isModalForm = false,
    politicalDivisions = [],
    series,
}) => {
    const { t } = useTranslation();
    const breakpoint = useBreakpoint();
    const [chartConfig, setChartConfig] = useState({
        options: {},
        series: [{ data: [], name: '' }],
    });
    const [chartWidth, setChartWidth] = useState('98%');
    const [selectedDays, setSelectedDays] = useState([1]);
    const [selectedMonth, setSelectedMonth] = useState(0);
    const [selectedOption, setSelectedOption] = useState(0);
    const [selectedWeeks, setSelectedWeeks] = useState([0]);
    const container = useRef(null);
    const theme = useTheme();
    const {
        generation_profile_formatted,
        id,
        type = 0,
        year = 2019,
    } = generationProfile;

    useEffect(() => {
        const margin = 32;
        switch (breakpoint) {
            case 'xs':
                setChartWidth(600);
                break;
            default: {
                const width = container?.current
                    ? container.current?.offsetWidth - margin
                    : '98%';
                setChartWidth(width);
                break;
            }
        }
    }, [breakpoint]);

    useEffect(() => {
        setChartConfig(
            getChartConfig({
                countryCurrencyLocale,
                days: selectedDays,
                generationProfile: generation_profile_formatted,
                month: selectedMonth,
                series,
                type: selectedOption,
                weeks: selectedWeeks,
                year,
            })
        );
    }, [generation_profile_formatted, series]);

    if (isEmpty(series)) return null;

    const handleOnChange = (e) => {
        const value = parseInt(e.target.value);
        setSelectedOption(value);
        setSelectedMonth((prev) => (value === 1 && prev === 0 ? 1 : prev));
        setChartConfig(
            getChartConfig({
                countryCurrencyLocale,
                days: selectedDays,
                generationProfile: generation_profile_formatted,
                month:
                    [0, 1].includes(value) && selectedMonth === 0
                        ? 1
                        : selectedMonth,
                series,
                type: value,
                weeks: selectedWeeks,
                year,
            })
        );
    };

    const handleOnChangeSelectedMonth = (e) => {
        const value = parseInt(e.target.value);
        setSelectedMonth(value);
        setChartConfig(
            getChartConfig({
                countryCurrencyLocale,
                days: selectedDays,
                generationProfile: generation_profile_formatted,
                month: value,
                series,
                type: selectedOption,
                weeks: selectedWeeks,
                year,
            })
        );
    };

    const handleOnChangeSelectedDays = (e) => {
        let value = e.target.value;
        if (value[value.length - 1] === 'all')
            value = selectedDays.length === 7 ? [] : [0, 1, 2, 3, 4, 5, 6];
        setSelectedDays(value);
        setChartConfig(
            getChartConfig({
                countryCurrencyLocale,
                days: value,
                generationProfile: generation_profile_formatted,
                month: selectedMonth,
                series,
                type: selectedOption,
                weeks: selectedWeeks,
                year,
            })
        );
    };

    const handleOnChangeSelectedWeeks = (e) => {
        let value = e.target.value;
        if (value[value.length - 1] === 'all')
            value = selectedWeeks.length === 4 ? [] : [0, 1, 2, 3];
        setSelectedWeeks(value);
        setChartConfig(
            getChartConfig({
                countryCurrencyLocale,
                days: selectedDays,
                generationProfile: generation_profile_formatted,
                month: selectedMonth,
                series,
                type: selectedOption,
                weeks: value,
                year,
            })
        );
    };

    return (
        <>
            <Box
                alignItems="flex-start"
                display="flex"
                justifyContent="space-between"
                maxWidth={chartWidth}
            >
                <Box>
                    {generation_profile_formatted && (
                        <ChartControls
                            chartType="area"
                            chartTypeOptions={['area', 'area']}
                            handleOnChangePeriod={handleOnChange}
                            handleOnChangeSelectedDays={
                                handleOnChangeSelectedDays
                            }
                            handleOnChangeSelectedWeeks={
                                handleOnChangeSelectedWeeks
                            }
                            hideChangeTypeButtons
                            selectedDays={selectedDays}
                            selectedOption={selectedOption}
                            selectedWeeks={selectedWeeks}
                        />
                    )}
                </Box>
                {!isModalForm && (
                    <DropdownCardMenu
                        items={[
                            {
                                handleClick: handleOpenCsvImporter,
                                icon: <EditIcon fontSize="small" />,
                                label: t('Upload generation profile'),
                                visible: canModify,
                            },
                            {
                                handleClick: () => {
                                    const politicalDivision =
                                        getValues('political_division');

                                    deleteGenerationProfile(
                                        id,
                                        politicalDivision,
                                        politicalDivisions.find(
                                            (item) =>
                                                item.value === politicalDivision
                                        ).label
                                    );
                                },
                                icon: <DeleteIcon fontSize="small" />,
                                label: `${t('Delete')} ${t(
                                    'Generation profile'
                                ).toLowerCase()}`,
                                visible: canDelete && type !== 0,
                            },
                        ]}
                        visible={canDelete || canModify}
                    />
                )}
            </Box>

            <ChartWrapper>
                <Chart
                    height={400}
                    key={`chart-generation-period-${selectedOption}-${selectedMonth}`}
                    options={{
                        ...chartConfig.options,
                        theme: { mode: theme.palette.mode },
                    }}
                    series={chartConfig.series}
                    type="area"
                    width={chartWidth}
                />
            </ChartWrapper>

            {[1, 2, 3].includes(selectedOption) && (
                <MonthSelector
                    onChange={handleOnChangeSelectedMonth}
                    showYearlyOption={[2, 3].includes(selectedOption)}
                    value={selectedMonth}
                />
            )}
        </>
    );
};

ChartComponent.propTypes = {
    canDelete: PropTypes.bool,
    canModify: PropTypes.bool,
    countryCurrencyLocale: PropTypes.string,
    deleteGenerationProfile: PropTypes.func,
    generationProfile: PropTypes.object,
    getValues: PropTypes.func,
    handleOpenCsvImporter: PropTypes.func,
    isModalForm: PropTypes.bool,
    politicalDivisions: PropTypes.array,
    series: PropTypes.array,
};

export default ChartComponent;
