import { updateOfferItems } from 'common/api/v1/offerTaxes';
import { showReponseErrorsAsAlert } from 'common/utils/helpers';

import eventsAfterUpdate from '../../proposalGeneratorOneColumn/actions/eventsAfterUpdate';
import { SAVE, SAVE_SUCCESS, SAVE_FAILURE } from '../actionTypes';
import { proposalGeneratorTaxesTableActions } from '../reducer';

export default (proposalId, values) => (dispatch) => {
    let newValues = {
        value: values.is_percentage ? values.percentage_value : values.value,
        offer_tax: values.id,
    };

    dispatch(proposalGeneratorTaxesTableActions[SAVE]());

    updateOfferItems(newValues, proposalId)
        .then((response) => {
            dispatch(
                proposalGeneratorTaxesTableActions[SAVE_SUCCESS](response.data)
            );
            dispatch(eventsAfterUpdate(response.data.data, proposalId));
        })
        .catch((error) => {
            dispatch(
                proposalGeneratorTaxesTableActions[SAVE_FAILURE](
                    error?.response?.data?.errors
                )
            );
            showReponseErrorsAsAlert(dispatch, error?.response);
        });
};
