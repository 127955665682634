import { createSelector } from 'reselect';

import { NAME } from './constants';

export const getModel = (state) => state[NAME];

export const getInitialValues = createSelector(
    getModel,
    (model) => model.initialValues
);

/* get batteries */
export const getBatteriesObject = createSelector(
    getModel,
    (model) => model.batteries
);
export const getBatteriesIsLoading = createSelector(
    getBatteriesObject,
    (value) => value.isLoading
);
export const getBatteriesData = createSelector(
    getBatteriesObject,
    (value) => value.data || []
);
export const getBatteriesErrors = createSelector(
    getBatteriesObject,
    (value) => value.errors
);

/* get offer */
export const getOfferValues = createSelector(getModel, (model) => model.offer);
export const getOfferValuesIsLoading = createSelector(
    getOfferValues,
    (value) => value.isFetching
);
export const getOfferValuesData = createSelector(
    getOfferValues,
    (value) => value.data
);
export const getOfferValuesErrors = createSelector(
    getOfferValues,
    (value) => value.errors
);

export const getOfferValuesWarnings = createSelector(
    getOfferValuesData,
    (value) => value?.warnings || []
);

/* SAVE */
export const getSave = createSelector(getModel, (model) => model.save);
export const getIsSaving = createSelector(getSave, (value) => value.isSaving);
export const getDataSave = createSelector(getSave, (value) => value.data || []);
export const getErrorsSave = createSelector(getSave, (value) => value.errors);

/* STORAGE_PLAN */
export const getStoragePlan = createSelector(
    getModel,
    (model) => model.storagePlan
);
