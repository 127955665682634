import { getRates } from 'common/api/v2/rateList';

import {
    FETCH_RATES,
    FETCH_RATES_FAILURE,
    FETCH_RATES_SUCCESS,
} from '../actionTypes';
import { actions } from '../reducer';

export default () => (dispatch) => {
    dispatch(actions[FETCH_RATES]());
    getRates()
        .then((response) =>
            dispatch(actions[FETCH_RATES_SUCCESS](response?.data?.data))
        )
        .catch((error) => dispatch(actions[FETCH_RATES_FAILURE](error)));
};
