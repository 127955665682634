import React, { useEffect, useState } from 'react';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
    AccordionDetails,
    Box,
    Divider,
    Tab,
    TabPanel,
    Tabs,
    Typography,
} from 'sunwise-ui';

import {
    StyledAccordion,
    StyledAccordionSummary,
} from 'common/components/accordions';
import { IconGridConsumption } from 'common/components/icons';
import { useBreakpoint } from 'common/hooks';

import ContractedDemandFields from './ContractedDemandFields';
const ContractedDemand = ({
    control,
    disabled,
    handleOnChangeNextContractedDemand,
    previousContractedDemandTiers,
    scheduleRateConfiguration,
}) => {
    const [selectedTab, setSelectedTab] = useState('previous');
    const { t } = useTranslation();
    const breakpoint = useBreakpoint();

    const handleChange = (_, newValue) => setSelectedTab(newValue);

    const nextContractedDemandFields =
        scheduleRateConfiguration?.tiers_demand_distribution?.[0]?.tiers;
    const hasNextContractedDemand = nextContractedDemandFields?.length > 0;
    const hasPrevContractedDemand = previousContractedDemandTiers?.length > 0;

    useEffect(() => {
        if (!hasPrevContractedDemand && hasNextContractedDemand)
            setSelectedTab('next');
    }, [hasNextContractedDemand, hasPrevContractedDemand]);

    if (!hasNextContractedDemand && !hasPrevContractedDemand) return null;

    return (
        <StyledAccordion disableGutters>
            <StyledAccordionSummary
                aria-controls="advanced-content"
                expandIcon={<ExpandMoreIcon />}
                height="60px"
                id="advanced-header"
            >
                <Box display="flex" alignItems="center">
                    <IconGridConsumption color="currentColor" />
                    <Typography variant="body2" ml={1} fontWeight="bold">
                        {t('Contracted demand', { count: 2 })}
                    </Typography>
                </Box>
            </StyledAccordionSummary>
            <Divider />
            <AccordionDetails sx={{ p: 0, py: 2 }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs
                        value={selectedTab}
                        onChange={handleChange}
                        variant={
                            ['xs', 'sm', 'md'].includes(breakpoint)
                                ? 'scrollable'
                                : 'standard'
                        }
                    >
                        {hasPrevContractedDemand && (
                            <Tab
                                id="tab-previous"
                                key="tab-previous"
                                label={t('Initial rate')}
                                value="previous"
                            />
                        )}

                        {hasNextContractedDemand && (
                            <Tab
                                id="tab-next"
                                key="tab-next"
                                label={t('Proposed rate')}
                                value="next"
                            />
                        )}
                    </Tabs>
                </Box>

                {hasPrevContractedDemand && (
                    <TabPanel
                        key="tab-panel-previous"
                        selectedTab={selectedTab}
                        value="previous"
                    >
                        <ContractedDemandFields
                            baseName="previous_contracted_demand"
                            control={control}
                            disabled
                            fields={previousContractedDemandTiers?.map((tier) =>
                                tier?.name?.toLowerCase()
                            )}
                        />
                    </TabPanel>
                )}

                {hasNextContractedDemand && (
                    <TabPanel
                        key="tab-panel-next"
                        selectedTab={selectedTab}
                        value="next"
                    >
                        <ContractedDemandFields
                            baseName="next_contracted_demand"
                            control={control}
                            disabled={disabled}
                            fields={nextContractedDemandFields?.map((tier) =>
                                tier?.name?.toLowerCase()
                            )}
                            onChange={handleOnChangeNextContractedDemand}
                        />
                    </TabPanel>
                )}
            </AccordionDetails>
            <Divider />
        </StyledAccordion>
    );
};

ContractedDemand.propTypes = {
    control: PropTypes.object,
    disabled: PropTypes.bool,
    handleOnChangeNextContractedDemand: PropTypes.func,
    previousContractedDemandTiers: PropTypes.array,
    scheduleRateConfiguration: PropTypes.object,
};

export default ContractedDemand;
