import React from 'react';

import get from 'lodash/get';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { Box, Link, Typography } from 'sunwise-ui';

import StatusIndicatorTooltip from 'common/components/StatusIndicatorTooltip';
import { COMMERCIAL_OFFER_STATUS } from 'common/constants/commercialOffer';

const NameFormatter = ({ row }) => {
    const label = `${get(row, 'parent.name', '-')}`;
    const isGenerated = row.status === COMMERCIAL_OFFER_STATUS.FINISHED.key;
    const to = isGenerated
        ? `/proposal-review-pro/${row.id}`
        : `/proposal/${row.id}`;

    return (
        <Box alignItems="center" display="flex" gap={1}>
            <StatusIndicatorTooltip
                isApproved={row?.has_approbed || false}
                isGenerated={isGenerated}
            />

            <Link
                color="inherit"
                component={RouterLink}
                to={to}
                underline="none"
            >
                <Typography variant="caption" fontWeight="bold">
                    {label}
                </Typography>
            </Link>
        </Box>
    );
};

NameFormatter.propTypes = {
    row: PropTypes.object,
};

export default NameFormatter;
