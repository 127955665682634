import API from '../..';
const ENTITY = 'reporter-indicators';

export const getIndicatorsSettings = () => API.get(`/api/v1/${ENTITY}/`);

export const saveIndicatorsSettings = (data) =>
    API.put(
        `/api/v1/${ENTITY}/`,
        { ...data },
        { headers: { 'Content-Type': 'application/json' } }
    );
