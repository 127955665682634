import i18next from 'i18next';

import { downloadDatasheet } from 'common/api/v1/datasheets';
import showToast from 'common/utils/showToast';

import {
    DOWNLOAD_DATASHEET,
    DOWNLOAD_DATASHEET_FAILURE,
    DOWNLOAD_DATASHEET_SUCCESS,
} from '../actionTypes';
import { multiTemplateReviewActions } from '../reducer';

import downloadFile from './downloadFile';

export default (datasheetName, openLoading, closeLoading) => (dispatch) => {
    if (!datasheetName) {
        showToast({
            body: i18next.t('The file url is corrupted'),
            type: 'danger',
        });
        return false;
    }

    dispatch(multiTemplateReviewActions[DOWNLOAD_DATASHEET]());
    openLoading(i18next.t('Getting file').concat('...'));

    downloadDatasheet(datasheetName)
        .then((response) => {
            dispatch(
                multiTemplateReviewActions[DOWNLOAD_DATASHEET_SUCCESS](
                    response.data
                )
            );
            const url = window.URL.createObjectURL(
                new Blob([response.data], { type: 'application/pdf' })
            );
            dispatch(downloadFile(name, url, 'csv'));
            closeLoading();
            showToast({
                body: i18next.t('It was created successfully'),
            });
        })
        .catch((error) => {
            dispatch(
                multiTemplateReviewActions[DOWNLOAD_DATASHEET_FAILURE](
                    error.response.data.errors
                )
            );
            closeLoading();
            showToast({
                type: 'danger',
                body: i18next.t('An error occurred while downloading the file'),
            });
        });
};
