import i18next from 'i18next';
import isNil from 'lodash/isNil';
import { createSelector } from 'reselect';

import orm from 'common/orm';

import { NAME } from './constants';

export const getModel = (state) => state[NAME];

export const getEntitiesSession = createSelector(getModel, (state) =>
    orm.session(state.entities)
);

export const getInitialValues = createSelector(
    getModel,
    (model) => model.initialValues
);

/* SAVE */

export const getSave = createSelector(getModel, (model) => model.save);

export const getIsSaving = createSelector(getSave, (value) => value.isSaving);

export const getDataSave = createSelector(getSave, (value) => value.data || []);

export const getErrorsSave = createSelector(getSave, (value) => value.errors);

/* COMPATIBLE RATES */
export const getCompatibleRates = createSelector(
    getModel,
    (model) => model.compatibleRates
);

export const getIsFetchingCompatibleRates = createSelector(
    getCompatibleRates,
    (value) => value.isFetching
);

export const getCompatibleRatesErrors = createSelector(
    getCompatibleRates,
    (value) => value.errors
);

export const getCompatibleRatesData = createSelector(
    getCompatibleRates,
    (compatibleRates) => compatibleRates.data
);

export const getRatesData = createSelector(getEntitiesSession, ({ Rate }) =>
    Rate.all().toRefArray()
);

export const getRatesDictionary = createSelector(getRatesData, (rates) =>
    rates.reduce((acc, current) => ({ ...acc, [current.id]: current }), {})
);

export const getSelectedPrevRateData = createSelector(
    getEntitiesSession,
    getInitialValues,
    ({ Rate }, formValues) => {
        if (!formValues || !formValues.previous_rate) return null;
        const rate = Rate.withId(formValues.previous_rate);
        return rate && rate.ref;
    }
);

export const getSelectedNextRateData = createSelector(
    getEntitiesSession,
    getInitialValues,
    ({ Rate }, formValues) => {
        if (!formValues || !formValues.next_rate) return null;
        const rate = Rate.withId(formValues.next_rate);
        return rate && rate.ref;
    }
);

export const getCompatibleRatesForSelect = createSelector(
    getEntitiesSession,
    getCompatibleRatesData,
    getSelectedPrevRateData,
    getSelectedNextRateData,
    ({ Rate }, compatibleRates, selectedPrevRate, selectedNextRate) => {
        const defaultLabel = {
            label: `${i18next.t('Select')} ${i18next.t('Rate').toLowerCase()}`,
            values: '',
            disabled: true,
        };
        const rates = compatibleRates.map((rateId) => {
            const rate = Rate.withId(rateId);
            return rate && rate.ref;
        });
        if (isNil(selectedPrevRate)) return [defaultLabel];
        if (compatibleRates.length === 0 && selectedNextRate)
            return [
                defaultLabel,
                { label: selectedNextRate.name, values: selectedNextRate.id },
            ];
        const items = rates.map((rate) => ({
            label: rate.name,
            value: rate.id,
        }));
        return [defaultLabel, ...items];
    }
);

// Schedule Rate Configuration
export const getScheduleRateConfiguration = createSelector(
    getModel,
    (model) => model.scheduleRateConfiguration
);

export const getIsFetchingScheduleRateConfiguration = createSelector(
    getScheduleRateConfiguration,
    (value) => value.isFetching
);

export const getScheduleRateConfigurationData = createSelector(
    getScheduleRateConfiguration,
    (value) => value.data
);
