import React, { useEffect } from 'react';

import { get, isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import * as actions from './actions';
import { Card } from './components';
import {
    getAverageIrradiationCompleteValue,
    getKwhValue,
    getSunHoursValue,
} from './helpers';
import * as selectors from './selectors';

const Container = ({
    anualSolarGeneration,
    averageIrradiation,
    canModifySolarGeneration,
    canModifySolarGenerationFile,
    initialize,
    isLoading,
    isLocked,
    isSaving,
    offerConfigGeneration,
    proposalId,
    resetForm,
    save,
    sunHours,
    systemSize,
}) => {
    useEffect(() => {
        return () => resetForm();
    }, []);

    useEffect(() => {
        if (!isEmpty(offerConfigGeneration)) {
            const generation = get(offerConfigGeneration, 'generation', 0);
            const pvSystemDataSource =
                offerConfigGeneration.pv_system_data_source;
            const sunHoursSegments =
                offerConfigGeneration?.irradiation_segments_h_sun;
            initialize({
                generation: generation,
                kwhValue: getKwhValue({
                    averageIrradiation,
                    generation,
                    sunHoursSegments,
                    pvSystemDataSource,
                }),
                source: offerConfigGeneration.source,
                sunHoursValue: getSunHoursValue({
                    generation,
                    sunHoursSegments,
                    pvSystemDataSource,
                    sunHours,
                }),
                urlFile: offerConfigGeneration.file,
                csvSystemSize: offerConfigGeneration.csv_system_size,
                pvSystemDataSource,
            });
        }
    }, [offerConfigGeneration]);

    const onSubmit = (values) => save(proposalId, values);

    return (
        <Card
            anualSolarGeneration={anualSolarGeneration}
            averageIrradiation={averageIrradiation}
            canModifySolarGeneration={canModifySolarGeneration}
            canModifySolarGenerationFile={canModifySolarGenerationFile}
            hasDesignerGeneration={
                offerConfigGeneration?.has_designer_generation
            }
            isLocked={isLoading || isLocked || isSaving}
            kwhValue={getAverageIrradiationCompleteValue(
                averageIrradiation,
                offerConfigGeneration.generation
            )}
            onSubmit={onSubmit}
            proposalId={proposalId}
            sunHours={sunHours}
            systemSize={systemSize}
        />
    );
};

const mapStateToProps = createStructuredSelector({
    isSaving: selectors.getIsSaving,
});

const mapDispatchToProps = (dispatch) => ({
    initialize: (values) => dispatch(actions.initialValues(values)),
    resetForm: () => dispatch(actions.resetForm()),
    save: (proposalId, values) => dispatch(actions.save(proposalId, values)),
});

Container.propTypes = {
    anualSolarGeneration: PropTypes.number,
    averageIrradiation: PropTypes.number,
    canModifySolarGeneration: PropTypes.bool,
    canModifySolarGenerationFile: PropTypes.bool,
    initialize: PropTypes.func,
    isLoading: PropTypes.bool,
    isLocked: PropTypes.bool,
    isSaving: PropTypes.bool,
    offerConfigGeneration: PropTypes.object,
    proposalId: PropTypes.string,
    resetForm: PropTypes.func,
    sunHours: PropTypes.number,
    systemSize: PropTypes.number,
    save: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(Container);
