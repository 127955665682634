import { editReport } from 'common/api/v1/editReporter';
import { updateTemplate } from 'common/api/v1/templateReporter';

import setReportEnd from '../../reportPreview/actions/setReportEnd';

import initialize from './initialize';

export default (values) => (dispatch) => {
    const { initTemplate, reportId, resetTemplate, templateId } = values;

    if (!initTemplate || !reportId || !resetTemplate || !templateId) return;

    editReport(reportId).then(() => {
        updateTemplate(reportId, templateId).then(() => {
            dispatch(
                setReportEnd(reportId, false, () => {
                    resetTemplate();
                    dispatch(initialize({ id: reportId, initTemplate }));
                })
            );
        });
    });
};
