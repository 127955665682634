import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { createStructuredSelector } from 'reselect';
import { Container as MaterialContainer } from 'sunwise-ui';

import { PERMISSION_LIST } from 'common/constants/permissionsV2';
import withPermissions from 'common/hocs/withPermissions';
import { getCountryCurrencyLocale } from 'common/utils/helpers/session';

import AccessRestrictedBanner from '../afterSalesSettings/components/warnings/AccessRestrictedBanner';
import * as credentialIntegrationsActions from '../credentialIntegrations/actions';

import * as actions from './actions';
import AppliedFilters from './components/AppliedFilters';
import Content from './components/Content';
import FiltersModal from './components/FiltersModal';
import Header from './components/Header';
import Indicators from './components/Indicators';
import { VIEWS } from './constants';
import { getFiltersFromQuery } from './helpers';
import * as selectors from './selectors';

const Container = ({
    canView,
    fetchContacts,
    fetchIndicatorsData,
    fetchMonitoringData,
    fetchPoliticalDivisions,
    fetchProviders,
    fetchRates,
    initialValuesFilters,
    initializeFilters,
    location,
    reset,
    setIsOpenFiltersModal,
}) => {
    const [formattedReads, setFormattedReads] = useState([]);
    const [view, setView] = useState(VIEWS.MAP);

    const { search: urlQuery = '' } = location;
    const countryCurrencyLocale = getCountryCurrencyLocale();

    useEffect(() => {
        fetchContacts();
        fetchIndicatorsData();
        fetchPoliticalDivisions('dp1');
        fetchProviders();
        fetchRates();

        () => reset();
    }, []);

    useEffect(() => {
        const filters = getFiltersFromQuery(urlQuery);
        initializeFilters(filters);
        fetchMonitoringData(filters);
    }, [urlQuery]);

    const handleOnChangeView = (newView) => {
        if (!newView) return;
        setView(newView);
    };

    const handleOpenFiltersModal = () => setIsOpenFiltersModal(true);

    if (!canView) return null;

    return (
        <AccessRestrictedBanner>
            <MaterialContainer maxWidth={false} height="100%">
                <Header
                    handleOnChangeView={handleOnChangeView}
                    handleOpenFiltersModal={handleOpenFiltersModal}
                    view={view}
                />

                <AppliedFilters filtersValues={initialValuesFilters} />

                <Indicators countryCurrencyLocale={countryCurrencyLocale} />

                <Content
                    countryCurrencyLocale={countryCurrencyLocale}
                    formattedReads={formattedReads}
                    freq={initialValuesFilters?.freq}
                    setFormattedReads={setFormattedReads}
                    sx={{ mt: 2 }}
                    view={view}
                />

                <FiltersModal />
            </MaterialContainer>
        </AccessRestrictedBanner>
    );
};

const mapStateToProps = createStructuredSelector({
    initialValuesFilters: selectors.getInitialValuesFilters,
});

const mapDispatchToProps = (dispatch) => ({
    fetchContacts: () => dispatch(actions.fetchContacts()),
    fetchIndicatorsData: () => dispatch(actions.fetchIndicatorsData()),
    fetchMonitoringData: (params) =>
        dispatch(actions.fetchMonitoringData(params)),
    fetchPoliticalDivisions: (str) =>
        dispatch(actions.fetchPoliticalDivisions(str)),
    fetchProviders: () =>
        dispatch(credentialIntegrationsActions.fetchProviders()),
    fetchRates: () => dispatch(actions.fetchRates()),
    initializeFilters: (params) => dispatch(actions.initializeFilters(params)),
    reset: () => dispatch(actions.reset()),
    setIsOpenFiltersModal: (isOpen) =>
        dispatch(actions.setIsOpenFiltersModal(isOpen)),
});

Container.propTypes = {
    canView: PropTypes.bool,
    fetchContacts: PropTypes.func,
    fetchIndicatorsData: PropTypes.func,
    fetchMonitoringData: PropTypes.func,
    fetchPoliticalDivisions: PropTypes.func,
    fetchProviders: PropTypes.func,
    fetchRates: PropTypes.func,
    initialValuesFilters: PropTypes.object,
    initializeFilters: PropTypes.func,
    location: PropTypes.object,
    reset: PropTypes.func,
    setIsOpenFiltersModal: PropTypes.func,
};
export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withPermissions(PERMISSION_LIST.MONITORING_DASHBOARD_PERMISSION),
    withRouter
)(Container);
