import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
    Card,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
} from 'sunwise-ui';

import { AutocompleteField, InputSearch } from 'common/components';

const FiltersCard = ({
    filterData,
    handleOnChangeSearch,
    handleOnSelectBrand,
    handleOnSelectType,
    productBrands,
}) => {
    const { t } = useTranslation();

    const getBrandOptions = () => {
        return productBrands.map(({ name }) => ({
            value: name,
            label: name,
        }));
    };

    const accesoryTypeOptions = [
        {
            disabled: true,
            label: t('Select'),
            value: '',
        },
        {
            label: t('Monitoring'),
            value: 0,
        },
        {
            label: t('Wire'),
            value: 1,
        },
        {
            label: t('Optimizer'),
            value: 2,
        },
        {
            label: t('Connector'),
            value: 3,
        },
        {
            label: t('Transformer'),
            value: 4,
        },
    ];

    return (
        <Card>
            <Card.Body>
                <Grid container alignItems="end" justifyContent="flex-end">
                    <Grid item xs={18} md={9} lg={11}>
                        <InputSearch
                            placeholder={t('Filter by name')}
                            onChange={handleOnChangeSearch}
                            value={filterData?.searchText}
                        />
                    </Grid>
                    <Grid item xs={18} md={5} lg={4}>
                        <AutocompleteField
                            label={t('Brand')}
                            options={getBrandOptions()}
                            onChange={(value) => handleOnSelectBrand(value)}
                            value={filterData?.brandName}
                        />
                    </Grid>
                    <Grid item xs={18} md={3}>
                        <FormControl fullWidth size="small">
                            <InputLabel>{t('Type')}</InputLabel>
                            <Select
                                label={t('Type')}
                                onChange={(e) => handleOnSelectType(e.target)}
                                value={filterData?.type}
                                variant="standard"
                            >
                                {accesoryTypeOptions.map(
                                    ({ label, value, disabled }) => (
                                        <MenuItem
                                            disabled={disabled}
                                            key={`type-select-${value}-${label}`}
                                            value={value}
                                        >
                                            {label}
                                        </MenuItem>
                                    )
                                )}
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
            </Card.Body>
        </Card>
    );
};

FiltersCard.propTypes = {
    filterData: PropTypes.object,
    handleOnChangeSearch: PropTypes.func,
    handleOnSelectBrand: PropTypes.func,
    handleOnSelectType: PropTypes.func,
    productBrands: PropTypes.array,
};

export default FiltersCard;
