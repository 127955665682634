import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Box, Grid } from 'sunwise-ui';

import { HeaderContainer, TitleIcon } from 'common/components';
import PlaceholderCardListItem from 'common/components/placeholder/PlaceholderCardListItem';
import csvImporter from 'common/modules/csvImporter';

import * as actions from '../actions';
import { createConsumptionProfile } from '../helpers';
import * as selectors from '../selectors';

import ListContent from './ListContent';

const ConsumptionProfileList = ({
    canDelete,
    canModify,
    isFetchingConsumptionProfiles,
    prepareNewConsumptionProfile,
}) => {
    const { t } = useTranslation();

    return (
        <Box>
            <HeaderContainer sxHeader={{ pt: 0 }}>
                <Grid item xs={18} sm={9}>
                    <TitleIcon
                        fontWeight="bold"
                        title={t('Profile', { count: 2 })}
                        variant="title"
                    />
                </Grid>
                {canModify && (
                    <Grid item xs={18} sm={9} textAlign="right">
                        <csvImporter.Container
                            buttonProps={{
                                disabled: isFetchingConsumptionProfiles,
                                sx: { width: { xs: '100%', sm: 'auto' } },
                            }}
                            buttonText={t('Add')}
                            columns={[
                                {
                                    columnName: 'date',
                                    displayLabel: t('Date'),
                                    required: true,
                                    shouldFormat: false,
                                    type: csvImporter.COLUMN_TYPES.DATE,
                                    matchAliases: ['fecha', 'timestamp'],
                                },
                                {
                                    columnName: 'time',
                                    displayLabel: t('Time'),
                                    matchAliases: ['hora', 'time'],
                                    shouldFormat: false,
                                    type: csvImporter.COLUMN_TYPES.TIME,
                                    visible: (_, columnsFormat) =>
                                        columnsFormat?.date &&
                                        !columnsFormat.date.includes('HH'),
                                },
                                {
                                    columnName: 'consumption',
                                    displayLabel: t('Consumption'),
                                    matchAliases: [
                                        'consumo total',
                                        'consumo',
                                        'consumption',
                                        'energy',
                                        'kwh',
                                        'profile',
                                    ],
                                    required: true,
                                    type: csvImporter.COLUMN_TYPES.NUMBER,
                                    priority: 1,
                                },
                            ]}
                            downloadExampleText={t('Download the sample CSV')}
                            onClickDownloadExampleFile={() =>
                                window.open(
                                    `${process.env.REACT_APP_ACADEMY_URL}es/articles/6955258-perfil-de-consumo`,
                                    '_blank'
                                )
                            }
                            onImport={(result, config) => {
                                const { profile, profileFormatted, year } =
                                    createConsumptionProfile(result, config);

                                prepareNewConsumptionProfile({
                                    year,
                                    consumption: profile,
                                    consumption_formatted: profileFormatted,
                                });
                            }}
                            returnFormattedData
                        />
                    </Grid>
                )}
            </HeaderContainer>

            <PlaceholderCardListItem ready={!isFetchingConsumptionProfiles}>
                <ListContent canDelete={canDelete} />
            </PlaceholderCardListItem>
        </Box>
    );
};

const mapStateToProps = createStructuredSelector({
    isFetchingConsumptionProfiles: selectors.getIsFetchingConsumptionProfiles,
});

const mapDispatchToProps = (dispatch) => ({
    prepareNewConsumptionProfile: (data) =>
        dispatch(actions.prepareNewConsumptionProfile(data)),
});

ConsumptionProfileList.propTypes = {
    canDelete: PropTypes.bool,
    canModify: PropTypes.bool,
    isFetchingConsumptionProfiles: PropTypes.bool,
    prepareNewConsumptionProfile: PropTypes.func,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ConsumptionProfileList);
