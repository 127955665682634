import i18next from 'i18next';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import isNull from 'lodash/isNull';

import { isEmptyText } from 'common/utils/helpers';

import { DEFAULT_TAX_ELEMENTS } from './constants';

export const getTaxes = (taxes) =>
    taxes.map((item) => {
        return {
            ...item,
            companies_taxes_elements: cleanTaxesElements(
                item.companies_taxes_elements
            ),
        };
    });

export const cleanTaxesElements = (elements) => {
    if (elements.length === 0) {
        return DEFAULT_TAX_ELEMENTS;
    }
    return elements.map((item) => item.element_type);
};

export const getCheckedProductsIds = (products) =>
    products.map((item) => item.value);

export const getCompanyTaxesReversed = (companyTaxes, id, taxes) => {
    const oldItem = taxes.find((tax) => tax.id === id);
    return companyTaxes.map((item) => {
        if (item.id === id) {
            return oldItem;
        }

        return item;
    });
};

export const getIsChecked = (elements, value) => elements.includes(value);

export const getIsEmptyTax = (item) => {
    if (isNull(item.id)) {
        return (
            isEmpty(item.tax_name) ||
            isEmpty(item.tax_value) ||
            parseFloat(item.tax_value) <= 0 ||
            (item.is_percentage && isEmpty(item.companies_taxes_elements))
        );
    }
    return false;
};

export const getProductsToSelect = () => [
    {
        checked: false,
        label: i18next.t('Solar panel', { count: 2 }),
        value: 0,
    },
    {
        checked: false,
        label: i18next.t('Inverter', { count: 2 }),
        value: 1,
    },
    {
        checked: false,
        label: i18next.t('Storage'),
        value: 6,
    },
    {
        checked: false,
        label: i18next.t('Accessory', { count: 2 }),
        value: 2,
    },
    {
        label: i18next.t('Structure', { count: 2 }),
        value: 3,
        checked: false,
    },
    {
        checked: false,
        label: i18next.t('Workforce and electrical equipment'),
        value: 4,
    },
    {
        checked: false,
        label: i18next.t('Additional', { count: 2 }),
        value: 5,
    },
];

export const getTaxHasChanged = (item, taxes) => {
    const initialTax = taxes.find((tax) => tax.id === item.id);
    return !isEqual(initialTax, {
        ...item,
        tax_value: parseFloat(item.tax_value),
    });
};

export const getTaxesWithShowedDropdown = (companyTaxes, id) =>
    companyTaxes.map((item) => {
        if (item.id === id) {
            return { ...item, showDropdown: true };
        }
        return { ...item, showDropdown: false };
    });

export const handleClickCancel = (
    companyTaxes,
    currentTax,
    isCreating,
    setCompanyTaxes,
    setCurrentTax,
    setIsCreating,
    setIsEditing,
    taxes
) => {
    if (isCreating) {
        const newData = [...companyTaxes];
        const index = newData.length - 1;
        newData.splice(index, 1);
        setCompanyTaxes(newData);
    }
    handleReverse(companyTaxes, currentTax, setCompanyTaxes, taxes);
    setCurrentTax({ id: null });
    setIsCreating(false);
    setIsEditing(false);
};

export const handleClickCreate = (
    setCompanyTaxes,
    setCurrentTax,
    setIsCreating,
    setIsEditing,
    taxes
) => {
    const newTax = {
        id: null,
        tax_name: '',
        tax_value: 0,
        is_percentage: true,
        companies_taxes_elements: DEFAULT_TAX_ELEMENTS,
    };
    setIsEditing(false);
    setCompanyTaxes([...taxes, newTax]);
    setCurrentTax(newTax);
    setIsCreating(true);
};

export const handleClickDelete = (
    companyTaxes,
    handleUpdate,
    id,
    index,
    isCreating,
    prepareDelete,
    setCompanyTaxes,
    setCurrentTax,
    setIsCreating
) => {
    if (isCreating) {
        const newData = [...companyTaxes];
        const index = newData.length - 1;
        newData.splice(index, 1);
        setCompanyTaxes(newData);
        setCurrentTax({ id: null });
        setIsCreating(false);
    } else {
        prepareDelete({
            beforeAction: () =>
                handleUpdate(
                    companyTaxes,
                    index,
                    'isDeleting',
                    true,
                    setCompanyTaxes,
                    setCurrentTax
                ),
            id,
        });
    }
};

export const handleOnClickAllElements = (
    companyTaxes,
    handleUpdate,
    index,
    productsToSelect,
    taxesElements,
    setCompanyTaxes,
    setCurrentTax
) => {
    let newTaxesElements = [];
    if (taxesElements.length === 0) {
        newTaxesElements = getCheckedProductsIds(productsToSelect);
    }
    handleUpdate(
        companyTaxes,
        index,
        'companies_taxes_elements',
        newTaxesElements,
        setCompanyTaxes,
        setCurrentTax
    );
};

export const handleOnClickElement = (
    companyTaxes,
    handleUpdate,
    index,
    item,
    taxesElements,
    setCompanyTaxes,
    setCurrentTax
) => {
    let newTaxesElements = [];
    if (!taxesElements.includes(item.value)) {
        newTaxesElements = [...taxesElements, item.value];
    } else {
        newTaxesElements = [...taxesElements];
        const index = newTaxesElements.indexOf(item.value);
        newTaxesElements.splice(index, 1);
    }
    handleUpdate(
        companyTaxes,
        index,
        'companies_taxes_elements',
        newTaxesElements,
        setCompanyTaxes,
        setCurrentTax
    );
};

export const handleUpdate = (
    companyTaxes,
    index,
    name,
    value,
    setCompanyTaxes,
    setCurrentTax
) => {
    let items = [...companyTaxes];
    let item = {
        ...items[index],
        [name]: value,
    };
    items[index] = item;
    setCurrentTax(item);
    setCompanyTaxes(items);
};

export const handleReverse = (
    companyTaxes,
    currentTax,
    setCompanyTaxes,
    taxes
) => {
    const oldItem = taxes.find((tax) => tax.id === currentTax.id);
    const index = companyTaxes.indexOf(currentTax);
    let items = [...companyTaxes];
    items[index] = {
        ...oldItem,
        companies_taxes_elements: cleanTaxesElements(
            oldItem.companies_taxes_elements
        ),
    };
    setCompanyTaxes(items);
};

export const handleUpdateShowDropdown = (
    companyTaxes,
    setCompanyTaxes,
    taxItem
) => {
    const taxesUpdated = getTaxesWithShowedDropdown(companyTaxes, taxItem.id);
    setCompanyTaxes(taxesUpdated);
};

export const isDisabledFields = (
    currentTax,
    isCreating,
    isEditing,
    item,
    taxes
) => {
    if (!isNull(currentTax.id) && isEditing) {
        return item.id !== currentTax.id && getTaxHasChanged(currentTax, taxes);
    }
    if (isCreating) {
        return item.id !== currentTax.id;
    }
    return false;
};

export const isDisabledCreateButton = (
    currentTax,
    isCreating,
    isEditing,
    taxes
) => {
    if (!isNull(currentTax.id) && isEditing) {
        return getTaxHasChanged(currentTax, taxes);
    }
    return isCreating;
};

export const isInvalid = (value) => !value || isEmptyText(value);

export const isVisibleCancelButton = (currentTax, isEditing, taxes) => {
    if (!isNull(currentTax.id) && isEditing) {
        return getTaxHasChanged(currentTax, taxes);
    }
    return false;
};

export const isVisibleSaveButton = (
    currentTax,
    isCreating,
    isEditing,
    taxes
) => {
    if (!isNull(currentTax.id) && isEditing) {
        return getTaxHasChanged(currentTax, taxes);
    }
    if (isCreating) {
        return !getIsEmptyTax(currentTax);
    }
    return false;
};
