import { object, string } from 'yup';

import getValidationTexts from 'common/utils/getValidationTexts';

export default () => {
    const { REQUIRED_TEXT } = getValidationTexts();

    return object().shape({
        exchange_type: string().required(REQUIRED_TEXT).nullable(),
        source: string()
            .when('exchange_type', {
                is: 'AUT',
                then: () =>
                    string()
                        .required(REQUIRED_TEXT)
                        .min(1, REQUIRED_TEXT)
                        .nullable(),
            })
            .nullable(),
        dollar_price: string()
            .when('exchange_type', {
                is: 'MNL',
                then: () => string().required(REQUIRED_TEXT).nullable(),
            })
            .nullable(),
        inverse_dollar_price: string()
            .when('exchange_type', {
                is: 'MNL',
                then: () => string().required(REQUIRED_TEXT).nullable(),
            })
            .nullable(),
    });
};
