import get from 'lodash/get';
import { number, object, string } from 'yup';

import getValidationTexts from 'common/utils/getValidationTexts';
import { dateRegex } from 'common/utils/helpers';

import { percentageToValue, valueToPercentage } from './helpers';

export const financialProductFormValidate = (_, props) => {
    const {
        getMaxValueText,
        getMinValueText,
        NUMBER_TYPE_TEXT,
        REQUIRED_TEXT,
    } = getValidationTexts();
    {
        const {
            commission_credit = 0,
            hitch = 0,
            insurance = 0,
            rate = 0,
            residual = 0,
            hitch_percentage = 0,
        } = props.initialValues;
        const total = props.total;

        return object().shape({
            commission_credit: number().when('is_commission_percentage', {
                is: true,
                then: () =>
                    number()
                        .typeError(NUMBER_TYPE_TEXT)
                        .required(REQUIRED_TEXT)
                        .min(
                            commission_credit,
                            getMinValueText(commission_credit)
                        )
                        .max(100, getMaxValueText(100))
                        .nullable(),
                otherwise: () =>
                    number()
                        .typeError(NUMBER_TYPE_TEXT)
                        .required(REQUIRED_TEXT)
                        .min(0, getMinValueText(0))
                        .max(
                            commission_credit,
                            getMaxValueText(commission_credit)
                        )
                        .nullable(),
            }),
            hitch: number().when('is_percentage', {
                is: false,
                then: () =>
                    number()
                        .typeError(NUMBER_TYPE_TEXT)
                        .required(REQUIRED_TEXT)
                        .min(hitch, getMinValueText(hitch))
                        .max(total, getMaxValueText(total))
                        .nullable(),
            }),
            hitch_percentage: number().when('is_percentage', {
                is: true,
                then: () =>
                    number()
                        .typeError(NUMBER_TYPE_TEXT)
                        .required(REQUIRED_TEXT)
                        .min(
                            hitch_percentage,
                            getMinValueText(hitch_percentage)
                        )
                        .max(100, getMaxValueText(100))
                        .nullable(),
            }),
            insurance: number()
                .typeError(NUMBER_TYPE_TEXT)
                .required(REQUIRED_TEXT)
                .min(0, getMinValueText(0))
                .max(insurance, getMaxValueText(insurance))
                .nullable(),
            rate: number()
                .typeError(NUMBER_TYPE_TEXT)
                .required(REQUIRED_TEXT)
                .min(0, getMinValueText(0))
                .max(rate, getMaxValueText(rate))
                .nullable(),
            residual: number()
                .typeError(NUMBER_TYPE_TEXT)
                .required(REQUIRED_TEXT)
                .min(0, getMinValueText(0))
                .max(residual, getMaxValueText(residual))
                .nullable(),
            term: string().required(REQUIRED_TEXT).nullable(),
        });
    }
};

export const ciBancoFormValidate = (_, props) => {
    const {
        getMinValueText,
        getMaxValueText,
        NUMBER_TYPE_TEXT,
        REQUIRED_TEXT,
    } = getValidationTexts();
    const { parameters, total } = props.initialValues;
    const { company_name, email } = props.contact;
    const isPercentage = get(parameters, 'is_percentage', false);
    const hitch = get(parameters, 'hitch', false);
    const minHitch = percentageToValue(isPercentage, hitch, total);
    const minHitchPercentage = valueToPercentage(isPercentage, hitch, total);
    return object().shape({
        types_business_company: string().when('step', {
            is: 1,
            then: () => string().required(REQUIRED_TEXT).nullable(),
            otherwise: () => string().nullable(),
        }),
        financier_product: string().when('step', {
            is: 1,
            then: () => string().required(REQUIRED_TEXT).nullable(),
            otherwise: () => string().nullable(),
        }),
        term: string().when(['step', 'is_serfimex'], {
            is: (step, isSerfimex) => step === 2 && isSerfimex === false,
            then: () => string().required(REQUIRED_TEXT).nullable(),
            otherwise: () => string().nullable(),
        }),
        hitch_percentage: number().when(['step', 'is_percentage'], {
            is: (step, is_percentage) => step === 2 && is_percentage === 'true',
            then: () =>
                number()
                    .typeError(NUMBER_TYPE_TEXT)
                    .required(REQUIRED_TEXT)
                    .min(
                        minHitchPercentage,
                        getMinValueText(minHitchPercentage)
                    )
                    .max(100, getMaxValueText(100))
                    .nullable(),
            otherwise: () => number().nullable(),
        }),
        hitch: number().when(['step', 'is_percentage'], {
            is: (step, is_percentage) =>
                step === 2 && is_percentage === 'false',
            then: () =>
                number()
                    .typeError(NUMBER_TYPE_TEXT)
                    .required(REQUIRED_TEXT)
                    .min(minHitch, getMinValueText(minHitch))
                    .max(total, getMaxValueText(total))
                    .nullable(),
            otherwise: () => number().nullable(),
        }),
        email: string().when(['step', 'is_serfimex'], {
            is: (step, isSerfimex) =>
                step === 1 && isSerfimex === true && !email,
            then: () => string().required(REQUIRED_TEXT).nullable(),
            otherwise: () => string().nullable(),
        }),
        company_name: string().when(
            ['step', 'is_serfimex', 'types_business_company'],
            {
                is: (step, isSerfimex, typesBusiness) =>
                    step === 1 &&
                    isSerfimex &&
                    typesBusiness === 'company' &&
                    !company_name,
                then: () => string().required(REQUIRED_TEXT).nullable(),
                otherwise: () => string().nullable(),
            }
        ),
    });
};

export const redGirasolFormValidate = () => {
    const { NUMBER_TYPE_TEXT, REQUIRED_TEXT } = getValidationTexts();

    return object().shape({
        types_business_company: string().when('step', {
            is: 1,
            then: () => string().required(REQUIRED_TEXT).nullable(),
            otherwise: () => string().nullable(),
        }),
        financier_product: string().when('step', {
            is: 1,
            then: () => string().required(REQUIRED_TEXT).nullable(),
            otherwise: () => string().nullable(),
        }),
        term: string().when('step', {
            is: 2,
            then: () => string().required(REQUIRED_TEXT).nullable(),
            otherwise: () => string().nullable(),
        }),
        hitch_percentage: number().when(['step', 'is_percentage'], {
            is: (step, is_percentage) => step === 2 && is_percentage === 'true',
            then: () =>
                number()
                    .typeError(NUMBER_TYPE_TEXT)
                    .required(REQUIRED_TEXT)
                    .nullable(),
            otherwise: () => number().nullable(),
        }),
        hitch: number().when(['step', 'is_percentage'], {
            is: (step, is_percentage) =>
                step === 2 && is_percentage === 'false',
            then: () =>
                number()
                    .typeError(NUMBER_TYPE_TEXT)
                    .required(REQUIRED_TEXT)
                    .nullable(),
            otherwise: () => number().nullable(),
        }),
    });
};

export const ceideFormValidate = () => {
    const { DATE_VALIDATION_TEXT, NUMBER_TYPE_TEXT, REQUIRED_TEXT } =
        getValidationTexts();

    return object().shape({
        types_business_company: string().when('step', {
            is: 1,
            then: () => string().required(REQUIRED_TEXT).nullable(),
            otherwise: () => string().nullable(),
        }),
        financier_product: string().when('step', {
            is: 1,
            then: () => string().required(REQUIRED_TEXT).nullable(),
            otherwise: () => string().nullable(),
        }),
        infonavit_data: object().shape({
            nss: string().required(REQUIRED_TEXT).nullable(),
            birthday: string()
                .matches(dateRegex, DATE_VALIDATION_TEXT)
                .required(REQUIRED_TEXT)
                .nullable(),
            total_amount: number()
                .typeError(NUMBER_TYPE_TEXT)
                .required(REQUIRED_TEXT)
                .nullable(),
            retention_value: number()
                .typeError(NUMBER_TYPE_TEXT)
                .required(REQUIRED_TEXT)
                .nullable(),
            administratives_expenses: number()
                .typeError(NUMBER_TYPE_TEXT)
                .required(REQUIRED_TEXT)
                .nullable(),
        }),
    });
};
