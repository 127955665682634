import i18next from 'i18next';

import { saveCreditAppDocument } from 'common/api/v1/sunpay';
import showToast from 'common/utils/showToast';

import {
    UPLOAD_DOCUMENT,
    UPLOAD_DOCUMENT_FAILURE,
    UPLOAD_DOCUMENT_SUCCESS,
} from '../actionTypes';
import { projectCommOfferTableActions } from '../reducer';

import fetchCommercialOffers from './fetchCommercialOffers';
import fetchCreditDocuments from './fetchCreditDocuments';

export default (
        creditAppId,
        handleUpdateCreditStatus,
        handleUpdateFile,
        isArchivedFilter,
        onSuccess,
        projectId,
        selectedFileId,
        tempFile
    ) =>
    (dispatch) => {
        dispatch(projectCommOfferTableActions[UPLOAD_DOCUMENT]());
        saveCreditAppDocument(selectedFileId, { file: tempFile })
            .then((response) => {
                dispatch(
                    projectCommOfferTableActions[UPLOAD_DOCUMENT_SUCCESS]()
                );
                if (handleUpdateFile) handleUpdateFile(response?.data?.data);
                if (onSuccess) onSuccess();
                dispatch(fetchCreditDocuments(creditAppId));
                dispatch(
                    fetchCommercialOffers(
                        projectId,
                        isArchivedFilter,
                        handleUpdateCreditStatus
                    )
                );
                showToast({
                    body: i18next.t('The file was uploaded successfully'),
                });
            })
            .catch((error) =>
                dispatch(
                    projectCommOfferTableActions[UPLOAD_DOCUMENT_FAILURE](
                        error?.response?.data?.errors
                    )
                )
            );
    };
