import React, { useEffect } from 'react';

import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { createStructuredSelector } from 'reselect';
import { Box } from 'sunwise-ui';

import PlaceholderCardContactsDashboard from 'common/components/placeholder/PlaceholderCardContactsDashboard';
import { PERMISSION_LIST } from 'common/constants/permissionsV2';
import withPermissions from 'common/hocs/withPermissions';
import * as lisaFilesActions from 'common/modules/lisaFiles/actions';
import {
    getCountryCurrencyIso,
    getCountryCurrencyLocale,
    getCountryCurrencySymbol,
} from 'common/utils/helpers/session';

import * as importConsumptionsModalActions from '../importConsumptionsModal/actions';

import * as actions from './actions';
import { CardConsumption, NoDataIntake } from './components';
import * as selectors from './selectors';

const Container = ({
    canModify,
    canView,
    consumptionEnergy,
    downloadCSV,
    errorsUpdatingConsumptionEnergy,
    fetchConsumptionEnergy,
    fetchStatusProjects,
    handleDownloadLisaFile,
    handleExportCSV,
    handleOnClickUpdateConsumption,
    handleOpenImportConsumptionsModal,
    infoPriceConsumtion,
    isDownloadingHistory,
    isFetching,
    isUpdatingConsumptionEnergy,
    monthsToChartKw,
    monthsToChartKwh,
    onClickAddConsumption,
    prepareDowloadHistory,
    prepareUpdate,
    selectProject,
    selectedProject,
}) => {
    const countryCurrencyIso = getCountryCurrencyIso();
    const countryCurrencyLocale = getCountryCurrencyLocale();
    const countryCurrencySymbol = getCountryCurrencySymbol();

    useEffect(() => {
        selectProject(selectedProject);
        fetchConsumptionEnergy(selectedProject.id);
    }, [selectedProject.id]);

    const getPeriodicityType = (period) => {
        if (period === 'Bimonthly') return 1;
        if (period === 'Quarter') return 2;
        return 0;
    };

    if (!canView) return null;

    return (
        <Box mb={2}>
            <PlaceholderCardContactsDashboard ready={isFetching}>
                {isEmpty(consumptionEnergy) ? (
                    <NoDataIntake
                        canModify={canModify}
                        handleOpenImportConsumptionsModal={
                            handleOpenImportConsumptionsModal
                        }
                        onClick={onClickAddConsumption}
                    />
                ) : (
                    <CardConsumption
                        canModify={canModify}
                        consumptionsCsv={consumptionEnergy.consumptions_csv}
                        countryCurrencyIso={countryCurrencyIso}
                        countryCurrencyLocale={countryCurrencyLocale}
                        countryCurrencySymbol={countryCurrencySymbol}
                        createdAt={consumptionEnergy.created_at}
                        errorsUpdatingConsumptionEnergy={
                            errorsUpdatingConsumptionEnergy
                        }
                        finalDate={
                            consumptionEnergy.lines_consumption_energies &&
                            !isEmpty(
                                consumptionEnergy.lines_consumption_energies
                            )
                                ? consumptionEnergy.lines_consumption_energies[
                                      consumptionEnergy
                                          .lines_consumption_energies.length - 1
                                  ]?.final_date
                                : ''
                        }
                        handleClickUpdate={prepareUpdate}
                        handleDownloadCSV={() =>
                            downloadCSV(
                                consumptionEnergy.service_number,
                                consumptionEnergy.id
                            )
                        }
                        handleDownloadLisaFile={handleDownloadLisaFile}
                        handleExportCSV={() =>
                            handleExportCSV(selectedProject.id)
                        }
                        handleOnClickUpdateConsumption={() =>
                            handleOnClickUpdateConsumption(
                                consumptionEnergy.id,
                                selectedProject.id
                            )
                        }
                        handleOpenImportConsumptionsModal={
                            handleOpenImportConsumptionsModal
                        }
                        holder={consumptionEnergy.holder}
                        infoPriceConsumtion={infoPriceConsumtion}
                        isDownloadingHistory={isDownloadingHistory}
                        periodicityType={
                            consumptionEnergy.certified
                                ? consumptionEnergy.is_bimonthly
                                    ? 1
                                    : 0
                                : getPeriodicityType(
                                      consumptionEnergy.periodicity_type
                                  )
                        }
                        isUpdatingConsumptionEnergy={
                            isUpdatingConsumptionEnergy
                        }
                        monthskw={monthsToChartKw}
                        monthskwh={monthsToChartKwh}
                        prepareDowloadHistory={() =>
                            prepareDowloadHistory(selectedProject.id)
                        }
                        projectName={selectedProject.name}
                        rate={consumptionEnergy.rate.name}
                        receiptOrigin={consumptionEnergy.receipt_origin}
                        region={
                            consumptionEnergy.rate_division !== null
                                ? consumptionEnergy.rate_division.name
                                : ''
                        }
                        serviceNumber={consumptionEnergy.service_number}
                        threadsNumber={consumptionEnergy.threads_number}
                        fetchStatusProjects={fetchStatusProjects}
                    />
                )}
            </PlaceholderCardContactsDashboard>
        </Box>
    );
};

const mapStateToProps = createStructuredSelector({
    consumptionEnergy: selectors.getDataConsumptionEnergy,
    errors: selectors.getErrorsConsumptionEnergy,
    errorsUpdatingConsumptionEnergy:
        selectors.getErrorsUpdatingConsumptionEnergy,
    infoPriceConsumtion: selectors.getInfoPriceConsumtion,
    isDownloadingHistory: selectors.getIsDownloadingHistory,
    isFetching: selectors.getIsFetchingConsumptionEnergy,
    isUpdatingConsumptionEnergy: selectors.getIsUpdatingConsumptionEnergy,
    monthsToChartKw: selectors.getMonthsToChartKw,
    monthsToChartKwh: selectors.getMonthsToChartKwh,
});

const mapDispatchToProps = (dispatch) => ({
    downloadCSV: (service_number, consumptionEnergyId) =>
        dispatch(actions.downloadCSV(service_number, consumptionEnergyId)),
    fetchConsumptionEnergy: (projectId) =>
        dispatch(actions.fetchConsumptionEnergy(projectId)),
    fetchStatusProjects: () => dispatch(actions.fetchStatusProjects()),
    handleDownloadLisaFile: (file_name) =>
        dispatch(lisaFilesActions.downloadFileFromName(file_name)),
    handleExportCSV: (projectId) => dispatch(actions.exportCSV(projectId)),
    handleOnClickUpdateConsumption: (id, projectId) => {
        dispatch(actions.updateConsumption(id, projectId));
    },
    handleOpenImportConsumptionsModal: () =>
        dispatch(importConsumptionsModalActions.setIsOpen(true)),
    onClickAddConsumption: () => dispatch(actions.onClickAddConsumption()),
    prepareUpdate: () => dispatch(actions.prepareUpdate()),
    prepareDowloadHistory: (id) => {
        dispatch(actions.prepareDowloadHistory(id));
    },
    selectProject: (project) => dispatch(actions.selectProject(project)),
});

Container.propTypes = {
    canModify: PropTypes.bool,
    canView: PropTypes.bool,
    consumptionEnergy: PropTypes.object,
    downloadCSV: PropTypes.func,
    errorsUpdatingConsumptionEnergy: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.array,
    ]),
    fetchConsumptionEnergy: PropTypes.func,
    fetchStatusProjects: PropTypes.func,
    handleDownloadLisaFile: PropTypes.func,
    handleExportCSV: PropTypes.func,
    handleOnClickUpdateConsumption: PropTypes.func,
    handleOpenImportConsumptionsModal: PropTypes.func,
    infoPriceConsumtion: PropTypes.array,
    isDownloadingHistory: PropTypes.bool,
    isFetching: PropTypes.bool,
    isUpdatingConsumptionEnergy: PropTypes.bool,
    monthsToChartKw: PropTypes.array,
    monthsToChartKwh: PropTypes.array,
    onClickAddConsumption: PropTypes.func,
    prepareDowloadHistory: PropTypes.func,
    prepareUpdate: PropTypes.func,
    selectProject: PropTypes.func,
    selectedProject: PropTypes.object,
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withPermissions(PERMISSION_LIST.CONSUMPTION_HISTORY_PERMISSION)
)(Container);
