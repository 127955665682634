import { object, string } from 'yup';

import getValidationTexts from 'common/utils/getValidationTexts';

import { COLUMN_TYPES } from './constants';
import { getIsVisible } from './helpers';

export default (values, { columns, returnFormattedData }) => {
    const { REQUIRED_TEXT } = getValidationTexts();
    if (!columns?.length) return object();

    const getValues = () => values;

    return object().shape({
        columnsMatch: object().shape(
            columns?.reduce((acc, column) => {
                const { columnName, required } = column;
                if (required)
                    acc[columnName] = string().required(REQUIRED_TEXT);
                else acc[columnName] = string().nullable();
                return acc;
            }, {}) || {}
        ),
        columnsFormat: object().shape(
            columns?.reduce((acc, column) => {
                const { columnName, type, visible = true } = column;

                const isColumnVisible = getIsVisible(visible, getValues);
                if (
                    type &&
                    isColumnVisible &&
                    returnFormattedData &&
                    [COLUMN_TYPES.DATE, COLUMN_TYPES.TIME].includes(type)
                )
                    acc[columnName] = string().required(REQUIRED_TEXT);
                else acc[columnName] = string().nullable();

                return acc;
            }, {}) || {}
        ),
    });
};
