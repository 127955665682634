import React, { useContext, useState } from 'react';

import { styled } from '@mui/material/styles';
import toNumber from 'lodash/toNumber';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button } from 'sunwise-ui';

import { IntlNumberInputField } from 'common/components';
import { LoadingContext } from 'common/utils/contexts';

const Container = styled('form')`
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    justify-content: flex-end;
`;

const MassiveMargin = ({ onSubmit, visible = true }) => {
    const { t } = useTranslation();
    const loadingContext = useContext(LoadingContext);
    const [margin, setMargin] = useState('');

    const handleSubmit = (event) => {
        event.preventDefault();
        loadingContext.openLoading(
            t('Saving change', { count: 2 }).concat('...')
        );
        onSubmit(
            toNumber(margin),
            () => {
                setMargin('');
                loadingContext.closeLoading();
            },
            () => loadingContext.closeLoading()
        );
    };

    const onChangeMargin = (value) => setMargin(value);

    if (!visible) return null;
    return (
        <Container onSubmit={handleSubmit}>
            <IntlNumberInputField
                allowNegativeValue={false}
                append="%"
                max={99}
                min={0}
                onChange={onChangeMargin}
                step="any"
                sx={{ marginBottom: '0!important' }}
                value={margin}
            />

            <Button
                disabled={margin === '' || isNaN(toNumber(margin))}
                sx={{ marginBottom: '0!important' }}
                type="submit"
            >
                {t('Exchange price by margin')}
            </Button>
        </Container>
    );
};

MassiveMargin.propTypes = {
    onSubmit: PropTypes.func,
    visible: PropTypes.bool,
};

export default MassiveMargin;
