import React from 'react';

import DeleteIcon from '@mui/icons-material/Delete';
import DownloadIcon from '@mui/icons-material/Download';
import PropTypes from 'prop-types';
import { useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Box, Button, Grid } from 'sunwise-ui';

import { ReactHookFormSelect } from 'common/components/form/bootstrap';
import TitleWithDetail from 'common/components/TitleWithDetail';
import { handleFileURL } from 'common/utils/helpers';
import { getCountryCurrencyLocale } from 'common/utils/helpers/session';

import { getSourceOptions } from '../helpers';

import UploadFileButton from './UploadFileButton';

const GenerationFromFile = ({
    canModifySolarGenerationFile,
    control,
    fileInputRef,
    handleOnChange,
    handleOnClickDeleteFile,
    hasFile,
    initialValues,
    isLocked,
}) => {
    const { t } = useTranslation();
    const source = useWatch({ control, name: 'source' });
    const countryCurrencyLocale = getCountryCurrencyLocale();

    return (
        <Box px={2}>
            <TitleWithDetail variant="body2">
                {t('Generation')} - {t('From .csv file').toLocaleUpperCase()}
            </TitleWithDetail>

            <Grid container mt={1} alignItems="center">
                <Grid item xs={18} md={9} lg={6}>
                    <ReactHookFormSelect
                        control={control}
                        disabled={
                            !canModifySolarGenerationFile || hasFile || isLocked
                        }
                        label={t('Select a source')}
                        name="source"
                        options={getSourceOptions({
                            countryCurrencyLocale,
                            initialValues,
                        })}
                    />
                </Grid>

                <Grid
                    item
                    xs={18}
                    md={9}
                    lg={6}
                    order={{ xs: 3, md: 2 }}
                    sx={{
                        display: 'flex',
                        flexDirection: { xs: 'column', md: 'row' },
                        justifyContent: { xs: 'center', md: 'left' },
                    }}
                >
                    <UploadFileButton
                        control={control}
                        disabled={
                            !canModifySolarGenerationFile || isLocked || !source
                        }
                        fileInputRef={fileInputRef}
                        handleOnChange={handleOnChange}
                        hasFile={hasFile}
                    />
                    {hasFile && (
                        <Box>
                            <Button
                                color="secondary"
                                disabled={
                                    !canModifySolarGenerationFile || isLocked
                                }
                                onClick={() =>
                                    window.open(
                                        handleFileURL(
                                            initialValues.urlFile.replace(
                                                'sunwise-dev.s3.amazonaws.com/media/',
                                                ''
                                            ),
                                            process.env.REACT_APP_S3_MEDIA_PATH
                                        ),
                                        '_blank'
                                    )
                                }
                                startIcon={<DownloadIcon />}
                                sx={{
                                    width: { xs: '100%', md: 'auto' },
                                    mb: { xs: 2, md: 0 },
                                }}
                                type="button"
                                variant="text"
                            >
                                {t('Download file')}
                            </Button>
                            <Button
                                color="secondary"
                                disabled={
                                    !canModifySolarGenerationFile || isLocked
                                }
                                onClick={handleOnClickDeleteFile}
                                startIcon={<DeleteIcon />}
                                sx={{
                                    width: { xs: '100%', md: 'auto' },
                                    ml: { xs: 0, md: 2 },
                                }}
                                type="button"
                                variant="text"
                            >
                                {t('Delete')}
                            </Button>
                        </Box>
                    )}
                </Grid>
            </Grid>
        </Box>
    );
};

GenerationFromFile.propTypes = {
    canModifySolarGenerationFile: PropTypes.bool,
    control: PropTypes.object,
    fileInputRef: PropTypes.object,
    handleOnChange: PropTypes.func,
    handleOnClickDeleteFile: PropTypes.func,
    hasFile: PropTypes.bool,
    initialValues: PropTypes.object,
    isLocked: PropTypes.bool,
};

export default GenerationFromFile;
