import { createSelector } from 'reselect';

import { NAME } from './constants';

export const getModel = (state) => state[NAME];

export const getInitialValues = createSelector(
    getModel,
    (model) => model.initialValues
);

/**
 * Fetch product detail
 */

export const getProductDatasheets = createSelector(
    getModel,
    (model) => model.productDatasheets
);

export const getIsLoadingProductDatasheets = createSelector(
    getProductDatasheets,
    (productDatasheets) => productDatasheets.isLoading
);

export const getDataProductDatasheets = createSelector(
    getProductDatasheets,
    (productDatasheets) => productDatasheets.data || {}
);

export const getDatasheetsProductDatasheets = createSelector(
    getDataProductDatasheets,
    (data) => data.data_sheets || []
);

/**
 * Upload datasheets
 */

export const getUploadDatasheet = createSelector(
    getModel,
    (model) => model.uploadDatasheet
);

export const getIsUploadingDatasheet = createSelector(
    getUploadDatasheet,
    (uploadDatasheet) => uploadDatasheet.isLoading
);

export const getUploadDatasheetErrors = createSelector(
    getUploadDatasheet,
    (uploadDatasheet) => uploadDatasheet.errors
);

export const getElementToDelete = createSelector(
    getModel,
    (model) => model.elementToDelete
);

export const getIsVisibleForm = createSelector(
    getModel,
    (model) => model.isVisibleForm
);

export const getIsOpenConfirmModal = createSelector(
    getModel,
    (model) => model.isOpenConfirmModal
);

export const getDataConfirmModal = createSelector(
    getModel,
    (model) => model.dataConfirmModal || {}
);
