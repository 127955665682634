import { getItem } from 'common/api/v1/countries';

import {
    FETCH_COUNTRIES,
    FETCH_COUNTRIES_FAILURE,
    FETCH_COUNTRIES_SUCCESS,
} from '../actionTypes';
import { ratesActions } from '../reducer';

export default (id) => (dispatch) => {
    dispatch(ratesActions[FETCH_COUNTRIES]());
    getItem(id)
        .then((response) =>
            dispatch(ratesActions[FETCH_COUNTRIES_SUCCESS](response.data))
        )
        .catch((error) =>
            dispatch(ratesActions[FETCH_COUNTRIES_FAILURE](error))
        );
};
