import { getItem } from 'common/api/v1/integrationsEmbeddedProposals';
import { getSessionLocalStorage } from 'common/utils/helpers/session';

import {
    FETCH_DATA,
    FETCH_DATA_FAILURE,
    FETCH_DATA_SUCCESS,
} from '../actionTypes';
import { handleLists } from '../helpers';
import { integrationWebActions } from '../reducer';

import fetchTemplatesFilteredByBranch from './fetchTemplatesFilteredByBranch';
import initializeForm from './initializeForm';

export default (uuid, branchSelected) => (dispatch) => {
    const session = getSessionLocalStorage();
    dispatch(integrationWebActions[FETCH_DATA]());

    getItem(uuid)
        .then((response) => {
            dispatch(integrationWebActions[FETCH_DATA_SUCCESS](response.data));

            const values = {
                ...response.data,
                agent: session.id,
                branch_office: response.data.branch_office
                    ? response.data.branch_office
                    : branchSelected,
                service_number: { 1: response.data.with_service_number },
                method_consumtion: handleLists(response.data.method_consumtion),
                install_type: handleLists(response.data.install_type),
                style: response.data.styles,
            };

            dispatch(initializeForm(values));
            if (values.branch_office)
                dispatch(fetchTemplatesFilteredByBranch(values.branch_office));
        })
        .catch((error) =>
            dispatch(integrationWebActions[FETCH_DATA_FAILURE](error))
        );
};
