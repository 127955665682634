import React from 'react';

import { isEmpty, isNull } from 'lodash';
import PropTypes from 'prop-types';
import { Typography } from 'sunwise-ui';

import {
    getCurrencyIso,
    getCurrencyLocale,
    getCurrencySymbol,
    numberFormat,
} from 'common/utils/helpers';

const DollarPriceItem = ({ oppositeCurrency }) => {
    if (isEmpty(oppositeCurrency) || isNull(oppositeCurrency)) return null;
    const currencyIso = getCurrencyIso(oppositeCurrency);
    const currencyLocale = getCurrencyLocale(oppositeCurrency);
    const currencySymbol = getCurrencySymbol(oppositeCurrency);
    return (
        <Typography variant="body2" noWrap component="div">
            $1 USD ={' '}
            {numberFormat(oppositeCurrency.dollar_price, {
                currency: currencyIso,
                locale: currencyLocale,
                style: 'currency',
                symbol: currencySymbol,
            })}
        </Typography>
    );
};

DollarPriceItem.propTypes = { oppositeCurrency: PropTypes.object };

export default DollarPriceItem;
