import React, { useEffect } from 'react';

import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { ShowErrors } from 'common/components';
import yupResolver from 'common/utils/yupResolver';

import * as actions from '../actions';
import * as selectors from '../selectors';
import validate from '../validate';

import Generation from './Generation';

const FormContainer = ({
    anualSolarGeneration,
    averageIrradiation,
    canModifySolarGeneration,
    canModifySolarGenerationFile,
    configCsvImporter,
    errors,
    initialValues,
    isLocked,
    kwhValue,
    onSubmit,
    prepareDeleteFile,
    proposalId,
    setConfigCsvImporter,
    sunHours,
    systemSize,
}) => {
    const { control, getValues, handleSubmit, reset, setValue } = useForm({
        defaultValues: initialValues,
        resolver: yupResolver(validate),
    });

    useEffect(() => reset(initialValues), [initialValues]);

    return (
        <form onSubmit={(e) => e.preventDefault()}>
            <Generation
                anualSolarGeneration={anualSolarGeneration}
                averageIrradiation={averageIrradiation}
                canModifySolarGeneration={canModifySolarGeneration}
                canModifySolarGenerationFile={canModifySolarGenerationFile}
                configCsvImporter={configCsvImporter}
                control={control}
                getValues={getValues}
                handleSubmit={handleSubmit(onSubmit)}
                initialValues={initialValues}
                isLocked={isLocked}
                kwhValue={kwhValue}
                prepareDeleteFile={prepareDeleteFile}
                proposalId={proposalId}
                setConfigCsvImporter={setConfigCsvImporter}
                setValue={setValue}
                sunHours={sunHours}
                systemSize={systemSize}
            />

            {errors && errors.length ? <ShowErrors errors={errors} /> : null}
        </form>
    );
};

const mapStateToProps = createStructuredSelector({
    configCsvImporter: selectors.getConfigCsvImporter,
    errors: selectors.getErrorsSave,
    initialValues: selectors.getInitialValues,
});

const mapDispatchToProps = (dispatch) => ({
    prepareDeleteFile: (proposalId, values) =>
        dispatch(actions.prepareDeleteFile(proposalId, values)),
    setConfigCsvImporter: (configCsvImporter) =>
        dispatch(actions.setConfigCsvImporter(configCsvImporter)),
});

FormContainer.propTypes = {
    anualSolarGeneration: PropTypes.number,
    averageIrradiation: PropTypes.number,
    canModifySolarGeneration: PropTypes.bool,
    canModifySolarGenerationFile: PropTypes.bool,
    configCsvImporter: PropTypes.object,
    errors: PropTypes.array,
    initialValues: PropTypes.object,
    isLocked: PropTypes.bool,
    kwhValue: PropTypes.number,
    onSubmit: PropTypes.func,
    prepareDeleteFile: PropTypes.func,
    proposalId: PropTypes.string,
    setConfigCsvImporter: PropTypes.func,
    sunHours: PropTypes.number,
    systemSize: PropTypes.number,
};

export default connect(mapStateToProps, mapDispatchToProps)(FormContainer);
