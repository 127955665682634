export const getSizeColumn = (
    canModifyCost,
    canModifyDiscount,
    canModifyMargin
) => {
    // I can see discount and margin but I can't see cost
    if (!canModifyCost && canModifyDiscount && canModifyMargin) {
        return {
            quantity: 3,
            model: 15,
            cost: [0],
            margin: [9],
            unit_price: [9],
            discount: [18],
        };
        // I can see cost and discount but I can't see margin
    } else if (canModifyCost && canModifyDiscount && !canModifyMargin) {
        return {
            quantity: 3,
            model: 15,
            cost: [9],
            margin: [0],
            unit_price: [9],
            discount: [18],
        };
        // I can see margin but I can't see cost and discount
    } else if (!canModifyCost && !canModifyDiscount && canModifyMargin) {
        return {
            quantity: 3,
            model: 15,
            cost: [0],
            margin: [9],
            unit_price: [9],
            discount: [0],
        };
        // I can see cost but I can't see discount and margin
    } else if (canModifyCost && !canModifyDiscount && !canModifyMargin) {
        return {
            quantity: 3,
            model: 15,
            cost: [9],
            margin: [0],
            unit_price: [9],
            discount: [0],
        };
        // I can see discount but I can't see cost and margin
    } else if (!canModifyCost && canModifyDiscount && !canModifyMargin) {
        return {
            quantity: 3,
            model: 15,
            cost: [0],
            margin: [0],
            unit_price: [9],
            discount: [9],
        };
        // I can see cost and margin but I can't see discount
    } else if (canModifyCost && !canModifyDiscount && canModifyMargin) {
        return {
            quantity: 3,
            model: 15,
            cost: [9],
            margin: [9],
            unit_price: [18],
            discount: [0],
        };
        // I can't see cost, discount and margin
    } else if (!canModifyCost && !canModifyDiscount && !canModifyMargin) {
        return {
            quantity: 3,
            model: 15,
            cost: [0],
            margin: [0],
            unit_price: [18],
            discount: [0],
        };
        // I cant see cost, discount and margin
    } else {
        return {
            quantity: 3,
            model: 15,
            cost: [9],
            margin: [9],
            unit_price: [9],
            discount: [9],
        };
    }
};
