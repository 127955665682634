import { getItems } from 'common/api/v1/suppliers';

import {
    FETCH_SUPPLIERS,
    FETCH_SUPPLIERS_FAILURE,
    FETCH_SUPPLIERS_SUCCESS,
} from '../actionTypes';
import { energyBackupConfigurationActions } from '../reducer';

export default () => (dispatch) => {
    dispatch(energyBackupConfigurationActions[FETCH_SUPPLIERS]());

    getItems()
        .then((response) =>
            dispatch(
                energyBackupConfigurationActions[FETCH_SUPPLIERS_SUCCESS](
                    response.data.data
                )
            )
        )
        .catch((error) =>
            dispatch(
                energyBackupConfigurationActions[FETCH_SUPPLIERS_FAILURE](error)
            )
        );
};
