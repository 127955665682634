import get from 'lodash/get';

import { filterNotifications } from 'common/api/v1/notifications';

import {
    FETCH_NOTIFICATIONS,
    FETCH_NOTIFICATIONS_FAILURE,
    FETCH_NOTIFICATIONS_SUCCESS,
} from '../actionTypes';
import { notificationsActions } from '../reducer';
import * as selectors from '../selectors';

export default ({ page = 1, pageSize, showMentions, type, viewed } = {}) =>
    (dispatch, getState) => {
        const existingData = selectors.getNotificationsData(getState());
        dispatch(notificationsActions[FETCH_NOTIFICATIONS]());

        filterNotifications({
            page,
            perPage: pageSize,
            showMentions,
            type,
            viewed,
        })
            .then((response) => {
                const { data, pagination = {} } = get(response, 'data', {});
                const totalItems = get(pagination, 'total_rows', 0);
                const itemsPerPage = get(pagination, 'per_page', 1);
                const totalPages = Math.ceil(totalItems / itemsPerPage);
                const groupedData = data.map((group) => {
                    const { date, notifications } = group;
                    const notificationsWithDate = notifications.map(
                        (notification) => ({
                            ...notification,
                            grouping_date: date,
                            is_checked: false,
                        })
                    );
                    return {
                        date,
                        notifications: notificationsWithDate,
                    };
                });
                dispatch(
                    notificationsActions[FETCH_NOTIFICATIONS_SUCCESS]({
                        pagination: {
                            ...pagination,
                            total_pages: totalPages,
                        },
                        data: [...existingData, ...groupedData],
                    })
                );
            })
            .catch((error) =>
                dispatch(
                    notificationsActions[FETCH_NOTIFICATIONS_FAILURE](error)
                )
            );
    };
