export const NAME = 'after-sales-settings';

export const DEFAULT_SERVICE_NUMBER_MASK = '*** *** *** *** *** *** *** ***';

export const AFTERSALES_CHARGEBEE_ADDON = 'Post-Venta-USD-Yearly';

export const DATE_FORMATS = {
    AFTER_SALES: 'yyyy-MM-dd',
    PERIOD_LABEL: 'MMM yy',
    PERIOD_LABEL_2: 'dd/MMM',
    SUNWISE: 'dd/MM/yyyy',
};

export const DEFAULT_DATE_FORMAT = DATE_FORMATS.SUNWISE;

export const MIN_DATE = new Date(2005, 0, 1);

export const SUBSIDY_RATES = [
    { label: '1', value: '1' },
    { label: '1A', value: '1A' },
    { label: '1B', value: '1B' },
    { label: '1C', value: '1C' },
    { label: '1D', value: '1D' },
    { label: '1E', value: '1E' },
    { label: '1F', value: '1F' },
];

export const PERIODICITY_TYPES = {
    Monthly: '0',
    Bimonthly: '1',
};

export const PERIODICITY_TEXTS = {
    [PERIODICITY_TYPES.Monthly]: 'Monthly',
    [PERIODICITY_TYPES.Bimonthly]: 'Bimonthly',
};
