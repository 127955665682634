import { getItemsByParent } from 'common/api/v1/politicalDivisions';

import {
    FETCH_POLITICAL_DIVISIONS2,
    FETCH_POLITICAL_DIVISIONS2_FAILURE,
    FETCH_POLITICAL_DIVISIONS2_SUCCESS,
} from '../actionTypes';
import { actions } from '../reducer';

export default (str) => (dispatch) =>
    new Promise((resolve) => {
        dispatch(actions[FETCH_POLITICAL_DIVISIONS2]());
        getItemsByParent(str)
            .then(({ data }) =>
                dispatch(
                    actions[FETCH_POLITICAL_DIVISIONS2_SUCCESS](data?.data)
                )
            )
            .catch((error) =>
                dispatch(
                    actions[FETCH_POLITICAL_DIVISIONS2_FAILURE](
                        error?.response?.data?.errors
                    )
                )
            )
            .finally(resolve);
    });
