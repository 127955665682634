import isNull from 'lodash/isNull';

import { INITIAL_VALUES } from '../actionTypes';
import { proposalGeneratorTaxesTableActions } from '../reducer';

export default (data) => (dispatch) => {
    const items = data.map((tax) => {
        return {
            amount: tax.amount,
            name: tax.name,
            value: tax.value,
            percentage_value: tax.value,
            is_percentage: tax.is_percentage,
            id: tax.id,
            elements: isNull(tax.taxes_offers_elements)
                ? []
                : tax.taxes_offers_elements,
        };
    });

    dispatch(
        proposalGeneratorTaxesTableActions[INITIAL_VALUES]({
            items,
        })
    );
};
