import React from 'react';

import PropTypes from 'prop-types';
import { Box, Tooltip } from 'sunwise-ui';

import ProposalStatusIcon from 'common/components/ProposalStatusIcon';
import { getStatusProposalLabel } from 'common/utils/helpers';

const StatusIndicatorTooltip = ({ isApproved, isGenerated }) => (
    <Tooltip
        placement="top"
        title={getStatusProposalLabel(isApproved, isGenerated)}
    >
        <Box>
            <ProposalStatusIcon
                isApproved={isApproved}
                isGenerated={isGenerated}
            />
        </Box>
    </Tooltip>
);

StatusIndicatorTooltip.propTypes = {
    isApproved: PropTypes.bool,
    isGenerated: PropTypes.bool,
};

export default StatusIndicatorTooltip;
