import i18next from 'i18next';
import cloneDeep from 'lodash/cloneDeep';

import alerts from 'common/modules/alerts';
import { parseDate, subDate } from 'common/utils/dates';
import { calcEnergyDistributionPercentages } from 'common/utils/helpers/rates';

import { DATE_FORMATS } from '../constants';
import {
    formatDateDefault,
    formatDateLabel,
    getHasPredictedValues,
    handleFormatDates,
    handleNormalizeFields,
    round,
} from '../helpers';
import * as selectors from '../selectors';

import prepareConsumptions from './prepareConsumptions';
import resetConsumptionProfile from './resetConsumptionProfile';

export default ({ formValues, rateConfiguration }) =>
    (dispatch, getState) => {
        const state = getState();
        const consumptionsRawData = selectors.getConsumptionsRawData(state);

        const data = cloneDeep(consumptionsRawData);
        const ratesDictionary = selectors.getRatesDictionary(state);
        const ratesNameIdDictionary =
            selectors.getCertifiedRatesNameIdDictionary(state);
        const isBimonthly = data.is_bimonthly ? '1' : '0';

        let newValues = {
            ...formValues,
            address: data.address,
            consumption_profile: null,
            contracted_demand: parseFloat(data.contracted_demand),
            file_to_download: data.file,
            holder: data.name,
            is_bimonthly: isBimonthly,
            periodicity_type: parseInt(isBimonthly),
            political_division1:
                formValues.political_division1 || data.political_division,
            rate: ratesNameIdDictionary[data.rate],
            rate_division_summer: data.rate_division_summer,
            receipt_origin: data.receipt_origin,
            season_change_pdf: data.season_change_pdf,
            service_number: data.service_number,
            zip_code: formValues.zip_code || data.zip_code,
        };

        let summary = [];
        const orderedCurrentValues = data.values.sort(
            (a, b) => parseInt(b.dateFrom) - parseInt(a.dateFrom)
        );
        orderedCurrentValues.forEach((item, index) => {
            const initialDate = formatDateDefault(
                parseDate(item.dateFrom, DATE_FORMATS.SCRAPPER)
            );
            const parseFinalDate = parseDate(
                orderedCurrentValues[index - 1]?.dateFrom ?? item.dateTo,
                DATE_FORMATS.SCRAPPER
            );
            const finalDate = formatDateDefault(parseFinalDate);
            const label = formatDateLabel(
                subDate(parseFinalDate, { days: data.is_bimonthly ? 30 : 15 })
            );

            const baseKw = round(item.monthly_service?.[0]?.base_kw);
            const baseKwh = Number(round(item.monthly_service?.[0]?.base_kwh));
            const midKw = round(item.monthly_service?.[0]?.middle_kw);
            const midKwh = Number(round(item.monthly_service?.[0]?.middle_kwh));
            const peakKw = round(
                item.monthly_service?.[0]?.peak_kw || item?.kw
            );
            const peakKwh = Number(round(item.monthly_service?.[0]?.peak_kwh));
            const totalKwh = baseKwh + midKwh + peakKwh || round(item.kwh);

            const period = {
                file: item.file || null,
                final_date: finalDate,
                initial_date: initialDate,
                kW: {
                    base: {
                        label: i18next.t('Base'),
                        placeholder: baseKw.toString() || '0',
                        tier: 0,
                        value: baseKw || null,
                    },
                    middle: {
                        label: i18next.t('Middle', { context: 'rate' }),
                        placeholder: midKw.toString() || '0',
                        tier: 1,
                        value: midKw || null,
                    },
                    peak: {
                        label: i18next.t('Peak'),
                        placeholder: peakKw.toString() || '0',
                        tier: 2,
                        value: peakKw || null,
                    },
                },
                kWh: {
                    base: {
                        label: i18next.t('Base'),
                        placeholder: baseKwh.toString() || '0',
                        tier: 0,
                        value: baseKwh || null,
                    },
                    middle: {
                        label: i18next.t('Middle', { context: 'rate' }),
                        placeholder: midKwh.toString() || '0',
                        tier: 1,
                        value: midKwh || null,
                    },
                    peak: {
                        label: i18next.t('Peak'),
                        placeholder: peakKwh.toString() || '0',
                        tier: 2,
                        value: peakKwh || null,
                    },
                },
                season_change_pdf:
                    index === 0 && data.season_change_pdf
                        ? data.season_change_pdf
                        : item.season_change_pdf,
                total: {
                    placeholder: totalKwh.toString() || '0',
                    value: totalKwh || '0',
                },
                label,
                power_factor: item?.fp || 90,
                predicted: item.predicted,
            };
            summary.push(period);
        });
        const normalizedSummary = ['GDMTH', 'DIT', 'DIST'].includes(data.rate)
            ? handleFormatDates({ isBimonthly: data.is_bimonthly, summary })
            : summary;
        const fixedNormalizedSummary = normalizedSummary.map(
            (period, index) => {
                if (index > 1) return period;
                if (index === 1)
                    return { ...period, final_date: summary[0].initial_date };
                return {
                    ...period,
                    final_date: summary[0].final_date,
                    initial_date: summary[0].initial_date,
                };
            }
        );

        const summaryWithNormalizedFields = handleNormalizeFields({
            discardValues: true,
            rate: ratesDictionary[newValues.rate],
            rateConfiguration,
            summary: fixedNormalizedSummary,
        });

        newValues.summary = summaryWithNormalizedFields;
        newValues.last_consumption = newValues.summary[0].final_date;

        newValues.distribution = calcEnergyDistributionPercentages(
            summaryWithNormalizedFields
        );

        if (data.rare_month) {
            newValues.rare_month = data.rare_month;
            if (data.rare_month === 1)
                dispatch(
                    alerts.actions.show({
                        confirmText: i18next.t('Back'),
                        hideButtons: false,
                        messages: [
                            i18next.t(
                                'The receipt entered does not contain a complete history, we recommend entering the rest of the consumptions manually'
                            ),
                        ],
                        onSuccess: () => {
                            dispatch(alerts.actions.close());
                        },
                        title: i18next.t('Alert'),
                        type: alerts.ALERT_TYPE_ALERT,
                        variant: 'warning',
                    })
                );
        }

        if (newValues.summary[0]?.file)
            newValues.file = newValues.summary[0]?.file;

        if (formValues?.consumption_profile)
            dispatch(resetConsumptionProfile());
        dispatch(
            prepareConsumptions({
                automaticHasChanges: getHasPredictedValues(
                    summaryWithNormalizedFields
                )
                    ? true
                    : null,
                initialValues: newValues,
            })
        );
    };
