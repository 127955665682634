import React from 'react';

import { useTheme } from '@mui/material/styles';
import { styled } from '@mui/material/styles';
import { push } from 'connected-react-router';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroller';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Box, Card, Grid, Typography } from 'sunwise-ui';

import DropdownCardMenu from 'common/components/cards/DropdownCardMenu';
import { COMMERCIAL_OFFER_STATUS } from 'common/constants/commercialOffer';
import { scroll } from 'common/utils/mixins';

import BranchOfficeTooltip from '../../projectCommercialOfferTable/components/BranchOfficeTooltip';
import DateTooltip from '../../projectCommercialOfferTable/components/DateTooltip';
import StatusIndicatorTooltip from '../../projectCommercialOfferTable/components/StatusIndicatorTooltip';
import * as actions from '../actions';
import * as selectors from '../selectors';

const ListContainer = styled(Box)`
    max-height: 400px;
    overflow-y: auto;
    padding: 0 8px;
    width: 100%;

    ${scroll.custom()}
`;

const ItemsList = ({
    canDelete,
    canModify,
    fetchData,
    isLoading,
    items,
    paginationData,
    prepareDelete,
    prepareUpdate,
    projectId,
    redirect,
    setModalFormOpenStatus,
    year,
}) => {
    const handleOnClickEdit = () => setModalFormOpenStatus(true);
    const { t } = useTranslation();
    const theme = useTheme();
    const sxCard = {
        backgroundColor: theme.palette.mode === 'dark' ? '#282b30' : '',
    };
    const sxBox = {
        cursor: 'pointer',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
    };

    return (
        <ListContainer>
            <InfiniteScroll
                hasMore={!isLoading && paginationData?.hasMore}
                loader={
                    <div className="scroll-loader" key={0}>
                        {t('Loading').concat('...')}
                    </div>
                }
                loadMore={(nextPage) => {
                    if (isLoading) return;
                    fetchData({ page: nextPage, projectId, year });
                }}
                pageStart={1}
                threshold={100}
                useWindow={false}
            >
                {items.map((item) => {
                    const { created_at, id, name, status } = item;
                    const isGenerated =
                        status === COMMERCIAL_OFFER_STATUS.FINISHED.key;

                    return (
                        <Card key={`report-item-${id}`} my={2} sx={sxCard}>
                            <Card.Body>
                                <Grid container spacing={0}>
                                    <Grid item lg={1} xs={2}>
                                        <StatusIndicatorTooltip
                                            isGenerated={isGenerated}
                                        />
                                    </Grid>

                                    <Grid
                                        item
                                        lg={15}
                                        onClick={() => {
                                            if (isGenerated)
                                                redirect(
                                                    `/report-review-pro/${id}`
                                                );
                                            else redirect(`/report/${id}`);
                                        }}
                                        sx={sxBox}
                                        xs={14}
                                    >
                                        <Typography
                                            variant="body2"
                                            fontWeight="bold"
                                            mb={1}
                                        >
                                            {name}
                                        </Typography>

                                        <Box
                                            display="flex"
                                            alignItems="center"
                                            gap={1}
                                        >
                                            <DateTooltip
                                                createdAt={`${created_at} 00:00:00`}
                                            />

                                            <BranchOfficeTooltip
                                                branchOffice={
                                                    item?.branch_office
                                                }
                                            />
                                        </Box>
                                    </Grid>

                                    <Grid item xs={2} textAlign="right">
                                        <DropdownCardMenu
                                            items={[
                                                {
                                                    handleClick: () =>
                                                        prepareUpdate(
                                                            item,
                                                            handleOnClickEdit
                                                        ),
                                                    label: t('Rename'),
                                                    visible: canModify,
                                                },
                                                {
                                                    handleClick: () =>
                                                        prepareDelete(
                                                            item,
                                                            projectId
                                                        ),
                                                    label: t('Delete'),
                                                    visible: canDelete,
                                                },
                                            ]}
                                            visible={canModify || canDelete}
                                        />
                                    </Grid>
                                </Grid>
                            </Card.Body>
                        </Card>
                    );
                })}
            </InfiniteScroll>
        </ListContainer>
    );
};

ItemsList.propTypes = {
    canDelete: PropTypes.bool,
    canModify: PropTypes.bool,
    fetchData: PropTypes.func,
    isLoading: PropTypes.bool,
    items: PropTypes.array,
    paginationData: PropTypes.object,
    prepareDelete: PropTypes.func,
    prepareUpdate: PropTypes.func,
    projectId: PropTypes.string,
    redirect: PropTypes.func,
    setModalFormOpenStatus: PropTypes.func,
    year: PropTypes.string,
};

const mapStateToProps = createStructuredSelector({
    isLoading: selectors.getFetchDataIsLoading,
    paginationData: selectors.getFetchDataPagination,
});

const mapDispatchToProps = (dispatch) => ({
    fetchData: (params) => dispatch(actions.fetchData(params)),
    prepareDelete: (report, projectId) =>
        dispatch(actions.prepareDelete(report, projectId)),
    prepareUpdate: (item, successCallback) =>
        dispatch(actions.prepareUpdate(item, successCallback)),
    redirect: (link) => dispatch(push(link)),
    setModalFormOpenStatus: (value) =>
        dispatch(actions.setModalFormOpenStatus(value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ItemsList);
