import React, { useState } from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Card, Divider, Grid, Typography } from 'sunwise-ui';

import { ReactHookFormSelect } from 'common/components/form/bootstrap';
import TitleWithDetail from 'common/components/TitleWithDetail';
import csvImporter from 'common/modules/csvImporter';
import { getIsMexicanAccount } from 'common/utils/helpers/session';

import { createGenerationProfile } from '../helpers';

import ChartComponent from './ChartComponent';
import GenerationProfileForm from './GenerationProfileForm';
import TableConfig from './TableConfig';

const IrradiationCard = ({
    canDelete,
    canModify,
    control,
    countryCurrencyLocale,
    defaultSourceData,
    deleteGenerationProfile,
    generationProfile,
    generationProfileYear,
    getAvg,
    getValues,
    handleSelectPoliticalDivision,
    handleSelectPoliticalDivision2,
    initializeGenerationProfileForm,
    isPoliticalDivision,
    isPoliticalDivision2,
    politicalDivisionsForSelect,
    politicalDivisions2ForSelect,
    selectedSourceName,
    series,
}) => {
    const [isOpenCsvImporter, setIsOpenCsvImporter] = useState(false);
    const { t } = useTranslation();
    const isMexicanAccount = getIsMexicanAccount();

    const handleOpenCsvImporter = () => setIsOpenCsvImporter(true);

    return (
        <>
            <Card>
                <Card.Body>
                    <Grid container>
                        <Grid item xs>
                            <TitleWithDetail variant="body2">
                                {isMexicanAccount
                                    ? t('Select the state and municipality')
                                    : t(
                                          'Select political division of order 1 and political division of order 2'
                                      )}
                            </TitleWithDetail>
                        </Grid>
                    </Grid>

                    <Divider sx={{ my: 2 }} />

                    <Grid container id="generation-political-divisions">
                        <Grid item xs md={9}>
                            <ReactHookFormSelect
                                control={control}
                                label={
                                    isMexicanAccount
                                        ? t('State')
                                        : t('Political division of order 1')
                                }
                                name="political_division"
                                onChange={handleSelectPoliticalDivision}
                                options={politicalDivisionsForSelect}
                            />
                        </Grid>
                        <Grid item xs md={9}>
                            <ReactHookFormSelect
                                control={control}
                                label={
                                    isMexicanAccount
                                        ? t('City')
                                        : t('Political division of order 2')
                                }
                                name="political_division2"
                                onChange={handleSelectPoliticalDivision2}
                                options={politicalDivisions2ForSelect}
                            />
                        </Grid>
                    </Grid>

                    {isPoliticalDivision && isPoliticalDivision2 && (
                        <>
                            <Divider sx={{ my: 2 }} />

                            <TitleWithDetail variant="body2">
                                {t('Solar irradiance graph')}
                            </TitleWithDetail>

                            <Typography variant="caption">
                                {t('Visualize and compare solar irradiance')}
                            </Typography>

                            <Grid container justifyContent="center" mt={1}>
                                <Grid item xs={18}>
                                    <ChartComponent
                                        canDelete={canDelete}
                                        canModify={canModify}
                                        countryCurrencyLocale={
                                            countryCurrencyLocale
                                        }
                                        deleteGenerationProfile={
                                            deleteGenerationProfile
                                        }
                                        generationProfile={generationProfile}
                                        generationProfileYear={
                                            generationProfileYear
                                        }
                                        getValues={getValues}
                                        handleOpenCsvImporter={
                                            handleOpenCsvImporter
                                        }
                                        politicalDivisions={
                                            politicalDivisionsForSelect
                                        }
                                        series={series}
                                    />
                                </Grid>
                            </Grid>
                        </>
                    )}
                </Card.Body>
            </Card>

            {defaultSourceData &&
                isPoliticalDivision &&
                isPoliticalDivision2 && (
                    <Card>
                        <Card.Body>
                            <Grid container>
                                <Grid item xs={18}>
                                    <TitleWithDetail variant="body2">
                                        {t('Solar irradiation table')}
                                    </TitleWithDetail>
                                    <Typography variant="caption">
                                        {t('Generation source')}:{' '}
                                        {selectedSourceName}
                                    </Typography>
                                </Grid>
                            </Grid>

                            <Divider sx={{ my: 2 }} />

                            <TableConfig
                                countryCurrencyLocale={countryCurrencyLocale}
                                getAvg={getAvg}
                                isDefault={true}
                                sourceData={defaultSourceData}
                            />
                        </Card.Body>
                    </Card>
                )}

            <GenerationProfileForm
                countryCurrencyLocale={countryCurrencyLocale}
                getValues={getValues}
                politicalDivisionsForSelect={politicalDivisionsForSelect}
                series={series}
            />

            <csvImporter.Container
                buttonProps={{ sx: { width: { xs: '100%', sm: 'auto' } } }}
                buttonText={t('Add')}
                columns={[
                    {
                        columnName: 'date',
                        displayLabel: t('Date'),
                        required: true,
                        shouldFormat: false,
                        type: csvImporter.COLUMN_TYPES.DATE,
                        matchAliases: ['fecha', 'timestamp'],
                    },
                    {
                        columnName: 'time',
                        displayLabel: t('Time'),
                        matchAliases: ['hora', 'time'],
                        shouldFormat: false,
                        type: csvImporter.COLUMN_TYPES.TIME,
                        visible: (_, columnsFormat) =>
                            columnsFormat?.date &&
                            !columnsFormat.date.includes('HH'),
                    },
                    {
                        columnName: 'generation',
                        displayLabel: t('Generation'),
                        matchAliases: [
                            'energia',
                            'energy',
                            'generacion',
                            'generation',
                            'irradiation',
                            'power',
                            'radiacion',
                            'solar',
                            'watts',
                        ],
                        required: true,
                        type: csvImporter.COLUMN_TYPES.NUMBER,
                        priority: 1,
                    },
                ]}
                downloadExampleText={t('Download the sample CSV')}
                onCancel={() => setIsOpenCsvImporter(false)}
                onClickDownloadExampleFile={() =>
                    window.open(
                        `${process.env.REACT_APP_ACADEMY_URL}es/articles/6953502-perfil-de-generacion`,
                        '_blank'
                    )
                }
                onImport={(result, config) => {
                    const { name, profile, profileFormatted, year } =
                        createGenerationProfile(result, config);
                    setIsOpenCsvImporter(false);

                    initializeGenerationProfileForm({
                        generation: profile,
                        generation_profile: profileFormatted,
                        name,
                        political_division1: getValues('political_division'),
                        year,
                    });
                }}
                openModal={isOpenCsvImporter}
                returnFormattedData
                visible={false}
            />
        </>
    );
};

IrradiationCard.propTypes = {
    canDelete: PropTypes.bool,
    canModify: PropTypes.bool,
    control: PropTypes.object,
    countryCurrencyLocale: PropTypes.string,
    defaultSourceData: PropTypes.array,
    deleteGenerationProfile: PropTypes.func,
    generationProfile: PropTypes.object,
    generationProfileYear: PropTypes.number,
    getAvg: PropTypes.func,
    getValues: PropTypes.func,
    handleSelectPoliticalDivision: PropTypes.func,
    handleSelectPoliticalDivision2: PropTypes.func,
    initializeGenerationProfileForm: PropTypes.func,
    isPoliticalDivision: PropTypes.bool,
    isPoliticalDivision2: PropTypes.bool,
    politicalDivisionsForSelect: PropTypes.array,
    politicalDivisions2ForSelect: PropTypes.array,
    selectedSourceName: PropTypes.string,
    series: PropTypes.array,
};

export default IrradiationCard;
