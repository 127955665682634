import { get } from 'react-hook-form';

const setCustomValidity = (ref, fieldPath, errors) => {
    if (ref && 'reportValidity' in ref) {
        const error = get(errors, fieldPath);
        ref.setCustomValidity((error && error.message) || '');
        ref.reportValidity();
    }
};

export const validateFieldsNatively = (errors, options) => {
    for (const fieldPath in options.fields) {
        const field = options.fields[fieldPath];
        if (field && field.ref && 'reportValidity' in field.ref)
            setCustomValidity(field.ref, fieldPath, errors);
        else if (field.refs)
            field.refs.forEach((ref) =>
                setCustomValidity(ref, fieldPath, errors)
            );
    }
};
