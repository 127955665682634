import React, { useEffect, useState } from 'react';

import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import * as propsosalGeneratorOneColumSelectors from '../proposalGeneratorOneColumn/selectors';

import * as actions from './actions';
import { Card } from './components';
import * as selectors from './selectors';

const Container = ({
    canModify,
    initialize,
    isLoading,
    isLocked,
    isSaving,
    customerEnergyData,
    dataOfferConfigConsumption,
    proposalId,
    resetForm,
    save,
}) => {
    const [annualKWh, setAnnualKWh] = useState(0);
    const [dailyAverageKWh, setDailyAverageKWh] = useState(0);

    useEffect(() => {
        return () => resetForm();
    }, []);

    useEffect(() => {
        if (!isEmpty(customerEnergyData)) {
            const consumption = get(
                dataOfferConfigConsumption,
                'consumption',
                0
            );
            const avgData = customerEnergyData.average_consumption;
            const delta = 1 + consumption / 100;

            setAnnualKWh(avgData.consumption_annual);
            setDailyAverageKWh(avgData.daily_avg_consumption);

            initialize({
                annual_kWh: avgData.consumption_annual * delta,
                consumption,
                consumption_percentage: consumption,
                daily_average_kWh: avgData.daily_avg_consumption * delta,
            });
        }
    }, [customerEnergyData]);

    const onSubmit = (values) => save(proposalId, values);

    return (
        <Card
            annualKWh={annualKWh}
            customerEnergyData={customerEnergyData}
            dailyAverageKWh={dailyAverageKWh}
            isLocked={!canModify || isLoading || isLocked || isSaving}
            onSubmit={onSubmit}
            proposalId={proposalId}
        />
    );
};

const mapStateToProps = createStructuredSelector({
    customerEnergyData:
        propsosalGeneratorOneColumSelectors.getCustomerEnergyData,
    dataOfferConfigConsumption:
        propsosalGeneratorOneColumSelectors.getDataOfferConfigConsumption,
    isSaving: selectors.getIsSaving,
});

const mapDispatchToProps = (dispatch) => ({
    initialize: (values) => dispatch(actions.initialValues(values)),
    resetForm: () => dispatch(actions.resetForm()),
    save: (proposalId, values) => dispatch(actions.save(proposalId, values)),
});

Container.propTypes = {
    canModify: PropTypes.bool,
    customerEnergyData: PropTypes.object,
    dataOfferConfigConsumption: PropTypes.object,
    initialize: PropTypes.func,
    isLoading: PropTypes.bool,
    isLocked: PropTypes.bool,
    isSaving: PropTypes.bool,
    proposalId: PropTypes.string,
    resetForm: PropTypes.func,
    save: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(Container);
