import React from 'react';

import PropTypes from 'prop-types';
import { useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Box } from 'sunwise-ui';

import {
    ReactHookFormCheck,
    ReactHookFormIntlNumberInput,
} from 'common/components/form/bootstrap';
import TitleWithDetail from 'common/components/TitleWithDetail';
import { getCompensationHasNettedExportedGeneration } from 'common/utils/helpers/rates';

const ExportedEnergyFields = ({
    compensationScheme,
    control,
    currencySymbol,
    hasOfferProfiles,
    isLocked,
}) => {
    const { t } = useTranslation();
    const zeroExport = useWatch({ control, name: 'zero_export' });

    const hasNettedExportedGeneration =
        getCompensationHasNettedExportedGeneration(compensationScheme);

    return (
        <>
            <Box sx={{ mb: 2 }}>
                <TitleWithDetail variant="body2">
                    {t('Exported solar generation')}
                </TitleWithDetail>
            </Box>

            <Box
                alignItems="center"
                display="flex"
                flexWrap={{ xs: 'wrap', md: 'nowrap' }}
                gap={1}
            >
                {hasOfferProfiles && (
                    <ReactHookFormCheck
                        control={control}
                        disabled={isLocked}
                        label={t('Zero export')}
                        name="zero_export"
                        sxFormControl={{ flexShrink: 0 }}
                    />
                )}

                <ReactHookFormIntlNumberInput
                    control={control}
                    disabled={isLocked || zeroExport}
                    fullWidth
                    label={
                        hasNettedExportedGeneration
                            ? t('Excess exported generation price')
                            : t('Price')
                    }
                    name="energy_export_price"
                    prepend={currencySymbol}
                />

                {hasNettedExportedGeneration && (
                    <ReactHookFormIntlNumberInput
                        control={control}
                        disabled={isLocked || zeroExport}
                        fullWidth
                        label={t('Netted exported generation price')}
                        name="netted_exported_generation_price"
                        prepend={currencySymbol}
                    />
                )}
            </Box>
        </>
    );
};

ExportedEnergyFields.propTypes = {
    compensationScheme: PropTypes.string,
    control: PropTypes.object,
    currencySymbol: PropTypes.string,
    hasOfferProfiles: PropTypes.bool,
    isLocked: PropTypes.bool,
};

export default ExportedEnergyFields;
