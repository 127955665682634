import i18next from 'i18next';
import { isEqual, isNaN, isNull } from 'lodash';

import { MAX_MARGIN_PERCENTAGE } from 'common/constants';
import { getPriceByWatt, numberFormat } from 'common/utils/helpers';
import showToast from 'common/utils/showToast';

import { PIE_CHART_COLORS } from './constants';

export const getDonutChartConfig = (
    currencyIso,
    currencyLocale,
    priceByWatt,
    systemSize,
    typeChange
) => {
    return {
        colors: PIE_CHART_COLORS,
        dataLabels: { enabled: false },
        labels: [
            i18next.t('Solar panel', { count: 2 }),
            i18next.t('Inverter', { count: 2 }),
            i18next.t('Structure', { count: 2 }),
            i18next.t('Workforce and electrical equipment'),
            i18next.t('Accessory', { count: 2 }),
            i18next.t('Additional', { count: 2 }),
            i18next.t('Storage'),
        ],
        legend: { show: false },
        chart: { background: '#ffffff00' },
        plotOptions: {
            pie: {
                donut: {
                    background: 'transparent',
                    size: '88%',
                    labels: {
                        show: true,
                        name: { offsetY: 25, show: true },
                        total: {
                            fontSize: '13px',
                            formatter: () =>
                                getPriceByWattFormatter(priceByWatt),
                            label: i18next.t('Price per watt without taxes'),
                            show: true,
                        },
                        value: {
                            fontSize: '18px',
                            fontWeight: 600,
                            formatter: (val) =>
                                numberFormat(val, {
                                    currency: currencyIso,
                                    decimals: 2,
                                    locale: currencyLocale,
                                    style: 'currency',
                                }),
                            offsetY: -20,
                            show: true,
                        },
                    },
                },
            },
        },
        stroke: { show: false },
        tooltip: {
            y: {
                formatter: (val) =>
                    getTooltipFormatter(
                        currencyIso,
                        currencyLocale,
                        systemSize,
                        typeChange,
                        val
                    ),
            },
        },
    };
};

export const getFinalTotal = (infonavitAvailableBalance, total) =>
    infonavitAvailableBalance > total ? 0 : total - infonavitAvailableBalance;

export const getPriceByWattFormatter = (value) => {
    const priceByWattFormatted = numberFormat(value, {
        currency: 'USD',
        decimals: 4,
        style: 'currency',
    });
    return `${priceByWattFormatted} USD/Watt`;
};

export const getProfitPercentage = (subtotalWithDiscount, profit) => {
    const value = (profit * 100) / subtotalWithDiscount;
    return value.toFixed(2);
};

const getTooltipFormatter = (
    currencyIso,
    currencyLocale,
    systemSize,
    typeChange,
    val
) => {
    const priceByWattProduct = getPriceByWatt(
        currencyIso,
        val,
        systemSize,
        typeChange
    );
    const valueFormatted = numberFormat(val, {
        currency: currencyIso,
        decimals: 2,
        locale: currencyLocale,
        style: 'currency',
    });

    const priceByWattFormatted = numberFormat(priceByWattProduct, {
        currency: 'USD',
        decimals: 4,
        style: 'currency',
    });

    return `${valueFormatted} (${priceByWattFormatted} USD/Watt)`;
};

export const getTotalWithTaxes = ({ subtotalWithDiscount, totalTaxes = 0 }) =>
    subtotalWithDiscount + totalTaxes;

export const handleClickDelete = (remove, handleRemove, index, productItem) => {
    if (productItem.is_new) {
        return remove(index);
    }
    if (productItem.id) {
        return handleRemove(productItem.id, () => remove(index));
    }
    return false;
};

export const handleNormalizeFieldsBuild =
    (handleOnCreate, handleOnSave, proposalId) =>
    (index, value, previousValue, allValues, name) => {
        if (
            value &&
            !isNull(value) &&
            !isNaN(value) &&
            Number(value) != previousValue
        ) {
            const values = allValues.items[index];
            if (!isEqual(Number(value), values[`temp_${name}`])) {
                handleAction(handleOnCreate, handleOnSave, proposalId, {
                    ...values,
                    [name]: Number(value),
                });
            }
        }
    };

export const handleNormalizeDiscountField =
    (handleNormalizeFields, index, maxDiscountByUnitPrice, productItem) =>
    (value, previousValue, allValues, name) => {
        if (value > maxDiscountByUnitPrice) {
            return handleNormalizeFields(
                index,
                productItem.temp_discount,
                previousValue,
                allValues,
                name
            );
        } else {
            return handleNormalizeFields(
                index,
                value,
                previousValue,
                allValues,
                name
            );
        }
    };

export const handleNormalizePercentageDiscountField =
    (handleNormalizeFields, index, maximumDiscount) =>
    (value, previousValue, allValues, name) => {
        if (value > maximumDiscount) {
            return handleNormalizeFields(
                index,
                maximumDiscount,
                previousValue,
                allValues,
                name
            );
        } else {
            return handleNormalizeFields(
                index,
                value,
                previousValue,
                allValues,
                name
            );
        }
    };

export const handleNormalizeMarginField =
    (handleNormalizeFields, index, productItem) =>
    (value, previousValue, allValues, name) => {
        if (value > MAX_MARGIN_PERCENTAGE) {
            showToast({
                body: i18next.t('It cannot be greater than {{value}}', {
                    value: MAX_MARGIN_PERCENTAGE,
                }),
                type: 'danger',
            });
            return handleNormalizeFields(
                index,
                productItem.temp_margin,
                previousValue,
                allValues,
                name
            );
        } else {
            return handleNormalizeFields(
                index,
                value,
                previousValue,
                allValues,
                name
            );
        }
    };

export const handleNormalizeModelFieldBuild =
    (handleOnCreate, handleOnSave, proposalId) =>
    (index, value, previousValue, allValues, name) => {
        if (!isNull(value) && !isNaN(value) && value != previousValue) {
            const nameItem = name.split('.');
            handleAction(handleOnCreate, handleOnSave, proposalId, {
                ...allValues.items[index],
                [nameItem[1]]: value,
            });
        }

        return value;
    };

export const handleNormalizeModel =
    (index, handleNormalizeModelField) =>
    (value, previousValue, allValues, name) =>
        handleNormalizeModelField(index, value, previousValue, allValues, name);

export const handleNormalizeField =
    (index, handleNormalizeFields) => (value, previousValue, allValues, name) =>
        handleNormalizeFields(index, value, previousValue, allValues, name);

const handleAction = (handleOnCreate, handleOnSave, proposalId, values) => {
    if (values.is_new) {
        return handleOnCreate(proposalId, values);
    }
    if (values.item) {
        return handleOnSave(proposalId, values);
    }
    return false;
};

export const handleRemoveBuild =
    (handleOnDelete, proposalId) => (id, successCallback) =>
        handleOnDelete(id, proposalId, successCallback);
