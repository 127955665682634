import React, { useCallback } from 'react';

import AttachFileIcon from '@mui/icons-material/AttachFile';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
import { Controller } from 'react-hook-form';
import { Box, Button } from 'sunwise-ui';

const WrapperField = styled('div')`
    align-items: center;
    cursor: pointer;
    display: flex;
    font-size: 12px;
    justify-content: center;
    letter-spacing: -0.29px;
    line-height: 19px;
    outline: none;
    padding: 8px;

    ${({ variant }) =>
        variant === 'firstLoad' &&
        `
        background: #002438;
        border-radius: 3px;
        border: 3px dashed #a3a6b4;
        color: #ffffff;
        margin-bottom: 4px;
        padding: 24px;
`}
`;

const RHFPDFFileUpload = ({
    accept,
    control,
    disabled,
    handleSendToLisa,
    isFromScrapper,
    name,
    onChange,
    sendToLisaText,
    success = false,
    text,
    textSuccess,
    uploadMode,
    uploadText,
}) => {
    const onDrop = useCallback(
        (acceptedFiles) => {
            if (onChange) onChange(acceptedFiles, uploadMode);
        },
        [uploadMode]
    );
    const { getRootProps, getInputProps } = useDropzone({ onDrop });
    return (
        <WrapperField
            {...getRootProps()}
            variant={!success ? 'firstLoad' : 'reload'}
        >
            <Controller
                control={control}
                name={name}
                render={() => (
                    <input
                        {...getInputProps()}
                        disabled={disabled}
                        accept={accept}
                    />
                )}
            />

            {!success ? (
                <Box alignItems="center" display="flex" gap={2}>
                    <AttachFileIcon />
                    {text || null}
                </Box>
            ) : (
                <Box
                    alignItems="center"
                    display="flex"
                    flexDirection="column"
                    gap={2}
                >
                    <CheckCircleIcon />
                    <Box>{textSuccess}</Box>

                    <Box display="flex" gap={2}>
                        <Button variant="outlined">{uploadText}</Button>

                        {isFromScrapper && (
                            <Button
                                onClick={(e) => {
                                    e.stopPropagation();
                                    handleSendToLisa();
                                }}
                                variant="outlined"
                            >
                                {sendToLisaText}
                            </Button>
                        )}
                    </Box>
                </Box>
            )}
        </WrapperField>
    );
};

RHFPDFFileUpload.propTypes = {
    accept: PropTypes.string,
    control: PropTypes.object,
    disabled: PropTypes.bool,
    handleSendToLisa: PropTypes.func,
    input: PropTypes.object,
    isFromScrapper: PropTypes.bool,
    name: PropTypes.string,
    onChange: PropTypes.func,
    selectedRate: PropTypes.object,
    sendToLisaText: PropTypes.string,
    success: PropTypes.bool,
    text: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    textSuccess: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    uploadMode: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    uploadText: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

export default RHFPDFFileUpload;
