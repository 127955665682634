import isEmpty from 'lodash/isEmpty';

import { bulkReasignAgent } from 'common/api/v1/contacts';
import { showReponseErrorsAsAlert } from 'common/utils/helpers';
import showToast from 'common/utils/showToast';

import {
    BULK_UPDATE_ITEMS,
    BULK_UPDATE_ITEMS_FAILURE,
    BULK_UPDATE_ITEMS_SUCCESS,
} from '../actionTypes';
import { actions } from '../reducer';

import filterContacts from './filterContacts';
import openReasignModal from './openReasignModal';

export default (filterData, values) => (dispatch) => {
    dispatch(actions[BULK_UPDATE_ITEMS]());

    if (
        !values ||
        !Array.isArray(values?.reasignAgents) ||
        values?.reasignAgents.length <= 0
    )
        return;

    const agents = values.reasignAgents.map((item) => ({
        contacts: item.contacts,
        id: item.agent,
    }));

    bulkReasignAgent({ agents })
        .then((response) => {
            dispatch(actions[BULK_UPDATE_ITEMS_SUCCESS](response.data));
            if (!isEmpty(filterData)) dispatch(filterContacts(filterData));
            showToast();
            if (values?.callback) values?.callback();
            dispatch(openReasignModal(false));
        })
        .catch((error) => {
            dispatch(
                actions[BULK_UPDATE_ITEMS_FAILURE](
                    error?.response?.data?.errors
                )
            );
            showReponseErrorsAsAlert(dispatch, error?.response);
        });
};
