import React from 'react';

import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Grid } from 'sunwise-ui';

import {
    ReactHookFormIntlNumberInput,
    ReactHookFormIntlNumberInputConsumption,
    ReactHookFormSelect2,
} from 'common/components/form/bootstrap';
import { HIGH_TENSION_RATES } from 'common/constants/rates';

import { getRateHasMeterType } from '../helpers';

import AccordionForm from './Accordion';

const ContractedDemandContainer = ({
    companyIsFromSpain,
    control,
    disabled,
    hasFormErrors,
    hourlyContractedDemandValues,
    selectedRate,
}) => {
    const { t } = useTranslation();

    const isCertified =
        selectedRate?.isCertified &&
        HIGH_TENSION_RATES.includes(selectedRate?.name);

    if (
        !selectedRate ||
        (!isCertified && isEmpty(hourlyContractedDemandValues))
    )
        return null;

    const hasMeterType = getRateHasMeterType(selectedRate, companyIsFromSpain);

    return (
        <>
            <AccordionForm
                hasFormErrors={hasFormErrors}
                title={t('Contracted demand')}
            >
                {hasMeterType && (
                    <Grid container mb={2}>
                        <Grid item xs={18} lg={9}>
                            <ReactHookFormSelect2
                                control={control}
                                disabled={disabled}
                                isClearable={false}
                                label={t('Meter type')}
                                name="spain_meter_type"
                                options={[
                                    {
                                        label: '',
                                        options: [
                                            { label: '1, 2, 3', value: 0 },
                                            { label: '4, 5', value: 1 },
                                        ],
                                    },
                                ]}
                                variant="filled"
                            />
                        </Grid>
                    </Grid>
                )}

                <Grid container>
                    {isCertified && (
                        <Grid item xs={18} lg={9}>
                            <ReactHookFormIntlNumberInput
                                append="kW"
                                control={control}
                                disabled={disabled}
                                fullWidth
                                label={t('Contracted demand')}
                                name="contracted_demand"
                                variant="filled"
                            />
                        </Grid>
                    )}
                    {!isCertified &&
                        Object.keys(hourlyContractedDemandValues).map((key) => (
                            <Grid
                                item
                                key={`contracted-demand-${key}`}
                                lg={9}
                                xs={18}
                            >
                                <ReactHookFormIntlNumberInputConsumption
                                    append="kW"
                                    control={control}
                                    disabled={disabled}
                                    fullWidth
                                    name={`hourly_contracted_demand.${key}`}
                                    variant="filled"
                                />
                            </Grid>
                        ))}
                </Grid>
            </AccordionForm>
        </>
    );
};

ContractedDemandContainer.propTypes = {
    companyIsFromSpain: PropTypes.bool,
    control: PropTypes.object,
    disabled: PropTypes.bool,
    hasFormErrors: PropTypes.bool,
    hourlyContractedDemandValues: PropTypes.object,
    selectedRate: PropTypes.object,
};

export default ContractedDemandContainer;
