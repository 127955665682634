import initializeForm from './initializeForm';
import resetForm from './resetForm';
import resetItem from './resetItem';
import selectItem from './selectItem';
import setIsNewRecord from './setIsNewRecord';

export default (item) => (dispatch) => {
    const addition_value =
        item?.addition_type === 'PERC'
            ? (item?.addition_value ?? 0) * 100
            : item?.addition_value ?? 0;

    const values = {
        abbreviation: item.currency.abbreviation,
        addition_type: item?.addition_type ?? 'AMNT',
        addition_value: parseFloat(addition_value),
        dollar_price: item.dollar_price,
        exchange_type: item.exchange_type,
        id: item.id,
        inverse_dollar_price: item.inverse_dollar_price,
        is_enabled: item.is_enabled,
        name: item.currency.name,
        original_dollar_price: item.original_dollar_price,
        source: item.source,
        symbol: item.currency.symbol,
    };

    dispatch(resetForm());
    dispatch(resetItem());
    dispatch(setIsNewRecord(false));
    dispatch(initializeForm(values));
    dispatch(selectItem({ id: item.id, name: item.currency.name }));
};
