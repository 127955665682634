import React from 'react';

import get from 'lodash/get';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Skeleton, UiDialog as Dialog } from 'sunwise-ui';

import CeideForm from './ceide/CeideForm';
import CIBancoEditForm from './cibanco/CIBancoEditForm';
import CIBancoForm from './cibanco/CIBancoForm';
import RedGirasolForm from './redgirasol/RedGirasolForm';

const AllianceFormModal = ({
    alliance,
    allianceProducts,
    canModifyContacts,
    contact,
    currencyIso,
    currencyLocale,
    currencySymbol,
    editionMode,
    fetchOfferDetails,
    financialProduct,
    financierType,
    handleClickCancel,
    isLoading,
    isLocked,
    isOpenModal,
    isPrequalifying,
    isSaving,
    proposalId,
    total,
}) => {
    const { t } = useTranslation();

    const renderAllianceForm = (editionMode) => {
        if (isLoading) return <Skeleton variant="rounded" height={30} />;
        switch (financierType) {
            case 1:
                return (
                    <RedGirasolForm
                        alliance={alliance}
                        allianceProducts={allianceProducts}
                        isLocked={isLocked}
                        isSaving={isSaving}
                        proposalId={proposalId}
                        total={total}
                    />
                );
            case 3:
                return (
                    <CeideForm
                        alliance={alliance}
                        allianceProducts={allianceProducts}
                        currencyIso={currencyIso}
                        currencyLocale={currencyLocale}
                        isLocked={isLocked}
                        isPrequalifying={isPrequalifying}
                        isSaving={isSaving}
                        proposalId={proposalId}
                        total={total}
                    />
                );
            default:
                if (editionMode)
                    return (
                        <CIBancoEditForm
                            alliance={alliance}
                            allianceProducts={allianceProducts}
                            currencyIso={currencyIso}
                            currencySymbol={currencySymbol}
                            editionMode={editionMode}
                            financialProduct={financialProduct}
                            isLocked={isLocked}
                            isSaving={isSaving}
                            proposalId={proposalId}
                            total={total}
                        />
                    );
                return (
                    <CIBancoForm
                        alliance={alliance}
                        allianceProducts={allianceProducts}
                        canModifyContacts={canModifyContacts}
                        contact={contact}
                        currencyIso={currencyIso}
                        currencySymbol={currencySymbol}
                        fetchOfferDetails={fetchOfferDetails}
                        isLocked={isLocked}
                        isSaving={isSaving}
                        proposalId={proposalId}
                        total={total}
                    />
                );
        }
    };

    return (
        <Dialog
            closeButton={isLocked ? false : true}
            isLoading={isLoading}
            onClose={() => handleClickCancel()}
            onExited={() => handleClickCancel()}
            open={isOpenModal}
            title={`${editionMode ? t('Edit') : ''} ${get(
                alliance,
                'financier.name',
                t('Alliance')
            )}`}
        >
            {renderAllianceForm(editionMode)}
        </Dialog>
    );
};

AllianceFormModal.propTypes = {
    alliance: PropTypes.object,
    allianceProducts: PropTypes.array,
    canModifyContacts: PropTypes.bool,
    contact: PropTypes.object,
    currencyIso: PropTypes.string,
    currencyLocale: PropTypes.string,
    currencySymbol: PropTypes.string,
    editionMode: PropTypes.bool,
    fetchOfferDetails: PropTypes.func,
    financialProduct: PropTypes.object,
    financierType: PropTypes.number,
    handleClickCancel: PropTypes.func,
    isLoading: PropTypes.bool,
    isLocked: PropTypes.bool,
    isOpenModal: PropTypes.bool,
    isPrequalifying: PropTypes.bool,
    isSaving: PropTypes.bool,
    proposalId: PropTypes.string,
    total: PropTypes.number,
};

export default AllianceFormModal;
