import React from 'react';

import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { Card, Typography } from 'sunwise-ui';

const Container = styled(Card)(
    ({ theme }) => `
        align-items: center;
        background-color: ${theme.palette.mode === 'dark' ? '#3F444C' : '#fff'};
        border-radius: 8px;
        cursor: pointer;
        display: flex;
        flex-direction: column;
        height: 248px;
        margin: 0 9px 16px;
        text-align: center;
        width: 100%;

        &.selected{
            box-shadow: 0 0 0 1px ${theme.palette.primary.main};
        }
`
);

const ActionContainer = styled('div')`
    align-items: center;
    border-radius: 8px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    height: 196px;
    justify-content: center;
    width: 100%;
`;

const Label = styled(Typography)`
    font-size: 13px;
    font-weight: bold;
    height: 29px;
    line-height: 29px;
    text-align: center;
`;

const IconPreviewItem = ({ icon, isSelected, onClick, text }) => (
    <Container className={isSelected ? 'selected' : ''} onClick={onClick}>
        <ActionContainer>{icon}</ActionContainer>
        <Label>{text}</Label>
    </Container>
);

IconPreviewItem.propTypes = {
    icon: PropTypes.object,
    isSelected: PropTypes.bool,
    onClick: PropTypes.func,
    text: PropTypes.string,
};

export default IconPreviewItem;
