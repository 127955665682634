import React, { useState } from 'react';

import LockIcon from '@mui/icons-material/Lock';
import isNull from 'lodash/isNull';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Divider, List, ListItem } from 'sunwise-ui';

import SecondaryDrawer from 'common/layouts/defaultLayout/SecondaryDrawer';
import StyledListItemButton from 'common/layouts/defaultLayout/StyledListItemButton';
import StyledStyledListItemText from 'common/layouts/defaultLayout/StyledListItemText';
import StyledMenuItem from 'common/layouts/defaultLayout/StyledMenuItem';
import { getIsMultibranchesAccount } from 'common/utils/helpers/session';

import { ReactComponent as BranchOfficesIcon } from 'resources/branchOfficesIcon.svg';

import * as actions from '../actions';
import { getSelectedText } from '../helpers';
import * as selectors from '../selectors';

const BranchOfficesDrawer = ({
    branchesData,
    branchesDictionary,
    branchesItems,
    branchSelected,
    container,
    isFetching,
    multiBranchMode,
    selectBranch,
}) => {
    const { t } = useTranslation();

    const [mobileOpen, setMobileOpen] = useState(false);
    const handleDrawerToggle = () => setMobileOpen(!mobileOpen);

    if (
        !getIsMultibranchesAccount() ||
        isNull(branchSelected) ||
        isFetching ||
        branchesData.length === 0
    )
        return null;

    const disabled =
        isFetching || (multiBranchMode && branchesItems.length === 1);

    return (
        <List disablePadding>
            <ListItem
                disablePadding
                disabled={disabled}
                onClick={handleDrawerToggle}
            >
                <StyledListItemButton>
                    <BranchOfficesIcon />
                    <StyledStyledListItemText
                        primary={getSelectedText(
                            multiBranchMode,
                            branchesDictionary,
                            branchSelected
                        )}
                    />
                </StyledListItemButton>
            </ListItem>

            <SecondaryDrawer
                {...{ container, mobileOpen, onClose: handleDrawerToggle }}
            >
                {!multiBranchMode && (
                    <StyledMenuItem
                        disabled={false}
                        key="dropdown-item-default"
                    >
                        <LockIcon />
                        {t(
                            'This section does not require a branch office selection'
                        )}
                    </StyledMenuItem>
                )}
                {branchesItems.map((item, index) => (
                    <StyledMenuItem
                        disabled={item.disabled || !multiBranchMode}
                        key={`dropdown-item-${index}`}
                        onClick={() => {
                            handleDrawerToggle();
                            selectBranch(item.value);
                        }}
                    >
                        <BranchOfficesIcon />
                        {item.value === 'all'
                            ? t('All text', { context: 'female', count: 2 })
                            : item.label}
                    </StyledMenuItem>
                ))}
            </SecondaryDrawer>

            <Divider />
        </List>
    );
};

const mapStateToProps = createStructuredSelector({
    branchesData: selectors.getBranchesData,
    branchesDictionary: selectors.getBranchesDictionary,
    branchesItems: selectors.getBranchesItemsData,
    branchSelected: selectors.getBranchSelected,
    isFetching: selectors.getIsFetchingBranches,
});

const mapDispatchToProps = (dispatch) => ({
    selectBranch: (branchId) => dispatch(actions.selectBranch(branchId)),
});

BranchOfficesDrawer.propTypes = {
    branchesData: PropTypes.array,
    branchesDictionary: PropTypes.object,
    branchesItems: PropTypes.array,
    branchSelected: PropTypes.string,
    container: PropTypes.func,
    isFetching: PropTypes.bool,
    multiBranchMode: PropTypes.oneOfType([PropTypes.bool, PropTypes.array]),
    selectBranch: PropTypes.func,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(BranchOfficesDrawer);
