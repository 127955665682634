import { createSelector } from 'reselect';

import { NAME } from './constants';

export const getModel = (state) => state[NAME];

export const getInitialValues = createSelector(
    getModel,
    (model) => model.initialValues
);

export const getBranchOfficeSelected = createSelector(
    getModel,
    (model) => model.branchOffice
);

export const getIsOpenModal = createSelector(getModel, (model) => model.isOpen);

export const getCompaniesCatalogs = createSelector(
    getModel,
    (model) => model.companiesCatalogs
);

export const getCompaniesCatalogsData = createSelector(
    getCompaniesCatalogs,
    (model) => model.data
);

export const getOfferTemplates = createSelector(
    getModel,
    (model) => model.offerTemplates
);

export const getIsFetchingTemplates = createSelector(
    getOfferTemplates,
    (model) => model.isFetching
);

export const getOfferTemplatesData = createSelector(
    getOfferTemplates,
    (model) => model.data
);

export const getSaveItem = createSelector(getModel, (model) => model.saveItem);

export const getErrorsSaveItem = createSelector(
    getSaveItem,
    (saveItem) => saveItem.errors
);

export const getIsSavingItem = createSelector(
    getSaveItem,
    (saveItem) => saveItem.isSaving
);

export const getSummaries = createSelector(
    getModel,
    (model) => model.summaries
);

export const getSummariesIsFetching = createSelector(
    getSummaries,
    (summaries) => summaries.isFetching
);

export const getSummariesData = createSelector(
    getSummaries,
    (summaries) => summaries.data || []
);

export const getSummariesErrors = createSelector(
    getSummaries,
    (summaries) => summaries.errors
);
