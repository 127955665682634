import fetchScheduleRateConfiguration from '../../afterSalesSettings/actions/fetchScheduleRateConfiguration';
import * as afterSalesSettingsSelectors from '../../afterSalesSettings/selectors';
import { buildInitialValues } from '../helpers';

import initializeForm from './initializeForm';

export default (item, successCallback) => (dispatch, getState) => {
    const afterSalesSettings = afterSalesSettingsSelectors.getFetchDataData(
        getState()
    );

    const promises = [];

    if (item?.rate?.id)
        promises.push(
            dispatch(
                fetchScheduleRateConfiguration({
                    certified: item.rate.certified,
                    rate: item.rate.id,
                    rate_division: afterSalesSettings?.rate_division?.id,
                })
            )
        );

    Promise.all(promises).then((scheduleRateConfiguration) => {
        const values = buildInitialValues(item, scheduleRateConfiguration);

        dispatch(initializeForm(values));
        if (successCallback) successCallback();
    });
};
