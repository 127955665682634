import React, { useEffect, useState } from 'react';

import NotificationsIcon from '@mui/icons-material/Notifications';
import SettingsIcon from '@mui/icons-material/Settings';
import { push } from 'connected-react-router';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import {
    Box,
    Container as MaterialContainer,
    Divider,
    Grid,
    IconButton,
} from 'sunwise-ui';

import TitleIcon from 'common/components/TitleIcon';

import * as notificationsSettingsActions from '../notificationsSettings/actions';

import * as actions from './actions';
import BulkOperationCard from './components/BulkOperationCard';
import NotificationsList from './components/NotificationsList';
import * as selectors from './selectors';

const Container = ({
    checkedNotifications,
    fetchNotifications,
    fetchNotificationsPerPage,
    groupedNotifications,
    handleClickBulkItems,
    handleClickOpenModal,
    isDeletingNotification,
    isFetching,
    isUpdatingNotification,
    isUpdatingNotifications,
    notifications,
    pagination,
    redirect,
    updateNotification,
    updateNotificationsTemp,
}) => {
    const { t } = useTranslation();
    const [checkedAll, setCheckedAll] = useState(false);
    const [filterData, setFilterData] = useState({
        page: 1,
        pageSize: 25,
        showMentions: false,
        type: 'all',
        viewed: false,
    });

    useEffect(() => {
        fetchNotifications(filterData);
    }, []);

    const isLoading =
        isDeletingNotification ||
        isFetching ||
        isUpdatingNotification ||
        isUpdatingNotifications;

    return (
        <MaterialContainer maxWidth={false}>
            <Grid container my={2}>
                <Grid
                    item
                    sx={{ alignItems: 'center', display: 'flex', gap: 1 }}
                    xs={18}
                >
                    <NotificationsIcon sx={{ color: '#ff9a00' }} />
                    <TitleIcon title={t('Your notifications')} />
                    <Box sx={{ ml: 'auto' }}>
                        <IconButton onClick={() => handleClickOpenModal()}>
                            <SettingsIcon sx={{ color: '#ff9a00' }} />
                        </IconButton>
                    </Box>
                </Grid>
            </Grid>
            <Divider />
            <BulkOperationCard
                checkedAll={checkedAll}
                checkedNotifications={checkedNotifications}
                fetchNotifications={fetchNotifications}
                filterData={filterData}
                handleClickBulkItems={handleClickBulkItems}
                notifications={notifications}
                setCheckedAll={setCheckedAll}
                setFilterData={setFilterData}
                updateNotificationsTemp={updateNotificationsTemp}
            />
            <Grid container>
                <Grid item xs={18}>
                    <NotificationsList
                        fetchNotificationsPerPage={fetchNotificationsPerPage}
                        filterData={filterData}
                        groupedNotifications={groupedNotifications}
                        isLoading={isLoading}
                        pagination={pagination}
                        redirect={redirect}
                        setCheckedAll={setCheckedAll}
                        setFilterData={setFilterData}
                        updateNotification={updateNotification}
                        updateNotificationsTemp={updateNotificationsTemp}
                    />
                </Grid>
            </Grid>
        </MaterialContainer>
    );
};

Container.propTypes = {
    checkedNotifications: PropTypes.array,
    fetchNotifications: PropTypes.func,
    fetchNotificationsPerPage: PropTypes.func,
    groupedNotifications: PropTypes.array,
    handleClickBulkItems: PropTypes.func,
    handleClickOpenModal: PropTypes.func,
    isDeletingNotification: PropTypes.bool,
    isFetching: PropTypes.bool,
    isUpdatingNotification: PropTypes.bool,
    isUpdatingNotifications: PropTypes.bool,
    notifications: PropTypes.array,
    pagination: PropTypes.object,
    redirect: PropTypes.func,
    updateNotification: PropTypes.func,
    updateNotificationsTemp: PropTypes.func,
};

const mapStateToProps = createStructuredSelector({
    checkedNotifications: selectors.getCheckedNotifications,
    groupedNotifications: selectors.getNotificationsData,
    isDeletingNotification: selectors.getIsDeletingNotification,
    isFetching: selectors.getIsFetchingNotifications,
    isUpdatingNotification: selectors.getIsUpdatingNotification,
    isUpdatingNotifications: selectors.getIsUpdatingNotifications,
    notifications: selectors.getFlattenedNotifications,
    pagination: selectors.getNotificationsPagination,
});

const mapDispatchToProps = (dispatch) => ({
    fetchNotifications: (filterData) =>
        dispatch(actions.fetchNotifications(filterData)),
    fetchNotificationsPerPage: (filterData) =>
        dispatch(actions.fetchNotificationsPerPage(filterData)),
    handleClickBulkItems: (callback, filterData, ids, value) =>
        dispatch(actions.bulkItems(callback, filterData, ids, value)),
    handleClickOpenModal: () =>
        dispatch(notificationsSettingsActions.setIsOpenModal(true)),
    redirect: (link) => dispatch(push(link)),
    updateNotification: (filterData, notification) =>
        dispatch(actions.updateNotification(filterData, notification)),
    updateNotificationsTemp: (callback, notification, value) =>
        dispatch(
            actions.updateNotificationsTemp(callback, notification, value)
        ),
});

export default connect(mapStateToProps, mapDispatchToProps)(Container);
