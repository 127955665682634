import { handleFileURL } from 'common/utils/helpers';

import { INITIALIZE_FORM } from '../actionTypes';
import { actions } from '../reducer';

export default (values) => (dispatch) => {
    const newValues = { ...values };

    if (values.loading_image) {
        newValues.loading_image = handleFileURL(
            values.loading_image,
            process.env.REACT_APP_S3_MEDIA_PATH
        );
    }

    if (values.logo_image) {
        newValues.logo_image = handleFileURL(
            values.logo_image,
            process.env.REACT_APP_S3_MEDIA_PATH
        );
    }

    if (values.logotype_image) {
        newValues.logotype_image = handleFileURL(
            values.logotype_image,
            process.env.REACT_APP_S3_MEDIA_PATH
        );
    }

    dispatch(actions[INITIALIZE_FORM](newValues));
};
