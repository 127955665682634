import React, { useEffect } from 'react';

import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Box, Button } from 'sunwise-ui';

import {
    ReactHookFormInput,
    ReactHookFormSelect,
} from 'common/components/form/bootstrap';
import TextVariant from 'common/components/TextVariant';
import yupResolver from 'common/utils/yupResolver';

import * as actions from '../actions';
import {
    OFFER_SMART_DOCUMENT_FORM_CREATE_MODE,
    OFFER_SMART_DOCUMENT_FORM_DUPLICATE_MODE,
} from '../constants';
import { getDocumentTemplatesToSelect } from '../helpers';
import * as selectors from '../selectors';
import smartDocumentValidate from '../smartDocumentValidate';

const FormContainer = ({
    documentTemplates,
    fetchDocumentTemplates,
    handleClickDocumentItem,
    initialValues,
    isDuplicatingSmartDocument,
    isFetchingDocumentTemplates,
    isSavingSmartDocument,
    offerSmartDocumentFormMode,
    proposalId,
    save,
}) => {
    const { t } = useTranslation();

    const isCreateMode =
        offerSmartDocumentFormMode === OFFER_SMART_DOCUMENT_FORM_CREATE_MODE;
    const isDuplicateMode =
        offerSmartDocumentFormMode === OFFER_SMART_DOCUMENT_FORM_DUPLICATE_MODE;

    const { control, handleSubmit, reset } = useForm({
        context: isCreateMode,
        defaultValues: initialValues,
        resolver: yupResolver(smartDocumentValidate),
    });

    useEffect(() => {
        fetchDocumentTemplates();
    }, []);

    useEffect(() => reset(initialValues), [initialValues]);

    const isDisabled =
        isDuplicatingSmartDocument ||
        isFetchingDocumentTemplates ||
        isSavingSmartDocument;

    return (
        <form
            onSubmit={handleSubmit((values) =>
                save(
                    handleClickDocumentItem,
                    offerSmartDocumentFormMode,
                    proposalId,
                    values
                )
            )}
        >
            {isDuplicateMode && (
                <Box textAlign="center" mb={2}>
                    <TextVariant
                        text={t(
                            'Remember that when duplicating a smart document, the tags and changes made in this editor will be preserved'
                        )}
                    />
                </Box>
            )}

            {!isCreateMode && (
                <ReactHookFormInput
                    control={control}
                    disabled={isDisabled}
                    label={`${t('Name')} *`}
                    maxLength="254"
                    name="name"
                    placeholder={t('Name')}
                    sx={{}}
                />
            )}

            {isCreateMode && (
                <ReactHookFormSelect
                    control={control}
                    disabled={isDisabled}
                    label={`${t('Select a template')} *`}
                    name="custom_offer_template"
                    options={getDocumentTemplatesToSelect(documentTemplates)}
                />
            )}

            <Box textAlign={'right'}>
                <Button disabled={isDisabled} type="submit">
                    {isDuplicatingSmartDocument || isSavingSmartDocument
                        ? t('Saving').concat('...')
                        : t('Continue')}
                </Button>
            </Box>
        </form>
    );
};

const mapStateToProps = createStructuredSelector({
    documentTemplates: selectors.getDocumentTemplatesData,
    initialValues: selectors.getOfferSmartDocumentInitialValues,
    isDuplicatingSmartDocument: selectors.getIsDuplicatingOfferSmartDocument,
    isFetchingDocumentTemplates: selectors.getIsFetchingDocumentTemplates,
    isSavingSmartDocument: selectors.getIsSavingOfferSmartDocument,
});

const mapDispatchToProps = (dispatch) => ({
    fetchDocumentTemplates: () => dispatch(actions.fetchDocumentTemplates()),
    save: (
        handleClickDocumentItem,
        offerSmartDocumentFormMode,
        proposalId,
        values
    ) =>
        dispatch(
            actions.saveSmartDocumentForm(
                handleClickDocumentItem,
                offerSmartDocumentFormMode,
                proposalId,
                values
            )
        ),
});

FormContainer.propTypes = {
    documentTemplates: PropTypes.array,
    fetchDocumentTemplates: PropTypes.func,
    handleClickDocumentItem: PropTypes.func,
    initialValues: PropTypes.object,
    isDuplicatingSmartDocument: PropTypes.bool,
    isFetchingDocumentTemplates: PropTypes.bool,
    isSavingSmartDocument: PropTypes.bool,
    offerSmartDocumentFormMode: PropTypes.number,
    proposalId: PropTypes.string,
    save: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(FormContainer);
