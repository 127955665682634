import fetchCurrencies from '../../multiCurrency/actions/filterItems';
import {
    INITIALIZE,
    INITIALIZE_FAILURE,
    INITIALIZE_SUCCESS,
} from '../actionTypes';
import { energyBackupConfigurationActions } from '../reducer';

import fetchBrands from './fetchBrands';
import fetchSuppliers from './fetchSuppliers';

export default () => (dispatch) => {
    dispatch(energyBackupConfigurationActions[INITIALIZE]());
    Promise.all([
        dispatch(fetchSuppliers()),
        dispatch(fetchBrands()),
        dispatch(fetchCurrencies()),
    ])
        .then(() =>
            dispatch(energyBackupConfigurationActions[INITIALIZE_SUCCESS]())
        )
        .catch(() =>
            dispatch(energyBackupConfigurationActions[INITIALIZE_FAILURE]())
        );
};
