import React, { useEffect, useState } from 'react';

import EditIcon from '@mui/icons-material/Edit';
import StarIcon from '@mui/icons-material/Star';
import { styled } from '@mui/material/styles';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
    Box,
    Button,
    Card,
    Collapse,
    IconButton,
    Typography,
} from 'sunwise-ui';

import PlaceholderCardContactsDashboard from 'common/components/placeholder/PlaceholderCardContactsDashboard';
import { PERMISSION_LIST } from 'common/constants/permissionsV2';
import withPermissions from 'common/hocs/withPermissions';
import { COMPANY_BRANCH_NAME } from 'common/modules/multiBranches/constants';

import DropdownCustomCardMenuMap from './DropdownCustomCardMenuMap';

const WraperField = styled(Box)(
    ({ theme }) => `
    background: ${
        theme.palette.mode === 'dark'
            ? 'rgb(40, 43, 48)'
            : 'rgba(238, 244, 250, 0.35)'
    };
    border-radius: 8px 8px 0px 0px;
    display: flex;
    flex-direction: column;
    gap: 5px;
    margin-bottom: 8px;
    padding: 8px 16px;
`
);

const CardContact = ({
    canDelete,
    canModify,
    canView,
    contact,
    fetchStatusContact,
    handleClickFavorite,
    handleClickType,
    handleClickUpdate,
    isLoadingContact,
    statusContactToSelect,
}) => {
    const { t } = useTranslation();
    const [isShowFullInfo, setIsShowFullInfo] = useState(false);

    useEffect(() => {
        fetchStatusContact();
    }, []);

    if (!contact || !canView) return null;

    const {
        agent,
        branch_office,
        company_position,
        contact_origin,
        emails = [],
        fav,
        first_lastname,
        name,
        rfc,
        second_lastname,
        status_contact,
        telephones = [],
    } = contact;

    const showEditButton = canModify || canDelete;

    return (
        <PlaceholderCardContactsDashboard ready={isLoadingContact}>
            <Box mb={2}>
                <Card>
                    <Card.Header sx={{ alignItems: 'center', display: 'flex' }}>
                        <Typography variant="body1" fontWeight="bold">
                            {t('General information')}
                        </Typography>
                        <Box ml="auto">
                            {showEditButton && (
                                <IconButton onClick={handleClickUpdate}>
                                    <EditIcon fontSize="small" />
                                </IconButton>
                            )}
                        </Box>
                    </Card.Header>
                    <Card.Body>
                        <Box
                            alignItems="center"
                            display="flex"
                            gap={1}
                            mb={2}
                            width="100%"
                        >
                            {canModify && (
                                <IconButton onClick={handleClickFavorite}>
                                    <StarIcon
                                        fontSize="small"
                                        sx={{ color: fav && '#ffaA00' }}
                                    />
                                </IconButton>
                            )}
                            <Typography variant="title" fontWeight="bold">
                                {`${name} ${first_lastname || ''} ${
                                    second_lastname || ''
                                }`}
                            </Typography>
                        </Box>

                        <DropdownCustomCardMenuMap
                            canModify={canModify}
                            handleSelected={handleClickType}
                            selected={status_contact}
                            statusToSelect={statusContactToSelect}
                        />

                        <Box
                            display="flex"
                            flexDirection="column"
                            gap={1}
                            pt={2}
                            width="100%"
                        >
                            {telephones.length > 0 &&
                                telephones.map(
                                    ({ phone_key, telephone }, index) => (
                                        <WraperField key={`phone-${index}`}>
                                            <Typography variant="caption">
                                                {t('Telephone')}
                                            </Typography>
                                            <Typography
                                                fontWeight="600"
                                                variant="body2"
                                            >
                                                {`${phone_key} ${telephone}`}
                                            </Typography>
                                        </WraperField>
                                    )
                                )}
                            {emails.length > 0 &&
                                emails.map(({ email }, index) => (
                                    <WraperField key={`email-${index}`}>
                                        <Typography variant="caption">
                                            {t('Email')}
                                        </Typography>
                                        <Typography
                                            variant="body2"
                                            fontWeight="600"
                                        >
                                            {email}
                                        </Typography>
                                    </WraperField>
                                ))}
                            <WraperField>
                                <Typography variant="caption">
                                    {t('Branch office')}
                                </Typography>
                                <Typography variant="body2" fontWeight="600">
                                    {branch_office === null
                                        ? COMPANY_BRANCH_NAME
                                        : branch_office?.name}
                                </Typography>
                            </WraperField>
                        </Box>

                        <Collapse in={isShowFullInfo}>
                            <Box
                                display="flex"
                                flexDirection="column"
                                gap={1}
                                sx={{ width: '100%', pt: 1 }}
                            >
                                {company_position && (
                                    <WraperField>
                                        <Typography variant="caption">
                                            {t('Position')}
                                        </Typography>
                                        <Typography
                                            variant="body2"
                                            fontWeight="600"
                                        >
                                            {company_position}
                                        </Typography>
                                    </WraperField>
                                )}
                                {rfc && (
                                    <WraperField>
                                        <Typography variant="caption">
                                            {t('Tax ID')}
                                        </Typography>
                                        <Typography
                                            variant="body2"
                                            fontWeight="600"
                                        >
                                            {rfc}
                                        </Typography>
                                    </WraperField>
                                )}
                                <WraperField>
                                    <Typography variant="caption">
                                        {t('Origin')}
                                    </Typography>
                                    <Typography
                                        variant="body2"
                                        fontWeight="600"
                                    >
                                        {get(contact_origin, 'name', '') ||
                                            t('No origin')}
                                    </Typography>
                                </WraperField>
                                <WraperField>
                                    <Typography variant="caption">
                                        {t('Assigned agent')}
                                    </Typography>
                                    <Typography
                                        variant="body2"
                                        fontWeight="600"
                                    >
                                        {`${get(agent, 'first_name', '')} ${get(
                                            agent,
                                            'last_name',
                                            ''
                                        )}`}
                                    </Typography>
                                </WraperField>
                            </Box>
                        </Collapse>

                        <Button
                            color="secondary"
                            fullWidth
                            variant="text"
                            onClick={() => setIsShowFullInfo(!isShowFullInfo)}
                            sx={{ mt: 1 }}
                        >
                            {isShowFullInfo
                                ? t('See less information')
                                : t('See more information')}
                        </Button>
                    </Card.Body>
                </Card>
            </Box>
        </PlaceholderCardContactsDashboard>
    );
};

CardContact.propTypes = {
    canDelete: PropTypes.bool,
    canModify: PropTypes.bool,
    canView: PropTypes.bool,
    contact: PropTypes.object,
    fetchStatusContact: PropTypes.func,
    handleClickFavorite: PropTypes.func,
    handleClickType: PropTypes.func,
    handleClickUpdate: PropTypes.func,
    isLoadingContact: PropTypes.bool,
    statusContactToSelect: PropTypes.array,
};

export default withPermissions(PERMISSION_LIST.CONTACTS_PERMISSION)(
    CardContact
);
