import { getScheduleRateConfiguration } from 'common/api/v2/scheduleRate';
import { RATES_WITH_DIVISION } from 'common/constants/rates';

import {
    FETCH_SCHEDULE_RATE_CONFIGURATION,
    FETCH_SCHEDULE_RATE_CONFIGURATION_FAILURE,
} from '../actionTypes';
import { projectConsumptionModalActions } from '../reducer';

import setScheduleRateConfiguration from './setScheduleRateConfiguration';

export default ({ rateDivision, rate }) =>
    (dispatch) => {
        return new Promise((resolve, reject) => {
            if (
                !rate?.id ||
                (rate.isCertified &&
                    RATES_WITH_DIVISION.includes(rate.name) &&
                    !rateDivision)
            )
                return reject();

            dispatch(
                projectConsumptionModalActions[
                    FETCH_SCHEDULE_RATE_CONFIGURATION
                ]()
            );

            getScheduleRateConfiguration({
                certified: rate.isCertified,
                rate: rate.id,
                rate_division: rateDivision || null,
            })
                .then((response) => {
                    const data = response?.data?.data;
                    dispatch(setScheduleRateConfiguration(data));
                    resolve(data);
                })
                .catch((error) => {
                    dispatch(
                        projectConsumptionModalActions[
                            FETCH_SCHEDULE_RATE_CONFIGURATION_FAILURE
                        ](error?.response?.data?.errors)
                    );

                    reject();
                });
        });
    };
