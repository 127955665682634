import React, { useEffect } from 'react';

import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { createStructuredSelector } from 'reselect';
import { Box } from 'sunwise-ui';

import { ReactHookFormSelect } from 'common/components/form/bootstrap';

import * as actions from '../../proposalGeneratorConsumptionAnalysisVsGeneration/actions';
import { getGenerationSources } from '../../proposalGeneratorConsumptionAnalysisVsGeneration/helpers';
import * as selectors from '../../proposalGeneratorConsumptionAnalysisVsGeneration/selectors';
import * as proposalGeneratorOneColumnSelectors from '../../proposalGeneratorOneColumn/selectors';

const FormContainer = ({
    defaultValues,
    isLocked,
    isSaving,
    match,
    offerConfigGeneration,
    resetForm,
    setGenerationSource,
}) => {
    const { t } = useTranslation();
    const { control, reset } = useForm({ defaultValues });
    const {
        params: { uid: proposalId },
    } = match;

    useEffect(() => () => resetForm(), []);

    useEffect(() => reset(defaultValues), [defaultValues]);

    const handleOnChange = ({ target: { value } }) =>
        setGenerationSource(proposalId, value, defaultValues);
    const options = getGenerationSources(offerConfigGeneration);
    const sxFormControl = { mb: '0!important' };

    return (
        <Box component="form" mb={3}>
            <ReactHookFormSelect
                control={control}
                disabled={isSaving || isLocked}
                label={t('Generation source')}
                name="generation_source"
                options={options}
                onChange={handleOnChange}
                sxFormControl={sxFormControl}
            />
        </Box>
    );
};

const mapStateToProps = createStructuredSelector({
    defaultValues: selectors.getInitialValues,
    isSaving: selectors.getIsSaving,
    offerConfigGeneration:
        proposalGeneratorOneColumnSelectors.getDataOfferConfigGeneration,
});

const mapDispatchToProps = (dispatch) => ({
    initializeForm: (values) => dispatch(actions.initializeForm(values)),
    resetForm: () => dispatch(actions.resetForm()),
    setGenerationSource: (proposalId, source, previousValues) =>
        dispatch(
            actions.setGenerationSource(proposalId, source, previousValues)
        ),
});

FormContainer.propTypes = {
    defaultValues: PropTypes.object,
    isLocked: PropTypes.bool,
    isSaving: PropTypes.bool,
    match: PropTypes.object,
    offerConfigGeneration: PropTypes.object,
    resetForm: PropTypes.func,
    setGenerationSource: PropTypes.func,
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withRouter
)(FormContainer);
