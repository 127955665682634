import { getOfferInfo } from 'common/api/v1/offerInfoPatern';

import {
    FETCH_OFFER_PATERN,
    FETCH_OFFER_PATERN_FAILURE,
    FETCH_OFFER_PATERN_SUCCESS,
} from '../actionTypes';
import { proposalGeneratorActions } from '../reducer';

export default (id) => (dispatch) => {
    dispatch(proposalGeneratorActions[FETCH_OFFER_PATERN]());

    getOfferInfo(id)
        .then((response) =>
            dispatch(
                proposalGeneratorActions[FETCH_OFFER_PATERN_SUCCESS](
                    response.data
                )
            )
        )
        .catch((error) =>
            dispatch(
                proposalGeneratorActions[FETCH_OFFER_PATERN_FAILURE](error)
            )
        );
};
