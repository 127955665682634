export const NAME = 'ZAP-MODULE';

export const ENTITIES = {
    CREDENTIAL: 'credential',
    RPU: 'rpu',
};

export const DOCUMENT_TYPES = {
    ALL: -1,
    PDF: 0,
    XML: 1,
};

export const MAX_ATTEMPTS = {
    [ENTITIES.CREDENTIAL]: 2,
    [ENTITIES.RPU]: 3,
};

export const STATUS = {
    ERROR: 1,
    SUCCESS: 0,
};

export const STATUS_CONFIG = {
    [STATUS.ERROR]: { color: '#d63031', label: 'Error' },
    [STATUS.SUCCESS]: { color: '#00b894', label: 'Success' },
};
