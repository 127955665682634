import connectSocket from '../connectSocket';
import { PREFIX } from '../constants';
import disconnectSocket from '../disconnectSocket';

const ENTITY = 'company';
const baseURL = process.env.REACT_APP_SOCKET_URL;

export const connect = (companyId) =>
    connectSocket(`${baseURL}/${companyId}/${ENTITY}/`, PREFIX);
export const disconnect = () => disconnectSocket(PREFIX);
