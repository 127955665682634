import i18next from 'i18next';
import get from 'lodash/get';

import alerts from 'common/modules/alerts';
import { getDefaultName } from 'common/utils/helpers';
import { getIsSelfConsumption } from 'common/utils/helpers/rates';
import {
    getIsMultibranchesAccount,
    getMainBranchOffice,
} from 'common/utils/helpers/session';

import { prepareUpdate } from '../../projectConsumptionCard/actions';
import * as projectConsumptionCardSelectors from '../../projectConsumptionCard/selectors';
import * as recordSelectors from '../../record/selectors';

import initializeValues from './initializeValues';
import openCommercialOfferModal from './openCommercialOfferModal';

export default ({ commercialOffers = [], projectName = '' } = {}) =>
    (dispatch, getState) => {
        const state = getState();
        const consumptionEnergyData =
            projectConsumptionCardSelectors.getDataConsumptionEnergy(state);
        const contact = recordSelectors.getDataContact(state);
        const isMultiBranches = getIsMultibranchesAccount();

        const {
            default_certified_rate_scheme_compensation,
            default_custom_rate_scheme_compensation,
        } = consumptionEnergyData || {};

        if (
            !consumptionEnergyData?.consumption_profile &&
            getIsSelfConsumption(
                default_certified_rate_scheme_compensation ||
                    default_custom_rate_scheme_compensation
            )
        ) {
            const dataAlert = {
                confirmText: i18next.t('Update'),
                title: i18next.t('Warning'),
                messages: [
                    i18next.t(
                        'The project does not have a Consumption Profile to carry out the Self-consumption simulation'
                    ),
                ],
                onSuccess: () => dispatch(prepareUpdate()),
                type: alerts.ALERT_TYPE_CONFIRM,
                variant: 'warning',
            };
            dispatch(alerts.actions.show(dataAlert));
            return;
        }

        const name = getDefaultName({
            arrayItems: commercialOffers,
            preffix: i18next.t('Proposal'),
            projectName,
        });

        let branchOffice = get(contact, 'branch_office.id', '');
        if (!isMultiBranches && !branchOffice)
            branchOffice = getMainBranchOffice();

        dispatch(
            initializeValues({
                branch_office: branchOffice,
                is_multibranch: isMultiBranches,
                name: name,
            })
        );
        dispatch(openCommercialOfferModal(true));
    };
