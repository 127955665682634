import i18next from 'i18next';
import get from 'lodash/get';

import { getDefaultName } from 'common/utils/helpers';

import * as recordSelectors from '../../record/selectors';

import initializeValues from './initializeValues';
import openModal from './openModal';

export default ({ projectName = '', summaries = [], value = false } = {}) =>
    (dispatch, getState) => {
        const state = getState();
        const contact = recordSelectors.getDataContact(state);

        const name = getDefaultName({
            arrayItems: summaries,
            preffix: i18next.t('Summary'),
            projectName,
        });
        dispatch(
            initializeValues({
                branch_office:
                    contact.branch_office === null
                        ? 'company'
                        : get(contact, 'branch_office.id', ''),
                name,
            })
        );
        dispatch(openModal(value));
    };
