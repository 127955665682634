import React from 'react';

import get from 'lodash/get';
import PropTypes from 'prop-types';

import { handleCloseDrawer } from '../helpers';

import DataSheetsCard from './DataSheetsCard';
import EditionButtons from './EditionButtons';
import ElectricBillCard from './ElectricBillCard';
import FinancialCard from './FinancialCard';
import ProposalCard from './ProposalCard';
import ShareCard from './ShareCard';
import SmartDocumentsCard from './SmartDocumentsCard';

const SupportMenuContent = ({
    canModify,
    downloadFormat,
    editionDisabled,
    handleClickDatasheetItem,
    handleClickDocumentItem,
    handleClickDownload,
    handleClickEditButton,
    handleClickElectricBillItem,
    handleClickPrepareRegenerateFinancingTemplate,
    handleClickPrepareRegenerateSmartDocumentTemplate,
    handleOnPrepareConverToTemplate,
    handleOnPrepareRegenerateTemplate,
    handleUpdateTemplate,
    isApproved,
    isDownloading,
    isFetching,
    isUpdatingCustomTemplate,
    offerDatasheets,
    offerInfoPatern,
    offerSmartDocuments,
    prepareRequest,
    proposalId,
    proposalName,
    restorePages,
    selectedDocument,
    setDownloadFormat,
    setEditionDisabled,
    swipeableDrawerRef,
    templateType,
}) => {
    const isDisabled =
        !editionDisabled ||
        isDownloading ||
        isFetching ||
        isUpdatingCustomTemplate;

    return (
        <>
            <EditionButtons
                canModify={canModify}
                editionDisabled={editionDisabled}
                handleClickCancel={() => {
                    restorePages();
                    setEditionDisabled(true);
                    handleCloseDrawer(swipeableDrawerRef);
                }}
                handleClickSave={() => {
                    handleUpdateTemplate();
                    handleCloseDrawer(swipeableDrawerRef);
                }}
                isUpdatingCustomTemplate={isUpdatingCustomTemplate}
                setEditionDisabled={setEditionDisabled}
            />

            <ShareCard
                canModify={canModify}
                downloadFormat={downloadFormat}
                handleClickDownload={handleClickDownload}
                isApproved={isApproved}
                isDisabled={isDisabled}
                isEnablePermalink={get(offerInfoPatern, 'hasPermalink', false)}
                permalink={get(offerInfoPatern, 'permalink', null)}
                hasProjectProfiles={get(
                    offerInfoPatern,
                    'hasProjectProfiles',
                    false
                )}
                proposalId={proposalId}
                proposalName={proposalName}
                selectedDocument={selectedDocument}
                setDownloadFormat={setDownloadFormat}
            />

            <ProposalCard
                handleClickDocumentItem={handleClickDocumentItem}
                handleClickEditButton={handleClickEditButton}
                handleOnPrepareRegenerateTemplate={
                    handleOnPrepareRegenerateTemplate
                }
                handleOnPrepareConverToTemplate={
                    handleOnPrepareConverToTemplate
                }
                isApproved={isApproved}
                isDisabled={isDisabled}
                proposalId={proposalId}
                proposalName={proposalName}
                selectedDocumentId={get(selectedDocument, 'id', null)}
            />

            <ElectricBillCard
                electricBills={get(offerInfoPatern, 'electricBills', [])}
                handleClickElectricBillItem={handleClickElectricBillItem}
                isDisabled={isDisabled}
                selectedDocumentId={get(selectedDocument, 'id', null)}
            />

            <FinancialCard
                contactId={get(offerInfoPatern, 'contactId', null)}
                creditStatus={get(offerInfoPatern, 'creditStatus', null)}
                financing={get(offerInfoPatern, 'financing', {})}
                handleClickDatasheetItem={handleClickDatasheetItem}
                handleClickDocumentItem={handleClickDocumentItem}
                handleClickPrepareRegenerateFinancingTemplate={
                    handleClickPrepareRegenerateFinancingTemplate
                }
                hasClosedCredit={get(offerInfoPatern, 'hasClosedCredit', false)}
                isArchived={get(offerInfoPatern, 'is_archived', false)}
                isDisabled={isDisabled}
                prepareRequest={prepareRequest}
                projectId={get(offerInfoPatern, 'projectId', null)}
                proposalId={proposalId}
                selectedDocumentId={get(selectedDocument, 'id', null)}
            />

            <SmartDocumentsCard
                canModify={canModify}
                handleClickDocumentItem={handleClickDocumentItem}
                handleClickEditButton={handleClickEditButton}
                handleClickPrepareRegenerateSmartDocumentTemplate={
                    handleClickPrepareRegenerateSmartDocumentTemplate
                }
                isApproved={isApproved}
                isDisabled={isDisabled}
                offerSmartDocuments={offerSmartDocuments}
                projectId={get(offerInfoPatern, 'projectId', null)}
                projectName={get(offerInfoPatern, 'projectName', '')}
                proposalDocumentDefault={{
                    id: proposalId,
                    name: proposalName,
                    type: templateType,
                }}
                proposalId={proposalId}
                selectedDocumentId={get(selectedDocument, 'id', null)}
            />

            <DataSheetsCard
                handleClickDatasheetItem={handleClickDatasheetItem}
                isDisabled={isDisabled}
                offerDatasheets={offerDatasheets}
                selectedDocumentId={get(selectedDocument, 'id', null)}
            />
        </>
    );
};

SupportMenuContent.propTypes = {
    canModify: PropTypes.bool,
    downloadFormat: PropTypes.number,
    editionDisabled: PropTypes.bool,
    handleClickDatasheetItem: PropTypes.func,
    handleClickDocumentItem: PropTypes.func,
    handleClickDownload: PropTypes.func,
    handleClickEditButton: PropTypes.func,
    handleClickElectricBillItem: PropTypes.func,
    handleClickPrepareRegenerateFinancingTemplate: PropTypes.func,
    handleClickPrepareRegenerateSmartDocumentTemplate: PropTypes.func,
    handleOnPrepareConverToTemplate: PropTypes.func,
    handleOnPrepareRegenerateTemplate: PropTypes.func,
    handleUpdateTemplate: PropTypes.func,
    isApproved: PropTypes.bool,
    isDownloading: PropTypes.bool,
    isFetching: PropTypes.bool,
    isUpdatingCustomTemplate: PropTypes.bool,
    offerDatasheets: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    offerInfoPatern: PropTypes.object,
    offerSmartDocuments: PropTypes.array,
    prepareRequest: PropTypes.func,
    proposalId: PropTypes.string,
    proposalName: PropTypes.string,
    restorePages: PropTypes.func,
    selectedDocument: PropTypes.object,
    setDownloadFormat: PropTypes.func,
    setEditionDisabled: PropTypes.func,
    swipeableDrawerRef: PropTypes.object,
    templateType: PropTypes.number,
};

export default SupportMenuContent;
