import { NORMAL_SIZE_DRAWER, SMALL_SIZE_DRAWER } from './constants';

export const getDrawerSizeInPX = (drawerSize = 'normal') =>
    drawerSize === 'normal' ? NORMAL_SIZE_DRAWER : SMALL_SIZE_DRAWER;

export const handleOnMouseHover = ({ breakpoint, e, handler }) => {
    const isMobile = ['xs', 'sm', 'md'].includes(breakpoint);
    if (isMobile) return;
    if (handler) handler(e);
};
