import React from 'react';

import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Alert, Box, Button, Grid, Typography } from 'sunwise-ui';

import { ReactHookFormTextarea } from 'common/components/form/bootstrap';
import ShowErrors from 'common/components/ShowErrors';
import yupResolver from 'common/utils/yupResolver';

import * as actions from '../actions';
import * as selectors from '../selectors';
import validate from '../validate';

const CommentForm = ({ closeModal, errors, initialValues, isSaving, save }) => {
    const { t } = useTranslation();

    const { control, handleSubmit, watch } = useForm({
        defaultValues: initialValues,
        resolver: yupResolver(validate),
    });

    return (
        <form onSubmit={handleSubmit(save)}>
            <Alert severity="warning">
                <Typography variant="caption" fontWeight="bold">
                    {t(
                        'This proposal has financing in process, editing it will affect the status of the application'
                    )}
                    .
                </Typography>
            </Alert>
            <Typography mt={2} fontWeight="bold" variant="caption">
                {t('Please indicate the reason why you wish to edit')}.
            </Typography>

            <ReactHookFormTextarea
                control={control}
                fullWidth
                label={`${t('Comment')} *`}
                name="comment"
                rows={3}
                sx={{ mt: 2 }}
            />

            <Box textAlign="right">
                <Typography variant="caption">
                    {watch('comment')?.length}/250
                </Typography>
            </Box>

            <ShowErrors errors={errors} />

            <Grid container mt={1}>
                <Grid
                    item
                    xs
                    sx={{
                        display: 'flex',
                        flexDirection: { xs: 'column', md: 'row' },
                        justifyContent: { xs: 'center', md: 'right' },
                    }}
                >
                    <Button
                        color="secondary"
                        onClick={closeModal}
                        sx={{
                            width: { xs: '100%', md: 'auto' },
                            order: { xs: 2, md: 1 },
                            mr: { xs: 0, md: 2 },
                        }}
                        variant="text"
                    >
                        {t('Cancel')}
                    </Button>
                    <Button
                        disabled={isSaving}
                        sx={{
                            width: { xs: '100%', md: 'auto' },
                            order: { xs: 1, md: 2 },
                            mb: { xs: 2, md: 0 },
                        }}
                        type="submit"
                        variant="outlined"
                    >
                        {t('Save')}
                    </Button>
                </Grid>
            </Grid>
        </form>
    );
};

CommentForm.propTypes = {
    closeModal: PropTypes.func,
    errors: PropTypes.array,
    initialValues: PropTypes.object,
    isSaving: PropTypes.bool,
    save: PropTypes.func,
};

const mapStateToProps = createStructuredSelector({
    errors: selectors.getSaveCommentErrors,
    initialValues: selectors.getInitialValues,
    isSaving: selectors.getIsSavingComment,
});

const mapDispatchToProps = (dispatch) => ({
    save: (values, callback) => dispatch(actions.saveComment(values, callback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CommentForm);
