import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box } from 'sunwise-ui';

import { ReactHookFormSelect } from 'common/components/form/bootstrap';
import TitleWithDetail from 'common/components/TitleWithDetail';

import { getPlansToSelect } from '../helpers';

const PlanForm = ({ control, isLocked, plans, setValue }) => {
    const { t } = useTranslation();

    return (
        <>
            <Box sx={{ mb: 2 }}>
                <TitleWithDetail variant="body2">
                    {t('Plan', { count: 2 })}
                </TitleWithDetail>
            </Box>

            <ReactHookFormSelect
                control={control}
                disabled={isLocked}
                label={t('Plan type')}
                name="proposal_plan"
                onChange={({ target: { value } }) => {
                    const plan = plans.find((item) => item.id === value);
                    if (plan) {
                        setValue(
                            'custom_offer_template',
                            plan.custom_offer_template
                        );
                    }
                }}
                options={getPlansToSelect(plans)}
            />
        </>
    );
};

PlanForm.propTypes = {
    control: PropTypes.object,
    isLocked: PropTypes.bool,
    plans: PropTypes.array,
    setValue: PropTypes.func,
};

export default PlanForm;
