import { getItems } from 'common/api/v2/offerAdditional';

import updateProductsData from '../../proposalGeneratorQuote/actions/updateProductsData';
import {
    FETCH_OFFER_ADDITIONALS,
    FETCH_OFFER_ADDITIONALS_FAILURE,
    FETCH_OFFER_ADDITIONALS_SUCCESS,
} from '../actionTypes';
import { proposalGeneratorAdditionalTableActions } from '../reducer';

import initialValues from './initialValues';
import resetForm from './resetForm';

export default (id, name) => (dispatch) =>
    new Promise((resolve) => {
        dispatch(
            proposalGeneratorAdditionalTableActions[FETCH_OFFER_ADDITIONALS]()
        );
        getItems(id)
            .then((response) => {
                dispatch(resetForm());

                const values = response.data.data;

                dispatch(
                    proposalGeneratorAdditionalTableActions[
                        FETCH_OFFER_ADDITIONALS_SUCCESS
                    ](response.data.data)
                );

                dispatch(initialValues(values));
                dispatch(updateProductsData(name, values));
            })
            .catch((error) =>
                dispatch(
                    proposalGeneratorAdditionalTableActions[
                        FETCH_OFFER_ADDITIONALS_FAILURE
                    ](error?.response?.data?.errors)
                )
            )
            .finally(resolve);
    });
