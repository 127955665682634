import React from 'react';

import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
    Card,
    Grid,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
} from 'sunwise-ui';

import BulkEditionMenu from 'common/components/BulkEditionMenu';

import { getStatusOptions, getUserOptions } from '../helpers';

const BulkOperationsCard = ({
    callback,
    filterData,
    handleClickBulkItems,
    handleClickUpdateTracking,
    handleOnSelectStatus,
    handleOnSelectUser,
    selectedBulkOption,
    selectionModel,
    setSelectionModel,
    users,
}) => {
    const { t } = useTranslation();

    return (
        <Card sx={{ mb: '16px !important' }}>
            <Card.Body>
                <Grid alignItems="end" container justifyContent="end">
                    <Grid item md={9} lg={3} xs={18}>
                        <FormControl fullWidth size="small">
                            <InputLabel>{t('Status')}</InputLabel>
                            <Select
                                label={t('Status')}
                                onChange={(e) => handleOnSelectStatus(e.target)}
                                value={filterData.completed}
                                variant="standard"
                            >
                                {getStatusOptions().map(
                                    ({ label, value, disabled }) => (
                                        <MenuItem
                                            disabled={disabled}
                                            key={`status-${value}-${label}`}
                                            value={value}
                                        >
                                            {label}
                                        </MenuItem>
                                    )
                                )}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item md={9} lg={4} xs={18}>
                        <FormControl fullWidth size="small">
                            <InputLabel>{t('User')}</InputLabel>
                            <Select
                                label={t('User')}
                                onChange={(e) => handleOnSelectUser(e.target)}
                                value={filterData.userId}
                                variant="standard"
                            >
                                {getUserOptions(users).map(
                                    ({ label, value, disabled }) => (
                                        <MenuItem
                                            disabled={disabled}
                                            key={`user-${value}-${label}`}
                                            value={value}
                                        >
                                            {label}
                                        </MenuItem>
                                    )
                                )}
                            </Select>
                        </FormControl>
                    </Grid>
                    {!isEmpty(selectionModel) && (
                        <Grid
                            item
                            xs={18}
                            lg={4}
                            sx={{ order: { xs: 2, md: 1 } }}
                        >
                            <BulkEditionMenu
                                items={[
                                    {
                                        label: t('Complete'),
                                        handleClick: () =>
                                            handleClickBulkItems(
                                                selectionModel,
                                                callback
                                            ),
                                        visible: true,
                                    },
                                    {
                                        label: t('Remember again'),
                                        handleClick: () =>
                                            handleClickUpdateTracking(
                                                selectionModel,
                                                callback
                                            ),
                                        visible: true,
                                    },
                                ]}
                                prefix={t('Follow-up', {
                                    count: selectionModel.length,
                                }).toLocaleLowerCase()}
                                selectedBulkOption={selectedBulkOption}
                                selectionModel={selectionModel}
                                setSelectionModel={setSelectionModel}
                            />
                        </Grid>
                    )}
                </Grid>
            </Card.Body>
        </Card>
    );
};

BulkOperationsCard.propTypes = {
    callback: PropTypes.func,
    filterData: PropTypes.object,
    handleClickBulkItems: PropTypes.func,
    handleClickUpdateTracking: PropTypes.func,
    handleOnSelectStatus: PropTypes.func,
    handleOnSelectUser: PropTypes.func,
    selectedBulkOption: PropTypes.string,
    selectionModel: PropTypes.array,
    setSelectionModel: PropTypes.func,
    users: PropTypes.array,
};

export default BulkOperationsCard;
