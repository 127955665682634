import {
    createSupportContact,
    updateSupportContact,
} from 'common/api/v1/contactSupport';
import { showReponseErrorsAsAlert } from 'common/utils/helpers';
import showToast from 'common/utils/showToast';

import {
    SAVE_SUPPORT_CONTACT,
    SAVE_SUPPORT_CONTACT_FAILURE,
    SAVE_SUPPORT_CONTACT_SUCCESS,
} from '../actionTypes';
import { supportContactFormActions } from '../reducer';
import * as selectors from '../selectors';

import closeModal from './closeModal';
import fetchSupportContact from './fetchSupportContact';

export default (values) => {
    return (dispatch, getState) => {
        const state = getState();
        const contactId = selectors.getContactId(state);

        const newValues = {
            contact: contactId,
            company_name: values.company_name,
            company_position: values.company_position,
            emails: JSON.stringify(
                values.emails.map((email) => ({
                    ...email,
                    email_type: email.email_type ? email.email_type : 0,
                }))
            ),
            first_lastname: values.first_lastname,
            name: values.name,
            second_lastname: values.second_lastname,
            telephones: JSON.stringify(
                values.telephones.map((phone) => ({
                    ...phone,
                    phone_type: phone.phone_type ? phone.phone_type : 0,
                }))
            ),
        };

        dispatch(supportContactFormActions[SAVE_SUPPORT_CONTACT]());

        const save = values.id
            ? () => updateSupportContact(newValues, values.id)
            : () => createSupportContact(newValues);

        save()
            .then((response) => {
                dispatch(
                    supportContactFormActions[SAVE_SUPPORT_CONTACT_SUCCESS](
                        response.data
                    )
                );
                dispatch(closeModal());
                dispatch(fetchSupportContact());

                showToast();
            })
            .catch((error) => {
                dispatch(
                    supportContactFormActions[SAVE_SUPPORT_CONTACT_FAILURE](
                        error?.response?.data?.errors
                    )
                );
                showReponseErrorsAsAlert(dispatch, error?.response);
            });
    };
};
