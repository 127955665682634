import React, { useEffect, useState } from 'react';

import AddIcon from '@mui/icons-material/Add';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import {
    Button,
    Container as MaterialContainer,
    Grid,
    UiDialog as Dialog,
} from 'sunwise-ui';

import { HeaderContainer, TitleIcon } from 'common/components';
import * as customColumnsSelectors from 'common/modules/customColumns/selectors';

import trackingFormModal from '../trackingFormModal';
import * as trackingFormModalActions from '../trackingFormModal/actions';
import * as trackingsActions from '../trackings/actions';
import * as trackingsSelectors from '../trackings/selectors';

import * as actions from './actions';
import ListItems from './components/ListItems';
import TrackingForm from './components/TrackingForm';
import * as selectors from './selectors';

const Container = ({
    contactsTrackings,
    fetchUsers,
    filterTrackings,
    handleClickBulkItems,
    handleClickUpdateTracking,
    handleCloseModalTracking,
    isFetching,
    isFetchingTableSettings,
    isOpenModalTracking,
    openTrackingFormModal,
    paginationItems,
    tableSettings,
    users,
}) => {
    const { t } = useTranslation();
    const [filterData, setFilterData] = useState({
        completed: 'False',
        orderBy: 'notification_at',
        page: 0,
        pageSize: 25,
        sortBy: 'asc',
        userId: '',
    });
    useEffect(() => {
        filterTrackings(filterData);
        fetchUsers();
    }, []);

    return (
        <MaterialContainer maxWidth={false} sx={{ mb: 2 }}>
            <HeaderContainer>
                <Grid item xs={18} md={12}>
                    <TitleIcon title={t('Follow-up', { count: 2 })} />
                </Grid>
                <Grid item xs={18} md={6} textAlign="right">
                    <Button
                        endIcon={<AddIcon fontSize="small" />}
                        onClick={() => openTrackingFormModal()}
                    >
                        {t('Add follow-up')}
                    </Button>
                </Grid>
            </HeaderContainer>
            <Grid container>
                <Grid item xs={18}>
                    <ListItems
                        filterData={filterData}
                        filterItems={filterTrackings}
                        handleClickBulkItems={handleClickBulkItems}
                        handleClickUpdateTracking={handleClickUpdateTracking}
                        isFetching={isFetching || isFetchingTableSettings}
                        isOpenModalTracking={isOpenModalTracking}
                        items={contactsTrackings}
                        paginationData={paginationItems}
                        setFilterData={setFilterData}
                        tableSettings={tableSettings}
                        users={users}
                    />
                </Grid>
            </Grid>
            <Dialog
                onClose={() => handleCloseModalTracking()}
                open={isOpenModalTracking}
                size="sm"
                title={t('Remember again')}
            >
                <TrackingForm filterData={filterData} />
            </Dialog>
            <trackingFormModal.Container />
        </MaterialContainer>
    );
};

Container.propTypes = {
    contactsTrackings: PropTypes.array,
    fetchUsers: PropTypes.func,
    filterTrackings: PropTypes.func,
    handleClickBulkItems: PropTypes.func,
    handleClickUpdateTracking: PropTypes.func,
    handleCloseModalTracking: PropTypes.func,
    isFetching: PropTypes.bool,
    isFetchingTableSettings: PropTypes.bool,
    isOpenModalTracking: PropTypes.bool,
    openTrackingFormModal: PropTypes.func,
    paginationItems: PropTypes.object,
    tableSettings: PropTypes.array,
    users: PropTypes.array,
};

const mapStateToProps = createStructuredSelector({
    contactsTrackings: selectors.getContactsTrackingData,
    isFetching: selectors.getIsFetchingContactsTrackings,
    isFetchingTableSettings: customColumnsSelectors.getIsFetchingTableSettings,
    isOpenModalTracking: selectors.getIsOpenModalTracking,
    paginationItems: selectors.getContactsTrackingPagination,
    tableSettings: customColumnsSelectors.getTableSettingsData,
    users: trackingsSelectors.getUsersData,
});

const mapDispatchToProps = (dispatch) => ({
    filterTrackings: (filterData) =>
        dispatch(actions.filterTrackings(filterData)),
    fetchUsers: () => dispatch(trackingsActions.fetchUsers()),
    handleClickBulkItems: (ids, callback, value) =>
        dispatch(actions.bulkItems(ids, callback, value)),
    handleClickUpdateTracking: (ids, callback) =>
        dispatch(actions.prepareUpdateTracking(ids, callback)),
    handleCloseModalTracking: () => dispatch(actions.closeModalTracking()),
    openTrackingFormModal: () =>
        dispatch(trackingFormModalActions.openModal(true)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Container);
