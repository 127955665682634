import React from 'react';

import i18next from 'i18next';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { ReactHookFormSelect } from 'common/components/form/bootstrap';
import { getIsMultibranchesAccount } from 'common/utils/helpers/session';

import { getBrachOfficesOptionsWithoutGlobal } from '../helpers';
import * as selectors from '../selectors';

const MainBranchOfficeSelectorRHF = ({
    branchesItems,
    className,
    control,
    disabled,
    hideCompany,
    isFetching,
    label,
    name,
}) => {
    const { t } = useTranslation();
    if (!getIsMultibranchesAccount()) return null;
    return (
        <ReactHookFormSelect
            className={className}
            control={control}
            disabled={isFetching || disabled}
            label={label}
            name={name}
            options={[
                {
                    disabled: true,
                    label: t('Select a branch office'),
                    value: '',
                },
                ...getBrachOfficesOptionsWithoutGlobal(
                    branchesItems,
                    hideCompany
                ),
            ]}
        />
    );
};

MainBranchOfficeSelectorRHF.defaultProps = {
    hideCompany: false,
    label: i18next.t('Branch office'),
    name: 'branch_office',
};

MainBranchOfficeSelectorRHF.propTypes = {
    branchesItems: PropTypes.array,
    className: PropTypes.string,
    control: PropTypes.object,
    disabled: PropTypes.bool,
    hideCompany: PropTypes.bool,
    isFetching: PropTypes.bool,
    label: PropTypes.string,
    name: PropTypes.string,
};

const mapStateToProps = createStructuredSelector({
    branchesItems: selectors.getBranchesItemsSelect,
    isFetching: selectors.getIsFetchingBranches,
});

export default connect(mapStateToProps, null)(MainBranchOfficeSelectorRHF);
