import i18next from 'i18next';
import { createSelector } from 'reselect';

import orm from 'common/orm';
import { arraytoDictionary } from 'common/utils/helpers';

import { NAME } from './constants';

export const getModel = (state) => state[NAME];

export const getEntitiesSession = createSelector(getModel, (state) =>
    orm.session(state.entities)
);

export const getInitialValues = createSelector(
    getModel,
    (model) => model.initialValues
);

export const getUploadFile = createSelector(
    getModel,
    (model) => model.uploadFile
);

export const getIsUploading = createSelector(
    getUploadFile,
    (uploadFile) => uploadFile.isLoading
);

export const getErrors = createSelector(
    getUploadFile,
    (uploadFile) => uploadFile.errors
);

export const getIsVisibleModal = createSelector(
    getModel,
    (model) => model.isVisibleModal
);

export const getElementToDelete = createSelector(
    getModel,
    (model) => model.elementToDelete
);

export const getFetchDocuments = createSelector(
    getModel,
    (model) => model.fetchDocuments
);

export const getIsLoadingFetchDocuments = createSelector(
    getFetchDocuments,
    (fetchDocuments) => fetchDocuments.isLoading
);

export const getDocumentsData = createSelector(
    getEntitiesSession,
    ({ Document }) => {
        return Document.all()
            .toModelArray()
            .map((c) => ({
                ...c.ref,
                documentType: c.documentType && c.documentType.ref,
            }));
    }
);

export const getDocumentTypesUsed = createSelector(
    getDocumentsData,
    (documents) => documents.map(({ documentTypeId }) => documentTypeId)
);

export const getDocumentsProjectType = createSelector(
    getModel,
    (model) => model.documentsProjectType
);

export const getDocumentsProjectTypeData = createSelector(
    getEntitiesSession,
    ({ DocumentType }) => {
        return DocumentType.all().toRefArray();
    }
);

export const getDocumentsTypeDictionary = createSelector(
    getDocumentsProjectTypeData,
    (documentsTypeData) =>
        arraytoDictionary(
            documentsTypeData.map((item) => item),
            'id'
        )
);

export const getDocumentsTypeToSelect = createSelector(
    getDocumentsProjectTypeData,
    getInitialValues,
    getDocumentTypesUsed,
    (documentsTypeData, initialValues, documentTypesUsed) => {
        if (initialValues.id) {
            return documentsTypeData
                .filter(
                    ({ id, isActive }) =>
                        initialValues.document_type === id ||
                        (isActive && !documentTypesUsed.includes(id))
                )
                .map((documentType) => ({
                    value: documentType.id,
                    label: documentType.name,
                    disabled: !documentType.isActive,
                }));
        }
        return [
            {
                value: '',
                label: i18next.t('Select type of document'),
                disabled: true,
            },
            ...documentsTypeData
                .filter(
                    ({ id, isActive }) =>
                        isActive && !documentTypesUsed.includes(id)
                )
                .map((documentType) => ({
                    value: documentType.id,
                    label: documentType.name,
                })),
        ];
    }
);

export const getIsOpenConfirmModal = createSelector(
    getModel,
    (model) => model.isOpenConfirmModal
);

export const getDataConfirmModal = createSelector(
    getModel,
    (model) => model.dataConfirmModal || {}
);

export const getDeleteDocument = createSelector(
    getModel,
    (model) => model.deleteDocument
);

export const getIsDocumentDeleting = createSelector(
    getDeleteDocument,
    (deleteDocument) => deleteDocument.isDeleting
);
