import React, { useEffect } from 'react';

import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import EditIcon from '@mui/icons-material/Edit';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Card, Typography } from 'sunwise-ui';

import DropdownCardMenu from 'common/components/cards/DropdownCardMenu';

import * as credentialIntegrationsActions from '../credentialIntegrations/actions';

import * as actions from './actions';
import ApiSourceModal from './components/ApiSourceModal';
import CardContent from './components/CardContent';
import ExternalSourceModal from './components/ExternalSourceModal';
import SettingsModal from './components/SettingsModal';
import * as selectors from './selectors';

const Container = ({
    canDelete,
    canModify,
    externalSources,
    fetchExternalSources,
    fetchPowerStations,
    fetchProviders,
    powerStationsData,
    prepareUpdateSettings,
    reset,
    resetCredentialIntegrations,
    selectedProjectId,
}) => {
    const { t } = useTranslation();

    useEffect(() => {
        fetchExternalSources(selectedProjectId);
        fetchPowerStations(selectedProjectId);
        fetchProviders();

        return () => {
            reset();
            resetCredentialIntegrations();
        };
    }, [selectedProjectId]);

    const hasPowerStationData = !isEmpty(powerStationsData);

    return (
        <>
            <Card>
                <Card.Header
                    sx={{
                        alignItems: 'center',
                        display: 'flex',
                        justifyContent: 'space-between',
                    }}
                >
                    <Typography variant="body1" fontWeight="bold">
                        {t('Measurement source', { count: 2 })}
                    </Typography>

                    <DropdownCardMenu
                        items={[
                            {
                                label: t('Edit settings'),
                                handleClick: () =>
                                    prepareUpdateSettings(
                                        selectedProjectId,
                                        powerStationsData?.id
                                    ),
                                icon: <EditIcon />,
                                visible: canModify,
                            },
                            {
                                label: t('Go to subscription'),
                                icon: <ArrowForwardIcon />,
                                handleClick: () => {},
                                visible: false,
                            },
                        ]}
                        visible={canModify}
                    />
                </Card.Header>

                <CardContent
                    canModify={canModify}
                    canDelete={canDelete}
                    externalSources={externalSources}
                    hasPowerStationData={hasPowerStationData}
                    powerStations={powerStationsData?.projects}
                    selectedProjectId={selectedProjectId}
                />
            </Card>

            <ApiSourceModal sunwiseProjectId={selectedProjectId} />

            <ExternalSourceModal sunwiseProjectId={selectedProjectId} />

            <SettingsModal sunwiseProjectId={selectedProjectId} />
        </>
    );
};

Container.propTypes = {
    canDelete: PropTypes.bool,
    canModify: PropTypes.bool,
    externalSources: PropTypes.array,
    fetchExternalSources: PropTypes.func,
    fetchPowerStations: PropTypes.func,
    fetchProviders: PropTypes.func,
    powerStationsData: PropTypes.object,
    prepareUpdateSettings: PropTypes.func,
    reset: PropTypes.func,
    resetCredentialIntegrations: PropTypes.func,
    selectedProjectId: PropTypes.string,
};

const mapStateToProps = createStructuredSelector({
    externalSources: selectors.getExternalSourcesData,
    powerStationsData: selectors.getPowerStationsData,
});

const mapDispatchToProps = (dispatch) => ({
    fetchExternalSources: (uuid) =>
        dispatch(actions.fetchExternalSources(uuid)),
    fetchPowerStations: (sunwiseProject) =>
        dispatch(actions.fetchPowerStations(sunwiseProject)),
    fetchProviders: () =>
        dispatch(credentialIntegrationsActions.fetchProviders()),
    prepareUpdateSettings: (sunwiseProjectId, powerStationsDataId) =>
        dispatch(
            actions.prepareUpdateSettings(sunwiseProjectId, powerStationsDataId)
        ),
    reset: () => dispatch(actions.reset()),
    resetCredentialIntegrations: () =>
        dispatch(credentialIntegrationsActions.reset()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Container);
