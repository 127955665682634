import React, { useEffect, useState } from 'react';

import { cloneDeep, isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import {
    Alert,
    Box,
    Button,
    Card,
    Checkbox,
    UiDialog as Dialog,
    Grid,
    Typography,
} from 'sunwise-ui';

import * as actions from '../actions';
import * as selectors from '../selectors';

const ProductsModal = ({
    handleCloseProductsSettingsModal,
    handleSaveProductsSettings,
    initialValues,
    isOpenModal,
    setProductsSettingsModalIsOpen,
}) => {
    const [productsConfig, setProductsConfig] = useState([]);
    const { t } = useTranslation();

    useEffect(() => {
        if (!isEmpty(initialValues)) setProductsConfig(initialValues?.products);
    }, [initialValues]);

    const handleOnChangeActiveProduct = (index, value) => {
        const newProductsConfig = cloneDeep(productsConfig);
        newProductsConfig[index].visible = value || false;
        setProductsConfig(newProductsConfig);
    };
    const activeProducts = productsConfig?.some((item) => item.visible);
    return (
        <Dialog
            onClose={() => setProductsSettingsModalIsOpen(false)}
            onExited={handleCloseProductsSettingsModal}
            open={isOpenModal}
            size="xs"
            title={t('Product', { count: 2 })}
        >
            {productsConfig?.map((item, index) => (
                <Card key={index}>
                    <Card.Body>
                        <Grid container alignItems="center" spacing={2}>
                            <Grid item xs={3} sm={2}>
                                <Checkbox
                                    checked={item.visible}
                                    onChange={(e) =>
                                        handleOnChangeActiveProduct(
                                            index,
                                            e.target.checked
                                        )
                                    }
                                />
                            </Grid>
                            <Grid item xs>
                                <Typography variant="subtitle">
                                    {item.label}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Card.Body>
                </Card>
            ))}

            <Box hidden={activeProducts} my={1}>
                <Alert severity="warning">
                    <Typography variant="caption" fontWeight="bold">
                        {t('You must select at least one product')}
                    </Typography>
                </Alert>
            </Box>

            <Box mt={2} textAlign="right">
                <Button
                    disabled={!activeProducts}
                    onClick={() =>
                        handleSaveProductsSettings({
                            id: initialValues.id,
                            products: productsConfig,
                        })
                    }
                    sx={{ width: { xs: '100%', md: 'auto' } }}
                >
                    {t('Save changes')}
                </Button>
            </Box>
        </Dialog>
    );
};

const mapStateToProps = createStructuredSelector({
    initialValues: selectors.getInitialValuesProductsSettings,
    isOpenModal: selectors.getIsProductsSettingsModalOpen,
});

const mapDispatchToProps = (dispatch) => ({
    handleCloseProductsSettingsModal: () =>
        dispatch(actions.resetProductsSettingsInitialValues()),
    setProductsSettingsModalIsOpen: (isOpen) =>
        dispatch(actions.setProductsSettingsModalIsOpen(isOpen)),
});

ProductsModal.propTypes = {
    handleCloseProductsSettingsModal: PropTypes.func,
    handleSaveProductsSettings: PropTypes.func,
    initialValues: PropTypes.object,
    isOpenModal: PropTypes.bool,
    setProductsSettingsModalIsOpen: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductsModal);
