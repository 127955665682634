import React from 'react';

import FilterListIcon from '@mui/icons-material/FilterList';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { IconButton, Menu, MenuItem } from 'sunwise-ui';

import { POLYGON_MAP, POLYGON_OBSTACLE, POLYGON_SEGMENT } from '../constants';

const FilterSegments = ({
    segments,
    setSegmentsList,
    selectedType,
    setSelectedType,
}) => {
    const { t } = useTranslation();

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => setAnchorEl(null);

    const handleFilterItems = (segments, type) => {
        setAnchorEl(null);
        if (type === 'all') {
            setSegmentsList(segments);
            setSelectedType(type);
            return;
        }
        const items = segments.filter(
            (segment) => segment.type === parseInt(type)
        );
        setSegmentsList(items);
        setSelectedType(parseInt(type));
    };

    return (
        <>
            <IconButton onClick={handleClick}>
                <FilterListIcon />
            </IconButton>
            <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
                <MenuItem
                    onClick={() => handleFilterItems(segments, 'all')}
                    selected={'all' === selectedType}
                >
                    {t('All text', { count: 2 })}
                </MenuItem>
                <MenuItem
                    onClick={() => handleFilterItems(segments, POLYGON_SEGMENT)}
                    selected={POLYGON_SEGMENT === selectedType}
                >
                    {t('Segment', { count: 2 })}
                </MenuItem>
                <MenuItem
                    onClick={() =>
                        handleFilterItems(segments, POLYGON_OBSTACLE)
                    }
                    selected={POLYGON_OBSTACLE === selectedType}
                >
                    {t('Obstacle', { count: 2 })}
                </MenuItem>
                <MenuItem
                    onClick={() => handleFilterItems(segments, POLYGON_MAP)}
                    selected={POLYGON_MAP === selectedType}
                >
                    {t('Image', { count: 2 })}
                </MenuItem>
            </Menu>
        </>
    );
};

FilterSegments.propTypes = {
    segments: PropTypes.array,
    selectedType: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    setSegmentsList: PropTypes.func,
    setSelectedType: PropTypes.func,
};

export default FilterSegments;
