import { updateType } from 'common/api/v1/contacts';
import { showReponseErrorsAsAlert } from 'common/utils/helpers';
import showToast from 'common/utils/showToast';

import {
    UPDATE_CONTACTS_TYPE,
    UPDATE_CONTACTS_TYPE_FAILURE,
} from '../actionTypes';
import { actions } from '../reducer';
import * as selectors from '../selectors';

import fetchContact from './fetchContact';

export default (type) => (dispatch, getState) => {
    const state = getState();
    const dataContact = selectors.getDataContact(state);
    const data = { status_contact: type.value };

    dispatch(actions[UPDATE_CONTACTS_TYPE]());

    updateType(data, dataContact.id)
        .then(() => {
            dispatch(fetchContact(dataContact.id, false));

            showToast();
        })
        .catch((error) => {
            dispatch(
                actions[UPDATE_CONTACTS_TYPE_FAILURE](
                    error?.response?.data?.errors
                )
            );
            showReponseErrorsAsAlert(dispatch, error?.response);
        });
};
