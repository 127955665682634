import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Grid } from 'sunwise-ui';

import {
    ReactHookFormIntlNumberInput,
    ReactHookFormSelect2,
    ReactHookFormSelect,
} from 'common/components/form/bootstrap';
import {
    DOMESTIC_RATES,
    RATES_WITH_POWER_FACTOR,
} from 'common/constants/rates';

import * as afterSalesSettingsActions from '../../../afterSalesSettings/actions';
import {
    PERIODICITY_TYPES,
    SUBSIDY_RATES,
} from '../../../afterSalesSettings/constants';
import { getPeriodicityOptions } from '../../../afterSalesSettings/helpers';
import * as afterSalesSettingsSelectors from '../../../afterSalesSettings/selectors';

const GeneralInformation = ({
    control,
    disabled,
    fetchScheduleRateConfiguration,
    isLoadingRates,
    isLoadingSummerMonths,
    rateDivision,
    rates,
    ratesDictionary,
    selectedRate,
    setValue,
    summerMonths,
}) => {
    const { t } = useTranslation();

    const handleOnChangeRate = (value) => {
        const newRate = ratesDictionary[value];
        const periodicityType = PERIODICITY_TYPES[newRate?.periodicity_type];
        setValue('is_bimonthly', periodicityType);
        fetchScheduleRateConfiguration({
            certified: newRate?.certified,
            rate: value,
            rate_division: rateDivision,
        });
    };

    return (
        <Grid container>
            <Grid item xs={9}>
                <ReactHookFormSelect2
                    control={control}
                    disabled={isLoadingRates || disabled}
                    label={t('Rate')}
                    name={'rate'}
                    onChange={handleOnChangeRate}
                    options={rates}
                    variant="standard"
                />
            </Grid>

            <Grid item xs={18} lg={9}>
                <ReactHookFormSelect
                    control={control}
                    disabled={disabled}
                    label={t('Period')}
                    name={'is_bimonthly'}
                    options={getPeriodicityOptions(selectedRate)}
                    variant="standard"
                />
            </Grid>

            {selectedRate && selectedRate.certified && (
                <>
                    {RATES_WITH_POWER_FACTOR.includes(selectedRate.name) && (
                        <Grid item xs={18} sm={9}>
                            <ReactHookFormIntlNumberInput
                                allowNegativeValue={false}
                                append="%"
                                control={control}
                                disabled={disabled}
                                fullWidth
                                label={t('Power factor')}
                                max={100}
                                min={0}
                                name={'power_factor'}
                                variant="standard"
                            />
                        </Grid>
                    )}

                    {selectedRate.name === 'DAC' && (
                        <Grid item xs={18} lg={9}>
                            <ReactHookFormSelect2
                                control={control}
                                disabled={disabled}
                                isClearable={false}
                                label={t('Subsidized rate').concat(' *')}
                                name={'subsidy_rate'}
                                options={[
                                    { label: '', options: SUBSIDY_RATES },
                                ]}
                                variant="standard"
                            />
                        </Grid>
                    )}

                    {DOMESTIC_RATES.includes(selectedRate.name) && (
                        <Grid item xs sm={9}>
                            <ReactHookFormSelect2
                                control={control}
                                disabled={disabled}
                                isClearable={false}
                                isLoading={isLoadingSummerMonths}
                                label={t('Start of summer').concat(' *')}
                                name={'rate_division_summer'}
                                options={[{ label: '', options: summerMonths }]}
                                variant="standard"
                            />
                        </Grid>
                    )}
                </>
            )}
        </Grid>
    );
};

GeneralInformation.propTypes = {
    control: PropTypes.object,
    disabled: PropTypes.bool,
    fetchScheduleRateConfiguration: PropTypes.func,
    isLoadingRates: PropTypes.bool,
    isLoadingSummerMonths: PropTypes.bool,
    rateDivision: PropTypes.string,
    rates: PropTypes.array,
    ratesDictionary: PropTypes.object,
    selectedRate: PropTypes.object,
    setValue: PropTypes.func,
    summerMonths: PropTypes.array,
};

const mapStateToProps = createStructuredSelector({
    rateDivision: afterSalesSettingsSelectors.getAfterSalesRateDivision,
    isLoadingRates: afterSalesSettingsSelectors.getRatesIsLoading,
    isLoadingSummerMonths: afterSalesSettingsSelectors.getSummerMonthsIsLoading,
    rates: afterSalesSettingsSelectors.getRatesForSelect,
    summerMonths: afterSalesSettingsSelectors.getSummerMonthsForSelect,
});

const mapDispatchToProps = (dispatch) => ({
    fetchScheduleRateConfiguration: (params) =>
        dispatch(
            afterSalesSettingsActions.fetchScheduleRateConfiguration(params)
        ),
});

export default connect(mapStateToProps, mapDispatchToProps)(GeneralInformation);
