import { createProject } from 'common/api/afterSales/projects';
import { showFastApiErrorsAsAlert } from 'common/utils/helpers';
import showToast from 'common/utils/showToast';

import * as afterSalesProjectsStatusActions from '../../afterSalesProjectsStatus/actions';
import * as profileSelectors from '../../profile/selectors';
import {
    TOGGLE_ASSIGN_PROJECT,
    TOGGLE_ASSIGN_PROJECT_FAILURE,
    TOGGLE_ASSIGN_PROJECT_SUCCESS,
} from '../actionTypes';
import { powerStationsActions } from '../reducer';

import fetchPowerStations from './fetchPowerStations';
import setAssignProjectModalIsOpen from './setAssignProjectModalIsOpen';

export default ({ credentialId, sunwiseProject, powerStation }) =>
    (dispatch, getState) => {
        if (!credentialId || !sunwiseProject || !powerStation?.data) return;

        dispatch(powerStationsActions[TOGGLE_ASSIGN_PROJECT]());

        const newValues = {
            company_sk_id: credentialId,
            name: powerStation?.data?.station_name || powerStation?.data?.id,
            read_requirements: {
                station_id: powerStation?.data?.id,
                name: powerStation?.data?.station_name,
            },
            sunwise_project: sunwiseProject,
        };

        createProject(newValues)
            .then((response) => {
                dispatch(
                    powerStationsActions[TOGGLE_ASSIGN_PROJECT_SUCCESS](
                        response?.data
                    )
                );
                dispatch(setAssignProjectModalIsOpen(false));
                dispatch(
                    fetchPowerStations(credentialId, powerStation?.filter)
                );

                showToast();

                const companyId = profileSelectors.getUserCompanyId(getState());
                dispatch(afterSalesProjectsStatusActions.connect(companyId));
            })
            .catch((error) => {
                dispatch(
                    powerStationsActions[TOGGLE_ASSIGN_PROJECT_FAILURE](error)
                );

                showFastApiErrorsAsAlert(dispatch, error?.response);
            });
    };
