import { getItems } from 'common/api/v1/users';

import {
    FETCH_AGENTS,
    FETCH_AGENTS_FAILURE,
    FETCH_AGENTS_SUCCESS,
} from '../actionTypes';
import { contactFormActions } from '../reducer';

export default (branchOffice) => (dispatch) => {
    dispatch(contactFormActions[FETCH_AGENTS]());

    getItems(branchOffice)
        .then((response) =>
            dispatch(
                contactFormActions[FETCH_AGENTS_SUCCESS](response.data.data)
            )
        )
        .catch((error) =>
            dispatch(
                contactFormActions[FETCH_AGENTS_FAILURE](
                    error?.response?.data?.message
                )
            )
        );
};
