import i18next from 'i18next';

import { SET_ELEMENT_TO_DELETE } from '../actionTypes';
import { projectDocumentActions } from '../reducer';

import openConfirmModal from './openConfirmModal';
import setDataConfirmModal from './setDataConfirmModal';

export default (element) => (dispatch) => {
    dispatch(projectDocumentActions[SET_ELEMENT_TO_DELETE](element));
    dispatch(
        setDataConfirmModal({
            confirmText: i18next.t('Delete'),
            message: i18next.t(
                'Are you really sure to delete the document "{{name}}"?',
                {
                    name: element.name,
                }
            ),
            title: i18next.t('Confirmation'),
            type: 'warning',
        })
    );
    dispatch(openConfirmModal(true));
};
