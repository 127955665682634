import React, { useEffect, useRef, useState } from 'react';

import { debounce } from 'lodash';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Checkbox, DataGrid } from 'sunwise-ui';

import {
    CustomPagination,
    InputSearch,
    ProductNameFormatter,
} from 'common/components';
import { useBreakpoint } from 'common/hooks';

const CurrencyList = ({
    canDelete,
    data,
    filterItemsParent,
    handleSelectParent,
    handleUnselectParent,
    isFetching,
    pagination,
}) => {
    const { t } = useTranslation();
    const [rowCountState, setRowCountState] = useState(
        pagination?.rowCount || 0
    );
    const [filterData, setFilterData] = useState({
        orderBy: 'name',
        page: 0,
        searchText: '',
        sortBy: 'asc',
    });
    const [searchText, setSearchText] = useState('');
    const breakpoint = useBreakpoint();

    useEffect(() => {
        setRowCountState((prevRowCountState) =>
            pagination?.total_rows !== undefined
                ? pagination?.total_rows
                : prevRowCountState
        );
    }, [pagination?.total_rows, setRowCountState]);

    useEffect(() => {
        filterItemsParent(filterData);
    }, [filterData]);

    const FormatterActions = (_, row) => (
        <Checkbox
            checked={row.added}
            disabled={!row.can_remove || !canDelete || row.added}
            onChange={(e) => {
                if (e.target.checked) {
                    handleSelectParent(row, e.target);
                } else {
                    handleUnselectParent(row.id);
                }
            }}
        />
    );

    const handleOnSortModelChange = (sortModel) => {
        if (!sortModel[0]) return;

        const filter = {
            ...filterData,
            orderBy: sortModel[0].field.toLocaleLowerCase(),
            sortBy: sortModel[0].sort,
        };
        setFilterData(filter);
    };

    const handleOnPageChange = (page) => {
        const filter = { ...filterData, page: page };
        setFilterData(filter);
    };

    const onChangeSearch = (value, filterData) => {
        const filter = { ...filterData, page: 0, searchText: value };
        setFilterData(filter);
    };

    const handleOnChangeValue = useRef(debounce(onChangeSearch, 500)).current;

    const handleOnChangeSearch = (event) => {
        setSearchText(event.target.value);
        handleOnChangeValue(event.target.value, filterData);
    };

    let _columns = [
        {
            field: 'name',
            flex: 1,
            headerName: t('Name'),
            renderCell: (params) => {
                const { row } = params;
                return ProductNameFormatter(row.name, row);
            },
        },
        {
            field: 'symbol',
            flex: 1,
            headerName: t('Symbol'),
        },
        {
            field: 'abbreviation',
            flex: 1,
            headerName: t('ISO code'),
        },
    ];

    if (breakpoint === 'xs') {
        _columns = [
            {
                field: 'name',
                flex: 1,
                headerName: t('Name'),
                renderCell: (params) => {
                    const { row } = params;
                    return ProductNameFormatter(row.name, row);
                },
            },
        ];
    }

    _columns = [
        ..._columns,
        {
            field: '',
            flex: 1,
            headerName: t('Add'),
            renderCell: (params) => {
                const { row } = params;
                return FormatterActions(null, row);
            },
            sortable: false,
        },
    ];

    return (
        <>
            <InputSearch
                onChange={handleOnChangeSearch}
                placeholder={t('Filter by name, brand or model')}
                value={searchText}
            />

            <DataGrid
                autoHeight
                columns={_columns}
                components={{ Pagination: CustomPagination }}
                disableColumnMenu
                disableSelectionOnClick
                getRowHeight={() => 'auto'}
                loading={isFetching}
                onPageChange={handleOnPageChange}
                onSortModelChange={handleOnSortModelChange}
                page={pagination?.current_page - 1 || 0}
                pageSize={pagination?.per_page || 25}
                pagination
                paginationMode="server"
                rowCount={rowCountState}
                rows={data}
                sortingMode="server"
                sx={{ my: 2 }}
            />
        </>
    );
};

CurrencyList.propTypes = {
    canDelete: PropTypes.bool,
    data: PropTypes.array,
    filterItemsParent: PropTypes.func,
    handleSelectParent: PropTypes.func,
    handleUnselectParent: PropTypes.func,
    isFetching: PropTypes.bool,
    pagination: PropTypes.object,
};

export default CurrencyList;
