import React, { useEffect } from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { Container as MaterialContainer, Grid } from 'sunwise-ui';

import { HeaderContainer, TitleIcon } from 'common/components';
import { PERMISSION_LIST } from 'common/constants/permissionsV2';
import withPermissions from 'common/hocs/withPermissions';

import * as actions from './actions';
import DataForm from './components/DataForm';

const Container = ({ canModify, canView, fetchData }) => {
    const { t } = useTranslation();
    useEffect(() => {
        fetchData();
    }, []);

    if (!canView) return null;

    return (
        <MaterialContainer maxWidth={false}>
            <HeaderContainer>
                <Grid item xs>
                    <TitleIcon fontWeight="bold" title={t('Branding')} />
                </Grid>
            </HeaderContainer>
            <DataForm canModify={canModify} />
        </MaterialContainer>
    );
};

const mapDispatchToProps = (dispatch) => ({
    fetchData: () => dispatch(actions.fetchData()),
});

Container.propTypes = {
    canModify: PropTypes.bool,
    canView: PropTypes.bool,
    fetchData: PropTypes.func,
};

export default compose(
    connect(null, mapDispatchToProps),
    withPermissions(PERMISSION_LIST.BRANDING_PERMISSION)
)(Container);
