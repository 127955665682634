import React, { useEffect, useRef, useState } from 'react';

import AddIcon from '@mui/icons-material/Add';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import {
    Box,
    Button,
    Card,
    Grid,
    Typography,
    UiDialog as Dialog,
} from 'sunwise-ui';

import { TemporaryDrawer, TitleIcon } from 'common/components';
import { handleFileURL } from 'common/utils/helpers';

import * as actions from '../actions';
import * as selectors from '../selectors';

import AddAllianceForm from './AddAllianceForm';
import FileReview from './FileReview';
import SideCard from './SideCard';
import StyledButtonAddAlliance from './StyledButtonAddAlliance';

const Alliances = ({
    canModifyAlliances,
    fetchFinarcierProduct,
    fetchFinarciers,
    fetchFinarciersInstaller,
    filterData,
    finarciersInstaller,
    uploadedFile,
}) => {
    const { t } = useTranslation();
    const allianceDrawerRef = useRef(null);

    useEffect(() => {
        fetchFinarciersInstaller();
    }, []);

    useEffect(() => {
        setSelectedFile(uploadedFile);
    }, [uploadedFile]);

    const [showModal, setShowModal] = useState(false);

    const [selectedAlliance, setSelectedAlliance] = useState({});

    const [showFileReviewModal, setShowFileReviewModal] = useState(false);
    const [selectedFile, setSelectedFile] = useState({});

    const handleOnClickNewAlliance = () => setShowModal(true);

    const handleOnClickAlliance = (alliance) => {
        setSelectedAlliance(alliance);
        if (allianceDrawerRef.current) {
            const { handleOpen } = allianceDrawerRef.current;
            handleOpen(true);
        }
    };

    const onClickStatus = (file) => {
        setSelectedFile(file);
        setShowFileReviewModal(true);
    };

    return (
        <>
            <Card>
                <Card.Header>
                    <TitleIcon
                        fontWeight="bold"
                        title={t('My alliance', { count: 2 })}
                        variant="subtitle"
                    />

                    <Typography variant="body2">
                        {t(
                            'Request approval and verification by a financial institution in order to create and apply financial products in your proposal'
                        )}
                    </Typography>
                </Card.Header>

                <Card.Body>
                    <Grid container>
                        {finarciersInstaller.map((item) => (
                            <Grid
                                item
                                key={`pending-alliance-${item.id}`}
                                xs
                                sx={{ textAlign: 'center' }}
                            >
                                <Button
                                    disabled={!canModifyAlliances}
                                    onClick={() => handleOnClickAlliance(item)}
                                    variant="default"
                                >
                                    <Box>
                                        <StyledButtonAddAlliance>
                                            <picture>
                                                <img
                                                    alt=""
                                                    src={handleFileURL(
                                                        item.financier.image,
                                                        process.env
                                                            .REACT_APP_S3_MEDIA_PATH
                                                    )}
                                                />
                                            </picture>
                                        </StyledButtonAddAlliance>

                                        {item.financier.name}
                                    </Box>
                                </Button>
                            </Grid>
                        ))}

                        <Grid item xs sx={{ textAlign: 'center' }}>
                            <Button
                                id="alliances-my-alliances-add-alliance-button"
                                onClick={handleOnClickNewAlliance}
                                variant="default"
                                visible={canModifyAlliances}
                            >
                                <Box>
                                    <StyledButtonAddAlliance variant="new">
                                        <AddIcon />
                                    </StyledButtonAddAlliance>
                                    {t('Add alliance')}
                                </Box>
                            </Button>
                        </Grid>
                    </Grid>
                </Card.Body>
            </Card>

            <Dialog
                onClose={() => setShowModal(false)}
                onEnter={() => fetchFinarciers()}
                open={showModal}
                title={t('Add alliance')}
            >
                <AddAllianceForm
                    onSuccess={() => {
                        fetchFinarciersInstaller();
                        fetchFinarcierProduct(filterData);
                        setShowModal(false);
                    }}
                    setShowModal={setShowModal}
                    showModal={showModal}
                />
            </Dialog>

            <TemporaryDrawer ref={allianceDrawerRef}>
                <SideCard
                    onClickStatus={onClickStatus}
                    selectedAlliance={selectedAlliance}
                />
            </TemporaryDrawer>

            <FileReview
                selectedFile={selectedFile}
                onHide={() => setShowFileReviewModal(false)}
                show={showFileReviewModal}
                user={selectedAlliance ? selectedAlliance.installer : {}}
            />
        </>
    );
};

const mapStateToProps = createStructuredSelector({
    finarciersInstaller: selectors.getFinanciersInstallerData,
    uploadedFile: selectors.getUploadedFileData,
});

const mapDispatchToProps = (dispatch) => ({
    fetchFinarciers: () => dispatch(actions.fetchFinarciers()),
    fetchFinarciersInstaller: () =>
        dispatch(actions.fetchFinarciersInstaller()),
});

Alliances.propTypes = {
    canModifyAlliances: PropTypes.bool,
    fetchFinarcierProduct: PropTypes.func,
    fetchFinarciers: PropTypes.func,
    fetchFinarciersInstaller: PropTypes.func,
    filterData: PropTypes.object,
    finarciersInstaller: PropTypes.array,
    uploadedFile: PropTypes.object,
};

export default connect(mapStateToProps, mapDispatchToProps)(Alliances);
