import React, { useEffect, useRef, useState } from 'react';

import PropTypes from 'prop-types';

import { useBreakpoint } from 'common/hooks';

import ChartWrapper from '../ChartWrapper';

import Generation from './Generation';

const Charts = ({ countryCurrencyLocale, handleSelectProject, seriesData }) => {
    const [chartWidth, setChartWidth] = useState(900);
    const breakpoint = useBreakpoint();
    const container = useRef(null);

    useEffect(() => {
        const margin = 32;
        switch (breakpoint) {
            case 'xs':
                setChartWidth(600);
                break;
            case 'sm':
                setChartWidth(750);
                break;
            default: {
                const width = container?.current
                    ? container.current?.offsetWidth - margin
                    : '100%';
                setChartWidth(width);
                break;
            }
        }
    }, [breakpoint]);

    return (
        <ChartWrapper ref={container}>
            <Generation
                countryCurrencyLocale={countryCurrencyLocale}
                handleSelectProject={handleSelectProject}
                seriesData={seriesData}
                width={chartWidth}
            />
        </ChartWrapper>
    );
};

Charts.propTypes = {
    countryCurrencyLocale: PropTypes.string,
    handleSelectProject: PropTypes.func,
    seriesData: PropTypes.object,
};

export default Charts;
