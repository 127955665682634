import i18next from 'i18next';

import { deleteDocument } from 'common/api/v1/projectDocuments';
import { showReponseErrorsAsAlert } from 'common/utils/helpers';
import showToast from 'common/utils/showToast';

import {
    DELETE_DOCUMENT,
    DELETE_DOCUMENT_FAILURE,
    DELETE_DOCUMENT_SUCCESS,
} from '../actionTypes';
import { projectDocumentActions } from '../reducer';
import * as selectors from '../selectors';

import openConfirmModal from './openConfirmModal';

export default () => (dispatch, getState) => {
    const state = getState();
    const elemenToDelete = selectors.getElementToDelete(state);

    dispatch(projectDocumentActions[DELETE_DOCUMENT]());

    deleteDocument(elemenToDelete.id)
        .then(() => {
            dispatch(openConfirmModal(false));
            dispatch(
                projectDocumentActions[DELETE_DOCUMENT_SUCCESS](
                    elemenToDelete.id
                )
            );
            showToast({
                body: i18next.t('It was successfully removed'),
            });
        })
        .catch((error) => {
            dispatch(
                projectDocumentActions[DELETE_DOCUMENT_FAILURE](
                    error?.response?.data?.errors
                )
            );
            showReponseErrorsAsAlert(dispatch, error?.response);
        });
};
