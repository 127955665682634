import React from 'react';

import { useTranslation } from 'react-i18next';
import { Alert } from 'sunwise-ui';

const IncompleteLisaResponse = () => {
    const { t } = useTranslation();
    return (
        <Alert severity="warning">
            {t(
                "I faced challenges while trying to interpret your file accurately. I've taken the initiative to send it to our support team for assistance. We'll keep you informed once I've undergone the necessary training to interpret these files effectively"
            )}
        </Alert>
    );
};

export default IncompleteLisaResponse;
