import React, { useEffect } from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
    ToggleButtonGroup,
    ToggleButton,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
} from 'sunwise-ui';

import { useBreakpoint } from 'common/hooks';

import AlertBadge from './warnings/AlertBadge';

const PeriodSelector = ({
    errors,
    selectedPeriod,
    setSelectedPeriod,
    summary,
    summaryWarnings,
}) => {
    const { t } = useTranslation();
    const breakpoint = useBreakpoint();
    const summaryMaxIndex = summary.length - 1;

    useEffect(() => setSelectedPeriod(0), [summaryMaxIndex]);

    const handleOnChange = (e) =>
        setSelectedPeriod(parseInt(e.currentTarget.value));
    const handleOnChangeSelect = (e) =>
        setSelectedPeriod(parseInt(e.target.value));

    if (!['xs', 'sm'].includes(breakpoint))
        return (
            <ToggleButtonGroup
                color="primary"
                exclusive
                fullWidth
                onChange={handleOnChange}
                size="small"
                sx={{ mb: 3 }}
                value={selectedPeriod}
            >
                {[...summary].reverse().map((period, index) => (
                    <ToggleButton
                        key={`period-${summaryMaxIndex - index}`}
                        value={summaryMaxIndex - index}
                    >
                        <AlertBadge
                            hasFormErrors={Boolean(
                                errors?.summary?.[summaryMaxIndex - index]
                            )}
                            label={period.label}
                            warnings={
                                summaryWarnings?.[summaryMaxIndex - index]
                            }
                        />
                    </ToggleButton>
                ))}
            </ToggleButtonGroup>
        );

    return (
        <FormControl fullWidth>
            <InputLabel>{t('Period')}</InputLabel>
            <Select
                label={t('Period')}
                onChange={handleOnChangeSelect}
                value={selectedPeriod}
            >
                {summary.map((period, index) => (
                    <MenuItem key={`period-${index}`} value={index}>
                        <AlertBadge
                            hasFormErrors={Boolean(
                                errors?.summary?.[summaryMaxIndex - index]
                            )}
                            label={period.label}
                            warnings={
                                summaryWarnings?.[summaryMaxIndex - index]
                            }
                        />
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

PeriodSelector.propTypes = {
    errors: PropTypes.object,
    selectedPeriod: PropTypes.number,
    setSelectedPeriod: PropTypes.func,
    summary: PropTypes.array,
    summaryWarnings: PropTypes.array,
};

export default PeriodSelector;
