import { getItems } from 'common/api/v1/integrations';

import {
    FETCH_INTEGRATIONS,
    FETCH_INTEGRATIONS_FAILURE,
    FETCH_INTEGRATIONS_SUCCESS,
} from '../actionTypes';
import { webIntegrationsActions } from '../reducer';

export default () => (dispatch) => {
    dispatch(webIntegrationsActions[FETCH_INTEGRATIONS]());

    getItems()
        .then((response) =>
            dispatch(
                webIntegrationsActions[FETCH_INTEGRATIONS_SUCCESS](
                    response.data
                )
            )
        )
        .catch((error) =>
            dispatch(webIntegrationsActions[FETCH_INTEGRATIONS_FAILURE](error))
        );
};
