import { saveTableSettings } from 'common/api/v1/core';
import { showReponseErrorsAsAlert } from 'common/utils/helpers';
import showToast from 'common/utils/showToast';

import {
    SAVE_TABLE_SETTINGS,
    SAVE_TABLE_SETTINGS_FAILURE,
    SAVE_TABLE_SETTINGS_SUCCESS,
    FETCH_TABLE_SETTINGS_SUCCESS,
} from '../actionTypes';
import { tableSettingsActions } from '../reducer';

import setIsOpenModal from './setIsOpenModal';

export default (callback, tableKey, values) => (dispatch) => {
    dispatch(tableSettingsActions[SAVE_TABLE_SETTINGS]());

    saveTableSettings({ settings: values, key: tableKey })
        .then((response) => {
            dispatch(
                tableSettingsActions[SAVE_TABLE_SETTINGS_SUCCESS](
                    response?.data?.data
                )
            );
            dispatch(
                tableSettingsActions[FETCH_TABLE_SETTINGS_SUCCESS](
                    response.data.data
                )
            );
            dispatch(setIsOpenModal(false));
            if (callback) callback();
            showToast();
        })
        .catch((error) => {
            dispatch(tableSettingsActions[SAVE_TABLE_SETTINGS_FAILURE](error));
            showReponseErrorsAsAlert(dispatch, error?.response);
        });
};
