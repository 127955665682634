import React, { useRef } from 'react';

import LinearProgress from '@mui/material/LinearProgress';
import { debounce, isEqual } from 'lodash';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
    BottomActions,
    Box,
    Button,
    Card,
    DataGrid,
    Typography,
} from 'sunwise-ui';

import { CustomPagination } from 'common/components';
import { DEBOUNCE_TIME } from 'common/constants';

import ContactColumns from './ContactColumns';
import ContactsFilter from './ContactsFilter';

const ContactsSection = ({
    agents,
    contacts,
    disabled,
    filterData,
    filterItems,
    isFetching,
    onCancelSelection,
    onConfirmSelection,
    paginationData,
    selectedContact,
    setFilterData,
    setSelectedContact,
}) => {
    const { t } = useTranslation();

    const handleOnFilter = useRef(
        debounce((filter) => filterItems(filter), DEBOUNCE_TIME)
    ).current;

    const handleFilterNewData = (newFilterData) => {
        if (disabled || isEqual(newFilterData, filterData)) return;
        setFilterData(newFilterData);
        handleOnFilter(newFilterData);
        setSelectedContact(null);
    };

    const handleOnChangeFilter = (key, value) => {
        handleFilterNewData({ ...filterData, page: 0, [key]: value });
    };

    const handleOnSortModelChange = (sortModel) => {
        if (!sortModel[0]) return;

        handleFilterNewData({
            ...filterData,
            orderBy: sortModel[0].field.toLocaleLowerCase(),
            sortBy: sortModel[0].sort,
        });
    };

    return (
        <>
            <Typography variant="h6" sx={{ my: 2, ml: 1 }}>
                {t('Select a contact')}
            </Typography>

            <ContactsFilter
                agents={agents}
                filterData={filterData}
                handleOnChangeFilter={handleOnChangeFilter}
            />

            <Card>
                <Card.Body>
                    <DataGrid
                        autoHeight
                        checkboxSelection={false}
                        columns={ContactColumns()}
                        components={{
                            LoadingOverlay: LinearProgress,
                            Pagination: CustomPagination,
                        }}
                        componentsProps={{
                            pagination: {
                                rowsPerPageOptions: [15, 25, 50, 75, 100],
                            },
                        }}
                        disableColumnMenu
                        disableSelectionOnClick
                        hideFooterSelectedRowCount
                        initialState={{
                            pagination: { pageSize: filterData?.pageSize },
                        }}
                        loading={isFetching}
                        onPageChange={(page) =>
                            handleOnChangeFilter('page', page)
                        }
                        onPageSizeChange={(newPage) =>
                            handleOnChangeFilter('pageSize', newPage)
                        }
                        onRowClick={(params) => setSelectedContact(params?.row)}
                        onSortModelChange={handleOnSortModelChange}
                        page={paginationData?.current_page - 1 || 0}
                        pageSize={filterData?.pageSize}
                        pagination
                        paginationMode="server"
                        rowCount={paginationData?.total_rows || 0}
                        rows={contacts}
                        selectionModel={[selectedContact?.id]}
                        sortingMode="server"
                        sx={{
                            '& .MuiDataGrid-row': { cursor: 'pointer' },
                            '& .MuiDataGrid-cell:focus': { outline: 'none' },
                        }}
                    />
                </Card.Body>
            </Card>

            <BottomActions>
                <Box display="flex" gap={1} mt={2}>
                    <Button
                        color="secondary"
                        disabled={disabled}
                        onClick={onCancelSelection}
                        variant="outlined"
                    >
                        {t('Cancel')}
                    </Button>

                    <Button
                        disabled={!selectedContact || disabled}
                        onClick={onConfirmSelection}
                    >
                        {t('Next')}
                    </Button>
                </Box>
            </BottomActions>
        </>
    );
};

ContactsSection.propTypes = {
    agents: PropTypes.array,
    contacts: PropTypes.array,
    disabled: PropTypes.bool,
    filterData: PropTypes.object,
    filterItems: PropTypes.func,
    isFetching: PropTypes.bool,
    onCancelSelection: PropTypes.func,
    onConfirmSelection: PropTypes.func,
    paginationData: PropTypes.object,
    selectedContact: PropTypes.object,
    setFilterData: PropTypes.func,
    setSelectedContact: PropTypes.func,
};

export default ContactsSection;
