import i18next from 'i18next';

import { FILE_ALLOWED_EXTENSIONS } from 'common/constants';
import alerts from 'common/modules/alerts';

import { UPLOAD_MODES } from '../constants';

import handleUploadCsvDatadis from './handleUploadCsvDatadis';
import handleUploadPDF from './handleUploadPDF';
import handleUploadXML from './handleUploadXML';
import setConfigCsvImportModal from './setConfigCsvImportModal';
import setUploadFileOrigin from './setUploadFileOrigin';
import uploadDocumentsAI from './uploadDocumentsAI';

export default ({ file, getValues, sendToLisa, setValue, uploadMode }) =>
    (dispatch) => {
        if (!file?.length) return;

        if (file.length > 12) {
            const dataAlert = {
                messages: [
                    i18next.t(
                        'You can only upload a maximum of 12 files at a time'
                    ),
                ],
                confirmText: i18next.t('Accept'),
                title: i18next.t('Error'),
                type: alerts.ALERT_TYPE_ALERT,
                variant: 'warning',
            };

            dispatch(alerts.actions.show(dataAlert));
            return;
        }

        const fileType = file[0]?.type;

        const isCSV = FILE_ALLOWED_EXTENSIONS.CSV.includes(fileType);
        const isXML = FILE_ALLOWED_EXTENSIONS.XML.includes(fileType);

        if (isCSV && uploadMode === UPLOAD_MODES.DATADIS)
            dispatch(
                handleUploadCsvDatadis({
                    file,
                    getValues,
                    setValue,
                    uploadMode,
                })
            );
        else if (isXML)
            dispatch(handleUploadXML({ file, getValues, setValue }));
        else if (isCSV) dispatch(setConfigCsvImportModal(true, file[0]));
        else if (sendToLisa || FILE_ALLOWED_EXTENSIONS.IMAGE.includes(fileType))
            dispatch(uploadDocumentsAI(file));
        else dispatch(handleUploadPDF({ file, getValues, setValue }));

        dispatch(setUploadFileOrigin());
    };
