import React, { useCallback, useState } from 'react';

import AttachFileIcon from '@mui/icons-material/AttachFile';
import { styled } from '@mui/material/styles';
import i18next from 'i18next';
import isArray from 'lodash/isArray';
import isString from 'lodash/isString';
import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
import { Controller } from 'react-hook-form';
import { Box } from 'sunwise-ui';

import { MAX_FILE_SIZE } from 'common/constants';
import { isImageBase64, isImageUrl } from 'common/utils/helpers';

import { ACCEPTED_FILES } from '../constants';

const Indications = styled(Box)`
    align-items: center;
    color: #fff;
    display: flex;
    flex-direction: column;
    font-size: 16px;
    font-weight: bold;
    justify-content: center;
    line-height: 19px;
    width: 100%;

    .ico {
        font-size: 30px;
    }
`;

const UploadMask = styled('div')`
    background-color: ${({ opacity }) => `rgba(0, 0, 0, ${opacity})`};
    cursor: pointer;
    display: flex;
    height: 100%;
    margin: 0;
    padding: 40px;
    position: relative;
    width: 100%;

    &:after {
        border-radius: 3px 0 0 3px;
        border: 5px dashed #a3a6b4;
        bottom: 40px;
        content: '';
        display: block;
        left: 40px;
        position: absolute;
        right: 40px;
        top: 40px;
    }
`;

const FileUploader = ({
    control,
    maxSize = MAX_FILE_SIZE,
    maxSizeErrorMessage = i18next.t(
        'The file exceeds the allowable size limit'
    ),
    name,
    opacity = '0.81',
    setValue,
}) => {
    const [errorSize, setErrorSize] = useState(null);

    const onDrop = useCallback((acceptedFiles) => {
        if (acceptedFiles.length > 0 && acceptedFiles[0].size >= maxSize) {
            setErrorSize(maxSizeErrorMessage);
            return;
        }

        setErrorSize(null);

        setValue(
            name,
            acceptedFiles.map((file) =>
                Object.assign(file, {
                    preview: URL.createObjectURL(file),
                })
            )
        );
    }, []);

    const { getRootProps, getInputProps } = useDropzone({ onDrop });

    return (
        <Controller
            control={control}
            name={name}
            render={({ field: { value } }) => (
                <UploadMask {...getRootProps()} opacity={opacity}>
                    <input {...getInputProps()} accept={ACCEPTED_FILES} />

                    <Indications>
                        <p className="ico">
                            <AttachFileIcon />
                        </p>

                        <p>
                            {isString(value) &&
                            !isImageBase64(value) &&
                            !isImageUrl(value)
                                ? value
                                : isArray(value)
                                ? value[0].path
                                : ''}
                        </p>

                        <p>{i18next.t('Attach new document')}</p>

                        {errorSize !== null && <label>{errorSize}</label>}
                    </Indications>
                </UploadMask>
            )}
        />
    );
};

FileUploader.propTypes = {
    control: PropTypes.object,
    maxSize: PropTypes.number,
    maxSizeErrorMessage: PropTypes.string,
    name: PropTypes.string,
    opacity: PropTypes.string,
    setValue: PropTypes.func,
};

export default FileUploader;
