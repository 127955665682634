import { getUsersList } from 'common/api/v1/users';

import {
    FETCH_USERS,
    FETCH_USERS_FAILURE,
    FETCH_USERS_SUCCESS,
} from '../actionTypes';
import { trackingsActions } from '../reducer';
import * as selectors from '../selectors';

export default () => (dispatch, getState) => {
    const contactId = selectors.getContactId(getState());
    dispatch(trackingsActions[FETCH_USERS]());

    getUsersList(contactId)
        .then((response) =>
            dispatch(
                trackingsActions[FETCH_USERS_SUCCESS](response?.data?.data)
            )
        )
        .catch((error) =>
            dispatch(
                trackingsActions[FETCH_USERS_FAILURE](
                    error?.response?.data?.errors
                )
            )
        );
};
