import { create, update } from 'common/api/v1/measurementSource';
import { showReponseErrorsAsAlert } from 'common/utils/helpers';
import showToast from 'common/utils/showToast';

import {
    SAVE_DATA,
    SAVE_DATA_FAILURE,
    SAVE_DATA_SUCCESS,
} from '../actionTypes';
import { SOURCE_FIELD_KEYS } from '../constants';
import { actions } from '../reducer';

export default ({ sunwiseProjectId, successCallback, values }) =>
    (dispatch) => {
        dispatch(actions[SAVE_DATA]());

        let newValues = {
            brand: values.brand,
            name: values.name,
            project: sunwiseProjectId,
            type: values.type,
            url: values.url,
        };

        Object.values(SOURCE_FIELD_KEYS).forEach((key) => {
            newValues[key] = values[key];
        });

        const save = !values.id
            ? () => create(newValues)
            : () => update(values.id, newValues);

        save()
            .then((response) => {
                dispatch(actions[SAVE_DATA_SUCCESS](response.data));
                showToast();

                if (successCallback) successCallback();
            })
            .catch((error) => {
                dispatch(
                    actions[SAVE_DATA_FAILURE](error?.response?.data?.errors)
                );
                showReponseErrorsAsAlert(dispatch, error?.response);
            });
    };
