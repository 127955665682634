import i18next from 'i18next';

import { getReportCsv } from 'common/api/v1/afterSalesEndCsv';
import showToast from 'common/utils/showToast';

import {
    DOWNLOAD_REPORT_CSV,
    DOWNLOAD_REPORT_CSV_FAILURE,
    DOWNLOAD_REPORT_CSV_SUCCESS,
} from '../actionTypes';
import { reportTemplateActions } from '../reducer';

import downloadFile from './downloadFile';

export default ({ id, name = i18next.t('File') }) =>
    (dispatch) => {
        dispatch(reportTemplateActions[DOWNLOAD_REPORT_CSV]());

        getReportCsv(id)
            .then((response) => {
                dispatch(
                    reportTemplateActions[DOWNLOAD_REPORT_CSV_SUCCESS](
                        response.data
                    )
                );
                const url = window.URL.createObjectURL(
                    new Blob([response.data])
                );
                dispatch(downloadFile(name, url, 'csv'));
                showToast({ body: i18next.t('It was created successfully') });
            })
            .catch((error) =>
                dispatch(
                    reportTemplateActions[DOWNLOAD_REPORT_CSV_FAILURE](error)
                )
            );
    };
