if (!String.prototype.replaceAll) {
    String.prototype.replaceAll = function (searchValue, replaceValue) {
        // If searchValue is a regular expression, use the replace method
        if (searchValue instanceof RegExp) {
            return this.replace(searchValue, replaceValue);
        }

        // Escape special characters in searchValue
        const escapedSearchValue = searchValue.replace(
            /[.*+?^${}()|[\]\\]/g,
            '\\$&'
        );

        // Create a new regular expression with the escaped searchValue and the global flag
        const regex = new RegExp(escapedSearchValue, 'g');

        // Use the replace method with the new regular expression
        return this.replace(regex, replaceValue);
    };
}
