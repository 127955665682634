import { fetchListCatalogs } from 'common/api/v1/companiesCatalogs';

import {
    FETCH_DOCUMENTS_PROJECT_TYPE_FAILURE,
    FETCH_DOCUMENTS_PROJECT_TYPE,
    FETCH_DOCUMENTS_PROJECT_TYPE_SUCCESS,
} from '../actionTypes';
import { DOCUMENTS_TYPE } from '../constants';
import { projectDocumentActions } from '../reducer';

export default () => (dispatch) => {
    dispatch(projectDocumentActions[FETCH_DOCUMENTS_PROJECT_TYPE]());

    fetchListCatalogs(DOCUMENTS_TYPE)
        .then((response) =>
            dispatch(
                projectDocumentActions[FETCH_DOCUMENTS_PROJECT_TYPE_SUCCESS](
                    response.data.data
                )
            )
        )
        .catch((error) =>
            dispatch(
                projectDocumentActions[FETCH_DOCUMENTS_PROJECT_TYPE_FAILURE](
                    error
                )
            )
        );
};
