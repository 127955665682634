import React, { useContext, useEffect, useState } from 'react';

import { isEqual } from 'lodash';
import PropTypes from 'prop-types';
import { useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Typography } from 'sunwise-ui';

import useDebounce from 'common/hooks/useDebounce';
import { GeneralContext } from 'common/utils/contexts';

import { POLYGON_OBSTACLE } from '../constants';
import { getNewModules } from '../helpers';

const getModulesQuantity = async ({
    google,
    map,
    offerPanels,
    polygon,
    segments,
    values,
}) => {
    const {
        azimuth,
        col_spacing,
        frame_col_spacing,
        frame_cols_number,
        frame_row_spacing,
        frame_rows_number,
        height,
        orientation,
        row_spacing,
        safe_zone,
        solar_module,
        tilt,
        zIndex,
    } = values;

    const selectedModule = offerPanels.find((item) => item.id === solar_module);

    if (!selectedModule) return 0;

    const obstacles = segments.filter(
        (segment) => segment.type === POLYGON_OBSTACLE
    );

    const data = {
        assigned: 0,
        azimuth,
        col_spacing,
        frame_col_spacing,
        frame_cols_number,
        frame_row_spacing,
        frame_rows_number,
        height,
        orientation,
        polygon: JSON.stringify(polygon),
        quantity: Math.Infinity,
        row_spacing,
        safe_zone,
        solar_module,
        tilt,
        zIndex,
    };

    const { boxes } = getNewModules({
        data,
        google,
        map,
        module: selectedModule,
        obstacles,
    });

    return boxes.length;
};

const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

const getWatchedValues = (values) => {
    const [
        azimuth,
        col_spacing,
        frame_col_spacing,
        frame_cols_number,
        frame_row_spacing,
        frame_rows_number,
        height,
        orientation,
        row_spacing,
        safe_zone,
        solar_module,
        tilt,
        zIndex,
    ] = values;

    return {
        azimuth,
        col_spacing,
        frame_col_spacing,
        frame_cols_number,
        frame_row_spacing,
        frame_rows_number,
        height,
        orientation,
        row_spacing,
        safe_zone,
        solar_module,
        tilt,
        zIndex,
    };
};

const MaxPanelLabel = ({ control, formValues, map, offerPanels, segments }) => {
    const { t } = useTranslation();
    const { google } = useContext(GeneralContext);
    const values = useWatch({
        control,
        name: [
            'azimuth',
            'col_spacing',
            'frame_col_spacing',
            'frame_cols_number',
            'frame_row_spacing',
            'frame_rows_number',
            'height',
            'orientation',
            'row_spacing',
            'safe_zone',
            'solar_module',
            'tilt',
            'zIndex',
        ],
    });
    const watchedValues = getWatchedValues(values);
    const [numPanels, setNumPanels] = useState(0);
    const [previousValues, setPreviousValues] = useState(watchedValues);
    const debouncedValue = useDebounce(values, 250);

    const handler = ({ sleepTime = 0 } = {}) => {
        const getNumPanels = async () => {
            if (sleepTime) await sleep(sleepTime);
            return getModulesQuantity({
                google,
                map,
                offerPanels,
                polygon: formValues.polygon,
                segments,
                values: watchedValues,
            });
        };

        getNumPanels().then((response) => {
            setNumPanels(response);
            setPreviousValues(watchedValues);
        });
    };

    useEffect(() => {
        handler({ sleepTime: 1 });
    }, []);

    useEffect(() => {
        if (isEqual(previousValues, watchedValues)) return;
        handler();
    }, [debouncedValue]);

    return (
        <Typography variant="caption">
            {t('Maximum number of panels')}: {numPanels} u
        </Typography>
    );
};

MaxPanelLabel.propTypes = {
    control: PropTypes.object,
    formValues: PropTypes.object,
    map: PropTypes.object,
    offerPanels: PropTypes.array,
    segments: PropTypes.array,
};

export default MaxPanelLabel;
