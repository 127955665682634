import isEmpty from 'lodash/isEmpty';

import { getCarouselTemplates } from 'common/api/v1/offerTemplate';

import {
    FETCH_CAROUSEL_TEMPLATES,
    FETCH_CAROUSEL_TEMPLATES_FAILURE,
    FETCH_CAROUSEL_TEMPLATES_SUCCESS,
} from '../actionTypes';
import { templateLibraryActions } from '../reducer';

export default (countryId) => (dispatch) => {
    dispatch(templateLibraryActions[FETCH_CAROUSEL_TEMPLATES]());

    getCarouselTemplates(countryId)
        .then((response) => {
            if (!isEmpty(response?.data?.data)) {
                const { new: news, oustanding, regulars } = response.data.data;
                dispatch(
                    templateLibraryActions[FETCH_CAROUSEL_TEMPLATES_SUCCESS]({
                        featuredTemplates: oustanding?.templates,
                        newTemplates: news?.templates,
                        regularTemplates: regulars?.templates,
                    })
                );
            }
        })
        .catch((error) => {
            dispatch(
                templateLibraryActions[FETCH_CAROUSEL_TEMPLATES_FAILURE](error)
            );
        });
};
