import React, { useContext } from 'react';

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { styled } from '@mui/material/styles';
import isUndefined from 'lodash/isUndefined';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Grid } from 'sunwise-ui';

import { DropdownCardMenu } from 'common/components/cards';
import { LoadingContext } from 'common/utils/contexts';

import { ReactComponent as CertifiedDatasheetIcon } from 'resources/icoCertifiedRate.svg';
import { ReactComponent as OwnDatasheetIcon } from 'resources/ownDatasheetIcon.svg';

import { getDatasheetName, getLastUpdatedText } from '../helpers';

const ContainerInfo = styled('div')`
    display: flex;
    flex-direction: row;
`;

const ContainerLabel = styled('div')`
    display: flex;
    flex-direction: column;
    line-height: 12px;

    .label-document {
        padding-left: 10px;
    }
`;

const Label = styled('label')`
    font-size: 16px;
    font-weight: 600;
    line-height: 22px;
    margin-left: 10px;
`;

const StyledOwnDatasheetIcon = styled(OwnDatasheetIcon)`
    fill: #ccd1e8;
    height: 40px;
    min-height: 40px;
    min-width: 28px;
    width: 28px;
`;

const StyledCertifiedDatasheetIcon = styled(CertifiedDatasheetIcon)`
    height: 40px;
    min-height: 40px;
    min-width: 28px;
    width: 28px;

    path {
        fill: #ff8f02;
        filter: contrast(0.8);
        stroke: transparent;

        &:first-child {
            stroke: #ff8f02;
        }
    }
`;

const getFileName = (datasheet) => {
    if (!isUndefined(datasheet.name)) return datasheet.name;
    return datasheet.title;
};

const DatasheetItem = ({
    datasheet,
    handleClickDelete,
    handleClickDownload,
    handleClickUpdate,
    values,
}) => {
    const { t } = useTranslation();
    const loadingContext = useContext(LoadingContext);

    const getOptionsDatasheetItem = (
        handleClickUpdate,
        handleClickDelete,
        handleClickDownload,
        datasheet,
        values
    ) => {
        if (!datasheet.is_certified)
            return [
                {
                    handleClick: () =>
                        handleClickDownload(
                            getDatasheetName(datasheet?.archive),
                            (text) => loadingContext.openLoading(text),
                            () => {
                                loadingContext.closeLoading();
                            }
                        ),
                    icon: <VisibilityIcon fontSize="small" />,
                    label: t('View'),
                },
                {
                    handleClick: () =>
                        handleClickUpdate({
                            ...datasheet,
                            productType: values.productType,
                            productId: values.id,
                        }),
                    icon: <EditIcon fontSize="small" />,
                    label: t('Edit'),
                },
                {
                    handleClick: () => handleClickDelete(datasheet, values),
                    icon: <DeleteIcon fontSize="small" />,
                    label: t('Delete'),
                },
            ];

        return [
            {
                handleClick: () =>
                    handleClickDownload(
                        getDatasheetName(datasheet?.archive),
                        (text) => loadingContext.openLoading(text),
                        () => {
                            loadingContext.closeLoading();
                        }
                    ),
                icon: <VisibilityIcon fontSize="small" />,
                label: t('View'),
            },
        ];
    };

    const DatasheetFileItem = ({
        datasheet,
        handleClickDelete,
        handleClickDownload,
        handleClickUpdate,
        values,
    }) => (
        <Grid container>
            <Grid item xs>
                <ContainerInfo>
                    {datasheet.is_certified && <StyledCertifiedDatasheetIcon />}
                    {!datasheet.is_certified && <StyledOwnDatasheetIcon />}

                    <ContainerLabel>
                        <Label>{getFileName(datasheet)}</Label>

                        {datasheet.created_by && (
                            <small className="label-document">
                                {t('By')}
                                {` ${datasheet.created_by.first_name} ${datasheet.created_by.last_name}`}
                            </small>
                        )}

                        <small className="label-document">
                            {getLastUpdatedText(datasheet)}
                        </small>
                    </ContainerLabel>
                </ContainerInfo>
            </Grid>
            <Grid item sx={{ textAlign: 'right' }}>
                <DropdownCardMenu
                    items={getOptionsDatasheetItem(
                        handleClickUpdate,
                        handleClickDelete,
                        handleClickDownload,
                        datasheet,
                        values
                    )}
                />
            </Grid>
        </Grid>
    );

    if (!datasheet || !datasheet.is_certified) return null;

    return (
        <DatasheetFileItem
            datasheet={datasheet}
            handleClickDelete={handleClickDelete}
            handleClickDownload={handleClickDownload}
            handleClickUpdate={handleClickUpdate}
            values={values}
        />
    );
};

DatasheetItem.propTypes = {
    handleClickDelete: PropTypes.func,
    handleClickDownload: PropTypes.func,
    handleClickUpdate: PropTypes.func,
    datasheet: PropTypes.object,
    values: PropTypes.object,
};

export default DatasheetItem;
