import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Typography } from 'sunwise-ui';

import { ROLE_LEVELS } from 'common/constants/permissionsV2';
import BranchOfficesDropdown from 'common/modules/multiBranches/components/BranchOfficesDropdown';

const BranchOfficeFormatter = ({ row }) => {
    const { t } = useTranslation();

    const { branch_office_name, branch_offices = [], id, role_level } = row;
    const isCustomOrGM = [
        ROLE_LEVELS.CUSTOMIZED,
        ROLE_LEVELS.GENERAL_MANAGER,
    ].includes(role_level);

    if (role_level === ROLE_LEVELS.OWNER) return '---';

    if (isCustomOrGM) {
        return (
            <BranchOfficesDropdown
                branchOffices={branch_offices.filter(
                    ({ checked, id }) => checked || id === 'company'
                )}
                keyName={`branch-office-name-${id}`}
            />
        );
    }

    if (branch_office_name) {
        return <Typography variant="caption">{branch_office_name}</Typography>;
    }

    return <Typography variant="caption">{t('No branch')}</Typography>;
};

BranchOfficeFormatter.propTypes = {
    row: PropTypes.object,
};

export default BranchOfficeFormatter;
