import React from 'react';

import DeleteIcon from '@mui/icons-material/Delete';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
    Box,
    Grid,
    IconButton,
    MenuItem,
    Tooltip,
    Typography,
} from 'sunwise-ui';

import {
    ReactHookFormInput,
    ReactHookFormIntlNumberInput,
} from 'common/components/form/bootstrap';

import {
    changeConsumptionRelationByPrice,
    handleNormalizeTopValueBuild,
    handleNormalizeUnitBuild,
    removeConceptInput,
} from '../../../helpers';

const ExcessLabel = styled(Typography)`
    background: #aaa;
    border-radius: 4px;
    font-size: 11px;
    font-weight: 500;
    left: 9px;
    padding: 0 4px;
    position: absolute;
    top: -8px;
`;

const Concept = ({
    control,
    countryCurrencySymbol,
    disabled,
    getValues,
    index,
    isEnergyConcept,
    isHourlyField,
    isHourlyLinkedConcept,
    isHourlyRate,
    isLastConcept,
    linkedConceptName,
    name,
    periodFieldName,
    setValue,
    showMode,
    topValueFieldEnabled,
    unitItems,
}) => {
    const unit = useWatch({ control, name: `${name}.${index}.unit` });
    const { t } = useTranslation();

    const typeValue =
        unit && unit !== '' && unit.search('%') >= 0
            ? '%'
            : countryCurrencySymbol;
    const isHelpConcept = topValueFieldEnabled && isLastConcept;
    const showDeleteButton =
        !disabled && !isHourlyLinkedConcept && !isHelpConcept;

    const colSize = { sm: topValueFieldEnabled ? 6 : 16 / 3, xs: 18 };

    return (
        <Box
            backgroundColor={isHelpConcept ? 'rgb(239 241 251 / 25%)' : null}
            borderRadius={1}
            px={1}
            pt={isHelpConcept ? 2 : 0}
        >
            <Grid container alignItems="flex-start" position="relative">
                {isHelpConcept && <ExcessLabel>{t('Excess')}</ExcessLabel>}

                <Grid
                    item
                    md
                    sx={{ display: 'flex', gap: isHourlyField ? 1 : 0 }}
                    {...colSize}
                >
                    {isHourlyField && (
                        <Typography variant="body1" sx={{ pt: 1 }}>
                            {index + 1}
                        </Typography>
                    )}
                    <ReactHookFormInput
                        control={control}
                        disabled={disabled || isHourlyLinkedConcept}
                        label={t('Name')}
                        name={`${name}.${index}.tag_name`}
                        onBlur={(e) => {
                            if (!linkedConceptName) return;
                            const linkedConceptValues =
                                getValues(linkedConceptName);
                            if (linkedConceptValues?.length > 0)
                                setValue(
                                    `${linkedConceptName}.${index}.tag_name`,
                                    e.target.value
                                );
                        }}
                        placeholder={t('Name')}
                    />
                </Grid>

                {topValueFieldEnabled && (
                    <>
                        <Grid item md {...colSize}>
                            <ReactHookFormIntlNumberInput
                                control={control}
                                disabled
                                fullWidth
                                label={t('Minimum', { context: 'male' })}
                                name={`${name}.${index}.min_value`}
                                placeholder={t('Minimum', { context: 'male' })}
                            />
                        </Grid>

                        <Grid item md {...colSize}>
                            <ReactHookFormIntlNumberInput
                                allowDecimals={false}
                                allowNegativeValue={false}
                                control={control}
                                disabled={showMode || isHelpConcept}
                                fullWidth
                                label={t('Maximum', { context: 'male' })}
                                name={`${name}.${index}.top_value`}
                                onChange={(e) =>
                                    handleNormalizeTopValueBuild({
                                        fieldsName: name,
                                        index,
                                        newValue: e?.target?.value,
                                        setValue,
                                        values: getValues(name),
                                    })
                                }
                                placeholder={t('Maximum', { context: 'male' })}
                            />
                        </Grid>
                    </>
                )}

                <Grid item md {...colSize}>
                    <ReactHookFormIntlNumberInput
                        append={typeValue === '%' && typeValue}
                        control={control}
                        disabled={showMode}
                        label={t('Quantity')}
                        fullWidth
                        name={`${name}.${index}.price`}
                        onChange={(e) => {
                            if (isEnergyConcept)
                                changeConsumptionRelationByPrice({
                                    conceptIndex: index,
                                    fieldsName: periodFieldName,
                                    getValues,
                                    setValue,
                                    value: e.target.value,
                                });
                        }}
                        placeholder={t('Quantity')}
                        prepend={typeValue !== '%' && typeValue}
                    />
                </Grid>

                <Grid item {...colSize} md xs={showDeleteButton ? 14 : 18}>
                    <ReactHookFormInput
                        control={control}
                        disabled={showMode}
                        label={t('Unit')}
                        name={`${name}.${index}.unit`}
                        onChange={(e) =>
                            handleNormalizeUnitBuild({
                                fieldsName: name,
                                index,
                                onlyUnitEnabled: topValueFieldEnabled,
                                setValue,
                                value: e.target.value,
                                values: getValues(name),
                            })
                        }
                        placeholder={t('Unit')}
                        select
                    >
                        {unitItems.map(({ label, value, disabled }) => (
                            <MenuItem
                                disabled={disabled}
                                key={`${index}-unit-select-${value}-${label}`}
                                value={value}
                            >
                                {label?.replace('$', countryCurrencySymbol)}
                            </MenuItem>
                        ))}
                    </ReactHookFormInput>
                </Grid>

                <Grid item xs={4} sm={2} lg={1} textAlign="right">
                    {showDeleteButton && (
                        <Tooltip title={t('Delete')}>
                            <IconButton
                                disabled={isHelpConcept}
                                onClick={() => {
                                    if (isHourlyField)
                                        setValue(
                                            'settings_data.hourly_distribution.option_deleted',
                                            { index, name }
                                        );

                                    removeConceptInput({
                                        conceptsFieldsName: name,
                                        getValues,
                                        indexToRemove: index,
                                        isEnergyConcept,
                                        isHourlyRate,
                                        linkedConceptName,
                                        periodFieldName,
                                        setValue,
                                        topValueFieldEnabled,
                                        values: getValues(name),
                                    });
                                }}
                            >
                                <DeleteIcon />
                            </IconButton>
                        </Tooltip>
                    )}
                </Grid>
            </Grid>
        </Box>
    );
};

Concept.propTypes = {
    control: PropTypes.object,
    countryCurrencySymbol: PropTypes.string,
    disabled: PropTypes.bool,
    getValues: PropTypes.func,
    index: PropTypes.number,
    isEnergyConcept: PropTypes.bool,
    isHourlyField: PropTypes.bool,
    isHourlyLinkedConcept: PropTypes.bool,
    isHourlyRate: PropTypes.bool,
    isLastConcept: PropTypes.bool,
    linkedConceptName: PropTypes.string,
    name: PropTypes.string,
    periodFieldName: PropTypes.string,
    setValue: PropTypes.func,
    showMode: PropTypes.bool,
    topValueFieldEnabled: PropTypes.bool,
    unitItems: PropTypes.array,
};

export default Concept;
