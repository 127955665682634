import React, { useEffect } from 'react';

import Papa from 'papaparse';
import PropTypes from 'prop-types';
import { useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Box, Button, Divider, Grid, Link, Typography } from 'sunwise-ui';

import {
    ReactHookFormCheck,
    ReactHookFormCheckboxButtonGroup,
    ReactHookFormDragnDropFile,
    ReactHookFormInput,
    ReactHookFormIntlNumberInput,
    ReactHookFormRadioButtonGroup,
    ReactHookFormSelect,
} from 'common/components/form/bootstrap';
import ShowErrors from 'common/components/ShowErrors';
import { DEFAULT_TERMS, FILE_ALLOWED_EXTENSIONS } from 'common/constants';
import {
    getAlgorithmTypes,
    getCurrencyIso,
    getCurrencySymbol,
} from 'common/utils/helpers';
import { getCountryCurrency } from 'common/utils/helpers/session';
import yupResolver from 'common/utils/yupResolver';

import * as actions from '../actions';
import * as selectors from '../selectors';
import validate from '../validate';

const ProductForm = ({
    closeModal,
    errors,
    fetchTemplates,
    filterData,
    initialValues,
    isFetchingTemplates,
    isSavingProduct,
    save,
    templates,
}) => {
    const { t } = useTranslation();

    const { control, getValues, handleSubmit, setValue } = useForm({
        defaultValues: initialValues,
        resolver: yupResolver(validate),
    });

    const [
        amountValues,
        filePercentage,
        parametersCommissionCredit,
        parametersHitch,
        parametersInsurance,
        parametersResidual,
        powerValues,
        typeAlgorithm,
    ] = useWatch({
        control,
        name: [
            'amount_values',
            'file_percentage',
            'parameters_commission_credit',
            'parameters_hitch',
            'parameters_insurance',
            'parameters_residual',
            'power_values',
            'type_algorithm',
        ],
    });

    useEffect(() => {
        fetchTemplates();
    }, []);

    const countryCurrency = getCountryCurrency();
    const currencyIso = getCurrencyIso(countryCurrency);
    const currencySymbol = getCurrencySymbol(countryCurrency);

    const getAppendText = (parameter) => {
        if (parameter && parseInt(parameter.unit) === 0) return '%';
        return currencyIso;
    };

    const getPreAppendText = (parameter) => {
        if (parameter && parseInt(parameter.unit) === 1) return currencySymbol;
        return null;
    };

    const handleData = (data) => {
        if (!data) return;

        const csvData = data.data;
        let termsArray = [];

        for (let i = 0; i < csvData.length; i++) {
            let j = 0;
            let indexTerm = 0;

            while (j < csvData[i].length) {
                if (csvData[i][j] !== '') {
                    termsArray[indexTerm] = csvData[i][j];
                }

                indexTerm++;
                j += 2;
            }
        }

        const terms = {};

        termsArray.forEach((item) => (terms[item] = true));

        setValue('parameters.terms', terms);
        setValue('type_algorithm', 'static_capital_payment');
    };

    const handleOnChangeFilePercentage = (e) => {
        const file = e[0];

        if (!file) return;

        Papa.parse(file, {
            complete: handleData,
        });

        setValue('parameters_rate.value', 0);
        setValue('parameters.tax', 0);
        setValue('parameters_commission_credit.value', 0);
        setValue('parameters_commission_credit.opening_commission_vat', 0);
        setValue('parameters_rate.isChecked', false);
        setValue('parameters_commission_credit.isChecked', false);
    };

    useEffect(() => {
        if (!powerValues?.isChecked) {
            setValue('power_values.max', 0);
            setValue('power_values.min', 0);
        }
    }, [powerValues?.isChecked]);
    useEffect(() => {
        if (!amountValues?.isChecked) {
            setValue('amount_values.max', 0);
            setValue('amount_values.min', 0);
        }
    }, [amountValues?.isChecked]);

    const isDisabled =
        getValues('disabled') || isFetchingTemplates || isSavingProduct;

    const formId = getValues('id');

    const downloadLink =
        filePercentage && formId
            ? filePercentage
            : 'https://sunwise-production.s3.amazonaws.com/media/PPA example file.csv';

    const getTemplateOptions = () => {
        const options = templates.map((item) => ({
            label: item.title,
            value: item.id,
        }));
        return [
            {
                disabled: true,
                label: t('Select template'),
                value: '',
            },
            ...options,
        ];
    };

    const handleOnBlurField = (name) => {
        const value = getValues(name);
        if (!value) setValue(name, 0);
    };

    const handleSave = (values) => save(values, filterData);

    return (
        <form>
            <ReactHookFormInput
                control={control}
                disabled={isDisabled}
                label={t('Product name')}
                name="name"
                placeholder={t('Add the name of the product')}
            />

            <ReactHookFormInput
                control={control}
                disabled={isDisabled}
                label={t('General information about the product')}
                multiline
                name="description"
            />

            <ReactHookFormSelect
                control={control}
                disabled={isDisabled}
                label={t('Template')}
                name="template"
                options={getTemplateOptions()}
            />

            <Box
                display="flex"
                flexDirection="column"
                gap={2}
                sx={{ display: 'none' }}
            >
                <Divider sx={{ mb: 2 }} />

                <Typography fontWeight="bold" variant="caption">
                    {t('Type of tax system')}
                </Typography>

                <ReactHookFormRadioButtonGroup
                    control={control}
                    disabled={isDisabled}
                    items={[
                        {
                            label: t('Physical person'),
                            value: 'physical_person',
                        },
                        {
                            label: t('Physical person with activity'),
                            value: 'person_company',
                        },
                        { label: t('Moral person'), value: 'company' },
                    ]}
                    name="types_business_company"
                />
            </Box>

            <Divider sx={{ mb: 2 }} />

            <Box display="flex" flexDirection="column">
                <Typography fontWeight="bold" variant="caption">
                    {t('Algorithm to run')}
                </Typography>
                <ReactHookFormRadioButtonGroup
                    control={control}
                    disabled={isDisabled || filePercentage !== null}
                    items={getAlgorithmTypes()}
                    name="type_algorithm"
                />
            </Box>

            <Divider sx={{ mb: 2 }} />

            <Box
                hidden={['static_payment', 'static_payment_tax'].includes(
                    typeAlgorithm
                )}
            >
                <Typography fontWeight="bold" variant="caption">
                    {t('Interest rate configuration file')}
                </Typography>

                <ReactHookFormDragnDropFile
                    accept={FILE_ALLOWED_EXTENSIONS.CSV}
                    control={control}
                    disabled={isDisabled}
                    formats="csv"
                    name="file_percentage"
                    onChange={handleOnChangeFilePercentage}
                    setValue={setValue}
                />

                <Box p={2}>
                    <Link href={downloadLink} target="_blank">
                        {t('See attached file')}
                    </Link>
                </Box>

                <Divider sx={{ mb: 2 }} />
            </Box>

            <Typography fontWeight="bold" variant="caption">
                {t('Parameter', { count: 2 })}
            </Typography>

            <Box mt={2}>
                <ReactHookFormIntlNumberInput
                    append="%"
                    control={control}
                    disabled={isDisabled}
                    fullWidth
                    label={t('Dealer fee')}
                    name="dealer_fee"
                    onBlur={() => handleOnBlurField('dealer_fee')}
                />
            </Box>

            <Grid container sx={{ mt: 0.1 }}>
                <Grid item md={9} xs={18}>
                    <ReactHookFormIntlNumberInput
                        append="%"
                        control={control}
                        disabled={isDisabled || filePercentage !== null}
                        fullWidth
                        label={t('Interest rate')}
                        name="parameters_rate.value"
                        onBlur={() =>
                            handleOnBlurField('parameters_rate.value')
                        }
                    />
                </Grid>

                <Grid item md={9} xs={18}>
                    <ReactHookFormIntlNumberInput
                        append="%"
                        control={control}
                        disabled={isDisabled || filePercentage !== null}
                        fullWidth
                        label={t('Tax on interest')}
                        name="parameters.tax"
                        onBlur={() => handleOnBlurField('parameters.tax')}
                    />
                </Grid>
            </Grid>

            <Box
                hidden={['static_payment', 'static_capital_payment'].includes(
                    typeAlgorithm
                )}
                my={2}
            >
                <ReactHookFormIntlNumberInput
                    append="%"
                    control={control}
                    disabled={isDisabled}
                    fullWidth
                    label={t('Annual increase')}
                    name="annual_increase"
                    onBlur={() => handleOnBlurField('annual_increase')}
                />
            </Box>

            <Grid
                alignItems="center"
                container
                sx={{ '.MuiFormControl-root': { mb: 0 } }}
            >
                <Grid item xs>
                    <ReactHookFormIntlNumberInput
                        append={getAppendText(parametersCommissionCredit)}
                        control={control}
                        disabled={
                            isDisabled ||
                            (parametersCommissionCredit &&
                                !parametersCommissionCredit.isChecked) ||
                            filePercentage !== null
                        }
                        fullWidth
                        label={t('Opening commission')}
                        name="parameters_commission_credit.value"
                        onBlur={() =>
                            handleOnBlurField(
                                'parameters_commission_credit.value'
                            )
                        }
                        prepend={getPreAppendText(parametersCommissionCredit)}
                    />
                </Grid>

                <Grid item md={4} xs={18}>
                    <ReactHookFormRadioButtonGroup
                        control={control}
                        disabled={
                            isDisabled ||
                            filePercentage ||
                            (parametersCommissionCredit &&
                                !parametersCommissionCredit.isChecked)
                        }
                        items={[
                            { label: t('Percentage'), value: 0 },
                            { label: t('Amount'), value: 1 },
                        ]}
                        name="parameters_commission_credit.unit"
                        row
                    />
                </Grid>
                <Grid item xs={18} md={4}>
                    <ReactHookFormIntlNumberInput
                        append="%"
                        control={control}
                        disabled={
                            isDisabled ||
                            filePercentage ||
                            (parametersCommissionCredit &&
                                !parametersCommissionCredit.isChecked)
                        }
                        fullWidth
                        label={t('Commission tax')}
                        name="parameters_commission_credit.opening_commission_vat"
                        onBlur={() =>
                            handleOnBlurField(
                                'parameters_commission_credit.opening_commission_vat'
                            )
                        }
                    />
                </Grid>
            </Grid>

            <Grid
                alignItems="center"
                container
                mt={1}
                sx={{ '.MuiFormControl-root': { mb: 0 } }}
            >
                <Grid
                    item
                    sx={{ alignItems: 'center', display: 'flex', gap: '16px' }}
                    xs
                >
                    <Box sx={{ '& .MuiCheckbox-root': { p: 0 } }}>
                        <ReactHookFormCheck
                            control={control}
                            disabled={isDisabled}
                            name="parameters_hitch.isChecked"
                        />
                    </Box>
                    <ReactHookFormIntlNumberInput
                        append={getAppendText(parametersHitch)}
                        control={control}
                        disabled={
                            !parametersHitch ||
                            !parametersHitch.isChecked ||
                            isDisabled
                        }
                        fullWidth
                        label={t('Minimum down payment')}
                        name="parameters_hitch.value"
                        onBlur={() =>
                            handleOnBlurField('parameters_hitch.value')
                        }
                        prepend={getPreAppendText(parametersHitch)}
                    />
                </Grid>

                <Grid item md={4} xs={18}>
                    <ReactHookFormRadioButtonGroup
                        control={control}
                        disabled={
                            !parametersHitch ||
                            !parametersHitch.isChecked ||
                            isDisabled
                        }
                        items={[
                            { label: t('Percentage'), value: 0 },
                            { label: t('Amount'), value: 1 },
                        ]}
                        name="parameters_hitch.unit"
                        row
                    />
                </Grid>
            </Grid>

            <Grid container mt={1}>
                <Grid
                    item
                    sx={{ alignItems: 'center', display: 'flex', gap: '16px' }}
                    xs={18}
                >
                    <Box sx={{ '& .MuiCheckbox-root': { p: 0 } }}>
                        <ReactHookFormCheck
                            control={control}
                            disabled={isDisabled}
                            name="parameters_residual.isChecked"
                        />
                    </Box>
                    <ReactHookFormIntlNumberInput
                        append="%"
                        control={control}
                        disabled={
                            !parametersResidual ||
                            !parametersResidual.isChecked ||
                            isDisabled
                        }
                        fullWidth
                        label={t('Minimal residual')}
                        name="parameters_residual.value"
                        onBlur={() =>
                            handleOnBlurField('parameters_residual.value')
                        }
                    />
                </Grid>
            </Grid>

            <Grid container>
                <Grid
                    item
                    sx={{ alignItems: 'center', display: 'flex', gap: '16px' }}
                    xs={18}
                >
                    <Box sx={{ '& .MuiCheckbox-root': { p: 0 } }}>
                        <ReactHookFormCheck
                            control={control}
                            disabled={isDisabled}
                            name="parameters_insurance.isChecked"
                        />
                    </Box>
                    <ReactHookFormIntlNumberInput
                        append={currencyIso}
                        control={control}
                        disabled={
                            !parametersInsurance ||
                            !parametersInsurance.isChecked ||
                            isDisabled
                        }
                        fullWidth
                        label={t('Annual insurance')}
                        name="parameters_insurance.value"
                        prepend={currencySymbol}
                        onBlur={() =>
                            handleOnBlurField('parameters_insurance.value')
                        }
                    />
                </Grid>
            </Grid>

            <Divider sx={{ mb: 2 }} />

            <Typography fontWeight="bold" variant="caption">
                {t('Limit amount')}
            </Typography>

            <Grid container sx={{ mt: 0.2 }}>
                <Grid
                    item
                    md={9}
                    sx={{ alignItems: 'center', display: 'flex', gap: '16px' }}
                    xs={18}
                >
                    <Box sx={{ '& .MuiCheckbox-root': { p: 0 } }}>
                        <ReactHookFormCheck
                            control={control}
                            disabled={isDisabled}
                            name="amount_values.isChecked"
                        />
                    </Box>
                    <ReactHookFormIntlNumberInput
                        append={currencyIso}
                        control={control}
                        disabled={
                            !amountValues ||
                            !amountValues.isChecked ||
                            isDisabled
                        }
                        fullWidth
                        label={t('Minimum amount')}
                        name="amount_values.min"
                        prepend={currencySymbol}
                        onBlur={() => handleOnBlurField('amount_values.min')}
                    />
                </Grid>

                <Grid item md={9} xs={18}>
                    <ReactHookFormIntlNumberInput
                        append={currencyIso}
                        control={control}
                        disabled={
                            !amountValues ||
                            !amountValues.isChecked ||
                            isDisabled
                        }
                        fullWidth
                        label={t('Maximum amount')}
                        name="amount_values.max"
                        prepend={currencySymbol}
                        onBlur={() => handleOnBlurField('amount_values.max')}
                    />
                </Grid>
            </Grid>

            <Divider sx={{ mb: 2 }} />

            <Typography fontWeight="bold" variant="caption">
                {t('Power limit')}
            </Typography>

            <Grid container sx={{ mt: 0.2 }}>
                <Grid
                    item
                    md={9}
                    sx={{ alignItems: 'center', display: 'flex', gap: '16px' }}
                    xs={18}
                >
                    <Box sx={{ '& .MuiCheckbox-root': { p: 0 } }}>
                        <ReactHookFormCheck
                            control={control}
                            disabled={isDisabled}
                            name="power_values.isChecked"
                        />
                    </Box>
                    <ReactHookFormIntlNumberInput
                        append={'kW'}
                        control={control}
                        disabled={
                            !powerValues || !powerValues.isChecked || isDisabled
                        }
                        fullWidth
                        label={t('Minimum power')}
                        name="power_values.min"
                        onBlur={() => handleOnBlurField('power_values.max')}
                    />
                </Grid>

                <Grid item md={9} xs={18}>
                    <ReactHookFormIntlNumberInput
                        append={'kW'}
                        control={control}
                        disabled={
                            !powerValues || !powerValues.isChecked || isDisabled
                        }
                        fullWidth
                        label={t('Maximum power')}
                        name="power_values.max"
                        onBlur={() => handleOnBlurField('power_values.max')}
                    />
                </Grid>
            </Grid>

            <Divider sx={{ mb: 2 }} />

            <Box display="flex" flexDirection="column" gap={2}>
                <Typography fontWeight="bold" variant="caption">
                    {t('Select the periods of the product')}
                </Typography>
                <ReactHookFormCheckboxButtonGroup
                    control={control}
                    disabled={isDisabled || filePercentage !== null}
                    items={DEFAULT_TERMS}
                    name="parameters.terms"
                    row
                />
            </Box>
            <ShowErrors errors={errors} />
            <Divider sx={{ mb: 2 }} />
            <Grid container>
                <Grid item xs={18} md={8}>
                    <Button
                        color="secondary"
                        href={`${process.env.REACT_APP_ACADEMY_URL}es/articles/4155161-agregar-un-producto-financiero-propio`}
                        sx={{
                            '&.MuiButton-root': { mb: { xs: 'auto', md: 0 } },
                            width: { md: 'auto', xs: '100%' },
                        }}
                        target="_blank"
                        variant="text"
                    >
                        {t('See manual')}
                    </Button>
                </Grid>

                <Grid
                    item
                    md={10}
                    sx={{
                        display: 'flex',
                        flexDirection: { md: 'row', xs: 'column' },
                        justifyContent: { md: 'right', xs: 'center' },
                    }}
                    xs={18}
                >
                    <Button
                        color="secondary"
                        onClick={closeModal}
                        sx={{
                            '&.MuiButton-root': { mb: { xs: 'auto', md: 0 } },
                            width: { xs: '100%', md: 'auto' },
                            order: { xs: 2, md: 1 },
                            mr: { xs: 0, md: 2 },
                        }}
                        type="button"
                        variant="text"
                    >
                        {t('Cancel')}
                    </Button>

                    <Button
                        disabled={isDisabled}
                        onClick={handleSubmit(handleSave)}
                        sx={{
                            '&.MuiButton-root': { mb: { xs: 'auto', md: 0 } },
                            order: { md: 2, xs: 1 },
                            width: { md: 'auto', xs: '100%' },
                        }}
                        type="button"
                        variant="outlined"
                    >
                        {t('Save')}
                    </Button>
                </Grid>
            </Grid>
        </form>
    );
};

ProductForm.propTypes = {
    closeModal: PropTypes.func,
    errors: PropTypes.array,
    fetchTemplates: PropTypes.func,
    filterData: PropTypes.object,
    initialValues: PropTypes.object,
    isFetchingTemplates: PropTypes.bool,
    isSavingProduct: PropTypes.bool,
    save: PropTypes.func,
    templates: PropTypes.array,
};

const mapStateToProps = createStructuredSelector({
    errors: selectors.getSaveProductErrors,
    initialValues: selectors.getInitialValues,
    isSavingProduct: selectors.getIsSavingProduct,
});

const mapDispatchToProps = (dispatch) => ({
    save: (values, filterData) => dispatch(actions.save(values, filterData)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProductForm);
