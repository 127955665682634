import { getOfferDatasheets } from 'common/api/v2/offerDatasheet';

import {
    FETCH_OFFER_DATASHEETS,
    FETCH_OFFER_DATASHEETS_FAILURE,
    FETCH_OFFER_DATASHEETS_SUCCESS,
} from '../actionTypes';
import { multiTemplateReviewActions } from '../reducer';

export default (id) => (dispatch) =>
    new Promise((resolve) => {
        dispatch(multiTemplateReviewActions[FETCH_OFFER_DATASHEETS]());

        getOfferDatasheets(id)
            .then((response) =>
                dispatch(
                    multiTemplateReviewActions[FETCH_OFFER_DATASHEETS_SUCCESS](
                        response.data.data
                    )
                )
            )
            .catch((error) =>
                dispatch(
                    multiTemplateReviewActions[FETCH_OFFER_DATASHEETS_FAILURE](
                        error
                    )
                )
            )
            .finally(resolve);
    });
