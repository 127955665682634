import React from 'react';

import { useTranslation } from 'react-i18next';
import { Alert } from 'sunwise-ui';

const MaxDaysInYearWarning = () => {
    const { t } = useTranslation();
    return (
        <Alert severity="warning">
            {t(
                'The dates for your periods have been adjusted to be compatible with a consumption profile'
            )}
        </Alert>
    );
};

export default MaxDaysInYearWarning;
