import React from 'react';

import ArticleIcon from '@mui/icons-material/Article';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box, Tooltip, Typography } from 'sunwise-ui';

const ProposalsTooltip = ({ isObsolete, proposals, proposalsNumber }) => {
    const { t } = useTranslation();

    return (
        <Tooltip
            placement="top"
            title={
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        gap: 1,
                        p: 1,
                    }}
                >
                    <Typography variant="caption" fontWeight="bold">
                        {proposalsNumber} {t('Proposal', { count: 2 })}
                    </Typography>
                    {isObsolete && (
                        <Typography variant="caption" sx={{ color: '#b00020' }}>
                            {t(
                                'The proposals linked to this summary have been edited, archived or deleted'
                            )}
                        </Typography>
                    )}
                    {proposals.map((document) => (
                        <Typography
                            variant="caption"
                            key={`document-item-${document.id}`}
                        >
                            {document.name}
                        </Typography>
                    ))}
                </Box>
            }
        >
            <ArticleIcon fontSize="small" />
        </Tooltip>
    );
};

ProposalsTooltip.propTypes = {
    isObsolete: PropTypes.bool,
    proposals: PropTypes.array,
    proposalsNumber: PropTypes.number,
};

export default ProposalsTooltip;
