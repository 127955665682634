import React from 'react';

import { styled } from '@mui/material/styles';
import { tooltipClasses } from '@mui/material/Tooltip';
import PropTypes from 'prop-types';
import { Alert, Tooltip } from 'sunwise-ui';

import { truncateString } from 'common/utils/helpers';

const CustomWidthTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))({
    [`& .${tooltipClasses.tooltip}`]: { maxWidth: 'none' },
});

const StepError = ({ error }) => {
    if (!error) return null;

    return (
        <Alert severity="error">
            <CustomWidthTooltip placement="top" title={error}>
                <div>{truncateString(error, 300)}</div>
            </CustomWidthTooltip>
        </Alert>
    );
};

StepError.propTypes = {
    error: PropTypes.string,
};

export default StepError;
