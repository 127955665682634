import { types } from 'sunwise-template-core';

import { getFilteredTemplates } from 'common/api/v1/offerCustomTemplate';

import {
    FETCH_DOCUMENT_TEMPLATES,
    FETCH_DOCUMENT_TEMPLATES_FAILURE,
    FETCH_DOCUMENT_TEMPLATES_SUCCESS,
} from '../actionTypes';
import { multiTemplateReviewActions } from '../reducer';

export default () => (dispatch) => {
    dispatch(multiTemplateReviewActions[FETCH_DOCUMENT_TEMPLATES]());

    getFilteredTemplates({ type: types.SMART_DOCUMENTS_TYPE })
        .then((response) =>
            dispatch(
                multiTemplateReviewActions[FETCH_DOCUMENT_TEMPLATES_SUCCESS](
                    response.data.data
                )
            )
        )
        .catch((error) =>
            dispatch(
                multiTemplateReviewActions[FETCH_DOCUMENT_TEMPLATES_FAILURE](
                    error
                )
            )
        );
};
