import i18next from 'i18next';
import { createSelector } from 'reselect';

import { NAME } from './constants';
import { getContactName } from './helpers';

export const getModel = (state) => state[NAME];

export const getInitialValuesFilters = createSelector(
    getModel,
    (model) => model.initialValuesFilters
);

export const getIsOpenFiltersModal = createSelector(
    getModel,
    (model) => model.isOpenFiltersModal
);

export const getContactsModel = createSelector(
    getModel,
    (model) => model.contacts
);

export const getContactsData = createSelector(
    getContactsModel,
    (model) => model.data || {}
);

export const getAgents = createSelector(
    getContactsData,
    (data) => data.agents || []
);

export const getAgentsForSelect = createSelector(getAgents, (agents) => {
    const items = agents?.map((agent) => ({
        label: getContactName(agent),
        value: agent.id,
    }));

    return [
        {
            label: '',
            options: [
                { label: i18next.t('All text'), value: null },
                ...(items || []),
            ],
        },
    ];
});

export const getContacts = createSelector(
    getContactsData,
    (data) => data.contacts || []
);

export const getContactsIsLoading = createSelector(
    getContactsModel,
    (model) => model.loading
);

export const getContactsForSelect = createSelector(getContacts, (contacts) => {
    const items = contacts?.map((contact) => ({
        label: getContactName(contact),
        value: contact.id,
    }));

    return [
        {
            label: '',
            options: [
                { label: i18next.t('All text'), value: null },
                ...(items || []),
            ],
        },
    ];
});

export const getProjectStatuses = createSelector(
    getContactsData,
    (data) => data.project_status || []
);

export const getProjectStatusesForSelect = createSelector(
    getProjectStatuses,
    (statuses) => {
        const items = statuses?.map((status) => ({
            label: status.name,
            value: status.id,
        }));

        return [
            {
                label: '',
                options: [
                    { label: i18next.t('All text'), value: null },
                    ...(items || []),
                ],
            },
        ];
    }
);

export const getProjects = createSelector(
    getContactsData,
    (data) => data.projects || []
);

export const getProjectsForSelect = createSelector(getProjects, (projects) => {
    const items = projects?.map((project) => ({
        label: project.name,
        value: project.id,
    }));

    return [
        {
            label: '',
            options: [
                { label: i18next.t('All text'), value: null },
                ...(items || []),
            ],
        },
    ];
});

export const getProjectsByContact = createSelector(getProjects, (projects) => {
    if (!projects?.length) return [];

    return projects.reduce((acc, project) => {
        const contactId = project?.contact?.id;
        if (!contactId) return acc;
        if (!acc[contactId]) acc[contactId] = [];
        acc[contactId].push(project);
        return acc;
    }, {});
});

export const getIndicatorsDataModel = createSelector(
    getModel,
    (model) => model.indicatorsData
);

export const getIndicatorsData = createSelector(
    getIndicatorsDataModel,
    (model) => model.data || {}
);

export const getIndicatorsDataIsLoading = createSelector(
    getIndicatorsDataModel,
    (model) => model.loading
);

export const getMonitoringModel = createSelector(
    getModel,
    (model) => model.monitoring
);

export const getMonitoringData = createSelector(
    getMonitoringModel,
    (model) => model.data || {}
);

export const getMonitoringProjects = createSelector(
    getMonitoringData,
    (data) => data.sunwise_projects || []
);

export const getMonitoringProjectsById = createSelector(
    getMonitoringProjects,
    (data) =>
        data.reduce((acc, project) => {
            if (project.id) acc[project.id] = project;
            return acc;
        }, {}) || {}
);

export const getMonitoringIsLoading = createSelector(
    getMonitoringModel,
    (model) => model.loading
);

export const getMonitoringErrors = createSelector(
    getMonitoringModel,
    (model) => model.errors
);

export const getPoliticalDivisionModel = createSelector(
    getModel,
    (model) => model.politicalDivisions
);

export const getPoliticalDivisionData = createSelector(
    getPoliticalDivisionModel,
    (model) => model.data || []
);

export const getPoliticalDivisionForSelect = createSelector(
    getPoliticalDivisionData,
    (data) => {
        const items = data?.map(({ id, name }) => ({ label: name, value: id }));
        return [
            {
                label: '',
                options: [
                    { label: i18next.t('All text'), value: null },
                    ...(items || []),
                ],
            },
        ];
    }
);

export const getPoliticalDivisionErrors = createSelector(
    getPoliticalDivisionModel,
    (model) => model.errors
);

export const getPoliticalDivisionIsLoading = createSelector(
    getPoliticalDivisionModel,
    (model) => model.loading
);

export const getProjectModel = createSelector(
    getModel,
    (model) => model.project
);

export const getProjectData = createSelector(
    getProjectModel,
    (model) => model.data
);

export const getProjectErrors = createSelector(
    getProjectModel,
    (model) => model.errors
);

export const getProjectIsLoading = createSelector(
    getProjectModel,
    (model) => model.loading
);

export const getRatesModel = createSelector(getModel, (model) => model.rates);

export const getRatesData = createSelector(
    getRatesModel,
    (model) => model?.data || []
);

export const getRatesErrors = createSelector(
    getRatesModel,
    (model) => model?.errors
);

export const getRatesIsLoading = createSelector(
    getRatesModel,
    (model) => model?.loading
);

export const getRatesForSelect = createSelector(getRatesData, (data) => {
    const { certified_rates, custom_rates } = data;

    const rates = [];

    if (certified_rates?.length)
        rates.push({
            label: i18next.t('Certificated'),
            options: certified_rates
                .filter((rate) => rate.is_enable)
                .map(({ id, name }) => ({ label: name, value: id })),
        });

    if (custom_rates?.length)
        rates.push({
            label: i18next.t('Custom', { context: 'female', count: 2 }),
            options: custom_rates
                .filter((rate) => rate?.properties?.is_enable)
                .map(({ id, properties }) => ({
                    label: properties?.name,
                    value: id,
                })),
        });

    return rates;
});
