import { fetchListCatalogs } from 'common/api/v1/companiesCatalogs';

import {
    FETCH_COMPANIES_CATALOGS,
    FETCH_COMPANIES_CATALOGS_FAILURE,
    FETCH_COMPANIES_CATALOGS_SUCCESS,
} from '../actionTypes';
import { proposalSummaryActions } from '../reducer';

export default (type) => (dispatch) => {
    dispatch(proposalSummaryActions[FETCH_COMPANIES_CATALOGS]());

    fetchListCatalogs(type)
        .then((response) => {
            dispatch(
                proposalSummaryActions[FETCH_COMPANIES_CATALOGS_SUCCESS](
                    response.data.data
                )
            );
        })
        .catch((error) =>
            dispatch(
                proposalSummaryActions[FETCH_COMPANIES_CATALOGS_FAILURE](error)
            )
        );
};
