import get from 'lodash/get';
import { object, string, ref } from 'yup';

import { ROLE_LEVELS } from 'common/constants/permissionsV2';
import getValidationTexts from 'common/utils/getValidationTexts';
import { getIsMultibranchesAccount } from 'common/utils/helpers/session';

export default (formValues, { isNewRecord }) => {
    const { PASSWORDS_DONT_MATCH, REQUIRED_TEXT } = getValidationTexts();
    const roleLevel = get(formValues, 'role_level', null);
    const isOwnerRole = roleLevel === ROLE_LEVELS.OWNER;
    const isMultiBranchesUser = getIsMultibranchesAccount();
    const isCustomOrGM = [
        ROLE_LEVELS.CUSTOMIZED,
        ROLE_LEVELS.GENERAL_MANAGER,
    ].includes(roleLevel);

    let validationRules = {
        company_group: string().required(REQUIRED_TEXT).nullable(),
        first_name: string().required(REQUIRED_TEXT).nullable(),
        last_name: string().required(REQUIRED_TEXT).nullable(),
        email: string().required(REQUIRED_TEXT).nullable(),
    };

    if (isNewRecord)
        validationRules = {
            ...validationRules,
            password: string().min(8).required(REQUIRED_TEXT).nullable(),
            confirm_password: string()
                .min(8)
                .required(REQUIRED_TEXT)
                .oneOf([ref('password'), null], PASSWORDS_DONT_MATCH)
                .nullable(),
        };

    if (isMultiBranchesUser && !isOwnerRole) {
        if (isCustomOrGM) {
            validationRules = {
                ...validationRules,
                branch_offices: string().required(REQUIRED_TEXT).nullable(),
            };
        } else {
            validationRules = {
                ...validationRules,
                branch_office: string().required(REQUIRED_TEXT).nullable(),
            };
        }
    }

    return object().shape(validationRules);
};
