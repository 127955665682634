import { getIndicatorsData } from 'common/api/afterSales/projects';

import {
    FETCH_INDICATORS_DATA,
    FETCH_INDICATORS_DATA_FAILURE,
    FETCH_INDICATORS_DATA_SUCCESS,
} from '../actionTypes';
import { actions } from '../reducer';

export default () => (dispatch) => {
    dispatch(actions[FETCH_INDICATORS_DATA]());

    getIndicatorsData()
        .then((response) =>
            dispatch(actions[FETCH_INDICATORS_DATA_SUCCESS](response?.data))
        )
        .catch((error) =>
            dispatch(actions[FETCH_INDICATORS_DATA_FAILURE](error?.response))
        );
};
