import { filterItems, getCompanyUsers } from 'common/api/v1/users';

import {
    FETCH_ITEMS,
    FETCH_ITEMS_FAILURE,
    FETCH_ITEMS_SUCCESS,
} from '../actionTypes';
import { companyUsersActions } from '../reducer';

export default (searchText = '') =>
    (dispatch) => {
        dispatch(companyUsersActions[FETCH_ITEMS]());

        const request = searchText
            ? filterItems(searchText)
            : getCompanyUsers();

        request
            .then((response) => {
                dispatch(
                    companyUsersActions[FETCH_ITEMS_SUCCESS](response.data.data)
                );
            })
            .catch((error) =>
                dispatch(
                    companyUsersActions[FETCH_ITEMS_FAILURE](
                        error?.response?.data?.errors
                    )
                )
            );
    };
