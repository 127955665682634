import isEmpty from 'lodash/isEmpty';

import { numberFormat } from 'common/utils/helpers';

export const getChartSettings = (
    categories,
    currencyIso,
    currencyLocale,
    currencySymbol,
    series
) => {
    return {
        options: {
            colors: ['#0073d0', '#03e396'],
            dataLabels: {
                enabled: false,
            },
            legend: {
                show: true,
            },
            xaxis: {
                categories: categories,
            },
            yaxis: [
                {
                    axisBorder: {
                        show: true,
                        color: '#0073d0',
                    },
                    decimalsInFloat: 0,
                    labels: {
                        formatter: (val) =>
                            numberFormat(val, {
                                currency: currencyIso,
                                locale: currencyLocale,
                                style: 'currency',
                                symbol: currencySymbol,
                            }),
                    },
                },
                {
                    opposite: true,
                    axisBorder: {
                        show: true,
                        color: '#03e396',
                    },
                    decimalsInFloat: 0,
                    labels: {
                        formatter: (val) =>
                            numberFormat(val, {
                                decimals: 0,
                                locale: currencyLocale,
                                style: 'decimal',
                                unit: 'kW',
                            }),
                    },
                },
            ],
            chart: {
                background: '#ffffff00',
                toolbar: {
                    show: false,
                },
            },
        },
        series: series,
    };
};

export const getProjectsChartSettings = (
    categories,
    currencyLocale,
    series
) => {
    return {
        options: {
            colors: ['#0073d0'],
            dataLabels: {
                enabled: false,
            },
            legend: {
                show: true,
            },
            xaxis: {
                categories: categories,
            },
            yaxis: [
                {
                    axisBorder: {
                        show: true,
                        color: '#0073d0',
                    },
                    decimalsInFloat: 0,
                    labels: {
                        formatter: (val) =>
                            numberFormat(val, {
                                decimals: 0,
                                locale: currencyLocale,
                                style: 'decimal',
                            }),
                    },
                },
            ],
            chart: {
                background: '#ffffff00',
                toolbar: {
                    show: false,
                },
            },
        },
        series: [series],
    };
};

export const getReadyPlaceholder = (defaultCurrency, isLoading) =>
    isLoading || isEmpty(defaultCurrency);
