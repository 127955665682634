import { getSessionLocalStorage } from 'common/utils/helpers/session';

import contactForm from '../../contactForm';

export default (branchSelected) => (dispatch) => {
    const session = getSessionLocalStorage();

    const newValues = {
        agent: session.id,
        branch_office: !['all', 'company'].includes(branchSelected)
            ? branchSelected
            : '',
    };

    dispatch(contactForm.actions.initializeForm(newValues));
    dispatch(contactForm.actions.setIsNewRecord(true));
    dispatch(contactForm.actions.setIsShowInformation(false));
    dispatch(contactForm.actions.showModal(true));
};
