import i18next from 'i18next';

import alerts from 'common/modules/alerts';

import deleteProject from './deleteProject';

export default (project, onSuccess) => (dispatch) => {
    const projectName = project.name;

    const dataAlert = {
        confirmText: i18next.t('Delete'),
        messages: [
            i18next.t('Are you sure to delete the {{ projectName }} project?', {
                projectName: projectName,
            }),
            i18next.t('Once deleted, all element information will be lost'),
        ],
        onSuccess: () => dispatch(deleteProject(project.id, onSuccess)),
        title: i18next.t('Confirmation'),
        type: alerts.ALERT_TYPE_CONFIRM,
        variant: 'warning',
    };

    dispatch(alerts.actions.show(dataAlert));
};
