import { createSelector } from 'reselect';

import { NAME } from './constants';

export const getModel = (state) => state[NAME];

export const getInitialValues = createSelector(
    getModel,
    (model) => model.initialValues
);

export const getModelSave = createSelector(getModel, (model) => model.save);

export const getSaveData = createSelector(getModelSave, (model) => model.data);

export const getSaveErrors = createSelector(
    getModelSave,
    (model) => model.errors
);

export const getIsSaving = createSelector(
    getModelSave,
    (model) => model.isSaving
);

export const getModelFetch = createSelector(getModel, (model) => model.fetch);

export const getFetchData = createSelector(
    getModelFetch,
    (model) => model.data
);

export const getFetchErrors = createSelector(
    getModelFetch,
    (model) => model.errors
);

export const getIsFetching = createSelector(
    getModelFetch,
    (model) => model.isFetching
);
