import { createSelector } from 'reselect';

import { NAME } from './constants';

export const getModel = (state) => state[NAME];

export const getInitialValues = createSelector(
    getModel,
    (model) => model.initialValues
);

export const getSession = createSelector(getModel, (model) => model.session);

export const getShowRedirectLogin = createSelector(
    getModel,
    (model) => model.showRedirectLogin
);

export const getUuid = createSelector(getModel, (model) => model.uuid);

export const getToken = createSelector(getModel, (model) => model.token);

export const getIsLoadingSession = createSelector(
    getSession,
    (session) => session.isLoading
);

export const getErrorsLogin = createSelector(
    getSession,
    (session) => session.errors
);

export const getValidPasswordConfirmationLength = createSelector(
    getModel,
    (model) => model.validPasswordConfirmationLength
);

export const getValidPassword = createSelector(
    getModel,
    (model) => model.validPassword
);

export const getValidPasswordConfirmation = createSelector(
    getModel,
    (model) => model.validPasswordConfirmation
);
