import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { BottomActions, Box, Button, Tabs, Tab, TabPanel } from 'sunwise-ui';

import { RATES_WITH_POWER_FACTOR } from 'common/constants/rates';
import yupResolver from 'common/utils/yupResolver';

import * as AnalysisVsGenerationselectors from '../../proposalGeneratorConsumptionAnalysisVsGeneration/selectors';
import * as actions from '../actions';
import * as selectors from '../selectors';
import validate from '../validate';

import ConsiderationsFields from './ConsiderationsFields';
import CurrencyFields from './CurrencyFields';
import DiscountFields from './DiscountFields';
import ExportedEnergyFields from './ExportedEnergyFields';
import OpexFields from './OpexFields';
import PlanFields from './PlanFields';
import PowerFactorFields from './PowerFactorFields';
import PPAFields from './PPAFields';
import RateTaxesFields from './RateTaxesFields';
import RoiFields from './RoiFields';
import TimeToCommissioningFields from './TimeToCommissioningFields';

const FormContainer = ({
    canModifyCurrencyType,
    canModifyDiscounts,
    canModifyExchangeType,
    canModifyIvaCFE,
    canModifyPlans,
    canModifyPricePerWatt,
    canModifyRatesAnualCFE,
    canModifyRatesDap,
    canModifyRoiSolarDegradation,
    canModifyRoiTaxIncentive,
    canModifyStockExchange,
    canModifySubtotal,
    compensationScheme,
    currencies,
    currencyIso,
    currencySymbol,
    customerMode,
    handleClickCancel,
    hasOfferProfiles,
    initialValues,
    isLocked,
    isSaving,
    maximumDiscount,
    nextRateData,
    plans,
    priceByWattMin,
    proposalId,
    save,
    selectedPrevRate,
    subtotalMin,
}) => {
    const { t } = useTranslation();
    const [selectedTab, setSelectedTab] = useState('general');
    const { control, handleSubmit, reset, setValue } = useForm({
        context: { maximumDiscount, priceByWattMin, subtotalMin },
        defaultValues: initialValues,
        resolver: yupResolver(validate),
    });

    useEffect(() => reset(initialValues), [initialValues]);

    const handleChange = (_, newValue) => setSelectedTab(newValue);

    const showPowerFactor =
        selectedPrevRate?.isCertified &&
        RATES_WITH_POWER_FACTOR.includes(selectedPrevRate?.name);

    const handleOnSave = (values) =>
        save(proposalId, { ...values, showPowerFactor });

    return (
        <form>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={selectedTab} onChange={handleChange}>
                    <Tab
                        id="tab-general"
                        key="tab-general"
                        label={t('General')}
                        value="general"
                    />
                    <Tab
                        id="tab-system"
                        key="tab-system"
                        label={t('System')}
                        value="system"
                    />
                    <Tab
                        id="tab-supplier"
                        key="tab-supplier"
                        label={t('Supplier')}
                        value="supplier"
                    />
                    <Tab
                        id="tab-considerations"
                        key="tab-considerations"
                        label={t('Terms and considerations')}
                        value="considerations"
                    />
                </Tabs>
            </Box>

            <TabPanel
                key="tab-general"
                selectedTab={selectedTab}
                value="general"
            >
                <PlanFields
                    control={control}
                    isLocked={!canModifyPlans || isLocked}
                    plans={plans}
                    setValue={setValue}
                />

                <CurrencyFields
                    canModifyCurrencyType={canModifyCurrencyType}
                    canModifyExchangeType={canModifyExchangeType}
                    control={control}
                    currencies={currencies}
                    isLocked={isLocked}
                    setValue={setValue}
                />

                <DiscountFields
                    canModifyDiscounts={canModifyDiscounts}
                    canModifyPricePerWatt={canModifyPricePerWatt}
                    canModifySubtotal={canModifySubtotal}
                    control={control}
                    currencies={currencies}
                    currencyIso={currencyIso}
                    currencySymbol={currencySymbol}
                    isHidden={customerMode}
                    isLocked={isLocked}
                    setValue={setValue}
                />
            </TabPanel>

            <TabPanel key="tab-system" selectedTab={selectedTab} value="system">
                <TimeToCommissioningFields
                    control={control}
                    isHidden={customerMode}
                    isLocked={isLocked}
                />

                <RoiFields
                    canModifyRoiSolarDegradation={canModifyRoiSolarDegradation}
                    canModifyRoiTaxIncentive={canModifyRoiTaxIncentive}
                    canModifyStockExchange={canModifyStockExchange}
                    control={control}
                    isLocked={isLocked}
                    nextRateData={nextRateData}
                />

                <OpexFields
                    canModify={true}
                    control={control}
                    isHidden={customerMode}
                    isLocked={isLocked}
                    setValue={setValue}
                />
            </TabPanel>

            <TabPanel
                key="tab-supplier"
                selectedTab={selectedTab}
                value="supplier"
            >
                <RateTaxesFields
                    canModifyIvaCFE={canModifyIvaCFE}
                    canModifyRatesAnualCFE={canModifyRatesAnualCFE}
                    canModifyRatesDap={canModifyRatesDap}
                    control={control}
                    isLocked={isLocked}
                    nextRateData={nextRateData}
                />

                <PowerFactorFields
                    control={control}
                    isLocked={isLocked}
                    showPowerFactor={showPowerFactor}
                />

                <ExportedEnergyFields
                    compensationScheme={compensationScheme}
                    control={control}
                    currencySymbol={currencySymbol}
                    hasOfferProfiles={hasOfferProfiles}
                    isLocked={isLocked}
                />

                <PPAFields
                    control={control}
                    currencySymbol={currencySymbol}
                    isLocked={isLocked}
                />
            </TabPanel>

            <TabPanel
                key="tab-considerations"
                selectedTab={selectedTab}
                value="considerations"
            >
                <ConsiderationsFields control={control} isLocked={isLocked} />
            </TabPanel>

            <BottomActions>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: { md: 'row', xs: 'column' },
                        gap: '16px',
                        justifyContent: { md: 'right', xs: 'center' },
                        pt: 2,
                        width: '100%',
                    }}
                >
                    <Button
                        color="secondary"
                        onClick={() => handleClickCancel()}
                        sx={{
                            order: { md: 1, xs: 2 },
                            width: { md: 'auto', xs: '100%' },
                        }}
                        variant="text"
                    >
                        {t('Cancel')}
                    </Button>
                    <Button
                        disabled={isLocked}
                        onClick={handleSubmit(handleOnSave)}
                        sx={{
                            order: { md: 2, xs: 1 },
                            width: { md: 'auto', xs: '100%' },
                        }}
                        type="submit"
                        variant="outlined"
                    >
                        {isSaving ? t('Saving').concat('...') : t('Save')}
                    </Button>
                </Box>
            </BottomActions>
        </form>
    );
};

const mapStateToProps = createStructuredSelector({
    initialValues: selectors.getInitialValues,
    selectedPrevRate: AnalysisVsGenerationselectors.getSelectedPrevRateData,
});

const mapDispatchToProps = (dispatch) => ({
    save: (id, valuesForm) => dispatch(actions.save(id, valuesForm)),
});

FormContainer.propTypes = {
    canModifyCurrencyType: PropTypes.bool,
    canModifyDiscounts: PropTypes.bool,
    canModifyExchangeType: PropTypes.bool,
    canModifyIvaCFE: PropTypes.bool,
    canModifyPlans: PropTypes.bool,
    canModifyPricePerWatt: PropTypes.bool,
    canModifyRatesAnualCFE: PropTypes.bool,
    canModifyRatesDap: PropTypes.bool,
    canModifyRoiSolarDegradation: PropTypes.bool,
    canModifyRoiTaxIncentive: PropTypes.bool,
    canModifyStockExchange: PropTypes.bool,
    canModifySubtotal: PropTypes.bool,
    compensationScheme: PropTypes.string,
    currencies: PropTypes.array,
    currencyIso: PropTypes.string,
    currencySymbol: PropTypes.string,
    customerMode: PropTypes.bool,
    handleClickCancel: PropTypes.func,
    hasOfferProfiles: PropTypes.bool,
    initialValues: PropTypes.object,
    isLocked: PropTypes.bool,
    isSaving: PropTypes.bool,
    maximumDiscount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    nextRateData: PropTypes.object,
    plans: PropTypes.array,
    priceByWattMin: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    proposalId: PropTypes.string,
    save: PropTypes.func,
    selectedPrevRate: PropTypes.object,
    subtotalMin: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default connect(mapStateToProps, mapDispatchToProps)(FormContainer);
