import { filterStructures } from 'common/api/v1/productsBulk';

import {
    FETCH_ITEMS,
    FETCH_ITEMS_FAILURE,
    FETCH_ITEMS_SUCCESS,
} from '../actionTypes';
import { structuresConfigurationActions } from '../reducer';

export default ({
        orderBy,
        page = 0,
        pageSize,
        searchText,
        sortBy,
        status,
    } = {}) =>
    (dispatch) => {
        dispatch(structuresConfigurationActions[FETCH_ITEMS]());

        filterStructures({
            orderBy,
            page: page + 1,
            perPage: pageSize,
            searchText,
            sortBy,
            status,
        })
            .then((response) =>
                dispatch(
                    structuresConfigurationActions[FETCH_ITEMS_SUCCESS](
                        response.data
                    )
                )
            )
            .catch((error) =>
                dispatch(
                    structuresConfigurationActions[FETCH_ITEMS_FAILURE](
                        error?.response?.data?.errors
                    )
                )
            );
    };
