import API from '../..';
const ENTITY = 'aftersales-project-configuration';

export const create = (data) =>
    API.post(
        `/api/v1/${ENTITY}/`,
        { ...data },
        { headers: { 'Content-Type': 'application/json' } }
    );
export const fetchData = (uuid) => API.get(`/api/v1/${ENTITY}/${uuid}`);
export const update = (uuid, data) =>
    API.put(
        `/api/v1/${ENTITY}/${uuid}`,
        { ...data },
        { headers: { 'Content-Type': 'application/json' } }
    );
export const deleteItem = (uuid) => API.delete(`/api/v1/${ENTITY}/${uuid}`);
