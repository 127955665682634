import { createItem } from 'common/api/v2/offerStructure';
import { showReponseErrorsAsAlert } from 'common/utils/helpers';

import eventsAfterUpdate from '../../proposalGeneratorOneColumn/actions/eventsAfterUpdate';
import { CREATE, CREATE_SUCCESS, CREATE_FAILURE } from '../actionTypes';
import { proposalGeneratorStructureTableActions } from '../reducer';

export default (proposalId, values) => (dispatch) => {
    if (!values.item) return false;

    const newValues = {
        product_branch_office: values.item,
    };

    dispatch(proposalGeneratorStructureTableActions[CREATE]());

    createItem(newValues, proposalId)
        .then((response) => {
            dispatch(
                proposalGeneratorStructureTableActions[CREATE_SUCCESS](
                    response.data.data
                )
            );
            dispatch(eventsAfterUpdate(response.data.data, proposalId));
        })
        .catch((error) => {
            dispatch(
                proposalGeneratorStructureTableActions[CREATE_FAILURE](
                    error?.response?.data?.errors
                )
            );
            showReponseErrorsAsAlert(dispatch, error?.response);
        });
};
