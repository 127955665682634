import { getLogs } from 'common/api/afterSales/projects';

import {
    FETCH_LOGS,
    FETCH_LOGS_FAILURE,
    FETCH_LOGS_SUCCESS,
} from '../actionTypes';
import { actions } from '../reducer';

export default (page = 1, pageSize = 8) =>
    (dispatch) => {
        dispatch(actions[FETCH_LOGS]());

        getLogs(page, pageSize)
            .then((response) =>
                dispatch(actions[FETCH_LOGS_SUCCESS](response?.data))
            )
            .catch((error) => dispatch(actions[FETCH_LOGS_FAILURE](error)));
    };
