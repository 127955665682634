import { createSelector } from 'reselect';

import { NAME } from './constants';

export const getModel = (state) => state[NAME];

export const getFetchTaxes = createSelector(
    getModel,
    (model) => model.fetchTaxes
);

export const getIsFetchingItems = createSelector(
    getFetchTaxes,
    (fetchTaxes) => fetchTaxes.isFetching
);

export const getDataFetchTaxes = createSelector(
    getFetchTaxes,
    (fetchTaxes) => fetchTaxes.data || []
);

export const getSaveTaxes = createSelector(
    getModel,
    (model) => model.saveTaxes
);

export const getErrorsSaveTaxes = createSelector(
    getSaveTaxes,
    (saveTaxes) => saveTaxes.errors
);

export const getIsSavingTaxes = createSelector(
    getSaveTaxes,
    (saveTaxes) => saveTaxes.isSaving
);
