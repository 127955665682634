import { POLYGON_MAP } from '../../../../modules/multiPanelLayout/constants';
import { SEGMENT_STROKE_ACTIVE_COLOR } from '../constants';
import { addRectanglePathListeners } from '../helpers';

import CustomOverlayViewBuilder from './CustomOverlayViewBuilder';

export default (google, vertexChange) => {
    const CustomOverlayView = CustomOverlayViewBuilder(google);
    class SegmentRectangle extends google.maps.Rectangle {
        constructor({
            id,
            image = null,
            opacity = 100,
            options = {},
            rotate = 0,
            type = POLYGON_MAP,
        }) {
            const newOptions = {
                draggable: options?.draggable || false,
                editable: options?.editable || false,
                fillOpacity: 0,
                map: options.map,
                bounds: options.bounds,
                strokeOpacity: 0,
                strokePosition: google.maps.StrokePosition.OUTSIDE,
                strokeWeight: 4,
                zIndex: -2,
            };
            super(newOptions);
            this.disabled = false;
            this.id = id;
            this.type = type;
            this.opacity = opacity;
            this.rotate = rotate;
            this.image = image;
            this.initOverlay();
            if (vertexChange) {
                addRectanglePathListeners({
                    google,
                    rectangle: this,
                    vertexChange,
                    updateOverlay: () => this.updateOverlay(),
                });
            }
        }

        initOverlay() {
            if (!this.image) return;
            if (this.overlay) {
                this.overlay.setMap(null);
            }
            this.overlay = new CustomOverlayView(
                this.bounds,
                this.image,
                this.rotate,
                this.opacity
            );
            this.overlay.setMap(this.map);
        }

        setMap(map) {
            if (map === null) {
                if (this.overlay) {
                    this.overlay.setMap(null);
                    this.overlay = null;
                }
                google.maps.event.clearListeners(this, 'bounds_changed');
            }
            super.setMap(map);
        }

        updateOverlay() {
            if (this.overlay) {
                this.overlay.setBounds(this.bounds);
            }
        }

        setOverlayOptions(image, opacity, rotate) {
            if (!image) return;
            this.image = image;
            this.opacity = opacity;
            this.rotate = rotate;
            if (this.overlay) this.overlay.setMap(null);
            this.overlay = new CustomOverlayView(
                this.bounds,
                this.image,
                this.rotate,
                this.opacity
            );
            this.overlay.setMap(this.map);
        }

        setSelected(isSelected, options = {}) {
            if (isSelected) {
                this.setOptions({
                    draggable: true,
                    editable: true,
                    strokeColor: SEGMENT_STROKE_ACTIVE_COLOR,
                });
            } else {
                this.setOptions({
                    draggable: false,
                    editable: false,
                    ...options,
                });
            }
        }

        setDisabled(isDisabled, isEditable = false) {
            if (isDisabled) {
                this.setOptions({
                    clickable: false,
                    editable: false,
                });
            } else {
                this.setOptions({
                    clickable: true,
                    editable: isEditable,
                });
            }
        }

        setOpacity(opacity) {
            this.opacity = opacity;
            if (this.overlay) {
                this.overlay.setOpacity(this.opacity);
            }
        }

        setRotate(rotate) {
            this.rotate = rotate;
            if (this.overlay) {
                this.overlay.setRotate(this.rotate);
            }
        }

        setAditionalOptions(options) {
            this.setOptions({ ...options });
        }

        setSunPosition() {}

        resetPosition() {}
    }

    return SegmentRectangle;
};
