import initializeCertifiedRateModalForm from './initializeCertifiedRateModalForm';
import setCertifiedRateModalIsOpen from './setCertifiedRateModalIsOpen';

export default (rateData) => (dispatch) => {
    dispatch(
        initializeCertifiedRateModalForm({
            ...rateData,
            energy_export_price: rateData.energy_export_price || 0,
            taxes_concepts: rateData.taxes_concepts || [],
            other_concepts: rateData.other_concepts || [],
        })
    );
    dispatch(setCertifiedRateModalIsOpen(true));
};
