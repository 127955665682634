import { updateContactPartially } from 'common/api/v1/contacts';
import { showReponseErrorsAsAlert } from 'common/utils/helpers';
import showToast from 'common/utils/showToast';

import {
    SAVE_CONTACT,
    SAVE_CONTACT_FAILURE,
    SAVE_CONTACT_SUCCESS,
} from '../actionTypes';
import { contactFormActions } from '../reducer';

import resetForm from './resetForm';

export default (values, callback) => (dispatch) => {
    dispatch(contactFormActions[SAVE_CONTACT](null));

    const newValues = {
        company_name: values.company_name,
        email: values.email,
    };

    updateContactPartially(newValues, values.contactId)
        .then((response) => {
            dispatch(contactFormActions[SAVE_CONTACT_SUCCESS](response.data));
            dispatch(resetForm());
            if (callback) callback();
            showToast();
        })
        .catch((error) => {
            dispatch(
                contactFormActions[SAVE_CONTACT_FAILURE](
                    error?.response?.data?.errors
                )
            );
            showReponseErrorsAsAlert(dispatch, error?.response);
        });
};
