import i18next from 'i18next';

import { SET_ELEMENT_TO_DELETE } from '../actionTypes';
import { productDatasheetsActions } from '../reducer';

import initializeForm from './initializeForm';
import openConfirmModal from './openConfirmModal';
import prepareCloseForm from './prepareCloseForm';
import setDataConfirmModal from './setDataConfirmModal';

export default (element, values) => (dispatch) => {
    const datasheetName = element.name;

    dispatch(
        initializeForm({
            productId: values.id,
            productType: values.productType,
        })
    );
    dispatch(productDatasheetsActions[SET_ELEMENT_TO_DELETE](element));
    dispatch(
        setDataConfirmModal({
            confirmText: i18next.t('Delete'),
            message: i18next.t(
                'Are you really sure to delete the datasheet {{ datasheetName }}?',
                { datasheetName: datasheetName }
            ),
            title: i18next.t('Confirmation'),
            type: 'warning',
        })
    );
    dispatch(openConfirmModal(true));
    dispatch(prepareCloseForm(false));
};
