import { createSelector } from 'reselect';

import { NAME } from './constants';
import { getFormName } from './helpers';

export const getModel = (state) => state[NAME];
export const getOwnProps = (_, ownProps) => ownProps;

export const getInitialValues = createSelector(
    getModel,
    getOwnProps,
    (model, ownProps) => model[getFormName(ownProps.name)]?.initialValues
);

export const getModelValuesByName = createSelector(
    getModel,
    getOwnProps,
    (model, ownProps) => model[getFormName(ownProps.name)] || {}
);

/* Geocode */
export const getFetchGeocode = createSelector(
    getModelValuesByName,
    (model) => model?.fetchGeocode
);

export const getFetchGeocodeData = createSelector(
    getFetchGeocode,
    (data) => data?.data || []
);

export const showGeocodeResults = createSelector(
    getModelValuesByName,
    (model) => model?.showGeocodeResults
);

export const getGeocodePointModel = createSelector(
    getModelValuesByName,
    (model) => model?.fetchGeocodePoint
);

export const getIsFetchingGeocodePoint = createSelector(
    getGeocodePointModel,
    (model) => model?.isFetching
);

export const getGeocodePointData = createSelector(
    getGeocodePointModel,
    (model) => model?.data
);

export const getGeocodePointErrors = createSelector(
    getGeocodePointModel,
    (model) => model?.errors
);
