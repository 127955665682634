import React from 'react';

import PropTypes from 'prop-types';

import SelectionAreaComponent from 'common/components/SelectionArea';

const ConceptDistribution = ({
    colors,
    conceptName,
    field,
    getValues,
    hourlyDistribution,
    setHourlyDistribution,
    setValue,
    showMode,
    weekDay,
}) => {
    const onStart = () =>
        setHourlyDistribution((prev) => ({
            ...prev,
            optionSelected: {
                ...(prev.optionSelected || {}),
                [weekDay]: getValues(
                    `${field}.select.${conceptName}.${weekDay}`
                ),
            },
        }));

    const onMove = ({ added, removed }) => {
        if (showMode || (added.length === 0 && removed.length === 0)) return;

        setHourlyDistribution((prev) => {
            let newValues = { ...prev };

            added.forEach(({ key }) => {
                const elementAdded = newValues[weekDay][key];
                newValues[weekDay][key] = {
                    value: newValues.optionSelected[weekDay] + 1 || 1,
                    color: colors[newValues.optionSelected[weekDay]],
                    oldState: elementAdded,
                };
            });

            removed.forEach(({ key }) => {
                const elementRemoved = newValues[weekDay][key];
                newValues[weekDay][key] = {
                    value: elementRemoved.oldState.value || 1,
                    color: elementRemoved.oldState.color,
                };
            });

            return newValues;
        });
    };

    const onStop = () =>
        setHourlyDistribution((prev) => {
            setValue(`${field}.${conceptName}_concepts`, prev, {
                shouldValidate: true,
            });
            return prev;
        });

    return (
        <SelectionAreaComponent
            columns={24}
            isSelectable={!showMode}
            onMove={onMove}
            onStart={onStart}
            onStop={onStop}
            selected={hourlyDistribution}
        />
    );
};

ConceptDistribution.propTypes = {
    colors: PropTypes.array,
    conceptName: PropTypes.string,
    field: PropTypes.string,
    getValues: PropTypes.func,
    hourlyDistribution: PropTypes.array,
    setHourlyDistribution: PropTypes.func,
    setValue: PropTypes.func,
    showMode: PropTypes.bool,
    weekDay: PropTypes.string,
};

export default ConceptDistribution;
