import { updateItem } from 'common/api/v2/offerPanel';
import { showReponseErrorsAsAlert } from 'common/utils/helpers';

import eventsAfterUpdate from '../../proposalGeneratorOneColumn/actions/eventsAfterUpdate';
import { SAVE, SAVE_SUCCESS, SAVE_FAILURE } from '../actionTypes';
import { proposalGeneratorPanelTableActions } from '../reducer';

export default (proposalId, values) => (dispatch) => {
    if (!values.id || values.expansion === '') return;

    dispatch(proposalGeneratorPanelTableActions[SAVE]());

    updateItem(values, proposalId)
        .then((response) => {
            dispatch(
                proposalGeneratorPanelTableActions[SAVE_SUCCESS](
                    response.data.data
                )
            );
            dispatch(eventsAfterUpdate(response.data.data, proposalId));
        })
        .catch((error) => {
            dispatch(
                proposalGeneratorPanelTableActions[SAVE_FAILURE](
                    error?.response?.data?.errors
                )
            );
            showReponseErrorsAsAlert(dispatch, error?.response);
        });
};
