import React from 'react';

import get from 'lodash/get';
import PropTypes from 'prop-types';
import { Box } from 'sunwise-ui';

import SidebarIndicators from 'common/modules/proposalIndicators';

import proposalGeneratorTemplate from '../../proposalGeneratorTemplate';

import StyledGrid from './StyledGrid';
import SupportControlButtons from './SupportControlButtons';

const SupportMenuGrid = ({
    batteriesOfferValues,
    canModify,
    compensationScheme,
    currencyIso,
    currencyLocale,
    customerMode,
    financialProductParameters,
    handleClickOpenConfigurationModal,
    hasSmartDocuments,
    isApproved,
    isClosed,
    isFetchingOfferDetails,
    isGenerated,
    maxDailyConsumption,
    nextRateData,
    offerDetails,
    offerDetailsComplements,
    offerDetailsConsumption,
    offerId,
    proposalConfiguration,
    setCustomerMode,
    totalsSolarSimulation,
}) => {
    return (
        <StyledGrid
            item
            md={6}
            lg={4}
            sx={{ display: { md: 'block', xs: 'none' } }}
        >
            <Box p={1}>
                <SupportControlButtons
                    canModify={canModify}
                    customerMode={customerMode}
                    financialProductParameters={financialProductParameters}
                    handleClickOpenConfigurationModal={
                        handleClickOpenConfigurationModal
                    }
                    hasSmartDocuments={hasSmartDocuments}
                    isApproved={isApproved}
                    isClosed={isClosed}
                    isFetchingOfferDetails={isFetchingOfferDetails}
                    isGenerated={isGenerated}
                    offerDetails={offerDetails}
                    offerId={offerId}
                    setCustomerMode={setCustomerMode}
                />

                <proposalGeneratorTemplate.Container
                    financialProductParameters={financialProductParameters}
                    isLoading={isFetchingOfferDetails}
                    isGenerated={isGenerated}
                    offerDetails={offerDetails}
                    offerPlanId={get(
                        offerDetailsComplements,
                        'proposal_plan',
                        null
                    )}
                    offerTemplateId={get(
                        offerDetailsComplements,
                        'custom_offer_template',
                        null
                    )}
                    proposalId={offerId}
                />

                <SidebarIndicators.Container
                    batteriesOfferValues={batteriesOfferValues}
                    compensationScheme={compensationScheme}
                    currencyIso={currencyIso}
                    currencyLocale={currencyLocale}
                    isLoading={isFetchingOfferDetails}
                    maxDailyConsumption={maxDailyConsumption}
                    nextRateData={nextRateData}
                    offerDetailsComplements={offerDetailsComplements}
                    offerDetailsConsumption={offerDetailsConsumption}
                    proposalConfiguration={proposalConfiguration}
                    proposalId={offerId}
                    totalsSolarSimulation={totalsSolarSimulation}
                />
            </Box>
        </StyledGrid>
    );
};

SupportMenuGrid.propTypes = {
    batteriesOfferValues: PropTypes.object,
    canModify: PropTypes.bool,
    compensationScheme: PropTypes.object,
    currencyIso: PropTypes.string,
    currencyLocale: PropTypes.string,
    customerMode: PropTypes.bool,
    financialProductParameters: PropTypes.object,
    handleClickOpenConfigurationModal: PropTypes.func,
    hasSmartDocuments: PropTypes.bool,
    isApproved: PropTypes.bool,
    isClosed: PropTypes.bool,
    isFetchingOfferDetails: PropTypes.bool,
    isGenerated: PropTypes.bool,
    maxDailyConsumption: PropTypes.number,
    nextRateData: PropTypes.object,
    offerDetails: PropTypes.object,
    offerDetailsComplements: PropTypes.object,
    offerDetailsConsumption: PropTypes.object,
    offerId: PropTypes.string,
    proposalConfiguration: PropTypes.object,
    setCustomerMode: PropTypes.func,
    totalsSolarSimulation: PropTypes.object,
};

export default SupportMenuGrid;
