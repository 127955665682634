import i18next from 'i18next';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

import { updateSegment } from 'common/api/v1/segmentsLayout';
import {
    SEGMENT_COLOR,
    SEGMENT_STROKE_COLOR,
} from 'common/components/maps/constants';
import {
    getNewSolarModules,
    getPolygonCoordinates,
    showReponseErrorsAsAlert,
} from 'common/utils/helpers';
import showToast from 'common/utils/showToast';

import {
    INITIALIZE_VALUES,
    UPDATE_SEGMENT,
    UPDATE_SEGMENT_FAILURE,
    UPDATE_SEGMENT_SUCCESS,
    UPDATE_SOLAR_MODULES_DESIGN_NUMBER,
} from '../actionTypes';
import { POLYGON_SEGMENT, POLYGON_MAP, POLYGON_OBSTACLE } from '../constants';
import {
    checkCallback,
    checkCallbackError,
    getDictionaryById,
    getModulesGroups,
    getNewModules,
    getObstaceZIndex,
    getQuantityEnabledModules,
    getSolarCharacterization,
} from '../helpers';
import { panelLayoutV2Actions } from '../reducer';
import * as selectors from '../selectors';

import fetchOfferPanel from './fetchOfferPanel';
import handleCollition from './handleCollition';
import setInitialValues from './setInitialValues';
import updateSegments from './updateSegments';

const buildSegmentPayload = ({ config, getState }) => {
    const { commercialOfferId, google, mapValue, values } = config;
    const state = getState();
    const segments = selectors.getSegmentsData(state);
    const segmentsDictionary = getDictionaryById(segments);
    const {
        azimuth,
        col_spacing,
        frame_col_spacing,
        frame_cols_number,
        frame_row_spacing,
        frame_rows_number,
        height,
        id,
        name,
        orientation,
        polygon,
        row_spacing,
        safe_zone,
        solar_module,
        solar_modules_number,
        tilt,
    } = values;
    const selectedSegment = segmentsDictionary[id];

    let defaultOfferPanelValues = {
        color: SEGMENT_COLOR,
        id: null,
        stroke_color: SEGMENT_STROKE_COLOR,
    };
    let module = null;
    let quantity = solar_modules_number;
    let assigned = 0;
    let solar_modules = [];
    let modules_groups = null;
    let grouped_cells = null;

    const offerPanels = selectors.getOfferPanelsData(state);
    const offerPanelFiltered = offerPanels.find(
        (item) => item.id === solar_module
    );

    if (offerPanelFiltered) {
        module = offerPanelFiltered;
        defaultOfferPanelValues = {
            ...defaultOfferPanelValues,
            ...module,
        };
    }

    const { color, stroke_color } = defaultOfferPanelValues;

    const solarCharacterization = {
        azimuth,
        col_spacing,
        commercial_offer: commercialOfferId,
        frame_col_spacing,
        frame_cols_number,
        frame_horizontal_number: frame_cols_number,
        frame_row_spacing,
        frame_rows_number,
        frame_vertical_number: frame_rows_number,
        height: height || 0,
        orientation,
        row_spacing,
        safe_zone: parseFloat(safe_zone) || 0,
        solar_module,
        tilt,
    };

    if (quantity > 0) {
        const obstacles = segments.filter(
            (segment) => segment.type === POLYGON_OBSTACLE
        );

        const { boxes, grouped } = getNewModules({
            data: {
                ...solarCharacterization,
                assigned,
                polygon: JSON.stringify(polygon),
                quantity,
            },
            google,
            map: mapValue,
            module,
            obstacles,
        });

        grouped_cells = grouped;
        modules_groups = getModulesGroups(grouped);

        solar_modules = boxes.map((module) => ({
            cell: module.cell,
            col: module.col,
            group: module.group,
            is_enable: module.enable,
            row: module.row,
            solar_module_points: JSON.stringify(
                module.paths.map((point) => ({
                    x: point[1],
                    y: point[0],
                }))
            ),
        }));
    }

    const field_segments = [
        {
            name,
            polygon: polygon.map((point) => ({
                x_coordinate: point[1],
                y_coordinate: point[0],
            })),
            solar_modules,
        },
    ];

    const payload = {
        ...selectedSegment,
        ...values,
        commercial_offer: commercialOfferId,
        field_segments,
        grouped_cells,
        modules_groups,
        segment_color: color,
        segment_stroke_color: stroke_color,
    };

    return payload;
};

const buildObstaclePayload = ({ config, getState }) => {
    const state = getState();
    const segments = selectors.getSegmentsData(state);
    const segmentsDictionary = getDictionaryById(segments);
    const { commercialOfferId, google, values } = config;

    const { id, name, polygon } = values;
    const selectedSegment = segmentsDictionary[id];

    const zIndex = getObstaceZIndex({
        google,
        polygon,
        segments,
        selectedSegment,
    });

    const field_segments = [
        {
            name,
            polygon: polygon.map((point) => ({
                x_coordinate: point[1],
                y_coordinate: point[0],
            })),
        },
    ];

    const payload = {
        ...selectedSegment,
        ...values,
        commercial_offer: commercialOfferId,
        field_segments,
        segment_color: '#8c8c8c',
        segment_stroke_color: '#11EFD9',
        zindex: zIndex,
    };

    return payload;
};

const buildImagePayload = ({ config, getState }) => {
    const state = getState();
    const segments = selectors.getSegmentsData(state);
    const segmentsDictionary = getDictionaryById(segments);
    const { commercialOfferId, values } = config;
    const { id, name, polygon, rotate } = values;
    const selectedSegment = segmentsDictionary[id];

    const field_segments = [
        {
            name,
            polygon: polygon.map((point) => ({
                x_coordinate: point[1],
                y_coordinate: point[0],
            })),
            solar_modules: [],
        },
    ];

    const payload = {
        ...selectedSegment,
        ...values,
        commercial_offer: commercialOfferId,
        field_segments,
        id,
        rotate: rotate ? parseFloat(rotate) : 0,
        type: POLYGON_MAP,
    };

    return payload;
};

const handleSegmentResponse = ({
    config,
    dispatch,
    payload,
    segmentData,
    segments,
    state,
}) => {
    const { callback, commercialOfferId } = config;
    const { field_segment_points, panel_modules } = segmentData;
    const newPolygon = getPolygonCoordinates(field_segment_points);
    const solarModules = getNewSolarModules(panel_modules);
    const quantityEnabledModules = getQuantityEnabledModules(solarModules);
    const updatedSegment = {
        ...payload,
        polygon: newPolygon,
        quantity_enabled_modules: quantityEnabledModules,
        solar_modules: solarModules,
        solar_modules_number: solarModules.length,
    };
    dispatch(panelLayoutV2Actions[UPDATE_SEGMENT_SUCCESS](updatedSegment));

    const newValuesSegments = segments.map((segment) =>
        payload.id === segment.id
            ? { ...segment, ...updatedSegment, open: true }
            : { ...segment, open: false }
    );
    dispatch(updateSegments(newValuesSegments));

    const newSolarModulesDesignNumber = newValuesSegments.reduce(
        (acc, current) => acc + current.solar_modules_number,
        0
    );
    dispatch(
        panelLayoutV2Actions[UPDATE_SOLAR_MODULES_DESIGN_NUMBER](
            newSolarModulesDesignNumber
        )
    );

    dispatch(fetchOfferPanel(commercialOfferId));

    const initialValues = selectors.getInitialValues(state);

    if (!isEmpty(initialValues)) {
        dispatch(panelLayoutV2Actions[INITIALIZE_VALUES](updatedSegment));
    }

    if (callback) callback();
};

const handleObstacleResponse = ({
    config,
    dispatch,
    payload,
    segmentData,
    segments,
}) => {
    const { callback, commercialOfferId, google, mapRef, mapValue } = config;
    const { field_segment_points, id: segmentId } = segmentData;
    const solarCharacterization = getSolarCharacterization(segmentData);
    const newPolygon = getPolygonCoordinates(field_segment_points);
    const updatedSegment = {
        ...payload,
        polygon: newPolygon,
        zIndex: payload.zindex,
        ...solarCharacterization,
    };

    dispatch(panelLayoutV2Actions[UPDATE_SEGMENT_SUCCESS](updatedSegment));

    const newValuesSegments = segments.map((segment) =>
        payload.id === segment.id
            ? { ...segment, ...updatedSegment, open: true }
            : { ...segment, open: false }
    );

    dispatch(updateSegments(newValuesSegments));

    dispatch(setInitialValues(updatedSegment));

    if (callback) callback(segmentId, null);

    dispatch(
        handleCollition({
            callback: (id, modules) => checkCallback(id, modules, mapRef),
            callbackError: (id, values) =>
                checkCallbackError(id, values, mapRef),
            commercialOfferId,
            google,
            mapValue,
            obstacle: updatedSegment,
        })
    );
};

const handleImageResponse = ({
    config,
    dispatch,
    payload,
    segmentData,
    segments,
}) => {
    const { callback } = config;
    const { field_segment_points } = segmentData;
    const newPolygon = getPolygonCoordinates(field_segment_points);
    const updatedSegment = {
        ...payload,
        polygon: newPolygon,
    };
    dispatch(panelLayoutV2Actions[UPDATE_SEGMENT_SUCCESS](updatedSegment));

    const newValuesSegments = segments.map((segment) =>
        payload.id === segment.id
            ? { ...segment, ...updatedSegment, open: true }
            : { ...segment, open: false }
    );
    dispatch(updateSegments(newValuesSegments));

    dispatch(setInitialValues(updatedSegment));

    if (callback) callback();
};

export default (config) => (dispatch, getState) => {
    const { values } = config;
    const { id: segmentId, type } = values;

    const state = getState();
    const segments = selectors.getSegmentsData(state);

    let payload = null;

    const buildProps = { config, getState };

    if (type === POLYGON_SEGMENT) {
        payload = buildSegmentPayload(buildProps);
    }

    if (type === POLYGON_OBSTACLE) {
        payload = buildObstaclePayload(buildProps);
    }

    if (type === POLYGON_MAP) {
        payload = buildImagePayload(buildProps);
    }

    dispatch(panelLayoutV2Actions[UPDATE_SEGMENT]());

    updateSegment(segmentId, payload)
        .then((response) => {
            const segmentData = get(response, 'data.data', {});
            const handlerProps = {
                config,
                dispatch,
                payload,
                segmentData,
                segments,
            };

            if (type === POLYGON_SEGMENT) {
                handleSegmentResponse({ ...handlerProps, state });
            }

            if (type === POLYGON_OBSTACLE) {
                handleObstacleResponse(handlerProps);
            }

            if (type === POLYGON_MAP) {
                handleImageResponse(handlerProps);
            }

            showToast({
                body: i18next.t('Segment changes have been saved'),
                position: 'bottom-center',
            });
        })
        .catch((error) => {
            dispatch(
                panelLayoutV2Actions[UPDATE_SEGMENT_FAILURE](
                    error?.response?.data?.errors
                )
            );
            showReponseErrorsAsAlert(dispatch, error.response);
        });
};
