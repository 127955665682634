import i18next from 'i18next';

import { deleteParentFieldSegments } from 'common/api/v1/parentFieldSegment';
import { showReponseErrorsAsAlert } from 'common/utils/helpers';
import showToast from 'common/utils/showToast';

import eventsAfterUpdate from '../../proposalGeneratorOneColumn/actions/eventsAfterUpdate';
import {
    DELETE_PARENT_FIELD_SEGMENTS,
    DELETE_PARENT_FIELD_SEGMENTS_FAILURE,
    DELETE_PARENT_FIELD_SEGMENTS_SUCCESS,
} from '../actionTypes';
import { proposalGeneratorPanelsLayoutActions } from '../reducer';

export default (proposalId) => (dispatch) => {
    dispatch(
        proposalGeneratorPanelsLayoutActions[DELETE_PARENT_FIELD_SEGMENTS]()
    );

    deleteParentFieldSegments(proposalId)
        .then((response) => {
            dispatch(
                proposalGeneratorPanelsLayoutActions[
                    DELETE_PARENT_FIELD_SEGMENTS_SUCCESS
                ]({
                    solarModulesNumber: 0,
                    segments: [],
                    haveParentFieldSegment: false,
                })
            );
            showToast({
                type: 'success',
                body: i18next.t('Successfully deleted'),
            });
            dispatch(eventsAfterUpdate(response.data.data, proposalId));
        })
        .catch((error) => {
            dispatch(
                proposalGeneratorPanelsLayoutActions[
                    DELETE_PARENT_FIELD_SEGMENTS_FAILURE
                ](error?.response?.data?.errors)
            );
            showReponseErrorsAsAlert(dispatch, error?.response);
        });
};
