import React from 'react';

import EditIcon from '@mui/icons-material/Edit';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box, Card, IconButton, Typography } from 'sunwise-ui';

import PlaceholderCardLocation from 'common/components/placeholder/PlaceholderCardLocation';
import { PERMISSION_LIST } from 'common/constants/permissionsV2';
import withPermissions from 'common/hocs/withPermissions';
import locationForm from 'common/modules/locationForm';

const CardLocation = ({
    canModify,
    canView,
    companyPositionLatLng,
    contactPosition,
    editModeLocation,
    isLoadingContact,
    isLoadingContactPosition,
    loadingContext,
    match,
    savePosition,
    setEditModeLocation,
}) => {
    const { t } = useTranslation();

    if (!canView) return null;

    return (
        <PlaceholderCardLocation
            ready={isLoadingContact || isLoadingContactPosition}
        >
            <Box mb={2}>
                <Card>
                    <Card.Header
                        sx={{
                            alignItems: 'center',
                            display: 'flex',
                            justifyContent: 'space-between',
                        }}
                    >
                        <Typography variant="body1" fontWeight="bold">
                            {t('Location')}
                        </Typography>

                        {get(contactPosition, 'position', null) !== null &&
                            !editModeLocation &&
                            canModify && (
                                <IconButton
                                    onClick={() => setEditModeLocation(true)}
                                >
                                    <EditIcon fontSize="small" />
                                </IconButton>
                            )}
                    </Card.Header>

                    <Card.Body>
                        <locationForm.Container
                            canModify={canModify}
                            center={companyPositionLatLng}
                            editMode={editModeLocation}
                            handleSubmit={(values) => {
                                loadingContext.openLoading(
                                    t('Saving').concat('...')
                                );
                                savePosition(
                                    values,
                                    match.params.uid,
                                    () => {
                                        setEditModeLocation(false);
                                        loadingContext.closeLoading();
                                    },
                                    () => {
                                        loadingContext.closeLoading();
                                    }
                                );
                            }}
                            initPosition={contactPosition}
                            name="location-user"
                            setEditMode={setEditModeLocation}
                        />
                    </Card.Body>
                </Card>
            </Box>
        </PlaceholderCardLocation>
    );
};

CardLocation.propTypes = {
    canModify: PropTypes.bool,
    canView: PropTypes.bool,
    companyPositionLatLng: PropTypes.object,
    contactPosition: PropTypes.object,
    editModeLocation: PropTypes.bool,
    isLoadingContact: PropTypes.bool,
    isLoadingContactPosition: PropTypes.bool,
    loadingContext: PropTypes.object,
    match: PropTypes.object,
    savePosition: PropTypes.func,
    setEditModeLocation: PropTypes.func,
};

export default withPermissions(PERMISSION_LIST.CONTACT_LOCATION_PERMISSION)(
    CardLocation
);
