import React, { useCallback, useContext, useEffect, useRef } from 'react';

import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { createStructuredSelector } from 'reselect';
import {
    PageToolbar,
    TemplateView,
    withTemplateCore,
    editionLevels,
} from 'sunwise-template-core';
import { Box, Grid } from 'sunwise-ui';

import SwipeableDrawer from 'common/components/SwipeableDrawer';
import { LoadingContext } from 'common/utils/contexts';
import { getCurrentLanguage } from 'common/utils/helpers/multiregion';
import { getToken } from 'common/utils/helpers/session';
import { scroll } from 'common/utils/mixins';

import * as actions from './actions';
import HeaderPreview from './components/HeaderPreview';
import SideMenu from './components/SideMenu';
import StyledSideBarColumn from './components/StyledSideBarColumn';
import {
    getIsLoadingContextMessage,
    getIsUpdatingContextMessage,
} from './helpers';
import * as selectors from './selectors';

const PageWrapper = styled(Box)(
    ({ theme }) => `
    background:  ${
        theme.palette.mode === 'dark' ? '#000' : 'rgb(248, 252, 255)'
    };
    height: calc(100% - 100px);
    overflow-y: scroll;
    padding-top: 24px;
    position: relative;
    width: 100%;

    ${scroll.custom()}

    .apexcharts-theme-light {
        color: black;
    }
`
);

const Container = ({
    breadcrumbLocations,
    canModify,
    currentBreadcrumb,
    editionDisabled,
    fetchCustomTemplate,
    fetchPdfFile,
    getTemplateUpdatedData,
    initTemplate,
    isFetchingCustomTemplate,
    isUpdatingCustomTemplate,
    match,
    reset,
    restorePages,
    setEditionDisabled,
    setTemplateBackup,
    templateName,
    templateType,
    updateTemplate,
}) => {
    const loadingContext = useContext(LoadingContext);
    const sideBarRef = useRef(null);
    const swipeableDrawerRef = useRef(null);
    const offerId = match.params.uid;

    useEffect(() => {
        getIsLoadingContextMessage(isFetchingCustomTemplate, loadingContext);
    }, [isFetchingCustomTemplate]);

    useEffect(() => () => reset(), []);

    useEffect(() => {
        if (offerId) {
            fetchCustomTemplate(offerId, initTemplate, templateType);
        }
    }, [offerId]);

    useEffect(() => {
        getIsUpdatingContextMessage(
            editionDisabled,
            isUpdatingCustomTemplate,
            loadingContext,
            setEditionDisabled
        );
    }, [isUpdatingCustomTemplate]);

    const handleUpdateTemplate = useCallback(
        () =>
            updateTemplate(
                offerId,
                templateType,
                getTemplateUpdatedData,
                setTemplateBackup
            ),
        [offerId, templateType, getTemplateUpdatedData, setTemplateBackup]
    );

    return (
        <>
            <Grid container spacing={0}>
                <Grid
                    item
                    xs={18}
                    md={12}
                    lg={13}
                    sx={{
                        height: {
                            lg: 'calc(100vh - 64px)',
                            xs: 'calc(100vh - 105px)',
                        },
                    }}
                >
                    <HeaderPreview
                        breadcrumbLocations={breadcrumbLocations}
                        currentBreadcrumb={currentBreadcrumb}
                        editionDisabled={editionDisabled}
                    />
                    <PageToolbar
                        editionLevel={editionLevels.PARTIAL_EDITION_MODE}
                        reOrderDisabled
                        sxToolbar={{
                            pl: '76px',
                            ...(editionDisabled ? { minHeight: 0 } : {}),
                        }}
                        visible={!editionDisabled}
                    />
                    <PageWrapper
                        sx={{ pb: { xs: '50px', lg: 0 } }}
                        toolbar={editionDisabled ? 'false' : 'true'}
                    >
                        <TemplateView
                            editionLevel={
                                editionDisabled
                                    ? editionLevels.NO_EDITION_MODE
                                    : editionLevels.PARTIAL_EDITION_MODE
                            }
                        />
                    </PageWrapper>
                </Grid>
                <StyledSideBarColumn
                    item
                    md={6}
                    lg={5}
                    ref={sideBarRef}
                    sx={{
                        display: { xs: 'none', md: 'block' },
                        height: {
                            md: 'calc(100vh - 64px)',
                            xs: 'calc(100vh - 105px)',
                        },
                    }}
                >
                    <SideMenu
                        canModify={canModify}
                        cancelUpdating={restorePages}
                        editionDisabled={editionDisabled}
                        fetchPdfFile={fetchPdfFile}
                        initializeUpdating={setTemplateBackup}
                        isUpdatingCustomTemplate={isUpdatingCustomTemplate}
                        match={match}
                        setEditionDisabled={setEditionDisabled}
                        swipeableDrawerRef={swipeableDrawerRef}
                        templateName={templateName}
                        templateType={templateType}
                        updateTemplate={handleUpdateTemplate}
                    />
                </StyledSideBarColumn>
            </Grid>
            <SwipeableDrawer ref={swipeableDrawerRef}>
                <SideMenu
                    canModify={canModify}
                    cancelUpdating={restorePages}
                    editionDisabled={editionDisabled}
                    fetchPdfFile={fetchPdfFile}
                    initializeUpdating={setTemplateBackup}
                    isUpdatingCustomTemplate={isUpdatingCustomTemplate}
                    match={match}
                    setEditionDisabled={setEditionDisabled}
                    swipeableDrawerRef={swipeableDrawerRef}
                    templateName={templateName}
                    templateType={templateType}
                    updateTemplate={handleUpdateTemplate}
                />
            </SwipeableDrawer>
        </>
    );
};

const mapStateToProps = createStructuredSelector({
    isFetchingCustomTemplate: selectors.getIsFetchingCustomTemplate,
    isUpdatingCustomTemplate: selectors.getIsUpdatingCustomTemplate,
});

const mapDispatchToProps = (dispatch) => ({
    fetchCustomTemplate: (id, initTemplate, templateType) =>
        dispatch(actions.fetchCustomTemplate(id, initTemplate, templateType)),
    fetchPdfFile: (
        id,
        templateName,
        downloadFormat,
        openLoading,
        closeLoading,
        templateType
    ) =>
        dispatch(
            actions.fetchPdfFile(
                id,
                templateName,
                downloadFormat,
                openLoading,
                closeLoading,
                templateType
            )
        ),
    reset: () => dispatch(actions.reset()),
    updateTemplate: (
        reviewId,
        templateType,
        getTemplateUpdatedData,
        setTemplateBackup
    ) =>
        dispatch(
            actions.updateTemplate(
                reviewId,
                templateType,
                getTemplateUpdatedData,
                setTemplateBackup
            )
        ),
});

Container.propTypes = {
    breadcrumbLocations: PropTypes.array,
    canModify: PropTypes.bool,
    currentBreadcrumb: PropTypes.string,
    editionDisabled: PropTypes.bool,
    fetchCustomTemplate: PropTypes.func,
    fetchPdfFile: PropTypes.func,
    getTemplateUpdatedData: PropTypes.func,
    initTemplate: PropTypes.func,
    isFetchingCustomTemplate: PropTypes.bool,
    isUpdatingCustomTemplate: PropTypes.bool,
    match: PropTypes.object,
    reset: PropTypes.func,
    restorePages: PropTypes.func,
    setEditionDisabled: PropTypes.func,
    setTemplateBackup: PropTypes.func,
    templateName: PropTypes.string,
    templateType: PropTypes.number,
    updateTemplate: PropTypes.func,
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withRouter,
    withTemplateCore(() => ({
        application: 'sunwise',
        baseUrl: process.env.REACT_APP_API_URL,
        editionMode: editionLevels.PARTIAL_EDITION_MODE,
        froalaLicenseKey: process.env.REACT_APP_FROALA_LICENSE,
        googleApiKey: process.env.REACT_APP_GOOGLE_MAPS_KEY,
        language: getCurrentLanguage(),
        token: getToken(),
    }))
)(Container);
