import { createSelector } from 'reselect';

import { NAME } from './constants';

export const getModel = (state) => state[NAME];

export const getSaveModel = createSelector(getModel, (model) => model.save);

export const getIsSaving = createSelector(
    getSaveModel,
    (save) => save.isSaving
);

export const getErrorsSave = createSelector(
    getSaveModel,
    (save) => save.errors
);
