import React from 'react';

import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button, Grid } from 'sunwise-ui';

import EmptyState from 'common/components/EmptyState';
import { parseJSON } from 'common/utils/helpers';

import EmptyStorageSystem from 'resources/EmptyStorageSystem.png';

import KeyValues from './KeyValues';
import WarningComponent from './WarningComponent';

const CardForm = ({
    canModify,
    currencyLocale,
    deleteStorageOffer,
    handleCreate,
    isLocked,
    offerEnergyBackup,
    offerValueData,
    proposalId,
    redirect,
    warnings,
}) => {
    const { t } = useTranslation();

    const handleOnClickEdit = () => {
        localStorage.setItem('openedProposalModule', 'energyBackup');
        redirect(`/energy-backup-setup/${proposalId}`);
    };

    const hasValues = !isEmpty(offerValueData?.storage_offer?.storage_plan);
    const hasMoraThanOneBattery =
        Array.isArray(offerEnergyBackup) && offerEnergyBackup.length > 1;

    const isDisabled = !canModify || hasMoraThanOneBattery || isLocked;

    const renderContent = () => {
        if (hasValues) {
            return (
                <>
                    <KeyValues
                        backupPercentage={
                            offerValueData?.storage_offer?.storage_percent
                        }
                        currencyLocale={currencyLocale}
                        storageOffer={offerValueData?.storage_offer}
                        storagePlan={
                            offerValueData?.storage_offer?.storage_plan
                        }
                    />

                    <Grid container mt={1}>
                        <Grid
                            item
                            xs
                            sx={{
                                display: 'flex',
                                flexDirection: { xs: 'column', md: 'row' },
                                justifyContent: { xs: 'center', md: 'right' },
                            }}
                        >
                            <Button
                                color="secondary"
                                disabled={isDisabled}
                                onClick={() => deleteStorageOffer(proposalId)}
                                sx={{
                                    width: { xs: '100%', md: 'auto' },
                                    order: { xs: 2, md: 1 },
                                    mr: { xs: 0, md: 2 },
                                }}
                                variant="text"
                            >
                                {t('Delete')}
                            </Button>
                            <Button
                                disabled={isDisabled}
                                onClick={handleOnClickEdit}
                                sx={{
                                    width: { xs: '100%', md: 'auto' },
                                    order: { xs: 1, md: 2 },
                                    mb: { xs: 2, md: 0 },
                                }}
                                variant="outlined"
                            >
                                {t('Edit')}
                            </Button>
                        </Grid>
                    </Grid>
                </>
            );
        }

        return (
            <EmptyState
                canModify={canModify}
                caption={t(
                    'Design a backup plan, and if you have the additional module, enhance your efficiency with Peak Shaving and Time Shifting'
                )}
                onClick={() => handleCreate(proposalId)}
                srcImage={EmptyStorageSystem}
                sxCard={{ boxShadow: 'none' }}
                textButton={t('Create')}
                title={t('Expand your energy management capabilities!')}
            />
        );
    };

    const renderWarnings = () => {
        let warningsArray = [];

        warnings.forEach((warning) => {
            const jsonWarning = parseJSON(warning);

            warningsArray.push({
                title: t('Alert'),
                body: jsonWarning?.msg?.replace(/&#8217;/g, "'"),
                variant: 'warning',
            });
        });

        if (hasMoraThanOneBattery) {
            warningsArray.push({
                title: t(
                    'Oops! Power backup configurator is only supported when having 1 storage model'
                ),
                body: t(
                    'Review your quote and make sure you only have 1 storage model in order to create a power backup configuration'
                ),
                variant: 'info',
            });
        }

        return warningsArray.map((warning, i) => (
            <WarningComponent
                body={warning.body}
                key={`warning-offer-${i}`}
                title={warning.title}
                variant={warning.variant}
            />
        ));
    };

    return (
        <>
            {renderWarnings()}
            {renderContent()}
        </>
    );
};

CardForm.propTypes = {
    canModify: PropTypes.bool,
    currencyLocale: PropTypes.string,
    deleteStorageOffer: PropTypes.func,
    handleCreate: PropTypes.func,
    isLocked: PropTypes.bool,
    offerEnergyBackup: PropTypes.array,
    offerValueData: PropTypes.object,
    proposalId: PropTypes.string,
    redirect: PropTypes.func,
    warnings: PropTypes.array,
};

export default CardForm;
