import React from 'react';

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Alert, Box, Grid, Typography } from 'sunwise-ui';

import DropdownCardMenu from 'common/components/cards/DropdownCardMenu';
import KeyIndicator from 'common/components/KeyIndicator';
import { BRIGHT_UID, SERFIMEX_UID } from 'common/constants';
import { numberFormat } from 'common/utils/helpers';

import AllianceItem from './AllianceItem';

const AllianceReviewCIBanco = ({
    alliance,
    currencyIso,
    currencyLocale,
    financialProduct,
    handleClickAlliance,
    handleClickDeleteAlliance,
    hasFinancingRequest,
    isGeneratedProposal,
    isLocked,
    proposalId,
}) => {
    const { t } = useTranslation();
    const {
        first_payment,
        id,
        monthly_payment = 0,
        total_cost_financing = 0,
    } = financialProduct;
    const parameters = get(financialProduct, 'parameters', {});
    const financierId = get(alliance, 'financier.id', null);
    const financierProduct = get(financialProduct, 'financier_product', {});
    const isSerfimex = financierId === SERFIMEX_UID;
    const isBright = financierId === BRIGHT_UID;

    return (
        <Grid container justifyContent="center" alignItems="center">
            <Grid
                xs={18}
                hidden={isGeneratedProposal || !hasFinancingRequest}
                item
            >
                <Alert severity="warning">
                    <Typography fontWeight="bold" variant="body2">
                        {t(
                            'This proposal already has a financial history in progress. Please generate the proposal again to continue with the follow-up'
                        )}
                    </Typography>
                </Alert>
            </Grid>
            <Grid item xs={9} md={4} lg={3} mt={2}>
                <AllianceItem alliance={alliance} disabled />
            </Grid>
            <Grid item xs={18} lg={15}>
                <Box display="flex" mb={2}>
                    <Typography fontWeight="bold" variant="body2">
                        {get(financierProduct, 'name', '---')}
                    </Typography>
                    <DropdownCardMenu
                        items={[
                            {
                                handleClick: () =>
                                    handleClickAlliance(alliance, true),
                                icon: <EditIcon fontSize="small" />,
                                label: t('Edit'),
                            },
                            {
                                handleClick: () =>
                                    handleClickDeleteAlliance(id, proposalId),
                                icon: <DeleteIcon fontSize="small" />,
                                label: t('Delete'),
                                visible: !hasFinancingRequest,
                            },
                        ]}
                        sxIconButton={{ ml: 'auto' }}
                        visible={!isLocked}
                    />
                </Box>
                <Grid container>
                    <Grid item xs={18} md={9} lg={6}>
                        <KeyIndicator
                            title={t('Interest rate')}
                            value={
                                isSerfimex
                                    ? parameters.rate
                                    : numberFormat(parameters.rate, {
                                          decimals: 2,
                                          locale: currencyLocale,
                                          style: 'decimal',
                                          unit: '%',
                                      })
                            }
                            widthIcon="20px"
                        />
                    </Grid>
                    <Grid item xs={18} md={9} lg={6}>
                        <KeyIndicator
                            title={t('Opening commission')}
                            value={numberFormat(parameters.commission_credit, {
                                decimals: 2,
                                locale: currencyLocale,
                                style: 'decimal',
                                unit: '%',
                            })}
                            widthIcon="20px"
                        />
                    </Grid>
                    <Grid item xs={18} md={9} lg={6}>
                        <KeyIndicator
                            title={t('Term')}
                            value={`${parameters.term} ${t('Month', {
                                count: 2,
                            })}`}
                            widthIcon="20px"
                        />
                    </Grid>
                    <Grid item xs={18} md={9} lg={6}>
                        <KeyIndicator
                            title={t('Down payment')}
                            value={numberFormat(parameters.hitch_percentage, {
                                decimals: 2,
                                locale: currencyLocale,
                                style: 'decimal',
                                unit: '%',
                            })}
                            widthIcon="20px"
                        />
                    </Grid>
                    {parameters.residual > 0 && (
                        <Grid item xs={18} md={9} lg={6}>
                            <KeyIndicator
                                title={t('Residual')}
                                value={numberFormat(parameters.residual, {
                                    decimals: 2,
                                    locale: currencyLocale,
                                    style: 'decimal',
                                    unit: '%',
                                })}
                                widthIcon="20px"
                            />
                        </Grid>
                    )}
                    <Grid item xs={18} md={9} lg={6}>
                        {isBright ? (
                            <KeyIndicator
                                title={t("First month's payment")}
                                value={numberFormat(first_payment, {
                                    currency: currencyIso,
                                    decimals: 2,
                                    locale: currencyLocale,
                                    style: 'currency',
                                })}
                                widthIcon="20px"
                            />
                        ) : (
                            <KeyIndicator
                                title={t('Average monthly payment')}
                                value={numberFormat(monthly_payment, {
                                    currency: currencyIso,
                                    decimals: 2,
                                    locale: currencyLocale,
                                    style: 'currency',
                                })}
                                widthIcon="20px"
                            />
                        )}
                    </Grid>
                    <Grid item xs={18} md={9} lg={6}>
                        <KeyIndicator
                            title={t('Financing amount')}
                            value={numberFormat(total_cost_financing, {
                                currency: currencyIso,
                                decimals: 2,
                                locale: currencyLocale,
                                style: 'currency',
                            })}
                            widthIcon="20px"
                        />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

AllianceReviewCIBanco.propTypes = {
    alliance: PropTypes.object,
    currencyIso: PropTypes.string,
    currencyLocale: PropTypes.string,
    financialProduct: PropTypes.object,
    handleClickAlliance: PropTypes.func,
    handleClickDeleteAlliance: PropTypes.func,
    hasFinancingRequest: PropTypes.bool,
    isGeneratedProposal: PropTypes.bool,
    isLocked: PropTypes.bool,
    proposalId: PropTypes.string,
};

export default AllianceReviewCIBanco;
