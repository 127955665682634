import i18next from 'i18next';

import {
    HOURS_IN_DAY,
    MONTHS,
    MONTHS_IN_YEAR,
    MS_IN_DAY,
} from 'common/constants';
import {
    differenceInYearsDate,
    formatDate,
    getDateStringAsUTC,
    differenceInDaysDate,
    getDaysInMonthDate,
    parseDate,
    subDate,
} from 'common/utils/dates';
import { getMonthLabel, numberFormat } from 'common/utils/helpers';

import { DATE_FORMATS } from '../afterSalesSettings/constants';

import { CHART_COLORS, DATE_OPTIONS, VIEW_OPTIONS } from './constants';

const formatToTwoDigits = (value) => value.toString().padStart(2, '0');
const formatDateLabel = (date) => {
    if (!date) return '';
    const formatted = formatDate(date, DATE_FORMATS.PERIOD_LABEL);
    return formatted?.toUpperCase();
};

export const getDefaultChartType = (viewOption) => {
    switch (viewOption) {
        case VIEW_OPTIONS.DAILY_HOUR:
        case VIEW_OPTIONS.DAILY_5_MINUTES:
            return 'area';
        default:
            return 'bar';
    }
};

export const getMaxDate = () => {
    const newDate = new Date();
    newDate.setDate(newDate.getDate() - 1);
    return newDate;
};

const getDaysInMonth = (date) =>
    getDaysInMonthDate(
        parseDate(date, DATE_OPTIONS[VIEW_OPTIONS.MONTHLY].format)
    ) || 0;

export const getMeasurementSourceChartConfig = ({
    config,
    countryCurrencyLocale,
    data,
    date,
}) => {
    const isHourly = config.viewOption === VIEW_OPTIONS.DAILY_HOUR;
    const isPerMinute = config.viewOption === VIEW_OPTIONS.DAILY_5_MINUTES;

    const formatter = (val) =>
        numberFormat(val, {
            decimals: 2,
            locale: countryCurrencyLocale,
            style: 'decimal',
            unit: isPerMinute ? 'kW' : 'kWh',
        });

    const categories = getChartCategories(config.viewOption, date);
    const {
        batteryPower,
        consumption,
        estimatedData,
        generation,
        gridConsumption,
        selfConsumption,
    } = getSeries(data, date, config.viewOption);
    let options = {
        chart: {
            animations: { speed: 300 },
            background: '#ffffff00',
            toolbar: { show: true },
            zoom: { enabled: true },
        },
        colors: CHART_COLORS.measurement_source,
        dataLabels: { enabled: false },
        fill: { opacity: 1 },
        legend: { showForNullSeries: false },
        stroke: { curve: 'smooth', width: 2 },
        tooltip: {
            x: {
                formatter: (_, { dataPointIndex }) => {
                    const value = categories?.[dataPointIndex] || '';
                    const estimated = estimatedData?.[dataPointIndex];
                    if (!estimated) return value;
                    return `${value} - ${i18next.t('Estimated')}: ${estimated}`;
                },
            },
            y: { formatter },
        },
        markers: { size: 0 },
        xaxis: { categories, tickAmount: 12 },
        yaxis: { decimalsInFloat: 2, labels: { formatter }, tickAmount: 4 },
    };

    const series = [
        {
            name: i18next.t('Total generation'),
            data: generation || [],
        },
        {
            name: i18next.t('Total consumption'),
            data: consumption || [],
        },
        {
            name: i18next.t('Self-consumption'),
            data: selfConsumption || [],
        },
        {
            name: i18next.t('Consumption from Grid'),
            data: gridConsumption || [],
        },
    ];

    if (isHourly || isPerMinute)
        series.push({
            name: i18next.t('Battery power'),
            data: batteryPower || [],
        });

    return {
        categories,
        hasEstimatedData: estimatedData?.some((value) => value),
        key: Object.values(config).join('-') + Math.random(),
        options,
        series,
        type: config.chartType,
    };
};

export const getSeries = (data, date, option) => {
    switch (option) {
        case VIEW_OPTIONS.ANNUAL:
            return getYearlySeries(data);
        case VIEW_OPTIONS.MONTHLY:
            return getMonthlySeries(data, date);
        case VIEW_OPTIONS.DAILY_HOUR:
            return getHourlySeries(data);
        case VIEW_OPTIONS.DAILY_5_MINUTES:
            return getPerMinuteSeries(data);
        default:
            return {};
    }
};

const getYearlySeries = (data) => {
    return getSeriesData(data, MONTHS_IN_YEAR, (date) => date.getUTCMonth());
};

const getMonthlySeries = (data, date = '') => {
    const daysInMonth = getDaysInMonth(date);
    return getSeriesData(data, daysInMonth, (date) => date.getUTCDate() - 1);
};

const getHourlySeries = (data) => {
    return getSeriesData(data, HOURS_IN_DAY, (date) => date.getUTCHours());
};

const getPerMinuteSeries = (data, interval = 5) => {
    const stepsPerHour = 60 / interval;
    return getSeriesData(data, HOURS_IN_DAY * stepsPerHour, (date) => {
        const hours = date.getUTCHours();
        const minutes = date.getUTCMinutes();

        return hours * stepsPerHour + Math.floor(minutes / interval);
    });
};

const getSeriesData = (data, size, indexGetter) => {
    if (!data?.timestamp?.length) return [];

    const {
        battery_power,
        estimated,
        generation,
        grid_consumption,
        self_consumption,
        timestamp,
        total_consumption,
    } = data;

    const batteryPowerData = new Array(size).fill(0),
        consumptionData = new Array(size).fill(0),
        estimatedData = new Array(size).fill(0),
        generationData = new Array(size).fill(0),
        gridConsumptionData = new Array(size).fill(0),
        selfConsumptionData = new Array(size).fill(0);

    for (let i = 0; i < timestamp.length; i++) {
        const time = timestamp[i];
        const date = new Date(time.includes('Z') ? time : time + 'Z');
        const index = indexGetter(date);

        batteryPowerData[index] += battery_power?.[i] || 0;
        consumptionData[index] += total_consumption?.[i] || 0;
        estimatedData[index] += estimated?.[i] || 0;
        generationData[index] += generation?.[i] || 0;
        gridConsumptionData[index] += grid_consumption?.[i] || 0;
        selfConsumptionData[index] += self_consumption?.[i] || 0;
    }

    return {
        batteryPower: batteryPowerData,
        consumption: consumptionData,
        estimatedData,
        generation: generationData,
        gridConsumption: gridConsumptionData,
        selfConsumption: selfConsumptionData,
    };
};

const getChartCategories = (option, date) => {
    switch (option) {
        case VIEW_OPTIONS.ANNUAL:
            return MONTHS.map((month) => getMonthLabel(month).toUpperCase());
        case VIEW_OPTIONS.MONTHLY:
            return Array.from(
                { length: getDaysInMonth(date) },
                (_, i) => i + 1
            );
        case VIEW_OPTIONS.DAILY_HOUR:
            return Array.from(
                { length: HOURS_IN_DAY },
                (_, i) => `${formatToTwoDigits(i)}:00`
            );
        case VIEW_OPTIONS.DAILY_5_MINUTES: {
            const interval = 5;
            const stepsPerHour = 60 / interval;

            return Array.from(
                { length: HOURS_IN_DAY * stepsPerHour },
                (_, i) => {
                    const hours = Math.floor(i / stepsPerHour);
                    const minutes = (i % stepsPerHour) * interval;

                    return `${formatToTwoDigits(hours)}:${formatToTwoDigits(
                        minutes
                    )}`;
                }
            );
        }
        default:
            return [];
    }
};

const getDateColumnName = (viewOption) => {
    switch (viewOption) {
        case VIEW_OPTIONS.DAILY_HOUR:
        case VIEW_OPTIONS.DAILY_5_MINUTES:
            return i18next.t('Time');
        case VIEW_OPTIONS.MONTHLY:
            return i18next.t('Day');
        case VIEW_OPTIONS.ANNUAL:
            return i18next.t('Month');
        default:
            return i18next.t('Date');
    }
};

const getDateColumnWidth = (seriesLength) => {
    if (seriesLength > 2) return { width: 100 };
    return { flex: 1, minWidth: 100 };
};

export const getColumnsFromSeries = (series, option, countryCurrencyLocale) => {
    const columns = [
        {
            field: 'date',
            headerName: getDateColumnName(option),
            ...getDateColumnWidth(series?.length || 0),
        },
    ];

    for (const serie of series)
        columns.push({
            field: serie.name,
            flex: 1,
            headerName: serie.name,
            valueGetter: ({ row }) =>
                numberFormat(row[serie.name], {
                    decimals: 2,
                    locale: countryCurrencyLocale,
                    style: 'decimal',
                }),
            minWidth: 200,
        });

    return columns;
};

export const getRowsFromSeries = (series, categories) => {
    const rows = [];

    for (let i = 0; i < categories?.length || 0; i++) {
        const row = { id: i, date: categories[i] };

        for (const serie of series) row[serie.name] = serie.data[i];

        rows.push(row);
    }

    return rows;
};

const getTotalPeriodsByKey = (periods, key) => {
    if (!periods?.length) return 0;
    return periods.reduce((acc, period) => acc + (period[key] || 0), 0);
};

/** Generation Accuracy **/
const addYearsToDateString = (
    date,
    years,
    dateFormat = DATE_FORMATS.SUNWISE
) => {
    const newDate = parseDate(date, dateFormat);
    newDate.setFullYear(newDate.getFullYear() + years);
    return formatDate(newDate, dateFormat);
};

export const createPeriodsDateRange = (startDate) => {
    if (!startDate) return [];

    const dates = [];
    const initial = parseDate(startDate, DATE_FORMATS.SUNWISE);

    for (
        let currentDate = new Date();
        currentDate > initial;
        currentDate.setFullYear(currentDate.getFullYear() - 1)
    ) {
        const finalDate = formatDateLabel(currentDate);
        const _initialDate = subDate(currentDate, { years: 1 });
        const parsedInitialDate =
            _initialDate > initial ? _initialDate : initial;
        const initialDate = formatDateLabel(parsedInitialDate);
        const label = `${initialDate} - ${finalDate}`;

        const valueFinalDate = formatDate(currentDate, DATE_FORMATS.SUNWISE);
        const valueInitialDate = formatDate(
            parsedInitialDate,
            DATE_FORMATS.SUNWISE
        );
        const value = `${valueInitialDate} - ${valueFinalDate}`;

        dates.push({ label, value });
    }

    return dates;
};

export const getParamsMeasurementSourceByPeriods = ({
    dateRange,
    dateRangeOptions,
    isBimonthly,
    referencePeriods,
    startDate,
    viewOption,
}) => {
    const periods = getReferencePeriods({
        dateRange,
        dateRangeOptions,
        isBimonthly,
        referencePeriods,
        startDate,
        viewOption,
    });

    if (!periods?.length) return {};

    const { initial_date } = periods[0] || {};
    const { final_date } = periods[periods.length - 1] || {};
    return {
        finalDate: final_date?.toUpperCase(),
        force: true,
        freq: 'D',
        initialDate: initial_date?.toUpperCase(),
        useSummaries: true,
    };
};

const getTooltipMessage = ({
    dataPointIndex,
    forceLabel,
    periods,
    seriesIndex,
}) => {
    if (!periods?.length) return '';
    const {
        final_date,
        initial_date,
        label,
        original_final_date,
        original_initial_date,
        original_label,
    } = periods[dataPointIndex];
    const serieLabel =
        !forceLabel && seriesIndex === 0 ? original_label : label;

    const parsedInitialDate = parseDate(
        seriesIndex === 0 ? original_initial_date : initial_date,
        DATE_FORMATS.SUNWISE
    );
    const parsedFinalDate = parseDate(
        seriesIndex === 0 ? original_final_date : final_date,
        DATE_FORMATS.SUNWISE
    );
    const initialDate = formatDate(
        parsedInitialDate,
        DATE_FORMATS.PERIOD_LABEL_2
    );
    const finalDate = formatDate(parsedFinalDate, DATE_FORMATS.PERIOD_LABEL_2);
    const diffDays = differenceInDaysDate(parsedFinalDate, parsedInitialDate);
    return `${serieLabel.toUpperCase()} - (${initialDate.toUpperCase()} - ${finalDate.toUpperCase()}) - (${diffDays} ${i18next.t(
        'Day',
        { count: diffDays }
    )})`;
};

export const getGenerationAccuracyChartConfig = ({
    categories,
    config,
    countryCurrencyLocale,
    periods,
    series,
}) => {
    const isPerMinute = config.viewOption === VIEW_OPTIONS.DAILY_5_MINUTES;

    const formatter = (val) =>
        numberFormat(val, {
            decimals: 2,
            locale: countryCurrencyLocale,
            style: 'decimal',
            unit: isPerMinute ? 'kW' : 'kWh',
        });

    let options = {
        chart: {
            background: '#ffffff00',
            toolbar: { show: false },
            zoom: { enabled: false },
        },
        colors: CHART_COLORS.generation_accuracy,
        dataLabels: { enabled: false },
        tooltip: {
            x: {
                formatter: (_, { dataPointIndex, seriesIndex }) =>
                    getTooltipMessage({ dataPointIndex, periods, seriesIndex }),
            },
            y: { formatter },
        },
        xaxis: { categories, tickAmount: 12 },
        yaxis: { decimalsInFloat: 2, labels: { formatter }, tickAmount: 4 },
    };

    return {
        categories,
        key: Object.values(config).join('-') + Math.random(),
        options,
        series,
        type: config.chartType,
    };
};

const getReferenceGenerationData = ({ measuredEnergy, periods }) => {
    const categories = [];
    const measured = new Array(periods.length).fill(0);
    const proposal = [];
    const proposalTimes = [];
    const { generation, timestamp } = measuredEnergy || {};

    for (const period of periods) {
        const { final_date, initial_date } = period || {};

        if (!initial_date || !final_date) continue;

        proposalTimes.push({
            end: getDateStringAsUTC(final_date),
            start: getDateStringAsUTC(initial_date) + MS_IN_DAY,
        });

        categories.push(period.label || '');
        proposal.push(period.generation || 0);
    }

    for (let i = 0; i < timestamp?.length || 0; i++) {
        const time = timestamp[i];
        const date = new Date(time.includes('Z') ? time : time + 'Z');

        const index = proposalTimes.findIndex(
            ({ start, end }) => date >= start && date <= end
        );

        if (index !== -1) measured[index] += generation[i] || 0;
    }

    return { categories, measured, proposal };
};

export const getGenerationAccuracySeries = ({ measuredEnergy, periods }) => {
    if (!periods?.length) return { categories: [], series: [] };

    const { categories, measured, proposal } = getReferenceGenerationData({
        measuredEnergy,
        periods,
    });

    const series = [
        { name: i18next.t('Proposal'), data: proposal || [] },
        { name: i18next.t('Measurement source'), data: measured || [] },
    ];

    return { categories, series };
};

const getPeriodsInRange = ({
    diffYears,
    finalDate = new Date(),
    finalDateKey = 'initial_date',
    initialDate = new Date(),
    initialDateKey = 'final_date',
    isBimonthly,
    periodDateFormat = DATE_FORMATS.SUNWISE,
    totalPeriods = [],
}) => {
    const periods = [];

    for (const period of totalPeriods) {
        const periodFinalDate = period?.[finalDateKey];
        if (!periodFinalDate) continue;

        const date = parseDate(periodFinalDate, periodDateFormat);
        const daysToSubtract = period?.is_bimonthly || isBimonthly ? 30 : 15;
        date.setFullYear(date.getFullYear() + (diffYears || 0));
        date.setDate(date.getDate() - daysToSubtract);

        if (date > finalDate) return periods;
        if (date < initialDate) continue;

        const periodInitialDate = period?.[initialDateKey];
        const newPeriod = {
            ...period,
            final_date: addYearsToDateString(
                periodFinalDate,
                diffYears,
                periodDateFormat
            ),
            initial_date: addYearsToDateString(
                periodInitialDate,
                diffYears,
                periodDateFormat
            ),
            label: formatDateLabel(date),
            original_final_date: periodFinalDate,
            original_initial_date: periodInitialDate,
            original_label: formatDateLabel(
                transformDateStringToMainDate(periodFinalDate, isBimonthly)
            ),
        };

        periods.push(newPeriod);
    }
    return periods;
};

const transformDateStringToMainDate = (
    date,
    isBimonthly,
    dateFormat = DATE_FORMATS.SUNWISE
) => {
    if (!date) return '';

    return subDate(parseDate(date, dateFormat), {
        days: isBimonthly ? 30 : 15,
    });
};

const getReferencePeriodsByYear = ({
    dateRange,
    isBimonthly,
    referencePeriods,
    diffYears,
}) => {
    if (!referencePeriods?.length || !dateRange) return [];

    const [initialDate, finalDate] = dateRange.split(' - ');

    if (!initialDate || !finalDate) return [];

    const parsedInitialDate = parseDate(initialDate, DATE_FORMATS.SUNWISE);
    const parsedFinalDate = parseDate(finalDate, DATE_FORMATS.SUNWISE);

    return getPeriodsInRange({
        diffYears,
        finalDate: parsedFinalDate,
        finalDateKey: 'date_finish',
        initialDate: parsedInitialDate,
        initialDateKey: 'date_start',
        isBimonthly,
        totalPeriods: referencePeriods,
    });
};

const getReferencePeriodsTotalsByYear = ({
    dateRangeOptions,
    isBimonthly,
    referencePeriods,
    diffYears,
}) => {
    if (!referencePeriods?.length || !dateRangeOptions?.length) return [];

    const totalPeriods = [];

    for (const dateRange of dateRangeOptions) {
        if (!dateRange?.value) continue;

        const periods = getReferencePeriodsByYear({
            dateRange: dateRange.value,
            isBimonthly,
            referencePeriods,
            diffYears,
        });

        if (!periods?.length) continue;
        const firstPeriod = periods[0];
        const lastPeriod = periods[periods.length - 1];

        totalPeriods.push({
            final_date: lastPeriod.final_date,
            generation: getTotalPeriodsByKey(periods, 'generation'),
            initial_date: firstPeriod.initial_date,
            label: dateRange?.value,
            original_final_date: lastPeriod.original_final_date,
            original_initial_date: firstPeriod.original_initial_date,
            original_label: `${firstPeriod.original_label} - ${lastPeriod.original_label}`,
        });
    }
    return totalPeriods.reverse();
};

export const getReferencePeriods = ({
    dateRange,
    dateRangeOptions,
    isBimonthly,
    referencePeriods,
    startDate,
    viewOption,
}) => {
    if (!startDate || !referencePeriods?.length) return [];
    const parsedInstallationDate = parseDate(startDate, DATE_FORMATS.SUNWISE);
    const parsedFirstPeriodDate = transformDateStringToMainDate(
        referencePeriods[0].date_finish,
        isBimonthly
    );
    const diffYears = differenceInYearsDate(
        parsedInstallationDate,
        parsedFirstPeriodDate
    );

    switch (viewOption) {
        case VIEW_OPTIONS.ANNUAL:
            return getReferencePeriodsTotalsByYear({
                dateRangeOptions,
                diffYears,
                isBimonthly,
                referencePeriods,
            });
        case VIEW_OPTIONS.MONTHLY:
            return getReferencePeriodsByYear({
                dateRange,
                diffYears,
                isBimonthly,
                referencePeriods,
            });
    }
};

// Generation Ratio Chart
export const getGenerationRatioChartConfig = ({
    categories,
    colors = CHART_COLORS.generation_ratio,
    config,
    countryCurrencyLocale,
    periods,
    series,
}) => {
    const formatter = (val) =>
        numberFormat(val, {
            decimals: 2,
            locale: countryCurrencyLocale,
            style: 'decimal',
            unit: '%',
        });

    let options = {
        chart: {
            background: '#ffffff00',
            toolbar: { show: false },
            zoom: { enabled: false },
        },
        colors,
        dataLabels: { enabled: false },
        tooltip: {
            x: {
                formatter: (_, { dataPointIndex, seriesIndex }) =>
                    getTooltipMessage({
                        dataPointIndex,
                        forceLabel: true,
                        periods,
                        seriesIndex,
                    }),
            },
            y: { formatter },
        },
        xaxis: { categories, tickAmount: 12 },
        yaxis: { decimalsInFloat: 2, labels: { formatter }, tickAmount: 4 },
    };

    return {
        categories,
        key: Object.values(config).join('-') + Math.random(),
        options,
        series,
        type: config.chartType,
    };
};

export const getGenerationRatioSeries = ({ measuredEnergy, periods }) => {
    if (!periods?.length) return { categories: [], series: [] };

    const { categories, measured, proposal } = getReferenceGenerationData({
        measuredEnergy,
        periods,
    });

    const ratioData = proposal?.map((value, index) => {
        const ratio = value === 0 ? 0 : measured[index] / value;
        return ratio * 100 || 0;
    });

    const series = [
        { name: i18next.t('Energy compliance'), data: ratioData || [] },
    ];

    return { categories, series };
};

// ROI progress Chart
export const getConsumptionHistoryPeriods = ({
    consumptionHistory,
    dateRange,
    dateRangeOptions,
    startDate,
    viewOption,
}) => {
    if (!startDate || !consumptionHistory?.length) return [];
    const parsedInstallationDate = parseDate(startDate, DATE_FORMATS.SUNWISE);
    const parsedFirstPeriodDate = transformDateStringToMainDate(
        consumptionHistory[0].final_date,
        consumptionHistory[0].is_bimonthly
    );
    const diffYears = differenceInYearsDate(
        parsedInstallationDate,
        parsedFirstPeriodDate
    );

    switch (viewOption) {
        case VIEW_OPTIONS.ANNUAL:
            return getConsumptionHistoryPeriodsTotalsByYear({
                consumptionHistory,
                dateRangeOptions,
                diffYears,
            });
        case VIEW_OPTIONS.MONTHLY:
            return getConsumptionHistoryPeriodsByYear({
                consumptionHistory,
                dateRange,
                diffYears,
            });
        default:
            return [];
    }
};

const getConsumptionHistoryPeriodsByYear = ({
    consumptionHistory,
    dateRange,
    diffYears,
}) => {
    if (!consumptionHistory?.length || !dateRange) return [];

    const [initialDate, finalDate] = dateRange.split(' - ');

    if (!initialDate || !finalDate) return [];

    const parsedInitialDate = parseDate(initialDate, DATE_FORMATS.SUNWISE);
    const parsedFinalDate = parseDate(finalDate, DATE_FORMATS.SUNWISE);

    return getPeriodsInRange({
        diffYears,
        finalDate: parsedFinalDate,
        finalDateKey: 'final_date',
        initialDate: parsedInitialDate,
        initialDateKey: 'initial_date',
        totalPeriods: consumptionHistory,
    });
};

const getConsumptionHistoryPeriodsTotalsByYear = ({
    consumptionHistory,
    dateRangeOptions,
    diffYears,
}) => {
    if (!consumptionHistory?.length || !dateRangeOptions?.length) return [];

    const totalPeriods = [];

    for (const dateRange of dateRangeOptions) {
        if (!dateRange?.value) continue;

        const periods = getConsumptionHistoryPeriodsByYear({
            dateRange: dateRange.value,
            consumptionHistory,
            diffYears,
        });

        if (!periods?.length) continue;
        const firstPeriod = periods[0];
        const lastPeriod = periods[periods.length - 1];

        totalPeriods.push({
            final_date: lastPeriod.final_date,
            initial_date: firstPeriod.initial_date,
            label: dateRange?.value,
            original_final_date: lastPeriod.original_final_date,
            original_initial_date: firstPeriod.original_initial_date,
            roi_progress: getTotalPeriodsByKey(periods, 'roi_progress'),
            original_label: `${firstPeriod.original_label} - ${lastPeriod.original_label}`,
        });
    }
    return totalPeriods.reverse();
};

const getTotalPrice = (values) => {
    if (!values || typeof values !== 'object') return 0;
    return Object.values(values).reduce((acc, value) => acc + (value || 0), 0);
};

export const getRoiProgress = ({ initialProgress, period, totalCost }) => {
    if (!totalCost) return 0;

    let progress = initialProgress || 0;
    const realPrice = getTotalPrice(period.real_info_price_consumption);
    const withoutSolarPrice = getTotalPrice(
        period.total_info_price_consumption
    );
    progress += (withoutSolarPrice - realPrice) / totalCost || 0;

    return progress;
};

export const getRoiProgressSeries = (periods) => {
    if (!periods?.length) return { categories: [], series: [] };

    const categories = periods.map((period) => period.label);
    const series = [
        {
            name: i18next.t('ROI progress') + ' (%)',
            data: periods.map((period) => period.roi_progress),
        },
    ];

    return { categories, series };
};

// Accumulated Savings
export const getAccumulatedSavingsChartConfig = ({
    accumulatedSavings,
    chartType,
    countryCurrencyIso,
    countryCurrencyLocale,
    countryCurrencySymbol,
}) => {
    const formatter = (val) =>
        numberFormat(val, {
            currency: countryCurrencyIso,
            locale: countryCurrencyLocale,
            style: 'currency',
            symbol: countryCurrencySymbol,
        });

    let options = {
        chart: {
            background: '#ffffff00',
            toolbar: { show: false },
            zoom: { enabled: false },
        },
        colors: CHART_COLORS.accumulated_savings,
        dataLabels: { enabled: false },
        tooltip: { y: { formatter } },
        xaxis: { categories: accumulatedSavings?.categories || [] },
        yaxis: { labels: { formatter } },
    };

    const series = [
        {
            data: accumulatedSavings?.projected_data || [],
            name: i18next.t('Projected'),
        },
        {
            data: accumulatedSavings?.actual_data || [],
            name: i18next.t('Current'),
        },
    ];

    return {
        categories: accumulatedSavings?.categories || [],
        key: chartType + Math.random(),
        options,
        series,
        type: chartType,
    };
};
