import get from 'lodash/get';

import { getItems } from 'common/api/v1/proposalsSummary';

import {
    FETCH_SUMMARIES,
    FETCH_SUMMARIES_FAILURE,
    FETCH_SUMMARIES_SUCCESS,
} from '../actionTypes';
import { proposalSummaryActions } from '../reducer';

export default (projectId, isArchived) => (dispatch) => {
    dispatch(proposalSummaryActions[FETCH_SUMMARIES]());

    getItems(projectId, isArchived)
        .then((response) =>
            dispatch(
                proposalSummaryActions[FETCH_SUMMARIES_SUCCESS](
                    response.data.data
                )
            )
        )
        .catch((error) =>
            dispatch(
                proposalSummaryActions[FETCH_SUMMARIES_FAILURE](
                    get(error, 'data.errors', [])
                )
            )
        );
};
