import React from 'react';

import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box, Breadcrumbs, Typography } from 'sunwise-ui';

import {
    NEW_TEMPLATE_MODE,
    TEMPLATE_MODAL_SELECT_BASE,
    TEMPLATE_MODAL_MAIN_DATA,
} from '../../constants';

const WizardBreadcrumbs = ({ setWizardStep, templateMode, wizardStep }) => {
    const { t } = useTranslation();
    if (templateMode !== NEW_TEMPLATE_MODE) return null;
    return (
        <Box sx={{ borderBottom: 1, borderColor: 'divider', py: 1 }}>
            <Breadcrumbs
                separator={<NavigateNextIcon fontSize="small" />}
                aria-label="breadcrumb"
            >
                <Typography
                    onClick={() => setWizardStep(TEMPLATE_MODAL_MAIN_DATA)}
                    sx={{
                        fontWeight:
                            wizardStep === TEMPLATE_MODAL_MAIN_DATA
                                ? 'bold'
                                : 'normal',
                    }}
                    variant="body2"
                >
                    {t('General data')}
                </Typography>
                <Typography
                    sx={{
                        fontWeight:
                            wizardStep === TEMPLATE_MODAL_SELECT_BASE
                                ? 'bold'
                                : 'normal',
                    }}
                    variant="body2"
                >
                    {t('Select base template')}
                </Typography>
            </Breadcrumbs>
        </Box>
    );
};

WizardBreadcrumbs.propTypes = {
    setWizardStep: PropTypes.func,
    templateMode: PropTypes.string,
    wizardStep: PropTypes.string,
};

export default WizardBreadcrumbs;
