import { getItems } from 'common/api/v1/energyBackupCompany';

import {
    FETCH_BATTERIES,
    FETCH_BATTERIES_FAILURE,
    FETCH_BATTERIES_SUCCESS,
} from '../actionTypes';
import { planConfigurationActions } from '../reducer';

export default (branchOffice) => (dispatch) => {
    dispatch(planConfigurationActions[FETCH_BATTERIES]());

    getItems(branchOffice)
        .then((response) => {
            dispatch(
                planConfigurationActions[FETCH_BATTERIES_SUCCESS](
                    response.data.data
                )
            );
        })
        .catch((error) =>
            dispatch(
                planConfigurationActions[FETCH_BATTERIES_FAILURE](
                    error?.response?.data?.errors
                )
            )
        );
};
