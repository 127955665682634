import React, { useContext, useEffect, useState } from 'react';

import { MarkerClusterer } from '@googlemaps/markerclusterer';
import { styled } from '@mui/material/styles';
import { Map } from 'google-maps-react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box } from 'sunwise-ui';

import { DEFAULT_LAT, DEFAULT_LNG } from 'common/constants';
import { GeneralContext } from 'common/utils/contexts';

import { SVG_BASE_CLUSTER } from '../constants';
import { getMarkerIconByStatus, getSvgUrl } from '../helpers';

const MapWrapper = styled(Box)`
    background-color: #000;
    bottom: 0;
    height: 100%;
    left: 0;
    position: relative;
    min-height: 400px;
    right: 0;
    top: 0;
    z-index: 1;
`;
const DEBOUNCE_TIME = 500;

const MapContainer = ({
    companyPositionLatLng,
    handleSelectProject,
    monitoringProjects,
}) => {
    const { google } = useContext(GeneralContext);
    const [mapValue, setMapValue] = useState(null);
    const { t } = useTranslation();
    const initialCenter = {
        lat: Number(companyPositionLatLng?.latitude) || DEFAULT_LAT,
        lng: Number(companyPositionLatLng?.longitude) || DEFAULT_LNG,
    };

    useEffect(() => {
        const markers = [];
        let markerClusterer = null;
        if (google && mapValue && monitoringProjects?.length > 0) {
            const scaledSize = new google.maps.Size(40, 50);
            const bounds = new google.maps.LatLngBounds();

            for (const project of monitoringProjects) {
                if (!project?.latitude || !project?.longitude) continue;
                const markerPosition = new google.maps.LatLng(
                    project?.latitude,
                    project?.longitude
                );
                const marker = new google.maps.Marker({
                    icon: {
                        scaledSize,
                        url: getMarkerIconByStatus(project?.status),
                    },
                    map: mapValue,
                    position: markerPosition,
                });

                markers.push(marker);
                bounds.extend(markerPosition);

                marker.addListener('click', () => {
                    handleSelectProject(project?.id);
                    marker.setAnimation(google.maps.Animation.BOUNCE);
                    setTimeout(() => marker.setAnimation(null), DEBOUNCE_TIME);
                });
            }
            mapValue.fitBounds(bounds, 15);

            markerClusterer = new MarkerClusterer({
                map: mapValue,
                markers,
                renderer: {
                    render({ count, position }) {
                        const clusterOptions = {
                            icon: {
                                anchor: new google.maps.Point(25, 25),
                                url: getSvgUrl(
                                    SVG_BASE_CLUSTER.replace('{{count}}', count)
                                ),
                            },
                            position,
                            title: `${t('Project', { count })}: ${count}`,
                            zIndex:
                                Number(google.maps.Marker.MAX_ZINDEX) + count,
                        };
                        return new google.maps.Marker(clusterOptions);
                    },
                },
            });
        }
        return () => {
            markers.forEach((marker) => marker.setMap(null));
            markerClusterer?.clearMarkers();
        };
    }, [google, mapValue, monitoringProjects]);

    return (
        <MapWrapper>
            <Map
                disableDefaultUI={false}
                disableDoubleClickZoom={true}
                fullscreenControl={false}
                google={google}
                initialCenter={initialCenter}
                mapTypeControl
                onReady={(_, map) => {
                    setMapValue(map);
                    if (map?.setMapTypeId && google?.maps?.MapTypeId?.SATELLITE)
                        map.setMapTypeId(google.maps.MapTypeId.SATELLITE);
                }}
                rotateControl={false}
                streetViewControl={false}
            />
        </MapWrapper>
    );
};

MapContainer.propTypes = {
    companyPositionLatLng: PropTypes.object,
    handleSelectProject: PropTypes.func,
    monitoringProjects: PropTypes.array,
};

export default MapContainer;
