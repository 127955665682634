import { getTemplates } from 'common/api/v1/aftersalesTemplates';

import {
    FETCH_TEMPLATES,
    FETCH_TEMPLATES_FAILURE,
    FETCH_TEMPLATES_SUCCESS,
} from '../actionTypes';
import { actions } from '../reducer';

export default (uuid) => (dispatch) =>
    new Promise((resolve) => {
        dispatch(actions[FETCH_TEMPLATES]());

        getTemplates(uuid)
            .then((response) =>
                dispatch(actions[FETCH_TEMPLATES_SUCCESS](response?.data?.data))
            )
            .catch((error) => dispatch(actions[FETCH_TEMPLATES_FAILURE](error)))
            .finally(resolve);
    });
