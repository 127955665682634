import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

import initializeForm from './initializeForm';
import setIsNewRecord from './setIsNewRecord';
import toggleModalProduct from './toggleModalProduct';

const mapItems = (items) => {
    if (isEmpty(items)) return [{ item: '' }];

    return items.map((item) => ({
        item: item.id,
    }));
};

const handleLists = (list) => {
    const temp = {};

    if (list) {
        list.forEach((item) => (temp[item] = true));
    }

    return temp;
};

export default (item) => (dispatch) => {
    if (item) {
        const newValues = {
            accessories: mapItems(item.accessories_branch_office),
            additionals: mapItems(item.additionals_branch_office),
            battery: get(item, ['battery_branch_office', 'id'], ''),
            branch_office: get(item, ['branch_office', 'id'], ''),
            considerations: item.considerations,
            custom_offer_template: get(
                item,
                ['custom_offer_template', 'id'],
                ''
            ),
            id: item.id,
            installation_type: handleLists(item.installation_type),
            inverter_brand: get(item, ['inverter_brand', 'id'], ''),
            name: item.name,
            panel: get(item, ['panel_branch_office', 'id'], ''),
            saving_percentage: item.saving_percentage,
            structures: mapItems(item.structures),
            validity: item.validity,
            workforce_and_electric_materials: mapItems(
                item.workforce_and_electric_materials_branch_office
            ),
        };
        dispatch(initializeForm(newValues));
    }
    dispatch(setIsNewRecord(false));
    dispatch(toggleModalProduct(true));
};
