import { getOfferSmartDocuments } from 'common/api/v1/offerSmartDocuments';

import {
    FETCH_OFFER_SMART_DOCUMENTS,
    FETCH_OFFER_SMART_DOCUMENTS_FAILURE,
    FETCH_OFFER_SMART_DOCUMENTS_SUCCESS,
} from '../../actionTypes';
import { multiTemplateReviewActions } from '../../reducer';

export default (id) => (dispatch) =>
    new Promise((resolve) => {
        dispatch(multiTemplateReviewActions[FETCH_OFFER_SMART_DOCUMENTS]());

        getOfferSmartDocuments(id)
            .then((response) =>
                dispatch(
                    multiTemplateReviewActions[
                        FETCH_OFFER_SMART_DOCUMENTS_SUCCESS
                    ](response.data.data)
                )
            )
            .catch((error) =>
                dispatch(
                    multiTemplateReviewActions[
                        FETCH_OFFER_SMART_DOCUMENTS_FAILURE
                    ](error)
                )
            )
            .finally(resolve);
    });
