import React, { useEffect, useState } from 'react';

import BarChartIcon from '@mui/icons-material/BarChart';
import ListIcon from '@mui/icons-material/List';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Grid, ToggleButton, ToggleButtonGroup } from 'sunwise-ui';

import * as actions from '../actions';
import { getAccumulatedSavingsChartConfig } from '../helpers';
import * as selectors from '../selectors';

import Chart from './Chart';

const chartTypes = [
    { icon: <ShowChartIcon />, value: 'line' },
    { icon: <BarChartIcon />, value: 'bar' },
    { icon: <ListIcon />, value: 'table' },
];

const AccumulatedSavingsChart = ({
    accumulatedSavings,
    countryCurrencyIso,
    countryCurrencyLocale,
    countryCurrencySymbol,
    fetchAccumulatedSavings,
    isFetchingConsumptionHistory,
    loadedData,
    selectedProjectId,
    setLoadedData,
}) => {
    const [chartConfig, setChartConfig] = useState({
        categories: [],
        options: {},
        series: [],
    });
    const [chartType, setChartType] = useState('bar');
    const { t } = useTranslation();

    const handleOnChangeChartType = (value) => {
        setChartType(value);
        const newConfig = getAccumulatedSavingsChartConfig({
            accumulatedSavings,
            chartType: value,
            countryCurrencyIso,
            countryCurrencyLocale,
            countryCurrencySymbol,
        });
        setChartConfig(newConfig);
    };

    useEffect(() => {
        fetchAccumulatedSavings(selectedProjectId, () => setLoadedData(true));
    }, []);

    useEffect(() => {
        if (accumulatedSavings) {
            const newConfig = getAccumulatedSavingsChartConfig({
                accumulatedSavings,
                chartType,
                countryCurrencyIso,
                countryCurrencyLocale,
                countryCurrencySymbol,
            });
            setChartConfig(newConfig);
        }
    }, [accumulatedSavings]);

    const disabled = isFetchingConsumptionHistory || !loadedData;

    return (
        <Grid container alignItems="flex-end" px={2}>
            <Grid item xs={18} textAlign="right">
                <ToggleButtonGroup
                    disabled={disabled}
                    exclusive
                    onChange={(_, value) =>
                        value !== null && handleOnChangeChartType(value)
                    }
                    size="small"
                    value={chartType}
                >
                    {chartTypes.map((type) => (
                        <ToggleButton key={type.value} value={type.value}>
                            {type.icon}
                        </ToggleButton>
                    ))}
                </ToggleButtonGroup>
            </Grid>

            <Grid item xs={18}>
                <Chart
                    chartConfig={chartConfig}
                    chartType={chartType}
                    emptyDescription={t(
                        'Make sure you have at least one historical period'
                    )}
                    isLoading={disabled}
                    showIndicators={false}
                    tableDateLabelDefault
                />
            </Grid>
        </Grid>
    );
};

const mapStateToProps = createStructuredSelector({
    accumulatedSavings: selectors.getAccumulatedSavingsData,
    isFetchingConsumptionHistory: selectors.getIsFetchingConsumptionHistory,
});

const mapDispatchToProps = (dispatch) => ({
    fetchAccumulatedSavings: (projectId, callback) =>
        dispatch(actions.fetchAccumulatedSavings(projectId, callback)),
});

AccumulatedSavingsChart.propTypes = {
    accumulatedSavings: PropTypes.object,
    countryCurrencyIso: PropTypes.string,
    countryCurrencyLocale: PropTypes.string,
    countryCurrencySymbol: PropTypes.string,
    fetchAccumulatedSavings: PropTypes.func,
    isFetchingConsumptionHistory: PropTypes.bool,
    loadedData: PropTypes.bool,
    selectedProjectId: PropTypes.string,
    setLoadedData: PropTypes.func,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AccumulatedSavingsChart);
