import React, { useContext, useEffect } from 'react';

import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { UiDialog as Dialog } from 'sunwise-ui';

import { LoadingContext } from 'common/utils/contexts';
import yupResolver from 'common/utils/yupResolver';

import * as actions from '../actions';
import { BUSINESS_TYPE_ITEMS } from '../constants';
import * as selectors from '../selectors';
import validate from '../validate';

import CustomRateFormTabs from './CustomRateFormTabs';

const CustomRateModalForm = ({
    initialValues,
    isNewRate,
    isSavingRate,
    isSavingSupplierUtility,
    modalIsOpen,
    resetRateModalForm,
    saveRate,
    setModalIsOpen,
}) => {
    const { t } = useTranslation();
    const loadingContext = useContext(LoadingContext);

    const {
        control,
        getValues,
        handleSubmit,
        reset,
        setValue,
        trigger,
        watch,
    } = useForm({
        defaultValues: initialValues,
        resolver: yupResolver(validate),
    });
    useEffect(() => {
        reset(initialValues);
    }, [initialValues]);

    useEffect(() => {
        if (isEmpty(loadingContext)) return;
        if (isSavingRate) loadingContext.openLoading(t('Saving').concat('...'));
        else loadingContext.closeLoading();
    }, [isSavingRate]);

    useEffect(() => {
        if (isEmpty(loadingContext)) return;
        if (isSavingSupplierUtility)
            loadingContext.openLoading(t('Saving').concat('...'));
        else loadingContext.closeLoading();
    }, [isSavingSupplierUtility]);

    const [businessType, nameShort] = watch([
        'main_data.business_type',
        'main_data.name_short',
    ]);

    return (
        <Dialog
            onClose={() => setModalIsOpen(false)}
            onExited={() => resetRateModalForm()}
            open={modalIsOpen}
            size="lg"
            title={`${t(BUSINESS_TYPE_ITEMS[businessType])} - ${nameShort}`}
        >
            <form
                onSubmit={handleSubmit((values) => saveRate(isNewRate, values))}
            >
                <CustomRateFormTabs
                    control={control}
                    getValues={getValues}
                    isNewRate={isNewRate}
                    setValue={setValue}
                    tabKey="custom-rate-modal-form-tabs"
                    trigger={trigger}
                />
            </form>
        </Dialog>
    );
};

const mapStateToProps = createStructuredSelector({
    initialValues: selectors.getRateModalInitialValues,
    isFetchingSupplierUtilities: selectors.getIsFetchingSupplierUtilities,
    isNewRate: selectors.getModalIsCreate,
    isSavingRate: selectors.getIsSavingRate,
    isSavingSupplierUtility: selectors.getIsSavingSupplierUtility,
    modalIsOpen: selectors.getModalIsOpen,
    supplierUtilitiesItemsToSelect: selectors.getSupplierUtilitiesItemsToSelect,
    supplierUtilitiesModalIsOpen: selectors.getSupplierUtilitiesModalIsOpen,
});

const mapDispatchToProps = (dispatch) => ({
    resetRateModalForm: () => dispatch(actions.resetRateModalForm()),
    saveRate: (isNewRate, values) =>
        dispatch(actions.saveRate(isNewRate, values)),
    setModalIsOpen: (isOpen) => dispatch(actions.setModalIsOpen(isOpen)),
    setSupplierUtilitiesModalIsOpen: (isOpen) =>
        dispatch(actions.setSupplierUtilitiesModalIsOpen(isOpen)),
});

CustomRateModalForm.propTypes = {
    initialValues: PropTypes.object,
    isNewRate: PropTypes.bool,
    isSavingRate: PropTypes.bool,
    isSavingSupplierUtility: PropTypes.bool,
    modalIsOpen: PropTypes.bool,
    resetRateModalForm: PropTypes.func,
    saveRate: PropTypes.func,
    setModalIsOpen: PropTypes.func,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CustomRateModalForm);
