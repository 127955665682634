import React, { useEffect } from 'react';

import get from 'lodash/get';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Box, Button, Grid, Typography } from 'sunwise-ui';

import {
    ReactHookFormCheck,
    ReactHookFormInput,
    ReactHookFormInputDatePicker,
    ReactHookFormSelect2,
    ReactHookFormSwitch,
} from 'common/components/form/bootstrap';
import ShowErrors from 'common/components/ShowErrors';
import { ROLE_LEVELS } from 'common/constants/permissionsV2';
import yupResolver from 'common/utils/yupResolver';

import * as actions from '../actions';
import { getGroupedRoleOptions } from '../helpers';
import * as selectors from '../selectors';
import validate from '../validate';

import BranchOfficeSelect from './BranchOfficeSelect';

const UserForm = ({
    canDelete,
    canModify,
    canModifyContacts,
    errors,
    handleClickRestartPassword,
    handleClickSave,
    initialValues,
    isNewRecord,
    isSaving,
    onChangePassword,
    onChangePasswordConfirmation,
    roles,
    rolesDictionary,
    setDialogTitle,
}) => {
    const { t } = useTranslation();

    const { control, getValues, handleSubmit, reset, setValue, watch } =
        useForm({
            context: { isNewRecord },
            defaultValues: initialValues,
            resolver: yupResolver(validate),
        });

    const [branchOffices, companyGroup, roleLevel, userBranchOffices] = watch([
        'branch_offices',
        'company_group',
        'role_level',
        'user_branch_offices',
    ]);

    useEffect(() => {
        reset(initialValues);
    }, [initialValues]);

    useEffect(() => {
        setDialogTitle(
            `${isNewRecord ? t('Add') : t('Update')} ${t(
                'Member'
            ).toLowerCase()}`
        );
    }, [isNewRecord]);

    useEffect(() => {
        const selectedRole = rolesDictionary[companyGroup];
        setValue('role_level', get(selectedRole, 'role_level', null));
    }, [companyGroup]);

    const isOwnerRole = roleLevel === ROLE_LEVELS.OWNER;

    const isDisabled = !isNewRecord && (!canModify || isOwnerRole || isSaving);

    return (
        <form>
            <Grid container>
                <Grid item xs sx={{ order: { xs: 2, md: 1 } }}>
                    <ReactHookFormSelect2
                        control={control}
                        disabled={isDisabled}
                        isClearable={false}
                        isLoading={false}
                        label={t('Role').concat(' *')}
                        name="company_group"
                        options={getGroupedRoleOptions(roles)}
                        sortOptions={false}
                    />
                </Grid>

                {!isDisabled && (
                    <Grid item xs={18} md={9} sx={{ order: { xs: 1, md: 2 } }}>
                        <Grid container>
                            <Grid item xs>
                                <Box alignItems="center" display="flex" gap={1}>
                                    <Typography variant="caption">
                                        {t('Status').concat(' *')}
                                    </Typography>
                                    <ReactHookFormSwitch
                                        control={control}
                                        disabled={!canDelete}
                                        label={t('Active')}
                                        name="is_enabled"
                                    />
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                )}
            </Grid>
            <ReactHookFormInput
                control={control}
                disabled={isDisabled}
                label={t('Name').concat(' *')}
                name="first_name"
            />
            <ReactHookFormInput
                control={control}
                disabled={isDisabled}
                label={t('Last name').concat(' *')}
                name="last_name"
            />
            <ReactHookFormInput
                control={control}
                disabled={isDisabled}
                label={t('Second last name')}
                name="second_surname"
            />
            <ReactHookFormInput
                control={control}
                disabled={isDisabled}
                label={t('Position')}
                name="position"
            />
            <ReactHookFormInput
                control={control}
                disabled={isDisabled}
                label={t('Phone number')}
                name="phone"
            />
            <ReactHookFormInputDatePicker
                control={control}
                disabled={isDisabled}
                fullWidth
                label={t('Date of birth')}
                maxDate={new Date()}
                name="birth_date"
            />
            <BranchOfficeSelect
                branchOffices={branchOffices}
                control={control}
                disabled={isDisabled}
                isNewRecord={isNewRecord}
                roleLevel={roleLevel}
                setValue={setValue}
                userBranchOffices={userBranchOffices}
            />
            <ReactHookFormInput
                control={control}
                disabled={!isNewRecord || isDisabled}
                label={t('Email').concat(' *')}
                name="email"
            />
            {isNewRecord && (
                <Box>
                    <ReactHookFormInput
                        control={control}
                        disabled={isDisabled}
                        label={t('Password').concat(' *')}
                        name="password"
                        onChange={(e) => {
                            onChangePassword(e);
                            setValue('password', e.target.value);
                        }}
                        type="password"
                    />

                    <ReactHookFormInput
                        control={control}
                        disabled={isDisabled}
                        label={t('Confirm password').concat(' *')}
                        name="confirm_password"
                        onChange={(e) => {
                            onChangePasswordConfirmation(
                                e,
                                getValues('password')
                            );
                            setValue('confirm_password', e.target.value);
                        }}
                        onFocus={(e) =>
                            onChangePasswordConfirmation(
                                e,
                                getValues('password')
                            )
                        }
                        type="password"
                    />
                </Box>
            )}
            {canModifyContacts && (
                <ReactHookFormCheck
                    control={control}
                    label={t('Only own contacts')}
                    id="has_limited_contacts"
                    name="has_limited_contacts"
                />
            )}
            <ShowErrors errors={errors} />
            <Grid container mt={1}>
                <Grid
                    item
                    xs
                    sx={{
                        display: 'flex',
                        flexDirection: { xs: 'column', md: 'row' },
                    }}
                >
                    {!isNewRecord && (
                        <Button
                            onClick={() =>
                                handleClickRestartPassword(getValues('id'))
                            }
                            disabled={isDisabled}
                            sx={{
                                width: { xs: '100%', md: 'auto' },
                                mb: { xs: 2, md: 0 },
                            }}
                            type="button"
                            variant="text"
                        >
                            {t('Restore password')}
                        </Button>
                    )}
                    <Box ml="auto">
                        <Button
                            disabled={isDisabled}
                            onClick={handleSubmit(handleClickSave)}
                            sx={{
                                width: { xs: '100%', md: 'auto' },
                            }}
                            type="button"
                            variant="outlined"
                        >
                            {t('Save')}
                        </Button>
                    </Box>
                </Grid>
            </Grid>
        </form>
    );
};

const mapStateToProps = createStructuredSelector({
    initialValues: selectors.getInitialValues,
    isNewRecord: selectors.getIsNewRecord,
    isSaving: selectors.getIsSavingItem,
});

const mapDispatchToProps = (dispatch) => ({
    handleClickRestartPassword: (id) =>
        dispatch(actions.prepareResetPassword(id)),
    handleClickSave: (values) => dispatch(actions.save(values)),
});

UserForm.propTypes = {
    canDelete: PropTypes.bool,
    canModify: PropTypes.bool,
    canModifyContacts: PropTypes.bool,
    errors: PropTypes.object,
    handleClickRestartPassword: PropTypes.func,
    handleClickSave: PropTypes.func,
    initialValues: PropTypes.object,
    isNewRecord: PropTypes.bool,
    isSaving: PropTypes.bool,
    onChangePassword: PropTypes.func,
    onChangePasswordConfirmation: PropTypes.func,
    roles: PropTypes.array,
    rolesDictionary: PropTypes.object,
    setDialogTitle: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(UserForm);
