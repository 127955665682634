import i18next from 'i18next';
import toNumber from 'lodash/toNumber';

import { numberFormat } from 'common/utils/helpers';

export const applyFormat = (number, locale = 'en-US', decimals = 0) =>
    numberFormat(number, { decimals, locale, style: 'decimal' });

export const compareRowsByColumn = ({ columnId, id1, id2, rows }) => {
    if (!columnId) return 0;
    const value1 = rows?.[id1]?.[columnId];
    const value2 = rows?.[id2]?.[columnId];
    return value1 - value2;
};

export const handleOnChangeLoadCapacityOrEfficiency = ({
    capacity = 0,
    efficiency,
} = {}) => {
    const _capacity = capacity ? toNumber(capacity) : 0;
    const _efficiency = efficiency ? toNumber(efficiency) : 0;

    if (_efficiency === 0) return Infinity;

    return _capacity / (_efficiency / 100);
};

export const getDateInformationFromIndex = (path, indexObject) => {
    const [period, day, hour] = path.split('.');
    const dayType = indexObject?.[period]?.[day]?.day || 0;
    const week = Math.ceil((Number(day) + 1) / 7);

    return { day, dayType, hour, period, week };
};

export const getEnergyWarningsRows = ({ emptyEnergyIndexes, realIndex }) => {
    return emptyEnergyIndexes.map((hourValues, index) => {
        const { dayType, hour, period, week } = getDateInformationFromIndex(
            hourValues.path,
            realIndex
        );

        return {
            ...hourValues,
            day: dayType,
            hour: hour,
            id: index,
            period: period,
            week: week,
        };
    });
};

export const filterValidArrayFields = (fields, key = 'max_power') => {
    if (!fields?.length) return [];
    return fields?.filter((field) => parseFloat(field[key]) >= 0) || [];
};

export const getFormattedValue = (value) => {
    const [integer, decimals] = (value ?? '').toString().split('.');
    if (!decimals) return `${integer || ''}`;
    return `${integer}.${decimals.slice(0, 2)}`;
};

export const getTierFields = ({ key = 'max_power', rate, tiers, values }) => {
    if (!tiers?.length);

    const dictValues = values?.reduce((acc, item) => {
        acc[item.identifier] = item;
        return acc;
    }, {});

    return tiers.map((item) => {
        const itemValue = dictValues?.[item.identifier];
        return {
            identifier: item.identifier,
            label: rate?.certified
                ? i18next.t(item.name, { context: 'rate' })
                : item.name,
            [key]: getFormattedValue(itemValue?.[key]) || null,
        };
    });
};
