import React, { useEffect, useState } from 'react';

import LinearProgress from '@mui/material/LinearProgress';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
    Alert,
    BottomActions,
    Box,
    Button,
    Card,
    DataGrid,
    Typography,
} from 'sunwise-ui';

import { CustomPagination } from 'common/components';
import Search from 'common/components/tables/Search';

import { getFilteredProjects } from '../helpers';

import ProjectColumns from './ProjectColumns';

const ProjectsSection = ({
    contactId,
    disabled,
    fetchProjects,
    isFetching,
    onCancelSelection,
    onSave,
    projects,
    selectedProject,
    setSelectedProject,
}) => {
    const [filteredProjects, setFilteredProjects] = useState(projects || []);
    const { t } = useTranslation();

    useEffect(() => {
        fetchProjects(contactId);
    }, [contactId]);

    useEffect(() => {
        setFilteredProjects(projects);
    }, [projects]);

    const filterProjects = (value) => {
        setFilteredProjects(getFilteredProjects(projects, value));
    };

    return (
        <>
            <Typography variant="h6" sx={{ mt: 2, ml: 1 }}>
                {t('Select a project')}
            </Typography>

            <Box py={2}>
                <Search
                    handleOnSearchChange={filterProjects}
                    placeholderSearch={t('Search project')}
                />
            </Box>

            <Alert severity="info" sx={{ mt: 2, ml: 1 }}>
                {t(
                    'Only projects that have initiated an after-sales project are shown'
                )}
            </Alert>

            <Card>
                <Card.Body>
                    <DataGrid
                        autoHeight
                        checkboxSelection={false}
                        columns={ProjectColumns()}
                        components={{
                            LoadingOverlay: LinearProgress,
                            Pagination: CustomPagination,
                        }}
                        initialState={{ pagination: { pageSize: 15 } }}
                        disableColumnMenu
                        disableSelectionOnClick
                        hideFooterSelectedRowCount
                        loading={isFetching}
                        onRowClick={(params) => setSelectedProject(params?.row)}
                        pagination
                        paginationMode="client"
                        rows={filteredProjects}
                        selectionModel={[selectedProject?.id]}
                        sx={{
                            '& .MuiDataGrid-row': { cursor: 'pointer' },
                            '& .MuiDataGrid-cell:focus': { outline: 'none' },
                        }}
                    />
                </Card.Body>
            </Card>

            <BottomActions>
                <Box display="flex" gap={1} mt={2}>
                    <Button
                        color="secondary"
                        disabled={disabled}
                        onClick={onCancelSelection}
                        variant="outlined"
                    >
                        {t('Back')}
                    </Button>

                    <Button
                        disabled={disabled || !selectedProject}
                        onClick={onSave}
                    >
                        {t('Save')}
                    </Button>
                </Box>
            </BottomActions>
        </>
    );
};

ProjectsSection.propTypes = {
    contactId: PropTypes.string,
    disabled: PropTypes.bool,
    fetchProjects: PropTypes.func,
    isFetching: PropTypes.bool,
    onCancelSelection: PropTypes.func,
    onSave: PropTypes.func,
    projects: PropTypes.array,
    selectedProject: PropTypes.object,
    setSelectedProject: PropTypes.func,
};

export default ProjectsSection;
