import i18next from 'i18next';
import get from 'lodash/get';

import { saveFinancierInstallerDocument } from 'common/api/v1/sunpay';
import { showReponseErrorsAsAlert } from 'common/utils/helpers';
import showToast from 'common/utils/showToast';

import {
    UPLOAD_DOCUMENT,
    UPLOAD_DOCUMENT_FAILURE,
    UPLOAD_DOCUMENT_SUCCESS,
} from '../actionTypes';
import { alliancesActions } from '../reducer';
import * as selectors from '../selectors';

export default (documentId, file, onSuccess) => (dispatch, getState) => {
    const state = getState();
    const documents = get(
        selectors.getFinancierInstallerDocumentsModel(state),
        'data.financier_installer_document',
        []
    );

    const index = documents
        .map((creditDocument) => creditDocument.id)
        .indexOf(documentId);

    dispatch(alliancesActions[UPLOAD_DOCUMENT]());

    saveFinancierInstallerDocument(documentId, { file })
        .then((response) => {
            onSuccess();
            showToast({
                body: i18next.t('The file was uploaded successfully'),
            });

            dispatch(
                alliancesActions[UPLOAD_DOCUMENT_SUCCESS]({
                    file,
                    data: response.data.data,
                    index,
                })
            );
        })
        .catch((error) => {
            dispatch(
                alliancesActions[UPLOAD_DOCUMENT_FAILURE](
                    error?.response?.data?.errors
                )
            );
            showReponseErrorsAsAlert(dispatch, error?.response);
        });
};
