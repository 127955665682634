import React, { useContext, useEffect, useState } from 'react';

import { get, isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { createStructuredSelector } from 'reselect';
import { Grid } from 'sunwise-ui';

import { CB_PORTAL_SECTIONS } from 'common/constants';
import { PERMISSION_LIST } from 'common/constants/permissionsV2';
import withChargebee from 'common/hocs/withChargebee';
import withPermissions from 'common/hocs/withPermissions';
import * as subscriptionSocket from 'common/sockets/subscription';
import { LoadingContext } from 'common/utils/contexts';
import parseQueryString from 'common/utils/parseQueryString';

import * as profileActions from '../profile/actions';
import * as profileSelectors from '../profile/selectors';

import * as actions from './actions';
import BillingInformation from './components/BillingInformation';
import CurrentPlan from './components/CurrentPlan';
import ProjectAddons from './components/ProjectAddons';
import { getCallbacks, handleOpenPortal, handleSocketEvents } from './helpers';
import * as selectors from './selectors';

const Container = ({
    billingInformation,
    canModify,
    cbInstance,
    connectSocket,
    disconnectSocket,
    fetchBillingInformation,
    fetchPaymentSources,
    fetchSubscription,
    fetchUserSettings,
    history,
    initialize,
    isFetchingPayInvoices,
    location,
    payInvoices,
    payInvoicesErrors,
    paymentSources,
    socketHandler,
    subscription,
    userSettings,
}) => {
    const { t } = useTranslation();
    const loadingContext = useContext(LoadingContext);
    const [event, setEvent] = useState(null);
    const { search: locationSearch = '' } = location;
    const { open_section: openSection = null } =
        parseQueryString(locationSearch);

    useEffect(() => {
        initialize();
    }, []);

    useEffect(() => {
        if (isEmpty(loadingContext)) return;
        if (isFetchingPayInvoices)
            loadingContext.openLoading(
                t('Wait while the operation is performed').concat('...')
            );
        else loadingContext.closeLoading();
    }, [isFetchingPayInvoices]);

    useEffect(() => {
        const { company } = userSettings;
        if (company?.id) connectSocket(company?.id);
        return () => {
            disconnectSocket();
        };
    }, [userSettings]);

    useEffect(() => {
        setEvent(socketHandler);
    }, [socketHandler]);

    useEffect(() => {
        handleSocketEvents(
            cbInstance,
            event,
            fetchBillingInformation,
            fetchPaymentSources,
            fetchSubscription,
            fetchUserSettings,
            setEvent
        );
    }, [cbInstance, event]);

    useEffect(() => {
        if (
            openSection &&
            Object.values(CB_PORTAL_SECTIONS).includes(openSection)
        )
            handlePortal(openSection);
    }, [cbInstance, openSection, subscription]);

    const resetLocation = () =>
        history.push({
            pathname: location.pathname,
            search: '',
        });

    const callbacks = getCallbacks(
        fetchSubscription,
        fetchUserSettings,
        resetLocation
    );

    const handlePortal = (sectionType, id = null) => {
        let subscriptionId = get(subscription, 'subscription_id', null);
        if (id) subscriptionId = id;
        return handleOpenPortal(
            callbacks,
            cbInstance,
            sectionType,
            subscriptionId
        );
    };

    return (
        <Grid container mb={2}>
            <Grid item xs={18} md={6}>
                <CurrentPlan
                    canModify={canModify}
                    handlePortal={handlePortal}
                    payInvoices={payInvoices}
                    payInvoicesErrors={payInvoicesErrors}
                    paymentSources={paymentSources}
                    subscription={subscription}
                />
            </Grid>
            <Grid item xs={18} md={6}>
                <ProjectAddons
                    canModify={canModify}
                    handlePortal={handlePortal}
                    subscription={subscription}
                />
            </Grid>
            <Grid item xs={18} md={6}>
                <BillingInformation
                    billingInformation={billingInformation}
                    canModify={canModify}
                    handlePortal={handlePortal}
                    paymentSources={paymentSources}
                    userSettings={userSettings}
                />
            </Grid>
        </Grid>
    );
};

const mapStateToProps = createStructuredSelector({
    billingInformation: selectors.getBillingInformationData,
    isFetchingPayInvoices: selectors.getIsFetchingPayInvoices,
    payInvoicesErrors: selectors.getPayInvoicesErrors,
    paymentSources: selectors.getPaymentSourcesData,
    socketHandler: selectors.getSocketHandler,
    subscription: selectors.getSubscriptionData,
    userSettings: profileSelectors.getDataFetch,
});

const mapDispatchToProps = (dispatch) => ({
    disconnectSocket: () => dispatch(subscriptionSocket.disconnect()),
    connectSocket: (companyId) =>
        dispatch(subscriptionSocket.connect(companyId)),
    fetchBillingInformation: () => dispatch(actions.fetchBillingInformation()),
    fetchPaymentSources: () => dispatch(actions.fetchPaymentSources()),
    fetchSubscription: () => dispatch(actions.fetchSubscription()),
    fetchUserSettings: () => dispatch(profileActions.fetchProfileData()),
    initialize: () => dispatch(actions.initialize()),
    payInvoices: (last4) => dispatch(actions.preparePayment(last4)),
});

Container.propTypes = {
    billingInformation: PropTypes.object,
    canModify: PropTypes.bool,
    cbInstance: PropTypes.object,
    connectSocket: PropTypes.func,
    disconnectSocket: PropTypes.func,
    fetchBillingInformation: PropTypes.func,
    fetchPaymentSources: PropTypes.func,
    fetchSubscription: PropTypes.func,
    fetchUserSettings: PropTypes.func,
    history: PropTypes.object,
    initialize: PropTypes.func,
    isFetchingPayInvoices: PropTypes.bool,
    location: PropTypes.object,
    payInvoices: PropTypes.func,
    payInvoicesErrors: PropTypes.array,
    paymentSources: PropTypes.array,
    socketHandler: PropTypes.string,
    subscription: PropTypes.object,
    userSettings: PropTypes.object,
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withPermissions(PERMISSION_LIST.SUBSCRIPTION_PERMISSION),
    withChargebee()
)(Container);
