import { getRoleList } from 'common/api/v1/companyRoles';

import {
    FETCH_ROLES,
    FETCH_ROLES_FAILURE,
    FETCH_ROLES_SUCCESS,
} from '../actionTypes';
import { companyUsersActions } from '../reducer';

export default () => (dispatch) => {
    dispatch(companyUsersActions[FETCH_ROLES]());

    getRoleList()
        .then((response) => {
            dispatch(companyUsersActions[FETCH_ROLES_SUCCESS](response.data));
        })
        .catch((error) =>
            dispatch(
                companyUsersActions[FETCH_ROLES_FAILURE](
                    error?.response?.data?.errors
                )
            )
        );
};
