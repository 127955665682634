import { deleteNotification } from 'common/api/v1/notifications';

import {
    DELETE_NOTIFICATION,
    DELETE_NOTIFICATION_FAILURE,
    DELETE_NOTIFICATION_SUCCESS,
} from '../actionTypes';
import { notificationsActions } from '../reducer';

import fetchNavNotifications from './fetchNavNotifications';
import fetchNotifications from './fetchNotifications';

export default (filterData, id) => (dispatch) => {
    dispatch(notificationsActions[DELETE_NOTIFICATION]());

    deleteNotification(id)
        .then(() => {
            dispatch(notificationsActions[DELETE_NOTIFICATION_SUCCESS]());
            dispatch(fetchNavNotifications());
            dispatch(fetchNotifications(filterData));
        })
        .catch((error) => {
            dispatch(
                notificationsActions[DELETE_NOTIFICATION_FAILURE](
                    error?.response?.data?.errors
                )
            );
        });
};
