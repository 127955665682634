import { createSelector } from 'reselect';

import { NAME } from './constants';

export const getModel = (state) => state[NAME];

export const getInitialValues = createSelector(
    getModel,
    (model) => model.initialValues
);

// ACCESSORY BRANDS
export const getAccessoryBrands = createSelector(
    getModel,
    (model) => model.accessoryBrands
);

export const getIsFetchingAccessoryBrands = createSelector(
    getAccessoryBrands,
    (value) => value.isFetching
);

export const getAccessoryBrandsData = createSelector(
    getAccessoryBrands,
    (value) => value.data || []
);

export const getAccessoryBrandsErrors = createSelector(
    getAccessoryBrands,
    (value) => value.errors
);

// BATTERY BRANDS
export const getBatteryBrands = createSelector(
    getModel,
    (model) => model.batteryBrands
);

export const getIsFetchingBatteryBrands = createSelector(
    getBatteryBrands,
    (value) => value.isFetching
);

export const getBatteryBrandsData = createSelector(
    getBatteryBrands,
    (value) => value.data || []
);

export const getBatteryBrandsErrors = createSelector(
    getBatteryBrands,
    (value) => value.errors
);

// BATTERY PRODUCTS
export const getBatteryProducts = createSelector(
    getModel,
    (model) => model.batteryProducts
);

export const getIsFetchingBatteryProducts = createSelector(
    getBatteryProducts,
    (value) => value.isFetching
);

export const getBatteryProductsData = createSelector(
    getBatteryProducts,
    (value) => value.data || []
);

export const getBatteryProductsErrors = createSelector(
    getBatteryProducts,
    (value) => value.errors
);

// COMPANY AND DETAILS
export const getOnboarding = createSelector(
    getModel,
    (model) => model.onboarding
);

export const getIsFetchingOnboarding = createSelector(
    getOnboarding,
    (value) => value.isFetching
);

export const getOnboardingData = createSelector(
    getOnboarding,
    (value) => value.data || {}
);

export const getOnboardingSection = createSelector(
    getOnboardingData,
    (value) => value.section
);

export const getOnboardingErrors = createSelector(
    getOnboarding,
    (value) => value.errors
);

// DOLLAR PRICE
export const getDollarPrice = createSelector(
    getModel,
    (model) => model.fetchDollarPrice
);

export const getIsFetchingDollarPrice = createSelector(
    getDollarPrice,
    (value) => value.isFetching
);

export const getDollarPriceData = createSelector(
    getDollarPrice,
    (value) => value.data || []
);

export const getDollarPriceErrors = createSelector(
    getDollarPrice,
    (value) => value.errors
);

// INVERTER BRANDS
export const getInverterBrands = createSelector(
    getModel,
    (model) => model.inverterBrands
);

export const getIsFetchingInverterBrands = createSelector(
    getInverterBrands,
    (value) => value.isFetching
);

export const getInverterBrandsData = createSelector(
    getInverterBrands,
    (value) => value.data || []
);

export const getInverterBrandsErrors = createSelector(
    getInverterBrands,
    (value) => value.errors
);

// INVERTER PRODUCTS
export const getInverterProducts = createSelector(
    getModel,
    (model) => model.inverterProducts
);

export const getIsFetchingInverterProducts = createSelector(
    getInverterProducts,
    (value) => value.isFetching
);

export const getInverterProductsData = createSelector(
    getInverterProducts,
    (value) => value.data || []
);

export const getInverterProductsErrors = createSelector(
    getInverterProducts,
    (value) => value.errors
);

// MEMBER ROLES
export const getMemberRoles = createSelector(
    getModel,
    (model) => model.memberRoles
);

export const getIsFetchingMemberRoles = createSelector(
    getMemberRoles,
    (value) => value.isFetching
);

export const getMemberRolesData = createSelector(
    getMemberRoles,
    (value) => value.data || []
);

export const getMemberRolesErrors = createSelector(
    getMemberRoles,
    (value) => value.errors
);

// PANEL BRANDS
export const getPanelBrands = createSelector(
    getModel,
    (model) => model.panelBrands
);

export const getIsFetchingPanelBrands = createSelector(
    getPanelBrands,
    (value) => value.isFetching
);

export const getPanelBrandsData = createSelector(
    getPanelBrands,
    (value) => value.data || []
);

export const getPanelBrandsErrors = createSelector(
    getPanelBrands,
    (value) => value.errors
);

// PANEL PRODUCTS
export const getPanelProducts = createSelector(
    getModel,
    (model) => model.panelProducts
);

export const getIsFetchingPanelProducts = createSelector(
    getPanelProducts,
    (value) => value.isFetching
);

export const getPanelProductsData = createSelector(
    getPanelProducts,
    (value) => value.data || []
);

export const getPanelProductsErrors = createSelector(
    getPanelProducts,
    (value) => value.errors
);

// COMPLETED STEPS
export const getCompletedSteps = createSelector(
    getModel,
    (model) => model.completed || {}
);

// STEP
export const getCurentStep = createSelector(getModel, (model) => model.step);

// MODAL
export const getIsOpenModal = createSelector(
    getModel,
    (model) => model.isOpenModal
);

// SAVE COMPANY
export const getSaveCompany = createSelector(
    getModel,
    (model) => model.saveCompany
);

export const getIsSavingCompany = createSelector(
    getSaveCompany,
    (value) => value.isSaving
);

export const getSaveCompanyErrors = createSelector(
    getSaveCompany,
    (value) => value.errors
);

// SAVE DETAILS
export const getSaveDetails = createSelector(
    getModel,
    (model) => model.saveDetails
);

export const getIsSavingDetails = createSelector(
    getSaveDetails,
    (value) => value.isSaving
);

export const getSaveDetailsErrors = createSelector(
    getSaveDetails,
    (value) => value.errors
);

// FINISH ONBOARDING
export const getFinishOnboarding = createSelector(
    getModel,
    (model) => model.finishOnboarding
);

export const getIsSavingOnboarding = createSelector(
    getFinishOnboarding,
    (value) => value.isSaving
);

export const getFinishOnboardingErrors = createSelector(
    getFinishOnboarding,
    (value) => value.errors
);

export const getSaveInvertersValidationErors = createSelector(
    getModel,
    (model) => model.saveInvertersValidationErors
);

export const getSavePanelsValidationErrors = createSelector(
    getModel,
    (model) => model.savePanelsValidationErrors
);

// SAVE ERRORS
export const getSaveErrors = createSelector(
    getSaveCompanyErrors,
    getSaveDetailsErrors,
    getFinishOnboardingErrors,
    getSaveInvertersValidationErors,
    getSavePanelsValidationErrors,
    (
        companyErrors,
        detailsErrors,
        finishOnboardingErrors,
        invertersValidationErors,
        panelsValidationErrors
    ) => ({
        company: companyErrors,
        details: detailsErrors,
        inverters: invertersValidationErors,
        panels: panelsValidationErrors,
        ...finishOnboardingErrors,
    })
);

// VALIDATE EMAIL
export const getValidateEmail = createSelector(
    getModel,
    (model) => model.validateEmail
);

export const getIsValidatingEmail = createSelector(
    getValidateEmail,
    (value) => value.isValidating
);
