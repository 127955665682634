import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box } from 'sunwise-ui';

import CheckAccess from 'common/components/CheckAccess';
import { PERMISSION_LIST } from 'common/constants/permissionsV2';
import { useBreakpoint } from 'common/hooks';
import { getHasPaymentDue } from 'common/utils/helpers/session';

import { ReactComponent as IcoFinancial } from 'resources/icoFinancial.svg';
import { ReactComponent as IcoAlliance } from 'resources/iconAlliance.svg';
import { ReactComponent as IcoPlans } from 'resources/icoPlans.svg';
import { ReactComponent as IcoProducts } from 'resources/icoProducts.svg';
import { ReactComponent as IcoTemplates } from 'resources/icoTemplates.svg';

import { handleOnMouseHover } from './helpers';
import StyledListItemButton from './StyledListItemButton';
import StyledStyledListItemText from './StyledStyledListItemText';

const SettingsOptions = ({
    handleClose,
    handleDrawerToggle,
    match,
    mobileOpen,
    redirect,
}) => {
    const { t } = useTranslation();
    const hasPaymentDue = getHasPaymentDue();
    const breakpoint = useBreakpoint();

    const onMouseLeave = (e) =>
        handleOnMouseHover({ e, breakpoint, handleClose });

    return (
        <Box onMouseLeave={onMouseLeave}>
            <CheckAccess
                requiredPermissions={[
                    PERMISSION_LIST.ACCESORIES_PERMISSION,
                    PERMISSION_LIST.ADITIONALS_PERMISSION,
                    PERMISSION_LIST.ENERGY_BACKUP_PERMISSION,
                    PERMISSION_LIST.INVERTERS_PERMISSION,
                    PERMISSION_LIST.PANELS_PERMISSION,
                    PERMISSION_LIST.STRUCTURES_PERMISSION,
                    PERMISSION_LIST.WORKFORCE_PERMISSION,
                ]}
            >
                <StyledListItemButton
                    className="__userguiding_products_module_navbar_item"
                    disabled={hasPaymentDue}
                    onClick={() => {
                        redirect('/products');
                        handleClose();
                        if (mobileOpen) handleDrawerToggle();
                    }}
                    selected={/^\/products/i.test(match.path)}
                    variant="horizontal"
                >
                    <IcoProducts />
                    <StyledStyledListItemText
                        primary={t('Product', { count: 2 })}
                    />
                </StyledListItemButton>
            </CheckAccess>

            <CheckAccess
                requiredPermissions={[PERMISSION_LIST.PLANS_PERMISSION]}
            >
                <StyledListItemButton
                    disabled={hasPaymentDue}
                    onClick={() => {
                        redirect('/plan-configuration');
                        handleClose();
                        if (mobileOpen) handleDrawerToggle();
                    }}
                    selected={'/plan-configuration' === match.path}
                    variant="horizontal"
                >
                    <IcoPlans />
                    <StyledStyledListItemText
                        primary={t('Plan', { count: 2 })}
                    />
                </StyledListItemButton>
            </CheckAccess>

            <CheckAccess
                requiredPermissions={[
                    PERMISSION_LIST.PROPOSAL_TEMPLATES_PERMISSION,
                    PERMISSION_LIST.REPORT_TEMPLATES_PERMISSION,
                    PERMISSION_LIST.SMART_DOCUMENT_TEMPLATES_PERMISSION,
                    PERMISSION_LIST.SUMMARY_TEMPLATES_PERMISSION,
                ]}
            >
                <StyledListItemButton
                    disabled={hasPaymentDue}
                    onClick={() => {
                        redirect('/template-library');
                        handleClose();
                        if (mobileOpen) handleDrawerToggle();
                    }}
                    selected={
                        /^\/template-library/i.test(match.path) ||
                        /^\/edit-template-pro/i.test(match.path)
                    }
                    variant="horizontal"
                >
                    <IcoTemplates />
                    <StyledStyledListItemText
                        primary={t('Template', { count: 2 })}
                    />
                </StyledListItemButton>
            </CheckAccess>

            <CheckAccess
                requiredPermissions={[PERMISSION_LIST.ALLIANCES_PERMISSION]}
            >
                <StyledListItemButton
                    disabled={hasPaymentDue}
                    onClick={() => {
                        redirect('/alliances');
                        handleClose();
                        if (mobileOpen) handleDrawerToggle();
                    }}
                    selected={'/alliances' === match.path}
                    variant="horizontal"
                >
                    <IcoAlliance />
                    <StyledStyledListItemText
                        primary={t('Alliance', {
                            count: 2,
                        })}
                    />
                </StyledListItemButton>
            </CheckAccess>

            <CheckAccess
                requiredPermissions={[
                    PERMISSION_LIST.FINANCIAL_PRODUCTS_PERMISSION,
                ]}
            >
                <StyledListItemButton
                    disabled={hasPaymentDue}
                    onClick={() => {
                        redirect('/financial-products');
                        handleClose();
                        if (mobileOpen) handleDrawerToggle();
                    }}
                    selected={'/financial-products' === match.path}
                    variant="horizontal"
                >
                    <IcoFinancial />
                    <StyledStyledListItemText
                        primary={t('Financial entity', {
                            count: 2,
                        })}
                    />
                </StyledListItemButton>
            </CheckAccess>
        </Box>
    );
};

SettingsOptions.propTypes = {
    handleClose: PropTypes.func,
    handleDrawerToggle: PropTypes.func,
    match: PropTypes.object,
    mobileOpen: PropTypes.bool,
    redirect: PropTypes.func,
};

export default SettingsOptions;
