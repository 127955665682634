import { getItems } from 'common/api/v1/companiesCurrencies';

import {
    FETCH_CURRENCIES,
    FETCH_CURRENCIES_FAILURE,
    FETCH_CURRENCIES_SUCCESS,
} from '../actionTypes';
import { ratesActions } from '../reducer';

export default () => (dispatch) => {
    dispatch(ratesActions[FETCH_CURRENCIES]());
    getItems()
        .then((response) =>
            dispatch(
                ratesActions[FETCH_CURRENCIES_SUCCESS](
                    response.data.data.map((item) => item.currency)
                )
            )
        )
        .catch((error) =>
            dispatch(ratesActions[FETCH_CURRENCIES_FAILURE](error))
        );
};
