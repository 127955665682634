import i18next from 'i18next';

import alerts from 'common/modules/alerts';

import { getDeleteMessage } from '../helpers';

import deleteCommercialOffer from './deleteCommercialOffer';

export default (
        hasSummaryLinked,
        isArchivedFilter,
        offerId,
        offerName,
        projectId
    ) =>
    (dispatch) => {
        const message = getDeleteMessage(hasSummaryLinked, offerName);
        const dataAlert = {
            confirmText: i18next.t('Delete'),
            messages: [message],
            onSuccess: () =>
                dispatch(
                    deleteCommercialOffer(isArchivedFilter, offerId, projectId)
                ),
            title: i18next.t('Delete proposal'),
            type: alerts.ALERT_TYPE_CONFIRM,
            variant: 'warning',
        };

        dispatch(alerts.actions.show(dataAlert));
    };
