import React from 'react';

import { useTheme } from '@mui/material';
import { push } from 'connected-react-router';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { createStructuredSelector } from 'reselect';

import AccordionCard from 'common/components/accordions/AccordionCard';
import { IconEnergyBackup } from 'common/components/icons';
import { PERMISSION_LIST } from 'common/constants/permissionsV2';
import withPermissions from 'common/hocs/withPermissions';

import * as energyBackupSetupSelectors from '../energyBackupSetup/selectors';
import * as proposalGeneratorEnergyStorageTableSelectors from '../proposalGeneratorEnergyStorageTable/selectors';

import * as actions from './actions';
import CardForm from './components/CardForm';
import * as selectors from './selectors';

const Container = ({
    canModify,
    createIsLoading,
    currencyLocale,
    deleteStorageOffer,
    handleCreate,
    isFetchingOfferProducts,
    isLoading,
    isLoadingOffer,
    isLocked = false,
    isSaving,
    match,
    offerValueData,
    proposalGeneratorEnergyStorageTableFormValues,
    redirect,
    warnings,
}) => {
    const theme = useTheme();
    const { t } = useTranslation();

    const isLoadingForm =
        isFetchingOfferProducts ||
        isLoading ||
        isSaving ||
        createIsLoading ||
        isLoadingOffer;

    return (
        <AccordionCard
            cardId="proposal_generator_energy_backup_card"
            classNameCard="__userguiding_proposal_generator_energy_backup_setup"
            defaultExpanded
            isLoading={isLoadingForm}
            svgIcon={<IconEnergyBackup color={theme.palette.primary.main} />}
            title={t('Storage system')}
        >
            <CardForm
                canModify={canModify}
                currencyLocale={currencyLocale}
                deleteStorageOffer={deleteStorageOffer}
                handleCreate={handleCreate}
                isLocked={isLoadingForm || isLocked}
                offerEnergyBackup={
                    proposalGeneratorEnergyStorageTableFormValues?.items
                }
                offerValueData={offerValueData}
                proposalId={match.params.uid}
                redirect={redirect}
                warnings={warnings}
            />
        </AccordionCard>
    );
};

const mapStateToProps = createStructuredSelector({
    createIsLoading: selectors.getCreateIsLoading,
    isLoadingOffer: energyBackupSetupSelectors.getOfferValuesIsLoading,
    offerValueData: energyBackupSetupSelectors.getOfferValuesData,
    proposalGeneratorEnergyStorageTableFormValues:
        proposalGeneratorEnergyStorageTableSelectors.getInitialValues,
    warnings: energyBackupSetupSelectors.getOfferValuesWarnings,
});

const mapDispatchToProps = (dispatch) => ({
    deleteStorageOffer: (commercialOfferId) =>
        dispatch(actions.deleteStorageOffer(commercialOfferId)),
    handleCreate: (commercialOfferId) =>
        dispatch(actions.createOffer(commercialOfferId)),
    redirect: (url) => dispatch(push(url)),
});

Container.propTypes = {
    canModify: PropTypes.bool,
    createIsLoading: PropTypes.bool,
    currencyLocale: PropTypes.string,
    deleteStorageOffer: PropTypes.func,
    handleCreate: PropTypes.func,
    isFetchingOfferProducts: PropTypes.bool,
    isLoading: PropTypes.bool,
    isLoadingOffer: PropTypes.bool,
    isLocked: PropTypes.bool,
    isSaving: PropTypes.bool,
    match: PropTypes.object,
    offerValueData: PropTypes.object,
    proposalGeneratorEnergyStorageTableFormValues: PropTypes.object,
    redirect: PropTypes.func,
    warnings: PropTypes.array,
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withPermissions(PERMISSION_LIST.PROPOSAL_ENERGY_BACKUP_PERMISSION),
    withRouter
)(Container);
