import { createSelector } from 'reselect';

import { formatDate, parseDate } from 'common/utils/dates';
import { getDateFormatByLocale } from 'common/utils/helpers/multiregion';

import { NAME } from './constants';

const getModel = (state) => state[NAME];

export const getIsOpenModal = createSelector(
    getModel,
    (model) => model.isOpenModal
);

export const getInitialValues = createSelector(
    getModel,
    (model) => model.initialValues
);

export const getProvidersModel = createSelector(
    getModel,
    (model) => model.providers
);

export const getProviders = createSelector(
    getProvidersModel,
    (model) => model.data || []
);

export const getIsFetchingProviders = createSelector(
    getProvidersModel,
    (model) => model.isFetching
);

export const getProvidersDictionary = createSelector(
    getProviders,
    (providers) =>
        providers.reduce((acc, provider) => {
            acc[provider.id] = provider;
            return acc;
        }, {}) || {}
);

export const getProvidersForSelect = createSelector(getProviders, (providers) =>
    providers
        .filter((provider) => provider?.active)
        .map((provider) => ({ label: provider.name, value: provider.id }))
);

export const getProvidersForSelect2 = createSelector(
    getProvidersForSelect,
    (options) => [{ label: '', options }]
);

export const getCredentialsModel = createSelector(
    getModel,
    (model) => model.credentials
);

export const getCredentialsData = createSelector(
    getCredentialsModel,
    (model) => {
        const dateFormat = getDateFormatByLocale();
        return model.data?.map((item) => ({
            ...item,
            updated_at: formatDate(
                parseDate(item.updated_at, 'yyyy-MM-dd'),
                dateFormat
            ),
        }));
    }
);

export const getIsFetchingCredentials = createSelector(
    getCredentialsModel,
    (model) => model.isFetching
);

export const getDeleteCredentialModel = createSelector(
    getModel,
    (model) => model.deleteCredential
);

export const getIsDeletingCredential = createSelector(
    getDeleteCredentialModel,
    (model) => model.isDeleting
);

export const getSaveCredentialModel = createSelector(
    getModel,
    (model) => model.saveCredential
);

export const getIsSavingCredential = createSelector(
    getSaveCredentialModel,
    (model) => model.isSaving
);
