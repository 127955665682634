import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Alert, Box } from 'sunwise-ui';

import { STATUS, FILE_COMPLEX_TYPE } from 'common/constants';

const Warning = ({ allianceStatus, creditType, session }) => {
    const { t } = useTranslation();

    if (allianceStatus === STATUS.CLOSED_STATUS.key) {
        return null;
    }

    switch (creditType) {
        case FILE_COMPLEX_TYPE:
            return (
                <Box mb={3}>
                    <Alert severity="warning">
                        {t(
                            'To continue with the process, register and request the credit directly on the portal'
                        )}{' '}
                        <a href="www.redgirasol.com" target="_blank">
                            www.redgirasol.com
                        </a>
                    </Alert>
                </Box>
            );
        default:
            return (
                <Box mb={3}>
                    <Alert severity="warning">
                        {t(
                            'Your account does not have the alliance enabled yet, please finish the alliance process'
                        )}{' '}
                        {session?.company_group?.name === 'Propietario' && (
                            <Link to="/alliances">
                                {t('Click here to see the alliances')}
                            </Link>
                        )}
                    </Alert>
                </Box>
            );
    }
};

Warning.propTypes = {
    allianceStatus: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    creditType: PropTypes.number,
    session: PropTypes.object,
};

export default Warning;
