import i18next from 'i18next';

import { getItems } from 'common/api/v1/funnelProposals';
import alerts from 'common/modules/alerts';
import { formatDate, parseDate } from 'common/utils/dates';

import {
    FETCH_ITEMS,
    FETCH_ITEMS_FAILURE,
    FETCH_ITEMS_SUCCESS,
    SET_STATUS_DICTIONARY,
} from '../actionTypes';
import { PER_PAGE } from '../constants';
import { actions } from '../reducer';

const NEXT_PAGE = 2;

export default (filterQuery) => (dispatch) => {
    dispatch(actions[FETCH_ITEMS]());

    const newFilterQuery = {
        search: filterQuery.search,
        user: filterQuery.user,
        financier: filterQuery.financier,
        range: `${formatDate(
            parseDate(filterQuery.initial_date, 'dd/MM/yyyy'),
            'yyyy-MM-dd'
        )},${formatDate(
            parseDate(filterQuery.final_date, 'dd/MM/yyyy'),
            'yyyy-MM-dd'
        )}`,
    };

    getItems({ filterQuery: newFilterQuery, per_page: PER_PAGE })
        .then((response) => {
            let index = 0;
            let statusDictionary = {};
            const data = response.data.data.reduce(
                (accColumns, { id, name, proposals, total_rows }) => {
                    const nextPage = total_rows > PER_PAGE ? NEXT_PAGE : null;

                    const value = {
                        ...accColumns,
                        [id]: {
                            id,
                            index,
                            name,
                            nextPage,
                            projects: proposals,
                            total_rows,
                        },
                    };
                    statusDictionary = { ...statusDictionary, [name]: id };
                    index++;
                    return value;
                },
                {}
            );

            dispatch(actions[SET_STATUS_DICTIONARY](statusDictionary));

            dispatch(
                actions[FETCH_ITEMS_SUCCESS]({
                    funnelProjects: data,
                    filterQuery,
                })
            );
        })
        .catch((error) => {
            if (error.response.data && error.response.data?.errors?.length) {
                const dataAlert = {
                    title: i18next.t('Message'),
                    messages: error.response.data.errors,
                    type: alerts.ALERT_TYPE_ALERT,
                    variant: 'warning',
                };

                dispatch(alerts.actions.show(dataAlert));
            }
            dispatch(actions[FETCH_ITEMS_FAILURE](error));
        });
};
