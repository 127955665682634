import React, { useState, useEffect } from 'react';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import i18next from 'i18next';
import isNil from 'lodash/isNil';
import PropTypes from 'prop-types';
import {
    Button,
    ListItemIcon,
    ListItemText,
    Menu,
    MenuItem,
    Typography,
} from 'sunwise-ui';

const DropDownMenu = ({
    defaultLabel,
    defaultValue,
    disabled,
    fullWidth,
    handleSelected,
    id,
    itemsToSelect,
    itemsToSelectValidationEnabled,
    keyName,
    startIconButton,
    sxButton,
    visible,
}) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [selected, setSelected] = useState({});

    useEffect(() => {
        if (defaultValue !== '' && itemsToSelect.length) {
            const itemSelected = itemsToSelect.find(
                (item) => item.value == defaultValue
            );

            if (itemSelected) setSelected(itemSelected);
        }
    }, []);

    useEffect(() => {
        if (
            itemsToSelectValidationEnabled &&
            !isNil(selected.value) &&
            itemsToSelect
        ) {
            const selectedIndex = itemsToSelect.findIndex(
                (item) => item.value === selected.value
            );
            if (selectedIndex >= 0) return;
            setSelected(
                itemsToSelect.find((item) => item.value === defaultValue) ?? {}
            );
        }
    }, [itemsToSelectValidationEnabled, selected, itemsToSelect, defaultValue]);

    const handleClick = (event) => setAnchorEl(event.currentTarget);
    const handleClose = () => setAnchorEl(null);
    const handleMenuItemClick = (index, item) => {
        setSelectedIndex(index);
        setAnchorEl(null);
        if (item.value !== selected.value) {
            setSelected(item);
            handleSelected(item);
        }
    };
    const label = selected && selected.label ? selected.label : defaultLabel;
    const open = Boolean(anchorEl);

    if (!visible) return null;
    return (
        <>
            <Button
                color="secondary"
                disabled={disabled || itemsToSelect.length === 0}
                endIcon={<KeyboardArrowDownIcon />}
                fullWidth={fullWidth}
                id={id}
                onClick={handleClick}
                startIcon={startIconButton}
                sx={sxButton}
                variant="outlined"
            >
                <Typography
                    component="span"
                    fontWeight="bold"
                    noWrap
                    sx={{
                        overflow: 'hidden',
                        width: 'calc(100% - 16px)',
                        textAlign: 'left',
                        textOverflow: 'ellipsis',
                    }}
                    variant="body2"
                >
                    {label}
                </Typography>
            </Button>

            <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
                {itemsToSelect?.map((item, index) => {
                    const {
                        disabled,
                        label,
                        visible = true,
                        icon,
                        iconPosition,
                    } = item;
                    return visible ? (
                        <MenuItem
                            disabled={disabled}
                            key={`${keyName}-dropdown-item-${index}`}
                            selected={index === selectedIndex}
                            onClick={() => handleMenuItemClick(index, item)}
                        >
                            {icon && iconPosition === 'left' && (
                                <ListItemIcon
                                    sx={{
                                        minWidth: '24px !important',
                                        mr: 1,
                                    }}
                                >
                                    {icon}
                                </ListItemIcon>
                            )}
                            <ListItemText primary={label} />
                            {icon && iconPosition === 'right' && (
                                <ListItemIcon
                                    sx={{
                                        minWidth: '24px !important',
                                        ml: 'auto',
                                    }}
                                >
                                    {icon}
                                </ListItemIcon>
                            )}
                        </MenuItem>
                    ) : null;
                })}
            </Menu>
        </>
    );
};

DropDownMenu.defaultProps = {
    defaultLabel: i18next.t('All text', { context: 'male', count: 2 }),
    disabled: false,
    itemsToSelect: [],
    keyName: '',
    startIconButton: null,
    visible: true,
};

DropDownMenu.propTypes = {
    defaultLabel: PropTypes.string,
    defaultValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    disabled: PropTypes.bool,
    fullWidth: PropTypes.bool,
    handleSelected: PropTypes.func,
    id: PropTypes.string,
    itemsToSelect: PropTypes.array,
    itemsToSelectValidationEnabled: PropTypes.bool,
    keyName: PropTypes.string,
    startIconButton: PropTypes.object,
    sxButton: PropTypes.object,
    visible: PropTypes.bool,
};

export default DropDownMenu;
