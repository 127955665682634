import { getTemplatesCatalog } from 'common/api/v1/templates';

import {
    FETCH_TEMPLATE_CATALOGS,
    FETCH_TEMPLATE_CATALOGS_FAILURE,
    FETCH_TEMPLATE_CATALOGS_SUCCESS,
} from '../actionTypes';
import { financialProductActions } from '../reducer';

export default () => (dispatch) => {
    dispatch(financialProductActions[FETCH_TEMPLATE_CATALOGS]());

    return getTemplatesCatalog()
        .then((response) => {
            dispatch(
                financialProductActions[FETCH_TEMPLATE_CATALOGS_SUCCESS](
                    response.data
                )
            );
        })
        .catch((error) =>
            dispatch(
                financialProductActions[FETCH_TEMPLATE_CATALOGS_FAILURE](
                    error?.response?.data?.errors
                )
            )
        );
};
