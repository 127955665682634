import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Alert } from 'sunwise-ui';

import { formatDate, parseDate } from 'common/utils/dates';
import { getDateFormatByLocale } from 'common/utils/helpers/multiregion';

const IncompleteCupsWarning = ({ lastDate }) => {
    const { t } = useTranslation();
    if (!lastDate) return null;

    const dateFormat = getDateFormatByLocale();
    const parsedDate = parseDate(lastDate, 'dd/MM/yyyy');
    const formattedDate = formatDate(parsedDate, dateFormat);

    return (
        <Alert severity="warning">
            {t(
                'The information returned from the SIPS appears to be incomplete. The last date of the information is {{lastDate}}. Please check and confirm that the information is correct',
                { lastDate: formattedDate }
            )}
        </Alert>
    );
};

IncompleteCupsWarning.propTypes = {
    lastDate: PropTypes.string,
};

export default IncompleteCupsWarning;
