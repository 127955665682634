import get from 'lodash/get';
import isArray from 'lodash/isArray';

import { uploadDatasheet, updateDatasheet } from 'common/api/v1/datasheets';
import showToast from 'common/utils/showToast';

import {
    UPLOAD_DATASHEET,
    UPLOAD_DATASHEET_FAILURE,
    UPLOAD_DATASHEET_SUCCESS,
} from '../actionTypes';
import { productDatasheetsActions } from '../reducer';

import fetchProductDatasheets from './fetchProductDatasheets';
import showForm from './showForm';

export default (values) => (dispatch) => {
    let newValues = {};
    newValues.id = values.id;
    newValues.name = values.name;

    if (isArray(values.archive)) {
        newValues.archive = values.archive && values.archive[0];
    }

    dispatch(productDatasheetsActions[UPLOAD_DATASHEET]());

    const save = values.id
        ? () => updateDatasheet(newValues, values.id)
        : () =>
              uploadDatasheet(newValues, values.productType, values.productId);

    save()
        .then((response) => {
            dispatch(
                productDatasheetsActions[UPLOAD_DATASHEET_SUCCESS](
                    response.data
                )
            );
            dispatch(showForm(false));
            dispatch(
                fetchProductDatasheets(values.productType, values.productId)
            );
            showToast();
        })
        .catch((error) =>
            dispatch(
                productDatasheetsActions[UPLOAD_DATASHEET_FAILURE](
                    get(error, 'response.data.errors', null)
                )
            )
        );
};
