import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { createStructuredSelector } from 'reselect';
import {
    Container as MaterialContainer,
    Grid,
    UiDialog as Dialog,
} from 'sunwise-ui';

import { HeaderContainer, TitleIcon } from 'common/components';
import { PERMISSION_LIST } from 'common/constants/permissionsV2';
import withPermissions from 'common/hocs/withPermissions';

import * as actions from './actions';
import { CardList, CardListDetail, ModalForm } from './components';
import * as selectors from './selectors';

const Container = ({
    canView,
    companiesCatalogs,
    handleClickCloseModal,
    isFetchingcompaniesCatalogs,
    isOpenModal,
    resetItem,
    type,
}) => {
    const { t } = useTranslation();
    const [dialogTitle, setDialogTitle] = useState(null);

    useEffect(() => {
        resetItem();
    }, []);

    return (
        <MaterialContainer maxWidth={false}>
            <HeaderContainer>
                <Grid item xs>
                    <TitleIcon fontWeight="bold" title={t('List settings')} />
                </Grid>
            </HeaderContainer>

            <Grid container>
                {canView && (
                    <Grid item xs={18} md={9}>
                        <CardList resetItem={resetItem} />
                    </Grid>
                )}

                <Grid item xs={18} md={9}>
                    <CardListDetail
                        companiesCatalogs={companiesCatalogs}
                        isFetching={isFetchingcompaniesCatalogs}
                        type={type}
                    />
                </Grid>
            </Grid>

            <Dialog
                onClose={() => handleClickCloseModal()}
                open={isOpenModal}
                size="xs"
                title={dialogTitle}
            >
                <ModalForm type={type} setDialogTitle={setDialogTitle} />
            </Dialog>
        </MaterialContainer>
    );
};

const mapStateToProps = createStructuredSelector({
    companiesCatalogs: selectors.getDataCompaniesCatalogs,
    isFetchingcompaniesCatalogs: selectors.getIsFetchingCompaniesCatalogs,
    isOpenModal: selectors.getIsOpenModal,
    keyboard: selectors.getCloseInEscape,
    type: selectors.getType,
});

const mapDispatchToProps = (dispatch) => ({
    handleClickCloseModal: () => dispatch(actions.toggleModal(false)),
    resetItem: () => dispatch(actions.resetItem()),
});

Container.propTypes = {
    canView: PropTypes.bool,
    companiesCatalogs: PropTypes.array,
    handleClickCloseModal: PropTypes.func,
    isFetchingcompaniesCatalogs: PropTypes.bool,
    isOpenModal: PropTypes.bool,
    resetItem: PropTypes.func,
    type: PropTypes.number,
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withPermissions(PERMISSION_LIST.LIST_SETTINGS_PERMISSION)
)(Container);
