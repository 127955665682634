import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { createStructuredSelector } from 'reselect';
import { UiDialog as Dialog } from 'sunwise-ui';

import * as actions from './actions';
import Form from './components/Form';
import * as selectors from './selectors';

const Container = ({
    initialize,
    isOpen,
    match,
    reset,
    resetForm,
    setIsOpen,
}) => {
    const { t } = useTranslation();
    const handleClose = () => setIsOpen(false);

    return (
        <Dialog
            onClose={handleClose}
            onEnter={() => {
                initialize({ contact: match.params.uid });
            }}
            onExited={() => {
                reset();
                resetForm();
            }}
            open={isOpen}
            size="sm"
            title={t('Import project')}
        >
            <Form onCancel={handleClose} />
        </Dialog>
    );
};

const mapStateToProps = createStructuredSelector({
    isOpen: selectors.getIsOpen,
});

const mapDispatchToProps = (dispatch) => ({
    initialize: (config) => dispatch(actions.initialize(config)),
    reset: () => dispatch(actions.reset()),
    resetForm: () => dispatch(actions.resetForm()),
    setIsOpen: (isOpen) => dispatch(actions.setIsOpen(isOpen)),
});

Container.propTypes = {
    initialize: PropTypes.func,
    isOpen: PropTypes.bool,
    match: PropTypes.object,
    reset: PropTypes.func,
    resetForm: PropTypes.func,
    setIsOpen: PropTypes.func,
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withRouter
)(Container);
