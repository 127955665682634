import React from 'react';

import CreditCardIcon from '@mui/icons-material/CreditCard';
import { get, isNull } from 'lodash';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from 'sunwise-ui';

import { parseDate, formatDate } from 'common/utils/dates';

import StyledMenuItem from './StyledMenuItem';

const SubscriptionMenuItem = ({
    handleCloseProfileMenu,
    match,
    redirect,
    subscription,
}) => {
    const { t } = useTranslation();
    const availableProjects = get(
        subscription,
        'counters.available_projects',
        0
    );
    const nextBillingDate = get(subscription, 'next_billing_at', '');
    const billingPeriod = get(subscription, 'billing_period_unit.key', null);

    return (
        <StyledMenuItem
            onClick={() => {
                handleCloseProfileMenu();
                redirect('/profile/subscription');
            }}
            selected={match.path === '/profile'}
        >
            <Box display="flex" gap={1} sx={{ width: '100%' }}>
                <CreditCardIcon />
                <Box display="flex" flexDirection="column">
                    <Typography variant="body2">
                        {subscription?.plan_name}{' '}
                        {billingPeriod === 'year' ? t('Annual') : t('Monthly')}
                    </Typography>
                    {nextBillingDate && (
                        <Typography variant="body2">
                            {`${t('Next billing')}: ${formatDate(
                                parseDate(
                                    nextBillingDate,
                                    'dd/MM/yyyy HH:mm:ss'
                                ),
                                'dd/MM/yyyy'
                            )}`}
                        </Typography>
                    )}
                    {!isNull(subscription?.counters) && (
                        <Typography variant="body2">
                            {`${t(
                                'Total projects available'
                            )}: ${availableProjects}`}
                        </Typography>
                    )}
                </Box>
            </Box>
        </StyledMenuItem>
    );
};

SubscriptionMenuItem.propTypes = {
    handleCloseProfileMenu: PropTypes.func,
    match: PropTypes.object,
    redirect: PropTypes.func,
    subscription: PropTypes.object,
};

export default SubscriptionMenuItem;
