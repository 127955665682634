import API from '../..';

const ENTITY = 'plans';

export const bulkPlans = (data) =>
    API.put(
        `/api/v1/${ENTITY}/bulk-status-flags/`,
        { ...data },
        { headers: { 'Content-Type': 'application/json' } }
    );
export const filterPlans = ({
    orderBy = 'created_at',
    page = 1,
    perPage = 25,
    searchText = '',
    sortBy = null,
    status = 'active',
} = {}) =>
    API.get(`/api/v1/${ENTITY}/`, {
        params: {
            order_by: orderBy,
            page: page,
            per_page: perPage,
            search: searchText,
            ...(searchText ? { search: searchText } : {}),
            sort: sortBy,
            status_flag: status,
        },
    });
export const getPlan = (id) => API.get(`/api/v1/${ENTITY}/${id}/`);
