import React, { useRef } from 'react';

import ArchiveOutlinedIcon from '@mui/icons-material/ArchiveOutlined';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import StarIcon from '@mui/icons-material/Star';
import StarOutlineIcon from '@mui/icons-material/StarOutline';
import UnarchiveOutlinedIcon from '@mui/icons-material/UnarchiveOutlined';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Box } from 'sunwise-ui';

import DropdownCardMenu from 'common/components/cards/DropdownCardMenu';
import { STATUS } from 'common/constants';

import * as actions from '../actions';
import * as selectors from '../selectors';

import ShareMenuItem from './ShareMenuItem';

const MenuOptions = ({
    canDelete,
    canModify,
    creditStatus,
    handleMarkAsFavorite,
    hasFinancingRequest,
    hasPermalink,
    hasSummaryLinked,
    isArchived,
    isArchivedFilter,
    isApproved,
    isFavorite,
    isGenerated,
    offerId,
    offerName,
    permalink,
    prepareCommercialOfferArchive,
    prepareCommercialOfferDelete,
    prepareCommercialOfferDuplicate,
    prepareCommercialOfferRename,
    projectId,
    proposalId,
}) => {
    const { t } = useTranslation();
    const menuRef = useRef(null);
    const isClosed = creditStatus === STATUS.CLOSED_STATUS.key;
    const isQuoted = creditStatus === STATUS.QUOTED_STATUS.key;

    return (
        <Box sx={{ ml: 'auto' }}>
            <DropdownCardMenu
                customAdditionalItem={
                    <ShareMenuItem
                        isHidden={!isGenerated || !hasPermalink}
                        offerName={offerName}
                        permalink={permalink}
                    />
                }
                items={[
                    {
                        disabled: isFavorite,
                        label: t('Favorite'),
                        icon: isFavorite ? (
                            <StarIcon fontSize="small" />
                        ) : (
                            <StarOutlineIcon fontSize="small" />
                        ),
                        handleClick: () =>
                            handleMarkAsFavorite(
                                isArchivedFilter,
                                projectId,
                                proposalId
                            ),
                        visible: canModify && !isArchived,
                    },
                    {
                        handleClick: () =>
                            prepareCommercialOfferRename(
                                isArchivedFilter,
                                offerName,
                                projectId,
                                proposalId
                            ),
                        icon: <EditIcon fontSize="small" />,
                        label: t('Rename'),
                        visible: canModify,
                    },
                    {
                        disabled: !canModify,
                        handleClick: () =>
                            prepareCommercialOfferDuplicate(
                                offerName,
                                proposalId
                            ),
                        icon: <FileCopyIcon fontSize="small" />,
                        label: t('Duplicate'),
                        visible: !isClosed,
                    },
                    {
                        handleClick: () =>
                            prepareCommercialOfferArchive(
                                hasFinancingRequest,
                                hasSummaryLinked,
                                isApproved,
                                isArchived,
                                isArchivedFilter,
                                isGenerated,
                                isQuoted,
                                offerId,
                                projectId
                            ),
                        icon: isArchived ? (
                            <UnarchiveOutlinedIcon fontSize="small" />
                        ) : (
                            <ArchiveOutlinedIcon fontSize="small" />
                        ),
                        label: isArchived ? t('Unarchive') : t('Archive'),
                        visible: canDelete && !isClosed,
                    },
                    {
                        handleClick: () =>
                            prepareCommercialOfferDelete(
                                hasSummaryLinked,
                                isArchivedFilter,
                                offerId,
                                offerName,
                                projectId
                            ),
                        icon: <DeleteIcon fontSize="small" />,
                        label: t('Delete'),
                        visible: canDelete && isArchived && !isClosed,
                    },
                ]}
                ref={menuRef}
                visible={canDelete || canModify}
            />
        </Box>
    );
};

const mapStateToProps = createStructuredSelector({
    isDuplicatingOffer: selectors.getIsDuplicatingOffer,
});

const mapDispatchToProps = (dispatch) => ({
    handleMarkAsFavorite: (isArchivedFilter, projectId, proposalId) =>
        dispatch(
            actions.onClickMarkAsFavorite(
                isArchivedFilter,
                projectId,
                proposalId
            )
        ),
    prepareCommercialOfferArchive: (
        hasFinancingRequest,
        hasSummaryLinked,
        isApproved,
        isArchived,
        isArchivedFilter,
        isGenerated,
        isQuoted,
        offerId,
        projectId
    ) =>
        dispatch(
            actions.prepareCommercialOfferArchive(
                hasFinancingRequest,
                hasSummaryLinked,
                isApproved,
                isArchived,
                isArchivedFilter,
                isGenerated,
                isQuoted,
                offerId,
                projectId
            )
        ),
    prepareCommercialOfferDelete: (
        hasSummaryLinked,
        isArchivedFilter,
        offerId,
        offerName,
        projectId
    ) =>
        dispatch(
            actions.prepareCommercialOfferDelete(
                hasSummaryLinked,
                isArchivedFilter,
                offerId,
                offerName,
                projectId
            )
        ),
    prepareCommercialOfferDuplicate: (proposalName, proposalId) =>
        dispatch(
            actions.prepareCommercialOfferDuplicate(proposalName, proposalId)
        ),
    prepareCommercialOfferRename: (
        isArchivedFilter,
        offerName,
        projectId,
        proposalId
    ) =>
        dispatch(
            actions.prepareCommercialOfferRename(
                isArchivedFilter,
                offerName,
                projectId,
                proposalId
            )
        ),
});

MenuOptions.propTypes = {
    canDelete: PropTypes.bool,
    canModify: PropTypes.bool,
    creditStatus: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    handleMarkAsFavorite: PropTypes.func,
    hasFinancingRequest: PropTypes.bool,
    hasPermalink: PropTypes.bool,
    hasSummaryLinked: PropTypes.bool,
    isArchived: PropTypes.bool,
    isArchivedFilter: PropTypes.bool,
    isApproved: PropTypes.bool,
    isFavorite: PropTypes.bool,
    isGenerated: PropTypes.bool,
    offerId: PropTypes.string,
    offerName: PropTypes.string,
    permalink: PropTypes.string,
    prepareCommercialOfferArchive: PropTypes.func,
    prepareCommercialOfferDelete: PropTypes.func,
    prepareCommercialOfferDuplicate: PropTypes.func,
    prepareCommercialOfferRename: PropTypes.func,
    projectId: PropTypes.string,
    proposalId: PropTypes.string,
};

export default connect(mapStateToProps, mapDispatchToProps)(MenuOptions);
