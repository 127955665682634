import { getProjects } from 'common/api/v2/projectsByList';

import {
    FETCH_PROJECT_BY_ID,
    FETCH_PROJECT_BY_ID_FAILURE,
    FETCH_PROJECT_BY_ID_SUCCESS,
} from '../actionTypes';
import { actions } from '../reducer';

export default (project) => (dispatch) => {
    if (!project || typeof project !== 'string')
        return dispatch(actions[FETCH_PROJECT_BY_ID_SUCCESS]({}));
    dispatch(actions[FETCH_PROJECT_BY_ID]());

    getProjects([project])
        .then((response) => {
            dispatch(
                actions[FETCH_PROJECT_BY_ID_SUCCESS](response?.data?.data?.[0])
            );
        })
        .catch(() => dispatch(actions[FETCH_PROJECT_BY_ID_FAILURE]()));
};
