import React from 'react';

import LockIcon from '@mui/icons-material/Lock';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { Box, Card, Tooltip, Typography } from 'sunwise-ui';

import { addDefaultSrc } from 'common/utils/helpers';

const Container = styled(Card)(
    ({ theme }) => `
        align-items: center;
        background-color: ${theme.palette.mode === 'dark' ? '#3F444C' : '#fff'};
        border-radius: 8px;
        cursor: pointer;
        display: flex;
        flex-direction: column;
        height: 248px;
        margin: 0 9px 16px;
        text-align: center;
        width: 100%;

        &.selected{
            box-shadow: 0 0 0 1px ${theme.palette.primary.main};
        }
`
);

const TemplateImage = styled(Box)`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 196px;
    background: #ffffff;
    border-radius: 8px;
    cursor: pointer;
    img {
        display: block;
        width: 100%;
        height: 100%;
        background: #ffffff;
        cursor: pointer;
        object-fit: cover;
        object-position: center;
    }
`;

const DefaultTemplatePreviewItem = ({
    isLocked,
    isSelected,
    language,
    languagesDictionary,
    onClick,
    previewImage,
    text,
}) => (
    <Container className={isSelected ? 'selected' : ''} onClick={onClick}>
        <TemplateImage>
            <img
                onError={(e) => addDefaultSrc(e, 160, 280)}
                src={previewImage}
            />
        </TemplateImage>
        <Box
            display="flex"
            alignItems="center"
            gap={1}
            p={1}
            sx={{ width: '100%' }}
        >
            {isLocked && <LockIcon sx={{ color: '#848bab' }} />}
            <Tooltip
                title={`${text} | (${languagesDictionary[language]?.language})`}
            >
                <Typography
                    variant="caption"
                    fontWeight="bold"
                    noWrap
                    sx={{
                        color: '#848bab',
                        overflow: 'hidden',
                        width: 'calc(100% - 16px)',
                        textAlign: 'left',
                        textOverflow: 'ellipsis',
                    }}
                >
                    {text} | ({languagesDictionary[language]?.language})
                </Typography>
            </Tooltip>
        </Box>
    </Container>
);

DefaultTemplatePreviewItem.propTypes = {
    isLocked: PropTypes.bool,
    isSelected: PropTypes.bool,
    language: PropTypes.string,
    languagesDictionary: PropTypes.object,
    onClick: PropTypes.func,
    previewImage: PropTypes.string,
    text: PropTypes.string,
};

export default DefaultTemplatePreviewItem;
