import isNil from 'lodash/isNil';

import initializeValues from './initializeValues';
import selectProject from './selectProject';
import setAutomaticHasChanges from './setAutomaticHasChanges';
import setIsFromCreate from './setIsFromCreate';
import setModalIsOpen from './setModalIsOpen';

export default ({
        automaticHasChanges,
        callbackMasterButton,
        initialValues,
        isFromCreate = false,
        isOpenModal,
        projectData,
    }) =>
    (dispatch) => {
        if (!isNil(projectData)) dispatch(selectProject(projectData));
        if (!isNil(automaticHasChanges))
            dispatch(setAutomaticHasChanges(automaticHasChanges));
        if (!isNil(initialValues))
            dispatch(
                initializeValues({
                    holder: projectData?.name || '',
                    ...initialValues,
                })
            );

        setIsFromCreate(isFromCreate);

        if (callbackMasterButton) {
            callbackMasterButton();
            return;
        }

        if (!isNil(isOpenModal)) dispatch(setModalIsOpen(isOpenModal));
    };
