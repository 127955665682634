import React from 'react';

import { useTranslation } from 'react-i18next';
import { Alert } from 'sunwise-ui';

const CNMCInconsistentAlert = () => {
    const { t } = useTranslation();
    return (
        <Alert severity="warning">
            {t(
                'Some inconsistencies have been noticed in the dates of the information provided by the CNMC, so some corrections have been made to ensure that everything is in order'
            )}
        </Alert>
    );
};

export default CNMCInconsistentAlert;
