import { fetchPaginatedData } from 'common/api/v1/reportCard';

import {
    FETCH_DATA,
    FETCH_DATA_SUCCESS,
    FETCH_DATA_FAILURE,
    FETCH_INITIAL_DATA,
} from '../actionTypes';
import { actions } from '../reducer';

export default ({ isInitialFetch, page = 1, pageSize = 6, projectId, year }) =>
    (dispatch) => {
        if (!projectId) return;

        const actionType = isInitialFetch ? FETCH_INITIAL_DATA : FETCH_DATA;
        dispatch(actions[actionType]());

        fetchPaginatedData(projectId, page, pageSize, year)
            .then((response) => {
                const data = response?.data || {};
                dispatch(actions[FETCH_DATA_SUCCESS](data));
            })
            .catch((error) =>
                dispatch(
                    actions[FETCH_DATA_FAILURE](error?.response?.data?.errors)
                )
            );
    };
