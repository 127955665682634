import i18next from 'i18next';

import alerts from 'common/modules/alerts';

import { getArchiveMessages } from '../helpers';

import archiveCommercialOffer from './archiveCommercialOffer';

export default (
        hasFinancingRequest,
        hasSummaryLinked,
        isApproved,
        isArchived,
        isArchivedFilter,
        isGenerated,
        isQuoted,
        offerId,
        projectId
    ) =>
    (dispatch) => {
        const text = isArchived ? i18next.t('Unarchive') : i18next.t('Archive');

        const messages = getArchiveMessages(
            hasFinancingRequest,
            hasSummaryLinked,
            isApproved,
            isArchived,
            isGenerated,
            isQuoted
        );

        const dataAlert = {
            confirmText: text,
            messages: messages,
            onSuccess: () => {
                dispatch(
                    archiveCommercialOffer(isArchivedFilter, offerId, projectId)
                );
            },
            title: isArchived
                ? i18next.t('Unarchive proposal')
                : i18next.t('Archive proposal'),
            type: alerts.ALERT_TYPE_CONFIRM,
            variant: 'warning',
        };

        dispatch(alerts.actions.show(dataAlert));
    };
