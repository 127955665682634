import React from 'react';

import AddIcon from '@mui/icons-material/Add';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button, BottomActions, Box } from 'sunwise-ui';

import { PERMISSION_LIST } from 'common/constants/permissionsV2';
import withPermissions from 'common/hocs/withPermissions';

import { PROPOSAL_SUMMARY_MAX, PROPOSAL_SUMMARY_TYPE } from '../constants';

const CreateButton = ({
    companiesCatalogs,
    getPermissionsByCode,
    handleClickCreate,
    type,
}) => {
    const { t } = useTranslation();

    const { canModify: canModifyCompanyList } = getPermissionsByCode(
        PERMISSION_LIST.LIST_SETTINGS_PERMISSION
    );
    const { canModify: canModifySummaryList } = getPermissionsByCode(
        PERMISSION_LIST.PROPOSAL_SUMMARIES_PERMISSION
    );

    const isDisabled =
        type === PROPOSAL_SUMMARY_TYPE &&
        companiesCatalogs.length >= PROPOSAL_SUMMARY_MAX;

    if (type === PROPOSAL_SUMMARY_TYPE && canModifySummaryList) {
        return (
            <BottomActions>
                <Box sx={{ mt: 2, width: '100%' }}>
                    <Button
                        disabled={isDisabled}
                        endIcon={<AddIcon />}
                        fullWidth
                        onClick={handleClickCreate}
                        type="button"
                        variant="outlined"
                    >
                        {t('Add')}
                    </Button>
                </Box>
            </BottomActions>
        );
    }

    return (
        canModifyCompanyList && (
            <BottomActions>
                <Box sx={{ mt: 2, width: '100%' }}>
                    <Button
                        endIcon={<AddIcon />}
                        fullWidth
                        onClick={handleClickCreate}
                        type="button"
                        variant="outlined"
                    >
                        {t('Add')}
                    </Button>
                </Box>
            </BottomActions>
        )
    );
};

CreateButton.propTypes = {
    companiesCatalogs: PropTypes.array,
    getPermissionsByCode: PropTypes.func,
    handleClickCreate: PropTypes.func,
    type: PropTypes.number,
};

export default withPermissions([
    PERMISSION_LIST.LIST_SETTINGS_PERMISSION,
    PERMISSION_LIST.PROPOSAL_SUMMARIES_PERMISSION,
])(CreateButton);
