import { getItems } from 'common/api/v1/users';

export default (branchOffice) => () =>
    new Promise((resolve, reject) =>
        getItems(branchOffice)
            .then((response) =>
                resolve({ id: branchOffice, agents: response?.data?.data })
            )
            .catch((error) => reject(error))
    );
