import React from 'react';

import ListAltIcon from '@mui/icons-material/ListAlt';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Card, Typography } from 'sunwise-ui';

import TemplateFolder from 'resources/templateFolder.png';

const Container = styled(Card)(
    ({ theme }) => `
        align-items: center;
        background-color: ${theme.palette.mode === 'dark' ? '#3F444C' : '#fff'};
        border-radius: 8px;
        cursor: pointer;
        display: flex;
        flex-direction: column;
        height: 248px;
        margin: 0 9px 16px;
        text-align: center;
        width: 100%;
`
);

const CategoryIcon = styled('img')`
    height: 18px;
    left: 38px;
    object-fit: contain;
    position: absolute;
    top: 34px;
    width: 21px;
    z-index: 2;
`;

const Label = styled(Typography)`
    font-size: 14px;
    font-weight: 600;
    height: 22px;
    line-height: 22px;
    margin-top: 31px;
`;

const ImageContainer = styled('div')`
    height: 83px;
    margin-top: 27px;
    position: relative;
    width: 104px;
`;

const FolderImg = styled('img')`
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 1;
`;

const TemplateCountContainer = styled('div')`
    align-items: center;
    display: flex;
    height: 14px;
    margin-top: 36px;
`;

const TemplateCountText = styled(Typography)`
    font-size: 11px;
    height: 13px;
    line-height: 13px;
    margin-left: 8px;
`;

const CategoryPreviewItem = ({ name, onClick, templatesCount, urlIcon }) => {
    const { t } = useTranslation();
    return (
        <Container onClick={onClick}>
            <Label>{name}</Label>
            <ImageContainer>
                <FolderImg src={TemplateFolder} />
                {urlIcon && <CategoryIcon src={urlIcon} />}
            </ImageContainer>
            <TemplateCountContainer>
                <ListAltIcon />
                <TemplateCountText>
                    {templatesCount} {t('Template', { count: 2 })}
                </TemplateCountText>
            </TemplateCountContainer>
        </Container>
    );
};

CategoryPreviewItem.propTypes = {
    name: PropTypes.string,
    onClick: PropTypes.func,
    templatesCount: PropTypes.number,
    urlIcon: PropTypes.string,
};

export default CategoryPreviewItem;
