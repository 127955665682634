import React, { useState } from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { UiDialog as Dialog } from 'sunwise-ui';

import { getSessionLocalStorage } from 'common/utils/helpers/session';

import Form from '../../../../modules/contactForm/components/Form';

const CreateContact = ({
    branchSelected,
    isModalOpenInitialState = true,
    onExited,
}) => {
    const { t } = useTranslation();
    const [isModalOpen, setIsModalOpen] = useState(isModalOpenInitialState);

    const session = getSessionLocalStorage();
    const branch_office = !['all', 'company'].includes(branchSelected)
        ? branchSelected
        : '';

    const props = {
        initialValues: {
            advanced: false,
            agent: session.id,
            branch_office,
            company_name: '',
            company_position: '',
            contact_origin: null,
            emails: [],
            first_lastname: '',
            id: '',
            name: '',
            rfc: '',
            second_lastname: '',
            status_contact: '',
            status_flag: '',
            telephones: [],
        },
    };

    return (
        <Dialog
            onClose={() => setIsModalOpen(false)}
            onExited={onExited}
            open={isModalOpen}
            size="md"
            title={t('Create contact')}
        >
            <Form
                {...props}
                canDeleteContacts={false}
                canModify={true}
                handleAfterSave={() => setIsModalOpen(false)}
                isNewRecord={true}
            />
        </Dialog>
    );
};

CreateContact.propTypes = {
    branchSelected: PropTypes.string,
    isModalOpenInitialState: PropTypes.bool,
    onExited: PropTypes.func,
};

export default CreateContact;
