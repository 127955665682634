import React from 'react';

import PropTypes from 'prop-types';

const IconPanel = ({ color = '#ff9a00', height = '24px', width = '24px' }) => (
    <svg
        width={width}
        height={height}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M9.92083 15C9.92083 14.4477 9.47311 14 8.92083 14C8.36855 14 7.92083 14.4477 7.92083 15C7.92083 15.3453 7.96458 15.6804 8.04685 16C8.40863 17.4056 9.51522 18.5122 10.9208 18.874V20H9.92083C9.36855 20 8.92083 20.4477 8.92083 21C8.92083 21.5523 9.36855 22 9.92083 22L9.92926 22H13.9124L13.9208 22C14.4731 22 14.9208 21.5523 14.9208 21C14.9208 20.4477 14.4731 20 13.9208 20H12.9208V18.874C14.3264 18.5122 15.433 17.4056 15.7948 16C15.8771 15.6804 15.9208 15.3453 15.9208 15C15.9208 14.4477 15.4731 14 14.9208 14C14.3685 14 13.9208 14.4477 13.9208 15C13.9208 15.3643 13.8234 15.7058 13.6533 16C13.3075 16.5978 12.6611 17 11.9208 17C11.1805 17 10.5342 16.5978 10.1884 16C10.0182 15.7058 9.92083 15.3643 9.92083 15Z"
            fill={color}
        />
        <path
            d="M8.79923 2.44925C8.82883 2.21062 8.64272 2 8.40227 2H4.25466C4.06135 2 3.89569 2.13825 3.86111 2.32845L3.55719 4H8.60685L8.79923 2.44925Z"
            fill={color}
        />
        <path
            d="M8.48279 5H3.37538L2.82992 8H8.11062L8.48279 5Z"
            fill={color}
        />
        <path d="M9.11829 8L9.49046 5H14.3508L14.723 8H9.11829Z" fill={color} />
        <path
            d="M7.98656 9H2.6481L2.00657 12.5284C1.96192 12.774 2.15055 13 2.40012 13H7.13689C7.33876 13 7.509 12.8496 7.53385 12.6492L7.98656 9Z"
            fill={color}
        />
        <path
            d="M8.99423 9H14.8471L15.2875 12.5508C15.3172 12.7894 15.131 13 14.8906 13H8.95069C8.71024 13 8.52413 12.7894 8.55373 12.5508L8.99423 9Z"
            fill={color}
        />
        <path
            d="M15.8547 9L16.3074 12.6492C16.3323 12.8496 16.5025 13 16.7044 13H21.4415C21.6911 13 21.8797 12.774 21.8351 12.5284L21.1936 9H15.8547Z"
            fill={color}
        />
        <path
            d="M21.0117 8L20.4663 5H15.3585L15.7307 8H21.0117Z"
            fill={color}
        />
        <path
            d="M15.2344 4H20.2845L19.9805 2.32845C19.946 2.13825 19.7803 2 19.587 2H15.439C15.1986 2 15.0124 2.21062 15.0421 2.44925L15.2344 4Z"
            fill={color}
        />
        <path
            d="M14.2268 4H9.61451L9.81911 2.35075C9.84397 2.15042 10.0142 2 10.2161 2H13.6252C13.8271 2 13.9973 2.15042 14.0222 2.35075L14.2268 4Z"
            fill={color}
        />
    </svg>
);

IconPanel.propTypes = {
    color: PropTypes.string,
    height: PropTypes.string,
    width: PropTypes.string,
};

export default IconPanel;
