import { createSelector } from 'reselect';

import { NAME } from './constants';

export const getModel = (state) => state[NAME];

export const getSocketInfoModel = createSelector(
    getModel,
    (model) => model.socketInfo
);

export const getCredentialsModel = createSelector(
    getModel,
    (model) => model.credentials
);

export const getCredentials = createSelector(
    getCredentialsModel,
    (model) => model.data || []
);

export const getCredentialsError = createSelector(
    getCredentialsModel,
    (model) => model.error
);

export const getCredentialsIsLoading = createSelector(
    getCredentialsModel,
    (model) => model.loading
);

export const getCredentialsForSelect = createSelector(
    getCredentials,
    (credentials) =>
        credentials.map((credential) => ({
            label: credential.mi_espacio_user,
            value: credential.id,
        }))
);
