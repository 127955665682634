import React from 'react';

import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { IconButton, Tooltip } from 'sunwise-ui';

const AverageButton = ({ onClick }) => {
    const { t } = useTranslation();
    return (
        <Tooltip title={t('Apply average to empty fields')}>
            <div>
                <IconButton onClick={onClick}>
                    <KeyboardDoubleArrowDownIcon />
                </IconButton>
            </div>
        </Tooltip>
    );
};

AverageButton.propTypes = { onClick: PropTypes.func };

export default AverageButton;
