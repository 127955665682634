import React, { useEffect, useRef } from 'react';

import NearMeIcon from '@mui/icons-material/NearMe';
import isNil from 'lodash/isNil';
import PropTypes from 'prop-types';
import { useGeolocated } from 'react-geolocated';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Grid, IconButton } from 'sunwise-ui';

import { ControlledMap } from 'common/components';
import {
    ReactHookFormInput,
    ReactHookFormInputDatePicker,
    ReactHookFormIntlNumberInput,
    ReactHookFormSelect2,
} from 'common/components/form/bootstrap';
import {
    ItemGeocodeResult,
    ListGeocodeResult,
    WrapperGeocodeResult,
} from 'common/components/maps';
import { DEFAULT_MAP_PREVIEW_ZOOM } from 'common/constants';
import yupResolver from 'common/utils/yupResolver';

import InstallationDateWarning from '../../afterSalesSettings/components/warnings/InstallationDateWarning';
import { MIN_DATE } from '../../afterSalesSettings/constants';
import * as companyGeneralActions from '../../companyGeneral/actions';
import * as companySelectors from '../../companyGeneral/selectors';
import * as profileConfigurationActions from '../../profileConfiguration/actions';
import * as profileConfigurationSelectors from '../../profileConfiguration/selectors';
import * as actions from '../actions';
import * as selectors from '../selectors';
import validateSettingsForm from '../validateSettingsForm';

import BottomActionsForm from './form/BottomActionsForm';

const DEBOUNCE_TIMEOUT = 300;

const FormContainer = ({
    companyPositionLatLng,
    fetchGeocodePoint,
    fetchPowerStations,
    fetchTimezones,
    geocodePointData,
    geocodeResult,
    handleGeocode,
    handleOnClose,
    initialValues,
    isFetchingDefaultLocation,
    isFetchingGeocodePoint,
    isFetchingTimezones,
    isSaving,
    saveSettings,
    setEmptyGeocodeResult,
    setShowGeocodeResults,
    showGeocodeResults,
    sunwiseProjectId,
    timezones,
}) => {
    const timeoutRef = useRef();
    const { t } = useTranslation();
    const { coords, getPosition, isGeolocationEnabled } = useGeolocated({
        positionOptions: { enableHighAccuracy: false },
        suppressLocationOnMount: true,
        userDecisionTimeout: 5000,
    });

    const { control, handleSubmit, reset, setValue, watch } = useForm({
        defaultValues: initialValues,
        resolver: yupResolver(validateSettingsForm),
    });
    const [position, startDate] = watch(['position', 'start_date']);

    useEffect(() => {
        fetchTimezones();

        return () => {
            if (timeoutRef.current) clearTimeout(timeoutRef.current);
        };
    }, []);

    useEffect(() => {
        if (coords) updateLocation(coords);
    }, [coords]);

    useEffect(() => {
        if (!isNil(geocodePointData)) setValue('address', geocodePointData);
    }, [geocodePointData]);

    useEffect(() => reset(initialValues), [initialValues]);

    useEffect(() => {
        if (!position) return;
        setValue('latitude', position.latitude);
        setValue('longitude', position.longitude);
    }, [position]);

    const updateLocation = (position) => {
        if (!position) return;
        setValue('position', position);
        fetchGeocodePoint(position.latitude, position.longitude);
    };

    const handleTimeout = (action) => {
        if (timeoutRef.current) clearTimeout(timeoutRef.current);
        timeoutRef.current = setTimeout(action, DEBOUNCE_TIMEOUT);
    };

    const handleOnSubmit = (values) => {
        saveSettings({
            successCallback: () => {
                handleOnClose();
                fetchPowerStations(sunwiseProjectId);
            },
            sunwiseProjectId,
            values,
        });
    };

    const isDisabled = isFetchingDefaultLocation || isSaving;

    return (
        <form onSubmit={handleSubmit(handleOnSubmit)}>
            <Grid container alignItems="center">
                <Grid item xs={18} sm={9}>
                    <ReactHookFormSelect2
                        control={control}
                        disabled={isDisabled}
                        isClearable={false}
                        isLoading={isFetchingTimezones}
                        label={t('Timezone')}
                        name="time_zone"
                        options={[{ label: '', options: timezones }]}
                        variant="standard"
                    />
                </Grid>

                <Grid item xs={18} sm={9}>
                    <ReactHookFormInputDatePicker
                        control={control}
                        disableFuture
                        disabled={isDisabled}
                        label={t('Commissioning date')}
                        minDate={MIN_DATE}
                        name="start_date"
                        variant="standard"
                    />
                </Grid>
            </Grid>

            <InstallationDateWarning date={startDate} />

            {geocodeResult &&
                geocodeResult.length > 0 &&
                showGeocodeResults && (
                    <WrapperGeocodeResult>
                        <ListGeocodeResult
                            style={{ transform: 'translate3d(0px, 38px, 0px)' }}
                        >
                            {geocodeResult.map((result, i) => (
                                <ItemGeocodeResult
                                    key={i}
                                    onClick={() => {
                                        setValue(
                                            'address',
                                            result.formatted_address
                                        );

                                        const { lat, lng } =
                                            result.geometry.location;
                                        updateLocation({
                                            latitude: lat,
                                            longitude: lng,
                                        });
                                    }}
                                >
                                    {result.formatted_address}
                                </ItemGeocodeResult>
                            ))}
                        </ListGeocodeResult>
                    </WrapperGeocodeResult>
                )}

            <ReactHookFormInput
                control={control}
                disabled={isDisabled || isFetchingGeocodePoint}
                label={t('Address')}
                name="address"
                onChange={({ target }) => {
                    handleTimeout(() => {
                        if (target?.value?.length >= 6)
                            handleGeocode(target.value);
                        else setEmptyGeocodeResult();
                    });
                }}
                onBlur={() => {
                    handleTimeout(() => setShowGeocodeResults(false));
                }}
                onFocus={() => setShowGeocodeResults(true)}
                variant="standard"
            />

            <Grid container mb={2}>
                <Grid item xs>
                    <ReactHookFormIntlNumberInput
                        control={control}
                        disabled={isDisabled}
                        fullWidth
                        label={t('Latitude')}
                        name="latitude"
                        onChange={({ target }) => {
                            setValue('position', {
                                latitude: target.value,
                                longitude: position.longitude,
                            });
                        }}
                        variant="standard"
                    />
                </Grid>
                <Grid item xs>
                    <ReactHookFormIntlNumberInput
                        control={control}
                        disabled={isDisabled}
                        fullWidth
                        label={t('Longitude')}
                        name="longitude"
                        onChange={({ target }) => {
                            setValue('position', {
                                latitude: position.latitude,
                                longitude: target.value,
                            });
                        }}
                        variant="standard"
                    />
                </Grid>
                <Grid item display="flex" alignItems="center">
                    <IconButton
                        disabled={!isGeolocationEnabled || isSaving}
                        onClick={() => {
                            if (!isNil(coords))
                                updateLocation({
                                    latitude: coords.latitude,
                                    longitude: coords.longitude,
                                });
                            else getPosition();
                        }}
                    >
                        <NearMeIcon />
                    </IconButton>
                </Grid>

                <Grid item xs={18}>
                    <Controller
                        control={control}
                        name="position"
                        render={({ field: { value } }) => (
                            <ControlledMap
                                center={companyPositionLatLng}
                                disableDefaultUI={isDisabled}
                                heightMap="206px"
                                key={isDisabled}
                                onChange={updateLocation}
                                readOnly={isDisabled}
                                title={t('Location')}
                                value={value}
                                zoom={DEFAULT_MAP_PREVIEW_ZOOM}
                            />
                        )}
                    />
                </Grid>
            </Grid>

            <BottomActionsForm
                cancelText={t('Cancel')}
                continueText={t('Save')}
                disabled={isDisabled}
                disabledContinue={isDisabled}
                onCancel={handleOnClose}
                type="submit"
            />
        </form>
    );
};

const mapStateToProps = createStructuredSelector({
    companyPositionLatLng: companySelectors.getCompanyPositionLatLng,
    geocodePointData: companySelectors.getGeocodePointData,
    geocodeResult: companySelectors.getFetchGeocodeData,
    initialValues: selectors.getInitialValuesSettings,
    isFetchingDefaultLocation: selectors.getIsFetchingDefaultLocation,
    isFetchingGeocodePoint: companySelectors.getIsFetchingGeocodePoint,
    isFetchingTimezones: profileConfigurationSelectors.getIsFetchingTimezones,
    showGeocodeResults: companySelectors.showGeocodeResults,
    timezones: profileConfigurationSelectors.getTimezonesOptions,
});

const mapDispatchToProps = (dispatch) => ({
    fetchGeocodePoint: (lat, lng) =>
        dispatch(companyGeneralActions.fetchGeocodePoint(lat, lng)),
    fetchPowerStations: (sunwiseProjectId) =>
        dispatch(actions.fetchPowerStations(sunwiseProjectId)),
    fetchTimezones: () =>
        dispatch(profileConfigurationActions.fetchTimezones()),
    handleGeocode: (str) =>
        dispatch(companyGeneralActions.fetchGeocodeResult(str)),
    saveSettings: (params) => dispatch(actions.saveSettings(params)),
    setEmptyGeocodeResult: () =>
        dispatch(companyGeneralActions.setEmptyGeocodeResult()),
    setShowGeocodeResults: (value) =>
        dispatch(companyGeneralActions.setShowGeocodeResults(value)),
});

FormContainer.propTypes = {
    companyPositionLatLng: PropTypes.object,
    fetchGeocodePoint: PropTypes.func,
    fetchPowerStations: PropTypes.func,
    fetchTimezones: PropTypes.func,
    geocodePointData: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    geocodeResult: PropTypes.array,
    handleGeocode: PropTypes.func,
    handleOnClose: PropTypes.func,
    initialValues: PropTypes.object,
    isFetchingDefaultLocation: PropTypes.bool,
    isFetchingGeocodePoint: PropTypes.bool,
    isFetchingTimezones: PropTypes.bool,
    isSaving: PropTypes.bool,
    saveSettings: PropTypes.func,
    setEmptyGeocodeResult: PropTypes.func,
    setShowGeocodeResults: PropTypes.func,
    showGeocodeResults: PropTypes.bool,
    sunwiseProjectId: PropTypes.string,
    timezones: PropTypes.array,
};

export default connect(mapStateToProps, mapDispatchToProps)(FormContainer);
