import React, { useCallback, useState } from 'react';

import FileUploadIcon from '@mui/icons-material/FileUpload';
import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import { Alert, Box, Button } from 'sunwise-ui';

const ButtonFieldFile = ({
    accept,
    className,
    disabled,
    field,
    fileInputRef,
    id,
    label = '',
    onChange,
    text,
    variant,
}) => {
    const { t } = useTranslation();
    const [error, setError] = useState(null);

    const onDrop = useCallback((acceptedFiles) => {
        setError(null);

        field.onChange(
            acceptedFiles.map((file) => {
                if (
                    accept &&
                    !accept
                        .split(',')
                        .map((a) => a.trim())
                        .includes(file.type)
                ) {
                    setError(
                        t('The file must be type {{type}}', {
                            type: accept,
                        })
                    );
                }

                return Object.assign(file, {
                    preview: URL.createObjectURL(file),
                });
            })
        );
        if (onChange) onChange(acceptedFiles);
    }, []);

    const { getRootProps, getInputProps } = useDropzone({ onDrop });

    return (
        <Box {...getRootProps} sx={{ display: 'flex', alignItems: 'center' }}>
            {label && <label>{label}</label>}
            <Button
                color="secondary"
                component="label"
                className={className}
                disabled={disabled}
                fullWidth
                id={id}
                startIcon={<FileUploadIcon />}
                variant={variant}
                mb={0}
            >
                {text}
                <input
                    {...getInputProps()}
                    accept={accept}
                    disabled={disabled}
                    ref={fileInputRef}
                />
            </Button>
            {error && <Alert severity="warning">{`${error}`}</Alert>}
        </Box>
    );
};

ButtonFieldFile.propTypes = {
    accept: PropTypes.string,
    className: PropTypes.string,
    disabled: PropTypes.bool,
    field: PropTypes.object,
    fileInputRef: PropTypes.object,
    id: PropTypes.string,
    label: PropTypes.string,
    onChange: PropTypes.func,
    text: PropTypes.string,
    variant: PropTypes.string,
};

export default ButtonFieldFile;
