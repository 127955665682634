import React, { useEffect } from 'react';

import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import MobileStepper from '@mui/material/MobileStepper';
import Step from '@mui/material/Step';
import StepButton from '@mui/material/StepButton';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import { styled } from '@mui/material/styles';
import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Box, Button, TabPanel, Typography } from 'sunwise-ui';

import ShowErrors from 'common/components/ShowErrors';
import { dictionaryToArray } from 'common/utils/helpers';
import yupResolver from 'common/utils/yupResolver';

import * as actions from '../actions';
import { ONBOARDING_STEPS } from '../constants';
import {
    checkKeyDown,
    getStepIndex,
    getStepKey,
    getStepLabel,
    getTextButton,
} from '../helpers';
import * as selectors from '../selectors';
import validate from '../validate';

import BatteryStep from './BatteryStep';
import CompanyStep from './CompanyStep';
import DetailsStep from './DetailsStep';
import FinishStep from './FinishStep';
import InvertersStep from './InvertersStep';
import MembersStep from './MembersStep';
import PanelsStep from './PanelsStep';
import StepCircle from './StepCircle';
import WelcomeStep from './WelcomeStep';
import WrapperStep from './WrapperStep';

const Content = styled(DialogContent)`
    background: linear-gradient(
            0deg,
            rgba(255, 255, 255, 0.5),
            rgba(255, 255, 255, 0.5)
        ),
        #eef4fa;
`;

const StepperForm = ({
    completedSteps,
    counters,
    currencies,
    currentStep,
    initialValues,
    isFetchingBatteryProducts,
    isFetchingInverterProducts,
    isFetchingPanelProducts,
    isLoading,
    isSavingCompany,
    isSavingDetails,
    isSavingOnboarding,
    save,
    saveErrors,
    setCurrentStep,
    subscriptionType,
    user,
}) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const { control, getValues, handleSubmit, reset, setValue, watch } =
        useForm({
            defaultValues: initialValues,
            resolver: yupResolver(validate),
        });
    const formValues = watch();

    useEffect(() => reset(initialValues), [initialValues]);

    const handleOnSave = (values) =>
        save({ ...values, step: currentStep }, (newStep) =>
            setValue('step', newStep)
        );

    const handleClickStep = (step) => {
        setCurrentStep(step);
        const arraySteps = dictionaryToArray(ONBOARDING_STEPS);
        setValue('step', arraySteps[step]);
    };

    const isDisabled =
        isFetchingBatteryProducts ||
        isFetchingInverterProducts ||
        isFetchingPanelProducts ||
        isLoading ||
        isSavingCompany ||
        isSavingDetails ||
        isSavingOnboarding;

    return (
        <form onKeyDown={(e) => checkKeyDown(e)}>
            <Content>
                <Box
                    sx={{
                        display: { xs: 'none', md: 'block' },
                        width: '100%',
                        py: 1,
                    }}
                >
                    <Stepper activeStep={currentStep} alternativeLabel>
                        {Object.values(ONBOARDING_STEPS).map((step, index) => {
                            const labelProps = {};
                            if (saveErrors[step]?.length) {
                                labelProps.error = true;
                            }
                            return (
                                <Step
                                    key={step}
                                    completed={completedSteps[index]}
                                >
                                    <StepButton
                                        color="inherit"
                                        onClick={() => handleClickStep(index)}
                                    >
                                        <StepLabel {...labelProps}>
                                            {getStepLabel(step)}
                                        </StepLabel>
                                    </StepButton>
                                </Step>
                            );
                        })}
                    </Stepper>
                </Box>
                {currentStep <= 6 && (
                    <Box
                        sx={{
                            alignItems: 'center',
                            display: { xs: 'flex', md: 'none' },
                            gap: 1,
                            justifyContent: 'center',
                            width: '100%',
                        }}
                    >
                        <StepCircle>{currentStep + 1}</StepCircle>
                        <Typography variant="h4">
                            {getStepLabel(getStepKey(currentStep))}
                        </Typography>
                    </Box>
                )}
                <TabPanel
                    selectedTab={currentStep}
                    value={getStepIndex(ONBOARDING_STEPS.REGISTER)}
                >
                    <WrapperStep isLoading={isLoading}>
                        <WelcomeStep isLoading={isLoading} user={user} />
                    </WrapperStep>
                </TabPanel>
                <TabPanel
                    selectedTab={currentStep}
                    value={getStepIndex(ONBOARDING_STEPS.COMPANY)}
                >
                    <WrapperStep isLoading={isLoading}>
                        <CompanyStep
                            control={control}
                            formValues={formValues}
                            setValue={setValue}
                        />
                        <ShowErrors
                            errors={saveErrors[ONBOARDING_STEPS.COMPANY]}
                        />
                    </WrapperStep>
                </TabPanel>
                <TabPanel
                    selectedTab={currentStep}
                    value={getStepIndex(ONBOARDING_STEPS.DETAILS)}
                >
                    <WrapperStep isLoading={isLoading}>
                        <DetailsStep
                            control={control}
                            currencies={currencies}
                            formValues={formValues}
                            getValues={getValues}
                            setValue={setValue}
                        />
                        <ShowErrors
                            errors={saveErrors[ONBOARDING_STEPS.DETAILS]}
                        />
                    </WrapperStep>
                </TabPanel>
                <TabPanel
                    selectedTab={currentStep}
                    value={getStepIndex(ONBOARDING_STEPS.MEMBERS)}
                >
                    <WrapperStep isLoading={isLoading}>
                        <MembersStep
                            control={control}
                            counters={counters}
                            formValues={formValues}
                            getValues={getValues}
                            setValue={setValue}
                            subscriptionType={subscriptionType}
                        />
                        <ShowErrors
                            errors={saveErrors[ONBOARDING_STEPS.MEMBERS]}
                        />
                    </WrapperStep>
                </TabPanel>
                <TabPanel
                    selectedTab={currentStep}
                    value={getStepIndex(ONBOARDING_STEPS.PANELS)}
                >
                    <WrapperStep isLoading={isLoading}>
                        <PanelsStep
                            control={control}
                            formValues={formValues}
                            getValues={getValues}
                            setValue={setValue}
                        />
                        <ShowErrors
                            errors={saveErrors[ONBOARDING_STEPS.PANELS]}
                        />
                    </WrapperStep>
                </TabPanel>
                <TabPanel
                    selectedTab={currentStep}
                    value={getStepIndex(ONBOARDING_STEPS.INVERTERS)}
                >
                    <WrapperStep isLoading={isLoading}>
                        <InvertersStep
                            control={control}
                            formValues={formValues}
                            getValues={getValues}
                            setValue={setValue}
                        />
                        <ShowErrors
                            errors={saveErrors[ONBOARDING_STEPS.INVERTERS]}
                        />
                    </WrapperStep>
                </TabPanel>
                <TabPanel
                    selectedTab={currentStep}
                    value={getStepIndex(ONBOARDING_STEPS.BATTERIES)}
                >
                    <WrapperStep isLoading={isLoading}>
                        <BatteryStep
                            control={control}
                            formValues={formValues}
                            getValues={getValues}
                            setValue={setValue}
                        />
                        <ShowErrors
                            errors={saveErrors[ONBOARDING_STEPS.BATTERIES]}
                        />
                    </WrapperStep>
                </TabPanel>
                <TabPanel
                    selectedTab={currentStep}
                    value={getStepIndex(ONBOARDING_STEPS.FINISH)}
                >
                    <WrapperStep isLoading={isLoading}>
                        <FinishStep />
                        <ShowErrors
                            errors={saveErrors[ONBOARDING_STEPS.FINISH]}
                        />
                    </WrapperStep>
                </TabPanel>
            </Content>
            <DialogActions sx={{ background: '#eef4fa' }}>
                <Box
                    sx={{
                        display: { xs: 'none', md: 'flex' },
                        flexDirection: { md: 'row', xs: 'column' },
                        gap: '16px',
                        justifyContent: { md: 'right', xs: 'center' },
                        px: 1,
                        py: 2,
                        width: '100%',
                    }}
                >
                    <Button
                        color="secondary"
                        size="small"
                        onClick={() => handleClickStep(currentStep - 1)}
                        disabled={currentStep === 0}
                        sx={{ mr: 'auto', '&.MuiButton-root': { mb: 0 } }}
                        variant="outlined"
                        visible={currentStep > 0}
                    >
                        {theme.direction === 'rtl' ? (
                            <KeyboardArrowRight />
                        ) : (
                            <KeyboardArrowLeft />
                        )}
                        {t('Back')}
                    </Button>
                    <Button
                        disabled={isDisabled}
                        onClick={handleSubmit(handleOnSave)}
                        sx={{
                            order: { md: 2, xs: 1 },
                            width: { md: 'auto', xs: '100%' },
                            '&.MuiButton-root': { mb: 0 },
                        }}
                        type="submit"
                    >
                        {getTextButton({
                            step: currentStep,
                            isSaving:
                                isSavingCompany ||
                                isSavingDetails ||
                                isSavingOnboarding,
                        })}
                    </Button>
                </Box>
                <Box
                    sx={{
                        display: { xs: 'block', md: 'none' },
                        width: '100%',
                        py: 1,
                    }}
                >
                    <MobileStepper
                        variant="text"
                        steps={dictionaryToArray(ONBOARDING_STEPS).length}
                        position="static"
                        activeStep={currentStep}
                        nextButton={
                            <Box
                                alignItems="center"
                                gap={1}
                                sx={{ display: { xs: 'flex', md: 'none' } }}
                            >
                                <Button
                                    disabled={isDisabled}
                                    onClick={handleSubmit(handleOnSave)}
                                    sx={{
                                        '&.MuiButton-root': { mb: 0 },
                                    }}
                                    type="submit"
                                >
                                    {getTextButton({
                                        step: currentStep,
                                        isSaving:
                                            isSavingCompany ||
                                            isSavingDetails ||
                                            isSavingOnboarding,
                                    })}
                                </Button>
                            </Box>
                        }
                        backButton={
                            <Button
                                color="secondary"
                                size="small"
                                onClick={() => handleClickStep(currentStep - 1)}
                                disabled={currentStep === 0}
                                sx={{ '&.MuiButton-root': { mb: 0 } }}
                                variant="outlined"
                            >
                                {theme.direction === 'rtl' ? (
                                    <KeyboardArrowRight />
                                ) : (
                                    <KeyboardArrowLeft />
                                )}
                                {t('Back')}
                            </Button>
                        }
                        sx={{ background: 'transparent' }}
                    />
                </Box>
            </DialogActions>
        </form>
    );
};

const mapStateToProps = createStructuredSelector({
    completedSteps: selectors.getCompletedSteps,
    currentStep: selectors.getCurentStep,
    initialValues: selectors.getInitialValues,
    isFetchingBatteryProducts: selectors.getIsFetchingBatteryProducts,
    isFetchingInverterProducts: selectors.getIsFetchingInverterProducts,
    isFetchingPanelProducts: selectors.getIsFetchingPanelProducts,
    isSavingCompany: selectors.getIsSavingCompany,
    isSavingDetails: selectors.getIsSavingDetails,
    isSavingOnboarding: selectors.getIsSavingOnboarding,
    saveErrors: selectors.getSaveErrors,
});

const mapDispatchToProps = (dispatch) => ({
    save: (values, callback) => dispatch(actions.save(values, callback)),
    setCurrentStep: (step) => dispatch(actions.setCurrentStep(step)),
});

StepperForm.propTypes = {
    completedSteps: PropTypes.object,
    counters: PropTypes.object,
    currencies: PropTypes.array,
    currentStep: PropTypes.number,
    initialValues: PropTypes.object,
    isFetchingBatteryProducts: PropTypes.bool,
    isFetchingInverterProducts: PropTypes.bool,
    isFetchingPanelProducts: PropTypes.bool,
    isLoading: PropTypes.bool,
    isSavingCompany: PropTypes.bool,
    isSavingDetails: PropTypes.bool,
    isSavingOnboarding: PropTypes.bool,
    save: PropTypes.func,
    saveErrors: PropTypes.object,
    setCurrentStep: PropTypes.func,
    subscriptionType: PropTypes.number,
    user: PropTypes.object,
};

export default connect(mapStateToProps, mapDispatchToProps)(StepperForm);
