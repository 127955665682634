import i18next from 'i18next';
import { get, isEmpty } from 'lodash';

import { DAYS_IN_PERIOD, INSURANCE_TYPE } from 'common/constants';
import { addDate, formatDate, parseDate, subDate } from 'common/utils/dates';
import { getColorButtonByStatus } from 'common/utils/helpers';

import {
    MANUAL_RECEIPT_ORIGIN,
    DEFAULT_SERVICE_NUMBER,
    LINE_KWH,
} from '../projectConsumptionsModal/constants';

export const getArchiveMessages = (
    hasFinancingRequest,
    hasSummaryLinked,
    isApproved,
    isArchived,
    isGenerated,
    isQuoted
) => {
    const defaultMessage = i18next.t(
        'Are you sure you want to archive this item?'
    );

    if (isArchived) {
        return [i18next.t('Are you sure you want to unarchive this item?')];
    }

    if (!isApproved && isGenerated && hasFinancingRequest) {
        return [
            defaultMessage,
            i18next.t(
                'By archiving this proposal the financial request will be removed'
            ),
        ];
    }

    if (isApproved && hasFinancingRequest) {
        return [
            defaultMessage,
            i18next.t(
                "By archiving this proposal the financial request and client's approval will be removed"
            ),
        ];
    }

    if ((isApproved && isQuoted) || isApproved) {
        return [
            defaultMessage,
            i18next.t(
                "By archiving this proposal the client's approval will be removed"
            ),
        ];
    }

    if (hasSummaryLinked) {
        return [
            defaultMessage,
            i18next.t(
                'This proposal is linked to a summary of proposals. Archiving it will automatically archive the related summary. Are you sure to archive this proposal?'
            ),
        ];
    }

    return [defaultMessage];
};

export const getArchiveSecondaryMessage = (isArchived) => {
    if (isArchived) {
        return i18next.t('Are you sure you want to unarchive this item?');
    }

    return i18next.t('Are you sure you want to archive this item?');
};

export const getDeleteMessage = (hasSummaryLinked, offerName) => {
    if (hasSummaryLinked)
        return i18next.t(
            'This proposal is linked to a summary of proposals. Deleting it will automatically archive the related summary. Are you sure to delete this proposal?'
        );
    return i18next.t('Are you sure to delete the "{{offerName}}" proposal?', {
        offerName,
    });
};

export const getCommercialOfferModalTitle = (consumptionEnergy) =>
    `${i18next.t('Create proposal')} ${
        isEmpty(consumptionEnergy)
            ? i18next.t('Without consumption').toLowerCase()
            : ''
    }`;

export const getDefaultCustomerEnergyConsumptions = (
    periodicityType = 0,
    lastDateConsumption = formatDate(new Date(), 'dd/MM/yyyy')
) => {
    const parsedPeriodicityType = parseInt(periodicityType);
    const daysToSubtract = (parsedPeriodicityType + 1) * DAYS_IN_PERIOD;
    const quantityMonths = 12 / (parsedPeriodicityType + 1);
    const { gdmth_summary_data, months_kw, months_kwh } = [
        ...new Array(quantityMonths),
    ].reduce(
        (acc, _, index) => {
            const realIndex = index + 1;
            const final = acc.currentPivotDate;
            const initial = subDate(
                parseDate(lastDateConsumption, 'dd/MM/yyyy'),
                {
                    minutes: daysToSubtract * realIndex * 24 * 60,
                }
            );
            const finalDate = formatDate(final, 'dd/MM/yyyy');
            const label = formatDate(final, 'MMM yy');
            const initialDate = formatDate(initial, 'dd/MM/yyyy');
            const labelGDMTH = formatDate(
                addDate(
                    parseDate(formatDate(initial, 'dd/MM/yyyy'), 'dd/MM/yyyy'),
                    { months: 1 }
                ),
                'MMM yy'
            );
            const newMonthKwh = {
                consumption: 0,
                final_date: finalDate,
                initial_date: initialDate,
                label: label,
                total_cost: 0,
                type_line: 0,
                value: 0,
            };
            const newMonthKw = {
                consumption: 0,
                final_date: finalDate,
                initial_date: initialDate,
                label: label,
                total_cost: 0,
                type_line: 0,
                value: 0,
            };
            const gdmthSummaryDataItem = {
                base_consumption: 0,
                base_consumption_kw: 0,
                id: null,
                initial_date: initialDate,
                label: labelGDMTH,
                intermediate_consumption: 0,
                intermediate_consumption_kw: 0,
                maximum_demand: 0,
                peak_consumption: 0,
                peak_consumption_kw: 0,
                power_factor: 90,
                total_consumption: 0,
            };
            return {
                ...acc,
                gdmth_summary_data: [
                    ...acc.gdmth_summary_data,
                    gdmthSummaryDataItem,
                ],
                months_kw: [...acc.months_kw, newMonthKw],
                months_kwh: [...acc.months_kwh, newMonthKwh],
                currentPivotDate: parseDate(
                    formatDate(initial, 'dd/MM/yyyy'),
                    'dd/MM/yyyy'
                ),
            };
        },
        {
            months_kw: [],
            months_kwh: [],
            currentPivotDate: parseDate(lastDateConsumption, 'dd/MM/yyyy'),
            gdmth_summary_data: [],
        }
    );

    return {
        gdmth_summary_data: gdmth_summary_data,
        months_kw: months_kw.reverse(),
        months_kwh: months_kwh.reverse(),
    };
};

export const getRequestCount = (commercialOffers) => {
    const offers = commercialOffers.map((item) =>
        get(item, 'commercial_offer[0]', {})
    );
    if (offers) {
        return offers.reduce((acc, current) => {
            if (get(current, 'has_financing_request', false)) return acc + 1;
            return acc;
        }, 0);
    }
    return 0;
};

export const getIsShowContracts = (
    contracts,
    hasRequestBureau,
    isBright,
    isMoralPerson,
    isSerfimex
) => {
    if (isEmpty(contracts)) return false;
    if (
        (!isEmpty(contracts) && isMoralPerson) ||
        (!isEmpty(contracts) && isBright) ||
        (!isEmpty(contracts) && isSerfimex)
    ) {
        return true;
    }

    if (!isEmpty(contracts) && !isMoralPerson && hasRequestBureau) {
        return true;
    }
    return false;
};

export const getIsShowDocuments = (
    documents,
    hasRequestBureau,
    isBright,
    isMoralPerson,
    isSerfimex
) => {
    if (isEmpty(documents)) return false;
    if (
        (!isEmpty(documents) && isMoralPerson) ||
        (!isEmpty(documents) && isBright) ||
        (!isEmpty(documents) && isSerfimex)
    ) {
        return true;
    }

    if (!isEmpty(documents) && !isMoralPerson && hasRequestBureau) {
        return true;
    }
    return false;
};

export const getIsShowInsuranceDocuments = (
    creditType,
    hasRequestBureau,
    insuranceDocuments,
    isBright,
    isMoralPerson,
    isSerfimex
) => {
    if (isEmpty(insuranceDocuments)) return false;
    if (
        (!isEmpty(insuranceDocuments) &&
            creditType === INSURANCE_TYPE &&
            isMoralPerson) ||
        (!isEmpty(insuranceDocuments) &&
            creditType === INSURANCE_TYPE &&
            isBright) ||
        (!isEmpty(insuranceDocuments) &&
            creditType === INSURANCE_TYPE &&
            isSerfimex)
    ) {
        return true;
    }

    if (
        !isEmpty(insuranceDocuments) &&
        creditType === INSURANCE_TYPE &&
        !isMoralPerson &&
        hasRequestBureau
    ) {
        return true;
    }
    return false;
};

export const getIsShowRequestDocuments = (
    creditType,
    hasRequestBureau,
    isBright,
    isMoralPerson,
    isSerfimex,
    requestFormDocuments
) => {
    if (isEmpty(requestFormDocuments)) return false;
    if (
        (!isEmpty(requestFormDocuments) &&
            creditType !== INSURANCE_TYPE &&
            isMoralPerson) ||
        (!isEmpty(requestFormDocuments) &&
            creditType !== INSURANCE_TYPE &&
            isBright) ||
        (!isEmpty(requestFormDocuments) &&
            creditType !== INSURANCE_TYPE &&
            isSerfimex)
    ) {
        return true;
    }

    if (
        !isEmpty(requestFormDocuments) &&
        creditType !== INSURANCE_TYPE &&
        !isMoralPerson &&
        hasRequestBureau
    ) {
        return true;
    }
    return false;
};

export const getSxRequestButton = (
    creditStatus,
    isDarkMode,
    isFullWidth = false
) => {
    if (isDarkMode) {
        return {
            backgroundColor: getColorButtonByStatus(creditStatus),
            border: `1px solid ${getColorButtonByStatus(creditStatus)}`,
            color: '#ffffff',
            width: isFullWidth ? '100%' : { xs: '100%', md: 'auto' },
            whiteSpace: 'nowrap',
            '&:hover': {
                backgroundColor: getColorButtonByStatus(creditStatus),
                borderColor: getColorButtonByStatus(creditStatus),
            },
        };
    }
    return {
        border: `1px solid ${getColorButtonByStatus(creditStatus)}`,
        color: getColorButtonByStatus(creditStatus),
        width: isFullWidth ? '100%' : { xs: '100%', md: 'auto' },
        whiteSpace: 'nowrap',
        '&:hover': {
            backgroundColor: 'transparent',
            borderColor: getColorButtonByStatus(creditStatus),
        },
    };
};

export const buildPayload = ({ selectedProject, values } = {}) => {
    const customerEnergyConsumptions = getDefaultCustomerEnergyConsumptions();

    const valuesKwh = customerEnergyConsumptions.months_kwh.map((month) => ({
        consumption: parseInt(month.value) || 0,
        final_date: month.final_date,
        initial_date: month.initial_date,
        predicted: month.predicted,
        total_cost: month.total_cost ? parseInt(month.total_cost) : -1,
        type_line: `${LINE_KWH}`,
        url: month.url,
    }));

    const consumptionLines = valuesKwh;

    const billing_period_start = formatDate(
        subDate(
            parseDate(
                customerEnergyConsumptions.months_kwh[
                    customerEnergyConsumptions.months_kwh.length - 1
                ].final_date,
                'dd/MM/yyyy'
            ),
            { years: 1 }
        ),

        'dd/MM/yyyy'
    );

    let newValues = {
        billing_period_end:
            customerEnergyConsumptions.months_kwh[
                customerEnergyConsumptions.months_kwh.length - 1
            ].final_date,
        billing_period_start: billing_period_start,
        certified: null,
        consumption_lines: consumptionLines,
        from_pdf: false,
        holder: selectedProject.name,
        is_bimonthly: false,
        periodicity_type: 0,
        political_division1: values.political_division1,
        political_division2: values.political_division2,
        project: selectedProject.id,
        rate: null,
        rate_division: values.rate_division,
        receipt_origin: MANUAL_RECEIPT_ORIGIN,
        service_number: DEFAULT_SERVICE_NUMBER,
        without_consumption: true,
        zip_code: values.zip_code,
    };

    return newValues;
};
