import i18next from 'i18next';
import isEmpty from 'lodash/isEmpty';

import { getDecimals } from 'common/utils/helpers';

export const getBagYearOptions = () => {
    const yearLabel = i18next
        .t('Year', {
            count: 2,
        })
        .toLowerCase();
    return [
        {
            label: `2 ${yearLabel}`,
            value: 2,
        },
        {
            label: `5  ${yearLabel}`,
            value: 5,
        },
        {
            label: `10  ${yearLabel}`,
            value: 10,
        },
        {
            label: `15  ${yearLabel}`,
            value: 15,
        },
        {
            label: `20  ${yearLabel}`,
            value: 20,
        },
        {
            label: `25  ${yearLabel}`,
            value: 25,
        },
    ];
};

export const getCustomRateConcepts = (concepts) => {
    if (concepts.length) {
        return concepts.reduce((acc, current) => {
            return { ...acc, [current.id]: `${current.price}` };
        }, {});
    }
    return {};
};

export const getIsDisabledButton = (
    canModifyExchangeType,
    isLocked,
    oppositeCurrency,
    typeChangeOffer
) => {
    if (isLocked || !canModifyExchangeType || isEmpty(oppositeCurrency)) {
        return true;
    }

    if (!isEmpty(oppositeCurrency) && oppositeCurrency.dollar_price === 0) {
        return true;
    }

    if (
        !isEmpty(oppositeCurrency) &&
        oppositeCurrency.dollar_price === parseFloat(typeChangeOffer)
    ) {
        return true;
    }
    return false;
};

export const getPlansToSelect = (plans) => {
    return [
        { label: i18next.t('Select a plan'), value: '' },
        ...plans.map((item) => ({
            label: item.name,
            value: item.id,
            custom_offer_template: item.custom_offer_template,
        })),
    ];
};

export const getPriceByWattMin = (
    currencyIso,
    maximumDiscount,
    subtotal,
    systemSize,
    typeChangeOffer
) => {
    const percentageValue = 1 - maximumDiscount / 100;
    const percentage = percentageValue > 0 ? percentageValue : 0;
    let priceByWattMin = 0;
    if (currencyIso !== 'USD') {
        priceByWattMin =
            (subtotal / (systemSize * 1000) / typeChangeOffer) * percentage;
    } else {
        priceByWattMin = (subtotal / (systemSize * 1000)) * percentage;
    }
    return priceByWattMin.toFixed(getDecimals(priceByWattMin));
};

export const getSubtotalMin = (maximumDiscount, subtotal) => {
    const percentageValue = 1 - maximumDiscount / 100;
    const percentage = percentageValue > 0 ? percentageValue : 0;
    const subtotalMin = subtotal * percentage;
    return subtotalMin.toFixed(getDecimals(subtotalMin));
};

export const getDefaultConceptsRate = (concepts) => {
    if (!isEmpty(concepts))
        return concepts.filter((concept) => concept.is_default);

    return [];
};

export const getNonDefaultConceptsRate = (concepts) => {
    if (!isEmpty(concepts))
        return concepts.filter((concept) => !concept.is_default);

    return [];
};
