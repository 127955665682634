import axios from 'axios';
import get from 'lodash/get';
import { serialize } from 'object-to-formdata';

import { handleInterceptorErrors } from 'common/utils/helpers/api';
import {
    getUserSettings,
    getIanaTimezoneKey,
    getRuntimeLanguage,
} from 'common/utils/helpers/multiregion';

const CancelToken = axios.CancelToken;
let cancellableRequests = {};

const API = axios.create({
    baseURL: process.env.REACT_APP_AFTER_SALES_API_URL,
});

API.cancelAll = () => {
    Object.keys(cancellableRequests).forEach((key) => {
        cancellableRequests[key]();
        delete cancellableRequests[key];
    });
};

API.interceptors.request.use((req) => {
    const token = localStorage.getItem('token');
    const customId = `${new Date().getTime()}`;
    const settings = getUserSettings();
    const language = get(settings, 'language.key', getRuntimeLanguage());
    const timezone = get(settings, 'timezone.key', getIanaTimezoneKey());
    req.headers['Accept-Language'] = language;
    req.headers['X-Timezone-IANA'] = timezone;
    req.headers['Authorization'] = `Bearer ${token}`;

    if (!req.headers['Content-Type'])
        req.headers['Content-Type'] = 'multipart/form-data';

    if (
        req.headers['Content-Type'] === 'multipart/form-data' &&
        ['post', 'put', 'patch'].includes(req.method)
    )
        req.data = serialize(req.data, { noFilesWithArrayNotation: true });

    req.customId = customId;
    req.cancelToken = new CancelToken(function executor(c) {
        cancellableRequests[customId] = c;
    });

    return req;
});

API.interceptors.response.use(
    (res) => {
        delete cancellableRequests[res.config.customId];
        return res;
    },
    (error) => handleInterceptorErrors(cancellableRequests, error, true)
);

export default API;
