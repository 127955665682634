import { getItems } from 'common/api/v2/proposalCurrencies';

import {
    FETCH_CURRENCIES,
    FETCH_CURRENCIES_FAILURE,
    FETCH_CURRENCIES_SUCCESS,
} from '../actionTypes';
import { proposalIndicatorsActions } from '../reducer';

export default (proposalId) => (dispatch) => {
    dispatch(proposalIndicatorsActions[FETCH_CURRENCIES]());

    getItems(proposalId)
        .then((response) =>
            dispatch(
                proposalIndicatorsActions[FETCH_CURRENCIES_SUCCESS](
                    response.data.data
                )
            )
        )
        .catch((error) =>
            dispatch(proposalIndicatorsActions[FETCH_CURRENCIES_FAILURE](error))
        );
};
