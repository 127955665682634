import { createSelector } from 'reselect';

import { NAME } from './constants';

export const getModel = (state) => state[NAME];

export const getInitialValues = createSelector(
    getModel,
    (model) => model.initialValues
);

/* Eliminar rol */
export const getDeleteItem = createSelector(
    getModel,
    (model) => model.deleteItem
);

export const getIsDeletingItem = createSelector(
    getDeleteItem,
    (deleteItem) => deleteItem.isDeleting
);

export const getErrorsDeleteItem = createSelector(
    getDeleteItem,
    (deleteItem) => deleteItem.errors
);

/* Roles */
export const getFetchItems = createSelector(
    getModel,
    (model) => model.fetchItems
);
export const getIsFetchingItems = createSelector(
    getFetchItems,
    (model) => model.isFetching
);
export const getFetchItemsData = createSelector(
    getFetchItems,
    (model) => model?.data?.data
);
export const getFetchItemsPagination = createSelector(
    getFetchItems,
    (model) => model?.data?.pagination
);

/* categories-settings */
export const getCategoriesSettingsModel = createSelector(
    getModel,
    (model) => model.categoriesSettings
);

export const getCategoriesSettingsIsFetching = createSelector(
    getCategoriesSettingsModel,
    (model) => model.isFetching
);

export const getCategoriesSettingsData = createSelector(
    getCategoriesSettingsModel,
    (model) => model.data || {}
);

export const getCategoriesSettingsErrors = createSelector(
    getCategoriesSettingsModel,
    (model) => model.errors
);

/* Niveles del rol */
export const getRoleLevelsModel = createSelector(
    getModel,
    (model) => model.fetchRoleLevels
);

export const getIsFetchingRoleLevels = createSelector(
    getRoleLevelsModel,
    (model) => model.isFetching
);

export const getRoleLevelsData = createSelector(
    getRoleLevelsModel,
    (model) => model.data || {}
);

export const getRoleLevelsErrors = createSelector(
    getRoleLevelsModel,
    (model) => model.errors
);

/* Detalle del rol */
export const getRoleDetailsModel = createSelector(
    getModel,
    (model) => model.fetchRoleDetails
);

export const getIsFetchingRoleDetails = createSelector(
    getRoleDetailsModel,
    (model) => model.isFetching
);

export const getRoleDetailsData = createSelector(
    getRoleDetailsModel,
    (model) => model.data || {}
);

export const getRoleDetailsErrors = createSelector(
    getRoleDetailsModel,
    (model) => model.errors
);

/* Detalle del nivel de rol */
export const getRoleLevelDetailsModel = createSelector(
    getModel,
    (model) => model.fetchRoleLevelDetails
);

export const getIsFetchingRoleLevelDetails = createSelector(
    getRoleLevelDetailsModel,
    (model) => model.isFetching
);

export const getRoleLevelDetailsData = createSelector(
    getRoleLevelDetailsModel,
    (model) => model.data || {}
);

export const getRoleLevelDetailsErrors = createSelector(
    getRoleLevelDetailsModel,
    (model) => model.errors
);

export const getSaveItem = createSelector(getModel, (model) => model.saveItem);

export const getIsSavingItem = createSelector(
    getSaveItem,
    (saveItem) => saveItem.isSaving
);

export const getDataSaveItem = createSelector(
    getSaveItem,
    (saveItem) => saveItem.data
);

export const getErrorsSaveItem = createSelector(
    getSaveItem,
    (saveItem) => saveItem.errors
);

export const getisOpenModal = createSelector(
    getModel,
    (model) => model.isOpenModal
);

export const getIsNewRecord = createSelector(
    getModel,
    (model) => model.isNewRecord
);
