import React from 'react';

import EditIcon from '@mui/icons-material/Edit';
import LockIcon from '@mui/icons-material/Lock';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { Box, Button } from 'sunwise-ui';

import DropdownCardMenu from 'common/components/cards/DropdownCardMenu';
import { Switch } from 'common/components/form';
import { PERMISSION_LIST, ROLE_LEVELS } from 'common/constants/permissionsV2';
import withPermissions from 'common/hocs/withPermissions';

import * as actions from '../actions';

const renderOptions = ({
    canDelete,
    canModify,
    handleChangeSwitch,
    handleClickUpdate,
    is_enabled,
    isOwner,
    row,
    t,
} = {}) => {
    if (isOwner)
        return (
            <Button
                color="secondary"
                startIcon={<LockIcon />}
                onClick={() => handleClickUpdate(row)}
                variant="text"
            >
                {t('View user')}
            </Button>
        );
    else
        return (
            <Box>
                <Switch
                    checked={is_enabled}
                    disabled={!canDelete}
                    onChange={() => handleChangeSwitch(row)}
                    sx={{ mr: 0 }}
                />
                <DropdownCardMenu
                    items={[
                        {
                            handleClick: () => handleClickUpdate(row),
                            icon: <EditIcon fontSize="small" />,
                            label: t('Edit'),
                        },
                    ]}
                    visible={canModify}
                />
            </Box>
        );
};

const ActionsFormatter = ({
    canDelete,
    canModify,
    handleChangeSwitch,
    handleClickUpdate,
    row,
}) => {
    const { t } = useTranslation();
    const { is_enabled, role_level } = row;
    const isOwner = role_level === ROLE_LEVELS.OWNER;

    return (
        <Box
            sx={{
                alignItems: 'center',
                display: 'flex',
                flexWrap: 'wrap',
                justifyContent: 'end',
                gap: '4px',
                width: '100%',
            }}
        >
            {renderOptions({
                canDelete,
                canModify,
                handleChangeSwitch,
                handleClickUpdate,
                is_enabled,
                isOwner,
                row,
                t,
            })}
        </Box>
    );
};

const mapDispatchToProps = (dispatch) => ({
    handleChangeSwitch: (item) => dispatch(actions.activateItem(item)),
    handleClickUpdate: (item) => dispatch(actions.prepareUpdate(item)),
});

ActionsFormatter.propTypes = {
    canDelete: PropTypes.bool,
    canModify: PropTypes.bool,
    handleChangeSwitch: PropTypes.func,
    handleClickUpdate: PropTypes.func,
    row: PropTypes.object,
};

export default compose(
    connect(null, mapDispatchToProps),
    withPermissions(PERMISSION_LIST.COMPANY_USERS_PERMISSION)
)(ActionsFormatter);
