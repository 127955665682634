import React, { useEffect, useState } from 'react';

import { styled, useTheme } from '@mui/material/styles';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import Chart from 'react-apexcharts';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Box } from 'sunwise-ui';

import { useBreakpoint } from 'common/hooks';
import { handleProposalEnergyChartConfig } from 'common/utils/helpersChart';
import { scroll } from 'common/utils/mixins';

import * as proposalGeneratorOneColumnSelectors from '../../proposalGeneratorOneColumn/selectors';

const ChartWrapper = styled(Box)(({ theme }) => ({
    height: 330,
    overflowX: 'scroll',
    overflowY: 'hidden',
    width: '100%',
    [theme.breakpoints.up(900)]: {
        justifyContent: 'center',
        overflowX: 'hidden',
    },
    ...scroll.object,
}));

const DailyChart = ({
    consumptionProjection,
    currencyLocale,
    solarSimulationData,
}) => {
    const [chartConfig, setChartConfig] = useState({
        options: {},
        series: [{ data: [], name: '' }],
    });
    const [chartWidth, setChartWidth] = useState(800);
    const container = React.useRef(null);
    const breakpoint = useBreakpoint();
    const theme = useTheme();

    const {
        consumptionHistory = [],
        firstDay,
        realIndex = {},
        zeroExport,
    } = consumptionProjection || {};

    useEffect(() => {
        switch (breakpoint) {
            case 'xs':
                setChartWidth(600);
                break;
            case 'sm':
                setChartWidth(750);
                break;
            default: {
                const width = container?.current?.offsetWidth || 800;
                setChartWidth(width);
                break;
            }
        }
    }, [breakpoint]);

    useEffect(() => {
        if (!isEmpty(solarSimulationData) && !isEmpty(consumptionHistory))
            handleChartChange({
                days: [firstDay],
                option: 3,
                period: 0,
                type: 'area',
                weeks: [0],
                year: 0,
            });
    }, [firstDay, consumptionHistory, solarSimulationData]);

    const handleChartChange = (configOptions) => {
        const { options, series } = handleProposalEnergyChartConfig({
            configOptions,
            consumptionHistory,
            countryCurrencyLocale: currencyLocale,
            isSelfConsumption: true,
            realIndex,
            solarSimulationData,
            zeroExport,
        });

        setChartConfig(() => ({
            options: {
                ...options,
                tooltip: { ...options.tooltip, followCursor: true },
            },
            series,
        }));
    };

    return (
        <ChartWrapper ref={container}>
            <Chart
                height={330}
                options={{
                    ...chartConfig.options,
                    theme: { mode: theme.palette.mode },
                }}
                series={chartConfig.series}
                type="line"
                width={chartWidth}
            />
        </ChartWrapper>
    );
};

DailyChart.propTypes = {
    consumptionProjection: PropTypes.object,
    currencyLocale: PropTypes.string,
    solarSimulationData: PropTypes.object,
};

const mapStateToProps = createStructuredSelector({
    consumptionProjection:
        proposalGeneratorOneColumnSelectors.getConsumptionProjectionData,
    solarSimulationData:
        proposalGeneratorOneColumnSelectors.getSolarSimulationData,
});

export default connect(mapStateToProps)(DailyChart);
