import React, { useEffect, useState } from 'react';

import cloneDeep from 'lodash/cloneDeep';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
    Alert,
    BottomActions,
    Box,
    Button,
    Tab,
    TabPanel,
    Tabs,
} from 'sunwise-ui';

import { HOURS_IN_DAY, MONTHS_IN_YEAR } from 'common/constants';
import { useBreakpoint } from 'common/hooks';
import yupResolver from 'common/utils/yupResolver';

import { BATTERY_STATUS } from '../constants';
import validate from '../validate';

import DistributionSection from './DistributionSection';

const baseArray = new Array(HOURS_IN_DAY * MONTHS_IN_YEAR).fill({
    value: 0,
    color: BATTERY_STATUS[0].color,
});
const defaultDistribution = {
    weekday: baseArray,
    saturday: baseArray,
    sunday: baseArray,
};

const Card = ({ handleOnSave, initialValues, isDisabled, rateTiers }) => {
    const [selectedTab, setSelectedTab] = useState('tab-ts-weekday');
    const [hourlyDistribution, setHourlyDistribution] =
        useState(defaultDistribution);

    const { control, getValues, handleSubmit, reset, setValue } = useForm({
        defaultValues: initialValues,
        resolver: yupResolver(validate),
    });

    const { t } = useTranslation();
    const breakpoint = useBreakpoint();

    useEffect(() => {
        if (!isEmpty(initialValues?.battery_hourly_status)) {
            const battery_hourly_status = cloneDeep(
                initialValues?.battery_hourly_status
            );
            const select = getValues('select');
            reset({ select, battery_hourly_status });
            setHourlyDistribution({ select, ...battery_hourly_status });
        }
    }, [initialValues]);

    const handleOnChangeTab = (_, newValue) => setSelectedTab(newValue);

    const options = BATTERY_STATUS.map((status) => ({
        label: t(status.label),
        value: status.value,
    }));

    if (['xs', 'sm'].includes(breakpoint))
        return (
            <Alert severity="warning">
                {t('This view is optimized for devices with larger resolution')}
            </Alert>
        );

    return (
        <Box px={2} py={1}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs
                    onChange={handleOnChangeTab}
                    scrollButtons="auto"
                    value={selectedTab}
                    variant="scrollable"
                >
                    <Tab
                        id="tab-ts-weekday"
                        key="tab-ts-weekday"
                        label={t('Monday to Friday')}
                        value="tab-ts-weekday"
                    />
                    <Tab
                        id="tab-ts-saturday"
                        key="tab-ts-saturday"
                        label={t('Saturday')}
                        value="tab-ts-saturday"
                    />
                    <Tab
                        id="tab-ts-sunday"
                        key="tab-ts-sunday"
                        label={t('Sunday')}
                        value="tab-ts-sunday"
                    />
                </Tabs>
            </Box>

            <TabPanel
                key="tab-ts-weekday"
                selectedTab={selectedTab}
                value="tab-ts-weekday"
            >
                <DistributionSection
                    control={control}
                    disabled={isDisabled}
                    getValues={getValues}
                    hourlyDistribution={hourlyDistribution?.weekday}
                    options={options}
                    rateTiers={rateTiers}
                    setHourlyDistribution={setHourlyDistribution}
                    setValue={setValue}
                    weekDay="weekday"
                />
            </TabPanel>
            <TabPanel
                key="tab-ts-saturday"
                selectedTab={selectedTab}
                value="tab-ts-saturday"
            >
                <DistributionSection
                    control={control}
                    disabled={isDisabled}
                    getValues={getValues}
                    hourlyDistribution={hourlyDistribution?.saturday}
                    options={options}
                    rateTiers={rateTiers}
                    setHourlyDistribution={setHourlyDistribution}
                    setValue={setValue}
                    weekDay="saturday"
                />
            </TabPanel>
            <TabPanel
                key="tab-ts-sunday"
                selectedTab={selectedTab}
                value="tab-ts-sunday"
            >
                <DistributionSection
                    control={control}
                    disabled={isDisabled}
                    getValues={getValues}
                    hourlyDistribution={hourlyDistribution?.sunday}
                    options={options}
                    rateTiers={rateTiers}
                    setHourlyDistribution={setHourlyDistribution}
                    setValue={setValue}
                    weekDay="sunday"
                />
            </TabPanel>

            <BottomActions>
                <Box sx={{ mt: 2 }}>
                    <Button
                        disabled={isDisabled}
                        onClick={handleSubmit(handleOnSave)}
                        type="button"
                    >
                        {t('Save')}
                    </Button>
                </Box>
            </BottomActions>
        </Box>
    );
};

Card.propTypes = {
    handleOnSave: PropTypes.func,
    initialValues: PropTypes.object,
    isDisabled: PropTypes.bool,
    rateTiers: PropTypes.array,
};

export default Card;
