import i18next from 'i18next';

import { createConsumption } from 'common/api/v2/sunwiseCSV';
import alerts from 'common/modules/alerts';
import { showReponseErrorsAsAlert } from 'common/utils/helpers';

import * as projectConsumptionsMocalSelectors from '../../projectConsumptionsModal/selectors';
import {
    GET_CSV_INFO,
    GET_CSV_INFO_FAILURE,
    GET_CSV_INFO_SUCCESS,
} from '../actionTypes';
import { importConsumptionsModalActions } from '../reducer';

export default ({ file, setHasMissingFields, setValue }) =>
    (dispatch, getState) => {
        if (!file) return;

        dispatch(importConsumptionsModalActions[GET_CSV_INFO]());

        dispatch(
            alerts.actions.show({
                confirmText: i18next.t('Back'),
                hideButtons: true,
                messages: [
                    i18next.t(
                        'Please wait a moment as the information is processed'
                    ),
                ],
                title: i18next.t('Loading').concat('...'),
                type: alerts.ALERT_TYPE_ALERT,
                variant: 'info',
            })
        );

        createConsumption({ file })
            .then((response) => {
                const data = response?.data?.data;
                dispatch(
                    importConsumptionsModalActions[GET_CSV_INFO_SUCCESS](data)
                );

                const ratesDictionary =
                    projectConsumptionsMocalSelectors.getRatesDictionary(
                        getState()
                    );

                setValue('rate', data?.rate?.id);
                setValue('zip_code', data?.zip_code);

                setHasMissingFields({
                    rate: !ratesDictionary?.[data?.rate?.id],
                    zipCode: !data?.zip_code,
                });

                dispatch(alerts.actions.close());
            })
            .catch((error) => {
                dispatch(alerts.actions.close());
                dispatch(
                    importConsumptionsModalActions[GET_CSV_INFO_FAILURE](error)
                );
                setValue('file', null);

                showReponseErrorsAsAlert(dispatch, error?.response);
            });
    };
