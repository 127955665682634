import { getItemsByParent } from 'common/api/v1/politicalDivisions';

import {
    FETCH_POLITICAL_DIVISIONS2,
    FETCH_POLITICAL_DIVISIONS2_FAILURE,
    FETCH_POLITICAL_DIVISIONS2_SUCCESS,
} from '../actionTypes';
import { projectCommOfferTableActions } from '../reducer';

export default (str) => (dispatch) => {
    dispatch(projectCommOfferTableActions[FETCH_POLITICAL_DIVISIONS2]());

    getItemsByParent(str)
        .then((response) =>
            dispatch(
                projectCommOfferTableActions[
                    FETCH_POLITICAL_DIVISIONS2_SUCCESS
                ](response.data.data)
            )
        )
        .catch((error) =>
            dispatch(
                projectCommOfferTableActions[
                    FETCH_POLITICAL_DIVISIONS2_FAILURE
                ](error?.response?.data?.errors)
            )
        );
};
