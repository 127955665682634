import React, { useEffect, useRef, useState } from 'react';

import SpeedIcon from '@mui/icons-material/Speed';
import { useTheme } from '@mui/material/styles';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import Chart from 'react-apexcharts';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Box, Grid } from 'sunwise-ui';

import { ChartControls, PeriodSelector } from 'common/components/charts';
import KeyIndicator from 'common/components/KeyIndicator';
import {
    DEFAULT_INITIAL_DATE,
    DEFAULT_MONTH_FINAL_DATE,
} from 'common/constants';
import { useBreakpoint } from 'common/hooks';
import { differenceInWeeksDate, parseDate } from 'common/utils/dates';
import { numberFormat } from 'common/utils/helpers';
import { getEnergyChartConfig } from 'common/utils/helpersChart';
import { scroll } from 'common/utils/mixins';

import { getEnergyChartSettings } from '../helpers';
import * as selectors from '../selectors';

const ChartWrapper = styled(Box)`
    overflow-x: scroll;
    overflow-y: hidden;
    width: 100%;

    ${scroll.custom()};
`;

const handleChartConfig = ({
    consumptionProfile,
    countryCurrencyLocale,
    monthskwh,
    monthskw,
    selectedDays,
    selectedOption,
    selectedPeriod,
    selectedWeeks,
}) => {
    const dataKw = monthskw.map((month) => month.value);
    const dataKwh = monthskwh.map((month) => month.value);
    const categories = monthskwh.map((month) => month.label.toUpperCase());
    const baseChartConfig = getEnergyChartSettings(
        dataKw,
        dataKwh,
        categories,
        countryCurrencyLocale,
        monthskwh
    );

    if (selectedOption === 0) return baseChartConfig;

    const summary = monthskwh.map((period) => ({
        initial_date: period.initial_date,
        final_date: period.final_date,
        total: { value: period.value },
    }));

    return getEnergyChartConfig({
        baseChartConfig,
        monthskwh,
        consumptionProfile,
        countryCurrencyLocale,
        dataKw,
        dataKwh,
        selectedDays,
        selectedOption,
        selectedPeriod,
        selectedWeeks,
        summary,
    });
};

const EnergyChart = ({
    consumptionProfile,
    countryCurrencyLocale,
    monthskw,
    monthskwh,
}) => {
    const { t } = useTranslation();
    const [chartConfig, setChartConfig] = useState({
        options: {},
        series: [{ data: [], name: '' }],
    });
    const [chartType, setChartType] = useState('area');
    const [chartWidth, setChartWidth] = useState('98%');
    const [selectedDays, setSelectedDays] = useState([1]);
    const [selectedOption, setSelectedOption] = useState(0);
    const [selectedPeriod, setSelectedPeriod] = useState(0);
    const [selectedWeeks, setSelectedWeeks] = useState([0]);
    const breakpoint = useBreakpoint();
    const container = useRef(null);
    const theme = useTheme();

    const weeksToSelect = differenceInWeeksDate(
        parseDate(
            monthskwh?.[selectedPeriod]?.final_date ?? DEFAULT_MONTH_FINAL_DATE,
            'dd/MM/yyyy'
        ),
        parseDate(
            monthskwh?.[selectedPeriod]?.initial_date ?? DEFAULT_INITIAL_DATE,
            'dd/MM/yyyy'
        )
    );

    useEffect(() => {
        const margin = 32;
        switch (breakpoint) {
            case 'xs':
                setChartWidth(600);
                break;
            case 'sm':
                setChartWidth(800);
                break;
            default: {
                const width = container?.current
                    ? container.current?.offsetWidth - margin
                    : '98%';
                setChartWidth(width);
                break;
            }
        }
    }, [breakpoint]);

    useEffect(() => {
        const { options, series } = handleChartConfig({
            consumptionProfile,
            countryCurrencyLocale,
            monthskwh,
            monthskw,
            selectedDays,
            selectedOption,
            selectedPeriod,
            selectedWeeks,
        });

        setChartConfig({ options, series });
    }, []);

    useEffect(() => {
        const newSelectedWeeks = selectedWeeks.filter(
            (week) => week < weeksToSelect
        );

        handleChartChange({
            consumptionProfile,
            countryCurrencyLocale,
            monthskwh,
            monthskw,
            selectedDays,
            selectedOption,
            selectedPeriod,
            selectedWeeks: newSelectedWeeks,
        });
        setSelectedWeeks(newSelectedWeeks);
    }, [weeksToSelect]);

    const handleChartChange = ({
        consumptionProfile,
        countryCurrencyLocale,
        monthskwh,
        monthskw,
        selectedDays,
        selectedOption,
        selectedPeriod,
        selectedWeeks,
    } = {}) => {
        const { options, series } = handleChartConfig({
            consumptionProfile,
            countryCurrencyLocale,
            monthskwh,
            monthskw,
            selectedDays,
            selectedOption,
            selectedPeriod,
            selectedWeeks,
        });
        setChartConfig(() => ({ options, series }));
    };

    const handleOnChangeSelectedOption = (e) => {
        const value = parseInt(e.target.value);
        setSelectedOption(value);
        handleChartChange({
            consumptionProfile,
            countryCurrencyLocale,
            monthskwh,
            monthskw,
            selectedDays,
            selectedOption: value,
            selectedPeriod,
            selectedWeeks,
        });
    };

    const handleOnChangeSelectedPeriod = (e) => {
        const value = parseInt(e.target.value);
        setSelectedPeriod(value);
        handleChartChange({
            consumptionProfile,
            countryCurrencyLocale,
            monthskwh,
            monthskw,
            selectedDays,
            selectedOption,
            selectedPeriod: value,
            selectedWeeks,
        });
    };

    const handleOnChangeSelectedDays = (e) => {
        let value = e.target.value;
        if (value[value.length - 1] === 'all')
            value = selectedDays.length === 7 ? [] : [0, 1, 2, 3, 4, 5, 6];
        setSelectedDays(value);
        handleChartChange({
            consumptionProfile,
            countryCurrencyLocale,
            monthskwh,
            monthskw,
            selectedDays: value,
            selectedOption,
            selectedPeriod,
            selectedWeeks,
        });
    };

    const handleOnChangeSelectedWeeks = (e) => {
        let value = e.target.value;
        if (value[value.length - 1] === 'all')
            value =
                selectedWeeks.length === weeksToSelect
                    ? []
                    : [...Array(weeksToSelect).keys()];
        setSelectedWeeks(value);
        handleChartChange({
            consumptionProfile,
            countryCurrencyLocale,
            monthskwh,
            monthskw,
            selectedDays,
            selectedOption,
            selectedPeriod,
            selectedWeeks: value,
        });
    };

    const getAnnualConsumption = (monthskwh) =>
        monthskwh.reduce((acc, cur) => {
            return acc + cur.value;
        }, 0);

    const getPeriodConsumption = (data) =>
        data.reduce((acc, cur) => {
            return acc + cur;
        }, 0);

    const getKeyIndicatorTitle = (value) => {
        switch (value) {
            case 1:
                return t('Monthly consumption');
            case 2:
                return t('Weekly consumption');
            case 3:
                return t('Daily consumption');
            default:
                '';
        }
    };

    return (
        <>
            {consumptionProfile && (
                <ChartControls
                    chartType={chartType}
                    chartTypeOptions={['area', 'bar']}
                    handleOnChangePeriod={handleOnChangeSelectedOption}
                    handleOnChangeSelectedDays={handleOnChangeSelectedDays}
                    handleOnChangeSelectedWeeks={handleOnChangeSelectedWeeks}
                    hideChangeTypeButtons={selectedOption === 0 ? true : false}
                    monthSelector={
                        <>
                            {[1, 2, 3].includes(selectedOption) && (
                                <PeriodSelector
                                    consumptionHistory={monthskwh}
                                    onChange={handleOnChangeSelectedPeriod}
                                    showHasDropdown
                                    value={selectedPeriod}
                                />
                            )}
                        </>
                    }
                    selectedDays={selectedDays}
                    selectedOption={selectedOption}
                    selectedWeeks={selectedWeeks}
                    setChartType={setChartType}
                    weeksToSelect={weeksToSelect}
                />
            )}

            <ChartWrapper>
                <Chart
                    height="250"
                    key={`chart-option-${selectedOption}-${selectedPeriod}-${chartType}`}
                    options={{
                        ...chartConfig.options,
                        theme: { mode: theme.palette.mode },
                    }}
                    series={chartConfig.series}
                    type={selectedOption === 0 ? 'bar' : chartType}
                    width={chartWidth}
                />
            </ChartWrapper>

            <Grid container>
                <Grid item xs>
                    <KeyIndicator
                        svgIcon={<SpeedIcon />}
                        title={t('Annual consumption')}
                        value={numberFormat(getAnnualConsumption(monthskwh), {
                            decimals: 0,
                            locale: countryCurrencyLocale,
                            style: 'decimal',
                            unit: 'kWh',
                        })}
                    />
                </Grid>

                {[1, 2, 3].includes(selectedOption) && (
                    <Grid item xs>
                        <KeyIndicator
                            svgIcon={<SpeedIcon />}
                            title={getKeyIndicatorTitle(selectedOption)}
                            value={numberFormat(
                                getPeriodConsumption(
                                    chartConfig.series[0].data
                                ),
                                {
                                    decimals: 0,
                                    locale: countryCurrencyLocale,
                                    style: 'decimal',
                                    unit: 'kWh',
                                }
                            )}
                        />
                    </Grid>
                )}
            </Grid>
        </>
    );
};

EnergyChart.propTypes = {
    consumptionProfile: PropTypes.array,
    countryCurrencyLocale: PropTypes.string,
    monthskw: PropTypes.array,
    monthskwh: PropTypes.array,
};

const mapStateToProps = createStructuredSelector({
    consumptionProfile: selectors.getConsumptionProfileFormatted,
});

export default connect(mapStateToProps, null)(EnergyChart);
