import React, { useEffect, useState } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import NewReleasesIcon from '@mui/icons-material/NewReleases';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { createStructuredSelector } from 'reselect';
import { Box, Button, IconButton, Snackbar, Typography } from 'sunwise-ui';

import * as actions from './actions';
import * as selectors from './selectors';

const Container = ({
    fetchVersion,
    hasNewVersionAvailable,
    isFetchingVersion,
    location,
    setNewVersionAvailable,
}) => {
    const { t } = useTranslation();
    const [open, setOpen] = useState(hasNewVersionAvailable);
    const route = location.pathname;

    useEffect(() => {
        fetchVersion();
    }, [route]);

    useEffect(() => {
        if (hasNewVersionAvailable) setOpen(true);
    }, [hasNewVersionAvailable]);

    const handleClose = () => {
        setNewVersionAvailable(false);
        setOpen(false);
    };

    const handleReload = () => {
        setNewVersionAvailable(false);
        window.location.reload();
        setOpen(false);
    };

    if (isFetchingVersion) return null;

    return (
        <Snackbar
            action={
                <IconButton
                    color="inherit"
                    onClick={() => handleClose()}
                    size="small"
                >
                    <CloseIcon fontSize="small" />
                </IconButton>
            }
            anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
            open={open}
            message={
                <Box display="flex" onClick={() => handleReload()}>
                    <Box
                        sx={{
                            alignItems: 'center',
                            display: 'flex',
                            justifyContent: 'center',
                            pr: 2,
                        }}
                    >
                        <NewReleasesIcon />
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                        }}
                    >
                        <Typography fontWeight="bold" variant="caption">
                            {t('A new version was released')}.
                        </Typography>
                        <Typography sx={{ mb: 1 }} variant="caption">
                            {t('Improvements to offer you a better service')}
                        </Typography>
                        <Button
                            color="secondary"
                            size="small"
                            variant="outlined"
                        >
                            {t('Click here to update')}
                        </Button>
                    </Box>
                </Box>
            }
            sx={{
                '& .MuiPaper-root': {
                    backgroundColor: '#ff9a00',
                    color: '#fff',
                },
            }}
        />
    );
};

const mapStateToProps = createStructuredSelector({
    hasNewVersionAvailable: selectors.getHasNewVersionAvailable,
    isFetchingVersion: selectors.getIsFetchingVersion,
});

const mapDispatchToProps = (dispatch) => ({
    fetchVersion: () => dispatch(actions.fetchVersion()),
    setNewVersionAvailable: (value) =>
        dispatch(actions.setNewVersionAvailable(value)),
});

Container.propTypes = {
    fetchVersion: PropTypes.func,
    hasNewVersionAvailable: PropTypes.bool,
    isFetchingVersion: PropTypes.bool,
    location: PropTypes.object,
    setNewVersionAvailable: PropTypes.func,
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withRouter
)(Container);
