import i18next from 'i18next';
import { isEmpty, isNull } from 'lodash';

import {
    PERMISSION_LEVELS,
    PERMISSION_LIST,
    ROLE_LEVELS,
} from 'common/constants/permissionsV2';

import { PROPOSER_CATEGORY_UUID } from './constants';

const CONTACT_PERMISSIONS = [
    PERMISSION_LIST.CONTACT_PROJECTS_PERMISSION,
    PERMISSION_LIST.CONTACT_LOCATION_PERMISSION,
    PERMISSION_LIST.SUPPORT_CONTACTS_PERMISSION,
    PERMISSION_LIST.PERSONAL_DOCUMENTS_PERMISSION,
    PERMISSION_LIST.TRACKING_CONTACTS_PERMISSION,
];
const PROJECT_PERMISSIONS = [
    PERMISSION_LIST.CONSUMPTION_HISTORY_PERMISSION,
    PERMISSION_LIST.COMMERCIAL_OFFER_DOCUMENTS_PERMISSION,
    PERMISSION_LIST.PROJECT_LOCATION_PERMISSION,
    PERMISSION_LIST.CONTACT_PROPOSALS_PERMISSION,
    PERMISSION_LIST.PROPOSAL_SUMMARIES_PERMISSION,
    PERMISSION_LIST.AFTER_SALES_PERMISSION,
    PERMISSION_LIST.MONITORING_PERMISSION,
];
const PROPOSER_PERMISSIONS = [
    PERMISSION_LIST.PROPOSAL_PLAN_PERMISSION,
    PERMISSION_LIST.PROPOSAL_PRICE_PER_WATT_PERMISSION,
    PERMISSION_LIST.PROPOSAL_SUBTOTAL_PERMISSION,
    PERMISSION_LIST.PROPOSAL_DISCOUNTS_PERMISSION,
    PERMISSION_LIST.PROPOSAL_CURRENCY_TYPE_PERMISSION,
    PERMISSION_LIST.PROPOSAL_EXCHANGE_TYPE_PERMISSION,
    PERMISSION_LIST.PROPOSAL_ROI_TAX_INCENTIVE_PERMISSION,
    PERMISSION_LIST.PROPOSAL_ROI_SOLAR_DEGRADATION_PERMISSION,
    PERMISSION_LIST.PROPOSAL_RATES_DAP_PERMISSION,
    PERMISSION_LIST.RATES_ANNUAL_CFE_PERMISSION,
    PERMISSION_LIST.PROPOSAL_IVA_CFE_PERMISSION,
    PERMISSION_LIST.PROPOSAL_STOCK_EXCHANGE_PERMISSION,
    PERMISSION_LIST.PROPOSAL_PANELS_PERMISSION,
    PERMISSION_LIST.PROPOSAL_INVERTERS_PERMISSION,
    PERMISSION_LIST.PROPOSAL_ACCESORIES_PERMISSION,
    PERMISSION_LIST.PROPOSAL_STRUCTURES_PERMISSION,
    PERMISSION_LIST.PROPOSAL_WORKFORCE_PERMISSION,
    PERMISSION_LIST.PROPOSAL_ADITIONALS_PERMISSION,
    PERMISSION_LIST.PROPOSAL_SOLAR_GENERATION_PERMISSION,
    PERMISSION_LIST.PROPOSAL_TAXES_PERMISSION,
    PERMISSION_LIST.PROPOSAL_SOLAR_GENERATION_UPLOAD_FILE_PERMISSION,
    PERMISSION_LIST.PROPOSAL_ENERGY_BACKUP_PERMISSION,
    PERMISSION_LIST.PROPOSAL_SOLAR_CONSUMPTION_PERMISSION,
    PERMISSION_LIST.PROPOSAL_PRODUCTS_MARGIN_PERMISSION,
    PERMISSION_LIST.PROPOSAL_PRODUCTS_COST_PERMISSION,
    PERMISSION_LIST.PROPOSAL_PRODUCTS_DISCOUNT_PERMISSION,
    PERMISSION_LIST.PROPOSAL_TIMESHIFTING_PERMISSION,
    PERMISSION_LIST.PANEL_LAYOUT_PERMISSION,
    PERMISSION_LIST.FINANCING_PERMISSION,
    PERMISSION_LIST.PROPOSAL_ALLIANCE_PRODUCT_PERMISSION,
    PERMISSION_LIST.EDIT_PROPOSAL_PREVIEW_PERMISSION,
    PERMISSION_LIST.REQUEST_ALLIANCE_PRODUCT_FOR_PROPOSAL_PERMISSION,
];

const getGroups = (category) => {
    return category.groups?.map((item) => ({
        addon_name: item?.addon_name,
        category: category?.id,
        id: item?.id,
        is_enabled: item?.is_enabled,
        name: item?.name,
        permission_level: item?.permission_level,
    }));
};

const getLabelOfPermisionLevel = (value) => {
    const options = {
        none: i18next.t('None'),
        view: i18next.t('View'),
        modify: i18next.t('Edit'),
        all: i18next.t('All text', { count: 2 }),
    };
    return options[value];
};

const getPermissionIndexes = (categories, id) => {
    let permissions = getFormattedPermissions(categories);
    return (
        permissions.find((item) => item.id === id) || {
            addonName: null,
            catIndex: null,
            index: null,
            subIndex: null,
        }
    );
};

const getSubGroups = (subcategory) => {
    if (subcategory?.category === PROPOSER_CATEGORY_UUID)
        return subcategory?.groups?.map((item) => ({
            addon_name: item?.addon_name,
            category: subcategory?.category,
            id: item?.id,
            is_enabled: item?.is_enabled,
            name: item?.name,
            permission_level: item?.permission_level,
            permission_checked:
                item?.permission_level !== PERMISSION_LEVELS.NONE,
        }));
    return subcategory?.groups?.map((item) => ({
        addon_name: item?.addon_name,
        id: item?.id,
        is_enabled: item?.is_enabled,
        name: item?.name,
        permission_level: item?.permission_level,
    }));
};

const getSubcategories = (category) => {
    return category?.subcategories.reduce((acc, current) => {
        if (current?.groups) {
            let subcategory = {
                groups: getSubGroups({ ...current, category: category.id }),
                id: current.id,
                name: current.name,
            };
            return [...acc, subcategory];
        }
        return acc;
    }, []);
};

const handleCallbackAddedPermission = ({
    addons,
    categories,
    permissions,
    proposerIndex,
    setValue,
}) => {
    permissions.map((id) => {
        const {
            addonName,
            catIndex,
            index,
            subIndex = null,
        } = getPermissionIndexes(categories, id);
        const hasAddon = addons.includes(addonName);
        let name = isNull(subIndex)
            ? `permissions.${catIndex}.groups.${index}`
            : `permissions.${catIndex}.subcategories.${subIndex}.groups.${index}`;
        if (catIndex === proposerIndex) {
            if (addonName) {
                setValue(`${name}.is_enabled`, hasAddon);
                setValue(`${name}.permission_checked`, hasAddon);
            } else {
                setValue(`${name}.is_enabled`, true);
                setValue(`${name}.permission_checked`, true);
            }
        } else setValue(`${name}.is_enabled`, true);
    });
};

const handleCallbackRemovedPermission = ({
    categories,
    permissions,
    proposerIndex,
    setValue,
}) => {
    permissions.map((id) => {
        const { catIndex, index, subIndex } = getPermissionIndexes(
            categories,
            id
        );
        let name = isNull(subIndex)
            ? `permissions.${catIndex}.groups.${index}`
            : `permissions.${catIndex}.subcategories.${subIndex}.groups.${index}`;
        setValue(`${name}.is_enabled`, false);
        if (catIndex === proposerIndex)
            setValue(`${name}.permission_checked`, false);
        else setValue(`${name}.permission_level`, PERMISSION_LEVELS.NONE);
    });
};

export const getFormattedPermissions = (permissions) => {
    return permissions.reduce((acc, current, catIndex) => {
        if (current?.subcategories) {
            current?.subcategories.forEach((subcategory, subIndex) => {
                subcategory?.groups.forEach((item, sindex) => {
                    if (current?.category === PROPOSER_CATEGORY_UUID)
                        acc = [
                            ...acc,
                            {
                                addonName: item.addon_name,
                                catIndex,
                                id: item.id,
                                index: sindex,
                                is_enabled: item.is_enabled,
                                permission_level: item.permission_checked
                                    ? PERMISSION_LEVELS.MODIFY
                                    : PERMISSION_LEVELS.NONE,
                                subIndex,
                            },
                        ];
                    else
                        acc = [
                            ...acc,
                            {
                                addonName: item.addon_name,
                                catIndex,
                                id: item.id,
                                index: sindex,
                                is_enabled: item.is_enabled,
                                permission_level: item.permission_level,
                                subIndex,
                            },
                        ];
                });
            });
        }
        if (current?.groups)
            current?.groups.forEach((item, index) => {
                acc = [
                    ...acc,
                    {
                        addonName: item.addon_name,
                        catIndex,
                        id: item.id,
                        index,
                        is_enabled: item.is_enabled,
                        permission_level: item.permission_level,
                        subIndex: null,
                    },
                ];
            });
        return acc;
    }, []);
};

export const getIsDisabledFields = ({ isDefault, isFetching, isNewRecord }) => {
    if (isFetching) return true;
    if (!isNewRecord && isDefault) return true;
    return false;
};

export const getPermissionGroups = (categoriesSettings) => {
    return categoriesSettings.reduce((acc, current) => {
        if (!isEmpty(current?.groups)) {
            let category = {
                category: current.id,
                id: current.id,
                name: current.name,
                groups: getGroups(current),
            };
            return [...acc, category];
        }
        if (!isEmpty(current?.subcategories)) {
            let category = {
                category: current.id,
                id: current.id,
                name: current.name,
                subcategories: getSubcategories(current),
            };
            return [...acc, category];
        }
        return acc;
    }, []);
};

export const getPermissionLevelOptions = ({ addonName, hasAddon, value }) => {
    if (addonName && !hasAddon) {
        let addonOptions = [
            { value: 'none', label: i18next.t('None') },
            { value: 'view', label: i18next.t('View') },
        ];
        if (!['none', 'view'].includes(value))
            return [
                ...addonOptions,
                { value, label: getLabelOfPermisionLevel(value) },
            ];
        else return addonOptions;
    } else
        return [
            { value: 'none', label: i18next.t('None') },
            { value: 'view', label: i18next.t('View') },
            { value: 'modify', label: i18next.t('Edit') },
            { value: 'all', label: i18next.t('All text', { count: 2 }) },
        ];
};

export const getProposerIndex = (categories) => {
    if (!isEmpty(categories)) {
        const indexList = categories.map((item) => item.id);
        return indexList.indexOf(PROPOSER_CATEGORY_UUID);
    }
    return null;
};

export const getRoleCategories = (categoriesSettings) => {
    return categoriesSettings.map((item) => ({
        id: item.id,
        name: item.name,
    }));
};

export const getRoleLevelOptions = (levels) => {
    const options = levels.reduce((acc, current) => {
        if (
            ![ROLE_LEVELS.OWNER, ROLE_LEVELS.CUSTOMIZED].includes(
                current.role_level
            )
        )
            return [
                ...acc,
                {
                    label: current.name,
                    value: current.id,
                },
            ];
        return acc;
    }, []);
    return [
        {
            disabled: true,
            label: 'Selecciona nivel',
            value: '',
        },
        ...options,
    ];
};

export const handleOnChangePermission = ({
    addons,
    categories,
    item,
    proposerIndex,
    setValue,
    value,
}) => {
    const hasPermission = [
        PERMISSION_LEVELS.VIEW,
        PERMISSION_LEVELS.MODIFY,
        PERMISSION_LEVELS.ALL,
    ].includes(value);

    if (
        item.id === PERMISSION_LIST.CONTACTS_PERMISSION &&
        value === PERMISSION_LEVELS.NONE
    ) {
        handleCallbackRemovedPermission({
            categories,
            permissions: [
                ...CONTACT_PERMISSIONS,
                ...PROJECT_PERMISSIONS,
                ...PROPOSER_PERMISSIONS,
            ],
            proposerIndex,
            setValue,
        });
    }
    if (item.id === PERMISSION_LIST.CONTACTS_PERMISSION && hasPermission) {
        handleCallbackAddedPermission({
            addons,
            categories,
            permissions: CONTACT_PERMISSIONS,
            proposerIndex,
            setValue,
        });
    }

    if (
        item.id === PERMISSION_LIST.CONTACT_PROJECTS_PERMISSION &&
        value === PERMISSION_LEVELS.NONE
    ) {
        handleCallbackRemovedPermission({
            categories,
            permissions: [...PROJECT_PERMISSIONS, ...PROPOSER_PERMISSIONS],
            proposerIndex,
            setValue,
        });
    }

    if (
        item.id === PERMISSION_LIST.CONTACT_PROJECTS_PERMISSION &&
        hasPermission
    ) {
        handleCallbackAddedPermission({
            addons,
            categories,
            permissions: PROJECT_PERMISSIONS,
            proposerIndex,
            setValue,
        });
    }

    if (
        item.id === PERMISSION_LIST.CONTACT_PROPOSALS_PERMISSION &&
        (value === PERMISSION_LEVELS.NONE || value === PERMISSION_LEVELS.VIEW)
    ) {
        handleCallbackRemovedPermission({
            categories,
            permissions: PROPOSER_PERMISSIONS,
            proposerIndex,
            setValue,
        });
    }

    if (
        item.id === PERMISSION_LIST.CONTACT_PROPOSALS_PERMISSION &&
        (value === PERMISSION_LEVELS.MODIFY || value === PERMISSION_LEVELS.ALL)
    ) {
        handleCallbackAddedPermission({
            addons,
            categories,
            permissions: PROPOSER_PERMISSIONS,
            proposerIndex,
            setValue,
        });
    }
};
