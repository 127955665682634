import i18next from 'i18next';

import { URL_TYPES } from 'common/constants';
import alerts from 'common/modules/alerts';
import {
    disconnectSocket,
    getPeriods,
    resetEntity,
} from 'common/modules/zap/actions';

import {
    FETCH_ZAP_DATA,
    FETCH_ZAP_DATA_FAILURE,
    FETCH_ZAP_DATA_SUCCESS,
} from '../actionTypes';
import { CONSUMPTIONS_CAPTURE_MODE } from '../constants';
import { projectConsumptionModalActions } from '../reducer';

import handleConsumptionIntegration from './handleConsumptionIntegration';
import setZapLoading from './setZapLoading';

export default ({ getValues, rpuId, setValue }) =>
    (dispatch) => {
        if (!rpuId) return;

        dispatch(projectConsumptionModalActions[FETCH_ZAP_DATA]());

        getPeriods({ rpuId })
            .then((response) => {
                const data = response?.data;

                if (!data?.length) {
                    dispatch(
                        alerts.actions.show({
                            hideButtons: true,
                            messages: [
                                i18next.t(
                                    'It was not possible to find any electric bill'
                                ),
                            ],
                            title: i18next.t('No electric bill found'),
                            type: alerts.ALERT_TYPE_ALERT,
                            variant: 'warning',
                        })
                    );
                    return;
                }

                const rawData = data?.map((item) => ({
                    completion: item,
                    file: item?.url,
                    status: 0, // STATUS_SUCCESS,
                    url_type: URL_TYPES.ZAP,
                }));

                dispatch(
                    projectConsumptionModalActions[FETCH_ZAP_DATA_SUCCESS](data)
                );

                dispatch(
                    handleConsumptionIntegration({
                        consumptionsCaptureMode: CONSUMPTIONS_CAPTURE_MODE.ZAP,
                        getValues,
                        rawData,
                        setValue,
                    })
                );
            })
            .catch((error) =>
                dispatch(
                    projectConsumptionModalActions[FETCH_ZAP_DATA_FAILURE](
                        error?.response?.data?.detail
                    )
                )
            )
            .finally(() => {
                dispatch(setZapLoading(false));
                dispatch(disconnectSocket());
                dispatch(resetEntity('rpu', rpuId));
            });
    };
