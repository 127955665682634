import { getItemsByParent } from 'common/api/v1/politicalDivisions';

import {
    FETCH_COMPATIBILITY_DATA_SUCCESS,
    FETCH_POLITICAL_DIVISIONS_BY_PARENT,
    FETCH_POLITICAL_DIVISIONS_BY_PARENT_FAILURE,
    FETCH_POLITICAL_DIVISIONS_BY_PARENT_SUCCESS,
} from '../actionTypes';
import { equipmentCompatibilityActions } from '../reducer';

import initializeForm from './initializeForm';

export default (uid) => (dispatch) => {
    dispatch(
        equipmentCompatibilityActions[FETCH_POLITICAL_DIVISIONS_BY_PARENT]()
    );

    return getItemsByParent(uid)
        .then((response) => {
            dispatch(
                equipmentCompatibilityActions[
                    FETCH_POLITICAL_DIVISIONS_BY_PARENT_SUCCESS
                ](response.data.data)
            );

            dispatch(
                initializeForm({
                    political_division1: uid,
                    political_division2: response.data.data[0].id,
                })
            );

            dispatch(
                equipmentCompatibilityActions[FETCH_COMPATIBILITY_DATA_SUCCESS](
                    null
                )
            );
        })
        .catch((error) =>
            dispatch(
                equipmentCompatibilityActions[
                    FETCH_POLITICAL_DIVISIONS_BY_PARENT_FAILURE
                ](error?.response?.data?.errors)
            )
        );
};
