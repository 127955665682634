import React from 'react';

import PropTypes from 'prop-types';
import { useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Box, Grid, ToggleButton, ToggleButtonGroup } from 'sunwise-ui';

import { ReactHookFormIntlNumberInput } from 'common/components/form/bootstrap';
import TitleWithDetail from 'common/components/TitleWithDetail';

const OpexForm = ({ canModify, control, isHidden, isLocked, setValue }) => {
    const { t } = useTranslation();
    const [currency_abbreviation, opex_periodicity] = useWatch({
        control,
        name: ['currency_abbreviation', 'opex_periodicity'],
    });
    if (isHidden) return null;
    return (
        <>
            <Box sx={{ mb: 2 }}>
                <TitleWithDetail>OPEX</TitleWithDetail>
            </Box>

            <Grid container>
                <Grid item xs={18} lg={9}>
                    <ToggleButtonGroup
                        color="primary"
                        disabled={isLocked || !canModify}
                        exclusive
                        fullWidth
                        onChange={(e) =>
                            setValue('opex_periodicity', e.target.value)
                        }
                        value={parseInt(opex_periodicity)}
                        size="small"
                        sx={{ mb: 2 }}
                    >
                        <ToggleButton value={0}>{t('Monthly')}</ToggleButton>
                        <ToggleButton value={1}>{t('Yearly')}</ToggleButton>
                    </ToggleButtonGroup>
                </Grid>

                <Grid item xs={18} lg={9}>
                    <ReactHookFormIntlNumberInput
                        append={currency_abbreviation}
                        prepend="$"
                        control={control}
                        disabled={isLocked || !canModify}
                        fullWidth
                        label={t('Quantity')}
                        name="opex"
                        placeholder="0"
                        step="any"
                    />
                </Grid>

                <Grid item xs={18}>
                    <ReactHookFormIntlNumberInput
                        allowNegativeValue={false}
                        append="%"
                        control={control}
                        disabled={isLocked || !canModify}
                        fullWidth
                        label={t('Annual percentage increase')}
                        min={0}
                        name="opex_percentage_increase"
                        placeholder="0"
                        step="any"
                    />
                </Grid>
            </Grid>
        </>
    );
};

OpexForm.propTypes = {
    canModify: PropTypes.bool,
    control: PropTypes.object,
    isHidden: PropTypes.bool,
    isLocked: PropTypes.bool,
    setValue: PropTypes.func,
};

export default OpexForm;
