import React, { useContext, useEffect, useState } from 'react';

import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import EditIcon from '@mui/icons-material/Edit';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { createStructuredSelector } from 'reselect';
import { Box, Card, Grid, IconButton, Typography } from 'sunwise-ui';

import PlaceholderCardLocation from 'common/components/placeholder/PlaceholderCardLocation';
import { PERMISSION_LIST } from 'common/constants/permissionsV2';
import withPermissions from 'common/hocs/withPermissions';
import locationForm from 'common/modules/locationForm';
import { LoadingContext } from 'common/utils/contexts';
import { getDefaultProjectLocation } from 'common/utils/helpers';

import * as companySelectors from '../companyGeneral/selectors';
import { getPositionData } from '../record/selectors';

import * as actions from './actions';
import * as selectors from './selectors';

const Container = ({
    canModify,
    canView,
    companyPositionLatLng,
    contactPosition,
    fetchPositions,
    handleClickClone,
    isLoading,
    position,
    savePosition,
    selectedProject,
    selectProject,
}) => {
    const { t } = useTranslation();
    const loadingContext = useContext(LoadingContext);
    const [editModeLocation, setEditModeLocation] = useState(false);

    useEffect(() => {
        selectProject(selectedProject);
        fetchPositions();
    }, [selectedProject.id]);

    if (!canView) return null;

    return (
        <Box mb={2}>
            <PlaceholderCardLocation ready={isLoading}>
                <Card>
                    <Card.Header>
                        <Grid container sx={{ alignItems: 'center' }}>
                            <Grid item xs>
                                <Typography variant="body1" fontWeight="bold">
                                    {t('Location')}
                                </Typography>
                            </Grid>

                            <Grid
                                item
                                xs
                                sx={{
                                    alignItems: 'center',
                                    display: 'flex',
                                    gap: '16px',
                                    justifyContent: 'end',
                                }}
                            >
                                {get(contactPosition, 'position', null) !==
                                    null &&
                                    !editModeLocation &&
                                    canModify && (
                                        <IconButton
                                            onClick={() => handleClickClone()}
                                        >
                                            <ContentCopyIcon fontSize="small" />
                                        </IconButton>
                                    )}

                                {get(position, 'position', null) !== null &&
                                    !editModeLocation &&
                                    canModify && (
                                        <IconButton
                                            onClick={() =>
                                                setEditModeLocation(true)
                                            }
                                        >
                                            <EditIcon fontSize="small" />
                                        </IconButton>
                                    )}
                            </Grid>
                        </Grid>
                    </Card.Header>

                    <Card.Body>
                        <locationForm.Container
                            canModify={canModify}
                            center={getDefaultProjectLocation(
                                contactPosition,
                                companyPositionLatLng
                            )}
                            editMode={editModeLocation}
                            handleSubmit={(values) => {
                                loadingContext.openLoading(
                                    t('Saving change', { count: 2 }).concat(
                                        '...'
                                    )
                                );
                                savePosition(
                                    values,
                                    selectedProject.id,
                                    () => {
                                        setEditModeLocation(false);
                                        loadingContext.closeLoading();
                                    },
                                    () => {
                                        loadingContext.closeLoading();
                                    }
                                );
                            }}
                            initPosition={position}
                            name="location-project"
                            setEditMode={setEditModeLocation}
                        />
                    </Card.Body>
                </Card>
            </PlaceholderCardLocation>
        </Box>
    );
};

const mapStateToProps = createStructuredSelector({
    companyPositionLatLng: companySelectors.getCompanyPositionLatLng,
    contactPosition: getPositionData,
    errors: selectors.getErrors,
    isLoading: selectors.getIsLoading,
    position: selectors.getPosition,
});

const mapDispatchToProps = (dispatch) => ({
    fetchPositions: () => dispatch(actions.fetchPositions()),
    handleClickClone: () => dispatch(actions.prepareClone()),
    savePosition: (values, uid, successCallback, failureCallback) =>
        dispatch(
            actions.savePosition(values, uid, successCallback, failureCallback)
        ),
    selectProject: (project) => dispatch(actions.selectProject(project)),
});

Container.propTypes = {
    canModify: PropTypes.bool,
    canView: PropTypes.bool,
    companyPositionLatLng: PropTypes.object,
    contactPosition: PropTypes.object,
    fetchPositions: PropTypes.func,
    handleClickClone: PropTypes.func,
    isLoading: PropTypes.bool,
    position: PropTypes.object,
    savePosition: PropTypes.func,
    selectedProject: PropTypes.object,
    selectProject: PropTypes.func,
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withPermissions(PERMISSION_LIST.PROJECT_LOCATION_PERMISSION)
)(Container);
