import { createSlice } from '@reduxjs/toolkit';

import {
    ACTIVATE_ITEM,
    ACTIVATE_ITEM_FAILURE,
    ACTIVATE_ITEM_SUCCESS,
    CHANGE_SEARCH_TEXT,
    CHANGE_SEARCH_TEXT_PARENT,
    FETCH_ITEMS,
    FETCH_ITEMS_FAILURE,
    FETCH_ITEMS_PARENT,
    FETCH_ITEMS_PARENT_FAILURE,
    FETCH_ITEMS_PARENT_SUCCESS,
    FETCH_ITEMS_SUCCESS,
    INITIALIZE_FORM,
    OPEN_MODAL,
    RESET_FORM,
    SAVE_ITEM,
    SAVE_ITEM_FAILURE,
    SAVE_ITEM_SUCCESS,
    SELECT_ITEM,
    SELECT_PARENT,
    SELECT_PARENT_FAILURE,
    SELECT_PARENT_SUCCESS,
    SET_IS_NEW_RECORD,
    UNSELECT_PARENT,
    UNSELECT_PARENT_FAILURE,
    UNSELECT_PARENT_SUCCESS,
} from './actionTypes';
import { NAME } from './constants';

const INITIAL_STATE = {
    activateItem: {
        data: [],
        errors: null,
        isFetching: false,
    },
    fetchItems: {
        data: [],
        errors: null,
        isFetching: false,
    },
    fetchItemsParent: {
        data: {
            data: [],
            pagination: null,
        },
        errors: null,
        isFetching: false,
    },
    initialValues: {},
    isOpenModal: false,
    saveItem: {
        isSaving: false,
        data: {},
        errors: null,
    },
    selectedItem: null,
    selectParent: {
        data: [],
        errors: null,
        isFetching: false,
    },

    searchText: '',
    searchTextParent: '',
    unselectParent: {
        data: [],
        errors: null,
        isFetching: false,
    },
};

const multiCurrencySlice = createSlice({
    name: NAME,
    initialState: INITIAL_STATE,
    reducers: {
        [ACTIVATE_ITEM]: (state) => {
            state.activateItem = {
                ...state.activateItem,
                errors: [],
                isFetching: true,
            };
        },
        [ACTIVATE_ITEM_FAILURE]: (state, action) => {
            state.activateItem = {
                ...state.activateItem,
                errors: action.payload,
                isFetching: false,
            };
        },
        [ACTIVATE_ITEM_SUCCESS]: (state, action) => {
            state.activateItem = {
                ...state.activateItem,
                data: action.payload,
                isFetching: false,
            };
        },
        [CHANGE_SEARCH_TEXT]: (state, action) => {
            state.searchText = action.payload;
        },
        [CHANGE_SEARCH_TEXT_PARENT]: (state, action) => {
            state.searchTextParent = action.payload;
        },
        [FETCH_ITEMS]: (state) => {
            state.fetchItems = {
                ...state.fetchItems,
                errors: [],
                isFetching: true,
            };
        },
        [FETCH_ITEMS_FAILURE]: (state, action) => {
            state.fetchItems = {
                ...state.fetchItems,
                errors: action.payload,
                isFetching: false,
            };
        },
        [FETCH_ITEMS_SUCCESS]: (state, action) => {
            state.fetchItems = {
                ...state.fetchItems,
                data: action.payload,
                isFetching: false,
            };
        },
        [FETCH_ITEMS_PARENT]: (state) => {
            state.fetchItemsParent = {
                ...state.fetchItemsParent,
                errors: [],
                isFetching: true,
            };
        },
        [FETCH_ITEMS_PARENT_FAILURE]: (state, action) => {
            state.fetchItemsParent = {
                ...state.fetchItemsParent,
                data: action.payload,
                isFetching: false,
            };
        },
        [FETCH_ITEMS_PARENT_SUCCESS]: (state, action) => {
            state.fetchItemsParent = {
                ...state.fetchItemsParent,
                data: action.payload,
                isFetching: false,
            };
        },
        [INITIALIZE_FORM]: (state, action) => {
            state.initialValues = { ...state.initialValues, ...action.payload };
        },
        [OPEN_MODAL]: (state, action) => {
            state.isOpenModal = action.payload;
        },
        [RESET_FORM]: (state) => {
            state.initialValues = INITIAL_STATE.initialValues;
        },
        [SAVE_ITEM]: (state) => {
            state.saveItem = { ...state.saveItem, errors: [], isSaving: true };
        },
        [SAVE_ITEM_FAILURE]: (state, action) => {
            state.saveItem = {
                ...state.saveItem,
                errors: action.payload,
                isSaving: false,
            };
        },
        [SAVE_ITEM_SUCCESS]: (state, action) => {
            state.saveItem = {
                ...state.saveItem,
                data: action.payload,
                isSaving: false,
            };
        },
        [SELECT_ITEM]: (state, action) => {
            state.selectedItem = action.payload;
        },
        [SELECT_PARENT]: (state) => {
            state.selectParent = {
                ...state.selectParent,
                errors: [],
                isFetching: true,
            };
        },
        [SELECT_PARENT_FAILURE]: (state, action) => {
            state.selectParent = {
                ...state.selectParent,
                errors: action.payload,
                isFetching: false,
            };
        },
        [SELECT_PARENT_SUCCESS]: (state, action) => {
            state.selectParent = {
                ...state.selectParent,
                data: action.payload,
                isFetching: false,
            };
        },
        [SET_IS_NEW_RECORD]: (state, action) => {
            state.isNewRecord = action.payload;
        },
        [UNSELECT_PARENT]: (state) => {
            state.unselectParent = {
                ...state.unselectParent,
                errors: [],
                isFetching: true,
            };
        },
        [UNSELECT_PARENT_FAILURE]: (state, action) => {
            state.unselectParent = {
                ...state.unselectParent,
                errors: action.payload,
                isFetching: false,
            };
        },
        [UNSELECT_PARENT_SUCCESS]: (state, action) => {
            state.unselectParent = {
                ...state.unselectParent,
                data: action.payload,
                isFetching: false,
            };
        },
    },
});

export const multiCurrencyActions = multiCurrencySlice.actions;

export default multiCurrencySlice.reducer;
