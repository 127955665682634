import React from 'react';

import PropTypes from 'prop-types';

const IconFinancial = ({
    color = '#ff9a00',
    height = '20px',
    width = '21px',
}) => (
    <svg
        width={width}
        height={height}
        viewBox="0 0 21 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M2 9C0.9 9 0 9.9 0 11V18C0 19.1 0.9 20 2 20C3.1 20 4 19.1 4 18V11C4 9.9 3.1 9 2 9Z"
            fill={color}
        />
        <path
            d="M18.99 15H12.16C12.05 15 11.94 14.98 11.83 14.94L10.36 14.43C10.1 14.34 9.97 14.06 10.06 13.8C10.15 13.54 10.44 13.4 10.7 13.5L11.82 13.93C11.93 13.97 12.06 14 12.18 14H14.81C15.46 14 15.99 13.47 15.99 12.82C15.99 12.33 15.68 11.89 15.22 11.71L8.3 9.13C8.08 9.04 7.84 9 7.6 9H6V18.02L12.37 19.83C12.78 19.95 13.22 19.93 13.62 19.78L21 17C21 15.89 20.1 15 18.99 15Z"
            fill={color}
        />
        <path
            d="M15 0C12.24 0 10 2.24 10 5C10 7.76 12.24 10 15 10C17.76 10 20 7.76 20 5C20 2.24 17.76 0 15 0ZM15.44 7.88V8.06C15.44 8.3 15.245 8.5 15 8.5C14.76 8.5 14.56 8.305 14.56 8.06V7.85C14.245 7.775 13.595 7.545 13.215 6.8C13.1 6.58 13.21 6.305 13.44 6.21L13.475 6.195C13.68 6.11 13.91 6.195 14.015 6.39C14.175 6.695 14.49 7.075 15.075 7.075C15.54 7.075 16.065 6.835 16.065 6.27C16.065 5.79 15.715 5.54 14.925 5.255C14.375 5.06 13.25 4.74 13.25 3.6C13.25 3.55 13.255 2.4 14.56 2.12V1.94C14.56 1.695 14.76 1.5 15 1.5C15.24 1.5 15.44 1.695 15.44 1.94V2.125C15.975 2.22 16.315 2.505 16.52 2.775C16.69 2.995 16.6 3.315 16.34 3.425C16.16 3.5 15.95 3.44 15.83 3.285C15.69 3.095 15.44 2.9 15.03 2.9C14.68 2.9 14.125 3.085 14.125 3.595C14.125 4.07 14.555 4.25 15.445 4.545C16.645 4.96 16.95 5.57 16.95 6.27C16.95 7.585 15.7 7.835 15.44 7.88Z"
            fill={color}
        />
    </svg>
);

IconFinancial.propTypes = {
    color: PropTypes.string,
    height: PropTypes.string,
    width: PropTypes.string,
};

export default IconFinancial;
