import React, { useEffect, useState } from 'react';

import VisibilityIcon from '@mui/icons-material/Visibility';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
    Button,
    Checkbox,
    IconButton,
    Menu,
    MenuItem,
    Tooltip,
    Typography,
} from 'sunwise-ui';

const VisibilityOption = ({ checked, label, onClick, onClickOnlyButton }) => {
    const [isHovered, setIsHovered] = useState(false);
    const { t } = useTranslation();
    return (
        <MenuItem
            onClick={onClick}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            sx={{ width: '300px' }}
        >
            <Checkbox checked={checked} />

            <Typography
                noWrap
                sx={{
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    width: isHovered ? 'calc(100% - 120px)' : '100%',
                }}
            >
                {label}
            </Typography>

            {onClickOnlyButton && isHovered && (
                <Button
                    onClick={(e) => {
                        e.stopPropagation();
                        onClickOnlyButton();
                    }}
                    sx={{
                        borderRadius: 2,
                        minWidth: '90px',
                        ml: 1,
                        overflow: 'hidden',
                        p: 0,
                        position: 'absolute',
                        right: '10px',
                        textOverflow: 'ellipsis',
                    }}
                >
                    {t('Only')}
                </Button>
            )}
        </MenuItem>
    );
};

VisibilityOption.propTypes = {
    checked: PropTypes.bool,
    label: PropTypes.string,
    onClick: PropTypes.func,
    onClickOnlyButton: PropTypes.func,
};

const ChartVisibilityControls = ({
    chartRef,
    defaultVisibility,
    onChangeVisibility,
    series,
}) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [isVisible, setIsVisible] = useState(false);
    const [options, setOptions] = useState([]);
    const [visibleSeries, setVisibleSeries] = useState(defaultVisibility || []);
    const { t } = useTranslation();

    const handleChangeVisibility = ({ chartRef, options, values }) => {
        if (!chartRef?.current?.chart) return;

        const mappedValues = values?.reduce((acc, value) => {
            acc[value] = true;
            return acc;
        }, {});

        options?.forEach((serie, index) => {
            if (mappedValues?.[index]) chartRef.current.chart.showSeries(serie);
            else chartRef.current.chart.hideSeries(serie);
        });

        setVisibleSeries(values);
    };

    const getDefaultVisibility = (series, defaultValues) => {
        const values = series?.map((_, index) => index) || [];
        if (!defaultValues) return values;
        const mappedDefaultValues = defaultValues?.reduce((acc, value) => {
            acc[value] = true;
            return acc;
        }, {});
        return values?.filter((index) => mappedDefaultValues?.[index]);
    };

    useEffect(() => {
        if (chartRef && series) {
            const newOptions = series.map((item) => item.name);
            const newValues = getDefaultVisibility(
                newOptions,
                defaultVisibility
            );
            setOptions(newOptions);
            setVisibleSeries(newValues);
            handleChangeVisibility({
                chartRef,
                options: newOptions,
                values: newValues,
            });
            setIsVisible(true);
        } else {
            setIsVisible(false);
            setOptions([]);
            setVisibleSeries([]);
        }
    }, [chartRef, series]);

    useEffect(() => {
        if (Array.isArray(defaultVisibility))
            setVisibleSeries(defaultVisibility);
    }, [defaultVisibility]);

    if (!isVisible) return null;

    const handleOnChangeVisibility = (value) => {
        let newValues = [];
        if (value === 'all')
            newValues =
                options?.length === visibleSeries?.length
                    ? []
                    : options.map((_, index) => index);
        else {
            if (visibleSeries.includes(value))
                newValues = visibleSeries.filter((item) => item !== value);
            else newValues = [...visibleSeries, value];
        }
        handleChangeVisibility({ chartRef, options, values: newValues });

        if (onChangeVisibility) onChangeVisibility(newValues);
    };

    const setOnlyOneSerie = (index) => {
        const newValues = [index];
        handleChangeVisibility({ chartRef, options, values: newValues });

        if (onChangeVisibility) onChangeVisibility(newValues);
    };

    const handleClick = (event) => setAnchorEl(event.currentTarget);
    const handleClose = () => setAnchorEl(null);

    const open = Boolean(anchorEl);

    return (
        <>
            <Tooltip title={t('Visibility')}>
                <IconButton onClick={handleClick}>
                    <VisibilityIcon />
                </IconButton>
            </Tooltip>

            <Menu
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
            >
                <VisibilityOption
                    checked={visibleSeries.length === options.length}
                    label={t('All text', { count: 2 })}
                    onClick={() => handleOnChangeVisibility('all')}
                />

                {options?.map((serie, index) => (
                    <VisibilityOption
                        checked={visibleSeries.includes(index)}
                        key={index}
                        label={serie}
                        onClick={() => handleOnChangeVisibility(index)}
                        onClickOnlyButton={() => setOnlyOneSerie(index)}
                    />
                ))}
            </Menu>
        </>
    );
};

ChartVisibilityControls.propTypes = {
    chartRef: PropTypes.object,
    defaultVisibility: PropTypes.array,
    onChangeVisibility: PropTypes.func,
    series: PropTypes.array,
};

export default ChartVisibilityControls;
