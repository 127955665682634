import React from 'react';

import PropTypes from 'prop-types';
import { useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Box } from 'sunwise-ui';

import {
    ReactHookFormCheck,
    ReactHookFormIntlNumberInput,
} from 'common/components/form/bootstrap';
import TitleWithDetail from 'common/components/TitleWithDetail';

const PPAFields = ({ control, currencySymbol, isLocked }) => {
    const { t } = useTranslation();
    const ppaActive = useWatch({ control, name: 'ppa_active' });

    return (
        <>
            <Box sx={{ mb: 2 }}>
                <TitleWithDetail variant="body2">PPA</TitleWithDetail>
            </Box>

            <Box
                alignItems="center"
                display="flex"
                flexWrap={{ xs: 'wrap', sm: 'nowrap' }}
                gap={1}
            >
                <ReactHookFormCheck
                    control={control}
                    disabled={isLocked}
                    label={t('Active')}
                    name="ppa_active"
                    sxFormControl={{ flexShrink: 0 }}
                />

                <ReactHookFormIntlNumberInput
                    allowNegativeValue={false}
                    control={control}
                    disabled={isLocked || !ppaActive}
                    fullWidth
                    label={t('Price')}
                    min={0}
                    name="ppa_price"
                    prepend={currencySymbol}
                />

                <ReactHookFormIntlNumberInput
                    allowNegativeValue={false}
                    append="%"
                    control={control}
                    disabled={isLocked || !ppaActive}
                    fullWidth
                    label={t('Annual percentage increase')}
                    min={0}
                    name="ppa_percentage_increase"
                    placeholder="0"
                />
            </Box>
        </>
    );
};

PPAFields.propTypes = {
    control: PropTypes.object,
    currencySymbol: PropTypes.string,
    isLocked: PropTypes.bool,
};

export default PPAFields;
