import get from 'lodash/get';

import { getOfferPanel } from 'common/api/v1/offerPanel';
import { SEGMENT_COLORS } from 'common/components/maps/constants';

import {
    FETCH_OFFER_PANELS,
    FETCH_OFFER_PANELS_FAILURE,
    FETCH_OFFER_PANELS_SUCCESS,
} from '../actionTypes';
import { panelLayoutV2Actions } from '../reducer';

export default (id) => (dispatch) =>
    new Promise((resolve) => {
        dispatch(panelLayoutV2Actions[FETCH_OFFER_PANELS]());

        getOfferPanel(id)
            .then((response) => {
                const data = get(response, 'data.data', []);
                const offerPanels = data.map((item) => {
                    const {
                        dimensions,
                        panel: { id, name, watts },
                        panels_layout: {
                            used_modules = 0,
                            segment_color = SEGMENT_COLORS[0].color,
                            segment_stroke_color = SEGMENT_COLORS[0]
                                .stroke_color,
                        },
                        quantity,
                    } = item;

                    const {
                        alto: height,
                        ancho: width,
                        profundo: depth,
                    } = dimensions;

                    return {
                        assigned: used_modules,
                        color: segment_color,
                        depth,
                        height,
                        id: id,
                        isLocked: used_modules >= quantity,
                        name: name,
                        quantity: quantity,
                        stroke_color: segment_stroke_color,
                        watts,
                        width,
                    };
                });

                const assignedModules = offerPanels.reduce(
                    (acc, current) => acc + current?.assigned,
                    0
                );
                const availableModules = offerPanels.reduce(
                    (acc, current) => acc + current?.quantity,
                    0
                );
                dispatch(
                    panelLayoutV2Actions[FETCH_OFFER_PANELS_SUCCESS]({
                        counters: {
                            assigned: assignedModules,
                            available: availableModules,
                        },
                        panels: offerPanels,
                    })
                );
            })
            .catch((error) => {
                dispatch(
                    panelLayoutV2Actions[FETCH_OFFER_PANELS_FAILURE](
                        error?.response?.data?.errors
                    )
                );
            })
            .finally(resolve);
    });
