import React, { useState } from 'react';

import HistoryIcon from '@mui/icons-material/History';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Grid, ToggleButton, ToggleButtonGroup } from 'sunwise-ui';

import AccordionCard from 'common/components/accordions/AccordionCard';
import { getDateFormatByLocale } from 'common/utils/helpers/multiregion';
import { getDaysInPeriod } from 'common/utils/helpers/rates';

import { formatNumber, getTotalInfoPrice } from '../helpers';
import * as selectors from '../selectors';

import DateTooltip from './DateTooltip';
import HistoricalDataGrid from './HistoricalDataGrid';

const ComparativeCard = ({
    currencyIso,
    currencyLocale,
    currencySymbol,
    historicalInfo,
    isLoading,
    isProposalBimonhtly,
    referencePeriods = [],
}) => {
    const { t } = useTranslation();
    const [selected, setSelected] = useState('referenceSource');
    const dateFormat = getDateFormatByLocale();

    const getNumberFieldFormatted = (value, isCurrency) => {
        if (isCurrency)
            return formatNumber({
                currencyIso,
                currencyLocale,
                currencySymbol,
                style: 'currency',
                value,
            });
        return formatNumber({ currencyLocale, decimals: 0, value });
    };

    const promiseColumns = [
        {
            field: 'period',
            headerName: t('Period'),
            renderCell: ({ row }) => (
                <DateTooltip
                    dateFormat={dateFormat}
                    finalDate={row?.date_finish}
                    initialDate={row?.date_start}
                    isBimonthly={isProposalBimonhtly}
                />
            ),
            sortable: false,
            width: 120,
        },
        {
            align: 'center',
            field: 'number_days',
            headerAlign: 'center',
            headerName: t('Days in period'),
            sortable: false,
            valueGetter: ({ row }) => {
                const days = getDaysInPeriod({
                    final_date: row.date_finish,
                    initial_date: row.date_start,
                });
                return days || 0;
            },
            width: 160,
        },
        {
            align: 'center',
            field: 'total_consumption',
            flex: 1,
            headerAlign: 'center',
            headerName: `${t('Total consumption')} (kWh)`,
            minWidth: 160,
            sortable: false,
            valueGetter: ({ row }) =>
                getNumberFieldFormatted(row?.consumption || 0),
        },
        {
            align: 'center',
            field: 'total_generation',
            flex: 1,
            headerAlign: 'center',
            headerName: `${t('Total generation')} (kWh)`,
            minWidth: 160,
            sortable: false,
            valueGetter: ({ row }) =>
                getNumberFieldFormatted(row?.generation || 0),
        },
    ];

    const realColumns = [
        {
            field: 'period',
            headerName: t('Period'),
            renderCell: ({ row }) => (
                <DateTooltip
                    dateFormat={dateFormat}
                    finalDate={row?.final_date}
                    initialDate={row?.initial_date}
                    isBimonthly={row?.is_bimonthly}
                />
            ),
            sortable: false,
            width: 120,
        },
        {
            align: 'center',
            field: 'number_days',
            headerAlign: 'center',
            headerName: t('Days in period'),
            sortable: false,
            valueGetter: ({ row }) => {
                const days = getDaysInPeriod({
                    final_date: row.final_date,
                    initial_date: row.initial_date,
                });
                return days || 0;
            },
            width: 160,
        },
        {
            align: 'center',
            field: 'total_consumption',
            flex: 1,
            headerAlign: 'center',
            headerName: `${t('Total consumption')} (kWh)`,
            minWidth: 160,
            sortable: false,
            valueGetter: ({ row }) =>
                getNumberFieldFormatted(row?.total?.consumption?.value || 0),
        },
        {
            align: 'center',
            field: 'total_generation',
            flex: 1,
            headerAlign: 'center',
            headerName: `${t('Total generation')} (kWh)`,
            minWidth: 160,
            sortable: false,
            valueGetter: ({ row }) =>
                getNumberFieldFormatted(row?.total?.generation?.value || 0),
        },
        {
            align: 'center',
            field: 'cost_with solar',
            flex: 1,
            headerAlign: 'center',
            headerName: t('Cost with solar'),
            minWidth: 160,
            sortable: false,
            valueGetter: ({ row }) =>
                getNumberFieldFormatted(
                    getTotalInfoPrice(row?.real_info_price_consumption) || 0,
                    true
                ),
        },
        {
            align: 'center',
            field: 'cost_without_solar',
            flex: 1,
            headerAlign: 'center',
            headerName: t('Cost without solar'),
            minWidth: 160,
            sortable: false,
            valueGetter: ({ row }) =>
                getNumberFieldFormatted(
                    getTotalInfoPrice(row?.total_info_price_consumption) || 0,
                    true
                ),
        },
    ];

    return (
        <AccordionCard
            defaultExpanded
            isLoading={isLoading}
            title={t('Historical')}
            svgIcon={<HistoryIcon color="primary" />}
        >
            <Grid container>
                <Grid item xs={18}>
                    <ToggleButtonGroup
                        color="primary"
                        exclusive
                        fullWidth
                        sx={{ pb: 2 }}
                        onChange={(_, newValue) => setSelected(newValue)}
                        value={selected}
                    >
                        <ToggleButton
                            value="referenceSource"
                            sx={{ textTransform: 'none' }}
                        >
                            {t('Reference source')}
                        </ToggleButton>
                        <ToggleButton value="real">{t('Current')}</ToggleButton>
                    </ToggleButtonGroup>
                </Grid>

                <Grid item xs={18}>
                    {selected === 'referenceSource' && (
                        <HistoricalDataGrid
                            columns={promiseColumns}
                            dateFields={['date_finish', 'date_start']}
                            isLoading={isLoading}
                            rows={referencePeriods}
                        />
                    )}

                    {selected === 'real' && (
                        <HistoricalDataGrid
                            columns={realColumns}
                            dateFields={['final_date', 'initial_date']}
                            isLoading={isLoading}
                            rows={historicalInfo}
                        />
                    )}
                </Grid>
            </Grid>
        </AccordionCard>
    );
};
const mapStateToProps = createStructuredSelector({
    historicalInfo: selectors.getHistoricalInfoData,
    isProposalBimonhtly: selectors.getProposalInfoIsBimonthly,
    referencePeriods: selectors.getReferencePeriods,
});

ComparativeCard.propTypes = {
    currencyIso: PropTypes.string,
    currencyLocale: PropTypes.string,
    currencySymbol: PropTypes.string,
    historicalInfo: PropTypes.array,
    isLoading: PropTypes.bool,
    isProposalBimonhtly: PropTypes.bool,
    referencePeriods: PropTypes.array,
};

export default connect(mapStateToProps)(ComparativeCard);
