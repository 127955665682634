import { saveProposalIndicatorsSettings } from 'common/api/v1/proposalIndicators';
import { showReponseErrorsAsAlert } from 'common/utils/helpers';
import showToast from 'common/utils/showToast';

import {
    SAVE_INDICATORS_SETTINGS,
    SAVE_INDICATORS_SETTINGS_FAILURE,
    SAVE_INDICATORS_SETTINGS_SUCCESS,
    FETCH_INDICATORS_SETTINGS_SUCCESS,
} from '../actionTypes';
import { proposalIndicatorsActions } from '../reducer';

import setIsOpenIndicatorsSettingsModal from './setIsOpenIndicatorsSettingsModal';

export default (values) => (dispatch) => {
    dispatch(proposalIndicatorsActions[SAVE_INDICATORS_SETTINGS]());

    saveProposalIndicatorsSettings({ indicators: values })
        .then((response) => {
            dispatch(
                proposalIndicatorsActions[SAVE_INDICATORS_SETTINGS_SUCCESS](
                    response?.data?.data
                )
            );
            dispatch(
                proposalIndicatorsActions[FETCH_INDICATORS_SETTINGS_SUCCESS](
                    response.data.data
                )
            );
            dispatch(setIsOpenIndicatorsSettingsModal(false));
            showToast();
        })
        .catch((error) => {
            dispatch(
                proposalIndicatorsActions[SAVE_INDICATORS_SETTINGS_FAILURE](
                    error
                )
            );
            showReponseErrorsAsAlert(dispatch, error?.response);
        });
};
