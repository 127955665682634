import { getSupportsByContact } from 'common/api/v1/contactSupport';

import {
    FETCH_SUPPORT_CONTACT,
    FETCH_SUPPORT_CONTACT_FAILURE,
    FETCH_SUPPORT_CONTACT_SUCCESS,
} from '../actionTypes';
import { supportContactFormActions } from '../reducer';
import * as selectors from '../selectors';

export default () => (dispatch, getState) => {
    const state = getState();
    const contactId = selectors.getContactId(state);

    dispatch(supportContactFormActions[FETCH_SUPPORT_CONTACT]());

    getSupportsByContact(contactId)
        .then((response) =>
            dispatch(
                supportContactFormActions[FETCH_SUPPORT_CONTACT_SUCCESS](
                    response.data
                )
            )
        )
        .catch((error) =>
            dispatch(
                supportContactFormActions[FETCH_SUPPORT_CONTACT_FAILURE](error)
            )
        );
};
