import { NAME } from './constants';

export const CLEAR_DOWNLOAD_CSV = `${NAME}/CLEAR_DOWNLOAD_CSV`;

export const DOWNLOAD_CSV = `${NAME}/DOWNLOAD_CSV`;
export const DOWNLOAD_CSV_SUCCESS = `${NAME}/DOWNLOAD_CSV_SUCCESS`;
export const DOWNLOAD_CSV_FAILURE = `${NAME}/DOWNLOAD_CSV_FAILURE`;

export const DOWNLOAD_HISTORY = `${NAME}/DOWLOAD_HISTORY`;
export const DOWNLOAD_HISTORY_SUCCESS = `${NAME}/DOWLOAD_HISTORY_SUCCESS`;
export const DOWNLOAD_HISTORY_FAILURE = `${NAME}/DOWLOAD_HISTORY_FAILURE`;

export const FETCH_CONSUMPTION_ENERGY = `${NAME}/FETCH_CONSUMPTION_ENERGY`;
export const FETCH_CONSUMPTION_ENERGY_NO_ANIMATION = `${NAME}/FETCH_CONSUMPTION_ENERGY_NO_ANIMATION`;
export const FETCH_CONSUMPTION_ENERGY_SUCCESS = `${NAME}/FETCH_CONSUMPTION_ENERGY_SUCCESS`;
export const FETCH_CONSUMPTION_ENERGY_FAILURE = `${NAME}/FETCH_CONSUMPTION_ENERGY_FAILURE`;

export const SELECT_PROJECT = `${NAME}/SELECT_PROJECT`;

export const FETCH_STATUS_PROJECT = `${NAME}/FETCH_STATUS_PROJECT`;
export const FETCH_STATUS_PROJECT_FAILURE = `${NAME}/FETCH_STATUS_PROJECT_FAILURE`;
export const FETCH_STATUS_PROJECT_SUCCESS = `${NAME}/FETCH_STATUS_PROJECT_SUCCESS`;

export const UPDATE_CONSUMPTION_ENERGY = `${NAME}/UPDATE_CONSUMPTION_ENERGY`;
export const UPDATE_CONSUMPTION_ENERGY_SUCCESS = `${NAME}/UPDATE_CONSUMPTION_ENERGY_SUCCESS`;
export const UPDATE_CONSUMPTION_ENERGY_FAILURE = `${NAME}/UPDATE_CONSUMPTION_ENERGY_FAILURE`;
