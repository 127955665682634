import React, { useEffect } from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Grid } from 'sunwise-ui';

import {
    ReactHookFormSelect,
    ReactHookFormSelect2,
} from 'common/components/form/bootstrap';
import { DOMESTIC_RATES, RATES_WITH_DIVISION } from 'common/constants/rates';
import { getCompatibleCompensationSchemes } from 'common/utils/helpers/rates';

import * as actions from '../actions';
import { PERIODICITY_TYPES, SUBSIDY_RATES } from '../constants';
import { getPeriodicityOptions } from '../helpers';
import * as selectors from '../selectors';

import FormAccordion from './FormAccordion';

const RateSection = ({
    control,
    disabled,
    fetchRates,
    fetchScheduleRateConfiguration,
    fetchSummerMonths,
    getValues,
    isLoadingRateDivisions,
    isLoadingRates,
    isLoadingRegions,
    isLoadingSummerMonths,
    rateDivisions,
    rates,
    ratesDictionary,
    regions,
    selectedRate,
    setValue,
    summerMonths,
}) => {
    const { t } = useTranslation();

    useEffect(() => {
        fetchRates();
        fetchSummerMonths();
    }, []);

    return (
        <FormAccordion hasFormErrors={false} title={t('Rate')}>
            <Grid container>
                <Grid item xs={18} lg={9}>
                    <ReactHookFormSelect2
                        control={control}
                        disabled={isLoadingRates || disabled}
                        label={t('Rate')}
                        name="rate"
                        onChange={(value) => {
                            const newRate = ratesDictionary[value];
                            fetchScheduleRateConfiguration({
                                certified: newRate?.certified,
                                rate: value,
                                rate_division: getValues('rate_division'),
                            });
                            setValue('compensation_scheme', null);

                            if (newRate?.certified) return;
                            const periodicityType =
                                PERIODICITY_TYPES[newRate?.periodicity_type];
                            setValue('is_bimonthly', periodicityType);
                        }}
                        options={rates}
                        variant="standard"
                    />
                </Grid>

                <Grid item xs={18} lg={9}>
                    <ReactHookFormSelect
                        control={control}
                        disabled={disabled}
                        label={t('Period')}
                        name="is_bimonthly"
                        options={getPeriodicityOptions(selectedRate)}
                        variant="standard"
                    />
                </Grid>

                {selectedRate?.certified && (
                    <>
                        {RATES_WITH_DIVISION.includes(selectedRate.name) && (
                            <Grid item xs={18} lg={9}>
                                <ReactHookFormSelect2
                                    control={control}
                                    disabled={
                                        isLoadingRateDivisions || disabled
                                    }
                                    isClearable={false}
                                    isLoading={isLoadingRateDivisions}
                                    label={t('Division').concat(' *')}
                                    name="rate_division"
                                    onChange={(value) => {
                                        fetchScheduleRateConfiguration({
                                            certified: selectedRate.certified,
                                            rate: selectedRate.id,
                                            rateDivision: value,
                                        });
                                    }}
                                    options={[
                                        { label: '', options: rateDivisions },
                                    ]}
                                    variant="standard"
                                />
                            </Grid>
                        )}

                        {selectedRate.name === 'DAC' && (
                            <>
                                <Grid item xs={18} lg={9}>
                                    <ReactHookFormSelect2
                                        control={control}
                                        disabled={disabled}
                                        isClearable={false}
                                        label={t('Subsidized rate').concat(
                                            ' *'
                                        )}
                                        name="subsidy_rate"
                                        options={[
                                            {
                                                label: '',
                                                options: SUBSIDY_RATES,
                                            },
                                        ]}
                                        variant="standard"
                                    />
                                </Grid>

                                <Grid item xs={18} lg={9}>
                                    <ReactHookFormSelect2
                                        control={control}
                                        disabled={disabled}
                                        isClearable={false}
                                        isLoading={isLoadingRegions}
                                        label={t('Region').concat(' *')}
                                        name="rate_region"
                                        options={[
                                            { label: '', options: regions },
                                        ]}
                                        variant="standard"
                                    />
                                </Grid>
                            </>
                        )}

                        {DOMESTIC_RATES.includes(selectedRate.name) && (
                            <Grid item xs sm={9}>
                                <ReactHookFormSelect2
                                    control={control}
                                    disabled={disabled}
                                    isClearable={false}
                                    isLoading={isLoadingSummerMonths}
                                    label={t('Start of summer').concat(' *')}
                                    name="rate_division_summer"
                                    options={[
                                        { label: '', options: summerMonths },
                                    ]}
                                    variant="standard"
                                />
                            </Grid>
                        )}
                    </>
                )}

                <Grid item xs={18} lg={9}>
                    <ReactHookFormSelect
                        control={control}
                        disabled={disabled}
                        label={t('Energy compensation scheme')}
                        name="compensation_scheme"
                        options={getCompatibleCompensationSchemes({
                            canViewSelfConsumption: true,
                            certified: selectedRate?.certified,
                            name: selectedRate?.name,
                            paymentType: selectedRate?.payment_type,
                        })}
                        variant="standard"
                    />
                </Grid>
            </Grid>
        </FormAccordion>
    );
};

RateSection.propTypes = {
    control: PropTypes.object,
    disabled: PropTypes.bool,
    fetchRates: PropTypes.func,
    fetchScheduleRateConfiguration: PropTypes.func,
    fetchSummerMonths: PropTypes.func,
    getValues: PropTypes.func,
    isLoadingRateDivisions: PropTypes.bool,
    isLoadingRates: PropTypes.bool,
    isLoadingRegions: PropTypes.bool,
    isLoadingSummerMonths: PropTypes.bool,
    rateDivisions: PropTypes.array,
    rates: PropTypes.array,
    ratesDictionary: PropTypes.object,
    regions: PropTypes.array,
    selectedRate: PropTypes.object,
    setValue: PropTypes.func,
    summerMonths: PropTypes.array,
};

const mapStateToProps = createStructuredSelector({
    isLoadingRateDivisions: selectors.getRateDivisionsIsLoading,
    isLoadingRates: selectors.getRatesIsLoading,
    isLoadingRegions: selectors.getRegionsIsLoading,
    isLoadingSummerMonths: selectors.getSummerMonthsIsLoading,
    rateDivisions: selectors.getRateDivisionsForSelect,
    rates: selectors.getRatesForSelect,
    regions: selectors.getRegionsForSelect,
    summerMonths: selectors.getSummerMonthsForSelect,
});

const mapDispatchToProps = (dispatch) => ({
    fetchRates: () => dispatch(actions.fetchRates()),
    fetchScheduleRateConfiguration: (params) =>
        dispatch(actions.fetchScheduleRateConfiguration(params)),
    fetchSummerMonths: () => dispatch(actions.fetchSummerMonths()),
});

export default connect(mapStateToProps, mapDispatchToProps)(RateSection);
