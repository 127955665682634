import { getCountries } from 'common/api/v1/countries';

import {
    FETCH_COUNTRIES,
    FETCH_COUNTRIES_FAILURE,
    FETCH_COUNTRIES_SUCCESS,
} from '../actionTypes';
import { createAccountActions } from '../reducer';

export default () => (dispatch) => {
    dispatch(createAccountActions[FETCH_COUNTRIES]());

    getCountries()
        .then((response) =>
            dispatch(
                createAccountActions[FETCH_COUNTRIES_SUCCESS](
                    response.data.data
                )
            )
        )
        .catch((error) =>
            dispatch(
                createAccountActions[FETCH_COUNTRIES_FAILURE](
                    error?.response?.data?.errors
                )
            )
        );
};
