import { getViews } from 'common/api/v1/interactiveProposal';

import {
    FETCH_VIEWS,
    FETCH_VIEWS_SUCCESS,
    FETCH_VIEWS_FAILURE,
} from '../actionTypes';
import { projectCommOfferTableActions } from '../reducer';

export default (id) => (dispatch) => {
    dispatch(projectCommOfferTableActions[FETCH_VIEWS]());

    getViews(id)
        .then((response) => {
            dispatch(
                projectCommOfferTableActions[FETCH_VIEWS_SUCCESS](response.data)
            );
        })
        .catch((error) =>
            dispatch(
                projectCommOfferTableActions[FETCH_VIEWS_FAILURE](
                    error?.response?.data?.errors
                )
            )
        );
};
