import React from 'react';

import { isArray, isUndefined } from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import * as multiBranchesSelectors from 'common/modules/multiBranches/selectors';

const getIsMultiBranchMode = (multiBranchMode, path) => {
    if (isUndefined(multiBranchMode)) return false;
    if (isArray(multiBranchMode)) return multiBranchMode.indexOf(path) >= 0;
    return multiBranchMode;
};

const LayoutComponent = ({
    branchSelected,
    component: Component,
    layout: Layout,
    matchProps,
    multiBranchMode,
    rest,
}) => {
    const isMultiBranchMode = getIsMultiBranchMode(
        multiBranchMode,
        matchProps.match.url
    );

    return (
        <Layout
            {...{
                ...rest,
                multiBranchMode: isMultiBranchMode,
            }}
        >
            {branchSelected && (
                <Component
                    {...{ ...matchProps, branchSelected }}
                    key={`multi-branch-layout-${branchSelected}`}
                />
            )}
        </Layout>
    );
};

const mapStateToProps = createStructuredSelector({
    branchSelected: multiBranchesSelectors.getBranchSelected,
});

LayoutComponent.propTypes = {
    branchSelected: PropTypes.string,
    component: PropTypes.func,
    layout: PropTypes.func,
    matchProps: PropTypes.object,
    multiBranchMode: PropTypes.oneOfType([PropTypes.bool, PropTypes.array]),
    rest: PropTypes.object,
};

export default connect(mapStateToProps, null)(LayoutComponent);
