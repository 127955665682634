import React, { useEffect } from 'react';

import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Box, Grid } from 'sunwise-ui';

import {
    LabelDecorator,
    ProgressLine,
    StyledSyncAltIcon,
} from 'common/components';
import { ReactHookFormIntlNumberInput } from 'common/components/form/bootstrap';
import { GENERATION_SOURCES } from 'common/constants';

import * as proposalGeneratorOneColumnSelectors from '../../proposalGeneratorOneColumn/selectors';
import * as proposalGeneratorPanelsLayoutSelectors from '../../proposalGeneratorPanelsLayout/selectors';
import {
    handleOnChangeEnergySaving,
    handleOnChangePanelNumber,
    handleOnChangeSystemSize,
} from '../helpers';
import * as selectors from '../selectors';

import GenerationSourceSelector from './GenerationSourceSelector';
import PanelWarnings from './PanelWarnings';

const FormContainer = ({
    canModify,
    contractedDemand,
    customerEnergyData,
    handleNormalize,
    initialRate,
    initialValues,
    isLoading,
    isLocked,
    isLockedDefault,
    isZeroExportActive,
    numOfferPanels = 0,
    offerInverters,
    originalConsumption,
    pvSystemDataSource,
    rateAlertsData,
    sunHours,
    parentFieldSegmentCounters,
}) => {
    const { t } = useTranslation();
    const { control, getValues, reset, watch } = useForm({ initialValues });

    useEffect(() => reset(initialValues), [initialValues]);
    const energySaving = watch('energy_saving');

    const { threads_number } = customerEnergyData;
    const limitKw = threads_number
        ? customerEnergyData?.rate?.[`solar_limit_${threads_number}`] ||
          Infinity
        : Infinity;
    const hasPanel = Boolean(initialValues?.panel);
    const isDisabled =
        !canModify || isLocked || !hasPanel || numOfferPanels > 1;
    const showUpdateButton =
        !isDisabled &&
        pvSystemDataSource === GENERATION_SOURCES.DESIGNER &&
        parentFieldSegmentCounters?.solarModulesDesignNumber !==
            initialValues?.quantity;

    const updatePanelsWithSegments = () => {
        handleOnChangePanelNumber(
            getValues(),
            handleNormalize,
            parentFieldSegmentCounters?.solarModulesDesignNumber
        );
    };

    const stylesBox = {
        alignItems: 'end',
        display: 'flex',
        gap: { md: 2, xs: 0 },
    };
    const stylesStyledSyncAltIconBox = { display: { md: 'block', xs: 'none' } };
    const gridItemCols = {
        xs: 18,
        md: pvSystemDataSource === GENERATION_SOURCES.NASA ? 6 : 9,
    };
    return (
        <Box>
            <GenerationSourceSelector isLocked={isLockedDefault} />

            <PanelWarnings
                contractedDemand={contractedDemand}
                energySaving={initialValues?.energy_saving}
                hasPanel={hasPanel}
                initialRate={initialRate}
                isLoading={isLoading}
                limitKw={limitKw}
                numOfferPanels={numOfferPanels}
                offerInverters={offerInverters}
                pvSystemDataSource={pvSystemDataSource}
                rateAlertsData={rateAlertsData}
                showUpdateButton={showUpdateButton}
                systemSize={initialValues?.system_size}
                updatePanelsWithSegments={updatePanelsWithSegments}
            />

            <Grid container>
                {pvSystemDataSource === GENERATION_SOURCES.NASA && (
                    <Grid item xs={18} md={6} sx={stylesBox}>
                        <ReactHookFormIntlNumberInput
                            allowDecimals
                            allowNegativeValue={false}
                            append="%"
                            control={control}
                            disabled={isDisabled || isZeroExportActive}
                            fullWidth
                            label={
                                <Box alignItems="center" display="flex" gap={2}>
                                    {t('Energy saving', {
                                        context: 'adjective',
                                    })}
                                    <LabelDecorator />
                                </Box>
                            }
                            name="energy_saving"
                            onChange={({ target: { value } }) =>
                                handleOnChangeEnergySaving(
                                    getValues(),
                                    handleNormalize,
                                    originalConsumption,
                                    sunHours,
                                    value
                                )
                            }
                            step="any"
                            variant="standard"
                        />

                        <Box sx={stylesStyledSyncAltIconBox}>
                            <StyledSyncAltIcon fontSize="medium" />
                        </Box>
                    </Grid>
                )}

                <Grid item {...gridItemCols} sx={stylesBox}>
                    <ReactHookFormIntlNumberInput
                        allowDecimals
                        allowNegativeValue={false}
                        append="kW"
                        control={control}
                        disabled={isDisabled}
                        fullWidth
                        label={t('System size')}
                        name="system_size"
                        onChange={({ target: { value } }) =>
                            handleOnChangeSystemSize(
                                getValues(),
                                handleNormalize,
                                value
                            )
                        }
                        step="any"
                        variant="standard"
                    />

                    <Box sx={stylesStyledSyncAltIconBox}>
                        <StyledSyncAltIcon fontSize="medium" />
                    </Box>
                </Grid>

                <Grid item {...gridItemCols}>
                    <ReactHookFormIntlNumberInput
                        allowDecimals={false}
                        allowNegativeValue={false}
                        append="u"
                        control={control}
                        disabled={isDisabled}
                        fullWidth
                        label={t('Number of panels')}
                        name="quantity"
                        onChange={({ target: { value } }) =>
                            handleOnChangePanelNumber(
                                getValues(),
                                handleNormalize,
                                value
                            )
                        }
                        step="any"
                        variant="standard"
                    />
                </Grid>
            </Grid>

            <Box mt={4} mb={2}>
                <ProgressLine value={Math.min(Number(energySaving), 100)} />
            </Box>
        </Box>
    );
};

const mapStateToProps = createStructuredSelector({
    initialValues: selectors.getInitialValues,
    isZeroExportActive:
        proposalGeneratorOneColumnSelectors.getIsZeroExportActive,
    parentFieldSegmentCounters:
        proposalGeneratorPanelsLayoutSelectors.getParentFieldSegmentCounters,
});

FormContainer.propTypes = {
    canModify: PropTypes.bool,
    contractedDemand: PropTypes.number,
    customerEnergyData: PropTypes.object,
    handleNormalize: PropTypes.func,
    initialRate: PropTypes.string,
    initialValues: PropTypes.object,
    isLoading: PropTypes.bool,
    isLocked: PropTypes.bool,
    isLockedDefault: PropTypes.bool,
    isZeroExportActive: PropTypes.bool,
    numOfferPanels: PropTypes.number,
    offerInverters: PropTypes.array,
    originalConsumption: PropTypes.number,
    parentFieldSegmentCounters: PropTypes.object,
    pvSystemDataSource: PropTypes.number,
    rateAlertsData: PropTypes.array,
    sunHours: PropTypes.number,
};

export default connect(mapStateToProps)(FormContainer);
