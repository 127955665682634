import get from 'lodash/get';

import { parseExponentialNumber } from 'common/utils/helpers';

import { INITIAL_VALUES } from '../actionTypes';
import { proposalGeneratorAccessoriesTableActions } from '../reducer';

export default (data) => (dispatch) => {
    const items = data.map((product) => {
        return {
            cost: product.cost,
            discount_type: product.discount_type,
            discount: product.discount,
            final_cost: product.final_cost,
            id: product.id,
            item: get(product, 'product_branch_office.id', null),
            margin: parseExponentialNumber(product.margin),
            percentage_discount: product.percentage_discount,
            quantity: product.quantity,
            selectedProduct: get(product, 'product_branch_office.id', null),
            temp_cost: product.cost,
            temp_discount: product.discount,
            temp_margin: product.margin,
            temp_quantity: product.quantity,
            unit_price: product.unit_price,
        };
    });

    dispatch(
        proposalGeneratorAccessoriesTableActions[INITIAL_VALUES]({
            items,
        })
    );
};
