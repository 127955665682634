import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box, DataGrid, Typography } from 'sunwise-ui';

import DropdownCardMenu from 'common/components/cards/DropdownCardMenu';
import { CB_PORTAL_SECTIONS, SUBSCRIPTION_STATUS } from 'common/constants';

import { getStatusDetails, getSubscriptionName } from '../helpers';

const SubscriptionTable = ({ canModify, handlePortal, items }) => {
    const { t } = useTranslation();

    const columns = [
        {
            align: 'left',
            field: 'item_price_id',
            flex: 1,
            headerName: t('Name'),
            minWidth: 250,
            renderCell: (params) => {
                const { row } = params;
                return (
                    <Typography
                        fontWeight={600}
                        variant="body2"
                        whiteSpace="pre-line"
                    >
                        {getSubscriptionName(row?.item_price_id)}{' '}
                        {row?.billing_period_unit === 'year'
                            ? t('Annual')
                            : t('Monthly')}
                    </Typography>
                );
            },
            sortable: false,
        },
        {
            align: 'left',
            field: 'amount',
            flex: 1,
            headerName: t('Amount'),
            minWidth: 120,
            sortable: false,
        },
        {
            align: 'left',
            field: 'status',
            flex: 1,
            headerName: t('Status'),
            minWidth: 110,
            renderCell: (params) => {
                const { row } = params;
                return getStatusDetails(row?.status);
            },
            sortable: false,
        },
        {
            align: 'right',
            field: '',
            flex: 1,
            renderCell: (params) => {
                const { row } = params;
                return (
                    <DropdownCardMenu
                        items={[
                            {
                                handleClick: () =>
                                    handlePortal(
                                        CB_PORTAL_SECTIONS.SUBSCRIPTION_CANCELLATION,
                                        row?.id
                                    ),
                                label: t('Cancel subscription'),
                                visible:
                                    row?.status === SUBSCRIPTION_STATUS.ACTIVE,
                            },
                            {
                                handleClick: () =>
                                    handlePortal(
                                        CB_PORTAL_SECTIONS.SUBSCRIPTION_DETAILS,
                                        row?.id
                                    ),
                                label: t('Active subscription'),
                                visible: [
                                    SUBSCRIPTION_STATUS.CANCELLED,
                                    SUBSCRIPTION_STATUS.NON_RENEWING,
                                ].includes(row?.status),
                            },
                        ]}
                        sxIconButton={{ ml: 'auto' }}
                        visible={canModify}
                    />
                );
            },
            sortable: false,
        },
    ];

    return (
        <Box sx={{ mt: 1 }}>
            <DataGrid
                autoHeight
                checkboxSelection={false}
                columns={columns}
                disableColumnMenu
                disableSelectionOnClick
                hideFooter
                rows={items}
            />
        </Box>
    );
};

SubscriptionTable.propTypes = {
    canModify: PropTypes.bool,
    handlePortal: PropTypes.func,
    items: PropTypes.array,
};

export default SubscriptionTable;
