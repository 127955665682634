import React, { useState } from 'react';

import debounce from 'lodash/debounce';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box, Grid, ToggleButton, ToggleButtonGroup } from 'sunwise-ui';

import { ReactHookFormIntlNumberInput } from 'common/components/form/bootstrap';
import TitleWithDetail from 'common/components/TitleWithDetail';
import { useBreakpoint } from 'common/hooks';

import { handleOnChangeValue } from '../helpers';

const Generation = ({
    annualKWh,
    control,
    dailyAverageKWh,
    initialValues,
    isLocked,
    setValue,
    submitForm,
}) => {
    const [preffixMode, setPreffixMode] = useState('annual_kWh');
    const { t } = useTranslation();
    const breakpoint = useBreakpoint();

    const handleOnChange = React.useRef(
        debounce(handleOnChangeValue, 1000)
    ).current;

    const preFlight = (newValue, previousValue, payload) => {
        if (parseFloat(newValue) === parseFloat(previousValue)) return;
        handleOnChange(payload);
    };

    return (
        <Box px={2}>
            <TitleWithDetail variant="body2">
                {`${t('Consumption')} - ${t('Manual').toLowerCase()}`}
            </TitleWithDetail>

            <Grid container mt={1}>
                <Grid item xs={18} md={4} lg={6}>
                    {preffixMode === 'annual_kWh' && (
                        <ReactHookFormIntlNumberInput
                            control={control}
                            disabled={isLocked}
                            fullWidth
                            label={`${t('Annual')} kWh`}
                            name="annual_kWh"
                            onChange={({ target: { value } }) =>
                                preFlight(value, initialValues.annual_kWh, {
                                    annualKWh,
                                    base: annualKWh,
                                    dailyAverageKWh,
                                    field: 'annual_kWh',
                                    setValue,
                                    submitForm,
                                    value: parseFloat(value),
                                })
                            }
                        />
                    )}

                    {preffixMode === 'daily_average_kWh' && (
                        <ReactHookFormIntlNumberInput
                            control={control}
                            disabled={isLocked}
                            fullWidth
                            label={`${t('Daily average')} kWh`}
                            name="daily_average_kWh"
                            onChange={({ target: { value } }) =>
                                preFlight(
                                    value,
                                    initialValues.daily_average_kWh,
                                    {
                                        annualKWh,
                                        base: dailyAverageKWh,
                                        dailyAverageKWh,
                                        field: 'daily_average_kWh',
                                        setValue,
                                        submitForm,
                                        value: parseFloat(value),
                                    }
                                )
                            }
                        />
                    )}

                    {preffixMode === 'consumption_percentage' && (
                        <ReactHookFormIntlNumberInput
                            control={control}
                            disabled={isLocked}
                            fullWidth
                            label={t('Percentage')}
                            name="consumption_percentage"
                            onChange={({ target: { value } }) =>
                                preFlight(
                                    value,
                                    initialValues.consumption_percentage,
                                    {
                                        annualKWh,
                                        dailyAverageKWh,
                                        field: 'consumption_percentage',
                                        setValue,
                                        submitForm,
                                        value: parseFloat(value),
                                    }
                                )
                            }
                        />
                    )}
                </Grid>
                <Grid
                    item
                    xs={18}
                    md={14}
                    lg={12}
                    sx={{ pt: { xs: 1, md: 0 } }}
                >
                    <ToggleButtonGroup
                        color="secondary"
                        disabled={isLocked}
                        exclusive
                        fullWidth
                        onChange={(e) => setPreffixMode(e.target.value)}
                        orientation={
                            ['xs', 'sm', 'md'].includes(breakpoint)
                                ? 'vertical'
                                : 'horizontal'
                        }
                        size="small"
                        value={preffixMode}
                    >
                        {[
                            {
                                label: `${t('Annual')} kWh`,
                                value: 'annual_kWh',
                            },
                            {
                                label: `${t('Daily average')} kWh`,
                                value: 'daily_average_kWh',
                            },
                            {
                                label: t('Percentage'),
                                value: 'consumption_percentage',
                            },
                        ].map((item) => (
                            <ToggleButton
                                disabled={item.disabled}
                                key={`toggle-buttons-${item.value}`}
                                value={item.value}
                            >
                                {item.label}
                            </ToggleButton>
                        ))}
                    </ToggleButtonGroup>
                </Grid>
            </Grid>
        </Box>
    );
};

Generation.propTypes = {
    annualKWh: PropTypes.number,
    control: PropTypes.object,
    dailyAverageKWh: PropTypes.number,
    initialValues: PropTypes.object,
    isLocked: PropTypes.bool,
    setValue: PropTypes.func,
    submitForm: PropTypes.func,
};

export default Generation;
