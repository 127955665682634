import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Alert } from 'sunwise-ui';

import { DOMESTIC_RATES } from 'common/constants/rates';

import { avgRates, getLimitRate } from '../../../../helpers';
import * as selectors from '../../../../selectors';

const DACAlerts = ({
    ratesNameDictionary,
    selectedRate,
    subsidyRate,
    summary,
}) => {
    const { t } = useTranslation();

    if (!selectedRate || !selectedRate.isCertified) return null;

    const limitRate = getLimitRate(subsidyRate, ratesNameDictionary);
    const avgRate = avgRates(summary);
    const { name, limitDac } = selectedRate;

    return (
        <>
            {name === 'DAC' && limitRate > avgRate && (
                <Alert severity="warning">
                    {t('The consumptions entered do not represent a DAC rate')}
                </Alert>
            )}
            {name !== 'DAC' &&
                DOMESTIC_RATES.includes(name) &&
                limitDac < avgRate && (
                    <Alert severity="warning">
                        {t('The consumptions entered represent a DAC rate')}
                    </Alert>
                )}
        </>
    );
};

const mapStateToProps = createStructuredSelector({
    ratesNameDictionary: selectors.getRatesNameDictionary,
});

DACAlerts.propTypes = {
    ratesNameDictionary: PropTypes.object,
    selectedRate: PropTypes.object,
    subsidyRate: PropTypes.string,
    summary: PropTypes.array,
};

export default connect(mapStateToProps, null)(DACAlerts);
