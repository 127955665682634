import React, { useEffect } from 'react';

import { get, isEmpty, isNull } from 'lodash';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Box, Card, Typography } from 'sunwise-ui';

import { CreditDocuments, TitleWithDetail } from 'common/components';
import StepperTimeline from 'common/components/StepperTimeline';
import { BRIGHT_UID, SERFIMEX_UID, STATUS } from 'common/constants';
import chatView from 'common/modules/chatView';
import { CREDIT_CHAT_TYPE } from 'common/modules/chatView/constants';
import { cleanJSON } from 'common/utils/helpers';
import { getSessionLocalStorage } from 'common/utils/helpers/session';

import CreditBureauCard from '../../../creditBureauCard';
import * as actions from '../../actions';
import { MORAL_PERSON_TYPE } from '../../constants';
import {
    getIsShowContracts,
    getIsShowDocuments,
    getIsShowInsuranceDocuments,
    getIsShowRequestDocuments,
} from '../../helpers';
import * as selectors from '../../selectors';

import AllianceDetailsRequest from './AllianceDetailsRequest';
import AllianceWarning from './AllianceWarning';
import CancelDropdown from './CancelDropdown';
import ConditionalApprovedWarning from './ConditionalApprovedWarning';
import InstallerCard from './InstallerCard';

const Container = ({
    cancelCreditApp,
    contracts,
    creditItem,
    documents,
    fetchCreditBureauData,
    fetchCreditDocuments,
    fetchCommercialOffers,
    handleToggleDrawer,
    insuranceDocuments,
    isArchived,
    isOpenDrawer,
    onClickStatus,
    prepareApproveChangesOnCredit,
    projectId,
    requestFormDocuments,
    setSelectedCreditItem,
}) => {
    const { t } = useTranslation();

    useEffect(() => {
        const creditAppId = get(creditItem, 'creditAppId', null);
        if (!isNull(creditAppId)) {
            fetchCreditDocuments(creditAppId);
        }
        if (get(creditItem, 'hasRequestBureau', false) && offerId) {
            fetchCreditBureauData(offerId);
        }
    }, [creditItem]);

    if (isEmpty(creditItem)) return null;

    const {
        credit_type: creditType,
        creditAppId,
        currency,
        financier_installer,
        financier_product,
        hasRequestBureau,
        infonavit_data,
        json_values,
        offerId,
        proposalId,
        typesBusiness,
    } = creditItem;

    const handleOnClickApproveChangesOnCredit = () =>
        prepareApproveChangesOnCredit(creditItem, () => {
            fetchCommercialOffers(projectId, isArchived);
            handleToggleDrawer(false);
        });

    const name = get(financier_product, 'name', '');
    const description = get(financier_product, 'description', '');
    const allianceStatus = get(financier_installer, 'status', null);
    const creditStatus = get(creditItem, 'status', null);
    const infonavitData = infonavit_data
        ? JSON.parse(cleanJSON(infonavit_data || '{}'))
        : {};
    const jsonValues = json_values
        ? JSON.parse(cleanJSON(json_values || '{}'))
        : {};
    const agent = get(financier_installer, 'agent', {});
    const primaTotal = get(jsonValues, 'prima_total', 0);
    const subTotal = get(jsonValues, 'subtotal', 0);
    const financierId = get(financier_installer, 'financier.id', '');
    const financierName = get(financier_installer, 'financier.name', '');
    const isBright = financierId === BRIGHT_UID;
    const isSerfimex = financierId === SERFIMEX_UID;
    const isMoralPerson = typesBusiness === MORAL_PERSON_TYPE;
    const isCancelledCreditStatus =
        creditStatus === STATUS.CANCELLED_STATUS.key;

    return (
        <Box display="flex" flexDirection="column">
            <Box display="flex" alignItems="center" mb={1}>
                <TitleWithDetail fontSize="16">{t('Request')}</TitleWithDetail>
                {cancelCreditApp && (
                    <CancelDropdown
                        cancelCreditApp={cancelCreditApp}
                        status={creditStatus}
                    />
                )}
            </Box>

            <Box sx={{ minHeight: '60px' }} mb={2}>
                <StepperTimeline status={creditStatus} />
            </Box>

            <ConditionalApprovedWarning
                financierName={financierName}
                handleOnClickApproveChangesOnCredit={
                    handleOnClickApproveChangesOnCredit
                }
                status={creditStatus}
            />

            <AllianceWarning
                allianceStatus={allianceStatus}
                creditType={creditType}
                session={getSessionLocalStorage()}
            />

            <InstallerCard agent={agent} />

            <AllianceDetailsRequest
                creditItem={creditItem}
                creditType={creditType}
                currency={currency}
                infonavitData={infonavitData}
                primaTotal={primaTotal}
                subTotal={subTotal}
            />

            <Card>
                <Card.Header>
                    <TitleWithDetail fontSize="16">{name}</TitleWithDetail>
                </Card.Header>
                <Card.Body>
                    <Typography variant="body2">{description}</Typography>
                </Card.Body>
            </Card>

            {!isMoralPerson && !isBright && !isSerfimex && (
                <CreditBureauCard.Container
                    creditItem={creditItem}
                    hasRequestBureau={hasRequestBureau}
                    isArchived={isArchived}
                    isOpenDrawer={isOpenDrawer}
                    offerId={offerId}
                    projectId={projectId}
                    proposalId={proposalId}
                    setSelectedCreditItem={setSelectedCreditItem}
                />
            )}

            {getIsShowRequestDocuments(
                creditType,
                hasRequestBureau,
                isBright,
                isMoralPerson,
                isSerfimex,
                requestFormDocuments
            ) && (
                <Card>
                    <Card.Header>
                        <TitleWithDetail fontSize="16">
                            {t('Request form')}
                        </TitleWithDetail>
                    </Card.Header>
                    <Card.Body>
                        <CreditDocuments
                            documents={requestFormDocuments}
                            onClickStatus={onClickStatus}
                            preDownload={true}
                            isDisabledStatus={isCancelledCreditStatus}
                        />
                    </Card.Body>
                </Card>
            )}

            {getIsShowInsuranceDocuments(
                creditType,
                hasRequestBureau,
                insuranceDocuments,
                isBright,
                isMoralPerson,
                isSerfimex
            ) && (
                <Card>
                    <Card.Header>
                        <TitleWithDetail fontSize="16">
                            {t('Insurance application form')}
                        </TitleWithDetail>
                    </Card.Header>
                    <Card.Body>
                        <CreditDocuments
                            documents={insuranceDocuments}
                            onClickStatus={onClickStatus}
                            preDownload={true}
                            isDisabledStatus={isCancelledCreditStatus}
                        />
                    </Card.Body>
                </Card>
            )}

            {getIsShowDocuments(
                documents,
                hasRequestBureau,
                isBright,
                isMoralPerson,
                isSerfimex
            ) && (
                <Card>
                    <Card.Header>
                        <TitleWithDetail fontSize="16">
                            {t('Document', { count: 2 })}
                        </TitleWithDetail>
                    </Card.Header>
                    <Card.Body>
                        <CreditDocuments
                            documents={documents}
                            onClickStatus={onClickStatus}
                            preDownload={false}
                            isDisabledStatus={isCancelledCreditStatus}
                        />
                    </Card.Body>
                </Card>
            )}

            {getIsShowContracts(
                contracts,
                hasRequestBureau,
                isBright,
                isMoralPerson,
                isSerfimex
            ) && (
                <Card>
                    <Card.Header>
                        <TitleWithDetail fontSize="16">
                            {t('Contract')}
                        </TitleWithDetail>
                    </Card.Header>
                    <Card.Body>
                        <CreditDocuments
                            documents={contracts}
                            onClickStatus={onClickStatus}
                            preDownload={true}
                            isDisabledStatus={isCancelledCreditStatus}
                        />
                    </Card.Body>
                </Card>
            )}

            <Card>
                <Card.Header>
                    <TitleWithDetail fontSize="16">{t('Chat')}</TitleWithDetail>
                </Card.Header>
                <Card.Body>
                    <chatView.Container
                        type={CREDIT_CHAT_TYPE}
                        entityId={creditAppId}
                        user={get(financier_installer, 'installer', {})}
                    />
                </Card.Body>
            </Card>
        </Box>
    );
};

const mapStateToProps = createStructuredSelector({
    contracts: selectors.getContractsList,
    documents: selectors.getDocumentsList,
    insuranceDocuments: selectors.getInsuranceDocumentsList,
    requestFormDocuments: selectors.getRequestFormDocumentsList,
});

const mapDispatchToProps = (dispatch) => ({
    fetchCreditBureauData: (comercialOfferUUID) =>
        dispatch(CreditBureauCard.actions.fetchBureauData(comercialOfferUUID)),
    fetchCreditDocuments: (creditAppID) =>
        dispatch(actions.fetchCreditDocuments(creditAppID)),
    prepareApproveChangesOnCredit: (creditItem, onSuccess) =>
        dispatch(actions.prepareApproveChangesOnCredit(creditItem, onSuccess)),
});

Container.propTypes = {
    cancelCreditApp: PropTypes.func,
    contracts: PropTypes.array,
    creditItem: PropTypes.object,
    documents: PropTypes.array,
    fetchCommercialOffers: PropTypes.func,
    fetchCreditBureauData: PropTypes.func,
    fetchCreditDocuments: PropTypes.func,
    handleToggleDrawer: PropTypes.func,
    insuranceDocuments: PropTypes.array,
    isArchived: PropTypes.bool,
    isOpenDrawer: PropTypes.bool,
    onClickStatus: PropTypes.func,
    prepareApproveChangesOnCredit: PropTypes.func,
    projectId: PropTypes.string,
    requestFormDocuments: PropTypes.array,
    setSelectedCreditItem: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(Container);
