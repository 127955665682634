import React from 'react';

import { Step, StepButton, StepLabel, Stepper } from '@mui/material';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { STEPS } from '../constants';

const StepperNavigation = ({
    currentStep,
    setCurrentStep,
    setCurrentStepError,
}) => {
    const { t } = useTranslation();
    return (
        <Stepper activeStep={currentStep} alternativeLabel sx={{ mt: 2 }}>
            {STEPS.map((step, index) => (
                <Step key={step.value}>
                    <StepButton
                        color="inherit"
                        onClick={() => {
                            if (index > currentStep) return;
                            setCurrentStep(index);
                            setCurrentStepError(null);
                        }}
                    >
                        <StepLabel>{t(step.label, { count: 2 })}</StepLabel>
                    </StepButton>
                </Step>
            ))}
        </Stepper>
    );
};

StepperNavigation.propTypes = {
    currentStep: PropTypes.number,
    setCurrentStep: PropTypes.func,
    setCurrentStepError: PropTypes.func,
};

export default StepperNavigation;
