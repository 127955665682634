import i18next from 'i18next';
import { get, isArray, isEmpty } from 'lodash';

export const getBranchOfficesDictionaryByAgent = (agents, isGlobal) => {
    return agents.reduce((acc, current) => {
        if (!isEmpty(get(current, 'branch_offices', []))) {
            const branchOffices = isGlobal
                ? current.branch_offices
                : current.branch_offices.filter(
                      (item) => item.id !== 'company'
                  );
            return {
                ...acc,
                [current.id]: branchOffices.map((item) => ({
                    label: item.name,
                    value: item.id,
                })),
            };
        }
        return { ...acc, [current.id]: [] };
    }, {});
};

export const getBranchOfficesIdsFromArray = (branchOffices) => {
    if (isArray(branchOffices) && !isEmpty(branchOffices)) {
        return branchOffices.map((item) => item.id).join();
    }
    return '';
};

export const getBrachOfficesOptionsWithoutGlobal = (
    branchesItems,
    hideCompany
) => {
    if (hideCompany)
        return branchesItems.filter((branch) => branch.value !== 'company');
    return branchesItems;
};

export const getProductBranchOfficesIds = (
    branchOfficesDictionary,
    product,
    branchSelected
) => {
    return (
        product?.branch_offices
            ?.filter(
                (b) =>
                    b.branch_id === null ||
                    branchOfficesDictionary[b.branch_id] ||
                    b.branch_id === branchSelected
            )
            .map((b) => (b.branch_id === null ? 'company' : b.branch_id)) || []
    );
};

export const getSelectedText = (
    multiBranchMode,
    branchesDictionary,
    branchSelected
) => {
    if (!multiBranchMode) return i18next.t('General');
    if (branchSelected === 'all') {
        return i18next.t('All text', { context: 'female', count: 2 });
    }
    return branchesDictionary[branchSelected];
};
