import { getProviders } from 'common/api/afterSales/providers';

import {
    FETCH_PROVIDERS,
    FETCH_PROVIDERS_FAILURE,
    FETCH_PROVIDERS_SUCCESS,
} from '../actionTypes';
import { credentialIntegrationsActions } from '../reducer';

export default () => (dispatch) => {
    dispatch(credentialIntegrationsActions[FETCH_PROVIDERS]());

    getProviders()
        .then((response) =>
            dispatch(
                credentialIntegrationsActions[FETCH_PROVIDERS_SUCCESS](
                    response.data
                )
            )
        )
        .catch((error) =>
            dispatch(
                credentialIntegrationsActions[FETCH_PROVIDERS_FAILURE](error)
            )
        );
};
