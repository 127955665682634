import { getBuyCustomWebpage } from 'common/api/v1/chargebee';

import {
    FETCH_CHARGEBEE_INTEGRATION,
    FETCH_CHARGEBEE_INTEGRATION_FAILURE,
    FETCH_CHARGEBEE_INTEGRATION_SUCCESS,
} from '../actionTypes';
import { webIntegrationsActions } from '../reducer';

const chargebeeIntegration = 'Cotizador-Web-Modulo-Adicional';

export default () => (dispatch) => {
    dispatch(webIntegrationsActions[FETCH_CHARGEBEE_INTEGRATION]());

    getBuyCustomWebpage({ product_id: chargebeeIntegration })
        .then((response) =>
            dispatch(
                webIntegrationsActions[FETCH_CHARGEBEE_INTEGRATION_SUCCESS](
                    response?.data?.data
                )
            )
        )
        .catch((error) =>
            dispatch(
                webIntegrationsActions[FETCH_CHARGEBEE_INTEGRATION_FAILURE](
                    error?.response?.data?.errors
                )
            )
        );
};
