import React, { useEffect } from 'react';

import FilterListIcon from '@mui/icons-material/FilterList';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Box, IconButton, Menu, MenuItem, Tooltip } from 'sunwise-ui';

import { getSessionLocalStorage } from 'common/utils/helpers/session';

import * as actions from '../actions';
import * as selectors from '../selectors';

const ToolbarMoreOptionsMenu = ({
    segmentFillMethod,
    setSegmentFillMethod,
    saveSegmentFillMethod,
}) => {
    const { t } = useTranslation();
    const [anchorEl, setAnchorEl] = React.useState(null);

    useEffect(() => {
        const session = getSessionLocalStorage();
        const autofill_panels = get(session, 'settings.autofill_panels', false);
        setSegmentFillMethod(autofill_panels);
    }, []);

    const open = Boolean(anchorEl);
    const sxBox = { maxWidth: '250px', whiteSpace: 'normal' };

    const handleOnClickIconButton = (event) => setAnchorEl(event.currentTarget);
    const handleOnClose = () => setAnchorEl(null);
    const handleOnClickMenuItem = (value) => {
        saveSegmentFillMethod(value, () => {
            setSegmentFillMethod(value);
            handleOnClose();
        });
    };

    return (
        <>
            <Tooltip title={t('Segment options')}>
                <Box id="panel-layout-toolbar-segment-options-button">
                    <IconButton onClick={handleOnClickIconButton} size="small">
                        <FilterListIcon fontSize="inherit" />
                    </IconButton>
                </Box>
            </Tooltip>

            <Menu
                anchorEl={anchorEl}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                onClose={handleOnClose}
                open={open}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            >
                <MenuItem
                    onClick={() => handleOnClickMenuItem(true)}
                    selected={true === segmentFillMethod}
                >
                    <Box sx={sxBox}>
                        <Box>
                            <strong>{t('Fill')}</strong>
                        </Box>
                        <Box>
                            {t('Fill the entire segment area with panels')}
                        </Box>
                    </Box>
                </MenuItem>
                <MenuItem
                    onClick={() => handleOnClickMenuItem(false)}
                    selected={false === segmentFillMethod}
                >
                    <Box sx={sxBox}>
                        <Box>
                            <strong>{t('Limited')}</strong>
                        </Box>
                        <Box>
                            {t(
                                'Limit the number of panels based on the number established in the proposal'
                            )}
                        </Box>
                    </Box>
                </MenuItem>
            </Menu>
        </>
    );
};

const mapStateToProps = createStructuredSelector({
    segmentFillMethod: selectors.getSegmentFillMethod,
});

const mapDispatchToProps = (dispatch) => ({
    saveSegmentFillMethod: (method, callback) =>
        dispatch(actions.saveSegmentFillMethod(method, callback)),
    setSegmentFillMethod: (method) =>
        dispatch(actions.setSegmentFillMethod(method)),
});

ToolbarMoreOptionsMenu.propTypes = {
    saveSegmentFillMethod: PropTypes.func,
    segmentFillMethod: PropTypes.bool,
    setSegmentFillMethod: PropTypes.func,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ToolbarMoreOptionsMenu);
