export const includesString = (str, prop) => {
    return prop && prop.toUpperCase().includes(str);
};

export const getFilteredProjects = (projects, value) => {
    if (!projects?.length) return;
    if (!value) return projects;
    const str = value.toUpperCase();
    return projects.filter(
        (project) =>
            includesString(str, project?.name) ||
            includesString(str, project?.rate?.name) ||
            includesString(str, project?.status_project?.name)
    );
};
