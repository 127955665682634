import i18next from 'i18next';

import { parseDate } from 'common/utils/dates';

import {
    DATE_FORMATS,
    PERIODICITY_TEXTS,
    PERIODICITY_TYPES,
} from './constants';

export const getDateIsBeforeProjectStartDate = ({
    date,
    projectInstallationDate,
}) => {
    if (!date || !projectInstallationDate) return false;

    const parsedInitialDate = parseDate(date, DATE_FORMATS.SUNWISE);
    const parsedProjectStartDate = parseDate(
        projectInstallationDate,
        DATE_FORMATS.SUNWISE
    );

    return parsedInitialDate < parsedProjectStartDate;
};

export const getPeriodicityOptions = (selectedRate) => {
    if (selectedRate?.certified)
        return [
            {
                label: i18next.t(PERIODICITY_TEXTS[PERIODICITY_TYPES.Monthly]),
                value: PERIODICITY_TYPES.Monthly,
            },
            {
                label: i18next.t(
                    PERIODICITY_TEXTS[PERIODICITY_TYPES.Bimonthly]
                ),
                value: PERIODICITY_TYPES.Bimonthly,
            },
        ];

    const periodicityType = PERIODICITY_TYPES[selectedRate?.periodicity_type];

    return [
        {
            label: i18next.t(PERIODICITY_TEXTS[periodicityType] || 'Monthly'),
            value: periodicityType || '0',
        },
    ];
};

export const setHourlyContractedDemand = ({
    certified,
    getValues,
    setValue,
    tiers,
}) => {
    if (!tiers?.length) {
        setValue('hourly_contracted_demand', {});
        setValue('hourly_contracted_demand_keys', []);
        return;
    }

    const tiersKeys = [];
    const demandValues = getValues('hourly_contracted_demand');

    for (const tier of tiers) {
        const tierName = tier.name || `${tier.identifier}`;
        const key = tierName.toLowerCase();
        tiersKeys.push(key);
        setValue(`hourly_contracted_demand.${key}`, {
            label: certified
                ? i18next.t(tier.name, { context: 'rate' })
                : tier.name,
            placeholder: demandValues?.[key]?.placeholder ?? '0',
            tier: tier.identifier,
            value: demandValues?.[key]?.value ?? null,
        });
    }

    setValue('hourly_contracted_demand_keys', tiersKeys);
};

export const getHourlyContractedDemandFromValues = (
    certified,
    tiers,
    values
) => {
    const hourly_contracted_demand = {};
    const hourly_contracted_demand_keys = [];

    if (!tiers?.length)
        return { hourly_contracted_demand, hourly_contracted_demand_keys };

    const mappedValues = values?.reduce((acc, value) => {
        acc[value.tier] = value.value;
        return acc;
    }, {});

    for (const tier of tiers) {
        const tierName = tier.name || `${tier.identifier}`;
        const key = tierName.toLowerCase();
        hourly_contracted_demand_keys.push(key);

        const fieldValue = mappedValues?.[tier.identifier];

        hourly_contracted_demand[key] = {
            label: certified
                ? i18next.t(tier.name, { context: 'rate' })
                : tier.name,
            placeholder: '0',
            tier: tier.identifier,
            value: fieldValue ?? null,
        };
    }

    return { hourly_contracted_demand, hourly_contracted_demand_keys };
};

export const getNewTotalCost = ({ currency, getValues }) => {
    if (!currency?.id) return;

    const typeChange = getValues('type_change');
    const totalCost = getValues('total_cost');
    const isUSD = currency?.abbreviation === 'USD';

    if (!typeChange || !totalCost) return totalCost;
    if (isUSD) return totalCost / typeChange;
    return totalCost * typeChange;
};
