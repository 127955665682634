import { getItems } from 'common/api/v1/branchOffices';

import {
    FETCH_ITEMS,
    FETCH_ITEMS_FAILURE,
    FETCH_ITEMS_SUCCESS,
} from '../actionTypes';
import { branchOfficesActions } from '../reducer';

export default (searchText = '', orderBy = '', sortBy = '', page = 1) =>
    (dispatch) => {
        dispatch(branchOfficesActions[FETCH_ITEMS]());

        getItems(searchText, orderBy, sortBy, page)
            .then((response) => {
                dispatch(
                    branchOfficesActions[FETCH_ITEMS_SUCCESS](
                        response.data.data
                    )
                );
            })
            .catch((error) =>
                dispatch(
                    branchOfficesActions[FETCH_ITEMS_FAILURE](
                        error?.response?.data?.errors
                    )
                )
            );
    };
