import get from 'lodash/get';

import { FILE_ALLOWED_EXTENSIONS } from 'common/constants';
import uploadFilesLisa from 'common/modules/lisaFiles/actions/uploadFiles';

import fetchProjects from '../../project/actions/fetchProjects';

import setConfigCsvImportModal from './setConfigCsvImportModal';
import uploadXML from './uploadXML';

export default ({
        files,
        getValues,
        project,
        setEnergyBalanceErrors,
        setValue,
    }) =>
    (dispatch) => {
        if (!files?.length || !project) return;
        const [file] = files;
        const fileType = file?.type;
        if (FILE_ALLOWED_EXTENSIONS.CSV.includes(fileType))
            dispatch(setConfigCsvImportModal({ file, isOpen: true }));
        else if (FILE_ALLOWED_EXTENSIONS.XML.includes(fileType))
            dispatch(
                uploadXML({
                    file,
                    getValues,
                    projectId: project?.id,
                    setEnergyBalanceErrors,
                    setValue,
                })
            );
        else
            dispatch(
                uploadFilesLisa({
                    files: [file],
                    onUpdatedProject: () =>
                        dispatch(
                            fetchProjects({
                                contactId: get(project, 'contact.id', null),
                            })
                        ),
                    overwrite: false,
                    project,
                })
            );
    };
