import { fetchData } from 'common/api/v1/historical';
import { parseDate } from 'common/utils/dates';

import { DATE_FORMATS } from '../../afterSalesSettings/constants';
import * as afterSalesSettingsSelectors from '../../afterSalesSettings/selectors';
import {
    FETCH_CONSUMPTION_HISTORY,
    FETCH_CONSUMPTION_HISTORY_FAILURE,
    FETCH_CONSUMPTION_HISTORY_SUCCESS,
} from '../actionTypes';
import { getRoiProgress } from '../helpers';
import { actions } from '../reducer';

export default (projectId) => (dispatch, getState) => {
    if (!projectId) return;
    dispatch(actions[FETCH_CONSUMPTION_HISTORY]());

    fetchData(projectId)
        .then((response) => {
            const data = response?.data?.data || [];
            const totalCost =
                afterSalesSettingsSelectors.getAfterSalesSettingsTotalCost(
                    getState()
                );

            const periods = [...data].sort(
                (a, b) =>
                    parseDate(a, DATE_FORMATS.SUNWISE) -
                    parseDate(b, DATE_FORMATS.SUNWISE)
            );

            const newPeriods = periods.reduce(
                (acc, period) => {
                    acc.progress += getRoiProgress({ period, totalCost }) || 0;
                    acc.data.push({
                        ...period,
                        roi_progress: 100 * acc.progress,
                    });

                    return acc;
                },
                { data: [], progress: 0 }
            );

            dispatch(
                actions[FETCH_CONSUMPTION_HISTORY_SUCCESS](newPeriods.data)
            );
        })
        .catch((error) =>
            dispatch(
                actions[FETCH_CONSUMPTION_HISTORY_FAILURE](
                    error?.response?.data?.errors
                )
            )
        );
};
