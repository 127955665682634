import React, { useContext, useEffect } from 'react';

import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { createStructuredSelector } from 'reselect';
import { Box, ConfirmDialog } from 'sunwise-ui';

import { PERMISSION_LIST } from 'common/constants/permissionsV2';
import withPermissions from 'common/hocs/withPermissions';
import { LoadingContext } from 'common/utils/contexts';

import * as actions from './actions';
import { DocumentList, Modal } from './components';
import * as selectors from './selectors';

const Container = ({
    canDelete,
    canModify,
    canView,
    contactId,
    dataConfirmModal,
    dataDocuments,
    fetchDocuments,
    fetchDocumentsType,
    handleClickCloseModal,
    handleClickDelete,
    initialValues,
    isDocumentDeleting,
    isFetchingDocuments,
    isOpenConfirmModal,
    isUploading,
    isVisibleModal,
    openConfirmModal,
    prepareDelete,
    prepareUpdateForm,
    reset,
    resetForm,
    save,
    showModal,
}) => {
    const { t } = useTranslation();
    const loadingContext = useContext(LoadingContext);

    useEffect(() => {
        fetchDocumentsType();
        return () => {
            reset();
        };
    }, []);

    useEffect(() => {
        if (contactId) {
            fetchDocuments(contactId);
        }
    }, [contactId]);

    useEffect(() => {
        if (isEmpty(loadingContext)) return;
        if (isUploading)
            loadingContext.openLoading(
                t('Saving change', { count: 2 }).concat('...')
            );
        else loadingContext.closeLoading();
    }, [isUploading]);

    useEffect(() => {
        if (isEmpty(loadingContext)) return;
        if (isDocumentDeleting)
            loadingContext.openLoading(t('Removing').concat('...'));
        else loadingContext.closeLoading();
    }, [isDocumentDeleting]);

    if (!canView) return null;

    return (
        <Box mb={2}>
            <DocumentList
                canDelete={canDelete}
                canModify={canModify}
                documents={dataDocuments}
                handleClickAdd={() => showModal(true)}
                handleClickDelete={prepareDelete}
                handleClickUpdate={prepareUpdateForm}
                isFetchingDocuments={isFetchingDocuments}
            />

            <Modal
                handleClickClose={handleClickCloseModal}
                handleSubmitForm={(values) => save(values, contactId)}
                isEdit={!isEmpty(initialValues?.file)}
                isUploading={isUploading}
                resetForm={resetForm}
                show={isVisibleModal}
            />

            <ConfirmDialog
                handleClickClose={() => openConfirmModal(false)}
                handleClickSuccess={handleClickDelete}
                isOpen={isOpenConfirmModal}
                message={dataConfirmModal && dataConfirmModal.message}
                title={dataConfirmModal && dataConfirmModal.title}
                variant={dataConfirmModal && dataConfirmModal.type}
            />
        </Box>
    );
};

const mapStateToProps = createStructuredSelector({
    dataConfirmModal: selectors.getDataConfirmModal,
    dataDocuments: selectors.getDocumentsData,
    initialValues: selectors.getInitialValues,
    isDocumentDeleting: selectors.getIsDocumentDeleting,
    isFetchingDocuments: selectors.getIsFetchingFetchDocuments,
    isOpenConfirmModal: selectors.getIsOpenConfirmModal,
    isUploading: selectors.getIsUploading,
    isVisibleModal: selectors.getIsVisibleModal,
});

const mapDispatchToProps = (dispatch) => ({
    fetchDocuments: (contactId) => dispatch(actions.fetchDocuments(contactId)),
    fetchDocumentsType: () => dispatch(actions.fetchDocumentsType()),
    handleClickCloseModal: () => dispatch(actions.closeModal()),
    handleClickDelete: () => dispatch(actions.deleteDocument()),
    openConfirmModal: (value) => dispatch(actions.openConfirmModal(value)),
    prepareDelete: (element) => dispatch(actions.prepareDelete(element)),
    prepareUpdateForm: (document) =>
        dispatch(actions.prepareUpdateForm(document)),
    reset: () => dispatch(actions.reset()),
    resetForm: () => dispatch(actions.resetForm()),
    save: (values, contactId) =>
        dispatch(actions.saveDocument(values, contactId)),
    showModal: (value) => dispatch(actions.showModal(value)),
});

Container.propTypes = {
    canDelete: PropTypes.bool,
    canModify: PropTypes.bool,
    canView: PropTypes.bool,
    contactId: PropTypes.string,
    dataConfirmModal: PropTypes.object,
    dataDocuments: PropTypes.array,
    fetchDocuments: PropTypes.func,
    fetchDocumentsType: PropTypes.func,
    handleClickCloseModal: PropTypes.func,
    handleClickDelete: PropTypes.func,
    initialValues: PropTypes.object,
    isDocumentDeleting: PropTypes.bool,
    isFetchingDocuments: PropTypes.bool,
    isOpenConfirmModal: PropTypes.bool,
    isUploading: PropTypes.bool,
    isVisibleModal: PropTypes.bool,
    openConfirmModal: PropTypes.func,
    prepareDelete: PropTypes.func,
    prepareUpdateForm: PropTypes.func,
    reset: PropTypes.func,
    resetForm: PropTypes.func,
    save: PropTypes.func,
    showModal: PropTypes.func,
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withPermissions(PERMISSION_LIST.PERSONAL_DOCUMENTS_PERMISSION)
)(Container);
