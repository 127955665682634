import React, { useState, useEffect, useRef, useMemo } from 'react';

import { useTheme } from '@mui/material/styles';
import { styled } from '@mui/material/styles';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import Chart from 'react-apexcharts';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Box } from 'sunwise-ui';

import { ChartControls } from 'common/components/charts';
import {
    DEFAULT_INITIAL_DATE,
    DEFAULT_MONTH_FINAL_DATE,
} from 'common/constants';
import { DEFAULT_SCHEDULE_RATE_CONFIGURATION } from 'common/constants/rates';
import { useBreakpoint } from 'common/hooks';
import { differenceInWeeksDate } from 'common/utils/dates';
import { getCountryCurrencyLocale } from 'common/utils/helpers/session';
import {
    getDataProfileFormattedByPeriods,
    getEnergyChartConfig,
} from 'common/utils/helpersChart';
import { scroll } from 'common/utils/mixins';

import { getChartConfig, parseDateDefault } from '../helpers';
import { getConsumptionProfileDistributed } from '../helpersConsumptionProfile';
import * as selectors from '../selectors';

import PeriodSelector from './PeriodSelector';

const ChartWrapper = styled(Box)`
    overflow-x: scroll;
    overflow-y: hidden;
    width: 100%;

    ${scroll.custom()};
`;

const handleChartConfig = ({
    chartType,
    consumptionProfile,
    consumptionProfileArray,
    countryCurrencyLocale,
    isFromCsv,
    isFromScrapper,
    rate_configuration,
    selectedDays,
    selectedOption,
    selectedPeriod,
    selectedRate,
    selectedWeeks,
    summary,
}) => {
    const baseChartConfig = getChartConfig({
        isFromScrapper,
        selectedRate,
        summary,
    });

    if (selectedOption === 0)
        return { ...baseChartConfig, key: 'chart-consumption' };
    const dataKwh =
        baseChartConfig?.series?.[0]?.data.map(
            (value) => parseFloat(value) || 0
        ) || [];
    const newSummary = [...summary].reverse();

    const consumptionProfileDistributed = isFromCsv
        ? consumptionProfileArray
        : getConsumptionProfileDistributed({
              consumption_profile: consumptionProfile?.consumption || [],
              consumption_profile_year: consumptionProfile?.year || 2018,
              rate_configuration: !isEmpty(rate_configuration)
                  ? rate_configuration
                  : DEFAULT_SCHEDULE_RATE_CONFIGURATION,
              summary,
          });

    const { profileFormattedByPeriods } = getDataProfileFormattedByPeriods({
        profile: consumptionProfileDistributed,
        summary: newSummary,
    });

    return {
        ...getEnergyChartConfig({
            baseChartConfig,
            consumptionProfile: profileFormattedByPeriods,
            countryCurrencyLocale,
            dataKwh,
            monthskwh: newSummary,
            selectedDays,
            selectedOption,
            selectedPeriod:
                selectedPeriod <= summary.length
                    ? summary.length - selectedPeriod - 1
                    : 0,
            selectedWeeks,
        }),
        key: `chart-${selectedPeriod}-${selectedOption}-${chartType}`,
    };
};

const ChartComponent = ({
    consumptionProfile,
    consumptionProfileArray,
    isFetchingConsumptionProfile,
    isFetchingScheduleRateConfiguration,
    isFromCsv,
    isFromScrapper,
    rate_configuration,
    selectedRate,
    summary,
}) => {
    const [chartConfig, setChartConfig] = useState({
        key: 'chart-consumption',
        options: {},
        series: [{ data: [], name: '' }],
    });
    const [chartType, setChartType] = useState('area');
    const [chartWidth, setChartWidth] = useState('98%');
    const [selectedDays, setSelectedDays] = useState([1]);
    const [selectedOption, setSelectedOption] = useState(0);
    const [selectedPeriod, setSelectedPeriod] = useState(0);
    const [selectedWeeks, setSelectedWeeks] = useState([0]);
    const breakpoint = useBreakpoint();
    const container = useRef(null);
    const countryCurrencyLocale = getCountryCurrencyLocale();
    const theme = useTheme();

    const weeksToSelect = useMemo(() => {
        const finalDate = summary?.[selectedPeriod]?.final_date;
        const initialDate = summary?.[selectedPeriod]?.initial_date;
        const diff = differenceInWeeksDate(
            parseDateDefault(finalDate || DEFAULT_MONTH_FINAL_DATE),
            parseDateDefault(initialDate || DEFAULT_INITIAL_DATE)
        );
        return (Math.max(diff, 0) || 0) + 1;
    }, [
        summary?.[selectedPeriod]?.final_date,
        summary?.[selectedPeriod]?.initial_date,
    ]);

    useEffect(() => {
        const margin = 32;
        switch (breakpoint) {
            case 'xs':
                setChartWidth(600);
                break;
            case 'sm':
                setChartWidth(800);
                break;
            default: {
                const width = container?.current
                    ? container.current?.offsetWidth - margin
                    : '98%';
                setChartWidth(width);
                break;
            }
        }
    }, [breakpoint]);

    useEffect(() => {
        if (
            !isFetchingConsumptionProfile &&
            !isFetchingScheduleRateConfiguration
        ) {
            const isEmptyConsumptionProfile =
                isEmpty(consumptionProfile?.consumption) && !isFromCsv;

            const newSelectedOption = isEmptyConsumptionProfile
                ? 0
                : selectedOption;

            const newSelectedWeeks = selectedWeeks.filter(
                (week) => week < weeksToSelect
            );
            setSelectedWeeks(newSelectedWeeks);
            setSelectedOption(newSelectedOption);
            handleChartChange({
                selectedDays,
                selectedOption: newSelectedOption,
                selectedPeriod,
                selectedWeeks: newSelectedWeeks,
            });
        }
    }, [
        chartType,
        consumptionProfile,
        isFetchingScheduleRateConfiguration,
        isFromScrapper,
        selectedRate,
        summary,
    ]);

    useEffect(() => {
        const newSelectedWeeks = selectedWeeks.filter(
            (week) => week < weeksToSelect
        );
        setSelectedWeeks(newSelectedWeeks);
        handleChartChange({
            selectedDays,
            selectedOption,
            selectedPeriod,
            selectedWeeks: newSelectedWeeks,
        });
    }, [weeksToSelect]);

    const handleChartChange = ({
        selectedDays,
        selectedOption,
        selectedPeriod,
        selectedWeeks,
    } = {}) => {
        if (
            !isFetchingConsumptionProfile &&
            !isFetchingScheduleRateConfiguration
        ) {
            const { key, options, series } = handleChartConfig({
                chartType,
                consumptionProfile,
                consumptionProfileArray,
                countryCurrencyLocale,
                isFromCsv,
                isFromScrapper,
                rate_configuration,
                selectedDays,
                selectedOption,
                selectedPeriod,
                selectedRate,
                selectedWeeks,
                summary,
            });
            setChartConfig(() => ({ key, options, series }));
        }
    };

    const handleOnChangeSelectedOption = (e) => {
        const value = parseInt(e.target.value);
        setSelectedOption(value);
        handleChartChange({
            selectedDays,
            selectedOption: value,
            selectedPeriod,
            selectedWeeks,
        });
    };

    const handleOnChangeSelectedPeriod = (value) => {
        setSelectedPeriod(value);
        handleChartChange({
            selectedDays,
            selectedOption,
            selectedPeriod: value,
            selectedWeeks,
        });
    };

    const handleOnChangeSelectedDays = (e) => {
        let value = e.target.value;
        if (value[value.length - 1] === 'all')
            value = selectedDays.length === 7 ? [] : [0, 1, 2, 3, 4, 5, 6];
        setSelectedDays(value);
        handleChartChange({
            selectedDays: value,
            selectedOption,
            selectedPeriod,
            selectedWeeks,
        });
    };

    const handleOnChangeSelectedWeeks = (e) => {
        let value = e.target.value;
        if (value[value.length - 1] === 'all')
            value =
                selectedWeeks.length === weeksToSelect
                    ? []
                    : [...Array(weeksToSelect).keys()];
        setSelectedWeeks(value);
        handleChartChange({
            selectedDays,
            selectedOption,
            selectedPeriod,
            selectedWeeks: value,
        });
    };

    return (
        <>
            {(!isEmpty(consumptionProfile?.consumption) || isFromCsv) && (
                <ChartControls
                    chartType={chartType}
                    chartTypeOptions={['area', 'bar']}
                    handleOnChangePeriod={handleOnChangeSelectedOption}
                    handleOnChangeSelectedDays={handleOnChangeSelectedDays}
                    handleOnChangeSelectedWeeks={handleOnChangeSelectedWeeks}
                    hideChangeTypeButtons={selectedOption === 0 ? true : false}
                    selectedDays={selectedDays}
                    selectedOption={selectedOption}
                    selectedWeeks={selectedWeeks}
                    setChartType={setChartType}
                    weeksToSelect={weeksToSelect}
                />
            )}

            <ChartWrapper mb={1}>
                <Chart
                    height="300"
                    key={chartConfig.key}
                    options={{
                        ...chartConfig.options,
                        theme: { mode: theme.palette.mode },
                    }}
                    series={chartConfig.series}
                    type={selectedOption === 0 ? 'bar' : chartType}
                    width={chartWidth}
                />
            </ChartWrapper>

            {[1, 2, 3].includes(selectedOption) && (
                <PeriodSelector
                    selectedPeriod={selectedPeriod}
                    setSelectedPeriod={handleOnChangeSelectedPeriod}
                    summary={summary}
                />
            )}
        </>
    );
};

const mapStateToProps = createStructuredSelector({
    consumptionProfile: selectors.getFetchConsumptionProfileData,
    consumptionProfileArray: selectors.getConsumptionProfileArray,
    hasMoreDaysThanAllowed: selectors.getHasMoreDaysThanAllowed,
    isFetchingConsumptionProfile:
        selectors.getFetchConsumptionProfileIsFetchhing,
    isFetchingScheduleRateConfiguration:
        selectors.getFetchScheduleRateConfigurationIsFetching,
    isFromCsv: selectors.getIsFromCsv,
    isFromScrapper: selectors.getIsFromScrapper,
    rate_configuration: selectors.getFetchScheduleRateConfigurationData,
});

ChartComponent.propTypes = {
    consumptionProfile: PropTypes.object,
    consumptionProfileArray: PropTypes.array,
    isFetchingConsumptionProfile: PropTypes.bool,
    isFetchingScheduleRateConfiguration: PropTypes.bool,
    isFromCsv: PropTypes.bool,
    isFromScrapper: PropTypes.bool,
    rate_configuration: PropTypes.object,
    selectedRate: PropTypes.object,
    summary: PropTypes.array,
};

export default connect(mapStateToProps)(ChartComponent);
