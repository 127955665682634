import i18next from 'i18next';
import get from 'lodash/get';

import { getLocationsByZipCode } from 'common/api/v1/politicalDivisions';
import alerts from 'common/modules/alerts';

import {
    FETCH_LOCATIONS_ZIP_CODE,
    FETCH_LOCATIONS_ZIP_CODE_FAILURE,
    FETCH_LOCATIONS_ZIP_CODE_SUCCESS,
} from '../actionTypes';
import { projectCommOfferTableActions } from '../reducer';

import fetchPoliticalDivisions2 from './fetchPoliticalDivisions2';
import fetchRateDivisions from './fetchRateDivisions';
import fetchRegionsByPoliticalDivision from './fetchRegionsByPoliticalDivision';

export default (zipCode, setValue) => (dispatch) => {
    if (!zipCode) {
        dispatch(
            projectCommOfferTableActions[FETCH_LOCATIONS_ZIP_CODE_SUCCESS]([])
        );
        return;
    }

    dispatch(projectCommOfferTableActions[FETCH_LOCATIONS_ZIP_CODE]());

    getLocationsByZipCode(zipCode)
        .then((response) => {
            dispatch(
                projectCommOfferTableActions[FETCH_LOCATIONS_ZIP_CODE_SUCCESS](
                    response.data || []
                )
            );

            if (response.data.length > 0) {
                const values = response.data[0];
                setValue('political_division1', values.state.id);

                dispatch(fetchPoliticalDivisions2(values.state.id));

                if (response.data.length === 1) {
                    setValue('zip_code', values.code);

                    setValue('political_division2', values.municipality.id);
                }

                dispatch(
                    fetchRateDivisions(
                        values.state.id,
                        values.rate_division ? values.rate_division.id : null,
                        setValue
                    )
                );

                dispatch(
                    fetchRegionsByPoliticalDivision(values.state.id, setValue)
                );
            }
        })
        .catch((error) => {
            const errors = get(error, ['response', 'data', 'errors'], []);

            dispatch(
                projectCommOfferTableActions[FETCH_LOCATIONS_ZIP_CODE_FAILURE](
                    errors
                )
            );

            dispatch(
                projectCommOfferTableActions[FETCH_LOCATIONS_ZIP_CODE_SUCCESS](
                    []
                )
            );

            if (errors?.length > 0) {
                const dataAlert = {
                    confirmText: i18next.t('Close'),
                    title: i18next.t('Message'),
                    messages: errors,
                    type: alerts.ALERT_TYPE_ALERT,
                    variant: 'warning',
                };

                dispatch(alerts.actions.show(dataAlert));
            }
        });
};
