import { SET_PRODUCTS_TOTALS } from '../actionTypes';
import {
    getFinalTotal,
    getProfitPercentage,
    getTotalWithTaxes,
} from '../helpers';
import { proposalGeneratorQuoteActions } from '../reducer';
import * as selectors from '../selectors';

export default (infonavitAvailableBalance) => (dispatch, getState) => {
    const state = getState();
    const totalCost = selectors.getTotalCostProducts(state);
    const subtotal = selectors.getProductsSubtotal(state);
    const subtotalWithDiscount =
        selectors.getProductsSubtotalWithDiscount(state);
    const discount = selectors.getProducstDiscount(state);
    const totalTaxes = selectors.getTotalCostTaxes(state);
    const profit = selectors.getProfit(state);

    const subtotalWithTaxes = getTotalWithTaxes({
        subtotalWithDiscount,
        totalTaxes,
    });

    const total = getFinalTotal(infonavitAvailableBalance, subtotalWithTaxes);
    const profitPercentage = getProfitPercentage(subtotalWithDiscount, profit);

    const newValues = {
        discount: discount,
        profit: profit,
        profitPercentage: profitPercentage,
        subtotal: subtotal,
        subtotalWithDiscount: subtotalWithDiscount,
        subtotalWithTaxes: subtotalWithTaxes,
        total: total,
        totalCost: totalCost,
    };

    dispatch(proposalGeneratorQuoteActions[SET_PRODUCTS_TOTALS](newValues));
};
