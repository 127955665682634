import { createSelector } from 'reselect';

import { NAME } from './constants';

export const getModel = (state) => state[NAME];

export const getInitialValues = createSelector(
    getModel,
    (model) => model.initialValues
);
