import { getRegionsByPoliticalDivision } from 'common/api/v1/catalogs';

import {
    FETCH_REGIONS,
    FETCH_REGIONS_SUCCESS,
    FETCH_REGIONS_FAILURE,
} from '../actionTypes';
import { projectCommOfferTableActions } from '../reducer';

export default (id, setValue) => (dispatch) => {
    dispatch(projectCommOfferTableActions[FETCH_REGIONS]());

    getRegionsByPoliticalDivision({ political_division: id })
        .then((response) => {
            dispatch(
                projectCommOfferTableActions[FETCH_REGIONS_SUCCESS](
                    response.data.data
                )
            );

            if (response.data.data.length > 0) {
                setValue('rate_region', response.data.data[0].id);
            }
        })
        .catch((error) =>
            dispatch(
                projectCommOfferTableActions[FETCH_REGIONS_FAILURE](
                    error?.response?.data?.errors
                )
            )
        );
};
