import { push } from 'connected-react-router';
import i18next from 'i18next';

import { duplicateOfferCustomTemplate } from 'common/api/v1/duplicateTemplate';
import { showReponseErrorsAsAlert } from 'common/utils/helpers';
import showToast from 'common/utils/showToast';

import {
    DUPLICATE,
    DUPLICATE_FAILURE,
    DUPLICATE_SUCCESS,
} from '../actionTypes';
import { templateLibraryActions } from '../reducer';

import filterTemplates from './filterTemplates';
import toggleModalTemplate from './toggleModalTemplate';

export default (values, filterData) => (dispatch) => {
    dispatch(templateLibraryActions[DUPLICATE]());
    const {
        branch_offices,
        id,
        language,
        locked,
        proposals_number,
        title,
        type,
        version,
    } = values;

    const newValues = {
        branch_offices,
        custom_offer: id,
        language,
        locked,
        proposals_number,
        title,
        type,
        version,
    };

    duplicateOfferCustomTemplate(newValues)
        .then((response) => {
            dispatch(templateLibraryActions[DUPLICATE_SUCCESS]());
            dispatch(toggleModalTemplate(false));
            if (
                values.branch_offices &&
                values.branch_offices.split(',').length > 1 &&
                values.branch_offices.includes('company')
            ) {
                dispatch(filterTemplates(filterData));
                showToast({
                    body: i18next.t('Templates were successfully duplicated'),
                });
            } else {
                dispatch(push(`/edit-template-pro/${response.data.id}`));
            }
        })
        .catch((error) => {
            dispatch(
                templateLibraryActions[DUPLICATE_FAILURE](
                    error?.response?.data?.errors
                )
            );
            showReponseErrorsAsAlert(dispatch, error?.response);
        });
};
