import React from 'react';

import AddIcon from '@mui/icons-material/Add';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Grow, Popper } from '@mui/material';
import i18next from 'i18next';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import {
    Button,
    ButtonGroup,
    Paper,
    ClickAwayListener,
    MenuList,
    MenuItem,
} from 'sunwise-ui';

import { useBreakpoint } from 'common/hooks';
import * as multiBranchesSelectors from 'common/modules/multiBranches/selectors';

import CreateContact from './components/CreateContact';
import CreateProject from './components/CreateProject';
import CreateProposal from './components/CreateProposal';

const getCreateProjectOption = () => ({
    component: ({ onExited }) => <CreateProject onExited={onExited} />,
    label: i18next.t('Create project'),
});

const getOptions = (branchSelected) => [
    {
        component: ({ onExited }) => <CreateProposal onExited={onExited} />,
        label: i18next.t('Create proposal'),
    },
    getCreateProjectOption(),
    {
        component: ({ onExited }) => (
            <CreateContact
                branchSelected={branchSelected}
                onExited={onExited}
            />
        ),
        label: i18next.t('Create contact'),
    },
];

const Container = ({ branchSelected }) => {
    const { t } = useTranslation();
    const breakpoint = useBreakpoint();
    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef(null);
    const [RenderComponent, setRenderComponent] = React.useState(null);

    const handleMainClick = () => handleMenuItemClick(getCreateProjectOption());

    const handleMenuItemClick = ({ component }) => {
        if (component)
            setRenderComponent(
                component({ onExited: () => setRenderComponent(null) })
            );
        setOpen(false);
    };

    const handleToggleMenu = () => setOpen((prevOpen) => !prevOpen);

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target))
            return;
        setOpen(false);
    };

    return (
        <>
            <ButtonGroup ref={anchorRef} size="small">
                <Button onClick={handleMainClick}>
                    {['xs'].includes(breakpoint) ? (
                        <AddIcon />
                    ) : (
                        t('Create project')
                    )}
                </Button>

                <Button onClick={handleToggleMenu} size="small">
                    <ArrowDropDownIcon />
                </Button>
            </ButtonGroup>

            <Popper
                anchorEl={anchorRef.current}
                disablePortal
                open={open}
                role={undefined}
                sx={{ zIndex: 1 }}
                transition
            >
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin:
                                placement === 'bottom'
                                    ? 'center top'
                                    : 'center bottom',
                        }}
                    >
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList autoFocusItem>
                                    {getOptions(branchSelected).map(
                                        (option) => (
                                            <MenuItem
                                                key={option.label}
                                                onClick={() =>
                                                    handleMenuItemClick(option)
                                                }
                                            >
                                                {option.label}
                                            </MenuItem>
                                        )
                                    )}
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>

            {RenderComponent}
        </>
    );
};

Container.propTypes = {
    branchSelected: PropTypes.string,
};

const mapStateToProps = createStructuredSelector({
    branchSelected: multiBranchesSelectors.getBranchSelected,
});

export default connect(mapStateToProps, null)(Container);
