import { push } from 'connected-react-router';
import get from 'lodash/get';

import { confirmSubscription } from 'common/api/v1/chargebee';
import { SUBSCRIPTION_STATUS } from 'common/constants';
import { showReponseErrorsAsAlert } from 'common/utils/helpers';
import { updateSession } from 'common/utils/helpers/session';

import {
    CONFIRM_SUBSCRIPTION,
    CONFIRM_SUBSCRIPTION_FAILURE,
    CONFIRM_SUBSCRIPTION_SUCCESS,
} from '../actionTypes';
import { selectBillingPlanActions } from '../reducer';
import * as selectors from '../selectors';

export default () => (dispatch, getState) => {
    const subscription = selectors.getSubscriptionData(getState());
    const hasNotSubscription =
        get(subscription, 'status.key', null) ===
        SUBSCRIPTION_STATUS.NO_SUBSCRIPTION;
    dispatch(selectBillingPlanActions[CONFIRM_SUBSCRIPTION]());

    confirmSubscription()
        .then((response) => {
            dispatch(
                selectBillingPlanActions[CONFIRM_SUBSCRIPTION_SUCCESS](
                    response.data.data
                )
            );
            if (hasNotSubscription) {
                updateSession({
                    hasOnboarding: true,
                });
                dispatch(push('/onboarding'));
                return false;
            } else dispatch(push('/profile/subscription'));
        })
        .catch((error) => {
            dispatch(
                selectBillingPlanActions[CONFIRM_SUBSCRIPTION_FAILURE](
                    error?.response?.data?.errors
                )
            );
            showReponseErrorsAsAlert(dispatch, error?.response);
        });
};
