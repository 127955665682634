import { changePasswordCompany } from 'common/api/v1/users';
import showToast from 'common/utils/showToast';

import { SAVE, SAVE_SUCCESS, SAVE_FAILURE } from '../actionTypes';
import { managePasswordActions } from '../reducer';

import resetForm from './resetForm';

export default (callback, values) => (dispatch) => {
    const newValues = {
        password: values.new_password,
        confirm_password: values.confirm_password,
    };

    dispatch(managePasswordActions[SAVE]());

    const id = values.id;

    changePasswordCompany(id, newValues)
        .then((response) => {
            dispatch(managePasswordActions[SAVE_SUCCESS](response.data));

            dispatch(resetForm);

            if (callback) dispatch(callback);

            showToast();
        })
        .catch((error) =>
            dispatch(
                managePasswordActions[SAVE_FAILURE](error.response.data.errors)
            )
        );
};
