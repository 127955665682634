import * as chatAllianceApi from 'common/api/v1/chatAlliance';
import * as chatCreditApi from 'common/api/v1/chatCredit';

import {
    SEND_MESSAGE,
    SEND_MESSAGE_FAILURE,
    SEND_MESSAGE_SUCCESS,
} from '../actionTypes';
import { CREDIT_CHAT_TYPE } from '../constants';
import { chatViewActions } from '../reducer';

import addMessage from './addMessage';
import initializeForm from './initializeForm';

const selectSendMessage = (chatId, newValues, type) => {
    if (type === CREDIT_CHAT_TYPE)
        return chatCreditApi.sendMessage(chatId, newValues);
    return chatAllianceApi.sendMessage(chatId, newValues);
};

export default (values, type, callback) => (dispatch) => {
    const message = values.message.trim();

    if (message.length === 0) return;

    const newValues = {
        message: message,
        message_from: values.user.id,
    };

    dispatch(initializeForm({ message: '', timestamp: new Date() }));

    dispatch(chatViewActions[SEND_MESSAGE]());

    selectSendMessage(values.chatId, newValues, type)
        .then((response) => {
            dispatch(chatViewActions[SEND_MESSAGE_SUCCESS](response.data));

            dispatch(initializeForm({ message: '', timestamp: new Date() }));

            const message = {
                created_at: response.data.created_at,
                id: response.data.id,
                message: response.data.message,
                message_from: response.data.message_from,
                name: values.user.name,
                updated_at: response.data.updated_at,
                username: response.data.username,
            };

            dispatch(addMessage(message));

            if (callback) callback();
        })
        .catch((error) =>
            dispatch(chatViewActions[SEND_MESSAGE_FAILURE](error))
        );
};
