import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box, Grid, Typography } from 'sunwise-ui';

import { IconInverter } from 'common/components/icons';

const InvertersData = ({ offerValuesData }) => {
    const { t } = useTranslation();

    const hasInverters =
        offerValuesData?.inverter_offer &&
        Array.isArray(offerValuesData.inverter_offer) &&
        offerValuesData.inverter_offer.length > 0;

    return (
        <Box className="__userguiding_energy_backup_inverters" px={2}>
            <Grid container>
                <Grid item xs>
                    <Box alignItems="center" display="flex" p={1} pl={0}>
                        <IconInverter />
                        <Typography ml={1} variant="body2">
                            {t('Inverter', { count: 2 })}
                        </Typography>
                    </Box>
                </Grid>
            </Grid>
            {hasInverters && (
                <Grid container>
                    <Grid item>
                        <Typography variant="body2" mb={1}>
                            {t(
                                'In your current proposal you have the following inverters'
                            )}
                            :
                        </Typography>

                        {offerValuesData?.inverter_offer.map((inverter) => {
                            const quantity = inverter?.quantity;
                            const name = inverter?.name;
                            return (
                                <Box
                                    key={`inverter-offer-${inverter.id}`}
                                    sx={{
                                        display: 'flex',
                                        flexDirection: {
                                            md: 'row',
                                            xs: 'column',
                                        },
                                        mb: 1,
                                    }}
                                >
                                    <Typography
                                        component="span"
                                        fontWeight="bold"
                                        variant="caption"
                                    >
                                        {quantity} {name}
                                    </Typography>
                                    <Typography
                                        component="span"
                                        pl={1}
                                        variant="caption"
                                    >
                                        {t('Model inverter', {
                                            count: quantity,
                                        }).toLowerCase()}
                                    </Typography>
                                </Box>
                            );
                        })}
                    </Grid>
                </Grid>
            )}
        </Box>
    );
};

InvertersData.propTypes = {
    offerValuesData: PropTypes.object,
};

export default InvertersData;
