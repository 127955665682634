import { filterItems } from 'common/api/v1/customRates';

import {
    FILTER_RATES_NEW_PAGE,
    FILTER_RATES_NEW_PAGE_FAILURE,
    FILTER_RATES_NEW_PAGE_SUCCESS,
} from '../actionTypes';
import { ratesActions } from '../reducer';

export default (params) => (dispatch) => {
    dispatch(ratesActions[FILTER_RATES_NEW_PAGE]());
    filterItems(params)
        .then((response) =>
            dispatch(
                ratesActions[FILTER_RATES_NEW_PAGE_SUCCESS]({
                    hasNextPage: response.data.next_page,
                    rates: response.data.data,
                })
            )
        )
        .catch((error) =>
            dispatch(ratesActions[FILTER_RATES_NEW_PAGE_FAILURE](error))
        );
};
