import { RATES_WITH_DIVISION } from 'common/constants/rates';
import setShowLisaLoading from 'common/modules/lisaFiles/actions/setShowLisaLoading';

import { FETCH_SCHEDULE_RATE_CONFIGURATION_FAILURE } from '../actionTypes';
import { projectConsumptionModalActions } from '../reducer';

import fetchScheduleRateConfiguration from './fetchScheduleRateConfiguration';
import handleReciptScheduleRateConfiguration from './handleReciptScheduleRateConfiguration';

export default ({
        calculateConsumptionWithCsvData,
        getValues,
        rate,
        rateDivision,
        setValue,
    }) =>
    (dispatch) => {
        if (
            !rate?.id ||
            (rate.isCertified &&
                RATES_WITH_DIVISION.includes(rate.name) &&
                !rateDivision)
        )
            return;

        dispatch(fetchScheduleRateConfiguration({ rate, rateDivision }))
            .then((rateConfiguration) =>
                dispatch(
                    handleReciptScheduleRateConfiguration({
                        calculateConsumptionWithCsvData,
                        getValues,
                        rate,
                        rateConfiguration,
                        setValue,
                    })
                )
            )
            .catch((error) => {
                dispatch(setShowLisaLoading(false));
                dispatch(
                    projectConsumptionModalActions[
                        FETCH_SCHEDULE_RATE_CONFIGURATION_FAILURE
                    ](error)
                );
            });
    };
