import React from 'react';

import orderBy from 'lodash/orderBy';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Typography } from 'sunwise-ui';

import { useBreakpoint } from 'common/hooks';
import BranchOfficeFormatter from 'common/modules/multiBranches/components/BranchOfficeFormatter';
import { costingChoiceToLabel } from 'common/utils/helpers';

import CostFormatter from './CostFormatter';
import MarginFormatter from './MarginFormatter';
import MarginRangeEdit from './MarginRangeEdit';
import PriceRangeDropDown from './PriceRangeDropDown';
import ProductActionsFormatter from './ProductActionsFormatter';

const ProductColumns2 = ({
    canDelete,
    canModify,
    filterData,
    handleClickBulkItems,
    handleClickDelete,
    handleClickDownloadDatasheet,
    handleClickUpdate,
    hiddenColumns,
    loadingContext,
    orders,
    rowModesModel,
    selectedRowId,
    setRowModesModel,
    setSelectedRowId,
}) => {
    const { t } = useTranslation();
    const breakpoint = useBreakpoint();

    let columns = [
        {
            align: 'left',
            field: 'brand',
            flex: 1,
            headerName: t('Brand'),
            hide: hiddenColumns.includes('brand'),
            order: orders?.brand,
            valueGetter: (params) => {
                const { row } = params;
                return row?.brand_name || row?.brand?.name || '';
            },
        },
        {
            align: 'left',
            editable: canModify,
            field: 'code',
            flex: 1,
            headerName: t('Code'),
            hide: hiddenColumns.includes('code'),
            order: orders?.code,
            renderCell: (params) => {
                const { row } = params;
                return (
                    <Typography variant="caption" fontWeight="bold">
                        {row.code}
                    </Typography>
                );
            },
        },
        {
            align: 'left',
            field: 'costing_unit',
            flex: 1,
            headerName: t('Unit costing'),
            hide: hiddenColumns.includes('costing_unit'),
            order: orders?.costing_unit,
            renderCell: (params) => {
                const { row } = params;
                return costingChoiceToLabel(row.costing_unit);
            },
        },
        {
            align: 'left',
            field: 'branch_office',
            flex: 1,
            headerName: t('Branch office'),
            hide: hiddenColumns.includes('branch_office'),
            order: orders?.branch_office,
            renderCell: (params) => {
                const { row } = params;
                return BranchOfficeFormatter(row.branch_office, row);
            },
        },
        {
            align: 'left',
            editable: canModify,
            field: 'name',
            flex: 1,
            headerName: t('Name'),
            hide: hiddenColumns.includes('name'),
            order: orders?.name,
            renderCell: (params) => {
                const { row } = params;
                return (
                    <Typography variant="caption" fontWeight="bold">
                        {row.name}
                    </Typography>
                );
            },
        },
        {
            align: 'left',
            editable: canModify,
            field: 'margin',
            flex: 1,
            headerName: t('Margin'),
            hide: hiddenColumns.includes('margin'),
            order: orders?.margin,
            renderCell: (params) => {
                const { row } = params;
                return <MarginFormatter row={row} />;
            },
            renderEditCell: (params) => {
                return <MarginRangeEdit {...params} />;
            },
        },
        {
            align: 'left',
            editable: canModify,
            field: 'range_prices',
            flex: 1,
            headerName: t('Price'),
            hide: hiddenColumns.includes('range_prices'),
            order: orders?.range_prices,
            renderCell: (params) => {
                const { row } = params;
                return PriceRangeDropDown(row.range_prices, row);
            },
            renderEditCell: (params) => {
                const { row } = params;
                if (row?.range_prices?.length > 1) {
                    return PriceRangeDropDown(row.range_prices, row);
                }
                return CostFormatter(row?.range_prices[0].final_cost, row);
            },
        },
    ];

    if (['xs', 'sm'].includes(breakpoint)) {
        columns = [
            {
                align: 'left',
                editable: canModify,
                field: 'name',
                flex: 1,
                headerName: t('Name'),
                hide: hiddenColumns.includes('name'),
                order: orders?.name,
                renderCell: (params) => {
                    const { row } = params;
                    return (
                        <Typography variant="caption" fontWeight="bold">
                            {row.name}
                        </Typography>
                    );
                },
            },
        ];
    }

    columns = [
        ...columns,
        {
            align: 'left',
            field: '',
            flex: 1,
            renderCell: (params) => {
                const { row } = params;
                return (
                    <ProductActionsFormatter
                        canDelete={canDelete}
                        canModify={canModify}
                        filterData={filterData}
                        handleClickBulkItems={handleClickBulkItems}
                        handleClickDelete={handleClickDelete}
                        handleClickDownloadDatasheet={
                            handleClickDownloadDatasheet
                        }
                        handleClickUpdate={handleClickUpdate}
                        loadingContext={loadingContext}
                        row={row}
                        rowModesModel={rowModesModel}
                        selectedRowId={selectedRowId}
                        setRowModesModel={setRowModesModel}
                        setSelectedRowId={setSelectedRowId}
                    />
                );
            },
            sortable: false,
        },
    ];

    return orderBy(columns, ['order'], ['asc']);
};

ProductColumns2.propTypes = {
    canDelete: PropTypes.bool,
    canModify: PropTypes.bool,
    filterData: PropTypes.object,
    handleClickBulkItems: PropTypes.func,
    handleClickDelete: PropTypes.func,
    handleClickDownloadDatasheet: PropTypes.func,
    handleClickUpdate: PropTypes.func,
    hiddenColumns: PropTypes.array,
    loadingContext: PropTypes.object,
    orders: PropTypes.object,
    rowModesModel: PropTypes.object,
    selectedRowId: PropTypes.func,
    setRowModesModel: PropTypes.func,
    setSelectedRowId: PropTypes.func,
};

export default ProductColumns2;
