import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import { useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Grid, Typography } from 'sunwise-ui';

import { ReactHookFormSelect2 } from 'common/components/form/bootstrap';
import InfoTooltip from 'common/components/InfoToolTip';

import { COLUMN_TYPES } from '../constants';
import { getFormatsByType, getIsVisible } from '../helpers';

const ColumnMatch = ({
    column,
    columnOptions,
    control,
    getValues,
    onChangeColumnsMatch,
}) => {
    const [isVisible, setIsVisible] = useState(false);
    const { t } = useTranslation();
    const columnsFormat = useWatch({ control, name: 'columnsFormat' });
    const {
        columnName,
        displayLabel,
        infoHint,
        required = false,
        type,
        visible = true,
    } = column;

    useEffect(() => {
        setIsVisible(getIsVisible(visible, getValues));
    }, [columnsFormat, getValues, visible]);

    if (!isVisible) return null;

    return (
        <Grid alignItems="flex-start" container maxWidth="1000px">
            <Grid
                alignItems="center"
                display="flex"
                gap={1}
                item
                md={6}
                xs={18}
            >
                {infoHint ? (
                    <>
                        <Typography fontWeight="bold">
                            {displayLabel}:
                        </Typography>

                        <InfoTooltip variant="no-wrapper">
                            {infoHint}
                        </InfoTooltip>
                    </>
                ) : (
                    <Typography fontWeight="bold">{displayLabel}: </Typography>
                )}
            </Grid>

            <Grid item xs={18} md={6}>
                <ReactHookFormSelect2
                    control={control}
                    fullWidth
                    label={`${t('Column')} (${t('Origin')}) ${
                        required ? '*' : ''
                    }`}
                    name={`columnsMatch.${columnName}`}
                    onChange={onChangeColumnsMatch}
                    options={[{ label: '', options: columnOptions || [] }]}
                    variant="outlined"
                />
            </Grid>

            {[
                COLUMN_TYPES.DATE,
                COLUMN_TYPES.TIME,
                COLUMN_TYPES.NUMBER,
            ].includes(type) && (
                <Grid item xs={18} md={6}>
                    <ReactHookFormSelect2
                        control={control}
                        fullWidth
                        label={`${t('Format')} (${t('Origin')})`}
                        name={`columnsFormat.${columnName}`}
                        options={[
                            {
                                label: '',
                                options: getFormatsByType(type),
                            },
                        ]}
                        variant="outlined"
                    />
                </Grid>
            )}
        </Grid>
    );
};

ColumnMatch.propTypes = {
    column: PropTypes.object,
    columnOptions: PropTypes.array,
    control: PropTypes.object,
    getValues: PropTypes.func,
    onChangeColumnsMatch: PropTypes.func,
};

export default ColumnMatch;
