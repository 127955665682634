import React, { useEffect } from 'react';

import EditIcon from '@mui/icons-material/Edit';
import EditNotificationsIcon from '@mui/icons-material/EditNotifications';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { DropdownCardMenu } from 'common/components/cards';

import setAlertsModalsOpen from '../../rateAlerts/actions/setAlertsModalsOpen';
import * as actions from '../actions';
import { BUSINESS_TYPE_ITEMS } from '../constants';
import * as selectors from '../selectors';

import CertifiedRateFormTabs from './CertifiedRateFormTabs';
import {
    CardHeader,
    CardInner,
    CardTitle,
    CardWrapper,
    NameText,
} from './styledComponents';

const CertifiedRateDetail = ({
    canModify,
    handleOpenRateAlertModal,
    isFetchingRate,
    othersUnitsDataToSelect,
    prepareUpdateCertifiedRate,
    selectedItemModel,
}) => {
    const { t } = useTranslation();
    const { businessType, name } = selectedItemModel.ref;
    const { control, handleSubmit, reset, setValue } = useForm({
        defaultValues: selectedItemModel.toUnparsed(),
    });

    useEffect(() => reset(selectedItemModel.toUnparsed()), [selectedItemModel]);

    return (
        <CardWrapper>
            <CardInner>
                <CardHeader>
                    <CardTitle component="span">
                        {isFetchingRate
                            ? t('Loading').concat('...')
                            : t('Certified rate')}
                    </CardTitle>

                    {!isFetchingRate && (
                        <DropdownCardMenu
                            items={[
                                {
                                    handleClick: () =>
                                        prepareUpdateCertifiedRate(
                                            selectedItemModel.toUnparsed()
                                        ),
                                    icon: <EditIcon fontSize="small" />,
                                    label: t('Edit'),
                                },
                                {
                                    handleClick: () =>
                                        handleOpenRateAlertModal(true),
                                    icon: (
                                        <EditNotificationsIcon fontSize="small" />
                                    ),
                                    label: t('Alert', { count: 2 }),
                                },
                            ]}
                            visible={canModify}
                        />
                    )}
                </CardHeader>

                <NameText component="span">
                    {t(BUSINESS_TYPE_ITEMS[businessType])} - {name}
                </NameText>

                {!isFetchingRate && (
                    <CertifiedRateFormTabs
                        control={control}
                        handleSubmit={handleSubmit}
                        othersUnitsDataToSelect={othersUnitsDataToSelect}
                        rateName={name}
                        setValue={setValue}
                        showMode
                    />
                )}
            </CardInner>
        </CardWrapper>
    );
};

const mapStateToProps = createStructuredSelector({
    othersUnitsDataToSelect: selectors.getOthersUnitsDataToSelect,
});

const mapDispatchToProps = (dispatch) => ({
    handleOpenRateAlertModal: (rateId) => dispatch(setAlertsModalsOpen(rateId)),
    initializeRateDetailForm: (values) =>
        dispatch(actions.initializeRateDetailForm(values)),
    prepareUpdateRate: (rateData) =>
        dispatch(actions.prepareUpdateRate(rateData)),
    resetRateDetailForm: () => dispatch(actions.resetRateDetailForm()),
});

CertifiedRateDetail.propTypes = {
    canModify: PropTypes.bool,
    handleOpenRateAlertModal: PropTypes.func,
    isFetchingRate: PropTypes.bool,
    othersUnitsDataToSelect: PropTypes.array,
    prepareUpdateCertifiedRate: PropTypes.func,
    selectedItemModel: PropTypes.object,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CertifiedRateDetail);
