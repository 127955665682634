import i18next from 'i18next';

import { getItem } from 'common/api/v1/integrations';
import { getIsMexicanAccount } from 'common/utils/helpers/session';

import {
    FETCH_INTEGRATION,
    FETCH_INTEGRATION_FAILURE,
    FETCH_INTEGRATION_SUCCESS,
} from '../actionTypes';
import { webIntegrationsActions } from '../reducer';
import initializeForm from '../web/actions/initializeForm';
import { handleLists } from '../web/helpers';
import * as selectors from '../web/selectors';

export default (uuid) => (dispatch, getState) => {
    const state = getState();
    const values = selectors.getInitialValuesForm(state);
    const isMexicanAccount = getIsMexicanAccount();

    dispatch(webIntegrationsActions[FETCH_INTEGRATION]());

    getItem(uuid)
        .then((response) => {
            dispatch(
                webIntegrationsActions[FETCH_INTEGRATION_SUCCESS](response.data)
            );
            if (!isMexicanAccount) {
                dispatch(
                    initializeForm({
                        ...values,
                        install_type: handleLists(['INSTALLATION_TYPE']),
                        method_consumtion: handleLists(['ENERGY']),
                        style: {
                            ...values.style,
                            button: {
                                ...values.style.button,
                                text: i18next.t('Generate express quote'),
                            },
                        },
                    })
                );
            }
        })
        .catch((error) =>
            dispatch(webIntegrationsActions[FETCH_INTEGRATION_FAILURE](error))
        );
};
