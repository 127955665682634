import get from 'lodash/get';

import { getRoleLevelDetails } from 'common/api/v1/systemRoles';

import {
    FETCH_ROLE_LEVEL_DETAILS,
    FETCH_ROLE_LEVEL_DETAILS_FAILURE,
    FETCH_ROLE_LEVEL_DETAILS_SUCCESS,
} from '../actionTypes';
import { getPermissionGroups, getRoleCategories } from '../helpers';
import { rolesConfigurationActions } from '../reducer';

import initializeForm from './initializeForm';

export default (formValues, levelId) => (dispatch) => {
    if (!levelId) return false;

    dispatch(rolesConfigurationActions[FETCH_ROLE_LEVEL_DETAILS]());

    getRoleLevelDetails(levelId)
        .then((response) => {
            const data = get(response, 'data', {});
            dispatch(
                rolesConfigurationActions[FETCH_ROLE_LEVEL_DETAILS_SUCCESS](
                    data
                )
            );
            const categoriesSettings = get(data, 'category_settings', []);
            dispatch(
                initializeForm({
                    ...formValues,
                    categories: getRoleCategories(categoriesSettings),
                    category: categoriesSettings[0]?.id,
                    permissions: getPermissionGroups(categoriesSettings),
                    level_of_permissions: levelId,
                })
            );
        })
        .catch((error) =>
            dispatch(
                rolesConfigurationActions[FETCH_ROLE_LEVEL_DETAILS_FAILURE](
                    error?.response?.data?.errors
                )
            )
        );
};
