import difference from 'lodash/difference';

import { auditContact } from 'common/api/v1/contacts';
import { updateItem, getItems } from 'common/api/v1/users';
import { ROLE_LEVELS } from 'common/constants/permissionsV2';
import { fullNameBuild } from 'common/utils/helpers';
import { showReponseErrorsAsAlert } from 'common/utils/helpers';
import showToast from 'common/utils/showToast';

import fetchUserSettings from '../../profile/actions/fetchProfileData';
import {
    SAVE_ITEM_SUCCESS,
    SAVE_ITEM_FAILURE,
    OPEN_REASSIGN_TYPE_UPDATE_MODAL,
} from '../actionTypes';
import { prepareReassignContactsPayload } from '../helpers';
import { companyUsersActions } from '../reducer';
import * as selectors from '../selectors';

import closeReassignModal from './closeReassignModal';
import filterItems from './filterItems';
import resetForm from './resetForm';
import toggleModalProduct from './toggleModalProduct';

export default (values, id, isReassignModalOpen) => (dispatch, getState) => {
    auditContact(id)
        .then(async (response) => {
            let auditBranchesResult = [];
            if (!values.is_enabled) {
                auditBranchesResult = response.data.data;
            } else {
                const initialValues = selectors.getInitialValues(getState());
                let auditBranches = [];
                if (initialValues.branch_offices) {
                    auditBranches = response.data.data
                        .filter(
                            (item) =>
                                item.branch_id !== null &&
                                initialValues.branch_offices
                                    .split(',')
                                    .includes(item.branch_id)
                        )
                        .map((item) => item.branch_id);
                } else {
                    auditBranches = response.data.data
                        .filter(
                            (item) =>
                                item.branch_id !== null &&
                                initialValues.branch_office === item.branch_id
                        )
                        .map((item) => item.branch_id);
                }

                const valuesBranches = [
                    ROLE_LEVELS.CUSTOMIZED,
                    ROLE_LEVELS.GENERAL_MANAGER,
                ].includes(values?.role_level)
                    ? values.branch_offices.split(',')
                    : [values.branch_office];

                const differenceByBranches = difference(
                    auditBranches,
                    valuesBranches
                );
                auditBranchesResult = response.data.data.filter((item) =>
                    differenceByBranches.includes(item.branch_id)
                );
            }

            if (auditBranchesResult.length > 0) {
                const payload = await prepareReassignContactsPayload(
                    auditBranchesResult,
                    getItems,
                    id
                );

                dispatch(
                    companyUsersActions[OPEN_REASSIGN_TYPE_UPDATE_MODAL]({
                        ...payload,
                        userId: id,
                        userName: fullNameBuild({
                            firstName: values.first_name,
                            lastName: values.last_name,
                            secondSurname: values.second_surname,
                        }),
                        dataToSave: values,
                    })
                );
            } else {
                updateItem(values, id)
                    .then((response) => {
                        dispatch(
                            companyUsersActions[SAVE_ITEM_SUCCESS](
                                response.data
                            )
                        );
                        dispatch(filterItems());
                        dispatch(fetchUserSettings());
                        dispatch(toggleModalProduct(false));
                        dispatch(resetForm());
                        if (isReassignModalOpen) {
                            dispatch(closeReassignModal());
                        }
                        showToast();
                    })
                    .catch((error) => {
                        dispatch(
                            companyUsersActions[SAVE_ITEM_FAILURE](
                                error?.response?.data?.errors
                            )
                        );
                        showReponseErrorsAsAlert(dispatch, error?.response);
                    });
            }
        })
        .catch((error) => {
            dispatch(
                companyUsersActions[SAVE_ITEM_FAILURE](
                    error?.response?.data?.errors
                )
            );
            showReponseErrorsAsAlert(dispatch, error?.response);
        });
};
