import { number, object, string } from 'yup';

import getValidationTexts from 'common/utils/getValidationTexts';

export default (_, props) => {
    const {
        getMaxValueText,
        getMinValueText,
        MUST_BE_GREATER_THAN_ZERO,
        NUMBER_TYPE_TEXT,
        REQUIRED_TEXT,
    } = getValidationTexts();

    return object().shape({
        proposal_plan: string().required(REQUIRED_TEXT).nullable(),
        currency: string().required(REQUIRED_TEXT).nullable(),
        type_change_offer: number()
            .typeError(NUMBER_TYPE_TEXT)
            .required(REQUIRED_TEXT)
            .min(0.0001, MUST_BE_GREATER_THAN_ZERO)
            .nullable(),
        type_discount: string().required(REQUIRED_TEXT).nullable(),
        discount: number().when('type_discount', {
            is: '0',
            then: () =>
                number()
                    .typeError(NUMBER_TYPE_TEXT)
                    .required(REQUIRED_TEXT)
                    .max(
                        props.maximumDiscount,
                        getMaxValueText(props.maximumDiscount)
                    )
                    .nullable(),
        }),
        price_watt: number().when('type_discount', {
            is: '1',
            then: () =>
                number()
                    .typeError(NUMBER_TYPE_TEXT)
                    .required(REQUIRED_TEXT)
                    .min(
                        props.priceByWattMin,
                        getMinValueText(props.priceByWattMin)
                    )
                    .nullable(),
        }),
        price_total: number().when('type_discount', {
            is: '2',
            then: () =>
                number()
                    .typeError(NUMBER_TYPE_TEXT)
                    .required(REQUIRED_TEXT)
                    .min(props.subtotalMin, getMinValueText(props.subtotalMin))
                    .nullable(),
        }),
        isr_percentage: number().when('isr', {
            is: true,
            then: () =>
                number()
                    .typeError(NUMBER_TYPE_TEXT)
                    .required(REQUIRED_TEXT)
                    .min(0, getMinValueText(0))
                    .max(100, getMaxValueText(100))
                    .nullable(),
        }),
        degradation_percentage: number()
            .typeError(NUMBER_TYPE_TEXT)
            .required(REQUIRED_TEXT)
            .min(0, getMinValueText(0))
            .max(100, getMaxValueText(100))
            .nullable(),
        bag_years: number()
            .typeError(NUMBER_TYPE_TEXT)
            .required(REQUIRED_TEXT)
            .min(2, getMinValueText(2))
            .max(50, getMaxValueText(50))
            .nullable(),
        increase_percentage: number()
            .typeError(NUMBER_TYPE_TEXT)
            .required(REQUIRED_TEXT)
            .min(0, getMinValueText(0))
            .max(100, getMaxValueText(100))
            .nullable(),
        dap_value: number()
            .typeError(NUMBER_TYPE_TEXT)
            .required(REQUIRED_TEXT)
            .min(0, getMinValueText(0))
            .max(100, getMaxValueText(100))
            .nullable(),
        tax_rate_percentage: number()
            .typeError(NUMBER_TYPE_TEXT)
            .required(REQUIRED_TEXT)
            .min(0, getMinValueText(0))
            .max(100, getMaxValueText(100))
            .nullable(),
        opex_percentage_increase: number()
            .typeError(NUMBER_TYPE_TEXT)
            .required(REQUIRED_TEXT)
            .min(0, getMinValueText(0))
            .nullable(),
    });
};
