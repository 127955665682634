import { getBrandsInverters } from 'common/api/v1/brands';

import {
    FETCH_INVERTERS,
    FETCH_INVERTERS_FAILURE,
    FETCH_INVERTERS_SUCCESS,
} from '../actionTypes';
import { planConfigurationActions } from '../reducer';

export default (branchOffice) => (dispatch) => {
    dispatch(planConfigurationActions[FETCH_INVERTERS]());

    getBrandsInverters(branchOffice)
        .then((response) => {
            dispatch(
                planConfigurationActions[FETCH_INVERTERS_SUCCESS](
                    response.data.data
                )
            );
        })
        .catch((error) =>
            dispatch(
                planConfigurationActions[FETCH_INVERTERS_FAILURE](
                    error?.response?.data?.errors
                )
            )
        );
};
