import get from 'lodash/get';
import { createSelector } from 'reselect';

import { NAME } from './constants';

export const getModel = (state) => state[NAME];

/* OFFER PRODUCTS */
export const getOfferProducts = createSelector(
    getModel,
    (model) => model.offerProducts
);

export const getIsFetchingOfferProducts = createSelector(
    getOfferProducts,
    (value) => value.isFetching
);

export const getOfferProductsData = createSelector(
    getOfferProducts,
    (value) => value.data
);

export const getOfferProductsErrors = createSelector(
    getOfferProducts,
    (value) => value.errors
);

/* PROPOSAL CATALOGS */
export const getProposalCatalogs = createSelector(
    getModel,
    (model) => model.offerProposalCatalogs
);

export const getIsFetchingProposalCatalogs = createSelector(
    getProposalCatalogs,
    (value) => value.isFetching
);

export const getProposalCatalogsData = createSelector(
    getProposalCatalogs,
    (value) => value.data || []
);

export const getProposalCatalogsErrors = createSelector(
    getProposalCatalogs,
    (value) => value.errors
);

export const getProposalPanelCatalogs = createSelector(
    getProposalCatalogsData,
    (data) => data.branch_office_panels || []
);

export const getProposalInverterCatalogs = createSelector(
    getProposalCatalogsData,
    (data) => data.branch_office_inverters || []
);

export const getProposalEnergyStorageCatalogs = createSelector(
    getProposalCatalogsData,
    (data) => data.branch_office_batteries || []
);

export const getProposalAccessoryCatalogs = createSelector(
    getProposalCatalogsData,
    (data) => data.branch_office_accessories || []
);

export const getProposalStructureCatalogs = createSelector(
    getProposalCatalogsData,
    (data) => data.branch_office_structures || []
);

export const getProposalWorkforceCatalogs = createSelector(
    getProposalCatalogsData,
    (data) => data.branch_office_workforce || []
);

export const getProposalAdditionalCatalogs = createSelector(
    getProposalCatalogsData,
    (data) => data.branch_office_additional || []
);

export const getProposalPlanCatalogs = createSelector(
    getProposalCatalogsData,
    (data) => data.branch_office_plans || []
);

export const getProposalTemplateCatalogs = createSelector(
    getProposalCatalogsData,
    (data) => data.custom_templates || []
);

export const getProposalCurrencyCatalogs = createSelector(
    getProposalCatalogsData,
    (data) => data.company_currencies || []
);

export const getCurrenciesData = createSelector(
    getProposalCurrencyCatalogs,
    (currencies) =>
        currencies
            .filter(
                (item) => item.is_enabled && !item.is_deleted && item.currency
            )
            .map((item) => ({
                ...item.currency,
                dollar_price: item.dollar_price,
            })) || []
);

/* OFFER ACCESSORIES */

export const getOfferAccessoriesData = createSelector(
    getOfferProductsData,
    (products) => products.accessory_offer || []
);

export const getTotalCostAccessories = createSelector(
    getOfferAccessoriesData,
    (data) => {
        let total = 0;

        if (data.length) {
            for (let i = 0; i < data.length; i++) {
                total += data[i].quantity * data[i].cost;
            }
        }

        return total;
    }
);

export const getFinalCostWithoutDiscountOfAccessories = createSelector(
    getOfferAccessoriesData,
    (data) => {
        let total = 0;

        if (data.length) {
            for (let i = 0; i < data.length; i++) {
                total += data[i].final_cost_without_discount;
            }
        }

        return total;
    }
);

export const getFinalCostAccessories = createSelector(
    getOfferAccessoriesData,
    (data) => {
        let total = 0;

        if (data.length) {
            for (let i = 0; i < data.length; i++) {
                total += data[i].final_cost;
            }
        }

        return total;
    }
);

export const getAccessoriesDiscount = createSelector(
    getOfferAccessoriesData,
    (data) => {
        let discount = 0;

        if (data.length) {
            for (let i = 0; i < data.length; i++) {
                discount += data[i].discount;
            }
        }

        return discount;
    }
);

/* OFFER ADDITIONALS */

export const getOfferAdditionalsData = createSelector(
    getOfferProductsData,
    (products) => products.additional_offer || []
);

export const getTotalCostAdditionals = createSelector(
    getOfferAdditionalsData,
    (data) => {
        let total = 0;

        if (data.length) {
            for (let i = 0; i < data.length; i++) {
                total += data[i].quantity * data[i].cost;
            }
        }

        return total;
    }
);

export const getFinalCostWithoutDiscountOfAdditionals = createSelector(
    getOfferAdditionalsData,
    (data) => {
        let total = 0;

        if (data.length) {
            for (let i = 0; i < data.length; i++) {
                total += data[i].final_cost_without_discount;
            }
        }

        return total;
    }
);

export const getFinalCostAdditionals = createSelector(
    getOfferAdditionalsData,
    (data) => {
        let total = 0;

        if (data.length) {
            for (let i = 0; i < data.length; i++) {
                total += data[i].final_cost;
            }
        }

        return total;
    }
);

export const getAdditionalsDiscount = createSelector(
    getOfferAdditionalsData,
    (data) => {
        let discount = 0;

        if (data.length) {
            for (let i = 0; i < data.length; i++) {
                discount += data[i].discount;
            }
        }

        return discount;
    }
);

/* OFFER INVERTERS */
export const getOfferInvertersData = createSelector(
    getOfferProductsData,
    (products) => products.inverter_offer || []
);

export const getTotalCostInverters = createSelector(
    getOfferInvertersData,
    (data) => {
        let total = 0;

        if (data.length) {
            for (let i = 0; i < data.length; i++) {
                total += data[i].quantity * data[i].cost;
            }
        }

        return total;
    }
);

export const getFinalCostWithoutDiscountOfInverters = createSelector(
    getOfferInvertersData,
    (data) => {
        let total = 0;

        if (data.length) {
            for (let i = 0; i < data.length; i++) {
                total += data[i].final_cost_without_discount;
            }
        }

        return total;
    }
);

export const getFinalCostInverters = createSelector(
    getOfferInvertersData,
    (data) => {
        let total = 0;

        if (data.length) {
            for (let i = 0; i < data.length; i++) {
                total += data[i].final_cost;
            }
        }

        return total;
    }
);

export const getInvertersDiscount = createSelector(
    getOfferInvertersData,
    (data) => {
        let discount = 0;

        if (data.length) {
            for (let i = 0; i < data.length; i++) {
                discount += data[i].discount;
            }
        }

        return discount;
    }
);

export const getHasIncompatibleInverters = createSelector(
    getOfferInvertersData,
    (data) => data?.some((item) => !item.is_compatible)
);

/* OFFER ENERGY STORAGE */
export const getOfferEnergyStorageData = createSelector(
    getOfferProductsData,
    (products) => products.battery_offer || []
);

export const getTotalCostEnergyStorage = createSelector(
    getOfferEnergyStorageData,
    (data) => {
        let total = 0;

        if (data.length) {
            for (let i = 0; i < data.length; i++) {
                total += data[i].quantity * data[i].cost;
            }
        }

        return total;
    }
);

export const getFinalCostWithoutDiscountOfEnergyStorage = createSelector(
    getOfferEnergyStorageData,
    (data) => {
        let total = 0;

        if (data.length) {
            for (let i = 0; i < data.length; i++) {
                total += data[i].final_cost_without_discount;
            }
        }

        return total;
    }
);

export const getFinalCostEnergyStorage = createSelector(
    getOfferEnergyStorageData,
    (data) => {
        let total = 0;

        if (data.length) {
            for (let i = 0; i < data.length; i++) {
                total += data[i].final_cost;
            }
        }

        return total;
    }
);

export const getEnergyStorageDiscount = createSelector(
    getOfferEnergyStorageData,
    (data) => {
        let discount = 0;

        if (data.length) {
            for (let i = 0; i < data.length; i++) {
                discount += data[i].discount;
            }
        }

        return discount;
    }
);

/* OFFER PANEL */

export const getOfferPanelData = createSelector(
    getOfferProductsData,
    (products) => products.panel_offer || {}
);

export const getOfferPanelsData = createSelector(
    getOfferProductsData,
    (products) => products.panel_offer || []
);

export const getTotalCostPanels = createSelector(getOfferPanelsData, (data) => {
    let total = 0;

    if (data.length) {
        for (let i = 0; i < data.length; i++) {
            total += data[i].quantity * data[i].cost;
        }
    }

    return total;
});

export const getFinalCostWithoutDiscountOfPanels = createSelector(
    getOfferPanelsData,
    (data) => {
        let total = 0;

        if (data.length) {
            for (let i = 0; i < data.length; i++) {
                total += data[i].final_cost_without_discount;
            }
        }

        return total;
    }
);

export const getFinalCostPanels = createSelector(getOfferPanelsData, (data) => {
    let total = 0;

    if (data.length) {
        for (let i = 0; i < data.length; i++) {
            total += data[i].final_cost;
        }
    }

    return total;
});

export const getPanelsDiscount = createSelector(getOfferPanelsData, (data) => {
    let discount = 0;

    if (data.length) {
        for (let i = 0; i < data.length; i++) {
            discount += data[i].discount;
        }
    }

    return discount;
});

export const getOfferPanelId = createSelector(getOfferPanelsData, (data) =>
    get(data, '[0].id', null)
);

export const getOfferPanelExpansion = createSelector(
    getOfferPanelsData,
    (data) => get(data, '[0]expansion', 0)
);

export const getOfferPanelNumber = createSelector(getOfferPanelsData, (data) =>
    get(data, '[0].quantity', 0)
);

/* OFFER STRUCTURES */
export const getOfferStructuresData = createSelector(
    getOfferProductsData,
    (products) => products.structure_offer || []
);

export const getTotalCostStructures = createSelector(
    getOfferStructuresData,
    (data) => {
        let total = 0;

        if (data.length) {
            for (let i = 0; i < data.length; i++) {
                total += data[i].quantity * data[i].cost;
            }
        }

        return total;
    }
);

export const getFinalCostWithoutDiscountOfStructures = createSelector(
    getOfferStructuresData,
    (data) => {
        let total = 0;

        if (data.length) {
            for (let i = 0; i < data.length; i++) {
                total += data[i].final_cost_without_discount;
            }
        }

        return total;
    }
);

export const getFinalCostStructures = createSelector(
    getOfferStructuresData,
    (data) => {
        let total = 0;

        if (data.length) {
            for (let i = 0; i < data.length; i++) {
                total += data[i].final_cost;
            }
        }

        return total;
    }
);

export const getStructuresDiscount = createSelector(
    getOfferStructuresData,
    (data) => {
        let discount = 0;

        if (data.length) {
            for (let i = 0; i < data.length; i++) {
                discount += data[i].discount;
            }
        }

        return discount;
    }
);

/* OFFER TAXES */
export const getOfferTaxesData = createSelector(
    getOfferProductsData,
    (products) => products.taxes_offer || []
);

export const getTotalCostTaxes = createSelector(getOfferTaxesData, (data) => {
    let total = 0;

    if (data.length) {
        for (let i = 0; i < data.length; i++) {
            total += data[i].amount;
        }
    }

    return total;
});

/* OFFER WORKFORCE */
export const getOfferWorkforceData = createSelector(
    getOfferProductsData,
    (products) => products.workforce_offer || []
);

export const getTotalCostWorkforce = createSelector(
    getOfferWorkforceData,
    (data) => {
        let total = 0;

        if (data.length) {
            for (let i = 0; i < data.length; i++) {
                total += data[i].quantity * data[i].cost;
            }
        }

        return total;
    }
);

export const getFinalCostWithoutDiscountOfWorkforce = createSelector(
    getOfferWorkforceData,
    (data) => {
        let total = 0;

        if (data.length) {
            for (let i = 0; i < data.length; i++) {
                total += data[i].final_cost_without_discount;
            }
        }

        return total;
    }
);

export const getFinalCostWorkforce = createSelector(
    getOfferWorkforceData,
    (data) => {
        let total = 0;

        if (data.length) {
            for (let i = 0; i < data.length; i++) {
                total += data[i].final_cost;
            }
        }

        return total;
    }
);

export const getWorkforceDiscount = createSelector(
    getOfferWorkforceData,
    (data) => {
        let discount = 0;

        if (data.length) {
            for (let i = 0; i < data.length; i++) {
                discount += data[i].discount;
            }
        }

        return discount;
    }
);

/* PRODUCTS COST */
export const getTotalCostProducts = createSelector(
    getTotalCostAccessories,
    getTotalCostAdditionals,
    getTotalCostPanels,
    getTotalCostInverters,
    getTotalCostWorkforce,
    getTotalCostStructures,
    getTotalCostEnergyStorage,
    (
        totalCostAccessories,
        totalCostAdditionals,
        totalCostInverters,
        totalCostPanels,
        totalCostStructures,
        totalCostWorkforce,
        totalCostEnergyStorage
    ) =>
        totalCostAccessories +
        totalCostAdditionals +
        totalCostInverters +
        totalCostPanels +
        totalCostStructures +
        totalCostWorkforce +
        totalCostEnergyStorage
);

/* SUBTOTAL */
export const getProductsSubtotal = createSelector(
    getFinalCostWithoutDiscountOfAccessories,
    getFinalCostWithoutDiscountOfAdditionals,
    getFinalCostWithoutDiscountOfInverters,
    getFinalCostWithoutDiscountOfPanels,
    getFinalCostWithoutDiscountOfStructures,
    getFinalCostWithoutDiscountOfWorkforce,
    getFinalCostWithoutDiscountOfEnergyStorage,
    (
        finalCostWithoutDiscountAccessories,
        finalCostWithoutDiscountAdditionals,
        finalCostWithoutDiscountInverters,
        finalCostWithoutDiscountPanels,
        finalCostWithoutDiscountStructures,
        finalCostWithoutDiscountWorkforce,
        finalCostWithoutDiscountEnergyStorage
    ) =>
        finalCostWithoutDiscountAccessories +
        finalCostWithoutDiscountAdditionals +
        finalCostWithoutDiscountInverters +
        finalCostWithoutDiscountPanels +
        finalCostWithoutDiscountStructures +
        finalCostWithoutDiscountWorkforce +
        finalCostWithoutDiscountEnergyStorage
);

/* DISCOUNTS */

export const getProducstDiscount = createSelector(
    getAccessoriesDiscount,
    getAdditionalsDiscount,
    getInvertersDiscount,
    getPanelsDiscount,
    getStructuresDiscount,
    getWorkforceDiscount,
    getEnergyStorageDiscount,
    (
        accessoriesDiscount,
        additionalsDiscount,
        invertersDiscount,
        panelsDiscount,
        structuresDiscount,
        workforceDiscount,
        energyStorageDiscount
    ) =>
        accessoriesDiscount +
        additionalsDiscount +
        invertersDiscount +
        panelsDiscount +
        structuresDiscount +
        workforceDiscount +
        energyStorageDiscount
);

/* SUBTOTAL WITH DISCOUNT */
export const getProductsSubtotalWithDiscount = createSelector(
    getFinalCostAccessories,
    getFinalCostAdditionals,
    getFinalCostInverters,
    getFinalCostPanels,
    getFinalCostStructures,
    getFinalCostWorkforce,
    getFinalCostEnergyStorage,
    (
        finalCostAccessories,
        finalCostAdditionals,
        finalCostInverters,
        finalCostPanels,
        finalCostStructures,
        finalCostWorkforce,
        finalCostEnergyStorage
    ) =>
        finalCostAccessories +
        finalCostAdditionals +
        finalCostInverters +
        finalCostPanels +
        finalCostStructures +
        finalCostWorkforce +
        finalCostEnergyStorage
);

/* PROPOSAL PROFIT */
export const getProfit = createSelector(
    getProductsSubtotalWithDiscount,
    getTotalCostProducts,
    (subtotalWithDiscount, totalCost) => subtotalWithDiscount - totalCost
);

/* PRODUCTS TOTALS */
export const getProductsTotals = createSelector(
    getModel,
    (model) => model.productsTotals || {}
);
