import React, { useEffect, useState } from 'react';

import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';
import Chart from 'react-apexcharts';
import { Grid } from 'sunwise-ui';

import PlaceholderProccessView from 'common/modules/loadingView/PlaceholderProccessView';

import { CHART_SIZE } from '../constants';
import { getDonutChartConfig } from '../helpers';

const DonutChart = ({
    currencyIso,
    currencyLocale,
    isFetching,
    priceByWatt,
    systemSize,
    totalAccessories,
    totalAdditionals,
    totalEnergyStorage,
    totalInverters,
    totalPanels,
    totalStructure,
    totalWorkforce,
    typeChange,
}) => {
    const [chartHeight, setChartHeight] = useState(325);
    const theme = useTheme();
    useEffect(() => {
        window.addEventListener('resize', function () {
            if (window.innerWidth >= 576 && window.innerWidth < 768) {
                setChartHeight(CHART_SIZE + CHART_SIZE * 0.1);
            } else if (window.innerWidth >= 768 && window.innerWidth < 992) {
                setChartHeight(CHART_SIZE + CHART_SIZE * 0.2);
            } else if (window.innerWidth >= 992 && window.innerWidth < 1200) {
                setChartHeight(CHART_SIZE + CHART_SIZE * 0.3);
            } else {
                setChartHeight(CHART_SIZE + CHART_SIZE * 0.4);
            }
        });
    }, []);

    if (isFetching)
        return (
            <PlaceholderProccessView
                wrapperHeight="250px"
                text="Preparing graph"
            />
        );

    const chartConfig = getDonutChartConfig(
        currencyIso,
        currencyLocale,
        priceByWatt,
        systemSize,
        typeChange
    );

    const series = [
        totalPanels,
        totalInverters,
        totalStructure,
        totalWorkforce,
        totalAccessories,
        totalAdditionals,
        totalEnergyStorage,
    ];

    return (
        <Grid container justifyContent="center">
            <Grid item xs={18} md={8} lg={7}>
                <Chart
                    options={{
                        ...chartConfig,
                        theme: { mode: theme.palette.mode },
                    }}
                    series={series}
                    type="donut"
                    height={`${chartHeight}px`}
                />
            </Grid>
        </Grid>
    );
};

DonutChart.propTypes = {
    currencyIso: PropTypes.string,
    currencyLocale: PropTypes.string,
    isFetching: PropTypes.bool,
    priceByWatt: PropTypes.number,
    systemSize: PropTypes.number,
    totalAccessories: PropTypes.number,
    totalAdditionals: PropTypes.number,
    totalEnergyStorage: PropTypes.number,
    totalInverters: PropTypes.number,
    totalPanels: PropTypes.number,
    totalStructure: PropTypes.number,
    totalWorkforce: PropTypes.number,
    typeChange: PropTypes.number,
};

export default DonutChart;
