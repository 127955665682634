import { getProposalIndicatorsSettings } from 'common/api/v1/proposalIndicators';

import {
    FETCH_INDICATORS_SETTINGS,
    FETCH_INDICATORS_SETTINGS_FAILURE,
    FETCH_INDICATORS_SETTINGS_SUCCESS,
} from '../actionTypes';
import { proposalIndicatorsActions } from '../reducer';

export default () => (dispatch) => {
    dispatch(proposalIndicatorsActions[FETCH_INDICATORS_SETTINGS]());

    getProposalIndicatorsSettings()
        .then((response) =>
            dispatch(
                proposalIndicatorsActions[FETCH_INDICATORS_SETTINGS_SUCCESS](
                    response?.data?.data
                )
            )
        )
        .catch((error) =>
            dispatch(
                proposalIndicatorsActions[FETCH_INDICATORS_SETTINGS_FAILURE](
                    error
                )
            )
        );
};
