import { styled } from '@mui/material/styles';

export default styled('div')`
    border-left: ${({ noBorder }) => (noBorder ? `0` : `1px solid #eff1fb`)};
    color: #848bab;
    ${({ clickable }) => clickable && `cursor: pointer;`}
    font-size: 13px;
    line-height: ${({ variant }) => (variant === 'small' ? `initial` : `32px`)};
    margin-left: 15px;
    padding-left: 10px;
    position: relative;
    text-align: center;

    i {
        color: #ff9a00;
    }

    ${({ variant }) =>
        variant === 'resume' &&
        `
        span{
            color: #FF9A00;
            font-size: 14px;
            font-weight: bold;
            line-height: 18px;
            margin-left: 20px;
        }
    `}

    ${({ variant }) =>
        variant === 'total' &&
        `
        border-left: 0;
        margin-right: 60px;
        span{
            color: #FF9A00;
            font-size: 14px;
            font-weight: bold;
            line-height: 18px;
            margin-left: 20px;
        }
    `}
`;
