import { handleFileURL } from 'common/utils/helpers';

import { INITIALIZE_FORM } from '../actionTypes';
import { companyGeneralActions } from '../reducer';

export default (values) => (dispatch) => {
    const newValues = { ...values };

    if (values.position) {
        newValues.city = values.position.city;
        newValues.cp = values.position.zip_code;
        newValues.description = values.position.description;
        newValues.latitude = values.position.latitude;
        newValues.longitude = values.position.longitude;
        newValues.political_division1 = values.position.political_division1;
    }

    newValues.currency = values.currency.id;

    if (values.image) {
        newValues.image = handleFileURL(
            values.image,
            process.env.REACT_APP_API_URL_IO
        );
    }
    dispatch(companyGeneralActions[INITIALIZE_FORM](newValues));
};
