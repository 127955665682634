import API from '../..';
const ENTITY = 'contacts';

export const bulkTrackings = (data) =>
    API.put(
        `/api/v1/${ENTITY}/tracking-bulk/`,
        { ...data },
        { headers: { 'Content-Type': 'application/json' } }
    );

export const filterTrackings = ({
    completed = '',
    orderBy = 'created_at',
    page = 1,
    perPage = 25,
    sortBy = null,
    userId,
} = {}) =>
    API.get(`/api/v1/${ENTITY}/tracking/`, {
        params: {
            order_by: orderBy,
            page: page,
            per_page: perPage,
            sort: sortBy,
            ...(completed != 'all' ? { completed } : {}),
            ...(userId ? { user: userId } : {}),
        },
    });
export const updateTracking = (data, id) =>
    API.put(`/api/v1/${ENTITY}/${id}`, { ...data });
