import { get, isNull } from 'lodash';

import { saveDetails } from 'common/api/v1/onboarding';
import showToast from 'common/utils/showToast';

import {
    SAVE_DETAILS,
    SAVE_DETAILS_SUCCESS,
    SAVE_DETAILS_FAILURE,
} from '../actionTypes';
import { ONBOARDING_STEPS } from '../constants';
import { onboardingWizardActions } from '../reducer';

import fetchOnboarding from './fetchOnboarding';
import resetErrors from './resetErrors';
import setCurrentStep from './setCurrentStep';
import updateSection from './updateSection';

export default (values, callback) => (dispatch) => {
    const {
        city,
        currency,
        currency_obj,
        description,
        dollar_price,
        exchange_type: exchangeType,
        exchangeRateId,
        inverse_dollar_price,
        latitude,
        longitude,
        political_division1,
        positionId,
        source,
        taxes,
        zip_code,
    } = values;

    const isoCode = get(currency_obj, 'abbreviation', null);

    let newValues = {
        exchange_rate:
            isoCode === 'USD'
                ? { id: exchangeRateId }
                : {
                      currency,
                      dollar_price,
                      exchange_type: exchangeType,
                      id: exchangeRateId,
                      inverse_dollar_price,
                      source: parseInt(source),
                  },
        taxes,
    };

    if (!isNull(latitude) && !isNull(longitude)) {
        newValues.position = {
            city,
            description,
            id: positionId,
            latitude,
            longitude,
            political_division1,
            zip_code,
        };
    }

    dispatch(onboardingWizardActions[SAVE_DETAILS]());

    saveDetails(newValues)
        .then((response) => {
            dispatch(
                onboardingWizardActions[SAVE_DETAILS_SUCCESS](response.data)
            );
            dispatch(resetErrors());
            dispatch(setCurrentStep(values?.step + 1));
            dispatch(updateSection(ONBOARDING_STEPS.MEMBERS));
            dispatch(fetchOnboarding(false));
            if (callback) callback(ONBOARDING_STEPS.MEMBERS);
            showToast();
        })
        .catch((error) => {
            dispatch(
                onboardingWizardActions[SAVE_DETAILS_FAILURE](
                    error?.response?.data?.errors
                )
            );
        });
};
