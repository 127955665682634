import React from 'react';

import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { Mention, MentionsInput } from 'react-mentions';

import { truncateString } from 'common/utils/helpers';

import { mentionsMessageStyle, mentionStyle } from '../../trackings/components';
import { getUsersOptions } from '../../trackings/helpers';
import { MAX_CHARACTERS } from '../constants';

const DescriptionFormatter = ({ description, isCompleted, users }) => {
    const theme = useTheme();
    const isDarkMode = theme.palette.mode === 'dark';
    return (
        <MentionsInput
            style={{
                ...mentionsMessageStyle,
                input: {
                    ...mentionsMessageStyle.input,
                    color: isDarkMode ? '#fff' : '#000',
                    fontSize: '13px',
                    textDecoration: isCompleted ? 'line-through' : 'none',
                },
            }}
            value={truncateString(description, MAX_CHARACTERS)}
        >
            <Mention
                data={getUsersOptions(users)}
                style={
                    isDarkMode
                        ? {
                              backgroundColor: 'rgba(255, 255, 255, 0.09)',
                          }
                        : mentionStyle
                }
            />
        </MentionsInput>
    );
};

DescriptionFormatter.propTypes = {
    description: PropTypes.string,
    isCompleted: PropTypes.bool,
    users: PropTypes.array,
};

export default DescriptionFormatter;
