import { saveIndicatorsSettings } from 'common/api/v1/reporterIndicators';
import { showReponseErrorsAsAlert } from 'common/utils/helpers';
import showToast from 'common/utils/showToast';

import {
    SAVE_INDICATORS_SETTINGS,
    SAVE_INDICATORS_SETTINGS_FAILURE,
    SAVE_INDICATORS_SETTINGS_SUCCESS,
    FETCH_INDICATORS_SETTINGS_SUCCESS,
} from '../actionTypes';
import { actions } from '../reducer';

import setIsOpenIndicatorsSettingsModal from './setIsOpenIndicatorsSettingsModal';

export default (values) => (dispatch) => {
    dispatch(actions[SAVE_INDICATORS_SETTINGS]());

    saveIndicatorsSettings({ indicators: values })
        .then((response) => {
            dispatch(
                actions[SAVE_INDICATORS_SETTINGS_SUCCESS](response?.data?.data)
            );
            dispatch(
                actions[FETCH_INDICATORS_SETTINGS_SUCCESS](response?.data?.data)
            );
            dispatch(setIsOpenIndicatorsSettingsModal(false));
            showToast();
        })
        .catch((error) => {
            dispatch(actions[SAVE_INDICATORS_SETTINGS_FAILURE](error));
            showReponseErrorsAsAlert(dispatch, error?.response);
        });
};
