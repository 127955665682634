import { getProjectsBySunwiseProject } from 'common/api/afterSales/projects';

import {
    FETCH_POWER_STATIONS,
    FETCH_POWER_STATIONS_FAILURE,
    FETCH_POWER_STATIONS_SUCCESS,
} from '../actionTypes';
import { actions } from '../reducer';

export default (sunwiseProject) => (dispatch) => {
    if (!sunwiseProject) return;

    dispatch(actions[FETCH_POWER_STATIONS]());

    getProjectsBySunwiseProject(sunwiseProject)
        .then((response) =>
            dispatch(actions[FETCH_POWER_STATIONS_SUCCESS](response?.data))
        )
        .catch((error) =>
            dispatch(
                actions[FETCH_POWER_STATIONS_FAILURE](
                    error?.response?.data?.detail
                )
            )
        );
};
