export const palette = {
    mode: 'dark',
    background: {
        default: '#121113',
        neutral: '#282B30',
        paper: '#1B1C20',
    },
    primary: {
        contrastText: '#fff',
        dark: '#A85B00',
        darker: '#C66B00',
        light: '#FFB843',
        lighter: '#FFCB48',
        main: '#FF9A00',
    },
    secondary: {
        main: '#FFF',
    },
};

export const components = (theme) => ({
    components: {
        ...theme.components,
        MuiButton: {
            ...theme.components.MuiButton,
            styleOverrides: {
                root: {
                    ...theme.components.MuiButton.styleOverrides.root,
                    boxShadow: 'none',
                },
            },
        },
        MuiCard: {
            styleOverrides: {
                root: {
                    ...theme.components.MuiCard.styleOverrides.root,
                    boxShadow:
                        'rgb(0 0 0 / 20%) 0px 2px 1px -1px, rgb(0 0 0 / 14%) 0px 1px 1px 0px, rgb(0 0 0 / 12%) 0px 1px 3px 0px',
                    '.MuiCard-root': {
                        boxShadow: '0px 0 0 1px rgb(255 255 255 / 12%)',
                    },
                },
            },
        },
        MuiDialog: {
            styleOverrides: {
                root: {
                    '.MuiPaper-root': {
                        boxShadow: '0px 0 0 1px rgb(255 255 255 / 12%)',
                    },
                    '.MuiDialogContent-root': {
                        borderBottom: 0,
                    },
                },
            },
        },
        MuiBackdrop: {
            styleOverrides: {
                root: {
                    ...theme.components.MuiBackdrop.styleOverrides.root,
                    background: [`rgb(0 0 0 / 69%)`],
                },
            },
        },
        MuiDrawer: {
            styleOverrides: {
                root: {
                    '> .MuiPaper-root': {
                        boxShadow: theme.shadows[0],
                    },
                },
            },
        },
    },
});
