import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box } from 'sunwise-ui';

import { CheckAccess } from 'common/components';
import { PERMISSION_LIST } from 'common/constants/permissionsV2';
import { useBreakpoint } from 'common/hooks';
import { getHasPaymentDue } from 'common/utils/helpers/session';

import { ReactComponent as ConsumptionProfilesIcon } from 'resources/consumptionProfilesIcon.svg';
import { ReactComponent as ElectricalCompatibilityIcon } from 'resources/electricalCompatibilityIcon.svg';
import { ReactComponent as MulticurrencyIcon } from 'resources/multicurrencyIcon.svg';
import { ReactComponent as RatesIcon } from 'resources/ratesIcon.svg';
import { ReactComponent as SolarGenerationIcon } from 'resources/solarGenerationIcon.svg';
import { ReactComponent as TaxIcon } from 'resources/taxIcon.svg';

import { handleOnMouseHover } from './helpers';
import StyledListItemButton from './StyledListItemButton';
import StyledStyledListItemText from './StyledStyledListItemText';

const ParametersOptions = ({
    handleClose,
    handleDrawerToggle,
    match,
    mobileOpen,
    redirect,
}) => {
    const { t } = useTranslation();
    const hasPaymentDue = getHasPaymentDue();
    const breakpoint = useBreakpoint();

    const onMouseLeave = (e) =>
        handleOnMouseHover({ e, breakpoint, handleClose });

    return (
        <Box onMouseLeave={onMouseLeave}>
            <CheckAccess
                requiredPermissions={[
                    PERMISSION_LIST.SOLAR_GENERATION_PERMISSION,
                ]}
            >
                <StyledListItemButton
                    disabled={hasPaymentDue}
                    onClick={() => {
                        redirect('/solar-generation');
                        handleClose();
                        if (mobileOpen) handleDrawerToggle();
                    }}
                    selected={'/solar-generation' === match.path}
                    variant="horizontal"
                >
                    <SolarGenerationIcon />
                    <StyledStyledListItemText primary={t('Solar generation')} />
                </StyledListItemButton>
            </CheckAccess>

            <CheckAccess
                requiredPermissions={[
                    PERMISSION_LIST.EQUIPMENT_COMPATIBILITY_PERMISSION,
                ]}
            >
                <StyledListItemButton
                    disabled={hasPaymentDue}
                    onClick={() => {
                        redirect('/equipment-compatibility');
                        handleClose();
                        if (mobileOpen) handleDrawerToggle();
                    }}
                    selected={'/equipment-compatibility' === match.path}
                    variant="horizontal"
                >
                    <ElectricalCompatibilityIcon />
                    <StyledStyledListItemText
                        primary={t('Electrical compatibility')}
                    />
                </StyledListItemButton>
            </CheckAccess>

            <CheckAccess
                requiredPermissions={[
                    PERMISSION_LIST.CERTIFIED_RATES_PERMISSION,
                ]}
            >
                <StyledListItemButton
                    disabled={hasPaymentDue}
                    onClick={() => {
                        redirect('/rates');
                        handleClose();
                        if (mobileOpen) handleDrawerToggle();
                    }}
                    selected={'/rates' === match.path}
                    variant="horizontal"
                >
                    <RatesIcon />
                    <StyledStyledListItemText
                        primary={t('Rate', { count: 2 })}
                    />
                </StyledListItemButton>
            </CheckAccess>

            <CheckAccess
                requiredPermissions={[PERMISSION_LIST.TAXES_PERMISSION]}
            >
                <StyledListItemButton
                    disabled={hasPaymentDue}
                    onClick={() => {
                        redirect('/taxes');
                        handleClose();
                        if (mobileOpen) handleDrawerToggle();
                    }}
                    selected={'/taxes' === match.path}
                    variant="horizontal"
                >
                    <TaxIcon />
                    <StyledStyledListItemText
                        primary={t('Tax', { count: 2 })}
                    />
                </StyledListItemButton>
            </CheckAccess>

            <CheckAccess
                requiredPermissions={[PERMISSION_LIST.CURRENCIES_PERMISSION]}
            >
                <StyledListItemButton
                    disabled={hasPaymentDue}
                    onClick={() => {
                        redirect('/currencies');
                        handleClose();
                        if (mobileOpen) handleDrawerToggle();
                    }}
                    selected={'/currencies' === match.path}
                    variant="horizontal"
                >
                    <MulticurrencyIcon />
                    <StyledStyledListItemText primary={t('Multicurrency')} />
                </StyledListItemButton>
            </CheckAccess>

            <CheckAccess
                requiredPermissions={[
                    PERMISSION_LIST.SELFCONSUMPTION_ADMIN_PROFILES_PERMISSION,
                ]}
            >
                <StyledListItemButton
                    disabled={hasPaymentDue}
                    onClick={() => {
                        redirect('/consumption-profiles');
                        handleClose();
                        if (mobileOpen) handleDrawerToggle();
                    }}
                    selected={'/consumption-profiles' === match.path}
                    variant="horizontal"
                >
                    <ConsumptionProfilesIcon />
                    <StyledStyledListItemText
                        primary={t('Consumption profile', { count: 2 })}
                    />
                </StyledListItemButton>
            </CheckAccess>
        </Box>
    );
};

ParametersOptions.propTypes = {
    handleClose: PropTypes.func,
    handleDrawerToggle: PropTypes.func,
    match: PropTypes.object,
    mobileOpen: PropTypes.bool,
    redirect: PropTypes.func,
};

export default ParametersOptions;
