import React, { useEffect } from 'react';

import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
    Card,
    Grid,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
} from 'sunwise-ui';

import { BulkEditionMenu, InputSearch } from 'common/components';
import { ReactHookFormInputDatePicker } from 'common/components/form/bootstrap';

import {
    getRateOptions,
    getStatusOptions,
    getStorageOptions,
    getSystemOptions,
    getUserOptions,
} from '../helpers';

const BulkOperationsCard = ({
    callback,
    filterData,
    handleClickBulkItems,
    handleClickEndDate,
    handleClickStartDate,
    handleOnChangeSearch,
    handleOnSelectRate,
    handleOnSelectStatus,
    handleOnSelectStorage,
    handleOnSelectSystem,
    handleOnSelectUser,
    initialDates,
    rates,
    selectedBulkOption,
    selectionModel,
    setSelectionModel,
    users,
}) => {
    const { t } = useTranslation();
    const { control, reset } = useForm({
        defaultValues: initialDates,
    });

    useEffect(() => reset(initialDates), [initialDates]);

    return (
        <Card sx={{ mb: '16px !important' }}>
            <Card.Body>
                <Grid alignItems="end" container justifyContent="end">
                    <Grid item md={9} lg={18} xs={18}>
                        <InputSearch
                            id="input-search"
                            onChange={handleOnChangeSearch}
                            placeholder={t('Search')}
                            value={filterData?.searchText}
                        />
                    </Grid>
                    <Grid item md={9} lg={6} xs={18}>
                        <FormControl fullWidth size="small">
                            <InputLabel>{t('User')}</InputLabel>
                            <Select
                                label={t('User')}
                                onChange={(e) => handleOnSelectUser(e.target)}
                                value={filterData.createdBy}
                                variant="standard"
                            >
                                {getUserOptions(users).map(
                                    ({ label, value, disabled }) => (
                                        <MenuItem
                                            disabled={disabled}
                                            key={`user-${value}-${label}`}
                                            value={value}
                                        >
                                            {label}
                                        </MenuItem>
                                    )
                                )}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item md={6} lg={2} xs={18}>
                        <ReactHookFormInputDatePicker
                            control={control}
                            fullWidth
                            label={t('Start date')}
                            maxDate={new Date()}
                            name="start_date"
                            onChange={(date) => handleClickStartDate(date)}
                            variant="standard"
                        />
                    </Grid>
                    <Grid item md={6} lg={2} xs={18}>
                        <ReactHookFormInputDatePicker
                            control={control}
                            fullWidth
                            label={t('Final date')}
                            maxDate={new Date()}
                            name="end_date"
                            onChange={(date) => handleClickEndDate(date)}
                            variant="standard"
                        />
                    </Grid>
                    <Grid item md={6} lg={2} xs={18}>
                        <FormControl fullWidth size="small">
                            <InputLabel>{t('Status')}</InputLabel>
                            <Select
                                label={t('Status')}
                                onChange={(e) => handleOnSelectStatus(e.target)}
                                value={filterData.status}
                                variant="standard"
                            >
                                {getStatusOptions().map(
                                    ({ label, value, disabled }) => (
                                        <MenuItem
                                            disabled={disabled}
                                            key={`status-${value}-${label}`}
                                            value={value}
                                        >
                                            {label}
                                        </MenuItem>
                                    )
                                )}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item md={6} lg={2} xs={18}>
                        <FormControl fullWidth size="small">
                            <InputLabel>{t('Rate')}</InputLabel>
                            <Select
                                label={t('Rate')}
                                onChange={(e) => handleOnSelectRate(e.target)}
                                value={filterData.rateName}
                                variant="standard"
                            >
                                {getRateOptions(rates).map(
                                    ({ label, value, disabled }) => (
                                        <MenuItem
                                            disabled={disabled}
                                            key={`rate-${value}-${label}`}
                                            value={value}
                                        >
                                            {label}
                                        </MenuItem>
                                    )
                                )}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item md={6} lg={2} xs={18}>
                        <FormControl fullWidth size="small">
                            <InputLabel>{`${t('System')} (kW)`}</InputLabel>
                            <Select
                                label={`${t('System')} (kW)`}
                                onChange={(e) => handleOnSelectSystem(e.target)}
                                value={filterData.systemRange}
                                variant="standard"
                            >
                                {getSystemOptions().map(
                                    ({ label, value, disabled }) => (
                                        <MenuItem
                                            disabled={disabled}
                                            key={`system-${value}-${label}`}
                                            value={value}
                                        >
                                            {label}
                                        </MenuItem>
                                    )
                                )}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item md={6} lg={2} xs={18}>
                        <FormControl fullWidth size="small">
                            <InputLabel>{`${t('Storage')} (kWh)`}</InputLabel>
                            <Select
                                label={`${t('Storage')} (kWh)`}
                                onChange={(e) =>
                                    handleOnSelectStorage(e.target)
                                }
                                value={filterData.storageRange}
                                variant="standard"
                            >
                                {getStorageOptions().map(
                                    ({ label, value, disabled }) => (
                                        <MenuItem
                                            disabled={disabled}
                                            key={`storage-${value}-${label}`}
                                            value={value}
                                        >
                                            {label}
                                        </MenuItem>
                                    )
                                )}
                            </Select>
                        </FormControl>
                    </Grid>

                    {!isEmpty(selectionModel) && (
                        <Grid
                            item
                            xs={18}
                            lg={4}
                            sx={{ order: { xs: 2, md: 1 } }}
                        >
                            <BulkEditionMenu
                                items={[
                                    {
                                        label: t('Archive'),
                                        handleClick: () =>
                                            handleClickBulkItems(
                                                selectionModel,
                                                callback
                                            ),
                                        visible: true,
                                    },
                                ]}
                                prefix={t('Proposal', {
                                    count: selectionModel.length,
                                }).toLocaleLowerCase()}
                                selectedBulkOption={selectedBulkOption}
                                selectionModel={selectionModel}
                                setSelectionModel={setSelectionModel}
                            />
                        </Grid>
                    )}
                </Grid>
            </Card.Body>
        </Card>
    );
};

BulkOperationsCard.propTypes = {
    callback: PropTypes.func,
    filterData: PropTypes.object,
    handleClickBulkItems: PropTypes.func,
    handleClickEndDate: PropTypes.func,
    handleClickStartDate: PropTypes.func,
    handleOnChangeSearch: PropTypes.func,
    handleOnSelectRate: PropTypes.func,
    handleOnSelectStatus: PropTypes.func,
    handleOnSelectStorage: PropTypes.func,
    handleOnSelectSystem: PropTypes.func,
    handleOnSelectUser: PropTypes.func,
    initialDates: PropTypes.object,
    rates: PropTypes.array,
    selectedBulkOption: PropTypes.string,
    selectionModel: PropTypes.array,
    setSelectionModel: PropTypes.func,
    users: PropTypes.array,
};

export default BulkOperationsCard;
