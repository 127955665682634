import React from 'react';

import Notifications from '@mui/icons-material/Notifications';
import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Badge, IconButton } from 'sunwise-ui';

import * as notificationActions from '../../../modules/notifications/actions';

const NotificationButton = ({
    handleToggleDrawer,
    quantity,
    removeNotificationCounter,
    sxIcon,
}) => {
    const theme = useTheme();
    return (
        <IconButton
            onClick={handleToggleDrawer(true, () =>
                removeNotificationCounter()
            )}
            size="small"
        >
            <Badge
                badgeContent={quantity}
                sx={{
                    '& .MuiBadge-badge': {
                        backgroundColor: theme.palette.primary.light,
                        color:
                            theme.palette.mode === 'dark'
                                ? theme.palette.primary.contrastText
                                : '#fff',
                    },
                }}
            >
                <Notifications sx={sxIcon} />
            </Badge>
        </IconButton>
    );
};

NotificationButton.propTypes = {
    handleToggleDrawer: PropTypes.func,
    quantity: PropTypes.number,
    removeNotificationCounter: PropTypes.func,
    sxIcon: PropTypes.object,
};

const mapDispatchToProps = (dispatch) => ({
    removeNotificationCounter: () =>
        dispatch(notificationActions.removeNotificationCounter()),
});

export default connect(null, mapDispatchToProps)(NotificationButton);
