import React, { useContext, useState } from 'react';

import DownloadIcon from '@mui/icons-material/Download';
import SendIcon from '@mui/icons-material/Send';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box, Grid, IconButton, Typography } from 'sunwise-ui';

import { LoadingContext } from 'common/utils/contexts';

import SideMenuCard from './SideMenuCard';
import SideMenuControls from './SideMenuControls';
import SideMenuQualityDropdown from './SideMenuQualityDropdown';

const SideMenu = ({
    cancelUpdating,
    canModify,
    editionDisabled,
    fetchPdfFile,
    initializeUpdating,
    isUpdatingCustomTemplate,
    match,
    setEditionDisabled,
    swipeableDrawerRef,
    templateName,
    templateType,
    updateTemplate,
}) => {
    const { t } = useTranslation();
    const format = localStorage.getItem('downloadFormat') || '2';
    const [downloadFormat, setDownloadFormat] = useState(format);
    const [statusPrint, setStatusPrint] = useState(false);

    const loadingContext = useContext(LoadingContext);

    const onRenderPdf = () => {
        setStatusPrint(true);
        fetchPdfFile(
            match.params.uid,
            templateName,
            downloadFormat,
            (text) => loadingContext.openLoading(text),
            () => {
                loadingContext.closeLoading();
                setStatusPrint(false);
            },
            templateType
        );
    };

    return (
        <SideMenuCard
            icon={<SendIcon sx={{ color: 'primary.main' }} />}
            title={t('Share')}
        >
            <SideMenuControls
                cancelUpdating={cancelUpdating}
                canModify={canModify}
                editionDisabled={editionDisabled}
                initializeUpdating={initializeUpdating}
                isUpdatingCustomTemplate={isUpdatingCustomTemplate}
                onUpdateOfferEnded={() =>
                    updateTemplate(match.params.uid, templateType)
                }
                setEditionDisabled={setEditionDisabled}
                statusPrint={statusPrint}
                swipeableDrawerRef={swipeableDrawerRef}
            />
            <Grid container mt={1}>
                <Grid item xs>
                    <Typography variant="body2">
                        {t('Download format', { context: 'noun' })}
                    </Typography>
                    <Box
                        sx={{
                            alignItems: 'center',
                            display: 'flex',
                            mt: 1,
                        }}
                    >
                        <SideMenuQualityDropdown
                            downloadFormat={downloadFormat}
                            editionDisabled={editionDisabled}
                            setDownloadFormat={setDownloadFormat}
                        />
                        <IconButton
                            className="__userguiding_generate_proposal_pdf_button"
                            disabled={statusPrint || !editionDisabled}
                            onClick={() => {
                                if (!statusPrint && editionDisabled)
                                    onRenderPdf();
                            }}
                            sx={{ ml: 1 }}
                            variant="default"
                        >
                            <DownloadIcon />
                        </IconButton>
                    </Box>
                </Grid>
            </Grid>
        </SideMenuCard>
    );
};

SideMenu.propTypes = {
    cancelUpdating: PropTypes.func,
    canModify: PropTypes.bool,
    editionDisabled: PropTypes.bool,
    fetchPdfFile: PropTypes.func,
    initializeUpdating: PropTypes.func,
    isUpdatingCustomTemplate: PropTypes.bool,
    match: PropTypes.object,
    setEditionDisabled: PropTypes.func,
    swipeableDrawerRef: PropTypes.object,
    templateName: PropTypes.string,
    templateType: PropTypes.number,
    updateTemplate: PropTypes.func,
};

export default SideMenu;
