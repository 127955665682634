import React, { useCallback } from 'react';

import AttachFileIcon from '@mui/icons-material/AttachFile';
import DownloadIcon from '@mui/icons-material/Download';
import { styled } from '@mui/material/styles';
import i18next from 'i18next';
import isNil from 'lodash/isNil';
import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
import { Controller } from 'react-hook-form';
import { Box, Button, Typography } from 'sunwise-ui';

import { MAX_FILE_SIZE } from 'common/constants';
import showToast from 'common/utils/showToast';

import { ReactComponent as IcoFinancial } from 'resources/icoFinancial.svg';

import { ACCEPTED_FILES } from '../constants';

const Wraper = styled(Box)`
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 100%;
    padding-right: 16px;
    padding-top: 54px;
    width: 100%;
`;

const StyledIcon = styled(IcoFinancial)`
    height: 26px;
    width: 31px;

    path {
        fill: #ff9a00;
    }
`;

const PreDownloadUploader = ({
    control,
    initialFile,
    maxSize = MAX_FILE_SIZE,
    maxSizeErrorMessage = i18next.t(
        'The file exceeds the allowable size limit'
    ),
    name,
    setValue,
}) => {
    const downloadFile = () => {
        const finalUrl = initialFile.includes('https')
            ? initialFile
            : `${process.env.REACT_APP_API_URL}${initialFile}`;
        window.open(finalUrl, '_blank');
    };

    const onDrop = useCallback((acceptedFiles) => {
        if (acceptedFiles.length > 0 && acceptedFiles[0].size >= maxSize) {
            showToast({
                body: maxSizeErrorMessage,
                type: 'danger',
                autoClose: 3000,
            });
            return;
        }
        setValue(
            name,
            acceptedFiles.map((file) =>
                Object.assign(file, {
                    preview: URL.createObjectURL(file),
                })
            )
        );
    }, []);

    const { getRootProps, getInputProps } = useDropzone({ onDrop });

    return (
        <Wraper>
            <Box
                alignItems="center"
                display="flex"
                gap={2}
                justifyContent="center"
                sx={{ width: '100%' }}
            >
                <StyledIcon />
                <Typography variant="h4">
                    {i18next.t('Select type of request')}
                </Typography>
            </Box>
            {!isNil(initialFile) && (
                <Box mt={4}>
                    <Button
                        onClick={downloadFile}
                        startIcon={<DownloadIcon />}
                        type="button"
                        variant="text"
                    >
                        {i18next.t('Download format')}
                    </Button>
                </Box>
            )}
            <Box mt={2}>
                <Controller
                    control={control}
                    name={name}
                    render={() => (
                        <Box {...getRootProps()}>
                            <input
                                {...getInputProps()}
                                accept={ACCEPTED_FILES}
                            />
                            <Button
                                startIcon={<AttachFileIcon />}
                                type="button"
                                variant="text"
                            >
                                {i18next.t('Attach document')}
                            </Button>
                        </Box>
                    )}
                />
            </Box>
        </Wraper>
    );
};

PreDownloadUploader.propTypes = {
    control: PropTypes.object,
    initialFile: PropTypes.string,
    maxSize: PropTypes.number,
    maxSizeErrorMessage: PropTypes.string,
    name: PropTypes.string,
    setValue: PropTypes.func,
};

export default PreDownloadUploader;
