import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { UiDialog as Dialog } from 'sunwise-ui';

import * as afterSalesMeasurementSourceSelectors from '../../afterSalesMeasurementSource/actions';
import * as afterSalesSettingsActions from '../../afterSalesSettings/actions';
import * as afterSalesSettingsSelectors from '../../afterSalesSettings/selectors';
import * as supplierIntegrationsActions from '../../supplierIntegrations/actions';
import * as actions from '../actions';
import * as selectors from '../selectors';

import Form from './Form';

const ModalForm = ({
    fetchPowerStations,
    fetchRates,
    fetchSummerMonths,
    fetchSupplierIntegrations,
    isModalFormOpen,
    selectedProject,
    setConfigCsvImportModal,
    setModalFormOpenStatus,
}) => {
    const { t } = useTranslation();

    const handleCloseImportModal = () =>
        setConfigCsvImportModal({ file: null, open: false });
    const handleOnClose = () => {
        setModalFormOpenStatus(false);
        handleCloseImportModal();
    };
    const handleOnEnter = () => {
        fetchPowerStations(selectedProject?.id);
        fetchRates();
        fetchSummerMonths();
        fetchSupplierIntegrations();
    };

    return (
        <Dialog
            onClose={handleOnClose}
            onEnter={handleOnEnter}
            open={isModalFormOpen}
            size="md"
            title={t('Receipt data')}
        >
            <Form
                handleCloseImportModal={handleCloseImportModal}
                onClose={handleOnClose}
                selectedProject={selectedProject}
            />
        </Dialog>
    );
};

ModalForm.propTypes = {
    fetchPowerStations: PropTypes.func,
    fetchRates: PropTypes.func,
    fetchSummerMonths: PropTypes.func,
    fetchSupplierIntegrations: PropTypes.func,
    isModalFormOpen: PropTypes.bool,
    selectedProject: PropTypes.object,
    setConfigCsvImportModal: PropTypes.func,
    setModalFormOpenStatus: PropTypes.func,
};

const mapStateToProps = createStructuredSelector({
    isModalFormOpen: selectors.getIsModalFormOpen,
    politicalDivision1:
        afterSalesSettingsSelectors.getAfterSalesSettingsPoliticalDivision1,
});

const mapDispatchToProps = (dispatch) => ({
    fetchPowerStations: (projectId) =>
        dispatch(
            afterSalesMeasurementSourceSelectors.fetchPowerStations(projectId)
        ),
    fetchRates: () => dispatch(afterSalesSettingsActions.fetchRates()),
    fetchSummerMonths: () =>
        dispatch(afterSalesSettingsActions.fetchSummerMonths()),
    fetchSupplierIntegrations: () =>
        dispatch(supplierIntegrationsActions.fetchIntegrations()),
    setConfigCsvImportModal: (values) =>
        dispatch(actions.setConfigCsvImportModal(values)),
    setModalFormOpenStatus: (value) =>
        dispatch(actions.setModalFormOpenStatus(value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ModalForm);
