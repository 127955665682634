import i18next from 'i18next';

import { deleteProject } from 'common/api/v1/contactProject';
import alerts from 'common/modules/alerts';
import { showReponseErrorsAsAlert } from 'common/utils/helpers';
import showToast from 'common/utils/showToast';

export default (id, onSuccess) => (dispatch) => {
    deleteProject(id)
        .then(() => {
            dispatch(alerts.actions.close());
            onSuccess();
            showToast({ body: i18next.t('It was successfully removed') });
        })
        .catch((error) => showReponseErrorsAsAlert(dispatch, error?.response));
};
