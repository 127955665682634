import React from 'react';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CloseIcon from '@mui/icons-material/Close';
import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
    Alert,
    Box,
    Button,
    Divider,
    Grid,
    IconButton,
    Typography,
} from 'sunwise-ui';

import CollapsedBreadcrumbs from 'common/components/CollapsedBreadcrumbs';

import { getBreadcrumbs } from '../helpers';

import TitlePreview from './TitlePreview';

const HeaderPreview = ({
    currentBreadcrumb,
    handleClickBreadcrumb,
    id,
    isDrawingMode,
    offerInfoPatern,
    setDrawingMode,
}) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const isDarkMode = theme.palette.mode === 'dark';

    return (
        <Box
            sx={{ backgroundColor: isDarkMode ? '#000' : 'rgb(247, 250, 253)' }}
        >
            {!isDrawingMode && (
                <Grid container spacing={0} sx={{ alignItems: 'center', p: 2 }}>
                    <Grid item xs={13} md={14} lg={15}>
                        <TitlePreview name={offerInfoPatern?.proposalName} />
                    </Grid>
                    <Grid item xs={5} md={4} lg={3} textAlign="right">
                        <Button
                            color="secondary"
                            onClick={() =>
                                handleClickBreadcrumb(null, null, id)
                            }
                            size="small"
                            startIcon={<ArrowBackIcon fontSize="small" />}
                            variant="text"
                        >
                            {t('Back')}
                        </Button>
                    </Grid>
                    <Grid item xs={18} sx={{ pl: { md: '40px' } }}>
                        <CollapsedBreadcrumbs
                            current={currentBreadcrumb}
                            locations={getBreadcrumbs(
                                handleClickBreadcrumb,
                                id,
                                offerInfoPatern
                            )}
                        />
                    </Grid>
                </Grid>
            )}

            {isDrawingMode && (
                <Grid
                    container
                    spacing={0}
                    sx={{ alignItems: 'center', px: 2, py: 1 }}
                >
                    <Grid item xs={18}>
                        <Alert
                            severity="info"
                            action={
                                <IconButton
                                    onClick={() => {
                                        setDrawingMode(false);
                                    }}
                                >
                                    <CloseIcon />
                                </IconButton>
                            }
                            sx={{
                                background: isDarkMode
                                    ? '#1B1C20'
                                    : 'rgb(218, 221, 234)',
                                '& .MuiAlert-icon': {
                                    color: '#737fb9',
                                },
                                color: isDarkMode ? '#fff' : 'inherit',
                                mb: 0,
                            }}
                        >
                            <Typography variant="body2" fontWeight="bold">
                                {t('Drawing mode activated')} (
                                {t('Press Escape key to exit')})
                            </Typography>
                            <Typography
                                variant="caption"
                                sx={{ display: { xs: 'none', md: 'block' } }}
                            >
                                {t(
                                    'Plot the points of the area where you need to place panels'
                                )}
                                .
                            </Typography>
                        </Alert>
                    </Grid>
                </Grid>
            )}

            <Divider sx={{ mb: 0 }} />
        </Box>
    );
};

HeaderPreview.propTypes = {
    currentBreadcrumb: PropTypes.string,
    handleClickBreadcrumb: PropTypes.func,
    id: PropTypes.string,
    isDrawingMode: PropTypes.bool,
    offerInfoPatern: PropTypes.object,
    setDrawingMode: PropTypes.func,
};

export default HeaderPreview;
