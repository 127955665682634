import React, { useEffect } from 'react';

import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import { useFormState, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Alert, BottomActions, Box, Button } from 'sunwise-ui';

import {
    getCompatibleCompensationSchemes,
    getCompensationSchemeByValue,
} from 'common/utils/helpers/rates';

import {
    CompensationSchemeDescription,
    DefaultSettings,
} from '../../PolicyComponents';

const PolicyDataTab = ({ control, showMode, trigger }) => {
    const { t } = useTranslation();
    const { defaultValues, errors, isSubmitted } = useFormState({ control });
    const [defaultCompensationScheme, paymentType] = useWatch({
        control,
        name: [
            'policy_data.default_compensation_scheme',
            'main_data.payment_type',
        ],
    });

    useEffect(() => {
        if (!trigger) return;
        trigger();
    }, []);

    const itemsToSelect = getCompatibleCompensationSchemes({
        canViewSelfConsumption: true,
        paymentType,
    });

    const selectedScheme = getCompensationSchemeByValue(
        defaultCompensationScheme
    );
    const showErrors = isSubmitted && !isEmpty(errors);

    return (
        <>
            <DefaultSettings
                baseName="policy_data."
                compensationSchemesOptions={itemsToSelect}
                initialValues={defaultValues?.policy_data}
                control={control}
                showMode={showMode}
            />
            {selectedScheme && (
                <CompensationSchemeDescription
                    compensationScheme={selectedScheme}
                />
            )}

            {showErrors && (
                <Alert severity="warning">
                    {!isEmpty(errors?.settings_data?.hourly_distribution)
                        ? t('Every hourly concept must be in at least one hour')
                        : t('Complete all fields with the correct information')}
                </Alert>
            )}

            {!showMode && (
                <BottomActions>
                    <Box sx={{ mt: 2 }}>
                        <Button disabled={showErrors} type="submit">
                            {t('Save')}
                        </Button>
                    </Box>
                </BottomActions>
            )}
        </>
    );
};

PolicyDataTab.propTypes = {
    control: PropTypes.object,
    showMode: PropTypes.bool,
    trigger: PropTypes.func,
};

export default PolicyDataTab;
