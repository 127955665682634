import { archiveOffer } from 'common/api/v1/contactProject';
import alerts from 'common/modules/alerts';
import { showReponseErrorsAsAlert } from 'common/utils/helpers';
import showToast from 'common/utils/showToast';

import {
    ARCHIVE_OFFER,
    ARCHIVE_OFFER_FAILURE,
    ARCHIVE_OFFER_SUCCESS,
} from '../actionTypes';
import { projectCommOfferTableActions } from '../reducer';

import fetchCommercialOffers from './fetchCommercialOffers';

export default (isArchivedFilter, offerId, projectId) => (dispatch) => {
    dispatch(projectCommOfferTableActions[ARCHIVE_OFFER]());

    archiveOffer(offerId)
        .then((response) => {
            dispatch(
                projectCommOfferTableActions[ARCHIVE_OFFER_SUCCESS](
                    response.data
                )
            );
            dispatch(fetchCommercialOffers(projectId, isArchivedFilter));
            dispatch(alerts.actions.close());
            showToast();
        })
        .catch((error) => {
            dispatch(
                projectCommOfferTableActions[ARCHIVE_OFFER_FAILURE](
                    error?.response?.data?.errors
                )
            );
            showReponseErrorsAsAlert(dispatch, error?.response);
        });
};
