import React from 'react';

import PropTypes from 'prop-types';
import { Box } from 'sunwise-ui';

import { STEPS_ORDER } from '../constants';

import ColumnMatch from './ColumnMatch';
import Review from './Review';
import UploadFile from './UploadFile';

const StepContent = ({
    columnOptions,
    columns,
    control,
    currentStep,
    file,
    getValues,
    matchedData,
    onChangeColumnsMatch,
    onFileChange,
}) => {
    switch (currentStep) {
        case STEPS_ORDER.UPLOAD:
            return <UploadFile file={file} onFileChange={onFileChange} />;
        case STEPS_ORDER.MATCH_COLUMNS:
            return (
                <Box
                    alignItems="center"
                    display="flex"
                    flexDirection="column"
                    gap={1}
                >
                    {columns?.map((column) => (
                        <ColumnMatch
                            column={column}
                            columnOptions={columnOptions}
                            control={control}
                            getValues={getValues}
                            key={column?.columnName}
                            onChangeColumnsMatch={onChangeColumnsMatch}
                        />
                    ))}
                </Box>
            );
        case STEPS_ORDER.REVIEW:
            return (
                <Review
                    columns={columns}
                    getValues={getValues}
                    matchedData={matchedData}
                />
            );
        default:
            return null;
    }
};

StepContent.propTypes = {
    columnOptions: PropTypes.array,
    columns: PropTypes.array,
    control: PropTypes.object,
    currentStep: PropTypes.number,
    file: PropTypes.object,
    getValues: PropTypes.func,
    matchedData: PropTypes.array,
    onChangeColumnsMatch: PropTypes.func,
    onFileChange: PropTypes.func,
};

export default StepContent;
