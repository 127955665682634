import i18next from 'i18next';

export const getAgentOptions = (agents = [], isNewRecord = false) => {
    if (!Array.isArray(agents)) return [];

    const items = agents
        .filter((agent) => agent.is_active && agent.is_enabled)
        .map((item) => {
            return {
                label: `${item.first_name} ${item.last_name}`,
                value: item.id,
            };
        });

    let itemsToSelect = [
        {
            disabled: true,
            label: i18next.t('Select an agent'),
            value: '',
        },
    ];

    if (!isNewRecord) {
        itemsToSelect.push({
            label: i18next.t('No advisor assigned'),
            value: 'DO_NOT_ASSIGN',
        });
    }

    itemsToSelect.push(...items);

    return itemsToSelect;
};

export const buildPayload = (values) => ({
    advanced: values.advanced,
    agent: values.agent,
    branch_office: values.branch_office,
    company_name: values.company_name,
    company_position: values.company_position,
    contact_origin: values.contact_origin,
    emails: JSON.stringify(values.emails),
    first_lastname: values.first_lastname,
    name: values.name,
    rfc: values.rfc,
    second_lastname: values.second_lastname,
    status_contact: values.status_contact,
    telephones: JSON.stringify(values.telephones),
});
