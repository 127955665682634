import React, { useEffect, useRef } from 'react';

import { push } from 'connected-react-router';
import { debounce } from 'lodash';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import { useWatch } from 'react-hook-form';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { createStructuredSelector } from 'reselect';
import { Divider, Grid } from 'sunwise-ui';

import {
    ReactHookFormInput,
    ReactHookFormSelect,
} from 'common/components/form/bootstrap';
import { DEBOUNCE_TIME } from 'common/constants';
import alerts from 'common/modules/alerts';
import {
    getCompatibleCompensationSchemes,
    getIsSelfConsumption,
} from 'common/utils/helpers/rates';
import yupResolver from 'common/utils/yupResolver';

import * as proposalGeneratorOneColumnSelectors from '../../proposalGeneratorOneColumn/selectors';
import * as actions from '../actions';
import {
    handleSaveFields,
    prepareInitializeContractedDemandFields,
    prepareInitializeData,
    prepareNextRateFields,
} from '../helpers';
import * as selectors from '../selectors';
import validate from '../validate';

import ContractedDemand from './ContractedDemand';
import Warnings from './Warnings';

const FormContainer = ({
    compatibleRatesForSelect,
    fetchCompatibleRates,
    fetchScheduleRateConfiguration,
    initialValues,
    initializeForm,
    isFetchingProfilesData,
    isFetchingScheduleRateConfiguration,
    isLocked,
    match,
    offerConfigGeneration,
    offerDetailsPattern,
    offerProfilesData,
    proposalConfiguration,
    rateChange,
    ratesDictionary,
    redirect,
    resetForm,
    save,
    scheduleRateConfiguration,
    selectedNextRate,
    selectedPrevRate,
    setScheduleRateConfiguration,
    showAlert,
    solarConsumption,
    tiersConsumptions,
    upsertRate,
}) => {
    const { t } = useTranslation();
    const { control, getValues, handleSubmit, reset, setValue } = useForm({
        defaultValues: initialValues,
        resolver: yupResolver(validate),
    });
    const previousRate = useWatch({ control, name: 'previous_rate' });
    const proposalId = match.params.uid;

    const onInitializeContractedDemand = ({
        offerConfiguration,
        rate,
        scheduleRateConfiguration,
        tiersConsumptions,
    }) => {
        const {
            next_contracted_demand: nextContractedDemand,
            contracted_demand: prevContractedDemand,
        } = tiersConsumptions?.[0] || {};

        prepareInitializeContractedDemandFields({
            nextContractedDemand,
            nextTiers:
                scheduleRateConfiguration?.tiers_demand_distribution?.[0]
                    ?.tiers,
            prevContractedDemand,
            prevTiers:
                offerConfiguration?.previous_rate_contracted_demand_definition,
            rate,
            setValue,
        });
    };

    useEffect(() => () => resetForm(), []);

    useEffect(() => reset(initialValues), [initialValues]);

    useEffect(() => {
        if (isEmpty(proposalConfiguration)) return;
        const data = prepareInitializeData(
            proposalConfiguration,
            upsertRate,
            offerConfigGeneration
        );

        initializeForm(data);
        const rateData = {
            id: data?.next_rate_object?.id,
            isCertified: data?.next_rate_object?.certified,
            name: data?.next_rate_object?.name,
        };

        fetchScheduleRateConfiguration({
            getValues,
            onSuccess: (data, rate) =>
                onInitializeContractedDemand({
                    offerConfiguration: proposalConfiguration,
                    rate,
                    scheduleRateConfiguration: data,
                    tiersConsumptions,
                }),
            rate: rateData,
            setValue,
        });
    }, [proposalConfiguration, tiersConsumptions]);

    useEffect(() => {
        if (previousRate) fetchCompatibleRates(proposalId);
    }, [previousRate]);

    useEffect(() => {
        if (
            !isFetchingProfilesData &&
            isEmpty(offerProfilesData) &&
            getIsSelfConsumption(initialValues.compensation_scheme)
        )
            handleShowAlert();
    }, [initialValues.compensation_scheme, isFetchingProfilesData]);

    const handleShowAlert = () => {
        const {
            contact: { id: contactId },
            project_id,
        } = offerDetailsPattern;
        const dataAlert = {
            confirmText: t('Update'),
            title: t('Warning'),
            messages: [
                t(
                    'The project does not have a Consumption Profile to carry out the Self-consumption simulation'
                ),
            ],
            onSuccess: () =>
                redirect(
                    `/record/${contactId}?tab=projects&project=${project_id}&view=proposals&type=proposals&open_consumption_modal=true`
                ),
            type: alerts.ALERT_TYPE_CONFIRM,
            variant: 'warning',
        };
        showAlert(dataAlert);
        setValue('compensation_scheme', initialValues.compensation_scheme);
    };

    const disabled = isLocked || compatibleRatesForSelect.length < 2;

    let options = [
        {
            disabled: true,
            label: `${t('Select')} ${t(
                'Energy compensation scheme'
            ).toLowerCase()}`,
            value: null,
        },
        ...getCompatibleCompensationSchemes({
            canViewSelfConsumption: true,
            certified: selectedNextRate?.isCertified,
            name: selectedNextRate?.name,
            paymentType: selectedNextRate?.paymentType,
        }),
    ];

    const onSuccessNextScheduleRate = (data, rate) => {
        prepareNextRateFields({
            getValues,
            rate,
            scheduleRateConfiguration: data,
            setValue,
        });
        handleSubmit((values) => save(proposalId, values, initialValues))();
    };

    const prepareUpdate = ({ target: { value } }) => {
        if (isEmpty(offerProfilesData) && getIsSelfConsumption(value))
            return handleShowAlert();

        handleSaveFields({
            handleSubmit,
            id: proposalId,
            initialValues,
            name: 'compensation_scheme',
            save,
            value,
        });
    };

    const handleChangeRate = ({ target: { value } }) => {
        const rateData = ratesDictionary[value];

        if (!rateData?.isCertified && rateData?.hasContractedDemands) {
            fetchScheduleRateConfiguration({
                getValues,
                onSuccess: onSuccessNextScheduleRate,
                rate: rateData,
                setValue,
            });
            return;
        }

        setScheduleRateConfiguration({});
        setValue('next_contracted_demand', {});

        handleSaveFields({
            handleSubmit,
            id: proposalId,
            initialValues,
            name: 'next_rate',
            save,
            value,
        });
    };

    const handleOnChangeNextContractedDemand = useRef(
        debounce(
            handleSubmit((values) => save(proposalId, values, initialValues)),
            DEBOUNCE_TIME
        )
    ).current;

    const sxFormControl = { mb: '0!important' };

    return (
        <form>
            <Warnings
                rateChange={rateChange}
                selectedNextRate={selectedNextRate}
                selectedPrevRate={selectedPrevRate}
                solarConsumption={solarConsumption}
            />

            <Grid container mb={2}>
                <Grid item xs={18} md={9} lg={4}>
                    <ReactHookFormInput
                        control={control}
                        disabled
                        label={t('Initial rate')}
                        name="initial_rate_name"
                        sx={sxFormControl}
                    />
                </Grid>

                <Grid item xs={18} md={9} lg={4}>
                    <ReactHookFormSelect
                        control={control}
                        disabled={disabled}
                        label={t('Proposed rate')}
                        name="next_rate"
                        onChange={handleChangeRate}
                        options={compatibleRatesForSelect}
                        sxFormControl={sxFormControl}
                    />
                </Grid>

                <Grid item xs={18} md={18} lg={10}>
                    <ReactHookFormSelect
                        control={control}
                        disabled={isLocked}
                        label={t('Energy compensation scheme')}
                        name="compensation_scheme"
                        onChange={prepareUpdate}
                        options={options}
                        sxFormControl={sxFormControl}
                    />
                </Grid>
            </Grid>

            <Divider />

            <ContractedDemand
                control={control}
                disabled={isLocked || isFetchingScheduleRateConfiguration}
                handleOnChangeNextContractedDemand={
                    handleOnChangeNextContractedDemand
                }
                previousContractedDemandTiers={
                    proposalConfiguration?.previous_rate_contracted_demand_definition
                }
                scheduleRateConfiguration={scheduleRateConfiguration}
            />
        </form>
    );
};

const mapStateToProps = createStructuredSelector({
    compatibleRatesForSelect: selectors.getCompatibleRatesForSelect,
    initialValues: selectors.getInitialValues,
    isFetchingProfilesData:
        proposalGeneratorOneColumnSelectors.getOfferProfilesIsFetching,
    isFetchingScheduleRateConfiguration:
        selectors.getIsFetchingScheduleRateConfiguration,
    offerConfigGeneration:
        proposalGeneratorOneColumnSelectors.getDataOfferConfigGeneration,
    offerDetailsPattern:
        proposalGeneratorOneColumnSelectors.getOfferDetailsPattern,
    offerProfilesData: proposalGeneratorOneColumnSelectors.getOfferProfilesData,
    proposalConfiguration:
        proposalGeneratorOneColumnSelectors.getProposalConfiguration,
    rateChange: proposalGeneratorOneColumnSelectors.getRateChange,
    ratesDictionary: selectors.getRatesDictionary,
    scheduleRateConfiguration: selectors.getScheduleRateConfigurationData,
    selectedNextRate: selectors.getSelectedNextRateData,
    selectedPrevRate: selectors.getSelectedPrevRateData,
    tiersConsumptions:
        proposalGeneratorOneColumnSelectors.getProposalTiersConsumptions,
});

const mapDispatchToProps = (dispatch) => ({
    fetchCompatibleRates: (initialRateId) =>
        dispatch(actions.fetchCompatibleRates(initialRateId)),
    fetchScheduleRateConfiguration: (params) =>
        dispatch(actions.fetchScheduleRateConfiguration(params)),
    initializeForm: (values) => dispatch(actions.initializeForm(values)),
    redirect: (link) => dispatch(push(link)),
    resetForm: () => dispatch(actions.resetForm()),
    save: (proposalId, values, previousValues) =>
        dispatch(actions.save(proposalId, values, previousValues)),
    setGenerationSource: (proposalId, source, previousValues) =>
        dispatch(
            actions.setGenerationSource(proposalId, source, previousValues)
        ),
    setScheduleRateConfiguration: (data) =>
        dispatch(actions.setScheduleRateConfiguration(data)),
    showAlert: (dataAlert) => dispatch(alerts.actions.show(dataAlert)),
    upsertRate: (rateData) => dispatch(actions.upsertRate(rateData)),
});

FormContainer.propTypes = {
    compatibleRatesForSelect: PropTypes.array,
    fetchCompatibleRates: PropTypes.func,
    fetchScheduleRateConfiguration: PropTypes.func,
    initialValues: PropTypes.object,
    initializeForm: PropTypes.func,
    isFetchingProfilesData: PropTypes.bool,
    isFetchingScheduleRateConfiguration: PropTypes.bool,
    isLocked: PropTypes.bool,
    match: PropTypes.object,
    offerConfigGeneration: PropTypes.object,
    offerDetailsPattern: PropTypes.object,
    offerProfilesData: PropTypes.object,
    proposalConfiguration: PropTypes.object,
    rateChange: PropTypes.number,
    ratesDictionary: PropTypes.object,
    redirect: PropTypes.func,
    resetForm: PropTypes.func,
    save: PropTypes.func,
    scheduleRateConfiguration: PropTypes.object,
    selectedNextRate: PropTypes.object,
    selectedPrevRate: PropTypes.object,
    setScheduleRateConfiguration: PropTypes.func,
    showAlert: PropTypes.func,
    solarConsumption: PropTypes.number,
    tiersConsumptions: PropTypes.array,
    upsertRate: PropTypes.func,
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withRouter
)(FormContainer);
