import React, { useEffect } from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { createStructuredSelector } from 'reselect';
import {
    TemplateView,
    withTemplateCore,
    editionLevels,
    types,
} from 'sunwise-template-core';

import { getCurrentLanguage } from 'common/utils/helpers/multiregion';
import parseQueryString from 'common/utils/parseQueryString';

import * as actions from './actions';
import * as selectors from './selectors';

const Container = ({ initTemplate, initialize, isInitializing }) => {
    const {
        templateFinishedId,
        templateType = types.ONE_PROPOSAL_TYPE,
        token,
        mode,
    } = parseQueryString(location.search);

    const editionLevel =
        mode === 'no-edition'
            ? editionLevels.NO_EDITION_MODE
            : editionLevels.PRINT_MODE;

    useEffect(() => {
        if (templateFinishedId) {
            initialize(
                templateFinishedId,
                parseInt(templateType),
                initTemplate,
                token
            );
        }
    }, [templateFinishedId, token]);

    if (!templateFinishedId || isInitializing) return null;

    return <TemplateView editionLevel={editionLevel} pageDesignDisabled />;
};

const mapStateToProps = createStructuredSelector({
    isInitializing: selectors.getIsInitializing,
});

const mapDispatchToProps = (dispatch) => ({
    initialize: (id, templateType, initTemplate, token) =>
        dispatch(actions.initialize(id, templateType, initTemplate, token)),
});

Container.propTypes = {
    initialize: PropTypes.func,
    isInitializing: PropTypes.bool,
    initTemplate: PropTypes.func,
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withTemplateCore(() => ({
        application: 'sunwise',
        baseUrl: process.env.REACT_APP_API_URL,
        froalaLicenseKey: process.env.REACT_APP_FROALA_LICENSE,
        googleApiKey: process.env.REACT_APP_GOOGLE_MAPS_KEY,
        language: getCurrentLanguage(),
    }))
)(Container);
