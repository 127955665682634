import React, { useEffect, useRef } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import SendIcon from '@mui/icons-material/Send';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Box, Button, Divider, IconButton, Typography } from 'sunwise-ui';

import { StyledH2 } from 'common/components';
import { ReactHookFormInput } from 'common/components/form/bootstrap';

import * as actions from './actions';
import * as selectors from './selectors';

const Container = styled(Box)(
    ({ theme }) => `
        background-color: ${
            theme.palette.mode === 'dark' ? '#3f444c' : '#f7fcff'
        };
        bottom: 0;
        display: flex;
        flex-direction: column;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
`
);

const Section = styled('div')`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    min-height: 0;
    padding: 0 15px;
`;
const ScrollableContent = styled('div')`
    flex-grow: 1;
    min-height: 0;
    overflow: auto;
`;

const Comment = styled('div')`
    display: flex;
    margin-bottom: 15px;

    picture {
        align-items: center;
        border-radius: 100%;
        display: flex;
        height: 35px;
        overflow: hidden;
        width: 35px;

        img {
            width: 100%;
        }
    }

    ${({ variant }) =>
        variant === 'self' &&
        `flex-direction: row-reverse;

        .role-name {
            text-align: right;
        }`}
`;

const Content = styled('div')`
    flex-grow: 1;
    padding: 0 15px;
`;

const Message = styled('div')`
    border-radius: 0 3px 3px 3px;
    margin-top: 5px;
    padding: 15px;

    ${({ variant }) =>
        variant === 'reject' &&
        `background-color: #f7edf0;

        .title{
            color: #FA6968;
            font-weight: 600;
            margin-bottom: 15px;
        }`}
`;

const FormContainer = ({
    callback,
    description,
    documentId,
    documentType,
    fetchMessages,
    handleOnClickSend,
    initialValues,
    isFetching,
    messages,
    user,
    userType,
}) => {
    const { t } = useTranslation();
    const messagesEndRef = useRef(null);

    const { control, handleSubmit, reset } = useForm({
        defaultValues: initialValues,
    });

    useEffect(() => {
        fetchMessages({ documentId, documentType, user, userType });
    }, []);

    useEffect(() => {
        reset(initialValues);
    }, [initialValues]);

    const scrollToBottom = () => {
        messagesEndRef.current.scrollTo(0, messagesEndRef.current.scrollHeight);
    };

    useEffect(() => {
        scrollToBottom();
    }, [messages]);

    const handleOnClickSubmit = (values) => handleOnClickSend(values, callback);

    return (
        <Container>
            <Section>
                <Box py={2}>
                    <Typography variant="body2" fontWeight="bold">
                        <small>{description}</small>
                    </Typography>
                </Box>

                <Divider sx={{ mb: 2 }} />

                <ScrollableContent ref={messagesEndRef}>
                    {messages.map((comment) => (
                        <Comment key={`comment-${comment.id}`}>
                            <picture>
                                <img alt="" src={comment.image}></img>
                            </picture>
                            <Content>
                                <div>
                                    <small>
                                        {comment.name} - {comment.role}
                                    </small>

                                    <StyledH2 size="sm">
                                        {comment.username}
                                    </StyledH2>
                                </div>
                                <Message
                                    variant={comment.isReject ? 'reject' : ''}
                                >
                                    {comment.isReject && (
                                        <>
                                            <div className="title">
                                                <IconButton color="error">
                                                    <CloseIcon />
                                                </IconButton>{' '}
                                                {t('Reason for rejection')}
                                            </div>

                                            {comment.reasons.map(
                                                (item, index) => (
                                                    <Button
                                                        key={`reject-reason-${index}`}
                                                    >
                                                        {item}
                                                    </Button>
                                                )
                                            )}
                                        </>
                                    )}
                                    <div>{comment.message}</div>
                                </Message>
                            </Content>
                        </Comment>
                    ))}
                </ScrollableContent>

                <form onSubmit={handleSubmit(handleOnClickSubmit)}>
                    <Box display="flex" gap="16px">
                        <ReactHookFormInput
                            control={control}
                            name="message"
                            placeholder={t('Add comment')}
                        />

                        <Button
                            color="success"
                            disabled={isFetching}
                            startIcon={<SendIcon />}
                            type="submit"
                        >
                            {t('Send')}
                        </Button>
                    </Box>
                </form>
            </Section>
        </Container>
    );
};

FormContainer.propTypes = {
    callback: PropTypes.func,
    description: PropTypes.string,
    documentId: PropTypes.string,
    documentType: PropTypes.string,
    fetchMessages: PropTypes.func,
    handleOnClickSend: PropTypes.func,
    initialValues: PropTypes.object,
    isFetching: PropTypes.bool,
    messages: PropTypes.array,
    user: PropTypes.object,
    userType: PropTypes.number,
};

const mapStateToProps = createStructuredSelector({
    initialValues: selectors.getInitialValues,
    isFetching: selectors.getSendIsFetching,
    messages: selectors.getMessages,
});

const mapDispatchToProps = (dispatch) => ({
    fetchMessages: (values) => dispatch(actions.fetchMessages(values)),
    handleOnClickSend: (values, callback) =>
        dispatch(actions.sendMessage(values, callback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FormContainer);
