import * as multiBranchesSelectors from 'common/modules/multiBranches/selectors';

import { UPDATE_TEMPLATE_MODE } from '../constants';

import initializeForm from './initializeForm';
import setTemplateMode from './setTemplateMode';
import toggleModalTemplate from './toggleModalTemplate';

const getTemplateBranchOffices = ({ is_global, branch_offices }, state) => {
    const branchSelected = multiBranchesSelectors.getBranchSelected(state);
    if (is_global || branchSelected === 'company') {
        return 'company';
    }
    if (branch_offices.length === 1) {
        return branch_offices[0].id;
    }
    if (branchSelected === 'all') {
        return branch_offices.map(({ id }) => id).join();
    }
    return branchSelected;
};
export default (template) => (dispatch, getState) => {
    dispatch(setTemplateMode(UPDATE_TEMPLATE_MODE));
    dispatch(
        initializeForm({
            ...template,
            branch_offices: getTemplateBranchOffices(template, getState()),
            language: template.language,
            proposals_number: `${template.proposalsNumber}`,
        })
    );
    dispatch(toggleModalTemplate(true));
};
