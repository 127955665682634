import get from 'lodash/get';

import { getPrecalificationInfonavitData } from 'common/api/v1/sunpay';
import { showReponseErrorsAsAlert } from 'common/utils/helpers';

import {
    FETCH_INFONAVIT_INFO,
    FETCH_INFONAVIT_INFO_FAILURE,
    FETCH_INFONAVIT_INFO_SUCCESS,
} from '../actionTypes';
import { prepareInfonavitData } from '../helpers';
import { financingAndInsuranceActions } from '../reducer';

import initialValuesCeide from './initialValuesCeide';

export default (callback, proposalId, values) => (dispatch) => {
    const infonavitData = get(values, 'infonavit_data', {});
    const administratives_expenses_percentage = values.administratives_expenses;
    const birthday = infonavitData.birthday;
    const nss = infonavitData.nss;
    const newValues = {
        nss,
        birthday,
        financier_product: values.financier_product,
    };

    dispatch(financingAndInsuranceActions[FETCH_INFONAVIT_INFO]());

    getPrecalificationInfonavitData(proposalId, newValues)
        .then((response) => {
            dispatch(
                financingAndInsuranceActions[FETCH_INFONAVIT_INFO_SUCCESS](
                    response.data
                )
            );

            const data = get(response, 'data.data', {});
            const extra = get(data, 'extra', null);
            const retention_amount = get(data, 'retention_amount', 0);
            const retention_percentage = get(data, 'retention_percentage', 5);
            const total_amount = get(data, 'total_amount', 0);

            const infonavitData = prepareInfonavitData(
                administratives_expenses_percentage,
                birthday,
                extra,
                nss,
                retention_amount,
                retention_percentage,
                total_amount
            );

            dispatch(
                initialValuesCeide({
                    ...values,
                    infonavit_data: infonavitData,
                })
            );

            if (callback) callback();
        })
        .catch((error) => {
            dispatch(
                financingAndInsuranceActions[FETCH_INFONAVIT_INFO_FAILURE](
                    error?.response?.data?.errors
                )
            );
            showReponseErrorsAsAlert(dispatch, error.response);
        });
};
