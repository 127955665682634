import { createImage } from 'common/api/v1/offerTemplate';

import { INITIALIZE, SAVE_IMAGE, SAVE_IMAGE_SUCCESS } from '../actionTypes';
import { imageUploaderActions } from '../reducer';

const getImageUrl = (url) => {
    if (url.includes('https')) return url;
    return `${process.env.REACT_APP_API_URL}${url}`;
};

export default (image) => (dispatch) => {
    if (image) {
        dispatch(imageUploaderActions[SAVE_IMAGE]());
        createImage({ image }).then((response) => {
            const imgSrc = getImageUrl(response.data.image);
            dispatch(imageUploaderActions[SAVE_IMAGE_SUCCESS](imgSrc));
            dispatch(imageUploaderActions[INITIALIZE]());
        });
    }
};
