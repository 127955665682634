import React from 'react';

import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import WarningIcon from '@mui/icons-material/Warning';
import { useTheme } from '@mui/material';
import styled from '@mui/material/styles/styled';
import { get, isNull } from 'lodash';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box, Tab, Tabs } from 'sunwise-ui';

import {
    IconEnergyBackup,
    IconFinancial,
    IconPanelSeeding,
    IconSaveEnergy,
} from 'common/components/icons';

import proposalGeneratorConsumptionAnalysisVsGeneration from '../../proposalGeneratorConsumptionAnalysisVsGeneration';
import proposalGeneratorEnergyBackup from '../../proposalGeneratorEnergyBackup';
import ProposalGeneratorEnergySaving from '../../proposalGeneratorEnergySaving';
import proposalGeneratorFinancingAndInsurance from '../../proposalGeneratorFinancingAndInsurance';
import proposalGeneratorPanelsLayout from '../../proposalGeneratorPanelsLayout';
import proposalGeneratorQuote from '../../proposalGeneratorQuote';

const TabsContainer = styled(Box)(
    ({ theme }) => `
        background: ${theme.palette.background.default};
        position: sticky;
        top: 0;
        z-index: 2;
`
);

const ProposalTabs = ({
    contact,
    currencyIso,
    currencyLocale,
    currencySymbol,
    customerEnergyData,
    customerMode,
    fetchOfferDetails,
    financialProductParameters,
    financierInstaller,
    infonavitData,
    isArchived,
    isGenerated,
    isLoadingDefault,
    isLockedDefault,
    mainColumnRef,
    offerDetails,
    offerDetailsComplements,
    offerDetailsConsumption,
    offerDetailsLocation,
    offerId,
    projectId,
    refCards,
    refTabs,
    setTabClicked,
    setTabSelected,
    sunHoursWithCustomGeneration,
    tabs,
    tabSelected,
}) => {
    const theme = useTheme();
    const { t } = useTranslation();

    const financialPlan = get(
        financialProductParameters,
        'product_financial_plan',
        null
    );
    const hasFinancingRequest = get(
        offerDetails,
        'has_financing_request',
        false
    );

    const scrollToSection = (index) => {
        mainColumnRef.current.scrollTo({
            top:
                refCards.current[index].current.offsetTop -
                refTabs.current.scrollHeight -
                mainColumnRef.current.offsetTop -
                16,
            behavior: 'smooth',
        });
        setTabSelected(tabs[index]);
        setTabClicked(tabs[index]);
    };

    return (
        <Box px={2}>
            <TabsContainer
                className="__userguiding_proposal_generator_top_menu"
                ref={refTabs}
                sx={{
                    borderBottom: 1,
                    borderColor: 'divider',
                    mb: 2,
                }}
            >
                <Tabs
                    aria-label="proposal tabs"
                    value={tabSelected}
                    variant="scrollable"
                >
                    <Tab
                        icon={
                            <IconSaveEnergy
                                color={theme.palette.primary.main}
                            />
                        }
                        iconPosition="start"
                        id="tab-analysis"
                        label={t('Analysis')}
                        onClick={() => scrollToSection(0)}
                        sx={{ gap: 2 }}
                        value={tabs[0]}
                    />

                    <Tab
                        icon={
                            <AttachMoneyIcon
                                fontSize="small"
                                sx={{ color: 'primary.main' }}
                            />
                        }
                        iconPosition="start"
                        id="tab-quote"
                        label={t('Quotation')}
                        onClick={() => scrollToSection(1)}
                        value={tabs[1]}
                    />

                    <Tab
                        icon={
                            <IconPanelSeeding
                                color={theme.palette.primary.main}
                            />
                        }
                        iconPosition="start"
                        id="tab-layout"
                        label={t('PV Layout')}
                        onClick={() => scrollToSection(2)}
                        sx={{ gap: 2 }}
                        value={tabs[2]}
                    />

                    <Tab
                        icon={
                            <IconEnergyBackup
                                color={theme.palette.primary.main}
                            />
                        }
                        iconPosition="start"
                        id="tab-storage"
                        label={t('Storage')}
                        onClick={() => scrollToSection(3)}
                        sx={{ gap: 2 }}
                        value={tabs[3]}
                    />

                    <Tab
                        icon={
                            hasFinancingRequest ? (
                                <WarningIcon sx={{ color: 'primary.main' }} />
                            ) : (
                                <IconFinancial
                                    color={theme.palette.primary.main}
                                    sx={{ maxHeight: '50px' }}
                                />
                            )
                        }
                        iconPosition="start"
                        id="tab-financing"
                        label={t('Financing')}
                        onClick={() => scrollToSection(4)}
                        sx={{ gap: 2 }}
                        value={tabs[4]}
                    />
                </Tabs>
            </TabsContainer>

            <Box ref={refCards.current[0]}>
                <ProposalGeneratorEnergySaving.Container
                    contractedDemand={customerEnergyData.contrated_demand}
                    customerEnergyData={customerEnergyData}
                    energySaving={offerDetailsConsumption.energy}
                    initialRate={get(customerEnergyData, 'rate.name', null)}
                    isLoading={isLoadingDefault}
                    isLocked={isLockedDefault}
                    originalConsumption={
                        offerDetailsConsumption.original_consumption
                    }
                    proposalId={offerId}
                    sunHours={sunHoursWithCustomGeneration}
                    systemSize={offerDetailsConsumption.system_size}
                />

                <proposalGeneratorConsumptionAnalysisVsGeneration.Container
                    customerEnergyData={customerEnergyData}
                    isLoading={isLoadingDefault}
                    isLocked={isLockedDefault}
                    proposalId={offerId}
                    solarConsumption={offerDetailsConsumption.solar_consumption}
                    systemSize={offerDetailsConsumption.system_size}
                />
            </Box>

            <Box ref={refCards.current[1]}>
                <proposalGeneratorQuote.Container
                    currencyIso={currencyIso}
                    currencyLocale={currencyLocale}
                    currencySymbol={currencySymbol}
                    customerMode={customerMode}
                    infonavitData={infonavitData}
                    isLoading={isLoadingDefault}
                    isLocked={isLockedDefault}
                    offerDiscount={get(offerDetailsComplements, 'discount', 0)}
                    priceByWatt={get(offerDetailsComplements, 'price_watt', 0)}
                    proposalId={offerId}
                    systemSize={offerDetailsConsumption.system_size}
                    typeChange={get(offerDetailsComplements, 'type_change', 0)}
                />
            </Box>

            <Box ref={refCards.current[2]}>
                <proposalGeneratorPanelsLayout.Container
                    isLoading={isLoadingDefault}
                    isLocked={isLockedDefault}
                    projectId={projectId}
                    projectLocation={offerDetailsLocation}
                    proposalId={offerId}
                />
            </Box>

            <Box ref={refCards.current[3]}>
                <proposalGeneratorEnergyBackup.Container
                    currencyLocale={currencyLocale}
                    isLoading={isLoadingDefault}
                    isLocked={isLockedDefault}
                    projectId={projectId}
                />
            </Box>

            <Box ref={refCards.current[4]}>
                <proposalGeneratorFinancingAndInsurance.Container
                    contact={contact}
                    currencyIso={currencyIso}
                    currencyLocale={currencyLocale}
                    currencySymbol={currencySymbol}
                    fetchOfferDetails={fetchOfferDetails}
                    financialProductParameters={financialProductParameters}
                    financierInstaller={financierInstaller}
                    hasFinancing={get(offerDetails, 'has_financing', false)}
                    hasFinancingRequest={hasFinancingRequest}
                    hasOwnFinancing={!isNull(financialPlan)}
                    isArchivedProposal={isArchived}
                    isGeneratedProposal={isGenerated}
                    isLoading={isLoadingDefault}
                    isLocked={isLockedDefault}
                    proposalId={offerId}
                />
            </Box>
        </Box>
    );
};

ProposalTabs.propTypes = {
    contact: PropTypes.object,
    currencyIso: PropTypes.string,
    currencyLocale: PropTypes.string,
    currencySymbol: PropTypes.string,
    customerEnergyData: PropTypes.object,
    customerMode: PropTypes.bool,
    fetchOfferDetails: PropTypes.func,
    financialProductParameters: PropTypes.object,
    financierInstaller: PropTypes.object,
    infonavitData: PropTypes.string,
    isArchived: PropTypes.bool,
    isGenerated: PropTypes.bool,
    isLoadingDefault: PropTypes.bool,
    isLockedDefault: PropTypes.bool,
    mainColumnRef: PropTypes.object,
    offerDetails: PropTypes.object,
    offerDetailsComplements: PropTypes.object,
    offerDetailsConsumption: PropTypes.object,
    offerDetailsLocation: PropTypes.object,
    offerId: PropTypes.string,
    projectId: PropTypes.string,
    refCards: PropTypes.object,
    refTabs: PropTypes.object,
    setTabClicked: PropTypes.func,
    setTabSelected: PropTypes.func,
    sunHoursWithCustomGeneration: PropTypes.number,
    tabs: PropTypes.array,
    tabSelected: PropTypes.string,
};

export default ProposalTabs;
