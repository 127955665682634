const rotatePoint = (point, center, angle) => {
    const radians = (angle * Math.PI) / 180.0;
    const cos = Math.cos(radians);
    const sin = Math.sin(radians);

    const translatedX = point.x - center.x;
    const translatedY = point.y - center.y;

    const rotatedX = translatedX * cos - translatedY * sin;
    const rotatedY = translatedX * sin + translatedY * cos;

    const finalX = rotatedX + center.x;
    const finalY = rotatedY + center.y;

    return { x: finalX, y: finalY };
};

const getCenter = ({ bounds, google } = {}) => {
    // Obtiene las coordenadas de los vértices del rectángulo
    const sw = bounds.getSouthWest(); // Esquina inferior izquierda
    const ne = bounds.getNorthEast(); // Esquina superior derecha

    // Calcula el centro del rectángulo
    const centerLat = (ne.lat() + sw.lat()) / 2;
    const centerLng = (ne.lng() + sw.lng()) / 2;

    // Crea un objeto LatLng que representa el centro del rectángulo
    return new google.maps.LatLng(centerLat, centerLng);
};

export default (google) => {
    class CustomOverlayView extends google.maps.OverlayView {
        div;
        rotate;
        opacity;

        constructor(bounds, image, rotate = 0, opacity = 100) {
            super();

            this.bounds = bounds;
            this.image = image;
            this.rotate = rotate;
            this.opacity = opacity;
            // Define a property to hold the image's div. We'll
            // actually create this div upon receipt of the onAdd()
            // method so we'll leave it null for now.
            this.div = null;
        }

        /**
         * onAdd is called when the map's panes are ready and the overlay has been
         * added to the map.
         */
        onAdd() {
            const div = document.createElement('div');
            div.style.position = 'absolute';

            // Create the img element and attach it to the div.
            const img = document.createElement('img');
            img.src = this.image;
            img.style.width = '100%';
            img.style.height = '100%';
            img.style.position = 'absolute';
            img.style.objectFit = 'contain';
            div.appendChild(img);

            this.div = div;

            // Add the element to the "overlayLayer" pane.
            const panes = this.getPanes();
            panes.overlayLayer.appendChild(div);
        }

        draw() {
            // We use the south-west and north-east
            // coordinates of the overlay to peg it to the correct position and size.
            // To do this, we need to retrieve the projection from the overlay.
            const overlayProjection = this.getProjection();

            // Retrieve the south-west and north-east coordinates of this overlay
            // in LatLngs and convert them to pixel coordinates.
            // We'll use these coordinates to resize the div.
            const sw = overlayProjection.fromLatLngToDivPixel(
                this.bounds.getSouthWest()
            );
            const ne = overlayProjection.fromLatLngToDivPixel(
                this.bounds.getNorthEast()
            );

            // Resize the image's div to fit the indicated dimensions.

            const heading = this.map.getHeading();
            const center = getCenter({ bounds: this.bounds, google });

            let _sw = rotatePoint(
                sw,
                overlayProjection.fromLatLngToDivPixel(center),
                heading
            );
            let _ne = rotatePoint(
                ne,
                overlayProjection.fromLatLngToDivPixel(center),
                heading
            );

            const div = this.div;
            div.style.left = _sw.x + 'px';
            div.style.top = _ne.y + 'px';
            div.style.width = _ne.x - _sw.x + 'px';
            div.style.height = _sw.y - _ne.y + 'px';
            div.style.transform = 'rotate(-' + heading + 'deg)';
            div.style.opacity = this.opacity / 100;
            div.style.zIndex = -2;

            const img = div.firstChild;
            img.style.transform = 'rotate(' + this.rotate + 'deg)';
        }

        // The onRemove() method will be called automatically from the API if
        // we ever set the overlay's map property to 'null'.
        onRemove() {
            this.div.parentNode.removeChild(this.div);
            this.div = null;
        }

        setRotate(rotate) {
            this.rotate = rotate;
            this.draw();
        }

        setOpacity(opacity) {
            this.opacity = opacity;
            this.draw();
        }

        setBounds(bounds) {
            this.bounds = bounds;
            this.draw();
        }
    }

    return CustomOverlayView;
};
