import i18next from 'i18next';

import alerts from 'common/modules/alerts';
import { getIsMexicanAccount } from 'common/utils/helpers/session';

import save from './save';

export default (values, setValue) => (dispatch) => {
    const isMexicanAccount = getIsMexicanAccount();
    if (values.hrsSol && values.hrsSol.length > 0) {
        const hrsSolArrayErrorsMinMax = [];

        values.hrsSol.forEach((hrSol, index) => {
            if (
                hrSol &&
                parseFloat(hrSol.value) > 0 &&
                (parseFloat(hrSol.value) < 2 || parseFloat(hrSol.value) > 8)
            ) {
                hrsSolArrayErrorsMinMax[index] = { value: 1 };
            }
        });

        if (hrsSolArrayErrorsMinMax.length && isMexicanAccount) {
            const dataAlert = {
                confirmText: i18next.t('Accept'),
                messages: [
                    i18next.t(
                        'The sunshine hours that you are entering are out of the recommended range, are you sure you want to proceed?'
                    ),
                ],
                onSuccess: () => {
                    dispatch(alerts.actions.close());
                    dispatch(save(values, setValue));
                },
                title: i18next.t('Confirmation'),
                type: alerts.ALERT_TYPE_CONFIRM,
                variant: 'warning',
            };

            dispatch(alerts.actions.show(dataAlert));
        } else {
            dispatch(save(values, setValue));
        }
    }
};
