import { filterTemplates } from 'common/api/v1/templates';

import {
    FETCH_TEMPLATES,
    FETCH_TEMPLATES_FAILURE,
    FETCH_TEMPLATES_SUCCESS,
} from '../actionTypes';
import { templateLibraryActions } from '../reducer';

export default ({
        orderBy,
        page = 0,
        pageSize,
        searchText,
        sortBy,
        status,
        type,
    } = {}) =>
    (dispatch) => {
        dispatch(templateLibraryActions[FETCH_TEMPLATES]());

        filterTemplates({
            language: '',
            orderBy,
            page: page + 1,
            perPage: pageSize,
            searchText,
            sortBy,
            status,
            type,
        })
            .then((response) =>
                dispatch(
                    templateLibraryActions[FETCH_TEMPLATES_SUCCESS](
                        response?.data
                    )
                )
            )
            .catch((error) =>
                dispatch(templateLibraryActions[FETCH_TEMPLATES_FAILURE](error))
            );
    };
