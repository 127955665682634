import React, { useEffect, useState } from 'react';

import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import yupResolver from 'common/utils/yupResolver';

import * as selectors from '../selectors';

import Grid from './form/Grid';
import StyledButton from './form/StyledButton';
import Input from './form/StyledInput';
import StyledRadioButton from './form/StyledRadioButton';
import StyledSelect from './form/StyledSelect';
import validate from './form/validate';

const StyledTitle = styled('h1')`
    color: #2845ff;
    font-size: 20px;
    font-weight: bold;
    line-height: 24px;
    margin: 40px 0;
`;

const FormContainer = ({
    config,
    currencyIso,
    locations = [],
    rates = [],
    initialValues,
    installTypes = [],
    periodsToSelect = [],
}) => {
    const { t } = useTranslation();
    const [showServiceNumberField, setShowServiceNumberField] = useState(null);
    const [methodToSelect, setMethodToSelect] = useState([]);
    const [installTypeToSelect, setInstallTypeToSelect] = useState([]);
    const arrayMethods = [
        {
            label: t('Energy'),
            value: 'ENERGY',
        },
        {
            label: t('Money'),
            value: 'MONEY',
        },
    ];

    const arrayInstallTypeToSelect = [
        {
            label: t('Rate'),
            value: 'RATE',
        },
        {
            label: t('Type of installation'),
            value: 'INSTALLATION_TYPE',
        },
    ];

    const {
        control,
        formState: { errors: error },
        handleSubmit,
        reset,
        setValue,
        watch,
    } = useForm({
        defaultValues: initialValues,
        resolver: yupResolver(validate),
    });

    const [methodConsumption, installType] = watch([
        'method_consumtion',
        'install_type',
    ]);

    const handleOnSubmit = () => {};

    useEffect(() => reset(initialValues), [initialValues]);

    useEffect(() => {
        if (config !== null) {
            setShowServiceNumberField(
                config.service_number[0] === '1' ? true : false
            );

            const filterMethods = arrayMethods.filter((obj) =>
                config.method_consumtion.includes(obj.value.toString())
            );

            setMethodToSelect(filterMethods);

            if (filterMethods.length) {
                setValue('method_consumtion', filterMethods[0].value);
            }

            const filterIntallTypes = arrayInstallTypeToSelect.filter((obj) =>
                config.install_type.includes(obj.value.toString())
            );

            setInstallTypeToSelect(filterIntallTypes);

            if (filterIntallTypes.length) {
                setValue('install_type', filterIntallTypes[0].value);
            }
        }
    }, [config]);

    return (
        <form>
            <StyledTitle style={(config && config.style.h1) || {}}>
                {t('Basic data to quote')}
            </StyledTitle>

            {showServiceNumberField && (
                <Controller
                    control={control}
                    name="service_number"
                    render={({ field }) => (
                        <Input
                            disabled
                            error={error?.service_number}
                            label={t('Service number')}
                            field={field}
                        />
                    )}
                />
            )}

            <Grid container>
                {methodToSelect && methodToSelect.length > 0 && (
                    <Grid item xs>
                        <Controller
                            control={control}
                            name="method_consumtion"
                            render={({ field }) => (
                                <StyledRadioButton
                                    classNameItems="text-center"
                                    field={field}
                                    items={methodToSelect}
                                    label={
                                        methodToSelect.length === 1
                                            ? t('Consumption method')
                                            : t('Select the consumption method')
                                    }
                                    radioVisible={false}
                                />
                            )}
                        />
                    </Grid>
                )}

                {installTypeToSelect && installTypeToSelect.length > 0 && (
                    <Grid item xs>
                        <Controller
                            control={control}
                            name="install_type"
                            render={({ field }) => (
                                <StyledRadioButton
                                    label={
                                        installTypeToSelect.length === 1
                                            ? t('Calculation method')
                                            : t('Select the calculation method')
                                    }
                                    classNameItems="text-center"
                                    field={field}
                                    items={installTypeToSelect}
                                    radioVisible={false}
                                />
                            )}
                        />
                    </Grid>
                )}
            </Grid>

            <Grid container>
                {methodToSelect && methodToSelect.length > 0 && (
                    <Grid item xs>
                        <Controller
                            control={control}
                            name="consumption_value"
                            render={({ field }) => (
                                <Input
                                    field={field}
                                    label={`${t(
                                        'How much is your electric consumption?'
                                    )} (${
                                        methodConsumption === 'ENERGY'
                                            ? 'kWh'
                                            : currencyIso
                                    })`}
                                    required
                                    disabled
                                />
                            )}
                        />
                    </Grid>
                )}

                {installTypeToSelect && installTypeToSelect.length > 0 && (
                    <Grid item xs>
                        <Controller
                            control={control}
                            name="intall_type_value"
                            render={({ field }) => (
                                <StyledSelect
                                    field={field}
                                    label={
                                        installType === 'RATE'
                                            ? t('Rate')
                                            : t('Type of installation')
                                    }
                                    options={
                                        installType === '1'
                                            ? rates
                                            : installTypes
                                    }
                                    required
                                    disabled
                                />
                            )}
                        />
                    </Grid>
                )}
            </Grid>

            <Grid container>
                <Grid item xs>
                    <Grid container>
                        <Grid item xs>
                            <Controller
                                control={control}
                                name="periodicity"
                                render={({ field }) => (
                                    <StyledSelect
                                        field={field}
                                        label={t('Periodicity')}
                                        options={periodsToSelect}
                                        disabled
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs>
                            <Controller
                                control={control}
                                name="cp"
                                render={({ field }) => (
                                    <Input
                                        field={field}
                                        label={t('Zip code')}
                                        required
                                        disabled
                                    />
                                )}
                            />
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs>
                    <Controller
                        control={control}
                        name="location"
                        render={({ field }) => (
                            <StyledSelect
                                field={field}
                                label={t('Where will you install the panels?')}
                                options={locations}
                                required
                                disabled
                            />
                        )}
                    />
                </Grid>
            </Grid>

            <StyledTitle style={(config && config.style.h2) || {}}>
                {t('Contact information')}
            </StyledTitle>

            <Grid container>
                <Grid item xs>
                    <Controller
                        control={control}
                        name="name"
                        render={({ field }) => (
                            <Input
                                label={t('Full name')}
                                required
                                disabled
                                field={field}
                            />
                        )}
                    />

                    <Controller
                        control={control}
                        name="email"
                        render={({ field }) => (
                            <Input
                                label={t('Email')}
                                required
                                disabled
                                error={error?.email}
                                field={field}
                            />
                        )}
                    />
                    <Controller
                        control={control}
                        name="phone"
                        render={({ field }) => (
                            <Input
                                label={t('Telephone')}
                                required
                                disabled
                                field={field}
                            />
                        )}
                    />
                </Grid>

                <Grid item xs>
                    <Controller
                        control={control}
                        name="Message"
                        render={({ field }) => (
                            <Input
                                as="textarea"
                                label={t('Message')}
                                rows={7}
                                disabled
                                field={field}
                            />
                        )}
                    />
                </Grid>
            </Grid>

            <StyledButton
                style={(config && config.style.button) || {}}
                type="submit"
                onClick={handleSubmit(handleOnSubmit)}
                disabled
            >
                <strong>
                    {(config && config.style.button.text) || t('Text')}
                </strong>
            </StyledButton>
        </form>
    );
};

FormContainer.propTypes = {
    config: PropTypes.object,
    currencyIso: PropTypes.string,
    locations: PropTypes.array,
    rates: PropTypes.array,
    initialValues: PropTypes.object,
    installTypes: PropTypes.array,
    periodsToSelect: PropTypes.array,
};

const mapStateToProps = createStructuredSelector({
    initialValues: selectors.getInitialValuesDummyForm,
});

export default connect(mapStateToProps)(FormContainer);
