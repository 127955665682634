import React from 'react';

import PropTypes from 'prop-types';

const IconPreview = ({
    color = '#ff9a00',
    height = '20px',
    width = '20px',
}) => (
    <svg
        width={width}
        height={height}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M21.3333 0H2.66667C1.18667 0 0 1.2 0 2.66667V21.3333C0 22.8 1.18667 24 2.66667 24H21.3333C22.8 24 24 22.8 24 21.3333V2.66667C24 1.2 22.8133 0 21.3333 0ZM21.3333 21.3333H2.66667V5.33333H21.3333V21.3333ZM14 13.3333C14 14.44 13.1067 15.3333 12 15.3333C10.8933 15.3333 10 14.44 10 13.3333C10 12.2267 10.8933 11.3333 12 11.3333C13.1067 11.3333 14 12.2267 14 13.3333ZM12 8C8.36 8 5.25333 10.2133 4 13.3333C5.25333 16.4533 8.36 18.6667 12 18.6667C15.64 18.6667 18.7467 16.4533 20 13.3333C18.7467 10.2133 15.64 8 12 8ZM12 16.6667C10.16 16.6667 8.66667 15.1733 8.66667 13.3333C8.66667 11.4933 10.16 10 12 10C13.84 10 15.3333 11.4933 15.3333 13.3333C15.3333 15.1733 13.84 16.6667 12 16.6667Z"
            fill={color}
        />
    </svg>
);

IconPreview.propTypes = {
    color: PropTypes.string,
    height: PropTypes.string,
    width: PropTypes.string,
};

export default IconPreview;
