import React, { useContext, useEffect } from 'react';

import { push } from 'connected-react-router';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { createStructuredSelector } from 'reselect';
import { Container as MaterialContainer, Grid } from 'sunwise-ui';

import { HeaderContainer, TitleIcon } from 'common/components';
import { PERMISSION_LIST } from 'common/constants/permissionsV2';
import withPermissions from 'common/hocs/withPermissions';
import * as customColumnsSelectors from 'common/modules/customColumns/selectors';
import { LoadingContext } from 'common/utils/contexts';

import companyGeneral from '../companyGeneral';

import * as actions from './actions';
import TabsContent from './components/TabsContent';
import TemplateCarousel from './components/TemplateCarousel';
import * as selectors from './selectors';

const Container = ({
    branchSelected,
    canModify,
    canView,
    companyCountry,
    filterTemplates,
    handleClickBulkItems,
    handleClickCreate,
    handleClickDelete,
    handleClickDuplicate,
    handleClickUpdate,
    handleSaveRow,
    initialize,
    isCreatingItem,
    isFetchingTableSettings,
    isFetchingTemplates,
    isInitializing,
    isRenamingTemplate,
    languagesDictionary,
    paginationTemplates,
    reset,
    tableSettings,
    templatesData,
}) => {
    const loadingContext = useContext(LoadingContext);
    const { t } = useTranslation();

    useEffect(() => {
        initialize();
        return () => reset();
    }, []);

    useEffect(() => {
        if (isEmpty(loadingContext)) return;
        if (isCreatingItem || isRenamingTemplate)
            loadingContext.openLoading(
                t('Saving change', { count: 2 }).concat('...')
            );
        else loadingContext.closeLoading();
    }, [isCreatingItem, isRenamingTemplate]);

    const commonProps = {
        branchSelected,
        filterTemplates,
        handleClickBulkItems,
        handleClickCreate,
        handleClickDelete,
        handleClickDuplicate,
        handleClickUpdate,
        handleSaveRow,
        isFetching:
            isFetchingTableSettings || isFetchingTemplates || isInitializing,
        languagesDictionary,
        paginationData: paginationTemplates,
        tableSettings,
        templatesData,
    };

    return (
        <MaterialContainer maxWidth={false}>
            <HeaderContainer>
                <Grid item xs>
                    <TitleIcon title={t('Template', { count: 2 })} />
                </Grid>
            </HeaderContainer>

            {canView && (
                <TemplateCarousel
                    branchSelected={branchSelected}
                    canModify={canModify}
                    handleClickCreate={handleClickCreate}
                    selectedCountry={companyCountry}
                />
            )}

            <TabsContent commonProps={commonProps} />
        </MaterialContainer>
    );
};

const mapStateToProps = createStructuredSelector({
    companyCountry: companyGeneral.selectors.getCompanyCountry,
    isCreatingItem: selectors.getIsCreatingItem,
    isFetchingLanguages: selectors.getIsFetchingLanguages,
    isFetchingTableSettings: customColumnsSelectors.getIsFetchingTableSettings,
    isFetchingTemplates: selectors.getIsFetchingTemplates,
    isInitializing: selectors.getIsInitializing,
    isRenamingTemplate: selectors.getIsRenamingTemplate,
    languagesDictionary: selectors.getLanguagesDictionary,
    paginationTemplates: selectors.getTemplatesPagination,
    tableSettings: customColumnsSelectors.getTableSettingsData,
    templatesData: selectors.getTemplatesData,
});

const mapDispatchToProps = (dispatch) => ({
    filterTemplates: (filterData) =>
        dispatch(actions.filterTemplates(filterData)),
    handleClickBulkItems: (ids, value, filterData, callback) =>
        dispatch(actions.prepareBulkItems(ids, value, filterData, callback)),
    handleClickCreate: (branchSelected, template, templateType) =>
        dispatch(actions.prepareCreate(branchSelected, template, templateType)),
    handleClickDelete: (filter, roww) =>
        dispatch(actions.prepareDelete(filter, roww)),
    handleClickDuplicate: (branchSelected, template) =>
        dispatch(actions.prepareDuplicate(branchSelected, template)),
    handleClickUpdate: (template) => dispatch(actions.prepareEdit(template)),
    handleSaveRow: (item) => dispatch(actions.updateProduct(item)),
    initialize: () => dispatch(actions.initialFetching()),
    redirect: (link) => dispatch(push(link)),
    reset: () => dispatch(actions.reset()),
});

Container.propTypes = {
    branchSelected: PropTypes.string,
    canModify: PropTypes.bool,
    canView: PropTypes.bool,
    companyCountry: PropTypes.string,
    filterTemplates: PropTypes.func,
    handleClickBulkItems: PropTypes.func,
    handleClickCreate: PropTypes.func,
    handleClickDelete: PropTypes.func,
    handleClickDuplicate: PropTypes.func,
    handleClickUpdate: PropTypes.func,
    handleSaveRow: PropTypes.func,
    initialize: PropTypes.func,
    isCreatingItem: PropTypes.bool,
    isFetchingTableSettings: PropTypes.bool,
    isFetchingTemplates: PropTypes.bool,
    isInitializing: PropTypes.bool,
    isRenamingTemplate: PropTypes.bool,
    languagesDictionary: PropTypes.object,
    paginationTemplates: PropTypes.object,
    reset: PropTypes.func,
    tableSettings: PropTypes.array,
    templatesData: PropTypes.array,
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withPermissions(PERMISSION_LIST.PROPOSAL_TEMPLATES_PERMISSION)
)(Container);
