import { createSelector } from 'reselect';

import { NAME } from './constants';

export const getModel = (state) => state[NAME];

export const getState = (state) => state;

export const getFetchCustomTemplate = createSelector(
    getModel,
    (model) => model.fetchCustomTemplate
);

export const getIsFetchingCustomTemplate = createSelector(
    getFetchCustomTemplate,
    (model) => model.isFetching
);

export const getDataFetchCustomTemplate = createSelector(
    getFetchCustomTemplate,
    (fetchCustomTemplate) => fetchCustomTemplate.data || null
);

export const getIsInitialize = createSelector(getModel, (data) => {
    return data.isInitialize;
});

export const getUpdateCustomTemplate = createSelector(
    getModel,
    (model) => model.updateCustomTemplate
);

export const getIsUpdatingCustomTemplate = createSelector(
    getUpdateCustomTemplate,
    (model) => model.isUpdating
);

export const getUpdateCustomTemplateType = createSelector(
    getUpdateCustomTemplate,
    (model) => model.type
);

export const getFetchClonePages = createSelector(
    getModel,
    (model) => model.fetchClonePages
);

export const getIsFetchingClonePages = createSelector(
    getFetchClonePages,
    (fetchClonePages) => fetchClonePages.isFetching
);

export const getFetchClonePagesData = createSelector(
    getFetchClonePages,
    (fetchClonePages) => fetchClonePages.data || []
);

export const getDefaultClonePagesData = createSelector(
    getFetchClonePagesData,
    (clonePages) => clonePages.filter(({ category }) => category)
);

export const getCustomClonePagesData = createSelector(
    getFetchClonePagesData,
    (clonePages) =>
        clonePages.filter(({ category, company }) => !category && company)
);

export const getFetchClonePagesErrors = createSelector(
    getFetchClonePages,
    (fetchClonePages) => fetchClonePages.errors || []
);

export const getPageSize = createSelector(getModel, (model) => model.pageSize);
