import { me } from 'common/api/v1/irradiationsCompaniesConfiguration';

import {
    FETCH_DEFAULT_SOURCE,
    FETCH_DEFAULT_SOURCE_SUCCESS,
    FETCH_DEFAULT_SOURCE_FAILURE,
} from '../actionTypes';
import { solarGenerationActions } from '../reducer';

export default (setValue) => (dispatch) => {
    dispatch(solarGenerationActions[FETCH_DEFAULT_SOURCE]());

    return me()
        .then((response) => {
            dispatch(
                solarGenerationActions[FETCH_DEFAULT_SOURCE_SUCCESS](
                    response.data.data
                )
            );
            setValue('source', response.data.data.source);
        })
        .catch((error) =>
            dispatch(
                solarGenerationActions[FETCH_DEFAULT_SOURCE_FAILURE](
                    error?.response?.data?.errors
                )
            )
        );
};
