import React, { useEffect, useState } from 'react';

import DownloadIcon from '@mui/icons-material/Download';
import WarningIcon from '@mui/icons-material/Warning';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { BottomActions, Box, Button } from 'sunwise-ui';

import yupResolver from 'common/utils/yupResolver';

import { STEPS_ORDER } from '../constants';
import {
    autoDetectFormat,
    autoMatchColumns,
    getMatchedData,
    getMatchedDataWithFormats,
    getValidColumnOptions,
    readCsv,
} from '../helpers';
import validate from '../validate';

import StepContent from './StepContent';
import StepError from './StepError';
import StepperNavigation from './StepperNavigation';

const Content = ({
    columns,
    downloadExampleText,
    initialFile,
    onCancel,
    onClickDownloadExampleFile,
    onImport,
    returnFormattedData = false,
}) => {
    const { control, getValues, handleSubmit, reset, setValue } = useForm({
        context: { columns, returnFormattedData },
        defaultValues: { columnsMatch: {}, columnsFormat: {} },
        resolver: yupResolver(validate),
    });
    const [columnOptions, setColumnOptions] = useState([]);
    const [currentStep, setCurrentStep] = useState(0);
    const [currentStepError, setCurrentStepError] = useState(null);
    const [data, setData] = useState([]);
    const [file, setFile] = useState(null);
    const [matchedData, setMatchedData] = useState([{}]);

    const { t } = useTranslation();

    const handleAutoMatch = (csvColumns) => {
        const matches = autoMatchColumns({
            columns,
            getValues,
            originColumns: csvColumns,
        });

        for (const column in matches) {
            if (!matches[column]) continue;
            setValue(`columnsMatch.${column}`, matches[column]);
        }
        return matches;
    };

    const handleDetectFormats = (values, columnsMatch) => {
        const formats = autoDetectFormat({ columns, columnsMatch, values });

        for (const column in formats) {
            if (!formats[column]) continue;
            setValue(`columnsFormat.${column}`, formats[column]);
        }

        return formats;
    };

    const onChangeColumnsMatch = (data) => {
        if (!data?.meta?.fields) return;
        setColumnOptions(
            getValidColumnOptions({
                columnsMatch: getValues('columnsMatch'),
                fields: data.meta.fields,
            })
        );
    };

    const handleResult = (result) => {
        const error = result?.error;
        const success = result?.success;

        setCurrentStepError(error || null);
        if (success) setCurrentStep(currentStep + 1);
    };

    const handleReadCsv = (fileToRead) => {
        if (!fileToRead) return;
        const onComplete = (results) => {
            handleResult({ success: true });
            setData(results);
            const matches = handleAutoMatch(results.meta.fields);
            handleDetectFormats(results.data, matches);

            onChangeColumnsMatch(results);
        };

        readCsv(fileToRead, onComplete, handleResult);
    };

    useEffect(() => {
        if (!file && initialFile && typeof initialFile === 'object') {
            setFile(initialFile);
            handleReadCsv(initialFile);
            setCurrentStep(1);
        }
    }, [initialFile]);

    const resetFileData = () => {
        reset();
        setColumnOptions([]);
        setData([]);
        setMatchedData([{}]);
    };

    const onFileChange = (newFile) => {
        resetFileData();
        setFile(newFile);
    };

    const handleClickImport = () => {
        let dataToImport = matchedData;
        if (returnFormattedData)
            dataToImport = getMatchedDataWithFormats({
                columns,
                columnsFormat: getValues('columnsFormat'),
                matchedData,
            });
        onImport(dataToImport, {
            columnsFormat: getValues('columnsFormat'),
            columnsMatch: getValues('columnsMatch'),
            file,
        });
        handleResult({ success: true });
    };

    const handleMatchColumns = () => {
        const {
            hasErrors,
            errorRows,
            matchedData: newMatchedData,
        } = getMatchedData({
            columns,
            columnsFormat: getValues('columnsFormat'),
            columnsMatch: getValues('columnsMatch'),
            data: data?.data,
        });

        setMatchedData(newMatchedData);
        let result = { success: true };

        if (hasErrors)
            result = {
                success: true,
                error: `${t(
                    'There appears to be an issue with the data or its format. If you continue, the rows with errors will be ignored. Do you want to continue?'
                )} ${t('Rows with errors')}: ${errorRows?.join(', ')}`,
            };

        handleResult(result);
    };

    const handleClickNext = () => {
        switch (currentStep) {
            case STEPS_ORDER.UPLOAD:
                return handleReadCsv(file);
            case STEPS_ORDER.MATCH_COLUMNS:
                return handleSubmit(handleMatchColumns)();
            case STEPS_ORDER.REVIEW:
                return handleClickImport();
            default:
                return;
        }
    };

    const handleClickBack = () => {
        setCurrentStepError(null);

        if (currentStep === STEPS_ORDER.MATCH_COLUMNS) reset();
        if (currentStep !== STEPS_ORDER.UPLOAD)
            return setCurrentStep(currentStep - 1);

        resetFileData();
        if (onCancel) onCancel();
    };

    const isUploadStep = currentStep === STEPS_ORDER.UPLOAD;

    return (
        <>
            <StepperNavigation
                currentStep={currentStep}
                setCurrentStep={setCurrentStep}
                setCurrentStepError={setCurrentStepError}
            />

            <Box my={2}>
                <StepContent
                    columnOptions={columnOptions}
                    columns={columns}
                    control={control}
                    currentStep={currentStep}
                    file={file}
                    getValues={getValues}
                    matchedData={matchedData}
                    onChangeColumnsMatch={() => onChangeColumnsMatch(data)}
                    onFileChange={onFileChange}
                />
            </Box>

            <StepError error={currentStepError} />

            <BottomActions>
                <Box
                    display="flex"
                    flexDirection={{ xs: 'column', sm: 'row' }}
                    gap={2}
                    mt={2}
                    width="100%"
                >
                    {onClickDownloadExampleFile && isUploadStep && (
                        <Button
                            color="secondary"
                            onClick={onClickDownloadExampleFile}
                            startIcon={<DownloadIcon />}
                            sx={{
                                order: { xs: 3, sm: 1 },
                                textTransform: 'none',
                            }}
                            variant="text"
                        >
                            {downloadExampleText}
                        </Button>
                    )}

                    <Button
                        color="secondary"
                        onClick={handleClickBack}
                        sx={{ ml: { xs: 0, sm: 'auto' }, order: 2 }}
                        variant="outlined"
                    >
                        {isUploadStep ? t('Cancel') : t('Back')}
                    </Button>

                    <Button
                        disabled={!file}
                        onClick={handleClickNext}
                        startIcon={currentStepError && <WarningIcon />}
                        sx={{ order: { xs: 1, sm: 3 } }}
                    >
                        {currentStep === STEPS_ORDER.REVIEW
                            ? t('Save')
                            : t('Next')}
                    </Button>
                </Box>
            </BottomActions>
        </>
    );
};

Content.propTypes = {
    columns: PropTypes.array,
    downloadExampleText: PropTypes.string,
    initialFile: PropTypes.object,
    onCancel: PropTypes.func,
    onClickDownloadExampleFile: PropTypes.func,
    onImport: PropTypes.func,
    returnFormattedData: PropTypes.bool,
};

export default Content;
