import React, { useEffect } from 'react';

import { useTheme } from '@mui/material';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Box, Divider, Typography } from 'sunwise-ui';

import { ReactHookFormSelect2 } from 'common/components/form/bootstrap';
import { IconTemplate } from 'common/components/icons';
import yupResolver from 'common/utils/yupResolver';

import * as selectors from '../selectors';
import validate from '../validate';

import GenerateButton from './GenerateButton';

const FormContainer = ({
    canModify,
    creditId,
    handleClickRefresh,
    initialValues,
    isEditingProposal,
    isGenerated,
    isLoading,
    offerDetails,
    proposalId,
    reverseBtn,
    templates,
}) => {
    const theme = useTheme();
    const { t } = useTranslation();

    const { control, handleSubmit, reset } = useForm({
        defaultValues: initialValues,
        resolver: yupResolver(validate),
    });

    useEffect(() => reset(initialValues), [initialValues]);

    return (
        <form>
            <Box
                display="flex"
                sx={{
                    width: '100%',
                    flexDirection: reverseBtn ? 'column-reverse' : 'column',
                }}
            >
                <GenerateButton
                    canModify={canModify}
                    creditId={creditId}
                    handleClickRefresh={handleClickRefresh}
                    handleSubmit={handleSubmit}
                    isEditingProposal={isEditingProposal}
                    isGenerated={isGenerated}
                    isLoading={isLoading}
                    offerDetails={offerDetails}
                    proposalId={proposalId}
                />
                <Box sx={{ width: '100%' }}>
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            p: 1,
                        }}
                    >
                        <IconTemplate color={theme.palette.primary.main} />
                        <Typography variant="body2" ml={1} fontWeight="bold">
                            {t('Template')}
                        </Typography>
                    </Box>
                    <Divider />
                    <Box p={1}>
                        <ReactHookFormSelect2
                            control={control}
                            disabled={isLoading || !canModify || isGenerated}
                            isClearable={false}
                            name="custom_offer_template"
                            options={templates}
                            placeholder={t('Select')}
                        />
                    </Box>
                </Box>
            </Box>
        </form>
    );
};

const mapStateToProps = createStructuredSelector({
    initialValues: selectors.getInitialValues,
});

FormContainer.propTypes = {
    canModify: PropTypes.bool,
    creditId: PropTypes.string,
    handleClickRefresh: PropTypes.func,
    initialValues: PropTypes.object,
    isEditingProposal: PropTypes.bool,
    isGenerated: PropTypes.bool,
    isLoading: PropTypes.bool,
    offerDetails: PropTypes.object,
    proposalId: PropTypes.string,
    reverseBtn: PropTypes.bool,
    templates: PropTypes.array,
};

export default connect(mapStateToProps, null)(FormContainer);
