import React from 'react';

import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import {
    FormControl,
    FormControlLabel,
    FormHelperText,
    Checkbox,
} from 'sunwise-ui';

const ReactHookFormCheck = ({
    color,
    control,
    disabled,
    id,
    label,
    labelPlacement,
    name,
    onChange,
    sxFormControl,
    sxFormLabel,
}) => {
    const onChangeAction = (e, onChangeField) => {
        onChangeField(e);
        if (onChange) onChange(e.target.checked);
    };

    return (
        <Controller
            control={control}
            name={name}
            render={({
                field: { value, onChange: onChangeField },
                fieldState: { error },
            }) => (
                <FormControl error={Boolean(error)} sx={sxFormControl}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                color={color}
                                checked={Boolean(value)}
                                disabled={disabled}
                                id={id}
                                onChange={(e) =>
                                    onChangeAction(e, onChangeField)
                                }
                            />
                        }
                        label={label}
                        labelPlacement={labelPlacement}
                        sx={{ margin: !label ?? 0, ...sxFormLabel }}
                    />

                    {Boolean(error) && (
                        <FormHelperText type="error">
                            {error?.message}
                        </FormHelperText>
                    )}
                </FormControl>
            )}
        />
    );
};

ReactHookFormCheck.defaultProps = {
    color: 'primary',
};

ReactHookFormCheck.propTypes = {
    color: PropTypes.string,
    control: PropTypes.object,
    disabled: PropTypes.bool,
    id: PropTypes.string,
    label: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    labelPlacement: PropTypes.string,
    name: PropTypes.string,
    onChange: PropTypes.func,
    sxFormControl: PropTypes.object,
    sxFormLabel: PropTypes.object,
};

export default ReactHookFormCheck;
