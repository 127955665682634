import { push } from 'connected-react-router';
import i18next from 'i18next';

import alerts from 'common/modules/alerts';

export default (offerDetailsPattern) => (dispatch) => {
    const { contact, project_id } = offerDetailsPattern;

    const dataAlert = {
        confirmText: i18next.t('Update'),
        title: i18next.t('Warning'),
        messages: [
            i18next.t(
                'The project does not have a Consumption Profile to carry out the Time shifting simulation'
            ),
        ],
        onSuccess: () =>
            dispatch(
                push(
                    `/record/${contact?.id}?tab=projects&project=${project_id}&view=proposals&type=proposals&open_consumption_modal=true`
                )
            ),
        type: alerts.ALERT_TYPE_CONFIRM,
        variant: 'warning',
    };
    dispatch(alerts.actions.show(dataAlert));
};
