import { connectRouter } from 'connected-react-router';
import { combineReducers } from 'redux';
import { reducer as templatesReducer } from 'sunwise-template-core';

import alerts from 'common/modules/alerts';
import chatView from 'common/modules/chatView';
import commentArea from 'common/modules/commentArea';
import customColumns from 'common/modules/customColumns';
import fileUploader from 'common/modules/fileUploader';
import imageUploader from 'common/modules/imageUploader';
import lisaFiles from 'common/modules/lisaFiles';
import locationForm from 'common/modules/locationForm';
import managePassword from 'common/modules/managePassword';
import masterButton from 'common/modules/masterButton';
import multiBranches from 'common/modules/multiBranches';
import productDatasheets from 'common/modules/productDatasheets';
import proposalIndicators from 'common/modules/proposalIndicators';
import releaseNotification from 'common/modules/releaseNotification';
import zap from 'common/modules/zap';
import orm from 'common/orm';
import createReducer from 'common/orm/createReducer';

import afterSalesSettings from '..//afterSalesSettings';
import accessoriesConfiguration from '../accessoriesConfiguration';
import additionalConfiguration from '../additionalConfiguration';
import afterSalesConsumptionHistory from '../afterSalesConsumptionHistory';
import afterSalesMeasurementSource from '../afterSalesMeasurementSource';
import afterSalesMonitoring from '../afterSalesMonitoring';
import aftersalesProjectsStatus from '../afterSalesProjectsStatus';
import alliances from '../alliances';
import branchOffices from '../branchOffices';
import companyBranding from '../companyBranding';
import companyGeneral from '../companyGeneral';
import companyUsers from '../companyUsers';
import consumptionProfiles from '../consumptionProfiles';
import contact from '../contact';
import contactForm from '../contactForm';
import createAccount from '../createAccount';
import createProjectModal from '../createProjectModal';
import credentialIntegrations from '../credentialIntegrations';
import creditBureauCard from '../creditBureauCard';
import dashboard from '../dashboard';
import documentContactForm from '../documentContactForm';
import editProposalCommentModal from '../editProposalCommentModal';
import editTemplateView from '../editTemplateView';
import energyBackupConfiguration from '../energyBackupConfiguration';
import energyBackupSetup from '../energyBackupSetup';
import equipmentCompatibility from '../equipmentCompatibility';
import financialProduct from '../financialProduct';
import followUps from '../followUps';
import forgotPassword from '../forgotPassword';
import funnelFinancing from '../funnelFinancing';
import funnelProjects from '../funnelProjects';
import importConsumptionsModal from '../importConsumptionsModal';
import invertersConfiguration from '../invertersConfiguration';
import listProjects from '../listProjects';
import listSettings from '../listSettings';
import login from '../login';
import monitoringDashboard from '../monitoringDashboard';
import multiCurrency from '../multiCurrency';
import multiPanelLayout from '../multiPanelLayout';
import multiTemplateReview from '../multiTemplateReview';
import notifications from '../notifications';
import notificationsSettings from '../notificationsSettings';
import onboardingWizard from '../onboardingWizard';
import panelConfiguration from '../panelConfiguration';
import planConfiguration from '../planConfiguration';
import powerStations from '../powerStations';
import profile from '../profile';
import profileConfiguration from '../profileConfiguration';
import profileInvoice from '../profileInvoice';
import profileSecurityPermissions from '../profileSecurityPermissions';
import project from '../project';
import projectCommercialOfferTable from '../projectCommercialOfferTable';
import projectConsumptionCard from '../projectConsumptionCard';
import projectConsumptionsModal from '../projectConsumptionsModal';
import projectDocument from '../projectDocument';
import projectLocation from '../projectLocation';
import projects from '../projects';
import proposalGenerator from '../proposalGenerator';
import proposalGeneratorAccessoryTable from '../proposalGeneratorAccessoryTable';
import proposalGeneratorAdditionalTable from '../proposalGeneratorAdditionalTable';
import proposalGeneratorAdvancedConsumptionSettings from '../proposalGeneratorAdvancedConsumptionSettings';
import proposalGeneratorAdvancedGenerationSettings from '../proposalGeneratorAdvancedGenerationSettings';
import proposalGeneratorAdvancedTimeShiftSettings from '../proposalGeneratorAdvancedTimeShiftSettings';
import proposalGeneratorConfigurationModal from '../proposalGeneratorConfigurationModal';
import proposalGeneratorConsumptionAnalysisVsGeneration from '../proposalGeneratorConsumptionAnalysisVsGeneration';
import proposalGeneratorEnergyBackup from '../proposalGeneratorEnergyBackup';
import proposalGeneratorEnergySaving from '../proposalGeneratorEnergySaving';
import proposalGeneratorEnergyStorageTable from '../proposalGeneratorEnergyStorageTable';
import proposalGeneratorFinancingAndInsurance from '../proposalGeneratorFinancingAndInsurance';
import proposalGeneratorInverterTable from '../proposalGeneratorInverterTable';
import proposalGeneratorOneColumn from '../proposalGeneratorOneColumn';
import proposalGeneratorPanelsLayout from '../proposalGeneratorPanelsLayout';
import proposalGeneratorPanelTable from '../proposalGeneratorPanelTable';
import proposalGeneratorQuote from '../proposalGeneratorQuote';
import proposalGeneratorStructureTable from '../proposalGeneratorStructureTable';
import proposalGeneratorTaxesTable from '../proposalGeneratorTaxesTable';
import proposalGeneratorTemplate from '../proposalGeneratorTemplate';
import proposalGeneratorWorkforceTable from '../proposalGeneratorWorkforceTable';
import proposalReviewPro from '../proposalReviewPro';
import proposals from '../proposals';
import proposalSummaryTable from '../proposalSummaryTable';
import rateAlerts from '../rateAlerts';
import rates from '../rates';
import record from '../record';
import reportsReportList from '../reportList';
import reportPreview from '../reportPreview';
import reportReviewPro from '../reportReviewPro';
import reportTemplateReview from '../reportTemplateReview';
import resetPassword from '../resetPassword';
import rolesConfiguration from '../rolesConfiguration';
import selectBillingPlan from '../selectBillingPlan';
import solarGeneration from '../solarGeneration';
import structureConfiguration from '../structureConfiguration';
import summaryReview from '../summaryReview';
import summaryTemplateReview from '../summaryTemplateReview';
import supplierIntegrations from '../supplierIntegrations';
import supportContactForm from '../supportContactForm';
import taxesConfiguration from '../taxesConfiguration';
import templateLibrary from '../templateLibrary';
import templateRender from '../templateRender';
import timeShiftingConfiguration from '../timeShiftingConfiguration';
import trackingFormModal from '../trackingFormModal';
import trackings from '../trackings';
import webIntegrations from '../webIntegrations';
import integrationWeb from '../webIntegrations/web';
import workforceConfiguration from '../workforceConfiguration';

export default (history) =>
    combineReducers({
        [accessoriesConfiguration.NAME]: accessoriesConfiguration.reducer,
        [additionalConfiguration.NAME]: additionalConfiguration.reducer,
        [afterSalesConsumptionHistory.NAME]:
            afterSalesConsumptionHistory.reducer,
        [afterSalesMeasurementSource.NAME]: afterSalesMeasurementSource.reducer,
        [afterSalesMonitoring.NAME]: afterSalesMonitoring.reducer,
        [afterSalesSettings.NAME]: afterSalesSettings.reducer,
        [aftersalesProjectsStatus.NAME]: aftersalesProjectsStatus.reducer,
        [alerts.NAME]: alerts.reducer,
        [alliances.NAME]: alliances.reducer,
        [branchOffices.NAME]: branchOffices.reducer,
        [chatView.NAME]: chatView.reducer,
        [commentArea.NAME]: commentArea.reducer,
        [customColumns.NAME]: customColumns.reducer,
        [companyBranding.NAME]: companyBranding.reducer,
        [companyGeneral.NAME]: companyGeneral.reducer,
        [companyUsers.NAME]: companyUsers.reducer,
        [consumptionProfiles.NAME]: consumptionProfiles.reducer,
        [contact.NAME]: contact.reducer,
        [contactForm.NAME]: contactForm.reducer,
        [createAccount.NAME]: createAccount.reducer,
        [createProjectModal.NAME]: createProjectModal.reducer,
        [credentialIntegrations.NAME]: credentialIntegrations.reducer,
        [creditBureauCard.NAME]: creditBureauCard.reducer,
        [dashboard.NAME]: dashboard.reducer,
        [documentContactForm.NAME]: documentContactForm.reducer,
        [editProposalCommentModal.NAME]: editProposalCommentModal.reducer,
        [editTemplateView.NAME]: editTemplateView.reducer,
        [energyBackupConfiguration.NAME]: energyBackupConfiguration.reducer,
        [energyBackupSetup.NAME]: energyBackupSetup.reducer,
        [equipmentCompatibility.NAME]: equipmentCompatibility.reducer,
        [fileUploader.NAME]: fileUploader.reducer,
        [financialProduct.NAME]: financialProduct.reducer,
        [followUps.NAME]: followUps.reducer,
        [forgotPassword.NAME]: forgotPassword.reducer,
        [funnelFinancing.NAME]: funnelFinancing.reducer,
        [funnelProjects.NAME]: funnelProjects.reducer,
        [imageUploader.NAME]: imageUploader.reducer,
        [importConsumptionsModal.NAME]: importConsumptionsModal.reducer,
        [integrationWeb.NAME]: integrationWeb.reducer,
        [invertersConfiguration.NAME]: invertersConfiguration.reducer,
        [lisaFiles.NAME]: lisaFiles.reducer,
        [listProjects.NAME]: listProjects.reducer,
        [listSettings.NAME]: listSettings.reducer,
        [locationForm.NAME]: locationForm.reducer,
        [login.NAME]: login.reducer,
        [managePassword.NAME]: managePassword.reducer,
        [masterButton.NAME]: masterButton.reducer,
        [monitoringDashboard.NAME]: monitoringDashboard.reducer,
        [multiBranches.NAME]: multiBranches.reducer,
        [multiCurrency.NAME]: multiCurrency.reducer,
        [multiPanelLayout.NAME]: multiPanelLayout.reducer,
        [multiTemplateReview.NAME]: multiTemplateReview.reducer,
        [notifications.NAME]: notifications.reducer,
        [notificationsSettings.NAME]: notificationsSettings.reducer,
        [onboardingWizard.NAME]: onboardingWizard.reducer,
        [panelConfiguration.NAME]: panelConfiguration.reducer,
        [planConfiguration.NAME]: planConfiguration.reducer,
        [powerStations.NAME]: powerStations.reducer,
        [productDatasheets.NAME]: productDatasheets.reducer,
        [profile.NAME]: profile.reducer,
        [profileConfiguration.NAME]: profileConfiguration.reducer,
        [profileInvoice.NAME]: profileInvoice.reducer,
        [profileSecurityPermissions.NAME]: profileSecurityPermissions.reducer,
        [project.NAME]: project.reducer,
        [projectCommercialOfferTable.NAME]: projectCommercialOfferTable.reducer,
        [projectConsumptionCard.NAME]: projectConsumptionCard.reducer,
        [projectConsumptionsModal.NAME]: projectConsumptionsModal.reducer,
        [projectDocument.NAME]: projectDocument.reducer,
        [projectLocation.NAME]: projectLocation.reducer,
        [projects.NAME]: projects.reducer,
        [proposalGenerator.NAME]: proposalGenerator.reducer,
        [proposalGeneratorAccessoryTable.NAME]:
            proposalGeneratorAccessoryTable.reducer,
        [proposalGeneratorAdditionalTable.NAME]:
            proposalGeneratorAdditionalTable.reducer,
        [proposalGeneratorAdvancedConsumptionSettings.NAME]:
            proposalGeneratorAdvancedConsumptionSettings.reducer,
        [proposalGeneratorAdvancedGenerationSettings.NAME]:
            proposalGeneratorAdvancedGenerationSettings.reducer,
        [proposalGeneratorAdvancedTimeShiftSettings.NAME]:
            proposalGeneratorAdvancedTimeShiftSettings.reducer,
        [proposalGeneratorConfigurationModal.NAME]:
            proposalGeneratorConfigurationModal.reducer,
        [proposalGeneratorConsumptionAnalysisVsGeneration.NAME]:
            proposalGeneratorConsumptionAnalysisVsGeneration.reducer,
        [proposalGeneratorEnergyBackup.NAME]:
            proposalGeneratorEnergyBackup.reducer,
        [proposalGeneratorEnergySaving.NAME]:
            proposalGeneratorEnergySaving.reducer,
        [proposalGeneratorEnergyStorageTable.NAME]:
            proposalGeneratorEnergyStorageTable.reducer,
        [proposalGeneratorFinancingAndInsurance.NAME]:
            proposalGeneratorFinancingAndInsurance.reducer,
        [proposalGeneratorInverterTable.NAME]:
            proposalGeneratorInverterTable.reducer,
        [proposalGeneratorOneColumn.NAME]: proposalGeneratorOneColumn.reducer,
        [proposalGeneratorPanelTable.NAME]: proposalGeneratorPanelTable.reducer,
        [proposalGeneratorPanelsLayout.NAME]:
            proposalGeneratorPanelsLayout.reducer,
        [proposalGeneratorQuote.NAME]: proposalGeneratorQuote.reducer,
        [proposalGeneratorStructureTable.NAME]:
            proposalGeneratorStructureTable.reducer,
        [proposalGeneratorTaxesTable.NAME]: proposalGeneratorTaxesTable.reducer,
        [proposalGeneratorTemplate.NAME]: proposalGeneratorTemplate.reducer,
        [proposalGeneratorWorkforceTable.NAME]:
            proposalGeneratorWorkforceTable.reducer,
        [proposalIndicators.NAME]: proposalIndicators.reducer,
        [proposalReviewPro.NAME]: proposalReviewPro.reducer,
        [proposals.NAME]: proposals.reducer,
        [proposalSummaryTable.NAME]: proposalSummaryTable.reducer,
        [rateAlerts.NAME]: rateAlerts.reducer,
        [rates.NAME]: rates.reducer,
        [record.NAME]: record.reducer,
        [releaseNotification.NAME]: releaseNotification.reducer,
        [reportPreview.NAME]: reportPreview.reducer,
        [reportReviewPro.NAME]: reportReviewPro.reducer,
        [reportTemplateReview.NAME]: reportTemplateReview.reducer,
        [reportsReportList.NAME]: reportsReportList.reducer,
        [resetPassword.NAME]: resetPassword.reducer,
        [rolesConfiguration.NAME]: rolesConfiguration.reducer,
        [selectBillingPlan.NAME]: selectBillingPlan.reducer,
        [solarGeneration.NAME]: solarGeneration.reducer,
        [structureConfiguration.NAME]: structureConfiguration.reducer,
        [summaryReview.NAME]: summaryReview.reducer,
        [summaryTemplateReview.NAME]: summaryTemplateReview.reducer,
        [supplierIntegrations.NAME]: supplierIntegrations.reducer,
        [supportContactForm.NAME]: supportContactForm.reducer,
        [taxesConfiguration.NAME]: taxesConfiguration.reducer,
        [templateLibrary.NAME]: templateLibrary.reducer,
        [templateRender.NAME]: templateRender.reducer,
        [timeShiftingConfiguration.NAME]: timeShiftingConfiguration.reducer,
        [trackingFormModal.NAME]: trackingFormModal.reducer,
        [trackings.NAME]: trackings.reducer,
        [webIntegrations.NAME]: webIntegrations.reducer,
        [workforceConfiguration.NAME]: workforceConfiguration.reducer,
        [zap.NAME]: zap.reducer,
        entities: createReducer(orm),
        router: connectRouter(history),
        sunwiseTemplateCore: templatesReducer,
    });
