import React from 'react';

import { styled } from '@mui/material/styles';
import isNil from 'lodash/isNil';
import PropTypes from 'prop-types';

const Container = styled('div')`
    align-items: center;
    display: flex;
`;

const Dot = styled('div')`
    background-color: ${({ background }) => background};
    border-radius: 100%;
    height: 16px;
    margin-right: 10px;
    width: 16px;
`;

const StyledImage = styled('img')`
    margin-right: 10px;
`;

const Label = styled('span')`
    color: ${({ color }) => color};
    font-size: ${({ fontSize }) => fontSize};
    font-weight: ${({ fontWeight }) => fontWeight};

    ${({ separatorRight }) =>
        separatorRight &&
        `
            border-right: 1px solid #eff1fb;
            padding-right: 15px;
        `}

    ${({ separatorLeft }) =>
        separatorLeft &&
        `
            border-left: 1px solid #eff1fb;
            padding-left: 15px;
        `}

    ${({ variant }) =>
        variant === 'blue' &&
        `
            color: #008DFF;
            font-weight: 600;
        `}


    ${({ variant }) =>
        variant === 'bold' &&
        `
            font-size: 14px;
            color: #202253;
            font-weight: 600;
        `}
`;

const getCircleColor = (color) => {
    switch (color) {
        case 'bittersweet':
            return '#FA6968';
        case 'blue':
            return ' #008DFF';
        case 'green':
            return '#09E1A1';
        case 'orange':
            return '#FF9A00';
        case 'purple':
            return '#DE09E1';
        default:
            return '#848BAB';
    }
};

const TextVariant = ({
    color = '#848bab',
    dot,
    fontSize = '13px',
    fontWeight = '500',
    icon,
    separatorLeft,
    separatorRight,
    text,
    variant,
}) => {
    const renderIcon = () => {
        if (isNil(icon)) return null;
        return <StyledImage src={icon} alt="" />;
    };

    const renderDot = () => {
        if (isNil(dot)) return null;
        return <Dot background={getCircleColor(dot)} />;
    };

    return (
        <Container dot={dot}>
            {renderDot()}
            {renderIcon()}
            <Label
                color={color}
                fontSize={fontSize}
                fontWeight={fontWeight}
                separatorLeft={separatorLeft}
                separatorRight={separatorRight}
                variant={variant}
            >
                {text}
            </Label>
        </Container>
    );
};

TextVariant.propTypes = {
    color: PropTypes.string,
    dot: PropTypes.string,
    fontSize: PropTypes.string,
    fontWeight: PropTypes.string,
    icon: PropTypes.string,
    separatorLeft: PropTypes.bool,
    separatorRight: PropTypes.bool,
    text: PropTypes.string,
    variant: PropTypes.string,
};

export default TextVariant;
