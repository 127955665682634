import get from 'lodash/get';

import { createItem, updateItem } from 'common/api/v1/branchOffices';
import fetchMultiBranches from 'common/modules/multiBranches/actions/fetchBranches';
import { showReponseErrorsAsAlert } from 'common/utils/helpers';
import showToast from 'common/utils/showToast';

import {
    SAVE_ITEM,
    SAVE_ITEM_SUCCESS,
    SAVE_ITEM_FAILURE,
} from '../actionTypes';
import { branchOfficesActions } from '../reducer';

import filterItems from './filterItems';
import resetForm from './resetForm';
import toggleModalProduct from './toggleModalProduct';

export default (values) => (dispatch) => {
    let newValues = {
        business_name: values.business_name,
        company_size: values.company_size,
        contact_cellphone: values.contact_cellphone,
        contact_email: values.contact_email,
        contact_name: values.contact_name,
        contact_telephone: values.contact_telephone,
        currency: values.currency,
        iva: values.iva,
        name: values.name,
        position: JSON.stringify({
            latitude: get(values, 'position.latitude', ''),
            longitude: get(values, 'position.longitude', ''),
            description: values.description,
            city: values.city,
            zip_code: values.cp,
            political_division1: values.political_division1,
        }),
        rfc: values.rfc,
        signatory_position: values.signatory_position,
        web_page: values.web_page,
        wizard_complete: true,
    };

    dispatch(branchOfficesActions[SAVE_ITEM]());

    const save = values.id
        ? () => updateItem(newValues, values.id)
        : () => createItem(newValues);

    save()
        .then((response) => {
            dispatch(branchOfficesActions[SAVE_ITEM_SUCCESS](response.data));
            dispatch(filterItems());
            dispatch(toggleModalProduct());
            dispatch(resetForm());

            showToast();
            dispatch(fetchMultiBranches());
        })
        .catch((error) => {
            dispatch(
                branchOfficesActions[SAVE_ITEM_FAILURE](
                    error?.response?.data?.errors
                )
            );
            showReponseErrorsAsAlert(dispatch, error?.response);
        });
};
