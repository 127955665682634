import { fetchAlerts } from 'common/api/v2/rateAlerts';
import { showReponseErrorsAsAlert } from 'common/utils/helpers';

import {
    FETCH_ALERTS,
    FETCH_ALERTS_FAILURE,
    FETCH_ALERTS_SUCCESS,
} from '../actionTypes';
import { rateAlertsActions } from '../reducer';

export default (rate) => (dispatch) => {
    if (!rate) return;

    dispatch(rateAlertsActions[FETCH_ALERTS]());

    let params = {};

    if (rate.isCertified) params.rate = rate.id;
    else params.custom_rate = rate.id;

    fetchAlerts(params)
        .then((response) =>
            dispatch(
                rateAlertsActions[FETCH_ALERTS_SUCCESS](response?.data?.data)
            )
        )
        .catch((error) => {
            dispatch(
                rateAlertsActions[FETCH_ALERTS_FAILURE](
                    error?.response?.data?.errors
                )
            );

            showReponseErrorsAsAlert(dispatch, error?.response);
        });
};
