import i18next from 'i18next';

import { getTemplateCSV } from 'common/api/v2/rateSummaryCSV';
import showToast from 'common/utils/showToast';

import {
    DOWNLOAD_OFFER_HOURLY_CSV,
    DOWNLOAD_OFFER_HOURLY_CSV_FAILURE,
    DOWNLOAD_OFFER_HOURLY_CSV_SUCCESS,
} from '../actionTypes';
import { multiTemplateReviewActions } from '../reducer';

import downloadFile from './downloadFile';

export default (id, name = i18next.t('File')) =>
    (dispatch) => {
        dispatch(multiTemplateReviewActions[DOWNLOAD_OFFER_HOURLY_CSV]());

        getTemplateCSV(id)
            .then((response) => {
                dispatch(
                    multiTemplateReviewActions[
                        DOWNLOAD_OFFER_HOURLY_CSV_SUCCESS
                    ](response.data)
                );
                const url = window.URL.createObjectURL(
                    new Blob([response.data])
                );
                dispatch(downloadFile(name, url, 'csv'));
                showToast({ body: i18next.t('It was created successfully') });
            })
            .catch((error) =>
                dispatch(
                    multiTemplateReviewActions[
                        DOWNLOAD_OFFER_HOURLY_CSV_FAILURE
                    ](error)
                )
            );
    };
