import { createSlice } from '@reduxjs/toolkit';

import {
    FETCH_CLONE_PAGES,
    FETCH_CLONE_PAGES_FAILURE,
    FETCH_CLONE_PAGES_SUCCESS,
    FETCH_CUSTOM_TEMPLATE,
    FETCH_CUSTOM_TEMPLATE_FAILURE,
    FETCH_CUSTOM_TEMPLATE_SUCCESS,
    INITIALIZE,
    INITIALIZE_FAILURE,
    INITIALIZE_SUCCESS,
    RESET,
    SET_CLONE_PAGES,
    SET_CLONE_PAGES_FAILURE,
    SET_CLONE_PAGES_SUCCESS,
    SET_PAGE_SIZE,
    UPDATE_TEMPLATE,
    UPDATE_TEMPLATE_FAILURE,
    UPDATE_TEMPLATE_SUCCESS,
} from './actionTypes';
import { NAME } from './constants';

const INITIAL_STATE = {
    fetchClonePages: { data: [], errors: [], isFetching: true },
    fetchCustomTemplate: { data: null, errors: [], isFetching: true },
    isInitialize: true,
    pageSize: 'A4',
    setClonePages: { errors: [], isFetching: true },
    updateCustomTemplate: { errors: [], isUpdating: false, type: null },
};

const editTemplateViewSlice = createSlice({
    name: NAME,
    initialState: INITIAL_STATE,
    reducers: {
        [FETCH_CUSTOM_TEMPLATE]: (state) => {
            state.fetchCustomTemplate = {
                ...state.fetchCustomTemplate,
                data: null,
                errors: [],
                isFetching: true,
            };
        },
        [FETCH_CUSTOM_TEMPLATE_FAILURE]: (state, action) => {
            state.fetchCustomTemplate = {
                ...state.fetchCustomTemplate,
                errors: action.payload,
                isFetching: false,
            };
        },
        [FETCH_CUSTOM_TEMPLATE_SUCCESS]: (state, action) => {
            state.fetchCustomTemplate = {
                ...state.fetchCustomTemplate,
                data: action.payload,
                isFetching: false,
            };
        },
        [INITIALIZE]: (state) => {
            state.isInitialize = true;
        },
        [INITIALIZE_FAILURE]: (state) => {
            state.isInitialize = false;
        },
        [INITIALIZE_SUCCESS]: (state) => {
            state.isInitialize = false;
        },
        [UPDATE_TEMPLATE]: (state, action) => {
            state.updateCustomTemplate = {
                ...state.updateCustomTemplate,
                errors: [],
                isUpdating: true,
                type: action.payload,
            };
        },
        [UPDATE_TEMPLATE_FAILURE]: (state, action) => {
            state.updateCustomTemplate = {
                ...state.updateCustomTemplate,
                errors: action.payload,
                isUpdating: false,
                type: null,
            };
        },
        [UPDATE_TEMPLATE_SUCCESS]: (state) => {
            state.updateCustomTemplate = {
                ...state.updateCustomTemplate,
                isUpdating: false,
                type: null,
            };
        },
        [FETCH_CLONE_PAGES]: (state) => {
            state.fetchClonePages = {
                ...state.fetchClonePages,
                errors: [],
                isFetching: true,
            };
        },
        [FETCH_CLONE_PAGES_FAILURE]: (state, action) => {
            state.fetchClonePages = {
                ...state.fetchClonePages,
                errors: action.payload,
                isFetching: false,
            };
        },
        [FETCH_CLONE_PAGES_SUCCESS]: (state, action) => {
            state.fetchClonePages = {
                ...state.fetchClonePages,
                data: action.payload,
                isFetching: false,
            };
        },
        [RESET]: () => INITIAL_STATE,
        [SET_CLONE_PAGES]: (state) => {
            state.setClonePages = {
                ...state.setClonePages,
                errors: [],
                isFetching: true,
            };
        },
        [SET_CLONE_PAGES_FAILURE]: (state, action) => {
            state.setClonePages = {
                ...state.setClonePages,
                errors: action.payload,
                isFetching: false,
            };
        },
        [SET_CLONE_PAGES_SUCCESS]: (state) => {
            state.setClonePages.isFetching = false;
        },

        [SET_PAGE_SIZE]: (state, action) => {
            state.pageSize = action.payload;
        },
    },
});

export const editTemplateViewActions = editTemplateViewSlice.actions;

export default editTemplateViewSlice.reducer;
