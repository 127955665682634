import React from 'react';

import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';
import Chart from 'react-apexcharts';
import { useTranslation } from 'react-i18next';
import { Box, ToggleButton, ToggleButtonGroup } from 'sunwise-ui';

import KeyIndicator from 'common/components/KeyIndicator';
import { useSetChart } from 'common/hooks';
import { numberFormat } from 'common/utils/helpers';

import { getEconomicChartSettings } from '../helpers';

import EnergyChart from './EnergyChart';

const ChartComponent = ({
    countryCurrencyIso,
    countryCurrencyLocale,
    countryCurrencySymbol,
    infoPriceConsumtion,
    monthskw,
    monthskwh,
}) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const { value: chartId, setChartValue } = useSetChart('chartEnergy');

    const categories = monthskwh.map((month) => month.label.toUpperCase());

    const chartConfigEconomic = getEconomicChartSettings(
        infoPriceConsumtion,
        categories,
        countryCurrencyIso,
        countryCurrencyLocale,
        countryCurrencySymbol,
        monthskwh
    );

    const getAnnualCost = (infoPriceConsumtion) => {
        return infoPriceConsumtion.reduce((acc, cur) => {
            let _acc = 0;

            for (const [, value] of Object.entries(cur.value)) {
                _acc += value;
            }

            return acc + _acc;
        }, 0);
    };

    return (
        <>
            <ToggleButtonGroup
                color="secondary"
                exclusive
                fullWidth
                onChange={(e) => setChartValue(e.target.value)}
                size="small"
                sx={{ mb: 2 }}
                value={chartId}
            >
                <ToggleButton value={'chartEnergy'}>
                    {t('Energetic')}
                </ToggleButton>
                {chartConfigEconomic?.series?.length > 0 && (
                    <ToggleButton value={'chartEconomic'}>
                        {t('Economical')}
                    </ToggleButton>
                )}
            </ToggleButtonGroup>

            <Box mt={2} sx={{ width: '100%' }}>
                {chartId === 'chartEnergy' && (
                    <EnergyChart
                        countryCurrencyLocale={countryCurrencyLocale}
                        monthskw={monthskw}
                        monthskwh={monthskwh}
                    />
                )}

                {chartId === 'chartEconomic' && (
                    <>
                        <Chart
                            height="250"
                            options={{
                                ...chartConfigEconomic.options,
                                theme: { mode: theme.palette.mode },
                            }}
                            series={chartConfigEconomic.series}
                            type="bar"
                        />

                        <KeyIndicator
                            svgIcon={<AttachMoneyIcon />}
                            title={t('Annual cost')}
                            value={numberFormat(
                                getAnnualCost(infoPriceConsumtion),
                                {
                                    currency: countryCurrencyIso,
                                    decimals: 2,
                                    locale: countryCurrencyLocale,
                                    style: 'currency',
                                }
                            )}
                        />
                    </>
                )}
            </Box>
        </>
    );
};

ChartComponent.propTypes = {
    countryCurrencyIso: PropTypes.string,
    countryCurrencyLocale: PropTypes.string,
    countryCurrencySymbol: PropTypes.string,
    infoPriceConsumtion: PropTypes.array,
    monthskw: PropTypes.array,
    monthskwh: PropTypes.array,
};

export default ChartComponent;
