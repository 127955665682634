import { createSelector } from 'reselect';

import { NAME } from './constants';

export const getModel = (state) => state[NAME];

export const getInitialValues = createSelector(
    getModel,
    (model) => model.initialValues
);

export const getFetchDataModel = createSelector(
    getModel,
    (model) => model.fetchData
);

export const getFetchDataData = createSelector(
    getFetchDataModel,
    (model) => model.data
);

export const getFetchDataItems = createSelector(
    getFetchDataData,
    (data) => data.items || []
);

export const getFetchDataPagination = createSelector(
    getFetchDataData,
    (data) => data.pagination
);

export const getFetchDataYearsForSelect = createSelector(
    getFetchDataData,
    (data) => {
        const options = data?.years?.map((year) => ({
            label: `${year}`,
            value: `${year}`,
        }));

        return [{ label: '', options }];
    }
);

export const getFetchDataErrors = createSelector(
    getFetchDataModel,
    (model) => model.errors
);

export const getFetchDataIsLoading = createSelector(
    getFetchDataModel,
    (model) => model.isLoading
);

export const getIsModalFormOpen = createSelector(
    getModel,
    (model) => model.isModalFormOpen
);

export const getSaveDataModel = createSelector(
    getModel,
    (model) => model.saveData
);

export const getIsSavingData = createSelector(
    getSaveDataModel,
    (model) => model.isLoading
);
