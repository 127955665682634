import React, { useEffect, useState } from 'react';

import i18next from 'i18next';
import PropTypes from 'prop-types';
import { Button, UiDialog as Dialog } from 'sunwise-ui';

import Content from './components/Content';

const Container = ({
    buttonProps = {},
    buttonText,
    columns,
    downloadExampleText = i18next.t('Download the sample CSV'),
    initialFile,
    onCancel,
    onClickDownloadExampleFile,
    onImport,
    openModal,
    returnFormattedData = false,
    size = 'lg',
    title = i18next.t('Import CSV'),
    visible = true,
}) => {
    const [modalIsOpen, setModalIsOpen] = useState(false);

    useEffect(() => {
        if (initialFile && typeof initialFile === 'object')
            setModalIsOpen(true);
    }, [initialFile]);

    useEffect(() => {
        if (openModal) setModalIsOpen(true);
    }, [openModal]);

    const launch = () => setModalIsOpen(true);

    const handleOnImport = (data, config) => {
        if (onImport) onImport(data, config);
        setModalIsOpen(false);
    };

    const handleOnCancel = () => {
        if (onCancel) onCancel();
        setModalIsOpen(false);
    };

    return (
        <>
            {visible && (
                <Button {...buttonProps} onClick={launch}>
                    {buttonText}
                </Button>
            )}

            <Dialog
                onClose={handleOnCancel}
                open={modalIsOpen}
                size={size}
                title={title}
            >
                <Content
                    columns={columns}
                    downloadExampleText={downloadExampleText}
                    initialFile={initialFile}
                    onCancel={handleOnCancel}
                    onClickDownloadExampleFile={onClickDownloadExampleFile}
                    onImport={handleOnImport}
                    returnFormattedData={returnFormattedData}
                />
            </Dialog>
        </>
    );
};

Container.propTypes = {
    buttonProps: PropTypes.object,
    buttonText: PropTypes.string,
    columns: PropTypes.array,
    downloadExampleText: PropTypes.string,
    initialFile: PropTypes.object,
    onCancel: PropTypes.func,
    onClickDownloadExampleFile: PropTypes.func,
    onImport: PropTypes.func,
    openModal: PropTypes.bool,
    returnFormattedData: PropTypes.bool,
    size: PropTypes.string,
    title: PropTypes.string,
    visible: PropTypes.bool,
};

export default Container;
