import React, { useEffect, useState } from 'react';

import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';
import Chart from 'react-apexcharts';

import { getChartOptions } from '../../helpers';

const Generation = ({
    countryCurrencyLocale,
    handleSelectProject,
    seriesData,
    width,
}) => {
    const [chartConfig, setChartConfig] = useState({
        options: {},
        series: [],
    });
    const theme = useTheme();

    useEffect(() => {
        const chartOptions = getChartOptions({
            categories: seriesData?.categories,
            colors: seriesData?.colors,
            countryCurrencyLocale,
            handleSelectProject,
            series: seriesData?.series,
        });
        setChartConfig({ options: chartOptions, series: seriesData?.series });
    }, [seriesData]);

    return (
        <Chart
            options={{
                ...chartConfig.options,
                theme: { mode: theme.palette.mode },
            }}
            series={chartConfig.series || []}
            type="line"
            height="100%"
            width={width}
        />
    );
};

Generation.propTypes = {
    countryCurrencyLocale: PropTypes.string,
    handleSelectProject: PropTypes.func,
    seriesData: PropTypes.object,
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default Generation;
