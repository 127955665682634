import React, { useEffect, useState } from 'react';

import AddchartIcon from '@mui/icons-material/Addchart';
import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';
import Chart from 'react-apexcharts';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Grid, Typography } from 'sunwise-ui';

import AccordionCard from 'common/components/accordions/AccordionCard';

import { CHART_COLORS } from '../constants';
import {
    getChartSettings,
    getChartsInfo,
    getPaymentChartSettings,
} from '../helpers';
import * as selectors from '../selectors';

const CHART_HEIGHT = 250;

const ComparativeCard = ({
    currencyIso,
    currencyLocale,
    currencySymbol,
    historicalInfo,
    isLoading,
    periodConfig,
    proposalInfo,
    proposalInfoIsBimonthly,
}) => {
    const [chartsInfo, setChartsInfo] = useState({
        consumptionSeries: [],
        energySettings: {},
        generationSeries: [],
        paymentSeries: [],
        paymentSettings: {},
    });
    const { t } = useTranslation();
    const theme = useTheme();

    useEffect(() => {
        if (!periodConfig) return;

        const {
            categories,
            consumptionSeries,
            energyDates,
            generationSeries,
            paymentPeriods,
            paymentSeries,
        } = getChartsInfo({
            historicalInfo,
            periodConfig,
            proposalInfo,
            proposalInfoIsBimonthly,
        });

        const commonConfig = {
            categories,
            currencyIso,
            currencyLocale,
            currencySymbol,
            energyDates,
            themeMode: theme?.palette?.mode,
        };

        const energySettings = getChartSettings(commonConfig);
        const paymentSettings = getPaymentChartSettings({
            categories,
            currencyIso,
            currencyLocale,
            paymentPeriods,
            themeMode: theme?.palette?.mode,
        });

        setChartsInfo({
            consumptionSeries,
            energySettings,
            generationSeries,
            paymentSeries,
            paymentSettings,
        });
    }, [historicalInfo, proposalInfo, periodConfig, theme?.palette?.mode]);

    return (
        <AccordionCard
            defaultExpanded
            isLoading={isLoading}
            svgIcon={<AddchartIcon color="primary" />}
            title={t('Comparative')}
        >
            <Grid container>
                <Grid item md={9} xs={18}>
                    <Typography variant="subtitle2">
                        {t('Total consumption')}
                    </Typography>

                    <Chart
                        height={CHART_HEIGHT}
                        key={chartsInfo.energySettings.key + 'consumption'}
                        options={{
                            ...chartsInfo.energySettings.options,
                            colors: CHART_COLORS.CONSUMPTION,
                        }}
                        series={chartsInfo.consumptionSeries}
                        type="bar"
                    />
                </Grid>
                <Grid item md={9} xs={18}>
                    <Typography variant="subtitle2">
                        {t('Total generation')}
                    </Typography>

                    <Chart
                        height={CHART_HEIGHT}
                        key={chartsInfo.energySettings.key + 'generation'}
                        options={{
                            ...chartsInfo.energySettings.options,
                            colors: CHART_COLORS.GENERATION,
                        }}
                        series={chartsInfo.generationSeries}
                        type="bar"
                    />
                </Grid>
                <Grid item xs={18}>
                    <Typography variant="subtitle2">
                        {t('Total cost')}
                    </Typography>

                    <Chart
                        height={CHART_HEIGHT}
                        options={{ ...chartsInfo.paymentSettings.options }}
                        series={chartsInfo.paymentSeries}
                        type="bar"
                    />
                </Grid>
            </Grid>
        </AccordionCard>
    );
};
const mapStateToProps = createStructuredSelector({
    historicalInfo: selectors.getHistoricalInfoData,
    periodConfig: selectors.getPeriodConfigData,
    proposalInfo: selectors.getProjectConfigurationProposalData,
    proposalInfoIsBimonthly: selectors.getProposalInfoIsBimonthly,
});

ComparativeCard.propTypes = {
    currencyIso: PropTypes.string,
    currencyLocale: PropTypes.string,
    currencySymbol: PropTypes.string,
    historicalInfo: PropTypes.array,
    isLoading: PropTypes.bool,
    periodConfig: PropTypes.object,
    proposalInfo: PropTypes.object,
    proposalInfoIsBimonthly: PropTypes.bool,
};

export default connect(mapStateToProps)(ComparativeCard);
