import { getData } from 'common/api/v2/irradiation';

import {
    FETCH_IRRADIATION_DATA,
    FETCH_IRRADIATION_DATA_FAILURE,
    FETCH_IRRADIATION_DATA_SUCCESS,
    INITIALIZE_IRRADIATION_VALUES,
} from '../actionTypes';
import { panelLayoutV2Actions } from '../reducer';

export default (id, values, callback) => (dispatch) => {
    dispatch(panelLayoutV2Actions[FETCH_IRRADIATION_DATA]());

    const newValues = {
        bifaciality: values.bifaciality,
        bifaciality_param: values.bifaciality_param / 100,
        irradation_API: values.source,
        losses: values.losses,
    };

    getData(id, newValues)
        .then((response) => {
            dispatch(
                panelLayoutV2Actions[FETCH_IRRADIATION_DATA_SUCCESS](
                    response.data.data
                )
            );

            const _values = {
                bifaciality: values.bifaciality,
                bifaciality_param: values.bifaciality_param,
                losses: values.losses,
                source: values.source,
            };

            dispatch(
                panelLayoutV2Actions[INITIALIZE_IRRADIATION_VALUES](_values)
            );

            if (callback) callback();
        })
        .catch((error) => {
            dispatch(
                panelLayoutV2Actions[FETCH_IRRADIATION_DATA_FAILURE](error)
            );
        });
};
