import { push } from 'connected-react-router';
import i18next from 'i18next';
import get from 'lodash/get';

import { duplicateOffer } from 'common/api/v1/contactProject';
import { showReponseErrorsAsAlert } from 'common/utils/helpers';
import showToast from 'common/utils/showToast';

import {
    DUPLICATE_OFFER,
    DUPLICATE_OFFER_FAILURE,
    DUPLICATE_OFFER_SUCCESS,
} from '../actionTypes';
import { projectCommOfferTableActions } from '../reducer';

import openDuplicateModal from './openDuplicateModal';

export default (values) => (dispatch) => {
    dispatch(projectCommOfferTableActions[DUPLICATE_OFFER]());

    const newValues = {
        name: values.name,
        type: 0,
    };

    duplicateOffer(values.id, newValues)
        .then((response) => {
            const newProposalId = get(response.data, 'id', null);
            dispatch(projectCommOfferTableActions[DUPLICATE_OFFER_SUCCESS]());
            dispatch(openDuplicateModal(false));
            dispatch(push(`/proposal/${newProposalId}`));
            showToast({ body: i18next.t('Successfully duplicated') });
        })
        .catch((error) => {
            dispatch(
                projectCommOfferTableActions[DUPLICATE_OFFER_FAILURE](
                    error?.response?.data?.errors
                )
            );
            dispatch(openDuplicateModal(false));
            showReponseErrorsAsAlert(dispatch, error?.response);
        });
};
