import React, { useState } from 'react';

import { Campaign, School } from '@mui/icons-material';
import { useTheme } from '@mui/material/styles';
import isNull from 'lodash/isNull';
import PropTypes from 'prop-types';
import { IconButton, AppBar, Box, Link, Toolbar } from 'sunwise-ui';

import { useBreakpoint } from 'common/hooks';
import BranchOfficesNavBarDropdown from 'common/modules/multiBranches/components/BranchOfficesNavBarDropdown';

import AfterSalesProjectsStatus from '../../../modules/afterSalesProjectsStatus';
import masterButton from '../../modules/masterButton';

import DollarPriceItem from './DollarPriceItem';
import Logo from './Logo';
import NotificationButton from './NotificationButton';
import NotificationsDrawer from './NotificationsDrawer';
import ProjectsCounterTooltip from './ProjectsCounterTooltip';

const TopBar = ({
    handleDrawerToggle,
    multiBranchMode,
    notificationsQuantity,
    oppositeCurrency,
    redirect,
    subscription,
}) => {
    const [isOpenDrawer, setIsOpenDrawer] = useState(false);

    const theme = useTheme();
    const breakpoint = useBreakpoint();
    const handleToggleDrawer = (open, callback) => (event) => {
        if (
            event &&
            event.type === 'keydown' &&
            (event.key === 'Tab' || event.key === 'Shift')
        )
            return;
        setIsOpenDrawer(open);
        if (callback) callback();
    };
    const isDarkMode = theme.palette.mode === 'dark';
    const sxIcon = {
        color: isDarkMode ? '#61666b' : '#1f3c53',
    };

    return (
        <>
            <Box sx={{ zIndex: 3 }}>
                <AppBar
                    position={
                        ['xs', 'sm'].includes(breakpoint) ? 'fixed' : 'static'
                    }
                    sx={{
                        background: isDarkMode ? '#1B1C1F' : '#fff',
                        boxShadow: isDarkMode ? 'none' : null,
                        color: isDarkMode ? '#b8bfc5' : '#1f3c53',
                        height: '52px',
                        justifyContent: 'center',
                    }}
                >
                    <Toolbar sx={{ px: { xs: 2 } }}>
                        <IconButton
                            onClick={handleDrawerToggle}
                            sx={{ display: { md: 'none' } }}
                        >
                            <Logo field={'logotype_image'} />
                        </IconButton>

                        <masterButton.Container />

                        <Box sx={{ flexGrow: 1 }} />
                        <DollarPriceItem oppositeCurrency={oppositeCurrency} />
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Link
                                href={`${process.env.REACT_APP_ACADEMY_URL}es/`}
                                target="_blank"
                                sx={{
                                    display: { md: 'initial', xs: 'none' },
                                    p: 1,
                                }}
                            >
                                <School sx={sxIcon} />
                            </Link>
                            <Link
                                href={process.env.REACT_APP_NEW_URL}
                                rel="noopener noreferrer"
                                sx={{
                                    display: { md: 'initial', xs: 'none' },
                                    p: 1,
                                }}
                                target="_blank"
                            >
                                <Campaign sx={sxIcon} />
                            </Link>
                            <ProjectsCounterTooltip
                                isDarkMode={isDarkMode}
                                isHidden={isNull(subscription?.counters)}
                                subscription={subscription}
                                sxIcon={sxIcon}
                            />

                            <AfterSalesProjectsStatus.Container
                                sxIcon={sxIcon}
                            />

                            <NotificationButton
                                handleToggleDrawer={handleToggleDrawer}
                                quantity={notificationsQuantity}
                                sxIcon={sxIcon}
                            />
                            <BranchOfficesNavBarDropdown
                                multiBranchMode={multiBranchMode}
                            />
                        </Box>
                    </Toolbar>
                </AppBar>
            </Box>

            <NotificationsDrawer
                handleToggleDrawer={handleToggleDrawer}
                isOpenDrawer={isOpenDrawer}
                redirect={redirect}
                setIsOpenDrawer={setIsOpenDrawer}
            />
        </>
    );
};

TopBar.propTypes = {
    handleDrawerToggle: PropTypes.func,
    multiBranchMode: PropTypes.bool,
    notificationsQuantity: PropTypes.number,
    oppositeCurrency: PropTypes.object,
    redirect: PropTypes.func,
    subscription: PropTypes.object,
};

export default TopBar;
