import i18next from 'i18next';
import { object, string, ref } from 'yup';

import getValidationTexts from 'common/utils/getValidationTexts';

export default () => {
    const { minimumNumberCharacters, REQUIRED_TEXT } = getValidationTexts();
    return object().shape({
        password: string()
            .min(8, minimumNumberCharacters(8))
            .required(REQUIRED_TEXT)
            .nullable(),
        confirm_password: string()
            .min(8, minimumNumberCharacters(8))
            .required(REQUIRED_TEXT)
            .oneOf(
                [ref('password'), null],
                i18next.t('Confirm password does not match')
            )
            .nullable(),
    });
};
