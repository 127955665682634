import React, { useEffect, useState } from 'react';

import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';
import Chart from 'react-apexcharts';
import { useTranslation } from 'react-i18next';
import { Typography } from 'sunwise-ui';

import { getChartOptions } from '../../helpers';
import ChartWrapper from '../ChartWrapper';

const ChartComponent = ({
    countryCurrencyLocale,
    selectedProjectId,
    seriesData,
}) => {
    const [chartConfig, setChartConfig] = useState({
        options: {},
        series: [],
    });
    const { t } = useTranslation();
    const theme = useTheme();

    useEffect(() => {
        const serieIndex = seriesData?.series?.findIndex(
            (serie) => serie.projectId === selectedProjectId
        );

        const colors = seriesData?.colors?.[serieIndex] ?? '#FF6633';
        const serie = seriesData?.series?.[serieIndex];
        const newSeries = serie ? [serie] : [];

        const chartOptions = getChartOptions({
            categories: seriesData?.categories,
            colors,
            countryCurrencyLocale,
            pointsSelection: false,
            series: newSeries,
        });
        setChartConfig({ options: chartOptions, series: newSeries });
    }, [seriesData, selectedProjectId]);

    return (
        <>
            <Typography variant="caption" fontWeight="bold">
                {t('Generation')}
            </Typography>
            <ChartWrapper height="300px">
                <Chart
                    options={{
                        ...chartConfig.options,
                        theme: { mode: theme.palette.mode },
                    }}
                    series={chartConfig.series || []}
                    type="bar"
                    height="100%"
                    width="100%"
                />
            </ChartWrapper>
        </>
    );
};

ChartComponent.propTypes = {
    countryCurrencyLocale: PropTypes.string,
    selectedProjectId: PropTypes.string,
    seriesData: PropTypes.object,
};

export default ChartComponent;
