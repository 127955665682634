import React, { useEffect, useState } from 'react';

import AddIcon from '@mui/icons-material/Add';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import isNull from 'lodash/isNull';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Grid, Skeleton } from 'sunwise-ui';

import CategoryPreviewItem from 'common/components/CategoryPreviewItem';
import IconPreviewItem from 'common/components/IconPreviewItem';
import { useBreakpoint } from 'common/hooks';

import PdfDocument from 'resources/pdfDocument.svg';

import * as templateLibrarySelectors from '../../../templateLibrary/selectors';
import { getClonePagesTemplatesByCategoryAndLanguage } from '../../helpers';
import * as selectors from '../../selectors';

import DefaultTemplatePreviewItem from './DefaultTemplatePreviewItem';

const DefaultTemplatesTab = ({
    categorySelected,
    clonePages,
    customTemplate,
    handleSelectPage,
    isFetchingCustomTemplate,
    isFetchingTemplateCategories,
    isForSummaryTemplate,
    languagesDictionary,
    pageSelected,
    proposalsNumber,
    selectCategory,
    templateBaseType,
    templateCategories,
}) => {
    const { t } = useTranslation();
    const breakpoint = useBreakpoint();
    const [items, setItems] = useState(6);

    useEffect(() => {
        if (['xs', 'sm'].includes(breakpoint)) setItems(2);
        if (['md'].includes(breakpoint)) setItems(3);
        setItems(6);
    }, [breakpoint]);

    if (isFetchingCustomTemplate || isFetchingTemplateCategories)
        return (
            <Grid container mb={2}>
                {Array.from(new Array(items)).map((_, index) => (
                    <Grid item key={`skeleton-${index}`} lg={3} md={6} xs={9}>
                        <Skeleton height={218} variant="rounded" />
                    </Grid>
                ))}
            </Grid>
        );

    if (isNull(categorySelected)) {
        const parsedTemplateCategories = templateCategories.map(
            (templateCategory) => {
                const count = getClonePagesTemplatesByCategoryAndLanguage({
                    category: templateCategory.id,
                    clonePages,
                    language: customTemplate.language,
                    proposalsNumber,
                    templateBaseType,
                }).length;
                return { ...templateCategory, count };
            }
        );

        return (
            <Grid container>
                <Grid item xs={18} md={6} lg={3}>
                    <IconPreviewItem
                        icon={<AddIcon fontSize="large" />}
                        isSelected={
                            pageSelected !== null &&
                            pageSelected.id === 'blank-page'
                        }
                        onClick={() =>
                            handleSelectPage({ id: 'blank-page', pages: 1 })
                        }
                        text={t('Blank template')}
                    />
                </Grid>

                {!isForSummaryTemplate &&
                    parsedTemplateCategories
                        .filter(({ count }) => count > 0)
                        .map((templateCategory) => (
                            <Grid
                                item
                                key={templateCategory.id}
                                lg={3}
                                md={6}
                                xs={18}
                            >
                                <CategoryPreviewItem
                                    id={templateCategory.id}
                                    name={templateCategory.name}
                                    onClick={() =>
                                        selectCategory(templateCategory.id)
                                    }
                                    templatesCount={templateCategory.count}
                                    urlIcon={templateCategory.file_url}
                                />
                            </Grid>
                        ))}
            </Grid>
        );
    }
    return (
        <Grid container>
            <Grid item xs={18} md={6} lg={3}>
                <IconPreviewItem
                    icon={<ArrowBackIosNewIcon fontSize="large" />}
                    onClick={() => selectCategory(null)}
                    text={t('Back')}
                />
            </Grid>

            {getClonePagesTemplatesByCategoryAndLanguage({
                category: categorySelected,
                clonePages,
                language: customTemplate.language,
                proposalsNumber,
                templateBaseType,
            }).map((clonePage) => (
                <Grid item xs={18} md={6} lg={3} key={clonePage.id}>
                    <DefaultTemplatePreviewItem
                        isLocked={clonePage.locked === 1}
                        isSelected={pageSelected?.id === clonePage.id}
                        language={customTemplate.language}
                        languagesDictionary={languagesDictionary}
                        onClick={() =>
                            handleSelectPage({
                                id: clonePage.id,
                                pages: clonePage.pages,
                            })
                        }
                        previewImage={
                            clonePage?.image_thumbnail
                                ? clonePage.image_thumbnail
                                : PdfDocument
                        }
                        text={clonePage.title}
                    />
                </Grid>
            ))}
        </Grid>
    );
};

const mapStateToProps = createStructuredSelector({
    clonePages: selectors.getDefaultClonePagesData,
    customTemplate: selectors.getDataFetchCustomTemplate,
    isFetchingCustomTemplate: selectors.getIsFetchingCustomTemplate,
    isFetchingTemplateCategories:
        templateLibrarySelectors.getIsFetchingTemplateCategories,
    languagesDictionary: templateLibrarySelectors.getLanguagesDictionary,
    templateCategories: templateLibrarySelectors.getTemplateCategoriesData,
});

DefaultTemplatesTab.propTypes = {
    categorySelected: PropTypes.string,
    clonePages: PropTypes.array,
    customTemplate: PropTypes.object,
    handleSelectPage: PropTypes.func,
    isFetchingCustomTemplate: PropTypes.bool,
    isFetchingTemplateCategories: PropTypes.bool,
    isForSummaryTemplate: PropTypes.bool,
    languagesDictionary: PropTypes.object,
    pageSelected: PropTypes.object,
    proposalsNumber: PropTypes.number,
    selectCategory: PropTypes.func,
    templateBaseType: PropTypes.number,
    templateCategories: PropTypes.array,
};

export default connect(mapStateToProps, null)(DefaultTemplatesTab);
