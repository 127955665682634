import React from 'react';

import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Divider, Drawer, IconButton, Typography } from 'sunwise-ui';

import DrawerContent from './DrawerContent';

const DrawerHeader = styled('div')(({ theme }) => ({
    alignItems: 'center',
    display: 'flex',
    padding: theme.spacing(0, 2),
    ...theme.mixins.toolbar,
    justifyContent: 'flex-start',
}));

const StatusDrawer = ({ handleToggleDrawer, isOpenDrawer }) => {
    const { t } = useTranslation();

    return (
        <Drawer
            anchor="right"
            open={isOpenDrawer}
            onClose={() => handleToggleDrawer(false)}
        >
            <DrawerHeader>
                <IconButton onClick={() => handleToggleDrawer(false)}>
                    <ChevronRightIcon />
                </IconButton>

                <Typography
                    variant="body2"
                    sx={{ fontWeight: 'bold', ml: 1, width: '100%' }}
                >
                    {t('Power stations sync status')}
                </Typography>
            </DrawerHeader>

            <Divider sx={{ mx: '1rem' }} />

            <DrawerContent />
        </Drawer>
    );
};

StatusDrawer.propTypes = {
    handleToggleDrawer: PropTypes.func,
    isOpenDrawer: PropTypes.bool,
};

export default StatusDrawer;
