import axios from 'axios';

import { handleInterceptorErrors } from 'common/utils/helpers/api';
import {
    getIanaTimezoneKey,
    getRuntimeLanguage,
} from 'common/utils/helpers/multiregion';

const API = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
});

API.interceptors.request.use((req) => {
    const branchSelected = localStorage.getItem('branch-selected') || 'all';
    req.headers['Accept-Language'] = getRuntimeLanguage();
    req.headers['X-Timezone-IANA'] = getIanaTimezoneKey();
    if (branchSelected && branchSelected !== 'all')
        req.headers['X-Branch-Office'] = branchSelected;
    return req;
});

API.interceptors.response.use(
    (res) => res,
    (error) => handleInterceptorErrors({}, error, false)
);

export default API;
