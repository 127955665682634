import isEmpty from 'lodash/isEmpty';

import { DAYS_IN_PERIOD } from 'common/constants';
import { subDate } from 'common/utils/dates';
import {
    calcEnergyDistributionPercentages,
    getFieldsInPeriod,
} from 'common/utils/helpers/rates';

import {
    formatDateDefault,
    formatDateLabel,
    getDefaultSummerMonth,
    parseDateDefault,
} from '../helpers';
import * as selectors from '../selectors';

import { updatePlaceholder } from './updatePlaceholders';

export default ({
        formValues,
        periodicityType,
        rate,
        rateConfiguration,
        setValue,
    } = {}) =>
    (_, getState) => {
        const state = getState();
        const profilesConsumptionData =
            selectors.getProfileConsumptionData(state);
        const ratesDictionary = selectors.getRatesDictionary(state);
        const summerMonths = selectors.getMonthsForSelect(state);

        const daysToSubtract = (periodicityType + 1) * DAYS_IN_PERIOD;
        const isBimonthly = periodicityType === 1 ? '1' : '0';
        const lastDateConsumption = formValues.last_consumption;
        const quantityMonths = 12 / (periodicityType + 1);

        const { tiers_energy_distribution } = rateConfiguration;

        const { summary } = [...new Array(quantityMonths)].reduce(
            (acc, _, index) => {
                const realIndex = index + 1;
                const final = acc.currentPivotDate;
                const initial = subDate(parseDateDefault(lastDateConsumption), {
                    days: Math.ceil(daysToSubtract * realIndex),
                });
                const label = formatDateLabel(
                    subDate(final, { days: isBimonthly === '1' ? 30 : 15 })
                );
                const final_date = formatDateDefault(final);
                const initial_date = formatDateDefault(initial);

                const fields = getFieldsInPeriod({
                    distribution: formValues?.distribution,
                    finalDate: final_date,
                    initialDate: initial_date,
                    period: formValues?.summary?.[index],
                    rate,
                    tiers_energy_distribution,
                });
                const period = {
                    file: null,
                    final_date,
                    initial_date,
                    ...fields,
                    label,
                    power_factor:
                        formValues?.summary?.[index]?.power_factor || 90,
                };
                return {
                    ...acc,
                    currentPivotDate: initial,
                    summary: [...acc.summary, period],
                };
            },
            {
                currentPivotDate: parseDateDefault(lastDateConsumption),
                summary: [],
            }
        );

        const defaultSummerMonth = getDefaultSummerMonth({
            formValues,
            selectedRate: rate,
            summerMonths,
        });

        const distribution = calcEnergyDistributionPercentages(summary);

        if (formValues.hourly_contracted_demand)
            setValue(
                'hourly_contracted_demand',
                formValues.hourly_contracted_demand
            );
        if (!isEmpty(distribution)) setValue('distribution', distribution);
        setValue('cnmc_data_modified', false);
        setValue('csv_data_modified', false);
        setValue('periodicity_type', periodicityType.toString());
        setValue('summary', summary);
        updatePlaceholder({
            formValues: {
                ...formValues,
                distribution: isEmpty(distribution)
                    ? formValues.distribution
                    : distribution,
                is_bimonthly: isBimonthly,
                last_consumption: lastDateConsumption,
                periodicity_type: periodicityType,
                rate_division_summer: defaultSummerMonth,
                summary,
            },
            profilesConsumptionData,
            rateId: formValues.rate,
            ratesDictionary,
            setValue,
        });
    };
