import { getItems } from 'common/api/v1/additional';

import {
    FETCH_ADDITIONALS,
    FETCH_ADDITIONALS_FAILURE,
    FETCH_ADDITIONALS_SUCCESS,
} from '../actionTypes';
import { planConfigurationActions } from '../reducer';

export default (branchOffice) => (dispatch) => {
    dispatch(planConfigurationActions[FETCH_ADDITIONALS]());

    getItems(branchOffice)
        .then((response) => {
            dispatch(
                planConfigurationActions[FETCH_ADDITIONALS_SUCCESS](
                    response.data.data
                )
            );
        })
        .catch((error) =>
            dispatch(
                planConfigurationActions[FETCH_ADDITIONALS_FAILURE](
                    error?.response?.data?.errors
                )
            )
        );
};
