import i18next from 'i18next';
import get from 'lodash/get';

import { getDuplicatedContact } from 'common/api/v1/contacts';
import alerts from 'common/modules/alerts';
import { showReponseErrorsAsAlert } from 'common/utils/helpers';

import { SAVE_CONTACT_FAILURE } from '../actionTypes';
import { contactFormActions } from '../reducer';
import * as selectors from '../selectors';

import saveContact from './saveContact';

export default (values, handleAfterSave, redirectToRecord = true) =>
    (dispatch, getState) => {
        const state = getState();
        const previousEmails = selectors.getEmails(state);
        const { emails = [] } = values;
        const [firstEmail = { email: '' }] = emails;
        const [firstPreviousEmail = { email: '' }] = previousEmails;

        if (
            !firstEmail.email ||
            firstEmail.email === firstPreviousEmail.email
        ) {
            dispatch(saveContact(values, handleAfterSave, redirectToRecord));
            return;
        }

        getDuplicatedContact(firstEmail.email)
            .then((response) => {
                const contactData = get(response, 'data.data', false);
                const { contact_exist: contactExist } = contactData;
                if (!contactExist) {
                    dispatch(
                        saveContact(values, handleAfterSave, redirectToRecord)
                    );
                    return;
                }

                const {
                    branch_office: { name: branchName },
                    multi_branch_office: isMultiBranch,
                } = contactData;
                let duplicateModalData = {
                    message: i18next.t(
                        'There is already a contact with this email'
                    ),
                };

                if (isMultiBranch)
                    duplicateModalData.message =
                        duplicateModalData.message.concat(
                            ` ${i18next.t('At the branch "{{branch}}"', {
                                branch: branchName,
                            })}`
                        );

                const dataAlert = {
                    messages: [duplicateModalData.message],
                    confirmText: i18next.t('Accept'),
                    title: i18next.t('Confirm'),
                    type: alerts.ALERT_TYPE_ONLY_CONFIRM,
                    variant: 'warning',
                };

                dispatch(alerts.actions.show(dataAlert));
            })
            .catch((error) => {
                dispatch(
                    contactFormActions[SAVE_CONTACT_FAILURE](
                        error?.response?.data?.errors
                    )
                );
                showReponseErrorsAsAlert(dispatch, error?.response);
            });
    };
