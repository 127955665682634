import { number, object, string } from 'yup';

import getValidationTexts from 'common/utils/getValidationTexts';

export default () => {
    const { REQUIRED_TEXT, INVALID_TEXT } = getValidationTexts();

    return object().shape({
        cfe_user: string().required(REQUIRED_TEXT),
        credential_id: string().required(REQUIRED_TEXT),
        last_payment: number()
            .nullable()
            .transform((value) => value || null),
        service_number: string()
            .transform((value) => value.replace(/\s+/g, ''))
            .required(REQUIRED_TEXT)
            .matches(/^\d{12}$/, INVALID_TEXT),
    });
};
