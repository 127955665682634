import React from 'react';

import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import InfoIcon from '@mui/icons-material/Info';
import { styled } from '@mui/material/styles';
import isEmpty from 'lodash/isEmpty';
import isNull from 'lodash/isNull';
import isNumber from 'lodash/isNumber';
import isUndefined from 'lodash/isUndefined';
import PropTypes from 'prop-types';
import { useFieldArray, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
    Box,
    Button,
    Checkbox,
    Card,
    Divider,
    FormControlLabel,
    Grid,
    IconButton,
    Tooltip,
    Typography,
} from 'sunwise-ui';

import {
    ReactHookFormCheck,
    ReactHookFormSelect,
    ReactHookFormIntlNumberInput,
} from 'common/components/form/bootstrap';
import LabelError from 'common/components/LabelError';
import { INFINITY } from 'common/constants';
import {
    calculateFinalCost,
    calculateMargin,
    calculateRangesPriceFrom,
    calculateRangesPriceTo,
    calculateRangesPriceToByNextRange,
    getCurrencyById,
    getCurrencyIso,
    getLabelWithCurrency,
} from 'common/utils/helpers';

const StyledGridItem = styled(Grid)`
    &.MuiGrid-item {
        padding-top: 0 !important;
    }
`;

const ReactHookFormArrayRanges = ({
    control,
    currencies,
    getValues,
    isDisabled,
    name,
    setValue,
}) => {
    const { t } = useTranslation();

    const { fields, append, remove } = useFieldArray({
        control,
        name,
    });

    const rangePrices = useWatch({ control, name });
    const _currency = useWatch({ control, name: 'currency' });
    const currency = getCurrencyById(currencies, _currency);
    const currencyIso = getCurrencyIso(currency);

    const isAllChecked = () => {
        if (isEmpty(getValues()) || isEmpty(rangePrices)) return false;
        return Boolean(
            rangePrices.reduce(
                (acc, { is_unique_price = false }) => is_unique_price * acc,
                true
            )
        );
    };

    const handleOnChange = (i) => {
        let last_price_to = rangePrices[i]?.price_to;

        for (let j = i + 1; j < rangePrices.length; j++) {
            let range = rangePrices[j];
            if (!isNull(range)) {
                if (
                    Number(last_price_to) > Number(range.price_from) ||
                    Number(range.price_from) - Number(last_price_to) >= 0
                ) {
                    setValue(
                        `${name}.${j}.price_from`,
                        calculateRangesPriceFrom(last_price_to)
                    );
                }
                if (Number(range.price_from) > Number(range.price_to)) {
                    last_price_to = calculateRangesPriceTo(range.price_from);

                    setValue(`${name}.${j}.price_to`, last_price_to);
                } else {
                    last_price_to = range.price_to;
                }
            }
        }
    };

    const handleNewItem = (lastField) => {
        const new_price_from = !isUndefined(lastField)
            ? calculateRangesPriceFrom(lastField.price_to)
            : 1;
        const new_price_to = calculateRangesPriceTo(new_price_from);

        append({
            cost: 0,
            final_cost: 0,
            margin: 0,
            price_from: new_price_from,
            price_to: new_price_to,
        });
    };

    const handleAddRange = () => {
        let priceFrom = 1,
            priceTo = 1;

        const field = {
            cost: 0,
            final_cost: 0,
            margin: 0,
            price_from: priceFrom,
            price_to: priceTo,
        };

        remove(0);
        append(field);
        handleNewItem(field);
    };

    const handleResetRange = (e) => {
        if (String(e.target.value)) setValue('costing_unit', e.target.value);
        setValue(`${name}.0.price_from`, 1);
        setValue(`${name}.0.price_to`, INFINITY);

        const length = fields.length;

        for (let i = length; i > 0; i--) remove(i);

        setValue(name, getValues(name));
    };

    const handleRemove = (index) => {
        if (index === 1) {
            setValue(`${name}.0.price_from`, 1);
            setValue(`${name}.0.price_to`, INFINITY);
        }
        remove(index);

        setValue(name, getValues(name));
    };

    const validateToPriceField = (event, value, i) => {
        const currentRange = getValues(`${name}.${i}`);
        if (!value || parseInt(currentRange.price_from) > parseInt(value)) {
            let last_price_to = calculateRangesPriceTo(currentRange.price_from);

            const nextRange = getValues(`${name}.${i + 1}`);
            if (nextRange)
                last_price_to = calculateRangesPriceToByNextRange(nextRange);

            event.preventDefault();
            setValue(`${name}.${i}.price_to`, last_price_to);
        }
    };

    const handleEnterKey = (event, value, i) => {
        if (event.key === 'Enter') validateToPriceField(event, value, i);
    };

    const handleChangeCheckingAll = (e) => {
        if (e.target.checked) {
            fields.forEach((i, index) => {
                setValue(`${name}.${index}.is_unique_price`, true);
            });
        } else {
            fields.forEach((i, index) => {
                setValue(`${name}.${index}.is_unique_price`, false);
            });
        }
    };

    const handleChangeCostValue = (i) => {
        const value = getValues(`${name}.${i}.cost`);
        if (!value) setValue(`${name}.${i}.cost`, 0);

        setValue(
            `${name}.${i}.final_cost`,
            calculateFinalCost(
                !isNumber(parseInt(value)) ? 0 : value,
                getValues(`${name}.${i}.margin`)
            )
        );
    };

    const handleChangeMarginValue = (i) => {
        let value = getValues(`${name}.${i}.margin`);

        if (!value) setValue(`${name}.${i}.margin`, 0);
        else if (parseFloat(value) >= 100) {
            setValue(`${name}.${i}.margin`, 99.99);
            value = 99.99;
        }

        setValue(
            `${name}.${i}.final_cost`,
            calculateFinalCost(
                getValues(`${name}.${i}.cost`),
                !isNumber(parseInt(value)) ? 0 : value
            )
        );
    };

    const handleChangeFinalCostValue = (i) => {
        let value = getValues(`${name}.${i}.final_cost`),
            costValue = getValues(`${name}.${i}.cost`);

        if (!value || parseFloat(value) < parseFloat(costValue)) {
            setValue(`${name}.${i}.final_cost`, costValue);
            setValue(`${name}.${i}.margin`, 0);
        } else if (parseFloat(costValue) === 0) {
            setValue(`${name}.${i}.margin`, 0);
            setValue(`${name}.${i}.cost`, value);
        } else if (calculateMargin(value, costValue) >= 100) {
            setValue(`${name}.${i}.margin`, 99.99);
            setValue(
                `${name}.${i}.final_cost`,
                calculateFinalCost(costValue, 99.99)
            );
        } else
            setValue(`${name}.${i}.margin`, calculateMargin(value, costValue));
    };

    const optionsToSelect = [
        {
            label: t('Select a type'),
            value: '',
            disabled: true,
        },
        { label: t('Per panel'), value: 0 },
        { label: t('Per inverter'), value: 1 },
        { label: t('Per watt'), value: 2 },
        {
            label: t('Per installation'),
            value: 3,
            disabled: fields.length > 1,
        },
    ];

    return (
        <Box mt={1}>
            <Typography variant="body2" fontWeight="bold">
                {t('Price')}
            </Typography>

            <Card>
                <Card.Body>
                    <Grid container alignItems="center" mt={1}>
                        <StyledGridItem
                            item
                            xs={18}
                            lg={6}
                            sx={{
                                pt: { xs: '16px !important', lg: 'auto' },
                                order: { xs: 2, lg: 1 },
                            }}
                        >
                            <ReactHookFormSelect
                                control={control}
                                disabled={isDisabled}
                                fullWidth
                                label={t('Unit costing')}
                                name="costing_unit"
                                onChange={(e) => handleResetRange(e)}
                                options={optionsToSelect}
                            />
                        </StyledGridItem>
                        <StyledGridItem
                            item
                            xs={18}
                            lg={4}
                            sx={{
                                pt: { xs: 0, lg: 'auto' },
                                order: { xs: 1, lg: 2 },
                            }}
                        >
                            <Button
                                disabled={
                                    getValues('costing_unit') === '3' ||
                                    isDisabled
                                }
                                endIcon={
                                    fields && fields.length === 1 ? (
                                        <AddIcon />
                                    ) : (
                                        <CloseIcon />
                                    )
                                }
                                onClick={
                                    fields && fields.length === 1
                                        ? handleAddRange
                                        : handleResetRange
                                }
                                sx={{
                                    width: { xs: '100%', lg: 'auto' },
                                }}
                                type="button"
                                variant="outlined"
                            >
                                {fields && fields.length === 1
                                    ? t('Cost by range')
                                    : t('Cost per unit')}
                            </Button>
                        </StyledGridItem>
                        <StyledGridItem
                            hidden={fields.length === 1}
                            item
                            xs={18}
                            lg={4}
                            sx={{ pt: { xs: 0, lg: 'auto' }, order: 3 }}
                        >
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={isAllChecked()}
                                        disabled={isDisabled}
                                        onChange={handleChangeCheckingAll}
                                    />
                                }
                                fontWeight="bold"
                                label={`${t('Flat price')} (${t('All text')})`}
                                sx={{
                                    mb: { xs: 0, lg: 2 },
                                    ml: 1,
                                    '& .MuiFormControlLabel-label': {
                                        lineHeight: 'normal',
                                        fontSize: '14px',
                                        fontWeight: 'bold',
                                    },
                                }}
                            />
                        </StyledGridItem>
                    </Grid>
                    {fields && fields.length === 1
                        ? fields.map((item, i) => (
                              <Grid container key={item.id}>
                                  <Grid item xs={18} lg={6}>
                                      <ReactHookFormIntlNumberInput
                                          allowNegativeValue={false}
                                          control={control}
                                          disabled={isDisabled}
                                          fullWidth
                                          label={getLabelWithCurrency(
                                              currencyIso,
                                              t('Cost')
                                          )}
                                          min="0"
                                          name={`${name}.${i}.cost`}
                                          onBlur={() =>
                                              handleChangeCostValue(i)
                                          }
                                          prepend="$"
                                      />
                                  </Grid>
                                  <Grid item xs={18} lg={6}>
                                      <ReactHookFormIntlNumberInput
                                          allowNegativeValue={false}
                                          append="%"
                                          control={control}
                                          disabled={isDisabled}
                                          fullWidth
                                          label={t('Margin')}
                                          min="0"
                                          name={`${name}.${i}.margin`}
                                          onBlur={() =>
                                              handleChangeMarginValue(i)
                                          }
                                      />
                                  </Grid>
                                  <Grid item xs={18} lg={6}>
                                      <ReactHookFormIntlNumberInput
                                          allowNegativeValue={false}
                                          control={control}
                                          disabled={isDisabled}
                                          fullWidth
                                          label={getLabelWithCurrency(
                                              currencyIso,
                                              t('Final price')
                                          )}
                                          name={`${name}.${i}.final_cost`}
                                          min="0"
                                          onBlur={() =>
                                              handleChangeFinalCostValue(i)
                                          }
                                          prepend="$"
                                      />
                                  </Grid>
                              </Grid>
                          ))
                        : fields.map((item, i) => (
                              <Grid
                                  container
                                  mt={i > 0 ?? 1}
                                  mb={1}
                                  key={item.id}
                              >
                                  <Grid item xs={18} lg={2}>
                                      <ReactHookFormIntlNumberInput
                                          allowNegativeValue={false}
                                          control={control}
                                          disabled
                                          fullWidth
                                          label={t('From')}
                                          name={`${name}.${i}.price_from`}
                                      />
                                  </Grid>
                                  <Grid item xs={18} lg={4}>
                                      <Box
                                          sx={{
                                              display: 'flex',
                                              alignItems: 'center',
                                              gap: '5px',
                                          }}
                                      >
                                          <ReactHookFormIntlNumberInput
                                              allowNegativeValue={false}
                                              allowDecimals={false}
                                              control={control}
                                              disabled={isDisabled}
                                              fullWidth
                                              label={t('To')}
                                              name={`${name}.${i}.price_to`}
                                              onBlur={({ event, value }) => {
                                                  validateToPriceField(
                                                      event,
                                                      value,
                                                      i
                                                  );
                                                  handleOnChange(i);
                                              }}
                                              onKeyDown={({ event, value }) =>
                                                  handleEnterKey(
                                                      event,
                                                      value,
                                                      i
                                                  )
                                              }
                                          />
                                          {i === fields.length - 1 ? (
                                              <Tooltip
                                                  title={t(
                                                      'If the number of products is greater than this number, the final price set in this row will apply'
                                                  )}
                                              >
                                                  <InfoIcon
                                                      sx={{
                                                          color: '#637381',
                                                          mb: 2,
                                                      }}
                                                  />
                                              </Tooltip>
                                          ) : (
                                              <Box sx={{ width: '24px' }} />
                                          )}
                                      </Box>
                                  </Grid>
                                  <Grid item xs={18} lg={3}>
                                      <ReactHookFormIntlNumberInput
                                          allowNegativeValue={false}
                                          control={control}
                                          disabled={isDisabled}
                                          fullWidth
                                          label={getLabelWithCurrency(
                                              currencyIso,
                                              t('Cost')
                                          )}
                                          min="0"
                                          name={`${name}.${i}.cost`}
                                          onBlur={() =>
                                              handleChangeCostValue(i)
                                          }
                                          prepend="$"
                                      />
                                  </Grid>
                                  <Grid item xs={18} lg={3}>
                                      <ReactHookFormIntlNumberInput
                                          allowNegativeValue={false}
                                          append="%"
                                          control={control}
                                          disabled={isDisabled}
                                          fullWidth
                                          label={t('Margin')}
                                          min="0"
                                          name={`${name}.${i}.margin`}
                                          onBlur={() =>
                                              handleChangeMarginValue(i)
                                          }
                                      />
                                  </Grid>
                                  <StyledGridItem item xs={18} lg={2}>
                                      <ReactHookFormCheck
                                          control={control}
                                          disabled={isDisabled}
                                          label={t('Flat price')}
                                          name={`${name}.${i}.is_unique_price`}
                                          sxFormLabel={{
                                              '& .MuiFormControlLabel-label': {
                                                  lineHeight: 'normal',
                                                  fontSize: '14px',
                                              },
                                          }}
                                      />
                                  </StyledGridItem>
                                  <Grid item xs={18} lg={4}>
                                      <Box
                                          sx={{
                                              display: 'flex',
                                              alignItems: 'center',
                                              gap: '5px',
                                          }}
                                      >
                                          <ReactHookFormIntlNumberInput
                                              allowNegativeValue={false}
                                              control={control}
                                              disabled={isDisabled}
                                              fullWidth
                                              label={getLabelWithCurrency(
                                                  currencyIso,
                                                  t('Final price')
                                              )}
                                              name={`${name}.${i}.final_cost`}
                                              min="0"
                                              onBlur={() =>
                                                  handleChangeFinalCostValue(i)
                                              }
                                              prepend="$"
                                          />
                                          {i > 0 && i === fields.length - 1 && (
                                              <IconButton
                                                  onClick={() =>
                                                      handleRemove(i)
                                                  }
                                                  sx={{ mb: 2 }}
                                              >
                                                  <DeleteIcon />
                                              </IconButton>
                                          )}
                                      </Box>
                                  </Grid>
                                  <Grid item xs={18}>
                                      <Divider />
                                  </Grid>
                              </Grid>
                          ))}

                    {fields && fields.length === 0 && (
                        <Grid container mt={2}>
                            <Grid item xs>
                                <LabelError type="error">
                                    {t('Enter at the least price range')}
                                </LabelError>
                            </Grid>
                        </Grid>
                    )}

                    {fields && fields.length > 1 && (
                        <Grid container mt={2}>
                            <Grid item xs={18} textAlign="right">
                                <Button
                                    endIcon={<AddIcon />}
                                    onClick={() =>
                                        handleNewItem(
                                            getValues(
                                                `${name}.${fields.length - 1}`
                                            )
                                        )
                                    }
                                    sx={{ width: { lg: 'auto', xs: '100%' } }}
                                    type="button"
                                    variant="outlined"
                                    visible={!isDisabled}
                                >
                                    {t('Add range')}
                                </Button>
                            </Grid>
                        </Grid>
                    )}
                </Card.Body>
            </Card>
        </Box>
    );
};

ReactHookFormArrayRanges.propTypes = {
    control: PropTypes.object,
    currencies: PropTypes.array,
    getValues: PropTypes.func,
    isDisabled: PropTypes.bool,
    name: PropTypes.string,
    setValue: PropTypes.func,
};

export default ReactHookFormArrayRanges;
