import React, { useState, useEffect } from 'react';

import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { get, isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Button, Grid } from 'sunwise-ui';

import { ShowErrors } from 'common/components';
import yupResolver from 'common/utils/yupResolver';

import * as actions from '../../../actions';
import {
    getFinancierProducts,
    getInfovanavitLabelButton,
} from '../../../helpers';
import * as selectors from '../../../selectors';
import { ceideFormValidate } from '../../../validate';
import FormStep1 from '../FormStep1';

import CeideFormStep2 from './CeideFormStep2';
import CeideFormStep3 from './CeideFormStep3';

const FormContainer = ({
    alliance,
    allianceProducts,
    currencyIso,
    currencyLocale,
    initialValues,
    initializeCeide,
    isLocked,
    isPrequalifying,
    isSaving,
    proposalId,
    saveCeide,
    submitInfonavitForm,
    total,
}) => {
    const [productSelected, setProductSelected] = useState({});

    const { control, handleSubmit, setValue, watch } = useForm({
        defaultValues: initialValues,
        resolver: yupResolver(ceideFormValidate),
    });

    const formValues = watch();

    const typesBusinessCompany = get(formValues, 'types_business_company', '');
    const parameters = get(productSelected, 'financier_product_parameters', {});
    const infonavitData = get(formValues, 'infonavit_data', {});
    const errors = get(productSelected, 'errors', []);
    const step = get(formValues, 'step', 0);

    useEffect(() => {
        if (!isEmpty(productSelected)) {
            initializeCeide({
                ...productSelected,
                financier_installer: alliance.id,
                infonavit_data: infonavitData,
                total,
                types_business_company: formValues.types_business_company,
            });
        }
    }, [productSelected]);

    const handleOnClickSave = () => {
        switch (step) {
            case 1:
                setValue('step', 2);
                return false;
            case 2:
                return handleSubmit((values) =>
                    submitInfonavitForm(() => setValue('step', 3), proposalId, {
                        ...values,
                        financier_product_parameters: parameters,
                    })
                )();
            case 3:
                return handleSubmit((values) =>
                    saveCeide(proposalId, values)
                )();
            default:
                return false;
        }
    };

    const financierProducts = getFinancierProducts(
        allianceProducts,
        typesBusinessCompany
    );

    return (
        <form>
            <FormStep1
                allianceProducts={allianceProducts}
                control={control}
                financierProducts={financierProducts}
                isLocked={isLocked}
                setProductSelected={setProductSelected}
                setValue={setValue}
                show={step === 1}
                typesBusinessCompany={typesBusinessCompany}
            />

            <CeideFormStep2
                alliance={alliance}
                control={control}
                productSelected={productSelected}
                show={step === 2}
            />

            <CeideFormStep3
                alliance={alliance}
                control={control}
                currencyIso={currencyIso}
                currencyLocale={currencyLocale}
                infonavitData={infonavitData}
                show={step === 3}
            />

            <Grid container>
                <Grid item xs>
                    <ShowErrors errors={errors} />
                </Grid>
            </Grid>
            <Grid container mt={1}>
                <Grid item xs sx={{ textAlign: { xs: 'center', md: 'right' } }}>
                    <Button
                        disabled={isLocked || errors.length > 0}
                        endIcon={<ArrowForwardIcon />}
                        onClick={handleOnClickSave}
                        sx={{ width: { xs: '100%', md: 'auto' } }}
                        type="button"
                    >
                        {getInfovanavitLabelButton(
                            isPrequalifying,
                            isSaving,
                            step
                        )}
                    </Button>
                </Grid>
            </Grid>
        </form>
    );
};

const mapStateToProps = createStructuredSelector({
    initialValues: selectors.getInitialValuesCeide,
});

const mapDispatchToProps = (dispatch) => ({
    initializeCeide: (values) => dispatch(actions.initialValuesCeide(values)),
    saveCeide: (id, values) => dispatch(actions.saveCeide(id, values)),
    submitInfonavitForm: (callback, proposalId, values) =>
        dispatch(actions.submitInfonavitForm(callback, proposalId, values)),
});

FormContainer.propTypes = {
    alliance: PropTypes.object,
    allianceProducts: PropTypes.array,
    currencyIso: PropTypes.string,
    currencyLocale: PropTypes.string,
    initialValues: PropTypes.object,
    initializeCeide: PropTypes.func,
    isLocked: PropTypes.bool,
    isPrequalifying: PropTypes.bool,
    isSaving: PropTypes.bool,
    proposalId: PropTypes.string,
    saveCeide: PropTypes.func,
    submitInfonavitForm: PropTypes.func,
    total: PropTypes.number,
};

export default connect(mapStateToProps, mapDispatchToProps)(FormContainer);
