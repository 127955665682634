import React, { forwardRef, useImperativeHandle, useState } from 'react';

import MoreVertIcon from '@mui/icons-material/MoreVert';
import PropTypes from 'prop-types';
import {
    IconButton,
    ListItemIcon,
    ListItemText,
    Menu,
    MenuItem,
} from 'sunwise-ui';

const DropdownCardMenu = ({
    customAdditionalItem = null,
    disabled,
    items,
    myForwardedRef,
    sxIconButton,
    visible,
    size,
}) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => setAnchorEl(event.currentTarget);
    const handleClose = () => setAnchorEl(null);

    useImperativeHandle(myForwardedRef, () => ({
        closeMenu() {
            handleClose();
        },
        isOpen: open,
    }));

    if (!visible) return null;

    return (
        <>
            <IconButton
                disabled={disabled}
                onClick={handleClick}
                size={size}
                sx={sxIconButton}
            >
                <MoreVertIcon fontSize="inherit" />
            </IconButton>

            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                {items.map(
                    (
                        {
                            disabled,
                            handleClick: menuItemClick,
                            icon,
                            iconPosition = 'left',
                            label,
                            link,
                            linkOptions,
                            visible = true,
                        },
                        index
                    ) => {
                        return visible ? (
                            <MenuItem
                                key={index}
                                disabled={disabled}
                                onClick={() => {
                                    if (menuItemClick) {
                                        menuItemClick();
                                        handleClose();
                                    }
                                }}
                                href={link}
                                {...linkOptions}
                            >
                                {icon && iconPosition === 'left' && (
                                    <ListItemIcon
                                        sx={{
                                            minWidth: '24px !important',
                                            mr: 1,
                                        }}
                                    >
                                        {icon}
                                    </ListItemIcon>
                                )}
                                <ListItemText primary={label} />
                                {icon && iconPosition === 'right' && (
                                    <ListItemIcon>{icon}</ListItemIcon>
                                )}
                            </MenuItem>
                        ) : null;
                    }
                )}
                {customAdditionalItem}
            </Menu>
        </>
    );
};

DropdownCardMenu.defaultProps = {
    customAdditionalItem: null,
    disabled: false,
    visible: true,
};

DropdownCardMenu.propTypes = {
    customAdditionalItem: PropTypes.object,
    disabled: PropTypes.bool,
    items: PropTypes.array,
    myForwardedRef: PropTypes.object,
    size: PropTypes.string,
    sxIconButton: PropTypes.object,
    visible: PropTypes.bool,
};

const DropdownCardMenuForwared = forwardRef((props, ref) => (
    <DropdownCardMenu {...props} myForwardedRef={ref} />
));

DropdownCardMenuForwared.displayName = 'DropdownCardMenuForwared';

export default DropdownCardMenuForwared;
