import Geocode from 'react-geocode';

import { getIsoCodeCurrentLocale } from 'common/utils/helpers/multiregion';

import {
    FETCH_GEOCODE,
    FETCH_GEOCODE_ERROR,
    FETCH_GEOCODE_SUCCESS,
} from '../actionTypes';
import { companyGeneralActions } from '../reducer';

export default (str) => (dispatch) => {
    Geocode.setApiKey(process.env.REACT_APP_GOOGLE_MAPS_KEY);
    Geocode.setLanguage(getIsoCodeCurrentLocale());
    Geocode.setRegion('mx');

    dispatch(companyGeneralActions[FETCH_GEOCODE]());

    return Geocode.fromAddress(str).then(
        (response) => {
            dispatch(
                companyGeneralActions[FETCH_GEOCODE_SUCCESS](response.results)
            );
        },
        (error) => {
            dispatch(
                companyGeneralActions[FETCH_GEOCODE_ERROR](
                    error?.response?.data?.errors
                )
            );
        }
    );
};
