import React from 'react';

import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from 'sunwise-ui';

import { DropdownCardMenu } from 'common/components/cards';
import { parseDate, formatDate } from 'common/utils/dates';
import { handleFileURL, getFileExtensionByPath } from 'common/utils/helpers';

const DocumentItem = ({
    createdAt,
    canDelete,
    canModify,
    documentType,
    handleClickDelete,
    handleClickUpdate,
    file,
    valueUser,
}) => {
    const { t } = useTranslation();
    return (
        <Box display="flex" alignItems="center" gap={1}>
            <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
                gap={1}
                mb={2}
            >
                <InsertDriveFileIcon sx={{ color: '#b4bbdd' }} />
                {file ? getFileExtensionByPath(file) : ''}
            </Box>
            <Box display="flex" sx={{ width: '100%' }}>
                <Box display="flex" flexDirection="column">
                    <Typography variant="body2" fontWeight="bold">
                        {documentType}
                    </Typography>
                    <Typography variant="body2">{valueUser}</Typography>
                    <Typography variant="caption">
                        {`${t('Loaded')} ${formatDate(
                            parseDate(createdAt, 'dd/MM/yyyy HH:mm:ss'),
                            'dd/MM/yyyy'
                        )}`}
                    </Typography>
                </Box>
                <Box sx={{ ml: 'auto' }}>
                    <DropdownCardMenu
                        items={[
                            {
                                handleClick: handleClickUpdate,
                                label: t('Edit'),
                                visible: canModify,
                            },
                            {
                                handleClick: () => {
                                    const link = document.createElement('a');
                                    link.href = handleFileURL(
                                        file,
                                        process.env.REACT_APP_S3_MEDIA_PATH
                                    );
                                    link.target = '_blank';
                                    document.body.appendChild(link);
                                    link.click();
                                    link.parentNode.removeChild(link);
                                },
                                label: t('Download'),
                            },
                            {
                                handleClick: handleClickDelete,
                                label: t('Delete'),
                                visible: canDelete,
                            },
                        ]}
                    />
                </Box>
            </Box>
        </Box>
    );
};

DocumentItem.propTypes = {
    createdAt: PropTypes.string,
    canDelete: PropTypes.bool,
    canModify: PropTypes.bool,
    documentType: PropTypes.string,
    file: PropTypes.string,
    handleClickDelete: PropTypes.func,
    handleClickUpdate: PropTypes.func,
    valueUser: PropTypes.string,
};

export default DocumentItem;
