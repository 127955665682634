import { default as turfDiference } from '@turf/difference';
import { polygon as turfPolygon } from '@turf/helpers';

import {
    checkCollition,
    degreesToRadians,
    drawDebugBoxes,
    fixOrientation,
    getBounds,
    getBoxSize,
    getBoxSizeWithTurf,
    getCenter,
    getPolygonCoords,
    getPolygonPaths,
    getPolygonPointsWithSetback,
    rotatePolygon,
} from './solarGeometryhelpers';

const CM_IN_METER = 1000;

const getPanelsLocation = ({
    azimuth,
    boxes,
    cellIndex,
    count,
    frameCols,
    frameRows,
    google,
    groupIndex,
    map,
    maxSolarModules,
    originalCenter,
    polygon,
    solarModuleBoxSize,
    spaceBetweenSolarModulesBoxSize,
}) => {
    let innerCount = 0;

    const projection = map.getProjection();
    const bounds = getBounds({ google, polygon });
    const sw = bounds.getSouthWest();
    const swPoint = projection.fromLatLngToPoint(sw);
    let cell = [];

    const _maxSolarModules =
        maxSolarModules < 0 || !maxSolarModules ? Infinity : maxSolarModules;

    for (let row = 0; row < frameRows; row++) {
        cell[row] = [];
        for (let col = 0; col < frameCols; col++) {
            if (count + innerCount >= _maxSolarModules) break;

            const _solarModulesAreaPolygon = getPolygonCoords({
                boxSize: solarModuleBoxSize,
                google,
                projection,
                swPoint,
                padding: spaceBetweenSolarModulesBoxSize,
                x: col,
                y: row,
            });

            rotatePolygon({
                angle: 180 + azimuth,
                center: originalCenter,
                google,
                polygon: _solarModulesAreaPolygon,
            });

            const data = {
                cell: cellIndex,
                col,
                enable: true,
                group: groupIndex,
                paths: _solarModulesAreaPolygon
                    .getPath()
                    .getArray()
                    .map((point) => [point.lat(), point.lng()]),
                row,
            };

            cell[row].push(data);
            boxes.push(data);

            innerCount++;
        }
    }

    return { count: innerCount, cell };
};

export function getSolarModulesInSegment({
    debug = false,
    google,
    map,
    maxSolarModules,
    obstacles,
    polygon,
    setup,
}) {
    if (!polygon) return [];

    const frameCols = parseFloat(setup.frameCols || 1);
    const frameRows = parseFloat(setup.frameRows || 1);

    if (frameCols <= 0 || frameRows <= 0) return { boxes: [], grouped: [] };

    const boxes = new Array();
    const debugBoxes = new google.maps.MVCArray();

    const azimuth = parseFloat(setup.azimuth || 0);
    const tilt = parseFloat(setup.tilt || 0);
    const cosTilt = Math.cos(degreesToRadians(tilt === 90 ? 89.99 : tilt));

    const frameColSpacing = parseFloat(setup.frameColSpacing || 0);
    const frameRowSpacing = parseFloat(setup.frameRowSpacing || 0);

    const solarModuleColSpacing = parseFloat(setup.solarModuleColSpacing || 0);
    const solarModuleRowSpacing = parseFloat(setup.solarModuleRowSpacing || 0);
    const solarModuleOrientation = setup.solarModuleOrientation;
    const _solarModuleHeight =
        parseFloat(setup.solarModuleHeight) / CM_IN_METER;
    const _solarModuleWidth = parseFloat(setup.solarModuleWidth) / CM_IN_METER;

    const { height: solarModuleHeight, width: solarModuleWidth } =
        fixOrientation({
            height: _solarModuleHeight,
            orientation: solarModuleOrientation,
            width: _solarModuleWidth,
        });

    const solarModuleTotalHeight =
        (solarModuleHeight + solarModuleRowSpacing) * cosTilt;
    const solarModuleTotalWidth = solarModuleWidth + solarModuleColSpacing;

    const frameTotalHeight =
        solarModuleTotalHeight * frameRows + frameRowSpacing;
    const frameTotalWidth = solarModuleTotalWidth * frameCols + frameColSpacing;

    const setback = -1 * parseFloat(setup.tolerance) || 0;

    const polygonPointsWithSetback = getPolygonPointsWithSetback({
        polygon,
        setback,
    });

    const polygonPlusSetback = new google.maps.Polygon({
        paths: polygonPointsWithSetback,
    });

    const originalPaths = getPolygonPaths(polygon);
    const originalCenter = getCenter({ google, paths: originalPaths });

    rotatePolygon({
        angle: 180 - azimuth,
        center: originalCenter,
        google,
        polygon: polygonPlusSetback,
    });
    const rotatedPolygonBounds = getBounds({
        google,
        polygon: polygonPlusSetback,
    });
    const sw = rotatedPolygonBounds.getSouthWest();
    const ne = rotatedPolygonBounds.getNorthEast();

    const north = ne.lat();
    const east = ne.lng();
    const south = sw.lat();
    const west = sw.lng();

    const bboxWidth = east - west;
    const bboxHeight = north - south;

    const solarModuleBoxSize = getBoxSize({
        google,
        height: solarModuleTotalHeight,
        map,
        width: solarModuleTotalWidth,
    });

    const spaceBetweenSolarModulesBoxSize = getBoxSize({
        google,
        height: (solarModuleRowSpacing * cosTilt) / 2,
        map,
        width: solarModuleColSpacing / 2,
    });

    const { cellHeightDeg, cellWidthDeg } = getBoxSizeWithTurf({
        bbox: rotatedPolygonBounds,
        height: frameTotalHeight,
        width: frameTotalWidth,
    });

    const { cellHeightDeg: paddingHeight, cellWidthDeg: paddingWidth } =
        getBoxSizeWithTurf({
            bbox: rotatedPolygonBounds,
            height: frameRowSpacing,
            width: frameColSpacing,
        });

    let polygonPlusSetbackPoints = polygonPlusSetback
        .getPath()
        .getArray()
        .map((point) => [point.lng(), point.lat()]);
    polygonPlusSetbackPoints.push(polygonPlusSetbackPoints[0]);
    const poly1 = turfPolygon([polygonPlusSetbackPoints]);

    const columns = Math.floor(bboxWidth / cellWidthDeg);
    const rows = Math.floor(bboxHeight / cellHeightDeg);

    const deltaX = (bboxWidth - columns * cellWidthDeg) / 2;
    const deltaY = (bboxHeight - rows * cellHeightDeg) / 2;

    let innerCount = 0;

    let currentY = south + deltaY;

    let groups = [];

    const _maxSolarModules =
        maxSolarModules < 0 || !maxSolarModules ? Infinity : maxSolarModules;

    for (let row = 0; row <= rows; row++) {
        let currentX = west + deltaX;
        let rowGroupBuffer = [];
        let cellIndex = 0;
        for (let col = 0; col <= columns; col++) {
            if (innerCount >= _maxSolarModules) break;
            const groupVertex = [
                { lat: currentY, lng: currentX },
                {
                    lat: currentY + cellHeightDeg - paddingHeight,
                    lng: currentX,
                },
                {
                    lat: currentY + cellHeightDeg - paddingHeight,
                    lng: currentX + cellWidthDeg - paddingWidth,
                },
                {
                    lat: currentY,
                    lng: currentX + cellWidthDeg - paddingWidth,
                },
            ];
            const groupPolygon = new google.maps.Polygon({
                paths: groupVertex,
            });

            const groupPaths = groupPolygon.getPath().getArray();

            let solarModulesAreaPolygonPoints = groupPaths.map((point) => [
                point.lng(),
                point.lat(),
            ]);
            solarModulesAreaPolygonPoints.push(
                solarModulesAreaPolygonPoints[0]
            );

            const poly2 = turfPolygon([solarModulesAreaPolygonPoints]);
            const polyDifference = turfDiference(poly2, poly1);

            currentX += cellWidthDeg;

            if (polyDifference) continue;

            const isInsideObstacle = checkCollition({
                azimuth,
                google,
                map,
                obstacles,
                originalCenter,
                paths: groupPaths,
            });
            if (isInsideObstacle) {
                if (rowGroupBuffer.length > 0) groups.push(rowGroupBuffer);
                rowGroupBuffer = [];
                cellIndex = 0;
                continue;
            }

            const { count, cell } = getPanelsLocation({
                azimuth,
                boxes,
                cellIndex,
                count: innerCount,
                debug,
                debugBoxes,
                frameCols,
                frameRows,
                google,
                groupIndex: groups.length,
                map,
                maxSolarModules,
                originalCenter,
                polygon: groupPolygon,
                solarModuleBoxSize,
                spaceBetweenSolarModulesBoxSize,
            });

            cellIndex++;

            innerCount += count;

            if (cell.length > 0) {
                if (frameColSpacing > 0.02) {
                    groups.push([cell]);
                    cellIndex = 0;
                } else rowGroupBuffer.push(cell);
            }

            if (debug) {
                debugBoxes.push(groupPolygon);
            }
        }

        if (frameColSpacing <= 0.02 && rowGroupBuffer.length > 0) {
            groups.push(rowGroupBuffer);
        }
        currentY += cellHeightDeg;
    }

    if (debug) {
        drawDebugBoxes({
            azimuth,
            debugBoxes,
            google,
            map,
            originalCenter,
        });
        polygonPlusSetback.setMap(map);
    }

    return { boxes, grouped: groups };
}
