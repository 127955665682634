import { createSelector } from 'reselect';

import { NAME } from './constants';

export const getModel = (state) => {
    return state[NAME];
};

export const getFetchProposals = createSelector(
    getModel,
    (model) => model.fetchProposals
);

export const getIsFetchingProposals = createSelector(
    getFetchProposals,
    (model) => model.isFetching
);

export const getProposalsData = createSelector(
    getFetchProposals,
    (model) => model?.data?.data || []
);

export const getProposalsPagination = createSelector(
    getFetchProposals,
    (model) => model?.data?.pagination
);
