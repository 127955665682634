import isEmpty from 'lodash/isEmpty';

import { SELECT_SEGMENT } from '../actionTypes';
import { panelLayoutV2Actions } from '../reducer';

export default (segment) => (dispatch) => {
    if (isEmpty(segment)) return;
    dispatch(
        panelLayoutV2Actions[SELECT_SEGMENT]({
            ...segment,
            open: !segment.open,
        })
    );
};
