import React, { useEffect } from 'react';

import { get, isNull } from 'lodash';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import { compose } from 'recompose';
import { createStructuredSelector } from 'reselect';
import { Box, Button, Grid, Link, Typography } from 'sunwise-ui';

import {
    ReactHookFormInput,
    ReactHookFormSelect,
} from 'common/components/form/bootstrap';
import ShowErrors from 'common/components/ShowErrors';
import { PERMISSION_LIST } from 'common/constants/permissionsV2';
import withPermissions from 'common/hocs/withPermissions';
import yupResolver from 'common/utils/yupResolver';

import * as profileSelectors from '../../profile/selectors';
import * as actions from '../actions';
import {
    handleSaveProjectAndShowConsumptionForm,
    handleSaveProjectWithoutConsumption,
} from '../helpers';
import * as selectors from '../selectors';
import validate from '../validate';

const ProjectForm = ({
    canModify,
    CustomFooter,
    errors,
    fetchProjects,
    handleOpenProject,
    initialValues,
    isSavingProject,
    saveProjectAndImportConsumption,
    saveProjectAndShowConsumptionForm,
    saveProjectWithoutConsumption,
    subscription,
}) => {
    const { t } = useTranslation();
    const { control, handleSubmit, reset } = useForm({
        defaultValues: initialValues,
        resolver: yupResolver(validate),
    });

    useEffect(() => reset(initialValues), [initialValues]);

    const availableProjects = get(
        subscription,
        'counters.available_projects',
        0
    );

    const showPurchase = availableProjects <= 5;
    const isDisabled = isNull(subscription?.counters)
        ? false
        : availableProjects === 0;

    const handleSaveAndImportConsumption = (values) => {
        saveProjectAndImportConsumption(values, (project) => {
            if (fetchProjects) fetchProjects();
            handleOpenProject(project?.id);
        });
    };

    const handleOnSubmit = (values) => {
        if (values.type === 'add_consumption')
            handleSaveProjectAndShowConsumptionForm(
                fetchProjects,
                handleOpenProject,
                saveProjectAndShowConsumptionForm
            )(values);

        if (values.type === 'import_csv')
            handleSaveAndImportConsumption(values);

        if (values.type === 'without_consumption')
            handleSaveProjectWithoutConsumption(
                fetchProjects,
                handleOpenProject,
                saveProjectWithoutConsumption
            )(values);
    };

    let options = [];

    if (canModify) {
        options = [
            {
                label: t('Add consumption', { count: 2 }),
                value: 'add_consumption',
            },
            {
                label: t('Import project'),
                value: 'import_csv',
            },
        ];
    }

    options.push({
        label: t('Continue without consumption'),
        value: 'without_consumption',
    });

    const renderCustomFooter = ({ control, handleSubmit }) => {
        if (CustomFooter)
            return (
                <CustomFooter control={control} handleSubmit={handleSubmit} />
            );
    };

    return (
        <form onSubmit={handleSubmit(handleOnSubmit)}>
            <Grid container>
                <Grid item xs>
                    <ReactHookFormInput
                        className="__intercom_project_name_field"
                        control={control}
                        dataIntercomTarget="Project Name (Field)"
                        disabled={isDisabled}
                        label={t("Project's name")}
                        name="name"
                        placeholder={t("Project's name")}
                    />

                    {!isNull(subscription?.counters) && (
                        <Box
                            alignItems="center"
                            display="flex"
                            gap={2}
                            mb={2}
                            pt={1}
                        >
                            <Typography
                                variant="body2"
                                sx={{
                                    color: showPurchase
                                        ? 'rgba(218, 30, 40, 1)'
                                        : 'inherit',
                                }}
                            >
                                {t('Available projects')}: {availableProjects}
                            </Typography>

                            {showPurchase && (
                                <Typography variant="body2">
                                    <Link
                                        color="secondary"
                                        component={RouterLink}
                                        to="/profile/subscription?open_section=edit_subscription"
                                    >
                                        {t('Purchase additional projects')}
                                    </Link>
                                </Typography>
                            )}
                        </Box>
                    )}

                    <ReactHookFormSelect
                        control={control}
                        disabled={isDisabled}
                        label={t('Add consumption', { count: 2 })}
                        name="type"
                        options={options}
                    />

                    <ShowErrors errors={errors || []} />

                    {renderCustomFooter({ control, handleSubmit })}

                    {!CustomFooter && (
                        <Button
                            className="__intercom_create_project_button"
                            dataIntercomTarget="Create project (Button)"
                            disabled={isDisabled || isSavingProject}
                            fullWidth
                            type="submit"
                        >
                            <strong>{t('Continue')}</strong>
                        </Button>
                    )}
                </Grid>
            </Grid>
        </form>
    );
};

const mapStateToProps = createStructuredSelector({
    errors: selectors.getSaveProjectErrors,
    initialValues: selectors.getInitialValues,
    isSavingProject: selectors.getProjectIsSaving,
    subscription: profileSelectors.getSubscription,
});

const mapDispatchToProps = (dispatch) => ({
    saveProjectAndImportConsumption: (values, callbackSuccess) =>
        dispatch(
            actions.saveProjectAndImportConsumption(values, callbackSuccess)
        ),
    saveProjectAndShowConsumptionForm: (values, callbackSuccess) =>
        dispatch(
            actions.saveProjectAndShowConsumptionForm(values, callbackSuccess)
        ),
    saveProjectWithoutConsumption: (values, callbackSuccess) =>
        dispatch(
            actions.saveProjectWithoutConsumption(values, callbackSuccess)
        ),
});

ProjectForm.propTypes = {
    canModify: PropTypes.bool,
    CustomFooter: PropTypes.func,
    errors: PropTypes.array,
    fetchProjects: PropTypes.func,
    handleOpenProject: PropTypes.func,
    initialValues: PropTypes.object,
    isSavingProject: PropTypes.bool,
    saveProjectAndImportConsumption: PropTypes.func,
    saveProjectAndShowConsumptionForm: PropTypes.func,
    saveProjectWithoutConsumption: PropTypes.func,
    subscription: PropTypes.object,
};

const mergeProps = (stateProps, dispatchProps, ownProps) =>
    Object.assign({}, stateProps, dispatchProps, ownProps);

export default compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    withPermissions(PERMISSION_LIST.CONSUMPTION_HISTORY_PERMISSION)
)(ProjectForm);
