import React, { useEffect, useState } from 'react';

import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Alert, Box, Button, Card } from 'sunwise-ui';

import {
    CreditDocuments,
    StepperTimeline,
    TitleWithDetail,
} from 'common/components';
import { STATUS } from 'common/constants';
import ChatView from 'common/modules/chatView/Container';
import { getTranslatedStatusValue } from 'common/utils/helpers';

import * as actions from '../actions';
import { FINANCIAL_STATUS } from '../constants';
import * as selectors from '../selectors';

import ModalTermsConditions from './ModalTermsConditions';
import RequestedProducts from './RequestedProducts';

const SideCard = ({
    allianceProductsList,
    documents,
    catalogRequirements,
    contracts,
    handleOnClickAddProduct,
    onClickStatus,
    requestFormat,
    fetchFinarciersInstallerData,
    fetchCatalogRequirements,
    selectedAlliance = {},
}) => {
    const { t } = useTranslation();

    useEffect(() => {
        if (selectedAlliance?.id)
            fetchFinarciersInstallerData(selectedAlliance?.id);
    }, [selectedAlliance]);

    const [showTermsModal, setShowTermsModal] = useState(false);

    const showTermsAndContitions = () => {
        if (selectedAlliance?.id)
            fetchCatalogRequirements(selectedAlliance?.id);
        setShowTermsModal(true);
    };

    const financialStatus = FINANCIAL_STATUS[selectedAlliance.status];

    return (
        <>
            <Box display="flex" flexDirection="column">
                <Box display="flex" alignItems="center" mb={1}>
                    <TitleWithDetail fontSize="16">
                        {t('Application alliance')}
                    </TitleWithDetail>
                </Box>

                <Box mb={3}>
                    <StepperTimeline
                        isAlliance={true}
                        status={financialStatus}
                    />
                </Box>

                {[
                    STATUS.REJECTED_STATUS.key,
                    STATUS.CANCELLED_STATUS.key,
                ].includes(financialStatus) && (
                    <Box mb={3}>
                        <Alert variant="danger">
                            {t('The status of this application is')}{' '}
                            {getTranslatedStatusValue(financialStatus)}
                        </Alert>
                    </Box>
                )}

                <Box mb={2} sx={{ width: '100%' }} textAlign="right">
                    <Button
                        color="secondary"
                        onClick={showTermsAndContitions}
                        variant="text"
                    >
                        {t('See terms and conditions')}
                    </Button>
                </Box>

                <Card>
                    <Card.Header>
                        <TitleWithDetail fontSize="16">
                            {t('Product', { count: 2 })}
                        </TitleWithDetail>
                    </Card.Header>
                    <Card.Body>
                        <RequestedProducts
                            allianceProductsList={allianceProductsList}
                            handleOnClickAddProduct={handleOnClickAddProduct}
                            fetchFinarciersInstallerData={
                                fetchFinarciersInstallerData
                            }
                            selectedAlliance={selectedAlliance}
                        />
                    </Card.Body>
                </Card>

                <Card hidden={isEmpty(requestFormat)}>
                    <Card.Header>
                        <TitleWithDetail variant="body2">
                            {t('Application form alliance')}
                        </TitleWithDetail>
                    </Card.Header>

                    <CreditDocuments
                        documents={requestFormat}
                        onClickStatus={onClickStatus}
                        preDownload={true}
                    />
                </Card>

                <Card hidden={isEmpty(documents)}>
                    <Card.Header>
                        <TitleWithDetail variant="body2">
                            {t('Document', { count: 2 })}
                        </TitleWithDetail>
                    </Card.Header>

                    <CreditDocuments
                        documents={documents}
                        onClickStatus={onClickStatus}
                        preDownload={false}
                    />
                </Card>

                <Card hidden={isEmpty(contracts)}>
                    <Card.Header>
                        <TitleWithDetail variant="body2">
                            {t('Contract')}
                        </TitleWithDetail>
                    </Card.Header>

                    <CreditDocuments
                        documents={contracts}
                        onClickStatus={onClickStatus}
                        preDownload={true}
                    />
                </Card>

                <Card>
                    <Card.Header>
                        <TitleWithDetail fontSize="16">
                            {t('Chat')}
                        </TitleWithDetail>
                    </Card.Header>
                    <Card.Body>
                        <ChatView
                            entityId={selectedAlliance.id}
                            user={selectedAlliance.installer}
                        />
                    </Card.Body>
                </Card>
            </Box>

            <ModalTermsConditions
                showModal={showTermsModal}
                setShowModal={setShowTermsModal}
                catalogRequirements={catalogRequirements}
            />
        </>
    );
};

const mapStateToProps = createStructuredSelector({
    allianceProductsList: selectors.getAllianceProductsList,
    catalogRequirements: selectors.getCatalogRequirementsData,
    contracts: selectors.getContractsList,
    documents: selectors.getDocumentsList,
    requestFormat: selectors.getRequestFormatList,
});

const mapDispatchToProps = (dispatch) => ({
    fetchFinarciersInstallerData: (id) =>
        dispatch(actions.fetchFinarciersInstallerData(id)),
    fetchCatalogRequirements: (id) =>
        dispatch(actions.fetchCatalogRequirements(id)),
    handleOnClickAddProduct: (id, callback) =>
        dispatch(actions.addFinancialProduct(id, callback)),
});

SideCard.propTypes = {
    allianceProductsList: PropTypes.array,
    documents: PropTypes.array,
    catalogRequirements: PropTypes.object,
    contracts: PropTypes.array,
    handleOnClickAddProduct: PropTypes.func,
    onClickStatus: PropTypes.func,
    requestFormat: PropTypes.array,
    fetchFinarciersInstallerData: PropTypes.func,
    fetchCatalogRequirements: PropTypes.func,
    selectedAlliance: PropTypes.object,
};

export default connect(mapStateToProps, mapDispatchToProps)(SideCard);
