import React from 'react';

import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from 'sunwise-ui';

import DropdownCardMenu from 'common/components/cards/DropdownCardMenu';
import { parseDate, formatDate } from 'common/utils/dates';
import { handleFileURL, getFileExtensionByPath } from 'common/utils/helpers';

const DocumentListItem = ({
    canDelete,
    canModify,
    createdAt,
    documentType,
    file,
    handleClickDelete,
    handleClickUpdate,
    valueUser,
}) => {
    const { t } = useTranslation();
    const loadedDate = formatDate(
        parseDate(createdAt, 'dd/MM/yyyy HH:mm:ss'),
        'dd/MM/yyyy'
    );

    return (
        <Box display="flex" alignItems="center" gap={2}>
            <Box
                alignItems="center"
                display="flex"
                flexDirection="column"
                justifyContent="center"
                gap={1}
            >
                <InsertDriveFileIcon sx={{ color: '#b4bbdd' }} />
                {file ? getFileExtensionByPath(file) : ''}
            </Box>
            <Box
                alignItems="center"
                display="flex"
                justifyContent="space-between"
                width="100%"
            >
                <Box display="flex" flexDirection="column">
                    <Typography variant="body2" fontWeight="bold">
                        {documentType}
                    </Typography>
                    <Typography variant="body2">{valueUser}</Typography>
                    <Typography variant="caption">
                        {`${t('Loaded')} ${loadedDate}`}
                    </Typography>
                </Box>

                <DropdownCardMenu
                    items={[
                        {
                            handleClick: handleClickUpdate,
                            label: t('Edit'),
                            visible: canModify,
                        },
                        {
                            handleClick: () => {
                                const link = document.createElement('a');
                                link.href = handleFileURL(
                                    file,
                                    process.env.REACT_APP_S3_MEDIA_PATH
                                );
                                link.target = '_blank';
                                document.body.appendChild(link);
                                link.click();
                                link.parentNode.removeChild(link);
                            },
                            label: t('Download'),
                        },
                        {
                            handleClick: handleClickDelete,
                            label: t('Delete'),
                            visible: canDelete,
                        },
                    ]}
                />
            </Box>
        </Box>
    );
};

DocumentListItem.propTypes = {
    canDelete: PropTypes.bool,
    canModify: PropTypes.bool,
    createdAt: PropTypes.string,
    documentType: PropTypes.string,
    file: PropTypes.string,
    handleClickDelete: PropTypes.func,
    handleClickUpdate: PropTypes.func,
    valueUser: PropTypes.string,
};

export default DocumentListItem;
