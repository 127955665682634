import { createSelector } from 'reselect';

import { NAME } from './constants';

export const getModel = (state) => {
    return state[NAME];
};

export const getInitialValues = createSelector(
    getModel,
    (model) => model.initialValues
);

export const geIsOpenModal = createSelector(
    getModel,
    (model) => model.isOpenModal
);

export const getSaveComment = createSelector(
    getModel,
    (model) => model.saveComment
);

export const getSaveCommentData = createSelector(
    getSaveComment,
    (model) => model.data
);

export const getIsSavingComment = createSelector(
    getSaveComment,
    (model) => model.isSaving
);

export const getSaveCommentErrors = createSelector(
    getSaveComment,
    (model) => model.errors
);
