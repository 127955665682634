import { styled } from '@mui/material/styles';
import { Box } from 'sunwise-ui';

export default styled(Box)`
    background-color: #ffffff;
    border-radius: 50%;
    border: 1px solid #eef4fa;
    box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2),
        0px 10px 14px 1px rgba(238, 244, 250, 0.14),
        0px 4px 18px 3px rgba(0, 0, 0, 0.12);
`;
