import { object, string } from 'yup';

import getValidationTexts from 'common/utils/getValidationTexts';

function getCredentialValidation(values, textValidation) {
    const credentialFields = Object.keys(values?.credential || {});
    const isRequired = !values?.id;
    return object()
        .shape(
            credentialFields.reduce((acc, field) => {
                if (isRequired) acc[field] = string().required(textValidation);
                else acc[field] = string().nullable();
                return acc;
            }, {})
        )
        .nullable();
}

export default (values) => {
    const { REQUIRED_TEXT } = getValidationTexts();

    return object().shape({
        credential: getCredentialValidation(values, REQUIRED_TEXT),
        description: string().nullable(),
        name: string()
            .when('id', {
                is: (val) => !val,
                then: () => string().required(REQUIRED_TEXT),
            })
            .nullable(),
        provider_id: string()
            .when('id', {
                is: (val) => !val,
                then: () => string().required(REQUIRED_TEXT),
            })
            .nullable(),
    });
};
