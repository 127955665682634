import { get, isEmpty } from 'lodash';

import * as multiBranchesSelectors from 'common/modules/multiBranches/selectors';
import { getIsMultibranchesAccount } from 'common/utils/helpers/session';

import { getBranchOfficesWithoutGlobal } from '../helpers';

import initializeForm from './initializeForm';
import setIsNewRecord from './setIsNewRecord';
import toggleModalProduct from './toggleModalProduct';

const getBranchOffices = (tempBranchOffices) => {
    if (!isEmpty(tempBranchOffices)) {
        return tempBranchOffices
            .filter(
                (branchOffice) =>
                    get(branchOffice, 'id', null) && branchOffice.checked
            )
            .map((branchOffice) => branchOffice.id)
            .join();
    }
    return '';
};

export const getBranchOfficesDictionaryOptions = (branchOffices) => {
    return branchOffices
        .filter(({ id }) => id !== 'company')
        .reduce((acc, current) => {
            return { ...acc, [current.id]: current.name };
        }, {});
};

export default (item) => (dispatch, getState) => {
    const branchesItems = multiBranchesSelectors.getBranchesItemsData(
        getState()
    );
    const branchOffices = getBranchOfficesWithoutGlobal(branchesItems);
    const branchSelected = multiBranchesSelectors.getBranchSelected(getState());
    const isMultiBranchesUser = getIsMultibranchesAccount();

    let values = {
        birth_date: item.birth_date,
        company_group: item.company_group,
        company: item.company,
        email: item.email,
        first_name: item.first_name,
        has_all_branchoffices: item?.settings?.has_all_branchoffices,
        has_limited_contacts: item.settings?.has_limited_contacts,
        id: item.id,
        is_enabled: item.is_enabled,
        last_name: item.last_name,
        phone: item.phone,
        position: item.position,
        role_level_initial: item.role_level,
        role_level: item.role_level,
        second_surname: item.second_surname,
        user_branch_offices: getBranchOfficesDictionaryOptions(
            item?.branch_offices
        ),
    };

    if (isMultiBranchesUser) {
        values.branch_office = item.branch_office || branchOffices[0]?.value;
        values.branch_offices = getBranchOffices(item.branch_offices);
    } else {
        values.branch_office = item.branch_office || branchSelected;
        values.branch_offices = item.branch_office || branchSelected;
    }

    dispatch(setIsNewRecord(false));
    dispatch(initializeForm(values));
    dispatch(toggleModalProduct(true));
};
