import { get, isNil } from 'lodash';
import { createSelector } from 'reselect';

import { DEFAULT_LAT, DEFAULT_LNG } from 'common/constants';

import { NAME } from './constants';

export const getModel = (state) => state[NAME];

export const getInitialValues = createSelector(
    getModel,
    (model) => model.initialValues
);

export const getSave = createSelector(getModel, (model) => model.save);

export const getIsSaving = createSelector(getSave, (save) => save.isSaving);

export const getDataSave = createSelector(getSave, (save) => save.data);

export const getErrorsSave = createSelector(getSave, (save) => save.errors);

export const getFetch = createSelector(getModel, (model) => model.fetch);

export const getIsFetching = createSelector(
    getFetch,
    (fetch) => fetch.isFetching
);

export const getCompanyData = createSelector(getFetch, (fetch) => fetch.data);

export const getCurrentCompanyCurrency = createSelector(
    getCompanyData,
    (data) => data.currency
);

export const getCompanyCountry = createSelector(
    getCompanyData,
    (data) => data.country
);

export const getCompanyCountryName = createSelector(
    getCompanyData,
    (data) => data.country_name
);

export const getCompanyIsFromMexico = createSelector(
    getCompanyCountryName,
    (country) => country === 'México'
);

export const getCompanyIsFromSpain = createSelector(
    getCompanyCountryName,
    (country) => country === 'España'
);

export const getAccountCountryPosition = createSelector(
    getCompanyData,
    (data) => data.country_position || {}
);

export const getAccountCountryPositionLatLng = createSelector(
    getAccountCountryPosition,
    (data) => {
        return {
            lat: get(data, 'location.latitude', null) || DEFAULT_LAT,
            lng: get(data, 'location.longitude', null) || DEFAULT_LNG,
        };
    }
);

export const getCompanyPosition = createSelector(
    getCompanyData,
    (data) => data.position || {}
);

export const getCompanyPositionLatLng = createSelector(
    getCompanyPosition,
    getAccountCountryPositionLatLng,
    (companyPosition, countryPosition) => {
        if (
            !isNil(companyPosition?.longitude) &&
            !isNil(companyPosition?.longitude)
        ) {
            return {
                lat: companyPosition.latitude,
                lng: companyPosition.longitude,
            };
        }

        return {
            ...countryPosition,
        };
    }
);

export const getErrorsFetch = createSelector(getFetch, (fetch) => fetch.errors);

export const getCompanyImage = createSelector(
    getInitialValues,
    (data) => data.image
);

export const getIsNewRecord = createSelector(
    getModel,
    (model) => model.isNewRecord
);

/* Geocode */
export const getFetchGeocode = createSelector(
    getModel,
    (model) => model.fetchGeocode
);

export const getFetchGeocodeData = createSelector(
    getFetchGeocode,
    (data) => data.data || []
);

export const showGeocodeResults = createSelector(
    getModel,
    (model) => model.showGeocodeResults
);

export const getGeocodePointModel = createSelector(
    getModel,
    (model) => model.fetchGeocodePoint
);

export const getIsFetchingGeocodePoint = createSelector(
    getGeocodePointModel,
    (model) => model.isFetching
);

export const getGeocodePointData = createSelector(
    getGeocodePointModel,
    (model) => model.data
);

export const getGeocodePointErrors = createSelector(
    getGeocodePointModel,
    (model) => model.errors
);
