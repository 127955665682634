import React from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { UiDialog as Dialog } from 'sunwise-ui';

import * as actions from '../actions';
import { getSmartDocumentModalTitle } from '../helpers';
import * as selectors from '../selectors';

import OfferSmartDocumentForm from './OfferSmartDocumentForm';

const OfferSmartDocumentModal = ({
    handleClickCloseModal,
    handleClickDocumentItem,
    isOpenModal,
    offerSmartDocumentFormMode,
    proposalId,
    resetForm,
}) => {
    return (
        <Dialog
            onClose={handleClickCloseModal}
            onExited={() => resetForm()}
            open={isOpenModal}
            size="md"
            title={getSmartDocumentModalTitle(offerSmartDocumentFormMode)}
        >
            <OfferSmartDocumentForm
                handleClickDocumentItem={handleClickDocumentItem}
                offerSmartDocumentFormMode={offerSmartDocumentFormMode}
                proposalId={proposalId}
            />
        </Dialog>
    );
};

const mapStateToProps = createStructuredSelector({
    isOpenModal: selectors.getIsOpenOfferSmartDocumentModal,
    offerSmartDocumentFormMode: selectors.getOfferSmartDocumentFormMode,
});

const mapDispatchToProps = (dispatch) => ({
    handleClickCloseModal: () =>
        dispatch(actions.openOfferSmartDocumentModal(false)),
    resetForm: () => dispatch(actions.resetOfferSmartDocumentForm()),
});

OfferSmartDocumentModal.propTypes = {
    handleClickCloseModal: PropTypes.func,
    handleClickDocumentItem: PropTypes.func,
    isOpenModal: PropTypes.bool,
    offerSmartDocumentFormMode: PropTypes.number,
    proposalId: PropTypes.string,
    resetForm: PropTypes.func,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(OfferSmartDocumentModal);
