export const NAME = 'aftersales-project-status';

export const DATE_FORMATS = {
    HOUR: 'HH:mm',
    TITLE: 'EEEE, LLL d',
};

export const PROGRESS_STATUS = {
    FAILED: 'failed',
    SUCCESS: 'success',
};

export const READ_TYPES_KEYS = {
    DAILY: 1,
    HOURLY: 0,
};

export const READ_TYPES_LABELS = {
    [READ_TYPES_KEYS.DAILY]: 'Daily',
    [READ_TYPES_KEYS.HOURLY]: 'Hourly',
};

export const READ_STATUS_KEYS = {
    ALERT: 1,
    FAILED: 2,
    SUCCESS: 0,
};

export const READ_STATUS = {
    [READ_STATUS_KEYS.ALERT]: { color: '#fdcb6e', label: 'Alert' },
    [READ_STATUS_KEYS.FAILED]: { color: '#d63031', label: 'Failed' },
    [READ_STATUS_KEYS.SUCCESS]: { color: '#00b894', label: 'Success' },
};
