import { getItem } from 'common/api/v1/countries';

import {
    FETCH_COUNTRY,
    FETCH_COUNTRY_FAILURE,
    FETCH_COUNTRY_SUCCESS,
} from '../actionTypes';
import { projectConsumptionModalActions } from '../reducer';

export default (id) => (dispatch) => {
    dispatch(projectConsumptionModalActions[FETCH_COUNTRY]());
    getItem(id)
        .then((response) =>
            dispatch(
                projectConsumptionModalActions[FETCH_COUNTRY_SUCCESS](
                    response.data
                )
            )
        )
        .catch((error) =>
            dispatch(
                projectConsumptionModalActions[FETCH_COUNTRY_FAILURE](error)
            )
        );
};
