import get from 'lodash/get';

import { calculateFinalCost } from 'common/utils/helpers';

import initializeForm from './initializeForm';
import openModalProduct from './openModalProduct';
import setIsNewRecord from './setIsNewRecord';

export default (item) => (dispatch) => {
    const values = {
        branch_offices:
            item.branch_office === null
                ? 'company'
                : get(item, 'branch_office.id', ''),
        brand: item?.brand?.id,
        code: item.code,
        cost: item.cost,
        currency: item?.currency?.id,
        currency_obj: item?.currency,
        data_sheet: item.data_sheet,
        description: item.description,
        final_cost: item.final_cost
            ? item.final_cost
            : calculateFinalCost(item.cost, item.margin),
        id: item.id,
        image_thumbnail: item.product?.image_thumbnail,
        margin: item.margin,
        name: item.name,
        product_type: item.product_type,
        status_flag: item.status_flag,
    };

    dispatch(setIsNewRecord(false));
    dispatch(initializeForm(values));
    dispatch(openModalProduct(true));
};
