import { ONBOARDING_STEPS } from '../constants';

import resetErrors from './resetErrors';
import setCurrentStep from './setCurrentStep';
import updateSection from './updateSection';

export default (values, callback) => (dispatch) => {
    const { inverter_brand, inverters } = values;
    localStorage.setItem(
        'tempInverterSettings',
        JSON.stringify({ inverter_brand, inverters })
    );
    dispatch(resetErrors());
    dispatch(setCurrentStep(values?.step + 1));
    dispatch(updateSection(ONBOARDING_STEPS.BATTERIES));
    if (callback) callback(ONBOARDING_STEPS.BATTERIES);
};
