import API from '../..';

const ENTITY = 'templates';

export const bulkTemplates = (data) =>
    API.put(
        `/api/v1/${ENTITY}/bulk-status-flags/`,
        { ...data },
        { headers: { 'Content-Type': 'application/json' } }
    );
export const filterTemplates = ({
    language = 'es-mx',
    orderBy = 'created_at',
    page = 1,
    perPage = 25,
    proposalsNumber,
    searchText = '',
    sortBy = null,
    status = 'active',
    type,
} = {}) =>
    API.get(`/api/v1/${ENTITY}/`, {
        params: {
            ...(language ? { language } : {}),
            order_by: orderBy,
            page: page,
            per_page: perPage,
            ...(proposalsNumber ? { proposals_number: proposalsNumber } : {}),
            search: searchText,
            ...(searchText ? { search: searchText } : {}),
            sort: sortBy,
            status_flag: status,
            type,
        },
    });
export const getTemplatesCatalog = () =>
    API.get(`/api/v1/${ENTITY}/catalog/`, {
        params: {
            language: 'es-mx',
        },
    });
export const getTemplatesToClone = (countryId = '', language = 'es-mx') =>
    API.get(`/api/v1/templates_to_clone/`, {
        params: { country: countryId, language },
    });
export const setClonePage = (offerCustomTemplateId, offerTemplateId, pages) =>
    API.put(
        `/api/v1/get_custom_template_pages/${offerCustomTemplateId}/${offerTemplateId}`,
        { pages }
    );
