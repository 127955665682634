import { getAllReads } from 'common/api/afterSales/projects';

import {
    FETCH_MONITORING_DATA,
    FETCH_MONITORING_DATA_FAILURE,
    FETCH_MONITORING_DATA_SUCCESS,
} from '../actionTypes';
import { getAfterSalesDateRange } from '../helpers';
import { actions } from '../reducer';

export default ({ initial_date, final_date, freq, ...rest } = {}) =>
    (dispatch) => {
        if (!initial_date || !final_date || !freq) return;
        dispatch(actions[FETCH_MONITORING_DATA]());

        const dateValues = getAfterSalesDateRange({
            final_date,
            freq,
            initial_date,
        });

        getAllReads({ ...dateValues, ...rest })
            .then((response) => {
                dispatch(
                    actions[FETCH_MONITORING_DATA_SUCCESS](response?.data)
                );
            })
            .catch((error) =>
                dispatch(actions[FETCH_MONITORING_DATA_FAILURE](error))
            );
    };
