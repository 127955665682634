import i18next from 'i18next';

import alerts from 'common/modules/alerts';

import * as editProposalCommentModalActions from '../../editProposalCommentModal/actions';
import { getEditMessages } from '../helpers';

import editProposal from './editProposal';

export default (
        financierProduct,
        hasFinancingRequest,
        hasSmartDocuments,
        isApproved,
        isGenerated,
        offerId
    ) =>
    (dispatch) => {
        const message = getEditMessages(
            financierProduct,
            hasSmartDocuments,
            isApproved,
            isGenerated
        );

        const onSuccess = () => {
            dispatch(editProposal(offerId));
            dispatch(alerts.actions.close());
            dispatch(alerts.actions.disableButtons(true));
        };

        const dataAlert = {
            title: i18next.t('Alert'),
            messages: message.messages.map((item) => `${item}.`),
            type: alerts.ALERT_TYPE_CONFIRM,
            variant: message.type,
            confirmText: i18next.t('Continue'),
            cancelText: i18next.t('Back'),
            onSuccess: () => {
                if (hasFinancingRequest)
                    dispatch(
                        editProposalCommentModalActions.prepareAddComment(
                            offerId
                        )
                    );
                else onSuccess();
            },
        };
        dispatch(alerts.actions.show(dataAlert));
    };
