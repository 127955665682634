import React from 'react';

import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box, Divider, Typography } from 'sunwise-ui';

import { BATTERY_STATUS } from '../constants';

const ColorIndicator = styled('span')`
    background: ${({ color }) => color ?? '#ff9a00'};
    border-radius: 7px;
    height: 14px;
    width: 14px;
`;

const InfoTooltipContent = ({ rateTiers }) => {
    const { t } = useTranslation();
    return (
        <Box p={1}>
            <Typography variant="subtitle2">{t('Tiers')}</Typography>
            <Divider />

            {rateTiers?.map((tier, index) => (
                <Typography key={index} variant="body2" mt={1}>
                    {tier?.label ?? ''}
                </Typography>
            ))}

            <Divider sx={{ my: 1 }} />

            <Typography variant="subtitle2">
                {`${t('Status')} (${t('Battery')})`}
            </Typography>

            {BATTERY_STATUS.map(({ color, label }, index) => (
                <Box
                    alignItems="center"
                    display="flex"
                    gap={1}
                    key={index}
                    mt={1}
                >
                    <ColorIndicator color={color} />
                    <Typography key={index} variant="body2">
                        {t(label)}
                    </Typography>
                </Box>
            ))}
        </Box>
    );
};

InfoTooltipContent.propTypes = {
    rateTiers: PropTypes.array,
};

export default InfoTooltipContent;
