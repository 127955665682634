import React, { useEffect, useState } from 'react';

import get from 'lodash/get';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { createStructuredSelector } from 'reselect';
import { Box, Button, Grid } from 'sunwise-ui';

import { PERMISSION_LIST } from 'common/constants/permissionsV2';
import withPermissions from 'common/hocs/withPermissions';

import * as profileSelectors from '../../profile/selectors';
import * as actions from '../actions';
import * as selectors from '../selectors';

const AccessRestrictedBanner = ({
    canModify,
    children,
    chargebeeIntegration,
    fetchChargebeeIntegration,
    handleChargebeeCheckout,
    minHeight = '100%',
    redirectTo,
    subscription,
}) => {
    const [cbInstance, setCbInstance] = useState(null);
    const { t } = useTranslation();

    useEffect(() => {
        if (!process.env.REACT_APP_CHARGEBEE_KEY) return;
        setCbInstance(
            window.Chargebee.init({ site: process.env.REACT_APP_CHARGEBEE_KEY })
        );
        fetchChargebeeIntegration();
    }, []);

    const handleOnGetAddon = () => {
        handleChargebeeCheckout({
            cbInstance,
            itemPriceId: get(chargebeeIntegration, 'item_price_id', null),
            redirectTo,
        });
    };

    const addons = get(subscription, 'addons', []);

    if (addons.includes('Cotizador-Web')) return children;

    return (
        <Box display="flex" flexDirection="column" gap={1} height="100%">
            <Grid container p={canModify ? 2 : 0}>
                <Grid item xs sx={{ textAlign: 'right' }}>
                    <Button
                        onClick={handleOnGetAddon}
                        sx={{ ml: { md: 'auto', xs: 0 }, mt: 1 }}
                        visible={canModify}
                    >
                        {t('Get access')}
                    </Button>
                </Grid>
            </Grid>

            <iframe
                src="https://www.sunwise.io/cotizador-web-integracion"
                style={{
                    border: 0,
                    height: minHeight,
                    width: '100%',
                }}
            />
        </Box>
    );
};

AccessRestrictedBanner.propTypes = {
    canModify: PropTypes.bool,
    chargebeeIntegration: PropTypes.object,
    children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    fetchChargebeeIntegration: PropTypes.func,
    handleChargebeeCheckout: PropTypes.func,
    minHeight: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    redirectTo: PropTypes.string,
    subscription: PropTypes.object,
};

const mapStateToProps = createStructuredSelector({
    chargebeeIntegration: selectors.getChargebeeIntegrationData,
    subscription: profileSelectors.getSubscription,
});

const mapDispatchToProps = (dispatch) => ({
    fetchChargebeeIntegration: () =>
        dispatch(actions.fetchChargebeeIntegration()),
    handleChargebeeCheckout: (data) =>
        dispatch(actions.handleChargebeeCheckout(data)),
});

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withPermissions(PERMISSION_LIST.SUBSCRIPTION_PERMISSION)
)(AccessRestrictedBanner);
