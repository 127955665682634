import { saveTableSettings } from 'common/api/v1/core';
import { showReponseErrorsAsAlert } from 'common/utils/helpers';
import showToast from 'common/utils/showToast';

import fetchUserSettings from '../../profile/actions/fetchProfileData';
import {
    SAVE_NOTIFICATION_SETTINGS,
    SAVE_NOTIFICATION_SETTINGS_SUCCESS,
    SAVE_NOTIFICATION_SETTINGS_FAILURE,
} from '../actionTypes';
import { notificationsSettingsActions } from '../reducer';

import closeModal from './closeModal';

export default (values) => (dispatch) => {
    const { settings } = values;
    dispatch(notificationsSettingsActions[SAVE_NOTIFICATION_SETTINGS]());
    saveTableSettings({ settings, key: 'notifications' })
        .then(() => {
            dispatch(
                notificationsSettingsActions[
                    SAVE_NOTIFICATION_SETTINGS_SUCCESS
                ]()
            );
            dispatch(fetchUserSettings());
            dispatch(closeModal());
            showToast();
        })
        .catch((error) => {
            dispatch(
                notificationsSettingsActions[
                    SAVE_NOTIFICATION_SETTINGS_FAILURE
                ](error?.response?.data?.errors)
            );
            showReponseErrorsAsAlert(dispatch, error?.response);
        });
};
