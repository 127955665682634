import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { UiDialog as Dialog } from 'sunwise-ui';

import TextVariant from 'common/components/TextVariant';

import * as actions from '../actions';
import * as selectors from '../selectors';

import DuplicateForm from './DuplicateForm';

const DuplicateFormModal = ({
    handleClickCloseModal,
    isOpenDuplicateModal,
    resetForm,
}) => {
    const { t } = useTranslation();
    return (
        <Dialog
            onClose={handleClickCloseModal}
            onExited={() => resetForm()}
            open={isOpenDuplicateModal}
            size="xs"
            title={t('Duplicate proposal')}
        >
            <TextVariant
                text={t(
                    'All data will be duplicated in a new proposal, except for any financial product. (own or alliance)'
                )}
            />

            <DuplicateForm handleClickCloseModal={handleClickCloseModal} />
        </Dialog>
    );
};
const mapStateToProps = createStructuredSelector({
    isOpenDuplicateModal: selectors.getIsOpenDuplicateModal,
});

const mapDispatchToProps = (dispatch) => ({
    handleClickCloseModal: () => dispatch(actions.openDuplicateModal(false)),
    resetForm: () => dispatch(actions.resetDuplicateForm()),
});

DuplicateFormModal.propTypes = {
    handleClickCloseModal: PropTypes.func,
    isOpenDuplicateModal: PropTypes.bool,
    resetForm: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(DuplicateFormModal);
