import React from 'react';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import LinearProgress from '@mui/material/LinearProgress';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box, DataGrid, Tooltip } from 'sunwise-ui';

import { CustomPagination } from 'common/components';

import { getIsVisible } from '../helpers';

const Review = ({ columns, getValues, matchedData }) => {
    const { t } = useTranslation();
    if (!columns) return null;

    const _columns = columns
        .filter(({ visible = true }) => getIsVisible(visible, getValues))
        .map(({ columnName, displayLabel }) => ({
            field: columnName,
            headerName: displayLabel,
            flex: 1,
            minWidth: 200,
            renderCell: ({ field, row }) => {
                const value = row[field];
                const error = row?.__errors?.[field];
                if (error)
                    return (
                        <Tooltip title={error}>
                            <Box display="flex" gap={2}>
                                <ReportProblemIcon color="error" />
                                {value}
                            </Box>
                        </Tooltip>
                    );

                return (
                    <Box display="flex" gap={2}>
                        <CheckCircleIcon color="success" />
                        {value}
                    </Box>
                );
            },
        }));

    _columns.unshift({
        field: 'index',
        headerName: t('Row'),
        valueGetter: (params) => params.row.id + 1,
        width: 80,
    });

    return (
        <DataGrid
            autoHeight
            columns={_columns}
            components={{
                LoadingOverlay: LinearProgress,
                Pagination: CustomPagination,
            }}
            componentsProps={{
                pagination: { rowsPerPageOptions: [25, 50, 75, 100] },
            }}
            disableColumnMenu
            disableSelectionOnClick
            editMode="row"
            experimentalFeatures={{ newEditingApi: true }}
            getRowHeight={() => 'auto'}
            initialState={{ pagination: { pageSize: 25 } }}
            rows={matchedData || []}
        />
    );
};

Review.propTypes = {
    columns: PropTypes.array,
    getValues: PropTypes.func,
    matchedData: PropTypes.array,
};

export default Review;
