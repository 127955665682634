import React from 'react';

import PropTypes from 'prop-types';
import { useFieldArray } from 'react-hook-form';

import { getMinInitialDate } from '../../../../helpers';

import IntakeCaptureField from './IntakeCaptureField';

const IntakeCaptureFieldArray = ({
    control,
    disabled,
    disabledConsumptionFieldsByCsv,
    getValues,
    handleNormalizeDates,
    isGDRate,
    name,
    profilesConsumptionData,
    ratesDictionary,
    selectedRate,
    setValue,
    showAvgDailyField,
    summaryWarnings,
}) => {
    const { fields } = useFieldArray({ control, name });
    const maxDate = new Date();
    const minDate = getMinInitialDate();

    return fields.map((item, index) => (
        <IntakeCaptureField
            control={control}
            disabled={disabled}
            disabledConsumptionFieldsByCsv={disabledConsumptionFieldsByCsv}
            getValues={getValues}
            handleNormalizeDates={handleNormalizeDates}
            index={index}
            isGDRate={isGDRate}
            key={item.id}
            maxDate={maxDate}
            minDate={minDate}
            name={`${name}.${index}`}
            period={item}
            periodWarnings={summaryWarnings[index]}
            profilesConsumptionData={profilesConsumptionData}
            ratesDictionary={ratesDictionary}
            selectedRate={selectedRate}
            setValue={setValue}
            showAvgDailyField={showAvgDailyField}
        />
    ));
};

IntakeCaptureFieldArray.propTypes = {
    control: PropTypes.object,
    disabled: PropTypes.bool,
    disabledConsumptionFieldsByCsv: PropTypes.bool,
    getValues: PropTypes.func,
    handleNormalizeDates: PropTypes.func,
    isGDRate: PropTypes.bool,
    name: PropTypes.string,
    profilesConsumptionData: PropTypes.array,
    ratesDictionary: PropTypes.object,
    selectedRate: PropTypes.object,
    setValue: PropTypes.func,
    showAvgDailyField: PropTypes.bool,
    summaryWarnings: PropTypes.array,
};

export default IntakeCaptureFieldArray;
