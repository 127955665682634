import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import { Autocomplete, TextField } from 'sunwise-ui';

const SingleAutocomplete = ({
    disableClearable,
    disabled,
    fullWidth,
    getOptionDisabled,
    isLoading,
    label,
    onChange,
    options,
    renderOption,
    value,
    variant,
}) => {
    const [currentValue, setCurrentValue] = useState(null);

    useEffect(() => {
        setCurrentValue(value);
    }, [value]);

    const optionsWithGroup = [];
    options?.forEach((group) => {
        if (group?.options) {
            group?.options?.forEach((option) =>
                optionsWithGroup.push({ ...option, group: group.label })
            );
        } else {
            optionsWithGroup.push({ ...group, group: '' });
        }
    });

    const sortedOptions = optionsWithGroup.sort(
        (a, b) => -b.group?.localeCompare(a.group)
    );

    const _renderOption = renderOption
        ? renderOption
        : (props, option) => (
              <li {...props} key={option.value}>
                  {option.label}
              </li>
          );

    return (
        <Autocomplete
            disableClearable={disableClearable}
            disabled={disabled}
            blurOnSelect={true}
            fullWidth={fullWidth}
            getOptionDisabled={getOptionDisabled}
            getOptionLabel={(option) => option?.label || ''}
            groupBy={(option) => option.group}
            isOptionEqualToValue={(option, currentValue) =>
                option.value === currentValue?.value
            }
            renderOption={_renderOption}
            loading={isLoading}
            renderInput={(params) => (
                <TextField {...params} label={label} variant={variant} />
            )}
            onChange={(_, newValue) => {
                setCurrentValue(newValue);
                if (onChange) onChange(newValue);
            }}
            options={sortedOptions}
            sx={{ width: fullWidth === true ? '100%' : 200 }}
            value={currentValue}
        />
    );
};

SingleAutocomplete.defaultProps = {
    fullWidth: false,
    value: null,
    variant: 'filled',
};

SingleAutocomplete.propTypes = {
    disableClearable: PropTypes.bool,
    disabled: PropTypes.bool,
    fullWidth: PropTypes.bool,
    getOptionDisabled: PropTypes.func,
    isLoading: PropTypes.bool,
    label: PropTypes.string,
    onChange: PropTypes.func,
    options: PropTypes.array,
    renderOption: PropTypes.func,
    value: PropTypes.object,
    variant: PropTypes.string,
};

export default SingleAutocomplete;
