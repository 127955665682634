import { getLocation } from 'common/api/v1/aftersalesLocation';

import {
    FETCH_DEFAULT_LOCATION,
    FETCH_DEFAULT_LOCATION_FAILURE,
    FETCH_DEFAULT_LOCATION_SUCCESS,
} from '../actionTypes';
import { actions } from '../reducer';

import initializeSettingsForm from './initializeSettingsForm';

export default (id) => (dispatch) => {
    if (!id) return;
    dispatch(actions[FETCH_DEFAULT_LOCATION]());

    getLocation(id)
        .then((response) => {
            const data = response?.data?.data;
            const latitude = data?.latitude || null;
            const longitude = data?.longitude || null;
            const newInitialValues = {
                latitude,
                longitude,
                position: { latitude, longitude },
            };
            dispatch(actions[FETCH_DEFAULT_LOCATION_SUCCESS](data));
            dispatch(initializeSettingsForm(newInitialValues));
        })
        .catch((error) =>
            dispatch(actions[FETCH_DEFAULT_LOCATION_FAILURE](error))
        );
};
