import { bulkActivateItems } from 'common/api/v1/sunpay';
import { showReponseErrorsAsAlert } from 'common/utils/helpers';
import showToast from 'common/utils/showToast';

import {
    BULK_UPDATE_ITEMS,
    BULK_UPDATE_ITEMS_FAILURE,
    BULK_UPDATE_ITEMS_SUCCESS,
    UPDATE_ITEMS_SUCCESS,
} from '../actionTypes';
import { alliancesActions } from '../reducer';
import * as selectors from '../selectors';

export default (ids, active, callback) => (dispatch, getState) => {
    const state = getState();
    const data = selectors.getFinancierProductData(state);

    dispatch(alliancesActions[BULK_UPDATE_ITEMS]());

    const products = ids.map((id) => ({
        id,
        is_active: active,
    }));

    bulkActivateItems({ products })
        .then((response) => {
            dispatch(
                alliancesActions[BULK_UPDATE_ITEMS_SUCCESS](response.data)
            );

            const newItems = data.map((item) => {
                if (ids.includes(item.id)) {
                    return {
                        ...item,
                        enabled: active,
                    };
                }
                return item;
            });
            dispatch(alliancesActions[UPDATE_ITEMS_SUCCESS](newItems));
            callback();
            showToast();
        })
        .catch((error) => {
            dispatch(
                alliancesActions[BULK_UPDATE_ITEMS_FAILURE](
                    error?.response?.data?.errors
                )
            );
            showReponseErrorsAsAlert(dispatch, error?.response);
        });
};
