import React, { useRef } from 'react';

import get from 'lodash/get';
import PropTypes from 'prop-types';
import { Box } from 'sunwise-ui';

import SwipeableDrawer from 'common/components/SwipeableDrawer';
import SidebarIndicators from 'common/modules/proposalIndicators';

import proposalGeneratorTemplate from '../../proposalGeneratorTemplate';

const SupportDrawer = ({
    batteriesOfferValues,
    compensationScheme,
    currencyIso,
    currencyLocale,
    financialProductParameters,
    isFetchingOfferDetails,
    isGenerated,
    maxDailyConsumption,
    nextRateData,
    offerDetails,
    offerDetailsComplements,
    offerDetailsConsumption,
    offerId,
    proposalConfiguration,
    totalsSolarSimulation,
}) => {
    const swipeableDrawerRef = useRef(null);

    return (
        <SwipeableDrawer ref={swipeableDrawerRef}>
            <>
                <Box sx={{ mb: 2 }}>
                    <SidebarIndicators.Container
                        batteriesOfferValues={batteriesOfferValues}
                        compensationScheme={compensationScheme}
                        currencyIso={currencyIso}
                        currencyLocale={currencyLocale}
                        isLoading={isFetchingOfferDetails}
                        maxDailyConsumption={maxDailyConsumption}
                        nextRateData={nextRateData}
                        offerDetailsComplements={offerDetailsComplements}
                        offerDetailsConsumption={offerDetailsConsumption}
                        proposalConfiguration={proposalConfiguration}
                        proposalId={offerId}
                        totalsSolarSimulation={totalsSolarSimulation}
                    />
                </Box>

                <proposalGeneratorTemplate.Container
                    financialProductParameters={financialProductParameters}
                    isGenerated={isGenerated}
                    isLoading={isFetchingOfferDetails}
                    offerDetails={offerDetails}
                    offerPlanId={get(
                        offerDetailsComplements,
                        'proposal_plan',
                        null
                    )}
                    offerTemplateId={get(
                        offerDetailsComplements,
                        'custom_offer_template',
                        null
                    )}
                    proposalId={offerId}
                    reverseBtn
                />
            </>
        </SwipeableDrawer>
    );
};

SupportDrawer.propTypes = {
    batteriesOfferValues: PropTypes.object,
    compensationScheme: PropTypes.object,
    currencyIso: PropTypes.string,
    currencyLocale: PropTypes.string,
    financialProductParameters: PropTypes.object,
    isFetchingOfferDetails: PropTypes.bool,
    isGenerated: PropTypes.bool,
    maxDailyConsumption: PropTypes.number,
    nextRateData: PropTypes.object,
    offerDetails: PropTypes.object,
    offerDetailsComplements: PropTypes.object,
    offerDetailsConsumption: PropTypes.object,
    offerId: PropTypes.string,
    proposalConfiguration: PropTypes.object,
    totalsSolarSimulation: PropTypes.object,
};

export default SupportDrawer;
