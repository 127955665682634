import importConsumptionsModal from '../../importConsumptionsModal';

import saveProject from './saveProject';

export default (values, callbackSuccess) => (dispatch) => {
    dispatch(
        saveProject(values, callbackSuccess, () => {
            dispatch(importConsumptionsModal.actions.setIsOpen(true));
        })
    );
};
