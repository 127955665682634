import React from 'react';

import { styled } from '@mui/material/styles';
import { push } from 'connected-react-router';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Box, Button } from 'sunwise-ui';

import { ReactComponent as NotFoundInteractiveProject } from 'resources/notFoundInteractiveProject.svg';

const Container = styled(Box)`
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    margin: 0 auto;
    max-width: 512px;
    padding: 30px;
    width: 100%;
    svg {
        max-width: 100%;
    }
`;

const StyledMessage = styled(Box)`
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
    margin: 34px 0;
    text-align: center;
`;

const InteractiveProjectNotFound = ({
    buttonLabel = 'Back to Sunwise',
    redirect,
    redirectTo = '/',
}) => {
    const { t } = useTranslation();
    return (
        <Container>
            <NotFoundInteractiveProject />
            <StyledMessage>
                {t('Oops! This item is no longer current or has been removed')}
            </StyledMessage>

            <StyledMessage>
                <Button onClick={() => redirect(redirectTo)}>
                    {t(buttonLabel)}
                </Button>
            </StyledMessage>
        </Container>
    );
};

InteractiveProjectNotFound.propTypes = {
    buttonLabel: PropTypes.string,
    redirect: PropTypes.func,
    redirectTo: PropTypes.string,
};

const mapDispatchToProps = (dispatch) => ({
    redirect: (link) => dispatch(push(link)),
});

export default connect(null, mapDispatchToProps)(InteractiveProjectNotFound);
