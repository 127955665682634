import i18next from 'i18next';

import { parseJSON } from 'common/utils/helpers';

import { INITIAL_VALUES } from '../actionTypes';
import {
    DEFAULT_ALGORITHM,
    DEFAULT_C_RATE,
    DEFAULT_DEGRADATION,
    DEFAULT_DOD,
    DEFAULT_EFFICIENCY,
} from '../constants';
import { getFormattedValue, getTierFields } from '../helpers';
import { advancedTimeShiftSettingsActions } from '../reducer';

export default (dataOfferTimeShifting) => (dispatch) => {
    const {
        algorithm,
        c_rate,
        charge_limits,
        charging_algorithm,
        controlled_charging_is_active,
        controlled_discharging_is_active,
        degradation_percentage,
        discharge_limits,
        discharge_power,
        dod,
        efficiency,
        exported_energy_charge,
        hourly_shifting,
        id,
        load_capacity,
        peak_shaving,
        peak_shaving_is_active,
        rate,
        timeShiftingConfiguration,
    } = dataOfferTimeShifting || {};

    const outSummerData = hourly_shifting?.find((item) => !item.is_summer);
    const summerData = hourly_shifting?.find((item) => item.is_summer);

    const chargeLimits = parseJSON(charge_limits);
    const dischargeLimits = parseJSON(discharge_limits);
    const outSummerIndicators = parseJSON(outSummerData?.indicators);
    const peakShaving = parseJSON(peak_shaving);
    const summerIndicators = parseJSON(summerData?.indicators);

    const summerPeakIndicator = summerIndicators?.find(
        (item) => item.name === 'Peak'
    );
    const summerMidIndicator = summerIndicators?.find(
        (item) => item.name === 'Middle'
    );
    const outSummerPeakIndicator = outSummerIndicators?.find(
        (item) => item.name === 'Peak'
    );
    const outSummerMidIndicator = outSummerIndicators?.find(
        (item) => item.name === 'Middle'
    );

    const max_peak_demand_year = {
        out_summer:
            outSummerPeakIndicator?.max_demand ||
            outSummerMidIndicator?.max_demand ||
            0,
        summer:
            summerPeakIndicator?.max_demand ||
            summerMidIndicator?.max_demand ||
            0,
    };

    const demandTiers = timeShiftingConfiguration?.schedule_rate_configuration
        ?.tiers_demand_distribution?.[0]?.tiers || [{ identifier: 0 }];

    const peakShavingFields = getTierFields({
        rate,
        tiers: demandTiers,
        values: peakShaving,
    });
    const chargeLimitsFields = getTierFields({
        rate,
        tiers: demandTiers,
        values: chargeLimits,
    });
    const dischargeLimitsFields = getTierFields({
        rate,
        tiers: demandTiers,
        values: dischargeLimits,
    });

    const dischargePowerPlaceholder =
        getFormattedValue(max_peak_demand_year.summer || 0) || '0';

    const values = {
        algorithm: algorithm ?? DEFAULT_ALGORITHM,
        c_rate: c_rate ?? DEFAULT_C_RATE,
        charging_algorithm: charging_algorithm ?? DEFAULT_ALGORITHM,
        charge_limits: chargeLimitsFields,
        controlled_charging_is_active: controlled_charging_is_active || false,
        controlled_discharging_is_active:
            controlled_discharging_is_active || false,
        degradation_percentage:
            degradation_percentage || degradation_percentage === 0
                ? getFormattedValue(degradation_percentage * 100)
                : DEFAULT_DEGRADATION,
        discharge_limits: dischargeLimitsFields,
        discharge_power: !discharge_power
            ? dischargePowerPlaceholder
            : getFormattedValue(discharge_power),
        discharge_power_placeholder: dischargePowerPlaceholder,
        dod: dod || dod === 0 ? getFormattedValue(dod * 100) : DEFAULT_DOD,
        efficiency: getFormattedValue(efficiency * 100 || DEFAULT_EFFICIENCY),
        exported_energy_charge: exported_energy_charge || false,
        id: id || null,
        load_capacity: getFormattedValue(load_capacity) || '0',
        out_summer: outSummerIndicators?.map((item) => ({
            ...item,
            name: rate.certified
                ? i18next.t(item.name, { context: 'rate' })
                : item.name,
        })),
        peak_shaving: peakShavingFields,
        peak_shaving_is_active: peak_shaving_is_active || false,
        summer: summerIndicators?.map((item) => ({
            ...item,
            name: rate.certified
                ? i18next.t(item.name, { context: 'rate' })
                : item.name,
        })),
    };

    dispatch(advancedTimeShiftSettingsActions[INITIAL_VALUES](values));
};
