import React from 'react';

import {
    LocalizationProvider,
    MobileDateTimePicker,
} from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import es from 'date-fns/locale/es';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { formatDate, isValidDate, parseDate } from 'common/utils/dates';
import {
    getDateTimeFormatByLocale,
    getTimezoneUserSettings,
} from 'common/utils/helpers/multiregion';

const RHFDateTimePicker = ({
    control,
    disableFuture,
    disablePast,
    disabled,
    format = getDateTimeFormatByLocale(),
    fullWidth = true,
    inputFormat = 'yyyy-MM-dd H:mm',
    label,
    maxDate,
    minDate,
    name,
    onChange,
    placeholder,
    readOnly,
    sxInput = {},
    variant = 'outlined',
    views,
}) => {
    const { t } = useTranslation();
    const onChangeAction = ({ date, inputFormat, onChange, onChangeField }) => {
        if (isValidDate(date)) {
            const formatedDate = formatDate(date, inputFormat);
            onChangeField(formatedDate);
            if (onChange) onChange(formatedDate);
        } else if (date === null) {
            onChangeField(null);
            if (onChange) onChange(null);
        }
    };

    return (
        <Controller
            name={name}
            control={control}
            render={({
                field: { onChange: onChangeField, value },
                fieldState: { error },
            }) => (
                <LocalizationProvider dateAdapter={AdapterDateFns} locale={es}>
                    <MobileDateTimePicker
                        disabled={disabled}
                        disableFuture={disableFuture}
                        disablePast={disablePast}
                        format={format}
                        inputFormat={inputFormat}
                        label={label}
                        localeText={{
                            toolbarTitle: label,
                            cancelButtonLabel: t('Cancel'),
                        }}
                        maxDate={maxDate}
                        minDate={minDate}
                        timeSteps={{ hours: 1, minutes: 1, seconds: 1 }}
                        onChange={(date) =>
                            onChangeAction({
                                date,
                                inputFormat,
                                onChange,
                                onChangeField,
                            })
                        }
                        readOnly={readOnly}
                        slotProps={{
                            textField: {
                                error: Boolean(error),
                                fullWidth,
                                helperText: error?.message,
                                placeholder,
                                variant,
                                sx: sxInput,
                            },
                            toolbar: {
                                sx: {
                                    '& > .MuiPickersToolbar-content': { m: 0 },
                                },
                            },
                        }}
                        timezone={getTimezoneUserSettings()}
                        value={value ? parseDate(value, inputFormat) : null}
                        views={views}
                    />
                </LocalizationProvider>
            )}
        />
    );
};

RHFDateTimePicker.propTypes = {
    control: PropTypes.object.isRequired,
    disableFuture: PropTypes.bool,
    disablePast: PropTypes.bool,
    disabled: PropTypes.bool,
    format: PropTypes.string,
    fullWidth: PropTypes.bool,
    inputFormat: PropTypes.string,
    label: PropTypes.string,
    maxDate: PropTypes.instanceOf(Date),
    minDate: PropTypes.instanceOf(Date),
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func,
    placeholder: PropTypes.string,
    readOnly: PropTypes.bool,
    sxInput: PropTypes.object,
    variant: PropTypes.string,
    views: PropTypes.arrayOf(
        PropTypes.oneOf(['year', 'month', 'day', 'hours', 'minutes'])
    ),
};

export default RHFDateTimePicker;
