import { renameProject } from 'common/api/v1/contactProject';
import alerts from 'common/modules/alerts';
import { showReponseErrorsAsAlert } from 'common/utils/helpers';
import showToast from 'common/utils/showToast';

import {
    RENAME_PROJECT,
    RENAME_PROJECT_FAILURE,
    RENAME_PROJECT_SUCCESS,
} from '../actionTypes';
import { projectActions } from '../reducer';

import fetchProjectDetails from './fetchProjectDetails';
import fetchProjects from './fetchProjects';

export default (filterData, project) => (dispatch) => {
    dispatch(projectActions[RENAME_PROJECT]());

    const newValues = {
        name: project.name,
    };

    renameProject(project.id, newValues)
        .then(() => {
            dispatch(projectActions[RENAME_PROJECT_SUCCESS]());
            dispatch(alerts.actions.close());
            dispatch(fetchProjects(filterData));
            dispatch(fetchProjectDetails(project.id));
            showToast();
        })
        .catch((error) => {
            dispatch(
                projectActions[RENAME_PROJECT_FAILURE](
                    error?.response?.data?.errors
                )
            );
            showReponseErrorsAsAlert(dispatch, error?.response);
        });
};
