import { createSelector } from 'reselect';

import { NAME } from './constants';

export const getModel = (state) => state[NAME];

export const getIsOpenIndicatorsSettingsModal = createSelector(
    getModel,
    (model) => model?.isOpenIndicatorsSettingsModal
);

export const getIndicatorsSettings = createSelector(
    getModel,
    (model) => model.indicatorsSettings
);

export const getIndicatorsSettingsData = createSelector(
    getIndicatorsSettings,
    (value) => value?.data
);

// currencies
export const getCurrencies = createSelector(
    getModel,
    (model) => model?.currencies
);

export const getCurrenciesData = createSelector(
    getCurrencies,
    (value) => value?.data
);

export const getCurrenciesDataForSelect = createSelector(
    getCurrenciesData,
    (value) =>
        value?.map((item) => ({
            label: item?.currency?.name,
            value: item?.currency?.abbreviation,
        })) || []
);

export const getCurrenciesErrors = createSelector(
    getCurrencies,
    (value) => value?.errors
);

export const getIsFetchingCurrencies = createSelector(
    getCurrencies,
    (value) => value?.isFetching
);
