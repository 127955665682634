import { fetchListCatalogs } from 'common/api/v1/companiesCatalogs';

import {
    FETCH_COMPANIES_CATALOGS,
    FETCH_COMPANIES_CATALOGS_FAILURE,
    FETCH_COMPANIES_CATALOGS_SUCCESS,
} from '../actionTypes';
import { listSettingsActions } from '../reducer';

import setType from './setType';

export default (type) => (dispatch) =>
    new Promise((resolve) => {
        dispatch(setType(type));
        dispatch(listSettingsActions[FETCH_COMPANIES_CATALOGS]());

        fetchListCatalogs(type)
            .then((response) => {
                dispatch(
                    listSettingsActions[FETCH_COMPANIES_CATALOGS_SUCCESS](
                        response.data.data
                    )
                );
            })
            .catch((error) =>
                dispatch(
                    listSettingsActions[FETCH_COMPANIES_CATALOGS_FAILURE](error)
                )
            )
            .finally(resolve);
    });
