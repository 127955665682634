import { createSelector } from 'reselect';

import { NAME } from './constants';

export const getModel = (state) => state[NAME];

/* CREATE */
export const getCreate = createSelector(getModel, (model) => model.create);
export const getCreateData = createSelector(
    getCreate,
    (value) => value.data || null
);
export const getCreateErrors = createSelector(
    getCreate,
    (value) => value.errors
);
export const getCreateIsLoading = createSelector(
    getCreate,
    (value) => value.isLoading
);

/* SAVE */
export const getSave = createSelector(getModel, (model) => model.save);
export const getDataSave = createSelector(getSave, (value) => value.data || []);
export const getErrorsSave = createSelector(getSave, (value) => value.errors);
export const getIsSaving = createSelector(getSave, (value) => value.isSaving);
