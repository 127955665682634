import React from 'react';

import PropTypes from 'prop-types';
import { Button } from 'sunwise-ui';

import { getIsMultibranchesAccount } from 'common/utils/helpers/session';

import { getProductBranchOfficesIds } from '../helpers';

import SelectedIcon from './SelectedIcon';
import SelectedText from './SelectedText';

const ProductBranchOfficesFormatter = ({
    branchOfficesDictionary,
    branchSelected = 'all',
    canModify,
    openModal,
    product,
    submitChanges,
}) => {
    const productBranchIds = getProductBranchOfficesIds(
        branchOfficesDictionary,
        product,
        branchSelected
    );

    return (
        <Button
            color="primary"
            className={`__userguiding_add_product_button ${
                branchSelected !== 'all' &&
                (!canModify || productBranchIds.indexOf(branchSelected) >= 0)
                    ? 'disabled'
                    : ''
            }`}
            endIcon={
                <SelectedIcon
                    branchSelected={branchSelected}
                    productBranches={productBranchIds}
                />
            }
            onClick={(evt) =>
                branchSelected === 'all' && getIsMultibranchesAccount()
                    ? openModal(evt)
                    : submitChanges([branchSelected])
            }
            sx={{ width: '175px' }}
            variant="outlined"
        >
            <SelectedText
                branchOfficesDictionary={branchOfficesDictionary}
                branchSelected={branchSelected}
                productBranches={productBranchIds}
            />
        </Button>
    );
};

ProductBranchOfficesFormatter.propTypes = {
    branchOfficesDictionary: PropTypes.object,
    branchSelected: PropTypes.string,
    canModify: PropTypes.bool,
    openModal: PropTypes.func,
    product: PropTypes.object,
    submitChanges: PropTypes.func,
};

export default ProductBranchOfficesFormatter;
