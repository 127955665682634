import i18next from 'i18next';

import { updateOfferEnded } from 'common/api/v1/newOfferEnded';
import { showReponseErrorsAsAlert } from 'common/utils/helpers';
import showToast from 'common/utils/showToast';

import {
    UPDATE_TEMPLATE,
    UPDATE_TEMPLATE_SUCCESS,
    UPDATE_TEMPLATE_FAILURE,
} from '../actionTypes';
import { multiTemplateReviewActions } from '../reducer';

const CONVERT_TO_TEMPLATE = 2;

export default (values) => (dispatch) => {
    const { proposalId, name } = values;
    dispatch(multiTemplateReviewActions[UPDATE_TEMPLATE]());

    updateOfferEnded(proposalId, {
        new_template_title: name,
        save_option: CONVERT_TO_TEMPLATE,
    })
        .then(() => {
            showToast({ body: i18next.t('Template was successfully saved') });
            dispatch(multiTemplateReviewActions[UPDATE_TEMPLATE_SUCCESS]());
        })
        .catch((error) => {
            dispatch(
                multiTemplateReviewActions[UPDATE_TEMPLATE_FAILURE](
                    error?.response?.data?.errors
                )
            );
            showReponseErrorsAsAlert(dispatch, error?.response);
        });
};
