import { getData } from 'common/api/v1/report';

import {
    FETCH_REPORT_DETAILS,
    FETCH_REPORT_DETAILS_FAILURE,
    FETCH_REPORT_DETAILS_SUCCESS,
} from '../actionTypes';
import { actions } from '../reducer';

export default (uuid) => (dispatch) =>
    new Promise((resolve) => {
        dispatch(actions[FETCH_REPORT_DETAILS]());

        getData(uuid)
            .then((response) => {
                dispatch(
                    actions[FETCH_REPORT_DETAILS_SUCCESS](response?.data?.data)
                );
                resolve(response?.data);
            })
            .catch((error) => {
                dispatch(
                    actions[FETCH_REPORT_DETAILS_FAILURE](
                        error?.response?.data?.errors
                    )
                );
                resolve(null);
            });
    });
