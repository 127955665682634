import get from 'lodash/get';

import { updateAdvancedGenerationSettings } from 'common/api/v2/offerConsumptionAndGeneration';
import alerts from 'common/modules/alerts';
import { showReponseErrorsAsAlert } from 'common/utils/helpers';

import eventsAfterUpdate from '../../proposalGeneratorOneColumn/actions/eventsAfterUpdate';
import { SAVE, SAVE_FAILURE, SAVE_SUCCESS } from '../actionTypes';
import { advancedGenerationSettingsAction } from '../reducer';

export default (proposalId, values) => (dispatch) => {
    const generation = get(values, 'generation', null);

    const newValues = {
        generation: generation,
        remove_file: true,
        source: 0,
    };

    dispatch(advancedGenerationSettingsAction[SAVE]());

    updateAdvancedGenerationSettings(newValues, proposalId)
        .then((response) => {
            dispatch(
                advancedGenerationSettingsAction[SAVE_SUCCESS](
                    response.data.data
                )
            );
            dispatch(eventsAfterUpdate(response.data.data, proposalId));

            dispatch(alerts.actions.close());
        })
        .catch((error) => {
            dispatch(
                advancedGenerationSettingsAction[SAVE_FAILURE](
                    error?.response?.data?.errors
                )
            );
            showReponseErrorsAsAlert(dispatch, error?.response);
        });
};
