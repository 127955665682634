import { connect } from 'common/sockets/afterSalesProjects';

import * as selectors from '../selectors';

export default (companyId) => (dispatch, getState) => {
    if (!companyId) return;

    const state = getState();
    const isConnected = selectors.getSocketConnected(state);

    if (!isConnected) dispatch(connect(companyId));
};
