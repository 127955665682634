import arrayMove from 'array-move';
import i18next from 'i18next';
import get from 'lodash/get';

import { INDICATORS, PRODUCTS_TYPE } from './constants';

export const formatterIndicators = (indicators) => {
    return indicators.reduce((acc, current) => {
        return [...acc, { ...current, is_open: false }];
    }, []);
};

export const getDailyAverage = ({ days, value }) => {
    if (!value || !days) return 0;
    return value / days || 0;
};

export const getIndicatorCurrencyValue = ({
    indicatorCurrency,
    proposalCurrency,
    typeChange,
    value,
}) => {
    if (proposalCurrency === indicatorCurrency) return value;
    if (proposalCurrency === 'USD') return value * typeChange;
    return value / typeChange;
};

export const getIndicatorName = (key) => {
    if (!INDICATORS[key]) return '';
    const name = i18next.t(INDICATORS[key].name);
    const complement = i18next.t(INDICATORS[key].complement);
    if (complement) return `${name} (${complement})`;
    return name;
};

export const getDropdownProductsLabel = (products, selectedProducts) => {
    if (selectedProducts.length === 0)
        return `${i18next.t('Select')} ${i18next.t('Product').toLowerCase()}`;
    if (products.length === selectedProducts.length)
        return `${i18next.t('Applied to')} ${i18next.t('All text', {
            context: 'male',
        })}`;
    return `${i18next.t('Applied to')} ${selectedProducts.length} ${i18next.t(
        'Product',
        { count: selectedProducts.length }
    )}`;
};

export const handleClickAllBuild = (
    products,
    selectedProducts,
    setProducts,
    setSelectedProducts
) => {
    if (selectedProducts.length === products.length) {
        setSelectedProducts([]);
        const newProducts = products.map((product) => ({
            ...product,
            checked: false,
        }));
        setProducts(newProducts);
    } else {
        const newProducts = products.map((product) => ({
            ...product,
            checked: true,
        }));
        const newSelectedProducts = products.map((item) => item.value);
        setProducts(newProducts);
        setSelectedProducts(newSelectedProducts);
    }
};

export const handleClickItemBuild = (
    checked,
    products,
    selectedProducts,
    setProducts,
    setSelectedProducts,
    value
) => {
    if (checked) setSelectedProducts([...selectedProducts, value]);
    else {
        const newSelectedProducts = selectedProducts.filter(
            (val) => val !== value
        );
        setSelectedProducts(newSelectedProducts);
    }
    handleOnCheck(checked, products, setProducts, value);
};

const handleOnCheck = (checked, products, setProducts, value) => {
    const newProducts = products.map((product) => {
        if (product.value === value) return { ...product, checked };
        return product;
    });
    setProducts(newProducts);
};

export const getActiveIndicators = (indicatorsList) => {
    return indicatorsList.reduce((acc, current) => {
        if (current.is_active) return acc + 1;
        return acc;
    }, 0);
};

export const handleOnChangedPropertyBuild = ({
    id,
    indicatorsList,
    key,
    setIndicatorsList,
    value,
}) => {
    if (!key) return;
    const newItems = indicatorsList.map((indicator) => {
        if (indicator.id === id) return { ...indicator, [key]: value };
        return indicator;
    });
    setIndicatorsList(newItems);
};

export const handleOnDragEnd = (items, result, setIndicatorsList) => {
    const { destination, source } = result;

    if (
        !destination ||
        (destination?.droppableId === source?.droppableId &&
            destination?.index === source?.index)
    )
        return;

    handleUpdateOrderItems(
        items,
        source.index,
        destination.index,
        setIndicatorsList
    );
};

const handleUpdateOrderItems = (
    items,
    oldIndex,
    newIndex,
    setIndicatorsList
) => {
    const copiedItems = [...items];
    const updatedItems = arrayMove(copiedItems, oldIndex, newIndex);

    let newItems = [];

    updatedItems.forEach((item, index) => {
        newItems.push({ ...item, order: index });
    });

    setIndicatorsList(newItems);
};

export const getIndicatorStorageValueByProducts = ({
    indicatorKey = '',
    indicatorsSettingsData,
    offerDetailsComplements,
    useDoD = false,
}) => {
    const values = get(
        offerDetailsComplements,
        'storage_price_gross_per_kwh',
        {}
    );
    const storageKWh = get(values, 'storage_kWh', 1);
    const dod = get(values, 'dod', 1);

    const products = indicatorsSettingsData?.find(
        (item) => item.name === indicatorKey
    )?.products;

    let sum = 0;
    products?.forEach((product) => {
        const key = PRODUCTS_TYPE[product];
        sum += values[key] || 0;
    });

    const _finalValue = sum / storageKWh || 0;
    return useDoD ? _finalValue / dod : _finalValue;
};
