import React, { useEffect, useState } from 'react';

import get from 'lodash/get';
import PropTypes from 'prop-types';
import { Trans, useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Grid, Typography } from 'sunwise-ui';

import {
    MONTHLY_FREQUENCY,
    PLAN_TYPES,
    SUBSCRIPTION_STATUS,
    YEARLY_FREQUENCY,
} from 'common/constants';

import * as actions from '../actions';
import { getFilteredPricing } from '../helpers';

import BenefitsCards from './BenefitsCards';
import FrequencyOptions from './FrequencyOptions';
import PricingList from './PricingList';

const Step1 = ({
    handleChargebeeCheckout,
    isFetching,
    pricingData,
    selectedTheme,
    subscription,
}) => {
    const { t } = useTranslation();
    const [cbInstance, setCbInstance] = useState(null);
    const [pricings, setPricings] = useState([]);
    const [billingFrequency, setBillingFrequency] = useState(YEARLY_FREQUENCY);
    const billingPeriod = get(subscription, 'billing_period_unit.key', null);
    const hasNotSubscription =
        get(subscription, 'status.key', null) ===
        SUBSCRIPTION_STATUS.NO_SUBSCRIPTION;
    const isCancelledSubscription =
        get(subscription, 'status.key', null) === SUBSCRIPTION_STATUS.CANCELLED;
    const subscriptionType = get(subscription, 'type.key', null);
    const isEnterprise = subscriptionType === PLAN_TYPES.ENTERPRISE;
    const showEnterpriseCard =
        (hasNotSubscription || isCancelledSubscription) && !isEnterprise;

    useEffect(() => {
        if (process.env.REACT_APP_CHARGEBEE_KEY) {
            setCbInstance(
                window.Chargebee.init({
                    site: process.env.REACT_APP_CHARGEBEE_KEY,
                })
            );
        }
    }, []);

    useEffect(() => {
        if (isCancelledSubscription)
            if (billingPeriod === 'year') setBillingFrequency(YEARLY_FREQUENCY);
            else setBillingFrequency(MONTHLY_FREQUENCY);
    }, [billingPeriod]);

    useEffect(() => {
        const filteredPricing = getFilteredPricing(
            billingFrequency,
            pricingData
        );
        setPricings(filteredPricing);
    }, [billingFrequency, pricingData]);

    const handleCheckout = (item_price_id) =>
        handleChargebeeCheckout(cbInstance, item_price_id);

    return (
        <Grid container justifyContent="center" my={2}>
            <Grid item xs={18} lg={showEnterpriseCard ? 12 : 9}>
                <Typography variant="h4" textAlign="center">
                    {t('Plans that adapt to your needs')}
                </Typography>

                <Typography variant="body1" textAlign="center">
                    <Trans>
                        We understand that businesses are always in different
                        stages of growth, thats why at Sunwise we offer you
                        different plan options to grow your business
                    </Trans>
                </Typography>

                <FrequencyOptions
                    billingFrequency={billingFrequency}
                    isHidden={isEnterprise}
                    setBillingFrequency={setBillingFrequency}
                />

                <PricingList
                    amount={get(subscription, 'amount', 0)}
                    handleCheckout={handleCheckout}
                    isCancelledSubscription={isCancelledSubscription}
                    isEnterprise={isEnterprise}
                    isLoading={isFetching}
                    pricings={pricings}
                    selectedTheme={selectedTheme}
                    showEnterpriseCard={showEnterpriseCard}
                />

                <BenefitsCards
                    selectedTheme={selectedTheme}
                    show={showEnterpriseCard}
                />
            </Grid>
        </Grid>
    );
};

const mapDispatchToProps = (dispatch) => ({
    handleChargebeeCheckout: (cbInstance, itemPriceId) =>
        dispatch(actions.handleChargebeeCheckout(cbInstance, itemPriceId)),
});

Step1.propTypes = {
    handleChargebeeCheckout: PropTypes.func,
    isFetching: PropTypes.bool,
    pricingData: PropTypes.object,
    selectedTheme: PropTypes.string,
    subscription: PropTypes.object,
};

export default connect(null, mapDispatchToProps)(Step1);
