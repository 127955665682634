import { getFinancialPlan } from 'common/api/v1/sunpay';

import {
    FETCH_PRODUCT,
    FETCH_PRODUCT_FAILURE,
    FETCH_PRODUCT_SUCCESS,
} from '../actionTypes';
import { financialProductActions } from '../reducer';

import prepareUpdate from './prepareUpdate';

export default (id, disabled = false) =>
    (dispatch) =>
        new Promise((resolve) => {
            dispatch(financialProductActions[FETCH_PRODUCT]());

            getFinancialPlan(id)
                .then((response) => {
                    dispatch(
                        financialProductActions[FETCH_PRODUCT_SUCCESS](
                            response.data
                        )
                    );
                    dispatch(prepareUpdate(response.data, disabled));
                })
                .catch((error) => {
                    dispatch(
                        financialProductActions[FETCH_PRODUCT_FAILURE](error)
                    );
                })
                .finally(resolve);
        });
