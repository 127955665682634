import initializeForm from './initializeForm';
import setIsNewRecord from './setIsNewRecord';
import toggleModalProduct from './toggleModalProduct';

export default (branchSelected, companyId) => (dispatch) => {
    const values = {
        branch_office: !['company', 'all'].includes(branchSelected)
            ? branchSelected
            : '',
        branch_offices: !['company', 'all'].includes(branchSelected)
            ? branchSelected
            : '',
        company: companyId,
    };
    dispatch(setIsNewRecord(true));
    dispatch(initializeForm(values));
    dispatch(toggleModalProduct(true));
};
