import { createSelector } from 'reselect';

import { NAME } from './constants';

export const getModel = (state) => {
    return state[NAME];
};

export const getContactsTrackings = createSelector(
    getModel,
    (model) => model.contactsTrackings
);

export const getIsFetchingContactsTrackings = createSelector(
    getContactsTrackings,
    (model) => model.isFetching
);

export const getContactsTrackingData = createSelector(
    getContactsTrackings,
    (model) => model?.data?.data || []
);

export const getContactsTrackingPagination = createSelector(
    getContactsTrackings,
    (model) => model?.data?.pagination
);

export const getInitialTrackingValues = createSelector(
    getModel,
    (model) => model.initialTrackingValues
);

export const getIsOpenModalTracking = createSelector(
    getModel,
    (model) => model.isOpenModalTracking
);

export const getSaveTracking = createSelector(
    getModel,
    (model) => model.saveTracking
);
export const getIsSavingTracking = createSelector(
    getSaveTracking,
    (model) => model.isSaving
);
export const getSaveTrackingErrors = createSelector(
    getSaveTracking,
    (model) => model.errors
);
