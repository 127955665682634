import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Alert, BottomActions, Box, Button } from 'sunwise-ui';

import useBreakpoint from 'common/hooks/useBreakpoint';

import { getTierColorByIndex } from '../../helpers';

import DistributionCard from './DistributionCard';

const DistributionSection = ({
    conceptName,
    control,
    distributionComponent,
    field,
    hourlyDistribution,
    onClickButton,
    options,
    showMode,
    ...rest
}) => {
    const breakpoint = useBreakpoint();
    const { t } = useTranslation();

    if (['xs', 'sm'].includes(breakpoint))
        return (
            <Alert severity="warning">
                {t('This view is optimized for devices with larger resolution')}
            </Alert>
        );

    if (!hourlyDistribution) return null;

    const optionsForSelect = options?.map((concept, index) => ({
        disabled: showMode,
        label: `${index + 1} - ${concept.tag_name}`,
        value: index,
    }));

    const colors = options?.map((_, index) =>
        getTierColorByIndex(index, options.length)
    );

    return (
        <Box px={1}>
            <DistributionCard
                colors={colors}
                conceptName={conceptName}
                control={control}
                defaultExpanded
                distributionComponent={distributionComponent}
                field={field}
                hourlyDistribution={hourlyDistribution.weekday}
                optionsForSelect={optionsForSelect}
                showMode={showMode}
                title={t('Monday to Friday')}
                weekDay="weekday"
                {...rest}
            />
            <DistributionCard
                colors={colors}
                conceptName={conceptName}
                control={control}
                distributionComponent={distributionComponent}
                field={field}
                hourlyDistribution={hourlyDistribution.saturday}
                optionsForSelect={optionsForSelect}
                showMode={showMode}
                title={t('Saturday')}
                weekDay="saturday"
                {...rest}
            />
            <DistributionCard
                colors={colors}
                conceptName={conceptName}
                control={control}
                distributionComponent={distributionComponent}
                field={field}
                hourlyDistribution={hourlyDistribution.sunday}
                optionsForSelect={optionsForSelect}
                rest={rest}
                showMode={showMode}
                title={t('Sunday')}
                weekDay="sunday"
                {...rest}
            />
            {!showMode && (
                <BottomActions>
                    <Button
                        onClick={onClickButton}
                        sx={{ mt: 2 }}
                        type="button"
                    >
                        {t('Next')}
                    </Button>
                </BottomActions>
            )}
        </Box>
    );
};

DistributionSection.propTypes = {
    conceptName: PropTypes.string,
    control: PropTypes.object,
    distributionComponent: PropTypes.func,
    field: PropTypes.string,
    getValues: PropTypes.func,
    hourlyDistribution: PropTypes.object,
    onClickButton: PropTypes.func,
    options: PropTypes.array,
    setHourlyDistribution: PropTypes.func,
    setValue: PropTypes.func,
    showMode: PropTypes.bool,
    title: PropTypes.string,
};

export default DistributionSection;
