import i18next from 'i18next';

import alerts from 'common/modules/alerts';

import archiveConsumptionProfile from './archiveConsumptionProfile';

export default (id, isArchived) => (dispatch) => {
    const text = isArchived ? 'Unarchive' : 'Archive';

    const dataAlert = {
        confirmText: i18next.t(text),
        messages: [
            i18next.t(
                `Are you sure you want to ${text.toLowerCase()} this item?`
            ),
        ],
        onSuccess: () => {
            dispatch(archiveConsumptionProfile(id, isArchived));
        },
        title: i18next.t(text.concat(' consumption profile')),
        type: alerts.ALERT_TYPE_CONFIRM,
        variant: 'warning',
    };

    dispatch(alerts.actions.show(dataAlert));
};
