import { filterItems } from 'common/api/v1/currencies';

import {
    FETCH_ITEMS_PARENT,
    FETCH_ITEMS_PARENT_FAILURE,
    FETCH_ITEMS_PARENT_SUCCESS,
} from '../actionTypes';
import { multiCurrencyActions } from '../reducer';

export default ({ orderBy, page = 0, searchText, sortBy } = {}) =>
    (dispatch) => {
        dispatch(multiCurrencyActions[FETCH_ITEMS_PARENT]());

        filterItems({
            orderBy,
            page: page + 1,
            searchText,
            sortBy,
        })
            .then((response) =>
                dispatch(
                    multiCurrencyActions[FETCH_ITEMS_PARENT_SUCCESS](
                        response.data
                    )
                )
            )
            .catch((error) =>
                dispatch(
                    multiCurrencyActions[FETCH_ITEMS_PARENT_FAILURE](error)
                )
            );
    };
