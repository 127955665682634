import React from 'react';

import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box, TextField, Typography } from 'sunwise-ui';

import DropdownCardMenu from 'common/components/cards/DropdownCardMenu';

const Wrapper = styled(Box)(
    ({ theme }) => `
    background: ${
        theme.palette.mode === 'dark'
            ? 'rgb(40, 43, 48)'
            : 'rgba(238, 244, 250, 0.35)'
    };
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin-bottom: 8px;
    padding: 8px 16px;
`
);

const SupportContactItem = ({
    canDelete,
    canModify,
    handleClickDelete,
    handleClickUpdate,
    supportContact,
}) => {
    const { t } = useTranslation();

    const { emails, first_lastname, name, telephones, second_lastname } =
        supportContact;

    return (
        <Wrapper>
            <Box
                alignItems="center"
                display="flex"
                justifyContent="space-between"
            >
                <Typography variant="body2" fontWeight="bold">
                    {`${name} ${first_lastname} ${second_lastname}`}
                </Typography>

                <DropdownCardMenu
                    items={[
                        {
                            handleClick: () => handleClickUpdate(),
                            label: t('Edit'),
                            visible: canModify,
                        },
                        {
                            handleClick: () => handleClickDelete(),
                            label: t('Delete'),
                            visible: canDelete,
                        },
                    ]}
                    visible={canModify || canDelete}
                />
            </Box>

            {telephones.length > 0 &&
                telephones.map(({ phone_key, telephone }, index) => (
                    <TextField
                        disabled
                        fullWidth
                        key={`phone-${index}`}
                        label={t('Telephone')}
                        sx={{ mb: 2 }}
                        value={`${phone_key} ${telephone}`}
                        variant="standard"
                    />
                ))}
            {emails.length > 0 &&
                emails.map(({ email }, index) => (
                    <TextField
                        disabled
                        fullWidth
                        key={`email-${index}`}
                        label={t('Email')}
                        sx={{ mb: 2 }}
                        value={email}
                        variant="standard"
                    />
                ))}
        </Wrapper>
    );
};

SupportContactItem.propTypes = {
    canDelete: PropTypes.bool,
    canModify: PropTypes.bool,
    handleClickDelete: PropTypes.func,
    handleClickUpdate: PropTypes.func,
    supportContact: PropTypes.object,
};

export default SupportContactItem;
