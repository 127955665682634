import React, { useContext, useEffect } from 'react';

import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import {
    BottomActions,
    Box,
    Button,
    Grid,
    UiDialog as Dialog,
} from 'sunwise-ui';

import { ShowErrors } from 'common/components';
import {
    ReactHookFormInput,
    ReactHookFormSelect2,
} from 'common/components/form/bootstrap';
import { LoadingContext } from 'common/utils/contexts';
import { getIsMexicanAccount } from 'common/utils/helpers/session';
import yupResolver from 'common/utils/yupResolver';

import * as actions from '../actions';
import * as selectors from '../selectors';
import validateGenerationProfile from '../validateGenerationProfile';

import ChartComponent from './ChartComponent';

const GenerationProfileForm = ({
    countryCurrencyLocale,
    getValues,
    initialValues,
    isSavingGenerationProfile,
    modalIsOpen,
    politicalDivisionsForSelect,
    resetModalForm,
    saveGenerationProfile,
    saveGenerationProfileErrors,
    setModalIsOpen,
}) => {
    const { t } = useTranslation();
    const loadingContext = useContext(LoadingContext);

    const { control, handleSubmit, reset, watch } = useForm({
        defaultValues: initialValues,
        resolver: yupResolver(validateGenerationProfile),
    });

    const [generationProfile, type, year] = watch([
        'generation_profile',
        'type',
        'year',
    ]);

    useEffect(() => reset(initialValues), [initialValues]);

    useEffect(() => {
        if (isEmpty(loadingContext)) return;
        if (isSavingGenerationProfile)
            loadingContext.openLoading(t('Saving').concat('...'));
        else loadingContext.closeLoading();
    }, [isSavingGenerationProfile]);

    const isMexicanAccount = getIsMexicanAccount();
    const onSubmit = (values) =>
        saveGenerationProfile(getValues, isMexicanAccount, values);

    return (
        <Dialog
            onClose={() => setModalIsOpen(false)}
            onExited={() => resetModalForm()}
            open={modalIsOpen}
            size="xl"
            title={`${t('Add')} ${t('Generation profile').toLocaleLowerCase()}`}
        >
            <form onSubmit={handleSubmit(onSubmit)}>
                <Grid container>
                    <Grid item xs={18} md={12}>
                        <ReactHookFormInput
                            control={control}
                            name="name"
                            label={t('Profile name')}
                        />
                    </Grid>
                    <Grid item xs={18} md={6}>
                        <ReactHookFormSelect2
                            control={control}
                            name="political_division1"
                            label={
                                isMexicanAccount
                                    ? t('State')
                                    : t('Political division of order 1')
                            }
                            options={[
                                {
                                    label: '',
                                    options: politicalDivisionsForSelect,
                                },
                            ]}
                        />
                    </Grid>
                </Grid>

                {generationProfile && (
                    <Box mb={2}>
                        <ChartComponent
                            countryCurrencyLocale={countryCurrencyLocale}
                            generationProfile={{
                                generation_profile_formatted: generationProfile,
                                type,
                                year,
                            }}
                            isModalForm
                            series={[
                                {
                                    data: Object.values(generationProfile).map(
                                        (month) =>
                                            month.total /
                                            (Object.values(month).length - 1)
                                    ),
                                    name: '%kWh/kWp',
                                },
                            ]}
                        />
                    </Box>
                )}

                <ShowErrors errors={saveGenerationProfileErrors} />

                <BottomActions>
                    <Button type="submit" sx={{ mt: 2 }}>
                        {t('Save')}
                    </Button>
                </BottomActions>
            </form>
        </Dialog>
    );
};

const mapStateToProps = createStructuredSelector({
    initialValues: selectors.getInitialValuesGenerationProfileForm,
    isSavingGenerationProfile: selectors.getIsSavingGenerationProfile,
    modalIsOpen: selectors.getIsOpenGenerationProfileForm,
    saveGenerationProfileErrors: selectors.getSaveGenerationProfileErrors,
});

const mapDispatchToProps = (dispatch) => ({
    resetModalForm: () => dispatch(actions.resetGenerationProfileForm()),
    saveGenerationProfile: (getValues, isMexicanAccount, values) =>
        dispatch(
            actions.prepareSaveGenerationProfile(
                getValues,
                isMexicanAccount,
                values
            )
        ),
    setModalIsOpen: (isOpen) =>
        dispatch(actions.setGenerationProfileModalIsOpen(isOpen)),
});

GenerationProfileForm.propTypes = {
    countryCurrencyLocale: PropTypes.string,
    getValues: PropTypes.func,
    initialValues: PropTypes.object,
    isSavingGenerationProfile: PropTypes.bool,
    modalIsOpen: PropTypes.bool,
    politicalDivisionsForSelect: PropTypes.array,
    resetModalForm: PropTypes.func,
    saveGenerationProfile: PropTypes.func,
    saveGenerationProfileErrors: PropTypes.array,
    setModalIsOpen: PropTypes.func,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(GenerationProfileForm);
