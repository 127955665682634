import { getItems } from 'common/api/v1/invertersCompany';

import {
    FETCH_INVERTERS,
    FETCH_INVERTERS_FAILURE,
    FETCH_INVERTERS_SUCCESS,
} from '../actionTypes';
import { equipmentCompatibilityActions } from '../reducer';

export default () => (dispatch) => {
    dispatch(equipmentCompatibilityActions[FETCH_INVERTERS]());

    getItems()
        .then((response) =>
            dispatch(
                equipmentCompatibilityActions[FETCH_INVERTERS_SUCCESS](
                    response.data.data
                )
            )
        )
        .catch((error) =>
            dispatch(
                equipmentCompatibilityActions[FETCH_INVERTERS_FAILURE](
                    error?.response?.data?.errors
                )
            )
        );
};
