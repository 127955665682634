import { filterItems } from 'common/api/v1/customRates';

import {
    FETCH_RATE_FAILURE,
    FILTER_RATES,
    FILTER_RATES_FAILURE,
    FILTER_RATES_SUCCESS,
} from '../actionTypes';
import { ratesActions } from '../reducer';

export default (params) => (dispatch) => {
    dispatch(ratesActions[FETCH_RATE_FAILURE]());
    dispatch(ratesActions[FILTER_RATES]());
    filterItems(params)
        .then((response) =>
            dispatch(
                ratesActions[FILTER_RATES_SUCCESS]({
                    hasNextPage: response.data.next_page,
                    rates: response.data.data,
                })
            )
        )
        .catch((error) => dispatch(ratesActions[FILTER_RATES_FAILURE](error)));
};
