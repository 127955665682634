import i18next from 'i18next';
import { isEmpty, isNull } from 'lodash';

import { GENERATION_SOURCES } from 'common/constants';
import { formatDate, parseDate, subDate } from 'common/utils/dates';
import { getDecimals, numberFormat } from 'common/utils/helpers';
import {
    formatTierField,
    getCompensationSchemeByBackendKey,
    getNormalizedSummary,
} from 'common/utils/helpers/rates';

import { SOURCE_OPTIONS } from '../proposalGeneratorAdvancedGenerationSettings/constants';

import { NASA_SOURCES_NAMES, PV_LAYOUT_SOURCES_NAMES } from './constants';
export const getAnualSolarGenerationData = (dataGeneration) =>
    dataGeneration.reduce((total, data) => total + data.generation, 0);

export const getConsumptionHistorySorted = (
    customerEnergyData,
    consumptionHistory
) => {
    if (consumptionHistory && !isNull(consumptionHistory)) {
        const daysToSubstract = getIsBimonthly(customerEnergyData) ? 30 : 15;
        return Object.keys(consumptionHistory).map((key) => {
            const year = consumptionHistory[key];

            const yearParsed = year.map((item) => ({
                ...item,
                parsedFinishDate: parseDate(item.date_finish, 'dd/MM/yyyy'),
            }));

            const yearSorted = yearParsed.sort(
                (a, b) => a.parsedFinishDate - b.parsedFinishDate
            );

            return yearSorted.map((item) => {
                const mainDate = subDate(item.parsedFinishDate, {
                    days: Math.floor(daysToSubstract),
                });
                const formattedDate = formatDate(mainDate, 'MMM yy');

                return {
                    consumption: item.consumption,
                    final_date: item.date_finish,
                    generation: item.generation,
                    generationInPeriod: item.generation_in_period,
                    infoPriceConsumption: item.info_price_consumption,
                    infoPriceGeneration: item.info_price_generation,
                    initial_date: item.date_start,
                    label: formattedDate.toUpperCase(),
                };
            });
        });
    }
    return [];
};

const getLabelWithAdditionalInfo = (label, option) =>
    option ? `${label} (${option})` : label;

export const getGenerationSources = ({
    designer_generation_api,
    file,
    has_designer_generation,
    nasa_irradiation_source,
    source,
}) => [
    {
        label:
            NASA_SOURCES_NAMES[nasa_irradiation_source || 0] ||
            i18next.t('Own source'),
        value: GENERATION_SOURCES.NASA,
    },
    {
        disabled: !file,
        label: getLabelWithAdditionalInfo(
            'CSV',
            SOURCE_OPTIONS.find((item) => item.value === source)?.label
        ),
        value: GENERATION_SOURCES.CSV,
    },
    {
        disabled: !has_designer_generation,
        label: getLabelWithAdditionalInfo(
            i18next.t('PV Layout'),
            PV_LAYOUT_SOURCES_NAMES[designer_generation_api]
        ),
        value: GENERATION_SOURCES.DESIGNER,
    },
];

export const getHasFile = (initialValuesAdvancedGeneration) =>
    parseInt(initialValuesAdvancedGeneration.source) !== 0 &&
    (!isNull(initialValuesAdvancedGeneration.urlFile) ||
        !isEmpty(initialValuesAdvancedGeneration.urlFile));

export const getIsBimonthly = (customerEnergyData) =>
    isNull(customerEnergyData) ? true : customerEnergyData.is_bimonthly;

export const getMonthsToChartKwh = ({
    is_bimonthly,
    lines_consumption_energies,
}) => {
    if (lines_consumption_energies && !isEmpty(lines_consumption_energies)) {
        const filteredKwh = lines_consumption_energies.filter(
            (line) => parseInt(line.type_line) === 0
        );

        const daysToSubstract = is_bimonthly ? 30 : 15;

        return filteredKwh.map((item) => {
            const label = formatDate(
                subDate(parseDate(item.final_date, 'dd/MM/yyyy'), {
                    days: Math.floor(daysToSubstract),
                }),
                'MMM yy'
            );

            return {
                label: label,
                value: item.consumption,
                generation: item.generation,
            };
        });
    }
    return [];
};

export const getSunHoursInformation = ({
    canModifySolarGeneration,
    canModifySolarGenerationFile,
    currencyLocale,
    generation,
    hasFile,
    sunHoursSegments,
    pvSystemDataSource,
    sunHours,
    sunHoursWithFile,
}) => {
    let sunHoursValue = (sunHours * (1 + generation / 100)).toFixed(
        getDecimals(sunHours * (1 + generation / 100))
    );

    if (pvSystemDataSource === GENERATION_SOURCES.DESIGNER)
        sunHoursValue = sunHoursSegments;
    else if (
        canModifySolarGenerationFile &&
        hasFile &&
        pvSystemDataSource === GENERATION_SOURCES.CSV
    ) {
        sunHoursValue = sunHoursWithFile;
    } else if (canModifySolarGeneration) {
        sunHoursValue = sunHoursValue || sunHoursWithFile;
    }

    return `${numberFormat(sunHoursValue, {
        locale: currencyLocale,
        style: 'decimal',
    })} ${i18next.t('Sunshine hours').toLowerCase()}`;
};

export const getSunHoursWithFile = (anualSolarGeneration, systemSize) =>
    systemSize > 0 ? (anualSolarGeneration / systemSize / 365).toFixed(2) : 0;

export const isDACRate = (
    selectedPrevRate,
    selectedNextRate,
    rateChange,
    solarConsumption
) => {
    if (
        isNull(selectedPrevRate) ||
        !selectedPrevRate.isCertified ||
        selectedPrevRate.name !== 'DAC'
    )
        return false;
    const avgSolarConsumption = solarConsumption / 12;
    return (
        (rateChange !== '' && parseInt(rateChange) > 12) ||
        avgSolarConsumption > selectedNextRate.limitDac
    );
};

// Initialize
export const prepareInitializeData = (
    {
        subsidy_rate,
        next_rate_object,
        previous_rate_object,
        compensation_scheme,
    } = {},
    upsertRate,
    offerConfigGeneration
) => {
    upsertRate(previous_rate_object);

    let nextRateObject = null;

    if (!isNull(subsidy_rate)) {
        upsertRate(subsidy_rate);
        nextRateObject = subsidy_rate;
    }
    if (!isNull(next_rate_object)) {
        upsertRate(next_rate_object);
        nextRateObject = next_rate_object;
    }
    if (!nextRateObject) nextRateObject = { ...previous_rate_object };

    const _compensation_scheme = getCompensationSchemeByBackendKey({
        backendKey: compensation_scheme,
        certified: nextRateObject?.certified,
        name: nextRateObject?.name,
        paymentType: nextRateObject?.payment_type,
    });

    return {
        compensation_scheme: _compensation_scheme?.value,
        initial_rate_name: previous_rate_object.name,
        next_rate: nextRateObject?.id,
        next_rate_object: nextRateObject,
        previous_rate: previous_rate_object.id,
        generation_source: offerConfigGeneration?.pv_system_data_source,
    };
};

const setTierFieldsWithValues = ({
    baseName,
    fieldsValues,
    rate,
    setValue,
    tiers,
}) => {
    if (!tiers?.length) return;
    const mappedTiersValues = fieldsValues?.reduce((acc, curr) => {
        acc[curr.tier] = curr;
        return acc;
    }, {});

    for (const tier of tiers) {
        if (!tier) continue;
        const fieldName = tier.name?.toLowerCase() || '';
        const values = mappedTiersValues?.[tier.identifier];
        const newField = formatTierField({
            isCertified: rate?.isCertified,
            oldField: { value: values?.consumption || 0 },
            tier,
        });
        setValue(`${baseName}.${fieldName}`, newField);
    }
};

export const prepareInitializeContractedDemandFields = ({
    nextContractedDemand,
    nextTiers,
    prevContractedDemand,
    prevTiers,
    rate,
    setValue,
}) => {
    setTierFieldsWithValues({
        baseName: 'previous_contracted_demand',
        fieldsValues: prevContractedDemand,
        rate,
        setValue,
        tiers: prevTiers,
    });
    setTierFieldsWithValues({
        baseName: 'next_contracted_demand',
        fieldsValues: nextContractedDemand?.length
            ? nextContractedDemand
            : prevContractedDemand,
        rate,
        setValue,
        tiers: nextTiers,
    });
};

export const prepareNextRateFields = ({
    getValues,
    rate,
    scheduleRateConfiguration,
    setValue,
}) => {
    if (!scheduleRateConfiguration || !setValue || !getValues) return {};

    const { tiers_demand_distribution } = scheduleRateConfiguration;
    const demandTiers = tiers_demand_distribution?.[0]?.tiers || [];

    if (!demandTiers.length) return setValue('next_contracted_demand', {});

    const oldValues = getValues('next_contracted_demand');
    setValue('next_contracted_demand', {});

    for (const tier of demandTiers) {
        const fieldName = tier?.name?.toLowerCase() || '';
        const newField = formatTierField({
            isCertified: rate?.isCertified,
            oldField: oldValues?.[fieldName],
            tier,
        });
        setValue(`next_contracted_demand.${fieldName}`, newField);
    }
};

// Save
export const getNormalizedTiersConsumptions = ({
    hourlyContractedDemand,
    consumptionHistory,
}) => {
    if (isEmpty(hourlyContractedDemand) || isEmpty(consumptionHistory?.[0]))
        return null;

    const newSummary = consumptionHistory[0].map((period) => ({
        id: period?.id,
        initial_date: period?.date_start,
        final_date: period?.date_finish,
    }));

    return getNormalizedSummary({
        contracted_demand: hourlyContractedDemand,
        summary: newSummary,
    });
};

export const handleSaveFields = ({
    handleSubmit,
    id,
    initialValues,
    name,
    save,
    value,
}) => {
    if (value !== null && value != initialValues[name])
        handleSubmit((allValues) =>
            save(id, { ...allValues, [name]: value }, initialValues)
        )();
    return value;
};
