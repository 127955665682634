import { fetchData } from 'common/api/v2/irradiation';

import {
    FETCH_IRRADIATION_DATA,
    FETCH_IRRADIATION_DATA_FAILURE,
    FETCH_IRRADIATION_DATA_SUCCESS,
    INITIALIZE_IRRADIATION_VALUES,
} from '../actionTypes';
import { panelLayoutV2Actions } from '../reducer';

export default (id) => (dispatch) =>
    new Promise((resolve) => {
        dispatch(panelLayoutV2Actions[FETCH_IRRADIATION_DATA]());

        fetchData(id)
            .then((response) => {
                const data = response.data.data;

                dispatch(
                    panelLayoutV2Actions[FETCH_IRRADIATION_DATA_SUCCESS](data)
                );

                if (data.length) {
                    const key = Object.keys(data[0])[0];
                    const firstObject = data[0][key];
                    const source = firstObject.API;
                    const losses = firstObject.losses || '25';
                    const bifaciality = firstObject.bifaciality || false;
                    const bifaciality_param =
                        firstObject.bifaciality_param * 100 || '90';

                    dispatch(
                        panelLayoutV2Actions[INITIALIZE_IRRADIATION_VALUES]({
                            bifaciality,
                            bifaciality_param,
                            losses,
                            source,
                        })
                    );
                }
            })
            .catch((error) => {
                dispatch(
                    panelLayoutV2Actions[FETCH_IRRADIATION_DATA_FAILURE](error)
                );
            })
            .finally(resolve);
    });
