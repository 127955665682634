import { updateConsumption } from 'common/api/v1/contactProject';
import { showReponseErrorsAsAlert } from 'common/utils/helpers';

import {
    UPDATE_CONSUMPTION_ENERGY,
    UPDATE_CONSUMPTION_ENERGY_FAILURE,
    UPDATE_CONSUMPTION_ENERGY_SUCCESS,
} from '../actionTypes';
import { projectConsumptionCardActions } from '../reducer';

import fetchConsumptionEnergy from './fetchConsumptionEnergy';

export default (id, projectId) => (dispatch) => {
    dispatch(projectConsumptionCardActions[UPDATE_CONSUMPTION_ENERGY]());

    updateConsumption(id)
        .then((response) => {
            dispatch(
                projectConsumptionCardActions[
                    UPDATE_CONSUMPTION_ENERGY_SUCCESS
                ](response.data)
            );

            dispatch(fetchConsumptionEnergy(projectId));
        })
        .catch((error) => {
            dispatch(
                projectConsumptionCardActions[
                    UPDATE_CONSUMPTION_ENERGY_FAILURE
                ](error?.response?.data?.errors)
            );
            showReponseErrorsAsAlert(dispatch, error?.response);
        });
};
