import { getDivisions } from 'common/api/v1/catalogs';

import {
    FETCH_DIVISIONS,
    FETCH_DIVISIONS_SUCCESS,
    FETCH_DIVISIONS_FAILURE,
} from '../actionTypes';
import { projectConsumptionModalActions } from '../reducer';

export default () => (dispatch) => {
    dispatch(projectConsumptionModalActions[FETCH_DIVISIONS]());
    getDivisions()
        .then(({ data }) =>
            dispatch(
                projectConsumptionModalActions[FETCH_DIVISIONS_SUCCESS](
                    data.data
                )
            )
        )
        .catch((error) =>
            dispatch(
                projectConsumptionModalActions[FETCH_DIVISIONS_FAILURE](
                    error?.response?.data?.errors
                )
            )
        );
};
