import i18next from 'i18next';

import { getBranchOfficesIdsFromArray } from 'common/modules/multiBranches/helpers';
import { getIsMultibranchesAccount } from 'common/utils/helpers/session';

import { DUPLICATE_TEMPLATE_MODE } from '../constants';

import initializeForm from './initializeForm';
import setTemplateMode from './setTemplateMode';
import toggleModalTemplate from './toggleModalTemplate';

export default (branchSelected, template) => (dispatch) => {
    const isMultiBranches = getIsMultibranchesAccount();
    const {
        branch_offices,
        id,
        is_global,
        language,
        proposals_number,
        title,
        type,
    } = template;

    let values = {
        branch_offices: is_global
            ? 'company'
            : getBranchOfficesIdsFromArray(branch_offices),
        id,
        language,
        proposals_number,
        title: `${i18next.t('Copy of')} ${title}`,
        type: type,
    };

    if (!isMultiBranches || (isMultiBranches && branchSelected !== 'all')) {
        values.branch_offices = branchSelected;
    }

    dispatch(initializeForm(values));
    dispatch(setTemplateMode(DUPLICATE_TEMPLATE_MODE));
    dispatch(toggleModalTemplate(true));
};
