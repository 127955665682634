import { createProject } from 'common/api/afterSales/projects';
import { showFastApiErrorsAsAlert } from 'common/utils/helpers';
import showToast from 'common/utils/showToast';

import * as afterSalesProjectsStatusActions from '../../afterSalesProjectsStatus/actions';
import * as profileSelectors from '../../profile/selectors';
import {
    SAVE_DATA,
    SAVE_DATA_FAILURE,
    SAVE_DATA_SUCCESS,
} from '../actionTypes';
import { SOURCE_FIELD_KEYS } from '../constants';
import { actions } from '../reducer';

export default ({ successCallback, sunwiseProjectId, values }) =>
    (dispatch, getState) => {
        if (!values?.credential?.id || !sunwiseProjectId) return;
        dispatch(actions[SAVE_DATA]());

        const newValues = {
            company_sk_id: values.credential.id,
            name:
                values.power_station?.station_name || values.power_station?.id,
            read_requirements: {
                station_id: values.power_station?.id,
                station_name: values.power_station?.station_name,
            },
            sunwise_project: sunwiseProjectId,
        };

        Object.values(SOURCE_FIELD_KEYS).forEach((key) => {
            newValues[key] = values[key] || false;
        });

        createProject(newValues)
            .then(() => {
                dispatch(actions[SAVE_DATA_SUCCESS]());
                showToast();

                const companyId = profileSelectors.getUserCompanyId(getState());
                dispatch(afterSalesProjectsStatusActions.connect(companyId));

                if (successCallback) successCallback();
            })
            .catch((error) => {
                dispatch(
                    actions[SAVE_DATA_FAILURE](error?.response?.data?.detail)
                );
                showFastApiErrorsAsAlert(dispatch, error?.response);
            });
    };
