import get from 'lodash/get';
import { array, number, object, ref, string } from 'yup';

import getValidationTexts from 'common/utils/getValidationTexts';

import {
    ALLOWED_REPEATED_CONCEPTS_NAMES,
    CONCEPTS_NAMES,
    NAME_LENGTH,
    NAME_SHORT_LENGTH,
} from './constants';

const getTotalConceptsWithSameTagName = ({ path, value, values }) => {
    const concepts = get(values, path.split('[').slice(0, -1).join('['), []);
    return concepts.filter((concept) => concept?.tag_name === value).length;
};

const getIsUniqueTagName = ({
    currentConcept,
    isHourlyRate,
    path,
    tagName,
    values,
}) => {
    let total = 0;
    const allowedConcepts = ALLOWED_REPEATED_CONCEPTS_NAMES[currentConcept];

    for (const concept of CONCEPTS_NAMES) {
        if (
            isHourlyRate &&
            currentConcept !== concept &&
            allowedConcepts?.includes(concept)
        )
            continue;

        total += getTotalConceptsWithSameTagName({
            path: path.replace(currentConcept, concept),
            value: tagName,
            values,
        });

        if (total > 1) return false;
    }
    return true;
};

const getConceptDistributionSchemaValidation = (concepts) => {
    const { MISSING_CONCEPTS_IN_DISTRIBUTION_TEXT, REQUIRED_TEXT } =
        getValidationTexts();
    return object()
        .shape({
            weekday: array().required(REQUIRED_TEXT),
            saturday: array().required(REQUIRED_TEXT),
            sunday: array().required(REQUIRED_TEXT),
        })
        .test({
            name: 'validHourlyDistribution',
            message: MISSING_CONCEPTS_IN_DISTRIBUTION_TEXT,
            test: (values = {}) => {
                const { weekday = [], saturday = [], sunday = [] } = values;
                const allValues = [...weekday, ...saturday, ...sunday];

                const isValid = concepts.every((_, index) =>
                    allValues.some((hour) => hour?.value === index + 1)
                );
                return isValid;
            },
        });
};

export const getConceptsSchemaValidation = ({
    conceptName,
    isHourlyRate,
    values,
} = {}) => {
    const {
        maximumNumberCharacters,
        MIN_ZERO_VALIDATION_TEXT,
        MUST_BE_UNIQUE_TEXT,
        NUMBER_TYPE_TEXT,
        REQUIRED_TEXT,
    } = getValidationTexts();
    return array().of(
        object().shape({
            price: number()
                .typeError(NUMBER_TYPE_TEXT)
                .required(REQUIRED_TEXT)
                .when('unit', {
                    is: 'MINIMUM',
                    then: () => number().min(0, MIN_ZERO_VALIDATION_TEXT),
                }),
            tag_name: string()
                .required(REQUIRED_TEXT)
                .max(124, maximumNumberCharacters(124))
                .test({
                    name: 'duplicate_tag_name',
                    message: MUST_BE_UNIQUE_TEXT,
                    test: function (tagName) {
                        return getIsUniqueTagName({
                            currentConcept: conceptName,
                            isHourlyRate,
                            path: this.path,
                            tagName,
                            values,
                        });
                    },
                }),
            unit: string().required(REQUIRED_TEXT),
        })
    );
};

const getEnergyConceptFields = ({
    isHourlyRate,
    isTopValueEnabled,
    values,
}) => {
    const {
        maximumNumberCharacters = () => {},
        MAX_THAN_PRICE_VALIDATION_TEXT,
        MIN_THAN_TOP_VALUE_VALIDATION_TEXT,
        MIN_ZERO_VALIDATION_TEXT,
        MUST_BE_UNIQUE_TEXT,
        NUMBER_TYPE_TEXT,
        REQUIRED_TEXT,
    } = getValidationTexts();
    const energyFields = {
        compensation_relation: number()
            .typeError(NUMBER_TYPE_TEXT)
            .required(REQUIRED_TEXT)
            .max(1)
            .min(0, MIN_ZERO_VALIDATION_TEXT)
            .nullable(),
        compensation_relation_price: number()
            .typeError(NUMBER_TYPE_TEXT)
            .required(REQUIRED_TEXT)
            .max(ref('price'), MAX_THAN_PRICE_VALIDATION_TEXT)
            .min(0, MIN_ZERO_VALIDATION_TEXT)
            .nullable(),
        price: number()
            .typeError(NUMBER_TYPE_TEXT)
            .required(REQUIRED_TEXT)
            .min(0, MIN_ZERO_VALIDATION_TEXT),
        tag_name: string()
            .required(REQUIRED_TEXT)
            .max(124, maximumNumberCharacters(124))
            .test({
                name: 'duplicate_tag_name',
                message: MUST_BE_UNIQUE_TEXT,
                test: function (tagName) {
                    return getIsUniqueTagName({
                        currentConcept: 'energy_concepts',
                        isHourlyRate,
                        path: this.path,
                        tagName,
                        values,
                    });
                },
            }),
        unit: string().required(REQUIRED_TEXT),
    };
    if (isTopValueEnabled) {
        return {
            ...energyFields,
            top_value: number()
                .required(REQUIRED_TEXT)
                .typeError(NUMBER_TYPE_TEXT)
                .required(REQUIRED_TEXT)
                .test({
                    name: 'min',
                    exclusive: false,
                    message: MIN_THAN_TOP_VALUE_VALIDATION_TEXT,
                    test: function (value) {
                        return value > parseInt(this.parent.min_value);
                    },
                }),
        };
    }
    return energyFields;
};

export const getEnergyConceptsSchemaValidation = ({
    isHourlyRate,
    isTopValueEnabled,
    values,
}) => {
    return array()
        .min(1)
        .required()
        .of(
            object(
                getEnergyConceptFields({
                    isHourlyRate,
                    isTopValueEnabled,
                    values,
                })
            )
        );
};

export const getMainDataSchemaValidation = () => {
    const {
        MAX_100_VALIDATION_TEXT,
        maximumNumberCharacters,
        MIN_ZERO_VALIDATION_TEXT,
        NUMBER_TYPE_TEXT,
        REQUIRED_TEXT,
    } = getValidationTexts();
    return object().shape({
        annual_percentage_increase: number()
            .typeError(NUMBER_TYPE_TEXT)
            .min(0, MIN_ZERO_VALIDATION_TEXT)
            .max(100, MAX_100_VALIDATION_TEXT)
            .required(REQUIRED_TEXT),
        business_type: string().required(REQUIRED_TEXT),
        country: string().required(REQUIRED_TEXT),
        currency: string().required(REQUIRED_TEXT),
        name: string()
            .required(REQUIRED_TEXT)
            .max(NAME_LENGTH, maximumNumberCharacters(NAME_LENGTH)),
        name_short: string()
            .required(REQUIRED_TEXT)
            .max(NAME_SHORT_LENGTH, maximumNumberCharacters(NAME_SHORT_LENGTH)),
        periodicity_type: string().required(REQUIRED_TEXT),
        rate_description: string()
            .required(REQUIRED_TEXT)
            .max(100, maximumNumberCharacters(100)),
        type_of_input_charges: string().required(REQUIRED_TEXT),
        utility_supplier: string().required(REQUIRED_TEXT),
        voltage_type: string().required(REQUIRED_TEXT),
    });
};

export const getPolicyDataSchemaValidation = () => {
    const { NUMBER_TYPE_TEXT, REQUIRED_TEXT } = getValidationTexts();
    return object().shape({
        default_compensation_scheme: string().required(REQUIRED_TEXT),
        energy_export_price: number()
            .typeError(NUMBER_TYPE_TEXT)
            .required(REQUIRED_TEXT),
        netted_exported_generation_price: number()
            .typeError(NUMBER_TYPE_TEXT)
            .nullable(),
        payback_type: string().required(REQUIRED_TEXT),
        period_compensation: string().required(REQUIRED_TEXT),
    });
};

export const getSettingsDataSchemaValidation = (
    isTopValueEnabled,
    isHourlyRate,
    values
) => {
    const {
        MAX_THAN_PRICE_VALIDATION_TEXT,
        MIN_ZERO_VALIDATION_TEXT,
        NUMBER_TYPE_TEXT,
        REQUIRED_TEXT,
    } = getValidationTexts();
    let validation = {
        periods: array().of(
            object().shape({
                date_end: string().required(REQUIRED_TEXT),
                date_start: string().required(REQUIRED_TEXT),
                demand_concepts: object().shape({
                    concepts: getConceptsSchemaValidation({
                        conceptName: 'demand_concepts',
                        isHourlyRate,
                        values,
                    }),
                }),
                contracted_demand_concepts: object().shape({
                    concepts: getConceptsSchemaValidation({
                        conceptName: 'contracted_demand_concepts',
                        isHourlyRate,
                        values,
                    }),
                }),
                energy_concepts: object().shape({
                    concepts: getEnergyConceptsSchemaValidation({
                        isHourlyRate,
                        isTopValueEnabled,
                        values,
                    }),
                }),
                excess_demand_concepts: object().shape({
                    concepts: getConceptsSchemaValidation({
                        conceptName: 'excess_demand_concepts',
                        isHourlyRate,
                        values,
                    }),
                }),
                other_concepts: object().shape({
                    concepts: getConceptsSchemaValidation({
                        conceptName: 'other_concepts',
                        values,
                    }),
                }),
                taxes_concepts: object().shape({
                    concepts: getConceptsSchemaValidation({
                        conceptName: 'taxes_concepts',
                        values,
                    }),
                }),
                total_compensation: number()
                    .typeError(NUMBER_TYPE_TEXT)
                    .required(REQUIRED_TEXT)
                    .max(ref('total_price'), MAX_THAN_PRICE_VALIDATION_TEXT)
                    .min(0, MIN_ZERO_VALIDATION_TEXT),
            })
        ),
    };
    if (isHourlyRate) {
        const energyConcepts =
            values?.settings_data?.periods?.[0]?.energy_concepts?.concepts ||
            [];
        const demandConcepts =
            values?.settings_data?.periods?.[0]?.contracted_demand_concepts
                ?.concepts || [];

        let hourlyDistribution = {
            energy_concepts:
                getConceptDistributionSchemaValidation(energyConcepts),
        };

        if (demandConcepts.length)
            hourlyDistribution.demand_concepts =
                getConceptDistributionSchemaValidation(demandConcepts);

        validation.hourly_distribution = object(hourlyDistribution);
    }
    return object().shape(validation);
};
