import { NAME } from './constants';

export const FETCH_ACCUMULATED_SAVINGS = `${NAME}/FETCH_ACCUMULATED_SAVINGS`;
export const FETCH_ACCUMULATED_SAVINGS_FAILURE = `${NAME}/FETCH_ACCUMULATED_SAVINGS_FAILURE`;
export const FETCH_ACCUMULATED_SAVINGS_SUCCESS = `${NAME}/FETCH_ACCUMULATED_SAVINGS_SUCCESS`;

export const FETCH_CONSUMPTION_HISTORY = `${NAME}/FETCH_CONSUMPTION_HISTORY`;
export const FETCH_CONSUMPTION_HISTORY_FAILURE = `${NAME}/FETCH_CONSUMPTION_HISTORY_FAILURE`;
export const FETCH_CONSUMPTION_HISTORY_SUCCESS = `${NAME}/FETCH_CONSUMPTION_HISTORY_SUCCESS`;

export const FETCH_MEASURED_ENERGY = `${NAME}/FETCH_MEASURED_ENERGY`;
export const FETCH_MEASURED_ENERGY_FAILURE = `${NAME}/FETCH_MEASURED_ENERGY_FAILURE`;
export const FETCH_MEASURED_ENERGY_SUCCESS = `${NAME}/FETCH_MEASURED_ENERGY_SUCCESS`;

export const FETCH_REFERENCE_INFO = `${NAME}/FETCH_REFERENCE_INFO`;
export const FETCH_REFERENCE_INFO_FAILURE = `${NAME}/FETCH_REFERENCE_INFO_FAILURE`;
export const FETCH_REFERENCE_INFO_SUCCESS = `${NAME}/FETCH_REFERENCE_INFO_SUCCESS`;

export const INITIALIZE_GENERATION_ACCURACY = `${NAME}/INITIALIZE_GENERATION_ACCURACY`;
export const INITIALIZE_GENERATION_RATIO = `${NAME}/INITIALIZE_GENERATION_RATIO`;
export const INITIALIZE_MEASUREMENT_SOURCE = `${NAME}/INITIALIZE_MEASUREMENT_SOURCE`;
export const INITIALIZE_ROI_PROGRESS = `${NAME}/INITIALIZE_ROI_COMPLIANCE`;

export const RESET = `${NAME}/RESET`;
