import React from 'react';

import LinearProgress from '@mui/material/LinearProgress';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { types } from 'sunwise-template-core';
import { DataGrid } from 'sunwise-ui';

import { CustomPagination } from 'common/components';

import * as templateLibrarySelectors from '../../../templateLibrary/selectors';
import * as selectors from '../../selectors';

import CustomTemplateColumns from './CustomTemplateColumns';

const CustomTemplatesTab = ({
    clonePages,
    customTemplate,
    handleSelectPage,
    isFetchingCustomTemplate,
    languagesDictionary,
    proposalsNumber,
    templateBaseType,
}) => {
    const filteredItems = clonePages.filter(
        (item) =>
            item.type === templateBaseType &&
            item.proposals_number === proposalsNumber &&
            item.pages >= 1 &&
            item?.languages?.some(
                (lang) => lang?.key === customTemplate?.language
            )
    );

    return (
        <DataGrid
            autoHeight
            columns={CustomTemplateColumns({
                isForSummaryTemplates:
                    templateBaseType === types.MULTIPROPOSAL_TYPE,
                languagesDictionary,
            })}
            components={{
                LoadingOverlay: LinearProgress,
                Pagination: CustomPagination,
            }}
            disableColumnMenu
            getRowHeight={() => 'auto'}
            initialState={{
                pagination: {
                    pageSize: 20,
                },
            }}
            loading={isFetchingCustomTemplate}
            pagination
            rows={filteredItems}
            onSelectionModelChange={(newSelectionModel) => {
                const selectedIDs = new Set(newSelectionModel);
                const selectedRowData = filteredItems.find((row) =>
                    selectedIDs.has(row.id.toString())
                );

                if (selectedRowData)
                    handleSelectPage({
                        id: selectedRowData.id,
                        pages: selectedRowData.pages,
                    });
            }}
        />
    );
};

const mapStateToProps = createStructuredSelector({
    clonePages: selectors.getCustomClonePagesData,
    customTemplate: selectors.getDataFetchCustomTemplate,
    isFetchingCustomTemplate: selectors.getIsFetchingCustomTemplate,
    languagesDictionary: templateLibrarySelectors.getLanguagesDictionary,
});

CustomTemplatesTab.propTypes = {
    clonePages: PropTypes.array,
    customTemplate: PropTypes.object,
    handleSelectPage: PropTypes.func,
    isFetchingCustomTemplate: PropTypes.bool,
    languagesDictionary: PropTypes.object,
    proposalsNumber: PropTypes.number,
    templateBaseType: PropTypes.number,
};

export default connect(mapStateToProps, null)(CustomTemplatesTab);
