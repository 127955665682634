import React, {
    useCallback,
    useContext,
    useEffect,
    useRef,
    useState,
} from 'react';

import { styled } from '@mui/material/styles';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { createStructuredSelector } from 'reselect';
import {
    PageToolbar,
    withTemplateCore,
    editionLevels,
} from 'sunwise-template-core';
import { Box, Grid } from 'sunwise-ui';

import SwipeableDrawer from 'common/components/SwipeableDrawer';
import * as lisaFileActions from 'common/modules/lisaFiles/actions';
import { LoadingContext } from 'common/utils/contexts';
import { getCurrentLanguage } from 'common/utils/helpers/multiregion';
import { getToken } from 'common/utils/helpers/session';
import { scroll } from 'common/utils/mixins';

import * as actions from './actions';
import {
    HeaderPreview,
    PreviewContent,
    StyledSideBarColumn,
    SupportMenuContent,
} from './components';
import {
    getIsDownloadingFileContextMessage,
    getIsLoadingContextMessage,
    getIsUpdatingContextMessage,
    handleClickDocumentItemBuild,
    handleClickDownloadBuild,
    handleClickElectricBillBuild,
    handleCloseDrawer,
    handleScrollTopSideBar,
} from './helpers';
import * as selectors from './selectors';

const PageWrapper = styled(Box)(
    ({ theme }) => `
    background:  ${
        theme.palette.mode === 'dark' ? '#000' : 'rgb(248, 252, 255)'
    };
    height: calc(100% - 100px);
    overflow-y: scroll;
    padding-top: 24px;
    position: relative;
    width: 100%;

    .apexcharts-theme-light {
        color: black;
    }

    ${scroll.custom()}
`
);

const Container = ({
    breadcrumbLocations,
    canModify,
    currentBreadcrumb,
    customTemplateId,
    downloadElectricBill,
    downloadReportCsv,
    downloadReportPdf,
    editionDisabled,
    fetchCustomTemplate,
    fetchElectricBill,
    getTemplateUpdatedData,
    initialize,
    initTemplate,
    isFetching,
    isFetchingCustomTemplate,
    isFetchingPdf,
    isInitializing,
    isUpdatingCustomTemplate,
    match,
    prepareRegenerateTemplate,
    reportDetails,
    reset,
    resetTemplate,
    restorePages,
    selectedDocument,
    setEditionDisabled,
    setSelectedDocument,
    setTemplateBackup,
    templateType,
    updateTemplate,
}) => {
    const loadingContext = useContext(LoadingContext);
    const format = localStorage.getItem('reportDownloadFormat') || '2';
    const [downloadFormat, setDownloadFormat] = useState(Number(format));
    const sideBarRef = useRef(null);
    const swipeableDrawerRef = useRef(null);
    const reportId = match.params.uid;
    const reportName = get(reportDetails, 'name', '');

    useEffect(() => {
        getIsLoadingContextMessage({
            isFetching,
            isFetchingCustomTemplate,
            isInitializing,
            loadingContext,
        });
    }, [isFetching, isFetchingCustomTemplate, isInitializing]);

    useEffect(() => () => reset(), []);

    useEffect(() => {
        if (!reportId) return;
        resetTemplate();
        initialize({ id: reportId, initTemplate });
        setSelectedDocument({
            id: reportId,
            name: reportName,
            type: templateType,
        });
    }, [reportId]);

    useEffect(() => {
        getIsUpdatingContextMessage(
            editionDisabled,
            isUpdatingCustomTemplate,
            loadingContext,
            setEditionDisabled
        );
    }, [isUpdatingCustomTemplate]);

    useEffect(() => {
        getIsDownloadingFileContextMessage({
            isFetchingPdf,
            loadingContext,
        });
    }, [isFetchingPdf]);

    const handleUpdateTemplate = useCallback(() => {
        updateTemplate({
            getTemplateUpdatedData,
            id: selectedDocument?.id,
            setTemplateBackup,
        });
    }, [selectedDocument, getTemplateUpdatedData, setTemplateBackup]);

    const handleClickElectricBillItem = (document) =>
        handleClickElectricBillBuild({
            document,
            fetchElectricBill,
            loadingContext,
            resetTemplate,
            setDownloadFormat,
            setSelectedDocument,
            swipeableDrawerRef,
        });

    const handleClickDownload = () =>
        handleClickDownloadBuild({
            downloadElectricBill,
            downloadFormat,
            downloadReportCsv,
            downloadReportPdf,
            loadingContext,
            selectedDocument,
        });

    const handleClickDocumentItem = (document) =>
        handleClickDocumentItemBuild({
            document,
            fetchCustomTemplate,
            initTemplate,
            resetTemplate,
            selectedDocument,
            setDownloadFormat,
            setSelectedDocument,
            swipeableDrawerRef,
        });

    const handleClickEditButton = () => {
        setEditionDisabled(false);
        setTemplateBackup();
        handleScrollTopSideBar(sideBarRef);
        handleCloseDrawer(swipeableDrawerRef);
    };

    const isDownloading = isFetchingPdf;

    const handleDblClick = (e) => {
        if (e?.detail === 2 && canModify) handleClickEditButton();
    };

    const handleOnRegenerateTemplate = () =>
        prepareRegenerateTemplate({
            initTemplate,
            reportId,
            resetTemplate,
            templateId: customTemplateId,
        });

    const supportMenu = (
        <SupportMenuContent
            canModify={canModify}
            downloadFormat={downloadFormat}
            editionDisabled={editionDisabled}
            handleClickDocumentItem={handleClickDocumentItem}
            handleClickDownload={handleClickDownload}
            handleClickEditButton={handleClickEditButton}
            handleClickElectricBillItem={handleClickElectricBillItem}
            handleOnRegenerateTemplate={handleOnRegenerateTemplate}
            handleUpdateTemplate={handleUpdateTemplate}
            isDownloading={isDownloading}
            isUpdatingCustomTemplate={isUpdatingCustomTemplate}
            reportDetails={reportDetails}
            reportId={reportId}
            reportName={reportName}
            restorePages={restorePages}
            selectedDocument={selectedDocument}
            setDownloadFormat={setDownloadFormat}
            setEditionDisabled={setEditionDisabled}
            swipeableDrawerRef={swipeableDrawerRef}
        />
    );

    return (
        <>
            <Grid container spacing={0}>
                <Grid
                    item
                    lg={14}
                    md={12}
                    sx={{
                        height: {
                            lg: 'calc(100vh - 64px)',
                            xs: 'calc(100vh - 105px)',
                        },
                    }}
                    xs={18}
                >
                    <HeaderPreview
                        breadcrumbLocations={breadcrumbLocations}
                        currentBreadcrumb={currentBreadcrumb}
                        reportStatus={get(reportDetails, 'status', 0)}
                    />

                    <PageToolbar
                        editionLevel={editionLevels.PARTIAL_EDITION_MODE}
                        reOrderDisabled
                        sxToolbar={{
                            pl: '76px',
                            ...(editionDisabled ? { minHeight: 0 } : {}),
                        }}
                        visible={!editionDisabled}
                    />

                    <PageWrapper
                        sx={{ pb: { lg: 0, xs: '50px' } }}
                        toolbar={editionDisabled ? 'false' : 'true'}
                        onClick={handleDblClick}
                    >
                        <PreviewContent
                            selectedDocument={selectedDocument}
                            editionDisabled={editionDisabled}
                        />
                    </PageWrapper>
                </Grid>

                <StyledSideBarColumn
                    item
                    lg={4}
                    md={6}
                    ref={sideBarRef}
                    sx={{
                        display: { md: 'block', xs: 'none' },
                        height: {
                            md: 'calc(100vh - 64px)',
                            xs: 'calc(100vh - 105px)',
                        },
                    }}
                >
                    {supportMenu}
                </StyledSideBarColumn>
            </Grid>

            <SwipeableDrawer ref={swipeableDrawerRef}>
                {supportMenu}
            </SwipeableDrawer>
        </>
    );
};

const mapStateToProps = createStructuredSelector({
    customTemplateId: selectors.getCustomTemplateId,
    isFetchingCustomTemplate: selectors.getIsFetchingCustomTemplate,
    isFetchingPdf: selectors.getIsFetchingPdfFile,
    isInitializing: selectors.getIsInitializing,
    isUpdatingCustomTemplate: selectors.getIsUpdatingCustomTemplate,
    selectedDocument: selectors.getSelectedDocument,
});

const mapDispatchToProps = (dispatch) => ({
    downloadElectricBill: (params) =>
        dispatch(actions.downloadElectricBill(params)),
    downloadReportCsv: (params) => dispatch(actions.downloadReportCsv(params)),
    downloadReportPdf: (params) => dispatch(actions.downloadReportPdf(params)),
    fetchCustomTemplate: (params) =>
        dispatch(actions.fetchCustomTemplate(params)),
    fetchElectricBill: (fileName, onSuccess) =>
        dispatch(lisaFileActions.getFile(fileName, onSuccess)),
    initialize: (params) => dispatch(actions.initialize(params)),
    prepareRegenerateTemplate: (params) =>
        dispatch(actions.prepareRegenerateTemplate(params)),
    reset: () => dispatch(actions.reset()),
    setSelectedDocument: (values) =>
        dispatch(actions.setSelectedDocument(values)),
    updateTemplate: (params) => dispatch(actions.updateTemplate(params)),
});

Container.propTypes = {
    breadcrumbLocations: PropTypes.array,
    canModify: PropTypes.bool,
    currentBreadcrumb: PropTypes.string,
    customTemplateId: PropTypes.string,
    downloadElectricBill: PropTypes.func,
    downloadReportCsv: PropTypes.func,
    downloadReportPdf: PropTypes.func,
    editionDisabled: PropTypes.bool,
    fetchCustomTemplate: PropTypes.func,
    fetchElectricBill: PropTypes.func,
    getTemplateUpdatedData: PropTypes.func,
    initialize: PropTypes.func,
    initTemplate: PropTypes.func,
    isFetching: PropTypes.bool,
    isFetchingCustomTemplate: PropTypes.bool,
    isFetchingPdf: PropTypes.bool,
    isInitializing: PropTypes.bool,
    isUpdatingCustomTemplate: PropTypes.bool,
    match: PropTypes.object,
    prepareRegenerateTemplate: PropTypes.func,
    reportDetails: PropTypes.object,
    reset: PropTypes.func,
    resetTemplate: PropTypes.func,
    restorePages: PropTypes.func,
    selectedDocument: PropTypes.object,
    setEditionDisabled: PropTypes.func,
    setSelectedDocument: PropTypes.func,
    setTemplateBackup: PropTypes.func,
    templateType: PropTypes.number,
    updateTemplate: PropTypes.func,
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withRouter,
    withTemplateCore(() => ({
        application: 'sunwise',
        baseUrl: process.env.REACT_APP_API_URL,
        editionMode: editionLevels.PARTIAL_EDITION_MODE,
        froalaLicenseKey: process.env.REACT_APP_FROALA_LICENSE,
        googleApiKey: process.env.REACT_APP_GOOGLE_MAPS_KEY,
        language: getCurrentLanguage(),
        token: getToken(),
    }))
)(Container);
