import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { createStructuredSelector } from 'reselect';
import { UiDialog as Dialog } from 'sunwise-ui';

import * as actions from './actions';
import ProjectForm from './components/ProjectForm';
import * as selectors from './selectors';

const Container = ({
    fetchProjects,
    handleOpenProject,
    initializeValues,
    match,
    modalIsOpen,
    reset,
    resetForm,
    setModalIsOpen,
}) => {
    const { t } = useTranslation();

    return (
        <Dialog
            onClose={() => setModalIsOpen(false)}
            onEnter={() => initializeValues({ contact: match.params.uid })}
            onExited={() => {
                reset();
                resetForm();
            }}
            open={modalIsOpen}
            title={t('Project information')}
            size="sm"
        >
            <ProjectForm
                fetchProjects={fetchProjects}
                handleOpenProject={handleOpenProject}
            />
        </Dialog>
    );
};

const mapStateToProps = createStructuredSelector({
    modalIsOpen: selectors.getModalIsOpen,
});

const mapDispatchToProps = (dispatch) => ({
    initializeValues: (values) => dispatch(actions.initializeValues(values)),
    reset: () => dispatch(actions.reset()),
    resetForm: () => dispatch(actions.resetForm()),
    setModalIsOpen: (isOpen) => dispatch(actions.setModalIsOpen(isOpen)),
});

Container.propTypes = {
    fetchProjects: PropTypes.func,
    handleOpenProject: PropTypes.func,
    initializeValues: PropTypes.func,
    match: PropTypes.object,
    modalIsOpen: PropTypes.bool,
    reset: PropTypes.func,
    resetForm: PropTypes.func,
    setModalIsOpen: PropTypes.func,
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withRouter
)(Container);
