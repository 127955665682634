import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { BottomActions, Box, Button, Card, Stack, StackItem } from 'sunwise-ui';

import PlaceholderCardDatasheets from 'common/components/placeholder/PlaceholderCardDatasheets';
import TextVariant from 'common/components/TextVariant';

import { canAddOwnDatasheet } from '../helpers';

import DatasheetItem from './DatasheetItem';

const ProductDatasheetsCard = ({
    datasheets,
    handleClickCreate,
    handleClickDelete,
    handleClickDownload,
    handleClickUpdate,
    isFetchingProductDetail,
    isVisibleForm,
    values,
}) => {
    const { t } = useTranslation();
    return (
        <PlaceholderCardDatasheets ready={isFetchingProductDetail}>
            <Card>
                <Card.Header>
                    <TextVariant
                        text={t('Data sheet', { count: 2 })}
                        variant="bold"
                    />
                </Card.Header>

                <Stack>
                    {datasheets &&
                        datasheets.map((datasheet, index) => (
                            <StackItem key={index}>
                                <DatasheetItem
                                    datasheet={datasheet}
                                    handleClickAdd={() => handleClickCreate()}
                                    handleClickDelete={handleClickDelete}
                                    handleClickDownload={handleClickDownload}
                                    handleClickUpdate={handleClickUpdate}
                                    values={values}
                                />
                            </StackItem>
                        ))}
                </Stack>

                {!isVisibleForm && canAddOwnDatasheet(datasheets) && (
                    <BottomActions>
                        <Box p={2}>
                            <Button
                                onClick={() => handleClickCreate()}
                                sx={{ width: { xs: '100%', md: 'auto' } }}
                                variant="outlined"
                            >
                                {t('Add data sheet')}
                            </Button>
                        </Box>
                    </BottomActions>
                )}
            </Card>
        </PlaceholderCardDatasheets>
    );
};

ProductDatasheetsCard.propTypes = {
    datasheets: PropTypes.array,
    handleClickCreate: PropTypes.func,
    handleClickDelete: PropTypes.func,
    handleClickDownload: PropTypes.func,
    handleClickUpdate: PropTypes.func,
    isFetchingProductDetail: PropTypes.bool,
    isVisibleForm: PropTypes.bool,
    values: PropTypes.object,
};

export default ProductDatasheetsCard;
