import React from 'react';

import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import Chart from 'react-apexcharts';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from 'sunwise-ui';

const ChartWrapper = styled(Box)`
    filter: url('#blur');
    filter: blur(3px);

    &:after {
        bottom: 0;
        content: '';
        display: block;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        z-index: 2;
    }
`;

const WrapperText = styled(Box)`
    background-color: rgba(0, 0, 0, 0.65);
    border-radius: 5px;
    color: #fff;
    height: fit-content;
    margin: auto;
    padding: 8px;
    text-align: center;
`;

const dummyChartConfig = {
    options: {
        chart: { background: '#ffffff00', toolbar: { show: false } },
        dataLabels: { enabled: false },
        fill: { opacity: 1 },
        legend: { show: false },
        plotOptions: { bar: { horizontal: false } },
        stroke: { colors: ['transparent'], show: true, width: 2 },
        xaxis: { categories: new Array(12).fill('---') },
    },
    series: [
        {
            data: [44, 55, 57, 56, 61, 58, 63, 60, 66, 44, 55, 57],
            name: '',
        },
        {
            data: [76, 85, 101, 98, 87, 105, 91, 114, 94, 76, 85, 101],
            name: '',
        },
    ],
};

const EmptyChart = ({ description }) => {
    const { t } = useTranslation();

    return (
        <Box position="relative">
            <ChartWrapper>
                <Chart
                    height={300}
                    options={dummyChartConfig.options}
                    series={dummyChartConfig.series}
                    type="bar"
                />
            </ChartWrapper>

            <Box
                sx={{
                    display: 'flex',
                    height: '100%',
                    left: 0,
                    maxWidth: { xs: '100%', md: '500px' },
                    mx: 'auto',
                    position: 'absolute',
                    right: 0,
                    top: 0,
                }}
            >
                <WrapperText>
                    <Typography variant="h6">
                        {t(
                            "Oops! It seems there isn't enough data available to display at the moment"
                        )}
                    </Typography>

                    {description && (
                        <Typography variant="body2">{description}</Typography>
                    )}
                </WrapperText>
            </Box>
        </Box>
    );
};

EmptyChart.propTypes = {
    description: PropTypes.string,
};

export default EmptyChart;
