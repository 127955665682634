import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import ProviderImage from 'common/components/ProviderImage';
import { useBreakpoint } from 'common/hooks';

import * as afterSalesMeasurementSourceSelectors from '../../afterSalesMeasurementSource/selectors';
import * as credentialIntegrationsSelectors from '../../credentialIntegrations/selectors';

const itemsByBreakpoint = {
    xs: 0,
    sm: 2,
    md: 6,
    lg: 10,
    xl: 16,
};

const Providers = ({ powerStationsProviders, providersDictionary }) => {
    const [itemsToShow, setItemsToShow] = useState([]);
    const breakpoint = useBreakpoint();

    useEffect(() => {
        if (!powerStationsProviders?.length) {
            setItemsToShow([]);
            return;
        }
        const limit = itemsByBreakpoint[breakpoint];
        const items = powerStationsProviders?.slice(0, limit);
        setItemsToShow(items);
    }, [breakpoint, powerStationsProviders]);

    if (!itemsToShow?.length || !providersDictionary) return null;

    return itemsToShow.map((provider) => (
        <ProviderImage
            key={provider}
            provider={providersDictionary[provider]}
            style={{ maxHeight: 30, maxWidth: 60 }}
        />
    ));
};

const mapStateToProps = createStructuredSelector({
    powerStationsProviders:
        afterSalesMeasurementSourceSelectors.getPowerStationsProviders,
    providersDictionary: credentialIntegrationsSelectors.getProvidersDictionary,
});

Providers.propTypes = {
    powerStationsProviders: PropTypes.array,
    providersDictionary: PropTypes.object,
};

export default connect(mapStateToProps)(Providers);
