import React from 'react';

import { styled, useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { Box, IconButton, Tooltip } from 'sunwise-ui';

const WrapperButtons = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    backgroundColor: theme.palette.mode === 'dark' ? '#282B30' : '#eef4fa',
    borderRadius: '8px',
    padding: '7px 5px',
}));

const SwitchButtons = ({
    hide,
    items,
    itemsBaseClass = '__userguiding_proposal_generator_switch_button_',
    sxBox,
    wrapperClass = '__userguiding_proposal_generator_switch_buttons',
}) => {
    const theme = useTheme();
    if (hide || items.length === 0) return null;
    return (
        <WrapperButtons
            className={wrapperClass}
            justifyContent="center"
            sx={sxBox}
        >
            {items.map(
                (
                    {
                        handleClick,
                        icon,
                        isActive,
                        isDisabled,
                        isVisible = true,
                        nameId,
                        tooltipLabel,
                    },
                    index
                ) => {
                    if (!isVisible) return;

                    const color = theme.palette.mode === 'dark' && '#61666b';
                    const opacity = isDisabled && '0.5';

                    return (
                        <Tooltip
                            key={`tooltip-button-${index}`}
                            title={tooltipLabel}
                        >
                            <IconButton
                                className={`${itemsBaseClass}${index} ${
                                    isActive ? 'active' : ''
                                }`}
                                disabled={isDisabled}
                                id={`proposal_generator_${nameId}_button`}
                                onClick={() => handleClick()}
                                sx={{
                                    '& .MuiSvgIcon-root': { color, opacity },
                                }}
                                variant="default"
                            >
                                {icon}
                            </IconButton>
                        </Tooltip>
                    );
                }
            )}
        </WrapperButtons>
    );
};

SwitchButtons.propTypes = {
    hide: PropTypes.bool,
    items: PropTypes.array,
    itemsBaseClass: PropTypes.string,
    sxBox: PropTypes.object,
    wrapperClass: PropTypes.string,
};

export default SwitchButtons;
