import React from 'react';

import PropTypes from 'prop-types';
import { Typography } from 'sunwise-ui';

import { formatDate, parseDate } from 'common/utils/dates';
import { getDateFormatByLocale } from 'common/utils/helpers/multiregion';

const DateFormatter = ({ row }) => {
    return (
        <Typography variant="caption">
            {formatDate(
                parseDate(row.created_at, 'dd/MM/yyyy HH:mm:ss'),
                getDateFormatByLocale()
            )}
        </Typography>
    );
};

DateFormatter.propTypes = {
    row: PropTypes.object,
};

export default DateFormatter;
