import get from 'lodash/get';

import { getCompanyBranding } from 'common/api/v1/branding';

import { FETCH, FETCH_SUCCESS, FETCH_FAILURE } from '../actionTypes';
import { actions } from '../reducer';

import initializeForm from './initializeForm';

export default () => (dispatch) => {
    dispatch(actions[FETCH]());

    getCompanyBranding()
        .then((response) => {
            dispatch(actions[FETCH_SUCCESS](response.data.data));
            dispatch(initializeForm(response.data.data));
        })
        .catch((error) =>
            dispatch(actions[FETCH_FAILURE](get(error, 'data.errors', [])))
        );
};
