import i18next from 'i18next';
import { createSelector } from 'reselect';

import { NAME } from './constants';

export const getModel = (state) => state[NAME];

export const getFetchBrands = createSelector(
    getModel,
    (model) => model.fetchBrands || {}
);
export const getFetchBrandsData = createSelector(
    getFetchBrands,
    (model) => model.data || []
);
export const getFetchBrandsToSelect = createSelector(
    getFetchBrandsData,
    (data) => {
        let brands = data.map((item) => ({ value: item.id, label: item.name }));
        brands.unshift({ value: '', label: i18next.t('No selected brand') });
        return brands;
    }
);

export const getFetchItems = createSelector(
    getModel,
    (model) => model.fetchItems
);
export const getIsFetchingItems = createSelector(
    getFetchItems,
    (model) => model.isFetching
);
export const getFetchItemsData = createSelector(
    getFetchItems,
    (model) => model?.data?.data
);
export const getFetchItemsPagination = createSelector(
    getFetchItems,
    (model) => model?.data?.pagination
);

export const getFetchItemsParent = createSelector(
    getModel,
    (model) => model.fetchItemsParent
);
export const getIsFetchingItemsParent = createSelector(
    getFetchItemsParent,
    (model) => model.isFetching
);
export const getFetchItemsParentData = createSelector(
    getFetchItemsParent,
    (model) => model?.data?.data
);
export const getFetchItemsParentPagination = createSelector(
    getFetchItemsParent,
    (model) => model?.data?.pagination
);

export const getFetchSuppliers = createSelector(
    getModel,
    (model) => model.fetchSuppliers || {}
);
export const getFetchSuppliersData = createSelector(
    getFetchSuppliers,
    (model) => model.data || []
);
export const getFetchSuppliersToSelect = createSelector(
    getFetchSuppliersData,
    (data) => data.map((item) => ({ value: item.id, label: item.name }))
);

export const getInitialMarginValues = createSelector(
    getModel,
    (model) => model.initialMarginValues
);
export const getInitialValues = createSelector(
    getModel,
    (model) => model.initialValues
);

export const getIsDownloading = createSelector(
    getModel,
    (model) => model.isDownloading
);

export const getIsInitializing = createSelector(
    getModel,
    (model) => model.isInitializing
);

export const getIsNewRecord = createSelector(
    getModel,
    (model) => model.isNewRecord
);

export const getIsOpenModal = createSelector(
    getModel,
    (model) => model.isOpenModal
);

export const getIsOpenModalMargin = createSelector(
    getModel,
    (model) => model.isOpenModalMargin
);

export const getIsOpenModalProduct = createSelector(
    getModel,
    (model) => model.isOpenModalProduct
);

export const getIsUploading = createSelector(
    getModel,
    (model) => model.isUploading
);

export const getProductAddedToBranches = createSelector(
    getModel,
    (model) => model.productAddedToBranches
);
export const getIsSavingProductAddedToBranches = createSelector(
    getProductAddedToBranches,
    (model) => model.isSaving
);

export const getSaveItem = createSelector(getModel, (model) => model.saveItem);
export const getIsSavingItem = createSelector(
    getSaveItem,
    (saveItem) => saveItem.isSaving
);
export const getSaveItemErrors = createSelector(
    getSaveItem,
    (model) => model.errors
);

export const getSaveMargin = createSelector(
    getModel,
    (model) => model.saveMargin
);
export const getIsSavingMargin = createSelector(
    getSaveMargin,
    (model) => model.isSaving
);
export const getSaveMarginErrors = createSelector(
    getSaveMargin,
    (model) => model.errors
);
