import i18next from 'i18next';

import alerts from 'common/modules/alerts';

import regenerateSmartDocumentTemplate from './regenerateSmartDocumentTemplate';

export default ({ documentId, initTemplate, resetTemplate }) =>
    (dispatch) => {
        const dataAlert = {
            confirmText: i18next.t('Regenerate'),
            messages: [
                i18next.t('Regenerating the template will restore all changes'),
            ],
            onSuccess: () => {
                if (resetTemplate) resetTemplate();
                dispatch(
                    regenerateSmartDocumentTemplate({
                        documentId,
                        initTemplate,
                    })
                );
            },
            title: i18next.t('Confirmation'),
            type: alerts.ALERT_TYPE_CONFIRM,
        };

        dispatch(alerts.actions.show(dataAlert));
    };
