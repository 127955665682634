import React from 'react';

import DownloadIcon from '@mui/icons-material/Download';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
    Box,
    Divider,
    IconButton,
    Stack,
    StackItem,
    Tooltip,
    Typography,
} from 'sunwise-ui';

import DocumentStatus from 'common/components/DocumentStatus';
import { DOCUMENT_STATUS } from 'common/constants';

import icoPreapproved from 'resources/icoPreapproved.svg';

const CreditDocuments = ({
    documents,
    onClickStatus,
    preDownload,
    isDisabledStatus = false,
}) => {
    const { t } = useTranslation();

    if (isEmpty(documents)) {
        return (
            <Box display="flex" alignItems="center" p={2}>
                <Typography
                    variant="body2"
                    fontWeight="bold"
                    sx={{ color: '#848bab' }}
                >
                    {t('No documents assigned')}
                </Typography>
            </Box>
        );
    }

    return (
        <Stack
            divider={<Divider />}
            sx={{ '.MuiDivider-fullWidth': { mt: 0 } }}
        >
            {documents.map((document) => (
                <StackItem key={document.id}>
                    <Box display="flex" alignItems="center" gap={1}>
                        <Box
                            display="flex"
                            alignItems="center"
                            sx={{ width: '65px' }}
                        >
                            {document.file &&
                                document.status !=
                                    DOCUMENT_STATUS.NO_DOCUMENT_STATUS.key && (
                                    <IconButton
                                        href={document.file}
                                        target="_blank"
                                    >
                                        <DownloadIcon />
                                    </IconButton>
                                )}
                            {document.required && (
                                <Tooltip
                                    title={t(
                                        'This symbol specifies which documents are required for pre-validation'
                                    )}
                                >
                                    <img src={icoPreapproved} width="35" />
                                </Tooltip>
                            )}
                        </Box>
                        <Box sx={{ width: '170px', pl: '5px' }}>
                            <Typography variant="body2" fontWeight="bold">
                                {document.name}
                            </Typography>
                        </Box>
                        <DocumentStatus
                            disabled={isDisabledStatus}
                            onClick={() =>
                                onClickStatus({
                                    ...document,
                                    preDownloadRequired: preDownload,
                                })
                            }
                            status={
                                document.status ===
                                DOCUMENT_STATUS.REQUESTED_STATUS.key
                                    ? DOCUMENT_STATUS.NO_DOCUMENT_STATUS.value
                                    : document.status
                            }
                            sxButton={{ ml: 'auto' }}
                        />
                    </Box>
                </StackItem>
            ))}
        </Stack>
    );
};

CreditDocuments.propTypes = {
    documents: PropTypes.array,
    onClickStatus: PropTypes.func,
    preDownload: PropTypes.bool,
    isDisabledStatus: PropTypes.bool,
};

export default CreditDocuments;
