import React from 'react';

import DeleteIcon from '@mui/icons-material/Delete';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box, Card, Grid, Typography } from 'sunwise-ui';

import { DropdownCardMenu } from 'common/components/cards';
import ProviderImage from 'common/components/ProviderImage';

import { VISIBLE_USER_FIELDS } from '../constants';
import { getFieldLabel } from '../helpers';

const IntegrationsList = ({
    canDelete,
    integrations,
    prepareDeleteKey,
    providersDictionary,
}) => {
    const { t } = useTranslation();

    if (!integrations?.length) return <Typography>{t('No items')}</Typography>;

    return (
        <Grid container>
            {integrations.map((integration) => {
                if (!integration) return null;
                const provider = providersDictionary?.[integration.type];

                return (
                    <Grid key={integration.id} item xs={18} sm={9} lg={6}>
                        <Card>
                            <Card.Header
                                sx={{
                                    alignItems: 'flex-start',
                                    display: 'flex',
                                    gap: 2,
                                    p: 1,
                                }}
                            >
                                <ProviderImage
                                    provider={provider}
                                    style={{
                                        flex: '1 1 auto',
                                        maxHeight: '60px',
                                        objectFit: 'contain',
                                        minWidth: '60px',
                                    }}
                                />

                                <DropdownCardMenu
                                    items={[
                                        {
                                            handleClick: () =>
                                                prepareDeleteKey(
                                                    integration.id
                                                ),
                                            icon: (
                                                <DeleteIcon fontSize="small" />
                                            ),
                                            label: t('Delete'),
                                            visible: canDelete,
                                        },
                                    ]}
                                    visible={canDelete}
                                />
                            </Card.Header>

                            <Card.Body
                                sx={{
                                    display: 'flex',
                                    flexDirection: { xs: 'column', sm: 'row' },
                                    justifyContent: 'space-between',
                                }}
                            >
                                {provider?.fields?.map((field) => {
                                    if (!VISIBLE_USER_FIELDS[field])
                                        return null;

                                    return (
                                        <Box key={field}>
                                            <Typography variant="body2">
                                                <span
                                                    style={{ fontWeight: 600 }}
                                                >
                                                    {getFieldLabel(field)}:
                                                </span>{' '}
                                                {integration[field] || '*****'}
                                            </Typography>
                                        </Box>
                                    );
                                })}
                            </Card.Body>
                        </Card>
                    </Grid>
                );
            })}
        </Grid>
    );
};

IntegrationsList.propTypes = {
    canDelete: PropTypes.bool,
    integrations: PropTypes.array,
    prepareDeleteKey: PropTypes.func,
    providersDictionary: PropTypes.object,
};

export default IntegrationsList;
