import { getItem } from 'common/api/v2/consumptionProfiles';

import {
    FETCH_CONSUMPTION_PROFILE,
    FETCH_CONSUMPTION_PROFILE_FAILURE,
    FETCH_CONSUMPTION_PROFILE_SUCCESS,
} from '../actionTypes';
import { projectConsumptionModalActions } from '../reducer';

export default (id) => (dispatch) => {
    dispatch(projectConsumptionModalActions[FETCH_CONSUMPTION_PROFILE]());

    getItem(id)
        .then((response) => {
            const data = response.data.data;
            dispatch(
                projectConsumptionModalActions[
                    FETCH_CONSUMPTION_PROFILE_SUCCESS
                ](data)
            );
        })
        .catch((error) =>
            dispatch(
                projectConsumptionModalActions[
                    FETCH_CONSUMPTION_PROFILE_FAILURE
                ](error)
            )
        );
};
