import { getCredentials } from 'common/api/zap/credentials';

import {
    FETCH_CREDENTIALS,
    FETCH_CREDENTIALS_FAILURE,
    FETCH_CREDENTIALS_SUCCESS,
} from '../actionTypes';
import { actions } from '../reducer';

import authenticate from './authenticate';

export default () => (dispatch) =>
    authenticate(() => {
        dispatch(actions[FETCH_CREDENTIALS]());

        return getCredentials()
            .then((response) =>
                dispatch(actions[FETCH_CREDENTIALS_SUCCESS](response?.data))
            )
            .catch((error) =>
                dispatch(actions[FETCH_CREDENTIALS_FAILURE](error))
            );
    });
