import { getTemplatesFilteredByBranch } from 'common/api/v1/offerCustomTemplate';

import {
    FETCH_CUSTOM_OFFER_TEMPLATE,
    FETCH_CUSTOM_OFFER_TEMPLATE_FAILURE,
    FETCH_CUSTOM_OFFER_TEMPLATE_SUCCESS,
} from '../actionTypes';
import { integrationWebActions } from '../reducer';

export default (branch) => (dispatch) => {
    dispatch(integrationWebActions[FETCH_CUSTOM_OFFER_TEMPLATE]());

    getTemplatesFilteredByBranch(branch)
        .then((response) => {
            dispatch(
                integrationWebActions[FETCH_CUSTOM_OFFER_TEMPLATE_SUCCESS](
                    response.data.data
                )
            );
        })
        .catch((error) =>
            dispatch(
                integrationWebActions[FETCH_CUSTOM_OFFER_TEMPLATE_FAILURE](
                    error?.response?.data?.errors
                )
            )
        );
};
