import { createSelector } from 'reselect';

import { NAME } from './constants';

export const getModel = (state) => state[NAME];

export const getInitialValues = createSelector(
    getModel,
    (model) => model.initialValues
);

export const getContactId = createSelector(
    getModel,
    (model) => model.contactId
);

export const getIsVisibleModal = createSelector(
    getModel,
    (model) => model.isVisibleModal
);

export const getSaveContact = createSelector(
    getModel,
    (model) => model.saveContact
);

export const getErrors = createSelector(
    getSaveContact,
    (saveContact) => saveContact.errors
);

export const getIsLoading = createSelector(
    getSaveContact,
    (saveContact) => saveContact.isLoading
);

export const getFetchSupportContacts = createSelector(
    getModel,
    (model) => model.fetchSupportContacts
);

export const getIsLoadingFetchSupportContacts = createSelector(
    getFetchSupportContacts,
    (fetchSupportContacts) => fetchSupportContacts.isLoading
);

export const getDataSupportContacts = createSelector(
    getFetchSupportContacts,
    (fetchSupportContacts) => fetchSupportContacts.data
);

export const getDataSupportContactsError = createSelector(
    getFetchSupportContacts,
    (fetchSupportContacts) => fetchSupportContacts.error
);

export const getDeleteItem = createSelector(
    getModel,
    (model) => model.deleteItem
);

export const getIsDeletingItem = createSelector(
    getDeleteItem,
    (deleteItem) => deleteItem.isDeleting
);

export const getDataDeleteItem = createSelector(
    getDeleteItem,
    (deleteItem) => deleteItem.data
);

export const getErrorsDeleteItem = createSelector(
    getDeleteItem,
    (deleteItem) => deleteItem.errors
);
