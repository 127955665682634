import { endOfMonth, endOfYear, startOfMonth, startOfYear } from 'date-fns';

import { formatDate, parseDate } from 'common/utils/dates';
import { showFastApiErrorsAsAlert } from 'common/utils/helpers';

import { DEFAULT_DATE_FORMAT } from '../../afterSalesSettings/constants';
import {
    FETCH_MEASURED_ENERGY,
    FETCH_MEASURED_ENERGY_FAILURE,
    FETCH_MEASURED_ENERGY_SUCCESS,
} from '../actionTypes';
import { DATE_OPTIONS, VIEW_OPTIONS } from '../constants';
import { actions } from '../reducer';

import fetchProjectMeasuredEnergyByDate from './fetchProjectMeasuredEnergyByDate';

const applyFormat = (date, format = DEFAULT_DATE_FORMAT) =>
    formatDate(date, format);

const getDateRange = (viewOption, parsedDate) => {
    switch (viewOption) {
        case VIEW_OPTIONS.ANNUAL:
            return {
                finalDate: applyFormat(endOfYear(parsedDate)),
                freq: 'M',
                initialDate: applyFormat(startOfYear(parsedDate)),
                useSummaries: true,
            };
        case VIEW_OPTIONS.MONTHLY:
            return {
                finalDate: applyFormat(endOfMonth(parsedDate)),
                freq: 'D',
                initialDate: applyFormat(startOfMonth(parsedDate)),
                useSummaries: true,
            };
        case VIEW_OPTIONS.DAILY_5_MINUTES:
            return {
                finalDate: null,
                freq: '5T',
                initialDate: applyFormat(parsedDate),
            };

        default:
            return {
                finalDate: null,
                freq: 'H',
                initialDate: applyFormat(parsedDate),
            };
    }
};

export default (projectId, values, callback) => (dispatch) => {
    if (!values) return;

    dispatch(actions[FETCH_MEASURED_ENERGY]());

    const { date, power_stations, view_option } = values;
    const format = DATE_OPTIONS[view_option].inputFormat || DEFAULT_DATE_FORMAT;

    const parsedDate = parseDate(date, format);
    const dateValues = getDateRange(view_option, parsedDate);

    const newValues = { ...dateValues, projects: power_stations };

    dispatch(fetchProjectMeasuredEnergyByDate(projectId, newValues))
        .then((data) => {
            dispatch(actions[FETCH_MEASURED_ENERGY_SUCCESS](data));
            return data;
        })
        .catch((error) => {
            dispatch(actions[FETCH_MEASURED_ENERGY_FAILURE](error));
            showFastApiErrorsAsAlert(dispatch, error?.response);
        })
        .finally((data) => {
            if (callback) callback(values, data);
        });
};
