import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import DropdownCardMenu from 'common/components/cards/DropdownCardMenu';

const MenuOptions = ({
    canModify,
    document,
    handleClickDocumentItem,
    handleClickEditButton,
    handleClickPrepareRegenerateSmartDocumentTemplate,
    isApproved,
    isDisabled,
    prepareOfferSmartDocumentDelete,
    prepareOfferSmartDocumentDuplicate,
    prepareOfferSmartDocumentRename,
    proposalDocumentDefault,
    proposalId,
    selectedDocumentId,
}) => {
    const { t } = useTranslation();
    return (
        <DropdownCardMenu
            items={[
                {
                    disabled: isDisabled || !canModify,
                    handleClick: () => handleClickEditButton(),
                    label: t('Edit'),
                },
                {
                    disabled: isDisabled || !canModify,
                    handleClick: () =>
                        handleClickPrepareRegenerateSmartDocumentTemplate(
                            document.id
                        ),
                    label: t('Regenerate'),
                },
                {
                    disabled: isDisabled || !canModify,
                    handleClick: () =>
                        prepareOfferSmartDocumentRename(
                            document.name,
                            document.id
                        ),
                    label: t('Rename'),
                },
                {
                    disabled: isDisabled || !canModify,
                    handleClick: () =>
                        prepareOfferSmartDocumentDuplicate(
                            document.name,
                            document.id
                        ),
                    label: t('Duplicate'),
                },
                {
                    disabled: isDisabled || !canModify,
                    handleClick: () =>
                        prepareOfferSmartDocumentDelete(
                            handleClickDocumentItem,
                            proposalDocumentDefault,
                            proposalId,
                            document.id
                        ),
                    label: t('Delete'),
                },
            ]}
            sxIconButton={{
                '&:hover': {
                    backgroundColor: 'transparent',
                },
            }}
            visible={!isApproved && document.id === selectedDocumentId}
        />
    );
};

MenuOptions.propTypes = {
    canModify: PropTypes.bool,
    document: PropTypes.object,
    handleClickDocumentItem: PropTypes.func,
    handleClickEditButton: PropTypes.func,
    handleClickPrepareRegenerateSmartDocumentTemplate: PropTypes.func,
    isApproved: PropTypes.bool,
    isDisabled: PropTypes.bool,
    prepareOfferSmartDocumentDelete: PropTypes.func,
    prepareOfferSmartDocumentDuplicate: PropTypes.func,
    prepareOfferSmartDocumentRename: PropTypes.func,
    proposalDocumentDefault: PropTypes.object,
    proposalId: PropTypes.string,
    selectedDocumentId: PropTypes.string,
};

export default MenuOptions;
