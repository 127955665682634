import { isEmpty, isUndefined } from 'lodash';

import {
    DEFAULT_NO_PERMISSIONS,
    PERMISSION_LEVELS,
} from 'common/constants/permissionsV2';

import {
    getPermissionsDictionary,
    getUserPermissions,
    logoutActions,
} from './session';

export const isValidAccess = (isPublic, requiredPermissions) => {
    if (isPublic || isEmpty(requiredPermissions)) return true;
    if (requiredPermissions)
        return validateAccessWithPermissions(requiredPermissions);
    return false;
};

export const validateAccessWithPermissions = (requiredPermissions) => {
    let count = 0;
    const permissions = getUserPermissions();
    if (isEmpty(permissions)) logoutActions();
    if (isEmpty(requiredPermissions)) return true;
    if (!isEmpty(permissions) && requiredPermissions) {
        requiredPermissions.forEach((permission) => {
            const hasPermission = getHasPermission(permission);
            if (hasPermission) count++;
        });
        return count >= 1 && count <= requiredPermissions.length;
    }
    return false;
};

export const validateByPermissionCode = (permissionCode) => {
    const permissionsDictionary = getPermissionsDictionary();
    if (Array.isArray(permissionCode)) {
        const permissionDictionary = permissionCode.reduce((acc, current) => {
            const permission = permissionsDictionary[current];
            if (!isUndefined(permission)) {
                return {
                    ...acc,
                    [current]: getCanActionsDictionary(
                        permissionsDictionary,
                        current
                    ),
                };
            } else {
                return {
                    ...acc,
                    [current]: DEFAULT_NO_PERMISSIONS,
                };
            }
        }, {});
        return {
            getPermissionsByCode: (code) => permissionDictionary[code],
        };
    }
    return getCanActionsDictionary(permissionsDictionary, permissionCode);
};

export const getCanActionsDictionary = (
    permissionsDictionary,
    permissionCode
) => {
    const permission = permissionsDictionary[permissionCode];
    if (!isUndefined(permission)) {
        const { is_enabled: isEnabled, permission_level: permissionLevel } =
            permission;
        switch (permissionLevel) {
            case PERMISSION_LEVELS.VIEW:
                return {
                    canDelete: false,
                    canModify: false,
                    canView: isEnabled,
                };
            case PERMISSION_LEVELS.MODIFY:
                return {
                    canDelete: false,
                    canModify: true,
                    canView: isEnabled,
                };
            case PERMISSION_LEVELS.ALL:
                return {
                    canDelete: true,
                    canModify: true,
                    canView: isEnabled,
                };
            default:
                return DEFAULT_NO_PERMISSIONS;
        }
    }
    return DEFAULT_NO_PERMISSIONS;
};

export const getHasPermission = (id) => {
    const permissionsDictionary = getPermissionsDictionary();
    const permission = permissionsDictionary[id];
    if (permission) {
        const { is_enabled: isEnabled, permission_level: permissionLevel } =
            permission;
        return isEnabled && permissionLevel !== PERMISSION_LEVELS.NONE;
    }
    return false;
};
