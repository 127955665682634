import React, { useContext, useEffect } from 'react';

import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { UiDialog as Dialog } from 'sunwise-ui';

import { LoadingContext } from 'common/utils/contexts';
import yupResolver from 'common/utils/yupResolver';

import * as actions from '../actions';
import { BUSINESS_TYPE_ITEMS } from '../constants';
import * as selectors from '../selectors';
import validateCertifiedRate from '../validateCertifiedRate';

import CertifiedRateFormTabs from './CertifiedRateFormTabs';

const CertifiedRateModalForm = ({
    initialValues,
    isSavingRate,
    modalIsOpen,
    othersUnitsDataToSelect,
    resetRateModalForm,
    saveRate,
    setModalIsOpen,
}) => {
    const { t } = useTranslation();
    const loadingContext = useContext(LoadingContext);
    const { getValues, control, handleSubmit, reset, setValue, watch } =
        useForm({
            defaultValues: initialValues,
            resolver: yupResolver(validateCertifiedRate),
        });
    const [businessType, name] = watch(['business_type', 'name']);

    useEffect(() => reset(initialValues), [initialValues]);

    useEffect(() => {
        if (isEmpty(loadingContext)) return;
        if (isSavingRate) loadingContext.openLoading(t('Saving').concat('...'));
        else loadingContext.closeLoading();
    }, [isSavingRate]);

    return (
        <Dialog
            onClose={() => setModalIsOpen(false)}
            onExited={() => resetRateModalForm()}
            open={modalIsOpen}
            size="xl"
            title={`${t(BUSINESS_TYPE_ITEMS[businessType])} - ${name}`}
        >
            <CertifiedRateFormTabs
                control={control}
                getValues={getValues}
                handleSubmit={handleSubmit}
                isSavingRate={isSavingRate}
                othersUnitsDataToSelect={othersUnitsDataToSelect}
                rateName={name}
                saveRate={saveRate}
                setValue={setValue}
                showMode={false}
            />
        </Dialog>
    );
};

const mapStateToProps = createStructuredSelector({
    initialValues: selectors.getCertifiedRateModalInitialValues,
    isSavingRate: selectors.getIsSavingRate,
    modalIsOpen: selectors.getCertifiedRateModalIsOpen,
    othersUnitsDataToSelect: selectors.getOthersUnitsDataToSelect,
});

const mapDispatchToProps = (dispatch) => ({
    resetRateModalForm: () => dispatch(actions.resetCertifiedRateModalForm()),
    saveRate: (values) => dispatch(actions.saveCertifiedRate(values)),
    setModalIsOpen: (isOpen) =>
        dispatch(actions.setCertifiedRateModalIsOpen(isOpen)),
});

CertifiedRateModalForm.propTypes = {
    initialValues: PropTypes.object,
    isSavingRate: PropTypes.bool,
    modalIsOpen: PropTypes.bool,
    othersUnitsDataToSelect: PropTypes.array,
    resetRateModalForm: PropTypes.func,
    saveRate: PropTypes.func,
    setModalIsOpen: PropTypes.func,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CertifiedRateModalForm);
