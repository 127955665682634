import { getCreditAppDocuments } from 'common/api/v1/sunpay';

import {
    FETCH_CREDIT_DOCUMENTS,
    FETCH_CREDIT_DOCUMENTS_FAILURE,
    FETCH_CREDIT_DOCUMENTS_SUCCESS,
} from '../actionTypes';
import { projectCommOfferTableActions } from '../reducer';

export default (creditAppId) => (dispatch) => {
    dispatch(projectCommOfferTableActions[FETCH_CREDIT_DOCUMENTS]());

    getCreditAppDocuments(creditAppId)
        .then((response) =>
            dispatch(
                projectCommOfferTableActions[FETCH_CREDIT_DOCUMENTS_SUCCESS](
                    response.data.data
                )
            )
        )
        .catch((error) =>
            dispatch(
                projectCommOfferTableActions[FETCH_CREDIT_DOCUMENTS_FAILURE](
                    error?.response?.data?.errors
                )
            )
        );
};
