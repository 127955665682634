import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box } from 'sunwise-ui';

import { ReactHookFormSelect } from 'common/components/form/bootstrap';
import TitleWithDetail from 'common/components/TitleWithDetail';

const TimeToCommissioningForm = ({ control, isHidden, isLocked }) => {
    const { t } = useTranslation();
    const options = new Array(12)
        .fill(0)
        .map((_, index) => ({ label: index, value: index }));
    if (isHidden) return null;
    return (
        <>
            <Box sx={{ mb: 2 }}>
                <TitleWithDetail>{t('Time to commissioning')}</TitleWithDetail>
            </Box>
            <ReactHookFormSelect
                control={control}
                disabled={isLocked}
                name="generation_delay"
                label={t('Month', { count: 2 })}
                options={options}
            />
        </>
    );
};

TimeToCommissioningForm.propTypes = {
    control: PropTypes.object,
    isHidden: PropTypes.bool,
    isLocked: PropTypes.bool,
};

export default TimeToCommissioningForm;
