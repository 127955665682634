import { NAME } from './constants';

export const FETCH_CLONE_PAGES = `${NAME}/FETCH_CLONE_PAGES`;
export const FETCH_CLONE_PAGES_FAILURE = `${NAME}/FETCH_CLONE_PAGES_FAILURE`;
export const FETCH_CLONE_PAGES_SUCCESS = `${NAME}/FETCH_CLONE_PAGES_SUCCESS`;

export const FETCH_CUSTOM_TEMPLATE = `${NAME}/FETCH_CUSTOM_TEMPLATE`;
export const FETCH_CUSTOM_TEMPLATE_FAILURE = `${NAME}/FETCH_CUSTOM_TEMPLATE_FAILURE`;
export const FETCH_CUSTOM_TEMPLATE_SUCCESS = `${NAME}/FETCH_CUSTOM_TEMPLATE_SUCCESS`;

export const INITIALIZE = `${NAME}/INITIALIZE`;
export const INITIALIZE_FAILURE = `${NAME}/INITIALIZE_FAILURE`;
export const INITIALIZE_SUCCESS = `${NAME}/INITIALIZE_SUCCESS`;

export const RESET = `${NAME}/RESET`;

export const SET_CLONE_PAGES = `${NAME}/SET_CLONE_PAGES`;
export const SET_CLONE_PAGES_FAILURE = `${NAME}/SET_CLONE_PAGES_FAILURE`;
export const SET_CLONE_PAGES_SUCCESS = `${NAME}/SET_CLONE_PAGES_SUCCESS`;

export const SET_PAGE_SIZE = `${NAME}/SET_PAGE_SIZE`;

export const UPDATE_TEMPLATE = `${NAME}/UPDATE_TEMPLATE`;
export const UPDATE_TEMPLATE_FAILURE = `${NAME}/UPDATE_TEMPLATE_FAILURE`;
export const UPDATE_TEMPLATE_SUCCESS = `${NAME}/UPDATE_TEMPLATE_SUCCESS`;
