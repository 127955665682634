import React from 'react';

import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { useRouteMatch, useLocation } from 'react-router-dom';
import { Box, Divider, Link, Typography } from 'sunwise-ui';

import { HIDDEN_FOOTER_ROUTES } from 'common/constants';

const StyledFooter = styled(Box)(({ theme }) => ({
    background: theme.palette.background.paper,
    padding: '15px',
    position: 'relative',
    zIndex: 2,
}));

const catchphrase = [
    {
        title: 'Here Comes the Sun',
        url: 'https://open.spotify.com/track/6dGnYIeXmHdcikdzNNDMm2?si=2SuM4dlTTj6vB3UTsqIpOA',
    },
    {
        title: 'Steal My Sunshine',
        url: 'https://open.spotify.com/track/4agp6oHofabdUedr0B1krj?si=SrSZ0apRQDizGwGkFIlMww',
    },
    {
        title: 'Sunshine',
        url: '	https://open.spotify.com/track/0z0JSkE5Nw3i3nin3BBSuG?si=-DrIok-kTn6aXXMWhjEolg',
    },
    {
        title: "Don't Let the Sun Go Down on Me",
        url: '	https://open.spotify.com/track/1o0ihabnEn4jVkHaWpQ4dA?si=XHjzwBnzTzCn3qLjtWmOSg',
    },
    {
        title: 'Chasing the Sun',
        url: '	https://open.spotify.com/track/4b4c0oH7PtrPsI86drzgFs?si=eiX-TGt-Tsu0cxnG09uLdw',
    },
    {
        title: 'Island in the Sun',
        url: '	https://open.spotify.com/track/2MLHyLy5z5l5YRp7momlgw?si=gxNHiH08T0m14zmeendMbg',
    },
    {
        title: 'Children of the Sun',
        url: '	https://open.spotify.com/track/1koW7ZXIK67vKaYWjzNlG8?si=AMCptRlTT1qoCfgt1PryfQ',
    },
    {
        title: 'Waiting for the Sun to Shine',
        url: '	https://open.spotify.com/track/6m65PQAYl7PJb7W8hlBBov?si=qdWmVJxNTFS_bWG8e9vBvw',
    },
    {
        title: 'Sunshine Superman',
        url: '	https://open.spotify.com/track/4RGxUjaCmVtD30pIhtEi7w?si=d_GjlzZQR9uNYByrwyZSLQ',
    },
    {
        title: 'We Touched the Sun',
        url: '	https://open.spotify.com/track/5iR3h8jV834JZLhaCBExAp?si=xVO1B9R8RWOvxDi-QgjBuA',
    },
    {
        title: 'Invisible Sun',
        url: '	https://open.spotify.com/track/5u8Dwz4h41DDOKfeFfexMR?si=dmlYX_R5T42Tkb_CkYcVzA',
    },
    {
        title: "Ain't Goin' Down 'til the Sun Comes Up",
        url: '	https://open.spotify.com/track/5zkaDGUM3xx5PnzfUbY4jY?si=_9Eoqgu2QOil7r9g2l__CA',
    },
    {
        title: 'Sunshine of Your Love',
        url: '	https://open.spotify.com/track/6FRwDxXsvSasw0y2eDArsz?si=gKMzmMXTQ2q3p6xxAlY_OQ',
    },
    {
        title: 'Sunshine on My Shoulders',
        url: '	https://open.spotify.com/track/3m8lTUL5GxGUNSaycI77ND?si=qiIlmIf0TPGbJxAeqtqJ8A',
    },
    {
        title: 'Keep on the Sunny Side',
        url: '	https://open.spotify.com/track/3uHV4rbEWjIkNTn3qTPt0d?si=W_NYR7o8Tu-VbLswwxVbGg',
    },
    {
        title: 'Country Sunshine',
        url: '	https://open.spotify.com/track/4jE0JNXJ2H3UdhoYBeu8st?si=CGe2p3JIScu8RFwsVEyx8g',
    },
    {
        title: 'Sunshine and Summertime',
        url: '	https://open.spotify.com/track/2TmD4OyEl5WyhXR6wIia6m?si=5MD9LEFzSW2HuPMyan_6hg',
    },
    {
        title: 'Waiting for the Sun',
        url: '	https://open.spotify.com/track/6KVM6U9Wcxgjh0jDjoRDgh?si=sRA5RLhoTiiujGRI-ltz7w',
    },
    {
        title: 'Good Day Sunshine',
        url: '	https://open.spotify.com/track/7HTH1ppjkkOe7RLoBDKXYJ?si=PbSRLZ1iQKi-n5HmS2HNpA',
    },
    {
        title: 'You Are the Sunshine of My Life',
        url: '	https://open.spotify.com/track/0n2pjCIMKwHSXoYfEbYMfX?si=CN3nxCoHReqqQ9dRfeTtDw',
    },
    {
        title: 'Tequila Sunrise',
        url: '	https://open.spotify.com/track/1WYokrkFOb9TFVuTrmxTTt?si=S4ZC3DdWTQGmh8ClvnDx8w',
    },
    {
        title: 'Everyday Sunshine',
        url: '	https://open.spotify.com/track/2PlkOKm1MHdudrd1AyTg4l?si=odv2bMaHTN-rC-nF0abycA',
    },
    {
        title: 'Sunshine on a Rainy Day',
        url: '	https://open.spotify.com/track/4j7OVQ5OaIwSekl8q9mJhb?si=sJFCjr6cSRCa4DQ8k_-FYg',
    },
    {
        title: 'You Are My Sunshine',
        url: '	https://open.spotify.com/track/76pllrWpfiCoLGhijQBYhO?si=RLGXOReFTXKjK2CvJzvRHQ',
    },
    {
        title: 'I Make My Own Sunshine',
        url: '	https://open.spotify.com/track/7o8td6GLrNECJ8ncm9RJMK?si=VgE7G4wST9OsqbXufmeO8Q',
    },
    {
        title: 'Bringing Back the Sunshine',
        url: '	https://open.spotify.com/track/5f4oD2jzyYjGM3Ds3bbYQx?si=6g8wR4iiQ3-WlfK8vWPTnQ',
    },
    {
        title: 'Trip Around the Sun',
        url: '	https://open.spotify.com/track/3RcFrGu08HvRPCh00iB2kJ?si=U1w0uzn6SC2qQw_dIdEbNg',
    },
    {
        title: 'Around the Sun',
        url: '	https://open.spotify.com/track/5jhm0V9ZMCVknuEogFnAPV?si=dblIzmrPSy-chW1mUp7KXg',
    },
    {
        title: 'Chasing the Sun',
        url: '	https://open.spotify.com/track/4b4c0oH7PtrPsI86drzgFs?si=1TZ4Fg0-T6Wlw08AtA3RQw',
    },
    {
        title: 'Walking on Sunshine',
        url: 'https://open.spotify.com/track/05wIrZSwuaVWhcv5FfqeH0?si=TBVc8lzdQiyVdCvIfR8s6g',
    },
    {
        title: 'Follow the Sun',
        url: 'https://open.spotify.com/track/0X1IP1zJNd3FgdiY75n7zW?si=ItLRbWdARayh9liAsk0M3A',
    },
    {
        title: "Ain't no Sunshine",
        url: 'https://open.spotify.com/track/1k1Bqnv2R0uJXQN4u6LKYt?si=cfFjZwhTTDGgyMwmjVcMPg',
    },
];

const item = catchphrase[Math.floor(Math.random() * catchphrase.length)];

const Footer = ({ showPlaylist }) => {
    const location = useLocation();
    const match = useRouteMatch();
    const route = location.pathname.slice(
        0,
        location.pathname.lastIndexOf('/')
    );
    if (
        HIDDEN_FOOTER_ROUTES.includes(route) ||
        HIDDEN_FOOTER_ROUTES.includes(match?.path)
    )
        return null;
    return (
        <Box sx={{ mt: 'auto' }}>
            <Divider />
            <StyledFooter>
                <Typography
                    variant="body2"
                    sx={{ color: '#848bab', textAlign: 'center' }}
                >
                    {process.env.REACT_APP_ENV}-{process.env.REACT_APP_VERSION}
                    {showPlaylist && (
                        <>
                            &nbsp;-&nbsp;
                            <Link
                                href={item.url}
                                rel="noopener noreferrer"
                                target="_blank"
                                underline="none"
                            >
                                {item.title}
                            </Link>
                        </>
                    )}
                </Typography>
            </StyledFooter>
        </Box>
    );
};

Footer.defaultProps = { showPlaylist: true };

Footer.propTypes = { showPlaylist: PropTypes.bool };

export default Footer;
