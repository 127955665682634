import { getLastCommercialOffersByRate } from 'common/api/v1/dashboards';

import {
    FETCH_COMMERCIAL_OFFERS_BY_RATE,
    FETCH_COMMERCIAL_OFFERS_BY_RATE_FAILURE,
    FETCH_COMMERCIAL_OFFERS_BY_RATE_SUCCESS,
} from '../actionTypes';
import { dashboardActions } from '../reducer';

export default (id) => (dispatch) => {
    dispatch(dashboardActions[FETCH_COMMERCIAL_OFFERS_BY_RATE]());

    getLastCommercialOffersByRate(id)
        .then((response) =>
            dispatch(
                dashboardActions[FETCH_COMMERCIAL_OFFERS_BY_RATE_SUCCESS](
                    response.data.data
                )
            )
        )
        .catch((error) =>
            dispatch(
                dashboardActions[FETCH_COMMERCIAL_OFFERS_BY_RATE_FAILURE](
                    error?.response?.data?.errors
                )
            )
        );
};
