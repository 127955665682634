import API from '../..';
const ENTITY = 'offer-custom-template';

export const deleteOfferCustomTemplate = (id) =>
    API.delete(`/api/v1/${ENTITY}/${id}`);
export const getFilteredTemplates = ({
    language,
    orderBy = null,
    page = 1,
    perPage = 25,
    searchText = '',
    sortBy = null,
    type,
} = {}) =>
    API.get(`/api/v1/list/${ENTITY}/`, {
        params: {
            language: language,
            order_by: orderBy,
            page: page,
            per_page: perPage,
            sort: sortBy,
            title: searchText,
            type: type,
        },
    });
export const getOfferCustomTemplates = ({ branchOffice, type = 0 }) =>
    API.get(`/api/v1/${ENTITY}/`, {
        params: { branch_office: branchOffice, type },
    });
export const getTemplatesFilteredByBranch = (branchOffice) =>
    API.get(`/api/v1/list/${ENTITY}/`, {
        params: { branch_office: branchOffice },
    });
