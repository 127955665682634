import { getRates } from 'common/api/v1/rate';

import {
    FETCH_RATES,
    FETCH_RATES_SUCCESS,
    FETCH_RATES_FAILURE,
} from '../actionTypes';
import { dashboardActions } from '../reducer';

export default () => (dispatch) => {
    dispatch(dashboardActions[FETCH_RATES]());

    return getRates()
        .then((response) =>
            dispatch(dashboardActions[FETCH_RATES_SUCCESS](response.data.data))
        )
        .catch((error) => {
            dispatch(
                dashboardActions[FETCH_RATES_FAILURE](
                    error?.response?.data?.errors
                )
            );
        });
};
