import React, { useEffect } from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroller';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Box, Typography } from 'sunwise-ui';

import * as actions from '../actions';
import { getDateTitle } from '../helpers';
import * as selectors from '../selectors';

import ReadItem from './ReadItem';

const Historical = ({
    fetchLogs,
    isLoading,
    logsData,
    logsPagination,
    projectsInfo,
    providersDictionary,
    resetLogs,
    wrapperRef,
}) => {
    const { t } = useTranslation();

    useEffect(() => {
        return () => resetLogs();
    }, []);

    return (
        <InfiniteScroll
            getScrollParent={() => wrapperRef.current}
            hasMore={!isLoading && logsPagination?.page < logsPagination?.pages}
            loader={
                <div className="scroll-loader" key={0}>
                    {t('Loading').concat('...')}
                </div>
            }
            loadMore={(page) => {
                if (isLoading) return;
                fetchLogs(page);
            }}
            initialLoad={true}
            pageStart={0}
            threshold={100}
            useWindow={false}
        >
            {logsData?.map((item) => (
                <Box key={item?.date}>
                    <Typography
                        variant="h6"
                        sx={{
                            backgroundColor: 'background.paper',
                            borderBottom: 1,
                            borderColor: 'divider',
                            position: 'sticky',
                            py: 1,
                            top: 0,
                            transform: 'translateY(-4px)',
                        }}
                    >
                        {getDateTitle(item.date)}
                    </Typography>

                    <Box display="flex" flexDirection="column" gap={2} my={1}>
                        {item?.reads?.map((read) => (
                            <ReadItem
                                key={read.read_id}
                                projectsInfo={projectsInfo}
                                providersDictionary={providersDictionary}
                                read={read}
                            />
                        ))}
                    </Box>
                </Box>
            ))}
        </InfiniteScroll>
    );
};

const mapStateToProps = createStructuredSelector({
    isLoading: selectors.getLogsLoading,
    logsPagination: selectors.getLogsPagination,
});

const mapDispatchToProps = (dispatch) => ({
    fetchLogs: (page, page_size) =>
        dispatch(actions.fetchLogs(page, page_size)),
    resetLogs: () => dispatch(actions.resetLogs()),
});

Historical.propTypes = {
    fetchLogs: PropTypes.func,
    isLoading: PropTypes.bool,
    logsData: PropTypes.array,
    logsPagination: PropTypes.object,
    projectsInfo: PropTypes.object,
    providersDictionary: PropTypes.object,
    resetLogs: PropTypes.func,
    wrapperRef: PropTypes.object,
};

export default connect(mapStateToProps, mapDispatchToProps)(Historical);
