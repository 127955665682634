import { createSlice } from '@reduxjs/toolkit';

import { NAME } from '../constants';

import {
    FETCH_CUSTOM_OFFER_TEMPLATE,
    FETCH_CUSTOM_OFFER_TEMPLATE_FAILURE,
    FETCH_CUSTOM_OFFER_TEMPLATE_SUCCESS,
    FETCH_DATA,
    FETCH_DATA_FAILURE,
    FETCH_DATA_SUCCESS,
    INITIALIZE_FORM,
    RESET_FORM,
    SAVE,
    SAVE_FAILURE,
    SAVE_SUCCESS,
} from './actionTypes';

const INITIAL_STATE = {
    apiKey: null,
    initialValuesDummyForm: {},
    initialValuesForm: {
        status_project: '',
        contact_origin: '',
        agent: '',
        branch_office: '',
        custom_offer_template: '',
        service_number: { 1: true },
        method_consumtion: { ENERGY: true, MONEY: true },
        install_type: { RATE: true, INSTALLATION_TYPE: true },
        style: {
            h1: {
                color: '#2845FF',
                fontSize: '20',
            },
            h2: {
                color: '#2845FF',
                fontSize: '20',
            },
            button: {
                borderRadius: '25',
                backgroundColor: 'primary.main',
                color: '#fff',
                fontSize: '16',
                text: '',
            },
        },
    },
    integration: {
        data: [],
        errors: null,
        isFetching: false,
    },
    save: {
        data: [],
        errors: null,
        isSaving: false,
    },
    fetchCustomOfferTemplates: {
        data: [],
        errors: [],
        isFetching: false,
    },
};

const integrationWebSlice = createSlice({
    name: NAME,
    initialState: INITIAL_STATE,
    reducers: {
        [FETCH_CUSTOM_OFFER_TEMPLATE]: (state) => {
            state.fetchCustomOfferTemplates = {
                ...state.fetchCustomOfferTemplates,
                errors: [],
                isFetching: true,
            };
        },
        [FETCH_CUSTOM_OFFER_TEMPLATE_FAILURE]: (state, action) => {
            state.fetchCustomOfferTemplates = {
                ...state.fetchCustomOfferTemplates,
                errors: action.payload,
                isFetching: false,
            };
        },
        [FETCH_CUSTOM_OFFER_TEMPLATE_SUCCESS]: (state, action) => {
            state.fetchCustomOfferTemplates = {
                ...state.fetchCustomOfferTemplates,
                data: action.payload,
                isFetching: false,
            };
        },
        [FETCH_DATA]: (state) => {
            state.integration = {
                ...state.integration,
                errors: null,
                isFetching: true,
            };
        },
        [FETCH_DATA_FAILURE]: (state, action) => {
            state.integration = {
                ...state.integration,
                errors: action.payload,
                isFetching: false,
            };
        },
        [FETCH_DATA_SUCCESS]: (state, action) => {
            state.integration = {
                ...state.integration,
                data: action.payload,
                errors: null,
                isFetching: false,
            };
        },
        [INITIALIZE_FORM]: (state, action) => {
            state.initialValuesForm = {
                ...state.initialValuesForm,
                ...action.payload,
            };
        },
        [RESET_FORM]: (state) => {
            state.initialValuesForm = INITIAL_STATE.initialValuesForm;
        },
        [SAVE]: (state) => {
            state.save = { ...state.save, errors: null, isSaving: true };
        },
        [SAVE_FAILURE]: (state, action) => {
            state.save = {
                ...state.save,
                errors: action.payload,
                isSaving: false,
            };
        },
        [SAVE_SUCCESS]: (state, action) => {
            state.save = {
                ...state.save,
                data: action.payload,
                errors: null,
                isSaving: false,
            };
        },
    },
});

export const integrationWebActions = integrationWebSlice.actions;

export default integrationWebSlice.reducer;
