import { number, object, string } from 'yup';

import getValidationTexts from 'common/utils/getValidationTexts';

export default () => {
    const { NUMBER_TYPE_TEXT, REQUIRED_TEXT, REQUIRED_TEXT_MAP } =
        getValidationTexts();

    return object().shape({
        name: string().required(REQUIRED_TEXT).nullable(),
        currency: string().required(REQUIRED_TEXT).nullable(),
        latitude: number()
            .typeError(NUMBER_TYPE_TEXT)
            .required(REQUIRED_TEXT)
            .nullable(),
        longitude: number()
            .typeError(NUMBER_TYPE_TEXT)
            .required(REQUIRED_TEXT)
            .nullable(),
        position: object().required(REQUIRED_TEXT_MAP).nullable(),
    });
};
