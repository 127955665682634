import React, { useEffect, useState } from 'react';

import LinearProgress, {
    linearProgressClasses,
} from '@mui/material/LinearProgress';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Box } from 'sunwise-ui';

import zapLogo from 'resources/zap_logo.svg';

import * as actions from './actions';
import { MAX_ATTEMPTS, STATUS, STATUS_CONFIG } from './constants';
import * as selectors from './selectors';

const Wrapper = styled('div')`
    align-items: center;
    background: ${({ theme }) =>
        theme?.palette?.mode === 'dark' ? '#000000ee' : '#002438ee'};
    display: flex;
    flex-direction: column;
    gap: 16px;
    height: 100%;
    justify-content: center;
    left: 0;
    opacity: ${({ visible }) => (visible ? '1' : '0')};
    position: fixed;
    top: 0;
    transition: ${({ visible }) =>
        visible
            ? 'opacity 0.25s linear, visibility 0s linear'
            : 'opacity 0.25s linear, visibility 0s linear 0.25s'};
    visibility: ${({ visible }) => (visible ? 'visible' : 'hidden')};
    width: 100%;
    z-index: 9999;
`;

const ImageContainer = styled(Box)`
    margin-bottom: 16px;
    position: relative;

    img,
    svg {
        animation-duration: 8s;
        animation-iteration-count: infinite;
        animation-name: RotateAndFade;
        animation-timing-function: linear;
        width: 64px;
    }
`;

const MessageText = styled('span')`
    color: #fff;
    font-size: 16px;
    letter-spacing: 0.01rem;
    line-height: 18px;
    min-height: 18px;
    text-align: center;
`;

const ProgressLine = styled(LinearProgress)(({ status }) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor: 'rgba(218, 112, 214, 0.4)',
    },
    [`& .${linearProgressClasses.bar}`]: {
        backgroundColor: STATUS_CONFIG[status]?.color || 'primary.main',
    },
}));

const Container = ({
    entity,
    entityId,
    isLoading: forceLoading,
    onFinished,
    reset,
    socketInfo,
}) => {
    const [isLoading, setIsLoading] = useState(false);
    const [loadingData, setLoadingData] = useState({});
    const { t } = useTranslation();

    useEffect(() => () => reset(), []);

    useEffect(() => {
        if (!socketInfo?.[entity]?.[entityId]) return;

        const { attempt, progress, status } = socketInfo[entity][entityId];

        const isFinished =
            progress === 100 &&
            (status !== STATUS.ERROR || attempt + 1 >= MAX_ATTEMPTS[entity]);

        setIsLoading(!isFinished);
        setLoadingData({ attempt: attempt + 1, progress, status });

        if (!isFinished) return;
        if (onFinished) onFinished();
        setLoadingData({});
    }, [socketInfo, entity, entityId]);

    if (!isLoading && !forceLoading) return null;

    const showAttempts = loadingData?.attempt > 1;

    return (
        <Wrapper visible={isLoading || forceLoading}>
            <ImageContainer>
                <img src={zapLogo} />
            </ImageContainer>

            {showAttempts && (
                <MessageText>
                    {`${t('Attempt')}: ${loadingData?.attempt || 1} / ${
                        MAX_ATTEMPTS[entity]
                    }`}
                </MessageText>
            )}

            <Box
                display="flex"
                justifyContent="space-between"
                maxWidth="300px"
                width="100%"
            >
                <MessageText>{t('Loading')}...</MessageText>

                <MessageText>{loadingData?.progress || 0}%</MessageText>
            </Box>

            <Box maxWidth="300px" width="100%">
                <ProgressLine
                    status={loadingData?.status}
                    value={loadingData?.progress || 0}
                    variant="determinate"
                />
            </Box>
        </Wrapper>
    );
};

const mapStateToProps = createStructuredSelector({
    socketInfo: selectors.getSocketInfoModel,
});

const mapDispatchToProps = (dispatch) => ({
    reset: () => dispatch(actions.reset()),
});

Container.propTypes = {
    entity: PropTypes.string,
    entityId: PropTypes.string,
    isLoading: PropTypes.bool,
    onFinished: PropTypes.func,
    reset: PropTypes.func,
    socketInfo: PropTypes.object,
};

export default connect(mapStateToProps, mapDispatchToProps)(Container);
