import { getOfferCustomTemplate } from 'common/api/v1/newOfferCustomTemplate';

import {
    FETCH_CUSTOM_TEMPLATE,
    FETCH_CUSTOM_TEMPLATE_SUCCESS,
    FETCH_CUSTOM_TEMPLATE_FAILURE,
    SET_PAGE_SIZE,
} from '../actionTypes';
import { editTemplateViewActions } from '../reducer';

export default (id) => (dispatch) =>
    new Promise((resolve, reject) => {
        dispatch(editTemplateViewActions[FETCH_CUSTOM_TEMPLATE]());

        return getOfferCustomTemplate(id)
            .then((response) => {
                dispatch(
                    editTemplateViewActions[FETCH_CUSTOM_TEMPLATE_SUCCESS](
                        response.data
                    )
                );

                dispatch(
                    editTemplateViewActions[SET_PAGE_SIZE](
                        response.data.page_size
                    )
                );

                resolve(response.data);
            })
            .catch((error) => {
                dispatch(
                    editTemplateViewActions[FETCH_CUSTOM_TEMPLATE_FAILURE](
                        error
                    )
                );
                reject();
            });
    });
