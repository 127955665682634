import { number, object, string } from 'yup';

import getValidationTexts from 'common/utils/getValidationTexts';

export default () => {
    const { NUMBER_TYPE_TEXT, REQUIRED_TEXT } = getValidationTexts();
    return object().shape({
        position: object().shape({
            latitude: number()
                .typeError(NUMBER_TYPE_TEXT)
                .required(REQUIRED_TEXT),
            longitude: number()
                .typeError(NUMBER_TYPE_TEXT)
                .required(REQUIRED_TEXT),
        }),
        title: string().required(REQUIRED_TEXT).nullable(),
    });
};
