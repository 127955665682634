import i18next from 'i18next';
import { number, object, string } from 'yup';

import getValidationTexts from 'common/utils/getValidationTexts';

import { getConceptsSchemaValidation } from './validations';

export default (values) => {
    const {
        MAX_100_VALIDATION_TEXT,
        MIN_ZERO_VALIDATION_TEXT,
        NUMBER_TYPE_TEXT,
        REQUIRED_TEXT,
    } = getValidationTexts();

    return object().shape({
        annual_percentage_increase: number()
            .typeError(NUMBER_TYPE_TEXT)
            .min(0, MIN_ZERO_VALIDATION_TEXT)
            .max(100, MAX_100_VALIDATION_TEXT)
            .required(REQUIRED_TEXT)
            .nullable(),
        dap: number()
            .typeError(NUMBER_TYPE_TEXT)
            .min(0, MIN_ZERO_VALIDATION_TEXT)
            .max(100, MAX_100_VALIDATION_TEXT)
            .required(REQUIRED_TEXT)
            .nullable(),
        default_compensation_scheme: string()
            .required(REQUIRED_TEXT)
            .nullable(),
        energy_export_price: number()
            .typeError(NUMBER_TYPE_TEXT)
            .required(REQUIRED_TEXT)
            .nullable(),
        netted_exported_generation_price: number()
            .typeError(NUMBER_TYPE_TEXT)
            .nullable(),
        other_concepts: getConceptsSchemaValidation({
            conceptName: 'other_concepts',
            values,
        }),
        rate_description: string()
            .required(REQUIRED_TEXT)
            .max(
                1000,
                i18next.t('Maximum {{number}} characters', {
                    number: 1000,
                })
            )
            .nullable(),
        tax_rate: number()
            .typeError(NUMBER_TYPE_TEXT)
            .min(0, MIN_ZERO_VALIDATION_TEXT)
            .max(100, MAX_100_VALIDATION_TEXT)
            .required(REQUIRED_TEXT)
            .nullable(),
        taxes_concepts: getConceptsSchemaValidation({
            conceptName: 'taxes_concepts',
            values,
        }),
    });
};
