import i18next from 'i18next';

import { deleteItem } from 'common/api/v2/consumptionProfiles';
import alerts from 'common/modules/alerts';
import { showReponseErrorsAsAlert } from 'common/utils/helpers';
import showToast from 'common/utils/showToast';

import {
    DELETE_CONSUMPTION_PROFILE,
    DELETE_CONSUMPTION_PROFILE_FAILURE,
    DELETE_CONSUMPTION_PROFILE_SUCCESS,
} from '../actionTypes';
import { consumptionProfilesActions } from '../reducer';
import * as selectors from '../selectors';

import fetchConsumptionProfiles from './fetchConsumptionProfiles';

export default (id) => (dispatch, getState) => {
    dispatch(consumptionProfilesActions[DELETE_CONSUMPTION_PROFILE]());

    deleteItem(id)
        .then(() => {
            const query = selectors.getFilterQuery(getState());
            dispatch(
                consumptionProfilesActions[DELETE_CONSUMPTION_PROFILE_SUCCESS]()
            );
            dispatch(fetchConsumptionProfiles(query));
            dispatch(alerts.actions.close());
            showToast({ body: i18next.t('It was successfully removed') });
        })
        .catch((error) => {
            dispatch(
                consumptionProfilesActions[DELETE_CONSUMPTION_PROFILE_FAILURE](
                    error?.response?.data?.errors
                )
            );
            showReponseErrorsAsAlert(dispatch, error?.response);
        });
};
