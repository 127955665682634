import React from 'react';

import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box, Divider, Typography } from 'sunwise-ui';

import ProviderImage from 'common/components/ProviderImage';

import { READ_STATUS, READ_TYPES_LABELS } from '../constants';

const Dot = styled('span')`
    background-color: ${({ color }) => color};
    border-radius: 50%;
    display: inline-block;
    height: 10px;
    width: 10px;
    margin-left: auto;
    margin-right: 8px;
`;

const ReadItem = ({ projectsInfo, providersDictionary, read }) => {
    const { t } = useTranslation();

    if (!read) return null;
    const { project_name, provider_id, status, sunwise_project_id, read_type } =
        read;

    const provider = providersDictionary[provider_id];
    const readStatus = READ_STATUS[status];
    const readType = READ_TYPES_LABELS[read_type];
    const projectInfo = projectsInfo?.[sunwise_project_id];

    return (
        <Box>
            <Typography variant="subtitle2" fontWeight="bold">
                {project_name}
            </Typography>

            <Typography variant="caption">
                {t('Project')}: {projectInfo?.name || '--'}
            </Typography>

            <Box alignItems="center" display="flex" width="100%" mt={1}>
                <ProviderImage
                    provider={provider}
                    style={{ maxHeight: '20px', maxWidth: '80px' }}
                />

                <Dot color={readStatus?.color} />
                <Typography variant="body2">
                    {t(readStatus?.label)} ({t(readType)})
                </Typography>
            </Box>

            <Divider sx={{ my: 1 }} />
        </Box>
    );
};

ReadItem.propTypes = {
    projectsInfo: PropTypes.object,
    providersDictionary: PropTypes.object,
    read: PropTypes.object,
};

export default ReadItem;
