import React, { useEffect, useState } from 'react';

import AddIcon from '@mui/icons-material/Add';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { createStructuredSelector } from 'reselect';
import {
    Button,
    Container as MaterialContainer,
    Divider,
    Grid,
    UiDialog as Dialog,
} from 'sunwise-ui';

import { HeaderContainer, TitleIcon } from 'common/components';
import { PERMISSION_LIST } from 'common/constants/permissionsV2';
import withPermissions from 'common/hocs/withPermissions';
import * as multiBranchesActions from 'common/modules/multiBranches/actions';
import { getCurrenciesToSelect } from 'common/utils/helpers';
import { getIsMultibranchesAccount } from 'common/utils/helpers/session';

import * as companySelectors from '../companyGeneral/selectors';
import * as multiCurrencyActions from '../multiCurrency/actions';
import * as multiCurrencySelectors from '../multiCurrency/selectors';

import * as actions from './actions';
import { Filter, ItemForm, ListItems } from './components';
import * as selectors from './selectors';

const Container = ({
    activateItem,
    canModify,
    canView,
    changeSearchText,
    company,
    companyPositionLatLng,
    currencies,
    fetchCurrencies,
    fetchMultiBranches,
    fetchPoliticalDivisions,
    filterItems,
    handleClickCloseModalProduct,
    handleClickCreate,
    handleClickDelete,
    handleClickUpdate,
    isFetching,
    isFetchingCurrencies,
    isOpenModalProduct,
    items,
    politicalDivisionsForSelect,
    resetForm,
    searchItems,
    searchText,
}) => {
    const { t } = useTranslation();
    const [orderBy, setOrderBy] = useState('');
    const [sortBy, setSortBy] = useState('asc');
    const [dialogTitle, setDialogTitle] = useState(null);

    useEffect(() => {
        filterItems();
        fetchMultiBranches();
        fetchPoliticalDivisions('dp1');
        fetchCurrencies();
    }, []);

    const handleChangeSearch = (value) => {
        changeSearchText(value);
        searchItems(value);
    };

    const handleChangeSwitch = (id) => {
        activateItem(id);
    };

    const handleSortable = (tempOrderBy, tempSortBy) => {
        setOrderBy(tempOrderBy);
        setSortBy(tempSortBy);
        filterItems(searchText, tempOrderBy, tempSortBy);
    };

    const showCreateButton = canModify && getIsMultibranchesAccount();

    return (
        <MaterialContainer maxWidth={false}>
            <HeaderContainer>
                <Grid item xs={18}>
                    <TitleIcon
                        fontWeight="bold"
                        title={t('Branch office', { count: 2 })}
                    />
                </Grid>
            </HeaderContainer>

            <Grid container>
                <Grid item xs={18} md={showCreateButton ? 12 : 18}>
                    <Filter handleChangeSearch={handleChangeSearch} />
                </Grid>
                {showCreateButton && (
                    <Grid item xs={18} md={6} textAlign="right">
                        <Button
                            endIcon={<AddIcon />}
                            onClick={handleClickCreate}
                            sx={{
                                width: {
                                    xs: '100%',
                                    md: 'auto',
                                },
                            }}
                            variant="outlined"
                        >
                            {t('Add branch office')}
                        </Button>
                    </Grid>
                )}
            </Grid>

            <Divider sx={{ my: 2 }} />

            {canView && (
                <ListItems
                    canModify={canModify}
                    handleChangeSwitch={handleChangeSwitch}
                    handleClickDelete={handleClickDelete}
                    handleClickUpdate={handleClickUpdate}
                    handleSortable={handleSortable}
                    isFetching={isFetching}
                    items={items}
                    orderBy={orderBy}
                    sortBy={sortBy}
                />
            )}

            <Dialog
                onClose={() => {
                    resetForm();
                    handleClickCloseModalProduct();
                }}
                open={isOpenModalProduct}
                title={dialogTitle}
            >
                <ItemForm
                    canModify={canModify}
                    company={company}
                    companyPositionLatLng={companyPositionLatLng}
                    currenciesForSelect={getCurrenciesToSelect(
                        currencies,
                        isFetchingCurrencies
                    )}
                    politicalDivisionsForSelect={politicalDivisionsForSelect}
                    setDialogTitle={setDialogTitle}
                />
            </Dialog>
        </MaterialContainer>
    );
};

const mapStateToProps = createStructuredSelector({
    company: companySelectors.getCompanyData,
    companyPositionLatLng: companySelectors.getCompanyPositionLatLng,
    currencies: multiCurrencySelectors.getDataFetchCurrencies,
    isFetching: selectors.getIsFetchingItems,
    isFetchingCurrencies: multiCurrencySelectors.getIsFetchingCurrencies,
    isOpenModalProduct: selectors.getIsOpenModalProduct,
    items: selectors.getDataFetchItems,
    politicalDivisionsForSelect: selectors.getPoliticalDivisionsForSelect,
    searchText: selectors.getSearchText,
});

const mapDispatchToProps = (dispatch) => ({
    activateItem: (id) => dispatch(actions.activateItem(id)),
    changeSearchText: (value) => dispatch(actions.changeSearchText(value)),
    fetchCurrencies: () => dispatch(multiCurrencyActions.filterItems()),
    fetchPoliticalDivisions: (str) =>
        dispatch(actions.fetchPoliticalDivisions(str)),
    filterItems: (searchText, orderBy, sortBy) =>
        dispatch(actions.filterItems(searchText, orderBy, sortBy)),
    fetchMultiBranches: () => dispatch(multiBranchesActions.fetchBranches()),
    handleClickCreate: () => dispatch(actions.prepareCreate()),
    handleClickDelete: (item) => dispatch(actions.prepareDelete(item)),
    handleClickUpdate: (item) => dispatch(actions.prepareUpdate(item)),
    handleClickCloseModalProduct: () =>
        dispatch(actions.toggleModalProduct(false)),
    resetForm: () => dispatch(actions.resetForm()),
    searchItems: (searchText) => dispatch(actions.searchItems(searchText)),
});

Container.propTypes = {
    activateItem: PropTypes.func,
    changeSearchText: PropTypes.func,
    canModify: PropTypes.bool,
    canView: PropTypes.bool,
    company: PropTypes.object,
    companyPositionLatLng: PropTypes.object,
    currencies: PropTypes.array,
    fetchCurrencies: PropTypes.func,
    fetchMultiBranches: PropTypes.func,
    fetchPoliticalDivisions: PropTypes.func,
    filterItems: PropTypes.func,
    handleClickCloseModalProduct: PropTypes.func,
    handleClickCreate: PropTypes.func,
    handleClickDelete: PropTypes.func,
    handleClickUpdate: PropTypes.func,
    isFetching: PropTypes.bool,
    isFetchingCurrencies: PropTypes.bool,
    isOpenModalProduct: PropTypes.bool,
    items: PropTypes.array,
    politicalDivisionsForSelect: PropTypes.array,
    resetForm: PropTypes.func,
    searchItems: PropTypes.func,
    searchText: PropTypes.string,
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withPermissions(PERMISSION_LIST.BRANCH_OFFICES_PERMISSION)
)(Container);
