import { numberFormat } from 'common/utils/helpers';
export const handleNormalizeBuild = (handleOnSave, proposalId) => (values) =>
    handleOnSave(proposalId, values);

export const getModulesByKW = (system_size, watts) =>
    Math.round(system_size * 1000) / watts;

export const getModulesByPercentage = (
    originalConsumption,
    percentage,
    sunHours,
    watts
) => {
    const consumption = originalConsumption * (percentage / 100) * 1000;
    const generation = 365 * sunHours * watts;
    return consumption > generation ? Math.ceil(consumption / generation) : 0;
};

export const getTotalProductPower = (products) => {
    const total = products?.reduce(
        (total, product) => total + (product?.watts * product?.quantity || 0),
        0
    );

    return (total || 0) / 1000;
};

export const handleOnChangeEnergySaving = (
    formValues,
    handleNormalize,
    originalConsumption,
    sunHours,
    value
) => {
    const modules = getModulesByPercentage(
        originalConsumption,
        value,
        sunHours,
        formValues?.watts
    );
    handleNormalize({ ...formValues, quantity: modules });
};

export const handleOnChangePanelNumber = (
    formValues,
    handleNormalize,
    value
) => {
    handleNormalize({ ...formValues, quantity: value });
};

export const handleOnChangeSystemSize = (
    formValues,
    handleNormalize,
    value
) => {
    const modules = getModulesByKW(value, formValues?.watts);
    handleNormalize({ ...formValues, quantity: modules });
};

export const getAlertMessage = (text, value, countryCurrencyLocale) => {
    const formattedValue = numberFormat(value || 0, {
        decimals: 0,
        locale: countryCurrencyLocale,
        style: 'decimal',
        unit: 'kW',
    });
    return `${text || ''} (${formattedValue})`;
};
