import React, { useEffect } from 'react';

import EmailIcon from '@mui/icons-material/Email';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Box, Button, Grid } from 'sunwise-ui';

import ShowErrors from 'common/components/ShowErrors';

import { ReactComponent as SunwiseIcon } from 'resources/isotypeSunwise.svg';

import * as actions from '../actions';
import * as selectors from '../selectors';

import ReduxFieldArray from './ReduxFieldArray';

const SettingsForm = ({ errors, handleClickSave, initialValues, isSaving }) => {
    const { t } = useTranslation();

    const { control, handleSubmit, reset } = useForm({
        defaultValues: initialValues,
    });

    useEffect(() => {
        reset(initialValues);
    }, [initialValues]);

    return (
        <form>
            <Grid container>
                <Grid item xs={18}>
                    <Box
                        display="flex"
                        justifyContent="end"
                        gap={1}
                        mt={2}
                        sx={{
                            '& svg': {
                                color: '#ff9a00',
                                height: '26px',
                                width: '26px',
                            },
                        }}
                    >
                        <Box component="span" sx={{ padding: '9px', pb: 0 }}>
                            <SunwiseIcon />
                        </Box>
                        <Box component="span" sx={{ padding: '9px', pb: 0 }}>
                            <EmailIcon sx={{ color: '#ff9a00' }} />
                        </Box>
                    </Box>
                    <ReduxFieldArray control={control} name="settings" />
                </Grid>
            </Grid>
            <ShowErrors errors={errors} />
            <Grid container mt={1}>
                <Grid item xs={18} textAlign="right">
                    <Button
                        disabled={isSaving}
                        onClick={handleSubmit(handleClickSave)}
                        sx={{
                            width: { xs: '100%', md: 'auto' },
                        }}
                        type="button"
                        variant="outlined"
                    >
                        {t('Save')}
                    </Button>
                </Grid>
            </Grid>
        </form>
    );
};

const mapStateToProps = createStructuredSelector({
    initialValues: selectors.getInitialValues,
    isSaving: selectors.getIsSavingNotificationSettings,
});

const mapDispatchToProps = (dispatch) => ({
    handleClickSave: (values) => dispatch(actions.save(values)),
});

SettingsForm.propTypes = {
    errors: PropTypes.object,
    handleClickSave: PropTypes.func,
    initialValues: PropTypes.object,
    isSaving: PropTypes.bool,
};

export default connect(mapStateToProps, mapDispatchToProps)(SettingsForm);
