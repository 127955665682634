import React from 'react';

import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';
import Chart from 'react-apexcharts';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Box, Card, Grid } from 'sunwise-ui';

import KeyIndicator from 'common/components/KeyIndicator';
import PlaceholderTemplateChart from 'common/components/placeholder/PlaceholderTemplateChart';
import { numberFormat } from 'common/utils/helpers';

import { getProjectsChartSettings } from '../helpers';
import * as selectors from '../selectors';

const ProjectsTotals = ({
    currencyLocale,
    projectCounters,
    readyPlaceholder,
}) => {
    const { t } = useTranslation();
    const theme = useTheme();

    const chartConfig = getProjectsChartSettings(
        projectCounters.categories,
        currencyLocale,
        projectCounters.series
    );

    return (
        <Card.Body>
            <Grid container>
                <Grid item xs={18} lg={13} xl={14}>
                    <PlaceholderTemplateChart ready={readyPlaceholder}>
                        <Chart
                            height="250"
                            options={{
                                ...chartConfig.options,
                                theme: { mode: theme.palette.mode },
                            }}
                            series={chartConfig.series}
                            type="bar"
                        />
                    </PlaceholderTemplateChart>
                </Grid>

                <Grid item xs={18} lg={5} xl={4}>
                    <Box
                        display="flex"
                        flexDirection="column"
                        gap={2}
                        sx={{ width: '100%' }}
                    >
                        <KeyIndicator
                            isLoading={readyPlaceholder}
                            title={t('Total projects')}
                            value={numberFormat(
                                projectCounters.projects_totals,
                                {
                                    decimals: 0,
                                    locale: currencyLocale,
                                    style: 'decimal',
                                }
                            )}
                            widthIcon="20px"
                        />
                    </Box>
                </Grid>
            </Grid>
        </Card.Body>
    );
};

const mapStateToProps = createStructuredSelector({
    projectCounters: selectors.getProjectsCountersData,
});

ProjectsTotals.propTypes = {
    currencyLocale: PropTypes.string,
    projectCounters: PropTypes.object,
    readyPlaceholder: PropTypes.bool,
};

const FormContainer = ProjectsTotals;

export default connect(mapStateToProps, null)(FormContainer);
