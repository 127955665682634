import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box, FormControl, InputLabel, MenuItem, Select } from 'sunwise-ui';

import { DAYS_NAMES, MONTHS } from 'common/constants';
import { getDayLabel, getMonthLabel } from 'common/utils/helpers';

import { POLYGON_SEGMENT } from '../../constants';

const ChartControls = ({
    controlValues,
    handleOnControlsChange,
    segments,
    weeksToSelect = 4,
}) => {
    const { t } = useTranslation();

    const { day, month, period, segment, week } = controlValues;

    if (!Array.isArray(segments)) return null;

    const segmentsToSelect = segments.filter(
        (item) => item.type === POLYGON_SEGMENT
    );

    return (
        <Box display="flex" flexWrap="wrap" gap="16px">
            <FormControl fullWidth size="small">
                <InputLabel>{t('Segment', { count: 2 })}</InputLabel>
                <Select
                    label={t('Segment', { count: 2 })}
                    onChange={(e) =>
                        handleOnControlsChange(e.target.value, 'segment')
                    }
                    value={segment}
                >
                    <MenuItem value={'all'}>
                        {t('All text', { count: 2 })}
                    </MenuItem>
                    {segmentsToSelect.map((segment) => (
                        <MenuItem
                            key={`menu-item-${segment.id}`}
                            value={segment.id}
                        >
                            {segment.name}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>

            <FormControl fullWidth size="small">
                <InputLabel>{t('Period')}</InputLabel>
                <Select
                    label={t('Period')}
                    onChange={(e) =>
                        handleOnControlsChange(e.target.value, 'period')
                    }
                    value={period}
                >
                    <MenuItem value={0}>{t('Yearly')}</MenuItem>
                    <MenuItem value={1}>{t('Monthly')}</MenuItem>
                    <MenuItem value={2}>{t('Weekly')}</MenuItem>
                    <MenuItem value={3}>{t('Daily')}</MenuItem>
                </Select>
            </FormControl>

            {[1, 2, 3].includes(period) && (
                <FormControl fullWidth size="small">
                    <InputLabel>{t('Month')}</InputLabel>
                    <Select
                        label={t('Month')}
                        onChange={(e) => {
                            handleOnControlsChange(e.target.value, 'month');
                        }}
                        value={month}
                    >
                        {MONTHS.map((month, index) => (
                            <MenuItem key={`month-${index}`} value={index}>
                                {getMonthLabel(month)}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            )}

            {[2, 3].includes(period) && (
                <FormControl fullWidth size="small">
                    <InputLabel>{t('Week')}</InputLabel>
                    <Select
                        label={t('Week')}
                        onChange={(e) => {
                            handleOnControlsChange(e.target.value, 'week');
                        }}
                        value={week}
                    >
                        {[...Array(weeksToSelect).keys()].map((_, index) => (
                            <MenuItem key={`week-${index}`} value={index}>
                                {`${t('Week')} ${index + 1}`}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            )}

            {period === 3 && (
                <FormControl fullWidth size="small">
                    <InputLabel>{t('Day')}</InputLabel>
                    <Select
                        label={t('Day')}
                        onChange={(e) => {
                            handleOnControlsChange(e.target.value, 'day');
                        }}
                        value={day}
                    >
                        {DAYS_NAMES.map((day, index) => (
                            <MenuItem key={`week-${index}`} value={index}>
                                {getDayLabel(day)}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            )}
        </Box>
    );
};

ChartControls.propTypes = {
    controlValues: PropTypes.object,
    handleOnControlsChange: PropTypes.func,
    segments: PropTypes.array,
    weeksToSelect: PropTypes.number,
};

export default ChartControls;
