import { createSelector } from 'reselect';

import { NAME } from './constants';

export const getModel = (state) => state[NAME];

export const getInitialValuesForm = createSelector(
    getModel,
    (model) => model.initialValuesForm
);

/* fetch integration */
export const getIntegrationModel = createSelector(
    getModel,
    (model) => model.integration
);

export const getIntegrationData = createSelector(
    getIntegrationModel,
    (model) => model.data
);

export const getIntegrationErrors = createSelector(
    getIntegrationModel,
    (model) => model.errors
);

export const getIntegrationIsFetching = createSelector(
    getIntegrationModel,
    (model) => model.isFetching
);

/* fetch integrations */
export const getIntegrationsModel = createSelector(
    getModel,
    (model) => model.integrations
);

export const getIntegrationsData = createSelector(
    getIntegrationsModel,
    (model) => model.data.data
);

export const getIntegrationsErrors = createSelector(
    getIntegrationsModel,
    (model) => model.errors
);

export const getIntegrationsIsFetching = createSelector(
    getIntegrationsModel,
    (model) => model.isFetching
);

export const getSaveIntegrationModel = createSelector(
    getModel,
    (model) => model.save
);

export const getIsSavingIntegration = createSelector(
    getSaveIntegrationModel,
    (save) => save.isFetching
);

// Chargebee
export const getChargebeeIntegrationModel = createSelector(
    getModel,
    (model) => model.chargebeeIntegration
);

export const getChargebeeIntegrationData = createSelector(
    getChargebeeIntegrationModel,
    (model) => model.data
);
