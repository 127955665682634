import React, { useCallback } from 'react';

import AttachFileIcon from '@mui/icons-material/AttachFile';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import { Box, Button } from 'sunwise-ui';

const WrapperField = styled(Box)`
    align-items: center;
    cursor: pointer;
    display: flex;
    font-size: 12px;
    justify-content: center;
    letter-spacing: -0.29px;
    line-height: 19px;
    outline: none;
    padding: 8px;

    ${({ variant }) =>
        variant === 'firstLoad' &&
        `
    background: #002438;
    border-radius: 3px;
    border: 3px dashed #a3a6b4;
    color: #ffffff;
    padding: 40px;
`}
`;

const UploadFile = ({ file, onFileChange }) => {
    const { t } = useTranslation();

    const onDrop = useCallback(
        (acceptedFiles) => {
            if (onFileChange) onFileChange(acceptedFiles[0]);
        },
        [onFileChange]
    );

    const { getRootProps, getInputProps } = useDropzone({ onDrop });

    return (
        <WrapperField
            {...getRootProps()}
            variant={!file ? 'firstLoad' : 'reload'}
        >
            <input {...getInputProps()} accept="text/csv" />

            {!file ? (
                <Box alignItems="center" display="flex" gap={2}>
                    <AttachFileIcon />
                    {t('Drag & drop some file here, or click to select file')}
                </Box>
            ) : (
                <Box
                    alignItems="center"
                    display="flex"
                    flexDirection="column"
                    gap={2}
                >
                    <CheckCircleIcon />

                    <Button>{t('Change file')}</Button>
                </Box>
            )}
        </WrapperField>
    );
};

UploadFile.propTypes = {
    file: PropTypes.object,
    onFileChange: PropTypes.func,
};

export default UploadFile;
