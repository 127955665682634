import i18next from 'i18next';

import alerts from 'common/modules/alerts';

import * as proposalGeneratorPanelsLayoutSelectors from '../../proposalGeneratorPanelsLayout/selectors';

import deleteItem from './delete';

export default (id, proposalId, successCallback) => (dispatch, getState) => {
    const state = getState();
    const haveParentFieldSegment =
        proposalGeneratorPanelsLayoutSelectors.getHaveParentFieldSegment(state);
    if (haveParentFieldSegment) {
        const dataAlert = {
            confirmText: i18next.t('Accept'),
            messages: [
                i18next.t(
                    'If you modify or delete the PV module you will need to redo the PV layout'
                ),
            ],
            onSuccess: () =>
                dispatch(deleteItem(id, proposalId, successCallback)),
            title: i18next.t('Confirmation'),
            type: alerts.ALERT_TYPE_CONFIRM,
            variant: 'warning',
        };
        dispatch(alerts.actions.show(dataAlert));
    } else {
        dispatch(deleteItem(id, proposalId, successCallback));
    }
};
