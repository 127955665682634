import React from 'react';

import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import { useFieldArray } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
    Button,
    FormHelperText,
    Grid,
    IconButton,
    Tooltip,
    Typography,
} from 'sunwise-ui';

import {
    ReactHookFormInput,
    ReactHookFormSelect,
} from 'common/components/form/bootstrap';

import { getMemberRolesToSelect } from '../helpers';

const ReduxFieldArrayMembers = ({
    control,
    formValues,
    getValues,
    isDisabledAddMemberButton,
    isValidatingEmail,
    memberRoles,
    name,
    setValue,
    validateEmail,
}) => {
    const { t } = useTranslation();
    const { append, fields, remove } = useFieldArray({ control, name });

    const handleClickAdd = () => {
        if (isDisabledAddMemberButton) return false;
        append({
            company_group: '',
            email: '',
            errors: '',
            id: Math.random(),
            isOwner: false,
            last_name: '',
            name: '',
            roleName: null,
        });
    };

    const handleValidateEmail = (i, memberId, value) =>
        validateEmail(
            { email: value, id: memberId, members: getValues('members') },
            (errors) => setValue(`${name}.${i}.errors`, errors)
        );

    return (
        <>
            <Grid alignItems="center" container mb={2}>
                <Grid item md={1} />
                <Grid item md={4}>
                    <Typography fontWeight="bold" variant="body2">
                        {t('Name')}
                    </Typography>
                </Grid>
                <Grid item md={4}>
                    <Typography fontWeight="bold" variant="body2">
                        {t('Last name')}
                    </Typography>
                </Grid>
                <Grid item md={4}>
                    <Typography fontWeight="bold" variant="body2">
                        {t('Email')}
                    </Typography>
                </Grid>
                <Grid item md={4}>
                    <Typography fontWeight="bold" variant="body2">
                        {t('Role')}
                    </Typography>
                </Grid>
            </Grid>
            {fields.map((item, i) => {
                const member = formValues?.members?.[i] || {};
                const memberId = member.id;
                const hasEmailError = !isEmpty(member.errors);
                return (
                    <Grid alignItems="start" container key={item.id}>
                        <Grid item md={1} textAlign="center">
                            <Typography variant="body2">{i + 1}</Typography>
                        </Grid>
                        <Grid item xs={18} md={4}>
                            <ReactHookFormInput
                                control={control}
                                disabled={i == 0}
                                label={t('Name')}
                                name={`${name}.${i}.first_name`}
                                type="text"
                            />
                        </Grid>
                        <Grid item xs={18} md={4}>
                            <ReactHookFormInput
                                control={control}
                                disabled={i == 0}
                                label={t('Last name')}
                                name={`${name}.${i}.last_name`}
                                type="text"
                            />
                        </Grid>
                        <Grid item xs={18} md={4}>
                            <ReactHookFormInput
                                control={control}
                                disabled={i == 0}
                                label={t('Email')}
                                name={`${name}.${i}.email`}
                                onBlur={(e) =>
                                    handleValidateEmail(
                                        i,
                                        memberId,
                                        e?.target?.value
                                    )
                                }
                                sx={
                                    hasEmailError
                                        ? {
                                              '&.MuiFormControl-root': {
                                                  mb: 0,
                                              },
                                          }
                                        : {}
                                }
                                type="text"
                            />
                            {hasEmailError && (
                                <FormHelperText error>
                                    {member.errors
                                        .join(' ')
                                        .replace('email: ', '')}
                                </FormHelperText>
                            )}
                        </Grid>
                        <Grid item xs={18} md={4}>
                            {member.isOwner ? (
                                <ReactHookFormInput
                                    control={control}
                                    disabled={i == 0}
                                    label={t('Role')}
                                    name={`${name}.${i}.roleName`}
                                    type="text"
                                />
                            ) : (
                                <ReactHookFormSelect
                                    control={control}
                                    disabled={i == 0}
                                    label={t('Role')}
                                    name={`${name}.${i}.company_group`}
                                    options={getMemberRolesToSelect(
                                        memberRoles
                                    )}
                                />
                            )}
                        </Grid>
                        <Grid item xs={3} md={1}>
                            {i > 0 && (
                                <Tooltip title={t('Delete')}>
                                    <IconButton onClick={() => remove(i)}>
                                        <DeleteIcon />
                                    </IconButton>
                                </Tooltip>
                            )}
                        </Grid>
                    </Grid>
                );
            })}
            <Grid container>
                <Grid item xs textAlign="right">
                    <Button
                        color="secondary"
                        disabled={
                            isDisabledAddMemberButton || isValidatingEmail
                        }
                        endIcon={<AddIcon />}
                        onClick={() => handleClickAdd()}
                        sx={{ width: { xs: '100%', md: 'auto' } }}
                        variant="outlined"
                    >
                        {t('Add')}
                    </Button>
                </Grid>
            </Grid>
        </>
    );
};

ReduxFieldArrayMembers.propTypes = {
    control: PropTypes.object,
    formValues: PropTypes.object,
    getValues: PropTypes.func,
    isDisabledAddMemberButton: PropTypes.bool,
    isValidatingEmail: PropTypes.bool,
    memberRoles: PropTypes.array,
    name: PropTypes.string,
    setValue: PropTypes.func,
    validateEmail: PropTypes.func,
};

export default ReduxFieldArrayMembers;
