import React from 'react';

import { styled } from '@mui/material/styles';
import { push } from 'connected-react-router';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Box } from 'sunwise-ui';

import EmptyState from 'common/components/EmptyState';
import PlaceholderCardOffer from 'common/components/placeholder/PlaceholderCardOffer';
import { scroll } from 'common/utils/mixins';

import EmptyStateArchived from 'resources/EmptyStateArchived.png';
import EmptySummaries from 'resources/EmptySummaries.png';

import { getRequirements } from '../helpers';

import SummaryItem from './SummaryItem';

const SummaryContainer = styled(Box)`
    display: flex;
    flex-direction: column;
    gap: 8px;
    max-height: 480px;
    overflow-x: hidden;
    overflow-y: scroll;
    widht: 100%;

    ${scroll.custom()}
`;

const SummaryTable = ({
    canDelete,
    canModify,
    commercialOffers,
    handleClickDownloadCSV,
    isArchivedFilter,
    isDisabledCreateButton,
    isLoading,
    prepareSummaryCreate,
    redirect,
    summaries,
    templates,
}) => {
    const { t } = useTranslation();

    if (!isLoading && summaries.length <= 0) {
        if (isArchivedFilter) {
            return (
                <EmptyState
                    srcImage={EmptyStateArchived}
                    sxCard={{ border: 0, boxShadow: 'none' }}
                    title={t('This project has no archived summaries')}
                />
            );
        } else {
            return (
                <EmptyState
                    canModify={canModify}
                    caption={t(
                        'Compare two or three proposals for the same project'
                    )}
                    disabled={isDisabledCreateButton}
                    isLoading={isLoading}
                    onClick={prepareSummaryCreate}
                    requirements={getRequirements(
                        isLoading,
                        commercialOffers,
                        templates
                    )}
                    srcImage={EmptySummaries}
                    sxCard={{ border: 0, boxShadow: 'none' }}
                    textButton={t('Create')}
                    title={t('This project has no summaries')}
                />
            );
        }
    }

    return (
        <PlaceholderCardOffer ready={isLoading}>
            <SummaryContainer>
                {summaries.map((item, index) => (
                    <SummaryItem
                        canDelete={canDelete}
                        canModify={canModify}
                        data={item}
                        handleClickDownloadCSV={handleClickDownloadCSV}
                        isArchivedFilter={isArchivedFilter}
                        key={`sumary-item-${index}`}
                        redirect={redirect}
                    />
                ))}
            </SummaryContainer>
        </PlaceholderCardOffer>
    );
};

const mapDispatchToProps = (dispatch) => ({
    redirect: (link) => dispatch(push(link)),
});

SummaryTable.propTypes = {
    canDelete: PropTypes.bool,
    canModify: PropTypes.bool,
    commercialOffers: PropTypes.array,
    handleClickDownloadCSV: PropTypes.func,
    isArchivedFilter: PropTypes.bool,
    isDisabledCreateButton: PropTypes.bool,
    isLoading: PropTypes.bool,
    prepareSummaryCreate: PropTypes.func,
    redirect: PropTypes.func,
    summaries: PropTypes.array,
    templates: PropTypes.array,
};

export default connect(null, mapDispatchToProps)(SummaryTable);
