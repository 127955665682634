import { createItem, updateItem } from 'common/api/v1/companiesCatalogs';
import { showReponseErrorsAsAlert } from 'common/utils/helpers';
import showToast from 'common/utils/showToast';

import {
    SAVE_ITEM,
    SAVE_ITEM_FAILURE,
    SAVE_ITEM_SUCCESS,
} from '../actionTypes';
import { listSettingsActions } from '../reducer';

import fetchCatalogs from './fetchCatalogs';
import resetForm from './resetForm';
import toggleModal from './toggleModal';

export default (values, type) => (dispatch) => {
    const newValues = {
        name: values.name,
        primary_color: values.primary_color,
        type: type,
    };

    dispatch(listSettingsActions[SAVE_ITEM]());

    const save = values.id
        ? () => updateItem(newValues, values.id)
        : () => createItem(newValues);

    save()
        .then((response) => {
            dispatch(listSettingsActions[SAVE_ITEM_SUCCESS](response.data));

            dispatch(fetchCatalogs(type));
            dispatch(toggleModal(false));
            dispatch(resetForm());

            showToast();
        })
        .catch((error) => {
            dispatch(
                listSettingsActions[SAVE_ITEM_FAILURE](
                    error?.response?.data?.errors
                )
            );
            showReponseErrorsAsAlert(dispatch, error?.response);
        });
};
