export const NAME = 'proposal-generator-advanced-generation-settings';

export const CSV_SOURCES = {
    HELIOSCOPE: 1,
    AURORA: 2,
    PVWATTS: 3,
    PVGIS: 4,
    CUSTOM: 5,
};

export const SOURCE_OPTIONS = [
    { label: 'HELIOSCOPE', value: CSV_SOURCES.HELIOSCOPE },
    { label: 'AURORA', value: CSV_SOURCES.AURORA },
    { label: 'PVWATTS', value: CSV_SOURCES.PVWATTS },
    { label: 'PVGIS', value: CSV_SOURCES.PVGIS },
    { label: 'CUSTOM', value: CSV_SOURCES.CUSTOM },
];
