import React from 'react';

import { styled } from '@mui/material/styles';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroller';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Box, Typography } from 'sunwise-ui';

import { ListItem } from 'common/components/List';
import { scroll } from 'common/utils/mixins';

import * as actions from '../actions';
import { buildQueryParams } from '../helpers';
import * as selectors from '../selectors';

const RateListContainer = styled(Box)`
    display: flex;
    flex-direction: column;
    height: 400px;
    overflow-y: auto;
    padding: 8px;
    width: 100%;

    ${scroll.custom()}

    @media (min-width: 900px) and (max-width: 1199px) {
        height: calc(100vh - 430px);
    }

    @media (min-width: 1200px) {
        height: calc(100vh - 460px);
    }
`;

const RateListContent = ({
    businessTypeSelected,
    canDelete,
    fetchRate,
    filterName,
    filterNewPageRates,
    hasNextPage,
    isEnabled,
    rateTypeSelected,
    rates,
    selectedItemId,
    settingEnabledRateId,
    voltageTypeSelected,
}) => {
    const { t } = useTranslation();

    if (isEmpty(rates))
        return (
            <Box pt={2}>
                <Typography fontWeight="bold" variant="body2">
                    {t('You have no configured rates')}
                </Typography>
            </Box>
        );

    return (
        <InfiniteScroll
            pageStart={1}
            loadMore={(nextPage) =>
                filterNewPageRates(
                    buildQueryParams({
                        businessTypeSelected,
                        filterName,
                        isEnabled,
                        page: nextPage,
                        rateTypeSelected,
                        voltageTypeSelected,
                    })
                )
            }
            hasMore={hasNextPage}
            loader={
                <div className="scroll-loader" key={0}>
                    {t('Loading').concat('...')}
                </div>
            }
            threshold={100}
        >
            <RateListContainer>
                {rates.map(
                    (
                        {
                            businessType,
                            id,
                            isCertified,
                            name,
                            shortName,
                            voltageType,
                        },
                        index
                    ) => (
                        <ListItem
                            businessType={businessType}
                            canDelete={canDelete}
                            disabled={settingEnabledRateId === id}
                            handleOnClick={() => fetchRate(id)}
                            hideSwitch
                            isCertified={isCertified}
                            isSelected={id === selectedItemId}
                            key={`rate-card-${id}-${index}`}
                            name={name}
                            refKey={`rate-card-${id}-${index}`}
                            shortName={shortName}
                            voltageType={voltageType}
                        />
                    )
                )}
            </RateListContainer>
        </InfiniteScroll>
    );
};

const mapStateToProps = createStructuredSelector({
    hasNextPage: selectors.getHasNextPage,
    rates: selectors.getRatesData,
    selectedItemId: selectors.getSelectedItemId,
    settingEnabledRateId: selectors.getSettingEnabledRateId,
});

const mapDispatchToProps = (dispatch) => ({
    fetchRate: (rateId) => dispatch(actions.fetchRate(rateId)),
    filterNewPageRates: (params) =>
        dispatch(actions.filterNewPageRates(params)),
});

RateListContent.propTypes = {
    businessTypeSelected: PropTypes.string,
    canDelete: PropTypes.bool,
    fetchRate: PropTypes.func,
    filterName: PropTypes.string,
    filterNewPageRates: PropTypes.func,
    hasNextPage: PropTypes.bool,
    isEnabled: PropTypes.bool,
    rateTypeSelected: PropTypes.string,
    rates: PropTypes.array,
    selectedItemId: PropTypes.string,
    settingEnabledRateId: PropTypes.string,
    voltageTypeSelected: PropTypes.string,
};

export default connect(mapStateToProps, mapDispatchToProps)(RateListContent);
