import API from 'common/api';

import energyBackupSetup from '../../energyBackupSetup';
import proposalGeneratorAccesoryTable from '../../proposalGeneratorAccessoryTable';
import proposalGeneratorAdditionalTable from '../../proposalGeneratorAdditionalTable';
import proposalGeneratorAdvancedGenerationSettings from '../../proposalGeneratorAdvancedGenerationSettings';
import proposalGeneratorConfigurationModal from '../../proposalGeneratorConfigurationModal';
import proposalGeneratorConsumptionAnalysisVsGeneration from '../../proposalGeneratorConsumptionAnalysisVsGeneration';
import proposalGeneratorEnergyBackup from '../../proposalGeneratorEnergyBackup';
import proposalGeneratorEnergySaving from '../../proposalGeneratorEnergySaving';
import proposalGeneratorFinancingAndInsurance from '../../proposalGeneratorFinancingAndInsurance';
import proposalGeneratorInverterTable from '../../proposalGeneratorInverterTable';
import proposalGeneratorPanelTable from '../../proposalGeneratorPanelTable';
import proposalGeneratorQuote from '../../proposalGeneratorQuote';
import proposalGeneratorStructureTable from '../../proposalGeneratorStructureTable';
import proposalGeneratorTaxesTable from '../../proposalGeneratorTaxesTable';
import proposalGeneratorTemplate from '../../proposalGeneratorTemplate';
import proposalGeneratorWorkforceTable from '../../proposalGeneratorWorkforceTable';
import { RESET_VALUES } from '../actionTypes';
import { proposalGeneratorOneColumnActions } from '../reducer';

export default () => (dispatch) => {
    API.cancelAll();
    dispatch(proposalGeneratorOneColumnActions[RESET_VALUES]());
    dispatch(
        proposalGeneratorConsumptionAnalysisVsGeneration.actions.resetForm()
    );
    dispatch(proposalGeneratorAdvancedGenerationSettings.actions.resetForm());
    dispatch(proposalGeneratorConfigurationModal.actions.resetForm());
    dispatch(proposalGeneratorFinancingAndInsurance.actions.resetForm());
    dispatch(
        proposalGeneratorFinancingAndInsurance.actions.resetAllianceForms()
    );
    dispatch(proposalGeneratorEnergySaving.actions.resetForm());
    dispatch(proposalGeneratorAccesoryTable.actions.resetForm());
    dispatch(proposalGeneratorAdditionalTable.actions.resetForm());
    dispatch(proposalGeneratorInverterTable.actions.resetForm());
    dispatch(proposalGeneratorPanelTable.actions.resetForm());
    dispatch(proposalGeneratorStructureTable.actions.resetForm());
    dispatch(proposalGeneratorWorkforceTable.actions.resetForm());
    dispatch(proposalGeneratorTaxesTable.actions.resetForm());
    dispatch(proposalGeneratorTemplate.actions.resetForm());
    dispatch(proposalGeneratorQuote.actions.resetData());
    dispatch(proposalGeneratorEnergyBackup.actions.resetData());
    dispatch(energyBackupSetup.actions.resetData());
};
