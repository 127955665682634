import { getItems } from 'common/api/v1/measurementSource';

import {
    FETCH_EXTERNAL_SOURCES,
    FETCH_EXTERNAL_SOURCES_SUCCESS,
    FETCH_EXTERNAL_SOURCES_FAILURE,
} from '../actionTypes';
import { actions } from '../reducer';

export default (uuid) => (dispatch) => {
    if (!uuid) return;
    dispatch(actions[FETCH_EXTERNAL_SOURCES]());

    getItems(uuid)
        .then((response) =>
            dispatch(
                actions[FETCH_EXTERNAL_SOURCES_SUCCESS](response?.data?.data)
            )
        )
        .catch((error) =>
            dispatch(
                actions[FETCH_EXTERNAL_SOURCES_FAILURE](
                    error?.response?.data?.errors
                )
            )
        );
};
