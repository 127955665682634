import { attr, fk, many, oneToOne } from 'redux-orm';

import { MANY_TO_MANY, ONE_TO_MANY, ONE_TO_ONE } from './constants';

const invertDictionary = (dictionary) => {
    return Object.keys(dictionary).reduce((acc, dictionaryKey) => {
        const field = dictionary[dictionaryKey];
        if (typeof field === 'object' && field !== null) {
            return { ...acc, [field.key]: dictionaryKey };
        }
        if (typeof field === 'string') {
            return { ...acc, [dictionary[dictionaryKey]]: dictionaryKey };
        }
        return acc;
    }, {});
};

export const getFieldsByDictionary = (dictionary) => {
    return Object.keys(dictionary).reduce((acc, fieldKey) => {
        const field = dictionary[fieldKey];
        if (typeof field === 'object' && field !== null) {
            switch (field.relationType) {
                case ONE_TO_MANY:
                    return {
                        ...acc,
                        [fieldKey]: fk(field.options),
                    };
                case MANY_TO_MANY:
                    return {
                        ...acc,
                        [fieldKey]: many(field.options),
                    };
                case ONE_TO_ONE:
                    return {
                        ...acc,
                        [fieldKey]: oneToOne(field.options),
                    };
                default:
                    return { ...acc, [fieldKey]: attr() };
            }
        }
        if (typeof field === 'string') {
            return { ...acc, [fieldKey]: attr() };
        }
        return acc;
    }, {});
};

export const getUnparsedDataByDictionary = (refData, dictionary) => {
    const invertedDictionary = invertDictionary(dictionary);
    return Object.keys(invertedDictionary).reduce(
        (acc, invertedDictionaryKey) => {
            return {
                ...acc,
                [invertedDictionaryKey]:
                    refData[invertedDictionary[invertedDictionaryKey]],
            };
        },
        {}
    );
};

export const modelPopulateByArray = (model, arrayData, isDeleteRequired) => {
    if (isDeleteRequired) {
        model.all().delete();
    }
    arrayData.forEach((itemData) => {
        model.generate(itemData);
    });
};

export const parseByDictionaryForCreate = (data, dictionary) => {
    return Object.keys(dictionary).reduce(
        (acc, dictionaryKey) => {
            const field = dictionary[dictionaryKey];
            if (typeof field === 'object' && field !== null) {
                return {
                    ...acc,
                    [dictionaryKey]: data[field.key],
                };
            }
            if (typeof field === 'string') {
                return {
                    ...acc,
                    [dictionaryKey]: data[field],
                };
            }
            return acc;
        },
        { _dictionary: dictionary }
    );
};

export const parseByDictionaryForUpdate = (data, dictionary) => {
    const invertedDictionary = invertDictionary(dictionary);
    return Object.keys(data).reduce((acc, dataKey) => {
        if (invertedDictionary[dataKey]) {
            return { ...acc, [invertedDictionary[dataKey]]: data[dataKey] };
        }
        return acc;
    }, {});
};
