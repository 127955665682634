import React, { useState } from 'react';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import LockIcon from '@mui/icons-material/Lock';
import isNull from 'lodash/isNull';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Button, Menu, MenuItem } from 'sunwise-ui';

import { getIsMultibranchesAccount } from 'common/utils/helpers/session';

import { ReactComponent as BranchOfficesIcon } from 'resources/branchOfficesIcon.svg';

import * as actions from '../actions';
import { getSelectedText } from '../helpers';
import * as selectors from '../selectors';

const MenuItemDropdown = ({
    branchesData,
    branchesDictionary,
    branchesItems,
    branchSelected,
    isFetching,
    multiBranchMode,
    selectBranch,
}) => {
    const { t } = useTranslation();
    const [anchorEl, setAnchorEl] = useState(null);

    if (
        !getIsMultibranchesAccount() ||
        isNull(branchSelected) ||
        isFetching ||
        branchesData.length === 0
    )
        return null;

    const open = Boolean(anchorEl);
    const handleClick = (event) => setAnchorEl(event.currentTarget);
    const handleClose = () => setAnchorEl(null);
    const disabled =
        isFetching || (multiBranchMode && branchesItems.length === 1);

    return (
        <>
            <Button
                disabled={disabled}
                endIcon={<KeyboardArrowDownIcon />}
                onClick={handleClick}
                variant="outlined"
            >
                {getSelectedText(
                    multiBranchMode,
                    branchesDictionary,
                    branchSelected
                )}
            </Button>
            <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
                {!multiBranchMode && (
                    <MenuItem disabled={false} key="dropdown-item-default">
                        <LockIcon />
                        {t(
                            'This section does not require a branch office selection'
                        )}
                    </MenuItem>
                )}
                {branchesItems.map((item, index) => (
                    <MenuItem
                        disabled={item.disabled || !multiBranchMode}
                        key={`dropdown-item-${index}`}
                        onClick={() => {
                            handleClose();
                            selectBranch(item.value);
                        }}
                    >
                        <BranchOfficesIcon />
                        &nbsp;
                        <span>
                            {item.value === 'all'
                                ? t('All text', { context: 'female', count: 2 })
                                : item.label}
                        </span>
                    </MenuItem>
                ))}
            </Menu>
        </>
    );
};

const mapStateToProps = createStructuredSelector({
    branchesData: selectors.getBranchesData,
    branchesDictionary: selectors.getBranchesDictionary,
    branchesItems: selectors.getBranchesItemsData,
    branchSelected: selectors.getBranchSelected,
    isFetching: selectors.getIsFetchingBranches,
});

const mapDispatchToProps = (dispatch) => ({
    selectBranch: (branchId) => dispatch(actions.selectBranch(branchId)),
});

MenuItemDropdown.propTypes = {
    branchesData: PropTypes.array,
    branchesDictionary: PropTypes.object,
    branchesItems: PropTypes.array,
    branchSelected: PropTypes.string,
    isFetching: PropTypes.bool,
    multiBranchMode: PropTypes.oneOfType([PropTypes.bool, PropTypes.array]),
    selectBranch: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(MenuItemDropdown);
