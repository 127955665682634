import React from 'react';

import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box, Card, Typography } from 'sunwise-ui';

import CurrencyForm from './CurrencyForm';

const CardListDetail = ({ canModify, currency }) => {
    const { t } = useTranslation();

    if (isEmpty(currency))
        return (
            <Card>
                <Box textAlign="center" py={6}>
                    <Typography variant="body2">
                        {t('Select a currency to configure')}
                    </Typography>
                </Box>
            </Card>
        );
    return (
        <Card>
            <Card.Header>
                <Typography fontWeight="bold" variant="h5">
                    {currency.name}
                </Typography>
            </Card.Header>
            <Card.Body>
                <CurrencyForm canModify={canModify} />
            </Card.Body>
        </Card>
    );
};

CardListDetail.propTypes = {
    canModify: PropTypes.bool,
    currency: PropTypes.object,
};

export default CardListDetail;
