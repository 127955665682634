import i18next from 'i18next';

import alerts from 'common/modules/alerts';

import * as energyBackupSetupSelectors from '../../energyBackupSetup/selectors';

import deleteProduct from './delete';

export default (id, proposalId) => (dispatch, getState) => {
    const state = getState();
    const energyBackupOfferValuesData =
        energyBackupSetupSelectors.getOfferValuesData(state);

    if (energyBackupOfferValuesData?.battery_offer?.[0]?.id === id) {
        const dataAlert = {
            confirmText: i18next.t('Remove'),
            messages: [
                i18next.t(
                    'Removing this storage product will also remove the configured power backup plan. Do you want continue?'
                ),
            ],
            onSuccess: () => dispatch(deleteProduct(id, proposalId)),
            title: i18next.t('Confirmation'),
            type: alerts.ALERT_TYPE_CONFIRM,
            variant: 'warning',
        };

        dispatch(alerts.actions.show(dataAlert));

        return;
    }

    dispatch(deleteProduct(id, proposalId));
};
