import React from 'react';

import ArchiveOutlinedIcon from '@mui/icons-material/ArchiveOutlined';
import EditIcon from '@mui/icons-material/Edit';
import UnarchiveOutlinedIcon from '@mui/icons-material/UnarchiveOutlined';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box, Grid, Skeleton, Typography } from 'sunwise-ui';

import EmptyState from 'common/components/EmptyState';

import EmptyProjects from 'resources/EmptyProjects.png';

import ProjectCardItem from './ProjectCardItem';
import ProjectListItem from './ProjectListItem';
import ProjectResponsiveItem from './ProjectResponsiveItem';

const getProjectData = (
    callback,
    canDelete,
    canModify,
    filterData,
    handleClickArchive,
    handleClickRename,
    handleOpenProject,
    isExpanded,
    project,
    selectedProjectId,
    t
) => {
    const isArchived = project?.status_flag === 'archived';
    return {
        canDelete,
        canModify,
        createdBy: project.created_by
            ? `${project.created_by.first_name} ${project.created_by.last_name}`
            : 'N/D',
        documents: project.proposals_numbers,
        date: project.created_at,
        id: project.id,
        isSelected: isExpanded && selectedProjectId === project.id,
        handleClickView: () => handleOpenProject(project.id),
        optionsMenu: [
            {
                handleClick: () => handleClickRename(filterData, project),
                icon: <EditIcon fontSize="small" />,
                label: t('Rename'),
                visible: canModify,
            },
            {
                handleClick: () =>
                    handleClickArchive(project, 'archived', callback),
                icon: <ArchiveOutlinedIcon fontSize="small" />,
                label: t('Archive'),
                visible: canDelete && !isArchived,
            },
            {
                handleClick: () =>
                    handleClickArchive(project, 'active', callback),
                icon: <UnarchiveOutlinedIcon fontSize="small" />,
                label: t('Unarchive'),
                visible: canDelete && isArchived,
            },
        ],
        rate: get(project, 'rate.name', ''),
        statusProject: project.status_project,
        title: project.name,
    };
};

const ProjectsList = ({
    callback,
    canDelete,
    canModify,
    filterData,
    handleClickArchive,
    handleClickRename,
    handleOpenProject,
    isExpanded,
    isLoading,
    listModeEnabled,
    prepareFormCreate,
    projects,
    projectsIds,
    renderExtraButtom,
    selectedProject,
    selectedProjectId,
}) => {
    const { t } = useTranslation();

    if (isLoading)
        return (
            <Box my={2}>
                <Skeleton
                    variant="rounded"
                    sx={{ height: { xs: 30, md: 50 }, width: '100%' }}
                />
            </Box>
        );

    if (projects.length <= 0) {
        if (filterData?.status === 'archived' || filterData?.searchText)
            return (
                <Box my={2}>
                    <Typography variant="body2">
                        {t('No projects were found')}
                    </Typography>
                </Box>
            );
        return (
            <Box sx={{ height: '500px' }}>
                <EmptyState
                    canModify={canModify}
                    caption={t(
                        'Create a new project and store all related proposals in it'
                    )}
                    classNameCardBody="__intercom_add_project_button"
                    dataIntercomTarget="Add Project (Button)"
                    onClick={prepareFormCreate}
                    srcImage={EmptyProjects}
                    sxCard={{ border: 0, boxShadow: 'none' }}
                    textButton={t('Create')}
                    title={t('There are no projects yet')}
                />
            </Box>
        );
    }

    if (
        !isExpanded &&
        selectedProjectId &&
        projectsIds.includes(selectedProjectId)
    ) {
        const projectData = getProjectData(
            callback,
            canDelete,
            canModify,
            filterData,
            handleClickArchive,
            handleClickRename,
            handleOpenProject,
            isExpanded,
            selectedProject,
            selectedProjectId,
            t
        );
        return (
            <>
                <Grid container>
                    <Grid item xs={18}>
                        <ProjectListItem {...projectData} />
                        <ProjectResponsiveItem {...projectData} />
                    </Grid>
                </Grid>
                {renderExtraButtom()}
            </>
        );
    }

    return (
        <>
            <Grid container>
                {projects.map((project) => {
                    const projectData = getProjectData(
                        callback,
                        canDelete,
                        canModify,
                        filterData,
                        handleClickArchive,
                        handleClickRename,
                        handleOpenProject,
                        isExpanded,
                        project,
                        selectedProjectId,
                        t
                    );

                    return (
                        <Grid
                            item
                            key={project.id}
                            lg={listModeEnabled ? 18 : 6}
                            md={listModeEnabled ? 18 : 9}
                            xl={listModeEnabled ? 18 : 4.5}
                            xs={18}
                        >
                            <ProjectResponsiveItem
                                canModify={canModify}
                                {...projectData}
                            />

                            {listModeEnabled ? (
                                <ProjectListItem {...projectData} />
                            ) : (
                                <ProjectCardItem {...projectData} />
                            )}
                        </Grid>
                    );
                })}
            </Grid>
            {renderExtraButtom()}
        </>
    );
};

ProjectsList.propTypes = {
    callback: PropTypes.func,
    canDelete: PropTypes.bool,
    canModify: PropTypes.bool,
    filterData: PropTypes.object,
    filteredProjects: PropTypes.array,
    handleClickArchive: PropTypes.func,
    handleClickRename: PropTypes.func,
    handleOpenProject: PropTypes.func,
    isExpanded: PropTypes.bool,
    isLoading: PropTypes.bool,
    listModeEnabled: PropTypes.bool,
    prepareFormCreate: PropTypes.func,
    projects: PropTypes.array,
    projectsIds: PropTypes.array,
    renderExtraButtom: PropTypes.func,
    selectedProject: PropTypes.object,
    selectedProjectId: PropTypes.string,
};

export default ProjectsList;
