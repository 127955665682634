import React, { useEffect } from 'react';

import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Button, Divider, Grid } from 'sunwise-ui';

import { ShowErrors } from 'common/components';
import {
    ReactHookFormArrayRanges,
    ReactHookFormInput,
    ReactHookFormSelect,
} from 'common/components/form/bootstrap';
import { FormWrapper } from 'common/components/productForm';
import { COMPANY_PRODUCTS } from 'common/constants/index';
import BranchOfficeSelectTypeRHF from 'common/modules/multiBranches/components/BranchOfficeSelectTypeRHF';
import productDatasheets from 'common/modules/productDatasheets';
import { getCurrenciesToSelect } from 'common/utils/helpers';
import yupResolver from 'common/utils/yupResolver';

import * as actions from '../actions';
import * as selectors from '../selectors';
import validate from '../validate';

const ProductForm = ({
    branchSelected,
    canDelete,
    canModify,
    currencies,
    errors,
    filterData,
    handleClickBulkItems,
    handleClickSave,
    handleCloseModalProduct,
    initializeForm,
    initialValues,
    isNewRecord,
    isSaving,
}) => {
    const { t } = useTranslation();
    const { control, getValues, handleSubmit, reset, setValue } = useForm({
        defaultValues: initialValues,
        resolver: yupResolver(validate),
    });

    useEffect(() => reset(initialValues), [initialValues]);

    const handleOnSave = (values) => handleClickSave(filterData, values);

    const isArchived = getValues('status_flag') === 'archived';

    return (
        <>
            <productDatasheets.Container
                productValues={{
                    ...initialValues,
                    productType: COMPANY_PRODUCTS.ADDITIONALS,
                }}
            />
            <form>
                <FormWrapper>
                    <ReactHookFormInput
                        control={control}
                        disabled={!canModify}
                        label={t('Brand')}
                        name="brand_name"
                        placeholder={t('Brand')}
                    />

                    <ReactHookFormInput
                        control={control}
                        disabled={!canModify}
                        label={t('Name').concat(' *')}
                        name="name"
                        placeholder={t('Name')}
                    />

                    <ReactHookFormInput
                        control={control}
                        disabled={!canModify}
                        label={t('Description')}
                        name="description"
                        placeholder={t('Description')}
                    />

                    <ReactHookFormInput
                        control={control}
                        disabled={!canModify}
                        label={t('Product code')}
                        name="code"
                        placeholder={t('Product code')}
                    />

                    <BranchOfficeSelectTypeRHF
                        control={control}
                        disabled={!isNewRecord}
                        label={t('Branch office')}
                        multiMode={branchSelected === 'all' && isNewRecord}
                        name="branch_offices"
                    />

                    <ReactHookFormSelect
                        control={control}
                        disabled={!canModify}
                        label={t('Currency')}
                        name="currency"
                        options={getCurrenciesToSelect(currencies, false)}
                    />

                    <Divider />

                    <ReactHookFormArrayRanges
                        control={control}
                        currencies={currencies}
                        getValues={getValues}
                        isDisabled={!canModify}
                        name="range_prices"
                        setValue={setValue}
                    />
                </FormWrapper>

                <ShowErrors errors={errors} />

                <Grid container mt={1}>
                    <Grid
                        item
                        xs
                        sx={{
                            display: 'flex',
                            flexDirection: { xs: 'column', md: 'row' },
                            justifyContent: { xs: 'center', md: 'right' },
                        }}
                    >
                        {!isNewRecord && (
                            <>
                                <Button
                                    color="secondary"
                                    onClick={() =>
                                        handleClickBulkItems(
                                            [getValues('id')],
                                            'archived',
                                            filterData,
                                            () =>
                                                initializeForm({
                                                    ...getValues(),
                                                    status_flag: 'archived',
                                                })
                                        )
                                    }
                                    sx={{
                                        width: { xs: '100%', md: 'auto' },
                                        order: { xs: 2, md: 1 },
                                        mr: { xs: 0, md: 2 },
                                    }}
                                    type="button"
                                    variant="text"
                                    visible={canDelete && !isArchived}
                                >
                                    {t('Archive')}
                                </Button>
                                <Button
                                    color="secondary"
                                    onClick={() =>
                                        handleClickBulkItems(
                                            [getValues('id')],
                                            'active',
                                            filterData,
                                            () =>
                                                initializeForm({
                                                    ...getValues(),
                                                    status_flag: 'active',
                                                })
                                        )
                                    }
                                    sx={{
                                        width: { xs: '100%', md: 'auto' },
                                        order: { xs: 2, md: 1 },
                                        mr: { xs: 0, md: 2 },
                                    }}
                                    type="button"
                                    variant="text"
                                    visible={canDelete && isArchived}
                                >
                                    {t('Restore')}
                                </Button>
                                <Button
                                    color="error"
                                    onClick={() =>
                                        handleClickBulkItems(
                                            [getValues('id')],
                                            'deleted',
                                            filterData,
                                            () => handleCloseModalProduct()
                                        )
                                    }
                                    sx={{
                                        width: { xs: '100%', md: 'auto' },
                                        order: { xs: 2, md: 1 },
                                        mr: { xs: 0, md: 2 },
                                    }}
                                    type="button"
                                    variant="text"
                                    visible={canDelete && isArchived}
                                >
                                    {t('Delete')}
                                </Button>
                            </>
                        )}
                        <Button
                            disabled={isSaving}
                            onClick={handleSubmit(handleOnSave)}
                            sx={{
                                width: { xs: '100%', md: 'auto' },
                                order: { xs: 1, md: 2 },
                                mb: { xs: 2, md: 0 },
                            }}
                            type="button"
                            variant="outlined"
                            visible={canModify && !isArchived}
                        >
                            {t('Save')}
                        </Button>
                    </Grid>
                </Grid>
            </form>
        </>
    );
};

const mapStateToProps = createStructuredSelector({
    errors: selectors.getSaveItemErrors,
    initialValues: selectors.getInitialValues,
    isNewRecord: selectors.getIsNewRecord,
    isSaving: selectors.getIsSavingItem,
});

const mapDispatchToProps = (dispatch) => ({
    handleClickSave: (filterData, values) =>
        dispatch(actions.save(filterData, values)),
});

ProductForm.propTypes = {
    branchSelected: PropTypes.string,
    canDelete: PropTypes.bool,
    canModify: PropTypes.bool,
    currencies: PropTypes.array,
    errors: PropTypes.array,
    filterData: PropTypes.object,
    handleClickBulkItems: PropTypes.func,
    handleClickSave: PropTypes.func,
    handleCloseModalProduct: PropTypes.func,
    initializeForm: PropTypes.func,
    initialValues: PropTypes.object,
    isNewRecord: PropTypes.bool,
    isSaving: PropTypes.bool,
};

const FormContainer = ProductForm;

export default connect(mapStateToProps, mapDispatchToProps)(FormContainer);
