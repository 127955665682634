import i18next from 'i18next';

import alerts from 'common/modules/alerts';

import * as energyBackupSetupSelectors from '../../energyBackupSetup/selectors';
import * as proposalGeneratorQuoteSelectors from '../../proposalGeneratorQuote/selectors';

import deleteItem from './delete';
import initialValues from './initialValues';

export default (id, proposalId) => (dispatch, getState) => {
    const state = getState();
    const offerEnergyBackup =
        proposalGeneratorQuoteSelectors.getOfferEnergyStorageData(state);

    const energyBackupOfferValuesData =
        energyBackupSetupSelectors.getOfferValuesData(state);
    let messages = [
        i18next.t(
            'At least one unit per product is required, if you do not want the product you can remove it'
        ),
    ];

    if (energyBackupOfferValuesData?.battery_offer?.[0]?.id === id)
        messages.push(
            i18next.t(
                'Removing this storage product will also remove the configured power backup plan. Do you want continue?'
            )
        );

    const dataAlert = {
        cancelText: i18next.t('Cancel'),
        confirmText: i18next.t('Delete'),
        title: i18next.t('Warning'),
        messages,
        onCancel: () => dispatch(initialValues(offerEnergyBackup)),
        onSuccess: () => dispatch(deleteItem(id, proposalId)),
        type: alerts.ALERT_TYPE_CONFIRM,
        variant: 'warning',
    };

    dispatch(alerts.actions.show(dataAlert));
};
