import React, { useState, useEffect } from 'react';

import { styled, useTheme } from '@mui/material/styles';
import { colorify } from 'lottie-colorify';
import Lottie from 'lottie-light-react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Box, Typography } from 'sunwise-ui';

import LoadingLisa from 'resources/lotties/LoadingLisa.json';
import LoadingLisaDark from 'resources/lotties/LoadingLisaDark.json';

import { STAGES } from '../constants';
import * as selectors from '../selectors';

const Wrapper = styled(Box)(({ theme }) => ({
    alignItems: 'center',
    background: theme.palette.background.default,
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    height: '100%',
    justifyContent: 'center',
    left: 0,
    opacity: 1,
    overflow: 'hidden',
    position: 'fixed',
    top: 0,
    transition: 'opacity 0.25s linear, visibility 0s linear',
    visibility: 'visible',
    width: '100%',
    zIndex: 9999,
}));

const Stepper = styled(Box)`
    align-items: center;
    display: flex;
    flex-direction: row;
    gap: 20px;
    justify-content: center;
    width: 100%;
`;

const Bullet = styled(Box)(({ theme, active }) => {
    const isActive = active === 'true';
    return {
        background: isActive ? theme.palette.primary.main : '#B6BBC1',
        borderRadius: '50%',
        height: '10px',
        width: '10px',
        transition: 'height 0.25s linear, width 0.25s linear',
        ...(isActive && {
            height: '16px',
            width: '16px',
        }),
    };
});

const getStage = (items) => {
    return items.reduce((acc, item) => {
        if (item?.stage === -1) return acc;
        return Math.min(acc, item?.stage || 0);
    }, 8);
};

const getFixedStage = (stage) => {
    if ([0, 1].includes(stage)) return 0;
    if ([2, 3].includes(stage)) return 1;
    if ([4].includes(stage)) return 2;
    return 3;
};

const LisaLoading = ({ filesSocketOcr, onCompleteUpload, show }) => {
    const [stage, setStage] = useState(0);
    const [animationData, setAnimationData] = useState(null);
    const theme = useTheme();
    const { t } = useTranslation();

    useEffect(() => {
        setAnimationData(
            colorify(
                ['#FF9A00', '#FF9A00', undefined, undefined, '#FF9A00'],
                theme.palette.mode === 'dark' ? LoadingLisaDark : LoadingLisa
            )
        );
    }, [theme.palette.mode]);

    useEffect(() => {
        if (!show) setStage(0);
    }, [show]);

    useEffect(() => {
        if (!filesSocketOcr) return;

        const files = Object.values(filesSocketOcr);
        if (files.length > 0) {
            const _stage = getStage(Object.values(filesSocketOcr));
            if ([7, 8].includes(_stage)) onCompleteUpload();

            setStage(getFixedStage(_stage));
        }
    }, [filesSocketOcr]);

    if (!show) return null;

    return (
        <Wrapper>
            <Lottie
                animationData={animationData}
                loop={true}
                style={{ height: '60%' }}
            />

            <Typography variant="h5">{t(STAGES[stage])}</Typography>

            <Stepper>
                {STAGES.map((_, index) => (
                    <Bullet active={`${index === stage}`} key={index} />
                ))}
            </Stepper>
        </Wrapper>
    );
};

const mapStateToProps = createStructuredSelector({
    filesSocketOcr: selectors.getFilesSocketOcr,
    show: selectors.getShowLisaLoading,
});

LisaLoading.propTypes = {
    filesSocketOcr: PropTypes.object,
    onCompleteUpload: PropTypes.func,
    show: PropTypes.bool,
};

export default connect(mapStateToProps)(LisaLoading);
