import i18next from 'i18next';

import { deleteItem } from 'common/api/v1/irradiations';
import alerts from 'common/modules/alerts';
import { showReponseErrorsAsAlert } from 'common/utils/helpers';
import showToast from 'common/utils/showToast';

import {
    DELETE_ITEM,
    DELETE_ITEM_SUCCESS,
    DELETE_ITEM_FAILURE,
} from '../actionTypes';
import { solarGenerationActions } from '../reducer';

import fetchIrradiationByStateAndSources from './fetchIrradiationByStateAndSources';
import toggleModal from './toggleModal';

export default (values, setValue) => (dispatch) => {
    dispatch(solarGenerationActions[DELETE_ITEM]());
    deleteItem(values.id)
        .then(() => {
            dispatch(solarGenerationActions[DELETE_ITEM_SUCCESS](null));
            dispatch(alerts.actions.close());
            dispatch(toggleModal(false));
            showToast({ body: i18next.t('It was successfully removed') });
            dispatch(
                fetchIrradiationByStateAndSources(
                    values.political_division2,
                    values.political_division,
                    setValue
                )
            );
        })
        .catch((error) => {
            dispatch(
                solarGenerationActions[DELETE_ITEM_FAILURE](
                    error?.response?.data?.errors
                )
            );
            showReponseErrorsAsAlert(dispatch, error?.response);
        });
};
