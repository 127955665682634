import { filterRoles } from 'common/api/v1/companyRoles';

import {
    FETCH_ITEMS,
    FETCH_ITEMS_FAILURE,
    FETCH_ITEMS_SUCCESS,
} from '../actionTypes';
import { rolesConfigurationActions } from '../reducer';

export default ({ orderBy, page = 0, pageSize, sortBy } = {}) =>
    (dispatch) => {
        dispatch(rolesConfigurationActions[FETCH_ITEMS]());

        filterRoles({
            orderBy,
            page: page + 1,
            perPage: pageSize,
            sortBy,
        })
            .then((response) =>
                dispatch(
                    rolesConfigurationActions[FETCH_ITEMS_SUCCESS](
                        response.data
                    )
                )
            )
            .catch((error) =>
                dispatch(
                    rolesConfigurationActions[FETCH_ITEMS_FAILURE](
                        error?.response?.data?.errors
                    )
                )
            );
    };
