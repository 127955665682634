import { getItems } from 'common/api/v2/proposalCatalogs';

import {
    FETCH_PROPOSAL_CATALOGS,
    FETCH_PROPOSAL_CATALOGS_FAILURE,
    FETCH_PROPOSAL_CATALOGS_SUCCESS,
} from '../actionTypes';
import { proposalGeneratorQuoteActions } from '../reducer';

export default (proposalId) => (dispatch) =>
    new Promise((resolve) => {
        dispatch(proposalGeneratorQuoteActions[FETCH_PROPOSAL_CATALOGS]());

        getItems(proposalId)
            .then((response) => {
                dispatch(
                    proposalGeneratorQuoteActions[
                        FETCH_PROPOSAL_CATALOGS_SUCCESS
                    ](response.data.data)
                );
            })
            .catch((error) =>
                dispatch(
                    proposalGeneratorQuoteActions[
                        FETCH_PROPOSAL_CATALOGS_FAILURE
                    ](error?.response?.data?.errors)
                )
            )
            .finally(resolve);
    });
