import i18next from 'i18next';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

import { USER_ORIGIN } from 'common/constants';
import { TIER_TYPES } from 'common/constants/rates';
import { formatDate, parseDate, subDate } from 'common/utils/dates';
import {
    calcEnergyDistributionPercentages,
    getDailyAvgFields,
    getEnergyAndDemandFields,
    getFieldsFromTiers,
    getFieldsInPeriod,
    getIsHourlyRate,
} from 'common/utils/helpers/rates';

import {
    fetchConsumptionProfile,
    fetchPoliticalDivisions2,
    fetchScheduleRateConfiguration,
    prepareConsumptions,
    setConsumptionProfileCsvData,
    setConsumptionWithCsvDataCalculated,
    setConsumptionsCaptureMode,
    setIsDisabled,
} from '../../projectConsumptionsModal/actions';
import { CONSUMPTIONS_CAPTURE_MODE } from '../../projectConsumptionsModal/constants';
import * as selectors from '../selectors';

const DATE_FORMAT = 'dd/MM/yyyy';

const cleanObjectValue = (object, key) =>
    typeof object !== 'undefined' && object !== null ? object[key] : '';

export default () => (dispatch, getState) => {
    const state = getState();
    const consumptionProfileData = selectors.getConsumptionProfile(state);
    const data = selectors.getDataConsumptionEnergy(state);
    const hasCommercialOfferEnded = selectors.getHasCommercialOfferEnded(state);
    const selectedProject = selectors.getSelectedProject(state);
    const automaticHasChanges =
        data.receipt_origin === 'semiautomatic' ? true : false;
    const isCertified = data.rate.certified;

    let values = {
        address: data.address,
        billing_period_end: data.billing_period_end,
        billing_period_start: data.billing_period_start,
        branch_office: data.branch_office,
        company: data.company,
        consumption_origin: data.consumption_origin,
        consumption_profile: data.consumption_profile?.[0]
            ? data.consumption_profile?.[0].consumption_profile
            : null,
        contracted_demand: data.contrated_demand,
        electrical_network: data.electrical_network,
        holder: data.holder,
        id: data.id,
        is_bimonthly: data.is_bimonthly ? '1' : '0',
        last_consumption: data.last_consumption ?? data.billing_period_end,
        periodicity_type: data.periodicity_type,
        political_division1: get(data, 'political_division1.id', null),
        political_division2: get(data, 'political_division2.id', null),
        rate: data.rate.id,
        rate_division: cleanObjectValue(data.rate_division, 'id'),
        rate_division_summer: cleanObjectValue(data.rate_division_summer, 'id'),
        rate_region: cleanObjectValue(data.rate_division, 'id'),
        receipt_origin: data.receipt_origin,
        service_number: data.service_number,
        spain_meter_type: data.spain_meter_type,
        subsidy_rate: cleanObjectValue(data.subsidy_rate, 'name'),
        terms_consumption: false,
        threads_number: data.threads_number,
        without_consumption: false,
        zip_code: data.zip_code,
    };

    if (data?.consumptions_csv?.id)
        values.consumptions_csv = data.consumptions_csv.id;

    const months_kWh = data.lines_consumption_energies
        .filter((item) => parseInt(item.type_line) === 0)
        .map((item) => ({ ...item, file: item.url }));

    const months_kW = data.lines_consumption_energies
        .filter((item) => parseInt(item.type_line) === 1)
        .map((item) => ({ ...item, file: item.url }));

    const sortedMonthskWh = months_kWh.sort(
        (a, b) =>
            parseDate(a.initial_date, DATE_FORMAT) -
            parseDate(b.initial_date, DATE_FORMAT)
    );

    const sortedMonthskW = months_kW.sort(
        (a, b) =>
            parseDate(a.initial_date, DATE_FORMAT) -
            parseDate(b.initial_date, DATE_FORMAT)
    );

    if (values.political_division1)
        dispatch(fetchPoliticalDivisions2(values.political_division1));

    dispatch(
        fetchScheduleRateConfiguration({
            rate: { id: values.rate, isCertified, name: data.rate.name },
            rateDivision: values.rate_division,
        })
    ).then((rateConfiguration) => {
        const { tiers_demand_distribution, tiers_energy_distribution } =
            rateConfiguration || {};

        let summary = [];

        const rateObject = {
            formType: data.rate.form_type,
            isCertified,
            name: data.rate.name,
            paymentType: data.rate.payment_type,
        };

        if (data?.gdmth_summary?.length > 0) {
            const gdmthSummary = [...data.gdmth_summary];
            const sortedSummary = gdmthSummary.sort(
                (a, b) =>
                    parseDate(a.initial_date, DATE_FORMAT) -
                    parseDate(b.initial_date, DATE_FORMAT)
            );
            summary = sortedSummary.map((period, index) => {
                const kWhData = sortedMonthskWh[index];
                if (!kWhData) return null;
                const label = formatDate(
                    subDate(parseDate(kWhData.final_date, DATE_FORMAT), {
                        days: data.is_bimonthly ? 30 : 15,
                    }),
                    'MMM yy'
                );
                const baseConsumption = parseInt(period.base_conpsumption);
                const middleConsumption = parseInt(
                    period.intermediate_consumption
                );
                const peakConsumption = parseInt(period.peak_consumption);
                const total =
                    baseConsumption + middleConsumption + peakConsumption;

                const fields = getFieldsInPeriod({
                    discardValues: true,
                    finalDate: kWhData.final_date,
                    initialDate: kWhData.initial_date,
                    period: {
                        kW: {
                            base: {
                                label: i18next.t('Base'),
                                placeholder: Math.round(
                                    period.base_conpsumption_kw
                                ).toString(),
                                value: Math.round(period.base_conpsumption_kw),
                            },
                            middle: {
                                label: i18next.t('Middle', { context: 'rate' }),
                                placeholder: Math.round(
                                    period.intermediate_consumption_kw
                                ).toString(),
                                value: Math.round(
                                    period.intermediate_consumption_kw
                                ),
                            },
                            peak: {
                                label: i18next.t('Peak'),
                                placeholder: Math.round(
                                    period.peak_consumption_kw
                                ).toString(),
                                value: Math.round(period.peak_consumption_kw),
                            },
                        },
                        kWh: {
                            base: {
                                label: i18next.t('Base'),
                                placeholder: baseConsumption.toString(),
                                value: baseConsumption,
                            },
                            middle: {
                                label: i18next.t('Middle', { context: 'rate' }),
                                placeholder: middleConsumption.toString(),
                                value: middleConsumption,
                            },
                            peak: {
                                label: i18next.t('Peak'),
                                placeholder: peakConsumption.toString(),
                                value: peakConsumption,
                            },
                        },
                        total: {
                            placeholder: total.toString(),
                            value: total,
                        },
                    },
                    rate: rateObject,
                    tiers_energy_distribution,
                });
                return {
                    ...fields,
                    file: kWhData.file,
                    final_date: kWhData.final_date,
                    id: period.id,
                    initial_date: kWhData.initial_date,
                    label: period.initial_date ? label : 'Último mes',
                    power_factor: period.power_factor,
                    predicted: period.predicted,
                    url_type: kWhData.url_type,
                };
            });
        } else {
            const sortedTierEnergyConsumptions =
                [...(data?.tiers_consumptions || [])].sort(
                    (a, b) =>
                        parseDate(a.initial_date, DATE_FORMAT) -
                        parseDate(b.initial_date, DATE_FORMAT)
                ) || [];

            const isHourlyRate = getIsHourlyRate(rateObject);

            if (
                sortedTierEnergyConsumptions.length > 0 &&
                tiers_demand_distribution?.[0]?.tiers &&
                isHourlyRate
            ) {
                const { fields: hourly_contracted_demand } = getFieldsFromTiers(
                    {
                        concepts:
                            sortedTierEnergyConsumptions?.[0]
                                ?.contracted_demand,
                        isCertified,
                        tierType: TIER_TYPES.DEMAND,
                        tiers: tiers_demand_distribution?.[0]?.tiers,
                    }
                );
                values.hourly_contracted_demand = hourly_contracted_demand;
            }

            summary = sortedMonthskWh.map((period, index) => {
                const finalDate = parseDate(period.final_date, DATE_FORMAT);
                const label = formatDate(
                    subDate(finalDate, { days: data.is_bimonthly ? 30 : 15 }),
                    'MMM yy'
                );
                const totalConsumption = period.consumption.toFixed(0);

                const { fields: _fields, total: _total } =
                    getEnergyAndDemandFields({
                        isCertified,
                        period: sortedTierEnergyConsumptions[index],
                        tiers_energy_distribution,
                    });

                const total = _total || totalConsumption;

                const fields = getFieldsInPeriod({
                    discardValues: true,
                    finalDate: period.final_date,
                    initialDate: period.initial_date,
                    tmp_kW: sortedMonthskW?.[index]?.consumption,
                    period: {
                        ..._fields,
                        total: { placeholder: `${total}`, value: total },
                    },
                    rate: rateObject,
                    tiers_energy_distribution,
                });

                return {
                    dailyAvg: getDailyAvgFields(
                        total,
                        period.final_date,
                        period.initial_date
                    ),
                    file: period.file,
                    final_date: period.final_date,
                    id: period.id,
                    initial_date: period.initial_date,
                    ...fields,
                    label: period.initial_date ? label : 'Último mes',
                    power_factor: period.power_factor,
                    predicted: period.predicted,
                    url_type: period.url_type,
                };
            });
        }

        const filteredSummaryData = summary.filter((item) => item !== null);

        values.summary = filteredSummaryData
            .sort(
                (a, b) =>
                    parseDate(b.initial_date, DATE_FORMAT) -
                    parseDate(a.initial_date, DATE_FORMAT)
            )
            .slice(0, 12);

        const distribution = calcEnergyDistributionPercentages(values.summary);
        if (!isEmpty(distribution)) values.distribution = distribution;

        values.last_consumption = values.summary[0].final_date;

        if (values.summary[0]?.file) values.file = values.summary[0]?.file;
        if (
            values.consumption_profile &&
            values.consumption_origin !== USER_ORIGIN
        )
            dispatch(fetchConsumptionProfile(values.consumption_profile));

        dispatch(
            prepareConsumptions({
                automaticHasChanges,
                initialValues: values,
                isOpenModal: true,
                projectData: selectedProject,
            })
        );

        if (values.consumption_origin === USER_ORIGIN) {
            dispatch(
                setConsumptionProfileCsvData({
                    consumptionProfileArray:
                        consumptionProfileData[0]?.consumption || [],
                    data: {
                        lastDateConsumption:
                            summary[summary.length - 1].final_date,
                        totalConsumption: months_kWh.reduce(
                            (acc, curr) => acc + curr.consumption,
                            0
                        ),
                    },
                    demandArray: data.consumption_profile?.[0]?.demand || [],
                })
            );
            dispatch(setConsumptionsCaptureMode(CONSUMPTIONS_CAPTURE_MODE.CSV));
            dispatch(setConsumptionWithCsvDataCalculated(true));
        }
        if (hasCommercialOfferEnded) dispatch(setIsDisabled(true));
    });
};
