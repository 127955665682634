import i18next from 'i18next';
import findIndex from 'lodash/findIndex';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import uniq from 'lodash/uniq';

import {
    DELTA,
    polygonOptions,
    SEGMENT_COLOR,
    SEGMENT_STROKE_COLOR,
} from 'common/components/maps/constants';
import SegmentPolygonBuilder from 'common/components/maps/models/SegmentPolygonBuilder';
import SegmentRectangleBuilder from 'common/components/maps/models/SegmentRectangleBuilder';
import {
    getNewSolarModules,
    getPolygonCoordinates,
    getSolarModules,
} from 'common/utils/helpers';

import iconMarkerColor from 'resources/iconMarkerColor.svg';

import { POLYGON_MAP, POLYGON_OBSTACLE, POLYGON_SEGMENT } from './constants';
import { getSolarModulesInSegment } from './solarGeometry';
import { getBounds, getCenter } from './solarGeometryhelpers';

const polylineCommonConfig = {
    clickable: false,
    strokeColor: '#FF0000',
    strokeOpacity: 0.75,
    strokeWeight: 2,
};

const vertexChangeDebounce = (checkSegment) => {
    let interval;
    return ({ evt = '', polygon, segmentId } = {}) => {
        clearTimeout(interval);
        interval = setTimeout(() => {
            interval = null;
            if (polygon.length <= 0) return;
            checkSegment({
                evt,
                polygon: polygon.map((latLng) => [latLng.lat(), latLng.lng()]),
                segmentId,
            });
        }, 250);
    };
};

export const restoreGroups = (response) => {
    let restoredGroup = [];

    if (!Array.isArray(response)) return restoredGroup;

    const length = response.length;

    if (length === 0) return restoredGroup;

    for (let i = 0; i < length; i++) {
        const cell = response[i];

        if (cell.group === null || cell.row === null || cell.col === null)
            continue;

        if (!restoredGroup[cell.group]) restoredGroup[cell.group] = [];

        if (!restoredGroup[cell.group][cell.cell])
            restoredGroup[cell.group][cell.cell] = [];
        if (!restoredGroup[cell.group][cell.cell][cell.row])
            restoredGroup[cell.group][cell.cell][cell.row] = [];

        restoredGroup[cell.group][cell.cell][cell.row][cell.col] = {
            cell: cell.cell,
            col: cell.col,
            enable: cell.enabled,
            group: cell.group,
            id: cell.id,
            paths: cell.path,
            row: cell.row,
        };
    }

    return restoredGroup;
};

export const getBreadcrumbs = (handleClickBreadcrumb, id, offerInfoPatern) => [
    {
        action: () =>
            handleClickBreadcrumb(get(offerInfoPatern, 'contactId', null)),
        label: get(offerInfoPatern, 'contactName', null),
    },
    {
        action: () =>
            handleClickBreadcrumb(
                get(offerInfoPatern, 'contactId', null),
                get(offerInfoPatern, 'projectId', null)
            ),
        label: get(offerInfoPatern, 'projectName', null),
    },
    {
        action: () => handleClickBreadcrumb(null, null, id),
        label: get(offerInfoPatern, 'proposalName', null),
    },
];

export const getDictionaryById = (array) => {
    return array.reduce(
        (acc, current) => ({ ...acc, [current.id]: current }),
        {}
    );
};

export const getSolarCharacterization = (segment) => {
    const solarCharacterization = get(segment, 'solar_characterization', {});
    const {
        azimuth,
        col_spacing,
        frame_col_spacing,
        frame_cols_number,
        frame_row_spacing,
        frame_rows_number,
        orientation,
        panel_company_branch_office,
        row_spacing,
        safe_zone,
        tilt,
    } = solarCharacterization;
    return {
        azimuth,
        col_spacing,
        frame_col_spacing,
        frame_cols_number,
        frame_horizontal_number: frame_cols_number,
        frame_row_spacing,
        frame_rows_number,
        frame_vertical_number: frame_rows_number,
        orientation,
        panel_company_branch_office,
        row_spacing,
        safe_zone,
        solar_module: panel_company_branch_office,
        tilt,
    };
};

export const formatSegment = (segment, parentId) => {
    const {
        field_segment_points,
        groups,
        height,
        id,
        image,
        name,
        opacity = 100,
        panel_modules,
        rotate = 0,
        segment_color = SEGMENT_COLOR,
        segment_stroke_color = SEGMENT_STROKE_COLOR,
        solar_modules,
        solar_modules_number,
        total_area,
        type,
        zindex,
    } = segment;

    const solarCharacterization = getSolarCharacterization(segment);

    const solarModules =
        solar_modules.length > 0 &&
        (!Array.isArray(panel_modules) || panel_modules.length === 0)
            ? getSolarModules(solar_modules)
            : getNewSolarModules(panel_modules);

    const quantityEnabledModules = getQuantityEnabledModules(solarModules);
    const grouped = restoreGroups(solarModules);
    const modules_groups = getModulesGroups(grouped);

    return {
        grouped_cells: grouped,
        groups,
        height,
        id,
        image,
        modules_groups,
        name,
        opacity,
        open: false,
        parent_field_segment: parentId,
        polygon: getPolygonCoordinates(field_segment_points),
        prev_location: getPolygonCoordinates(field_segment_points),
        quantity_enabled_modules: quantityEnabledModules,
        rotate,
        segment_color,
        segment_stroke_color,
        solar_modules: solarModules,
        solar_modules_number,
        total_area,
        type,
        zIndex: zindex,
        ...solarCharacterization,
    };
};

export const getParentFieldSegments = (segments, parentId) => {
    let newSegments = [];
    if (!segments.field_segments.length) return newSegments;

    newSegments = segments.field_segments.map((segment) =>
        formatSegment(segment, parentId)
    );

    return newSegments;
};

export const handleOpenChangeSegments = ({
    item,
    segments,
    updateSegments,
}) => {
    const newValues = segments.map((segment) =>
        segment.id === item.id
            ? { ...segment, open: !segment.open }
            : { ...segment, open: false }
    );
    updateSegments(newValues);
};

export const handleOpenDrawer = (swipeableDrawerRef, value) => {
    if (swipeableDrawerRef.current) {
        const { handleOpen } = swipeableDrawerRef.current;
        handleOpen(value);
    }
};

export const buildShape = ({
    checkSegment,
    google,
    handleOnClickModule,
    mapRef,
    newSegment,
    onContextMenu,
    prepareEditSegment,
    selectSegmentById,
    setSelectedItems,
    setTempSegments,
    sunPosition,
}) => {
    let newShape;
    const vertexChangeDebounceBuild = vertexChangeDebounce(checkSegment);
    const map = mapRef.current.self();
    const {
        height,
        id,
        image,
        opacity,
        polygon,
        rotate,
        safe_zone: safeZone,
        segment_color: fillColor,
        segment_stroke_color: strokeColor,
        solar_modules: solarModules,
        type,
        zIndex,
    } = newSegment;
    const paths = polygon.map(
        (point) => new google.maps.LatLng(point[0], point[1])
    );

    if ([POLYGON_OBSTACLE, POLYGON_SEGMENT].includes(type)) {
        const SegmentPolygon = SegmentPolygonBuilder(
            google,
            vertexChangeDebounceBuild,
            onContextMenu
        );

        newShape = new SegmentPolygon({
            height,
            id,
            onClickModule: handleOnClickModule,
            options: {
                draggable: false,
                editable: false,
                fillColor,
                fillOpacity: 0.7,
                map,
                paths,
                strokeColor,
            },
            safeZone,
            solarModules,
            sunPosition,
            type,
            zIndex,
        });
    }

    if (type === POLYGON_MAP) {
        const SegmentRectangle = SegmentRectangleBuilder(
            google,
            vertexChangeDebounceBuild
        );

        const polygon = new google.maps.Polygon({ paths });
        const bounds = getBounds({ google, polygon });

        newShape = new SegmentRectangle({
            id,
            image,
            opacity,
            options: { bounds, map, strokeColor },
            rotate,
            type,
        });
    }

    const shapeWithEvents = initEventsShape({
        google,
        newShape,
        prepareEditSegment,
        segmentId: newSegment.id,
        selectSegmentById,
        setSelectedItems,
    });

    setTempSegments((tempSegments) => ({
        ...tempSegments,
        [newSegment.id]: shapeWithEvents,
    }));
};

export const buildShapes = ({
    checkSegment,
    google,
    handleOnClickModule,
    mapRef,
    onContextMenu,
    prepareEditSegment,
    segments,
    selectSegmentById,
    setSelectedItems,
    setTempSegments,
    sunPosition,
    tempSegments,
}) => {
    for (let i = 0; i < segments.length; i++) {
        const segment = segments[i];
        if (tempSegments[segment.id]) continue;

        buildShape({
            checkSegment,
            google,
            handleOnClickModule,
            mapRef,
            newSegment: segment,
            onContextMenu,
            prepareEditSegment,
            selectSegmentById,
            setSelectedItems,
            setTempSegments,
            sunPosition,
        });
    }
};

export const handleAfterPaste = ({
    checkSegment,
    google,
    mapRef,
    newSegment,
    onContextMenu,
    prepareEditSegment,
    selectSegmentById,
    setSelectedItems,
    setTempSegments,
    sunPosition,
}) => {
    buildShape({
        checkSegment,
        google,
        mapRef,
        newSegment,
        onContextMenu,
        prepareEditSegment,
        selectSegmentById,
        setSelectedItems,
        setTempSegments,
        sunPosition,
    });
};

const initEventsShape = ({
    google,
    newShape,
    prepareEditSegment,
    segmentId,
    selectSegmentById,
    setSelectedItems,
}) => {
    google.maps.event.addListener(
        newShape,
        'click',
        ({ domEvent: { ctrlKey, metaKey } }) => {
            selectSegmentById(segmentId);
            setSelectedItems({
                isMulti: ctrlKey || metaKey,
                segmentId,
            });
        }
    );
    google.maps.event.addListener(newShape, 'dblclick', () => {
        prepareEditSegment(segmentId);
    });

    return newShape;
};

const handleOverlayType = ({ event, google, createSegment }) => {
    const overlayTypes = google.maps.drawing.OverlayType;
    let polygon;
    let paths;
    let bounds;
    if (event.type === overlayTypes.POLYGON) {
        paths = event.overlay.getPath().getArray();
        polygon = paths.map((latLng) => [latLng.lat(), latLng.lng()]);
    }
    if (event.type === overlayTypes.RECTANGLE) {
        bounds = event.overlay.getBounds();
        const northEast = bounds.getNorthEast();
        const southWest = bounds.getSouthWest();
        polygon = [
            [northEast.lat(), southWest.lng()],
            [northEast.lat(), northEast.lng()],
            [southWest.lat(), northEast.lng()],
            [southWest.lat(), southWest.lng()],
        ];
    }
    event.overlay.setMap(null);
    createSegment(polygon);
};

const handleOverlayComplete = ({
    breakpoint,
    createSegment,
    drawingManager,
    event,
    google,
    onOpenDrawer,
}) => {
    const overlayTypes = google.maps.drawing.OverlayType;
    if (
        [overlayTypes.POLYGON, overlayTypes.RECTANGLE].includes(
            drawingManager.getDrawingMode()
        )
    ) {
        handleOverlayType({ event, google, createSegment });
        if (['xs', 'sm', 'md'].includes(breakpoint)) onOpenDrawer();
        return;
    }
    event.overlay.setMap(null);
    return;
};

/* useEffects */
export const copyPasteEventsUseEffect = ({
    commercialOfferId,
    handleOnCopy,
    handleOnPaste,
    mapValue,
    refBox,
}) => {
    return () => {
        if (!refBox || !mapValue) return;

        const handleKeyDown = (event) => {
            event.preventDefault();
            const code = event.which || event.keyCode;
            let charCode = String.fromCharCode(code).toLowerCase();
            if (event.ctrlKey || event.metaKey) {
                if (charCode === 'c') handleOnCopy();
                if (charCode === 'v') handleOnPaste({ commercialOfferId });
            }
        };

        refBox.current.addEventListener('keydown', handleKeyDown);

        return () => {
            if (!refBox) return;
            refBox.current?.removeEventListener('keydown', handleKeyDown);
        };
    };
};

const getPolylinePoints = ({ google, map, point }) => {
    const heading = map.getHeading();
    const distance = 1000;
    const spherical = google.maps.geometry.spherical;

    const north = spherical.computeOffset(point, distance, 0 + heading);
    const south = spherical.computeOffset(point, distance, 180 + heading);
    const east = spherical.computeOffset(point, distance, 90 + heading);
    const west = spherical.computeOffset(point, distance, 270 + heading);

    return [
        [north, south],
        [east, west],
    ];
};

const buildPolyline = ({ google, map, point }) => {
    const [[north, south], [east, west]] = getPolylinePoints({
        google,
        map,
        point,
    });

    const northSouth = new google.maps.Polyline({
        ...polylineCommonConfig,
        path: [north, south],
    });

    const eastWest = new google.maps.Polyline({
        ...polylineCommonConfig,
        path: [east, west],
    });

    northSouth.setMap(map);
    eastWest.setMap(map);

    return [northSouth, eastWest];
};

export const drawingModeUseEffect = ({
    breakpoint,
    createSegment,
    drawingManager,
    drawingType,
    google,
    isDrawingMode,
    isGuideLinesActive = false,
    map,
    onOpenDrawer,
} = {}) => {
    let lastPointEastWest = null;
    let lastPointNorthSouth = null;
    let originEastWest = null;
    let originNorthSouth = null;
    let points = [];
    let prevEvt = null;

    const handleOnClickListener = (point) => {
        points.push(point);

        if (!isGuideLinesActive) return;
        const [northSouth, eastWest] = buildPolyline({
            google,
            map,
            point,
        });

        if (points.length === 1) {
            originNorthSouth = northSouth;
            originEastWest = eastWest;
        }

        if (points.length > 1) {
            if (lastPointEastWest) lastPointEastWest.setMap(null);
            if (lastPointNorthSouth) lastPointNorthSouth.setMap(null);
            lastPointNorthSouth = northSouth;
            lastPointEastWest = eastWest;
        }
    };

    const handleOnHeadingChanged = () => {
        if (points.length === 0) return;

        const initialPoint = points[0];

        if (originEastWest) originEastWest.setMap(null);
        if (originNorthSouth) originNorthSouth.setMap(null);

        const [northSouth, eastWest] = buildPolyline({
            google,
            map,
            point: initialPoint,
        });

        originNorthSouth = northSouth;
        originEastWest = eastWest;

        if (points.length > 1) {
            const lastPoint = points[points.length - 1];

            const [northSouth, eastWest] = buildPolyline({
                google,
                map,
                point: lastPoint,
            });

            if (lastPointEastWest) lastPointEastWest.setMap(null);
            if (lastPointNorthSouth) lastPointNorthSouth.setMap(null);
            lastPointNorthSouth = northSouth;
            lastPointEastWest = eastWest;
        }
    };

    const reset = () => {
        lastPointEastWest?.setMap(null);
        lastPointNorthSouth?.setMap(null);
        originEastWest?.setMap(null);
        originNorthSouth?.setMap(null);
        lastPointEastWest = null;
        lastPointNorthSouth = null;
        originEastWest = null;
        originNorthSouth = null;
        points = [];
    };

    return () => {
        const mapsEvent = google.maps.event;
        if (drawingManager == null) return;
        if (isDrawingMode) {
            const overlayTypes = google.maps.drawing.OverlayType;
            let drawingOverlayType = overlayTypes.POLYGON;

            if (drawingType === POLYGON_MAP)
                drawingOverlayType = overlayTypes.RECTANGLE;
            else {
                mapsEvent.addListener(map, 'drag', () => {
                    prevEvt = 'drag';
                });

                mapsEvent.addListener(map, 'mouseup', (e) => {
                    if (prevEvt !== 'mousedown') {
                        prevEvt = 'mouseup';
                        return;
                    }

                    handleOnClickListener(e.latLng);
                });

                mapsEvent.addListener(map, 'mousedown', () => {
                    prevEvt = 'mousedown';
                });

                mapsEvent.addListener(map, 'heading_changed', () => {
                    handleOnHeadingChanged();
                });
            }

            if (drawingManager.getDrawingMode() !== drawingOverlayType) {
                drawingManager.setDrawingMode(drawingOverlayType);
                mapsEvent.clearListeners(drawingManager, 'overlaycomplete');
                mapsEvent.addListener(
                    drawingManager,
                    'overlaycomplete',
                    (event) =>
                        handleOverlayComplete({
                            breakpoint,
                            createSegment,
                            drawingManager,
                            event,
                            google,
                            onOpenDrawer,
                        })
                );
            }
        } else {
            if (drawingManager.getDrawingMode() !== null)
                drawingManager.setDrawingMode(null);

            mapsEvent.clearListeners(map, 'drag');
            mapsEvent.clearListeners(map, 'mouseup');
            mapsEvent.clearListeners(map, 'mousedown');
        }

        return () => reset();
    };
};

export const editionModeUseEffect = ({
    breakpoint,
    isDisablingModules,
    isEditionMode,
    onOpenDrawer,
    selectedSegmentId,
    tempSegments,
}) => {
    return () => {
        if (isEmpty(tempSegments)) return;
        for (const tempSegmentKey in tempSegments) {
            const tempSegment = tempSegments[tempSegmentKey];
            if (isEditionMode) {
                tempSegment.setDisabled(true);
            } else {
                if (tempSegmentKey === selectedSegmentId) {
                    tempSegment.setDisabled(
                        isEditionMode || isDisablingModules,
                        true
                    );
                } else {
                    tempSegment.setDisabled(
                        isEditionMode || isDisablingModules,
                        false
                    );
                }
            }

            if (tempSegment.setEnableModulesToEdit) {
                tempSegment.setEnableModulesToEdit(isDisablingModules);
            }
        }

        if (isEditionMode && ['xs', 'sm', 'md'].includes(breakpoint))
            onOpenDrawer();
    };
};

export const updateSegmentDataUseEffect = ({
    tempSegments,
    updateSegmentData,
}) => {
    return () => {
        if (isEmpty(updateSegmentData)) return;

        const {
            height,
            id,
            image,
            opacity,
            rotate,
            safe_zone,
            segment_color: fillColor,
            solar_modules: solarModules = [],
            type,
            zIndex,
        } = updateSegmentData;

        const segment = tempSegments[id];
        if (type !== POLYGON_MAP) {
            if (segment.setSolarModules) segment.setSolarModules(solarModules);

            if (segment.setAditionalOptions)
                segment.setAditionalOptions({ fillColor, fillOpacity: 0.7 });

            if (segment.setHeight) segment.setHeight(height, zIndex || 0);

            if (segment.setSafeZone) segment.setSafeZone(safe_zone);
        }

        if (segment.setDisabled) segment.setDisabled(true, false);

        if (type === POLYGON_MAP) {
            segment.setOverlayOptions(image, opacity, rotate);
        }
    };
};

export const escapeUseEffect = ({
    setDrawingMode,
    setIsDisablingModules,
    useCallback,
} = {}) => {
    const escFunction = useCallback((event) => {
        if (event.key === 'Escape') {
            setDrawingMode(false);
            setIsDisablingModules(false);
        }
    }, []);

    return () => {
        document.addEventListener('keydown', escFunction, false);

        return () => {
            document.removeEventListener('keydown', escFunction, false);
        };
    };
};

export const loadingUseEffect = ({
    isCheckinSegment,
    isDeletingSegment,
    isEditingSegment,
    isFetchingIrradiationData,
    isSavingSegment,
    loadingContext,
}) => {
    return () => {
        if (isEmpty(loadingContext)) return;
        if (
            isCheckinSegment ||
            isDeletingSegment ||
            isEditingSegment ||
            isSavingSegment ||
            isFetchingIrradiationData
        )
            loadingContext.openLoading(
                i18next.t('Saving change', { count: 2 }).concat('...')
            );
        else loadingContext.closeLoading();
    };
};

export const mapValueUseEffect = ({
    currentProjectLocation,
    google,
    mapValue,
    setDrawingManager,
}) => {
    let originalSetFunc = null;
    let marker = null;
    return () => {
        if (!mapValue || !currentProjectLocation) return;

        const position = new google.maps.LatLng(
            currentProjectLocation.latitude,
            currentProjectLocation.longitude
        );

        marker = new google.maps.Marker({
            animation: google.maps.Animation.DROP,
            icon: {
                scaledSize: new google.maps.Size(32, 32),
                url: iconMarkerColor,
            },
            map: mapValue,
            position: position,
        });

        mapValue.setOptions({
            mapId: '90f87356969d889c',
            restriction: {
                latLngBounds: {
                    east: position.lng() + DELTA,
                    north: position.lat() + DELTA,
                    south: position.lat() - DELTA,
                    west: position.lng() - DELTA,
                },
                strictBounds: true,
            },
            tilt: 0,
        });

        let zoomRangeModifier = mapValue.__proto__.__proto__.__proto__;
        originalSetFunc = zoomRangeModifier.set;
        const hackedSetFunc = function (a, b) {
            if (a === 'maxZoom') b += 2;
            originalSetFunc.call(this, a, b);
        };
        zoomRangeModifier.set = hackedSetFunc;

        const drawingManager = new google.maps.drawing.DrawingManager({
            drawingMode: null,
            drawingControl: false,
            polygonOptions: {
                ...polygonOptions,
                strokeWeight: 5,
                strokePosition: google.maps.StrokePosition.OUTSIDE,
            },
        });
        drawingManager.setMap(mapValue);
        setDrawingManager(drawingManager);

        return () => {
            if (!mapValue) return;

            if (marker) {
                marker.setMap(null);
                marker = null;
            }

            let zoomRangeModifier = mapValue.__proto__.__proto__.__proto__;
            zoomRangeModifier.set = function (a, b) {
                originalSetFunc.call(this, a, b);
            };
        };
    };
};

export const selectedSegmentIdUseEffect = ({
    segmentsDictionary,
    selectedSegmentId,
    tempSegments,
}) => {
    return () => {
        for (const tempSegmentKey in tempSegments) {
            if (tempSegmentKey === selectedSegmentId) {
                tempSegments[selectedSegmentId].setSelected(true);
            } else {
                tempSegments[tempSegmentKey].setSelected(false, {
                    strokeColor:
                        segmentsDictionary?.[tempSegmentKey]
                            ?.segment_stroke_color,
                });
            }
        }
    };
};

export const getNewModules = ({ data, google, map, module, obstacles }) => {
    const { assigned, quantity, polygon, ...solar_characterization } = data;
    const parsedPolygon = JSON.parse(polygon);

    if (parsedPolygon.length <= 2 || isEmpty(module))
        return { boxes: [], grouped: [] };

    const { height: solarModuleHeight = 0, width: solarModuleWidth = 0 } =
        module;

    const {
        azimuth,
        col_spacing: solarModuleColSpacing,
        frame_col_spacing: frameColSpacing,
        frame_cols_number: frameCols,
        frame_row_spacing: frameRowSpacing,
        frame_rows_number: frameRows,
        height,
        orientation: solarModuleOrientation,
        row_spacing: solarModuleRowSpacing,
        safe_zone: tolerance,
        tilt,
        zIndex,
    } = solar_characterization;

    const setup = {
        azimuth,
        frameCols,
        frameColSpacing,
        frameRows,
        frameRowSpacing,
        height,
        solarModuleColSpacing,
        solarModuleHeight,
        solarModuleOrientation,
        solarModuleRowSpacing,
        solarModuleWidth,
        tilt,
        tolerance,
        zIndex,
    };

    const maxSolarModules =
        parseInt(quantity) - parseInt(assigned) <= 0
            ? parseInt(quantity)
            : parseInt(quantity) - parseInt(assigned);

    const _polygon = new google.maps.Polygon({
        paths: parsedPolygon.map((latlng) => ({
            lat: latlng[0],
            lng: latlng[1],
        })),
    });

    const { boxes, grouped } = getSolarModulesInSegment({
        google,
        map,
        maxSolarModules,
        obstacles,
        polygon: _polygon,
        setup,
    });

    return { boxes, grouped };
};

export const getObstaceZIndex = ({
    google,
    polygon,
    segments,
    selectedSegment,
}) => {
    const center = getCenter({
        google,
        paths: polygon.map((point) => ({
            lat: point[0],
            lng: point[1],
        })),
    });

    const filteredSegmnets = segments.filter(
        (segment) => segment.id !== selectedSegment.id
    );

    let zIndex = 0;

    if (filteredSegmnets.length > 0) {
        filteredSegmnets.forEach((segment) => {
            const temp_polygon = new google.maps.Polygon({
                paths: segment.polygon.map((point) => ({
                    lat: point[0],
                    lng: point[1],
                })),
            });

            const flag = google.maps.geometry.poly.containsLocation(
                center,
                temp_polygon
            );

            const segmentHeight = parseFloat(segment.height);

            if (flag) zIndex += segmentHeight;
        });

        if (zIndex) zIndex++;
    }

    return zIndex || 0;
};

export const getCollidedPolygons = ({ google, points, polygons }) => {
    let collidedPolygons = [];
    const obstaclePolygon = new google.maps.Polygon({
        paths: points.map((latlng) => ({
            lat: latlng[0],
            lng: latlng[1],
        })),
    });
    const obstaclePaths = obstaclePolygon.getPath().getArray();

    polygons.forEach((polygon) => {
        const _polygon = new google.maps.Polygon({
            paths: polygon.polygon.map((latlng) => ({
                lat: latlng[0],
                lng: latlng[1],
            })),
        });

        let flag = false;

        for (let i = 0; i < obstaclePaths.length; i++) {
            if (!flag) {
                flag = google.maps.geometry.poly.containsLocation(
                    obstaclePaths[i],
                    _polygon
                );
            }
        }

        if (flag) collidedPolygons.push(polygon);
    });

    return collidedPolygons;
};

export const mergeArrays = (arr1 = [], arr2 = []) => {
    let merge = [...arr1, ...arr2];
    let newArray = [];
    let uniqueObject = {};

    for (let i in merge) {
        const id = merge[i]['id'];
        uniqueObject[id] = merge[i];
    }

    for (let i in uniqueObject) {
        newArray.push(uniqueObject[i]);
    }

    return newArray;
};

export const checkCallback = (id, modules, mapRef) => {
    if (!mapRef.current) return;
    mapRef.current.setModulesToSegment(id, modules);
};

export const checkCallbackError = (id, values, mapRef) => {
    if (!mapRef.current) return;
    mapRef.current.resetSegmentPosition(id, values);
};

export const getQuantityEnabledModules = (solarModules) => {
    return solarModules.reduce(
        (acc, current) => (current.enabled ? acc + 1 : acc),
        0
    );
};

export const getGroupSize = (group) => {
    let meta = {
        cells: 0,
        cols: 0,
        rows: 0,
    };

    if (!Array.isArray(group) || group.length === 0) return null;

    const sample = group;
    meta.cells = sample.length;
    meta.rows = sample[0].length;
    meta.cols = sample[0][0].length;

    return meta;
};

export const mergeGroupCells = (group = [], index = 0) => {
    if (!Array.isArray(group)) return null;
    const { rows: rowsSize } = getGroupSize(group);
    let merged = new Array(rowsSize).fill([]);
    for (let cells = 0; cells < group.length; cells++) {
        const cell = group[cells];
        for (let rows = 0; rows < cell.length; rows++) {
            const row = cell[rows];
            for (let cols = 0; cols < row.length; cols++) {
                const col = row[cols];
                merged[rows] = merged[rows].concat({
                    ...col,
                    cell: cells,
                    group: index,
                });
            }
        }
    }
    return merged;
};

const getLargestColumnCardinality = (group) => {
    if (!Array.isArray(group) || group.length === 0) return 0;
    const values = group.map((row) => row.length);
    return Math.max(...uniq(values));
};

const normalizeGroup = (group) => {
    if (!Array.isArray(group) || group.length === 0) return [];

    const biggestColumnCardinality = getLargestColumnCardinality(group);

    const normalizedGroup = group.map((row) => {
        const newRow = [...row];
        if (newRow.length < biggestColumnCardinality) {
            const delta = biggestColumnCardinality - newRow.length;
            for (let i = 0; i < delta; i++) newRow.push(null);
        }
        return newRow;
    });

    return normalizedGroup;
};

const getColsToSlice = (group) => {
    let colsToSlice = new Set();

    if (!Array.isArray(group) || group.length === 0) return colsToSlice;

    const numRows = group.length;
    let tempCount = {};

    for (let r = 0; r < numRows; r++) {
        const row = group[r];
        for (let c = 0; c < row.length; c++) {
            const col = row[c];
            if (col?.enable) continue;
            if (!tempCount[c]) tempCount[c] = 0;
            tempCount[c]++;

            if (tempCount[c] === numRows) colsToSlice.add(c);
        }
    }

    return colsToSlice;
};

export const getModulesGroup = (group) => {
    if (!Array.isArray(group) || group.length === 0) return [];

    const normalizedGroup = normalizeGroup(group);
    const colsToSlice = getColsToSlice(normalizedGroup);

    if (!colsToSlice.size) return [normalizedGroup];

    const numRows = normalizedGroup.length;
    const numCols = normalizedGroup[0].length;

    let result = [];
    let tempMatrix = [];

    for (let cols = 0; cols < numCols; cols++) {
        if (colsToSlice.has(cols)) {
            if (tempMatrix.length) result.push(tempMatrix);
            tempMatrix = [];
            continue;
        }

        for (let rows = 0; rows < numRows; rows++) {
            if (!tempMatrix[rows]) tempMatrix[rows] = [];
            const value = normalizedGroup[rows][cols];
            tempMatrix[rows].push(value);
        }
    }

    if (tempMatrix.length) result.push(tempMatrix);

    return result;
};

export const getModulesGroups = (groups) => {
    const finalGroups = [];

    if (!Array.isArray(groups)) return finalGroups;

    for (let g = 0; g < groups.length; g++) {
        const mergedGroupCells = mergeGroupCells(groups[g], g);
        const newGroups = getModulesGroup(mergedGroupCells);

        for (let j = 0; j < newGroups.length; j++) {
            finalGroups.push(newGroups[j]);
        }
    }

    return finalGroups;
};

export const prepareModulesGroups = (groups) => {
    let newGroups = [];

    for (let i = 0; i < groups.length; i++) {
        const row = groups[i].length;
        const col = groups[i][0].length;

        const index = findIndex(newGroups, { row, col });

        if (index === -1) {
            newGroups.push({ col, match: 1, row });
        } else {
            newGroups[index].match = newGroups[index].match + 1;
        }
    }

    return newGroups;
};

export const countPanelPerSegmentsById = (id, segments) => {
    const filteredSegments = segments.filter(
        (segment) => segment.solar_module === id
    );

    const quantityEnabledModules = filteredSegments.reduce(
        (acc, current) => acc + current.quantity_enabled_modules,
        0
    );

    return quantityEnabledModules;
};
