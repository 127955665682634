import {
    fetchAllianceDocumentMessages,
    fetchCreditDocumentMessages,
} from 'common/api/v1/chat';

import {
    FETCH_MESSAGES,
    FETCH_MESSAGES_FAILURE,
    FETCH_MESSAGES_SUCCESS,
} from '../actionTypes';
import {
    ALLIANCE_DOCUMENT_TYPE,
    FINANCIER_USER_TYPE,
    INSTALLER_USER_TYPE,
} from '../constants';
import { commentAreaActions } from '../reducer';

import initializeForm from './initializeForm';

export default ({ documentId, documentType, user, userType }) =>
    (dispatch) => {
        const action =
            documentType === ALLIANCE_DOCUMENT_TYPE
                ? fetchAllianceDocumentMessages
                : fetchCreditDocumentMessages;

        dispatch(commentAreaActions[FETCH_MESSAGES]());

        action(documentId)
            .then((response) => {
                dispatch(
                    commentAreaActions[FETCH_MESSAGES_SUCCESS](response.data)
                );

                const values = {
                    chatId: response.data.id,
                    message: '',
                    user: user,
                };

                values.message_from =
                    userType === FINANCIER_USER_TYPE
                        ? FINANCIER_USER_TYPE
                        : INSTALLER_USER_TYPE;

                dispatch(initializeForm(values));
            })
            .catch((error) =>
                dispatch(commentAreaActions[FETCH_MESSAGES_FAILURE](error))
            );
    };
