import { getConsumptionEnergy } from 'common/api/v2/costumerEnergyConsumption';

import {
    FETCH_CONSUMPTION_ENERGY,
    FETCH_CONSUMPTION_ENERGY_FAILURE,
    FETCH_CONSUMPTION_ENERGY_NO_ANIMATION,
    FETCH_CONSUMPTION_ENERGY_SUCCESS,
} from '../actionTypes';
import { projectConsumptionCardActions } from '../reducer';

export default (projectId, animation = true) =>
    (dispatch) => {
        const type =
            animation === true
                ? FETCH_CONSUMPTION_ENERGY
                : FETCH_CONSUMPTION_ENERGY_NO_ANIMATION;

        dispatch(projectConsumptionCardActions[type]());

        getConsumptionEnergy(projectId)
            .then((response) =>
                dispatch(
                    projectConsumptionCardActions[
                        FETCH_CONSUMPTION_ENERGY_SUCCESS
                    ](response.data.data)
                )
            )
            .catch((error) =>
                dispatch(
                    projectConsumptionCardActions[
                        FETCH_CONSUMPTION_ENERGY_FAILURE
                    ](error?.response?.data?.errors)
                )
            );
    };
