import { deleteItem } from 'common/api/v1/historical';
import { showReponseErrorsAsAlert } from 'common/utils/helpers';
import showToast from 'common/utils/showToast';

import {
    DELETE_DATA,
    DELETE_DATA_FAILURE,
    DELETE_DATA_SUCCESS,
} from '../actionTypes';
import { actions } from '../reducer';

import fetchData from './fetchData';

export default (uuid, projectId) => (dispatch) => {
    dispatch(actions[DELETE_DATA]());

    deleteItem(uuid)
        .then(() => {
            dispatch(actions[DELETE_DATA_SUCCESS]());
            showToast();
            dispatch(fetchData({ isInitialFetch: true, projectId }));
        })
        .catch((error) => {
            dispatch(actions[DELETE_DATA_FAILURE](error));
            showReponseErrorsAsAlert(dispatch, error?.response);
        });
};
