import i18next from 'i18next';

import alerts from 'common/modules/alerts';

import deleteCredential from './deleteCredential';

export default (id) => (dispatch) => {
    const dataAlert = {
        confirmText: i18next.t('Delete'),
        haveTextCaptcha: true,
        messages: [
            i18next.t(
                'Are you sure to delete this credential? Everything related to this credential will be deleted'
            ),
        ],
        onSuccess: () => dispatch(deleteCredential(id)),
        textToValidate: i18next.t('Delete').toUpperCase(),
        title: i18next.t('Confirmation'),
        type: alerts.ALERT_TYPE_CONFIRM,
        variant: 'warning',
    };

    dispatch(alerts.actions.show(dataAlert));
};
