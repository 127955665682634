import i18next from 'i18next';

import alerts from 'common/modules/alerts';

import downloadHistory from './downloadHistory';

export default (id) => (dispatch) => {
    const dataAlert = {
        cancelText: i18next.t('Cancel'),
        confirmText: i18next.t('Continue'),
        messages: [
            i18next.t(
                'This process can take between 5 to 30 minutes. We will notify you when the download is complete'
            ),
        ],
        onSuccess: () => {
            dispatch(downloadHistory(id));
            dispatch(alerts.actions.close());
        },
        title: i18next.t('Confirmation'),
        type: alerts.ALERT_TYPE_CONFIRM,
        variant: 'warning',
    };

    dispatch(alerts.actions.show(dataAlert));
};
