import React from 'react';

import SyncIcon from '@mui/icons-material/Sync';
import { get, isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Box, IconButton } from 'sunwise-ui';

import { ReactHookFormIntlNumberInput } from 'common/components/form/bootstrap';
import {
    getCurrencyIso,
    getCurrencySymbol,
    getOppositeCurrency,
} from 'common/utils/helpers';

import { getIsDisabledButton } from '../helpers';

const TypeChangeField = ({
    canModifyExchangeType,
    currencies,
    control,
    isLocked,
    setValue,
}) => {
    const { t } = useTranslation();
    const oppositeCurrency = getOppositeCurrency(currencies);
    const currencyIso = getCurrencyIso(oppositeCurrency);
    const currencySymbol = getCurrencySymbol(oppositeCurrency);
    const dollarPrice = get(oppositeCurrency, 'dollar_price', 0);
    const typeChangeOffer = useWatch({ control, name: 'type_change_offer' });
    const disabled = getIsDisabledButton(
        canModifyExchangeType,
        isLocked,
        oppositeCurrency,
        typeChangeOffer
    );

    const handleOnClick = () => {
        if (!isEmpty(oppositeCurrency))
            setValue('type_change_offer', dollarPrice);
    };

    return (
        <Box sx={{ alignItems: 'center', display: 'flex', gap: '16px' }}>
            <ReactHookFormIntlNumberInput
                append={currencyIso}
                className="__intercom_type_change_offer_field"
                control={control}
                dataIntercomTarget="Type change offer (field)"
                disabled={isLocked || !canModifyExchangeType}
                fullWidth
                label={t('Exchange rate')}
                name="type_change_offer"
                prepend={currencySymbol}
            />
            <IconButton
                className="__intercom_refresh_change_offer_button"
                data-intercom-target="Refresh change offer (Button)"
                disabled={disabled}
                onClick={handleOnClick}
                sx={{ mb: 2 }}
                type="button"
            >
                <SyncIcon />
            </IconButton>
        </Box>
    );
};

TypeChangeField.propTypes = {
    canModifyExchangeType: PropTypes.bool,
    control: PropTypes.object,
    currencies: PropTypes.array,
    isLocked: PropTypes.bool,
    setValue: PropTypes.func,
};

export default TypeChangeField;
