import i18next from 'i18next';

import { SET_OFFER_SMART_DOCUMENT_FORM_MODE } from '../../actionTypes';
import { OFFER_SMART_DOCUMENT_FORM_DUPLICATE_MODE } from '../../constants';
import { multiTemplateReviewActions } from '../../reducer';

import initializeSmartDocumentForm from './initializeOfferSmartDocumentForm';
import openOfferSmartDocumentModal from './openOfferSmartDocumentModal';

export default (name, id) => (dispatch) => {
    dispatch(
        multiTemplateReviewActions[SET_OFFER_SMART_DOCUMENT_FORM_MODE](
            OFFER_SMART_DOCUMENT_FORM_DUPLICATE_MODE
        )
    );
    dispatch(
        initializeSmartDocumentForm({
            id,
            name: `${i18next.t('Copy of')} ${name}`,
        })
    );

    return dispatch(openOfferSmartDocumentModal(true));
};
