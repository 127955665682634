import i18next from 'i18next';

import { refreshFinancing } from 'common/api/v1/sunpay';
import { showReponseErrorsAsAlert } from 'common/utils/helpers';
import showToast from 'common/utils/showToast';

import eventsAfterUpdate from '../../proposalGeneratorOneColumn/actions/eventsAfterUpdate';
import {
    REFRES_FINANCING,
    REFRES_FINANCING_SUCCESS,
    REFRES_FINANCING_FAILURE,
} from '../actionTypes';
import { proposalGeneratorTemplateActions } from '../reducer';

export default (creditId, proposalId) => (dispatch) => {
    dispatch(proposalGeneratorTemplateActions[REFRES_FINANCING]());

    refreshFinancing(creditId)
        .then((response) => {
            dispatch(
                proposalGeneratorTemplateActions[REFRES_FINANCING_SUCCESS](
                    response.data.data
                )
            );
            showToast({
                type: 'success',
                body: i18next.t('Financing data successfully updated'),
            });
            dispatch(eventsAfterUpdate(['offer-details'], proposalId));
        })
        .catch((error) => {
            dispatch(
                proposalGeneratorTemplateActions[REFRES_FINANCING_FAILURE](
                    error?.response?.data?.errors
                )
            );
            showReponseErrorsAsAlert(dispatch, error?.response);
        });
};
