import { push } from 'connected-react-router';
import get from 'lodash/get';

import { getSubscription } from 'common/api/v1/companies';

import {
    FETCH_SUBSCRIPTION,
    FETCH_SUBSCRIPTION_FAILURE,
    FETCH_SUBSCRIPTION_SUCCESS,
} from '../actionTypes';
import { selectBillingPlanActions } from '../reducer';

export default () => (dispatch) => {
    dispatch(selectBillingPlanActions[FETCH_SUBSCRIPTION]());

    getSubscription()
        .then((response) => {
            dispatch(
                selectBillingPlanActions[FETCH_SUBSCRIPTION_SUCCESS](
                    response.data
                )
            );
            const subscriptionStatus = get(response.data, 'status.key', null);
            const dueInvoicesCount = get(
                response.data,
                'due_invoices_count',
                0
            );
            if (subscriptionStatus === 'active' || dueInvoicesCount > 0) {
                dispatch(push('/profile/subscription'));
                return false;
            }
        })
        .catch((error) =>
            dispatch(
                selectBillingPlanActions[FETCH_SUBSCRIPTION_FAILURE](
                    error?.response?.data?.errors
                )
            )
        );
};
