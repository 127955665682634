import { getSources } from 'common/api/v1/irradiations';

import {
    FETCH_SOURCES,
    FETCH_SOURCES_FAILURE,
    FETCH_SOURCES_SUCCESS,
} from '../actionTypes';
import { solarGenerationActions } from '../reducer';

export default () => (dispatch) => {
    dispatch(solarGenerationActions[FETCH_SOURCES]());

    return getSources()
        .then((response) =>
            dispatch(
                solarGenerationActions[FETCH_SOURCES_SUCCESS](
                    response.data.data
                )
            )
        )
        .catch((error) =>
            dispatch(
                solarGenerationActions[FETCH_SOURCES_FAILURE](
                    error?.response?.data?.errors
                )
            )
        );
};
