import React from 'react';

import DeleteIcon from '@mui/icons-material/Delete';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import DropdownCardMenu from 'common/components/cards/DropdownCardMenu';

const DropdownMenu = ({
    canModify,
    className = '',
    handleClickDelete,
    isLocked,
}) => {
    const { t } = useTranslation();
    if (isLocked) return null;
    return (
        <DropdownCardMenu
            className={className}
            items={[
                {
                    handleClick: () => handleClickDelete(),
                    icon: <DeleteIcon fontSize="small" />,
                    label: t('Delete'),
                },
            ]}
            visible={canModify}
        />
    );
};

DropdownMenu.propTypes = {
    canModify: PropTypes.bool,
    className: PropTypes.string,
    handleClickDelete: PropTypes.func,
    isLocked: PropTypes.bool,
};

export default DropdownMenu;
