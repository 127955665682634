import React from 'react';

import WarningIcon from '@mui/icons-material/Warning';
import { yellow } from '@mui/material/colors';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Button } from 'sunwise-ui';

import { COMMERCIAL_OFFER_STATUS } from 'common/constants/commercialOffer';

import * as energyBackupSetupSelectors from '../../energyBackupSetup/selectors';
import * as proposalGeneratorQuoteSelectors from '../../proposalGeneratorQuote/selectors';
import * as actions from '../actions';

const UpdateButton = styled(Button)(({ theme }) => ({
    color: theme.palette.getContrastText(yellow[500]),
    backgroundColor: yellow[500],
    '&:hover': {
        backgroundColor: yellow[600],
    },
}));

const GenerateButton = ({
    canModify,
    creditId,
    handleClickRefresh,
    handleSubmit,
    hasIncompatibleInverters,
    isEditingProposal,
    isGenerated,
    isLoading,
    offerDetails,
    prepareEndProposal,
    proposalId,
    warnings,
}) => {
    const { t } = useTranslation();

    const hasWarnings = warnings && warnings.length > 0;

    const handleOnSubmit = (values) =>
        prepareEndProposal(
            proposalId,
            values,
            offerDetails.status,
            hasIncompatibleInverters,
            hasWarnings
        );

    const isDisabled = isGenerated ? false : !canModify;

    if (isEditingProposal)
        return (
            <UpdateButton
                disabled={!canModify || isLoading}
                color="info"
                fullWidth
                onClick={() => handleClickRefresh(creditId, proposalId)}
            >
                {t('Update')}
            </UpdateButton>
        );

    return (
        <Button
            className="__userguiding_proposal_generator_button_generate"
            disabled={isLoading || isDisabled}
            fullWidth
            onClick={handleSubmit(handleOnSubmit)}
            startIcon={
                hasWarnings || hasIncompatibleInverters ? <WarningIcon /> : null
            }
        >
            {offerDetails.status === COMMERCIAL_OFFER_STATUS.FINISHED.key
                ? t('View proposal')
                : t('Generate')}
        </Button>
    );
};

const mapStateToProps = createStructuredSelector({
    hasIncompatibleInverters:
        proposalGeneratorQuoteSelectors.getHasIncompatibleInverters,
    warnings: energyBackupSetupSelectors.getOfferValuesWarnings,
});

const mapDispatchToProps = (dispatch) => ({
    prepareEndProposal: (
        proposalId,
        values,
        status,
        hasIncompatibleInverters,
        hasWarnings
    ) =>
        dispatch(
            actions.prepareEndProposal(
                proposalId,
                values,
                status,
                hasIncompatibleInverters,
                hasWarnings
            )
        ),
});

GenerateButton.propTypes = {
    canModify: PropTypes.bool,
    creditId: PropTypes.string,
    handleClickRefresh: PropTypes.func,
    handleSubmit: PropTypes.func,
    hasIncompatibleInverters: PropTypes.bool,
    isEditingProposal: PropTypes.bool,
    isGenerated: PropTypes.bool,
    isLoading: PropTypes.bool,
    offerDetails: PropTypes.object,
    prepareEndProposal: PropTypes.func,
    proposalId: PropTypes.string,
    warnings: PropTypes.array,
};

export default connect(mapStateToProps, mapDispatchToProps)(GenerateButton);
