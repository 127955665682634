import React from 'react';

import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { get, isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Avatar, Box, Button, Card, Grid, Typography } from 'sunwise-ui';

import TitleWithDetail from 'common/components/TitleWithDetail';
import { handleFileURL, stringAvatar } from 'common/utils/helpers';

const InstallerCard = ({ agent }) => {
    const { t } = useTranslation();

    if (isEmpty(agent)) return null;

    const agentName = `${get(agent, 'first_name', '')} ${get(
        agent,
        'last_name',
        ''
    )}`;

    return (
        <Box mb={3}>
            <Card>
                <Card.Header sx={{ p: 1 }}>
                    <TitleWithDetail fontSize="16">
                        {t('Contact an advisor')}
                    </TitleWithDetail>
                </Card.Header>
                <Card.Body>
                    <Grid container>
                        <Grid item xs={5}>
                            {get(agent, 'profile_image', '') ? (
                                <Avatar
                                    alt={agentName}
                                    src={handleFileURL(
                                        agent?.profile_image,
                                        process.env.REACT_APP_S3_MEDIA_PATH
                                    )}
                                    sx={{ height: 80, width: 80 }}
                                />
                            ) : (
                                <Avatar
                                    {...stringAvatar(agentName)}
                                    sx={{
                                        fontSize: '16px',
                                        height: 80,
                                        width: 80,
                                    }}
                                />
                            )}
                        </Grid>
                        <Grid item xs={13}>
                            <Typography variant="body2" fontWeight="bold">
                                {agentName}
                            </Typography>
                            <Typography variant="caption">
                                {`${
                                    agent.phone
                                        ? agent.phone
                                        : t('Unregistered phone')
                                } / ${agent.email}`}
                            </Typography>
                            {agent.phone && (
                                <Box mt={1}>
                                    <Button
                                        color="secondary"
                                        href={`https://api/v1.whatsapp.com/send?phone=${agent.phone}`}
                                        startIcon={
                                            <WhatsAppIcon
                                                sx={{ color: '#4caf50' }}
                                            />
                                        }
                                        target="_blank"
                                        variant="text"
                                    >
                                        {t('Contact an advisor')}
                                    </Button>
                                </Box>
                            )}
                        </Grid>
                    </Grid>
                </Card.Body>
            </Card>
        </Box>
    );
};

InstallerCard.propTypes = {
    agent: PropTypes.object,
};

export default InstallerCard;
