export const mapStyles = [
    {
        elementType: 'geometry',
        stylers: [{ color: '#212121' }],
    },
    {
        elementType: 'labels.icon',
        stylers: [{ visibility: 'off' }],
    },
    {
        elementType: 'labels.text.fill',
        stylers: [{ color: '#757575' }],
    },
    {
        elementType: 'labels.text.stroke',
        stylers: [{ color: '#212121' }],
    },
    {
        elementType: 'geometry',
        featureType: 'administrative',
        stylers: [{ color: '#757575' }],
    },
    {
        elementType: 'labels.text.fill',
        featureType: 'administrative.country',
        stylers: [{ color: '#9e9e9e' }],
    },
    {
        featureType: 'administrative.land_parcel',
        stylers: [{ visibility: 'off' }],
    },
    {
        elementType: 'labels.text.fill',
        featureType: 'administrative.locality',
        stylers: [{ color: '#bdbdbd' }],
    },
    {
        elementType: 'labels.text.fill',
        featureType: 'poi',
        stylers: [{ color: '#757575' }],
    },
    {
        elementType: 'geometry',
        featureType: 'poi.park',
        stylers: [{ color: '#181818' }],
    },
    {
        elementType: 'labels.text.fill',
        featureType: 'poi.park',
        stylers: [{ color: '#616161' }],
    },
    {
        elementType: 'labels.text.stroke',
        featureType: 'poi.park',
        stylers: [{ color: '#1b1b1b' }],
    },
    {
        elementType: 'geometry.fill',
        featureType: 'road',
        stylers: [{ color: '#2c2c2c' }],
    },
    {
        elementType: 'labels.text.fill',
        featureType: 'road',
        stylers: [{ color: '#8a8a8a' }],
    },
    {
        elementType: 'geometry',
        featureType: 'road.arterial',
        stylers: [{ color: '#373737' }],
    },
    {
        elementType: 'geometry',
        featureType: 'road.highway',
        stylers: [{ color: '#3c3c3c' }],
    },
    {
        elementType: 'geometry',
        featureType: 'road.highway.controlled_access',
        stylers: [{ color: '#4e4e4e' }],
    },
    {
        elementType: 'labels.text.fill',
        featureType: 'road.local',
        stylers: [{ color: '#616161' }],
    },
    {
        elementType: 'labels.text.fill',
        featureType: 'transit',
        stylers: [{ color: '#757575' }],
    },
    {
        elementType: 'geometry',
        featureType: 'water',
        stylers: [{ color: '#000000' }],
    },
    {
        elementType: 'labels.text.fill',
        featureType: 'water',
        stylers: [{ color: '#3d3d3d' }],
    },
];

export const POLYGON_SEGMENT = 0;
export const POLYGON_OBSTACLE = 1;
export const POLYGON_MAP = 2;

export const SEGMENT_COLOR = '#FF9A0080';
export const SEGMENT_STROKE_COLOR = '#FF9A00';

export const SEGMENT_ACTIVE_COLOR = '#FF9A00B3';
export const SEGMENT_STROKE_ACTIVE_COLOR = '#002438';

export const SOLAR_MODULE_COLOR = '#185577';
export const SOLAR_MODULE_STROKE_COLOR = '#002438';

export const SEGMENT_COLORS = [
    {
        color: SEGMENT_COLOR,
        stroke_color: SEGMENT_STROKE_COLOR,
    },
    {
        color: '#42a5f5',
        stroke_color: '#2196f3',
    },
    {
        color: '#26c6da',
        stroke_color: '#00bcd4',
    },
    {
        color: '#26a69a',
        stroke_color: '#009688',
    },
];

export const polygonOptions = {
    clickable: false,
    draggable: false,
    editable: true,
    fillColor: SEGMENT_COLOR,
    fillOpacity: 1,
    strokeColor: SEGMENT_STROKE_COLOR,
    strokeOpacity: 1,
    zIndex: 2,
};

export const solarCharacterization = {
    azimuth: 180,
    col_spacing: 0.02,
    frame_col_spacing: 0.02,
    frame_cols_number: 1,
    frame_horizontal_number: 1,
    frame_row_spacing: 1,
    frame_rows_number: 1,
    frame_vertical_number: 1,
    orientation: 'P',
    panel_company_branch_office: null,
    row_spacing: 0.02,
    safe_zone: 0,
    tilt: 21,
};

export const DELTA = 0.005;
