import React, { useRef, useState, useEffect, Suspense } from 'react';

import { CssBaseline } from '@mui/material';
import {
    createTheme,
    StyledEngineProvider,
    ThemeProvider,
} from '@mui/material/styles';
import { GoogleApiWrapper } from 'google-maps-react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter, Router } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { compose } from 'recompose';
import { createStructuredSelector } from 'reselect';
import {
    breakpoints,
    customShadows,
    componentsOverride,
    palette,
    shadows,
    shape,
    typography,
} from 'sunwise-ui';

import 'common/utils/polyfills/string';
import { NotificationsListener, OnLineListener } from 'common/components';
import FirstLoadView from 'common/modules/firstLoadView/Container';
import LoadingProcessView from 'common/modules/loadingView/ProcessView';
import { LoadingProvider, GeneralProvider } from 'common/utils/contexts';
import 'react-toastify/dist/ReactToastify.css';
import { handleBuildTheme } from 'common/utils/helpers';
import { getIsGuest } from 'common/utils/helpers/session';

import ToastAskPermission from './common/components/ToastAskPermission';
import { handleFaviconAndTitle } from './common/utils/helpers';
import main from './modules/main';
import * as profileActions from './modules/profile/actions';
import * as profileSelectors from './modules/profile/selectors';
import {
    components as themeDarkComponents,
    palette as themeDarkPalette,
} from './theme-dark';

const App = ({
    branding,
    google,
    history,
    location,
    selectedTheme = 'light',
    setBranding,
    setTheme,
    userCompany,
    subscription,
}) => {
    const loadingProcessRef = useRef(null);
    const [providerValue, setProviderValue] = useState({});
    const isGuest = getIsGuest();

    const { darkPalette, lightPalette } = handleBuildTheme({
        baseDarkPalette: themeDarkPalette,
        baseLightPalette: palette,
        branding,
    });

    const themeOptions = {
        breakpoints,
        customShadows,
        palette: selectedTheme === 'light' ? lightPalette : darkPalette,
        shadows,
        shape,
        typography,
    };
    const theme = createTheme(themeOptions);
    theme.components = componentsOverride(theme);
    if (selectedTheme === 'dark')
        theme.components = { ...themeDarkComponents(theme).components };

    useEffect(() => {
        if (loadingProcessRef.current !== null) {
            const { openLoading, closeLoading } = loadingProcessRef.current;
            setProviderValue({ openLoading, closeLoading });
        }
    }, [loadingProcessRef]);

    useEffect(() => {
        const storageTheme = localStorage.getItem('theme') || 'light';
        setTheme(storageTheme);
        const storageBranding = localStorage.getItem('branding');
        if (storageBranding && storageBranding !== 'undefined') {
            const branding = JSON.parse(storageBranding);
            setBranding(branding);
        }
    }, []);

    useEffect(() => {
        handleFaviconAndTitle({ isGuest, userCompany, subscription });
    }, [isGuest, userCompany, subscription]);

    return (
        <Suspense fallback={null}>
            <LoadingProcessView
                ref={loadingProcessRef}
                selectedTheme={selectedTheme}
            />
            <FirstLoadView
                disabled={location.pathname === '/template-render'}
                selectedTheme={selectedTheme}
            />
            <Router history={history}>
                <LoadingProvider value={providerValue}>
                    <GeneralProvider value={{ google }}>
                        <StyledEngineProvider injectFirst>
                            <ThemeProvider theme={theme}>
                                <CssBaseline />
                                <NotificationsListener />
                                <OnLineListener />
                                <ToastAskPermission />
                                <main.Container />
                            </ThemeProvider>
                        </StyledEngineProvider>
                    </GeneralProvider>
                </LoadingProvider>
                <ToastContainer />
            </Router>
        </Suspense>
    );
};

App.propTypes = {
    branding: PropTypes.object,
    google: PropTypes.object,
    history: PropTypes.object,
    location: PropTypes.object,
    selectedTheme: PropTypes.string,
    setBranding: PropTypes.func,
    setTheme: PropTypes.func,
    userCompany: PropTypes.object,
    subscription: PropTypes.object,
};

const mapStateToProps = createStructuredSelector({
    branding: profileSelectors.getBranding,
    selectedTheme: profileSelectors.getTheme,
    userCompany: profileSelectors.getUserCompany,
    subscription: profileSelectors.getSubscription,
});

const mapDispatchToProps = (dispatch) => ({
    setBranding: (value) => dispatch(profileActions.setBranding(value)),
    setTheme: (value) => dispatch(profileActions.setTheme(value)),
});

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    GoogleApiWrapper({
        apiKey: process.env.REACT_APP_GOOGLE_MAPS_KEY,
        libraries: ['drawing', 'geometry'],
        LoadingContainer: () => null,
    }),
    withRouter
)(App);
