import React from 'react';

import get from 'lodash/get';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box, Grid } from 'sunwise-ui';

import {
    ReactHookFormInputDatePicker,
    ReactHookFormIntlNumberInputConsumption,
} from 'common/components/form/bootstrap';
import { getDaysInPeriod } from 'common/utils/helpers/rates';

import { updatePlaceholder } from '../../../actions';
import {
    getBorderColor,
    getMaxInitialDate,
    getMinInitialDate,
    handleNormalizeConsumption,
    handleNormalizeFinalDateBuild,
    handleNormalizeInitialDateBuild,
    handleNormalizeTotalKwh,
    handleOnPasteHourlyFields,
} from '../../../helpers';
import {
    AvgConsumptionWarning,
    DaysInPeriodWarning,
    DemandRatioWarning,
    MissingFieldsAlert,
    SeasonChangeWarning,
} from '../../warnings';
import MonthlyPDFFileUpload from '../certified/hourly/MonthlyPDFFileUpload';

const handleOnChangeKWhValue = ({
    daysInPeriod,
    getValues,
    index,
    name,
    profilesConsumptionData,
    ratesDictionary,
    setValue,
}) => {
    const total = getValues(`${name}.total.value`);
    const dailyAvg = total ? (total / daysInPeriod).toFixed(2) : 0;
    setValue(`${name}.dailyAvg`, {
        placeholder: dailyAvg.toString(),
        value: dailyAvg,
    });
    setValue(`${name}.updatedManual`, true);
    handleNormalizeTotalKwh({
        getValues,
        index,
        profilesConsumptionData,
        ratesDictionary,
        setValue,
    });
};

const handleOnChangeKWValue = ({
    getValues,
    name,
    profilesConsumptionData,
    ratesDictionary,
    setValue,
}) => {
    setValue(`${name}.updatedManual`, true);
    updatePlaceholder({
        formValues: getValues(),
        profilesConsumptionData,
        ratesDictionary,
        setValue,
    });
};

const handleOnChangeDailyAvg = ({
    daysInPeriod,
    getValues,
    name,
    profilesConsumptionData,
    ratesDictionary,
    setValue,
}) => {
    const dailyAvg = getValues(`${name}.dailyAvg.value`);
    const newValue = dailyAvg ? dailyAvg * daysInPeriod : 0;
    setValue(`${name}.updatedManual`, true);
    setValue(`${name}.total`, {
        placeholder: newValue.toString(),
        value: newValue,
    });
    updatePlaceholder({
        formValues: getValues(),
        profilesConsumptionData,
        ratesDictionary,
        setValue,
    });
};

const IntakeCaptureField = ({
    control,
    disabled,
    disabledConsumptionFieldsByCsv,
    getValues,
    handleDownloadLisaFile,
    handleNormalizeDates,
    handleUploadPDFReciptFile,
    index,
    isGDRate,
    name,
    period,
    periodWarnings,
    profilesConsumptionData,
    rateConfiguration,
    ratesDictionary,
    setValue,
    showAvgDailyField,
}) => {
    const { t } = useTranslation();
    const {
        hasAvgConsumptionWarning,
        hasDaysInPeriodWarning,
        inconsistentDemands,
    } = periodWarnings ?? {};
    const borderColor = getBorderColor(get(period, 'predicted'));
    const daysInPeriod = getDaysInPeriod(period);
    const energyFields = Object.keys(period?.kWh ?? {});
    const sxCard = { border: hasDaysInPeriodWarning && '1px solid #ffc107' };
    const sxWarning = { border: borderColor ? `1px solid ${borderColor}` : '' };

    return (
        <Box borderRadius={1} sx={sxCard}>
            {!disabledConsumptionFieldsByCsv && (
                <MonthlyPDFFileUpload
                    control={control}
                    disabled={disabledConsumptionFieldsByCsv}
                    handleDownloadLisaFile={handleDownloadLisaFile}
                    name={`${name}.file`}
                    onChange={(e) =>
                        handleUploadPDFReciptFile({
                            file: e,
                            getValues,
                            index,
                            rateConfiguration,
                            ratesDictionary,
                            sendToLisa: true,
                            setValue,
                        })
                    }
                    setValue={setValue}
                    url_type={period?.url_type}
                />
            )}
            <Grid container>
                <Grid item xs>
                    <ReactHookFormInputDatePicker
                        control={control}
                        disabled={disabledConsumptionFieldsByCsv}
                        label={t('Initial date')}
                        maxDate={getMaxInitialDate(period)}
                        minDate={getMinInitialDate()}
                        name={`${name}.initial_date`}
                        onChange={(value) =>
                            handleNormalizeInitialDateBuild({
                                getValues,
                                handleNormalizeDates,
                                index,
                                rateConfiguration,
                                ratesDictionary,
                                setValue,
                                value,
                            })
                        }
                        sxInput={sxWarning}
                        tabIndex="-1"
                    />
                </Grid>

                <Grid item xs>
                    <ReactHookFormInputDatePicker
                        control={control}
                        disabled={index > 0 || disabledConsumptionFieldsByCsv}
                        label={t('Final date')}
                        maxDate={new Date()}
                        minDate={getMinInitialDate()}
                        name={`${name}.final_date`}
                        onChange={() => {
                            if (index > 0) return;
                            handleNormalizeFinalDateBuild({
                                getValues,
                                handleNormalizeDates,
                                profilesConsumptionData,
                                rateConfiguration,
                                ratesDictionary,
                                setValue,
                            });
                        }}
                        sxInput={sxWarning}
                        tabIndex="-1"
                    />
                </Grid>
            </Grid>

            <Box mb={2}>{t('Energy')}</Box>

            <Grid container>
                {showAvgDailyField && !energyFields.length && (
                    <Grid item xs>
                        <ReactHookFormIntlNumberInputConsumption
                            append="kWh"
                            control={control}
                            disabled={disabledConsumptionFieldsByCsv}
                            fullWidth
                            label={t('Daily average')}
                            name={`${name}.dailyAvg`}
                            onBlur={() =>
                                handleOnChangeDailyAvg({
                                    daysInPeriod,
                                    getValues,
                                    name,
                                    profilesConsumptionData,
                                    ratesDictionary,
                                    setValue,
                                })
                            }
                            sx={sxWarning}
                            tabIndex={`${index + 1}`}
                        />
                    </Grid>
                )}
                {energyFields.map((key, column) => (
                    <Grid key={`period-kWh-${key}`} item xs={18} lg>
                        <ReactHookFormIntlNumberInputConsumption
                            allowDecimals={false}
                            control={control}
                            disabled={disabledConsumptionFieldsByCsv}
                            fullWidth
                            label={period?.kWh[key]?.label}
                            name={`${name}.kWh.${key}`}
                            onBlur={() =>
                                handleNormalizeConsumption({
                                    getValues,
                                    index,
                                    profilesConsumptionData,
                                    ratesDictionary,
                                    setValue,
                                })
                            }
                            onPaste={(event) =>
                                handleOnPasteHourlyFields({
                                    event,
                                    initialColumn: column,
                                    initialRow: 0,
                                    name,
                                    period,
                                    setValue,
                                })
                            }
                            sx={sxWarning}
                            tier={period?.kWh?.[key]?.tier}
                        />
                    </Grid>
                ))}

                <Grid item xs>
                    <ReactHookFormIntlNumberInputConsumption
                        control={control}
                        disabled={disabledConsumptionFieldsByCsv}
                        fullWidth
                        label={t('Total')}
                        name={`${name}.total`}
                        onBlur={() =>
                            handleOnChangeKWhValue({
                                daysInPeriod,
                                getValues,
                                index,
                                name,
                                profilesConsumptionData,
                                ratesDictionary,
                                setValue,
                            })
                        }
                        sx={sxWarning}
                        tabIndex={`${index + 1}`}
                    />
                </Grid>
            </Grid>

            {isGDRate && (
                <>
                    <Box mb={2}>{t('Demand')}</Box>

                    <Grid container>
                        {Object.keys(period?.kW ?? {}).map((key, column) => (
                            <Grid item xs key={`period-kW-${key}`}>
                                <ReactHookFormIntlNumberInputConsumption
                                    control={control}
                                    disabled={disabled}
                                    fullWidth
                                    label={period?.kW[key]?.label}
                                    name={`${name}.kW.${key}`}
                                    onBlur={() =>
                                        handleOnChangeKWValue({
                                            getValues,
                                            name,
                                            profilesConsumptionData,
                                            ratesDictionary,
                                            setValue,
                                        })
                                    }
                                    onPaste={(event) =>
                                        handleOnPasteHourlyFields({
                                            event,
                                            initialColumn: column,
                                            initialRow: 1,
                                            name,
                                            period,
                                            setValue,
                                        })
                                    }
                                    sx={sxWarning}
                                    tabIndex={`${index + 1.1}`}
                                    tier={period?.kW?.[key]?.tier}
                                />
                            </Grid>
                        ))}
                    </Grid>
                </>
            )}

            {period.season_change_pdf && <SeasonChangeWarning />}

            <DemandRatioWarning inconsistentDemands={inconsistentDemands} />

            <DaysInPeriodWarning
                hasDaysInPeriodWarning={hasDaysInPeriodWarning}
            />

            <AvgConsumptionWarning
                hasAvgConsumptionWarning={hasAvgConsumptionWarning}
            />

            <MissingFieldsAlert period={period} />
        </Box>
    );
};

IntakeCaptureField.propTypes = {
    control: PropTypes.object,
    disabled: PropTypes.bool,
    disabledConsumptionFieldsByCsv: PropTypes.bool,
    getValues: PropTypes.func,
    handleDownloadLisaFile: PropTypes.func,
    handleNormalizeDates: PropTypes.func,
    handleUploadPDFReciptFile: PropTypes.func,
    index: PropTypes.number,
    isGDRate: PropTypes.bool,
    name: PropTypes.string,
    period: PropTypes.object,
    periodWarnings: PropTypes.object,
    profilesConsumptionData: PropTypes.array,
    rateConfiguration: PropTypes.object,
    ratesDictionary: PropTypes.object,
    setValue: PropTypes.func,
    showAvgDailyField: PropTypes.bool,
};

export default IntakeCaptureField;
