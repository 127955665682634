import { fetchRequirements } from 'common/api/v1/sunpay';

import {
    FETCH_REQUIREMENTS,
    FETCH_REQUIREMENTS_FAILURE,
    FETCH_REQUIREMENTS_SUCCESS,
} from '../actionTypes';
import { alliancesActions } from '../reducer';

export default (id) => (dispatch) => {
    dispatch(alliancesActions[FETCH_REQUIREMENTS]());

    fetchRequirements(id)
        .then((response) =>
            dispatch(
                alliancesActions[FETCH_REQUIREMENTS_SUCCESS](response.data)
            )
        )
        .catch((error) =>
            dispatch(alliancesActions[FETCH_REQUIREMENTS_FAILURE](error))
        );
};
