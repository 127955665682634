import { markFavorite } from 'common/api/v1/contacts';
import { showReponseErrorsAsAlert } from 'common/utils/helpers';
import showToast from 'common/utils/showToast';

import { MARK_FAVORITE, MARK_FAVORITE_FAILURE } from '../actionTypes';
import { actions } from '../reducer';
import * as selectors from '../selectors';

import fetchContact from './fetchContact';

export default () => (dispatch, getState) => {
    const state = getState();
    const dataContact = selectors.getDataContact(state);
    const data = { contact: dataContact.id };

    dispatch(actions[MARK_FAVORITE]());

    markFavorite(data)
        .then(() => {
            dispatch(fetchContact(dataContact.id, false));
            showToast();
        })
        .catch((error) => {
            dispatch(
                actions[MARK_FAVORITE_FAILURE](error?.response?.data?.errors)
            );
            showReponseErrorsAsAlert(dispatch, error?.response);
        });
};
