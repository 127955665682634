import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Grid } from 'sunwise-ui';

import { ReactHookFormIntlNumberInput } from 'common/components/form/bootstrap';

const UnitPriceColumn = ({ control, customerMode, index, name, size }) => {
    const { t } = useTranslation();

    return (
        <Grid item xs lg={customerMode ? 18 : size.unit_price[0]}>
            <ReactHookFormIntlNumberInput
                control={control}
                disabled
                fullWidth
                label={t('Unit Price')}
                name={`${name}.${index}.unit_price`}
                variant="standard"
            />
        </Grid>
    );
};

UnitPriceColumn.propTypes = {
    control: PropTypes.object,
    customerMode: PropTypes.bool,
    index: PropTypes.number,
    name: PropTypes.string,
    size: PropTypes.object,
};

export default UnitPriceColumn;
