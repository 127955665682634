import { isToday, isYesterday } from 'date-fns';
import i18next from 'i18next';

import { formatDate, parseDate } from 'common/utils/dates';
import { getIsLocalRedirect } from 'common/utils/helpers';

import { DATE_FORMATS } from './constants';

export const getDateTitle = (date, dateFormat = 'dd-MM-yyyy') => {
    if (!date) return '';

    const parsedDate = parseDate(date, dateFormat);

    if (isToday(parsedDate)) return i18next.t('Today');
    if (isYesterday(parsedDate)) return i18next.t('Yesterday');
    return formatDate(parsedDate, DATE_FORMATS.TITLE);
};

export const getFilterOptions = () => [
    {
        label: i18next.t('All notifications'),
        value: 'all',
    },
    {
        label: i18next.t('Reads'),
        value: true,
    },
    {
        label: i18next.t('Unread'),
        value: false,
    },
];

export const getNotificationTypesOptions = () => [
    {
        label: i18next.t('All text'),
        value: 'all',
    },
    {
        label: i18next.t('Account'),
        value: 0,
    },
    {
        label: i18next.t('Project', { count: 2 }),
        value: 1,
    },
    {
        label: i18next.t('Contact'),
        value: 2,
    },
    {
        label: i18next.t('Financing'),
        value: 3,
    },
];

export const handleClickNotificationBuild = (
    handleClickMarkAsReaded,
    notification,
    redirect,
    setIsOpenDrawer = false
) => {
    const { id, viewed } = notification;
    if (setIsOpenDrawer) setIsOpenDrawer(false);
    handleClickMarkAsReaded({ id, viewed });
    if (getIsLocalRedirect(notification.web_url)) {
        redirect(notification.web_url);
    } else {
        window.open(notification.web_url, '_black');
    }
};
