import React from 'react';

import BarChartIcon from '@mui/icons-material/BarChart';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
    Box,
    FormControl,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
} from 'sunwise-ui';

import MultiSelect from 'common/components/MultiSelect';
import { DAYS_NAMES } from 'common/constants';
import { getDayLabel } from 'common/utils/helpers';

import ChartVisibilityControls from './ChartVisibilityControls';

const ChartControls = ({
    chartRef,
    chartType,
    chartTypeOptions,
    defaultVisibility,
    handleOnChangePeriod,
    handleOnChangeSelectedDays,
    handleOnChangeSelectedWeeks,
    hideChangeTypeButtons = false,
    monthSelector = null,
    onChangeVisibility,
    selectMultipleDays = false,
    selectMultipleWeeks = false,
    selectedDays,
    selectedOption,
    selectedWeeks,
    series,
    setChartType = () => {},
    showPeriodSelectors = true,
    showVisibilityControls = false,
    weeksToSelect = 4,
}) => {
    const { t } = useTranslation();
    return (
        <Box display="flex" alignItems="center" justifyContent="space-between">
            <Box
                display={showPeriodSelectors ? 'flex' : 'none'}
                flexWrap="wrap"
                gap="16px"
            >
                <FormControl size="small">
                    <InputLabel>{t('Option')}</InputLabel>
                    <Select
                        label={t('Option')}
                        onChange={handleOnChangePeriod}
                        value={selectedOption}
                    >
                        <MenuItem value={0}>{t('Yearly')}</MenuItem>
                        <MenuItem value={1}>{t('Monthly')}</MenuItem>
                        <MenuItem value={2}>{t('Weekly')}</MenuItem>
                        <MenuItem value={3}>{t('Daily')}</MenuItem>
                    </Select>
                </FormControl>

                <>{monthSelector}</>

                {[2, 3].includes(selectedOption) && (
                    <MultiSelect
                        allTextValue={t('All text', {
                            context: 'female',
                            count: 2,
                        })}
                        optionsSelected={selectedWeeks}
                        handleOnChangeOptionsSelected={
                            handleOnChangeSelectedWeeks
                        }
                        multiple={selectMultipleWeeks}
                        label={t('Week', { count: 2 })}
                        options={[...Array(weeksToSelect).keys()].map(
                            (index) => `${t('Week')} ${index + 1}`
                        )}
                    />
                )}
                {selectedOption === 3 && (
                    <MultiSelect
                        allTextValue={t('All text', { count: 2 })}
                        optionsSelected={selectedDays}
                        handleOnChangeOptionsSelected={
                            handleOnChangeSelectedDays
                        }
                        multiple={selectMultipleDays}
                        label={t('Day', { count: 2 })}
                        options={DAYS_NAMES.map((day) => getDayLabel(day))}
                    />
                )}
            </Box>

            <Box flex={1} textAlign="right">
                {!hideChangeTypeButtons && (
                    <>
                        <IconButton
                            disabled={chartType === chartTypeOptions[0]}
                            onClick={() => setChartType(chartTypeOptions[0])}
                        >
                            <ShowChartIcon />
                        </IconButton>
                        <IconButton
                            disabled={chartType === chartTypeOptions[1]}
                            onClick={() => setChartType(chartTypeOptions[1])}
                        >
                            <BarChartIcon />
                        </IconButton>
                    </>
                )}

                {showVisibilityControls && (
                    <ChartVisibilityControls
                        chartRef={chartRef}
                        defaultVisibility={defaultVisibility}
                        onChangeVisibility={onChangeVisibility}
                        series={series}
                    />
                )}
            </Box>
        </Box>
    );
};

ChartControls.propTypes = {
    chartRef: PropTypes.object,
    chartType: PropTypes.string,
    chartTypeOptions: PropTypes.array,
    defaultVisibility: PropTypes.array,
    handleOnChangePeriod: PropTypes.func,
    handleOnChangeSelectedDays: PropTypes.func,
    handleOnChangeSelectedWeeks: PropTypes.func,
    hideChangeTypeButtons: PropTypes.bool,
    monthSelector: PropTypes.element,
    onChangeVisibility: PropTypes.func,
    selectMultipleDays: PropTypes.bool,
    selectMultipleWeeks: PropTypes.bool,
    selectedDays: PropTypes.array,
    selectedOption: PropTypes.number,
    selectedWeeks: PropTypes.array,
    series: PropTypes.array,
    setChartType: PropTypes.func,
    showPeriodSelectors: PropTypes.bool,
    showVisibilityControls: PropTypes.bool,
    weeksToSelect: PropTypes.number,
};

export default ChartControls;
