import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Container as MaterialContainer, Grid } from 'sunwise-ui';

import { HeaderContainer, TitleIcon } from 'common/components';
import { PERMISSION_LIST } from 'common/constants/permissionsV2';
import withPermissions from 'common/hocs/withPermissions';

import DataForm from './components/DataForm';

const Container = ({ canModify, canView }) => {
    const { t } = useTranslation();

    if (!canView) return null;

    return (
        <MaterialContainer maxWidth={false}>
            <HeaderContainer>
                <Grid item xs>
                    <TitleIcon
                        fontWeight="bold"
                        title={t('General information')}
                    />
                </Grid>
            </HeaderContainer>
            <DataForm canModify={canModify} />
        </MaterialContainer>
    );
};

Container.propTypes = {
    canModify: PropTypes.bool,
    canView: PropTypes.bool,
};

export default withPermissions(PERMISSION_LIST.GENERAL_COMPANY_INFO_PERMISSION)(
    Container
);
