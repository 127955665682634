import React from 'react';

import AddIcon from '@mui/icons-material/Add';
import UploadIcon from '@mui/icons-material/Upload';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import Chart from 'react-apexcharts';
import { useTranslation } from 'react-i18next';
import { Box, Card } from 'sunwise-ui';

import DropdownButton from 'common/components/DropdownButton';

const StyledBox = styled(Box)`
    filter: url('#blur');
    filter: blur(3px);

    &:after {
        background-color: rgb(0 0 0 / 25%);
        bottom: 0;
        content: '';
        display: block;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        z-index: 2;
    }
`;

const dummyChartConfig = {
    options: {
        chart: { background: '#ffffff00', toolbar: { show: false } },
        dataLabels: { enabled: false },
        fill: { opacity: 1 },
        legend: { show: false },
        plotOptions: { bar: { horizontal: false } },
        stroke: { colors: ['transparent'], show: true, width: 2 },
        xaxis: { categories: new Array(12).fill('---') },
    },
    series: [
        {
            data: [44, 55, 57, 56, 61, 58, 63, 60, 66, 44, 55, 57],
            name: '',
        },
        {
            data: [76, 85, 101, 98, 87, 105, 91, 114, 94, 76, 85, 101],
            name: '',
        },
    ],
};

const NoDataIntake = ({
    canModify,
    handleOpenImportConsumptionsModal,
    onClick,
}) => {
    const { t } = useTranslation();
    return (
        <Card sx={{ mb: 2, position: 'relative' }}>
            <StyledBox>
                <Card.Body>
                    <Chart
                        height="150"
                        options={dummyChartConfig.options}
                        series={dummyChartConfig.series}
                        type="bar"
                    />
                </Card.Body>
            </StyledBox>

            {canModify && (
                <Box
                    sx={{
                        alignItems: 'center',
                        display: 'flex',
                        gap: 1,
                        height: '100%',
                        justifyContent: 'center',
                        left: 0,
                        maxWidth: { xs: '100%', md: '500px' },
                        mx: 'auto',
                        p: 2,
                        position: 'absolute',
                        right: 0,
                        top: 0,
                    }}
                >
                    <DropdownButton
                        options={[
                            {
                                icon: <AddIcon />,
                                label: t('Add consumption', { count: 2 }),
                                onClick: onClick,
                            },
                            {
                                icon: <UploadIcon />,
                                label: t('Import project'),
                                onClick: handleOpenImportConsumptionsModal,
                            },
                        ]}
                    />
                </Box>
            )}
        </Card>
    );
};

NoDataIntake.propTypes = {
    canModify: PropTypes.bool,
    handleOpenImportConsumptionsModal: PropTypes.func,
    onClick: PropTypes.func,
};

export default NoDataIntake;
