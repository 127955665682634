import React from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Card } from 'sunwise-ui';

import * as lisaFilesActions from 'common/modules/lisaFiles/actions';
import { getIsGDRate } from 'common/utils/helpers/rates';

import * as actions from '../../../actions';
import { getMinInitialDate } from '../../../helpers';
import PeriodSelector from '../../PeriodSelector';

import IntakeCaptureField from './IntakeCaptureField';

const Container = ({
    control,
    disabled,
    disabledConsumptionFieldsByCsv,
    errors,
    getValues,
    handleDownloadLisaFile,
    handleNormalizeDates,
    handleUploadPDFReciptFile,
    profilesConsumptionData,
    rateConfiguration,
    ratesDictionary,
    selectedPeriod,
    selectedRate,
    setSelectedPeriod,
    setValue,
    summary,
    summaryWarnings,
}) => {
    if (!selectedRate) return null;

    const isGDRate = getIsGDRate(selectedRate);
    const showAvgDailyField =
        !selectedRate.isCertified && selectedRate.periodicityType === '0';

    return (
        <Card sx={{ overflow: 'visible' }}>
            <Card.Body>
                <PeriodSelector
                    errors={errors}
                    selectedPeriod={selectedPeriod}
                    setSelectedPeriod={setSelectedPeriod}
                    summary={summary}
                    summaryWarnings={summaryWarnings}
                />
                <IntakeCaptureField
                    control={control}
                    disabled={disabled}
                    disabledConsumptionFieldsByCsv={
                        disabledConsumptionFieldsByCsv
                    }
                    getValues={getValues}
                    handleDownloadLisaFile={handleDownloadLisaFile}
                    handleNormalizeDates={handleNormalizeDates}
                    handleUploadPDFReciptFile={handleUploadPDFReciptFile}
                    index={selectedPeriod}
                    isGDRate={isGDRate}
                    key={`intake-capture-field-${selectedPeriod}`}
                    maxDate={new Date()}
                    minDate={getMinInitialDate()}
                    name={`summary.${selectedPeriod}`}
                    period={summary?.[selectedPeriod] ?? {}}
                    periodWarnings={summaryWarnings[selectedPeriod]}
                    profilesConsumptionData={profilesConsumptionData}
                    rateConfiguration={rateConfiguration}
                    ratesDictionary={ratesDictionary}
                    setValue={setValue}
                    showAvgDailyField={showAvgDailyField}
                />
            </Card.Body>
        </Card>
    );
};

Container.propTypes = {
    control: PropTypes.object,
    disabled: PropTypes.bool,
    disabledConsumptionFieldsByCsv: PropTypes.bool,
    errors: PropTypes.object,
    getValues: PropTypes.func,
    handleDownloadLisaFile: PropTypes.func,
    handleNormalizeDates: PropTypes.func,
    handleUploadPDFReciptFile: PropTypes.func,
    profilesConsumptionData: PropTypes.array,
    rateConfiguration: PropTypes.object,
    ratesDictionary: PropTypes.object,
    selectedPeriod: PropTypes.number,
    selectedRate: PropTypes.object,
    setSelectedPeriod: PropTypes.func,
    setValue: PropTypes.func,
    summary: PropTypes.array,
    summaryWarnings: PropTypes.array,
};

const mapDispatchToProps = (dispatch) => ({
    handleDownloadLisaFile: (file_name) =>
        dispatch(lisaFilesActions.downloadFileFromName(file_name)),
    handleUploadPDFReciptFile: (config = {}) =>
        dispatch(actions.handleUploadPDFReciptFile(config)),
});

export default connect(null, mapDispatchToProps)(Container);
