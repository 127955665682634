import { getItems } from 'common/api/v2/consumptionProfiles';

import {
    FETCH_CONSUMPTION_PROFILES,
    FETCH_CONSUMPTION_PROFILES_FAILURE,
    FETCH_CONSUMPTION_PROFILES_SUCCESS,
} from '../actionTypes';
import { projectConsumptionModalActions } from '../reducer';

export default () => (dispatch) => {
    dispatch(projectConsumptionModalActions[FETCH_CONSUMPTION_PROFILES]());

    getItems()
        .then((response) => {
            const data = response.data.data;
            dispatch(
                projectConsumptionModalActions[
                    FETCH_CONSUMPTION_PROFILES_SUCCESS
                ](data)
            );
        })
        .catch((error) => {
            dispatch(
                projectConsumptionModalActions[
                    FETCH_CONSUMPTION_PROFILES_FAILURE
                ](error)
            );
        });
};
