import {
    FILTER_CONSUMPTION_PROFILES,
    FILTER_CONSUMPTION_PROFILES_FAILURE,
    FILTER_CONSUMPTION_PROFILES_SUCCESS,
} from '../actionTypes';
import { ROWS_PER_PAGE } from '../constants';
import { getDataFiltered } from '../helpers';
import { consumptionProfilesActions } from '../reducer';
import * as selectors from '../selectors';

export default (params) => (dispatch, getState) => {
    dispatch(consumptionProfilesActions[FILTER_CONSUMPTION_PROFILES]());

    setTimeout(() => {
        try {
            const state = getState();
            const data = selectors.getFetchConsumptionProfilesData(state);
            const dataFiltered = getDataFiltered({ data, ...params });
            dispatch(
                consumptionProfilesActions[FILTER_CONSUMPTION_PROFILES_SUCCESS](
                    {
                        hasNextPage: dataFiltered.length > ROWS_PER_PAGE,
                        consumptionProfiles: dataFiltered.slice(
                            0,
                            ROWS_PER_PAGE
                        ),
                    }
                )
            );
        } catch (error) {
            dispatch(
                consumptionProfilesActions[FILTER_CONSUMPTION_PROFILES_FAILURE](
                    error
                )
            );
        }
    }, 300);
};
