import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';

import { generatePortalSession } from 'common/api/v1/chargebee';

const WithChargebee = (props) => {
    const { Children, injectedProp } = props;
    const [cbInstance, setCbInstance] = useState(null);

    useEffect(() => {
        if (process.env.REACT_APP_CHARGEBEE_KEY) {
            setCbInstance(
                window.Chargebee.init({
                    site: process.env.REACT_APP_CHARGEBEE_KEY,
                })
            );
        }
    }, []);

    useEffect(() => {
        if (cbInstance === null) return;
        cbInstance.setPortalSession(() =>
            generatePortalSession().then((response) => response.data)
        );
    }, [cbInstance]);

    return <Children {...injectedProp} cbInstance={cbInstance} />;
};

WithChargebee.propTypes = {
    Children: PropTypes.node,
    injectedProp: PropTypes.object,
    permissionCode: PropTypes.oneOfType([PropTypes.number, PropTypes.array]),
};

const WithChargebeeHOC = () => (Children) => (props) =>
    WithChargebee({
        injectedProp: props,
        Children,
    });

export default WithChargebeeHOC;
