import i18next from 'i18next';

import { updateItem } from 'common/api/v1/energyBackupCompany';
import { showReponseErrorsAsAlert } from 'common/utils/helpers';
import showToast from 'common/utils/showToast';

import {
    SAVE_ITEM,
    SAVE_ITEM_FAILURE,
    SAVE_ITEM_SUCCESS,
} from '../actionTypes';
import { energyBackupConfigurationActions } from '../reducer';

import closeModalProduct from './closeModalProduct';

export default (filterData, values) => (dispatch) => {
    if (!values.id) {
        showToast({
            body: i18next.t(
                'There was an error and could not complete the process'
            ),
            type: 'danger',
        });

        return;
    }

    const newValues = {
        code: values.code,
        cost: values.cost || 0,
        currency: values.currency,
        description: values.description,
        final_cost: values.final_cost || 0,
        margin: values.margin || 0,
        name: values.name,
        product_type: values.product_type,
    };

    dispatch(energyBackupConfigurationActions[SAVE_ITEM]());

    updateItem(newValues, values.id)
        .then(() => {
            dispatch(energyBackupConfigurationActions[SAVE_ITEM_SUCCESS]());
            dispatch(closeModalProduct(filterData));
            showToast();
        })
        .catch((error) => {
            dispatch(
                energyBackupConfigurationActions[SAVE_ITEM_FAILURE](
                    error?.response?.data?.errors
                )
            );
            showReponseErrorsAsAlert(dispatch, error?.response);
        });
};
