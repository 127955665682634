import get from 'lodash/get';

import { getByStateAndSources } from 'common/api/v1/irradiations';

import {
    FETCH_IRRADIATION_BY_STATE_AND_SOURCE,
    FETCH_IRRADIATION_BY_STATE_AND_SOURCE_FAILURE,
    FETCH_IRRADIATION_BY_STATE_AND_SOURCE_SUCCESS,
} from '../actionTypes';
import { solarGenerationActions } from '../reducer';

export default (id, politicalDivision, setValue) => (dispatch) => {
    dispatch(solarGenerationActions[FETCH_IRRADIATION_BY_STATE_AND_SOURCE]());

    const values = {
        political_division1: politicalDivision,
        political_division2: id,
        source_list: '[0, 1, 2, 3, 4, 5]',
    };

    return getByStateAndSources(values)
        .then((response) => {
            const sourceDictionary = response.data.data.reduce(
                (acc, source) => {
                    acc[`source_${source.source}`] = source;
                    return acc;
                },
                {}
            );

            setValue(
                'saved_by',
                get(sourceDictionary, 'source_3.saved_by', 'city')
            );

            setValue(
                'keep_or_delete',
                get(sourceDictionary, 'source_3.keep_or_delete', 'keep')
            );

            dispatch(
                solarGenerationActions[
                    FETCH_IRRADIATION_BY_STATE_AND_SOURCE_SUCCESS
                ](response.data.data)
            );
        })
        .catch((error) =>
            dispatch(
                solarGenerationActions[
                    FETCH_IRRADIATION_BY_STATE_AND_SOURCE_FAILURE
                ](error?.response?.data?.errors)
            )
        );
};
