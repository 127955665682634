import { addRpu, disconnectSocket } from 'common/modules/zap/actions';
import { showReponseErrorsAsAlert } from 'common/utils/helpers';

import {
    ADD_ZAP_RPU,
    ADD_ZAP_RPU_FAILURE,
    ADD_ZAP_RPU_SUCCESS,
} from '../actionTypes';
import { projectConsumptionModalActions } from '../reducer';

import setZapLoading from './setZapLoading';

export default (values) => (dispatch) => {
    if (!values) return;

    dispatch(projectConsumptionModalActions[ADD_ZAP_RPU]());
    dispatch(setZapLoading(true));

    const newValues = {
        cfeUser: values.cfe_user,
        credentialId: values.credential_id,
        serviceNumber: values.service_number,
        lastPayment: values.last_payment,
    };

    dispatch(addRpu(newValues))
        .then((response) =>
            dispatch(
                projectConsumptionModalActions[ADD_ZAP_RPU_SUCCESS](
                    response?.data
                )
            )
        )
        .catch((error) => {
            dispatch(
                projectConsumptionModalActions[ADD_ZAP_RPU_FAILURE](error)
            );
            dispatch(disconnectSocket());
            dispatch(setZapLoading(false));

            showReponseErrorsAsAlert(dispatch, error?.response);
        });
};
