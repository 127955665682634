import { getNextValidRates } from 'common/api/v2/rate';

import {
    FETCH_COMPATIBLE_RATES,
    FETCH_COMPATIBLE_RATES_SUCCESS,
    FETCH_COMPATIBLE_RATES_FAILURE,
} from '../actionTypes';
import { analysisVsGenerationActions } from '../reducer';

export default (proposalId) => (dispatch) => {
    dispatch(analysisVsGenerationActions[FETCH_COMPATIBLE_RATES]());

    getNextValidRates(proposalId)
        .then((response) => {
            dispatch(
                analysisVsGenerationActions[FETCH_COMPATIBLE_RATES_SUCCESS](
                    response.data.data
                )
            );
        })
        .catch((error) => {
            dispatch(
                analysisVsGenerationActions[FETCH_COMPATIBLE_RATES_FAILURE](
                    error?.response?.data?.errors
                )
            );
        });
};
