import React, { useState } from 'react';

import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Paper from '@mui/material/Paper';
import TableBody from '@mui/material/TableBody';
import i18next from 'i18next';
import PropTypes from 'prop-types';
import {
    Button,
    Menu,
    MenuItem,
    Table,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from 'sunwise-ui';

import {
    getDecimals,
    getCurrencyIso,
    getCurrencySymbol,
    numberFormat,
} from 'common/utils/helpers';
import { getCountryCurrencyLocale } from 'common/utils/helpers/session';

const iconUniquePrice = (isUniquePrice) =>
    isUniquePrice ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />;

const PriceRangeDropDown = ({ cell, row }) => {
    const [anchorEl, setAnchorEl] = useState(null);

    const currencyIso = getCurrencyIso(row.currency);
    const countryCurrencyLocale = getCountryCurrencyLocale();
    const currencySymbol = getCurrencySymbol(row.currency);
    const rangePrices = cell;
    const open = Boolean(anchorEl);

    const handleClick = (event) => setAnchorEl(event.currentTarget);
    const handleClose = () => setAnchorEl(null);

    if (rangePrices.length === 1)
        return numberFormat(rangePrices[0].final_cost, {
            currency: currencyIso,
            locale: countryCurrencyLocale,
            style: 'currency',
            symbol: currencySymbol,
            decimals: getDecimals(rangePrices[0].final_cost),
        });

    return (
        <>
            <Button
                id={row.id}
                endIcon={<KeyboardArrowDownIcon />}
                onClick={handleClick}
                variant="outlined"
            >
                {i18next.t('View range')}
            </Button>

            <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
                <MenuItem>
                    <TableContainer component={Paper}>
                        <Table size="small" sx={{ minWidth: 650 }}>
                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                        {i18next.t('Product', { count: 2 })}
                                    </TableCell>
                                    <TableCell align="center">
                                        {i18next.t('Cost', { count: 2 })}{' '}
                                        {currencyIso}
                                    </TableCell>
                                    <TableCell align="center">
                                        {i18next.t('Price', { count: 2 })}{' '}
                                        {currencyIso}
                                    </TableCell>
                                    <TableCell align="center">
                                        {i18next.t('Single price')}
                                    </TableCell>
                                    <TableCell align="center">
                                        {i18next.t('Margin')}
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {rangePrices.map((range) => (
                                    <TableRow key={range.id}>
                                        <TableCell component="th" scope="row">
                                            {`${i18next.t(
                                                'From'
                                            )} ${numberFormat(
                                                range.price_from,
                                                {
                                                    decimals: 0,
                                                    locale: countryCurrencyLocale,
                                                    style: 'decimal',
                                                }
                                            )} ${i18next
                                                .t('To')
                                                .toLowerCase()} ${numberFormat(
                                                range.price_to,
                                                {
                                                    decimals: 0,
                                                    locale: countryCurrencyLocale,
                                                    style: 'decimal',
                                                }
                                            )}`}
                                        </TableCell>
                                        <TableCell align="center">
                                            {numberFormat(range.cost, {
                                                currency: currencyIso,
                                                decimals: getDecimals(
                                                    range.cost
                                                ),
                                                locale: countryCurrencyLocale,
                                                style: 'currency',
                                                symbol: currencySymbol,
                                            })}
                                        </TableCell>
                                        <TableCell align="center">
                                            {numberFormat(range.final_cost, {
                                                currency: currencyIso,
                                                decimals: getDecimals(
                                                    range.final_cost
                                                ),
                                                locale: countryCurrencyLocale,
                                                style: 'currency',
                                                symbol: currencySymbol,
                                            })}
                                        </TableCell>
                                        <TableCell align="center">
                                            {iconUniquePrice(
                                                range.is_unique_price
                                            )}
                                        </TableCell>
                                        <TableCell align="center">
                                            {range.margin}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </MenuItem>
            </Menu>
        </>
    );
};

PriceRangeDropDown.propTypes = {
    cell: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    row: PropTypes.object,
};

const PriceRangeDropDownFormatter = (cell, row) => (
    <PriceRangeDropDown cell={cell} row={row} />
);

export default PriceRangeDropDownFormatter;
