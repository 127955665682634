import API from '../..';
const ENTITY = 'companies';

export const getBillingInformation = () =>
    API.get(`/api/v1/${ENTITY}/billing-information/`);
export const getMyCompany = () => API.get(`/api/v1/${ENTITY}/me/`);
export const getPaymentSources = () =>
    API.get(`/api/v1/${ENTITY}/payment-sources/`);
export const getSubscription = () => API.get(`/api/v1/${ENTITY}/subscription/`);
export const updateItem = (data, id) =>
    API.put(`/api/v1/${ENTITY}/${id}`, { ...data });
