import projectConsumptionsModal from '../../projectConsumptionsModal';

import saveProject from './saveProject';

export default (values, callbackSuccess) => (dispatch) => {
    dispatch(
        saveProject(values, callbackSuccess, (data) => {
            dispatch(
                projectConsumptionsModal.actions.prepareConsumptions({
                    isFromCreate: true,
                    isOpenModal: true,
                    projectData: data,
                })
            );
        })
    );
};
