import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Grid } from 'sunwise-ui';

import { ReactHookFormIntlNumberInput } from 'common/components/form/bootstrap';

const MarginColumn = ({
    canModify,
    canModifyMargin,
    control,
    formValues,
    handleNormalizeFields,
    handleNormalizeMarginField,
    index,
    initialValues,
    isDisabledField,
    isHidden,
    name,
    productItem,
    size,
}) => {
    const { t } = useTranslation();

    if (isHidden) return null;

    const isDisabledMargin = isDisabledField || !canModifyMargin;

    if (canModifyMargin || (!canModify && canModifyMargin)) {
        return (
            <Grid item xs={18} lg={size.margin[0]}>
                <ReactHookFormIntlNumberInput
                    allowNegativeValue={false}
                    append="%"
                    control={control}
                    disabled={isDisabledMargin}
                    fullWidth
                    label={t('Margin')}
                    max={99}
                    min={0}
                    name={`${name}.${index}.margin`}
                    onChange={({ target: { value } }) =>
                        handleNormalizeMarginField(
                            handleNormalizeFields,
                            index,
                            productItem
                        )(
                            value,
                            initialValues?.[name]?.[index]?.margin,
                            formValues,
                            name
                        )
                    }
                    step="any"
                    variant="standard"
                />
            </Grid>
        );
    }
    return null;
};

MarginColumn.propTypes = {
    canModify: PropTypes.bool,
    canModifyMargin: PropTypes.bool,
    control: PropTypes.object,
    formValues: PropTypes.object,
    handleNormalizeFields: PropTypes.func,
    handleNormalizeMarginField: PropTypes.func,
    index: PropTypes.number,
    initialValues: PropTypes.object,
    isDisabledField: PropTypes.bool,
    isHidden: PropTypes.bool,
    name: PropTypes.string,
    productItem: PropTypes.object,
    size: PropTypes.object,
};

export default MarginColumn;
