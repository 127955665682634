import React, { useEffect } from 'react';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { get, isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Alert, Box, Button, Chip, TextField, Typography } from 'sunwise-ui';

import { ReactHookFormInput } from 'common/components/form/bootstrap';
import ShowErrors from 'common/components/ShowErrors';
import yupResolver from 'common/utils/yupResolver';

import * as actions from '../actions';
import {
    getIsDisabled,
    getIsPassed,
    getNoAttempts,
    getStatusButtonProps,
    getStatusLabel,
    upperRFCFieldValue,
} from '../helpers';
import * as selectors from '../selectors';
import validate from '../validate';

const CreditBureauForm = ({
    creditItem,
    creditStatus,
    errorsOnSave,
    fetchBureauData,
    hasAuthorization,
    initialValues,
    isArchived,
    isEnabledEdition,
    isFetching,
    isSaving,
    offerId,
    prepareSave,
    projectId,
    proposalId,
    selectCreditItem,
    setIsEnabledEdition,
    status,
}) => {
    const { t } = useTranslation();

    const { control, getValues, handleSubmit, reset, setValue } = useForm({
        defaultValues: initialValues,
        resolver: yupResolver(validate),
    });

    useEffect(() => reset(initialValues), [initialValues]);

    const handleCallback = (offers) => {
        const offerItem = offers.find((item) => item.id === proposalId);
        if (offerItem && !isEmpty(creditItem)) {
            const commercialOffer = get(offerItem, 'commercial_offer[0]', {});
            selectCreditItem({
                ...creditItem,
                hasRequestBureau: get(
                    commercialOffer,
                    'has_request_buro',
                    false
                ),
            });
        }
        setIsEnabledEdition(false);
    };

    const handleOnSave = (data) => {
        return prepareSave(
            (offers) => handleCallback(offers),
            isArchived,
            offerId,
            projectId,
            { ...data, reference: offerId }
        );
    };

    const records = get(fetchBureauData, 'records', { current: 0, limit: 1 });
    const isPassed = getIsPassed(status);
    const isDisabled = getIsDisabled(
        creditStatus,
        isFetching,
        isPassed,
        isSaving,
        records
    );

    if (isEnabledEdition) {
        return (
            <form onSubmit={handleSubmit(handleOnSave)}>
                <Box sx={{ mb: 2 }}>
                    <Typography variant="body2">
                        {t('Request credit information to')}
                    </Typography>
                </Box>

                <ReactHookFormInput
                    control={control}
                    disabled={isDisabled}
                    label={t('Name')}
                    name="first_name"
                />

                <ReactHookFormInput
                    control={control}
                    disabled={isDisabled}
                    label={t('Middle name')}
                    name="second_name"
                />

                <ReactHookFormInput
                    control={control}
                    disabled={isDisabled}
                    label={t('Last name')}
                    name="first_last_name"
                />

                <ReactHookFormInput
                    control={control}
                    disabled={isDisabled}
                    label={t('Second last name')}
                    name="second_last_name"
                />

                <ReactHookFormInput
                    control={control}
                    disabled={isDisabled}
                    label="RFC"
                    name="rfc"
                    onChange={({ target }) =>
                        upperRFCFieldValue(setValue, target.value)
                    }
                />

                <ReactHookFormInput
                    control={control}
                    disabled={isDisabled}
                    label={t('Email')}
                    name="email"
                />

                <ReactHookFormInput
                    control={control}
                    disabled={isDisabled}
                    label={t('Street')}
                    name="street"
                />

                <ReactHookFormInput
                    control={control}
                    disabled={isDisabled}
                    label={t('Suburb')}
                    name="suburb"
                />

                <ReactHookFormInput
                    control={control}
                    disabled={isDisabled}
                    label={t('City')}
                    name="city"
                />

                <ReactHookFormInput
                    control={control}
                    disabled={isDisabled}
                    label={t('State')}
                    name="state"
                />

                <ReactHookFormInput
                    control={control}
                    disabled={isDisabled}
                    label={t('Zip code')}
                    name="zipcode"
                />

                {errorsOnSave && <ShowErrors errors={errorsOnSave} />}

                {getNoAttempts(records) && (
                    <Box my={2}>
                        <Alert severity="warning">
                            <Typography variant="caption" fontWeight="bold">
                                {t(
                                    'You have exceeded the number of queries allowed per proposal'
                                )}
                            </Typography>
                        </Alert>
                    </Box>
                )}

                <Button disabled={isDisabled} fullWidth type="submit">
                    <strong>{t('Send')}</strong>
                </Button>
            </form>
        );
    }

    return (
        <Box>
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 1,
                    mb: 2,
                }}
            >
                <CheckCircleIcon fontSize="small" sx={{ color: '#26f2ad' }} />
                <Typography variant="body2">{t('Request sent to')}</Typography>
            </Box>
            <Box mb={2}>
                {getValues('email') && (
                    <TextField
                        fullWidth
                        disabled
                        label={t('Email')}
                        variant="outlined"
                        value={getValues('email')}
                    />
                )}
            </Box>
            <Chip
                className="__userguiding_credit_bureau_chip_status"
                label={getStatusLabel(status)}
                sx={{
                    width: '100%',
                    fontWeight: 600,
                    ...getStatusButtonProps(status),
                }}
            />
            {!hasAuthorization && (
                <Box my={2}>
                    <Alert severity="info">
                        <Typography variant="caption" fontWeight="bold">
                            {t(
                                'Make sure the client authorizes the process via email'
                            )}
                        </Typography>
                    </Alert>
                </Box>
            )}
        </Box>
    );
};

const mapStateToProps = createStructuredSelector({
    errorsOnSave: selectors.getSaveErrors,
    fetchBureauData: selectors.getFetchData,
    initialValues: selectors.getInitialValues,
    isFetching: selectors.getIsFetching,
    isSaving: selectors.getIsSaving,
});

const mapDispatchToProps = (dispatch) => ({
    prepareSave: (callback, isArchivedFilter, offerId, projectId, values) =>
        dispatch(
            actions.prepareSave(
                callback,
                isArchivedFilter,
                offerId,
                projectId,
                values
            )
        ),
});

CreditBureauForm.propTypes = {
    creditItem: PropTypes.object,
    errorsOnSave: PropTypes.array,
    fetchBureauData: PropTypes.object,
    hasAuthorization: PropTypes.bool,
    initialValues: PropTypes.object,
    isArchived: PropTypes.bool,
    isEnabledEdition: PropTypes.bool,
    isFetching: PropTypes.bool,
    isSaving: PropTypes.bool,
    offerId: PropTypes.string,
    prepareSave: PropTypes.func,
    projectId: PropTypes.string,
    proposalId: PropTypes.string,
    selectCreditItem: PropTypes.func,
    setIsEnabledEdition: PropTypes.func,
    creditStatus: PropTypes.number,
    status: PropTypes.object,
};

export default connect(mapStateToProps, mapDispatchToProps)(CreditBureauForm);
