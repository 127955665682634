import { updateTemplate } from 'common/api/v2/offerComplements';
import { showReponseErrorsAsAlert } from 'common/utils/helpers';

import { SAVE, SAVE_FAILURE, SAVE_SUCCESS } from '../actionTypes';
import { proposalGeneratorTemplateActions } from '../reducer';

import generateProposalPreview from './generateProposalPreview';

const saveTemplate = (proposalId, values) => (dispatch) => {
    const newValues = { custom_offer_template: values.custom_offer_template };

    dispatch(proposalGeneratorTemplateActions[SAVE]());

    updateTemplate(newValues, proposalId)
        .then((response) => {
            dispatch(
                proposalGeneratorTemplateActions[SAVE_SUCCESS](
                    response.data.data
                )
            );
            dispatch(generateProposalPreview(proposalId));
        })
        .catch((error) => {
            dispatch(
                proposalGeneratorTemplateActions[SAVE_FAILURE](
                    error?.response?.data?.errors
                )
            );
            showReponseErrorsAsAlert(dispatch, error?.response);
        });
};

export default saveTemplate;
