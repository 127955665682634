import React from 'react';

import PropTypes from 'prop-types';
import { Stack } from 'sunwise-ui';

import { numberFormat } from 'common/utils/helpers';

import { getIndicatorCurrencyValue } from '../helpers';

import Indicator from './Indicator';

const IndicatorsContent = ({
    batteriesOfferValues,
    compensationScheme,
    currencyIso,
    currencyLocale,
    grossStoragePrice,
    indicatorsSettingsData,
    isLoading,
    maxDailyConsumption,
    netStoragePrice,
    nextRateData,
    offerDetailsComplements,
    offerDetailsConsumption,
    proposalConfiguration,
    totalsSolarSimulation,
}) => {
    if (!indicatorsSettingsData?.length) return null;

    const getCurrencyDataByValue = (value, currency) => {
        const indicatorCurrency = currency || 'USD';
        const indicatorCurrencyValue = getIndicatorCurrencyValue({
            indicatorCurrency,
            proposalCurrency: currencyIso,
            typeChange: offerDetailsComplements?.type_change ?? 1,
            value,
        });
        return { indicatorCurrency, indicatorCurrencyValue };
    };

    const getIndicatorDecimalValue = (value) => {
        if (!value) return '- -';

        return numberFormat(value, {
            decimals: 2,
            locale: currencyLocale,
            style: 'decimal',
        });
    };

    return (
        <Stack spacing={1}>
            {indicatorsSettingsData?.map(
                (indicator) =>
                    indicator.is_active && (
                        <Indicator
                            annualSaving={
                                offerDetailsConsumption?.annual_saving
                            }
                            annualSavingPercentage={
                                offerDetailsComplements?.annual_saving_percentage
                            }
                            batteriesOfferValues={batteriesOfferValues}
                            compensationScheme={compensationScheme}
                            currencyIso={currencyIso}
                            currencyLocale={currencyLocale}
                            energySaving={offerDetailsConsumption?.energy}
                            getCurrencyDataByValue={getCurrencyDataByValue}
                            getIndicatorDecimalValue={getIndicatorDecimalValue}
                            grossStoragePrice={grossStoragePrice}
                            indicator={indicator}
                            isLoading={isLoading}
                            key={indicator.id}
                            localCurrency={
                                offerDetailsComplements?.local_currency
                                    ?.currency__abbreviation
                            }
                            maxDailyConsumption={maxDailyConsumption}
                            netStoragePrice={netStoragePrice}
                            nextRateData={nextRateData}
                            originalConsumption={
                                offerDetailsConsumption?.original_consumption
                            }
                            priceByWatt={offerDetailsComplements?.price_watt}
                            priceWattLocal={
                                offerDetailsComplements?.price_watt_local
                            }
                            proposalConfiguration={proposalConfiguration}
                            returnInvestment={
                                offerDetailsConsumption?.return_of_investment
                            }
                            systemGeneration={
                                offerDetailsConsumption?.system_generation
                            }
                            systemSize={offerDetailsConsumption?.system_size}
                            totalWithTaxes={
                                offerDetailsComplements?.total_price_iva
                            }
                            totalWithoutTaxes={
                                offerDetailsComplements?.total_price
                            }
                            totalsSolarSimulation={totalsSolarSimulation}
                            zeroExport={offerDetailsComplements?.zero_export}
                        />
                    )
            )}
        </Stack>
    );
};

IndicatorsContent.defaultProps = {
    annualSaving: 0,
    energySaving: 0,
    grossStoragePrice: 0,
    netStoragePrice: 0,
    originalConsumption: 0,
    priceByWatt: 0,
    returnInvestment: 0,
    systemGeneration: 0,
    systemSize: 0,
    totalWithTaxes: 0,
    totalWithoutTaxes: 0,
    typeChange: 1,
};

IndicatorsContent.propTypes = {
    batteriesOfferValues: PropTypes.object,
    compensationScheme: PropTypes.object,
    currencyIso: PropTypes.string,
    currencyLocale: PropTypes.string,
    grossStoragePrice: PropTypes.number,
    indicatorsSettingsData: PropTypes.array,
    isLoading: PropTypes.bool,
    maxDailyConsumption: PropTypes.number,
    netStoragePrice: PropTypes.number,
    nextRateData: PropTypes.object,
    offerDetailsComplements: PropTypes.object,
    offerDetailsConsumption: PropTypes.object,
    proposalConfiguration: PropTypes.object,
    totalsSolarSimulation: PropTypes.object,
};

export default IndicatorsContent;
