import { generatePreview } from 'common/api/v1/proposals';
import alerts from 'common/modules/alerts';
import { showReponseErrorsAsAlert } from 'common/utils/helpers';

import {
    SET_OFFER_END,
    SET_OFFER_END_FAILURE,
    SET_OFFER_END_SUCCESS,
} from '../actionTypes';
import { proposalGeneratorTemplateActions } from '../reducer';

import redirecToProposalReview from './redirecToProposalReview';

export default (proposalId) => (dispatch) =>
    new Promise((resolve, reject) => {
        dispatch(proposalGeneratorTemplateActions[SET_OFFER_END]());
        generatePreview({ commercial_offer: proposalId })
            .then(() => {
                dispatch(
                    proposalGeneratorTemplateActions[SET_OFFER_END_SUCCESS]()
                );
                dispatch(alerts.actions.disableButtons(false));
                dispatch(alerts.actions.close());
                dispatch(redirecToProposalReview(proposalId));
                resolve();
            })
            .catch((error) => {
                dispatch(
                    proposalGeneratorTemplateActions[SET_OFFER_END_FAILURE](
                        error?.response?.data?.errors
                    )
                );
                reject();
                showReponseErrorsAsAlert(dispatch, error?.response);
            });
    });
