import { createSelector } from 'reselect';

import { parseDate } from 'common/utils/dates';

import { NAME } from './constants';

export const getModel = (state) => state[NAME];

export const getInitialValues = createSelector(
    getModel,
    (model) => model.initialValues
);

/* send message */
export const getSendModel = createSelector(getModel, (model) => model.send);

export const getSendData = createSelector(
    getSendModel,
    (model) => model.data || []
);

export const getSendErrors = createSelector(
    getSendModel,
    (model) => model.errors
);

export const getSendIsFetching = createSelector(
    getSendModel,
    (model) => model.isFetching
);

/* fetch messages */
export const getMessagesModel = createSelector(
    getModel,
    (model) => model.messages
);

export const getMessagesData = createSelector(
    getMessagesModel,
    (model) => model.data || {}
);

export const getMessages = createSelector(getMessagesModel, (model) =>
    model.data.messages
        .map((item) => {
            return {
                id: item.id,
                name: item.name,
                message: item.message,
                image: item.logo,
                created_at: item.created_at,
                updated_at: item.updated_at,
                role: item.sent_from,
                username: item.username,
            };
        })
        .sort(
            (a, b) =>
                parseDate(a.created_at, 'dd/MM/yyyy HH:mm:ss') -
                parseDate(b.created_at, 'dd/MM/yyyy HH:mm:ss')
        )
);

export const getMessagesErrros = createSelector(
    getMessagesModel,
    (model) => model.errors
);

export const getMessagesIsFetching = createSelector(
    getMessagesModel,
    (model) => model.isFetching
);

export const getChatId = createSelector(
    getInitialValues,
    (model) => model.chatId
);

export const getSocketConnected = createSelector(
    getModel,
    (model) => model.socketConnected
);

export const getSocketError = createSelector(
    getModel,
    (model) => model.socketError
);
