import isArray from 'lodash/isArray';

import { saveCompany } from 'common/api/v1/onboarding';
import showToast from 'common/utils/showToast';

import {
    SAVE_COMPANY,
    SAVE_COMPANY_SUCCESS,
    SAVE_COMPANY_FAILURE,
} from '../actionTypes';
import { ONBOARDING_STEPS } from '../constants';
import { onboardingWizardActions } from '../reducer';

import fetchOnboarding from './fetchOnboarding';
import resetErrors from './resetErrors';
import setCurrentStep from './setCurrentStep';
import updateSection from './updateSection';

export default (values, callback) => (dispatch) => {
    let newValues = {
        business_name: values.business_name,
        contact_email: values.contact_email,
        contact_telephone: values.contact_telephone,
        name: values.name,
        rfc: values.rfc,
    };

    if (isArray(values.image)) {
        newValues.image = values.image && values.image[0];
    }

    dispatch(onboardingWizardActions[SAVE_COMPANY]());

    saveCompany(newValues)
        .then((response) => {
            dispatch(
                onboardingWizardActions[SAVE_COMPANY_SUCCESS](response.data)
            );
            dispatch(resetErrors());
            dispatch(setCurrentStep(values?.step + 1));
            dispatch(updateSection(ONBOARDING_STEPS.DETAILS));
            dispatch(fetchOnboarding(false));
            if (callback) callback(ONBOARDING_STEPS.DETAILS);
            showToast();
        })
        .catch((error) => {
            dispatch(
                onboardingWizardActions[SAVE_COMPANY_FAILURE](
                    error?.response?.data?.errors || []
                )
            );
        });
};
