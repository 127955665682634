import React, { useContext, useEffect, useRef, useState } from 'react';

import AddIcon from '@mui/icons-material/Add';
import { styled } from '@mui/material/styles';
import { push } from 'connected-react-router';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { createStructuredSelector } from 'reselect';
import { Box, Button, Divider, Grid, Typography } from 'sunwise-ui';
import useDynamicRefs from 'use-dynamic-refs';

import TemporaryDrawer from 'common/components/TemporaryDrawer';
import { PERMISSION_LIST } from 'common/constants/permissionsV2';
import withPermissions from 'common/hocs/withPermissions';
import { LoadingContext } from 'common/utils/contexts';

import * as projectConsumptionCardSelectors from '../projectConsumptionCard/selectors';
import FilterDropdown from '../projectWorkTabs/components/FilterDropdown';

import * as actions from './actions';
import CommercialOfferModal from './components/CommercialOfferModal';
import DuplicateFormModal from './components/DuplicateFormModal';
import FileReview from './components/FileReview';
import ProposalTable from './components/ProposalTable';
import RequestSideCard from './components/requestSideCard/Container';
import * as selectors from './selectors';

export const TextFilterBy = styled(Typography)`
    font-size: 14px;
    font-style: normal;
    font-weight: bold;
    line-height: 17px;
    margin-bottom: 0;
`;

const Container = ({
    cancelCreditApp,
    canDelete,
    canModify,
    commercialOffers,
    fetchCommercialOffers,
    isDuplicatingOffer,
    isFetchingConsumptionEnergy,
    isFetchingProjectCommercialOffers,
    isSavingOffer,
    prepareCommercialOfferCreate,
    projectId,
    projectName,
    redirect,
    reset,
    resetErrors,
}) => {
    const { t } = useTranslation();
    const loadingContext = useContext(LoadingContext);
    const [creditItemSelected, setSelectedCreditItem] = useState({});
    const [isArchived, setIsArchived] = useState(false);
    const [selectedFile, setSelectedFile] = useState({});
    const [showModal, setShowModal] = useState(false);
    const requestDrawerRef = useRef(null);
    const [getRef, setRef] = useDynamicRefs();
    const requestIn = localStorage.getItem('requestIn');

    useEffect(() => () => reset(), []);

    useEffect(() => {
        if (isEmpty(loadingContext)) return null;
        if (isSavingOffer || isDuplicatingOffer)
            loadingContext.openLoading(
                t('Saving change', { count: 2 }).concat('...')
            );
        else loadingContext.closeLoading();
    }, [isDuplicatingOffer, isSavingOffer]);

    useEffect(() => {
        resetErrors();
        fetchCommercialOffers(projectId, isArchived);
    }, [isArchived, projectId]);

    useEffect(() => {
        if (!isFetchingProjectCommercialOffers && requestIn) {
            setTimeout(() => {
                const button = getRef(requestIn);
                button?.current?.click();
            }, 1000);
        }
    }, [isFetchingProjectCommercialOffers, requestIn]);

    const handleToggleDrawer = (value) => {
        if (requestDrawerRef.current) {
            const { handleOpen } = requestDrawerRef.current;
            handleOpen(value);
        }
        if (!value) setSelectedCreditItem({});
        return;
    };

    const getIsOpenDrawer = () => {
        if (requestDrawerRef.current)
            return requestDrawerRef.current.getIsOpen();
        return false;
    };

    const handleCancelCreditApp = () => {
        cancelCreditApp(creditItemSelected.offerId, () => {
            fetchCommercialOffers(projectId, isArchived);
            handleToggleDrawer(false);
        });
    };

    const onClickStatus = (file) => {
        setSelectedFile(file);
        setShowModal(true);
    };

    const handleOnClickCreate = () =>
        prepareCommercialOfferCreate({
            commercialOffers,
            projectName,
        });

    const isLoading =
        isDuplicatingOffer ||
        isFetchingConsumptionEnergy ||
        isFetchingProjectCommercialOffers ||
        isSavingOffer;

    return (
        <Box>
            <Grid container spacing={0} sx={{ mb: 2 }}>
                <Grid
                    item
                    md={9}
                    sx={{
                        alignItems: { md: 'center', xs: 'start' },
                        display: 'flex',
                        flexDirection: { md: 'row', xs: 'column' },
                        gap: 2,
                    }}
                    xs={18}
                >
                    <Typography fontWeight="bold" variant="body2">
                        {t('Filter by')}
                    </Typography>
                    <FilterDropdown
                        defaultValue={isArchived}
                        handleSelected={(item) => setIsArchived(item.value)}
                    />
                </Grid>

                <Grid
                    item
                    md={9}
                    sx={{ pt: { md: 0, xs: 2 }, textAlign: 'right' }}
                    xs={18}
                >
                    <Button
                        className="__intercom_add_commercial_offer_button"
                        color="secondary"
                        dataIntercomTarget="Add Commercial Offer (Button)"
                        disabled={isLoading}
                        endIcon={<AddIcon />}
                        onClick={handleOnClickCreate}
                        variant="outlined"
                        visible={canModify && !isArchived}
                        sx={{ width: { xs: '100%', md: 'auto' } }}
                    >
                        {t('Create proposal')}
                    </Button>
                </Grid>
            </Grid>

            <Divider sx={{ mb: 2 }} />

            <ProposalTable
                canModify={canModify}
                canDelete={canDelete}
                commercialOffers={commercialOffers}
                handleToggleDrawer={handleToggleDrawer}
                isArchivedFilter={isArchived}
                isLoading={isLoading}
                prepareCommercialOfferCreate={handleOnClickCreate}
                redirect={redirect}
                setSelectedCreditItem={setSelectedCreditItem}
                setRef={setRef}
            />

            <DuplicateFormModal />

            <CommercialOfferModal projectId={projectId} />

            <TemporaryDrawer
                onClose={() => {
                    localStorage.removeItem('requestIn');
                }}
                ref={requestDrawerRef}
            >
                <RequestSideCard
                    cancelCreditApp={handleCancelCreditApp}
                    creditItem={creditItemSelected}
                    fetchCommercialOffers={fetchCommercialOffers}
                    handleToggleDrawer={handleToggleDrawer}
                    isArchived={isArchived}
                    isOpenDrawer={getIsOpenDrawer()}
                    onClickStatus={onClickStatus}
                    projectId={projectId}
                    requestDrawerRef={requestDrawerRef}
                    setSelectedCreditItem={setSelectedCreditItem}
                />
            </TemporaryDrawer>

            <FileReview
                creditItem={creditItemSelected}
                isArchivedFilter={isArchived}
                onHide={() => setShowModal(false)}
                projectId={projectId}
                setSelectedCreditItem={setSelectedCreditItem}
                selectedFile={selectedFile}
                setSelectedFile={setSelectedFile}
                show={showModal}
                user={
                    creditItemSelected.financier_installer
                        ? creditItemSelected.financier_installer.installer
                        : {}
                }
            />
        </Box>
    );
};

const mapStateToProps = createStructuredSelector({
    commercialOffers: selectors.getCommercialOffersData,
    deleteErrors: selectors.getDeleteOfferErrors,
    isDuplicatingOffer: selectors.getIsDuplicatingOffer,
    isFetchingConsumptionEnergy:
        projectConsumptionCardSelectors.getIsFetchingConsumptionEnergy,
    isFetchingProjectCommercialOffers: selectors.getIsFetchingCommercialOffers,
    isSavingOffer: selectors.getCreateOfferIsSaving,
});

const mapDispatchToProps = (dispatch) => ({
    cancelCreditApp: (offerId, onSuccess) =>
        dispatch(actions.prepareCancel(offerId, onSuccess)),
    fetchCommercialOffers: (id, isArchived) =>
        dispatch(actions.fetchCommercialOffers(id, isArchived)),
    prepareCommercialOfferCreate: ({ commercialOffers, projectName }) =>
        dispatch(
            actions.prepareCommercialOfferCreate({
                commercialOffers,
                projectName,
            })
        ),
    redirect: (link) => dispatch(push(link)),
    reset: () => dispatch(actions.reset()),
    resetErrors: () => dispatch(actions.resetErrors()),
});

Container.propTypes = {
    cancelCreditApp: PropTypes.func,
    canDelete: PropTypes.bool,
    canModify: PropTypes.bool,
    commercialOffers: PropTypes.array,
    fetchCommercialOffers: PropTypes.func,
    isDuplicatingOffer: PropTypes.bool,
    isFetchingConsumptionEnergy: PropTypes.bool,
    isFetchingProjectCommercialOffers: PropTypes.bool,
    isSavingOffer: PropTypes.bool,
    prepareCommercialOfferCreate: PropTypes.func,
    projectId: PropTypes.string,
    projectName: PropTypes.string,
    redirect: PropTypes.func,
    reset: PropTypes.func,
    resetErrors: PropTypes.func,
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withPermissions(PERMISSION_LIST.CONTACT_PROPOSALS_PERMISSION)
)(Container);
