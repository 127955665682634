import { get, isEmpty } from 'lodash';

import { FETCH_PARENT_FIELD_SEGMENTS_SUCCESS } from '../actionTypes';
import { getSegmentsFormatted } from '../helpers';
import { proposalGeneratorPanelsLayoutActions } from '../reducer';

export default (response) => (dispatch) => {
    const parentFieldSegment = get(
        response,
        `data.data.parent_field_segments[0]`,
        {}
    );
    if (isEmpty(parentFieldSegment)) {
        dispatch(
            proposalGeneratorPanelsLayoutActions[
                FETCH_PARENT_FIELD_SEGMENTS_SUCCESS
            ]({
                haveParentFieldSegment: false,
                segments: [],
                solarModulesDesignNumber: 0,
                solarModulesOfferNumber: 0,
            })
        );
    } else {
        const segments = getSegmentsFormatted(parentFieldSegment);
        const { solar_modules_design_number, solar_modules_offer_number } =
            parentFieldSegment;
        dispatch(
            proposalGeneratorPanelsLayoutActions[
                FETCH_PARENT_FIELD_SEGMENTS_SUCCESS
            ]({
                haveParentFieldSegment: true,
                segments,
                solarModulesDesignNumber: solar_modules_design_number,
                solarModulesOfferNumber: solar_modules_offer_number,
            })
        );
    }
};
