import { createSelector } from 'reselect';

import { NAME } from './constants';

export const getModel = (state) => state[NAME];

export const getNotifications = createSelector(
    getModel,
    (model) => model.fetchNotifications
);

export const getNotificationsData = createSelector(
    getNotifications,
    (model) => model.data.data || []
);

export const getFlattenedNotifications = createSelector(
    getNotificationsData,
    (notificationsData) => {
        return notificationsData.reduce((acc, group) => {
            return acc.concat(group.notifications);
        }, []);
    }
);

export const getCheckedNotifications = createSelector(
    getFlattenedNotifications,
    (data) =>
        data
            .filter((notification) => notification.is_checked)
            .map((notification) => notification.id)
);

export const getIsFetchingNotifications = createSelector(
    getNotifications,
    (model) => model.isFetching || false
);

export const getNotificationsPagination = createSelector(
    getNotifications,
    (model) => model.data.pagination || null
);

export const getNavNotifications = createSelector(
    getModel,
    (model) => model.fetchNavNotifications
);

export const getNavNotificationsData = createSelector(
    getNavNotifications,
    (model) => model.data.data || []
);

export const getNavNotificationsNoReaded = createSelector(
    getNavNotifications,
    (model) => model.data.unread_notifications || 0
);

export const getIsFetchingNavNotifications = createSelector(
    getNavNotifications,
    (model) => model.isFetching || false
);

export const getDeleteNotification = createSelector(
    getModel,
    (model) => model.deleteNotification
);

export const getIsDeletingNotification = createSelector(
    getDeleteNotification,
    (model) => model.isDeleting
);

export const getMarkAllNotificationsAsRead = createSelector(
    getModel,
    (model) => model.markAllNotificationsAsRead
);

export const getIsUpdatingNotifications = createSelector(
    getMarkAllNotificationsAsRead,
    (model) => model.isUpdating
);

export const getRemoveNotificationCounter = createSelector(
    getModel,
    (model) => model.removeNotificationCounter
);

export const getIsRemovingNotificationCounter = createSelector(
    getRemoveNotificationCounter,
    (model) => model.isRemoving
);

export const getUpdateNotification = createSelector(
    getModel,
    (model) => model.updateNotification
);

export const getIsUpdatingNotification = createSelector(
    getUpdateNotification,
    (model) => model.isUpdating
);
