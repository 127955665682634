import { push } from 'connected-react-router';
export default (contactId = null, projectId = null, proposalId = null) =>
    (dispatch) => {
        if (proposalId) {
            dispatch(push(`/proposal/${proposalId}`));
            return false;
        }
        if (projectId) {
            dispatch(
                push(
                    `/record/${contactId}?tab=projects&project=${projectId}&view=proposals&type=proposals`
                )
            );
            return false;
        }
        if (contactId) {
            dispatch(push(`/record/${contactId}?tab=projects`));
            return false;
        }
    };
