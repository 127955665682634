import API from '../..';
const ENTITY = 'segments-layout';

export const createSegment = (data) =>
    API.post(
        `/api/v1/${ENTITY}/`,
        { ...data },
        { headers: { 'Content-Type': 'application/json' } }
    );
export const updateSegment = (fieldSegmentId, data) =>
    API.put(
        `/api/v1/${ENTITY}/${fieldSegmentId}/`,
        { ...data },
        { headers: { 'Content-Type': 'application/json' } }
    );
export const deleteSegment = (fieldSegmentId) =>
    API.delete(`/api/v1/${ENTITY}/${fieldSegmentId}/`);
