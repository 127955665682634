import { getScheduleRateConfiguration } from 'common/api/v2/scheduleRate';
import { RATES_WITH_DIVISION } from 'common/constants/rates';

import {
    FETCH_SCHEDULE_RATE_CONFIGURATION,
    FETCH_SCHEDULE_RATE_CONFIGURATION_FAILURE,
} from '../actionTypes';
import { analysisVsGenerationActions } from '../reducer';

import setScheduleRateConfiguration from './setScheduleRateConfiguration';

export default ({ onSuccess, rate, rateDivision }) =>
    (dispatch) => {
        if (
            !rate?.id ||
            (rate.isCertified &&
                RATES_WITH_DIVISION.includes(rate.name) &&
                !rateDivision)
        )
            return;

        dispatch(
            analysisVsGenerationActions[FETCH_SCHEDULE_RATE_CONFIGURATION]()
        );

        getScheduleRateConfiguration({
            certified: rate.isCertified,
            rate: rate.id,
            rate_division: rateDivision || null,
        })
            .then((response) => {
                const data = response?.data?.data;

                dispatch(setScheduleRateConfiguration(data));

                if (onSuccess) onSuccess(data, rate);
            })
            .catch((error) =>
                dispatch(
                    analysisVsGenerationActions[
                        FETCH_SCHEDULE_RATE_CONFIGURATION_FAILURE
                    ](error?.response?.data?.errors)
                )
            );
    };
