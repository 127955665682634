import { updateItem } from 'common/api/v2/consumptionProfiles';
import alerts from 'common/modules/alerts';
import { showReponseErrorsAsAlert } from 'common/utils/helpers';
import showToast from 'common/utils/showToast';

import {
    ARCHIVE_CONSUMPTION_PROFILE,
    ARCHIVE_CONSUMPTION_PROFILE_FAILURE,
    ARCHIVE_CONSUMPTION_PROFILE_SUCCESS,
} from '../actionTypes';
import { consumptionProfilesActions } from '../reducer';
import * as selectors from '../selectors';

import fetchConsumptionProfiles from './fetchConsumptionProfiles';

export default (id, isArchived) => (dispatch, getState) => {
    dispatch(consumptionProfilesActions[ARCHIVE_CONSUMPTION_PROFILE]());
    updateItem({ id, is_archived: !isArchived })
        .then((response) => {
            const query = selectors.getFilterQuery(getState());
            dispatch(
                consumptionProfilesActions[ARCHIVE_CONSUMPTION_PROFILE_SUCCESS](
                    response.data
                )
            );
            dispatch(fetchConsumptionProfiles(query));
            dispatch(alerts.actions.close());
            showToast();
        })
        .catch((error) => {
            dispatch(
                consumptionProfilesActions[ARCHIVE_CONSUMPTION_PROFILE_FAILURE](
                    error?.response?.data?.errors
                )
            );
            showReponseErrorsAsAlert(dispatch, error?.response);
        });
};
