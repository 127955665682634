import { createSlice } from '@reduxjs/toolkit';

import {
    BULK_UPDATE_ITEMS,
    BULK_UPDATE_ITEMS_FAILURE,
    BULK_UPDATE_ITEMS_SUCCESS,
    CREATE,
    CREATE_FAILURE,
    CREATE_SUCCESS,
    DELETE_ITEM,
    DELETE_ITEM_FAILURE,
    DELETE_ITEM_SUCCESS,
    DUPLICATE,
    DUPLICATE_FAILURE,
    DUPLICATE_SUCCESS,
    FETCH_CAROUSEL_TEMPLATES,
    FETCH_CAROUSEL_TEMPLATES_FAILURE,
    FETCH_CAROUSEL_TEMPLATES_SUCCESS,
    FETCH_CUSTOM_BASE_TEMPLATES,
    FETCH_CUSTOM_BASE_TEMPLATES_FAILURE,
    FETCH_CUSTOM_BASE_TEMPLATES_SUCCESS,
    FETCH_LANGUAGES,
    FETCH_LANGUAGES_FAILURE,
    FETCH_LANGUAGES_SUCCESS,
    FETCH_TEMPLATE_CATEGORIES,
    FETCH_TEMPLATE_CATEGORIES_FAILURE,
    FETCH_TEMPLATE_CATEGORIES_SUCCESS,
    INITIALIZE,
    INITIALIZE_FAILURE,
    INITIALIZE_SUCCESS,
    INITIALIZE_FORM,
    OPEN_MODAL_TEMPLATE,
    RENAME_ITEM,
    RENAME_ITEM_FAILURE,
    RENAME_ITEM_SUCCESS,
    RESET,
    RESET_FORM,
    SET_TEMPLATE_MODE,
    SET_WIZARD_STEP,
    UPDATE_TEMPLATES_SUCCESS,
    FETCH_TEMPLATES,
    FETCH_TEMPLATES_FAILURE,
    FETCH_TEMPLATES_SUCCESS,
} from './actionTypes';
import { NAME, TEMPLATE_MODAL_MAIN_DATA } from './constants';

const INITIAL_STATE = {
    bulkUpdateItems: {
        errors: null,
        isFetching: false,
    },
    carouselTemplates: {
        data: {},
        errors: null,
        isFetching: false,
    },
    createItem: {
        errors: null,
        isCreating: false,
    },
    customBaseTemplates: {
        data: {
            data: [],
            pagination: null,
        },
        errors: null,
        isFetching: false,
    },
    deleteItem: {
        errors: null,
        isDeleting: false,
    },
    initialValues: {
        baseTemplateLanguages: [],
        branch_offices: '',
        custom_offer: null,
        id: null,
        language: '',
        locked: 0,
        proposals_number: '1',
        title: '',
        type: null,
        version: 2,
    },
    isInitializing: false,
    isOpenModalTemplate: false,
    languages: {
        data: [],
        errors: null,
        isFetching: false,
    },
    renameItem: {
        errors: null,
        isUpdating: false,
    },
    templateCategories: {
        data: [],
        errors: null,
        isFetching: false,
    },
    templateMode: null,
    templates: {
        data: { data: [], pagination: null },
        errors: null,
        isFetching: false,
    },
    wizardStep: TEMPLATE_MODAL_MAIN_DATA,
};

const templateLibrarySlice = createSlice({
    name: NAME,
    initialState: INITIAL_STATE,
    reducers: {
        [BULK_UPDATE_ITEMS]: (state) => {
            state.bulkUpdateItems = {
                ...state.bulkUpdateItems,
                errors: [],
                isFetching: true,
            };
        },
        [BULK_UPDATE_ITEMS_FAILURE]: (state, action) => {
            state.bulkUpdateItems = {
                ...state.bulkUpdateItems,
                errors: action.payload,
                isFetching: false,
            };
        },
        [BULK_UPDATE_ITEMS_SUCCESS]: (state) => {
            state.bulkUpdateItems = {
                ...state.bulkUpdateItems,
                isFetching: false,
            };
        },
        [CREATE]: (state) => {
            state.createItem = {
                ...state.createItem,
                errors: [],
                isCreating: true,
            };
        },
        [CREATE_FAILURE]: (state, action) => {
            state.createItem = {
                ...state.createItem,
                errors: action.payload,
                isCreating: false,
            };
        },
        [CREATE_SUCCESS]: (state) => {
            state.createItem = {
                ...state.createItem,
                isCreating: false,
            };
        },
        [DUPLICATE]: (state) => {
            state.createItem = {
                ...state.createItem,
                errors: [],
                isCreating: true,
            };
        },
        [DUPLICATE_FAILURE]: (state, action) => {
            state.createItem = {
                ...state.createItem,
                errors: action.payload,
                isCreating: false,
            };
        },
        [DUPLICATE_SUCCESS]: (state) => {
            state.createItem = {
                ...state.createItem,
                isCreating: false,
            };
        },
        [DELETE_ITEM]: (state) => {
            state.deleteItem = {
                ...state.deleteItem,
                errors: [],
                isDeleting: true,
            };
        },
        [DELETE_ITEM_FAILURE]: (state, action) => {
            state.deleteItem = {
                ...state.deleteItem,
                errors: action.payload,
                isDeleting: false,
            };
        },
        [DELETE_ITEM_SUCCESS]: (state, action) => {
            state.deleteItem = {
                ...state.deleteItem,
                errors: action.payload,
                isDeleting: false,
            };
        },
        [FETCH_CAROUSEL_TEMPLATES]: (state) => {
            state.carouselTemplates = {
                ...state.carouselTemplates,
                errors: [],
                isFetching: true,
            };
        },
        [FETCH_CAROUSEL_TEMPLATES_FAILURE]: (state, action) => {
            state.carouselTemplates = {
                ...state.carouselTemplates,
                errors: action.payload,
                isFetching: false,
            };
        },
        [FETCH_CAROUSEL_TEMPLATES_SUCCESS]: (state, action) => {
            state.carouselTemplates = {
                ...state.carouselTemplates,
                data: action.payload,
                isFetching: false,
            };
        },
        [FETCH_CUSTOM_BASE_TEMPLATES]: (state) => {
            state.customBaseTemplates = {
                ...state.customBaseTemplates,
                errors: [],
                isFetching: true,
            };
        },
        [FETCH_CUSTOM_BASE_TEMPLATES_FAILURE]: (state, action) => {
            state.customBaseTemplates = {
                ...state.customBaseTemplates,
                errors: action.payload,
                isFetching: false,
            };
        },
        [FETCH_CUSTOM_BASE_TEMPLATES_SUCCESS]: (state, action) => {
            state.customBaseTemplates = {
                ...state.customBaseTemplates,
                data: action.payload,
                isFetching: false,
            };
        },
        [FETCH_TEMPLATES]: (state, action) => {
            state.templates = {
                data: { data: [], pagination: null },
                errors: [],
                isFetching: true,
                query: action.payload,
            };
        },
        [FETCH_TEMPLATES_FAILURE]: (state, action) => {
            state.templates.errors = action.payload;
            state.templates.isFetching = false;
        },
        [FETCH_TEMPLATES_SUCCESS]: (state, action) => {
            state.templates.data = action.payload;
            state.templates.isFetching = false;
        },
        [FETCH_TEMPLATE_CATEGORIES]: (state, action) => {
            state.templateCategories = {
                ...state.templateCategories,
                errors: [],
                isFetching: true,
                query: action.payload,
            };
        },
        [FETCH_TEMPLATE_CATEGORIES_FAILURE]: (state, action) => {
            state.templateCategories = {
                ...state.templateCategories,
                errors: action.payload,
                isFetching: false,
            };
        },
        [FETCH_TEMPLATE_CATEGORIES_SUCCESS]: (state, action) => {
            state.templateCategories = {
                ...state.templateCategories,
                data: action.payload,
                isFetching: false,
            };
        },
        [FETCH_LANGUAGES]: (state) => {
            state.languages = {
                ...state.languages,
                errors: [],
                isFetching: true,
            };
        },
        [FETCH_LANGUAGES_FAILURE]: (state, action) => {
            state.languages = {
                ...state.languages,
                errors: action.payload,
                isFetching: false,
            };
        },
        [FETCH_LANGUAGES_SUCCESS]: (state, action) => {
            state.languages = {
                ...state.languages,
                data: action.payload,
                isFetching: false,
            };
        },
        [INITIALIZE]: (state) => {
            state.isInitializing = true;
        },
        [INITIALIZE_FAILURE]: (state) => {
            state.isInitializing = false;
        },
        [INITIALIZE_SUCCESS]: (state) => {
            state.isInitializing = false;
        },
        [INITIALIZE_FORM]: (state, action) => {
            state.initialValues = { ...state.initialValues, ...action.payload };
        },
        [RESET_FORM]: (state) => {
            state.customBaseTemplates = INITIAL_STATE.customBaseTemplates;
            state.initialValues = INITIAL_STATE.initialValues;
            state.templateMode = INITIAL_STATE.templateMode;
            state.wizardStep = INITIAL_STATE.wizardStep;
        },
        [RESET]: () => INITIAL_STATE,
        [OPEN_MODAL_TEMPLATE]: (state, action) => {
            state.isOpenModalTemplate = action.payload;
        },
        [SET_TEMPLATE_MODE]: (state, action) => {
            state.templateMode = action.payload;
        },
        [SET_WIZARD_STEP]: (state, action) => {
            state.wizardStep = action.payload;
        },
        [RENAME_ITEM]: (state) => {
            state.renameItem = {
                ...state.renameItem,
                errors: [],
                isUpdating: true,
            };
        },
        [RENAME_ITEM_FAILURE]: (state, action) => {
            state.renameItem = {
                ...state.renameItem,
                errors: action.payload,
                isUpdating: false,
            };
        },
        [RENAME_ITEM_SUCCESS]: (state) => {
            state.renameItem = {
                ...state.renameItem,
                isUpdating: false,
            };
        },
        [UPDATE_TEMPLATES_SUCCESS]: (state, action) => {
            state.templates.data.data = action.payload;
        },
    },
});

export const templateLibraryActions = templateLibrarySlice.actions;

export default templateLibrarySlice.reducer;
