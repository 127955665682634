import React, { useState } from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box, Button } from 'sunwise-ui';

import InputSearch from 'common/components/InputSearch';

const CUPSSearch = ({ disabled, fetchCupsInfo, getValues, setValue }) => {
    const [cups, setCups] = useState('');
    const { t } = useTranslation();

    const handleSearch = () => {
        fetchCupsInfo({ cups, getValues, setValue });
    };

    const handleOnChangeCups = (e) => {
        setCups(e.target.value);
    };

    return (
        <Box
            display="flex"
            flexDirection={{ xs: 'column', sm: 'row' }}
            gap={1}
            px={1}
        >
            <InputSearch
                disabled={disabled}
                onChange={handleOnChangeCups}
                placeholder="CUPS"
                value={cups}
                variant="filled"
            />

            <Button disabled={disabled} type="button" onClick={handleSearch}>
                {t('Search')}
            </Button>
        </Box>
    );
};

CUPSSearch.propTypes = {
    disabled: PropTypes.bool,
    fetchCupsInfo: PropTypes.func,
    getValues: PropTypes.func,
    setValue: PropTypes.func,
};

export default CUPSSearch;
