import i18next from 'i18next';

import { revokeApproval } from 'common/api/v1/interactiveProposal';
import { showReponseErrorsAsAlert } from 'common/utils/helpers';
import showToast from 'common/utils/showToast';

import setHasApprobed from '../../proposalReviewPro/actions/setHasApprobed';
import {
    REVOKE_APPROVAL,
    REVOKE_APPROVAL_FAILURE,
    REVOKE_APPROVAL_SUCCESS,
} from '../actionTypes';
import { multiTemplateReviewActions } from '../reducer';

export default (proposalId) => (dispatch) => {
    dispatch(multiTemplateReviewActions[REVOKE_APPROVAL]());

    revokeApproval(proposalId)
        .then(() => {
            dispatch(multiTemplateReviewActions[REVOKE_APPROVAL_SUCCESS](true));
            dispatch(setHasApprobed(false));
            showToast({
                body: i18next.t('Approval successfully revoked'),
            });
        })
        .catch((error) => {
            dispatch(
                multiTemplateReviewActions[REVOKE_APPROVAL_FAILURE](
                    error?.response?.data?.errors
                )
            );
            showReponseErrorsAsAlert(dispatch, error?.response);
        });
};
