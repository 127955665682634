import React from 'react';

import LinearProgress from '@mui/material/LinearProgress';
import { styled } from '@mui/material/styles';
import { push } from 'connected-react-router';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Box, Divider, List, Typography } from 'sunwise-ui';

import { scroll } from 'common/utils/mixins';

import * as actions from '../actions';
import { getDateTitle, handleClickNotificationBuild } from '../helpers';
import * as selectors from '../selectors';

import NotificationItem from './NotificationItem';

const NotificationsWrapper = styled(List)`
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow-y: scroll;
    padding-left: 16px;
    padding-right: 16px;
    width: 100%;
    ${scroll.custom}
`;

const NavNotificationsList = ({
    filterData,
    groupedNotifications,
    isDeletingNotification,
    isFetching,
    isRemovingNotificationCounter,
    isUpdatingNotification,
    isUpdatingNotifications,
    redirect,
    setIsOpenDrawer,
    updateNotification,
}) => {
    const { t } = useTranslation();

    const handleClickMarkAsReaded = (notification) =>
        updateNotification(filterData, notification);

    const handleClickNotification = (notification) =>
        handleClickNotificationBuild(
            handleClickMarkAsReaded,
            notification,
            redirect,
            setIsOpenDrawer
        );

    const isLoading =
        isDeletingNotification ||
        isFetching ||
        isRemovingNotificationCounter ||
        isUpdatingNotification ||
        isUpdatingNotifications;

    if (isLoading) {
        return (
            <Box p={2} sx={{ width: '100%' }}>
                <LinearProgress />
            </Box>
        );
    }

    if (groupedNotifications.length === 0) {
        return (
            <Box p={2} sx={{ textAlign: 'center' }}>
                <Typography variant="caption">
                    {t('No notifications for now')}
                </Typography>
            </Box>
        );
    }

    return (
        <NotificationsWrapper component="nav">
            {groupedNotifications.map((group, index) => (
                <Box key={`date-${group.date}-${index}`}>
                    <Box py={1} textAlign="right">
                        <Typography fontWeight="bold" variant="caption">
                            {getDateTitle(group?.date)}
                        </Typography>
                    </Box>
                    <Divider sx={{ mb: 2 }} />
                    <List component="nav">
                        {group?.notifications.map((notification) => (
                            <NotificationItem
                                handleClickNotification={
                                    handleClickNotification
                                }
                                key={`notification-item-${notification.id}`}
                                notification={notification}
                            />
                        ))}
                    </List>
                </Box>
            ))}
        </NotificationsWrapper>
    );
};

NavNotificationsList.propTypes = {
    filterData: PropTypes.object,
    groupedNotifications: PropTypes.array,
    isDeletingNotification: PropTypes.bool,
    isFetching: PropTypes.bool,
    isRemovingNotificationCounter: PropTypes.bool,
    isUpdatingNotification: PropTypes.bool,
    isUpdatingNotifications: PropTypes.bool,
    redirect: PropTypes.func,
    setIsOpenDrawer: PropTypes.func,
    updateNotification: PropTypes.func,
};

const mapStateToProps = createStructuredSelector({
    groupedNotifications: selectors.getNavNotificationsData,
    isDeletingNotification: selectors.getIsDeletingNotification,
    isFetching: selectors.getIsFetchingNavNotifications,
    isRemovingNotificationCounter: selectors.getIsRemovingNotificationCounter,
    isUpdatingNotification: selectors.getIsUpdatingNotification,
    isUpdatingNotifications: selectors.getIsUpdatingNotifications,
});

const mapDispatchToProps = (dispatch) => ({
    updateNotification: (filterData, notification) =>
        dispatch(actions.updateNotification(filterData, notification)),
    redirect: (link) => dispatch(push(link)),
});

NavNotificationsList.propTypes = {
    filterData: PropTypes.object,
    groupedNotifications: PropTypes.array,
    isDeletingNotification: PropTypes.bool,
    isFetching: PropTypes.bool,
    isRemovingNotificationCounter: PropTypes.bool,
    isUpdatingNotification: PropTypes.bool,
    isUpdatingNotifications: PropTypes.bool,
    redirect: PropTypes.func,
    updateNotification: PropTypes.func,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(NavNotificationsList);
