import { createSlice } from '@reduxjs/toolkit';

import {
    ARCHIVE_OFFER,
    ARCHIVE_OFFER_FAILURE,
    ARCHIVE_OFFER_SUCCESS,
    CREATE_OFFER,
    CREATE_OFFER_FAILURE,
    CREATE_OFFER_SUCCESS,
    DELETE_OFFER,
    DELETE_OFFER_FAILURE,
    DELETE_OFFER_SUCCESS,
    DUPLICATE_OFFER,
    DUPLICATE_OFFER_FAILURE,
    DUPLICATE_OFFER_SUCCESS,
    FETCH_COMMERCIAL_OFFERS,
    FETCH_COMMERCIAL_OFFERS_FAILURE,
    FETCH_COMMERCIAL_OFFERS_SUCCESS,
    FETCH_CREDIT_DOCUMENTS,
    FETCH_CREDIT_DOCUMENTS_FAILURE,
    FETCH_CREDIT_DOCUMENTS_SUCCESS,
    FETCH_DIVISIONS,
    FETCH_DIVISIONS_FAILURE,
    FETCH_DIVISIONS_SUCCESS,
    FETCH_LOCATIONS_ZIP_CODE,
    FETCH_LOCATIONS_ZIP_CODE_SUCCESS,
    FETCH_LOCATIONS_ZIP_CODE_FAILURE,
    FETCH_POLITICAL_DIVISIONS,
    FETCH_POLITICAL_DIVISIONS_FAILURE,
    FETCH_POLITICAL_DIVISIONS_SUCCESS,
    FETCH_POLITICAL_DIVISIONS2,
    FETCH_POLITICAL_DIVISIONS2_FAILURE,
    FETCH_POLITICAL_DIVISIONS2_SUCCESS,
    FETCH_REGIONS,
    FETCH_REGIONS_FAILURE,
    FETCH_REGIONS_SUCCESS,
    FETCH_VIEWS,
    FETCH_VIEWS_FAILURE,
    FETCH_VIEWS_SUCCESS,
    INITIALIZE_DUPLICATE_FORM_VALUES,
    INITIALIZE_VALUES,
    OPEN_COMMERCIAL_OFFER_MODAL,
    RENAME_OFFER,
    RENAME_OFFER_FAILURE,
    RENAME_OFFER_SUCCESS,
    RESET_COMMERCIAL_OFFER_FORM,
    RESET_DELETE_ERRORS,
    RESET_DUPLICATE_FORM,
    RESET,
    RESET_VIEWS,
    SET_IS_OPEN_DUPLICATE_MODAL,
    SET_OFFER_AS_FAVORITE,
    SET_OFFER_AS_FAVORITE_FAILURE,
    SET_OFFER_AS_FAVORITE_SUCCESS,
    UPDATE_CREDIT_APPLICATION_STATUS,
    UPDATE_CREDIT_APPLICATION_STATUS_FAILURE,
    UPDATE_CREDIT_APPLICATION_STATUS_SUCCESS,
    UPLOAD_DOCUMENT,
    UPLOAD_DOCUMENT_FAILURE,
    UPLOAD_DOCUMENT_SUCCESS,
    FETCH_PLANS,
    FETCH_PLANS_FAILURE,
    FETCH_PLANS_SUCCESS,
} from './actionTypes';
import { NAME } from './constants';

const INITIAL_STATE = {
    archiveOffer: {
        isLoading: false,
        errors: [],
    },
    commercialOfferInitialValues: {
        branch_office: '',
        id: '',
        is_multibranch: false,
        name: '',
        political_division1: '',
        political_division2: '',
        rate_division: '',
        type: '',
        without_consumptions: false,
        zip_code: '',
    },
    commercialOffers: {
        isFetching: false,
        data: [],
        errors: [],
    },
    createOffer: {
        isSaving: false,
        data: null,
        errors: [],
    },
    creditDocuments: {
        isFetching: false,
        data: null,
        errors: [],
    },
    deleteOffer: {
        isDeleting: false,
        errors: [],
    },
    divisions: {
        isFetching: false,
        data: null,
        errors: [],
    },
    duplicateFormInitialValues: {
        id: null,
        name: '',
    },
    duplicateOffer: {
        isDuplicating: false,
        data: null,
        errors: [],
    },
    favoriteOffer: {
        isLoading: false,
        data: null,
        errors: [],
    },
    fetchPlans: {
        data: [],
        errors: null,
        isFetching: false,
    },
    fetchViews: {
        isFetching: false,
        data: [],
        errors: null,
    },
    fileUploaded: {
        errors: [],
        isUploading: false,
    },
    isOpenCommercialOfferModal: false,
    isOpenDuplicateModal: false,
    locationsByZipCode: {
        isFetching: false,
        data: [],
        errors: null,
    },
    politicalDivisions: {
        isFetching: false,
        data: [],
        errors: null,
    },
    politicalDivisions2: {
        isFetching: false,
        data: [],
        errors: null,
    },
    regions: {
        isFetching: false,
        data: null,
        errors: [],
    },
    renameOffer: {
        isLoading: false,
        errors: [],
    },
    updateStatus: {
        isUpdating: false,
        data: null,
        errors: [],
    },
};

const projectCommercialOfferTableSlice = createSlice({
    name: NAME,
    initialState: INITIAL_STATE,
    reducers: {
        [ARCHIVE_OFFER]: (state) => {
            state.archiveOffer = {
                ...state.archiveOffer,
                errors: [],
                isLoading: true,
            };
        },
        [ARCHIVE_OFFER_FAILURE]: (state, action) => {
            state.archiveOffer = {
                ...state.archiveOffer,
                errors: action.payload,
                isLoading: false,
            };
        },
        [ARCHIVE_OFFER_SUCCESS]: (state, action) => {
            state.archiveOffer = {
                ...state.archiveOffer,
                data: action.payload,
                isLoading: false,
            };
        },
        [CREATE_OFFER]: (state) => {
            state.createOffer = {
                ...state.createOffer,
                errors: [],
                isSaving: true,
            };
        },
        [CREATE_OFFER_FAILURE]: (state, action) => {
            state.createOffer = {
                ...state.createOffer,
                errors: action.payload,
                isSaving: false,
            };
        },
        [CREATE_OFFER_SUCCESS]: (state, action) => {
            state.createOffer = {
                ...state.createOffer,
                data: action.payload,
                isSaving: false,
            };
        },
        [DELETE_OFFER]: (state) => {
            state.deleteOffer = {
                ...state.deleteOffer,
                errors: [],
                isDeleting: true,
            };
        },
        [DELETE_OFFER_FAILURE]: (state, action) => {
            state.deleteOffer = {
                ...state.deleteOffer,
                errors: action.payload,
                isDeleting: false,
            };
        },
        [DELETE_OFFER_SUCCESS]: (state, action) => {
            state.deleteOffer = {
                ...state.deleteOffer,
                data: action.payload,
                isDeleting: false,
            };
        },
        [DUPLICATE_OFFER]: (state) => {
            state.duplicateOffer = {
                ...state.duplicateOffer,
                errors: [],
                isDuplicating: true,
            };
        },
        [DUPLICATE_OFFER_FAILURE]: (state, action) => {
            state.duplicateOffer = {
                ...state.duplicateOffer,
                errors: action.payload,
                isDuplicating: false,
            };
        },
        [DUPLICATE_OFFER_SUCCESS]: (state, action) => {
            state.duplicateOffer = {
                ...state.duplicateOffer,
                data: action.payload,
                isDuplicating: false,
            };
        },
        [FETCH_COMMERCIAL_OFFERS]: (state) => {
            state.commercialOffers = {
                ...state.commercialOffers,
                errors: [],
                isFetching: true,
            };
        },
        [FETCH_COMMERCIAL_OFFERS_FAILURE]: (state, action) => {
            state.commercialOffers = {
                ...state.commercialOffers,
                errors: action.payload,
                isFetching: false,
            };
        },
        [FETCH_COMMERCIAL_OFFERS_SUCCESS]: (state, action) => {
            state.commercialOffers = {
                ...state.commercialOffers,
                data: action.payload,
                isFetching: false,
            };
        },
        [FETCH_CREDIT_DOCUMENTS]: (state) => {
            state.creditDocuments = {
                ...state.creditDocuments,
                errors: [],
                isFetching: true,
            };
        },
        [FETCH_CREDIT_DOCUMENTS_FAILURE]: (state, action) => {
            state.creditDocuments = {
                ...state.creditDocuments,
                errors: action.payload,
                isFetching: false,
            };
        },
        [FETCH_CREDIT_DOCUMENTS_SUCCESS]: (state, action) => {
            state.creditDocuments = {
                ...state.creditDocuments,
                data: action.payload,
                isFetching: false,
            };
        },
        [FETCH_DIVISIONS]: (state) => {
            state.divisions = {
                ...state.divisions,
                errors: [],
                isFetching: true,
            };
        },
        [FETCH_DIVISIONS_FAILURE]: (state, action) => {
            state.divisions = {
                ...state.divisions,
                errors: action.payload,
                isFetching: false,
            };
        },
        [FETCH_DIVISIONS_SUCCESS]: (state, action) => {
            state.divisions = {
                ...state.divisions,
                data: action.payload,
                isFetching: false,
            };
        },
        [FETCH_LOCATIONS_ZIP_CODE]: (state) => {
            state.locationsByZipCode = {
                ...state.locationsByZipCode,
                errors: [],
                isFetching: true,
            };
        },
        [FETCH_LOCATIONS_ZIP_CODE_FAILURE]: (state, action) => {
            state.locationsByZipCode = {
                ...state.locationsByZipCode,
                errors: action.payload,
                isFetching: false,
            };
        },
        [FETCH_LOCATIONS_ZIP_CODE_SUCCESS]: (state, action) => {
            state.locationsByZipCode = {
                ...state.locationsByZipCode,
                data: action.payload,
                isFetching: false,
            };
        },
        [FETCH_PLANS]: (state) => {
            state.fetchPlans = {
                ...state.fetchPlans,
                errors: [],
                isFetching: true,
            };
        },
        [FETCH_PLANS_FAILURE]: (state, action) => {
            state.fetchPlans = {
                ...state.fetchPlans,
                errors: action.payload,
                isFetching: false,
            };
        },
        [FETCH_PLANS_SUCCESS]: (state, action) => {
            state.fetchPlans = {
                ...state.fetchPlans,
                data: action.payload,
                isFetching: false,
            };
        },
        [FETCH_POLITICAL_DIVISIONS]: (state) => {
            state.politicalDivisions = {
                ...state.politicalDivisions,
                errors: [],
                isFetching: true,
            };
        },
        [FETCH_POLITICAL_DIVISIONS_FAILURE]: (state, action) => {
            state.politicalDivisions = {
                ...state.politicalDivisions,
                errors: action.payload,
                isFetching: false,
            };
        },
        [FETCH_POLITICAL_DIVISIONS_SUCCESS]: (state, action) => {
            state.politicalDivisions = {
                ...state.politicalDivisions,
                data: action.payload,
                isFetching: false,
            };
        },
        [FETCH_POLITICAL_DIVISIONS2]: (state) => {
            state.politicalDivisions2 = {
                ...state.politicalDivisions2,
                errors: [],
                isFetching: true,
            };
        },
        [FETCH_POLITICAL_DIVISIONS2_FAILURE]: (state, action) => {
            state.politicalDivisions2 = {
                ...state.politicalDivisions2,
                errors: action.payload,
                isFetching: false,
            };
        },
        [FETCH_POLITICAL_DIVISIONS2_SUCCESS]: (state, action) => {
            state.politicalDivisions2 = {
                ...state.politicalDivisions2,
                data: action.payload,
                isFetching: false,
            };
        },
        [FETCH_REGIONS]: (state) => {
            state.regions = { ...state.regions, errors: [], isFetching: true };
        },
        [FETCH_REGIONS_FAILURE]: (state, action) => {
            state.regions = {
                ...state.regions,
                errors: action.payload,
                isFetching: false,
            };
        },
        [FETCH_REGIONS_SUCCESS]: (state, action) => {
            state.regions = {
                ...state.regions,
                data: action.payload,
                isFetching: false,
            };
        },
        [FETCH_VIEWS]: (state) => {
            state.fetchViews = {
                ...state.fetchViews,
                errors: [],
                isFetching: false,
            };
        },
        [FETCH_VIEWS_FAILURE]: (state, action) => {
            state.fetchViews = {
                ...state.fetchViews,
                errors: action.payload,
                isFetching: false,
            };
        },
        [FETCH_VIEWS_SUCCESS]: (state, action) => {
            state.fetchViews = {
                ...state.fetchViews,
                data: action.payload,
                isFetching: false,
            };
        },
        [INITIALIZE_DUPLICATE_FORM_VALUES]: (state, action) => {
            state.duplicateFormInitialValues = {
                ...state.duplicateFormInitialValues,
                ...action.payload,
            };
        },
        [INITIALIZE_VALUES]: (state, action) => {
            state.commercialOfferInitialValues = {
                ...state.commercialOfferInitialValues,
                ...action.payload,
            };
        },
        [OPEN_COMMERCIAL_OFFER_MODAL]: (state, action) => {
            state.isOpenCommercialOfferModal = action.payload;
        },
        [RENAME_OFFER]: (state) => {
            state.renameOffer = {
                ...state.renameOffer,
                errors: [],
                isLoading: true,
            };
        },
        [RENAME_OFFER_FAILURE]: (state, action) => {
            state.renameOffer = {
                ...state.renameOffer,
                errors: action.payload,
                isLoading: false,
            };
        },
        [RENAME_OFFER_SUCCESS]: (state, action) => {
            state.renameOffer = {
                ...state.renameOffer,
                data: action.payload,
                isLoading: false,
            };
        },
        [SET_OFFER_AS_FAVORITE]: (state) => {
            state.favoriteOffer = {
                ...state.favoriteOffer,
                errors: [],
                isLoading: true,
            };
        },
        [SET_OFFER_AS_FAVORITE_FAILURE]: (state, action) => {
            state.favoriteOffer = {
                ...state.favoriteOffer,
                errors: action.payload,
                isLoading: false,
            };
        },
        [SET_OFFER_AS_FAVORITE_SUCCESS]: (state, action) => {
            state.favoriteOffer = {
                ...state.favoriteOffer,
                data: action.payload,
                isLoading: false,
            };
        },
        [RESET]: () => INITIAL_STATE,
        [RESET_COMMERCIAL_OFFER_FORM]: (state) => {
            state.commercialOfferInitialValues =
                INITIAL_STATE.commercialOfferInitialValues;
            state.locationsByZipCode = INITIAL_STATE.locationsByZipCode;
            state.politicalDivisions2 = INITIAL_STATE.politicalDivisions2;
        },
        [RESET_DUPLICATE_FORM]: (state) => {
            state.duplicateFormInitialValues =
                INITIAL_STATE.duplicateFormInitialValues;
        },
        [RESET_DELETE_ERRORS]: (state) => {
            state.deleteOffer = INITIAL_STATE.deleteOffer;
        },
        [RESET_VIEWS]: (state) => {
            state.fetchViews = INITIAL_STATE.fetchViews;
        },
        [SET_IS_OPEN_DUPLICATE_MODAL]: (state, action) => {
            state.isOpenDuplicateModal = action.payload;
        },
        [UPDATE_CREDIT_APPLICATION_STATUS]: (state) => {
            state.updateStatus = {
                ...state.updateStatus,
                errors: [],
                isLoading: true,
            };
        },
        [UPDATE_CREDIT_APPLICATION_STATUS_FAILURE]: (state, action) => {
            state.updateStatus = {
                ...state.updateStatus,
                errors: action.payload,
                isLoading: false,
            };
        },
        [UPDATE_CREDIT_APPLICATION_STATUS_SUCCESS]: (state, action) => {
            state.updateStatus = {
                ...state.updateStatus,
                data: action.payload,
                isLoading: false,
            };
        },
        [UPLOAD_DOCUMENT]: (state) => {
            state.fileUploaded.isUploading = true;
        },
        [UPLOAD_DOCUMENT_FAILURE]: (state, action) => {
            state.fileUploaded = {
                ...state.fileUploaded,
                errors: action.payload,
                isUploading: false,
            };
        },
        [UPLOAD_DOCUMENT_SUCCESS]: (state) => {
            state.fileUploaded.isUploading = false;
        },
    },
});

export const projectCommOfferTableActions =
    projectCommercialOfferTableSlice.actions;

export default projectCommercialOfferTableSlice.reducer;
