import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Typography } from 'sunwise-ui';

import { useBreakpoint } from 'common/hooks';

import ContactFormatter from './ContactFormatter';
import DescriptionFormatter from './DescriptionFormatter';
import UserFormatter from './UserFormatter';

const CompactColumns = ({ users }) => {
    const { t } = useTranslation();
    const breakpoint = useBreakpoint();

    const columnName = {
        field: 'contact',
        flex: 1,
        headerName: t('Contact'),
        renderCell: ContactFormatter,
    };

    let columns = [
        columnName,
        {
            field: 'description',
            flex: 1,
            headerName: t('Follow-up'),
            renderCell: (params) => {
                const { row } = params;
                return (
                    <DescriptionFormatter
                        description={row?.description}
                        users={users}
                    />
                );
            },
        },
        {
            field: 'objective',
            flex: 1,
            headerName: t('Objective'),
            renderCell: (params) => {
                const { row } = params;
                return (
                    <Typography variant="caption">
                        {row?.tracking_purpose?.name}
                    </Typography>
                );
            },
        },
        {
            field: 'user',
            flex: 1,
            headerName: t('User'),
            renderCell: UserFormatter,
        },
        {
            field: 'notification_at',
            flex: 1,
            headerName: t('Date'),
            renderCell: (params) => {
                const { row } = params;
                return (
                    <Typography variant="caption">
                        {row?.notification_at}
                    </Typography>
                );
            },
        },
    ];

    if (breakpoint === 'xs') {
        columns = [columnName];
    }

    return columns;
};

CompactColumns.propTypes = {
    users: PropTypes.array,
};

export default CompactColumns;
