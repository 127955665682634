import i18next from 'i18next';

import { deleteOffer } from 'common/api/v1/contactProject';
import alerts from 'common/modules/alerts';
import { showReponseErrorsAsAlert } from 'common/utils/helpers';
import showToast from 'common/utils/showToast';

import {
    DELETE_OFFER,
    DELETE_OFFER_FAILURE,
    DELETE_OFFER_SUCCESS,
} from '../actionTypes';
import { projectCommOfferTableActions } from '../reducer';

import fetchCommercialOffers from './fetchCommercialOffers';

export default (isArchivedFilter, offerId, projectId) => (dispatch) => {
    dispatch(projectCommOfferTableActions[DELETE_OFFER]());

    deleteOffer(offerId)
        .then((response) => {
            dispatch(
                projectCommOfferTableActions[DELETE_OFFER_SUCCESS](
                    response.data
                )
            );
            dispatch(fetchCommercialOffers(projectId, isArchivedFilter));
            dispatch(alerts.actions.close());
            showToast({ body: i18next.t('It was successfully removed') });
        })
        .catch((error) => {
            dispatch(
                projectCommOfferTableActions[DELETE_OFFER_FAILURE](
                    error?.response?.data?.errors
                )
            );
            showReponseErrorsAsAlert(dispatch, error?.response);
        });
};
