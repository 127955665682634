import { getItems } from 'common/api/v1/users';

import {
    FETCH_AGENTS,
    FETCH_AGENTS_FAILURE,
    FETCH_AGENTS_SUCCESS,
} from '../actionTypes';
import { actions } from '../reducer';

export default () => (dispatch) => {
    dispatch(actions[FETCH_AGENTS]());

    getItems()
        .then((response) =>
            dispatch(actions[FETCH_AGENTS_SUCCESS](response.data.data))
        )
        .catch((error) =>
            dispatch(
                actions[FETCH_AGENTS_FAILURE](error?.response?.data?.errors)
            )
        );
};
