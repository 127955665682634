import React, {
    useCallback,
    useContext,
    useEffect,
    useRef,
    useState,
} from 'react';

import { styled, useTheme } from '@mui/material/styles';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { createStructuredSelector } from 'reselect';
import { Box, Grid } from 'sunwise-ui';

import SwipeableDrawer from 'common/components/SwipeableDrawer';
import { LoadingContext } from 'common/utils/contexts';

import * as proposalGeneratorOneColumnActions from '../proposalGeneratorOneColumn/actions';

import * as actions from './actions';
import {
    Alerts,
    HeaderLayout,
    MapWrapper,
    SegmentEditCard,
    SegmentsBar,
    StyledGrid,
    Toolbar,
} from './components';
import { escapeUseEffect, handleOpenDrawer, loadingUseEffect } from './helpers';
import * as selectors from './selectors';
import { getElevationAngle, getAzimuthAngle } from './shaderHelpers';

const PageWrapper = styled(Box)`
    height: 100%;
    padding: 16px;
    position: relative;
    width: 100%;
`;

const PanelLayout = ({
    drawingType,
    handleClickBreadcrumb,
    initialFetching,
    initialValues,
    isCheckinSegment,
    isDeletingSegment,
    isDisablingModules,
    isDrawingMode,
    isEditingSegment,
    isFetchingIrradiationData,
    isFetchingOfferInfoPatern,
    isFetchingParentFieldSegment,
    isSavingSegment,
    match,
    offerInfoPatern,
    offerPanels,
    reset,
    resetForm,
    segments,
    setDrawingMode,
    setIsDisablingModules,
    shaderSettings,
    simulationIsPlaying,
}) => {
    const { t } = useTranslation();
    const mapRef = useRef(null);
    const sideBarRef = useRef(null);
    const swipeableDrawerRef = useRef(null);
    const theme = useTheme();
    const isDarkMode = theme.palette.mode === 'dark';
    const loadingContext = useContext(LoadingContext);
    const [selectedTab, setSelectedTab] = useState('design');
    const [sunPosition, setSunPosition] = useState({
        azimuth: 0,
        elevation: 0,
    });
    const [isEnabledSimulation, setIsEnabledSimulation] = useState(false);
    const [isGuideLinesActive, setIsGuideLinesActive] = useState(false);

    useEffect(() => {
        if (isEmpty(shaderSettings)) return;

        const dateTime = new Date(
            `${shaderSettings.month || 1}/${shaderSettings.day || 1}/${
                shaderSettings.year || 1920
            } ${shaderSettings.hour || 0}:${shaderSettings.minutes || 0}:00`
        );

        let azimuth = 0;
        let elevation = 0;

        if (isEnabledSimulation) {
            azimuth = getAzimuthAngle(
                shaderSettings.lat,
                shaderSettings.lng,
                dateTime.getTime(),
                shaderSettings.timezone
            );

            elevation = getElevationAngle(
                shaderSettings.lat,
                shaderSettings.lng,
                dateTime.getTime(),
                shaderSettings.timezone
            );
        }

        setSunPosition({ azimuth, elevation });
    }, [shaderSettings, isEnabledSimulation]);

    useEffect(() => {
        initialFetching(match.params.uid);
        return () => reset();
    }, []);

    useEffect(
        escapeUseEffect({
            setDrawingMode,
            setIsDisablingModules,
            useCallback,
        }),
        []
    );

    useEffect(
        loadingUseEffect({
            isCheckinSegment,
            isDeletingSegment,
            isEditingSegment,
            isFetchingIrradiationData,
            isSavingSegment,
            loadingContext,
        }),
        [
            isCheckinSegment,
            isDeletingSegment,
            isEditingSegment,
            isFetchingIrradiationData,
            isSavingSegment,
        ]
    );

    if (isFetchingParentFieldSegment || isFetchingOfferInfoPatern) return null;

    const onCloseDrawer = () => handleOpenDrawer(swipeableDrawerRef, false);

    const segmentBar = ({ isMobile = false } = {}) => (
        <SegmentsBar
            commercialOfferId={match.params.uid}
            isDrawingMode={isDrawingMode}
            isEditionMode={!isEmpty(initialValues)}
            isMobile={isMobile}
            mapRef={mapRef}
            offerPanels={offerPanels}
            onCloseDrawer={onCloseDrawer}
            segments={segments}
            selectedTab={selectedTab}
            setDrawingMode={setDrawingMode}
            setIsEnabledSimulation={setIsEnabledSimulation}
            setSelectedTab={setSelectedTab}
            sunPosition={sunPosition}
        />
    );

    return (
        <>
            <Grid
                container
                spacing={0}
                sx={{
                    backgroundColor: isDarkMode ? '#000' : 'rgb(248, 252, 255)',
                    height: '100%',
                }}
            >
                <Grid
                    item
                    lg={14}
                    md={12}
                    xs={18}
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        height: '100%',
                    }}
                >
                    <HeaderLayout
                        currentBreadcrumb={t('Layout')}
                        handleClickBreadcrumb={handleClickBreadcrumb}
                        id={match.params.uid}
                        isDrawingMode={isDrawingMode}
                        offerInfoPatern={offerInfoPatern}
                        setDrawingMode={setDrawingMode}
                    />
                    <Toolbar
                        isEditionMode={
                            !isEmpty(initialValues) || simulationIsPlaying
                        }
                        isGuideLinesActive={isGuideLinesActive}
                        mapRef={mapRef}
                        onCloseDrawer={() =>
                            handleOpenDrawer(swipeableDrawerRef, false)
                        }
                        setIsGuideLinesActive={setIsGuideLinesActive}
                    />
                    <PageWrapper>
                        <Box
                            sx={{
                                padding: '4px',
                                position: 'absolute',
                                width: 'calc(100% - 32px)',
                                zIndex: 10,
                            }}
                        >
                            <Alerts segments={segments} />
                        </Box>

                        <MapWrapper
                            commercialOfferId={match.params.uid}
                            drawingType={drawingType}
                            isDisablingModules={isDisablingModules}
                            isDrawingMode={isDrawingMode}
                            isEditionMode={!isEmpty(initialValues)}
                            isEnabledSimulation={isEnabledSimulation}
                            isGuideLinesActive={isGuideLinesActive}
                            onOpenDrawer={() =>
                                handleOpenDrawer(swipeableDrawerRef, true)
                            }
                            ref={mapRef}
                            segments={segments}
                            sunPosition={sunPosition}
                        />
                    </PageWrapper>
                </Grid>
                <StyledGrid
                    item
                    lg={4}
                    md={6}
                    ref={sideBarRef}
                    sx={{
                        display: { md: 'block', xs: 'none' },
                        height: {
                            md: 'calc(100vh - 64px)',
                            xs: 'calc(100vh - 105px)',
                        },
                    }}
                >
                    {segmentBar()}
                </StyledGrid>
            </Grid>

            <SwipeableDrawer
                onCloseEnded={() => {
                    resetForm();
                }}
                ref={swipeableDrawerRef}
            >
                <>
                    {isEmpty(initialValues) && segmentBar({ isMobile: true })}
                    {!isEmpty(initialValues) && (
                        <SegmentEditCard
                            commercialOfferId={match.params.uid}
                            mapRef={mapRef}
                            offerPanels={offerPanels}
                            onCloseDrawer={onCloseDrawer}
                            sxBox={{
                                display: { md: 'none', xs: 'block' },
                                width: '100%',
                            }}
                        />
                    )}
                </>
            </SwipeableDrawer>
        </>
    );
};

const mapStateToProps = createStructuredSelector({
    drawingType: selectors.getDrawingType,
    initialValues: selectors.getInitialValues,
    isCheckinSegment: selectors.getIsCheckinSegment,
    isDeletingSegment: selectors.getIsDeletingSegment,
    isDisablingModules: selectors.getIsDisablingModules,
    isDrawingMode: selectors.getIsDrawingMode,
    isEditingSegment: selectors.getUpdateSegmentIsSaving,
    isFetchingIrradiationData: selectors.getIrradiationIsFetching,
    isFetchingOfferInfoPatern: selectors.getIsFetchingOfferInfoPatern,
    isFetchingParentFieldSegment: selectors.getIsFetchingParentFieldSegment,
    isSavingSegment: selectors.getCreateSegmentIsSaving,
    offerInfoPatern: selectors.getOfferInfoPaternData,
    offerPanels: selectors.getOfferPanelsData,
    segments: selectors.getSegmentsData,
    shaderSettings: selectors.getShaderSettingsData,
    simulationIsPlaying: selectors.getSimulationIsPlaying,
});

const mapDispatchToProps = (dispatch) => ({
    handleClickBreadcrumb: (contactId, projetcId, proposalId) =>
        dispatch(
            proposalGeneratorOneColumnActions.handleClickBreadcrumb(
                contactId,
                projetcId,
                proposalId
            )
        ),
    initialFetching: (id) => dispatch(actions.initialFetching(id)),
    reset: () => dispatch(actions.resetValues()),
    resetForm: () => dispatch(actions.resetForm()),
    setDrawingMode: (isDrawingMode, type) =>
        dispatch(actions.setDrawingMode(isDrawingMode, type)),
    setIsDisablingModules: (isDisabling) =>
        dispatch(actions.setIsDisablingModules(isDisabling)),
});

PanelLayout.propTypes = {
    drawingType: PropTypes.number,
    handleClickBreadcrumb: PropTypes.func,
    initialFetching: PropTypes.func,
    initialValues: PropTypes.object,
    isCheckinSegment: PropTypes.bool,
    isDeletingSegment: PropTypes.bool,
    isDisablingModules: PropTypes.bool,
    isDrawingMode: PropTypes.bool,
    isEditingSegment: PropTypes.bool,
    isFetchingIrradiationData: PropTypes.bool,
    isFetchingOfferInfoPatern: PropTypes.bool,
    isFetchingParentFieldSegment: PropTypes.bool,
    isSavingSegment: PropTypes.bool,
    match: PropTypes.object,
    offerInfoPatern: PropTypes.object,
    offerPanels: PropTypes.array,
    reset: PropTypes.func,
    resetForm: PropTypes.func,
    segments: PropTypes.array,
    setDrawingMode: PropTypes.func,
    setIsDisablingModules: PropTypes.func,
    shaderSettings: PropTypes.object,
    simulationIsPlaying: PropTypes.bool,
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withRouter
)(PanelLayout);
