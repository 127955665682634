import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import { useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Alert, Card, Link, Typography } from 'sunwise-ui';

import { useBreakpoint } from 'common/hooks';

import { resetSourceInfoFields } from '../../helpers';
import BottomActionsForm from '../form/BottomActionsForm';
import Table from '../Table';

const CredentialsList = ({
    control,
    credentials,
    disabled,
    fetchCredentials,
    isFetching,
    onCancelSelection,
    onContinue,
    providersDictionary,
    setValue,
}) => {
    const [filterData, setFilterData] = useState({ pageNo: 1, pageSize: 15 });
    const { t } = useTranslation();
    const breakpoint = useBreakpoint();
    const credential = useWatch({ control, name: 'credential' });

    useEffect(() => fetchCredentials(), []);

    const onChangeFilter = (key, value) =>
        setFilterData({ ...filterData, [key]: value });

    let columns = [
        {
            align: 'left',
            field: 'name',
            flex: 1,
            headerName: t('Name'),
        },
        {
            align: 'left',
            field: 'provider',
            flex: 1,
            headerName: t('Provider'),
            valueGetter: ({ row }) => {
                const provider = providersDictionary[row.provider_id || 0];
                return provider?.name || '--';
            },
        },
    ];

    if (!['xs', 'sm'].includes(breakpoint))
        columns.push({
            align: 'left',
            field: 'description',
            flex: 1,
            headerName: t('Description'),
            valueGetter: ({ row }) => row?.description || '--',
        });

    return (
        <>
            <Typography variant="h6" sx={{ mb: 2, ml: 1 }}>
                {t('Select a credential')}
            </Typography>

            <Alert severity="info" sx={{ mx: 1, mb: 2 }}>
                {t(
                    'If the credential you need is not in the list, add it in the credentials section'
                )}
                <Link
                    color="secondary"
                    href="/monitoring-credentials"
                    rel="noopener noreferrer"
                    sx={{ ml: 1 }}
                    underline="hover"
                    variant="text"
                >
                    {t('Go to credentials')}
                </Link>
            </Alert>

            <Card>
                <Card.Body>
                    <Table
                        columns={columns}
                        isLoading={isFetching}
                        items={credentials}
                        onPageChange={(page) =>
                            onChangeFilter('pageNo', page + 1)
                        }
                        onPageSizeChange={(size) =>
                            onChangeFilter('pageSize', size)
                        }
                        onRowClick={(params) => {
                            if (disabled) return;
                            setValue('credential', params?.row);
                            setValue('power_station', null);

                            const providerId = params?.row?.provider_id;
                            const provider = providersDictionary?.[providerId];

                            resetSourceInfoFields(
                                provider?.valid_read_fields,
                                setValue
                            );
                        }}
                        page={filterData.pageNo - 1 || 0}
                        pageSize={filterData.pageSize}
                        selectionModel={[credential?.id]}
                    />
                </Card.Body>
            </Card>

            <BottomActionsForm
                cancelText={t('Cancel')}
                continueText={t('Continue')}
                disabled={disabled}
                disabledContinue={!credential?.id}
                onCancel={onCancelSelection}
                onContinue={onContinue}
            />
        </>
    );
};

CredentialsList.propTypes = {
    control: PropTypes.object,
    credentials: PropTypes.array,
    disabled: PropTypes.bool,
    fetchCredentials: PropTypes.func,
    isFetching: PropTypes.bool,
    onCancelSelection: PropTypes.func,
    onContinue: PropTypes.func,
    providersDictionary: PropTypes.object,
    setValue: PropTypes.func,
};

export default CredentialsList;
