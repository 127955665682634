import React from 'react';

import FolderIcon from '@mui/icons-material/Folder';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { IconButton, Box, Tooltip, Typography } from 'sunwise-ui';

const ProjectsCounterTooltip = ({
    isDarkMode,
    isHidden,
    subscription,
    sxIcon,
}) => {
    const { t } = useTranslation();
    const availableProjects = get(
        subscription,
        'counters.available_projects',
        0
    );

    if (isHidden) return null;

    return (
        <Tooltip
            title={`${t('Total projects available')}: ${availableProjects}`}
        >
            <IconButton size="small" sx={{ position: 'relative' }}>
                <FolderIcon sx={{ ...sxIcon, fontSize: 30 }} />
                <Box
                    sx={{
                        alignItems: 'center',
                        display: 'flex',
                        height: '24px',
                        justifyContent: 'center',
                        position: 'absolute',
                        width: '100%',
                    }}
                >
                    <Typography
                        sx={{
                            color: isDarkMode ? '#1f3c53' : '#fff',
                            fontSize: 10,
                            fontWeight: 'bold',
                        }}
                    >
                        {availableProjects > 99 ? '99+' : availableProjects}
                    </Typography>
                </Box>
            </IconButton>
        </Tooltip>
    );
};

ProjectsCounterTooltip.propTypes = {
    isDarkMode: PropTypes.bool,
    isHidden: PropTypes.bool,
    subscription: PropTypes.object,
    sxIcon: PropTypes.object,
};

export default ProjectsCounterTooltip;
