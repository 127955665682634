import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { ReactHookFormSelect } from 'common/components/form/bootstrap';
import { getIsMultibranchesAccount } from 'common/utils/helpers/session';

const getBranchOfficesOptions = (branchOfficesDictionary) => {
    return Object.keys(branchOfficesDictionary)
        .filter((branchKey) => !['all', 'company'].includes(branchKey))
        .map((branchKey) => ({
            label: branchOfficesDictionary[branchKey],
            value: branchKey,
        }));
};

const UserBranchOfficesSelect = ({
    branchOfficesDictionary,
    control,
    disabled,
    label,
    name,
}) => {
    const { t } = useTranslation();
    const options = getBranchOfficesOptions(branchOfficesDictionary);

    if (!getIsMultibranchesAccount()) return null;
    return (
        <ReactHookFormSelect
            control={control}
            disabled={disabled}
            label={label}
            name={name}
            options={[
                {
                    disabled: true,
                    label: t('Select a branch office'),
                    value: '',
                },
                ...options,
            ]}
        />
    );
};

UserBranchOfficesSelect.propTypes = {
    branchOfficesDictionary: PropTypes.object,
    control: PropTypes.object,
    disabled: PropTypes.bool,
    label: PropTypes.string,
    name: PropTypes.string,
};

export default UserBranchOfficesSelect;
