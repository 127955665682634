import React from 'react';

import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box, Grid } from 'sunwise-ui';

import { ReactHookFormIntlNumberInput } from 'common/components/form/bootstrap';

import FieldArrayConcepts from '../CustomRateFormTabs/SettingsDataTab/FieldArrayConcepts';
import SectionHeader from '../SectionHeader';
import { Label } from '../styledComponents';

const FieldsContainer = styled(Box)`
    display: flex;
    flex-direction: column;
    margin-bottom: 43px;
    margin-top: 29px;
`;

const ChargesTab = ({
    control,
    disabled,
    getValues,
    othersUnitsDataToSelect,
    setValue,
    showMode,
}) => {
    const { t } = useTranslation();

    return (
        <>
            <Grid container>
                <Grid item xs>
                    <Label>{t('Annual percentage increase')}:</Label>
                </Grid>
                <Grid item xs md={9}>
                    <ReactHookFormIntlNumberInput
                        append="%"
                        control={control}
                        disabled={disabled}
                        max={100}
                        min={0}
                        name="annual_percentage_increase"
                    />
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs>
                    <Label>{t('Right to public lighting')}:</Label>
                </Grid>
                <Grid item xs md={9}>
                    <ReactHookFormIntlNumberInput
                        append="%"
                        control={control}
                        disabled={disabled}
                        max={100}
                        min={0}
                        name="dap"
                    />
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs>
                    <Label>{t('Tax rate')}:</Label>
                </Grid>
                <Grid item xs md={9}>
                    <ReactHookFormIntlNumberInput
                        append="%"
                        control={control}
                        disabled={disabled}
                        max={100}
                        min={0}
                        name="tax_rate"
                    />
                </Grid>
            </Grid>

            <SectionHeader title={t('Other charges')} />
            <FieldsContainer>
                <FieldArrayConcepts
                    control={control}
                    getValues={getValues}
                    isConceptsOtherChargesTaxes
                    name="other_concepts"
                    setValue={setValue}
                    showMode={showMode}
                    unitItems={othersUnitsDataToSelect}
                />
            </FieldsContainer>
            <SectionHeader title={t('Tax', { count: 2 })} />
            <FieldsContainer>
                <FieldArrayConcepts
                    control={control}
                    getValues={getValues}
                    isConceptsOtherChargesTaxes
                    name="taxes_concepts"
                    setValue={setValue}
                    showMode={showMode}
                    unitItems={othersUnitsDataToSelect}
                />
            </FieldsContainer>
        </>
    );
};

ChargesTab.propTypes = {
    control: PropTypes.object,
    disabled: PropTypes.bool,
    getValues: PropTypes.func,
    othersUnitsDataToSelect: PropTypes.array,
    setValue: PropTypes.func,
    showMode: PropTypes.bool,
};

export default ChargesTab;
