import React from 'react';

import PropTypes from 'prop-types';

const IconZeroExport = ({
    color = '#ff9a00',
    height = '24px',
    width = '24px',
}) => (
    <svg
        width={width}
        height={height}
        viewBox="0 0 131 83"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M3 79.0727C33.8988 79.0727 35.0308 64.1042 40 42.5M127.196 79.0727C95.1253 79.0727 93.3372 64.1042 89 42.5"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeWidth="9"
            style={{ fill: 'none' }}
        />
        <path
            d="M40.5 36C44.0502 20.4117 49.7617 3 65.996 3C81.0035 3 84.8936 20.4117 88 36"
            stroke={color}
            strokeDasharray="8 12"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="9"
            style={{ fill: 'none' }}
        />
        <line
            stroke={color}
            strokeLinecap="round"
            strokeWidth="7"
            style={{ fill: 'none' }}
            x1="22.5"
            x2="106.829"
            y1="41.5"
            y2="41.5"
        />
    </svg>
);

IconZeroExport.propTypes = {
    color: PropTypes.string,
    height: PropTypes.string,
    width: PropTypes.string,
};

export default IconZeroExport;
