import i18next from 'i18next';

import { uploadCsv } from 'common/api/v2/costumerEnergyConsumption';
import alerts from 'common/modules/alerts';
import { showReponseErrorsAsAlert } from 'common/utils/helpers';

import {
    UPLOAD_FILE,
    UPLOAD_FILE_FAILURE,
    UPLOAD_FILE_SUCCESS,
} from '../actionTypes';
import { UPLOAD_MODES } from '../constants';
import { getNormalizedCsvData } from '../helpers';
import { projectConsumptionModalActions } from '../reducer';
import * as selectors from '../selectors';

import handleReceiptCsvData from './handleReceiptCsvData';
import setConfigCsvImportModal from './setConfigCsvImportModal';

export default ({ data, getValues, result, setValue }) =>
    (dispatch, getState) => {
        if (!data?.file) return;

        const selectedProject = selectors.getSelectedProject(getState());
        const config = {
            consumption: data?.file,
            origin: UPLOAD_MODES.CSV,
            project: selectedProject?.id,
        };

        dispatch(projectConsumptionModalActions[UPLOAD_FILE]());

        dispatch(
            alerts.actions.show({
                confirmText: i18next.t('Back'),
                hideButtons: true,
                messages: [
                    i18next.t(
                        'Please wait a moment as the information is processed'
                    ),
                ],
                title: i18next.t('Loading').concat('...'),
                type: alerts.ALERT_TYPE_ALERT,
                variant: 'info',
            })
        );

        uploadCsv(config)
            .then((response) => {
                const responseData = response?.data?.data || {};

                const {
                    demandData,
                    lastDate,
                    normalizedData,
                    totalConsumption,
                } = getNormalizedCsvData(
                    result,
                    data?.columnsFormat,
                    data?.columnsMatch
                );

                let values = {
                    consumptionProfileArray: normalizedData,
                    data: {
                        lastDateConsumption: lastDate,
                        totalConsumption,
                        url: responseData?.consumptions_csv?.id,
                    },
                    demandArray: demandData,
                };

                dispatch(alerts.actions.close());
                dispatch(setConfigCsvImportModal());
                dispatch(
                    projectConsumptionModalActions[UPLOAD_FILE_SUCCESS](
                        responseData
                    )
                );
                dispatch(handleReceiptCsvData({ getValues, setValue, values }));
            })
            .catch((error) => {
                dispatch(projectConsumptionModalActions[UPLOAD_FILE_FAILURE]());
                dispatch(alerts.actions.close());
                showReponseErrorsAsAlert(dispatch, error?.response);
            });
    };
