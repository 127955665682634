import isNil from 'lodash/isNil';

import { getNextPageListProjects } from 'common/api/v3/funnelProjects';

import {
    FETCH_NEXT_PAGE,
    FETCH_NEXT_PAGE_FAILURE,
    FETCH_NEXT_PAGE_SUCCESS,
} from '../actionTypes';
import { listProjectsActions } from '../reducer';

export default (nextPage, filterQuery) => (dispatch) => {
    dispatch(listProjectsActions[FETCH_NEXT_PAGE]());
    getNextPageListProjects({ page: nextPage, filterQuery })
        .then((response) => {
            const projects = response.data.data;
            if (projects.length > 0) {
                dispatch(
                    listProjectsActions[FETCH_NEXT_PAGE_SUCCESS]({
                        projects: response.data.data,
                        hasNextPage: !isNil(
                            response.data.pagination.links.next
                        ),
                    })
                );
            } else {
                dispatch(
                    listProjectsActions[FETCH_NEXT_PAGE_FAILURE]({ error: [] })
                );
            }
        })
        .catch((error) => {
            dispatch(listProjectsActions[FETCH_NEXT_PAGE_FAILURE]({ error }));
        });
};
