import { createSelector } from 'reselect';

import { NAME } from './constants';

export const getModel = (state) => state[NAME];
export const getState = (state) => state;

// DELETE OFFER SMART DOCUMENT
export const getDeleteOfferSmartDocument = createSelector(
    getModel,
    (model) => model.deleteOfferSmartDocument
);

export const getIsDeletingOfferSmartDocument = createSelector(
    getDeleteOfferSmartDocument,
    (value) => value.isDeleting
);

// DOCUMENT TEMPLATES
export const getDocumentTemplates = createSelector(
    getModel,
    (model) => model.documentTemplates
);

export const getDocumentTemplatesData = createSelector(
    getDocumentTemplates,
    (value) => value.data
);

export const getIsFetchingDocumentTemplates = createSelector(
    getDocumentTemplates,
    (value) => value.isFetching
);

// DOWNLOAD OFFER CSV
export const getDownloadOfferCsv = createSelector(
    getModel,
    (model) => model.downloadOfferCsv
);

export const getIsFetchingOfferCsv = createSelector(
    getDownloadOfferCsv,
    (value) => value.isFetching
);

// DOWNLOAD OFFER PDF
export const getDownloadOfferPdf = createSelector(
    getModel,
    (model) => model.downloadOfferPdf
);

export const getIsFetchingPdfFile = createSelector(
    getDownloadOfferPdf,
    (value) => value.isFetching
);

// DOWNLOAD OFFER SMART DOCUMENT CSV
export const getDownloadOfferSmartDocumentCsv = createSelector(
    getModel,
    (model) => model.downloadOfferSmartDocumentCsv
);

export const getIsFetchingOfferSmartDocumentCsv = createSelector(
    getDownloadOfferSmartDocumentCsv,
    (value) => value.isFetching
);

// DUPLICATE OFFER SMART DOCUMENT
export const getDuplicateOfferSmartDocument = createSelector(
    getModel,
    (model) => model.duplicateOfferSmartDocument
);

export const getIsDuplicatingOfferSmartDocument = createSelector(
    getDuplicateOfferSmartDocument,
    (value) => value.isDuplicating
);

// FETCH CUSTOM TEMPLATE
export const getFetchCustomTemplate = createSelector(
    getModel,
    (model) => model.fetchCustomTemplate
);

export const getFetchCustomTemplateData = createSelector(
    getFetchCustomTemplate,
    (model) => model.data
);

export const getIsFetchingCustomTemplate = createSelector(
    getFetchCustomTemplate,
    (value) => value.isFetching
);

export const getFetchCustomTemplateErrors = createSelector(
    getFetchCustomTemplate,
    (model) => model.errors
);

export const getCustomTemplateId = createSelector(
    getFetchCustomTemplate,
    (model) => model.data?.id ?? null
);

// FETCH FINANCING TEMPLATE
export const getFinancingTemplate = createSelector(
    getModel,
    (model) => model.financingTemplate
);

export const getIsFetchingFinancingTemplate = createSelector(
    getFinancingTemplate,
    (value) => value.isFetching
);

// IS OPEN OFFER SMART DOCUMENT MODAL
export const getIsOpenOfferSmartDocumentModal = createSelector(
    getModel,
    (model) => model.isOpenOfferSmartDocumentModal
);

// OFFER DATASHEETS
export const getOfferDatasheets = createSelector(
    getModel,
    (value) => value.offerDatasheets
);

export const getOfferDatasheetsData = createSelector(
    getOfferDatasheets,
    (values) => values.data || []
);

export const getIsFetchingOfferDatasheets = createSelector(
    getOfferDatasheets,
    (values) => values.isFetching
);

// OFFER SMART DOCUMENT FORM MODE
export const getOfferSmartDocumentFormMode = createSelector(
    getModel,
    (model) => model.offerSmartDocumentFormMode
);

// OFFER SMART DOCUMENTS
export const getOfferSmartDocuments = createSelector(
    getModel,
    (model) => model.offerSmartDocuments
);

export const getOfferSmartDocumentsData = createSelector(
    getOfferSmartDocuments,
    (value) => value.data
);

export const getIsFetchingOfferSmartDocuments = createSelector(
    getOfferSmartDocuments,
    (value) => value.isFetching
);

// RENAME OFFER SMART DOCUMENT
export const getRenameOfferSmartDocument = createSelector(
    getModel,
    (model) => model.renameOfferSmartDocument
);

export const getIsRenamingOfferSmartDocument = createSelector(
    getRenameOfferSmartDocument,
    (value) => value.isRenaming
);

// SAVE OFFER SMART DOCUMENT
export const getSaveOfferSmartDocument = createSelector(
    getModel,
    (model) => model.saveOfferSmartDocument
);

export const getIsSavingOfferSmartDocument = createSelector(
    getSaveOfferSmartDocument,
    (value) => value.isSaving
);

// SMART DOCUMENT INITIAL VALUES
export const getOfferSmartDocumentInitialValues = createSelector(
    getModel,
    (model) => model.offerSmartDocumentInitialValues
);

export const getSelectedDocument = createSelector(
    getModel,
    (model) => model.selectedDocument
);

// UPDATE CUSTOM TEMPLATE
export const getUpdateCustomTemplate = createSelector(
    getModel,
    (model) => model.updateCustomTemplate
);

export const getIsUpdatingCustomTemplate = createSelector(
    getUpdateCustomTemplate,
    (value) => value.isUpdating
);

export const getUpdateCustomTemplateType = createSelector(
    getUpdateCustomTemplate,
    (value) => value.type
);

// INITIALIZING
export const getIsInitializing = createSelector(
    getModel,
    (model) => model.initializing
);

// DOWNLOAD OFFER XLS
export const getDownloadOfferXLS = createSelector(
    getModel,
    (model) => model.downloadOfferXLS
);

export const getIsFetchingOfferXLS = createSelector(
    getDownloadOfferXLS,
    (model) => model.isFetching
);

// DOWNLOAD OFFER HOURLY CSV
export const getDownLoadOfferHourlyCsv = createSelector(
    getModel,
    (model) => model.downloadOfferHourlyCsv
);

export const getIsFetchingOfferHourlyCsv = createSelector(
    getDownLoadOfferHourlyCsv,
    (model) => model.isFetching
);
