import Geocode from 'react-geocode';

import { getIsoCodeCurrentLocale } from 'common/utils/helpers/multiregion';

import {
    FETCH_GEOCODE_POINT,
    FETCH_GEOCODE_POINT_FAILURE,
    FETCH_GEOCODE_POINT_SUCCESS,
} from '../actionTypes';
import { locationFormActions } from '../reducer';

export default (lat, lng, name) => (dispatch) => {
    Geocode.setApiKey(process.env.REACT_APP_GOOGLE_MAPS_KEY);
    Geocode.setLanguage(getIsoCodeCurrentLocale());
    Geocode.setRegion('mx');

    dispatch(locationFormActions[FETCH_GEOCODE_POINT]({ name }));

    return Geocode.fromLatLng(lat, lng).then(
        (response) => {
            dispatch(
                locationFormActions[FETCH_GEOCODE_POINT_SUCCESS]({
                    name,
                    payload: response.results[0].formatted_address,
                })
            );
        },
        (error) =>
            dispatch(
                locationFormActions[FETCH_GEOCODE_POINT_FAILURE]({
                    name,
                    payload: error,
                })
            )
    );
};
