import React from 'react';

import SendIcon from '@mui/icons-material/Send';
import SyncIcon from '@mui/icons-material/Sync';
import { styled } from '@mui/material/styles';
import isArray from 'lodash/isArray';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Box, Button, Typography } from 'sunwise-ui';

import {
    getFileExtensionByPath,
    isCompressedFileSupported,
    isImageFileSupported,
} from 'common/utils/helpers';

import { ReactComponent as TextDocument } from 'resources/textDocument.svg';

import { DOCUMENT_STATUS } from '../../constants';

import FileUploader from './components/FileUploader';
import FileUploaderButton from './components/FileUploaderButton';
import PreDownloadUploader from './components/PreDownloadUploader';
import * as selectors from './selectors';

const StyledForm = styled('form')`
    display: flex;
    flex-direction: column;
    height: 100%;
`;

const FilePreview = styled(Box)`
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    width: 100%;
    img {
        max-height: 90%;
        max-width: 100%;
    }
`;

const Actions = styled(Box)`
    bottom: 50px;
    display: flex;
    gap: 16px;
    justify-content: center;
    position: absolute;
    text-align: center;
    width: 50%;
`;

const ReplaceContainer = styled(Box)`
    z-index: 1;
`;

const RejextedContainer = styled(Box)`
    height: 100%;
    position: relative;
    width: 100%;
`;

const InputFileOverlayed = styled(Box)`
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 2;
`;

const PreviewContent = styled(Box)`
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 1;
`;

const FormContainer = ({
    handleSend,
    initialFile,
    initialValues,
    isPreDownloadFile,
    status,
}) => {
    const { t } = useTranslation();
    const { control, handleSubmit, setValue, watch } = useForm({
        defaultValues: initialValues,
    });

    const fileValue = watch('file');
    const removeFile = () => setValue('file', null);
    const handleSave = (values) =>
        handleSend(values.file && values.file[0], () => removeFile());

    const renderPreview = (
        tempUrl,
        isImage,
        isCompressedFile,
        isFileLoaded
    ) => {
        if (isImage) return <img alt="" src={tempUrl} />;
        if (isCompressedFile || isFileLoaded) return <TextDocument />;
        return (
            <embed
                height="100%"
                src={`https://drive.google.com/viewerng/viewer?embedded=true&url=${tempUrl}`}
                width="100%"
            />
        );
    };

    const finalUrl = initialFile?.includes('https')
        ? initialFile
        : `${process.env.REACT_APP_API_URL}${initialFile}`;

    const renderContent = () => {
        if (
            status === DOCUMENT_STATUS.REJECTED_STATUS.key &&
            initialFile !== null &&
            !isArray(fileValue)
        )
            return (
                <RejextedContainer>
                    <PreviewContent>
                        <FilePreview>
                            {renderPreview(
                                finalUrl,
                                isImageFileSupported(finalUrl),
                                isCompressedFileSupported(finalUrl)
                            )}
                            <Typography variant="body2" fontWeight="bold" p={2}>
                                {t('{{extension}} file', {
                                    extension:
                                        getFileExtensionByPath(
                                            finalUrl
                                        ).toUpperCase(),
                                })}
                            </Typography>
                        </FilePreview>
                    </PreviewContent>
                    <InputFileOverlayed>
                        <FileUploader
                            control={control}
                            name="file"
                            opacity="0.7"
                            setValue={setValue}
                        />
                        ;
                    </InputFileOverlayed>
                </RejextedContainer>
            );

        if (
            isPreDownloadFile &&
            (status === DOCUMENT_STATUS.REQUESTED_STATUS.key ||
                status === DOCUMENT_STATUS.NO_DOCUMENT_STATUS.key) &&
            initialFile !== null &&
            !isArray(fileValue)
        )
            return (
                <PreDownloadUploader
                    control={control}
                    name="file"
                    initialFile={initialFile}
                    setValue={setValue}
                />
            );

        if (!isArray(fileValue) && initialFile === null)
            return (
                <FileUploader
                    control={control}
                    name="file"
                    setValue={setValue}
                />
            );

        if (isArray(fileValue))
            return (
                <>
                    <FilePreview>
                        {renderPreview(
                            fileValue[0]?.preview,
                            isImageFileSupported(fileValue[0]?.path),
                            isCompressedFileSupported(fileValue[0]?.path),
                            true
                        )}
                        <Typography variant="body2" fontWeight="bold" p={2}>
                            {t('{{extension}} file', {
                                extension: getFileExtensionByPath(
                                    fileValue[0]?.path
                                ).toUpperCase(),
                            })}
                        </Typography>
                    </FilePreview>

                    <Actions>
                        <Button
                            onClick={handleSubmit(handleSave)}
                            startIcon={<SendIcon />}
                        >
                            {t('Send')}
                        </Button>

                        <Button
                            color="secondary"
                            onClick={removeFile}
                            startIcon={<SyncIcon />}
                        >
                            {t('Change')}
                        </Button>
                    </Actions>
                </>
            );

        return (
            <FilePreview>
                {status !== DOCUMENT_STATUS.APPROVED_STATUS.key && (
                    <ReplaceContainer>
                        <FileUploaderButton
                            control={control}
                            name="file"
                            setValue={setValue}
                        />
                    </ReplaceContainer>
                )}
                {renderPreview(
                    finalUrl,
                    isImageFileSupported(finalUrl),
                    isCompressedFileSupported(finalUrl)
                )}
                <Typography variant="body2" fontWeight="bold" p={2}>
                    {t('{{extension}} file', {
                        extension:
                            getFileExtensionByPath(finalUrl).toUpperCase(),
                    })}
                </Typography>
            </FilePreview>
        );
    };

    return <StyledForm>{renderContent()}</StyledForm>;
};

const mapStateToProps = createStructuredSelector({
    initialValues: selectors.getInitialValues,
});

FormContainer.propTypes = {
    handleSend: PropTypes.func,
    initialFile: PropTypes.string,
    initialValues: PropTypes.object,
    isPreDownloadFile: PropTypes.bool,
    status: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default connect(mapStateToProps)(FormContainer);
