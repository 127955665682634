import i18next from 'i18next';

import { deleteItem } from 'common/api/v2/generationProfiles';
import alerts from 'common/modules/alerts';
import { showReponseErrorsAsAlert } from 'common/utils/helpers';
import showToast from 'common/utils/showToast';

import {
    DELETE_GENERATION_PROFILE,
    DELETE_GENERATION_PROFILE_FAILURE,
    DELETE_GENERATION_PROFILE_SUCCESS,
} from '../actionTypes';
import { solarGenerationActions } from '../reducer';

import fetchGenerationProfile from './fetchGenerationProfile';

export default (id, political_division1) => (dispatch) => {
    dispatch(solarGenerationActions[DELETE_GENERATION_PROFILE]());
    deleteItem(id)
        .then(() => {
            dispatch(
                solarGenerationActions[DELETE_GENERATION_PROFILE_SUCCESS]()
            );

            dispatch(fetchGenerationProfile(political_division1));

            dispatch(alerts.actions.close());
            showToast({ body: i18next.t('It was successfully removed') });
        })
        .catch((error) => {
            dispatch(
                solarGenerationActions[DELETE_GENERATION_PROFILE_FAILURE](
                    error?.response?.data?.errors
                )
            );
            showReponseErrorsAsAlert(dispatch, error?.response);
        });
};
