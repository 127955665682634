import React, { useState } from 'react';

import SolarPowerIcon from '@mui/icons-material/SolarPower';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Card, Typography } from 'sunwise-ui';

import * as selectors from '../../selectors';

import IrradiationChart from './IrradiationChart';
import IrradiationForm from './IrradiationForm';

const IrradiationCard = ({
    hasChanges,
    initialValues,
    segments,
    setHasChanges,
}) => {
    const { t } = useTranslation();

    const [formValues, setFormValues] = useState({
        source: initialValues.source,
        losses: initialValues.losses,
    });

    return (
        <>
            <Typography
                fontWeight="bold"
                sx={{ display: 'flex', gap: 1, mb: 2 }}
                variant="body2"
            >
                <SolarPowerIcon /> {t('Generation')}
            </Typography>

            <Card>
                <Card.Body>
                    <IrradiationForm
                        segments={segments}
                        setFormValues={setFormValues}
                        setHasChanges={setHasChanges}
                    />

                    <IrradiationChart
                        hasChanges={hasChanges}
                        segments={segments}
                        formValues={formValues}
                    />
                </Card.Body>
            </Card>
        </>
    );
};

const mapStateToProps = createStructuredSelector({
    initialValues: selectors.getInitialIrradiationValues,
});

IrradiationCard.propTypes = {
    hasChanges: PropTypes.bool,
    initialValues: PropTypes.object,
    segments: PropTypes.array,
    setHasChanges: PropTypes.func,
};

export default connect(mapStateToProps, null)(IrradiationCard);
