import { getCompatibilityData } from 'common/api/v1/equipmentCompatibility';

import {
    FETCH_COMPATIBILITY_DATA,
    FETCH_COMPATIBILITY_DATA_FAILURE,
    FETCH_COMPATIBILITY_DATA_SUCCESS,
} from '../actionTypes';
import { equipmentCompatibilityActions } from '../reducer';

import initializeForm from './initializeForm';

export default (
        selectedPanel,
        selectedInverter,
        political_division1,
        political_division2,
        setValue
    ) =>
    (dispatch) => {
        dispatch(equipmentCompatibilityActions[FETCH_COMPATIBILITY_DATA]());

        const values = {
            product_panel: selectedPanel,
            product_inverter: selectedInverter,
            political_division1: political_division1,
            political_division2: political_division2,
        };

        getCompatibilityData(values)
            .then((response) => {
                dispatch(
                    equipmentCompatibilityActions[
                        FETCH_COMPATIBILITY_DATA_SUCCESS
                    ](response.data.data)
                );

                const fetchValues = {
                    ...response.data.data,
                    product_panel: selectedPanel,
                    product_inverter: selectedInverter,
                    political_division1: political_division1,
                    political_division2: political_division2,
                    temp: [
                        response.data.data.temp_min,
                        response.data.data.temp_max,
                    ],
                };

                dispatch(initializeForm(fetchValues));

                setValue('oversize', response.data.data.oversize);
                setValue('temp', [
                    response.data.data.temp_min,
                    response.data.data.temp_max,
                ]);
            })
            .catch((error) =>
                dispatch(
                    equipmentCompatibilityActions[
                        FETCH_COMPATIBILITY_DATA_FAILURE
                    ](error?.response?.data?.errors)
                )
            );
    };
