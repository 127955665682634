import React, { useEffect } from 'react';

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { styled } from '@mui/material/styles';
import { push } from 'connected-react-router';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Card, Grid, Typography } from 'sunwise-ui';

import { DropdownCardMenu } from 'common/components/cards';

import IntegrationWebLogo from 'resources/IntegrationWebLogo.svg';

import * as actions from '../actions';
import { EMBEDDED_PROPOSAL } from '../constants';
import * as selectors from '../selectors';

const StyledImage = styled('img')`
    max-width: 100%;
`;

const Integrations = ({
    canDelete,
    canModify,
    fetchIntegrations,
    handleClickRename,
    integrations = [],
    prepareDelete,
    redirect,
}) => {
    useEffect(() => {
        fetchIntegrations();
    }, []);

    const { t } = useTranslation();

    if (!integrations.length) {
        return <p>{t('No items')}</p>;
    }

    const handleRedirect = (item) =>
        redirect(
            `/web-quoter/${item.id}/${item.reference ? item.reference : ''}`
        );

    const handleIntegrationTypeImage = (type) => {
        switch (type) {
            case EMBEDDED_PROPOSAL:
                return IntegrationWebLogo;
            default:
                return '';
        }
    };

    return (
        <Grid container>
            {integrations.map((item) => (
                <Grid item xs={18} md={6} key={item.id}>
                    <Card>
                        <Card.Header
                            sx={{
                                alignItems: 'center',
                                display: 'flex',
                                padding: '8px 16px',
                            }}
                        >
                            <Typography fontWeight="bold" variant="title">
                                {item.name}
                            </Typography>
                            <DropdownCardMenu
                                items={[
                                    {
                                        handleClick: () => handleRedirect(item),
                                        icon: (
                                            <VisibilityIcon fontSize="small" />
                                        ),
                                        label: t('View integration'),
                                    },
                                    {
                                        handleClick: () =>
                                            handleClickRename(item),
                                        icon: <EditIcon fontSize="small" />,
                                        label: t('Change name'),
                                        visible: canModify,
                                    },
                                    {
                                        handleClick: () => prepareDelete(item),
                                        icon: <DeleteIcon fontSize="small" />,
                                        label: t('Delete'),
                                        visible: canDelete,
                                    },
                                ]}
                                sxIconButton={{ ml: 'auto' }}
                            />
                        </Card.Header>
                        <Card.Body sx={{ textAlign: 'center' }}>
                            <StyledImage
                                src={handleIntegrationTypeImage(item.type)}
                            />
                        </Card.Body>
                    </Card>
                </Grid>
            ))}
        </Grid>
    );
};

Integrations.propTypes = {
    canDelete: PropTypes.bool,
    canModify: PropTypes.bool,
    fetchIntegrations: PropTypes.func,
    handleClickRename: PropTypes.func,
    integrations: PropTypes.array,
    prepareDelete: PropTypes.func,
    redirect: PropTypes.func,
};

const mapStateToProps = createStructuredSelector({
    integrations: selectors.getIntegrationsData,
});

const mapDispatchToProps = (dispatch) => ({
    redirect: (link) => dispatch(push(link)),
    fetchIntegrations: () => dispatch(actions.fetchIntegrations()),
    prepareDelete: (item) => dispatch(actions.prepareDelete(item)),
    handleClickRename: (item) => dispatch(actions.prepareRename(item)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Integrations);
