import i18next from 'i18next';

import { bulkPlans } from 'common/api/v1/plans';
import { showReponseErrorsAsAlert } from 'common/utils/helpers';
import showToast from 'common/utils/showToast';

import {
    BULK_UPDATE_ITEMS,
    BULK_UPDATE_ITEMS_FAILURE,
    BULK_UPDATE_ITEMS_SUCCESS,
} from '../actionTypes';
import { planConfigurationActions } from '../reducer';

import filterItems from './filterItems';

export default (ids, value, filterData, callback) => (dispatch) => {
    dispatch(planConfigurationActions[BULK_UPDATE_ITEMS]());

    const plans = ids.map((id) => ({ id, status_flag: value }));

    bulkPlans({ plans })
        .then(() => {
            dispatch(planConfigurationActions[BULK_UPDATE_ITEMS_SUCCESS]());
            dispatch(filterItems(filterData));
            if (value === 'deleted')
                showToast({ body: i18next.t('It was successfully removed') });
            else showToast();
            callback();
        })
        .catch((error) => {
            dispatch(
                planConfigurationActions[BULK_UPDATE_ITEMS_FAILURE](
                    error?.response?.data?.errors
                )
            );
            showReponseErrorsAsAlert(dispatch, error?.response);
        });
};
