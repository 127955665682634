import React from 'react';

import get from 'lodash/get';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Alert, Box, Typography } from 'sunwise-ui';

import MembersPlaceholder from 'resources/onboarding/members.svg';

import * as actions from '../actions';
import { getIsDisabledAddMemberButton } from '../helpers';
import * as selectors from '../selectors';

import ReduxFieldArrayMembers from './ReduxFieldArrayMembers';

const MembersStep = ({
    control,
    counters,
    formValues,
    getValues,
    isValidatingEmail,
    memberRoles,
    setValue,
    subscriptionType,
    validateEmail,
}) => {
    const { t } = useTranslation();
    const isDisabledAddMemberButton = getIsDisabledAddMemberButton(
        counters,
        get(formValues, 'members', []),
        subscriptionType
    );
    return (
        <>
            <Box
                sx={{
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 1,
                    mb: 2,
                    py: 1,
                    width: '100%',
                }}
            >
                <img src={MembersPlaceholder} />
                <Typography variant="h6">
                    {t('Add your team members')}
                </Typography>
                <Typography variant="body2">
                    {t(
                        'With your contracted plan you are free to add more members to send them an invitation and work together'
                    )}
                </Typography>
            </Box>
            <ReduxFieldArrayMembers
                control={control}
                formValues={formValues}
                getValues={getValues}
                isDisabledAddMemberButton={isDisabledAddMemberButton}
                isValidatingEmail={isValidatingEmail}
                memberRoles={memberRoles}
                name="members"
                setValue={setValue}
                validateEmail={validateEmail}
            />
            {isDisabledAddMemberButton && (
                <Alert severity="warning">
                    <Typography fontWeight="bold" variant="body2">
                        {t("You have reached your plan's user limit")}
                    </Typography>
                </Alert>
            )}
        </>
    );
};

const mapStateToProps = createStructuredSelector({
    isValidatingEmail: selectors.getIsValidatingEmail,
    memberRoles: selectors.getMemberRolesData,
});

const mapDispatchToProps = (dispatch) => ({
    validateEmail: (values, callback) =>
        dispatch(actions.validateEmail(values, callback)),
});

MembersStep.propTypes = {
    control: PropTypes.object,
    counters: PropTypes.object,
    formValues: PropTypes.object,
    getValues: PropTypes.func,
    isValidatingEmail: PropTypes.bool,
    memberRoles: PropTypes.array,
    setValue: PropTypes.func,
    subscriptionType: PropTypes.number,
    validateEmail: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(MembersStep);
