import { updateContact } from 'common/api/v1/contacts';
import { showReponseErrorsAsAlert } from 'common/utils/helpers';
import showToast from 'common/utils/showToast';

import {
    SAVE_CONTACT,
    SAVE_CONTACT_FAILURE,
    SAVE_CONTACT_SUCCESS,
    UPDATE_ITEMS_SUCCESS,
} from '../actionTypes';
import { actions } from '../reducer';
import * as selectors from '../selectors';

export default (values) => (dispatch, getState) => {
    const state = getState();
    const data = selectors.getContactsData(state);

    if (!values.id) return;

    const newValues = {
        emails: JSON.stringify(values.emails),
        first_lastname: values.first_lastname,
        name: values.name,
        second_lastname: values.second_lastname,
    };

    dispatch(actions[SAVE_CONTACT]());

    updateContact(newValues, values.id)
        .then((response) => {
            dispatch(actions[SAVE_CONTACT_SUCCESS](response.data));
            const newItems = data.map((item) =>
                item.id === values.id ? values : item
            );
            dispatch(actions[UPDATE_ITEMS_SUCCESS](newItems));
            showToast();
        })
        .catch((error) => {
            dispatch(
                actions[SAVE_CONTACT_FAILURE](error?.response?.data?.errors)
            );
            showReponseErrorsAsAlert(dispatch, error?.response);
        });
};
