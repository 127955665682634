import { bulkTrackings } from 'common/api/v1/followUps';
import { showReponseErrorsAsAlert } from 'common/utils/helpers';
import showToast from 'common/utils/showToast';

import {
    BULK_UPDATE_ITEMS,
    BULK_UPDATE_ITEMS_FAILURE,
    BULK_UPDATE_ITEMS_SUCCESS,
    UPDATE_ITEMS_SUCCESS,
} from '../actionTypes';
import { followUpsActions } from '../reducer';
import * as selectors from '../selectors';

export default (ids, callback, value = true) =>
    (dispatch, getState) => {
        const trackings = selectors.getContactsTrackingData(getState());

        const newValues = {
            completed: value,
            trackings: ids,
        };

        dispatch(followUpsActions[BULK_UPDATE_ITEMS]());

        bulkTrackings(newValues)
            .then(() => {
                dispatch(followUpsActions[BULK_UPDATE_ITEMS_SUCCESS]());
                const newItems = trackings.map((item) => {
                    if (ids.includes(item.id)) {
                        return {
                            ...item,
                            completed: value,
                        };
                    }
                    return item;
                });
                dispatch(followUpsActions[UPDATE_ITEMS_SUCCESS](newItems));
                showToast();
                callback();
            })
            .catch((error) => {
                dispatch(
                    followUpsActions[BULK_UPDATE_ITEMS_FAILURE](
                        error?.response?.data?.errors
                    )
                );
                showReponseErrorsAsAlert(dispatch, error?.response);
            });
    };
