import React from 'react';

import CachedIcon from '@mui/icons-material/Cached';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { createStructuredSelector } from 'reselect';
import { Box, Button } from 'sunwise-ui';

import {
    ReactHookFormIntlNumberInput,
    ReactHookFormSelect,
    ReactHookFormSwitch,
} from 'common/components/form/bootstrap';
import yupResolver from 'common/utils/yupResolver';

import * as actions from '../../actions';
import * as selectors from '../../selectors';
import validate from '../../validateIrradiation';

const IrradiationCard = ({
    initialValues,
    match,
    save,
    segments,
    setFormValues,
    setHasChanges,
}) => {
    const { t } = useTranslation();
    const {
        params: { uid },
    } = match;

    const { control, handleSubmit, watch } = useForm({
        defaultValues: initialValues,
        resolver: yupResolver(validate),
    });

    const [source, bifaciality] = watch(['source', 'bifaciality']);

    const handleOnSubmit = (values) =>
        save(uid, values, () => {
            setFormValues((prevValues) => ({
                ...prevValues,
                losses: values.losses,
                source: values.source,
            }));

            setHasChanges(() => false);
        });

    const options = [
        { disabled: true, label: t('Select'), value: '' },
        { label: 'PVWatts', value: 'PV_WATTS' },
        { label: 'PVGIS', value: 'PV_GIS' },
    ];

    if (!Array.isArray(segments) || segments === 0) return null;

    const isDisabled = segments.length === 0;

    return (
        <form onSubmit={handleSubmit(handleOnSubmit)}>
            <ReactHookFormSelect
                control={control}
                disabled={isDisabled}
                label={t('Source')}
                name="source"
                options={options}
            />

            <ReactHookFormIntlNumberInput
                allowNegativeValue={false}
                append="%"
                control={control}
                disabled={isDisabled}
                fullWidth
                label={t('Loss')}
                max={100}
                min={0}
                name="losses"
            />

            {source === 'PV_WATTS' && (
                <>
                    <Box sx={{ mb: 2 }}>
                        <ReactHookFormSwitch
                            control={control}
                            fullWidth
                            label={t('Bifacial')}
                            name="bifaciality"
                        />
                    </Box>

                    <ReactHookFormIntlNumberInput
                        allowNegativeValue={false}
                        append="%"
                        control={control}
                        disabled={isDisabled || !bifaciality}
                        fullWidth
                        label={t('Efficiency')}
                        max={100}
                        min={0}
                        name="bifaciality_param"
                    />
                </>
            )}

            <Button
                disabled={isDisabled}
                endIcon={<CachedIcon />}
                fullWidth
                sx={{ mb: 2 }}
                type="submit"
                variant="outlined"
            >
                {t('Simulate generation')}
            </Button>
        </form>
    );
};

const mapStateToProps = createStructuredSelector({
    initialValues: selectors.getInitialIrradiationValues,
});

const mapDispatchToProps = (dispatch) => ({
    save: (uuid, values, callback) =>
        dispatch(actions.saveIrradiationData(uuid, values, callback)),
});

IrradiationCard.propTypes = {
    initialValues: PropTypes.object,
    match: PropTypes.object,
    save: PropTypes.func,
    segments: PropTypes.array,
    setFormValues: PropTypes.func,
    setHasChanges: PropTypes.func,
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withRouter
)(IrradiationCard);
