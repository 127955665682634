import { createSelector } from 'reselect';

import { NAME } from './constants';

export const getModel = (state) => state[NAME];

export const getInitialValues = createSelector(
    getModel,
    (model) => model.initialValues
);

// CREATE

export const getCreate = createSelector(getModel, (model) => model.create);

export const getIsCreating = createSelector(
    getCreate,
    (value) => value.isCreating
);

export const getCreateData = createSelector(
    getCreate,
    (value) => value.data || {}
);

export const getCreateErrors = createSelector(
    getCreate,
    (value) => value.errors
);

// FETCH OFFER PANEL

export const getOfferPanel = createSelector(
    getModel,
    (model) => model.offerPanel
);

export const getIsFetchingOfferPanel = createSelector(
    getOfferPanel,
    (value) => value.isFetching
);

export const getOfferPanelData = createSelector(
    getOfferPanel,
    (value) => value.data || []
);

export const getOfferPanelErrors = createSelector(
    getOfferPanel,
    (value) => value.errors
);

// SAVE

export const getSave = createSelector(getModel, (model) => model.save);

export const getIsSaving = createSelector(getSave, (value) => value.isSaving);

export const getSaveData = createSelector(getSave, (value) => value.data || []);

export const getErrorsSave = createSelector(getSave, (value) => value.errors);

export const getIsUpdating = createSelector(
    getIsCreating,
    getIsSaving,
    (isCreating, isSaving) => isCreating || isSaving
);
