import i18next from 'i18next';

import { deleteItem } from 'common/api/v1/companyTaxes';
import alerts from 'common/modules/alerts';
import { showReponseErrorsAsAlert } from 'common/utils/helpers';
import showToast from 'common/utils/showToast';

import {
    DELETE_TAXES,
    DELETE_TAXES_FAILURE,
    DELETE_TAXES_SUCCESS,
} from '../actionTypes';
import { taxesConfigurationActions } from '../reducer';

import fetchTaxes from './fetchTaxes';
import resetTaxes from './resetTaxes';

export default (id) => (dispatch) => {
    dispatch(taxesConfigurationActions[DELETE_TAXES]());

    deleteItem(id)
        .then(() => {
            dispatch(taxesConfigurationActions[DELETE_TAXES_SUCCESS](null));
            dispatch(alerts.actions.close());
            dispatch(resetTaxes());
            dispatch(fetchTaxes());

            showToast({ body: i18next.t('It was successfully removed') });
        })
        .catch((error) => {
            dispatch(
                taxesConfigurationActions[DELETE_TAXES_FAILURE](
                    error?.response?.data?.errors
                )
            );
            showReponseErrorsAsAlert(dispatch, error?.response);
        });
};
