import { getItem } from 'common/api/v2/generationProfiles';
import { getDataProfileFormatted } from 'common/utils/helpersChart';

import {
    FETCH_GENERATION_PROFILE,
    FETCH_GENERATION_PROFILE_FAILURE,
    FETCH_GENERATION_PROFILE_SUCCESS,
} from '../actionTypes';
import { solarGenerationActions } from '../reducer';

export default (politicalDivision) => (dispatch) => {
    dispatch(solarGenerationActions[FETCH_GENERATION_PROFILE]());

    getItem(politicalDivision)
        .then((response) => {
            const data = response.data.data;
            const generation_profile_formatted = getDataProfileFormatted(
                data.generation,
                data.year || 2019
            );
            dispatch(
                solarGenerationActions[FETCH_GENERATION_PROFILE_SUCCESS]({
                    ...data,
                    generation_profile_formatted,
                })
            );
        })
        .catch((error) =>
            dispatch(
                solarGenerationActions[FETCH_GENERATION_PROFILE_FAILURE](error)
            )
        );
};
