import React from 'react';

import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';

import { isValidAccess } from 'common/utils/helpers/permissionsV2';
import {
    getHasOnboarding,
    getHasPaymentDue,
    getIsMexicanAccount,
} from 'common/utils/helpers/session';

import LayoutComponent from './LayoutComponent';
import LayoutPublicComponent from './LayoutPublicComponent';

const LayoutRoute = ({
    checkOnboarding,
    checkPaymentDue,
    component,
    isHidden,
    isPublic,
    layout,
    multiBranchMode,
    onlyForMexico,
    redirect = { pathname: '/login' },
    requiredPermissions,
    ...rest
}) => {
    const hasOnboarding = getHasOnboarding();
    const hasPaymentDue = getHasPaymentDue();
    const isMexicanAccount = getIsMexicanAccount();

    if (checkPaymentDue && hasPaymentDue)
        return <Redirect to={{ pathname: '/profile/subscription' }} />;
    if (checkOnboarding && hasOnboarding)
        return <Redirect to={{ pathname: '/onboarding' }} />;
    if (isHidden || (onlyForMexico && !isMexicanAccount))
        return <Redirect to={redirect} />;
    if (!isValidAccess(isPublic, requiredPermissions))
        return <Redirect to={redirect} />;

    return (
        <Route
            {...rest}
            render={(matchProps) =>
                isPublic ? (
                    <LayoutPublicComponent
                        component={component}
                        layout={layout}
                        matchProps={matchProps}
                        rest={rest}
                    />
                ) : (
                    <LayoutComponent
                        component={component}
                        layout={layout}
                        matchProps={matchProps}
                        multiBranchMode={multiBranchMode}
                        rest={rest}
                    />
                )
            }
        />
    );
};

LayoutRoute.defaultProps = {
    checkOnboarding: false,
    checkPaymentDue: false,
    isHidden: false,
    isPublic: false,
    onlyForMexico: false,
    redirect: { pathname: '/login' },
};

LayoutRoute.propTypes = {
    checkOnboarding: PropTypes.bool,
    checkPaymentDue: PropTypes.bool,
    component: PropTypes.func,
    isHidden: PropTypes.bool,
    isPublic: PropTypes.bool,
    layout: PropTypes.func,
    multiBranchMode: PropTypes.oneOfType([PropTypes.bool, PropTypes.array]),
    onlyForMexico: PropTypes.bool,
    redirect: PropTypes.object,
    requiredPermissions: PropTypes.array,
};

export default LayoutRoute;
