import { SET_IS_INFO_CONSISTENT } from '../actionTypes';
import { advancedTimeShiftSettingsActions } from '../reducer';

export default ({ timeShiftingData, offerStorageData }) =>
    (dispatch) => {
        const { battery_offer, storage_offer } = offerStorageData || {};

        const { quantity, storage_cap } = battery_offer?.[0] || {};
        const energyForTimeShifting =
            quantity *
                (storage_cap / 1000) *
                (1 - storage_offer?.storage_percent) || 0;

        dispatch(
            advancedTimeShiftSettingsActions[SET_IS_INFO_CONSISTENT](
                energyForTimeShifting?.toFixed(2) ===
                    Number(timeShiftingData?.load_capacity)?.toFixed(2)
            )
        );
    };
