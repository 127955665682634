import { createSelector } from 'reselect';

import { NAME } from './constants';

export const getModel = (state) => state[NAME];

export const getContact = createSelector(getModel, (model) => model.contact);

export const getDataContact = createSelector(
    getContact,
    (contact) => contact.data
);

export const getIsLoadingContact = createSelector(
    getContact,
    (contact) => contact.isLoading
);

export const getStatusContact = createSelector(
    getModel,
    (model) => model.statusContact
);

export const getStatusContactToSelect = createSelector(
    getStatusContact,
    (statusContacts) => getStatusContactToDropdown(statusContacts)
);

const getStatusContactToDropdown = (statusContacts) =>
    statusContacts.data
        ? statusContacts.data.map((statusContact) => ({
              value: statusContact.id,
              label: statusContact.name,
          }))
        : [];

export const getFetchPosition = createSelector(
    getModel,
    (model) => model.fetchPositions
);

export const getPositionData = createSelector(
    getFetchPosition,
    (fetchPositions) => fetchPositions.data || {}
);

export const getIsLoadingPosition = createSelector(
    getFetchPosition,
    (fetchPositions) => fetchPositions.isLoading || false
);

export const getSavePosition = createSelector(
    getModel,
    (model) => model.savePosition
);

export const getSavePositionErrors = createSelector(
    getSavePosition,
    (savePosition) => savePosition.errors
);
