import React, { useEffect, useRef, useState } from 'react';

import { styled } from '@mui/material/styles';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroller';
import { Box, Divider, List, Typography } from 'sunwise-ui';

import { scroll } from 'common/utils/mixins';

import { getDateTitle, handleClickNotificationBuild } from '../helpers';

import NotificationItem from './NotificationItem';

const NotificationsWrapper = styled(Box)`
    display: flex;
    flex-direction: column;
    height: 570px;
    overflow-y: scroll;
    padding-right: 8px;
    width: 100%;
    ${scroll.custom}
`;

const NotificationsList = ({
    fetchNotificationsPerPage,
    filterData,
    groupedNotifications,
    isLoading,
    pagination,
    redirect,
    setCheckedAll,
    updateNotification,
    updateNotificationsTemp,
}) => {
    const { t } = useTranslation();
    const [scrollTop, setScrollTop] = useState(0);
    const wrapperRef = useRef(null);
    const infiniteRef = useRef(null);

    const handleScroll = () => {
        if (wrapperRef.current) {
            const { scrollTop, scrollHeight, clientHeight } =
                wrapperRef.current;
            const scrollThreshold = 0.9; // Umbral del 90%
            if (scrollTop / (scrollHeight - clientHeight) >= scrollThreshold) {
                setScrollTop(scrollTop);
            }
        }
    };

    useEffect(() => {
        const wrapper = wrapperRef.current;
        if (wrapper) {
            wrapper.addEventListener('scroll', handleScroll);
            return () => {
                wrapper.removeEventListener('scroll', handleScroll);
            };
        }
    }, []);

    useEffect(() => {
        if (infiniteRef?.current) {
            infiniteRef.current.pageLoaded = 1;
            setScrollTop(0);
        }
    }, [filterData.type, filterData.viewed]);

    useEffect(() => {
        if (wrapperRef.current) {
            wrapperRef.current.scrollTop = scrollTop;
        }
    }, [groupedNotifications]);

    const handleClickMarkAsReaded = (notification) =>
        updateNotification(filterData, notification);

    const handleClickNotification = (notification) =>
        handleClickNotificationBuild(
            handleClickMarkAsReaded,
            notification,
            redirect
        );

    const renderNotifications = () => {
        if (!isLoading && isEmpty(groupedNotifications))
            return (
                <Box p={2} sx={{ textAlign: 'center' }}>
                    <Typography variant="caption">
                        {t('No notifications for now')}
                    </Typography>
                </Box>
            );

        return groupedNotifications.map((group, index) => (
            <Box key={`date-${group.date}-${index}`}>
                <Box py={1} textAlign="right">
                    <Typography fontWeight="bold" variant="caption">
                        {getDateTitle(group?.date)}
                    </Typography>
                </Box>
                <Divider sx={{ mb: 2 }} />
                <List component="nav">
                    {group?.notifications.map((notification) => (
                        <NotificationItem
                            handleClickNotification={handleClickNotification}
                            key={`notification-item-${notification.id}`}
                            notification={notification}
                            setCheckedAll={setCheckedAll}
                            updateNotificationsTemp={updateNotificationsTemp}
                            withCheckbox={true}
                        />
                    ))}
                </List>
            </Box>
        ));
    };

    return (
        <NotificationsWrapper ref={wrapperRef}>
            <InfiniteScroll
                getScrollParent={() => wrapperRef.current}
                hasMore={
                    !isLoading &&
                    pagination?.current_page < pagination?.total_pages
                }
                ref={infiniteRef}
                loader={null}
                loadMore={(nextPage) => {
                    if (isLoading) return;
                    const { pageSize, showMentions, type, viewed } = filterData;
                    fetchNotificationsPerPage({
                        page: nextPage,
                        pageSize,
                        showMentions,
                        type,
                        viewed,
                    });
                }}
                pageStart={1}
                threshold={100}
                useWindow={false}
            >
                {renderNotifications()}
            </InfiniteScroll>
        </NotificationsWrapper>
    );
};

NotificationsList.propTypes = {
    fetchNotificationsPerPage: PropTypes.func,
    filterData: PropTypes.object,
    groupedNotifications: PropTypes.array,
    isLoading: PropTypes.bool,
    pagination: PropTypes.object,
    redirect: PropTypes.func,
    setCheckedAll: PropTypes.func,
    updateNotification: PropTypes.func,
    updateNotificationsTemp: PropTypes.func,
};

export default NotificationsList;
