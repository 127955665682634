import React, { useState } from 'react';

import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box, Grid, ToggleButton, ToggleButtonGroup } from 'sunwise-ui';

import { ReactHookFormIntlNumberInput } from 'common/components/form/bootstrap';
import { convertCurrency } from 'common/utils/helpers';

const StyledToggleButtonGroup = styled(ToggleButtonGroup)`
    margin-left: 8px;
    & .MuiToggleButton-primary {
        color: #545759;
        padding: 6px 12px;
        &.Mui-selected {
            background-color: #dcdbdc;
        }
    }
`;

const COST_TYPE = { COST: 0, COST_BY_SIZE: 1 };

const CostColumn = ({
    canModify,
    canModifyCost,
    control,
    currencyIso,
    currencySymbol = '$',
    formValues,
    handleNormalizeField,
    handleNormalizeFields,
    hasCostBySize = false,
    index,
    initialValues,
    isDisabledField,
    isHidden,
    name,
    size,
    sizeUnit = 'W',
    totalSize = 1,
    typeChange,
}) => {
    const [selected, setSelected] = useState(0);
    const { t } = useTranslation();

    if (isHidden) return null;

    const isDisabledCost = isDisabledField || !canModifyCost;

    if (canModifyCost || (!canModify && canModifyCost)) {
        return (
            <Grid item xs lg={size.cost[0]}>
                <Box display="flex" alignItems="center">
                    {selected === 0 ? (
                        <ReactHookFormIntlNumberInput
                            control={control}
                            disabled={isDisabledCost}
                            fullWidth
                            key={`${name}.${index}.cost`}
                            label={t('Cost')}
                            min={0}
                            name={`${name}.${index}.cost`}
                            onChange={({ target: { value } }) => {
                                handleNormalizeField(
                                    index,
                                    handleNormalizeFields
                                )(
                                    value,
                                    initialValues?.[name]?.[index]?.cost,
                                    formValues,
                                    name
                                );
                            }}
                            step="any"
                            variant="standard"
                        />
                    ) : (
                        <ReactHookFormIntlNumberInput
                            control={control}
                            decimalsLimit={4}
                            disabled={isDisabledCost}
                            fullWidth
                            key={`${name}.${index}.cost_by_size`}
                            label={t('Cost')}
                            min={0}
                            name={`${name}.${index}.cost_by_size`}
                            onChange={({ target: { value } }) => {
                                const costByDollar = convertCurrency({
                                    amount: value,
                                    exchangeRate: typeChange,
                                    fromCurrency: 'USD',
                                    toCurrency: currencyIso,
                                });
                                handleNormalizeField(
                                    index,
                                    handleNormalizeFields
                                )(
                                    costByDollar * totalSize,
                                    initialValues?.[name]?.[index]?.cost,
                                    formValues,
                                    'cost'
                                );
                            }}
                            step="any"
                            variant="standard"
                        />
                    )}

                    {hasCostBySize && (
                        <StyledToggleButtonGroup
                            color="primary"
                            exclusive
                            onChange={(e) =>
                                setSelected(parseInt(e.target.value))
                            }
                            value={selected}
                            size="small"
                        >
                            <ToggleButton value={COST_TYPE.COST}>
                                {currencySymbol}
                            </ToggleButton>
                            <ToggleButton value={COST_TYPE.COST_BY_SIZE}>
                                {`USD/${sizeUnit}`}
                            </ToggleButton>
                        </StyledToggleButtonGroup>
                    )}
                </Box>
            </Grid>
        );
    }
    return null;
};

CostColumn.propTypes = {
    canModify: PropTypes.bool,
    canModifyCost: PropTypes.bool,
    control: PropTypes.object,
    currencyIso: PropTypes.string,
    currencySymbol: PropTypes.string,
    formValues: PropTypes.object,
    handleNormalizeField: PropTypes.func,
    handleNormalizeFields: PropTypes.func,
    hasCostBySize: PropTypes.bool,
    index: PropTypes.number,
    initialValues: PropTypes.object,
    isDisabledField: PropTypes.bool,
    isHidden: PropTypes.bool,
    name: PropTypes.string,
    size: PropTypes.object,
    sizeUnit: PropTypes.string,
    totalSize: PropTypes.number,
    typeChange: PropTypes.number,
};

export default CostColumn;
