import { DAYS_IN_WEEK, HOURS_IN_DAY } from 'common/constants';
import { parseDate } from 'common/utils/dates';
import { numberFormat } from 'common/utils/helpers';
import { normalizeCsvDataToHourlyArray } from 'common/utils/helpers/csv';
import {
    createFilledArray,
    getChartCategories,
    getDataProfileFormatted,
    getDaysByWeek,
} from 'common/utils/helpersChart';

import { colors } from './constants';

const MONTHS_ARRAY = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

const getDataMonthlyChart = (generationProfile, monthSelected, series) => {
    const monthData = generationProfile[monthSelected];
    const totalDays = Object.values(monthData).length;
    let newSeries = [];

    for (const source of series) {
        let monthlyData = [];

        const _total = source.data[monthSelected - 1] * totalDays;
        const factor = _total / monthData.total;

        for (const day in monthData) {
            const dayData = monthData[day];

            if (!dayData?.total) continue;

            monthlyData.push(dayData.total * factor);
        }

        newSeries.push({ data: monthlyData, name: source.name });
    }
    return newSeries;
};

const getWeeklyData = (generationProfile, months, series, weeks) => {
    const daysInMonth = getDaysByWeek(weeks);
    const newSeries = [];
    const weeklyData = createFilledArray(DAYS_IN_WEEK, 0);

    for (const month of months) {
        if (!generationProfile[month]) continue;

        for (const day in daysInMonth) {
            if (!generationProfile[month][day]) continue;

            const dayType = generationProfile[month][day].day;

            const fixedDayType = dayType === 0 ? 6 : dayType - 1;

            weeklyData[fixedDayType] += generationProfile[month][day].total;
        }
    }

    const multiplier = months.length * months.length * weeks.length;

    for (const source of series) {
        const sunHoursFC = source.data.reduce((acc, curr, index) => {
            const monthProfile = generationProfile[index + 1];

            const totalDays = Object.values(monthProfile).length - 1;

            const factor = totalDays / multiplier / monthProfile.total;

            return months.includes(index + 1) ? acc + curr * factor || 0 : acc;
        }, 0);

        newSeries.push({
            data: weeklyData.map((value) => value * sunHoursFC),
            name: source.name,
        });
    }
    return newSeries;
};

const getDailyData = (generationProfile, months, days, series, weeks) => {
    const dailyData = createFilledArray(HOURS_IN_DAY, 0);
    const daysInMonth = getDaysByWeek(weeks);
    const newSeries = [];

    for (const month of months) {
        for (const day of daysInMonth) {
            if (!generationProfile[month][day]) continue;

            if (!days.includes(generationProfile[month]?.[day]?.day)) continue;

            for (let hour = 0; hour <= 23; hour++)
                dailyData[hour] += generationProfile[month][day][hour] || 0;
        }
    }

    const multiplier =
        days.length * months.length * months.length * weeks.length;

    for (const source of series) {
        const sunHoursFC = source.data.reduce((acc, curr, index) => {
            const monthProfile = generationProfile[index + 1];
            const totalDays = Object.values(monthProfile).length - 1;

            const factor = totalDays / multiplier / monthProfile.total;

            return months.includes(index + 1) ? acc + curr * factor || 0 : acc;
        }, 0);

        newSeries.push({
            data: dailyData.map((value) => value * sunHoursFC),
            name: source.name,
        });
    }
    return newSeries;
};

const getChartSeries = ({
    days,
    generationProfile,
    month,
    series,
    type,
    weeks,
}) => {
    if (!generationProfile) return series;
    const isAnnualType = month === 0;
    switch (type) {
        case 0:
            return series;
        case 1:
            return getDataMonthlyChart(generationProfile, month, series);

        case 2:
            return getWeeklyData(
                generationProfile,
                isAnnualType ? MONTHS_ARRAY : [month],
                series,
                weeks
            );
        case 3:
            return getDailyData(
                generationProfile,
                isAnnualType ? MONTHS_ARRAY : [month],
                days,
                series,
                weeks
            );
    }
};

// EXPORTS
export const getAvg = (values) => {
    if (!values || !Array.isArray(values)) return 0;

    const total = values.reduce((acc, curr) => acc + parseFloat(curr.value), 0);
    if (!total) return 0;

    return (total / values.length).toFixed(2);
};

export const getChartConfig = ({
    countryCurrencyLocale,
    days,
    generationProfile,
    month,
    series,
    type,
    weeks,
    year,
}) => ({
    options: {
        chart: {
            background: '#ffffff00',
            toolbar: {
                show: false,
            },
            zoom: {
                enabled: false,
            },
        },
        colors: colors,
        dataLabels: {
            enabled: false,
        },
        tooltip: {
            y: {
                formatter: (val) =>
                    numberFormat(val, {
                        locale: countryCurrencyLocale,
                        style: 'decimal',
                        unit: type === 3 ? 'kW / kWp' : 'kWh / kWp',
                    }),
            },
        },
        xaxis: {
            categories: getChartCategories(type, month, year),
        },
        yaxis: {
            min: 0,
            labels: {
                formatter: (val) =>
                    numberFormat(val, {
                        locale: countryCurrencyLocale,
                        style: 'decimal',
                    }),
            },
            title: {
                text: type === 3 ? 'kW / kWp' : 'kWh / kWp',
            },
        },
    },
    series: getChartSeries({
        days,
        generationProfile,
        month,
        series,
        type,
        weeks,
    }),
});

/** CSV Importer */
export const createGenerationProfile = (csvData, config) => {
    if (!csvData || !config) return;

    const { columnsFormat } = config;

    let format = columnsFormat?.date || 'dd/MM/yyyy';
    const hasTimeColumn = !format.includes('HH') && columnsFormat?.time;

    if (hasTimeColumn) format = `${format} ${columnsFormat.time}`;

    let firstDate = csvData[0].date;

    if (hasTimeColumn) firstDate = `${firstDate} ${csvData[0].time}`;

    const parsedDate = parseDate(firstDate, format);

    const year = parsedDate.getFullYear();

    const { resultData, totals } = normalizeCsvDataToHourlyArray({
        columnsFormat,
        columnsToFill: [
            { name: 'generation', returnTotal: true, resolution: 'sum' },
        ],
        generateDate: false,
        initialDateUTC: Date.UTC(year, 0, 1, 0, 0, 0, 0),
        rowsData: csvData,
    });

    const totalGeneration = totals?.generation / 100 || 0;

    const profile = resultData.map((row) =>
        (row.generation / totalGeneration || 0).toFixed(15)
    );
    const profileFormatted = getDataProfileFormatted(profile, year);

    return { name: config?.file?.name || '', profile, profileFormatted, year };
};
