import { downloadHistory } from 'common/api/v1/contactProject';
import showToast from 'common/utils/showToast';

import { DOWNLOAD_HISTORY, DOWNLOAD_HISTORY_FAILURE } from '../actionTypes';
import { projectConsumptionCardActions } from '../reducer';

import fetchConsumptionEnergy from './fetchConsumptionEnergy';

export default (projectId) => (dispatch) => {
    dispatch(projectConsumptionCardActions[DOWNLOAD_HISTORY]());

    downloadHistory(projectId)
        .then((response) => {
            dispatch(fetchConsumptionEnergy(projectId, false));

            showToast({ body: response.data });
        })
        .catch((error) =>
            dispatch(
                projectConsumptionCardActions[DOWNLOAD_HISTORY_FAILURE](
                    error?.response
                )
            )
        );
};
