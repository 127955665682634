import i18next from 'i18next';
import { isEmpty, isNull, toNumber } from 'lodash';

import { DAYS_IN_YEAR, GENERATION_SOURCES } from 'common/constants';
import { getDecimals } from 'common/utils/helpers';

import {
    buildCsv,
    normalizeCsvDataToHourlyArray,
} from '../../common/utils/helpers/csv';

import { SOURCE_OPTIONS } from './constants';

export const getAverageIrradiationCompleteValue = (
    averageIrradiation,
    generation = 100
) => {
    return averageIrradiation * (1 + generation / 100);
};

export const getHasFile = (initialValues) => {
    return (
        parseInt(initialValues.source) !== 0 &&
        (!isNull(initialValues.urlFile) || !isEmpty(initialValues.urlFile))
    );
};

export const getKwhValue = ({
    averageIrradiation,
    generation,
    sunHoursSegments,
    pvSystemDataSource,
}) => {
    if (pvSystemDataSource === GENERATION_SOURCES.DESIGNER)
        return sunHoursSegments * DAYS_IN_YEAR;
    return (averageIrradiation * (1 + generation / 100)).toFixed(
        getDecimals(averageIrradiation * (1 + generation / 100))
    );
};

export const getSourceOptions = () => [
    { label: i18next.t('Select a source'), value: 0, disabled: true },
    ...SOURCE_OPTIONS,
];

export const getSunHoursValue = ({
    generation,
    sunHoursSegments,
    pvSystemDataSource,
    sunHours,
}) => {
    if (pvSystemDataSource === GENERATION_SOURCES.DESIGNER)
        return sunHoursSegments;
    return (sunHours * (1 + generation / 100)).toFixed(
        getDecimals(sunHours * (1 + generation / 100))
    );
};

export const getSunHoursWithFile = (anualSolarGeneration, systemSize) =>
    systemSize > 0
        ? (anualSolarGeneration / systemSize / DAYS_IN_YEAR).toFixed(2)
        : 0;

export const handleOnChangeBuild =
    (proposalId, save, setTimer, timer) => (values) => {
        clearTimeout(timer);
        setTimer(setTimeout(() => save(proposalId, values), 1000));
    };

export const handleOnChangeValue = ({
    averageIrradiation,
    base,
    setValue,
    field,
    value,
    sunHours,
    handleSubmit,
}) => {
    if (field === 'sunHoursValue' || field === 'kwhValue') {
        const percentage = ((toNumber(value) - base) / base) * 100;
        setValue('generation', percentage);
    }

    if (field === 'generation') {
        const sunHoursValue = parseFloat(sunHours * (1 + value / 100));
        const kwhValue = parseFloat(averageIrradiation * (1 + value / 100));
        setValue(
            'sunHoursValue',
            sunHoursValue.toFixed(getDecimals(sunHoursValue))
        );
        setValue('kwhValue', kwhValue.toFixed(getDecimals(kwhValue)));
    }
    handleSubmit();
};

export const buildGenerationCsv = (data, config) => {
    const { resultData } = normalizeCsvDataToHourlyArray({
        columnsToFill: [{ name: 'generation', resolution: 'sum' }],
        rowsData: data,
        columnsFormat: config?.columnsFormat,
    });

    const csv = buildCsv(resultData, 'generation.csv');

    return csv;
};
