import i18next from 'i18next';

import { createUserAccount } from 'common/api/v1/auth';
import { isExistDate } from 'common/utils/dates';
import { showReponseErrorsAsAlert } from 'common/utils/helpers';

import {
    CREATE_ACCOUNT_USER,
    CREATE_ACCOUNT_USER_SUCCESS,
    CREATE_ACCOUNT_USER_FAILURE,
} from '../actionTypes';
import { createAccountActions } from '../reducer';

import autoLogin from './autoLogin';
import resetForm from './resetForm';

export default (data, loadingContext) => (dispatch) => {
    dispatch(createAccountActions[CREATE_ACCOUNT_USER]());
    loadingContext.openLoading(
        i18next.t('Creating your account').concat('...')
    );
    if (
        !isExistDate(
            parseInt(data.yearOfBirth),
            parseInt(data.monthOfBirth) - 1,
            parseInt(data.dayOfBirth)
        )
    ) {
        dispatch(
            createAccountActions[CREATE_ACCOUNT_USER_FAILURE]([
                i18next.t('The date entered is invalid'),
            ])
        );
        loadingContext.closeLoading();
        return;
    }

    const values = {
        ...data,
        birth_date: `${data.monthOfBirth}/${data.dayOfBirth}/${data.yearOfBirth}`,
    };

    createUserAccount(values)
        .then((response) => {
            const { token } = response.data;
            localStorage.setItem('token', token);
            dispatch(
                createAccountActions[CREATE_ACCOUNT_USER_SUCCESS](
                    response.data.data
                )
            );
            dispatch(resetForm());
            dispatch(autoLogin(() => loadingContext.closeLoading()));
        })
        .catch((error) => {
            loadingContext.closeLoading();
            dispatch(
                createAccountActions[CREATE_ACCOUNT_USER_FAILURE](
                    error?.response?.data?.errors
                )
            );
            showReponseErrorsAsAlert(dispatch, error?.response);
        });
};
