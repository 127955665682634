import React, { useEffect } from 'react';

import CircleIcon from '@mui/icons-material/Circle';
import { debounce, isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { createStructuredSelector } from 'reselect';

import AccordionProduct from 'common/components/accordions/AccordionProduct';
import { DEBOUNCE_TIME } from 'common/constants';
import { PERMISSION_LIST } from 'common/constants/permissionsV2';
import withPermissions from 'common/hocs/withPermissions';
import { numberFormat } from 'common/utils/helpers';

import * as proposalGeneratorQuoteSelectors from '../proposalGeneratorQuote/selectors';

import * as actions from './actions';
import CardForm from './components/CardForm';
import { handleNormalizeFieldsBuild } from './helpers';
import * as selectors from './selectors';

const Container = ({
    canModify,
    currencyIso,
    currencyLocale,
    currencySymbol,
    initialValues,
    initialize,
    initializeProductsSettingsModal,
    isFetching,
    isLoading,
    isLocked = false,
    isUpdating,
    offerTaxes,
    proposalId,
    save,
    saveProductsSettings,
    totalCost,
}) => {
    const { t } = useTranslation();

    useEffect(() => {
        if (!isEmpty(offerTaxes)) {
            initialize(offerTaxes);
        }
    }, [offerTaxes]);

    const handleOnSave = React.useRef(debounce(save, DEBOUNCE_TIME)).current;

    const handleNormalizeFields = handleNormalizeFieldsBuild(
        handleOnSave,
        proposalId
    );
    const handleSaveProductsSettings = (values) =>
        saveProductsSettings(values, proposalId);

    const isLoadingForm = isFetching || isLoading || isUpdating;

    const isLockedForm = isLoadingForm || isLocked;

    return (
        <AccordionProduct
            caption={t('Total price')}
            cardId="proposal_generator_tax_card"
            isLoading={isLoadingForm}
            svgIcon={<CircleIcon sx={{ color: '#4c4e50', fontSize: 25 }} />}
            title={t('Tax', {
                count: 2,
            })}
            total={numberFormat(totalCost, {
                currency: currencyIso,
                locale: currencyLocale,
                style: 'currency',
            })}
        >
            <CardForm
                canModify={canModify}
                currencyIso={currencyIso}
                currencyLocale={currencyLocale}
                currencySymbol={currencySymbol}
                handleNormalizeFields={handleNormalizeFields}
                handleSaveProductsSettings={handleSaveProductsSettings}
                initializeProductsSettingsModal={
                    initializeProductsSettingsModal
                }
                initialValues={initialValues}
                isLoading={isLoadingForm}
                isLocked={isLockedForm}
            />
        </AccordionProduct>
    );
};

const mapStateToProps = createStructuredSelector({
    initialValues: selectors.getInitialValues,
    isFetching: selectors.getIsFetchingOfferTaxes,
    isUpdating: selectors.getIsUpdating,
    offerTaxes: proposalGeneratorQuoteSelectors.getOfferTaxesData,
    totalCost: proposalGeneratorQuoteSelectors.getTotalCostTaxes,
});

const mapDispatchToProps = (dispatch) => ({
    initialize: (values) => dispatch(actions.initialValues(values)),
    initializeProductsSettingsModal: (id, values) =>
        dispatch(actions.initializeProductsSettingsModal(id, values)),
    save: (proposalId, valuesForm) =>
        dispatch(actions.save(proposalId, valuesForm)),
    saveProductsSettings: (values, proposalId) =>
        dispatch(actions.saveProductsSettings(values, proposalId)),
});

Container.propTypes = {
    canModify: PropTypes.bool,
    currencyIso: PropTypes.string,
    currencyLocale: PropTypes.string,
    currencySymbol: PropTypes.string,
    initialValues: PropTypes.object,
    initialize: PropTypes.func,
    initializeProductsSettingsModal: PropTypes.func,
    isFetching: PropTypes.bool,
    isLoading: PropTypes.bool,
    isLocked: PropTypes.bool,
    isUpdating: PropTypes.bool,
    offerTaxes: PropTypes.array,
    proposalId: PropTypes.string,
    save: PropTypes.func,
    saveProductsSettings: PropTypes.func,
    totalCost: PropTypes.number,
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withPermissions(PERMISSION_LIST.PROPOSAL_TAXES_PERMISSION)
)(Container);
