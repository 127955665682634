import { array, number, object, string } from 'yup';

import getValidationTexts from 'common/utils/getValidationTexts';
import { getIsMultibranchesAccount } from 'common/utils/helpers/session';

export default () => {
    const { getMaxValueText, REQUIRED_TEXT } = getValidationTexts();
    const isMultiBranches = getIsMultibranchesAccount();
    return object().shape({
        branch_offices: isMultiBranches
            ? string().required(REQUIRED_TEXT).nullable()
            : string().nullable(),
        brand_name: string().required(REQUIRED_TEXT).nullable(),
        code: string().required(REQUIRED_TEXT).nullable(),
        currency: string().required(REQUIRED_TEXT).nullable(),
        name: string().required(REQUIRED_TEXT).nullable(),
        range_prices: array().of(
            object().shape({
                cost: number().typeError(REQUIRED_TEXT).required(REQUIRED_TEXT),
                final_cost: number()
                    .typeError(REQUIRED_TEXT)
                    .required(REQUIRED_TEXT),
                margin: number()
                    .max(99.99, getMaxValueText(99.99))
                    .typeError(REQUIRED_TEXT)
                    .required(REQUIRED_TEXT),
            })
        ),
    });
};
