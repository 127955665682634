import React, { useEffect, useState } from 'react';

import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Button, Grid, Typography } from 'sunwise-ui';

import {
    ReactHookFormInput,
    ReactHookFormSelect2,
} from 'common/components/form/bootstrap';
import yupResolver from 'common/utils/yupResolver';

import * as projectConsumptionsCardSelectors from '../../projectConsumptionCard/selectors';
import * as projectConsumptionsModalActions from '../../projectConsumptionsModal/actions';
import * as projectConsumptionsModalSelectors from '../../projectConsumptionsModal/selectors';
import * as actions from '../actions';
import * as selectors from '../selectors';
import validate from '../validate';

import FileUpload from './FileUpload';

const Form = ({
    callbackMasterButton,
    CustomFooter,
    fetchLocationsByZipCode,
    fetchRates,
    getCSVInfo,
    groupedRatesForSelect,
    initialValues,
    isFetchingLocationsZipCode,
    isFetchingRates,
    isSaving,
    onCancel,
}) => {
    const { t } = useTranslation();
    const [hasMissingFields, setHasMissingFields] = useState({
        rate: false,
        zipCode: false,
    });

    const { control, handleSubmit, setValue } = useForm({
        context: { hasMissingFields },
        defaultValues: initialValues,
        resolver: yupResolver(validate),
    });

    useEffect(() => {
        fetchRates();
    }, []);

    const handleOnSubmit = (formValues) => {
        fetchLocationsByZipCode({
            callbackMasterButton,
            formValues,
            setHasMissingFields,
            setValue,
        });
    };

    const rateOptions = isEmpty(groupedRatesForSelect)
        ? []
        : Object.values(groupedRatesForSelect);

    const disabled = isFetchingLocationsZipCode || isSaving;

    const renderCustomFooter = ({ handleSubmit }) => {
        if (CustomFooter) return <CustomFooter handleSubmit={handleSubmit} />;
    };

    return (
        <form onSubmit={handleSubmit(handleOnSubmit)}>
            <FileUpload
                accept=".csv"
                control={control}
                disabled={disabled}
                name="file"
                onChange={(file) =>
                    getCSVInfo({ file, setHasMissingFields, setValue })
                }
                setValue={setValue}
            />

            {(hasMissingFields.rate || hasMissingFields.zipCode) && (
                <Typography variant="subtitle1">
                    {t(
                        'The following fields are required to continue with the process'
                    )}
                </Typography>
            )}

            <Grid container mb={2} mt={1}>
                {hasMissingFields.rate && (
                    <Grid item xs={18}>
                        <ReactHookFormSelect2
                            control={control}
                            disabled={isFetchingRates || disabled}
                            label={t('Rate')}
                            name="rate"
                            options={rateOptions}
                            variant="filled"
                        />
                    </Grid>
                )}

                {hasMissingFields.zipCode && (
                    <Grid item xs={18}>
                        <ReactHookFormInput
                            control={control}
                            disabled={disabled}
                            fullWidth
                            label={t('Zip code')}
                            name="zip_code"
                            variant="filled"
                        />
                    </Grid>
                )}
            </Grid>

            {renderCustomFooter({ handleSubmit })}

            {!CustomFooter && (
                <>
                    <Button disabled={disabled} fullWidth type="submit">
                        {t('Continue')}
                    </Button>

                    <Button
                        disabled={isSaving}
                        fullWidth
                        onClick={onCancel}
                        variant="outlined"
                    >
                        {t('Cancel')}
                    </Button>
                </>
            )}
        </form>
    );
};

const mapStateToProps = createStructuredSelector({
    groupedRatesForSelect:
        projectConsumptionsModalSelectors.getGroupedRatesForSelect,
    initialValues: selectors.getInitialValues,
    isFetchingLocationsZipCode: selectors.getIsFetchingLocationsZipCode,
    isSaving: selectors.getIsSaving,
    selectedProject: projectConsumptionsCardSelectors.getSelectedProject,
});

const mapDispatchToProps = (dispatch) => ({
    fetchRates: () => dispatch(projectConsumptionsModalActions.fetchRates()),
    fetchLocationsByZipCode: (config) =>
        dispatch(actions.fetchLocationsByZipCode(config)),
    getCSVInfo: (config) => dispatch(actions.getCSVInfo(config)),
});

Form.propTypes = {
    callbackMasterButton: PropTypes.func,
    CustomFooter: PropTypes.func,
    fetchLocationsByZipCode: PropTypes.func,
    fetchRates: PropTypes.func,
    getCSVInfo: PropTypes.func,
    groupedRatesForSelect: PropTypes.object,
    initialValues: PropTypes.object,
    isFetchingLocationsZipCode: PropTypes.bool,
    isFetchingRates: PropTypes.bool,
    isSaving: PropTypes.bool,
    onCancel: PropTypes.func,
};

const mergeProps = (stateProps, dispatchProps, ownProps) =>
    Object.assign({}, stateProps, dispatchProps, ownProps);

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(Form);
