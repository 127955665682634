import React, { useEffect } from 'react';

import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Button, Grid } from 'sunwise-ui';

import { ReactHookFormCheck } from 'common/components/form/bootstrap';
import ShowErrors from 'common/components/ShowErrors';
import yupResolver from 'common/utils/yupResolver';

import * as actions from '../actions';
import * as selectors from '../selectors';
import validate from '../validate';

const StyledPermissionText = styled('p')`
    font-size: 14px;
    font-style: normal;
    font-weight: normal;
    line-height: 26px;
`;

const FormContainer = ({
    errors,
    handleClickSave,
    initialValues,
    isSaving,
    reset,
}) => {
    const { t } = useTranslation();

    const {
        control,
        formState: { isDirty },
        handleSubmit,
        reset: resetForm,
    } = useForm({
        defaultValues: initialValues,
        resolver: yupResolver(validate),
    });

    useEffect(() => resetForm(initialValues), [initialValues]);

    return (
        <form onSubmit={handleSubmit(handleClickSave)}>
            <ReactHookFormCheck
                control={control}
                label={
                    <StyledPermissionText>
                        {t(
                            'I grant permission to Sunwise staff to temporarily access my account to attend specific and previously requested issues'
                        )}
                        .
                    </StyledPermissionText>
                }
                name="allow_staff_support"
                id="allow_staff_support"
            />

            <ShowErrors errors={errors} />

            {isDirty && (
                <Grid container mt={1}>
                    <Grid
                        item
                        xs
                        sx={{
                            display: 'flex',
                            flexDirection: { xs: 'column', md: 'row' },
                            justifyContent: { xs: 'center', md: 'right' },
                        }}
                    >
                        <Button
                            color="secondary"
                            onClick={() => {
                                reset();
                                resetForm(initialValues);
                            }}
                            sx={{
                                width: { xs: '100%', md: 'auto' },
                                order: { xs: 2, md: 1 },
                                mr: { xs: 0, md: 2 },
                            }}
                            variant="text"
                        >
                            {t('Cancel')}
                        </Button>
                        <Button
                            disabled={isSaving}
                            sx={{
                                width: { xs: '100%', md: 'auto' },
                                order: { xs: 1, md: 2 },
                                mb: { xs: 2, md: 0 },
                            }}
                            type="submit"
                            variant="outlined"
                        >
                            {isSaving ? t('Saving').concat('...') : t('Save')}
                        </Button>
                    </Grid>
                </Grid>
            )}
        </form>
    );
};

FormContainer.propTypes = {
    errors: PropTypes.array,
    handleClickSave: PropTypes.func,
    initialValues: PropTypes.object,
    isSaving: PropTypes.bool,
    reset: PropTypes.func,
};

const mapStateToProps = createStructuredSelector({
    errors: selectors.getErrorsSave,
    initialValues: selectors.getInitialValues,
    isSaving: selectors.getIsSaving,
});

const mapDispatchToProps = (dispatch) => ({
    handleClickSave: (values) => dispatch(actions.save(values)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FormContainer);
