import { updateType } from 'common/api/v1/contactProject';
import { showReponseErrorsAsAlert } from 'common/utils/helpers';
import showToast from 'common/utils/showToast';

import { UPDATE_STATUS_PROJECT_SUCCESS } from '../actionTypes';
import { projectActions } from '../reducer';

import fetchProjectDetails from './fetchProjectDetails';

export default (id, status, onFail) => (dispatch) => {
    const data = { status_project: status };

    updateType(data, id)
        .then(() => {
            dispatch(
                projectActions[UPDATE_STATUS_PROJECT_SUCCESS]({
                    projectId: id,
                    statusProject: status,
                })
            );
            dispatch(fetchProjectDetails(id));
            showToast();
        })
        .catch((error) => {
            showReponseErrorsAsAlert(dispatch, error?.response);
            if (onFail) onFail();
        });
};
