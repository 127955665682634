import i18next from 'i18next';

import { deleteItem } from 'common/api/v1/contactSupport';
import alerts from 'common/modules/alerts';
import { showReponseErrorsAsAlert } from 'common/utils/helpers';
import showToast from 'common/utils/showToast';

import {
    DELETE_ITEM,
    DELETE_ITEM_FAILURE,
    DELETE_ITEM_SUCCESS,
} from '../actionTypes';
import { supportContactFormActions } from '../reducer';

import fetchSupportContact from './fetchSupportContact';

export default (id) => (dispatch) => {
    dispatch(supportContactFormActions[DELETE_ITEM]());

    deleteItem(id)
        .then(() => {
            dispatch(supportContactFormActions[DELETE_ITEM_SUCCESS](null));
            dispatch(alerts.actions.close());

            dispatch(fetchSupportContact());

            showToast({ body: i18next.t('It was successfully removed') });
        })
        .catch((error) => {
            dispatch(
                supportContactFormActions[DELETE_ITEM_FAILURE](
                    error?.response?.data?.errors
                )
            );
            showReponseErrorsAsAlert(dispatch, error?.response);
        });
};
