import React, { useEffect } from 'react';

import ArchiveOutlinedIcon from '@mui/icons-material/ArchiveOutlined';
import EditIcon from '@mui/icons-material/Edit';
import EditNotificationsIcon from '@mui/icons-material/EditNotifications';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import UnarchiveOutlinedIcon from '@mui/icons-material/UnarchiveOutlined';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { DropdownCardMenu } from 'common/components/cards';

import setAlertsModalsOpen from '../../rateAlerts/actions/setAlertsModalsOpen';
import * as actions from '../actions';
import { BUSINESS_TYPE_ITEMS } from '../constants';
import * as selectors from '../selectors';

import CustomRateFormTabs from './CustomRateFormTabs';
import {
    CardHeader,
    CardInner,
    CardTitle,
    CardWrapper,
    FormContainer,
    NameText,
} from './styledComponents';

const RateDetailForm = ({
    canDelete,
    canModify,
    handleOpenRateAlertModal,
    initialValues,
    initializeRateDetailForm,
    isFetchingRate,
    prepareDuplicateRate,
    prepareUpdateRate,
    resetRateDetailForm,
    selectedItemDataUnparsed,
    setRateEnabled,
}) => {
    const { t } = useTranslation();

    const { control, getValues, handleSubmit, reset, setValue } = useForm({
        defaultValues: initialValues,
    });

    useEffect(() => {
        if (selectedItemDataUnparsed) {
            if (!isFetchingRate)
                initializeRateDetailForm(selectedItemDataUnparsed);
        } else resetRateDetailForm();
    }, []);

    useEffect(() => reset(initialValues), [initialValues]);

    return (
        <CardWrapper>
            <CardInner>
                <CardHeader>
                    <CardTitle component="span">
                        {isFetchingRate
                            ? t('Loading').concat('...')
                            : t('Custom rate')}
                    </CardTitle>

                    {!isFetchingRate && (
                        <DropdownCardMenu
                            items={[
                                {
                                    handleClick: () =>
                                        prepareUpdateRate(
                                            selectedItemDataUnparsed
                                        ),
                                    icon: <EditIcon fontSize="small" />,
                                    label: t('Edit'),
                                    visible: canModify,
                                },
                                {
                                    handleClick: () =>
                                        handleOpenRateAlertModal(true),
                                    icon: (
                                        <EditNotificationsIcon fontSize="small" />
                                    ),
                                    label: t('Alert', { count: 2 }),
                                    visible: canModify,
                                },
                                {
                                    handleClick: () =>
                                        prepareDuplicateRate(
                                            selectedItemDataUnparsed
                                        ),
                                    icon: <FileCopyIcon fontSize="small" />,
                                    label: t('Duplicate'),
                                    visible: canModify,
                                },
                                {
                                    handleClick: () =>
                                        setRateEnabled(
                                            selectedItemDataUnparsed?.id,
                                            !selectedItemDataUnparsed?.is_enable
                                        ),
                                    icon: selectedItemDataUnparsed?.is_enable ? (
                                        <UnarchiveOutlinedIcon fontSize="small" />
                                    ) : (
                                        <ArchiveOutlinedIcon fontSize="small" />
                                    ),
                                    label: t(
                                        selectedItemDataUnparsed?.is_enable
                                            ? 'Archive'
                                            : 'Unarchive'
                                    ),
                                    visible: canDelete,
                                },
                            ]}
                            visible={canDelete || canModify}
                        />
                    )}
                </CardHeader>

                <NameText component="span">
                    {t(
                        BUSINESS_TYPE_ITEMS[
                            getValues('main_data.business_type')
                        ]
                    )}{' '}
                    - {getValues('main_data.name_short')}
                </NameText>

                <FormContainer onSubmit={handleSubmit}>
                    {!isFetchingRate && (
                        <CustomRateFormTabs
                            control={control}
                            getValues={getValues}
                            setValue={setValue}
                            showMode
                            tabKey="custom-rate-detail-form-tabs"
                        />
                    )}
                </FormContainer>
            </CardInner>
        </CardWrapper>
    );
};

const mapStateToProps = createStructuredSelector({
    initialValues: selectors.getRateDetailInitialValues,
});

const mapDispatchToProps = (dispatch) => ({
    handleOpenRateAlertModal: (rateId) => dispatch(setAlertsModalsOpen(rateId)),
    initializeRateDetailForm: (values) =>
        dispatch(actions.initializeRateDetailForm(values)),
    prepareDuplicateRate: (rateData) =>
        dispatch(actions.prepareDuplicateRate(rateData)),
    prepareUpdateRate: (rateData) =>
        dispatch(actions.prepareUpdateRate(rateData)),
    resetRateDetailForm: () => dispatch(actions.resetRateDetailForm()),
    setRateEnabled: (rateId, isEnabled) =>
        dispatch(actions.setRateEnabled(rateId, isEnabled)),
});

RateDetailForm.propTypes = {
    canDelete: PropTypes.bool,
    canModify: PropTypes.bool,
    handleOpenRateAlertModal: PropTypes.func,
    initialValues: PropTypes.object,
    initializeRateDetailForm: PropTypes.func,
    isFetchingRate: PropTypes.bool,
    prepareDuplicateRate: PropTypes.func,
    prepareUpdateRate: PropTypes.func,
    resetRateDetailForm: PropTypes.func,
    selectedItemDataUnparsed: PropTypes.object,
    setRateEnabled: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(RateDetailForm);
