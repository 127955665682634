import React, { useEffect, useState } from 'react';

import i18next from 'i18next';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import ReactHookFormSelect from 'common/components/form/bootstrap/ReactHookFormSelect';

import { getBranchOfficesDictionaryByAgent } from '../helpers';

const BranchOfficeSelectorByAgentRHF = ({
    agentId,
    agents,
    className,
    control,
    disabled,
    isGlobal,
    isHidden,
    label,
    name,
}) => {
    const { t } = useTranslation();
    const [branchesItemsDictionary, setBranchItemsDictionary] = useState({});
    const [options, setOptions] = useState([]);

    useEffect(() => {
        if (!isEmpty(agents)) {
            setBranchItemsDictionary(
                getBranchOfficesDictionaryByAgent(agents, isGlobal)
            );
        }
    }, [agents]);

    useEffect(() => {
        if (agentId && branchesItemsDictionary[agentId])
            setOptions(branchesItemsDictionary[agentId]);
        else setOptions([]);
    }, [branchesItemsDictionary, agentId]);

    if (isHidden) return null;

    return (
        <ReactHookFormSelect
            className={className}
            control={control}
            disabled={disabled}
            label={label}
            name={name}
            options={[
                {
                    disabled: true,
                    label: t('Select a branch office'),
                    value: '',
                },
                ...options,
            ]}
            required
        />
    );
};

BranchOfficeSelectorByAgentRHF.defaultProps = {
    branchesItems: [],
    isHidden: false,
    label: i18next.t('Branch office'),
    name: 'branch_office',
};

BranchOfficeSelectorByAgentRHF.propTypes = {
    agentId: PropTypes.string,
    agents: PropTypes.array,
    className: PropTypes.string,
    control: PropTypes.object,
    disabled: PropTypes.bool,
    isGlobal: PropTypes.bool,
    isHidden: PropTypes.bool,
    label: PropTypes.string,
    name: PropTypes.string,
};

export default BranchOfficeSelectorByAgentRHF;
