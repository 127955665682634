import React from 'react';

import isNull from 'lodash/isNull';
import PropTypes from 'prop-types';
import { useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Box, Grid, Link } from 'sunwise-ui';

import { InfoToolTip, TitleWithDetail } from 'common/components';
import {
    ReactHookFormCheck,
    ReactHookFormIntlNumberInput,
} from 'common/components/form/bootstrap';
import { HIGH_TENSION_RATES } from 'common/constants/rates';

const RoiFields = ({
    canModifyRoiSolarDegradation,
    canModifyRoiTaxIncentive,
    canModifyStockExchange,
    control,
    isLocked,
    nextRateData,
}) => {
    const { t } = useTranslation();
    const hasIsr = useWatch({ control, name: 'isr' });
    return (
        <>
            <Box sx={{ mb: 2 }}>
                <TitleWithDetail variant="body2">{t('ROI')}</TitleWithDetail>
            </Box>

            <Grid container>
                <Grid item lg={hasIsr ? 9 : 18} md={hasIsr ? 9 : 18} xs={18}>
                    <ReactHookFormCheck
                        control={control}
                        disabled={isLocked || !canModifyRoiTaxIncentive}
                        label={t('With tax incentive')}
                        name="isr"
                    />
                </Grid>

                {hasIsr && (
                    <Grid lg={9} item md={9} xs={18}>
                        <ReactHookFormIntlNumberInput
                            append="%"
                            control={control}
                            disabled={isLocked || !canModifyRoiTaxIncentive}
                            fullWidth
                            label={t('Percentage')}
                            name="isr_percentage"
                            placeholder="%"
                        />
                    </Grid>
                )}
            </Grid>

            {!isNull(nextRateData) &&
                nextRateData?.certified &&
                HIGH_TENSION_RATES.includes(nextRateData.name) && (
                    <Box alignItems="center" display="flex" gap={1} mb={3}>
                        <ReactHookFormCheck
                            control={control}
                            disabled={isLocked}
                            label={t(
                                'Modify calculation to pre-2018 resolution'
                            )}
                            name="deprecated"
                            sxFormControl={{ mb: '0px !important' }}
                        />

                        <InfoToolTip variant="no-wrapper">
                            {t(
                                'Affects the calculation formula for "Capacidad" and "Distribución" concepts. Learn more at'
                            )}{' '}
                            <Link
                                href={`${process.env.REACT_APP_ACADEMY_URL}es/articles/9332918-calculos-de-capacidad-y-distribucion-resolucion-pre-2018`}
                                target="_blank"
                            >
                                Sunwise Academy
                            </Link>
                        </InfoToolTip>
                    </Box>
                )}

            <Grid container>
                <Grid lg md xs={18} item>
                    <ReactHookFormIntlNumberInput
                        append="%"
                        caption={`% ${t('Annual')}`}
                        control={control}
                        disabled={isLocked || !canModifyRoiSolarDegradation}
                        fullWidth
                        label={t('Solar degradation')}
                        name="degradation_percentage"
                        placeholder="%"
                    />
                </Grid>

                <Grid item lg={9} md={9} xs={18}>
                    <ReactHookFormIntlNumberInput
                        allowDecimals={false}
                        allowNegativeValue={false}
                        append={t('Year', { count: 2 })}
                        control={control}
                        disabled={isLocked || !canModifyStockExchange}
                        fullWidth
                        label={t('Simulation years')}
                        max={50}
                        name="bag_years"
                    />
                </Grid>
            </Grid>
        </>
    );
};

RoiFields.propTypes = {
    canModifyRoiSolarDegradation: PropTypes.bool,
    canModifyRoiTaxIncentive: PropTypes.bool,
    canModifyStockExchange: PropTypes.bool,
    control: PropTypes.object,
    isLocked: PropTypes.bool,
    nextRateData: PropTypes.object,
};

export default RoiFields;
