import { getDocumentsByContact } from 'common/api/v1/contactDocuments';

import {
    FETCH_DOCUMENTS,
    FETCH_DOCUMENTS_SUCCESS,
    FETCH_DOCUMENTS_FAILURE,
} from '../actionTypes';
import { documentContactFormActions } from '../reducer';

export default (contactId) => (dispatch) => {
    dispatch(documentContactFormActions[FETCH_DOCUMENTS]());

    getDocumentsByContact(contactId)
        .then((response) =>
            dispatch(
                documentContactFormActions[FETCH_DOCUMENTS_SUCCESS](
                    response.data
                )
            )
        )
        .catch((error) =>
            dispatch(documentContactFormActions[FETCH_DOCUMENTS_FAILURE](error))
        );
};
