import React from 'react';

import get from 'lodash/get';
import PropTypes from 'prop-types';
import { Card, Grid } from 'sunwise-ui';

import {
    ReactHookFormInputDatePicker,
    ReactHookFormIntlNumberInput,
    ReactHookFormIntlNumberInputConsumption,
} from 'common/components/form/bootstrap';
import { RATES_WITH_POWER_FACTOR } from 'common/constants/rates';
import { getDaysInPeriod } from 'common/utils/helpers/rates';

import { updatePlaceholder } from '../../../../actions';
import {
    getBorderColor,
    getMaxInitialDate,
    handleNormalizeFinalDateBuild,
    handleNormalizeInitialDateBuild,
    handleNormalizeTotalKwh,
    handleOnPasteArrayFields,
} from '../../../../helpers';
import {
    AvgConsumptionWarning,
    DaysInPeriodWarning,
    SeasonChangeWarning,
} from '../../../warnings';

const handleOnChangeKWhValue = ({
    daysInPeriod,
    getValues,
    index,
    name,
    profilesConsumptionData,
    ratesDictionary,
    setValue,
}) => {
    const total = getValues(`${name}.total.value`);
    const dailyAvg = total ? (total / daysInPeriod).toFixed(2) : 0;
    setValue(`${name}.dailyAvg`, {
        placeholder: dailyAvg.toString(),
        value: dailyAvg,
    });
    setValue(`${name}.updatedManual`, true);
    handleNormalizeTotalKwh({
        getValues,
        index,
        profilesConsumptionData,
        ratesDictionary,
        setValue,
    });
};

const handleOnChangeKWValue = ({
    getValues,
    name,
    profilesConsumptionData,
    ratesDictionary,
    setValue,
}) => {
    setValue(`${name}.updatedManual`, true);
    updatePlaceholder({
        formValues: getValues(),
        profilesConsumptionData,
        ratesDictionary,
        setValue,
    });
};

const handleOnChangeDailyAvg = ({
    daysInPeriod,
    getValues,
    name,
    profilesConsumptionData,
    ratesDictionary,
    setValue,
}) => {
    const dailyAvg = getValues(`${name}.dailyAvg.value`);
    const newValue = dailyAvg ? dailyAvg * daysInPeriod : 0;
    setValue(`${name}.updatedManual`, true);
    setValue(`${name}.total`, {
        placeholder: newValue.toString(),
        value: newValue,
    });
    updatePlaceholder({
        formValues: getValues(),
        profilesConsumptionData,
        ratesDictionary,
        setValue,
    });
};

const IntakeCaptureFieldArray = ({
    control,
    disabled,
    disabledConsumptionFieldsByCsv,
    getValues,
    handleNormalizeDates,
    index,
    isGDRate,
    maxDate,
    minDate,
    name,
    period,
    periodWarnings,
    profilesConsumptionData,
    rateConfiguration,
    ratesDictionary,
    selectedRate,
    setValue,
    showAvgDailyField,
}) => {
    const { hasAvgConsumptionWarning, hasDaysInPeriodWarning } =
        periodWarnings ?? {};
    const borderColor = getBorderColor(get(period, 'predicted'));
    const daysInPeriod = getDaysInPeriod(period);
    const sxWarning = {
        border: borderColor ? `1px solid ${borderColor}` : '',
    };
    const sxCard = {
        border: `${hasDaysInPeriodWarning && '1px solid #ffc107'}`,
        overflow: 'visible',
    };

    return (
        <Card sx={sxCard}>
            <Card.Body sx={{ pb: '0!important' }}>
                <Grid container>
                    <Grid item xs>
                        <ReactHookFormInputDatePicker
                            control={control}
                            disabled={disabledConsumptionFieldsByCsv}
                            maxDate={getMaxInitialDate(period)}
                            minDate={minDate}
                            name={`${name}.initial_date`}
                            onChange={(value) =>
                                handleNormalizeInitialDateBuild({
                                    getValues,
                                    handleNormalizeDates,
                                    index,
                                    rateConfiguration,
                                    ratesDictionary,
                                    setValue,
                                    value,
                                })
                            }
                            sxInput={sxWarning}
                            tabIndex="-1"
                        />
                    </Grid>

                    <Grid item xs>
                        <ReactHookFormInputDatePicker
                            control={control}
                            disabled={
                                index > 0 || disabledConsumptionFieldsByCsv
                            }
                            maxDate={maxDate}
                            minDate={minDate}
                            name={`${name}.final_date`}
                            onChange={() => {
                                if (index > 0) return;
                                handleNormalizeFinalDateBuild({
                                    getValues,
                                    handleNormalizeDates,
                                    profilesConsumptionData,
                                    rateConfiguration,
                                    ratesDictionary,
                                    setValue,
                                });
                            }}
                            sxInput={sxWarning}
                            tabIndex="-1"
                        />
                    </Grid>

                    <Grid item xs>
                        {daysInPeriod}
                    </Grid>

                    {showAvgDailyField && (
                        <Grid item xs>
                            <ReactHookFormIntlNumberInputConsumption
                                control={control}
                                disabled={disabledConsumptionFieldsByCsv}
                                name={`${name}.dailyAvg`}
                                onBlur={() =>
                                    handleOnChangeDailyAvg({
                                        daysInPeriod,
                                        getValues,
                                        name,
                                        profilesConsumptionData,
                                        ratesDictionary,
                                        setValue,
                                    })
                                }
                                sx={sxWarning}
                                tabIndex={`${index + 1}`}
                            />
                        </Grid>
                    )}

                    <Grid item xs>
                        <ReactHookFormIntlNumberInputConsumption
                            control={control}
                            disabled={disabledConsumptionFieldsByCsv}
                            name={`${name}.total`}
                            onBlur={() =>
                                handleOnChangeKWhValue({
                                    daysInPeriod,
                                    getValues,
                                    index,
                                    name,
                                    profilesConsumptionData,
                                    ratesDictionary,
                                    setValue,
                                })
                            }
                            onPaste={(event) =>
                                handleOnPasteArrayFields({
                                    columns: [
                                        'total',
                                        'kW.peak',
                                        'power_factor',
                                    ],
                                    event,
                                    getValues,
                                    initialRow: index,
                                    setValue,
                                })
                            }
                            sx={sxWarning}
                            tabIndex={`${index + 1}`}
                        />
                    </Grid>

                    {isGDRate && (
                        <Grid item xs>
                            <ReactHookFormIntlNumberInputConsumption
                                control={control}
                                disabled={disabled}
                                name={`${name}.kW.peak`}
                                onBlur={() =>
                                    handleOnChangeKWValue({
                                        getValues,
                                        name,
                                        profilesConsumptionData,
                                        ratesDictionary,
                                        setValue,
                                    })
                                }
                                onPaste={(event) =>
                                    handleOnPasteArrayFields({
                                        columns: ['kW.peak', 'power_factor'],
                                        event,
                                        getValues,
                                        initialRow: index,
                                        setValue,
                                    })
                                }
                                sx={sxWarning}
                                tabIndex={`${index + 1.1}`}
                            />
                        </Grid>
                    )}

                    {RATES_WITH_POWER_FACTOR.includes(selectedRate?.name) &&
                        selectedRate?.isCertified && (
                            <Grid item xs>
                                <ReactHookFormIntlNumberInput
                                    control={control}
                                    disabled={disabled}
                                    name={`${name}.power_factor`}
                                    onPaste={(event) =>
                                        handleOnPasteArrayFields({
                                            columns: ['power_factor'],
                                            event,
                                            getValues,
                                            initialRow: index,
                                            setValue,
                                        })
                                    }
                                    sx={sxWarning}
                                    tabIndex={`${index + 1.1}`}
                                />
                            </Grid>
                        )}
                </Grid>

                {period.season_change_pdf && <SeasonChangeWarning />}

                <DaysInPeriodWarning
                    hasDaysInPeriodWarning={hasDaysInPeriodWarning}
                />

                <AvgConsumptionWarning
                    hasAvgConsumptionWarning={hasAvgConsumptionWarning}
                />
            </Card.Body>
        </Card>
    );
};

IntakeCaptureFieldArray.propTypes = {
    control: PropTypes.object,
    disabled: PropTypes.bool,
    disabledConsumptionFieldsByCsv: PropTypes.bool,
    getValues: PropTypes.func,
    handleNormalizeDates: PropTypes.func,
    index: PropTypes.number,
    isGDRate: PropTypes.bool,
    maxDate: PropTypes.object,
    minDate: PropTypes.object,
    name: PropTypes.string,
    period: PropTypes.object,
    periodWarnings: PropTypes.object,
    profilesConsumptionData: PropTypes.array,
    rateConfiguration: PropTypes.object,
    ratesDictionary: PropTypes.object,
    selectedRate: PropTypes.object,
    setValue: PropTypes.func,
    showAvgDailyField: PropTypes.bool,
};

export default IntakeCaptureFieldArray;
