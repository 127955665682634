import React from 'react';

import isNull from 'lodash/isNull';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { createStructuredSelector } from 'reselect';
import { Card } from 'sunwise-ui';

import EmptyMessage from 'common/components/EmptyMessage';
import { PERMISSION_LIST } from 'common/constants/permissionsV2';
import withPermissions from 'common/hocs/withPermissions';

import * as actions from '../actions';
import {
    DOCUMENTS_CONTACT_TYPE,
    DOCUMENTS_PROJECTS_TYPE,
    PROPOSAL_SUMMARY_TYPE,
} from '../constants';
import { getTitleCard, onDragEnd } from '../helpers';
import * as selectors from '../selectors';

import CreateButton from './CreateButton';
import SortableList from './SortableList';
const CardListDetail = ({
    companiesCatalogs,
    getPermissionsByCode,
    handleClickCreate,
    isFetching,
    prepareDelete,
    prepareUpdate,
    type,
    updateActiveStatus,
    updateOrder,
    updatingActiveStatusId,
}) => {
    const { t } = useTranslation();

    const handleOnDragEnd = (result) =>
        onDragEnd(companiesCatalogs, result, type, updateOrder);

    if (isNull(type))
        return <EmptyMessage text={t('Select a catalog to start')} />;

    if (isFetching)
        return (
            <EmptyMessage
                text={t('Loading catalog', { count: 2 }).concat('...')}
            />
        );

    if (companiesCatalogs.length === 0)
        return (
            <EmptyMessage text={t('There are no catalogs to display')}>
                <CreateButton
                    companiesCatalogs={companiesCatalogs}
                    handleClickCreate={handleClickCreate}
                    type={type}
                />
            </EmptyMessage>
        );

    const { canView: canViewCompanyList } = getPermissionsByCode(
        PERMISSION_LIST.LIST_SETTINGS_PERMISSION
    );
    const { canView: canViewSummaryList } = getPermissionsByCode(
        PERMISSION_LIST.PROPOSAL_SUMMARIES_PERMISSION
    );

    const show =
        type === PROPOSAL_SUMMARY_TYPE
            ? canViewSummaryList
            : canViewCompanyList;

    return (
        show && (
            <Card>
                <Card.Header>
                    <h5>{getTitleCard(type)}</h5>
                </Card.Header>
                <Card.Body>
                    <SortableList
                        id={`list-${type}`}
                        companiesCatalogs={companiesCatalogs}
                        onDragEnd={handleOnDragEnd}
                        prepareUpdate={prepareUpdate}
                        prepareDelete={prepareDelete}
                        showToggleSwitch={[
                            DOCUMENTS_CONTACT_TYPE,
                            DOCUMENTS_PROJECTS_TYPE,
                        ].includes(type)}
                        type={type}
                        updateActiveStatus={updateActiveStatus}
                        updatingActiveStatusId={updatingActiveStatusId}
                    />

                    <CreateButton
                        companiesCatalogs={companiesCatalogs}
                        handleClickCreate={handleClickCreate}
                        type={type}
                    />
                </Card.Body>
            </Card>
        )
    );
};

const mapStateToProps = createStructuredSelector({
    type: selectors.getType,
    updatingActiveStatusId: selectors.getUpdatingActiveStatusId,
});

const mapDispatchToProps = (dispatch) => ({
    handleClickCreate: () => dispatch(actions.prepareCreate()),
    prepareDelete: (id, type) => dispatch(actions.prepareDelete(id, type)),
    prepareUpdate: (companyCatalog) =>
        dispatch(actions.prepareUpdate(companyCatalog)),
    updateActiveStatus: (id, isActive) =>
        dispatch(actions.updateActiveStatus(id, isActive)),
    updateOrder: (items, newIndex, oldIndex, type) =>
        dispatch(actions.updateOrder(items, newIndex, oldIndex, type)),
});

CardListDetail.propTypes = {
    companiesCatalogs: PropTypes.array,
    getPermissionsByCode: PropTypes.func,
    handleClickCreate: PropTypes.func,
    isFetching: PropTypes.bool,
    prepareDelete: PropTypes.func,
    prepareUpdate: PropTypes.func,
    type: PropTypes.number,
    updateActiveStatus: PropTypes.func,
    updateOrder: PropTypes.func,
    updatingActiveStatusId: PropTypes.string,
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withPermissions([
        PERMISSION_LIST.LIST_SETTINGS_PERMISSION,
        PERMISSION_LIST.PROPOSAL_SUMMARIES_PERMISSION,
    ])
)(CardListDetail);
