import i18next from 'i18next';
import get from 'lodash/get';

import { INITIAL_VALUES_RED_GIRASOL } from '../actionTypes';
import {
    hasCommissionDict,
    percentageToValue,
    valueToPercentage,
} from '../helpers';
import { financingAndInsuranceActions } from '../reducer';

const MONTH_DISABLED_IF_MIN_AMOUNT_IS_NOT_REACH = 6;
const MIN_AMOUNT_TO_ENEBLE_SIX_MONTH_TERM = 700000;

export default (values) => (dispatch) => {
    const parameters = get(values, 'financier_product_parameters', {});

    let terms = [];

    if (parameters) {
        terms = parameters.terms
            .split(',')
            .filter(
                (item) =>
                    !(
                        parseInt(item) ===
                            MONTH_DISABLED_IF_MIN_AMOUNT_IS_NOT_REACH &&
                        parseFloat(values.total) >=
                            MIN_AMOUNT_TO_ENEBLE_SIX_MONTH_TERM
                    )
            )
            .map((item) => ({
                label: `${item} ${i18next.t('Month', {
                    count: parseInt(item),
                })}`,
                value: item,
            }));
    }

    const newValues = {
        amount: values.total,
        commission_credit: valueToPercentage(
            parameters.is_commission_percentage,
            parameters.commission_credit,
            values.total
        ),
        financier_installer: values.financier_installer,
        financier_product: parameters.financier_product,
        hitch_percentage: valueToPercentage(
            parameters.is_percentage,
            parameters.hitch,
            values.total
        ),
        hitch: percentageToValue(
            parameters.is_percentage,
            parameters.hitch,
            values.total
        ),
        insurance: parameters.insurance,
        is_commission_percentage: hasCommissionDict(parameters)
            ? true
            : parameters.is_commission_percentage,
        is_percentage: parameters.is_percentage.toString(),
        rate: parameters.rate,
        residual: parameters.residual,
        termsToSelect: terms,
        total: values.total,
        types_business_company: values.types_business_company,
    };

    dispatch(
        financingAndInsuranceActions[INITIAL_VALUES_RED_GIRASOL](newValues)
    );
};
