import i18next from 'i18next';
import { isEmpty, get } from 'lodash';
import { createSelector } from 'reselect';

import { panelTypeToLabel, inverterTypeToLabel } from 'common/utils/helpers';

import { NAME } from './constants';

export const getModel = (state) => {
    return state[NAME];
};

export const getInitialValues = createSelector(
    getModel,
    (model) => model.initialValues
);

export const getFetchPanels = createSelector(
    getModel,
    (model) => model.fetchPanels
);

export const getIsFetchingPanels = createSelector(
    getFetchPanels,
    (fetchPanels) => fetchPanels.isFetching
);

export const getDataFetchPanels = createSelector(
    getFetchPanels,
    (fetchPanels) => {
        if (isEmpty(fetchPanels?.data)) return [];

        return fetchPanels.data.map((item) => ({
            name: get(item, 'name', ''),
            id: get(item, 'id', ''),
            brand: get(item, 'brand.name', ''),
            productType: panelTypeToLabel(get(item, 'product_type', 0)),
            code: get(item, 'code', ''),
            dataSheets: get(item, 'data_sheets', []),
        }));
    }
);

export const getErrorsFetchPanels = createSelector(
    getFetchPanels,
    (fetchPanels) => fetchPanels.errors || []
);

export const getFetchInverters = createSelector(
    getModel,
    (model) => model.fetchInverters
);

export const getIsFetchingInverters = createSelector(
    getFetchInverters,
    (fetchInverters) => fetchInverters.isFetching
);

export const getDataFetchInverters = createSelector(
    getFetchInverters,
    (fetchInverters) => {
        if (isEmpty(fetchInverters?.data)) return [];

        return fetchInverters.data.map((item) => ({
            name: get(item, 'name', ''),
            id: get(item, 'id', ''),
            brand: get(item, 'brand.name', ''),
            productType: inverterTypeToLabel(get(item, 'product_type', 0)),
            code: get(item, 'code', ''),
            dataSheets: get(item, 'data_sheets', []),
        }));
    }
);

export const getErrorsFetchInverters = createSelector(
    getFetchInverters,
    (fetchInverters) => fetchInverters.errors || []
);

export const getPoliticalDivisions = createSelector(
    getModel,
    (model) => model.politicalDivisions
);

export const getPoliticalDivisionsData = createSelector(
    getPoliticalDivisions,
    (data) => data.data || []
);

export const getPoliticalDivisionsForSelect = createSelector(
    getPoliticalDivisions,
    (dataValues) => {
        const items = dataValues.data.map((value) => {
            return {
                label: value.name,
                value: value.id,
            };
        });

        items.unshift({
            disabled: true,
            label: i18next.t('Search'),
            value: '',
        });

        return items;
    }
);

export const getPoliticalDivisionsByParent = createSelector(
    getModel,
    (model) => model.politicalDivisionsByParent
);

export const getPoliticalDivisionsByParentData = createSelector(
    getPoliticalDivisionsByParent,
    (data) => data.data || []
);

export const getPoliticalDivisionsByParentForSelect = createSelector(
    getPoliticalDivisionsByParent,
    (dataValues) => {
        const items = dataValues.data.map((value) => {
            return {
                label: value.name,
                value: value.id,
            };
        });

        items.unshift({
            disabled: true,
            label: i18next.t('Search'),
            value: '',
        });

        return items;
    }
);

export const getFetchDatasheetPanel = createSelector(
    getModel,
    (model) => model.fetchDatasheetPanel
);

export const getIsFetchingDatasheetPanel = createSelector(
    getFetchDatasheetPanel,
    (model) => model.isFetching
);

export const getDatasheetPanelData = createSelector(
    getFetchDatasheetPanel,
    (model) => model.data
);

export const getFetchDatasheetInverter = createSelector(
    getModel,
    (model) => model.fetchDatasheetInverter || {}
);

export const getIsFetchingDatasheetInverter = createSelector(
    getFetchDatasheetInverter,
    (model) => model.isFetching
);

export const getDatasheetInverterData = createSelector(
    getFetchDatasheetInverter,
    (model) => model.data
);

export const getPanelInverterRange = createSelector(
    getModel,
    (model) => model.fetchCompatibility
);

export const getIsFetchingPanelInverterRange = createSelector(
    getPanelInverterRange,
    (model) => model.isFetching
);

export const getPanelInverterRangeData = createSelector(
    getPanelInverterRange,
    (model) => model.data
);

export const getSaveItem = createSelector(
    getModel,
    (model) => model.saveItem || {}
);

export const getIsSavingItem = createSelector(
    getSaveItem,
    (model) => model.isSaving
);
