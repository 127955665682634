import React from 'react';

import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';
import Chart from 'react-apexcharts';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Box, Card, Grid } from 'sunwise-ui';

import KeyIndicator from 'common/components/KeyIndicator';
import PlaceholderTemplateChart from 'common/components/placeholder/PlaceholderTemplateChart';
import { numberFormat } from 'common/utils/helpers';

import { getChartSettings } from '../helpers';
import * as selectors from '../selectors';

const ProposalsTotals = ({
    currencyIso,
    currencyLocale,
    currencySymbol,
    proposalsTotals,
    readyPlaceholder,
}) => {
    const { t } = useTranslation();
    const theme = useTheme();

    const chartConfig = getChartSettings(
        proposalsTotals.categories,
        currencyIso,
        currencyLocale,
        currencySymbol,
        proposalsTotals.series
    );

    return (
        <Card.Body>
            <Grid container>
                <Grid item xs={18} lg={13} xl={14}>
                    <PlaceholderTemplateChart ready={readyPlaceholder}>
                        <Chart
                            height="250"
                            options={{
                                ...chartConfig.options,
                                theme: { mode: theme.palette.mode },
                            }}
                            series={chartConfig.series}
                            type="bar"
                        />
                    </PlaceholderTemplateChart>
                </Grid>

                <Grid item xs={18} lg={5} xl={4}>
                    <Box
                        display="flex"
                        flexDirection="column"
                        gap={2}
                        sx={{ width: '100%' }}
                    >
                        <KeyIndicator
                            isLoading={readyPlaceholder}
                            title={t('Proposals issued')}
                            value={numberFormat(
                                proposalsTotals.projects_number,
                                {
                                    decimals: 0,
                                    locale: currencyLocale,
                                    style: 'decimal',
                                }
                            )}
                            widthIcon="20px"
                        />
                        <KeyIndicator
                            isLoading={readyPlaceholder}
                            title={t('Value of the proposals')}
                            value={numberFormat(
                                proposalsTotals.projects_totals,
                                {
                                    currency: currencyIso,
                                    locale: currencyLocale,
                                    style: 'currency',
                                    symbol: currencySymbol,
                                }
                            )}
                            widthIcon="20px"
                        />
                        <KeyIndicator
                            isLoading={readyPlaceholder}
                            title={t('Power of the proposals')}
                            value={numberFormat(proposalsTotals.projects_kws, {
                                decimals: 0,
                                locale: currencyLocale,
                                style: 'decimal',
                                unit: 'kW',
                            })}
                            widthIcon="20px"
                        />
                    </Box>
                </Grid>
            </Grid>
        </Card.Body>
    );
};

const mapStateToProps = createStructuredSelector({
    proposalsTotals: selectors.getProposalsTotalsData,
});

ProposalsTotals.propTypes = {
    currencyIso: PropTypes.string,
    currencyLocale: PropTypes.string,
    currencySymbol: PropTypes.string,
    proposalsTotals: PropTypes.object,
    readyPlaceholder: PropTypes.bool,
};

const FormContainer = ProposalsTotals;

export default connect(mapStateToProps, null)(FormContainer);
