import React, { useContext, useEffect, useState } from 'react';

import { styled } from '@mui/material/styles';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box, Button, Tab, Tabs, TabPanel, Typography } from 'sunwise-ui';

import { IntlNumberInputField } from 'common/components';
import {
    getDatasheetName,
    getFirstOrDefaultCertifiedDatasheet,
    getIsEnabledCertifiedDatasheetDownload,
} from 'common/modules/productDatasheets/helpers';
import { LoadingContext } from 'common/utils/contexts';

const Label = styled(Typography)`
    font-size: 13px;
    font-weight: bold;
    line-height: 16px;
`;

const Text = styled(Typography)`
    position: relative;
    width: 200px;
    font-size: 13px;
    line-height: 16px;
    text-align: center;
    &:before {
        background-color: #dee2e6;
        content: '';
        display: block;
        height: 14px;
        left: 0;
        margin-top: -7px;
        position: absolute;
        top: 50%;
        width: 1px;
    }
`;

const ItemContainer = styled('div')`
    align-items: center;
    background: rgba(238, 244, 250, 0.5);
    border-radius: 8px;
    box-sizing: border-box;
    display: flex;
    gap: 8px;
    justify-content: space-between;
    margin-bottom: 8px;
    padding: 16px 8px;
`;

const renderItems = (items) => (
    <Box display="flex" flexDirection="column">
        {items
            .filter(({ tag }) => tag !== 'max_e')
            .map((item, index) => (
                <ItemContainer key={`tab-item-data-${index}`}>
                    <Label component="span">{item.title}</Label>
                    <Text component="span">
                        {item.value}
                        {item.unity}
                    </Text>
                </ItemContainer>
            ))}
    </Box>
);

const renderOversizingInput = ({
    canModify,
    category,
    handleChange,
    oversizingValue,
    showOversize,
    t,
} = {}) => {
    if (!showOversize || category !== 'Datos de entrada(DC)') return null;

    return (
        <ItemContainer>
            <Label>{t('Select % oversize')}</Label>

            <IntlNumberInputField
                append="%"
                disabled={!canModify}
                max={100}
                min={0}
                onChange={handleChange}
                placeholder={t('Enter %')}
                step={1}
                value={oversizingValue}
                sx={{ mb: '0!important' }}
            />
        </ItemContainer>
    );
};

const renderOversizingButton = ({
    canModify,
    category,
    onSaveOversize,
    oversizingValue,
    showOversize,
    t,
} = {}) => {
    if (!showOversize || category !== 'Datos de entrada(DC)') return null;

    return (
        <Button
            fullWidth
            onClick={() => onSaveOversize(oversizingValue)}
            visible={canModify}
        >
            {t('Update configuration')}
        </Button>
    );
};

const CardDetails = ({
    canModify,
    datasheet,
    handleClickDownloadDatasheet,
    onSaveOversize,
    oversize,
    selectedEquipment,
    showOversize,
}) => {
    const { t } = useTranslation();
    const loadingContext = useContext(LoadingContext);
    const [selectedTab, setSelectedTab] = useState(2);
    const [oversizingValue, setOversizingValue] = useState(`${oversize || ''}`);
    const dataSheets = get(selectedEquipment, 'dataSheets', null);

    useEffect(() => {
        const tempValue = `${oversize || ''}`;
        if (tempValue !== oversizingValue) {
            setOversizingValue(tempValue);
        }
    }, [oversize]);

    const handleChange = (value) => setOversizingValue(value);
    const handleOnChangeTab = (_, newValue) => setSelectedTab(newValue);

    const dataSheet = dataSheets
        ? getFirstOrDefaultCertifiedDatasheet(dataSheets)
        : null;

    return (
        <>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={selectedTab} onChange={handleOnChangeTab}>
                    {typeof datasheet !== 'undefined' &&
                        datasheet !== null &&
                        datasheet?.objects?.length &&
                        datasheet?.objects.map((tabData) => (
                            <Tab
                                key={`tab-${tabData.category}`}
                                id={`tab-${tabData.category}`}
                                label={tabData.category}
                                value={tabData.Position}
                            />
                        ))}
                </Tabs>
            </Box>

            {datasheet?.objects?.length &&
                datasheet?.objects.map((tabData) => (
                    <TabPanel
                        value={tabData.Position}
                        key={`tab-${tabData.category}`}
                        selectedTab={selectedTab}
                    >
                        {renderOversizingInput({
                            canModify,
                            category: tabData.category,
                            handleChange,
                            oversizingValue,
                            showOversize,
                            t,
                        })}
                        {renderItems(tabData.data)}
                        {renderOversizingButton({
                            canModify,
                            category: tabData.category,
                            onSaveOversize,
                            oversizingValue,
                            showOversize,
                            t,
                        })}
                    </TabPanel>
                ))}

            <Button
                disabled={!getIsEnabledCertifiedDatasheetDownload(dataSheets)}
                fullWidth
                onClick={() =>
                    handleClickDownloadDatasheet(
                        getDatasheetName(dataSheet?.archive),
                        (text) => loadingContext.openLoading(text),
                        () => {
                            loadingContext.closeLoading();
                        }
                    )
                }
                target="_blank"
            >
                {t('View certified data sheet')}
            </Button>
        </>
    );
};

CardDetails.propTypes = {
    canModify: PropTypes.bool,
    datasheet: PropTypes.object,
    handleClickDownloadDatasheet: PropTypes.func,
    onSaveOversize: PropTypes.func,
    oversize: PropTypes.number,
    selectedEquipment: PropTypes.object,
    showOversize: PropTypes.bool,
};

export default CardDetails;
