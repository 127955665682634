import { createSelector } from 'reselect';

import { NAME } from './constants';

export const getModel = (state) => state[NAME];

export const getInitialValues = createSelector(
    getModel,
    (model) => model.initialValues
);

export const getSession = createSelector(getModel, (model) => model.session);

export const getIsLoadingSession = createSelector(
    getSession,
    (session) => session.isLoading
);

export const getErrorsLogin = createSelector(
    getSession,
    (session) => session.errors
);

export const getFetchContries = createSelector(
    getModel,
    (model) => model.countries
);

export const getIsFetchingContries = createSelector(
    getFetchContries,
    (fetchCountries) => fetchCountries.isFetching
);

export const getDataFetchCountries = createSelector(
    getFetchContries,
    (fetchCountries) => fetchCountries.data || []
);

export const getErrorsFetchCountries = createSelector(
    getFetchContries,
    (fetchCountries) => fetchCountries.errors
);

export const getFetchGeocode = createSelector(
    getModel,
    (model) => model.fetchGeocode
);

export const getIsFetchingGeocode = createSelector(
    getFetchGeocode,
    (model) => model.isFetching
);

export const getDataFetchGeocode = createSelector(
    getFetchGeocode,
    (model) => model.data
);

export const getErrorsFetchGeocode = createSelector(
    getFetchGeocode,
    (model) => model.errors
);
