import React from 'react';

import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { Breadcrumbs, Link, Tooltip, Typography } from 'sunwise-ui';

import { useBreakpoint } from 'common/hooks';

const getMaxItems = ({ breakpoint, locations } = {}) => {
    if (breakpoint === 'xs') return 2;
    if (breakpoint === 'sm') return 3;
    return locations.length + 1;
};

const StyledBreadCrumbs = styled(Breadcrumbs)(({ theme }) => ({
    '& .MuiBreadcrumbs-separator': { display: 'none' },
    '& .MuiBreadcrumbs-ol': {
        display: 'flex',
        padding: '0 0 0 16px',
        gap: '8px',
    },
    '& .MuiBreadcrumbs-li': {
        alignItems: 'center',
        background:
            theme.palette.mode === 'dark'
                ? 'rgba(255, 255, 255, 0.16)'
                : '#EEF4FA',
        border: 'none',
        borderBottomLeftRadius: '8px',
        borderTopLeftRadius: '8px',
        clipPath:
            'polygon(50% 0%, calc(100% - 15px) 0%, 100% 50%, calc(100% - 15px) 100%, 50% 100%, 0% 100%, 15px 50%, 0% 0%)',
        color: theme.palette.mode === 'dark' ? 'unset' : 'rgba(0, 0, 0, 0.6)',
        cursor: 'default',
        fontSize: '14px',
        fontWeight: '600',
        gap: '4px',
        lineHeight: '1.12rem',
        marginLeft: '-16px',
        maxWidth: 'fit-content',
        overflow: 'hidden',
        padding: '4px 20px',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },
    '& .MuiBreadcrumbs-li:first-of-type': {
        clipPath:
            'polygon(50% 0%, calc(100% - 15px) 0%, 100% 50%, calc(100% - 15px) 100%, 50% 100%, 0% 100%, 0% 0%)',
        padding: '4px 20px 4px 12px',
    },
    '& .MuiBreadcrumbs-li:last-child': {
        borderBottomRightRadius: '8px',
        borderTopRightRadius: '8px',
        clipPath:
            'polygon(50% 0%, 100% 0%, 100% 100%, 50% 100%, 0% 100%, 15px 50%, 0% 0%)',
        padding: '4px 12px 4px 20px',
    },
}));

const CollapsedBreadcrumbs = ({ current, locations }) => {
    const breakpoint = useBreakpoint();
    return (
        <StyledBreadCrumbs maxItems={getMaxItems({ breakpoint, locations })}>
            {locations.map(
                ({ action, customlabel = null, label, link }, index) => {
                    return (
                        <Tooltip key={`breadcrumb-${index}`} title={label}>
                            <Link
                                color="inherit"
                                href={link ? link : '#'}
                                onClick={link ? () => {} : action}
                                underline="none"
                            >
                                {customlabel ? customlabel : label}
                            </Link>
                        </Tooltip>
                    );
                }
            )}
            <Typography as="span" sx={{ fontSize: '14px', fontWeight: 600 }}>
                {current}
            </Typography>
        </StyledBreadCrumbs>
    );
};

CollapsedBreadcrumbs.propTypes = {
    current: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    locations: PropTypes.array,
};

export default CollapsedBreadcrumbs;
