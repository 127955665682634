import React, { useEffect, useState } from 'react';

import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Alert, Box, Button, UiDialog as Dialog, Typography } from 'sunwise-ui';

import * as actions from '../actions';
import {
    formatterIndicators,
    getActiveIndicators,
    handleOnChangedPropertyBuild,
    handleOnDragEnd,
} from '../helpers';
import * as selectors from '../selectors';

import SortableList from './SortableList';

const IndicatorsSettingsModal = ({
    currenciesForSelect,
    fetchProposalCurrencies,
    handleClickCloseModal,
    indicatorsSettingsData,
    isOpenIndicatorsSettingsModal,
    proposalId,
    saveIndicatorsSettings,
}) => {
    const { t } = useTranslation();
    const [indicatorsList, setIndicatorsList] = useState([]);

    useEffect(() => {
        if (isOpenIndicatorsSettingsModal && proposalId)
            fetchProposalCurrencies(proposalId);
    }, [isOpenIndicatorsSettingsModal, proposalId]);

    useEffect(() => {
        if (!isEmpty(indicatorsSettingsData))
            setIndicatorsList(formatterIndicators(indicatorsSettingsData));
    }, [indicatorsSettingsData]);

    const onDragEnd = (result) =>
        handleOnDragEnd(indicatorsList, result, setIndicatorsList);

    const handleOnSelectItem = (id, value) =>
        handleOnChangedPropertyBuild({
            id,
            indicatorsList,
            key: 'is_active',
            setIndicatorsList,
            value,
        });

    const handleOnClickSettings = (id, value) =>
        handleOnChangedPropertyBuild({
            id,
            indicatorsList,
            key: 'is_open',
            setIndicatorsList,
            value,
        });

    const handleOnSelectedProducts = (id, selectedProducts) =>
        handleOnChangedPropertyBuild({
            id,
            indicatorsList,
            key: 'products',
            setIndicatorsList,
            value: selectedProducts,
        });
    const handleOnChangedCurrency = (id, value) =>
        handleOnChangedPropertyBuild({
            id,
            indicatorsList,
            setIndicatorsList,
            value,
            key: 'currency',
        });

    const handleOnChangedYear = (id, value) =>
        handleOnChangedPropertyBuild({
            id,
            indicatorsList,
            key: 'year',
            setIndicatorsList,
            value,
        });

    const activeIndicators = getActiveIndicators(indicatorsList);

    return (
        <Dialog
            onClose={handleClickCloseModal}
            open={isOpenIndicatorsSettingsModal}
            size="md"
            title={t('Indicators settings')}
        >
            <SortableList
                currenciesForSelect={currenciesForSelect}
                handleOnChangedCurrency={handleOnChangedCurrency}
                handleOnChangedYear={handleOnChangedYear}
                handleOnClickSettings={handleOnClickSettings}
                handleOnSelectItem={handleOnSelectItem}
                handleOnSelectedProducts={handleOnSelectedProducts}
                indicators={indicatorsList}
                onDragEnd={onDragEnd}
            />
            <Box hidden={activeIndicators > 0} my={1}>
                <Alert severity="warning">
                    <Typography variant="caption" fontWeight="bold">
                        {t('At least one active indicator is required')}
                    </Typography>
                </Alert>
            </Box>
            <Box mt={2} textAlign="right">
                <Button
                    disabled={isEmpty(indicatorsList) || activeIndicators === 0}
                    onClick={() => saveIndicatorsSettings(indicatorsList)}
                    sx={{ width: { xs: '100%', md: 'auto' } }}
                >
                    {t('Save changes')}
                </Button>
            </Box>
        </Dialog>
    );
};

const mapStateToProps = createStructuredSelector({
    currenciesForSelect: selectors.getCurrenciesDataForSelect,
    indicatorsSettingsData: selectors.getIndicatorsSettingsData,
    isOpenIndicatorsSettingsModal: selectors.getIsOpenIndicatorsSettingsModal,
});

const mapDispatchToProps = (dispatch) => ({
    fetchProposalCurrencies: (proposalId) =>
        dispatch(actions.fetchProposalCurrencies(proposalId)),
    handleClickCloseModal: () =>
        dispatch(actions.setIsOpenIndicatorsSettingsModal(false)),
    saveIndicatorsSettings: (values) =>
        dispatch(actions.saveIndicatorsSettings(values)),
});

IndicatorsSettingsModal.propTypes = {
    currenciesForSelect: PropTypes.array,
    fetchProposalCurrencies: PropTypes.func,
    handleClickCloseModal: PropTypes.func,
    indicatorsSettingsData: PropTypes.array,
    isOpenIndicatorsSettingsModal: PropTypes.bool,
    proposalId: PropTypes.string,
    saveIndicatorsSettings: PropTypes.func,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(IndicatorsSettingsModal);
