import React from 'react';

import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { useFieldArray } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Box, Grid } from 'sunwise-ui';

import {
    ReactHookFormInput,
    ReactHookFormIntlNumberInput,
} from 'common/components/form/bootstrap';
import { getCountryCurrencySymbol } from 'common/utils/helpers/session';

const FieldsContainer = styled(Box)`
    display: flex;
    flex-direction: column;
`;

const RowContainer = styled(Grid)(
    ({ theme }) => `
    background: ${
        theme.palette.mode === 'dark'
            ? 'rgb(40, 43, 48)'
            : 'rgba(238, 244, 250, 0.35)'
    };
    border-radius: 8px;
    display: flex;
    padding: 10px 0;
    position: relative;

    .form-control:disabled {
        cursor: not-allowed;
    }
`
);

const FieldArrayConcepts = ({ control, name, values }) => {
    const { t } = useTranslation();
    const { fields } = useFieldArray({ control, name });
    const countryCurrencySymbol = getCountryCurrencySymbol();
    return (
        <FieldsContainer>
            {fields.map((field, index) => {
                const typeValue =
                    values[index].unit &&
                    values[index].unit !== '' &&
                    values[index].unit.search('%') >= 0
                        ? '%'
                        : countryCurrencySymbol;

                return (
                    <RowContainer container key={`field-${index}`}>
                        <Grid item sm>
                            <ReactHookFormInput
                                control={control}
                                disabled
                                label={t('Name')}
                                name={`${name}.${index}.tag_name`}
                                placeholder={t('Name')}
                            />
                        </Grid>

                        <Grid item sm>
                            <ReactHookFormIntlNumberInput
                                append={typeValue === '%' && typeValue}
                                control={control}
                                disabled
                                label={t('Quantity')}
                                name={`${name}.${index}.price`}
                                placeholder={t('Quantity')}
                                prepend={typeValue !== '%' && typeValue}
                            />
                        </Grid>

                        <Grid item sm>
                            <ReactHookFormInput
                                control={control}
                                disabled
                                input={{
                                    value: values[index]?.unit
                                        ?.toString()
                                        .replace('$', countryCurrencySymbol),
                                }}
                                label={t('Unit')}
                                name={`${name}.${index}.unit`}
                                placeholder={t('Unit')}
                            />
                        </Grid>
                    </RowContainer>
                );
            })}
        </FieldsContainer>
    );
};

FieldArrayConcepts.propTypes = {
    control: PropTypes.object,
    name: PropTypes.string,
    values: PropTypes.array,
};

export default FieldArrayConcepts;
