import { createSelector } from 'reselect';

import { NAME } from './constants';

export const getModel = (state) => state[NAME];

export const getFetchPosition = createSelector(
    getModel,
    (model) => model.fetchPositions
);

export const getPosition = createSelector(
    getFetchPosition,
    (fetchPositions) => fetchPositions.data || {}
);

export const getIsLoading = createSelector(
    getFetchPosition,
    (fetchPositions) => fetchPositions.isLoading || false
);

export const getSavePosition = createSelector(
    getModel,
    (model) => model.savePosition
);

export const getIsSaving = createSelector(
    getSavePosition,
    (savePosition) => savePosition.isLoading
);

export const getErrors = createSelector(
    getSavePosition,
    (savePosition) => savePosition.errors
);

export const getSelectedProject = createSelector(
    getModel,
    (model) => model.selectedProject
);
