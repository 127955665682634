import i18next from 'i18next';
import { types } from 'sunwise-template-core';

import { deleteSmartDocument } from 'common/api/v1/smartDocuments';
import alerts from 'common/modules/alerts';
import { showReponseErrorsAsAlert } from 'common/utils/helpers';
import showToast from 'common/utils/showToast';

import {
    DELETE_OFFER_SMART_DOCUMENT,
    DELETE_OFFER_SMART_DOCUMENT_FAILURE,
    DELETE_OFFER_SMART_DOCUMENT_SUCCESS,
} from '../../actionTypes';
import { multiTemplateReviewActions } from '../../reducer';
import * as selectors from '../../selectors';

import fetchOfferSmartDocuments from './fetchOfferSmartDocuments';

export default (
        handleClickDocumentItem,
        proposalDocumentDefault,
        proposalId,
        smartDocumentId
    ) =>
    (dispatch, getState) => {
        const state = getState();
        const offerSmartDocuments = selectors.getOfferSmartDocumentsData(state);
        const filteredSmartDocuments = offerSmartDocuments.find(
            (item) => item.id !== smartDocumentId
        );
        dispatch(multiTemplateReviewActions[DELETE_OFFER_SMART_DOCUMENT]());

        deleteSmartDocument(smartDocumentId)
            .then(() => {
                dispatch(
                    multiTemplateReviewActions[
                        DELETE_OFFER_SMART_DOCUMENT_SUCCESS
                    ](smartDocumentId)
                );
                dispatch(fetchOfferSmartDocuments(proposalId));
                if (filteredSmartDocuments)
                    handleClickDocumentItem({
                        ...filteredSmartDocuments,
                        type: types.SMART_DOCUMENTS_TYPE,
                    });
                else handleClickDocumentItem(proposalDocumentDefault);
                dispatch(alerts.actions.close());
                showToast({ body: i18next.t('It was successfully removed') });
            })
            .catch((error) => {
                dispatch(
                    multiTemplateReviewActions[
                        DELETE_OFFER_SMART_DOCUMENT_FAILURE
                    ](error?.response?.data?.errors)
                );
                showReponseErrorsAsAlert(dispatch, error?.response);
            });
    };
