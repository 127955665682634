const getBaseConsumptionValue = (month) =>
    -(3269 * month ** 11) / 5702400 +
    (29933 * month ** 10) / 725760 -
    (474547 * month ** 9) / 362880 +
    (727379 * month ** 8) / 30240 -
    (16374263 * month ** 7) / 57600 +
    (78060773 * month ** 6) / 34560 -
    (158909333 * month ** 5) / 12960 +
    (16420040717 * month ** 4) / 362880 -
    (50123707891 * month ** 3) / 453600 +
    (1698373877 * month ** 2) / 10080 -
    (564321353 * month) / 3960 +
    50542;

export const getBaseConsumptionValues = (args = {}) => {
    const { lowHighMultiplier = 1, shiftMonths = 0 } = args;
    let arr = [];
    for (let i = 12; i >= 1; i--)
        arr.push(getBaseConsumptionValue(i) * lowHighMultiplier);
    return getShiftMonths({ arr, shiftMonths });
};

export const getShiftMonths = ({ arr, shiftMonths }) =>
    !shiftMonths
        ? arr
        : arr.slice(shiftMonths).concat(arr.slice(0, shiftMonths));

export const getAvgMultiplier = ({
    baseConsumptionArray,
    mergedUserValues,
}) => {
    let cont = 0,
        sum = 0;
    mergedUserValues.forEach((element, index) => {
        if (element && element !== null) {
            sum += element / baseConsumptionArray[index];
            cont++;
        }
    });
    return !sum ? 1 : sum / cont;
};

export const getGroupsByPeriods = ({
    baseConsumptionArray,
    numPeriods = 12,
}) => {
    if (numPeriods === 0) return baseConsumptionArray;
    const arr = [];
    const baseIndex = 12 / numPeriods;
    for (let p = 0; p < numPeriods; p++) {
        const tempArr = [];
        for (let i = baseIndex * p; i <= baseIndex * p + (baseIndex - 1); i++) {
            tempArr.push(baseConsumptionArray[i]);
        }
        arr.push(tempArr);
    }
    return arr;
};

export const mergeUserValues = ({ groupsByPeriods, userValues }) => {
    const arr = [];
    if (!userValues || userValues.length === 0) return groupsByPeriods.flat();
    groupsByPeriods.forEach((group, index) => {
        const totalGroup = group.reduce((total, curr) => total + curr, 0);
        group.forEach((originalValue) => {
            arr.push(
                userValues[index] === null || userValues[index] === ''
                    ? null
                    : (userValues[index] / totalGroup) * originalValue
            );
        });
    });
    return arr;
};

export const calculateValues = ({
    avgMultiplier,
    baseConsumptionArray,
    mergedUserValues,
}) =>
    mergedUserValues.map(
        (element, index) =>
            element ?? baseConsumptionArray[index] * avgMultiplier
    );

/*
const capturedValuesMonthly = [
    2000,
    null,
    null,
    null,
    null,
    null,
    null,
    1000,
    null,
    null,
    null,
    null,
];
const capturedValuesBimonthly = [2000, 1000, null, null, null, null];
const capturedValuesQuarterly = [2000, 1000, null, null];
const capturedValuesAnnual = [];
const testCapturedValues = capturedValuesMonthly;

const baseConsumptionArray = getBaseConsumptionValues();
const groupsByPeriods = getGroupsByPeriods({
    baseConsumptionArray,
    numPeriods: testCapturedValues.length,
});
const mergedCapturedValues = mergeCapturedValues({
    userValues: testCapturedValues,
    groupsByPeriods,
});
const avgMultiplier = getAvgMultiplier({
    baseConsumptionArray,
    mergedCapturedValues,
});
const south = baseConsumptionArray
    .slice(6)
    .concat(baseConsumptionArray.slice(0, 6));

*/
