import { saveConfig } from 'common/api/v2/timeShiftingHourlyConfiguration';
import { showReponseErrorsAsAlert } from 'common/utils/helpers';

import eventsAfterUpdate from '../../proposalGeneratorOneColumn/actions/eventsAfterUpdate';
import {
    SAVE_TIME_SHIFTING_CONFIGURATION,
    SAVE_TIME_SHIFTING_CONFIGURATION_FAILURE,
    SAVE_TIME_SHIFTING_CONFIGURATION_SUCCESS,
} from '../actionTypes';
import { getFlatTimeShiftingConfiguration } from '../helpers';
import { timeShiftingConfigurationActions } from '../reducer';

export default (proposalId, values) => (dispatch) => {
    if (!values?.battery_hourly_status || !proposalId) return;
    dispatch(
        timeShiftingConfigurationActions[SAVE_TIME_SHIFTING_CONFIGURATION]()
    );

    const { battery_hourly_status } = values;

    const newValues = {
        saturday_distribution: getFlatTimeShiftingConfiguration(
            battery_hourly_status.saturday
        ),
        sunday_distribution: getFlatTimeShiftingConfiguration(
            battery_hourly_status.sunday
        ),
        weekday_distribution: getFlatTimeShiftingConfiguration(
            battery_hourly_status.weekday
        ),
    };

    saveConfig(proposalId, newValues)
        .then((response) => {
            dispatch(
                timeShiftingConfigurationActions[
                    SAVE_TIME_SHIFTING_CONFIGURATION_SUCCESS
                ](response?.data?.data)
            );

            dispatch(eventsAfterUpdate(response?.data?.data, proposalId));
        })
        .catch((error) => {
            dispatch(
                timeShiftingConfigurationActions[
                    SAVE_TIME_SHIFTING_CONFIGURATION_FAILURE
                ](error?.response?.data?.errors)
            );

            showReponseErrorsAsAlert(dispatch, error?.response);
        });
};
