import { getUserById } from 'common/api/v1/users';
import * as funnelSocket from 'common/sockets/funnel';

import { BRANCH_OFFICES_DICTIONARY_BACKUP } from '../actionTypes';
import { funnelProjectsActions } from '../reducer';
export default (userId, branchOfficesDictionary) => (dispatch) => {
    dispatch(
        funnelProjectsActions[BRANCH_OFFICES_DICTIONARY_BACKUP](
            branchOfficesDictionary
        )
    );
    getUserById(userId).then((response) => {
        if (response.data.company) {
            dispatch(funnelSocket.connect(response.data.company));
        }
    });
};
