import React, { useContext, useEffect, useState } from 'react';

import get from 'lodash/get';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { createStructuredSelector } from 'reselect';
import {
    Alert,
    Box,
    Container as MaterialContainer,
    Grid,
    UiDialog as Dialog,
    Typography,
} from 'sunwise-ui';

import { HeaderContainer, InputSearch, TitleIcon } from 'common/components';
import { PLAN_TYPES } from 'common/constants';
import { PERMISSION_LIST } from 'common/constants/permissionsV2';
import detectScrollBottom from 'common/hocs/detectScrollBottom';
import withPermissions from 'common/hocs/withPermissions';
import { LoadingContext } from 'common/utils/contexts';
import {
    getIsOwnerUser,
    getSessionLocalStorage,
} from 'common/utils/helpers/session';

import * as companyGeneralActions from '../companyGeneral/actions';
import * as companyGeneralSelectors from '../companyGeneral/selectors';
import * as profileSelectors from '../profile/selectors';

import * as actions from './actions';
import {
    ListItems,
    ReassignByUpdateModal,
    ReassignByDeactivateModal,
    ResetPasswordModal,
    UserForm,
} from './components';
import DefaultButton from './components/DefaultButton';
import { getIsDisabledAddMemberButton } from './helpers';
import * as selectors from './selectors';

const Container = ({
    branchSelected,
    changeSearchText,
    closeModalProduct,
    company,
    displayOnlyBottom,
    fetchCompanyData,
    fetchRoles,
    filterItems,
    getPermissionsByCode,
    handleChangePassword,
    handleChangePasswordConfirmation,
    handleClickCreate,
    handleClickLicenses,
    initialValues,
    isFetching,
    IsFetchingActivateItem,
    isFetchingRoles,
    isOpenModalProduct,
    isSavingItem,
    items,
    reset,
    roles,
    rolesDictionary,
    searchItems,
    searchText,
    subscription,
    validPassword,
    validPasswordConfirmation,
    validPasswordConfirmationLength,
}) => {
    const { t } = useTranslation();
    const [dialogTitle, setDialogTitle] = useState(null);
    const loadingContext = useContext(LoadingContext);
    const counters = get(subscription, 'counters', {});
    const activeUsers = get(subscription, 'active_users', 0);
    const subscriptionType = get(subscription, 'type.key', null);
    const isOwnerUser = getIsOwnerUser();
    const session = getSessionLocalStorage();
    const isDisabledAddMemberButton = getIsDisabledAddMemberButton(
        activeUsers,
        counters,
        subscriptionType
    );
    const isPro = subscriptionType === PLAN_TYPES.PRO;
    const { canDelete, canModify, canView } = getPermissionsByCode(
        PERMISSION_LIST.COMPANY_USERS_PERMISSION
    );
    const { canModify: canModifyContacts } = getPermissionsByCode(
        PERMISSION_LIST.CONTACTS_PERMISSION
    );
    const { canModify: canModifyRemainingLicenses } = getPermissionsByCode(
        PERMISSION_LIST.SUBSCRIPTION_PERMISSION
    );

    useEffect(() => {
        fetchCompanyData();
        fetchRoles();
        filterItems();
        return () => {
            reset();
        };
    }, []);

    useEffect(() => {
        if (IsFetchingActivateItem)
            loadingContext.openLoading(t('Saving user status').concat('...'));
        return () => {
            if (IsFetchingActivateItem) loadingContext.closeLoading();
        };
    }, [IsFetchingActivateItem]);

    useEffect(() => {
        if (isSavingItem)
            loadingContext.openLoading(
                t('Saving change', { count: 2 }).concat('...')
            );
        return () => {
            if (isSavingItem) loadingContext.closeLoading();
        };
    }, [isSavingItem]);

    const handleChangeSearch = (e) => {
        changeSearchText(e.target.value);
        searchItems();
    };

    const onChangePassword = (event) => handleChangePassword(event);
    const onChangePasswordConfirmation = (event, password) =>
        handleChangePasswordConfirmation(event, password);

    return (
        <MaterialContainer maxWidth={false}>
            <HeaderContainer>
                <Grid item md={12} xs={18}>
                    <TitleIcon
                        fontWeight="bold"
                        title={t('User', { count: 2 })}
                    />
                </Grid>

                <Grid item xs={18} md={6} textAlign="right">
                    <DefaultButton
                        branchSelected={branchSelected}
                        canModify={canModify}
                        canModifyRemainingLicenses={canModifyRemainingLicenses}
                        company={company}
                        handleClickCreate={handleClickCreate}
                        handleClickLicenses={handleClickLicenses}
                        isDisabledAddMemberButton={isDisabledAddMemberButton}
                        isHidden={
                            isFetching ||
                            isFetchingRoles ||
                            IsFetchingActivateItem
                        }
                        isPro={isPro}
                    />
                </Grid>
            </HeaderContainer>
            {canView && (
                <Box>
                    <Box sx={{ my: 2 }}>
                        <InputSearch
                            onChange={handleChangeSearch}
                            placeholder={t('Filter by name')}
                            value={searchText}
                            visible={canView}
                        />
                    </Box>
                    <ListItems
                        handleChangeSearch={handleChangeSearch}
                        isFetching={isFetching}
                        isFetchingRoles={isFetchingRoles}
                        isOwnerUser={isOwnerUser}
                        items={items}
                        searchText={searchText}
                    />
                </Box>
            )}

            {displayOnlyBottom && (
                <Box mt={2} textAlign="right">
                    <DefaultButton
                        branchSelected={branchSelected}
                        canModify={canModify}
                        canModifyRemainingLicenses={canModifyRemainingLicenses}
                        company={company}
                        handleClickCreate={handleClickCreate}
                        handleClickLicenses={handleClickLicenses}
                        isDisabledAddMemberButton={isDisabledAddMemberButton}
                        isHidden={
                            isFetching ||
                            isFetchingRoles ||
                            IsFetchingActivateItem
                        }
                        isPro={isPro}
                    />
                </Box>
            )}
            {isDisabledAddMemberButton && (
                <Box mt={2}>
                    <Alert severity="warning">
                        <Typography fontWeight="bold" variant="body2">
                            {t("You have reached your plan's user limit")}
                        </Typography>
                    </Alert>
                </Box>
            )}

            <Dialog
                onClose={closeModalProduct}
                open={isOpenModalProduct}
                title={dialogTitle}
            >
                <UserForm
                    canDelete={canDelete}
                    canModify={canModify && session.id !== initialValues.id}
                    canModifyContacts={canModifyContacts}
                    company={company}
                    onChangePassword={onChangePassword}
                    onChangePasswordConfirmation={onChangePasswordConfirmation}
                    roles={roles}
                    rolesDictionary={rolesDictionary}
                    setDialogTitle={setDialogTitle}
                    validPassword={validPassword}
                    validPasswordConfirmation={validPasswordConfirmation}
                    validPasswordConfirmationLength={
                        validPasswordConfirmationLength
                    }
                />
            </Dialog>
            <ReassignByDeactivateModal />
            <ReassignByUpdateModal />
            <ResetPasswordModal />
        </MaterialContainer>
    );
};

const mapStateToProps = createStructuredSelector({
    company: companyGeneralSelectors.getCompanyData,
    initialValues: selectors.getInitialValues,
    isFetching: selectors.getIsFetchingItems,
    IsFetchingActivateItem: selectors.getIsFetchingActivateItem,
    isFetchingRoles: selectors.getIsFetchingRoles,
    isOpenModalProduct: selectors.getIsOpenModalProduct,
    isSavingItem: selectors.getIsSavingItem,
    items: selectors.getDataFetchItems,
    roles: selectors.getFetchRolesData,
    rolesDictionary: selectors.getFetchRolesDictionary,
    searchText: selectors.getSearchText,
    subscription: profileSelectors.getSubscription,
    validPassword: selectors.getValidPassword,
    validPasswordConfirmation: selectors.getValidPasswordConfirmation,
    validPasswordConfirmationLength:
        selectors.getValidPasswordConfirmationLength,
});

const mapDispatchToProps = (dispatch) => ({
    changeSearchText: (value) => dispatch(actions.changeSearchText(value)),
    closeModalProduct: () => dispatch(actions.closeModalProduct()),
    filterItems: () => dispatch(actions.filterItems()),
    fetchCompanyData: () => dispatch(companyGeneralActions.fetchCompanyData()),
    fetchRoles: () => dispatch(actions.fetchRoles()),
    handleChangePassword: (event) =>
        dispatch(actions.handleChangePassword(event)),
    handleChangePasswordConfirmation: (event, password) =>
        dispatch(actions.handleChangePasswordConfirmation(event, password)),
    handleClickCreate: (branchSelected, companyId) =>
        dispatch(actions.prepareCreate(branchSelected, companyId)),
    handleClickLicenses: () => dispatch(actions.redirectLicenses()),
    reset: () => dispatch(actions.reset()),
    searchItems: () => dispatch(actions.searchItems()),
});

Container.propTypes = {
    branchSelected: PropTypes.string,
    changeSearchText: PropTypes.func,
    closeModalProduct: PropTypes.func,
    company: PropTypes.object,
    displayOnlyBottom: PropTypes.bool,
    fetchCompanyData: PropTypes.func,
    fetchRoles: PropTypes.func,
    filterItems: PropTypes.func,
    getPermissionsByCode: PropTypes.func,
    handleChangePassword: PropTypes.func,
    handleChangePasswordConfirmation: PropTypes.func,
    handleClickCreate: PropTypes.func,
    handleClickLicenses: PropTypes.func,
    initialValues: PropTypes.object,
    isFetching: PropTypes.bool,
    IsFetchingActivateItem: PropTypes.bool,
    isFetchingRoles: PropTypes.bool,
    isOpenModalProduct: PropTypes.bool,
    isSavingItem: PropTypes.bool,
    items: PropTypes.array,
    reset: PropTypes.func,
    roles: PropTypes.array,
    rolesDictionary: PropTypes.object,
    searchItems: PropTypes.func,
    searchText: PropTypes.string,
    subscription: PropTypes.object,
    validPassword: PropTypes.bool,
    validPasswordConfirmation: PropTypes.bool,
    validPasswordConfirmationLength: PropTypes.bool,
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    detectScrollBottom(),
    withPermissions([
        PERMISSION_LIST.COMPANY_USERS_PERMISSION,
        PERMISSION_LIST.CONTACTS_PERMISSION,
        PERMISSION_LIST.SUBSCRIPTION_PERMISSION,
    ])
)(Container);
