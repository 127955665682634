import React from 'react';

import get from 'lodash/get';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Typography } from 'sunwise-ui';

import {
    formatDistanceDate,
    parseDate,
    formatDate,
    zonedDate,
} from 'common/utils/dates';
import { getTimezoneUserSettings } from 'common/utils/helpers/multiregion';

import TrackingItem from './TrackingItem';

const PastTrackings = ({
    canDelete,
    canModify,
    handleClickChangeDate,
    handleClickDelete,
    handleClickMarkAsCompleted,
    handleClickUpdate,
    isLoading,
    trackings,
    users,
}) => {
    const { t } = useTranslation();

    if (!isLoading && trackings?.length === 0) {
        return (
            <Typography variant="body2">
                {t('You have no completed follow-ups')}
            </Typography>
        );
    }
    return trackings.map((tracking, index) => {
        const agent = `${get(tracking, 'agent.first_name', '---')} ${get(
            tracking,
            'agent.last_name',
            '---'
        )}`;
        const currentDate = zonedDate(new Date(), getTimezoneUserSettings());
        const trackingPurpose = tracking.tracking_purpose?.name || '---';
        let createdAt = '';
        let trackingDate = '';
        let hasExpired = false;

        if (tracking.notification_at) {
            const targetDate = parseDate(
                tracking?.notification_at,
                'dd/MM/yyyy HH:mm:ss'
            );
            createdAt = formatDistanceDate(
                targetDate,
                zonedDate(new Date(), getTimezoneUserSettings()),
                { addSuffix: true }
            );
            trackingDate = formatDate(targetDate, 'dd MMMM yyyy h:mm aa');
            hasExpired = currentDate > targetDate;
        }

        return (
            <TrackingItem
                canDelete={canDelete}
                canModify={canModify}
                agent={agent}
                completed={tracking.completed}
                createdAt={createdAt}
                description={tracking.description}
                goal={trackingPurpose}
                handleClickDelete={() => handleClickDelete(tracking)}
                handleClickMarkAsCompleted={() =>
                    handleClickMarkAsCompleted(tracking.id)
                }
                handleClickUpdate={() => handleClickUpdate(tracking.id)}
                handleClickChangeDate={handleClickChangeDate}
                hasExpired={hasExpired}
                id={tracking.id}
                key={index}
                remember={tracking.remember}
                trackingDate={trackingDate}
                trackingType={tracking.tracking_type}
                users={users}
            />
        );
    });
};

PastTrackings.propTypes = {
    canDelete: PropTypes.bool,
    canModify: PropTypes.bool,
    handleClickChangeDate: PropTypes.func,
    handleClickDelete: PropTypes.func,
    handleClickMarkAsCompleted: PropTypes.func,
    handleClickUpdate: PropTypes.func,
    isLoading: PropTypes.bool,
    trackings: PropTypes.array,
    users: PropTypes.array,
};

export default PastTrackings;
