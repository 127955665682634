import { getSummerMonths } from 'common/api/v1/catalogs';

import {
    FETCH_SUMMER_MONTHS,
    FETCH_SUMMER_MONTHS_SUCCESS,
    FETCH_SUMMER_MONTHS_FAILURE,
} from '../actionTypes';
import { actions } from '../reducer';

export default () => (dispatch) => {
    dispatch(actions[FETCH_SUMMER_MONTHS]());
    getSummerMonths()
        .then(({ data }) =>
            dispatch(actions[FETCH_SUMMER_MONTHS_SUCCESS](data?.data))
        )
        .catch((error) =>
            dispatch(
                actions[FETCH_SUMMER_MONTHS_FAILURE](
                    error?.response?.data?.errors
                )
            )
        );
};
