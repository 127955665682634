import { reassignContacts } from 'common/api/v1/contacts';
import { showReponseErrorsAsAlert } from 'common/utils/helpers';

import {
    FETCH_REASSIGN_CONTACTS,
    FETCH_REASSIGN_CONTACTS_FAILURE,
    FETCH_REASSIGN_CONTACTS_SUCCESS,
} from '../actionTypes';
import { companyUsersActions } from '../reducer';

export default (userId, reassignBranches, dataToSave) => (dispatch) =>
    new Promise((resolve, reject) => {
        dispatch(companyUsersActions[FETCH_REASSIGN_CONTACTS]());

        const reassignBody = {
            update: reassignBranches.map((reassignBranch) => {
                if (reassignBranch.branch_office_id === null) {
                    return {
                        branch_office_id: 'company',
                        new_agent_id: reassignBranch.newAgent,
                    };
                }
                return {
                    branch_office_id: reassignBranch.branchId,
                    new_agent_id: reassignBranch.newAgent,
                };
            }),
        };
        reassignContacts(userId, reassignBody)
            .then(() => {
                dispatch(
                    companyUsersActions[FETCH_REASSIGN_CONTACTS_SUCCESS]()
                );
                resolve({ userId, dataToSave });
            })
            .catch((error) => {
                dispatch(
                    companyUsersActions[FETCH_REASSIGN_CONTACTS_FAILURE](
                        error?.response?.data?.errors
                    )
                );
                reject();
                showReponseErrorsAsAlert(dispatch, error?.response);
            });
    });
