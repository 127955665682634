import { NAME } from './constants';

export const FETCH_OFFER_COMPLEMENTS = `${NAME}/FETCH_OFFER_COMPLEMENTS`;
export const FETCH_OFFER_COMPLEMENTS_FAILURE = `${NAME}/FETCH_OFFER_COMPLEMENTS_FAILURE`;
export const FETCH_OFFER_COMPLEMENTS_SUCCESS = `${NAME}/FETCH_OFFER_COMPLEMENTS_SUCCESS`;

export const INITIAL_VALUES = `${NAME}/INITIAL_VALUES`;

export const RESET_FORM = `${NAME}/RESET_FORM`;

export const SAVE = `${NAME}/SAVE`;
export const SAVE_FAILURE = `${NAME}/SAVE_FAILURE`;
export const SAVE_SUCCESS = `${NAME}/SAVE_SUCCESS`;

export const SET_IS_OPEN_MODAL = `${NAME}/SET_IS_OPEN_MODAL`;
