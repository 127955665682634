import { getItemsByLevel } from 'common/api/v1/politicalDivisions';

import {
    FETCH_POLITICAL_DIVISIONS,
    FETCH_POLITICAL_DIVISIONS_SUCCESS,
    FETCH_POLITICAL_DIVISIONS_FAILURE,
} from '../actionTypes';
import { projectConsumptionModalActions } from '../reducer';

export default (str) => (dispatch) => {
    dispatch(projectConsumptionModalActions[FETCH_POLITICAL_DIVISIONS]());
    getItemsByLevel(str)
        .then(({ data }) =>
            dispatch(
                projectConsumptionModalActions[
                    FETCH_POLITICAL_DIVISIONS_SUCCESS
                ](data.data)
            )
        )
        .catch((error) =>
            dispatch(
                projectConsumptionModalActions[
                    FETCH_POLITICAL_DIVISIONS_FAILURE
                ](error?.response?.data?.errors)
            )
        );
};
