import i18next from 'i18next';
import isEmpty from 'lodash/isEmpty';

import { BUREAU_STATUS, STATUS } from 'common/constants';

export const getNoAttempts = (records) => {
    if (records.current >= records.limit) {
        return true;
    }
    return false;
};

export const getIsDisabled = (
    creditStatus,
    isFetching,
    isPassed,
    isSaving,
    records
) => {
    if (
        isFetching ||
        isSaving ||
        isPassed ||
        getNoAttempts(records) ||
        creditStatus === STATUS.CANCELLED_STATUS.key
    ) {
        return true;
    }
    return false;
};

export const getIsPassed = (status) => status.key === BUREAU_STATUS.PASSED;

export const getIsShowEditButton = (
    creditStatus,
    fetchBureauData,
    hasRequestBureau,
    status
) => {
    if (
        !isEmpty(fetchBureauData) &&
        hasRequestBureau &&
        !getIsPassed(status) &&
        creditStatus !== STATUS.CANCELLED_STATUS.key
    ) {
        return true;
    }
    return false;
};

export const getStatusLabel = (status) => {
    switch (status.key) {
        case BUREAU_STATUS.REFUSED:
            return i18next.t('Rejected');
        case BUREAU_STATUS.NOT_FOUND:
            return i18next.t('Not found');
        case BUREAU_STATUS.NOT_AUTHORIZED:
            return i18next.t('Not authorized');
        case BUREAU_STATUS.PASSED:
            return i18next.t('Passed');
        case BUREAU_STATUS.PENDING_CLARIFICATION:
            return i18next.t('Pending clarification');
        default:
            return i18next.t('Requested');
    }
};

export const getStatusButtonProps = (status) => {
    switch (status.key) {
        case BUREAU_STATUS.REQUESTED:
            return {
                background: '#CED4DC',
                color: '#011e2e',
            };
        case BUREAU_STATUS.REFUSED:
            return {
                background: '#E81412',
                color: '#011e2e',
            };
        case BUREAU_STATUS.NOT_FOUND:
            return {
                background: '#979EA8',
                color: '#011e2e',
            };
        case BUREAU_STATUS.NOT_AUTHORIZED:
            return {
                background: '#282C34',
                color: '#ffffff',
            };
        case BUREAU_STATUS.PASSED:
            return {
                background: '#54C45E',
                color: '#011e2e',
            };
        case BUREAU_STATUS.PENDING_CLARIFICATION:
            return {
                background: '#F6E203',
                color: '#011e2e',
            };
        default:
            return {};
    }
};

export const upperRFCFieldValue = (setValue, value) =>
    setValue('rfc', value.toUpperCase());
