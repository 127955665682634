import i18next from 'i18next';
import { createSelector } from 'reselect';

import {
    ALLIANCE_DOCUMENTS_CATEGORY,
    REQUEST_FORM_TYPE,
    DOCUMENTS_TYPE,
    CONTRACT_TYPE,
} from 'common/constants';

import { NAME } from './constants';

export const getModel = (state) => state[NAME];

export const getInitialValues = createSelector(
    getModel,
    (model) => model.initialValues
);

export const getTermInitialValues = createSelector(
    getModel,
    (model) => model.termInitialValues
);

/* fetch financiers */
export const getFinanciersModel = createSelector(
    getModel,
    (model) => model.financiers
);

export const getFinanciersData = createSelector(
    getFinanciersModel,
    (model) => model.data
);

export const getFinanciersErrors = createSelector(
    getFinanciersModel,
    (model) => model.errors
);

export const getFinanciersIsFetching = createSelector(
    getFinanciersModel,
    (model) => model.isFetching
);

/* fetch financiers-installer-documents */
export const getFinancierInstallerDocumentsModel = createSelector(
    getModel,
    (model) => model.financierInstallerDocuments
);

export const getFinancierInstallerDocumentsData = createSelector(
    getFinancierInstallerDocumentsModel,
    (model) => model.data
);

export const getFinancierInstallerDocumentsErrors = createSelector(
    getFinancierInstallerDocumentsModel,
    (model) => model.errors
);

export const getFinancierInstallerDocumentsIsFetching = createSelector(
    getFinancierInstallerDocumentsModel,
    (model) => model.isFetching
);

export const getDocumentsList = createSelector(
    getFinancierInstallerDocumentsData,
    (model) =>
        Array.isArray(model.financier_installer_document) &&
        model.financier_installer_document.length > 0
            ? model.financier_installer_document.filter(
                  (item) =>
                      item.category === ALLIANCE_DOCUMENTS_CATEGORY &&
                      item.type === DOCUMENTS_TYPE
              )
            : []
);

export const getRequestFormatList = createSelector(
    getFinancierInstallerDocumentsData,
    (model) =>
        Array.isArray(model.financier_installer_document) &&
        model.financier_installer_document.length > 0
            ? model.financier_installer_document.filter(
                  (item) =>
                      item.category === ALLIANCE_DOCUMENTS_CATEGORY &&
                      item.type === REQUEST_FORM_TYPE
              )
            : []
);

export const getContractsList = createSelector(
    getFinancierInstallerDocumentsData,
    (model) =>
        Array.isArray(model.financier_installer_document) &&
        model.financier_installer_document.length > 0
            ? model.financier_installer_document.filter(
                  (item) =>
                      item.category === ALLIANCE_DOCUMENTS_CATEGORY &&
                      item.type === CONTRACT_TYPE
              )
            : []
);

export const getAllianceProductsList = createSelector(
    getFinancierInstallerDocumentsData,
    (model) => model.financier_product
);

/* fetch financier-product */
export const getFinancierProductModel = createSelector(
    getModel,
    (model) => model.financierProduct
);

export const getFinancierProductIsFetching = createSelector(
    getFinancierProductModel,
    (model) => model.isFetching
);

export const getFinancierProductData = createSelector(
    getFinancierProductModel,
    (model) => model?.data?.data
);

export const getPaginationFinancierProduct = createSelector(
    getFinancierProductModel,
    (fetchItems) => fetchItems?.data?.pagination
);

export const getFinancierProductErrors = createSelector(
    getFinancierProductModel,
    (model) => model.errors
);

/* fetch financiers-installer */
export const getFinanciersInstallerModel = createSelector(
    getModel,
    (model) => model.financiersInstaller
);

export const getFinanciersInstallerData = createSelector(
    getFinanciersInstallerModel,
    (model) => model.data
);

export const getFinanciersInstallerErrors = createSelector(
    getFinanciersInstallerModel,
    (model) => model.errors
);

export const getFinanciersInstallerIsFetching = createSelector(
    getFinanciersInstallerModel,
    (model) => model.isFetching
);

/* save financiers-installer */
export const getSaveFinanciersInstallerModel = createSelector(
    getModel,
    (model) => model.saveFinanciersInstaller
);

export const getSaveFinanciersInstallerData = createSelector(
    getSaveFinanciersInstallerModel,
    (model) => model.data
);

export const getSaveFinanciersInstallerErrors = createSelector(
    getSaveFinanciersInstallerModel,
    (model) => model.errors
);

export const getSaveFinanciersInstallerIsFetching = createSelector(
    getSaveFinanciersInstallerModel,
    (model) => model.isSaving
);

/* Fetch productos */
export const getModelProducts = createSelector(
    getModel,
    (model) => model.products
);

export const getProductsData = createSelector(
    getModelProducts,
    (model) => model.data
);

export const getProductsErrors = createSelector(
    getModelProducts,
    (model) => model.errors
);

export const getProductsIsFetching = createSelector(
    getModelProducts,
    (model) => model.isFetching
);

/* Modal product status */
export const getProductModalIsOpen = createSelector(
    getModel,
    (model) => model.productModalIsOpen
);

export const getUploadingFile = createSelector(
    getModel,
    (model) => model.fileUploaded
);

export const isFileUploading = createSelector(
    getUploadingFile,
    (fileUploaded) => fileUploaded.isUploaing
);

export const getUploadedFileData = createSelector(
    getUploadingFile,
    (fileUploaded) => fileUploaded.data || []
);

export const getUploadedFileErrors = createSelector(
    getUploadingFile,
    (fileUploaded) => fileUploaded.errors
);

/* Requirements */
export const getRequirementsModel = createSelector(
    getModel,
    (model) => model.requirements
);

export const getRequirementsData = createSelector(
    getRequirementsModel,
    (model) => model.data || { content: '' }
);

export const getRequirementsErrors = createSelector(
    getRequirementsModel,
    (model) => model.errors
);

export const getRequirementsIsFetching = createSelector(
    getRequirementsModel,
    (model) => model.isFetching
);

export const getProductEnableUpdating = createSelector(
    getModel,
    (model) => model.productEnableUpdating
);

// Catalog requirements
export const getCatalogRequirementsModel = createSelector(
    getModel,
    (model) => model.catalogRequirements
);

export const getCatalogRequirementsData = createSelector(
    getCatalogRequirementsModel,
    (model) => {
        return model.data
            ? model.data[0]
            : { content: i18next.t('Loading').concat('...') };
    }
);

/* Templates */
export const getfetchTemplateCatalogs = createSelector(
    getModel,
    (model) => model.fetchTemplateCatalogs
);

export const getIsFetchingTemplateCatalogs = createSelector(
    getfetchTemplateCatalogs,
    (fetchTemplateCatalogs) => fetchTemplateCatalogs.isFetching
);

export const getfetchTemplateCatalogsData = createSelector(
    getfetchTemplateCatalogs,
    (fetchTemplateCatalogs) => fetchTemplateCatalogs.data || []
);

export const getfetchTemplateCatalogsErrors = createSelector(
    getfetchTemplateCatalogs,
    (fetchTemplateCatalogs) => fetchTemplateCatalogs.errors || []
);
