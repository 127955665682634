import i18next from 'i18next';

import { uploadCFEFile } from 'common/api/v1/contactProject';
import alerts from 'common/modules/alerts';

import {
    UPLOAD_FILE,
    UPLOAD_FILE_FAILURE,
    UPLOAD_FILE_SUCCESS,
} from '../actionTypes';
import { CONSUMPTIONS_CAPTURE_MODE } from '../constants';
import { projectConsumptionModalActions } from '../reducer';
import * as selectors from '../selectors';

import fetchLocationsByZipCode from './fetchLocationsByZipCode';
import fetchPoliticalDivisions2 from './fetchPoliticalDivisions2';
import fetchRateDivisions from './fetchRateDivisions';
import fetchRegionsByPoliticalDivision from './fetchRegionsByPoliticalDivision';
import fetchScheduleRateConfiguration from './handleFetchScheduleRateConfiguration';
import setConsumptionsCaptureMode from './setConsumptionsCaptureMode';
import setConsumptionsRawData from './setConsumptionsRawData';
import setConsumptionWithCsvDataCalculated from './setConsumptionWithCsvDataCalculated';
import uploadDocumentsAI from './uploadDocumentsAI';

export default ({ file, getValues, setValue }) =>
    (dispatch, getState) => {
        if (!file) return;

        dispatch(projectConsumptionModalActions[UPLOAD_FILE]());

        dispatch(
            alerts.actions.show({
                confirmText: i18next.t('Back'),
                hideButtons: true,
                messages: [
                    i18next.t(
                        'Please wait a moment as the information is processed'
                    ),
                ],
                title: i18next.t('Loading').concat('...'),
                type: alerts.ALERT_TYPE_ALERT,
                variant: 'info',
            })
        );

        uploadCFEFile({ file })
            .then((response) => {
                const state = getState();
                const ratesDictionary = selectors.getRatesDictionary(state);
                const ratesNameIdDictionary =
                    selectors.getCertifiedRatesNameIdDictionary(state);
                const data = response?.data?.data;
                const rateId = ratesNameIdDictionary[data.rate];
                const rate = ratesDictionary[rateId];
                setValue('rate', rateId || null);
                setValue('zip_code', data?.zip_code || null);

                dispatch(
                    setConsumptionsCaptureMode(
                        CONSUMPTIONS_CAPTURE_MODE.SCRAPPER
                    )
                );
                dispatch(
                    setConsumptionsRawData({
                        ...data,
                        receipt_origin: 'automatic_pdf',
                    })
                );

                dispatch(
                    projectConsumptionModalActions[UPLOAD_FILE_SUCCESS](data)
                );
                dispatch(setConsumptionWithCsvDataCalculated(false));
                dispatch(alerts.actions.close());

                if (data.zip_code)
                    dispatch(
                        fetchLocationsByZipCode({
                            getValues,
                            rate,
                            setValue,
                            value: data.zip_code,
                        })
                    );
                else if (data.political_division) {
                    dispatch(fetchPoliticalDivisions2(data.political_division));
                    Promise.all([
                        dispatch(
                            fetchRateDivisions(
                                data.political_division,
                                null,
                                setValue
                            )
                        ),
                        dispatch(
                            fetchRegionsByPoliticalDivision(
                                data.political_division,
                                setValue
                            )
                        ),
                    ]).then(([rateDivision]) =>
                        dispatch(
                            fetchScheduleRateConfiguration({
                                getValues,
                                rate,
                                rateDivision,
                                setValue,
                            })
                        )
                    );
                }
            })
            .catch((error) => {
                dispatch(alerts.actions.close());
                dispatch(
                    projectConsumptionModalActions[UPLOAD_FILE_FAILURE](
                        error?.response?.data?.errors
                    )
                );

                dispatch(uploadDocumentsAI(file));
            });
    };
