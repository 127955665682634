import i18next from 'i18next';

import { updateReportEnded } from 'common/api/v1/aftersalesPrinted';
import { showReponseErrorsAsAlert } from 'common/utils/helpers';
import showToast from 'common/utils/showToast';

import {
    UPDATE_TEMPLATE,
    UPDATE_TEMPLATE_SUCCESS,
    UPDATE_TEMPLATE_FAILURE,
} from '../actionTypes';
import { reportTemplateActions } from '../reducer';

export default ({ getTemplateUpdatedData, id, setTemplateBackup }) =>
    (dispatch) => {
        dispatch(reportTemplateActions[UPDATE_TEMPLATE]());
        const updatedData = getTemplateUpdatedData();

        updateReportEnded(id, { content: JSON.stringify(updatedData) })
            .then(() => {
                dispatch(reportTemplateActions[UPDATE_TEMPLATE_SUCCESS]());
                setTemplateBackup(true);

                showToast({
                    body: i18next.t('Changes were saved successfully'),
                });
            })
            .catch((error) => {
                dispatch(
                    reportTemplateActions[UPDATE_TEMPLATE_FAILURE](
                        error?.response?.data?.errors
                    )
                );
                showReponseErrorsAsAlert(dispatch, error?.response);
            });
    };
