import i18next from 'i18next';
import isArray from 'lodash/isArray';

import { deleteDatasheet } from 'common/api/v1/datasheets';
import showToast from 'common/utils/showToast';

import {
    DELETE_DATASHEET,
    DELETE_DATASHEET_FAILURE,
    DELETE_DATASHEET_SUCCESS,
} from '../actionTypes';
import { productDatasheetsActions } from '../reducer';
import * as selectors from '../selectors';

import fetchProductDatasheets from './fetchProductDatasheets';
import openConfirmModal from './openConfirmModal';

export default () => (dispatch, getState) => {
    const state = getState();
    const datasheet = selectors.getElementToDelete(state);
    const initialValues = selectors.getInitialValues(state);

    dispatch(productDatasheetsActions[DELETE_DATASHEET]());

    deleteDatasheet(datasheet.id)
        .then(() => {
            dispatch(openConfirmModal(false));
            dispatch(productDatasheetsActions[DELETE_DATASHEET_SUCCESS]({}));
            dispatch(
                fetchProductDatasheets(
                    initialValues.productType,
                    initialValues.productId
                )
            );
            showToast({ body: i18next.t('It was successfully removed') });
        })
        .catch((error) => {
            const errors = error?.response?.data?.errors;
            const message = `${i18next.t(
                'An error occurred while deleting'
            )}. ${isArray(errors) ? errors[0] : ''}`;
            dispatch(openConfirmModal(false));
            dispatch(
                productDatasheetsActions[DELETE_DATASHEET_FAILURE](errors)
            );
            showToast({ body: message, type: 'danger' });
        });
};
