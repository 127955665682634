import i18next from 'i18next';
import get from 'lodash/get';

export const getOptionsToSelect = ({
    data,
    isNewRecord,
    label,
    planData,
    prefix,
}) => {
    let items = data.map((item) => ({
        label: item.name,
        value: item.id,
    }));
    const item = get(planData, prefix, {});
    if (!isNewRecord && ['archived', 'deleted'].includes(item?.status_flag)) {
        items = [
            ...items,
            {
                disabled: true,
                label: item.name,
                value: item.id,
            },
        ];
    }
    return [{ label, value: '', disabled: true }, ...items];
};

export const getOptionsToSelectForRHFArray = ({
    data,
    isNewRecord,
    label,
    planData,
    prefix,
}) => {
    let items = data.map((item) => ({
        label: item.name,
        value: item.id,
    }));
    const array = get(planData, prefix, []);
    array.forEach((item) => {
        if (
            !isNewRecord &&
            ['archived', 'deleted'].includes(item?.status_flag)
        ) {
            items = [
                ...items,
                {
                    disabled: true,
                    label: item.name,
                    value: item.id,
                },
            ];
        }
    });
    return [{ label, value: '', disabled: true }, ...items];
};

export const getTemplatesToSelect = (templates, isNewRecord, planData) => {
    let items = templates.map((item) => ({
        label: item.title,
        value: item.id,
    }));
    const template = get(planData, 'custom_offer_template', {});
    if (
        !isNewRecord &&
        ['archived', 'deleted'].includes(template?.status_flag)
    ) {
        items = [
            ...items,
            {
                disabled: true,
                label: template.title,
                value: template.id,
            },
        ];
    }
    return [
        { label: i18next.t('Select a template'), value: '', disabled: true },
        ...items,
    ];
};
