import { deleteItem } from 'common/api/v2/offerAdditional';
import { showReponseErrorsAsAlert } from 'common/utils/helpers';

import eventsAfterUpdate from '../../proposalGeneratorOneColumn/actions/eventsAfterUpdate';
import { DELETE, DELETE_SUCCESS, DELETE_FAILURE } from '../actionTypes';
import { proposalGeneratorAdditionalTableActions } from '../reducer';

export default (id, proposalId, successCallback) => (dispatch) => {
    dispatch(proposalGeneratorAdditionalTableActions[DELETE]());

    deleteItem(id, proposalId)
        .then((response) => {
            dispatch(
                proposalGeneratorAdditionalTableActions[DELETE_SUCCESS](
                    response.data.data
                )
            );
            if (successCallback) successCallback();
            dispatch(eventsAfterUpdate(response.data.data, proposalId));
        })
        .catch((error) => {
            dispatch(
                proposalGeneratorAdditionalTableActions[DELETE_FAILURE](
                    error?.response?.data?.errors
                )
            );
            showReponseErrorsAsAlert(dispatch, error?.response);
        });
};
