import { push } from 'connected-react-router';
import i18next from 'i18next';

import { saveOfferCustomTemplate } from 'common/api/v1/newOfferCustomTemplate';
import { showReponseErrorsAsAlert } from 'common/utils/helpers';
import showToast from 'common/utils/showToast';

import { CREATE, CREATE_SUCCESS, CREATE_FAILURE } from '../actionTypes';
import { BLANK_TEMPLATE } from '../constants';
import { templateLibraryActions } from '../reducer';

import filterTemplates from './filterTemplates';
import toggleModalTemplate from './toggleModalTemplate';

export default (values, filterData) => (dispatch) => {
    dispatch(templateLibraryActions[CREATE]());
    const {
        branch_offices,
        custom_offer,
        language,
        locked,
        proposals_number,
        title,
        type,
        version,
    } = values;
    const newValues = {
        branch_offices,
        custom_offer: custom_offer === BLANK_TEMPLATE ? null : custom_offer,
        language,
        locked,
        proposals_number,
        title,
        type,
        version,
    };
    saveOfferCustomTemplate(newValues)
        .then((response) => {
            dispatch(templateLibraryActions[CREATE_SUCCESS]());
            dispatch(toggleModalTemplate(false));
            if (
                values.branch_offices &&
                values.branch_offices.split(',').length > 1 &&
                values.branch_offices.includes('company')
            ) {
                dispatch(filterTemplates(filterData));
                showToast({ body: i18next.t('It was created successfully') });
            } else {
                dispatch(push(`/edit-template-pro/${response.data.id}`));
            }
        })
        .catch((error) => {
            dispatch(
                templateLibraryActions[CREATE_FAILURE](
                    error?.response?.data?.errors
                )
            );
            showReponseErrorsAsAlert(dispatch, error?.response);
        });
};
