import React, {
    forwardRef,
    useImperativeHandle,
    useEffect,
    useState,
} from 'react';

import { styled, useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box, Button, SwipeableDrawer } from 'sunwise-ui';

import { scroll } from 'common/utils/mixins';

const Puller = styled(Box)`
    background-color: #d4d4d4;
    border-radius: 3px;
    height: 6px;
    width: 30px;
`;

const Wrapper = styled(Box)(
    ({ theme }) => `
    align-items: center;
    background-color: ${theme.palette.mode === 'dark' ? '#1B1C20' : '#ffffff'};
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    bottom: 0;
    box-shadow: 0 -5px 8px rgba(0, 0, 0, 0.08);
    display: flex;
    flex-direction: column;
    gap: 8px;
    justify-content: center;
    left: 0;
    position: fixed;
    width: 100%;
    z-index: 1000;
`
);

const StyledBox = styled(Box)`
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 8px;
`;

const WrapperContent = styled(Box)`
    padding: 0 10px;
    overflow-y: scroll;

    ${scroll.custom()}
`;

const SwipeableDrawer2 = ({ children, myForwardedRef, onCloseEnded }) => {
    const { t } = useTranslation();
    const [open, setOpen] = useState(false);
    const theme = useTheme();

    const defaultAnchor = 'bottom';

    useEffect(() => {
        document.body.classList.remove('overflow-hidden');
        handleHideIntercomLauncher(false);
        if (open) {
            document.body.classList.add('overflow-hidden');
            handleHideIntercomLauncher(true);
        }
    }, [open]);

    const handleHideIntercomLauncher = (value) => {
        const intercom = window.Intercom;
        if (typeof intercom === 'function') {
            intercom('update', {
                hide_default_launcher: value,
            });
        }
    };

    useImperativeHandle(myForwardedRef, () => ({
        handleOpen(value) {
            setOpen(value);
        },
        getIsOpen() {
            return open;
        },
    }));

    const toggleDrawer = (value) => (event) => {
        if (
            event &&
            event.type === 'keydown' &&
            (event.key === 'Tab' || event.key === 'Shift')
        ) {
            return;
        }

        if (value) {
            document.body.classList.add('overflow-hidden');
            handleHideIntercomLauncher(true);
        } else {
            document.body.classList.remove('overflow-hidden');
            handleHideIntercomLauncher(false);
        }

        setOpen(value);

        if (value === false && onCloseEnded) {
            setTimeout(
                () => onCloseEnded(),
                theme.transitions.duration.leavingScreen + 50
            );
        }
    };

    return (
        <div>
            <React.Fragment key={defaultAnchor}>
                <Wrapper sx={{ display: { xs: 'block', md: 'none' } }}>
                    <StyledBox>
                        <Button onClick={toggleDrawer(true)}>
                            {t('View menu')}
                        </Button>
                    </StyledBox>
                </Wrapper>
                <SwipeableDrawer
                    anchor={defaultAnchor}
                    open={open}
                    onClose={toggleDrawer(false)}
                    onOpen={toggleDrawer(true)}
                >
                    <Box display="flex" flexDirection="column" p={1}>
                        <StyledBox>
                            <Puller />
                        </StyledBox>
                        <WrapperContent>{children}</WrapperContent>
                    </Box>
                </SwipeableDrawer>
            </React.Fragment>
        </div>
    );
};

SwipeableDrawer2.propTypes = {
    children: PropTypes.object,
    myForwardedRef: PropTypes.object,
    onCloseEnded: PropTypes.func,
};

const SwipeableDrawerForwared = forwardRef((props, ref) => (
    <SwipeableDrawer2 {...props} myForwardedRef={ref} />
));

SwipeableDrawerForwared.displayName = 'SwipeableDrawerForwared';

export default SwipeableDrawerForwared;
