import { getData } from 'common/api/v1/projectConfiguration';

import {
    FETCH_PROJECT_CONFIGURATION,
    FETCH_PROJECT_CONFIGURATION_FAILURE,
    FETCH_PROJECT_CONFIGURATION_SUCCESS,
} from '../actionTypes';
import { actions } from '../reducer';

export default (reportId, projectId) => (dispatch) =>
    new Promise((resolve) => {
        if (!projectId) {
            resolve();
            return;
        }
        dispatch(actions[FETCH_PROJECT_CONFIGURATION]());

        getData(projectId, reportId)
            .then((response) =>
                dispatch(
                    actions[FETCH_PROJECT_CONFIGURATION_SUCCESS](
                        response?.data?.data
                    )
                )
            )
            .catch((error) =>
                dispatch(actions[FETCH_PROJECT_CONFIGURATION_FAILURE](error))
            )
            .finally(resolve);
    });
