import React, { useEffect, useState } from 'react';

import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DownloadIcon from '@mui/icons-material/Download';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import SyncIcon from '@mui/icons-material/Sync';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import {
    Box,
    Button,
    Card,
    Chip,
    Grid,
    Menu,
    MenuItem,
    Typography,
} from 'sunwise-ui';

import { CONSUMPTION_CSV_TYPES } from 'common/constants';
import { URL_TYPES } from 'common/constants';
import {
    handleDownloadSunwiseFile,
    handleFileURL,
    getFileExtensionByPath,
} from 'common/utils/helpers';
import { getIsMexicanAccount } from 'common/utils/helpers/session';
import parseQueryString from 'common/utils/parseQueryString';

import { getShowUpdateButton } from '../helpers';

import ChartComponent from './ChartComponent';
import DropdownActions from './DropdownActions';

const Wrapper = styled(Grid)(
    ({ theme }) => `
    background: ${
        theme.palette.mode === 'dark'
            ? 'rgb(40, 43, 48)'
            : 'rgba(238, 244, 250, 0.35)'
    };
    border-bottom: 1px solid;
    border-radius: 8px 8px 0px 0px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 5px;
    height: 100%;
    margin-bottom: 8px;
    padding: 8px 16px;
`
);

const TextContent = styled(Typography)`
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
`;

const CardConsumption = ({
    canModify,
    consumptionsCsv,
    countryCurrencyIso,
    countryCurrencyLocale,
    countryCurrencySymbol,
    errorsUpdatingConsumptionEnergy,
    fetchStatusProjects,
    finalDate,
    handleClickUpdate,
    handleDownloadCSV,
    handleDownloadLisaFile,
    handleExportCSV,
    handleOnClickUpdateConsumption,
    handleOpenImportConsumptionsModal,
    holder,
    infoPriceConsumtion,
    isDownloadingHistory,
    isUpdatingConsumptionEnergy,
    location,
    monthskw,
    monthskwh,
    periodicityType,
    prepareDowloadHistory,
    rate,
    receiptOrigin,
    region,
    serviceNumber,
}) => {
    const { t } = useTranslation();

    const { file: fileCsv, type: typeCsv } = consumptionsCsv || {};
    const monthsWithPdf = monthskwh.filter((month) => month.url).reverse();
    const showUpdateButton = getShowUpdateButton(finalDate, periodicityType);
    const showUpdateHistoryButton = monthsWithPdf.length === monthskwh.length;

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => setAnchorEl(event.currentTarget);
    const handleClose = () => setAnchorEl(null);

    useEffect(() => {
        fetchStatusProjects();
    }, []);

    const { search: locationSearch = '' } = location;
    const { open_consumption_modal: openConsumptionModal } =
        parseQueryString(locationSearch);

    const showRegion = getIsMexicanAccount() && region;

    useEffect(() => {
        if (
            openConsumptionModal &&
            openConsumptionModal === 'true' &&
            canModify
        )
            handleClickUpdate();
    }, [openConsumptionModal]);

    const getFileLabel = (month = {}) =>
        `${getFileExtensionByPath(month.url)?.toUpperCase()} - ${month.label}`;

    return (
        <Card>
            <Card.Header
                sx={{
                    alignItems: 'center',
                    display: 'flex',
                    justifyContent: 'space-between',
                }}
            >
                <Typography variant="body1" fontWeight="bold">
                    {t('Service data')}
                </Typography>

                <DropdownActions
                    canModify={canModify}
                    handleClickExport={handleExportCSV}
                    handleClickImport={handleOpenImportConsumptionsModal}
                    handleClickUpdate={handleClickUpdate}
                />
            </Card.Header>
            <Card.Body>
                <Box hidden>
                    <Grid container>
                        <Grid item sx={{ display: 'flex', gap: '16px' }}>
                            {receiptOrigin !== 'manual' &&
                                receiptOrigin !== 'automatic_pdf' &&
                                receiptOrigin !== 'semiautomatic_pdf' && (
                                    <>
                                        {showUpdateButton && canModify ? (
                                            <Button
                                                color="secondary"
                                                disabled={
                                                    isUpdatingConsumptionEnergy
                                                }
                                                startIcon={<SyncIcon />}
                                                onClick={
                                                    handleOnClickUpdateConsumption
                                                }
                                                variant="outlined"
                                            >
                                                {errorsUpdatingConsumptionEnergy
                                                    ? t('Try again')
                                                    : !isUpdatingConsumptionEnergy
                                                    ? t('Update consumption')
                                                    : t('Updating consumption')}
                                            </Button>
                                        ) : (
                                            <Chip
                                                icon={<CheckCircleIcon />}
                                                label={t('Current consumption')}
                                            />
                                        )}
                                    </>
                                )}
                        </Grid>
                    </Grid>
                </Box>

                <Grid container>
                    <Grid item xs={18} md={9}>
                        <Wrapper>
                            <Typography variant="body2">{t('Name')}</Typography>
                            <TextContent variant="body2" fontWeight="bold">
                                {holder}
                            </TextContent>
                        </Wrapper>
                    </Grid>

                    <Grid item xs={18} md={5}>
                        <Wrapper>
                            <Typography variant="body2">
                                {t('Service number')}
                            </Typography>
                            <TextContent variant="body2" fontWeight="bold">
                                {serviceNumber}
                            </TextContent>
                        </Wrapper>
                    </Grid>

                    <Grid item xs={18} md={4}>
                        <Wrapper>
                            <Typography variant="body2">{t('Rate')}</Typography>
                            <TextContent variant="body2" fontWeight="bold">
                                {rate}
                            </TextContent>
                        </Wrapper>
                    </Grid>

                    {showRegion && (
                        <Grid item xs={18}>
                            <Wrapper>
                                <Typography variant="body2">
                                    {t('Region')}
                                </Typography>
                                <TextContent variant="body2" fontWeight="bold">
                                    {region}
                                </TextContent>
                            </Wrapper>
                        </Grid>
                    )}
                </Grid>

                <Grid container sx={{ alignItems: 'center', py: 2 }}>
                    <Grid item md>
                        <Typography fontWeight="bold" variant="body1">
                            {t('Consumption history')}
                        </Typography>
                    </Grid>

                    {rate === 'GDMTH' &&
                        (receiptOrigin === 'descarga' ||
                            receiptOrigin === 'semiautomatic') &&
                        canModify && (
                            <Grid item sx={{ textAlign: 'right' }}>
                                <Button
                                    color="secondary"
                                    disabled={
                                        isDownloadingHistory ||
                                        (receiptOrigin === 'descarga' &&
                                            monthsWithPdf.length === 12)
                                    }
                                    onClick={prepareDowloadHistory}
                                    startIcon={<ArrowDownwardIcon />}
                                    sx={{
                                        width: { md: 'auto', xs: '100%' },
                                    }}
                                    variant="outlined"
                                >
                                    {showUpdateHistoryButton
                                        ? t('Update history')
                                        : t('Download history')}
                                </Button>
                            </Grid>
                        )}

                    <Grid item md sx={{ textAlign: 'right' }}>
                        <Button
                            color="secondary"
                            endIcon={<KeyboardArrowDownIcon />}
                            onClick={handleClick}
                            startIcon={<DownloadIcon />}
                            sx={{ width: { xs: '100%', md: 'auto' } }}
                            variant="outlined"
                        >
                            {t('Download', { count: 2 })}
                        </Button>

                        <Menu
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                        >
                            <MenuItem
                                onClick={() => {
                                    handleDownloadCSV();
                                    handleClose();
                                }}
                            >
                                {`CSV - ${t('Consumption')}`}
                            </MenuItem>

                            {monthsWithPdf.map((month, index) => {
                                const label = getFileLabel(month);

                                return month.url_type === URL_TYPES.LISA ? (
                                    <MenuItem
                                        key={`monthsWithPdf-${index}`}
                                        onClick={() => {
                                            handleDownloadLisaFile(month.url);
                                            handleClose();
                                        }}
                                    >
                                        {label}
                                    </MenuItem>
                                ) : (
                                    <MenuItem
                                        key={`monthsWithPdf-${index}`}
                                        onClick={() => {
                                            const url = handleFileURL(
                                                month.url,
                                                process.env.REACT_APP_API_URL
                                            );
                                            handleDownloadSunwiseFile(
                                                url,
                                                label
                                            );
                                            handleClose();
                                        }}
                                    >
                                        {label}
                                    </MenuItem>
                                );
                            })}

                            {fileCsv && (
                                <MenuItem
                                    component="a"
                                    href={handleFileURL(
                                        fileCsv,
                                        process.env.REACT_APP_API_URL
                                    )}
                                    onClick={handleClose}
                                    rel="noopener noreferrer"
                                    target="_blank"
                                >
                                    {`CSV - ${t('Consumption')} (${t(
                                        CONSUMPTION_CSV_TYPES[typeCsv]
                                    )})`}
                                </MenuItem>
                            )}
                        </Menu>
                    </Grid>
                </Grid>

                <ChartComponent
                    countryCurrencyIso={countryCurrencyIso}
                    countryCurrencyLocale={countryCurrencyLocale}
                    countryCurrencySymbol={countryCurrencySymbol}
                    infoPriceConsumtion={infoPriceConsumtion}
                    monthskw={monthskw}
                    monthskwh={monthskwh}
                />
            </Card.Body>
        </Card>
    );
};

CardConsumption.propTypes = {
    canModify: PropTypes.bool,
    consumptionsCsv: PropTypes.object,
    countryCurrencyIso: PropTypes.string,
    countryCurrencyLocale: PropTypes.string,
    countryCurrencySymbol: PropTypes.string,
    errorsUpdatingConsumptionEnergy: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.array,
    ]),
    fetchStatusProjects: PropTypes.func,
    finalDate: PropTypes.string,
    handleClickUpdate: PropTypes.func,
    handleDownloadCSV: PropTypes.func,
    handleDownloadLisaFile: PropTypes.func,
    handleExportCSV: PropTypes.func,
    handleOnClickUpdateConsumption: PropTypes.func,
    handleOpenImportConsumptionsModal: PropTypes.func,
    holder: PropTypes.string,
    infoPriceConsumtion: PropTypes.array,
    isDownloadingHistory: PropTypes.bool,
    isUpdatingConsumptionEnergy: PropTypes.bool,
    location: PropTypes.object,
    monthskw: PropTypes.array,
    monthskwh: PropTypes.array,
    periodicityType: PropTypes.number,
    prepareDowloadHistory: PropTypes.func,
    rate: PropTypes.string,
    receiptOrigin: PropTypes.string,
    region: PropTypes.string,
    serviceNumber: PropTypes.string,
};

export default withRouter(CardConsumption);
