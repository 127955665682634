import API from '../..';
const ENTITY = 'api/v3/funnel-projects';

export const getFunnelProjects = ({ filterQuery, per_page = 7 } = {}) =>
    API.get(`/${ENTITY}/?${filterQuery ? filterQuery : ''}`, {
        params: { per_page },
    });

export const getNewPageProjects = ({
    status,
    page,
    per_page = 7,
    filterQuery,
} = {}) =>
    API.get(`/${ENTITY}/pagination/?${filterQuery ? filterQuery : ''}`, {
        params: { page, per_page, status },
    });

export const updateStatusOrder = (projectUUID, data) =>
    API.put(
        `/${ENTITY}/${projectUUID}/`,
        { ...data },
        { headers: { 'Content-Type': 'application/json' } }
    );

export const getListProjects = ({ filterQuery, perPage = 7 }) =>
    API.get(
        `api/v3/funnel/listview/?per_page=${perPage}${
            filterQuery ? '&' + filterQuery : ''
        }`
    );

export const getNextPageListProjects = ({ page, perPage = 7, filterQuery }) =>
    API.get(
        `/api/v3/funnel/listview/?page=${page}&per_page=${perPage}${
            filterQuery ? '&' + filterQuery : ''
        }`
    );
