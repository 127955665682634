import i18next from 'i18next';

import { exportFile } from 'common/api/v1/panelsCompany';
import { showReponseErrorsAsAlert } from 'common/utils/helpers';

import { SET_IS_DOWNLOADING } from '../actionTypes';
import { panelConfigurationActions } from '../reducer';

export default (
        { orderBy, page = 0, pageSize, searchText, sortBy, status } = {},
        downloadType = null
    ) =>
    (dispatch) => {
        dispatch(panelConfigurationActions[SET_IS_DOWNLOADING](true));
        exportFile({
            key: 'panels',
            orderBy,
            page: page + 1,
            perPage: pageSize,
            personalized: downloadType === 'custom',
            searchText,
            sortBy,
            status,
        })
            .then((response) => {
                const url = window.URL.createObjectURL(
                    new Blob([response.data])
                );
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute(
                    'download',
                    `${i18next.t('Panel', { count: 2 })}.csv`
                );
                document.body.appendChild(link);
                link.click();
                dispatch(panelConfigurationActions[SET_IS_DOWNLOADING](false));
            })
            .catch((error) => {
                dispatch(panelConfigurationActions[SET_IS_DOWNLOADING](false));
                showReponseErrorsAsAlert(dispatch, error?.response);
            });
    };
