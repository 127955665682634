import React from 'react';

import PropTypes from 'prop-types';

const IconSaveMoney = ({
    color = '#ff9a00',
    height = '24px',
    width = '24px',
}) => (
    <svg
        width={width}
        height={height}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M17.56 5.23015L19.83 7.50015H21C21.55 7.50015 22 7.95015 22 8.50015V13.7401C22 14.1701 21.73 14.5501 21.32 14.6901L19.18 15.4101L17.93 19.5701C17.67 20.4201 16.89 21.0001 16.01 21.0001H14C12.9 21.0001 12 20.1001 12 19.0001H10C10 20.1001 9.1 21.0001 8 21.0001H6.01C5.11 21.0001 4.32 20.4001 4.08 19.5401C3.37 16.9801 2 11.7501 2 9.50015C2 6.46015 4.46 4.00015 7.5 4.00015H12.5C13.4 2.81015 14.81 2.03015 16.4 2.00015C17.14 1.99015 17.84 2.49015 17.97 3.21015C18.03 3.52015 17.99 3.82015 17.88 4.08015C17.74 4.42015 17.63 4.81015 17.56 5.23015ZM15 10.0001C15 10.5501 15.45 11.0001 16 11.0001C16.55 11.0001 17 10.5501 17 10.0001C17 9.45015 16.55 9.00015 16 9.00015C15.45 9.00015 15 9.45015 15 10.0001ZM7.5 9.46875C7.5 10.0625 7.95625 10.4438 9.375 10.8125C10.7938 11.1812 12.3125 11.7875 12.3125 13.5625C12.3125 14.8438 11.3438 15.55 10.125 15.7812V16.1875C10.125 16.7063 9.70625 17.125 9.1875 17.125C8.66875 17.125 8.25 16.7063 8.25 16.1875V15.7688C7.3125 15.5688 6.48125 15.0562 6.125 14.1875C5.96875 13.8 6.2625 13.375 6.68125 13.375H6.85625C7.1125 13.375 7.325 13.55 7.41875 13.7937C7.625 14.3188 8.15625 14.6875 9.1875 14.6875C10.5688 14.6875 10.875 14 10.875 13.5688C10.875 12.9875 10.5625 12.4375 9 12.0625C7.25625 11.6437 6.0625 10.925 6.0625 9.48125C6.0625 8.275 7.0375 7.4875 8.25 7.225V6.8125C8.25 6.29375 8.66875 5.875 9.1875 5.875C9.70625 5.875 10.125 6.29375 10.125 6.8125V7.24375C11.1 7.48125 11.7125 8.09375 11.975 8.83125C12.1187 9.21875 11.825 9.625 11.4125 9.625H11.225C10.9625 9.625 10.7563 9.4375 10.6812 9.1875C10.5188 8.65 10.075 8.3125 9.1875 8.3125C8.13125 8.3125 7.5 8.7875 7.5 9.46875Z"
            fill={color}
        />
    </svg>
);

IconSaveMoney.propTypes = {
    color: PropTypes.string,
    height: PropTypes.string,
    width: PropTypes.string,
};

export default IconSaveMoney;
