import * as chatAllianceApi from 'common/api/v1/chatAlliance';
import * as chatCreditApi from 'common/api/v1/chatCredit';

import {
    INIT_CHAT,
    INIT_CHAT_FAILURE,
    INIT_CHAT_SUCCESS,
} from '../actionTypes';
import { CREDIT_CHAT_TYPE } from '../constants';
import { chatViewActions } from '../reducer';

import fetchMessages from './fetchMessages';

const selectInitChat = (entityId, type) => {
    if (type === CREDIT_CHAT_TYPE) return chatCreditApi.initChat(entityId);
    return chatAllianceApi.initChat(entityId);
};

export default (entityId, user, type) => (dispatch) => {
    dispatch(chatViewActions[INIT_CHAT]());

    selectInitChat(entityId, type)
        .then((response) =>
            dispatch(chatViewActions[INIT_CHAT_SUCCESS](response.data))
        )
        .catch((error) => dispatch(chatViewActions[INIT_CHAT_FAILURE](error)))
        .finally(() => dispatch(fetchMessages(entityId, user, type)));
};
