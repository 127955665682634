import { getItems } from 'common/api/v2/offerAccessory';

import updateProductsData from '../../proposalGeneratorQuote/actions/updateProductsData';
import {
    FETCH_OFFER_ACCESSORIES,
    FETCH_OFFER_ACCESSORIES_FAILURE,
    FETCH_OFFER_ACCESSORIES_SUCCESS,
} from '../actionTypes';
import { proposalGeneratorAccessoriesTableActions } from '../reducer';

import initialValues from './initialValues';
import resetForm from './resetForm';

export default (id, name) => (dispatch) =>
    new Promise((resolve) => {
        dispatch(
            proposalGeneratorAccessoriesTableActions[FETCH_OFFER_ACCESSORIES]()
        );
        getItems(id)
            .then((response) => {
                dispatch(resetForm());

                const values = response.data.data;

                dispatch(
                    proposalGeneratorAccessoriesTableActions[
                        FETCH_OFFER_ACCESSORIES_SUCCESS
                    ](response.data.data)
                );

                dispatch(initialValues(values));
                dispatch(updateProductsData(name, values));
            })
            .catch((error) =>
                dispatch(
                    proposalGeneratorAccessoriesTableActions[
                        FETCH_OFFER_ACCESSORIES_FAILURE
                    ](error?.response?.data?.errors)
                )
            )
            .finally(resolve);
    });
