import { getNavbarBranchOffices } from 'common/api/v1/branchOffices';

import {
    FETCH_BRANCHES,
    FETCH_BRANCHES_FAILURE,
    FETCH_BRANCHES_SUCCESS,
    SELECT_BRANCH,
} from '../actionTypes';
import { multiBranchesActions } from '../reducer';

import selectBranch from './selectBranch';

export default () => (dispatch) => {
    dispatch(multiBranchesActions[FETCH_BRANCHES]());

    getNavbarBranchOffices()
        .then((response) => {
            const branches = response?.data?.data;
            dispatch(multiBranchesActions[FETCH_BRANCHES_SUCCESS](branches));
            if (branches.length === 0) {
                dispatch(selectBranch('all'));
            } else {
                const branchSelected = localStorage.getItem('branch-selected');
                if (!branchSelected) {
                    dispatch(selectBranch(branches[0]?.id));
                } else {
                    dispatch(
                        multiBranchesActions[SELECT_BRANCH](branchSelected)
                    );
                }
            }
        })
        .catch((error) => {
            dispatch(
                multiBranchesActions[FETCH_BRANCHES_FAILURE](
                    error?.response?.data?.errors
                )
            );
        });
};
