import React, { useEffect, useState } from 'react';

import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Box, Tab, TabPanel, Tabs } from 'sunwise-ui';

import { scroll } from 'common/utils/mixins';

import * as credentialIntegrationsActions from '../../credentialIntegrations/actions';
import * as credentialIntegrationsSelectors from '../../credentialIntegrations/selectors';
import * as actions from '../actions';
import { getProjectsWithoutInfo } from '../helpers';
import * as selectors from '../selectors';

import Historical from './Historical';
import ProjectItem from './ProjectItem';

const TabPanelWrapper = styled(Box)`
    height: 100%;
    height: 100%;
    overflow-y: scroll;
    padding: 0 16px;

    ${scroll.custom()};
`;

const DrawerContent = ({
    fetchProjectsInfo,
    fetchProviders,
    isLoadingProjectsInfo,
    logsData,
    projectsInfo,
    projectsSyncArray,
    providersDictionary,
}) => {
    const [selectedTab, setSelectedTab] = useState('historical');
    const wrapperRef = React.useRef(null);
    const { t } = useTranslation();

    useEffect(() => {
        fetchProviders();
    }, []);

    useEffect(() => {
        if (isLoadingProjectsInfo) return;

        const projectsWithoutInfo = getProjectsWithoutInfo({
            logsData,
            projectsInfo,
            projectsSyncArray,
        });

        if (projectsWithoutInfo?.length > 0)
            fetchProjectsInfo(projectsWithoutInfo);
    }, [logsData, projectsSyncArray, isLoadingProjectsInfo]);

    const handleChangeTab = (_, newValue) => setSelectedTab(newValue);

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
                overflowY: 'auto',
                width: { xs: '100vw', md: 480 },
            }}
            role="presentation"
        >
            <Tabs
                value={selectedTab}
                onChange={handleChangeTab}
                variant="fullWidth"
                sx={{ borderBottom: 1, borderColor: 'divider', mx: 2 }}
            >
                <Tab label={t('Historical')} value="historical" />
                <Tab label={t('Syncing')} value="syncing" />
            </Tabs>

            <TabPanelWrapper ref={wrapperRef}>
                <TabPanel selectedTab={selectedTab} value="syncing">
                    {projectsSyncArray.map((project) => (
                        <ProjectItem
                            key={project.sunwise_project}
                            project={project}
                            projectInfo={projectsInfo[project.sunwise_project]}
                            providersDictionary={providersDictionary}
                        />
                    ))}
                </TabPanel>
                <TabPanel selectedTab={selectedTab} value="historical">
                    <Historical
                        logsData={logsData}
                        projectsInfo={projectsInfo}
                        providersDictionary={providersDictionary}
                        wrapperRef={wrapperRef}
                    />
                </TabPanel>
            </TabPanelWrapper>
        </Box>
    );
};

const mapStateToProps = createStructuredSelector({
    isLoadingProjectsInfo: selectors.getProjectsInfoLoading,
    logsData: selectors.getLogsData,
    projectsInfo: selectors.getProjectsInfoData,
    projectsSyncArray: selectors.getProjectsSyncAsArray,
    providersDictionary: credentialIntegrationsSelectors.getProvidersDictionary,
});

const mapDispatchToProps = (dispatch) => ({
    fetchProjectsInfo: (projects) =>
        dispatch(actions.fetchProjectsInfo(projects)),
    fetchProviders: () =>
        dispatch(credentialIntegrationsActions.fetchProviders()),
});

DrawerContent.propTypes = {
    fetchProjectsInfo: PropTypes.func,
    fetchProviders: PropTypes.func,
    isLoadingProjectsInfo: PropTypes.bool,
    logsData: PropTypes.array,
    projectsInfo: PropTypes.object,
    projectsSyncArray: PropTypes.array,
    providersDictionary: PropTypes.object,
};

export default connect(mapStateToProps, mapDispatchToProps)(DrawerContent);
