const splitOnFirst = (string, separator) => {
    if (!(typeof string === 'string' && typeof separator === 'string')) {
        throw new TypeError('Expected the arguments to be of type `string`');
    }

    if (string === '' || separator === '') {
        return [];
    }

    const separatorIndex = string.indexOf(separator);

    if (separatorIndex === -1) {
        return [];
    }

    return [
        string.slice(0, separatorIndex),
        string.slice(separatorIndex + separator.length),
    ];
};

const customDecodeURIComponent = (input) => {
    let replaceMap = {
        '%FE%FF': '\uFFFD\uFFFD',
        '%FF%FE': '\uFFFD\uFFFD',
    };

    const multiMatcher = new RegExp('(%[a-f0-9]{2})+', 'gi');

    let match = multiMatcher.exec(input);
    while (match) {
        try {
            replaceMap[match[0]] = decodeURIComponent(match[0]);
        } catch (err) {
            const result = decode(match[0]);

            if (result !== match[0]) {
                replaceMap[match[0]] = result;
            }
        }

        match = multiMatcher.exec(input);
    }

    replaceMap['%C2'] = '\uFFFD';

    const entries = Object.keys(replaceMap);

    for (let i = 0; i < entries.length; i++) {
        const key = entries[i];
        input = input.replace(new RegExp(key, 'g'), replaceMap[key]);
    }

    return input;
};

const decode = (encodedURI) => {
    if (typeof encodedURI !== 'string') {
        throw new TypeError(
            'Expected `encodedURI` to be of type `string`, got `' +
                typeof encodedURI +
                '`'
        );
    }
    try {
        encodedURI = encodedURI.replace(/\+/g, ' ');
        return decodeURIComponent(encodedURI);
    } catch (err) {
        return customDecodeURIComponent(encodedURI);
    }
};

const formatter = (key, value, accumulator) => {
    if (accumulator[key] === undefined) {
        accumulator[key] = value;
        return;
    }
    accumulator[key] = [].concat(accumulator[key], value);
};

const keysSorter = (input) => {
    if (Array.isArray(input)) {
        return input.sort();
    }

    if (typeof input === 'object') {
        return keysSorter(Object.keys(input))
            .sort((a, b) => Number(a) - Number(b))
            .map((key) => input[key]);
    }

    return input;
};

export const parseQueryString = (queryString) => {
    let query = queryString;
    const ret = Object.create(null);

    if (typeof query !== 'string') {
        return ret;
    }
    query = query.trim().replace(/^[?#&]/, '');

    if (!query) {
        return ret;
    }
    for (const param of query.split('&')) {
        if (param === '') {
            continue;
        }

        let [key, value] = splitOnFirst(param.replace(/\+/g, ' '), '=');

        value = value === undefined ? null : decode(value);
        formatter(decode(key), value, ret);
    }

    return Object.keys(ret)
        .sort()
        .reduce((result, key) => {
            const value = ret[key];
            if (
                Boolean(value) &&
                typeof value === 'object' &&
                !Array.isArray(value)
            ) {
                result[key] = keysSorter(value);
            } else {
                result[key] = value;
            }

            return result;
        }, Object.create(null));
};

export default parseQueryString;
