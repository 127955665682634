import { getCSV } from 'common/api/v2/sunwiseCSV';

import {
    DOWNLOAD_CSV,
    DOWNLOAD_CSV_FAILURE,
    DOWNLOAD_CSV_SUCCESS,
} from '../actionTypes';
import { projectConsumptionCardActions } from '../reducer';

export default (projectId) => (dispatch) => {
    dispatch(projectConsumptionCardActions[DOWNLOAD_CSV]());

    getCSV(projectId)
        .then((response) => {
            dispatch(
                projectConsumptionCardActions[DOWNLOAD_CSV_SUCCESS](
                    response?.data
                )
            );

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${projectId}.csv`);
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
        })
        .catch((error) => {
            dispatch(
                projectConsumptionCardActions[DOWNLOAD_CSV_FAILURE](
                    error?.response?.data?.errors
                )
            );
        });
};
