import React, { useEffect } from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { UiDialog as Dialog } from 'sunwise-ui';

import * as actions from './actions';
import TrackingForm from './components/TrackingForm';
import * as selectors from './selectors';

const Container = ({
    closeModal,
    contacts,
    fetchContacts,
    fetchTrackingsPurposes,
    fetchUsers,
    isOpenModal,
    trackingsPurposes,
    trackingsPurposesToSelect,
    users,
}) => {
    const { t } = useTranslation();

    useEffect(() => {
        fetchContacts();
        fetchTrackingsPurposes();
    }, []);

    return (
        <Dialog
            onClose={() => closeModal()}
            open={isOpenModal}
            size="md"
            title={t('Create tracking')}
        >
            <TrackingForm
                contacts={contacts}
                fetchUsers={fetchUsers}
                trackingsPurposes={trackingsPurposes}
                trackingsPurposesToSelect={trackingsPurposesToSelect}
                users={users}
            />
        </Dialog>
    );
};

Container.propTypes = {
    closeModal: PropTypes.func,
    contacts: PropTypes.array,
    fetchContacts: PropTypes.func,
    fetchTrackingsPurposes: PropTypes.func,
    fetchUsers: PropTypes.func,
    isOpenModal: PropTypes.bool,
    trackingsPurposes: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    trackingsPurposesToSelect: PropTypes.array,
    users: PropTypes.array,
};

const mapStateToProps = createStructuredSelector({
    contacts: selectors.getContactsData,
    isOpenModal: selectors.getIsOpenModal,
    trackingsPurposes: selectors.getTrackingPurpose,
    trackingsPurposesToSelect: selectors.getTrackingPurposeToSelect,
    users: selectors.getUsersData,
});

const mapDispatchToProps = (dispatch) => ({
    closeModal: () => dispatch(actions.closeModal()),
    fetchContacts: () => dispatch(actions.fetchContacts()),
    fetchTrackingsPurposes: () => dispatch(actions.fetchTrackingsPurposes()),
    fetchUsers: (contactId) => dispatch(actions.fetchUsers(contactId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Container);
