import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Alert } from 'sunwise-ui';

import { isDACRate } from '../helpers';

const Warnings = ({
    rateChange,
    selectedNextRate,
    selectedPrevRate,
    solarConsumption,
}) => {
    const { t } = useTranslation();

    if (!selectedPrevRate) return null;

    return (
        <>
            {isDACRate(
                selectedPrevRate,
                selectedNextRate,
                rateChange,
                solarConsumption
            ) && (
                <Alert severity="warning">
                    {t(
                        'Your solar consumption is higher than the DAC limit of the rate {{rate}}',
                        {
                            rate:
                                (selectedNextRate && selectedNextRate.name) ||
                                '',
                        }
                    )}
                </Alert>
            )}

            {rateChange !== '' &&
                selectedPrevRate.isCertified === 'DAC' &&
                selectedPrevRate.name === 'DAC' &&
                parseInt(rateChange) === 0 && (
                    <Alert severity="warning">
                        {t(
                            'The subsidized rate is not DAC with the configured consumptions'
                        )}
                    </Alert>
                )}
        </>
    );
};

Warnings.propTypes = {
    rateChange: PropTypes.number,
    selectedNextRate: PropTypes.object,
    selectedPrevRate: PropTypes.object,
    solarConsumption: PropTypes.number,
};

export default Warnings;
