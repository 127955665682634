import i18next from 'i18next';
import { createSelector } from 'reselect';

import orm from 'common/orm';

import { NAME } from './constants';

export const getModel = (state) => state[NAME];

export const getEntitiesSession = createSelector(getModel, (state) =>
    orm.session(state.entities)
);

export const getCertifiedRateModalInitialValues = createSelector(
    getModel,
    (model) => model.certifiedRateModalInitialValues
);

export const getRateDetailInitialValues = createSelector(
    getModel,
    (model) => model.rateDetailInitialValues
);

export const getRateModalInitialValues = createSelector(
    getModel,
    (model) => model.rateModalInitialValues
);

export const getSupplierUtilityInitialValues = createSelector(
    getModel,
    (model) => model.supplierUtilityInitialValues
);

export const getModalIsOpen = createSelector(
    getModel,
    (model) => model.modalIsOpen
);

export const getModalIsCreate = createSelector(
    getModel,
    (model) => model.modalIsCreate
);

export const getCertifiedRateModalIsOpen = createSelector(
    getModel,
    (model) => model.certifiedRateModalIsOpen
);

export const getSupplierUtilitiesModalIsOpen = createSelector(
    getModel,
    (model) => model.supplierUtilitiesModalIsOpen
);

export const getSupplierUtilityFormIsOpen = createSelector(
    getModel,
    (model) => model.supplierUtilityFormIsOpen
);

export const getSaveItem = createSelector(getModel, (model) => model.saveItem);

export const getErrorsSaveItem = createSelector(
    getSaveItem,
    (saveItem) => saveItem.errors
);

export const getIsSavingItem = createSelector(
    getSaveItem,
    (saveItem) => saveItem.isSaving
);

export const getShowModal = createSelector(
    getModel,
    (model) => model.showModal
);

export const getIsToggleModal = createSelector(
    getModel,
    (model) => model.toggleModal
);

export const getFetchCountries = createSelector(
    getModel,
    (model) => model.fetchCountries
);

export const getIsFetchingCountries = createSelector(
    getFetchCountries,
    (fetchCountries) => fetchCountries.isFetching
);

export const getCountriesErrors = createSelector(
    getFetchCountries,
    (fetchCountries) => fetchCountries.errors
);

export const getCountriesData = createSelector(
    getEntitiesSession,
    ({ Country }) => Country.all().toRefArray()
);

export const getCountriesItemsToSelect = createSelector(
    getCountriesData,
    (countries) => [
        {
            label: i18next.t('Select a country'),
            value: '',
            disabled: true,
        },
        ...countries.map((country) => ({
            label: country.name,
            value: country.id,
        })),
    ]
);

export const getFetchDivisions = createSelector(
    getModel,
    (model) => model.fetchDivisions
);

export const getIsFetchingDivisions = createSelector(
    getFetchDivisions,
    (fetchDivisions) => fetchDivisions.isFetching
);

export const getDivisionsErrors = createSelector(
    getFetchDivisions,
    (fetchDivisions) => fetchDivisions.errors
);

export const getFetchDivisionsData = createSelector(
    getFetchDivisions,
    (fetchDivisions) => fetchDivisions?.data || []
);

export const getDivisionsItemsToSelect = createSelector(
    getFetchDivisionsData,
    (divisions) => [
        {
            label: '',
            options: [
                {
                    label: i18next.t('Division'),
                    value: '',
                },
                ...divisions.map((value) => ({
                    label: value.name,
                    value: value.id,
                })),
            ],
        },
    ]
);

export const getFilterRates = createSelector(
    getModel,
    (model) => model.filterRates
);

export const getFilterQuery = createSelector(
    getFilterRates,
    (filterRates) => filterRates.query
);

export const getIsFetchingRates = createSelector(
    getFilterRates,
    (filterRates) => filterRates.isFetching
);

export const getFilterRatesHasNextPage = createSelector(
    getFilterRates,
    (filterRates) => filterRates.hasNextPage
);

export const getIsFetchingNextPage = createSelector(
    getFilterRates,
    (filterRates) => filterRates.isFetchingNextPage
);

export const getHasNextPage = createSelector(
    getFilterRatesHasNextPage,
    getIsFetchingNextPage,
    (hasNextPage, isFetchingNextPage) => hasNextPage && !isFetchingNextPage
);

export const getRatesErrors = createSelector(
    getFilterRates,
    (filterRates) => filterRates.errors
);

export const getRatesData = createSelector(getEntitiesSession, ({ Rate }) =>
    Rate.all().toRefArray()
);

export const getNumberCertifiedRates = createSelector(
    getRatesData,
    (rates = []) => rates.filter((r) => r.isCertified).length
);

export const getCurrencies = createSelector(
    getModel,
    (model) => model.fetchCurrencies
);

export const getIsFetchingCurrencies = createSelector(
    getCurrencies,
    (curriencies) => curriencies.isFetching
);

export const getCurrenciesData = createSelector(
    getEntitiesSession,
    ({ Currency }) => Currency.all().toRefArray()
);

export const getCurrenciesItemsToSelect = createSelector(
    getCurrenciesData,
    (currencies) => [
        {
            label: i18next.t('Select a currency'),
            value: '',
            disabled: true,
        },
        ...currencies.map((country) => ({
            label: country.name,
            value: country.id,
        })),
    ]
);

export const getSupplierUtilities = createSelector(
    getModel,
    (model) => model.fetchSupplierUtilities
);

export const getIsFetchingSupplierUtilities = createSelector(
    getSupplierUtilities,
    (supplierUtilities) => supplierUtilities.isFetching
);

export const getSupplierUtilitiesData = createSelector(
    getEntitiesSession,
    ({ SupplierUtility }) => SupplierUtility.all().toRefArray() || []
);

export const getSupplierUtilitiesItemsToSelect = createSelector(
    getSupplierUtilitiesData,
    (supplierUtilities) => [
        {
            label: i18next.t('Select an electrical supplier'),
            value: '',
            disabled: true,
        },
        ...supplierUtilities.map((supplierUtility) => ({
            label: supplierUtility.name,
            value: supplierUtility.id,
        })),
    ]
);

export const getSaveRate = createSelector(getModel, (model) => model.saveRate);

export const getIsSavingRate = createSelector(
    getSaveRate,
    (saveRate) => saveRate.isSaving
);

export const getSaveRateErrors = createSelector(
    getSaveRate,
    (saveRate) => saveRate.errors
);

export const getSaveSupplierUtility = createSelector(
    getModel,
    (model) => model.saveSupplierUtility
);

export const getIsSavingSupplierUtility = createSelector(
    getSaveSupplierUtility,
    (saveSupplierUtility) => saveSupplierUtility.isSaving
);

export const getSaveSupplierUtilityErrors = createSelector(
    getSaveSupplierUtility,
    (saveSupplierUtility) => saveSupplierUtility.errors
);

export const getFetchUnits = createSelector(
    getModel,
    (model) => model.fetchUnits
);

export const getIsFetchingUnits = createSelector(
    getFetchUnits,
    (fetchUnits) => fetchUnits.isFetching
);

export const getUnitsErrors = createSelector(
    getIsFetchingUnits,
    (fetchUnits) => fetchUnits.errors
);

export const getUnitsData = createSelector(getEntitiesSession, ({ UnitRate }) =>
    UnitRate.all().toRefArray()
);

export const getEnergyUnitsDataToSelect = createSelector(
    getUnitsData,
    (unitsData) => [
        {
            label: i18next.t('Select unit'),
            value: '',
            disabled: true,
        },
        ...unitsData
            .filter((item) => item.typeConcept === 'Energy')
            .map((item) => ({
                label: item.name,
                value: item.name,
            })),
    ]
);

export const getDemandUnitsDataToSelect = createSelector(
    getUnitsData,
    (unitsData) => [
        {
            label: i18next.t('Select unit'),
            value: '',
            disabled: true,
        },
        ...unitsData
            .filter((item) => item.typeConcept === 'Demand')
            .map((item) => ({
                label: item.name,
                value: item.name,
            })),
    ]
);

export const getOthersUnitsDataToSelect = createSelector(
    getUnitsData,
    (unitsData) => [
        {
            label: i18next.t('Select unit'),
            value: '',
            disabled: true,
        },
        ...unitsData
            .filter(
                (item) =>
                    item.typeConcept !== 'Demand' &&
                    item.typeConcept !== 'Energy'
            )
            .map((item) => ({
                label: item.name,
                value: item.name,
            })),
    ]
);

export const getFetchContractedDemandUnits = createSelector(
    getModel,
    (model) => model.fetchContractedDemandUnits
);

export const getContractedDemandUnitsData = createSelector(
    getFetchContractedDemandUnits,
    (fetchContractedDemandUnits) => fetchContractedDemandUnits?.data || []
);

export const getContractedDemandUnitsDataToSelect = createSelector(
    getContractedDemandUnitsData,
    (contractedDemandUnits) => [
        {
            label: i18next.t('Select unit'),
            value: '',
            disabled: true,
        },
        ...contractedDemandUnits.map((item) => ({
            label: item.name,
            value: item.name,
        })),
    ]
);

export const getFetchSpainExcessDemandUnits = createSelector(
    getModel,
    (model) => model.fetchSpainExcessDemandUnits
);

export const getSpainExcessDemandUnitsData = createSelector(
    getFetchSpainExcessDemandUnits,
    (fetchSpainExcessDemandUnits) => fetchSpainExcessDemandUnits?.data || []
);

export const getSpainExcessDemandUnitsDataToSelect = createSelector(
    getSpainExcessDemandUnitsData,
    (spainExcessDemandUnits) => [
        {
            label: i18next.t('Select unit'),
            value: '',
            disabled: true,
        },
        ...spainExcessDemandUnits.map((item) => ({
            label: item.name,
            value: item.name,
        })),
    ]
);

export const getFetchRate = createSelector(
    getModel,
    (model) => model.fetchRate
);

export const getIsFetchingRate = createSelector(
    getFetchRate,
    (fetchRate) => fetchRate.isFetching
);

export const getSelectedItemId = createSelector(
    getFetchRate,
    (fetchRate) => fetchRate.id
);

export const getSelectedItemModel = createSelector(
    getEntitiesSession,
    getSelectedItemId,
    ({ Rate }, id) => {
        if (!id) return null;
        return Rate.withId(id);
    }
);

export const getSelectedItemData = createSelector(
    getIsFetchingRate,
    getSelectedItemModel,
    (isFetching, rateModel) => {
        if (!rateModel || isFetching) return null;
        return rateModel.ref;
    }
);

export const getSelectedItemIsCertified = createSelector(
    getSelectedItemData,
    (rateData) => {
        if (!rateData) return false;
        return rateData.isCertified;
    }
);

export const getSelectedItemDataUnparsed = createSelector(
    getIsFetchingRate,
    getSelectedItemModel,
    (isFetching, rateModel) => {
        if (!rateModel || isFetching) return null;
        return rateModel.toUnparsed();
    }
);

export const getSetRateEnabled = createSelector(
    getModel,
    (model) => model.setRateEnabled
);

export const getSettingEnabledRateId = createSelector(
    getSetRateEnabled,
    (model) => model.rateId
);

export const getHasActiveProjects = createSelector(
    getModel,
    (model) => model.hasActiveProjects
);
