import { updateOfferCustomTemplate } from 'common/api/v1/newOfferCustomTemplate';
import { showReponseErrorsAsAlert } from 'common/utils/helpers';

import { SET_PAGE_SIZE } from '../actionTypes';
import { editTemplateViewActions } from '../reducer';

export default (updatedData) => (dispatch) => {
    dispatch(editTemplateViewActions[SET_PAGE_SIZE](updatedData.page_size));

    const values = {
        content: JSON.stringify(updatedData),
        page_size: updatedData.page_size,
    };

    updateOfferCustomTemplate(values, updatedData.id)
        .then()
        .catch((error) => {
            showReponseErrorsAsAlert(dispatch, error?.response);
        });
};
