import React from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { UiDialog as Dialog } from 'sunwise-ui';

import * as projectConsumptionCardSelectors from '../../../projectConsumptionCard/selectors';
import * as actions from '../../actions';
import { getCommercialOfferModalTitle } from '../../helpers';
import * as selectors from '../../selectors';

import CommercialOfferForm from './CommercialOfferForm';

const CommercialOfferModal = ({
    consumptionEnergy,
    handleClickCloseModal,
    isOpenCommercialOfferModal,
    projectId,
    resetForm,
}) => {
    return (
        <Dialog
            onClose={handleClickCloseModal}
            onExited={() => resetForm()}
            open={isOpenCommercialOfferModal}
            size="md"
            title={getCommercialOfferModalTitle(consumptionEnergy)}
        >
            <CommercialOfferForm
                consumptionEnergy={consumptionEnergy}
                projectId={projectId}
            />
        </Dialog>
    );
};
const mapStateToProps = createStructuredSelector({
    consumptionEnergy: projectConsumptionCardSelectors.getDataConsumptionEnergy,
    isOpenCommercialOfferModal: selectors.getIsOpenCommercialOfferModal,
});

const mapDispatchToProps = (dispatch) => ({
    handleClickCloseModal: () =>
        dispatch(actions.openCommercialOfferModal(false)),
    resetForm: () => dispatch(actions.resetCommercialOfferForm()),
});

CommercialOfferModal.propTypes = {
    consumptionEnergy: PropTypes.object,
    handleClickCloseModal: PropTypes.func,
    isOpenCommercialOfferModal: PropTypes.bool,
    projectId: PropTypes.string,
    resetForm: PropTypes.func,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CommercialOfferModal);
