import { fetchPaginatedData } from 'common/api/v1/historical';

import {
    FETCH_CONSUMPTION_HISTORY,
    FETCH_CONSUMPTION_HISTORY_FAILURE,
    FETCH_CONSUMPTION_HISTORY_SUCCESS,
    FETCH_INITIAL_CONSUMPTION_HISTORY,
} from '../actionTypes';
import { actions } from '../reducer';

export default ({ isInitialFetch, page = 1, pageSize = 6, projectId, year }) =>
    (dispatch) => {
        if (!projectId) return;

        const actionType = isInitialFetch
            ? FETCH_INITIAL_CONSUMPTION_HISTORY
            : FETCH_CONSUMPTION_HISTORY;

        dispatch(actions[actionType]());

        fetchPaginatedData(projectId, page, pageSize, year)
            .then((response) => {
                const data = response?.data;
                dispatch(actions[FETCH_CONSUMPTION_HISTORY_SUCCESS](data));
            })
            .catch((error) =>
                dispatch(
                    actions[FETCH_CONSUMPTION_HISTORY_FAILURE](
                        error?.response?.data?.errors
                    )
                )
            );
    };
