import React, { useEffect, useState } from 'react';

import LinearProgress from '@mui/material/LinearProgress';
import PropTypes from 'prop-types';
import { DataGrid } from 'sunwise-ui';

import { CustomPagination } from 'common/components';

import { getColumnsFromSeries, getRowsFromSeries } from '../helpers';

const MeasuredEnergyTable = ({
    categories,
    countryCurrencyLocale,
    isFetching,
    series,
    viewOption,
}) => {
    const [tableContent, setTableContent] = useState({
        rows: [],
        columns: [],
    });

    useEffect(() => {
        setTableContent({
            rows: getRowsFromSeries(series, categories),
            columns: getColumnsFromSeries(
                series,
                viewOption,
                countryCurrencyLocale
            ),
        });
    }, [series, categories]);

    return (
        <DataGrid
            autoHeight
            checkboxSelection={false}
            components={{
                LoadingOverlay: LinearProgress,
                Pagination: CustomPagination,
            }}
            columns={tableContent.columns}
            loading={isFetching}
            disableColumnMenu
            disableSelectionOnClick
            hideFooterSelectedRowCount
            pageSize={12}
            pagination
            paginationMode="client"
            rows={tableContent.rows}
            sx={{
                '& .MuiDataGrid-cell:focus': { outline: 'none' },
                my: 2,
            }}
        />
    );
};

MeasuredEnergyTable.propTypes = {
    categories: PropTypes.array,
    countryCurrencyLocale: PropTypes.string,
    isFetching: PropTypes.bool,
    series: PropTypes.array,
    viewOption: PropTypes.number,
};

export default MeasuredEnergyTable;
