import React from 'react';

import { styled } from '@mui/material/styles';
import { isArray, isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box, Grid } from 'sunwise-ui';

import { InfoToolTip } from 'common/components';
import { ReactHookFormIntlNumberInput } from 'common/components/form/bootstrap';
import TitleWithDetail from 'common/components/TitleWithDetail';

import { FieldArrayConcepts } from './TaxRateSettings';

const RateSettingsContainer = styled(Box)`
    display: flex;
    flex-direction: column;
    padding: 0 10px;
`;

const FieldsContainer = styled(Box)`
    display: flex;
    flex-direction: column;
    margin: 20px 10px;
`;

const CertifiedRateSection = ({
    canModifyRatesAnualCFE,
    canModifyRatesDap,
    canModifyTaxesRate,
    control,
    isLocked,
    otherConcepts,
    taxesConcepts,
}) => {
    const { t } = useTranslation();

    return (
        <>
            <Box sx={{ mb: 2 }}>
                <TitleWithDetail variant="body2">
                    {t('Tax rate')}
                </TitleWithDetail>
            </Box>
            <Grid container>
                <Grid item xs={18} md={9} lg={9}>
                    <ReactHookFormIntlNumberInput
                        append="%"
                        caption={`% ${t('Annual')}`}
                        control={control}
                        disabled={isLocked || !canModifyRatesAnualCFE}
                        fullWidth
                        label={t('Rate increase')}
                        min="0"
                        name="increase_percentage"
                        step="any"
                    />
                </Grid>

                <Grid item xs={18} md={9} lg={9}>
                    <ReactHookFormIntlNumberInput
                        append="%"
                        caption={`% ${t('Period')}`}
                        control={control}
                        disabled={isLocked || !canModifyRatesDap}
                        fullWidth
                        label="DAP"
                        min="0"
                        name="dap_value"
                        step="any"
                    />
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={18} md={18} lg={18}>
                    <ReactHookFormIntlNumberInput
                        append="%"
                        control={control}
                        disabled={isLocked || !canModifyTaxesRate}
                        fullWidth
                        label={t('Tax rate')}
                        min="0"
                        name="tax_rate_percentage"
                        step="any"
                    />
                </Grid>
            </Grid>

            {isArray(otherConcepts) && !isEmpty(otherConcepts) && (
                <RateSettingsContainer>
                    <Grid container>
                        <TitleWithDetail>{t('Other charges')}</TitleWithDetail>
                        <InfoToolTip variant="no-wrapper">
                            {t(
                                'These elements can be modified from the rate settings in the Parameters section'
                            )}
                        </InfoToolTip>
                    </Grid>

                    <FieldsContainer>
                        <FieldArrayConcepts
                            control={control}
                            disabled={isLocked || !canModifyTaxesRate}
                            name="other_concepts"
                            values={otherConcepts}
                        />
                    </FieldsContainer>
                </RateSettingsContainer>
            )}

            {isArray(taxesConcepts) && !isEmpty(taxesConcepts) && (
                <RateSettingsContainer>
                    <Grid container>
                        <TitleWithDetail>
                            {t('Tax', { count: 2 })}
                        </TitleWithDetail>
                        <InfoToolTip variant="no-wrapper">
                            {t(
                                'These elements can be modified from the rate settings in the Parameters section'
                            )}
                        </InfoToolTip>
                    </Grid>

                    <FieldsContainer>
                        <FieldArrayConcepts
                            control={control}
                            disabled={isLocked || !canModifyTaxesRate}
                            name="taxes_concepts"
                            values={taxesConcepts}
                        />
                    </FieldsContainer>
                </RateSettingsContainer>
            )}
        </>
    );
};

CertifiedRateSection.propTypes = {
    canModifyRatesAnualCFE: PropTypes.bool,
    canModifyRatesDap: PropTypes.bool,
    canModifyTaxesRate: PropTypes.bool,
    control: PropTypes.object,
    isLocked: PropTypes.bool,
    otherConcepts: PropTypes.array,
    taxesConcepts: PropTypes.array,
};

export default CertifiedRateSection;
