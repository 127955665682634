import React from 'react';

import FolderIcon from '@mui/icons-material/Folder';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Card, Grid } from 'sunwise-ui';

import TitleIcon from 'common/components/TitleIcon';
import { PERMISSION_LIST } from 'common/constants/permissionsV2';
import withPermissions from 'common/hocs/withPermissions';

import ListItems from './ListItems';

const ProjectsContent = ({
    canDelete,
    canModify,
    canView,
    fetchProjects,
    filterData,
    handleClickArchive,
    handleClickRename,
    handleOpenProject,
    isLoading,
    prepareFormCreate,
    projects,
    selectedProject,
    selectedProjectId,
    setFilterData,
}) => {
    const { t } = useTranslation();

    if (!canView) return null;

    return (
        <Card>
            <Card.Header>
                <Grid container>
                    <Grid item xs>
                        <TitleIcon
                            fontWeight="bold"
                            icon={
                                <FolderIcon
                                    fontSize="medium"
                                    sx={{
                                        color: 'primary.main',
                                    }}
                                />
                            }
                            title={t('Project', { count: 2 })}
                            variant="body1"
                        />
                    </Grid>
                </Grid>
            </Card.Header>
            <Card.Body>
                <ListItems
                    canDelete={canDelete}
                    canModify={canModify}
                    fetchProjects={fetchProjects}
                    filterData={filterData}
                    handleClickArchive={handleClickArchive}
                    handleClickRename={handleClickRename}
                    handleOpenProject={handleOpenProject}
                    isLoading={isLoading}
                    prepareFormCreate={prepareFormCreate}
                    projects={projects}
                    selectedProject={selectedProject}
                    selectedProjectId={selectedProjectId}
                    setFilterData={setFilterData}
                />
            </Card.Body>
        </Card>
    );
};

ProjectsContent.propTypes = {
    canDelete: PropTypes.bool,
    canModify: PropTypes.bool,
    canView: PropTypes.bool,
    fetchProjects: PropTypes.func,
    filterData: PropTypes.object,
    handleClickArchive: PropTypes.func,
    handleClickRename: PropTypes.func,
    handleOpenProject: PropTypes.func,
    isLoading: PropTypes.bool,
    prepareFormCreate: PropTypes.func,
    projects: PropTypes.array,
    selectedProject: PropTypes.object,
    selectedProjectId: PropTypes.string,
    setFilterData: PropTypes.func,
};

export default withPermissions(PERMISSION_LIST.CONTACT_PROJECTS_PERMISSION)(
    ProjectsContent
);
