import { getPlans } from 'common/api/v2/plans';

import {
    FETCH_PLANS,
    FETCH_PLANS_FAILURE,
    FETCH_PLANS_SUCCESS,
} from '../actionTypes';
import { projectCommOfferTableActions } from '../reducer';

export default (branchOffice) => (dispatch) => {
    if (!branchOffice) return;

    dispatch(projectCommOfferTableActions[FETCH_PLANS]());

    getPlans(branchOffice)
        .then((response) =>
            dispatch(
                projectCommOfferTableActions[FETCH_PLANS_SUCCESS](
                    response?.data?.data
                )
            )
        )
        .catch((error) =>
            dispatch(projectCommOfferTableActions[FETCH_PLANS_FAILURE](error))
        );
};
