import { createTracking, updateTracking } from 'common/api/v1/contactTrackings';
import { showReponseErrorsAsAlert } from 'common/utils/helpers';
import showToast from 'common/utils/showToast';

import * as profileSelectors from '../../profile/selectors';
import {
    SAVE_TRACKING,
    SAVE_TRACKING_SUCCESS,
    SAVE_TRACKING_FAILURE,
} from '../actionTypes';
import { trackingsActions } from '../reducer';
import * as selectors from '../selectors';

import fetchTrackings from './fetchTrackings';
import fetchTrackingsNext from './fetchTrackingsNext';
import showForm from './showForm';

export default (values) => (dispatch, getState) => {
    const contactId = selectors.getContactId(getState());
    const userId = profileSelectors.getUserId(getState());
    const newValues = {
        agent: userId,
        contact: contactId,
        description: values.description,
        notification_at: values.notification_at?.concat(':00'),
        objective_tracking: values.objective_tracking,
        remember: values.remember,
        tracking_purpose: values.tracking_purpose,
        tracking_type: values.tracking_type,
    };

    dispatch(trackingsActions[SAVE_TRACKING]());

    const save = values.id
        ? () => updateTracking(newValues, values.id)
        : () => createTracking(newValues);

    save()
        .then((response) => {
            dispatch(trackingsActions[SAVE_TRACKING_SUCCESS](response.data));
            dispatch(showForm(false));
            dispatch(fetchTrackings());
            dispatch(fetchTrackingsNext());

            showToast();
        })
        .catch((error) => {
            dispatch(
                trackingsActions[SAVE_TRACKING_FAILURE](
                    error?.response?.data?.errors
                )
            );
            showReponseErrorsAsAlert(dispatch, error?.response);
        });
};
