import React, { useState } from 'react';

import AddIcon from '@mui/icons-material/Add';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { createStructuredSelector } from 'reselect';
import {
    Button,
    Container as MaterialContainer,
    Card,
    Grid,
    Typography,
    UiDialog as Dialog,
} from 'sunwise-ui';

import { HeaderContainer, TitleIcon } from 'common/components';
import { PERMISSION_LIST } from 'common/constants/permissionsV2';
import withPermissions from 'common/hocs/withPermissions';
import * as customColumnsSelectors from 'common/modules/customColumns/selectors';

import * as actions from './actions';
import ListItems from './components/ListItems';
import ProductForm from './components/ProductForm';
import * as selectors from './selectors';

const Container = ({
    canDelete,
    canModify,
    canView,
    closeModal,
    fetchTemplates,
    filterProducts,
    handleClickBulkItems,
    handleClickUpdate,
    handleSaveRow,
    isFetching,
    isFetchingTableSettings,
    isFetchingTemplates,
    isOpenProductModal,
    openModalProduct,
    paginationData,
    products,
    tableSettings,
    templates,
}) => {
    const [filterData, setFilterData] = useState({
        orderBy: 'created_at',
        page: 0,
        pageSize: 25,
        searchText: '',
        sortBy: 'desc',
        status: 'active',
    });

    const { t } = useTranslation();

    return (
        <MaterialContainer maxWidth={false}>
            <HeaderContainer>
                <Grid item xs>
                    <TitleIcon title={t('Financial product', { count: 2 })} />
                </Grid>
            </HeaderContainer>

            <Card>
                <Card.Header>
                    <Grid container>
                        <Grid item xs>
                            <TitleIcon
                                fontWeight="bold"
                                title={t('Own product', { count: 2 })}
                                variant="subtitle"
                            />
                            <Typography variant="body2">
                                {t(
                                    'Configure your own plans with monthly payments, rates, commissions, among others, to apply them directly to your proposals'
                                )}
                            </Typography>
                        </Grid>
                        <Grid item xs={18} lg={6} sx={{ textAlign: 'right' }}>
                            <Button
                                endIcon={<AddIcon />}
                                onClick={() => openModalProduct(true)}
                                variant="outlined"
                                visible={canModify}
                                sx={{ width: { xs: '100%', md: 'auto' } }}
                            >
                                {t('Add financial product')}
                            </Button>
                        </Grid>
                    </Grid>
                </Card.Header>

                <Card.Body>
                    {canView && (
                        <ListItems
                            canDelete={canDelete}
                            canModify={canModify}
                            canView={canView}
                            filterData={filterData}
                            filterItems={filterProducts}
                            handleClickBulkItems={handleClickBulkItems}
                            handleClickUpdate={handleClickUpdate}
                            handleSaveRow={handleSaveRow}
                            isFetching={isFetching || isFetchingTableSettings}
                            items={products}
                            paginationData={paginationData}
                            setFilterData={setFilterData}
                            tableSettings={tableSettings}
                        />
                    )}
                </Card.Body>
            </Card>

            <Dialog
                open={isOpenProductModal}
                onClose={() => closeModal()}
                title={t('Enter your product data')}
            >
                <ProductForm
                    closeModal={closeModal}
                    fetchTemplates={fetchTemplates}
                    filterData={filterData}
                    isFetchingTemplates={isFetchingTemplates}
                    templates={templates}
                />
            </Dialog>
        </MaterialContainer>
    );
};

Container.propTypes = {
    canDelete: PropTypes.bool,
    canModify: PropTypes.bool,
    canView: PropTypes.bool,
    closeModal: PropTypes.func,
    fetchTemplates: PropTypes.func,
    filterProducts: PropTypes.func,
    handleClickBulkItems: PropTypes.func,
    handleClickUpdate: PropTypes.func,
    handleSaveRow: PropTypes.func,
    isFetching: PropTypes.bool,
    isFetchingTableSettings: PropTypes.bool,
    isFetchingTemplates: PropTypes.bool,
    isOpenProductModal: PropTypes.bool,
    openModalProduct: PropTypes.func,
    paginationData: PropTypes.object,
    products: PropTypes.array,
    tableSettings: PropTypes.array,
    templates: PropTypes.array,
};

const mapStateToProps = createStructuredSelector({
    isFetching: selectors.getIsFetchingProducts,
    isFetchingTableSettings: customColumnsSelectors.getIsFetchingTableSettings,
    isFetchingTemplates: selectors.getIsFetchingTemplateCatalogs,
    isOpenProductModal: selectors.getIsOpenProductModal,
    paginationData: selectors.getProductsPagination,
    products: selectors.getProductsData,
    tableSettings: customColumnsSelectors.getTableSettingsData,
    templates: selectors.getTemplateCatalogsData,
});

const mapDispatchToProps = (dispatch) => ({
    closeModal: () => dispatch(actions.closeModal()),
    fetchTemplates: () => dispatch(actions.fetchTemplates()),
    filterProducts: (filterData) =>
        dispatch(actions.filterProducts(filterData)),
    handleClickBulkItems: (ids, value, filterData, callback) =>
        dispatch(actions.prepareBulkItems(ids, value, filterData, callback)),
    handleClickUpdate: (id, disabled) =>
        dispatch(actions.fetchProduct(id, disabled)),
    handleSaveRow: (item) => dispatch(actions.updateProduct(item)),
    openModalProduct: (status) => dispatch(actions.toggleModalProduct(status)),
});

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withPermissions(PERMISSION_LIST.FINANCIAL_PRODUCTS_PERMISSION)
)(Container);
