import get from 'lodash/get';

import { updateItem } from 'common/api/v1/proposalsSummary';
import alerts from 'common/modules/alerts';
import { showReponseErrorsAsAlert } from 'common/utils/helpers';
import showToast from 'common/utils/showToast';

import {
    RENAME_SUMMARY,
    RENAME_SUMMARY_FAILURE,
    RENAME_SUMMARY_SUCCESS,
} from '../actionTypes';
import { proposalSummaryActions } from '../reducer';

import fetchSummaries from './fetchSummaries';

export default (values, isArchivedFilter) => (dispatch) => {
    dispatch(proposalSummaryActions[RENAME_SUMMARY]());

    const id = get(values, 'id', null);
    const projectId = get(values.project, 'id', null);

    updateItem(id, {
        name: values.name,
    })
        .then(() => {
            dispatch(proposalSummaryActions[RENAME_SUMMARY_SUCCESS]());
            dispatch(alerts.actions.close());
            dispatch(fetchSummaries(projectId, isArchivedFilter));

            showToast();
        })
        .catch((error) => {
            dispatch(
                proposalSummaryActions[RENAME_SUMMARY_FAILURE](
                    error?.response?.data?.errors
                )
            );
            showReponseErrorsAsAlert(dispatch, error?.response);
        });
};
