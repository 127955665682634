import i18next from 'i18next';

import { deleteAccessKey } from 'common/api/afterSales/accessConfig';
import alerts from 'common/modules/alerts';
import showToast from 'common/utils/showToast';

import {
    DELETE_CREDENTIAL,
    DELETE_CREDENTIAL_FAILURE,
    DELETE_CREDENTIAL_SUCCESS,
} from '../actionTypes';
import { credentialIntegrationsActions } from '../reducer';

import fetchCredentials from './fetchCredentials';

export default (id) => (dispatch) => {
    dispatch(credentialIntegrationsActions[DELETE_CREDENTIAL]());

    deleteAccessKey(id)
        .then(() => {
            dispatch(
                credentialIntegrationsActions[DELETE_CREDENTIAL_SUCCESS]()
            );
            showToast({ body: i18next.t('Successfully deleted') });
            dispatch(fetchCredentials());
        })
        .catch((error) => {
            const errorDetail = error?.response?.data?.detail;

            dispatch(
                credentialIntegrationsActions[DELETE_CREDENTIAL_FAILURE](
                    errorDetail
                )
            );

            if (Array.isArray(errorDetail)) {
                const dataAlert = {
                    confirmText: i18next.t('Accept'),
                    messages: errorDetail.map((detail) => detail?.msg),
                    title: i18next.t('Error'),
                    type: alerts.ALERT_TYPE_ALERT,
                    variant: 'warning',
                };

                dispatch(alerts.actions.show(dataAlert));
            } else {
                showToast({
                    type: 'danger',
                    body: i18next.t('An error occurred during the process'),
                });
            }
        });
};
