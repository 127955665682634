import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { createStructuredSelector } from 'reselect';
import { Container as MaterialContainer, Grid } from 'sunwise-ui';

import { HeaderContainer, TitleIcon } from 'common/components';
import RateDetailCardPlaceholder from 'common/components/placeholder/RateDetailCardPlaceholder';
import { PERMISSION_LIST } from 'common/constants/permissionsV2';
import withPermissions from 'common/hocs/withPermissions';

import companyGeneral from '../companyGeneral';
import RateAlerts from '../rateAlerts';

import * as actions from './actions';
import {
    CertifiedRateModalForm,
    CustomRateModalForm,
    RateDetail,
    RateFilter,
    RateList,
} from './components';
import { buildQueryParams } from './helpers';
import * as selectors from './selectors';

const Container = ({
    canDelete,
    canModify,
    canView,
    certifiedRatesNumber,
    companyCountry,
    fetchContractedDemandUnits,
    fetchCountries,
    fetchCurrencies,
    fetchDivisions,
    fetchSpainExcessDemandUnits,
    fetchSupplierUtilities,
    fetchUnits,
    filterQuery,
    filterRates,
    isCompanyFromSpain,
    isFetchingRate,
    isFetchingRates,
    prepareUpdateCertifiedRate,
    reset,
    selectedItemId,
    selectedItemIsCertified,
    selectedItemModel,
    setFilterQuery,
}) => {
    const { t } = useTranslation();
    const [filterName, setFilterName] = useState('');
    const [businessTypeSelected, selectBusinessType] = useState('all');
    const [rateTypeSelected, selectRateType] = useState('all');
    const [voltageTypeSelected, selectVoltageType] = useState('all');
    const [isEnabled, setIsEnabled] = useState(true);

    useEffect(() => {
        fetchUnits();
        fetchContractedDemandUnits();
        fetchDivisions();
        return () => reset();
    }, []);

    useEffect(() => {
        setFilterQuery(
            buildQueryParams({
                businessTypeSelected,
                filterName,
                isEnabled,
                page: 1,
                rateTypeSelected,
                voltageTypeSelected,
            })
        );
    }, [
        businessTypeSelected,
        filterName,
        isEnabled,
        rateTypeSelected,
        voltageTypeSelected,
    ]);

    useEffect(() => {
        if (filterQuery !== null) filterRates(filterQuery);
    }, [filterQuery]);

    useEffect(() => {
        if (companyCountry) {
            fetchCurrencies();
            fetchSupplierUtilities(companyCountry);
            fetchCountries(companyCountry);
        }
        if (isCompanyFromSpain) fetchSpainExcessDemandUnits();
    }, [companyCountry]);

    if (!canView) return null;

    return (
        <MaterialContainer maxWidth={false}>
            <HeaderContainer>
                <Grid item xs>
                    <TitleIcon title={t('Rate', { count: 2 })} />
                </Grid>
            </HeaderContainer>
            <RateFilter
                businessTypeSelected={businessTypeSelected}
                certifiedRatesNumber={certifiedRatesNumber}
                isEnabled={isEnabled}
                isFetchingRates={isFetchingRates}
                rateTypeSelected={rateTypeSelected}
                selectBusinessType={selectBusinessType}
                selectRateType={selectRateType}
                selectVoltageType={selectVoltageType}
                setFilterName={setFilterName}
                setIsEnabled={setIsEnabled}
                voltageTypeSelected={voltageTypeSelected}
            />

            <Grid container>
                <Grid item xs={18} lg={6} xl={9}>
                    <RateList
                        businessTypeSelected={businessTypeSelected}
                        canModify={canModify}
                        canDelete={canDelete}
                        filterName={filterName}
                        isEnabled={isEnabled}
                        rateTypeSelected={rateTypeSelected}
                        voltageTypeSelected={voltageTypeSelected}
                    />
                </Grid>

                <Grid item xs={18} lg={12} xl={9}>
                    <RateDetailCardPlaceholder ready={isFetchingRate}>
                        <RateDetail
                            canDelete={canDelete}
                            canModify={canModify}
                            disabled={isFetchingRates}
                            isFetchingRate={isFetchingRate}
                            prepareUpdateCertifiedRate={
                                prepareUpdateCertifiedRate
                            }
                            selectedItemId={selectedItemId}
                            selectedItemIsCertified={selectedItemIsCertified}
                            selectedItemModel={selectedItemModel}
                        />
                    </RateDetailCardPlaceholder>
                </Grid>
            </Grid>

            <CustomRateModalForm />
            <CertifiedRateModalForm />
            <RateAlerts.Container rate={selectedItemModel} />
        </MaterialContainer>
    );
};

const mapStateToProps = createStructuredSelector({
    certifiedRatesNumber: selectors.getNumberCertifiedRates,
    companyCountry: companyGeneral.selectors.getCompanyCountry,
    filterQuery: selectors.getFilterQuery,
    isCompanyFromSpain: companyGeneral.selectors.getCompanyIsFromSpain,
    isFetchingRate: selectors.getIsFetchingRate,
    isFetchingRates: selectors.getIsFetchingRates,
    selectedItemId: selectors.getSelectedItemId,
    selectedItemIsCertified: selectors.getSelectedItemIsCertified,
    selectedItemModel: selectors.getSelectedItemModel,
});

const mapDispatchToProps = (dispatch) => ({
    fetchContractedDemandUnits: () =>
        dispatch(actions.fetchContractedDemandUnits()),
    fetchCountries: (id) => dispatch(actions.fetchCountries(id)),
    fetchCurrencies: (countryId) =>
        dispatch(actions.fetchCurrencies(countryId)),
    fetchDivisions: () => dispatch(actions.fetchDivisions()),
    fetchSpainExcessDemandUnits: () =>
        dispatch(actions.fetchSpainExcessDemandUnits()),
    fetchSupplierUtilities: (countryId) =>
        dispatch(actions.fetchSupplierUtilities(countryId)),
    fetchUnits: () => dispatch(actions.fetchUnits()),
    filterRates: (params) => dispatch(actions.filterRates(params)),
    prepareUpdateCertifiedRate: (values) =>
        dispatch(actions.prepareUpdateCertifiedRate(values)),
    reset: () => dispatch(actions.reset()),
    setFilterQuery: (query) => dispatch(actions.setFilterQuery(query)),
});

Container.propTypes = {
    canDelete: PropTypes.bool,
    canModify: PropTypes.bool,
    canView: PropTypes.bool,
    certifiedRatesNumber: PropTypes.number,
    companyCountry: PropTypes.string,
    fetchContractedDemandUnits: PropTypes.func,
    fetchCountries: PropTypes.func,
    fetchCurrencies: PropTypes.func,
    fetchDivisions: PropTypes.func,
    fetchSpainExcessDemandUnits: PropTypes.func,
    fetchSupplierUtilities: PropTypes.func,
    fetchUnits: PropTypes.func,
    filterQuery: PropTypes.string,
    filterRates: PropTypes.func,
    isCompanyFromSpain: PropTypes.bool,
    isFetchingRate: PropTypes.bool,
    isFetchingRates: PropTypes.bool,
    prepareUpdateCertifiedRate: PropTypes.func,
    reset: PropTypes.func,
    selectedItemId: PropTypes.string,
    selectedItemIsCertified: PropTypes.bool,
    selectedItemModel: PropTypes.object,
    setFilterQuery: PropTypes.func,
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withPermissions(PERMISSION_LIST.CERTIFIED_RATES_PERMISSION)
)(Container);
