import React, { useEffect, useState } from 'react';

import LinearProgress from '@mui/material/LinearProgress';
import PropTypes from 'prop-types';
import { useWatch } from 'react-hook-form';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { MULTIPROPOSAL_TYPE } from 'sunwise-template-core/lib/constants/types';
import { DataGrid } from 'sunwise-ui';

import { CustomPagination } from 'common/components';

import * as actions from '../../actions';
import * as selectors from '../../selectors';

import CustomTemplateColumns from './CustomTemplateColumns';

const CustomTemplatesTab = ({
    control,
    filterCustomBaseTemplates,
    handleSelectTemplate,
    isFetchingCustomBaseTemplates,
    isForSummaryTemplates,
    items,
    languagesDictionary,
    paginationData,
}) => {
    const [language, proposalsNumber, type] = useWatch({
        control,
        name: ['language', 'proposals_number', 'type'],
    });
    const [filterData, setFilterData] = useState({
        language: language,
        orderBy: 'created_at',
        page: 0,
        pageSize: 25,
        searchText: '',
        sortBy: 'desc',
        status: 'active',
        type: type,
    });
    const [rowCount, setRowCount] = useState(paginationData?.rowCount || 0);
    const [selectionModel, setSelectionModel] = useState([]);

    useEffect(() => {
        if (paginationData)
            setRowCount((prevRowCount) =>
                paginationData?.total_rows !== undefined
                    ? paginationData?.total_rows
                    : prevRowCount
            );
    }, [paginationData?.total_rows, setRowCount]);

    useEffect(() => {
        if (filterData) {
            if (type === MULTIPROPOSAL_TYPE)
                filterCustomBaseTemplates({
                    ...filterData,
                    proposalsNumber: proposalsNumber,
                });
            else filterCustomBaseTemplates(filterData);
        }
    }, [filterData]);

    useEffect(() => {
        handleSelectTemplate({
            id: selectionModel[0],
            locked: 0,
        });
    }, [selectionModel]);

    const handleOnPageChange = (page) => {
        setFilterData({ ...filterData, page: page });
    };

    const handleOnSortModelChange = (sortModel) => {
        if (!sortModel[0]) return;

        setFilterData({
            ...filterData,
            orderBy: sortModel[0].field.toLocaleLowerCase(),
            sortBy: sortModel[0].sort,
        });
    };

    return (
        <DataGrid
            autoHeight
            columns={CustomTemplateColumns({
                isForSummaryTemplates,
                languagesDictionary,
            })}
            components={{
                LoadingOverlay: LinearProgress,
                Pagination: CustomPagination,
            }}
            componentsProps={{
                pagination: {
                    rowsPerPageOptions: [25, 50, 75, 100],
                },
            }}
            disableColumnMenu
            editMode="row"
            experimentalFeatures={{ newEditingApi: true }}
            getRowHeight={() => 'auto'}
            initialState={{
                pagination: {
                    pageSize: filterData?.pageSize,
                },
            }}
            loading={isFetchingCustomBaseTemplates}
            onPageChange={handleOnPageChange}
            onPageSizeChange={(newPage) =>
                setFilterData({ ...filterData, pageSize: newPage })
            }
            onSelectionModelChange={(newModel) => setSelectionModel(newModel)}
            onSortModelChange={handleOnSortModelChange}
            page={paginationData?.current_page - 1 || 0}
            pageSize={filterData.pageSize}
            pagination
            paginationMode="server"
            rowCount={rowCount}
            rows={items}
            selectionModel={selectionModel}
            sortingMode="server"
            sx={{
                '& .MuiDataGrid-row--editing .MuiDataGrid-cell': {
                    backgroundColor: 'rgba(255, 154, 0, 0.16)',
                },
                '& .MuiDataGrid-row--editing:hover': {
                    backgroundColor: 'rgba(255, 154, 0, 0.08)',
                },
                '& .MuiInputBase-formControl input': {
                    border: 0,
                    outline: 'none',
                    boxShadow: 'none',
                },
            }}
        />
    );
};

const mapStateToProps = createStructuredSelector({
    isFetchingCustomBaseTemplates: selectors.getIsFetchingCustomBaseTemplates,
    items: selectors.getCustomBaseTemplatesData,
    paginationData: selectors.getCustomBaseTemplatesPagination,
});

const mapDispatchToProps = (dispatch) => ({
    filterCustomBaseTemplates: (filterData) =>
        dispatch(actions.filterCustomBaseTemplates(filterData)),
});

CustomTemplatesTab.propTypes = {
    control: PropTypes.object,
    filterCustomBaseTemplates: PropTypes.func,
    handleSelectTemplate: PropTypes.func,
    isFetchingCustomBaseTemplates: PropTypes.bool,
    isForSummaryTemplates: PropTypes.bool,
    items: PropTypes.array,
    languagesDictionary: PropTypes.object,
    paginationData: PropTypes.object,
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomTemplatesTab);
