import { appendErrors } from 'react-hook-form';

import { toNestError } from './toNestError';
import { validateFieldsNatively } from './validateFieldsNatively';

const parseErrorSchema = (error, validateAllFieldCriteria) =>
    (error.inner || []).reduce((previous, error) => {
        if (!previous[error?.path])
            previous[error?.path] = {
                message: error.message,
                type: error?.type,
            };
        if (validateAllFieldCriteria) {
            const types = previous[error?.path].types;
            const messages = types && types[error?.type];
            previous[error?.path] = appendErrors(
                error?.path,
                validateAllFieldCriteria,
                previous,
                error?.type,
                messages ? [].concat(messages, error.message) : error.message
            );
        }
        return previous;
    }, {});

export default (schema, schemaOptions = {}, resolverOptions = {}) =>
    async (values, context, options) => {
        try {
            const result = await schema(values, context)[
                resolverOptions.mode === 'sync' ? 'validateSync' : 'validate'
            ](
                values,
                Object.assign({ abortEarly: false }, schemaOptions, { context })
            );
            options.shouldUseNativeValidation &&
                validateFieldsNatively({}, options);
            return {
                values: resolverOptions.rawValues ? values : result,
                errors: {},
            };
        } catch (e) {
            if (!e.inner) throw e;
            return {
                values: {},
                errors: toNestError(
                    parseErrorSchema(
                        e,
                        !options.shouldUseNativeValidation &&
                            options.criteriaMode === 'all'
                    ),
                    options
                ),
            };
        }
    };
