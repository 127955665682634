import { isEmpty } from 'lodash';

import {
    addDate,
    differenceInDaysDate,
    getDaysInYearDate,
} from 'common/utils/dates';

import { SET_HAS_MORE_DAYS_THAN_ALLOWED } from '../actionTypes';
import { formatDateDefault, parseDateDefault } from '../helpers';
import { projectConsumptionModalActions } from '../reducer';

export default ({ getValues, summary }) =>
    (dispatch) => {
        if (
            isEmpty(summary) ||
            !getValues('consumption_profile') ||
            !summary?.[summary?.length - 1]?.initial_date ||
            !summary?.[0]?.final_date
        )
            return summary;

        const _firstInitialDate = summary[summary.length - 1].initial_date;
        const firstInitialDate = parseDateDefault(_firstInitialDate);
        const lastFinalDate = parseDateDefault(summary[0].final_date);

        const difference =
            differenceInDaysDate(lastFinalDate, firstInitialDate) -
            getDaysInYearDate(firstInitialDate);

        const daysToRemove = difference > 0 ? difference : 0;

        if (daysToRemove > 0)
            dispatch(
                projectConsumptionModalActions[SET_HAS_MORE_DAYS_THAN_ALLOWED](
                    true
                )
            );

        const newFirstInitialDate = formatDateDefault(
            addDate(firstInitialDate, { days: daysToRemove })
        );

        const newSummary = summary.map((period, index) => {
            if (index === summary.length - 1)
                return { ...period, initial_date: newFirstInitialDate };
            return {
                ...period,
                final_date:
                    summary[index - 1]?.initial_date || period.final_date,
            };
        });
        return newSummary;
    };
