import { save } from 'common/api/v2/storageOffer';
import { showReponseErrorsAsAlert } from 'common/utils/helpers';

import { SAVE, SAVE_FAILURE, SAVE_SUCCESS } from '../actionTypes';
import { DEFAULT_BACKUP_PERCENTAGE, DAY_TYPES } from '../constants';
import { getDailyAverage, getDecimalPercentageByQuantity } from '../helpers';
import { energyBackupSetupActions } from '../reducer';

import fetchStorageOffer from './fetchStorageOffer';

export default (commercialOfferId, values = {}, complements = {}) =>
    (dispatch) => {
        dispatch(energyBackupSetupActions[SAVE]());

        const {
            base_day_type,
            storage_percent,
            quantity,
            product_branch_office,
        } = values;
        const {
            batteryOffer,
            canModifyTimeShifting,
            diffDays,
            maxDailyConsumption,
            originalConsumption,
        } = complements;

        const _backupPercentage = canModifyTimeShifting
            ? storage_percent
            : DEFAULT_BACKUP_PERCENTAGE;
        const backupPercentage = _backupPercentage / 100 || 0;

        const realQuantity = Math.ceil(quantity) || 0;
        const storageCap = batteryOffer?.[0]?.storage_cap / 1000 || 0;

        let totalDailyConsumption =
            base_day_type === DAY_TYPES.MAX
                ? maxDailyConsumption
                : getDailyAverage({
                      days: diffDays,
                      value: originalConsumption,
                  });

        const storagePercentage = getDecimalPercentageByQuantity({
            quantity: realQuantity,
            unitTotal: storageCap,
            total: totalDailyConsumption,
        });

        const newValues = {
            product_branch_office: product_branch_office,
            quantity: realQuantity,
            storage_percent: backupPercentage,
            base_day_type: values?.base_day_type,
            storage_plan: JSON.stringify({
                daily_cap_batteries: storageCap * realQuantity,
                daily_cap_percentage: storagePercentage * backupPercentage,
                total_backup_days: storagePercentage * backupPercentage,
            }),
        };

        save(commercialOfferId, newValues)
            .then((response) => {
                dispatch(
                    energyBackupSetupActions[SAVE_SUCCESS](response?.data?.data)
                );
                dispatch(fetchStorageOffer(commercialOfferId));
            })
            .catch((error) => {
                dispatch(
                    energyBackupSetupActions[SAVE_FAILURE](
                        error?.response?.data?.errors
                    )
                );
                showReponseErrorsAsAlert(dispatch, error?.response);
            });
    };
