import { getStatus } from 'common/api/v1/burocredito';

import {
    FETCH_DATA,
    FETCH_DATA_FAILURE,
    FETCH_DATA_SUCCESS,
} from '../actionTypes';
import { creditBureauCardActions } from '../reducer';

export default (proposalUUID, onSuccessCallback) => (dispatch) => {
    dispatch(creditBureauCardActions[FETCH_DATA]());

    getStatus(proposalUUID)
        .then((response) => {
            dispatch(
                creditBureauCardActions[FETCH_DATA_SUCCESS](response.data)
            );

            if (onSuccessCallback) onSuccessCallback();
        })
        .catch((error) =>
            dispatch(
                creditBureauCardActions[FETCH_DATA_FAILURE](
                    error?.response?.data?.errors
                )
            )
        );
};
