import { isEmpty } from 'lodash';
import get from 'lodash/get';

import { getMyCompany } from 'common/api/v1/companies';

import { setBranding } from '../../profile/actions';
import { FETCH, FETCH_SUCCESS, FETCH_FAILURE } from '../actionTypes';
import { companyGeneralActions } from '../reducer';

import initializeForm from './initializeForm';

export default () => (dispatch) => {
    dispatch(companyGeneralActions[FETCH]());

    getMyCompany()
        .then((response) => {
            dispatch(companyGeneralActions[FETCH_SUCCESS](response.data));
            dispatch(initializeForm(response.data));
            const { branding } = response.data;
            if (branding && !isEmpty(branding)) dispatch(setBranding(branding));
        })
        .catch((error) =>
            dispatch(
                companyGeneralActions[FETCH_FAILURE](
                    get(error, 'data.errors', [])
                )
            )
        );
};
