import React from 'react';

import SolarPowerIcon from '@mui/icons-material/SolarPower';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box, Card, Typography } from 'sunwise-ui';

import TitleWithDetail from 'common/components/TitleWithDetail';
import { numberFormat } from 'common/utils/helpers';

const Title = styled(Typography)`
    font-size: 16px;
    font-weight: 600;
    line-height: 19px;
    min-height: 19px;
`;

const TempContainer = styled('div')`
    align-items: center;
    border-radius: 8px;
    border: 1px solid #218cff;
    display: flex;
    height: 48px;
    justify-content: center;
    width: calc(50% - 4px);
`;

const TempLabel = styled(Typography)`
    flex-basis: 0;
    flex-grow: 1;
    font-size: 12px;
    line-height: 15px;
    min-height: 15px;
    text-align: center;
`;

const DivDecore = styled('div')`
    background-color: #218cff;
    height: 14px;
    width: 1px;
`;

const Temps = styled('div')`
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-top: 18px;
    width: 100%;
`;

const InstructionLabel = styled(Typography)`
    font-size: 11px;
    line-height: 14px;
    margin-bottom: 17px;
    margin-top: 32px;
    min-height: 14px;
`;

const CardCalculationConditions = ({
    countryCurrencyLocale,
    max = 0,
    min = 0,
}) => {
    const { t } = useTranslation();
    return (
        <Card>
            <Card.Header sx={{ alignItems: 'center', display: 'flex', gap: 2 }}>
                <SolarPowerIcon />
                <Title component="span">{t('Calculation conditions')}</Title>
            </Card.Header>

            <Card.Body>
                <Box display="flex" flexDirection="column">
                    <TitleWithDetail fontSize="16">
                        {t('Temperature')}
                    </TitleWithDetail>
                    <Temps>
                        <TempContainer>
                            <TempLabel component="span">
                                <strong>
                                    {t('Minimum', { context: 'female' })}
                                </strong>
                            </TempLabel>
                            <DivDecore />
                            <TempLabel component="span">
                                {numberFormat(min, {
                                    locale: countryCurrencyLocale,
                                    style: 'decimal',
                                    unit: 'ºC',
                                })}
                            </TempLabel>
                        </TempContainer>
                        <TempContainer>
                            <TempLabel component="span">
                                <strong>
                                    {t('Maximum', { context: 'female' })}
                                </strong>
                            </TempLabel>
                            <DivDecore />
                            <TempLabel component="span">
                                {numberFormat(max, {
                                    locale: countryCurrencyLocale,
                                    style: 'decimal',
                                    unit: 'ºC',
                                })}
                            </TempLabel>
                        </TempContainer>
                    </Temps>
                    <InstructionLabel component="span">
                        {t(
                            'This information is a reference of the configuration in solar generation'
                        )}
                    </InstructionLabel>
                </Box>
            </Card.Body>
        </Card>
    );
};

CardCalculationConditions.propTypes = {
    countryCurrencyLocale: PropTypes.string,
    max: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    min: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default CardCalculationConditions;
