export const getDailyAverage = ({ days, value }) => {
    if (!value || !days) return 0;
    return value / days;
};

export const getQuantityByPercentage = ({ percentage, total, unitTotal }) => {
    return (percentage * total) / unitTotal;
};

export const getPercentageByStorageSize = ({ storageSize, total }) => {
    return 100 * (storageSize / total);
};

export const getDecimalPercentageByQuantity = ({
    quantity,
    total,
    unitTotal,
}) => {
    return (quantity * unitTotal) / total;
};
