import React, { useEffect, useState } from 'react';

import { styled, useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';
import Chart from 'react-apexcharts';
import { Box, Skeleton } from 'sunwise-ui';

import { useBreakpoint } from 'common/hooks';
import { scroll } from 'common/utils/mixins';

import EmptyChart from './EmptyChart';
import KeyIndicators from './KeyIndicators';
import Table from './Table';

const ChartWrapper = styled(Box)`
    height: 320px;
    margin-bottom: 16px;
    overflow-x: scroll;
    overflow-y: hidden;
    width: 100%;

    ${scroll.custom()};
`;

const hasData = (series) => series?.some((serie) => serie.data.length);

const ChartComponent = ({
    chartConfig,
    chartType,
    countryCurrencyIso,
    countryCurrencyLocale,
    countryCurrencySymbol,
    emptyDescription,
    formConfig,
    isCurrency,
    isLoading,
    showIndicators = true,
    tableDateLabelDefault,
    unit,
}) => {
    const [chartWidth, setChartWidth] = useState(900);
    const breakpoint = useBreakpoint();
    const theme = useTheme();

    useEffect(() => {
        switch (breakpoint) {
            case 'xs':
                setChartWidth(600);
                break;
            case 'sm':
                setChartWidth(800);
                break;
            default:
                setChartWidth('98%');
                break;
        }
    }, [breakpoint]);

    if (isLoading) return <Skeleton height={500} variant="rounded" />;
    if (!hasData(chartConfig?.series))
        return <EmptyChart description={emptyDescription} />;

    return (
        <>
            {chartType === 'table' ? (
                <Table
                    categories={chartConfig.categories}
                    countryCurrencyLocale={countryCurrencyLocale}
                    series={chartConfig.series}
                    viewOption={
                        tableDateLabelDefault ? null : formConfig?.view_option
                    }
                />
            ) : (
                <ChartWrapper>
                    <Chart
                        height={300}
                        key={chartConfig.key}
                        options={{
                            theme: { mode: theme.palette.mode },
                            ...chartConfig.options,
                        }}
                        series={chartConfig.series}
                        type={chartConfig.type}
                        width={chartWidth}
                    />
                </ChartWrapper>
            )}

            {showIndicators && (
                <KeyIndicators
                    countryCurrencyIso={countryCurrencyIso}
                    countryCurrencyLocale={countryCurrencyLocale}
                    countryCurrencySymbol={countryCurrencySymbol}
                    isCurrency={isCurrency}
                    series={chartConfig.series}
                    unit={unit}
                    viewOption={formConfig?.view_option}
                />
            )}
        </>
    );
};

ChartComponent.propTypes = {
    chartConfig: PropTypes.object,
    chartType: PropTypes.string,
    countryCurrencyIso: PropTypes.string,
    countryCurrencyLocale: PropTypes.string,
    countryCurrencySymbol: PropTypes.string,
    emptyDescription: PropTypes.string,
    formConfig: PropTypes.object,
    isCurrency: PropTypes.bool,
    isLoading: PropTypes.bool,
    showIndicators: PropTypes.bool,
    tableDateLabelDefault: PropTypes.bool,
    unit: PropTypes.string,
};

export default ChartComponent;
