import i18next from 'i18next';

import { updateSmartDocument } from 'common/api/v1/smartDocuments';
import { showReponseErrorsAsAlert } from 'common/utils/helpers';
import showToast from 'common/utils/showToast';

import {
    RENAME_OFFER_SMART_DOCUMENT,
    RENAME_OFFER_SMART_DOCUMENT_FAILURE,
    RENAME_OFFER_SMART_DOCUMENT_SUCCESS,
} from '../../actionTypes';
import { multiTemplateReviewActions } from '../../reducer';

import fetchOfferSmartDocuments from './fetchOfferSmartDocuments';
import openOfferSmartDocumentModal from './openOfferSmartDocumentModal';
export default (proposalId, values) => (dispatch) => {
    dispatch(multiTemplateReviewActions[RENAME_OFFER_SMART_DOCUMENT]());

    updateSmartDocument(values.id, {
        name: values.name,
    })
        .then(() => {
            dispatch(
                multiTemplateReviewActions[RENAME_OFFER_SMART_DOCUMENT_SUCCESS](
                    values
                )
            );
            dispatch(fetchOfferSmartDocuments(proposalId));
            dispatch(openOfferSmartDocumentModal(false));
            showToast({ body: i18next.t('Successfully saved') });
        })
        .catch((error) => {
            dispatch(
                multiTemplateReviewActions[RENAME_OFFER_SMART_DOCUMENT_FAILURE](
                    error?.response?.data?.errors
                )
            );
            showReponseErrorsAsAlert(dispatch, error?.response);
        });
};
