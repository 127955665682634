import { createSelector } from 'reselect';

import { NAME } from './constants';

export const getModel = (state) => state[NAME];

export const getOfferInfoPatern = createSelector(
    getModel,
    (model) => model.offerInfoPatern
);

export const getIsFetchingOfferInfoPatern = createSelector(
    getOfferInfoPatern,
    (value) => value.isFetching
);

export const getDataOfferrInfoPatern = createSelector(
    getOfferInfoPatern,
    (value) => value.data
);
