import i18next from 'i18next';
import { createSelector } from 'reselect';

import { NAME } from './constants';
export const getModel = (state) => state[NAME];

export const getInitialValues = createSelector(
    getModel,
    (model) => model.initialValues
);

export const getSave = createSelector(getModel, (model) => model.save);

export const getIsSaving = createSelector(getSave, (save) => save.isSaving);

export const getDataSave = createSelector(getSave, (save) => save.data);

export const getErrorsSave = createSelector(getSave, (save) => save.errors);

/**
 * Languages
 */

export const getLanguageModel = createSelector(
    getModel,
    (model) => model.languages
);

export const getLanguagesData = createSelector(
    getLanguageModel,
    (model) => model.data || []
);

export const getLanguagesOptions = createSelector(
    getLanguagesData,
    (languagesData) => [
        {
            label: i18next.t('Select a language'),
            value: '',
            disabled: true,
        },
        ...languagesData.map((item) => ({
            label: item.language,
            value: item.key,
        })),
    ]
);

export const getIsFetchingLanguages = createSelector(
    getLanguageModel,
    (model) => model.isFetching
);

/**
 * Timezones
 */
export const getTimezoneModel = createSelector(
    getModel,
    (model) => model.timezones
);

export const getTimezonesData = createSelector(
    getTimezoneModel,
    (model) => model.data || []
);

export const getTimezonesOptions = createSelector(
    getTimezonesData,
    (fetchTimezones) => {
        let items = fetchTimezones.map((item) => ({
            label: item.name,
            value: item.key,
        }));

        items.unshift({
            label: i18next.t('Select a time zone'),
            value: '',
            disabled: true,
        });

        return items;
    }
);

export const getIsFetchingTimezones = createSelector(
    getTimezoneModel,
    (model) => model.isFetching
);
