import { array, number, object, string } from 'yup';

import getValidationTexts from 'common/utils/getValidationTexts';
import { emailRegex } from 'common/utils/helpers';

import { ONBOARDING_STEPS } from './constants';

export default (props) => {
    const {
        getMaxValueText,
        EMAIL_VALIDATION_TEXT,
        NUMBER_TYPE_TEXT,
        REQUIRED_TEXT,
    } = getValidationTexts();
    return object().shape({
        name: string().when('step', {
            is: ONBOARDING_STEPS.COMPANY,
            then: () => string().required(REQUIRED_TEXT).nullable(),
            otherwise: () => string().nullable(),
        }),
        business_name: string().when('step', {
            is: ONBOARDING_STEPS.COMPANY,
            then: () => string().required(REQUIRED_TEXT).nullable(),
            otherwise: () => string().nullable(),
        }),
        rfc: string().when('step', {
            is: ONBOARDING_STEPS.COMPANY,
            then: () => string().required(REQUIRED_TEXT).nullable(),
            otherwise: () => string().nullable(),
        }),
        contact_email: string().when('step', {
            is: ONBOARDING_STEPS.COMPANY,
            then: () =>
                string()
                    .required(REQUIRED_TEXT)
                    .matches(emailRegex, EMAIL_VALIDATION_TEXT)
                    .nullable(),
            otherwise: () => string().nullable(),
        }),
        contact_telephone: string().when('step', {
            is: ONBOARDING_STEPS.COMPANY,
            then: () => string().required(REQUIRED_TEXT).nullable(),
            otherwise: () => string().nullable(),
        }),
        dollar_price: number().when('step', {
            is: ONBOARDING_STEPS.DETAILS,
            then: () =>
                number()
                    .typeError(NUMBER_TYPE_TEXT)
                    .required(REQUIRED_TEXT)
                    .nullable(),
            otherwise: () => number().nullable(),
        }),
        taxes: array().of(
            object().shape({
                tax_name: string().when('step', (value, schema) => {
                    if (props.step === ONBOARDING_STEPS.MEMBERS) {
                        return string().required(REQUIRED_TEXT).nullable();
                    }
                    return schema;
                }),
                tax_value: number().when('step', (value, schema) => {
                    if (props.step === ONBOARDING_STEPS.MEMBERS) {
                        return number()
                            .typeError(NUMBER_TYPE_TEXT)
                            .required(REQUIRED_TEXT)
                            .nullable();
                    }
                    return schema;
                }),
            })
        ),
        members: array().of(
            object().shape({
                first_name: string().when('step', (value, schema) => {
                    if (props.step === ONBOARDING_STEPS.MEMBERS) {
                        return string().required(REQUIRED_TEXT).nullable();
                    }
                    return schema;
                }),
                last_name: string().when('step', (value, schema) => {
                    if (props.step === ONBOARDING_STEPS.MEMBERS) {
                        return string().required(REQUIRED_TEXT).nullable();
                    }
                    return schema;
                }),
                email: string().when('step', (value, schema) => {
                    if (props.step === ONBOARDING_STEPS.MEMBERS) {
                        return string()
                            .required(REQUIRED_TEXT)
                            .matches(emailRegex, EMAIL_VALIDATION_TEXT)
                            .nullable();
                    }
                    return schema;
                }),
                company_group: string().when('step', (value, schema) => {
                    if (props.step === ONBOARDING_STEPS.MEMBERS) {
                        return string().required(REQUIRED_TEXT).nullable();
                    }
                    return schema;
                }),
            })
        ),
        panel_brand: string().when('step', {
            is: ONBOARDING_STEPS.PANELS,
            then: () => string().required(REQUIRED_TEXT).nullable(),
            otherwise: () => string().nullable(),
        }),
        panels: array().of(
            object().shape({
                name: string().when('step', (value, schema) => {
                    if (props.step === ONBOARDING_STEPS.PANELS) {
                        return string().required(REQUIRED_TEXT).nullable();
                    }
                    return schema;
                }),
                cost: number().when('step', (value, schema) => {
                    if (props.step === ONBOARDING_STEPS.PANELS) {
                        return number()
                            .typeError(NUMBER_TYPE_TEXT)
                            .required(REQUIRED_TEXT)
                            .nullable();
                    }
                    return schema;
                }),
                margin: number().when('step', (value, schema) => {
                    if (props.step === ONBOARDING_STEPS.PANELS) {
                        return number()
                            .typeError(NUMBER_TYPE_TEXT)
                            .max(99.99, getMaxValueText(99.99))
                            .required(REQUIRED_TEXT)
                            .nullable();
                    }
                    return schema;
                }),
            })
        ),
        inverter_brand: string().when('step', {
            is: ONBOARDING_STEPS.INVERTERS,
            then: () => string().required(REQUIRED_TEXT).nullable(),
            otherwise: () => string().nullable(),
        }),
        inverters: array().of(
            object().shape({
                name: string().when('step', (value, schema) => {
                    if (props.step === ONBOARDING_STEPS.INVERTERS) {
                        return string().required(REQUIRED_TEXT).nullable();
                    }
                    return schema;
                }),
                cost: number().when('step', (value, schema) => {
                    if (props.step === ONBOARDING_STEPS.INVERTERS) {
                        return number()
                            .typeError(NUMBER_TYPE_TEXT)
                            .required(REQUIRED_TEXT)
                            .nullable();
                    }
                    return schema;
                }),
                margin: number().when('step', (value, schema) => {
                    if (props.step === ONBOARDING_STEPS.INVERTERS) {
                        return number()
                            .typeError(NUMBER_TYPE_TEXT)
                            .max(99.99, getMaxValueText(99.99))
                            .required(REQUIRED_TEXT)
                            .nullable();
                    }
                    return schema;
                }),
            })
        ),
        batteries: array().of(
            object().shape({
                name: string().when('step', (value, schema) => {
                    if (props.step === ONBOARDING_STEPS.BATTERIES) {
                        return string().required(REQUIRED_TEXT).nullable();
                    }
                    return schema;
                }),
                cost: number().when('step', (value, schema) => {
                    if (props.step === ONBOARDING_STEPS.BATTERIES) {
                        return number()
                            .typeError(NUMBER_TYPE_TEXT)
                            .required(REQUIRED_TEXT)
                            .nullable();
                    }
                    return schema;
                }),
                margin: number().when('step', (value, schema) => {
                    if (props.step === ONBOARDING_STEPS.BATTERIES) {
                        return number()
                            .typeError(NUMBER_TYPE_TEXT)
                            .max(99.99, getMaxValueText(99.99))
                            .required(REQUIRED_TEXT)
                            .nullable();
                    }
                    return schema;
                }),
            })
        ),
    });
};
