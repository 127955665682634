import { getTimezones } from 'common/api/v1/international';

import {
    FETCH_TIMEZONES,
    FETCH_TIMEZONES_FAILURE,
    FETCH_TIMEZONES_SUCCESS,
} from '../actionTypes';
import { profileConfigurationActions } from '../reducer';

export default () => (dispatch) => {
    dispatch(profileConfigurationActions[FETCH_TIMEZONES]());

    getTimezones()
        .then((response) =>
            dispatch(
                profileConfigurationActions[FETCH_TIMEZONES_SUCCESS](
                    response.data.data
                )
            )
        )
        .catch((error) =>
            dispatch(
                profileConfigurationActions[FETCH_TIMEZONES_FAILURE](
                    error?.response?.data?.errors
                )
            )
        );
};
