import { createSelector } from 'reselect';

import { NAME } from './constants';

export const getModel = (state) => state[NAME];

/** Report details */
export const getReportDetails = createSelector(
    getModel,
    (model) => model.reportDetails
);

export const getIsFetchingReportDetails = createSelector(
    getReportDetails,
    (value) => value.isFetching
);

export const getReportDetailsData = createSelector(
    getReportDetails,
    (value) => value.data
);
