import { getProjects } from 'common/api/v2/projectsByList';

import {
    FETCH_PROJECTS_INFO,
    FETCH_PROJECTS_INFO_FAILURE,
    FETCH_PROJECTS_INFO_SUCCESS,
} from '../actionTypes';
import { actions } from '../reducer';
import * as selectors from '../selectors';

export default (projects) => (dispatch, getState) => {
    const state = getState();

    const isFetching = selectors.getProjectsInfoLoading(state);

    if (!projects?.length || isFetching) return;
    dispatch(actions[FETCH_PROJECTS_INFO](projects));

    getProjects(projects)
        .then((response) =>
            dispatch(actions[FETCH_PROJECTS_INFO_SUCCESS](response?.data?.data))
        )
        .catch(() => dispatch(actions[FETCH_PROJECTS_INFO_FAILURE]()));
};
