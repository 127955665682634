import React from 'react';

import { debounce, toNumber } from 'lodash';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import csvImporter from 'common/modules/csvImporter';

import { CSV_SOURCES } from '../constants';
import {
    buildGenerationCsv,
    getHasFile,
    getSunHoursWithFile,
    handleOnChangeValue,
} from '../helpers';

import GenerationFromFile from './GenerationFromFile';
import ManualGeneration from './ManualGeneration';

const Generation = ({
    anualSolarGeneration,
    averageIrradiation,
    canModifySolarGeneration,
    canModifySolarGenerationFile,
    configCsvImporter,
    control,
    getValues,
    handleSubmit,
    initialValues,
    isLocked,
    kwhValue,
    prepareDeleteFile,
    proposalId,
    setConfigCsvImporter,
    setValue,
    sunHours,
    systemSize,
}) => {
    const { t } = useTranslation();
    const fileInputRef = React.useRef(null);
    const handleOnChange = React.useRef(
        debounce(handleOnChangeValue, 1000)
    ).current;

    const sunHoursWithFile = getSunHoursWithFile(
        anualSolarGeneration,
        systemSize
    );

    const hasFile = getHasFile(initialValues);

    return (
        <>
            <ManualGeneration
                averageIrradiation={averageIrradiation}
                canModifySolarGeneration={canModifySolarGeneration}
                control={control}
                handleOnChange={handleOnChange}
                handleSubmit={handleSubmit}
                hasFile={hasFile}
                initialValues={initialValues}
                isLocked={isLocked}
                kwhValue={kwhValue}
                setValue={setValue}
                sunHours={sunHours}
                sunHoursWithFile={toNumber(sunHoursWithFile)}
            />

            <GenerationFromFile
                canModifySolarGenerationFile={canModifySolarGenerationFile}
                control={control}
                fileInputRef={fileInputRef}
                handleOnChange={(value) => {
                    const source = getValues('source');

                    if (source == CSV_SOURCES.CUSTOM)
                        setConfigCsvImporter({ file: value[0], isOpen: true });
                    else
                        handleOnChange({
                            setValue,
                            field: 'file',
                            value,
                            handleSubmit,
                        });
                }}
                handleOnClickDeleteFile={() =>
                    prepareDeleteFile(proposalId, getValues())
                }
                hasFile={hasFile}
                initialValues={initialValues}
                isLocked={isLocked}
            />

            {canModifySolarGenerationFile &&
                !isLocked &&
                configCsvImporter?.isOpen && (
                    <csvImporter.Container
                        columns={[
                            {
                                columnName: 'date',
                                displayLabel: t('Date'),
                                matchAliases: ['date', 'fecha', 'timestamp'],
                                required: false,
                                shouldFormat: false,
                                type: csvImporter.COLUMN_TYPES.DATE,
                            },
                            {
                                columnName: 'time',
                                displayLabel: t('Time'),
                                shouldFormat: false,
                                type: csvImporter.COLUMN_TYPES.TIME,
                                visible: (_, columnsFormat) =>
                                    columnsFormat?.date &&
                                    !columnsFormat.date.includes('HH'),
                            },
                            {
                                columnName: 'generation',
                                displayLabel: t('Generation') + ' (W)',
                                matchAliases: [
                                    'energia',
                                    'energy',
                                    'generacion',
                                    'generation',
                                    'irradiation',
                                    'radiacion',
                                    'solar',
                                    'watts',
                                ],
                                required: true,
                                shouldFormat: false,
                                type: csvImporter.COLUMN_TYPES.NUMBER,
                                matchPriority: 1,
                            },
                        ]}
                        initialFile={configCsvImporter?.file}
                        onCancel={() => {
                            setConfigCsvImporter({ file: null, isOpen: false });
                            setValue('file', null);

                            if (fileInputRef.current)
                                fileInputRef.current.value = '';
                        }}
                        onImport={(result, config) => {
                            const newFile = buildGenerationCsv(result, config);

                            setValue('file', [newFile]);

                            setValue('csv_config', {
                                columnsMatch: {
                                    date: 'date',
                                    generation: 'generation',
                                },
                                columnsFormat: {
                                    date: 'dd/MM/yyyy HH:mm',
                                    generation: '.',
                                },
                            });

                            handleSubmit();
                            setConfigCsvImporter({ file: null, isOpen: false });
                        }}
                        openModal={configCsvImporter?.isOpen}
                        returnFormattedData
                        visible={false}
                    />
                )}
        </>
    );
};

Generation.propTypes = {
    anualSolarGeneration: PropTypes.number,
    averageIrradiation: PropTypes.number,
    canModifySolarGeneration: PropTypes.bool,
    canModifySolarGenerationFile: PropTypes.bool,
    configCsvImporter: PropTypes.object,
    control: PropTypes.object,
    getValues: PropTypes.func,
    handleSubmit: PropTypes.func,
    initialValues: PropTypes.object,
    isLocked: PropTypes.bool,
    kwhValue: PropTypes.number,
    prepareDeleteFile: PropTypes.func,
    proposalId: PropTypes.string,
    setConfigCsvImporter: PropTypes.func,
    setValue: PropTypes.func,
    sunHours: PropTypes.number,
    systemSize: PropTypes.number,
};

export default Generation;
