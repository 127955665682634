import i18next from 'i18next';

import { deleteOfferCustomTemplate } from 'common/api/v1/offerCustomTemplate';
import alerts from 'common/modules/alerts';
import { showReponseErrorsAsAlert } from 'common/utils/helpers';
import showToast from 'common/utils/showToast';

import {
    DELETE_ITEM,
    DELETE_ITEM_FAILURE,
    DELETE_ITEM_SUCCESS,
} from '../actionTypes';
import { templateLibraryActions } from '../reducer';

import filterTemplates from './filterTemplates';

export default (filterData, id) => (dispatch) => {
    dispatch(templateLibraryActions[DELETE_ITEM]());

    deleteOfferCustomTemplate(id)
        .then(() => {
            dispatch(templateLibraryActions[DELETE_ITEM_SUCCESS]());
            dispatch(alerts.actions.close());
            dispatch(filterTemplates(filterData));
            showToast({ body: i18next.t('Successfully deleted') });
        })
        .catch((error) => {
            dispatch(
                templateLibraryActions[DELETE_ITEM_FAILURE](
                    error?.response?.data?.errors
                )
            );
            showReponseErrorsAsAlert(dispatch, error?.response);
        });
};
