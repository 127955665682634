import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
    Card,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
} from 'sunwise-ui';

import { InputSearch } from 'common/components';
import { getAgentOptions } from 'common/utils/helpers';

const ContactsFilter = ({ agents, filterData, handleOnChangeFilter }) => {
    const { t } = useTranslation();

    const handleOnChangeSearch = (e) =>
        handleOnChangeFilter('searchText', e.target.value);

    const handleOnSelectAgent = (e) =>
        handleOnChangeFilter('agentId', e.target.value);

    return (
        <Card>
            <Card.Body>
                <Grid container alignItems="end">
                    <Grid item md={14} xs={18}>
                        <InputSearch
                            onChange={handleOnChangeSearch}
                            placeholder={t('Search contact')}
                            value={filterData?.searchText}
                        />
                    </Grid>

                    <Grid item md={4} xs={18}>
                        <FormControl fullWidth size="small">
                            <InputLabel>{t('User')}</InputLabel>
                            <Select
                                label={t('User')}
                                onChange={handleOnSelectAgent}
                                value={filterData?.agentId}
                                variant="standard"
                            >
                                {getAgentOptions(agents).map(
                                    ({ label, value, disabled }) => (
                                        <MenuItem
                                            disabled={disabled}
                                            key={`agent-select-${value}-${label}`}
                                            value={value}
                                        >
                                            {label}
                                        </MenuItem>
                                    )
                                )}
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
            </Card.Body>
        </Card>
    );
};

ContactsFilter.propTypes = {
    agents: PropTypes.array,
    filterData: PropTypes.object,
    handleOnChangeFilter: PropTypes.func,
};

export default ContactsFilter;
