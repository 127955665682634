import React, { useState } from 'react';

import AddIcon from '@mui/icons-material/Add';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Box, Button, Card, ToggleButton, ToggleButtonGroup } from 'sunwise-ui';

import * as actions from '../actions';
import { SOURCES } from '../constants';
import * as selectors from '../selectors';

import ApiSourceList from './ApiSourceList';
import EmptyState from './EmptyState';
import ManualSourceList from './ManualSourceList';

const CardContent = ({
    canModify,
    canDelete,
    externalSources,
    isSaving,
    powerStations,
    powerStationsDataId,
    prepareAddApiSource,
    selectedProjectId,
    setExternalSourceFormModalOpenStatus,
}) => {
    const [selectedSource, setSelectedSource] = useState(
        SOURCES.REGISTERED_PROVIDER
    );
    const { t } = useTranslation();

    const addAction = () => {
        if (selectedSource === SOURCES.REGISTERED_PROVIDER)
            prepareAddApiSource(selectedProjectId, powerStationsDataId);
        else setExternalSourceFormModalOpenStatus(true);
    };

    const disabled = isSaving;
    const addButton = (
        <Button
            disabled={disabled}
            endIcon={<AddIcon />}
            onClick={addAction}
            variant="outlined"
        >
            {t('Add')}
        </Button>
    );

    const hasPowerStations =
        powerStations?.length > 0 || externalSources?.length > 0;

    return (
        <Card.Body>
            <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                mb={1}
            >
                <ToggleButtonGroup
                    color="primary"
                    exclusive
                    onChange={(_, value) =>
                        value !== null && setSelectedSource(value)
                    }
                    size="small"
                    value={selectedSource}
                >
                    <ToggleButton
                        value={SOURCES.REGISTERED_PROVIDER}
                        sx={{ px: 2 }}
                    >
                        API
                    </ToggleButton>

                    <ToggleButton value={SOURCES.EXTERNAL} sx={{ px: 2 }}>
                        {t('External')}
                    </ToggleButton>
                </ToggleButtonGroup>

                {canModify && hasPowerStations && addButton}
            </Box>
            {hasPowerStations ? (
                <>
                    {selectedSource === SOURCES.REGISTERED_PROVIDER && (
                        <ApiSourceList
                            canDelete={canDelete}
                            disabled={disabled}
                            powerStations={powerStations}
                            selectedProjectId={selectedProjectId}
                        />
                    )}

                    {selectedSource === SOURCES.EXTERNAL && (
                        <ManualSourceList
                            canDelete={canDelete}
                            disabled={disabled}
                            externalSources={externalSources}
                            selectedProjectId={selectedProjectId}
                        />
                    )}
                </>
            ) : (
                <EmptyState addButton={addButton} canModify={canModify} />
            )}
        </Card.Body>
    );
};

CardContent.propTypes = {
    canModify: PropTypes.bool,
    canDelete: PropTypes.bool,
    externalSources: PropTypes.array,
    isSaving: PropTypes.bool,
    powerStations: PropTypes.array,
    powerStationsDataId: PropTypes.string,
    prepareAddApiSource: PropTypes.func,
    selectedProjectId: PropTypes.string,
    setExternalSourceFormModalOpenStatus: PropTypes.func,
};

const mapStateToProps = createStructuredSelector({
    isSaving: selectors.getIsSavingData,
    powerStationsDataId: selectors.getPowerStationsDataId,
});

const mapDispatchToProps = (dispatch) => ({
    prepareAddApiSource: (projectId, powerStationsDataId) =>
        dispatch(actions.prepareAddApiSource(projectId, powerStationsDataId)),
    setExternalSourceFormModalOpenStatus: (value) =>
        dispatch(actions.setExternalSourceFormModalOpenStatus(value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CardContent);
