import { get, isNull } from 'lodash';

import { getAuthMe } from 'common/api/v1/auth';
import { updateSession } from 'common/utils/helpers/session';

import { FETCH, FETCH_FAILURE, FETCH_SUCCESS } from '../actionTypes';
import { profileActions } from '../reducer';

import initializeForm from './initializeForm';
import initializeMultiregion from './initializeMultiregion';
import initializeSecurityPermissions from './initializeSecurityPermissions';
import setCurrentValues from './setCurrentValues';

export default () => (dispatch) => {
    dispatch(profileActions[FETCH]());

    getAuthMe()
        .then((response) => {
            const {
                has_sunwise_update_notification = false,
                onboarding,
                permissions,
            } = response.data;
            const groups = get(permissions, 'groups', []);
            updateSession({
                hasOnboarding: !isNull(onboarding),
                has_sunwise_update_notification,
                settings: response?.data?.settings,
            });
            localStorage.setItem('permissions', JSON.stringify(groups));
            dispatch(profileActions[FETCH_SUCCESS](response.data));
            dispatch(initializeForm(response.data));
            dispatch(initializeMultiregion(response.data));
            dispatch(initializeSecurityPermissions(response.data));
            dispatch(
                setCurrentValues({
                    first_name: response?.data?.first_name,
                    last_name: response?.data?.last_name,
                    profile_image: response?.data?.profile_image,
                })
            );
        })
        .catch((error) =>
            dispatch(
                profileActions[FETCH_FAILURE](error?.response?.data?.errors)
            )
        );
};
