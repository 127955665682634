import API from '../..';
const ENTITY = 'proposal-custom-indicators';

export const getProposalIndicatorsSettings = () =>
    API.get(`/api/v1/${ENTITY}/`);
export const saveProposalIndicatorsSettings = (data) =>
    API.put(
        `/api/v1/${ENTITY}/`,
        { ...data },
        { headers: { 'Content-Type': 'application/json' } }
    );
