import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { createStructuredSelector } from 'reselect';
import { Box, Card } from 'sunwise-ui';

import { PERMISSION_LIST } from 'common/constants/permissionsV2';
import withPermissions from 'common/hocs/withPermissions';
import useBreakpoint from 'common/hooks/useBreakpoint';

import afterSalesMonitoring from '../afterSalesMonitoring';
import afterSalesSettings from '../afterSalesSettings';
import AccessRestrictedBanner from '../afterSalesSettings/components/warnings/AccessRestrictedBanner';
import * as afterSalesSettingsSelectors from '../afterSalesSettings/selectors';

import Settings from './components/Settings';

const Container = ({
    afterSalesSettingsDataId,
    canDelete,
    canModify,
    canView,
    fetchAfterSalesSettings,
    selectedProjectId,
}) => {
    const [height, setHeight] = useState(3050);
    const breakpoint = useBreakpoint();

    useEffect(() => {
        fetchAfterSalesSettings(selectedProjectId);
    }, [selectedProjectId]);

    useEffect(() => {
        switch (breakpoint) {
            case 'xs':
                setHeight(4390);
                break;
            case 'sm':
                setHeight(3700);
                break;
            case 'md':
                setHeight(2650);
                break;
            default:
                setHeight(3050);
                break;
        }
    }, [breakpoint]);

    if (!canView) return null;

    if (!afterSalesSettingsDataId)
        return (
            <AccessRestrictedBanner minHeight={height}>
                <afterSalesSettings.Container
                    canModify={canModify}
                    selectedProjectId={selectedProjectId}
                />
            </AccessRestrictedBanner>
        );

    return (
        <AccessRestrictedBanner minHeight={height}>
            <Card>
                <Card.Body>
                    <Settings
                        canDelete={canDelete}
                        canModify={canModify}
                        selectedProjectId={selectedProjectId}
                    />

                    <Box mt={2}>
                        <afterSalesMonitoring.Container
                            selectedProjectId={selectedProjectId}
                        />
                    </Box>
                </Card.Body>
            </Card>
        </AccessRestrictedBanner>
    );
};

const mapStateToProps = createStructuredSelector({
    afterSalesSettingsDataId:
        afterSalesSettingsSelectors.getAfterSalesSettingsDataId,
});

const mapDispatchToProps = (dispatch) => ({
    fetchAfterSalesSettings: (projectId) =>
        dispatch(afterSalesSettings.actions.fetchAfterSalesSettings(projectId)),
});

Container.propTypes = {
    afterSalesSettingsDataId: PropTypes.string,
    canDelete: PropTypes.bool,
    canModify: PropTypes.bool,
    canView: PropTypes.bool,
    fetchAfterSalesSettings: PropTypes.func,
    selectedProjectId: PropTypes.string,
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withPermissions(PERMISSION_LIST.MONITORING_PERMISSION)
)(Container);
