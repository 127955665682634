import { createSelector } from 'reselect';

import { NAME } from './constants';

export const getModel = (state) => state[NAME];

// BILLING INFORMATION
export const getBillingInformation = createSelector(
    getModel,
    (model) => model.billingInformation
);

export const getIsFetchingBillingInformation = createSelector(
    getBillingInformation,
    (value) => value.isFetching
);

export const getBillingInformationData = createSelector(
    getBillingInformation,
    (value) => value.data || {}
);

export const getBillingInformationErrors = createSelector(
    getBillingInformation,
    (value) => value.errors
);

// PAY INVOICES
export const getPayInvoices = createSelector(
    getModel,
    (model) => model.payInvoices
);

export const getIsFetchingPayInvoices = createSelector(
    getPayInvoices,
    (value) => value.isFetching
);

export const getPayInvoicesData = createSelector(
    getPayInvoices,
    (value) => value.data || {}
);

export const getPayInvoicesErrors = createSelector(
    getPayInvoices,
    (value) => value.errors
);

// PAYMENT SOURCES
export const getPaymentSources = createSelector(
    getModel,
    (model) => model.paymentSources
);

export const getIsFetchingPaymentSources = createSelector(
    getPaymentSources,
    (value) => value.isFetching
);

export const getPaymentSourcesData = createSelector(
    getPaymentSources,
    (value) => value.data || {}
);

export const getPaymentSourcesErrors = createSelector(
    getPaymentSources,
    (value) => value.errors
);

// SUBSCRIPTION
export const getSubscription = createSelector(
    getModel,
    (model) => model.subscription
);

export const getIsFetchingSubscription = createSelector(
    getSubscription,
    (value) => value.isFetching
);

export const getSubscriptionData = createSelector(
    getSubscription,
    (value) => value.data || {}
);

export const getSubscriptionErrors = createSelector(
    getSubscription,
    (value) => value.errors
);

// SUBSCRIPTION SOCKET
export const getSocketMessage = createSelector(
    getModel,
    (model) => model.socketMessage
);

export const getSocketHandler = createSelector(
    getSocketMessage,
    (message) => message.handler
);
