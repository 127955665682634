import React from 'react';

import InfoIcon from '@mui/icons-material/Info';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Box, Button, Grid, IconButton, Typography } from 'sunwise-ui';

import DocumentInfoList from 'common/components/DocumentInfoList';
import {
    ReactHookFormDragnDropFile,
    ReactHookFormInput,
    ReactHookFormSelect,
} from 'common/components/form/bootstrap';
import ShowErrors from 'common/components/ShowErrors';
import { FILE_ALLOWED_EXTENSIONS } from 'common/constants';
import yupResolver from 'common/utils/yupResolver';

import validate from './validate';

const StyledTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
        color: theme.palette.background.paper,
    },
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.background.paper,
        color: theme.palette.mode === 'dark' ? '#fff' : null,
    },
}));

const DocumentFormContainer = ({
    documentsTypeToSelect,
    errors,
    handleSubmitForm,
    handleClickCancel,
    initialValues,
    isUploading,
}) => {
    const { t } = useTranslation();

    const { control, handleSubmit, setValue } = useForm({
        defaultValues: initialValues,
        resolver: yupResolver(validate),
    });

    return (
        <form onSubmit={handleSubmit(handleSubmitForm)}>
            <Grid container>
                <Grid item xs={18} md={9}>
                    <ReactHookFormSelect
                        control={control}
                        name="type_document"
                        label={t('Select type of document')}
                        options={documentsTypeToSelect}
                    />
                </Grid>
                <Grid item xs={18} md={9}>
                    <Box display="flex" alignItems="center" gap={1}>
                        <ReactHookFormInput
                            control={control}
                            maxLength={60}
                            name="value_user"
                            label={t('Enter value')}
                            placeholder={t('Enter value')}
                        />
                        <StyledTooltip
                            placement="left-start"
                            title={<DocumentInfoList />}
                        >
                            <IconButton sx={{ mb: 2 }}>
                                <InfoIcon />
                            </IconButton>
                        </StyledTooltip>
                    </Box>
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={18}>
                    <Typography variant="body2" mb={1}>{`${t(
                        'Attach file'
                    )} (${t('Optional')})`}</Typography>
                    <ReactHookFormDragnDropFile
                        accept={FILE_ALLOWED_EXTENSIONS.PDF.concat(
                            ',',
                            FILE_ALLOWED_EXTENSIONS.IMAGE
                        )}
                        control={control}
                        formats="pdf, jpg, jpeg, png"
                        name="file"
                        setValue={setValue}
                    />
                </Grid>
            </Grid>

            <ShowErrors errors={errors} />

            <Grid container mt={1}>
                <Grid
                    item
                    xs
                    sx={{
                        display: 'flex',
                        flexDirection: { xs: 'column', md: 'row' },
                        justifyContent: { xs: 'center', md: 'right' },
                    }}
                >
                    <Button
                        color="secondary"
                        onClick={handleClickCancel}
                        variant="text"
                        sx={{
                            width: { xs: '100%', md: 'auto' },
                            order: { xs: 2, md: 1 },
                            mr: { xs: 0, md: 2 },
                        }}
                    >
                        {t('Cancel')}
                    </Button>
                    <Button
                        color="secondary"
                        disabled={isUploading}
                        sx={{
                            width: { xs: '100%', md: 'auto' },
                            order: { xs: 1, md: 2 },
                            mb: { xs: 2, md: 0 },
                        }}
                        type="submit"
                        variant="outlined"
                    >
                        {t('Save')}
                    </Button>
                </Grid>
            </Grid>
        </form>
    );
};

DocumentFormContainer.propTypes = {
    documentsTypeToSelect: PropTypes.array,
    errors: PropTypes.array,
    handleClickCancel: PropTypes.func,
    handleSubmitForm: PropTypes.func,
    initialValues: PropTypes.object,
    isUploading: PropTypes.bool,
};

export default DocumentFormContainer;
