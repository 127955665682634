import React, { useEffect } from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { TitleWithDetail } from 'common/components';
import AccordionCard from 'common/components/accordions/AccordionCard';

import * as actions from './actions';
import Card from './components/Card';
import * as selectors from './selectors';

const Container = ({
    fetchTimeShiftingConfiguration,
    initialRate,
    initialValues,
    isDisabled,
    isSaving,
    proposalId,
    rateTiers,
    reset,
    saveTimeShiftingConfiguration,
}) => {
    const { t } = useTranslation();

    useEffect(() => {
        fetchTimeShiftingConfiguration(proposalId);
        return () => reset();
    }, []);

    const handleOnSave = (data) =>
        saveTimeShiftingConfiguration(proposalId, data);

    const _rateTiers = rateTiers?.map(({ identifier, name }) => {
        const _name = initialRate?.certified
            ? t(name, { context: 'rate' })
            : name;
        return { label: `${identifier + 1} - ${_name}`, value: identifier };
    });

    return (
        <AccordionCard
            customTitle={
                <TitleWithDetail variant="body2">
                    {t('Time shifting')}
                </TitleWithDetail>
            }
            isLoading={isSaving}
        >
            <Card
                isDisabled={isDisabled || isSaving}
                handleOnSave={handleOnSave}
                initialValues={initialValues}
                rateTiers={_rateTiers}
            />
        </AccordionCard>
    );
};

const mapStateToProps = createStructuredSelector({
    initialValues: selectors.getInitialValues,
    isSaving: selectors.getIsSavingTimeShiftingConfiguration,
    rateTiers: selectors.getRateTiers,
});

const mapDispatchToProps = (dispatch) => ({
    fetchTimeShiftingConfiguration: (commercialOfferId) =>
        dispatch(actions.fetchTimeShiftingConfiguration(commercialOfferId)),
    reset: () => dispatch(actions.reset()),
    saveTimeShiftingConfiguration: (commercialOfferId, data) =>
        dispatch(
            actions.saveTimeShiftingConfiguration(commercialOfferId, data)
        ),
});

Container.propTypes = {
    fetchTimeShiftingConfiguration: PropTypes.func,
    initialRate: PropTypes.object,
    initialValues: PropTypes.object,
    isDisabled: PropTypes.bool,
    isSaving: PropTypes.bool,
    proposalId: PropTypes.string,
    rateTiers: PropTypes.array,
    reset: PropTypes.func,
    saveTimeShiftingConfiguration: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(Container);
