import { createItem } from 'common/api/v1/users';
import { showReponseErrorsAsAlert } from 'common/utils/helpers';
import showToast from 'common/utils/showToast';

import fetchUserSettings from '../../profile/actions/fetchProfileData';
import { SAVE_ITEM_SUCCESS, SAVE_ITEM_FAILURE } from '../actionTypes';
import { companyUsersActions } from '../reducer';

import filterItems from './filterItems';
import resetForm from './resetForm';
import toggleModalProduct from './toggleModalProduct';

export default (values) => (dispatch) => {
    createItem(values)
        .then((response) => {
            dispatch(companyUsersActions[SAVE_ITEM_SUCCESS](response.data));

            dispatch(filterItems());
            dispatch(fetchUserSettings());
            dispatch(toggleModalProduct(false));
            dispatch(resetForm());
            showToast();
        })
        .catch((error) => {
            dispatch(
                companyUsersActions[SAVE_ITEM_FAILURE](
                    error?.response?.data?.errors
                )
            );
            showReponseErrorsAsAlert(dispatch, error?.response);
        });
};
