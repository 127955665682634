import React from 'react';

import QueryStatsIcon from '@mui/icons-material/QueryStats';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Box, Divider, Typography } from 'sunwise-ui';

import DropdownCardMenu from 'common/components/cards/DropdownCardMenu';

import * as actions from '../../actions';
import * as selectors from '../../selectors';

import IndicatorsContent from './IndicatorsContent';
import IndicatorsSettingsModal from './IndicatorsSettingsModal';

const SidebarIndicators = ({
    handleClickOpenIndicatorsSettingsModal,
    indicatorsSettingsData,
    reportComplements,
}) => {
    const { t } = useTranslation();

    return (
        <>
            <Box alignItems="center" display="flex" p={1}>
                <QueryStatsIcon sx={{ color: '#ff9a00' }} />
                <Typography variant="body2" ml={1} fontWeight="bold">
                    {t('Key indicator', { count: 2 })}
                </Typography>
                <DropdownCardMenu
                    items={[
                        {
                            handleClick: () =>
                                handleClickOpenIndicatorsSettingsModal(true),
                            label: t('Set up'),
                        },
                    ]}
                    sxIconButton={{ ml: 'auto' }}
                />
            </Box>

            <Divider />

            <Box p={1}>
                <IndicatorsContent
                    indicatorsSettingsData={indicatorsSettingsData}
                    valuesIndicators={reportComplements}
                />
            </Box>

            <IndicatorsSettingsModal />
        </>
    );
};

SidebarIndicators.propTypes = {
    handleClickOpenIndicatorsSettingsModal: PropTypes.func,
    indicatorsSettingsData: PropTypes.array,
    reportComplements: PropTypes.object,
};

const mapStateToProps = createStructuredSelector({
    indicatorsSettingsData: selectors.getIndicatorsSettingsData,
});

const mapDispatchToProps = (dispatch) => ({
    handleClickOpenIndicatorsSettingsModal: (value) =>
        dispatch(actions.setIsOpenIndicatorsSettingsModal(value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SidebarIndicators);
