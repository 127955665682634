import React, { useEffect } from 'react';

import PropTypes from 'prop-types';
import { useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Box, Divider, Grid } from 'sunwise-ui';
import { Typography } from 'sunwise-ui';

import {
    ReactHookFormIntlNumberInput,
    ReactHookFormSelect,
} from 'common/components/form/bootstrap';
import { numberFormat } from 'common/utils/helpers';

import { temporalOptions } from '../helpers';

import Source from './Source';
import TypeGroup from './TypeGroup';

const CurrencySettings = ({
    control,
    countryCurrencyLocale,
    formValues,
    handleChangeDollarPrice,
    handleChangeInverseDollarPrice,
    isDisabled,
    isDisabledPrice,
    isDisabledSource,
    isoCode,
    setValue,
}) => {
    const { t } = useTranslation();
    const showInverse = false;
    const [
        additionValue,
        additionType,
        exchange_type,
        originalDollarPrice,
        recalculateAdditionValue,
    ] = useWatch({
        control,
        name: [
            'addition_value',
            'addition_type',
            'exchange_type',
            'original_dollar_price',
            'recalculateAdditionValue',
        ],
    });

    useEffect(() => {
        if (recalculateAdditionValue === 'PERC') {
            const percentage = (additionValue / originalDollarPrice) * 100;
            setValue('addition_value', percentage);
        }

        if (recalculateAdditionValue === 'AMNT') {
            const amount = originalDollarPrice * (additionValue / 100);
            setValue('addition_value', amount);
        }
    }, [recalculateAdditionValue]);

    const getTotal = (originalDollarPrice, additionValue, additionType) => {
        const _originalDollarPrice = parseFloat(
            isNaN(originalDollarPrice) ? 0 : originalDollarPrice || 0
        );
        const _additionValue = parseFloat(additionValue || 0);
        if (!additionValue || isNaN(additionValue)) return _originalDollarPrice;
        if (additionType === 'AMNT')
            return _originalDollarPrice + _additionValue;
        if (additionType === 'PERC')
            return (
                _originalDollarPrice +
                (_originalDollarPrice * _additionValue) / 100
            );
        return _originalDollarPrice;
    };

    if (isoCode === 'USD') return null;

    return (
        <>
            <Typography fontWeight="bold" variant="body2">
                {t('Source')}
            </Typography>

            <Divider sx={{ my: 2 }} />

            <Grid container>
                <Grid item xs>
                    <ReactHookFormSelect
                        control={control}
                        disabled={isDisabled}
                        fullWidth
                        label={t('Exchange rate')}
                        name="exchange_type"
                        onChange={(e) =>
                            setValue('exchange_type', e.target.value)
                        }
                        options={temporalOptions()}
                        type="text"
                    />
                </Grid>

                <Source
                    control={control}
                    isDisabledSource={isDisabledSource}
                    isoCode={isoCode}
                    setValue={setValue}
                />
            </Grid>

            <Typography fontWeight="bold" variant="body2">
                {t('Equivalence against the Dollar')}
            </Typography>

            <Divider sx={{ my: 2 }} />

            <ReactHookFormIntlNumberInput
                append={formValues.abbreviation}
                control={control}
                disabled={isDisabledPrice}
                fullWidth
                label={numberFormat(1, {
                    locale: countryCurrencyLocale,
                    style: 'decimal',
                    unit: 'USD',
                })}
                min="0"
                name="original_dollar_price"
                onChange={(e) => handleChangeDollarPrice(e.target.value)}
                prepend={formValues.symbol}
            />

            {showInverse && (
                <ReactHookFormIntlNumberInput
                    control={control}
                    disabled={isDisabledPrice}
                    fullWidth
                    label={
                        <>
                            inv.{' '}
                            {numberFormat(1, {
                                locale: countryCurrencyLocale,
                                style: 'decimal',
                                unit: 'USD',
                            })}
                        </>
                    }
                    min={0}
                    name="inverse_dollar_price"
                    onChange={(value) => handleChangeInverseDollarPrice(value)}
                />
            )}

            {exchange_type === 'AUT' && (
                <>
                    <Box sx={{ display: 'flex', alignItems: 'start' }}>
                        <ReactHookFormIntlNumberInput
                            id={`addition_value_${additionType}`}
                            control={control}
                            disabled={isDisabledSource}
                            fullWidth
                            label={t('Addition value')}
                            name="addition_value"
                            step="any"
                        />

                        <TypeGroup
                            currencySymbol="$"
                            disabled={isDisabledSource}
                            handleOnChange={(value) => {
                                setValue('addition_type', value);
                                setValue('recalculateAdditionValue', value);
                            }}
                            selected={additionType}
                        />
                    </Box>

                    <Typography>
                        <strong>{t('Total')}</strong>: $
                        {getTotal(
                            originalDollarPrice,
                            additionValue,
                            additionType
                        ).toFixed(4)}
                    </Typography>
                </>
            )}
        </>
    );
};

CurrencySettings.propTypes = {
    control: PropTypes.object,
    countryCurrencyLocale: PropTypes.string,
    formValues: PropTypes.object,
    handleChangeDollarPrice: PropTypes.func,
    handleChangeInverseDollarPrice: PropTypes.func,
    isDisabled: PropTypes.bool,
    isDisabledPrice: PropTypes.bool,
    isDisabledSource: PropTypes.bool,
    isoCode: PropTypes.string,
    setValue: PropTypes.func,
};

export default CurrencySettings;
