import React from 'react';

import FileCopyIcon from '@mui/icons-material/FileCopy';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from 'sunwise-ui';

import { useBreakpoint } from 'common/hooks';

const CustomTemplateColumns = ({
    isForSummaryTemplates,
    languagesDictionary,
}) => {
    const { t } = useTranslation();
    const breakpoint = useBreakpoint();

    const nameColumn = [
        {
            align: 'left',
            field: 'title',
            flex: 1,
            headerName: t('Title'),
            renderCell: (params) => {
                const { row } = params;
                return (
                    <Box display="flex" flexDirection="column">
                        <Typography variant="caption">{row.title}</Typography>
                        <Typography variant="caption">
                            {row.pages} {t('Sheet', { count: row.pages })}
                        </Typography>
                    </Box>
                );
            },
        },
    ];

    let columns = [
        ...nameColumn,
        {
            align: 'left',
            field: 'proposals_number',
            flex: 1,
            headerName: t('Proposal', { count: 2 }),
            hide: !isForSummaryTemplates,
            renderCell: (params) => {
                const { row } = params;
                return (
                    <Box alignItems="center" display="flex" gap={1}>
                        <FileCopyIcon fontSize="small" />
                        <Typography variant="body2">
                            {row?.proposals_number}{' '}
                            {t('Proposal', {
                                count: row?.proposals_number,
                            })}
                        </Typography>
                    </Box>
                );
            },
        },
        {
            align: 'left',
            field: 'language',
            flex: 1,
            headerName: t('Language'),
            renderCell: (params) => {
                const { row } = params;
                if (languagesDictionary[row?.language])
                    return languagesDictionary[row?.language]?.language;
                return null;
            },
        },
        {
            align: 'left',
            field: 'created_at',
            flex: 1,
            headerName: t('Creation date'),
            renderCell: (params) => {
                const { row } = params;
                return row?.created_at;
            },
        },
    ];

    if (['xs', 'sm'].includes(breakpoint)) columns = nameColumn;

    return columns;
};

CustomTemplateColumns.propTypes = {
    isForSummaryTemplates: PropTypes.bool,
    languagesDictionary: PropTypes.object,
};

export default CustomTemplateColumns;
