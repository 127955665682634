import React from 'react';

import LockIcon from '@mui/icons-material/Lock';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { PageThumbnail, templateCoreHelpers } from 'sunwise-template-core';
import { Box } from 'sunwise-ui';

const BORDER_WIDTH = 4;
const THUMBNAIL_HEIGHT = 101;
const THUMBNAIL_WIDTH = 71;

const Container = styled('div')`
    display: flex;
    position: relative;
`;

const PageWrapper = styled('div')`
    align-items: center;
    background-color: #fff;
    border-radius: 2px;
    border: ${BORDER_WIDTH}px solid transparent;
    cursor: pointer;
    display: flex;
    height: ${({ orientation }) =>
        orientation === 'portrait' ? THUMBNAIL_HEIGHT : THUMBNAIL_WIDTH}px;
    justify-content: center;
    margin: 0 auto;
    overflow: hidden;
    position: relative;
    width: ${({ orientation }) =>
        orientation === 'portrait' ? THUMBNAIL_WIDTH : THUMBNAIL_HEIGHT}px;
    z-index: 1;

    &.selected {
        border: ${BORDER_WIDTH}px solid #008dff;
    }
`;

const StyledPageThumbnail = styled(PageThumbnail)`
    && {
        height: ${({ page }) =>
            (page.orientation === 'portrait'
                ? THUMBNAIL_HEIGHT
                : THUMBNAIL_WIDTH) -
            BORDER_WIDTH * 2}px;
        min-height: initial;
        min-width: initial;
        width: ${({ page }) =>
            (page.orientation === 'portrait'
                ? THUMBNAIL_WIDTH
                : THUMBNAIL_HEIGHT) -
            BORDER_WIDTH * 2}px;

        & > div {
            transform: scale(
                ${({ page, pageHeight, pageWidth }) =>
                    page.orientation === 'portrait'
                        ? (THUMBNAIL_WIDTH - BORDER_WIDTH * 2) / pageWidth
                        : (THUMBNAIL_HEIGHT - BORDER_WIDTH * 2) / pageHeight},
                ${({ page, pageHeight, pageWidth }) =>
                    page.orientation === 'portrait'
                        ? (THUMBNAIL_HEIGHT - BORDER_WIDTH * 2) / pageHeight
                        : (THUMBNAIL_WIDTH - BORDER_WIDTH * 2) / pageWidth}
            );
        }
    }
`;

const ItemPreviewPage = ({ handleClickSelect, page, pageSize, selected }) => {
    const { height: pageHeight, width: pageWidth } =
        templateCoreHelpers.getPageDimensions(pageSize);
    return (
        <Container orientation={page.orientation}>
            <PageWrapper
                className={selected && 'selected'}
                onClick={handleClickSelect}
                orientation={page.orientation}
            >
                <Box sx={{ pointerEvents: 'none' }}>
                    <StyledPageThumbnail
                        page={page}
                        pageHeight={pageHeight}
                        pageWidth={pageWidth}
                    />
                </Box>
                {page.blocked === 1 && <LockIcon />}
            </PageWrapper>
        </Container>
    );
};

ItemPreviewPage.propTypes = {
    handleClickSelect: PropTypes.func,
    page: PropTypes.object,
    pageSize: PropTypes.string,
    selected: PropTypes.bool,
};

export default ItemPreviewPage;
