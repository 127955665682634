import { getItems } from 'common/api/v1/accessoriesCompany';

import {
    FETCH_ACCESSORIES,
    FETCH_ACCESSORIES_FAILURE,
    FETCH_ACCESSORIES_SUCCESS,
} from '../actionTypes';
import { planConfigurationActions } from '../reducer';

export default (branchOffice) => (dispatch) => {
    dispatch(planConfigurationActions[FETCH_ACCESSORIES]());

    getItems(branchOffice)
        .then((response) => {
            dispatch(
                planConfigurationActions[FETCH_ACCESSORIES_SUCCESS](
                    response.data.data
                )
            );
        })
        .catch((error) =>
            dispatch(
                planConfigurationActions[FETCH_ACCESSORIES_FAILURE](
                    error?.response?.data?.errors
                )
            )
        );
};
