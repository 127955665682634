import i18next from 'i18next';

import { deleteTracking } from 'common/api/v1/contactTrackings';
import { showReponseErrorsAsAlert } from 'common/utils/helpers';
import showToast from 'common/utils/showToast';

import {
    DELETE_TRACKING,
    DELETE_TRACKING_FAILURE,
    DELETE_TRACKING_SUCCESS,
} from '../actionTypes';
import { trackingsActions } from '../reducer';
import * as selectors from '../selectors';

import fetchTrackings from './fetchTrackings';
import fetchTrackingsNext from './fetchTrackingsNext';
import openConfirmModal from './openConfirmModal';

export default () => (dispatch, getState) => {
    const state = getState();
    const elemenToDelete = selectors.getElementToDelete(state);

    dispatch(trackingsActions[DELETE_TRACKING]());

    deleteTracking(elemenToDelete.id)
        .then(() => {
            dispatch(trackingsActions[DELETE_TRACKING_SUCCESS]());
            dispatch(fetchTrackings());
            dispatch(fetchTrackingsNext());
            dispatch(openConfirmModal(false));

            showToast({ body: i18next.t('It was successfully removed') });
        })
        .catch((error) => {
            dispatch(
                trackingsActions[DELETE_TRACKING_FAILURE](
                    error?.response?.data?.errors
                )
            );
            showReponseErrorsAsAlert(dispatch, error?.response);
        });
};
