import { createSelector } from 'reselect';

import { NAME } from './constants';

export const getModel = (state) => state[NAME];

export const getPowerStationsModel = createSelector(
    getModel,
    (model) => model.powerStations
);

export const getPowerStations = createSelector(
    getPowerStationsModel,
    (model) => model.data
);

export const getPowerStationsData = createSelector(
    getPowerStations,
    (stations) => stations.data || []
);

export const getPowerStationsPagination = createSelector(
    getPowerStations,
    (stations) => stations.pagination
);

export const getPowerStationsColumns = createSelector(
    getPowerStations,
    (stations) => stations.columns || []
);

export const getCredentialName = createSelector(
    getPowerStations,
    (stations) => stations.company_sk_name
);

export const getPowerStationsIsFetching = createSelector(
    getPowerStationsModel,
    (model) => model.isFetching
);

export const getPowerStationsErrors = createSelector(
    getPowerStationsModel,
    (model) => model.errors
);

export const getAssignProjectModalIsOpen = createSelector(
    getModel,
    (model) => model.assignProjectModalIsOpen
);

export const getSelectedPowerStation = createSelector(
    getModel,
    (model) => model.selectedPowerStation
);

export const getToggleAssingProjectModel = createSelector(
    getModel,
    (model) => model.toggleAssignProject
);

export const getIsSavingToggleAssignProject = createSelector(
    getToggleAssingProjectModel,
    (model) => model.isSaving
);

/* Sunwise projects */
export const getProjectsModel = createSelector(
    getModel,
    (model) => model.projects
);

export const getProjects = createSelector(
    getProjectsModel,
    (model) => model.data || []
);

export const getIsFetchingProjects = createSelector(
    getProjectsModel,
    (model) => model.isFetching
);

export const getProjectsDictionary = createSelector(getProjects, (projects) =>
    projects?.reduce((acc, project) => {
        acc[project.id] = project;
        return acc;
    }, {})
);
export const getAfterSalesProjectsModel = createSelector(
    getModel,
    (model) => model.afterSalesProjects
);

export const getAfterSalesProjects = createSelector(
    getAfterSalesProjectsModel,
    (model) => model?.data?.filter((project) => !project.is_deleted) || []
);

export const getIsFetchingAfterSalesProjects = createSelector(
    getAfterSalesProjectsModel,
    (model) => model.isFetching
);
