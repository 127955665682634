import { createSelector } from 'reselect';

import { NAME } from './constants';

export const getModel = (state) => state[NAME];

export const getFetchVersion = createSelector(
    getModel,
    (model) => model.fetchVersion
);

export const getIsFetchingVersion = createSelector(
    getFetchVersion,
    (model) => model?.isFetching
);

export const getHasNewVersionAvailable = createSelector(
    getModel,
    (model) => model.newVersionAvailable
);
