import i18next from 'i18next';

import alerts from 'common/modules/alerts';

import renameCommercialOffer from './renameCommercialOffer';

export default (isArchivedFilter, offerName, projectId, proposalId) =>
    (dispatch) => {
        const dataAlert = {
            confirmText: i18next.t('Rename'),
            defaultTextResponse: offerName,
            haveTextResponse: true,
            messages: [
                i18next.t('Are you sure you want to rename this element?'),
            ],
            onSuccess: (value) => {
                dispatch(
                    renameCommercialOffer(
                        projectId,
                        proposalId,
                        isArchivedFilter,
                        value
                    )
                );
            },
            title: i18next.t('Rename proposal'),
            type: alerts.ALERT_TYPE_CONFIRM,
            variant: 'warning',
        };

        dispatch(alerts.actions.show(dataAlert));
    };
