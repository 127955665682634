import { get, isEmpty } from 'lodash';

import { getTemplatesToClone } from 'common/api/v1/templates';
import { DEFAULT_TEMPLATE_LANGUAGE } from 'common/constants';

import {
    FETCH_CLONE_PAGES,
    FETCH_CLONE_PAGES_FAILURE,
    FETCH_CLONE_PAGES_SUCCESS,
} from '../actionTypes';
import { editTemplateViewActions } from '../reducer';

export default (companyCountry = '', language) =>
    (dispatch) => {
        if (!companyCountry) return;

        dispatch(editTemplateViewActions[FETCH_CLONE_PAGES]());

        return getTemplatesToClone(companyCountry, language)
            .then((response) => {
                const data = get(response, ['data', 'data'], []);

                if (!isEmpty(data)) {
                    const templates = data.map((item) => ({
                        ...item,
                        languages: !isEmpty(item.languages)
                            ? item.languages
                            : [DEFAULT_TEMPLATE_LANGUAGE],
                    }));

                    dispatch(
                        editTemplateViewActions[FETCH_CLONE_PAGES_SUCCESS](
                            templates
                        )
                    );
                }
            })
            .catch((error) =>
                dispatch(
                    editTemplateViewActions[FETCH_CLONE_PAGES_FAILURE](error)
                )
            );
    };
