import { fetchListCatalogs } from 'common/api/v1/companiesCatalogs';

import {
    FETCH_ORIGIN_CONTACT,
    FETCH_ORIGIN_CONTACT_FAILURE,
    FETCH_ORIGIN_CONTACT_SUCCESS,
} from '../actionTypes';
import { ORIGIN_CONTACT_TYPE } from '../constants';
import { contactFormActions } from '../reducer';

export default () => (dispatch) => {
    dispatch(contactFormActions[FETCH_ORIGIN_CONTACT]());

    fetchListCatalogs(ORIGIN_CONTACT_TYPE)
        .then((response) =>
            dispatch(
                contactFormActions[FETCH_ORIGIN_CONTACT_SUCCESS](
                    response.data.data
                )
            )
        )
        .catch((error) =>
            dispatch(contactFormActions[FETCH_ORIGIN_CONTACT_FAILURE](error))
        );
};
