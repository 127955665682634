import { array, object, number, string } from 'yup';

import getValidationTexts from 'common/utils/getValidationTexts';
import { emailRegex } from 'common/utils/helpers';
import { getIsMultibranchesAccount } from 'common/utils/helpers/session';

export default () => {
    const { EMAIL_VALIDATION_TEXT, NUMBER_TYPE_TEXT, REQUIRED_TEXT } =
        getValidationTexts();

    let validation = {
        agent: string().required(REQUIRED_TEXT).nullable(),
        emails: array().of(
            object({
                email: string()
                    .required(REQUIRED_TEXT)
                    .matches(emailRegex, EMAIL_VALIDATION_TEXT)
                    .nullable(),
                email_type: string().nullable(),
            })
        ),
        name: string().required(REQUIRED_TEXT).nullable(),
        telephones: array().of(
            object({
                extension: string().nullable(),
                phone_key: string().required(REQUIRED_TEXT).nullable(),
                phone_type: string().nullable(),
                telephone: number()
                    .typeError(NUMBER_TYPE_TEXT)
                    .required(REQUIRED_TEXT)
                    .nullable(),
            })
        ),
    };

    if (getIsMultibranchesAccount()) {
        validation = {
            ...validation,
            branch_office: string().required(REQUIRED_TEXT).nullable(),
        };
    }

    return object().shape(validation);
};
