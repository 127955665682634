import React, { useEffect, useState } from 'react';

import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';
import Chart from 'react-apexcharts';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Box } from 'sunwise-ui';

import { KeyIndicator } from 'common/components';
import { IconPanel } from 'common/components/icons';
import { numberFormat } from 'common/utils/helpers';
import { getCountryCurrencyLocale } from 'common/utils/helpers/session';

import * as actions from '../../actions';
import {
    getCategories,
    getKeyIndicatorTitle,
    getSeries,
} from '../../IrradiationHelpers';
import * as selectors from '../../selectors';

import ChartControls from './ChartControls';

const IrradiationChart = ({
    hasChanges,
    irradiationData,
    formValues,
    segments,
}) => {
    const theme = useTheme();

    const [controlValues, setControlValues] = useState({
        day: 0,
        month: 0,
        period: 0,
        segment: 'all',
        week: 0,
    });

    const countryCurrencyLocale = getCountryCurrencyLocale();

    useEffect(() => {
        setControlValues((values) => ({ ...values, ...formValues }));
    }, [formValues]);

    const handleOnControlsChange = (value, name) =>
        setControlValues((values) => ({ ...values, [name]: value }));

    const chartConfig = {
        options: {
            chart: {
                background: '#ffffff00',
                toolbar: {
                    tools: {
                        download: false,
                        selection: true,
                        zoom: true,
                        zoomin: true,
                        zoomout: true,
                        pan: false,
                    },
                },
            },
            dataLabels: { enabled: false },
            series: getSeries(controlValues, irradiationData),
            xaxis: { categories: getCategories(controlValues) },
            yaxis: {
                forceNiceScale: true,
                labels: {
                    formatter: (val) =>
                        numberFormat(val / 1000, {
                            decimals: 2,
                            locale: countryCurrencyLocale,
                            style: 'decimal',
                            unit: 'kW',
                        }),
                },
                min: 0,
                show: false,
            },
        },
    };

    if (irradiationData.length === 0 || segments.length === 0) return null;
    const showKeyIndicator =
        Array.isArray(chartConfig.options.series[0].data) &&
        chartConfig.options.series[0].data.length > 0;

    return (
        <Box sx={{ position: 'relative' }}>
            <ChartControls
                controlValues={controlValues}
                handleOnControlsChange={handleOnControlsChange}
                segments={segments}
            />

            <Box sx={{ mt: 1 }}>
                <Chart
                    options={{
                        ...chartConfig.options,
                        theme: { mode: theme.palette.mode },
                    }}
                    series={chartConfig.options.series}
                    type="area"
                    width="100%"
                />

                {showKeyIndicator && (
                    <KeyIndicator
                        svgIcon={<IconPanel />}
                        title={getKeyIndicatorTitle(controlValues.period)}
                        value={numberFormat(
                            chartConfig.options.series[0].data.reduce(
                                (acc, cur) => acc + cur / 1000,
                                0
                            ),
                            {
                                decimals: 2,
                                locale: countryCurrencyLocale,
                                style: 'decimal',
                                unit: 'kWh',
                            }
                        )}
                    />
                )}

                {hasChanges && (
                    <Box
                        sx={{
                            backdropFilter: 'blur(10px)',
                            bottom: 0,
                            left: 0,
                            position: 'absolute',
                            right: 0,
                            top: '-5px',
                            zIndex: 100,
                        }}
                    />
                )}
            </Box>
        </Box>
    );
};

IrradiationChart.propTypes = {
    hasChanges: PropTypes.bool,
    formValues: PropTypes.object,
    irradiationData: PropTypes.array,
    segments: PropTypes.array,
};

const mapStateToProps = createStructuredSelector({
    irradiationData: selectors.getIrradiationData,
});

const mapDispatchToProps = (dispatch) => ({
    saveIrradiationData: (uuid, values, callback) =>
        dispatch(actions.saveIrradiationData(uuid, values, callback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(IrradiationChart);
