import { NAME } from './constants';

export const FETCH_CONTACTS = `${NAME}/FETCH_CONTACTS`;
export const FETCH_CONTACTS_FAILURE = `${NAME}/FETCH_CONTACTS_FAILURE`;
export const FETCH_CONTACTS_SUCCESS = `${NAME}/FETCH_CONTACTS_SUCCESS`;

export const FETCH_TRACKINGS_HOURS = `${NAME}/FETCH_TRACKINGS_HOURS`;
export const FETCH_TRACKINGS_HOURS_FAILURE = `${NAME}/FETCH_TRACKINGS_HOURS_FAILURE`;
export const FETCH_TRACKINGS_HOURS_SUCCESS = `${NAME}/FETCH_TRACKINGS_HOURS_SUCCESS`;

export const FETCH_TRACKINGS_PURPOSES = `${NAME}/FETCH_TRACKINGS_PURPOSES`;
export const FETCH_TRACKINGS_PURPOSES_FAILURE = `${NAME}/FETCH_TRACKINGS_PURPOSES_FAILURE`;
export const FETCH_TRACKINGS_PURPOSES_SUCCESS = `${NAME}/FETCH_TRACKINGS_PURPOSES_SUCCESS`;

export const FETCH_USERS = `${NAME}/FETCH_USERS`;
export const FETCH_USERS_FAILURE = `${NAME}/FETCH_USERS_FAILURE`;
export const FETCH_USERS_SUCCESS = `${NAME}/FETCH_USERS_SUCCESS`;

export const INITIALIZE_FORM = `${NAME}/INITIALIZE_FORM`;

export const SET_IS_OPEN_MODAL = `${NAME}/SET_IS_OPEN_MODAL`;

export const RESET_FORM = `${NAME}/RESET_FORM`;

export const SAVE_TRACKING = `${NAME}/SAVE_TRACKING`;
export const SAVE_TRACKING_FAILURE = `${NAME}/SAVE_TRACKING_FAILURE`;
export const SAVE_TRACKING_SUCCESS = `${NAME}/SAVE_TRACKING_SUCCESS`;
