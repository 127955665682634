export const NAME = 'csv-importer';

export const COLUMN_TYPES = {
    DATE: 'date',
    INTEGER: 'integer',
    NUMBER: 'number',
    STRING: 'string',
    TIME: 'time',
};

export const ISO_FORMAT = "yyyy-MM-dd'T'HH:mm:ssX";

export const DATE_FORMATS = [
    {
        label: 'dd/MM/yyyy',
        value: 'dd/MM/yyyy',
    },
    {
        label: 'dd/MM/yyyy HH:mm',
        value: 'dd/MM/yyyy HH:mm',
    },
    {
        label: 'dd/MM/yyyy HH:mm:ss',
        value: 'dd/MM/yyyy HH:mm:ss',
    },
    {
        label: 'MM/dd/yyyy',
        value: 'MM/dd/yyyy',
    },
    {
        label: 'MM/dd/yyyy HH:mm',
        value: 'MM/dd/yyyy HH:mm',
    },
    {
        label: 'MM/dd/yyyy HH:mm:ss',
        value: 'MM/dd/yyyy HH:mm:ss',
    },
    {
        label: 'yyyy/MM/dd',
        value: 'yyyy/MM/dd',
    },
    {
        label: 'yyyy/MM/dd HH:mm',
        value: 'yyyy/MM/dd HH:mm',
    },
    {
        label: 'yyyy/MM/dd HH:mm:ss',
        value: 'yyyy/MM/dd HH:mm:ss',
    },
    {
        label: 'dd-MM-yyyy',
        value: 'dd-MM-yyyy',
    },
    {
        label: 'dd-MM-yyyy HH:mm',
        value: 'dd-MM-yyyy HH:mm',
    },
    {
        label: 'dd-MM-yyyy HH:mm:ss',
        value: 'dd-MM-yyyy HH:mm:ss',
    },
    {
        label: 'MM-dd-yyyy',
        value: 'MM-dd-yyyy',
    },
    {
        label: 'MM-dd-yyyy HH:mm',
        value: 'MM-dd-yyyy HH:mm',
    },
    {
        label: 'MM-dd-yyyy HH:mm:ss',
        value: 'MM-dd-yyyy HH:mm:ss',
    },
    {
        label: 'yyyy-MM-dd',
        value: 'yyyy-MM-dd',
    },

    {
        label: 'yyyy-MM-dd HH:mm',
        value: 'yyyy-MM-dd HH:mm',
    },

    {
        label: 'yyyy-MM-dd HH:mm:ss',
        value: 'yyyy-MM-dd HH:mm:ss',
    },
    {
        label: 'ISO 8601',
        value: ISO_FORMAT,
    },
];

export const TIME_FORMATS = [
    {
        label: 'HH:mm:ss',
        value: 'HH:mm:ss',
    },
    {
        label: 'HH:mm',
        value: 'HH:mm',
    },
    {
        label: 'HH',
        value: 'HH',
    },
];

export const DEFAULT_FORMATS = {
    [COLUMN_TYPES.DATE]: 'dd/MM/yyyy',
    [COLUMN_TYPES.TIME]: 'HH:mm:ss',
    [COLUMN_TYPES.NUMBER]: '.',
};

export const STEPS = [
    { label: 'Upload', value: 'upload' },
    { label: 'Match column', value: 'match_columns' },
    { label: 'Review', value: 'review' },
];

export const STEPS_ORDER = {
    UPLOAD: 0,
    MATCH_COLUMNS: 1,
    REVIEW: 2,
};
