import React, { useEffect, useMemo, useState } from 'react';

import isNull from 'lodash/isNull';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { types } from 'sunwise-template-core';
import {
    Box,
    Button,
    Tab,
    TabPanel,
    Tabs,
    Typography,
    UiDialog as Dialog,
} from 'sunwise-ui';

import IntlNumberInputField from 'common/components/IntlNumberInputField';

import CustomTemplatesTab from './CustomTemplatesTab';
import DefaultTemplatesTab from './DefaultTemplatesTab';

const NewPageModal = ({
    handleClickClose,
    isOpen,
    onAddPage,
    onClonePage,
    onEnter,
    proposalsNumber,
    templateType,
}) => {
    const { t } = useTranslation();
    const [currentKey, setCurrentKey] = useState('default-pages-templates-tab');
    const [categorySelected, selectCategory] = useState(null);
    const [pageSelected, selectPage] = useState(null);
    const [fromPage, setFromPage] = useState(1);
    const [toPage, setToPage] = useState(1);
    const isDefaultTab = currentKey === 'custom-pages-templates-tab';

    const isForSummaryTemplate = useMemo(
        () => templateType === types.MULTIPROPOSAL_TYPE,
        [templateType]
    );

    useEffect(() => {
        if (isDefaultTab && !isNull(categorySelected)) {
            selectCategory(null);
        }
        if (!isNull(pageSelected)) selectPage(null);
    }, [currentKey]);

    const handleFromPageValue = (value) =>
        setFromPage(value === '' ? '' : parseInt(value));

    const handleToPageValue = (value) =>
        setToPage(value === '' ? '' : parseInt(value));

    const handleSelectPage = (page) => {
        setFromPage(1);
        setToPage(page.pages);
        selectPage(page);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (pageSelected !== null && pageSelected.id === 'blank-page') {
            onAddPage(toPage);
        } else {
            const temp = [];
            for (let i = fromPage; i <= toPage; ++i) temp.push(i);
            onClonePage(pageSelected.id, temp.join());
        }
        handleClickClose();
        selectPage(null);
    };

    const handleChange = (_, newValue) => setCurrentKey(newValue);

    return (
        <Dialog
            onClose={() => {
                handleClickClose();
                selectPage(null);
            }}
            onEnter={onEnter}
            onExited={() => {
                setCurrentKey('default-pages-templates-tab');
                selectCategory(null);
                selectPage(null);
                setFromPage(1);
                setToPage(1);
            }}
            open={isOpen}
            title={t('New page')}
        >
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={currentKey} onChange={handleChange}>
                    <Tab
                        id="tab-default-pages-templates-tab"
                        key="tab-default-pages-templates-tab"
                        label={t('Default', { context: 'female', count: 2 })}
                        value="default-pages-templates-tab"
                    />

                    <Tab
                        id="tab-custom-pages-templates-tab"
                        key="tab-custom-pages-templates-tab"
                        label={t('My template', { count: 2 })}
                        value="custom-pages-templates-tab"
                    />
                </Tabs>
            </Box>

            <TabPanel
                key="tab-default-pages-templates-tab"
                selectedTab={currentKey}
                value="default-pages-templates-tab"
            >
                <DefaultTemplatesTab
                    categorySelected={categorySelected}
                    handleSelectPage={handleSelectPage}
                    isForSummaryTemplate={isForSummaryTemplate}
                    pageSelected={pageSelected}
                    proposalsNumber={proposalsNumber}
                    selectCategory={selectCategory}
                    templateBaseType={templateType}
                />
            </TabPanel>

            <TabPanel
                key="tab-custom-pages-templates-tab"
                selectedTab={currentKey}
                value="custom-pages-templates-tab"
            >
                <CustomTemplatesTab
                    handleSelectPage={handleSelectPage}
                    proposalsNumber={proposalsNumber}
                    templateBaseType={templateType}
                />
            </TabPanel>

            <Box display="flex" alignItems="center">
                {pageSelected && (
                    <Box
                        alignItems="center"
                        display="flex"
                        gap={1}
                        sx={{ '.MuiFormControl-root': { mb: 0 } }}
                    >
                        {pageSelected.id === 'blank-page' ? (
                            <IntlNumberInputField
                                label="Cantidad de hojas"
                                onBlur={(event) => {
                                    if (
                                        event.target.value === '' ||
                                        parseInt(event.target.value) < 1
                                    )
                                        handleToPageValue(1);
                                }}
                                onChange={handleToPageValue}
                                value={toPage}
                            />
                        ) : (
                            <>
                                <Typography variant="caption">
                                    {t('From')} {t('Page').toLowerCase()}
                                </Typography>

                                <IntlNumberInputField
                                    disabled={pageSelected.id === 'blank-page'}
                                    max={toPage}
                                    onBlur={(event) => {
                                        if (
                                            event.target.value === '' ||
                                            parseInt(event.target.value) < 1
                                        )
                                            handleFromPageValue(1);
                                    }}
                                    onChange={handleFromPageValue}
                                    sx={{ textAlign: 'center', width: '60px' }}
                                    value={fromPage}
                                />

                                <Typography variant="caption">
                                    {t('To').toLowerCase()}
                                </Typography>

                                <IntlNumberInputField
                                    disabled={pageSelected.id === 'blank-page'}
                                    max={pageSelected.pages}
                                    onBlur={(event) => {
                                        if (event.target.value === '')
                                            handleToPageValue(
                                                pageSelected.pages
                                            );

                                        if (
                                            parseInt(event.target.value) <
                                            parseInt(fromPage)
                                        )
                                            handleToPageValue(fromPage);
                                    }}
                                    onChange={handleToPageValue}
                                    sx={{ textAlign: 'center', width: '60px' }}
                                    value={toPage}
                                />
                            </>
                        )}
                    </Box>
                )}

                <Box sx={{ ml: 'auto' }}>
                    <Button
                        disabled={pageSelected === null}
                        onClick={handleSubmit}
                        type="button"
                    >
                        {t('Add')}
                    </Button>
                </Box>
            </Box>
        </Dialog>
    );
};

NewPageModal.propTypes = {
    handleClickClose: PropTypes.func,
    isOpen: PropTypes.bool,
    onAddPage: PropTypes.func,
    onClonePage: PropTypes.func,
    onEnter: PropTypes.func,
    proposalsNumber: PropTypes.number,
    templateType: PropTypes.number,
};

export default NewPageModal;
