import { getOfferCustomTemplates } from 'common/api/v1/offerCustomTemplate';

import {
    FETCH_OFFER_TEMPLATES,
    FETCH_OFFER_TEMPLATES_FAILURE,
    FETCH_OFFER_TEMPLATES_SUCCESS,
} from '../actionTypes';
import { proposalSummaryActions } from '../reducer';

export default (branchOffice, type) => (dispatch) => {
    dispatch(proposalSummaryActions[FETCH_OFFER_TEMPLATES]());

    getOfferCustomTemplates({ branchOffice, type })
        .then((response) =>
            dispatch(
                proposalSummaryActions[FETCH_OFFER_TEMPLATES_SUCCESS](
                    response.data.data
                )
            )
        )
        .catch((error) =>
            dispatch(
                proposalSummaryActions[FETCH_OFFER_TEMPLATES_FAILURE](error)
            )
        );
};
