import { getItems } from 'common/api/v2/consumptionProfiles';

import {
    FETCH_CONSUMPTION_PROFILES,
    FETCH_CONSUMPTION_PROFILES_FAILURE,
    FETCH_CONSUMPTION_PROFILES_SUCCESS,
    FETCH_CONSUMPTION_PROFILE_FAILURE,
} from '../actionTypes';
import { consumptionProfilesActions } from '../reducer';

import filterConsumptionProfiles from './filterConsumptionProfiles';

export default (params) => (dispatch) => {
    dispatch(consumptionProfilesActions[FETCH_CONSUMPTION_PROFILE_FAILURE]());
    dispatch(consumptionProfilesActions[FETCH_CONSUMPTION_PROFILES]());

    getItems()
        .then((response) => {
            const data = [...(response?.data?.data || [])].sort((a, b) => {
                if (a.type === b.type) return a.usage_type - b.usage_type;
                return a.type - b.type;
            });

            dispatch(
                consumptionProfilesActions[FETCH_CONSUMPTION_PROFILES_SUCCESS](
                    data
                )
            );
            dispatch(filterConsumptionProfiles(params));
        })
        .catch((error) =>
            dispatch(
                consumptionProfilesActions[FETCH_CONSUMPTION_PROFILES_FAILURE](
                    error
                )
            )
        );
};
