import React from 'react';

import WifiTetheringIcon from '@mui/icons-material/WifiTethering';
import WifiTetheringErrorIcon from '@mui/icons-material/WifiTetheringError';
import { LinearProgress } from '@mui/material';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box, Divider, Typography } from 'sunwise-ui';

import ProviderImage from 'common/components/ProviderImage';

import { PROGRESS_STATUS } from '../constants';

const ProjectItem = ({ project, projectInfo, providersDictionary }) => {
    const { t } = useTranslation();
    const { current_percentage, provider, station_name, status } = project;

    return (
        <>
            <Box alignItems="center" display="flex" gap={2}>
                <ProviderImage
                    provider={providersDictionary?.[provider]}
                    style={{ maxHeight: '40px', maxWidth: '70px' }}
                />

                <Typography variant="body2">{station_name}</Typography>
            </Box>

            <Typography variant="caption">
                {`${t('Project')}: ${projectInfo?.name || '--'}`}
            </Typography>

            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Box mr={1} width="100%">
                    <LinearProgress
                        variant="determinate"
                        color="success"
                        value={current_percentage || 0}
                    />
                </Box>

                <Typography variant="body2" mr={1}>
                    {`${current_percentage || 0}%`}
                </Typography>

                {status === PROGRESS_STATUS.SUCCESS && (
                    <WifiTetheringIcon color="success" size="small" />
                )}

                {status === PROGRESS_STATUS.FAILED && (
                    <WifiTetheringErrorIcon color="error" size="small" />
                )}
            </Box>

            <Divider sx={{ my: 1 }} />
        </>
    );
};

ProjectItem.propTypes = {
    project: PropTypes.object,
    projectInfo: PropTypes.object,
    providersDictionary: PropTypes.object,
};

export default ProjectItem;
