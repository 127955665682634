import i18next from 'i18next';

import { deleteKey } from 'common/api/v1/integrationsSuppliers';
import { showReponseErrorsAsAlert } from 'common/utils/helpers';
import showToast from 'common/utils/showToast';

import {
    DELETE_INTEGRATION,
    DELETE_INTEGRATION_FAILURE,
    DELETE_INTEGRATION_SUCCESS,
} from '../actionTypes';
import { supplierIntegrationsActions } from '../reducer';

import fetchIntegrations from './fetchIntegrations';

export default (id) => (dispatch) => {
    dispatch(supplierIntegrationsActions[DELETE_INTEGRATION]());

    deleteKey(id)
        .then(() => {
            dispatch(supplierIntegrationsActions[DELETE_INTEGRATION_SUCCESS]());
            showToast({ body: i18next.t('It was successfully removed') });
            dispatch(fetchIntegrations());
        })
        .catch((error) => {
            dispatch(
                supplierIntegrationsActions[DELETE_INTEGRATION_FAILURE](
                    error?.response?.data?.errors
                )
            );
            showReponseErrorsAsAlert(dispatch, error?.response);
        });
};
