import { addProductToBranches } from 'common/api/v1/accessoriesCompany';
import { showReponseErrorsAsAlert } from 'common/utils/helpers';
import showToast from 'common/utils/showToast';

import {
    ADD_PRODUCT_TO_BRANCHES,
    ADD_PRODUCT_TO_BRANCHES_FAILURE,
    ADD_PRODUCT_TO_BRANCHES_SUCCESS,
} from '../actionTypes';
import { accessoriesConfigurationActions } from '../reducer';

export default (id, branches, successCallback) => (dispatch) => {
    dispatch(accessoriesConfigurationActions[ADD_PRODUCT_TO_BRANCHES]());
    addProductToBranches({
        parent_accesory: id,
        branch_offices: branches.join(),
    })
        .then(() => {
            successCallback();
            dispatch(
                accessoriesConfigurationActions[
                    ADD_PRODUCT_TO_BRANCHES_SUCCESS
                ]()
            );
            showToast();
        })
        .catch((error) => {
            dispatch(
                accessoriesConfigurationActions[
                    ADD_PRODUCT_TO_BRANCHES_FAILURE
                ](error?.response?.data?.errors)
            );
            showReponseErrorsAsAlert(dispatch, error?.response);
        });
};
