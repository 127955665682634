import React, { useContext, useEffect } from 'react';

import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Box, Button, Divider, Typography } from 'sunwise-ui';

import { ReactHookFormSelect2 } from 'common/components/form/bootstrap';
import { IconTemplate } from 'common/components/icons';
import { LoadingContext } from 'common/utils/contexts';
import yupResolver from 'common/utils/yupResolver';

import * as actions from '../actions';
import * as selectors from '../selectors';
import validateGenerate from '../validateGenerate';

const SidebarForm = ({
    canModify,
    initialValues,
    isGenerated,
    isLoading,
    isSaving,
    isSavingReportEnd,
    reportId,
    reverseBtn,
    save,
    templates,
}) => {
    const { t } = useTranslation();
    const loadingContext = useContext(LoadingContext);

    const { control, handleSubmit, reset } = useForm({
        defaultValues: initialValues,
        resolver: yupResolver(validateGenerate),
    });

    useEffect(() => reset(initialValues), [initialValues]);

    useEffect(() => {
        if (isEmpty(loadingContext)) return;
        if (isSaving || isSavingReportEnd)
            loadingContext.openLoading(
                t('Saving change', { count: 2 }).concat('...')
            );
        else loadingContext.closeLoading();
    }, [isSaving, isSavingReportEnd]);

    const handleOnSubmit = (values) =>
        save({ ...values, isGenerated, reportId });

    if (!canModify) return null;

    return (
        <form
            style={{
                display: 'flex',
                flexDirection: reverseBtn ? 'column-reverse' : 'column',
                width: '100%',
            }}
        >
            <Button
                disabled={isLoading}
                onClick={handleSubmit(handleOnSubmit)}
                fullWidth
            >
                {isGenerated ? t('View report') : t('Generate')}
            </Button>

            <Box sx={{ width: '100%', p: 1 }}>
                <Box sx={{ alignItems: 'center', display: 'flex' }}>
                    <IconTemplate />
                    <Typography variant="body2" ml={1} fontWeight="bold">
                        {t('Template')}
                    </Typography>
                </Box>

                <Divider sx={{ my: 1 }} />

                <ReactHookFormSelect2
                    control={control}
                    disabled={isLoading || isGenerated}
                    isClearable={false}
                    label={t('Select a template')}
                    name="template"
                    options={templates}
                />
            </Box>
        </form>
    );
};

const mapStateToProps = createStructuredSelector({
    initialValues: selectors.getTemplateInitialValues,
    isSaving: selectors.getSaveTemplateIsSaving,
    isSavingReportEnd: selectors.getReportEndIsSaving,
});

const mapDispatchToProps = (dispatch) => ({
    save: (reportId, values) =>
        dispatch(actions.saveTemplate(reportId, values)),
});

SidebarForm.propTypes = {
    canModify: PropTypes.bool,
    initialValues: PropTypes.object,
    isGenerated: PropTypes.bool,
    isLoading: PropTypes.bool,
    isSaving: PropTypes.bool,
    isSavingReportEnd: PropTypes.bool,
    reportId: PropTypes.string,
    reverseBtn: PropTypes.bool,
    save: PropTypes.func,
    templates: PropTypes.array,
};

export default connect(mapStateToProps, mapDispatchToProps)(SidebarForm);
