import { createSelector } from 'reselect';

import { arraytoDictionary } from 'common/utils/helpers';

import { NAME } from './constants';

export const getModel = (state) => state[NAME];

export const getInitialValues = createSelector(
    getModel,
    (model) => model.initialValues
);

export const getReassignInitialValues = createSelector(
    getModel,
    (model) => model.reassignInitialValues
);

export const getPasswordInitialValues = createSelector(
    getModel,
    (model) => model.passwordInitialValues
);

export const getSaveItem = createSelector(getModel, (model) => model.saveItem);

export const getIsSavingItem = createSelector(
    getSaveItem,
    (saveItem) => saveItem.isSaving
);

export const getDataSaveItem = createSelector(
    getSaveItem,
    (saveItem) => saveItem.data
);

export const getErrorsSaveItem = createSelector(
    getSaveItem,
    (saveItem) => saveItem.errors
);

export const getDeleteItem = createSelector(
    getModel,
    (model) => model.deleteItem
);

export const getIsDeletingItem = createSelector(
    getDeleteItem,
    (deleteItem) => deleteItem.isDeleting
);

export const getDataDeleteItem = createSelector(
    getDeleteItem,
    (deleteItem) => deleteItem.data
);

export const getErrorsDeleteItem = createSelector(
    getDeleteItem,
    (deleteItem) => deleteItem.errors
);

export const getFetchItems = createSelector(
    getModel,
    (model) => model.fetchItems
);

export const getIsFetchingItems = createSelector(
    getFetchItems,
    (fetchItems) => fetchItems.isFetching
);

export const getDataFetchItems = createSelector(
    getFetchItems,
    (fetchItems) => fetchItems.data || []
);

export const getErrorsFetchItems = createSelector(
    getFetchItems,
    (fetchItems) => fetchItems.errors
);

export const getFetchRoles = createSelector(
    getModel,
    (model) => model.fetchRoles
);

export const getIsFetchingRoles = createSelector(
    getFetchRoles,
    (fetchRoles) => fetchRoles.isFetching
);

export const getFetchRolesData = createSelector(
    getFetchRoles,
    (fetchRoles) => fetchRoles.data || []
);

export const getFetchRolesDictionary = createSelector(
    getFetchRolesData,
    (roles) => arraytoDictionary(roles, 'id') || {}
);

export const getFetchRolesErrors = createSelector(
    getFetchRoles,
    (fetchRoles) => fetchRoles.errors
);

export const getActivateItem = createSelector(
    getModel,
    (model) => model.activateItem
);

export const getIsFetchingActivateItem = createSelector(
    getActivateItem,
    (activateItem) => activateItem.isFetching
);
export const getDataActivateItem = createSelector(
    getActivateItem,
    (activateItem) => activateItem.data
);
export const getErrorsActivateItem = createSelector(
    getActivateItem,
    (activateItem) => activateItem.erros
);

export const getIsOpenModalProduct = createSelector(
    getModel,
    (model) => model.isOpenModalProduct
);

export const getIsOpenModalPassword = createSelector(
    getModel,
    (model) => model.isOpenModalPassword
);

export const getIsNewRecord = createSelector(
    getModel,
    (model) => model.isNewRecord
);

export const getSearchText = createSelector(
    getModel,
    (model) => model.searchText
);

export const getValidPasswordConfirmationLength = createSelector(
    getModel,
    (model) => model.validPasswordConfirmationLength
);

export const getValidPassword = createSelector(
    getModel,
    (model) => model.validPassword
);

export const getValidPasswordConfirmation = createSelector(
    getModel,
    (model) => model.validPasswordConfirmation
);

export const getBranchAgentsDictionary = createSelector(
    getModel,
    (model) => model.branchAgentsDictionary
);

export const getReassignType = createSelector(
    getModel,
    (model) => model.reassignType
);

export const getFetchReassignContacts = createSelector(
    getModel,
    (model) => model.fetchReassignContacts
);

export const getIsFetchingReassignContacts = createSelector(
    getFetchReassignContacts,
    (fetchReassignContacts) => fetchReassignContacts.isFetching
);

export const getErrorsFetchReassignContacts = createSelector(
    getFetchReassignContacts,
    (fetchReassignContacts) => fetchReassignContacts.error
);
