import React, { useCallback } from 'react';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DownloadIcon from '@mui/icons-material/Download';
import UploadIcon from '@mui/icons-material/Upload';
import { styled } from '@mui/material/styles';
import isString from 'lodash/isString';
import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Box, Button, IconButton } from 'sunwise-ui';

import { URL_TYPES } from 'common/constants';
import { handleFileURL } from 'common/utils/helpers';

const WrapperField = styled(Box)`
    ${({ variant }) =>
        variant === 'firstLoad' &&
        `
    background: #002438;
    border-radius: 3px;
    border: 3px dashed #a3a6b4;
    color: #ffffff;
    padding: 8px;
`}
    cursor: pointer;
    outline: none;
    text-align: center;
`;

const MonthlyPDFFileUpload = ({
    control,
    disabled,
    handleDownloadLisaFile,
    name,
    onChange,
    setValue,
    url_type,
}) => {
    const { t } = useTranslation();
    const onDrop = useCallback((acceptedFiles) => {
        setValue(
            name,
            acceptedFiles.map((file) =>
                Object.assign(file, {
                    preview: URL.createObjectURL(file),
                    typeFile: file.type,
                    sizeFile: file.size,
                })
            )
        );
        if (onChange) onChange(acceptedFiles);
    }, []);
    const { getRootProps, getInputProps } = useDropzone({ onDrop });
    return (
        <Controller
            control={control}
            name={name}
            render={({ field: { value } }) => (
                <Box mb={!value ? 3 : 1}>
                    <WrapperField
                        {...getRootProps()}
                        variant={!value ? `firstLoad` : `reload`}
                    >
                        <input disabled={disabled} {...getInputProps()} />
                        {!value ? (
                            <>
                                <IconButton color="secondary">
                                    <UploadIcon />
                                </IconButton>
                                {t('Upload billing for this period')}
                            </>
                        ) : (
                            <Box
                                alignItems="center"
                                display="flex"
                                flexDirection="column"
                                gap="8px"
                                sx={{ textAlign: 'center' }}
                            >
                                <CheckCircleIcon />
                                <Box>{t('Upload was successful')}</Box>
                                <Button sx={{ m: '0!important' }}>
                                    {t('Change file')}
                                </Button>
                            </Box>
                        )}
                    </WrapperField>

                    {isString(value) && (
                        <Box sx={{ mt: 1, textAlign: 'right' }}>
                            {url_type !== URL_TYPES.LISA && (
                                <IconButton
                                    color="info"
                                    href={handleFileURL(
                                        value,
                                        process.env.REACT_APP_API_URL
                                    )}
                                    rel="noopener noreferrer"
                                    target="_blank"
                                    size="small"
                                >
                                    <DownloadIcon />
                                </IconButton>
                            )}

                            {url_type === URL_TYPES.LISA && (
                                <IconButton
                                    color="info"
                                    onClick={() =>
                                        handleDownloadLisaFile(value)
                                    }
                                    size="small"
                                >
                                    <DownloadIcon />
                                </IconButton>
                            )}

                            {t('Download')}
                        </Box>
                    )}
                </Box>
            )}
        />
    );
};

MonthlyPDFFileUpload.propTypes = {
    classNameWrapper: PropTypes.string,
    control: PropTypes.object,
    disabled: PropTypes.bool,
    handleDownloadLisaFile: PropTypes.func,
    name: PropTypes.string,
    onChange: PropTypes.func,
    setValue: PropTypes.func,
    url_type: PropTypes.number,
};

export default MonthlyPDFFileUpload;
