import { getRateDivisions } from 'common/api/v1/catalogs';

import {
    FETCH_RATE_DIVISIONS,
    FETCH_RATE_DIVISIONS_SUCCESS,
    FETCH_RATE_DIVISIONS_FAILURE,
} from '../actionTypes';
import { actions } from '../reducer';

import fetchScheduleRateConfiguration from './fetchScheduleRateConfiguration';

export default ({ id, selectedRate, selectedRateDivision = null, setValue }) =>
    (dispatch) => {
        dispatch(actions[FETCH_RATE_DIVISIONS]());
        getRateDivisions({ political_division: id })
            .then(({ data }) => {
                dispatch(actions[FETCH_RATE_DIVISIONS_SUCCESS](data?.data));

                let selected = null;
                if (data?.data?.length > 0)
                    selected = selectedRateDivision || data.data[0].id;

                if (setValue) {
                    setValue('rate_division', selected);
                    dispatch(
                        fetchScheduleRateConfiguration({
                            certified: selectedRate?.certified,
                            rate: selectedRate?.id,
                            rate_division: selected,
                        })
                    );
                } else if (selectedRateDivision && selectedRate)
                    dispatch(
                        fetchScheduleRateConfiguration({
                            certified: selectedRate?.certified,
                            rate: selectedRate?.id,
                            rate_division: selectedRateDivision,
                        })
                    );

                return selected;
            })
            .catch((error) =>
                dispatch(
                    actions[FETCH_RATE_DIVISIONS_FAILURE](
                        error?.response?.data?.errors
                    )
                )
            );
    };
