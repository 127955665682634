import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { MenuItem } from 'sunwise-ui';

import { ReactHookFormInput } from 'common/components/form/bootstrap';

import * as actions from '../../actions';
import * as selectors from '../../selectors';

const SupplierUtilityInput = ({
    control,
    countryId,
    disabled,
    isFetchingSupplierUtilities,
    name,
    supplierUtilitiesItemsToSelect,
}) => {
    const { t } = useTranslation();
    const fieldDisabled = !countryId || isFetchingSupplierUtilities || disabled;

    return (
        <ReactHookFormInput
            control={control}
            disabled={fieldDisabled}
            label={t('Electricity supplier')}
            name={name}
            select
        >
            {supplierUtilitiesItemsToSelect.map(
                ({ label, value, disabled }) => (
                    <MenuItem
                        disabled={disabled}
                        key={`utility-supplier-${value}-${label}`}
                        value={value}
                    >
                        {label}
                    </MenuItem>
                )
            )}
        </ReactHookFormInput>
    );
};

const mapStateToProps = createStructuredSelector({
    isFetchingSupplierUtilities: selectors.getIsFetchingSupplierUtilities,
    supplierUtilitiesItemsToSelect: selectors.getSupplierUtilitiesItemsToSelect,
});

const mapDispatchToProps = (dispatch) => ({
    setSupplierUtilitiesModalIsOpen: (isOpen) =>
        dispatch(actions.setSupplierUtilitiesModalIsOpen(isOpen)),
});

SupplierUtilityInput.propTypes = {
    control: PropTypes.object,
    countryId: PropTypes.string,
    disabled: PropTypes.bool,
    isFetchingSupplierUtilities: PropTypes.bool,
    name: PropTypes.string,
    supplierUtilitiesItemsToSelect: PropTypes.array,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SupplierUtilityInput);
