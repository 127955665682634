import React from 'react';

import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
    Box,
    Button,
    Grid,
    Divider,
    Stack,
    StackItem,
    Typography,
} from 'sunwise-ui';

import Switch from 'common/components/form/Switch';

const RequestedProducts = ({
    allianceProductsList,
    handleOnClickAddProduct,
    fetchFinarciersInstallerData,
    selectedAlliance = {},
}) => {
    const { t } = useTranslation();

    if (isEmpty(allianceProductsList)) {
        return (
            <Box display="flex" alignItems="center" p={2}>
                <Typography
                    variant="body2"
                    fontWeight="bold"
                    sx={{ color: '#848bab' }}
                >
                    {t('There are no products assigned')}
                </Typography>
            </Box>
        );
    }

    return (
        <Stack
            divider={<Divider />}
            sx={{ '.MuiDivider-fullWidth': { mt: 0 } }}
        >
            {allianceProductsList.map((product) => (
                <StackItem key={`requested-product-${product.id}`}>
                    <Grid container alignItems="center">
                        <Grid item xs>
                            <Typography variant="body2" fontWeight="bold">
                                {product.name}
                            </Typography>
                        </Grid>
                        <Grid item>
                            {product.added ? (
                                <Switch
                                    checked={product.approved}
                                    disabled={true}
                                />
                            ) : (
                                <Button
                                    onClick={() =>
                                        handleOnClickAddProduct(
                                            product.id,
                                            () =>
                                                fetchFinarciersInstallerData(
                                                    selectedAlliance.id
                                                )
                                        )
                                    }
                                    variant="outlined"
                                >
                                    {t('Apply for')}
                                </Button>
                            )}
                        </Grid>
                    </Grid>
                </StackItem>
            ))}
        </Stack>
    );
};

RequestedProducts.propTypes = {
    allianceProductsList: PropTypes.array,
    handleOnClickAddProduct: PropTypes.func,
    fetchFinarciersInstallerData: PropTypes.func,
    selectedAlliance: PropTypes.object,
};

export default RequestedProducts;
