import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import get from 'lodash/get';
import { initReactI18next } from 'react-i18next';

import { DEFAULT_LANGUAGE } from 'common/constants/multiregion';
import {
    getUserSettings,
    getRuntimeLanguage,
} from 'common/utils/helpers/multiregion';

import TRANSLATIONS_EN from './i18n/translations/en.json';
import TRANSLATIONS_ES_ES from './i18n/translations/es-es.json';
import TRANSLATIONS_ES from './i18n/translations/es.json';
import TRANSLATIONS_PT from './i18n/translations/pt.json';

const settings = getUserSettings();
const language = get(settings, 'language.key', getRuntimeLanguage());

const config = {
    debug: ['dev', 'staging'].includes(process.env.REACT_APP_ENV),
    fallbackLng: DEFAULT_LANGUAGE,
    interpolation: { escapeValue: false },
    lng: language,
    react: {
        transSupportBasicHtmlNodes: true,
        useSuspense: true,
    },
    nonExplicitSupportedLngs: true,
    resources: {
        en: { translation: TRANSLATIONS_EN },
        es: { translation: TRANSLATIONS_ES },
        'es-ES': { translation: TRANSLATIONS_ES_ES },
        pt: { translation: TRANSLATIONS_PT },
    },
};

i18n.use(LanguageDetector).use(initReactI18next).init(config);

export default i18n;
