import isEmpty from 'lodash/isEmpty';

import { getDictionaryById } from '../helpers';
import * as selectors from '../selectors';

import selectSegment from './selectSegment';
import updateSegments from './updateSegments';

export default (segmentId) => (dispatch, getState) => {
    const state = getState();
    const segments = selectors.getSegmentsData(state);
    const segmentsDictionary = getDictionaryById(segments);
    const selectedSegmentId = selectors.getSelectedSegmentId(state);
    const initialValues = selectors.getInitialValues(state);

    if (segmentsDictionary[segmentId])
        dispatch(selectSegment(segmentsDictionary[segmentId]));

    if (segmentId !== selectedSegmentId && isEmpty(initialValues))
        dispatch(
            updateSegments(
                segments.map((item) => ({
                    ...item,
                    open: item.id === segmentId,
                }))
            )
        );
};
