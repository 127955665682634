import i18next from 'i18next';
import isEmpty from 'lodash/isEmpty';

import { bulkProducts } from 'common/api/v1/productsBulk';
import { showReponseErrorsAsAlert } from 'common/utils/helpers';
import showToast from 'common/utils/showToast';

import {
    BULK_UPDATE_ITEMS,
    BULK_UPDATE_ITEMS_FAILURE,
    BULK_UPDATE_ITEMS_SUCCESS,
} from '../actionTypes';
import { invertersConfigurationActions } from '../reducer';

import filterItems from './filterItems';

export default (ids, value, filterData, callback) => (dispatch) => {
    dispatch(invertersConfigurationActions[BULK_UPDATE_ITEMS]());

    const products = ids.map((id) => ({ id, status_flag: value }));

    bulkProducts({ product_type: 'inverters', products })
        .then(() => {
            dispatch(
                invertersConfigurationActions[BULK_UPDATE_ITEMS_SUCCESS]()
            );
            if (!isEmpty(filterData)) dispatch(filterItems(filterData));
            if (value === 'deleted')
                showToast({ body: i18next.t('It was successfully removed') });
            if (value === 'archived')
                showToast({ body: i18next.t('It was successfully archived') });
            if (value === 'active')
                showToast({
                    body: i18next.t('It was successfully unarchived'),
                });
            if (callback) callback();
        })
        .catch((error) => {
            dispatch(
                invertersConfigurationActions[BULK_UPDATE_ITEMS_FAILURE](
                    error?.response?.data?.errors
                )
            );
            showReponseErrorsAsAlert(dispatch, error?.response);
        });
};
