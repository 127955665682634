import { getOfferDetails } from 'common/api/v2/offerDetails';

import {
    FETCH_OFFER_DETAILS,
    FETCH_OFFER_DETAILS_FAILURE,
    FETCH_OFFER_DETAILS_SUCCESS,
} from '../actionTypes';
import { proposalGeneratorOneColumnActions } from '../reducer';

import showProposalAlert from './showProposalAlert';

export default (id) => (dispatch) =>
    new Promise((resolve) => {
        dispatch(proposalGeneratorOneColumnActions[FETCH_OFFER_DETAILS]());

        getOfferDetails(id)
            .then((response) => {
                dispatch(
                    proposalGeneratorOneColumnActions[
                        FETCH_OFFER_DETAILS_SUCCESS
                    ](response.data.data)
                );
            })
            .catch((error) => {
                dispatch(
                    proposalGeneratorOneColumnActions[
                        FETCH_OFFER_DETAILS_FAILURE
                    ](error)
                );
                dispatch(showProposalAlert());
            })
            .finally(resolve);
    });
