import i18next from 'i18next';

export const getAgentOptions = (agents) => {
    const items = agents
        .filter((agent) => agent.is_active && agent.is_enabled)
        .map((item) => {
            return {
                label: `${item.first_name} ${item.last_name}`,
                value: item.id,
            };
        });

    return [
        {
            disabled: true,
            label: i18next.t('Select an agent'),
            value: '',
        },
        ...items,
    ];
};

export const getFormConfig = (values) => {
    if (values) {
        return {
            ...values,
            service_number: handleCheckboxField(values.service_number),
            method_consumtion: handleCheckboxField(values.method_consumtion),
            install_type: handleCheckboxField(values.install_type),
            style: {
                h1: {
                    ...values.style.h1,
                    fontSize: values.style.h1.fontSize + 'px',
                },
                h2: {
                    ...values.style.h2,
                    fontSize: values.style.h2.fontSize + 'px',
                },
                button: {
                    ...values.style.button,
                    borderRadius: values.style.button.borderRadius + 'px',
                    fontSize: values.style.button.fontSize + 'px',
                    text: values.style.button.text,
                },
            },
        };
    }

    return {};
};

export const handleCheckboxField = (values) => {
    if (!values) return null;

    const object_keys = Object.keys(values);
    let temp = [];

    object_keys.forEach((item) => {
        if (values[item] === true) temp.push(item);
    });

    return temp;
};

export const handleLists = (list) => {
    const temp = {};

    if (list) {
        list.forEach((item) => (temp[item] = true));
    }

    return temp;
};
