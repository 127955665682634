import { NAME } from './constants';

export const FETCH_AGENTS = `${NAME}/FETCH_AGENTS`;
export const FETCH_AGENTS_FAILURE = `${NAME}/FETCH_AGENTS_FAILURE`;
export const FETCH_AGENTS_SUCCESS = `${NAME}/FETCH_AGENTS_SUCCESS`;

export const FETCH_PHONE_CODES = `${NAME}/FETCH_PHONE_CODES`;
export const FETCH_PHONE_CODES_FAILURE = `${NAME}/FETCH_PHONE_CODES_FAILURE`;
export const FETCH_PHONE_CODES_SUCCESS = `${NAME}/FETCH_PHONE_CODES_SUCCESS`;

export const FETCH_ORIGIN_CONTACT = `${NAME}/FETCH_ORIGIN_CONTACT`;
export const FETCH_ORIGIN_CONTACT_FAILURE = `${NAME}/FETCH_ORIGIN_CONTACT_FAILURE`;
export const FETCH_ORIGIN_CONTACT_SUCCESS = `${NAME}/FETCH_ORIGIN_CONTACT_SUCCESS`;

export const FETCH_STATUS_CONTACT = `${NAME}/FETCH_STATUS_CONTACT`;
export const FETCH_STATUS_CONTACT_FAILURE = `${NAME}/FETCH_STATUS_CONTACT_FAILURE`;
export const FETCH_STATUS_CONTACT_SUCCESS = `${NAME}/FETCH_STATUS_CONTACT_SUCCESS`;

export const INITIALIZE_FORM = `${NAME}/INITIALIZE_FORM`;

export const OPEN_DUPLICATE_MODAL = `${NAME}/OPEN_DUPLICATE_MODAL`;

export const RESET_DATA_DUPLICATE_MODAL = `${NAME}/RESET_DATA_DUPLICATE_MODAL`;
export const RESET_FORM = `${NAME}/RESET_FORM`;

export const SAVE_CONTACT = `${NAME}/SAVE_CONTACT`;
export const SAVE_CONTACT_FAILURE = `${NAME}/SAVE_CONTACT_FAILURE`;
export const SAVE_CONTACT_SUCCESS = `${NAME}/SAVE_CONTACT_SUCCESS`;

export const SET_DATA_DUPLICATE_MODAL = `${NAME}/SET_DATA_DUPLICATE_MODAL`;

export const SET_IS_NEW_RECORD = `${NAME}/SET_IS_NEW_RECORD`;
export const SET_IS_SHOW_INFORMATION = `${NAME}/SET_IS_SHOW_INFORMATION`;

export const SHOW_MODAL = `${NAME}/SHOW_MODAL`;
