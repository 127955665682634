import React from 'react';

import { styled } from '@mui/material/styles';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroller';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Box, Typography } from 'sunwise-ui';

import { ListItem } from 'common/components/List';
import { scroll } from 'common/utils/mixins';

import * as actions from '../actions';
import { PROFILE_TYPE_ITEMS } from '../constants';
import * as selectors from '../selectors';

const ListContainer = styled(Box)`
    display: flex;
    flex-direction: column;
    height: 400px;
    overflow-y: auto;
    padding: 8px;
    width: 100%;

    ${scroll.custom()}

    @media (min-width: 900px) and (max-width: 1199px) {
        height: calc(100vh - 430px);
    }
    @media (min-width: 1200px) {
        height: calc(100vh - 460px);
    }
`;

const ListContent = ({
    canDelete,
    consumptionProfiles,
    fetchConsumptionProfile,
    filterNewPageConsumptionProfiles,
    filterQuery,
    hasNextPage,
    selectedItemId,
}) => {
    const { t } = useTranslation();

    if (isEmpty(consumptionProfiles))
        return (
            <Typography
                variant="body2"
                fontWeight="bold"
                sx={{ color: '#b3b7cb' }}
            >
                {t('You have no configured consumption profiles')}
            </Typography>
        );

    return (
        <InfiniteScroll
            hasMore={hasNextPage}
            loader={
                <div className="scroll-loader" key={0}>
                    {t('Loading').concat('...')}
                </div>
            }
            loadMore={(nextPage) =>
                filterNewPageConsumptionProfiles({
                    page: nextPage,
                    ...filterQuery,
                })
            }
            pageStart={1}
            threshold={100}
        >
            <ListContainer>
                {consumptionProfiles.map(
                    ({ id, type, name, usage_type }, index) => (
                        <ListItem
                            businessType={PROFILE_TYPE_ITEMS[usage_type]}
                            canDelete={canDelete}
                            handleOnClick={() => fetchConsumptionProfile(id)}
                            hideSwitch
                            isCertified={type === 0}
                            isSelected={id === selectedItemId}
                            key={`consumption-profile-card-${id}-${index}`}
                            name={name}
                            refKey={`consumption-profile-card-${id}-${index}`}
                            voltageType="Low tension"
                        />
                    )
                )}
            </ListContainer>
        </InfiniteScroll>
    );
};

const mapStateToProps = createStructuredSelector({
    consumptionProfiles: selectors.getConsumptionProfilesData,
    filterQuery: selectors.getFilterQuery,
    hasNextPage: selectors.getHasNextPage,
    selectedItemId: selectors.getSelectedItemId,
});

const mapDispatchToProps = (dispatch) => ({
    fetchConsumptionProfile: (profileId) =>
        dispatch(actions.fetchConsumptionProfile(profileId)),
    filterNewPageConsumptionProfiles: (params) =>
        dispatch(actions.filterNewPageConsumptionProfiles(params)),
});

ListContent.propTypes = {
    canDelete: PropTypes.bool,
    consumptionProfiles: PropTypes.array,
    fetchConsumptionProfile: PropTypes.func,
    filterNewPageConsumptionProfiles: PropTypes.func,
    filterQuery: PropTypes.object,
    hasNextPage: PropTypes.bool,
    selectedItemId: PropTypes.string,
};

export default connect(mapStateToProps, mapDispatchToProps)(ListContent);
