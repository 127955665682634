import React from 'react';

import { useTheme } from '@mui/material/styles';
import { get, isNull } from 'lodash';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import { Box, Card, Grid, Link, Typography } from 'sunwise-ui';

import EmptyState from 'common/components/EmptyState';
import PlaceholderCardOffer from 'common/components/placeholder/PlaceholderCardOffer';
import { COMMERCIAL_OFFER_STATUS } from 'common/constants/commercialOffer';
import { getCurrencyIso, numberFormat } from 'common/utils/helpers';
import { getCountryCurrencyLocale } from 'common/utils/helpers/session';

import EmptyProposals from 'resources/EmptyProposals.png';
import EmptyStateArchived from 'resources/EmptyStateArchived.png';

import { getRequestCount } from '../helpers';

import BranchOfficeTooltip from './BranchOfficeTooltip';
import DateTooltip from './DateTooltip';
import FinancingSection from './FinancingSection';
import InteractiveLinkTooltip from './InteractiveLinkTooltip';
import MenuOptions from './MenuOptions';
import SmartDocumentsTooltip from './SmartDocumentsTooltip';
import StatusIndicatorTooltip from './StatusIndicatorTooltip';
import ViewsTooltip from './ViewsTooltip';

const ProposalTable = ({
    canDelete,
    canModify,
    commercialOffers,
    handleToggleDrawer,
    isArchivedFilter,
    isLoading,
    prepareCommercialOfferCreate,
    setSelectedCreditItem,
    setRef,
}) => {
    const { t } = useTranslation();
    const theme = useTheme();

    if (!isLoading && commercialOffers.length <= 0) {
        if (isArchivedFilter) {
            return (
                <EmptyState
                    srcImage={EmptyStateArchived}
                    sxCard={{ border: 0, boxShadow: 'none' }}
                    title={t('This project has no archived proposals')}
                />
            );
        } else {
            return (
                <EmptyState
                    canModify={canModify}
                    caption={t(
                        'Here you will see all the proposals of the project. Start creating one!'
                    )}
                    classNameCard="border-0"
                    classNameCardBody="__intercom_add_commercial_offer_button"
                    dataIntercomTarget="Add Commercial Offer (Button)"
                    onClick={prepareCommercialOfferCreate}
                    srcImage={EmptyProposals}
                    sxCard={{ border: 0, boxShadow: 'none' }}
                    textButton={t('Create')}
                    title={t('This project has no proposals')}
                />
            );
        }
    }

    return (
        <PlaceholderCardOffer ready={isLoading}>
            <Box>
                {commercialOffers.map((item) => {
                    const commercialOffer = get(
                        item,
                        'commercial_offer[0]',
                        {}
                    );
                    const { currency } = commercialOffer;
                    const currencyIso = getCurrencyIso(currency);
                    const currencyLocale = getCountryCurrencyLocale();
                    const isGenerated =
                        commercialOffer.status ===
                        COMMERCIAL_OFFER_STATUS.FINISHED.key;
                    const to = isGenerated
                        ? `/proposal-review-pro/${commercialOffer.id}`
                        : `/proposal/${commercialOffer.id}`;
                    return (
                        <Card
                            key={`proposal-item-${item.id}`}
                            style={{
                                backgroundColor:
                                    theme.palette.mode === 'dark'
                                        ? '#282b30'
                                        : '',
                            }}
                        >
                            <Grid
                                container
                                spacing={0}
                                sx={{
                                    px: 1,
                                    py: 2,
                                    flexDirection: 'row',
                                    flexWrap: 'noWrap',
                                }}
                            >
                                <Box sx={{ px: 1 }}>
                                    <StatusIndicatorTooltip
                                        isApproved={
                                            commercialOffer.has_approbed
                                        }
                                        isGenerated={isGenerated}
                                    />
                                </Box>

                                <Grid item md={17} sx={{ px: 1 }} xs={16}>
                                    <Grid container spacing={0}>
                                        <Grid item xs={14} lg={16}>
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    justifyContent: 'center',
                                                }}
                                            >
                                                <Link
                                                    color="inherit"
                                                    component={RouterLink}
                                                    to={to}
                                                    underline="none"
                                                >
                                                    <strong>{item.name}</strong>
                                                </Link>

                                                <Typography variant="body2">
                                                    {numberFormat(
                                                        commercialOffer.total,
                                                        {
                                                            currency:
                                                                currencyIso,
                                                            locale: currencyLocale,
                                                            style: 'currency',
                                                        }
                                                    )}
                                                </Typography>
                                            </Box>
                                        </Grid>

                                        <MenuOptions
                                            canDelete={canDelete}
                                            canModify={canModify}
                                            creditStatus={get(
                                                commercialOffer,
                                                'credit_application.status',
                                                null
                                            )}
                                            hasFinancingRequest={get(
                                                commercialOffer,
                                                'has_financing_request',
                                                false
                                            )}
                                            hasPermalink={
                                                commercialOffer.has_permalink
                                            }
                                            hasSummaryLinked={
                                                commercialOffer.proposal_summary_linked
                                            }
                                            isApproved={
                                                commercialOffer.has_approbed
                                            }
                                            isArchived={
                                                commercialOffer.is_archived
                                            }
                                            isArchivedFilter={isArchivedFilter}
                                            isFavorite={item.favorite}
                                            isGenerated={isGenerated}
                                            offerId={commercialOffer.id}
                                            offerName={item.name}
                                            permalink={
                                                commercialOffer.permalink
                                            }
                                            projectId={item.project}
                                            proposalId={item.id}
                                        />
                                    </Grid>

                                    <Grid container spacing={0}>
                                        <Grid item xs={18}>
                                            <Box
                                                sx={{
                                                    alignItems: 'center',
                                                    display: 'flex',
                                                    flexWrap: 'wrap',
                                                    gap: 2,
                                                    mt: 1,
                                                }}
                                            >
                                                <DateTooltip
                                                    createdAt={item.created_at}
                                                />

                                                <BranchOfficeTooltip
                                                    branchOffice={
                                                        item?.branch_office
                                                    }
                                                />

                                                <SmartDocumentsTooltip
                                                    isHidden={!isGenerated}
                                                    smartDocuments={get(
                                                        commercialOffer,
                                                        'smart_documents',
                                                        []
                                                    )}
                                                />

                                                <InteractiveLinkTooltip
                                                    isActive={
                                                        commercialOffer.has_permalink
                                                    }
                                                    isHidden={
                                                        !isGenerated ||
                                                        isNull(
                                                            commercialOffer.permalink
                                                        )
                                                    }
                                                />

                                                <ViewsTooltip
                                                    isHidden={!isGenerated}
                                                    offerId={commercialOffer.id}
                                                    viewsCounter={get(
                                                        commercialOffer,
                                                        'views_counter',
                                                        0
                                                    )}
                                                />
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>

                            <FinancingSection
                                creditAppId={get(
                                    commercialOffer,
                                    'credit_application_id',
                                    null
                                )}
                                creditItem={get(
                                    commercialOffer,
                                    'credit_item',
                                    {}
                                )}
                                creditStatus={get(
                                    commercialOffer,
                                    'credit_application.status',
                                    null
                                )}
                                currency={get(commercialOffer, 'currency', {})}
                                hasClosedCredit={get(
                                    commercialOffer,
                                    'has_credit_close',
                                    false
                                )}
                                hasFinancingRequest={get(
                                    commercialOffer,
                                    'has_financing_request',
                                    false
                                )}
                                hasRequestBureau={get(
                                    commercialOffer,
                                    'has_request_buro',
                                    false
                                )}
                                handleToggleDrawer={handleToggleDrawer}
                                isArchived={commercialOffer.is_archived}
                                isGenerated={isGenerated}
                                offerId={commercialOffer.id}
                                projectId={item.project}
                                proposalId={item.id}
                                requestCount={getRequestCount(commercialOffers)}
                                setSelectedCreditItem={setSelectedCreditItem}
                                setRef={setRef}
                                typesBusiness={
                                    commercialOffer.types_business_company
                                }
                            />
                        </Card>
                    );
                })}
            </Box>
        </PlaceholderCardOffer>
    );
};

ProposalTable.propTypes = {
    canDelete: PropTypes.bool,
    canModify: PropTypes.bool,
    commercialOffers: PropTypes.array,
    handleToggleDrawer: PropTypes.func,
    isArchivedFilter: PropTypes.bool,
    isLoading: PropTypes.bool,
    prepareCommercialOfferCreate: PropTypes.func,
    setSelectedCreditItem: PropTypes.func,
    setRef: PropTypes.func,
};

export default ProposalTable;
