import { bulkNotifications } from 'common/api/v1/notifications';
import { showReponseErrorsAsAlert } from 'common/utils/helpers';
import showToast from 'common/utils/showToast';

import {
    BULK_UPDATE_ITEMS,
    BULK_UPDATE_ITEMS_FAILURE,
    BULK_UPDATE_ITEMS_SUCCESS,
} from '../actionTypes';
import { notificationsActions } from '../reducer';

import fetchNotifications from './fetchNotifications';

export default (callback, filterData, ids, value) => (dispatch) => {
    dispatch(notificationsActions[BULK_UPDATE_ITEMS]());

    const notifications = ids.map((id) => ({ id, viewed: value }));

    bulkNotifications({ notifications })
        .then(() => {
            dispatch(notificationsActions[BULK_UPDATE_ITEMS_SUCCESS]());
            dispatch(fetchNotifications(filterData));
            showToast();
            callback();
        })
        .catch((error) => {
            dispatch(
                notificationsActions[BULK_UPDATE_ITEMS_FAILURE](
                    error?.response?.data?.errors
                )
            );
            showReponseErrorsAsAlert(dispatch, error?.response);
        });
};
