import i18next from 'i18next';

import { getPdfFile } from 'common/api/v1/renderTemplate';
import showToast from 'common/utils/showToast';

import {
    DOWNLOAD_REPORT_PDF,
    DOWNLOAD_REPORT_PDF_FAILURE,
    DOWNLOAD_REPORT_PDF_SUCCESS,
} from '../actionTypes';
import { reportTemplateActions } from '../reducer';

import downloadFile from './downloadFile';

export default ({ downloadFormat, id, name = i18next.t('File'), type }) =>
    (dispatch) => {
        const newValues = {
            quality: downloadFormat,
            renderName: name,
            templateFinishedId: id,
            templateType: type,
        };
        dispatch(reportTemplateActions[DOWNLOAD_REPORT_PDF]());
        return getPdfFile(newValues)
            .then((response) => {
                dispatch(
                    reportTemplateActions[DOWNLOAD_REPORT_PDF_SUCCESS](
                        response.data
                    )
                );
                dispatch(downloadFile(name, response.data, 'pdf'));
                showToast({ body: i18next.t('It was created successfully') });
            })
            .catch((error) => {
                dispatch(
                    reportTemplateActions[DOWNLOAD_REPORT_PDF_FAILURE](error)
                );
                showToast({
                    type: 'danger',
                    body: i18next.t('An error occurred'),
                });
            });
    };
