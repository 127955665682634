import { INITIAL_VALUES } from '../actionTypes';
import { proposalGeneratorTemplateActions } from '../reducer';

export default (offerPlanId, offerTemplateId) => (dispatch) => {
    const newValues = {
        custom_offer_template: offerTemplateId,
        proposal_plan: offerPlanId,
    };
    dispatch(proposalGeneratorTemplateActions[INITIAL_VALUES](newValues));
};
