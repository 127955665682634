import { push } from 'connected-react-router';
import i18next from 'i18next';

import { createItem } from 'common/api/v1/proposalsSummary';
import { showReponseErrorsAsAlert } from 'common/utils/helpers';
import showToast from 'common/utils/showToast';

import {
    SAVE_ITEM,
    SAVE_ITEM_FAILURE,
    SAVE_ITEM_SUCCESS,
} from '../actionTypes';
import { proposalSummaryActions } from '../reducer';

import closeModal from './closeModal';

export default (values, projectId) => (dispatch) => {
    const newValues = {
        custom_offer_template: values.custom_offer_template,
        linked_proposals: JSON.stringify(values.linked_proposals),
        name: values.name,
        project: projectId,
    };

    dispatch(proposalSummaryActions[SAVE_ITEM]());

    createItem(newValues)
        .then((response) => {
            dispatch(proposalSummaryActions[SAVE_ITEM_SUCCESS](response.data));
            dispatch(closeModal());
            showToast({
                body: i18next.t('Summary successfully created'),
            });
            dispatch(push(`/summary-review/${response.data.id}`));
        })
        .catch((error) => {
            dispatch(
                proposalSummaryActions[SAVE_ITEM_FAILURE](
                    error?.response?.data?.errors
                )
            );
            showReponseErrorsAsAlert(dispatch, error?.response);
        });
};
