import React from 'react';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Box, Button, Grid, Typography } from 'sunwise-ui';

import CollapsedBreadcrumbs from 'common/components/CollapsedBreadcrumbs';
import { IconEnergyBackup } from 'common/components/icons';

import * as proposalGeneratorOneColumnActions from '../../proposalGeneratorOneColumn/actions';
import * as proposalGeneratorOneColumnSelectors from '../../proposalGeneratorOneColumn/selectors';

const Header = ({
    handleClickBreadcrumb,
    isFetchingOfferDetails,
    offerDetails,
    offerDetailsPattern,
    proposalId,
}) => {
    const { t } = useTranslation();

    const locations = [
        {
            label: get(offerDetailsPattern, 'contact.name', null),
            action: () =>
                handleClickBreadcrumb(
                    get(offerDetailsPattern, 'contact.id', null)
                ),
        },
        {
            label: get(offerDetailsPattern, 'name', null),
            action: () =>
                handleClickBreadcrumb(
                    get(offerDetailsPattern, 'contact.id', null),
                    get(offerDetailsPattern, 'project_id', null)
                ),
        },
        {
            label: get(offerDetails, 'name', null),
            action: () => handleClickBreadcrumb(null, null, proposalId),
        },
    ];

    if (isFetchingOfferDetails) return null;

    return (
        <Grid container spacing={0} sx={{ alignItems: 'center', p: 2 }}>
            <Grid item xs>
                <Box alignItems="center" display="flex" gap={2}>
                    <IconEnergyBackup />
                    <Typography variant="h4">{t('Storage system')}</Typography>
                </Box>
            </Grid>
            <Grid item xs={5} md={4} lg={3} textAlign="right">
                <Button
                    color="secondary"
                    onClick={() =>
                        handleClickBreadcrumb(null, null, proposalId)
                    }
                    size="small"
                    startIcon={<ArrowBackIcon fontSize="small" />}
                    variant="text"
                >
                    {t('Back')}
                </Button>
            </Grid>
            <Grid item xs={18} sx={{ pl: { md: '30px' } }}>
                <CollapsedBreadcrumbs
                    current={t('Storage system')}
                    locations={locations}
                />
            </Grid>
        </Grid>
    );
};

const mapStateToProps = createStructuredSelector({
    isFetchingOfferDetails:
        proposalGeneratorOneColumnSelectors.getIsFetchingOfferDetails,
    offerDetails:
        proposalGeneratorOneColumnSelectors.getOfferDetailsCommercialOffer,
    offerDetailsPattern:
        proposalGeneratorOneColumnSelectors.getOfferDetailsPattern,
});

const mapDispatchToProps = (dispatch) => ({
    handleClickBreadcrumb: (contactId, projetcId, proposalId) =>
        dispatch(
            proposalGeneratorOneColumnActions.handleClickBreadcrumb(
                contactId,
                projetcId,
                proposalId
            )
        ),
});

Header.propTypes = {
    handleClickBreadcrumb: PropTypes.func,
    isFetchingOfferDetails: PropTypes.bool,
    offerDetails: PropTypes.object,
    offerDetailsPattern: PropTypes.object,
    proposalId: PropTypes.string,
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
