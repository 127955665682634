import React, { useEffect, useRef, useState } from 'react';

import { styled, useTheme } from '@mui/material/styles';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import Chart from 'react-apexcharts';
import { Box } from 'sunwise-ui';

import { ChartControls, MonthSelector } from 'common/components/charts';
import { useBreakpoint } from 'common/hooks';
import { getCountryCurrencyLocale } from 'common/utils/helpers/session';
import { scroll } from 'common/utils/mixins';

import { getChartConfig } from '../helpers';

const ChartWrapper = styled(Box)`
    overflow-x: scroll;
    overflow-y: hidden;
    width: 100%;

    ${scroll.custom()}
`;

const ChartComponent = ({
    consumptionProfile,
    fullWidth = false,
    year = 2019,
}) => {
    const breakpoint = useBreakpoint();
    const [chartConfig, setChartConfig] = useState({
        options: {},
        series: [{ data: [], name: '' }],
    });
    const [chartType, setChartType] = useState('area');
    const [chartWidth, setChartWidth] = useState(900);
    const [selectedDays, setSelectedDays] = useState([1]);
    const [selectedMonth, setSelectedMonth] = useState(0);
    const [selectedOption, setSelectedOption] = useState(0);
    const [selectedWeeks, setSelectedWeeks] = useState([0]);
    const container = useRef(null);
    const theme = useTheme();

    const handleOnChange = (e) => {
        const value = parseInt(e.target.value);
        setSelectedOption(value);
        setSelectedMonth((prev) => (value === 1 && prev === 0 ? 1 : prev));
        setChartConfig(
            getChartConfig({
                consumptionProfile,
                days: selectedDays,
                getCountryCurrencyLocale,
                month:
                    [0, 1].includes(value) && selectedMonth === 0
                        ? 1
                        : selectedMonth,
                type: value,
                weeks: selectedWeeks,
                year,
            })
        );
    };

    const handleOnChangeSelectedMonth = (e) => {
        const value = parseInt(e.target.value);
        setSelectedMonth(value);
        setChartConfig(
            getChartConfig({
                consumptionProfile,
                days: selectedDays,
                getCountryCurrencyLocale,
                month: value,
                type: selectedOption,
                weeks: selectedWeeks,
                year,
            })
        );
    };

    const handleOnChangeSelectedWeeks = (e) => {
        let value = e.target.value;
        if (value[value.length - 1] === 'all')
            value = selectedWeeks.length === 4 ? [] : [0, 1, 2, 3];
        setSelectedWeeks(value);
        setChartConfig(
            getChartConfig({
                consumptionProfile,
                days: selectedDays,
                getCountryCurrencyLocale,
                month: selectedMonth,
                type: selectedOption,
                weeks: value,
                year,
            })
        );
    };

    const handleOnChangeSelectedDays = (e) => {
        let value = e.target.value;
        if (value[value.length - 1] === 'all')
            value = selectedDays.length === 7 ? [] : [0, 1, 2, 3, 4, 5, 6];
        setSelectedDays(value);
        setChartConfig(
            getChartConfig({
                consumptionProfile,
                days: value,
                getCountryCurrencyLocale,
                month: selectedMonth,
                type: selectedOption,
                weeks: selectedWeeks,
                year,
            })
        );
    };

    useEffect(() => {
        const margin = 32;
        switch (breakpoint) {
            case 'xs':
                setChartWidth(600);
                break;
            case 'sm':
                setChartWidth(750);
                break;
            case 'md':
                setChartWidth(fullWidth ? '98%' : 600);
                break;
            case 'lg':
                setChartWidth(fullWidth ? '98%' : 800);
                break;
            default: {
                const width = container?.current
                    ? container.current?.offsetWidth - margin
                    : '98%';
                setChartWidth(width);
                break;
            }
        }
    }, [breakpoint]);

    useEffect(() => {
        if (!isEmpty(consumptionProfile))
            setChartConfig(
                getChartConfig({
                    consumptionProfile,
                    days: selectedDays,
                    getCountryCurrencyLocale,
                    month: selectedMonth,
                    type: selectedOption,
                    weeks: selectedWeeks,
                    year,
                })
            );
    }, [consumptionProfile, year]);

    if (isEmpty(consumptionProfile)) return <></>;

    return (
        <>
            <ChartControls
                chartType={chartType}
                chartTypeOptions={['area', 'bar']}
                handleOnChangePeriod={handleOnChange}
                handleOnChangeSelectedDays={handleOnChangeSelectedDays}
                handleOnChangeSelectedWeeks={handleOnChangeSelectedWeeks}
                selectedDays={selectedDays}
                selectedOption={selectedOption}
                selectedWeeks={selectedWeeks}
                setChartType={setChartType}
            />

            <ChartWrapper>
                <Chart
                    height={350}
                    key={`chart-period-${selectedOption}-${selectedMonth}-${chartType}`}
                    options={{
                        ...chartConfig.options,
                        theme: { mode: theme.palette.mode },
                    }}
                    series={chartConfig.series}
                    type={chartType}
                    width={chartWidth}
                />
            </ChartWrapper>

            {[1, 2, 3].includes(selectedOption) && (
                <MonthSelector
                    onChange={handleOnChangeSelectedMonth}
                    showYearlyOption={[2, 3].includes(selectedOption)}
                    value={selectedMonth}
                />
            )}
        </>
    );
};

ChartComponent.propTypes = {
    consumptionProfile: PropTypes.object,
    fullWidth: PropTypes.bool,
    year: PropTypes.number,
};

export default ChartComponent;
