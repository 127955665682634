import { push } from 'connected-react-router';
import i18next from 'i18next';

import { resetPasswordService } from 'common/api/v1/auth';
import { showReponseErrorsAsAlert } from 'common/utils/helpers';
import showToast from 'common/utils/showToast';

import {
    RESET_PASSWORD,
    RESET_PASSWORD_SUCCESS,
    RESET_PASSWORD_FAILURE,
} from '../actionTypes';
import { resetPasswordActions } from '../reducer';
import * as selectors from '../selectors';

export default (data) => (dispatch, getState) => {
    const state = getState();
    const uuid = selectors.getUuid(state);
    const token = selectors.getToken(state);

    dispatch(resetPasswordActions[RESET_PASSWORD]());

    resetPasswordService(data, uuid, token)
        .then((response) => {
            dispatch(
                resetPasswordActions[RESET_PASSWORD_SUCCESS](response.data)
            );
            dispatch(push('/wizard'));

            showToast({
                body: i18next.t('Your password has been reset'),
                autoClose: 3000,
            });
        })
        .catch((error) => {
            dispatch(
                resetPasswordActions[RESET_PASSWORD_FAILURE](
                    error?.response?.data?.errors
                )
            );
            showReponseErrorsAsAlert(dispatch, error?.response);
        });
};
