import { createSelector } from 'reselect';

import { NAME } from './constants';

export const getModel = (state) => state[NAME];

export const getInitialValues = createSelector(
    getModel,
    (model) => model.initialValues
);

// CREATE

export const getCreate = createSelector(getModel, (model) => model.create);

export const getIsCreating = createSelector(
    getCreate,
    (value) => value.isCreating
);

export const getCreateData = createSelector(
    getCreate,
    (value) => value.data || {}
);

export const getCreateErrors = createSelector(
    getCreate,
    (value) => value.errors
);

// DELETE

export const getDelete = createSelector(getModel, (model) => model.delete);

export const getIsDeleting = createSelector(
    getDelete,
    (value) => value.isDeleting
);

export const getDeleteData = createSelector(
    getDelete,
    (value) => value.data || {}
);

export const getDeleteErrors = createSelector(
    getDelete,
    (value) => value.errors
);

// FETCH OFFER TAXES

export const getOfferTaxes = createSelector(
    getModel,
    (model) => model.offerTaxes
);

export const getIsFetchingOfferTaxes = createSelector(
    getOfferTaxes,
    (value) => value.isFetching
);

export const getOfferTaxesData = createSelector(
    getOfferTaxes,
    (value) => value.data || []
);

export const getOfferTaxesErrors = createSelector(
    getOfferTaxes,
    (value) => value.errors
);

// SAVE

export const getSave = createSelector(getModel, (model) => model.save);

export const getIsSaving = createSelector(getSave, (value) => value.isSaving);

export const getSaveData = createSelector(getSave, (value) => value.data || {});

export const getSaveErrors = createSelector(getSave, (value) => value.errors);

export const getIsUpdating = createSelector(
    getIsCreating,
    getIsDeleting,
    getIsSaving,
    (isCreating, isDeleting, isSaving) => isCreating || isDeleting || isSaving
);

// PRODUCTS SETTINGS
export const getIsProductsSettingsModalOpen = createSelector(
    getModel,
    (model) => model.isProductsSettingsModalOpen
);

export const getInitialValuesProductsSettings = createSelector(
    getModel,
    (model) => model.initialValuesProductsSettings
);
