import { filterItems } from 'common/api/v1/companiesCurrencies';

import {
    FETCH_ITEMS,
    FETCH_ITEMS_FAILURE,
    FETCH_ITEMS_SUCCESS,
} from '../actionTypes';
import { multiCurrencyActions } from '../reducer';

export default (searchText = '') =>
    (dispatch) => {
        dispatch(multiCurrencyActions[FETCH_ITEMS]());

        filterItems(searchText)
            .then((response) =>
                dispatch(
                    multiCurrencyActions[FETCH_ITEMS_SUCCESS](
                        response.data.data
                    )
                )
            )
            .catch((error) =>
                dispatch(
                    multiCurrencyActions[FETCH_ITEMS_FAILURE](
                        error?.response?.data?.errors
                    )
                )
            );
    };
