import { getFinancierProductByInstaller } from 'common/api/v1/sunpay';

import {
    FETCH_ALLIANCE_PRODUCTS,
    FETCH_ALLIANCE_PRODUCTS_FAILURE,
    FETCH_ALLIANCE_PRODUCTS_SUCCESS,
} from '../actionTypes';
import { financingAndInsuranceActions } from '../reducer';

export default (allianceId, proposalId) => (dispatch) =>
    new Promise((resolve, reject) => {
        dispatch(financingAndInsuranceActions[FETCH_ALLIANCE_PRODUCTS]());
        getFinancierProductByInstaller(proposalId, allianceId)
            .then((response) => {
                dispatch(
                    financingAndInsuranceActions[
                        FETCH_ALLIANCE_PRODUCTS_SUCCESS
                    ](response.data.data)
                );
                resolve();
            })
            .catch((error) => {
                dispatch(
                    financingAndInsuranceActions[
                        FETCH_ALLIANCE_PRODUCTS_FAILURE
                    ](error?.response?.data?.errors)
                );
                reject();
            });
    });
