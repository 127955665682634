import get from 'lodash/get';
import { createSelector } from 'reselect';

import { NAME } from './constants';

export const getModel = (state) => state[NAME];

export const getInitialValues = createSelector(
    getModel,
    (model) => model.initialValues
);

// PROJECT LOCATION
export const getCurrentProjectLocation = createSelector(
    getModel,
    (model) => model.currentProjectLocation
);

export const getCurrentProjectLocationData = createSelector(
    getCurrentProjectLocation,
    (model) => model.data
);

// SELECTED SEGMENT
export const getSelectedSegment = createSelector(
    getModel,
    (model) => model.selectedSegment
);

export const getSelectedSegmentId = createSelector(
    getSelectedSegment,
    (model) => get(model, 'id', null)
);

// CREATE SEGMENT
export const getCreateSegment = createSelector(
    getModel,
    (model) => model.createSegment
);

export const getCreateSegmentData = createSelector(
    getCreateSegment,
    (model) => model.data
);

export const getCreateSegmentErrors = createSelector(
    getCreateSegment,
    (model) => model.errors
);

export const getCreateSegmentIsSaving = createSelector(
    getCreateSegment,
    (model) => model.isSaving
);

// CHECK SEGMENT
export const getCheckSegment = createSelector(
    getModel,
    (model) => model.checkSegment
);

export const getIsCheckinSegment = createSelector(
    getCheckSegment,
    (model) => model.isSaving
);

export const getCheckSegmentErrors = createSelector(
    getCheckSegment,
    (model) => model.errors
);

// DELETE SEGMENT
export const getDeleteSegment = createSelector(
    getModel,
    (model) => model.deleteSegment
);

export const getIsDeletingSegment = createSelector(
    getDeleteSegment,
    (model) => model.isDeleting
);

export const getDeleteSegmentErrors = createSelector(
    getDeleteSegment,
    (model) => model.errors
);

// EDIT SEGMENT
export const getUpdateSegment = createSelector(
    getModel,
    (model) => model.updateSegment
);

export const getUpdateSegmentData = createSelector(
    getUpdateSegment,
    (model) => model.data
);

export const getUpdatedSegmentErrors = createSelector(
    getUpdateSegment,
    (model) => model.errors
);

export const getUpdateSegmentIsSaving = createSelector(
    getUpdateSegment,
    (model) => model.isSaving
);

// PARENT FIELD SEGMENT
export const getParentFieldSegment = createSelector(
    getModel,
    (model) => model.fetchParentFieldSegment
);

export const getIsFetchingParentFieldSegment = createSelector(
    getParentFieldSegment,
    (value) => value.isFetching
);

export const getParentFieldSegmentData = createSelector(
    getParentFieldSegment,
    (value) => value.data || {}
);

// SEGMENTS
export const getSegmentsData = createSelector(
    getParentFieldSegmentData,
    (values) => values.segments || []
);

export const getParentFieldSegmentCounters = createSelector(
    getParentFieldSegmentData,
    (values) => ({
        solarModulesDesignNumber: values.solarModulesDesignNumber,
        solarModulesOfferNumber: values.solarModulesOfferNumber,
    })
);

export const getParentFieldSegmentErrors = createSelector(
    getParentFieldSegment,
    (value) => value.errors
);

// OFFER INFO PATTERN
export const getOfferInfoPatern = createSelector(
    getModel,
    (model) => model.offerInfoPatern
);

export const getIsFetchingOfferInfoPatern = createSelector(
    getOfferInfoPatern,
    (value) => value.isFetching
);

export const getOfferInfoPaternData = createSelector(
    getOfferInfoPatern,
    (value) => value.data
);

// OFFER PANELS
export const getOfferPanels = createSelector(
    getModel,
    (model) => model.offerPanels
);

export const getIsFetchingOfferPanels = createSelector(
    getOfferPanels,
    (offerInfo) => offerInfo.isFetching
);

export const getOfferPanelsCounters = createSelector(
    getOfferPanels,
    (offerInfo) => offerInfo.counters || {}
);

export const getOfferPanelsData = createSelector(
    getOfferPanels,
    (offerInfo) => offerInfo.data || {}
);

export const getOfferPanelsErrors = createSelector(
    getOfferPanels,
    (offerInfo) => offerInfo.errors
);

// DRAWING MODE
export const getIsDrawingMode = createSelector(
    getModel,
    (model) => model.drawingMode
);

// DRAWING TYPE
export const getDrawingType = createSelector(
    getModel,
    (model) => model.drawingType
);

/** SHADOW SETUP */
export const getShaderSetttingsInitialValues = createSelector(
    getModel,
    (model) => model.shaderSettingsInitialValues
);

export const getShaderSettings = createSelector(
    getModel,
    (model) => model.saveShaderSettings
);

export const getShaderSettingsIsSaving = createSelector(
    getShaderSettings,
    (model) => model.isSaving
);

export const getShaderSettingsData = createSelector(
    getShaderSettings,
    (model) => model.data
);

export const getShaderSettingsErrors = createSelector(
    getShaderSettings,
    (model) => model.errors
);

/** IMAGE UPLOAD */
export const getSaveImageModel = createSelector(
    getModel,
    (model) => model.saveImage
);

export const getSaveImageIsSaving = createSelector(
    getSaveImageModel,
    (model) => model.isSaving
);

export const getSaveImageData = createSelector(
    getSaveImageModel,
    (model) => model.data
);

export const getSaveImageErrors = createSelector(
    getSaveImageModel,
    (model) => model.errors
);

/** SELECT/COPY/PASTE */
export const getSelectedItems = createSelector(
    getModel,
    (model) => model.selectedItems
);

export const getCopyBuffer = createSelector(
    getModel,
    (model) => model.copyBuffer
);

/** DISABLED/ENABLE MODULES */
export const getIsDisablingModules = createSelector(
    getModel,
    (model) => model.isDisablingModules
);

// SIMULATION STATUS
export const getSimulationStatus = createSelector(
    getModel,
    (model) => model.simulation
);

export const getSimulationIsPlaying = createSelector(
    getSimulationStatus,
    (model) => model.isPlaying
);

/** IRRADIATION */
export const getIrradiationModel = createSelector(
    getModel,
    (model) => model.irradiation
);

export const getIrradiationData = createSelector(
    getIrradiationModel,
    (model) => model.data
);

export const getIrradiationErrors = createSelector(
    getIrradiationModel,
    (model) => model.errors
);

export const getIrradiationIsFetching = createSelector(
    getIrradiationModel,
    (model) => model.isFetching
);

export const getInitialIrradiationValues = createSelector(
    getModel,
    (model) => model.initialIrradiationValues
);

export const getSegmentFillMethod = createSelector(
    getModel,
    (model) => model.segmentFillMethod
);
