import i18next from 'i18next';

import { clonePosition } from 'common/api/v1/projectPositions';
import alerts from 'common/modules/alerts';
import { showReponseErrorsAsAlert } from 'common/utils/helpers';
import showToast from 'common/utils/showToast';

import {
    CLONE_POSITION,
    CLONE_POSITION_SUCCESS,
    CLONE_POSITION_ERROR,
    FETCH_LOCATION_SUCCESS,
} from '../actionTypes';
import { projectLocationActions } from '../reducer';

export default (id) => (dispatch) => {
    dispatch(projectLocationActions[CLONE_POSITION]());

    clonePosition(id)
        .then((response) => {
            dispatch(projectLocationActions[CLONE_POSITION_SUCCESS](null));
            dispatch(alerts.actions.close());

            dispatch(
                projectLocationActions[FETCH_LOCATION_SUCCESS](response.data)
            );

            showToast({
                body: i18next.t('Was copied successfully'),
            });
        })
        .catch((error) => {
            dispatch(
                projectLocationActions[CLONE_POSITION_ERROR](
                    error?.response?.data?.errors
                )
            );
            showReponseErrorsAsAlert(dispatch, error?.response);
        });
};
