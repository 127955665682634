import { createSelector } from 'reselect';

import { NAME } from './constants';

export const getModel = (state) => state[NAME];

export const getInitialValues = createSelector(
    getModel,
    (model) => model.initialValues
);

export const getSave = createSelector(getModel, (model) => model.save);

export const getIsSaving = createSelector(getSave, (save) => save.isSaving);

export const getDataSave = createSelector(getSave, (save) => save.data);

export const getErrorsSave = createSelector(getSave, (save) => save.errors);

export const getFetch = createSelector(getModel, (model) => model.fetch);

export const getIsFetching = createSelector(
    getFetch,
    (fetch) => fetch.isFetching
);

export const getCompanyData = createSelector(getFetch, (fetch) => fetch.data);

export const getErrorsFetch = createSelector(getFetch, (fetch) => fetch.errors);

export const getCompanyImage = createSelector(
    getInitialValues,
    (data) => data.image
);
