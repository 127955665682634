import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box, Grid } from 'sunwise-ui';

import { getIsGDRate } from 'common/utils/helpers/rates';

import { handleApplyAvgToRowBuild } from '../../../../helpers';
import AverageButton from '../../../AverageButton';
import Title from '../../../Title';

import DACAlerts from './DACAlerts';
import IntakeCaptureFieldArray from './IntakeCaptureFieldArray';

const Container = ({
    control,
    disabled,
    disabledConsumptionFieldsByCsv,
    getValues,
    handleNormalizeDates,
    profilesConsumptionData,
    rateConfiguration,
    ratesDictionary,
    selectedRate,
    setValue,
    subsidyRate,
    summary,
    summaryWarnings,
}) => {
    const { t } = useTranslation();
    const isGDRate = getIsGDRate(selectedRate);
    const showAvgDailyField =
        !selectedRate.isCertified && selectedRate.periodicityType === '0';
    const handleOnClicAvgButton = () =>
        handleApplyAvgToRowBuild({ getValues, setValue });
    return (
        <>
            <Box sx={{ mb: 2 }}>
                <Grid container>
                    <Grid item xs={18}>
                        <Box display="flex" justifyContent="end">
                            <AverageButton onClick={handleOnClicAvgButton} />
                        </Box>
                    </Grid>

                    <Grid item xs>
                        <Title>{t('Initial date')}</Title>
                    </Grid>

                    <Grid item xs>
                        <Title>{t('Final date')}</Title>
                    </Grid>

                    <Grid item xs>
                        <Title>{t('Days in period')}</Title>
                    </Grid>

                    {showAvgDailyField && (
                        <Grid item xs>
                            <Title>{t('Avg. Daily kWh')}</Title>
                        </Grid>
                    )}

                    <Grid item xs>
                        <Title>kWh</Title>
                    </Grid>

                    {isGDRate && (
                        <Grid item xs>
                            <Title>kW</Title>
                        </Grid>
                    )}

                    {['RAMT', 'GDBT', 'GDMTO'].includes(selectedRate?.name) &&
                        selectedRate?.isCertified && (
                            <Grid item xs>
                                <Title>{t('Power factor')}</Title>
                            </Grid>
                        )}
                </Grid>
            </Box>

            <IntakeCaptureFieldArray
                control={control}
                disabled={disabled}
                disabledConsumptionFieldsByCsv={disabledConsumptionFieldsByCsv}
                getValues={getValues}
                handleNormalizeDates={handleNormalizeDates}
                isGDRate={isGDRate}
                name="summary"
                profilesConsumptionData={profilesConsumptionData}
                rateConfiguration={rateConfiguration}
                ratesDictionary={ratesDictionary}
                selectedRate={selectedRate}
                setValue={setValue}
                showAvgDailyField={showAvgDailyField}
                summaryWarnings={summaryWarnings}
            />

            <DACAlerts
                selectedRate={selectedRate}
                subsidyRate={subsidyRate}
                summary={summary}
            />
        </>
    );
};

Container.propTypes = {
    control: PropTypes.object,
    disabled: PropTypes.bool,
    disabledConsumptionFieldsByCsv: PropTypes.bool,
    getValues: PropTypes.func,
    handleNormalizeDates: PropTypes.func,
    profilesConsumptionData: PropTypes.array,
    rateConfiguration: PropTypes.object,
    ratesDictionary: PropTypes.object,
    selectedRate: PropTypes.object,
    setValue: PropTypes.func,
    subsidyRate: PropTypes.string,
    summary: PropTypes.array,
    summaryWarnings: PropTypes.array,
};

export default Container;
