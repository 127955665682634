import React from 'react';

import i18next from 'i18next';
import { Alert } from 'sunwise-ui';

import { COMMERCIAL_OFFER_STATUS } from 'common/constants/commercialOffer';
import alerts from 'common/modules/alerts';
import showToast from 'common/utils/showToast';

import redirecToProposalReview from './redirecToProposalReview';
import saveTemplate from './saveTemplate';

const prepareEndProposal =
    (proposalId, values, status, hasIncompatibleInverters, hasWarnings) =>
    (dispatch) => {
        if (!values.proposal_plan) {
            showToast({
                autoClose: 3000,
                type: 'warning',
                body: i18next.t('Select a plan to continue'),
            });
            return;
        }

        if (status === COMMERCIAL_OFFER_STATUS.FINISHED.key) {
            dispatch(redirecToProposalReview(proposalId));
            return;
        }

        const messages = [];

        if (hasWarnings) {
            messages.push(
                <Alert severity="warning">
                    {i18next.t('Your proposal has warnings related to storage')}
                </Alert>
            );
        }

        if (hasIncompatibleInverters) {
            messages.push(
                <Alert severity="warning">
                    {i18next.t(
                        'Your proposal has warnings related to inverters compatibility'
                    )}
                </Alert>
            );
        }

        if (messages.length > 0) {
            const dataAlert = {
                cancelText: i18next.t('Cancel'),
                classNameMessages: 'text-left',
                confirmText: i18next.t('Continue'),
                messages,
                onSuccess: () => {
                    dispatch(alerts.actions.disableButtons(true));
                    dispatch(saveTemplate(proposalId, values));
                },
                title: i18next.t('Generate proposal'),
                type: alerts.ALERT_TYPE_CONFIRM,
                variant: 'warning',
            };

            dispatch(alerts.actions.show(dataAlert));
        } else {
            dispatch(saveTemplate(proposalId, values));
        }
    };

export default prepareEndProposal;
