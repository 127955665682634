import { createSlice } from '@reduxjs/toolkit';

import orm, { modelPopulateByArray } from 'common/orm';

import {
    DELETE_DOCUMENT,
    DELETE_DOCUMENT_FAILURE,
    DELETE_DOCUMENT_SUCCESS,
    FETCH_DOCUMENTS,
    FETCH_DOCUMENTS_FAILURE,
    FETCH_DOCUMENTS_PROJECT_TYPE,
    FETCH_DOCUMENTS_PROJECT_TYPE_FAILURE,
    FETCH_DOCUMENTS_PROJECT_TYPE_SUCCESS,
    FETCH_DOCUMENTS_SUCCESS,
    INITIALIZE_FORM,
    OPEN_CONFIRM_MODAL,
    RESET,
    RESET_FORM,
    SET_DATA_CONFIRM_MODAL,
    SET_ELEMENT_TO_DELETE,
    SHOW_MODAL,
    UPLOAD_DOCUMENT,
    UPLOAD_DOCUMENT_FAILURE,
    UPLOAD_DOCUMENT_SUCCESS,
} from './actionTypes';
import { NAME } from './constants';

const INITIAL_STATE = {
    entities: orm.getEmptyState(),
    initialValues: {
        id: null,
        document_type: '',
        name: '',
        file: null,
        value_user: '',
    },
    uploadFile: {
        isLoading: false,
        errors: null,
    },
    fetchDocuments: {
        isLoading: false,
        data: [],
        error: null,
    },
    deleteDocument: {
        isDeleting: false,
        error: null,
    },
    elementToDelete: null,
    isVisibleModal: false,
    documentsProjectType: {
        isLoading: false,
        error: null,
    },
    isOpenConfirmModal: null,
    dataConfirmModal: {
        title: null,
        message: '',
        variant: '',
    },
};

const projectDocumentSlice = createSlice({
    name: NAME,
    initialState: INITIAL_STATE,
    reducers: {
        [UPLOAD_DOCUMENT]: (state) => {
            state.uploadFile.isLoading = true;
        },
        [UPLOAD_DOCUMENT_FAILURE]: (state, action) => {
            state.uploadFile = {
                ...state.uploadFile,
                errors: action.payload,
                isLoading: false,
            };
        },
        [UPLOAD_DOCUMENT_SUCCESS]: (state, action) => {
            const session = orm.session(state.entities);
            const { Document } = session;

            Document.generate(action.payload);

            state.uploadFile.isLoading = false;
            state.entities = session.state;
        },
        [SHOW_MODAL]: (state, action) => {
            state.isVisibleModal = action.payload;
        },
        [FETCH_DOCUMENTS]: (state) => {
            state.fetchDocuments.isLoading = true;
        },
        [FETCH_DOCUMENTS_FAILURE]: (state, action) => {
            state.fetchDocuments = {
                ...state.fetchDocuments,
                error: action.payload,
                isLoading: false,
            };
        },
        [FETCH_DOCUMENTS_SUCCESS]: (state, action) => {
            const session = orm.session(state.entities);
            const { Document } = session;

            modelPopulateByArray(
                Document,
                action.payload.map(({ document_type, title, ...rest }) => ({
                    ...rest,
                    name: title,
                    document_type_id: document_type?.id,
                }))
            );

            state.fetchDocuments = {
                ...state.fetchDocuments,
                error: null,
                isLoading: false,
            };
            state.entities = session.state;
        },
        [DELETE_DOCUMENT]: (state) => {
            state.deleteDocument.isDeleting = true;
        },
        [DELETE_DOCUMENT_FAILURE]: (state, action) => {
            state.deleteDocument = {
                ...state.deleteDocument,
                error: action.payload,
                isDeleting: false,
            };
        },
        [DELETE_DOCUMENT_SUCCESS]: (state, action) => {
            const session = orm.session(state.entities);
            const { Document } = session;

            const document = Document.withId(action.payload);

            if (document) document.delete();

            state.deleteDocument.isDeleting = false;
            state.elementToDelete = null;
            state.entities = session.state;
        },
        [INITIALIZE_FORM]: (state, action) => {
            state.initialValues = { ...state.initialValues, ...action.payload };
        },
        [RESET]: () => INITIAL_STATE,
        [RESET_FORM]: (state) => {
            state.initialValues = INITIAL_STATE.initialValues;
        },
        [FETCH_DOCUMENTS_PROJECT_TYPE]: (state) => {
            state.documentsProjectType.isLoading = true;
        },
        [FETCH_DOCUMENTS_PROJECT_TYPE_FAILURE]: (state, action) => {
            state.documentsProjectType = {
                ...state.documentsProjectType,
                error: action.payload,
                isLoading: false,
            };
        },
        [FETCH_DOCUMENTS_PROJECT_TYPE_SUCCESS]: (state, action) => {
            const session = orm.session(state.entities);
            const { DocumentType } = session;

            modelPopulateByArray(DocumentType, action.payload);

            state.documentsProjectType.isLoading = false;
            state.entities = session.state;
        },
        [OPEN_CONFIRM_MODAL]: (state, action) => {
            state.isOpenConfirmModal = action.payload;
        },
        [SET_DATA_CONFIRM_MODAL]: (state, action) => {
            state.dataConfirmModal = action.payload;
        },
        [SET_ELEMENT_TO_DELETE]: (state, action) => {
            state.elementToDelete = action.payload;
        },
    },
});

export const projectDocumentActions = projectDocumentSlice.actions;

export default projectDocumentSlice.reducer;
