const getStringIncludesValue = (source, value) =>
    source && source.toUpperCase().includes(value);

export const getFilteredProjects = (projects, val) => {
    const str = val.toUpperCase();
    if (!str) return projects;
    return projects.filter(
        (project) =>
            getStringIncludesValue(project.name, str) ||
            getStringIncludesValue(project?.rate?.name, str) ||
            getStringIncludesValue(project?.status_project?.name, str)
    );
};

export const getTotalProjects = (projects) => {
    const total = projects.length - 1;
    return total < 0 ? 0 : total;
};
