import API from '../..';
const ENTITY = 'proposals';

export const filterProposals = ({
    createdBy = 'all',
    endDate = '',
    isArchived = 'False',
    orderBy = 'created_at',
    page = 1,
    perPage = 25,
    rateName = 'all',
    searchText,
    sortBy = null,
    startDate = '',
    status = 'all',
    storageRange = 'all',
    systemRange = 'all',
} = {}) =>
    API.get(`/api/v1/${ENTITY}/`, {
        params: {
            is_archived: isArchived,
            order_by: orderBy,
            page: page,
            per_page: perPage,
            sort: sortBy,
            ...(createdBy !== 'all' ? { created_by: createdBy } : {}),
            ...(endDate ? { end_date: endDate } : {}),
            ...(rateName !== 'all' ? { rate_name: rateName } : {}),
            ...(searchText ? { search: searchText } : {}),
            ...(startDate ? { start_date: startDate } : {}),
            ...(status !== 'all' ? { status } : {}),
            ...(storageRange !== 'all' ? { ...storageRange } : {}),
            ...(systemRange !== 'all' ? { ...systemRange } : {}),
        },
    });

export const generatePreview = (data) =>
    API.post(`/api/v1/${ENTITY}/generate-preview/`, { ...data });
